import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobOrderProductItemService {
  async create({
    job_order_info_id,
    quotation_info_id,
    quotation_item_id,
    unlock_invoice_at,
    lock_invoice_at,
    item_status_id,
    note,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/job-order-product-item`, {
        job_order_info_id,
        quotation_info_id,
        quotation_item_id,
        unlock_invoice_at,
        lock_invoice_at,
        item_status_id,
        note,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      job_order_product_item_id,
      job_order_info_id,
      quotation_info_id,
      quotation_item_id,
      unlock_invoice_at,
      lock_invoice_at,
      item_status_id,
      note,
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/job-order-product-item/${job_order_product_item_id}`, {
        job_order_info_id,
        quotation_info_id,
        quotation_item_id,
        unlock_invoice_at,
        lock_invoice_at,
        item_status_id,
        note,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ job_order_product_item_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/job-order-product-item/${job_order_product_item_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-product-item`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ job_order_product_item_id }) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-product-item/${job_order_product_item_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
