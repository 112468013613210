<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายงานการชำระเงิน</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/invoice']">เอกสารทางบัญชี</a>
                </li>
                <li class="breadcrumb-item active">รายงานการชำระเงิน</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mt-1">รายงานการชำระเงิน</h4>
                <div class="heading-elements">
                  <search-box (search)="search($event)"></search-box>
                </div>
              </div>
  
              <div class="card-content collapse show">
                <div class="card-body">
                    <loading *ngIf="isLoading"></loading>
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th class="text-center">เลขที่เอกสาร</th>
                                    <th>วันที่ชำระ</th>
                                    <th class="text-right">จำนวนเงิน (บาท)</th>
                                    <th class="text-center">หัก ณ ที่จ่าย (%)</th>
                                    <th class="text-center">ภาษีมูลค่าเพิ่ม (%)</th>
                                    <th class="text-right">ยอดสุทธิ (บาท)</th>
                                    <th>วิธีการชำระเงิน</th>
                                    <th class="text-center">ได้รับใบหัก ณ ที่จ่ายแล้ว</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let payment of payments; let i = index">
                                <td class="text-center text-nowrap">
                                  {{payment?.acc_invoice_info?.doc_no || payment?.acc_tax_invoice_info?.doc_no}}
                                </td>
                                <td>{{payment.payment_date | thai_date: "short":"date" | dash}}</td>
                                <td class="text-right">{{payment.value_before_wht | number : '1.2-2'}}</td>
                                <td class="text-center">
                                  <div *ngIf="payment.wht_percent != 0">
                                    {{payment.wht_percent || 0}} %
                                  </div>
                                  <div *ngIf="payment.wht_percent == 0">
                                    -
                                  </div>
                                </td>
                                <td class="text-center">{{payment.tax || 0}} %</td>
                                <td class="text-right">{{payment?.grand_total | number : '1.2-2'}}</td>
                                <td>
                                    {{payment.payment_method_info.payment_method_name_th}}
                                </td>
                                <td class="text-center">
                                    <div class="text-muted" *ngIf="payment.wht_received_date || payment.wht_percent == 0">
                                      ได้รับแล้ว
                                    </div>
                                    <div class="text-danger" *ngIf="!payment.wht_received_date && payment.wht_percent !=0">
                                      ยังไม่ได้รับ
                                    </div>
                                </td>
                              </tr>
                                <tr *ngIf="payments.length == 0">
                                    <td colspan="12" class="text-center">
                                        ไม่มีข้อมูล
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
  
                    <app-paginator
                      *ngIf="userInfo$ | async"
                      [filter]="filter"
                      [reset]="reset"
                      [fetch]="load"
                      (changePage)="onChangePage($event)">
                    </app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>
