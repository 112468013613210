import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationAcceptedService {
  async create({
    quotation_info_id,
    accepted_date,
    accepted_po_no,
    note
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-accepted`, {
        quotation_info_id,
        accepted_date,
        accepted_po_no,
        note
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_accepted_id,
        quotation_info_id,
        accepted_date,
        accepted_po_no,
        note
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-accepted/${quotation_accepted_id}`, {
        quotation_info_id,
        accepted_date,
        accepted_po_no,
        note
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_accepted_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-accepted/${quotation_accepted_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-accepted`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter , order_by: 'accepted_date:asc'},
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_accepted_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-accepted/${quotation_accepted_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
