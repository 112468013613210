import Axios from "../axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class AccRecivedVoucherItemService {
  async create({
    acc_received_voucher_info_id,
    acc_document_payment_id,
    doc_type,
    doc_no
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-received-voucher-item`, {
        acc_received_voucher_info_id,
        acc_document_payment_id,
        doc_type,
        doc_no
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    acc_received_voucher_item_id,
    acc_received_voucher_info_id,
    acc_document_payment_id,
    doc_type,
    doc_no
  }) {
    let result = null;
    try {
      result = await Axios().put(`/acc-received-voucher-item/${acc_received_voucher_item_id}`, {
        acc_document_payment_id,
        acc_received_voucher_info_id,
        doc_type,
        doc_no
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_received_voucher_item_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-received-voucher-item/${acc_received_voucher_item_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-received-voucher-item`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ acc_received_voucher_item_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-received-voucher-item/${acc_received_voucher_item_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
