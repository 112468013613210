import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  Output,
  NgZone,
  Input,
  SimpleChanges,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { GETService, CarInfoService } from "src/app/services";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-car-edit',
  templateUrl: './car-edit.component.html',
  styleUrls: ['./car-edit.component.css']
})
export class CarEditComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  @Input("car") car;
  @Input("carTypes") carTypes;
  @Output() done = new EventEmitter();
  @ViewChild("closeSidebar") closeSidebar: ElementRef;

  constructor(
    private zone: NgZone,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private GETService: GETService,
    private CarInfoService: CarInfoService
  ) {}

  async setForm() {
    this.form = this.formBuilder.group({
      car_info_id:  [""],
      car_type_id: ["", [Validators.required]], 
      brand: ["", [Validators.required]], 
      model: ["", [Validators.required]],
      car_plate: ["", [Validators.required]],
    });

    this.setData();
  }

  async ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.setForm();
  }

  async setData() {
    if(this.car){
      this.form.patchValue(this.car);
    }
    this.form.disable();
  }

  async submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let response = await this.CarInfoService.update({
      ...this.form.getRawValue()
    });
    this.done.emit(response.success || response.error);
    this.closeSidebar.nativeElement.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        setTimeout(() => {
          this.setForm();
        }, 500);
      } else {
        this.form.disable();
        setTimeout(() => {
          this.setData();
        });
      }
    });
  }

  async delete(closeModalEl) {
    let response = await this.CarInfoService.delete({
      car_info_id: this.car.car_info_id,
    });
    closeModalEl.click();
    this.closeSidebar.nativeElement.click();
    this.done.emit(response.error || response.success);
  }
  enableForm() {
    this.form.enable();
  }
}
