import { Injectable } from "@angular/core";
import {
  CanActivate,
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from "@angular/router";
import { Store } from "@ngxs/store";
import { GetUserInfo } from "src/app/store/user/user.action";
import { Observable } from "rxjs";

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(private router: Router, private store: Store) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise(async (resolve) => {
      if (localStorage.getItem("token")) {
        await this.store.dispatch(new GetUserInfo()).toPromise();
  
        resolve(true);
      } else {
        this.router.navigate(["/login"], {
          queryParams: {
            return: state.url,
          },
        });
        resolve(false);
      }
    });
  }
}
