<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการรถยนต์</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/car']">ตั้งค่าระบบ</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/car']">จัดการข้อมูลรถยนต์</a>
                </li>
                <li class="breadcrumb-item active">รายการรถยนต์</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button class="btn btn-outline-primary"                     
              data-target="#create-car"
              trigger-sidebar>
            <i class="feather icon-plus-circle icon-left"></i> เพิ่มรถยนต์
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status
          *ngIf="status"
          [status]="status"
        ></app-response-status>
  
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mt-1">รายการรถ</h4>
                <div class="heading-elements">
                  <search-box (search)="search($event)"></search-box>
                </div>
              </div>
  
              <div class="card-content collapse show">
                <div class="card-body">
                  <loading *ngIf="isLoading"></loading>
                  <div class="table-responsive" *ngIf="!isLoading">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th class="w-25">ประเภท</th>
                          <th class="w-25">ยี่ห้อ</th>
                          <th>รุ่น</th>
                          <th>ทะเบียนรถ</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let car of cars">
                          <td class="text-nowrap">{{car.car_type_info?.cart_type_name_th}}</td>
                          <td>
                           {{car.brand}}
                          </td>
                          <td>{{car.model}}</td>
                          <td>{{ car.car_plate }}</td>
                          <td>
                            <button
                              type="button"
                              class="btn mr-1 mb-1 btn-primary btn-sm"
                              (click)="select(car)"
                              trigger-sidebar
                              data-target="#edit-car"
                            >
                              <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                            </button>
  
                          </td>
                        </tr>
                        <tr *ngIf="!cars.length">
                          <td colspan="5" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
  
                  <app-paginator
                    [filter]="filter"
                    [reset]="reset"
                    [fetch]="load"
                    (changePage)="onChangePage($event)"
                  ></app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <app-car-create [carTypes]="carTypes" (done)="doReset($event)">
  </app-car-create>

  <app-car-edit [car]="car" [carTypes]="carTypes" (done)="doReset($event)">
  </app-car-edit>