import { Component, OnInit } from "@angular/core";
import { Select } from "@ngxs/store";
import moment from "moment";
import { Observable } from "rxjs";
import { Standard, StandardFilter, StandardService } from "src/app/services";
import { UserInfoState } from "src/app/store/user/user.state";
declare var $: any;
@Component({
  selector: "app-standard-list",
  templateUrl: "./standard-list.component.html",
  styleUrls: ["./standard-list.component.css"],
})
export class StandardListComponent implements OnInit {
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;

  isLoading: boolean = true;
  status: string;
  standards: Standard[] = [];
  standard: Standard | null;

  load: Function = this.StandardService.load;
  filter: StandardFilter;
  reset: boolean = false;
  standardId: string;

  createStandard: boolean = false;
  constructor(public StandardService: StandardService) {
    this.userInfo$.subscribe((userInfo) => {
      if (
        userInfo.user_role_id !== "ADMIN" &&
        userInfo.emp_info?.emp_department_id !== "CALIB_DEP"
      ) {
        window.location.href = "/landing";
      }
    });
  }

  ngOnInit(): void {}

  onChangePage(standards: Standard[]) {
    this.standards = standards;
    this.isLoading = false;
  }
  create() {
    this.createStandard = true;
    setTimeout(() => {
      $("#modalStandardCreate").modal("toggle");
    }, 100);
  }
  select(standardId: string) {
    this.standardId = standardId;
  }
  edit(standard: Standard) {
    this.standard = standard;
    setTimeout(() => {
      $("#modalStandardEdit").modal("toggle");
    }, 100);
  }
  saveStandard(e) {
    if (e) {
      this.doReset(e.status.success || e.status.error);
      e.closeModal.nativeElement.click();
    }
    this.standard = null;
    this.createStandard = false;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }

  search(filter: StandardFilter) {
    this.filter = filter;
  }
  download() {
    this.StandardService.download();
  }
  async delete(closeModalEl) {
    let response = await this.StandardService.delete({
      standard_id: this.standardId,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }

  close() {
    setTimeout(() => {
      this.standard = null;
      this.createStandard = false;
    }, 100);
  }

  validateDue(date: string | null): boolean {
    return date !== null ? moment().isAfter(moment(date).endOf("day")) : false;
  }
}
