import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccCreditNoteInfoService {
  async create({
    ref_doc_type,
    ref_acc_document_info_id,
    credit_note_type,
    doc_no,
    revise_count,
    doc_date,
    customer_id,
    customer_address_id,
    doc_note,
    doc_reason,
    ref_grand_total_before_vat,
    new_amount,
    difference,
    tax,
    grand_total,
    doc_status_id,
    acc_billing_note_info_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-credit-note-info`, {
        ref_doc_type,
        ref_acc_document_info_id,
        credit_note_type,
        doc_no,
        revise_count,
        doc_date,
        customer_id,
        customer_address_id,
        doc_note,
        doc_reason,
        ref_grand_total_before_vat,
        new_amount,
        difference,
        tax: parseFloat(tax),
        grand_total,
        doc_status_id,
        acc_billing_note_info_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      acc_credit_note_info_id,
      ref_doc_type,
      ref_acc_document_info_id,
      credit_note_type,
      doc_no,
      revise_count,
      doc_date,
      customer_id,
      customer_address_id,
      doc_note,
      doc_reason,
      ref_grand_total_before_vat,
      new_amount,
      difference,
      tax,
      grand_total,
      doc_status_id,
      acc_billing_note_info_id
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/acc-credit-note-info/${acc_credit_note_info_id}`, {
        ref_doc_type,
        ref_acc_document_info_id,
        credit_note_type,
        doc_no,
        revise_count,
        doc_date,
        customer_id,
        customer_address_id,
        doc_note,
        doc_reason,
        ref_grand_total_before_vat,
        new_amount,
        difference,
        tax: parseFloat(tax),
        grand_total,
        doc_status_id,
        acc_billing_note_info_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_credit_note_info_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-credit-note-info/${acc_credit_note_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-credit-note-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ acc_credit_note_info_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-credit-note-info/${acc_credit_note_info_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  async getCode(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-credit-note-info-code`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
