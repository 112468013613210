import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class MITEquipmentService {
  async create({
    equipment_name,
    mit_scope_id,
    // mit_method_id,
    // mit_cp_id,
    service_location,
    note_1,
    note_2,
    mit_cp_list,
    lab_mit_cp_list,
    onsite_mit_cp_list
  }) {
    let result = null;
    try {
      result = await Axios().post(`/mit-equipment`, {
        equipment_name,
        mit_scope_id,
        // mit_method_id,
        // mit_cp_id,
        service_location,
        note_1,
        note_2,
        mit_cp_list,
        lab_mit_cp_list,
        onsite_mit_cp_list
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    mit_equipment_id,
    equipment_name,
    mit_scope_id,
    // mit_method_id,
    // mit_cp_id,
    service_location,
    note_1,
    note_2,
    mit_cp_list,
    lab_mit_cp_list,
    onsite_mit_cp_list
  }) {
    let result = null;
    try {
      result = await Axios().put(`/mit-equipment/${mit_equipment_id}`, {
        equipment_name,
        mit_scope_id,
        // mit_method_id,
        // mit_cp_id,
        service_location,
        note_1,
        note_2,
        mit_cp_list,
        lab_mit_cp_list,
        onsite_mit_cp_list
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ mit_equipment_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/mit-equipment/${mit_equipment_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/mit-equipment`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ mit_equipment_id }) {
    let result = null;
    try {
      result = await Axios().get(`/mit-equipment/${mit_equipment_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
