<loading *ngIf="pageLoad"></loading>
<div class="card mb-0" [hidden]="pageLoad">
    <div class="card-content collapse show">
        <div class="card-body p-0">

            <div class="d-flex justify-content-between mb-1">
                <div>
                    <button
                    *ngIf="!isTranDep && pageType !='show' && (jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' && jobOrderInfo?.doc_status_id !== 'CONFIRMED')"
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#work-order-non-item-create"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบเสนอราคา
                    </button>
                </div>
            </div>

            <div class="table-responsive" style="max-height: 600px;">
                <table class="table table-bordered">
                    <thead> 
                        <tr>
                            <th class="text-center">ลำดับ</th>
                            <th>
                               เลขที่ใบเสนอราคา
                            </th>
                            <th>
                                รายการ
                             </th>
                             <th>
                              คำอธิบาย
                            </th>
                            <th class="text-right" *ngIf="!isCalibration">
                              จำนวน
                            </th>
                            <th class="text-right" *ngIf="!isCalibration && !isTranDep">
                              ราคา
                            </th>
                            <th>
                                สถานะ
                            </th>
                            <th *ngIf="!isTranDep && pageType !='show' 
                            && ((template == 'PRD' 
                            && (jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' || jobOrderInfo?.doc_status_id !== 'CONFIRMED')) ||
                            (template == 'TRN' 
                            && (jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' && isSale)))">
                                action
                                <div *ngIf="pageType !='show' && template == 'PRD' && jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM'">
                                  <button
                                    (click)="isConfirmAll = true"
                                    data-target="#confirm-calibration-item"
                                    data-toggle="modal"
                                    type="button"
                                    class="btn mr-1 mt-1 mb-1 btn-success btn-sm"
                                  > <i class="fa fa-check-circle-o icon-left"></i> ยืนยันให้บริการได้ทั้งหมด
                                  </button>
                                </div>
                                <div *ngIf="pageType !='show' && template == 'PRD' && jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM'">
                                  <button
                                      (click)="isRejectAll = true"
                                      data-target="#reject-product-item"
                                      data-toggle="modal"
                                      type="button"
                                      class="btn mr-1 btn-danger btn-sm"
                                  > <i class="fa fa-times-circle-o icon-left"></i> ไม่สามารถให้บริการได้ทั้งหมด
                                  </button>
                                </div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of jobOrderItems; let i = index;">
                            <td class="text-center">
                                {{i + 1}}
                            </td>
                            <td>
                                {{item.quotation_info?.doc_no}}
                            </td>
                            <td>
                                {{item.quotation_item_info?.product_title}}
                            </td>
                            <td>
                              {{item.quotation_item_info?.product_description | dash}}
                            </td>
                            <td class="text-right" *ngIf="!isCalibration">
                                {{item.quotation_item_info.quantity}}
                            </td>
                            <td class="text-right" *ngIf="!isCalibration && !isTranDep">
                                {{CalculateFunctionService.sumProduct(item.quotation_item_info) | number : '1.2-2'}}
                            </td>   
                            <td class="text-nowrap"> 
                              <div [ngClass]="{
                                'text-success': (item?.item_status_id == 'CRF-CONFIRMED' || item?.item_status_id == 'CRF-COMPLETED' || item?.item_status_id == 'PREPARE_FOR_SHIP'),
                                'text-danger': (item?.item_status_id == 'CRF-REJECTED' || item?.item_status_id == 'CRF-CANCELED') }">
                                {{getStatus(item.item_status_id)}}
                                <span *ngIf="item?.item_status_id == 'CRF-REJECTED'" data-toggle="popover" data-popover="true" [attr.data-content]="setContent(item, 'confirm')">
                                  <i class="fa fa-comment pointer" aria-hidden="true"></i>
                                </span>
                                <span *ngIf="item?.item_status_id == 'CRF-CANCELED' && template == 'PRD' && item.job_order_status_work_info.length > 0" data-toggle="popover" data-popover="true" [attr.data-content]="setContent(item, 'work')">
                                  <i class="fa fa-comment pointer" aria-hidden="true"></i>
                                </span>
                              </div>
                              <span class="text-nowrap" *ngIf="item.quotation_item_info?.cost_of_sales != null && !isTranDep">
                                (ต้นทุน: {{utils.convertStingToNumber(item.quotation_item_info?.cost_of_sales) | number : '1.2-2' }} บาท)
                              </span>
                            </td>   
                            <td *ngIf="!isTranDep && pageType !='show' 
                            && ((template == 'PRD' 
                            && (jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' || jobOrderInfo?.doc_status_id !== 'CONFIRMED')) ||
                            (template == 'TRN' 
                            && (jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' && isSale)))">
                                <button
                                  (click)="select(item)"
                                  *ngIf="template == 'PRD' && jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM'"
                                  data-target="#confirm-calibration-item"
                                  data-toggle="modal"
                                  type="button"
                                  class="btn mr-1 mb-1 btn-success btn-sm"
                                > <i class="fa fa-check-circle-o icon-left"></i> ยืนยันรายการ
                                </button>
                                <button
                                    (click)="select(item)"
                                    data-target="#reject-product-item"
                                    data-toggle="modal"
                                    *ngIf="template == 'PRD' && jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM'"
                                    type="button"
                                    class="btn mr-1 mb-1 btn-danger btn-sm"
                                > <i class="fa fa-times-circle-o icon-left"></i> ไม่อนุมัติ
                                </button>
                                  <button
                                    (click)="select(item)"
                                    data-target="#confirm-calibration-item"
                                    data-toggle="modal"
                                    *ngIf="template == 'PRD' && jobOrderInfo?.doc_status_id === 'CONFIRMED' && !isSale"
                                    type="button"
                                    class="btn mr-1 mb-1 btn-success btn-sm"
                                > <i class="fa fa-check-circle-o icon-left"></i> เสร็จสิ้น
                                </button>
                                <button
                                    (click)="select(item)"
                                    data-target="#reject-product-item"
                                    data-toggle="modal"
                                    *ngIf="template == 'PRD' && jobOrderInfo?.doc_status_id === 'CONFIRMED' && !isSale"
                                    type="button"
                                    class="btn mr-1 mb-1 btn-danger btn-sm"
                                > <i class="fa fa-times-circle-o icon-left"></i> ยกเลิก
                                </button>
                                <button
                                    (click)="cancel(item)"
                                    *ngIf="jobOrderInfo?.doc_status_id === 'CONFIRMED' && isSale"
                                    type="button"
                                    class="btn mr-1 mb-1 btn-danger btn-sm text-nowrap"
                                > <i class="fa fa-times-circle-o icon-left"></i> ยกเลิก
                                </button>
                                <button
                                    type="button"
                                    *ngIf="(jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' && jobOrderInfo?.doc_status_id !== 'CONFIRMED')"
                                    data-target="#modalConfirmDelMsgReceiveItem"
                                    data-toggle="modal"
                                    (click)="select(item)"
                                    class="btn mr-1 mb-1 btn-danger btn-sm"
                                > <i class="fa fa-trash icon-left"></i> ลบ
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="jobOrderItems.length === 0">
                            <td [attr.colspan]="pageType =='show'? 7:8" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <span class="pl-1 text-danger w-100">
              * ราคา Lock เมื่อ มีการบันทึก CP นอก Scope หรือ มีการเปลี่ยนแปลง Point แล้วราคาใหม่สูงกว่าราคาเดิม
            </span>
            <span class="pl-1 text-danger w-100" *ngIf="(submitted && !canConfirm) && jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM' && template=='PRD'">
              โปรดยืนยันรายการ
            </span>
            <span class="pl-1 text-danger w-100" *ngIf="(submitted && !canCompleted) && jobOrderInfo?.doc_status_id === 'CONFIRMED' && template=='PRD'">
                โปรดบันทึกรายการ
            </span>
        </div>
    </div>
</div>

<app-confirm-delete
  [id]="'modalConfirmDelMsgReceiveItem'"
  (save)="delete($event)"
></app-confirm-delete>

<app-work-order-non-lab-item-create 
*ngIf="customer_info && itemStatus && (next_status != 'confirm' && next_status != 'complete')" 
[jobOrderItems]="jobOrderItems"
[customer_info]="customer_info" 
[itemStatus]="itemStatus" 
(done)="submitJobOrderItem($event);">
</app-work-order-non-lab-item-create>

<div
  modal
  class="modal fade text-left"
  id="reject-product-item"
  tabindex="-1"
  (close)="cancelForm()"
  role="dialog"
  aria-labelledby="rejectProductItem"
  aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="rejectProductItem">
          {{jobOrderInfo?.doc_status_id == 'CONFIRMED'?'ยกเลิก':'ไม่สามารถให้บริการได้' }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="cancelForm()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div class="form-body">

              <div class="mt-1">
                <form-group
                [control]="form.controls.reject_reason"
                [submitted]="isSubmit"
                >
                  <label for="template">
                    สาเหตุ <span class="danger">*</span>
                  </label>
                  <textarea class="form-control" name="reject_reason" id="save.reject_reason" rows="4" formControlName="reject_reason"></textarea>
                </form-group>

              </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          aria-label="Close"
          (click)="cancelForm()"
          #closeRejectBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="saveForm()">
          <i class="fa fa-check icon-left"></i>ยืนยัน
        </button>
      </div>
    </div>
  </div>
</div>

<app-confirm-calibration-item 
[jobOrder]="jobOrder"
[isConfirmAll]="isConfirmAll"
(done)="confirmAndComplateItemCodePRD($event);">
</app-confirm-calibration-item>