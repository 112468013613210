import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { 
  CustomerAddressService, 
  CustomerContactService, 
} from 'src/app/services';
import { 
  DownloadFileService,
  NgSelect2Customer,
  QuotationProductItemService,
  QuotationUtilsService 
} from 'src/app/services/quotations';
import { 
  JobOrderProductItemService,
  JobOrderContactService,
  JobOrderFileService,
  JobOrderInfoService 
} from 'src/app/services/job-orders';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Observable, Subscription } from 'rxjs';
import { Location } from "@angular/common";

@Component({
  selector: 'app-work-order-non-lab-show',
  templateUrl: './work-order-non-lab-show.component.html',
  styleUrls: ['./work-order-non-lab-show.component.css']
})
export class WorkOrderNonLabShowComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  pageLoad: boolean = true;
  resetdate: boolean = true;
  //array
  files: any = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerContactList: any = [];
  //dropdown
  chargeReportAddressList = [];
  sendReportAddressList = [];
  addressList = [];
  //string
  job_order_info_id: string = "";
  template: string = "";
  customer_level_id: string = "";
  //any
  status: any;
  customer_info = null;
  jobOrderInfo = null;
  jobOrderProductItem = null;

  isSalesManager = false;
  isSalesCoordinator = false;
  isSales:boolean = false;
  isAccDep:boolean = false;
  isPuchase:boolean = false;
  isCalibration:boolean = false;
  isTranDep:boolean = false;
  isMessenger:boolean = false;
  
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;
  userInfo = null;

  constructor(
    private fbd: FormBuilder,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private JobOrderInfoService: JobOrderInfoService,
    private JobOrderContactService: JobOrderContactService,
    private JobOrderFileService: JobOrderFileService,
    private DownloadFileService: DownloadFileService,
    private JobOrderProductItemService: JobOrderProductItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public _utils : QuotationUtilsService,
    public _ngSelect2Customer: NgSelect2Customer,
  ) { 
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
    });
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
  }


  async ngOnInit() {
    this._ngSelect2Customer.initPopOver();
    this.createForm();
    
    await this.setData();
  }

  ngOnDestroy() {
    this._ngSelect2Customer.clearPopOver();
  }

  async setData(){
    this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      this.userInfo = userInfo;
      
      this.isSalesManager =
      userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP") &&
      userInfo?.emp_info?.emp_level_id === "MANAGER";
      this.isSalesCoordinator =
      userInfo?.emp_info?.emp_level_id.startsWith("OPERATION") &&
      userInfo?.emp_info?.emp_position_info?.emp_position_name_en.includes("Sale coordinator");
      this.isSales = 
      userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP");
      this.isPuchase =
      userInfo?.emp_info?.emp_department_id.startsWith("PUCHASE_DEP");
      this.isCalibration =
      userInfo?.emp_info?.emp_department_id.startsWith("CALIB_DEP");
      this.isTranDep =
      userInfo?.emp_info?.emp_department_id.startsWith("TRAN_DEP");
      this.isAccDep = 
      userInfo?.emp_info?.emp_department_id.startsWith("ACC_DEP");
      this.isMessenger = 
      userInfo?.emp_info?.emp_position_info?.emp_position_id.startsWith("TRAN_DEP-03");

      await this.JobOrderInfoService.getById({job_order_info_id: this.job_order_info_id})
      .then(async res=>{
        let resultData = res.resultData || {};
        this.jobOrderInfo = res.resultData || {};

        this.customer_level_id = resultData.customer_info.customer_level_id;

        await this.CustomerAddressService.load(null, {  customer_id: resultData.customer_info.customer_id }).then((res)=>{
          if(res.success){
  
            this.customerAddressList = res.resultData || [];
            this.customerAddressList = this.customerAddressList.map(elem=>{
              return this._utils.genCustomerAddressList(elem);
            })
          }
        })
  
        let data = {
          ...resultData,
          credit_term_day: resultData?.customer_info?.credit_term_day || 0,
          customer_name: `[${resultData?.customer_info?.customer_code || ''}] ${resultData?.customer_info?.company_name || ''} ${resultData.customer_info?.branch?" (" + resultData.customer_info?.branch + ")": ""}`,
          service_time: resultData?.service_time? resultData?.service_time?.split(":")[0]+ ':'+ resultData?.service_time?.split(":")[1]: "00:00"
        }
        this.form.patchValue(data);
        this.form.controls['doc_date'].setValue(moment(resultData.doc_date).format('YYYY-MM-DD'));
        this.form.controls['pickup_date'].setValue(moment(resultData.pickup_date).format('YYYY-MM-DD'));
        this.form.controls['return_date'].setValue(moment(resultData.return_date).format('YYYY-MM-DD'));
        if(resultData.real_return_date){
          this.form.controls['real_return_date'].setValue(moment(resultData.real_return_date).format('YYYY-MM-DD'));
        }
        
        await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: resultData.customer_id})
        .then(async (res) => {
          let resultData = res.resultData || [];
  
          this.chargeReportAddressList = resultData.filter(item=> item.customer_address_type_id === 3).map(elem => (
            {
              id: elem.customer_address_id,
              text: elem.address_name +' '+ this._utils.genAddress(elem),
            } 
          ));

          this.sendReportAddressList =  resultData.filter(item=> item.customer_address_type_id === 6).map(elem => (
            {
              id: elem.customer_address_id,
              text: elem.address_name +' '+ this._utils.genAddress(elem),
            } 
          ));

          this.addressList =  resultData.map(elem => (
            {
              id: elem.customer_address_id,
              text: elem.address_name +' '+ this._utils.genAddress(elem),
            } 
          ));
        });
  
        if(resultData.customer_address_id){
          let response = this.customerAddressList.find(item=> item.id == resultData.customer_address_id);
          if(response){
            this.form.controls['customer_address'].setValue(response.address_name); 
            this.form.controls['mit_iplus_code'].setValue(response.data.mit_iplus_code);   
          }
        }
        await this.CustomerContactService.load(null, {
          order_by: "isMaster:desc",
          customer_id: resultData.customer_info.customer_id
        }).then((res) => {
          this.customerContactList = res.resultData || [];
          this.customerContactList = this.customerContactList.map(function(elem) {
            return {
              id: elem.customer_contact_id,
              customer_id: elem.customer_id,
              text: `${elem.firstname} ${elem.lastname}`,
              data: elem
            }
          });
        });
  
        await this.JobOrderContactService.load(null, { job_order_info_id: this.job_order_info_id }).then((res) => {
          let ids = res.resultData.map(v=>{
            return v.customer_contact_id;
          })
          this.form.controls['customer_contact_ids'].setValue(ids);
        });
        
        await this.JobOrderFileService.load(null, { job_order_info_id: this.job_order_info_id  }).then((res)=>{
          this.files = res.resultData || [];
        })
        this.reset();
        this.pageLoad = false;
      })
    });
  }

  reset(){
    this.resetdate = false;
    setTimeout(() => {
      this.resetdate = true;
    }, 100);
  }

  createForm(){
    this.form = this.fbd.group({
      customer_id: [""],
      customer_name: [""],
      customer_address_id: [""],
      customer_address: [""],
      mit_iplus_code: [""],
      pickup_date: [""],
      return_date: [""],
      real_return_date: [""],
      doc_status_id: [""],
      charge_address_id: [""],
      service_time: ["00:00"],

      doc_no: [""],
      doc_date: [""],
      job_order_type: [""],
      special_condition: [""],
      note: [""],
      accessory: [""],
      location: [""],
      calibration_address_id: [],
      no_of_day: [""],
      no_of_people: [""],

      customer_contact_ids: [[], [Validators.required]],
      credit_term_day: [""],
      sales_user_id: [""],
    });

    this.form.disable();
  }

  async getJobOrderItems(event){
    this.jobOrderProductItem = event;
  }

  async cancel(jobOrder){
    if(jobOrder){
      let jobInfoResponse = await this.JobOrderInfoService.update({
        ...jobOrder,
        doc_status_id: 'CANCELED'
      });
      if(jobInfoResponse.success){
        let promises = [];

        promises.push(
          await Promise.all(this.jobOrderProductItem?.jobOrderItems.map(async (item) =>{
            let jobOrderProductItemResponse = await this.JobOrderProductItemService
            .update({
              ...item,
              item_status_id: 'CRF-CANCELED',
              job_order_info_id: jobInfoResponse.resultData.job_order_info_id,
            });
            await this.updateProductItem(item, jobInfoResponse, jobOrderProductItemResponse);
          }))
        )
        promises.push(
          await this.router.navigateByUrl("/work-order", {
            state: {
              status: jobInfoResponse.success,
            },
          })
        )
        await Promise.all(promises);
      }
    }
  }

  async saveAsReturn(event){
    if(event){
      let jobInfoResponse = await this.JobOrderInfoService.update({
        ...this.jobOrderInfo,
        doc_status_id: 'RETURNED_SUCCESS',
        returned_customer_signature: event,
        returned_customer_signature_date: new Date()
      });
      if(jobInfoResponse.success){
        //////
        let promises = [];

        promises.push(
          await Promise.all(this.jobOrderProductItem?.jobOrderItems.map(async (item) =>{
            let jobOrderProductItemResponse = await this.JobOrderProductItemService
            .update({
              ...item,
              item_status_id: 'RETURNED',
              job_order_info_id: jobInfoResponse.resultData.job_order_info_id,
            });
            await this.updateProductItem(item, jobInfoResponse, jobOrderProductItemResponse);
          }))
        )
        if(this.isMessenger){
         promises.push(
          await this.router.navigateByUrl("/waiting-for-delivery", {
            state: {
              status: jobInfoResponse.success,
            },
          })
         )
        }else{
          promises.push(
            await this.router.navigateByUrl("/work-order", {
              state: {
                status: jobInfoResponse.success,
              },
            })
          )
        }
        await Promise.all(promises);
      }
    }
  }

  async updateProductItem(item, jobOrderInfoResponse, jobOrderProductItem){
    if(item.quotation_item_info){
      let data = {
        ...item.quotation_item_info,
        // Update
        job_order_info_id: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.job_order_info_id: "",
        job_order_info_status: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.doc_status_id: "",
        job_order_info_doc_no: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.doc_no: "",
        job_order_info_type: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.job_order_type: "",

        job_order_product_item_status: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderProductItem.resultData.item_status_id: "",
        job_order_product_item_id: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderProductItem.resultData.job_order_product_item_id: "",
        lock_invoice_at: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderProductItem.resultData.lock_invoice_at: "",
        unlock_invoice_at: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderProductItem.resultData.unlock_invoice_at: "",
      }
      await this.QuotationProductItemService.update(data);
    }
  }

  edit(jobOrder){
    this.router.navigateByUrl("/work-order/non-calibration/"+ jobOrder.job_order_info_id +"/edit?template=" +jobOrder.job_order_type);
  }
  
  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }

  confirmJoborder(jobOrder, status = ''){
    if(jobOrder.job_order_type == "TRN" || jobOrder.job_order_type == "PRD"){
      this.router.navigateByUrl("/work-order/non-calibration/"+ jobOrder.job_order_info_id +"/edit?template=" +jobOrder.job_order_type +"&&status=" + status);
    }else{
      this.router.navigateByUrl("/work-order/calibration/"+ jobOrder.job_order_info_id  +"/edit?template=" +jobOrder.job_order_type +"&&status=" + status);
    }
  }

  openPdf(jobOrder){
    let url = null;
    if(['ICL','ICS'].includes(jobOrder.job_order_type)){
      url = this.router.createUrlTree(['/job-order-document/icl-ics/'+ jobOrder.job_order_info_id])
    }else if(['ECL','ECS'].includes(jobOrder.job_order_type)){
      url = this.router.createUrlTree(['/job-order-document/ecl-ecs/'+ jobOrder.job_order_info_id])
    }else if(jobOrder.job_order_type == "PRD"){
      url = this.router.createUrlTree(['/job-order-document/purchase/'+ jobOrder.job_order_info_id])
    }

    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  openProductPdf(jobOrder){
    let url = null;
    url = this.router.createUrlTree(['/job-order-document/product/'+ jobOrder.job_order_info_id])
    if(url){
      window.open(url.toString(), '_blank');
    }
  }


  openServiceReportPdf(jobOrder){
    let url = null;
    url = this.router.createUrlTree(['/job-order-document/service-report/'+ jobOrder.job_order_info_id])
    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  editCert(jobOrder, status = 'complate'){
    if(jobOrder.job_order_type == "PRD"){
      this.router.navigateByUrl("/work-order/non-calibration/"+ jobOrder.job_order_info_id +"/edit?template=" +jobOrder.job_order_type +"&&status=" + status);
    }
  }

  back(): void {
    this.location.back();
  }
}
