import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MITMethodService } from "src/app/services";

@Component({
  selector: "app-method-list",
  templateUrl: "./method-list.component.html",
  styleUrls: ["./method-list.component.css"],
})
export class MethodListComponent implements OnInit {
  methods = [];
  status: string;
  mit_method_id: string;
  reset: boolean = false;
  filter = {};
  isLoading = true;

  load: Function = this.MITMethodService.load;
  constructor(
    private router: Router,
    private MITMethodService: MITMethodService
  ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.methods = [];
  }
  onChangePage(methods: Array<any>) {
    this.methods = methods;
    this.isLoading = false;
  }
  select(mit_method_id: string) {
    this.mit_method_id = mit_method_id;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
      this.mit_method_id = null;
    }, 50);
  }
  search(filter) {
    this.filter = {
      method_name: filter.search_all
    };
  }
  async delete(closeModalEl) {
    if(!this.mit_method_id) return

    let response = await this.MITMethodService.delete({
      mit_method_id: this.mit_method_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }
}
