<div class="card" *ngIf="pageLoad">
    <div class="card-content collapse show">
        <div class="card-body col-11">
          <p>Remark {{note_type=='standard_in'? 1: 2}}</p>
          
            <div class="table-responsive">
              <table class="table table-striped table-bordered" >
                <thead>
                  <tr>
                      <th class="text-center" style="width: 7%;">ลำดับ</th>
                      <th>หมายเหตุ</th>
                  </tr>
                </thead>
                <tbody >
                    <tr *ngFor="let item of noteList; let i = index;">
                        <td class="text-center">
                            {{i+1}}
                        </td>
                        <td>
                          {{item.quotation_note}}
                        </td>
                    </tr>
                    <tr *ngIf="noteList.length === 0">
                        <td colspan="2" class="text-center">ไม่มีข้อมูล</td>
                    </tr>
                </tbody>
              </table>
            </div>
        </div>
    </div>
</div>
