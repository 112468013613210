<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการใบเสนอราคา</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/quotation']">จัดการใบเสนอราคา</a>
                </li>
                <li class="breadcrumb-item active">รายการใบเสนอราคา</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button class="btn btn-outline-primary" [routerLink]="['/quotation/create']">
              <i class="feather icon-plus-circle icon-left"></i> สร้างใบเสนอราคา
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <h4 class="card-title mt-1 col-12 col-sm-4 col-md-6 col-lg-9">รายการใบเสนอราคา</h4>
                  <span class="col-12 col-sm-8 col-md-6 col-lg-3">
                    <search-box (search)="search($event)" [placeholder]="'ค้นหาเลขที่เอกสาร/รหัส/ชื่อลูกค้า'" [search_all]="params?.search_all"></search-box>
                  </span>
                </div>
              </div>
  
              <div class="card-content collapse show">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-12 col-sm-12 col-md-8 col-lg-9 align-self-end">
                          <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "!params?.doc_status_id" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchStatus()">ทั้งหมด</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='DRAFT'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchStatus('DRAFT')">ร่าง ({{number_draft_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WAITING_FOR_APPROVE'" id="pill3" data-toggle="pill" href="#pill3" aria-expanded="false" (click)="searchStatus('WAITING_FOR_APPROVE')">รออนุมัติ ({{number_waiting_for_approve_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WAITTING_FOR_ACCEPT'" id="pill4" data-toggle="pill" href="#pill4" aria-expanded="false" (click)="searchStatus('WAITTING_FOR_ACCEPT')">รอตอบรับ ({{number_waiting_for_accept_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='EXPIRED'" id="pill5" data-toggle="pill" href="#pill5" aria-expanded="false" (click)="searchStatus('EXPIRED')">พ้นกำหนด ({{number_expired}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='REVISE'" id="pill20" data-toggle="pill" href="#pill20" aria-expanded="false" (click)="searchStatus('REVISE')">Revise ({{number_revise_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='REVISE_WAITING_FOR_APPROVE'" id="pill21" data-toggle="pill" href="#pill21" aria-expanded="false" (click)="searchStatus('REVISE_WAITING_FOR_APPROVE')">Revise รออนุมัติ ({{number_revise_approve_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='ACCEPTED' && params?.is_no_file_attached=='true'" id="pill22" data-toggle="pill" href="#pill22" aria-expanded="false" (click)="searchStatus('NO_PO')">ยอมรับ (ไม่ได้แนบ PO) ({{number_no_po_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='ACCEPTED' && !params?.is_no_file_attached" id="pill6" data-toggle="pill" href="#pill6" aria-expanded="false" (click)="searchStatus('ACCEPTED')">ยอมรับ ({{number_accepted_status}})</a>
                            </li>
                            <li class="nav-item border-light">
                                <a class="nav-link text-light py-0" [class.active] = "params?.doc_status_id=='VOIDED'" id="pill7" data-toggle="pill" href="#pill7" aria-expanded="false" (click)="searchStatus('VOIDED')">ยกเลิก ({{number_voided_status}})</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-4 col-lg-3" *ngIf="(userInfo$ | async)?.is_sales_supervisor || (userInfo$ | async)?.is_co_sales_supervisor || (userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales">
                          <fieldset class="form-group">
                            <ng-select2
                            [options]="
                            {
                              language: _UtilsService.select2OptionsLanguage()
                            }"
                            [data]="employeeList"
                            [value]="params?.user_id"
                            placeholder="พนักงานขาย : ทั้งหมด"
                            (valueChanged)="filterByUserId($event)"
                            [allowClear]="true"
                            >
                          </ng-select2>
                          </fieldset>
                        </div>
                    </div>
                    <loading *ngIf="isLoading"></loading>
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                <th class="text-center">เลขที่เอกสาร</th>
                                <th class="w-25">ชื่อลูกค้า</th>
                                <th>วันที่ออก</th>
                                <th>ใช้ได้ถึง</th>
                                <th class="text-right">มูลค่าก่อน Vat (บาท)</th>
                                <th class="text-center">สถานะ</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="quotations">
                                <tr *ngFor="let quotation of quotations; let i = index">
                                    <th [ngClass]="{'text-blue': quotation.accepted_date && quotation.accepted_doc_path, 'text-danger' : quotation.accepted_date && !quotation.accepted_doc_path }">{{quotation.doc_no}}</th>
                                    <td>
                                      <div>
                                        [{{quotation?.customer_info?.customer_code}}] {{quotation?.customer_info?.company_name}}
                                        <br>
                                        <small class="text-muted">
                                          ออกเอกสารในนาม: {{quotation.customer_address_info?.address_name || '-'}}  (iplus: {{ quotation.customer_address_info?.mit_iplus_code}})
                                        </small>
                                        <br>
                                        <small class="text-muted">
                                          พนักงานขาย: {{quotation.sales_assignment_info?.user_info?.firstname_th}} {{quotation.sales_assignment_info?.user_info?.lastname_th || '-'}}
                                        </small>
                                      </div>
                                     
                                    </td>
                                    <td>{{quotation.doc_date | thai_date: "short":"date" | dash }}</td>
                                    <td>{{quotation.doc_validdate | thai_date: "short":"date" | dash}}</td>
                                    <td class="text-right text-nowrap">
                                      <div>
                                        {{(quotation.grand_total * 100) /107 | number : '1.2-2'}}
                                      </div>
                                    </td>
                                    <td class="text-center text-nowrap">{{quotation.doc_status_info.document_status_name_th}}</td>
                                    <td>
                                        <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm"
                                        [routerLink]="[
                                          '/quotation/' + quotation.quotation_info_id + '/show'
                                        ]">
                                          <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                        </a>
                                        
                                        <a 
                                          *ngIf="quotation.sales_assignment_info && ((userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales  || (userInfo$ | async)?.is_sales_supervisor  || (userInfo$ | async)?.is_co_sales_supervisor 
                                          ||  quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                                          type="button"
                                          [routerLink]="[
                                          '/quotation/' + quotation.quotation_info_id + '/edit'
                                          ]"
                                          [queryParams]="{action:'copy'}"
                                          class="btn mr-1 mb-1 btn-light btn-sm">
                                          <i class="fa fa-copy icon-left"></i> คัดลอก
                                        </a>
                                        <a 
                                          *ngIf="quotation.doc_status_id != 'VOIDED' 
                                          && ((userInfo$ | async)?.is_sales_manager  || (userInfo$ | async)?.is_co_sales  || (userInfo$ | async)?.is_sales_supervisor || (userInfo$ | async)?.is_co_sales_supervisor
                                          ||  quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                                          type="button" class="btn mr-1 mb-1 btn-blue btn-sm"
                                          target="_blank"
                                          [routerLink]="[
                                          '/document/' + quotation.quotation_info_id
                                          ]">
                                          <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                                        </a>
                                        <button
                                         *ngIf="(quotation.doc_status_id === 'DRAFT' || quotation.doc_status_id === 'WAITTING_FOR_ACCEPT')
                                         && ((userInfo$ | async)?.is_sales_manager  || (userInfo$ | async)?.is_co_sales_supervisor 
                                         || ((userInfo$ | async)?.is_co_sales  && quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)
                                         ||  quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                                          type="button"
                                          class="btn mr-1 mb-1 btn-danger btn-sm"
                                          data-target="#quotation-confirm-voided"
                                          data-toggle="modal"
                                          (click)="select(quotation)"
                                        >
                                        <i class="fa fa-times icon-left"></i> ยกเลิก
                                      </button>
                                    </td>
                                </tr>
                                <tr *ngIf="quotations.length === 0">
                                    <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
  
                    <app-paginator
                      *ngIf="(userInfo$ | async) && initialPage"
                      [filter]="filter"
                      [reset]="reset"
                      [fetch]="load"
                      [initialPage]="initialPage"
                      (filterLimit)="getFilterLimit($event)"
                      (changePage)="onChangePage($event)">
                    </app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>

<app-quotation-confirm-voided 
  *ngIf="quotation"
  (save)="onSaveConfirmVoided($event)" 
  [quotation]="quotation">
</app-quotation-confirm-voided>

