<div class="app-content content" *ngIf="isReady">
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">แก้ไขข้อมูลสินค้า</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#"     [routerLink]="['/product']">จัดการข้อมูลสินค้า</a>
              </li>
              <li class="breadcrumb-item active">แก้ไขข้อมูลสินค้า</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            [routerLink]="['/product']"
            type="button"
            class="btn btn-warning btn-min-width mr-1"
          >
            <i class="fa fa-times"></i> ยกเลิก
          </button>
          <button
            (click)="submit()"
            type="button"
            class="btn btn-primary btn-min-width"
          >
            <i class="fa fa-save"></i> บันทึก
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>
      <section class="basic-elements">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body">
                  <!-- <ul class="nav nav-tabs mb-2" role="tablist">
                    <li class="nav-item">
                      <a
                        class="nav-link d-flex align-items-center active"
                        id="product-info-tab"
                        data-toggle="tab"
                        href="#product-info"
                        aria-controls="account"
                        role="tab"
                        aria-selected="true"
                      >
                        <span class="d-block">ข้อมูลสินค้า</span>
                      </a>
                    </li>
                    <li
                      class="nav-item"
                      *ngIf="this.form.value.product_type_id === CALIBRATION"
                    >
                      <a
                        class="nav-link d-flex align-items-center"
                        id="service-info-tab"
                        data-toggle="tab"
                        href="#service-info"
                        aria-controls="information"
                        role="tab"
                        aria-selected="false"
                      >
                        <span class="d-block">ข้อมูลบริการสอบเทียบ</span>
                      </a>
                    </li>
                  </ul> -->
                  <!-- <div class="tab-content">
                    <div
                      class="tab-pane active"
                      id="product-info"
                      aria-labelledby="product-info-tab"
                      role="tabpanel"
                    > -->
                  <form
                    autocomplete="off"
                    class="form"
                    role="form"
                    action="/dashboard/user-accounts"
                    method="post"
                    [formGroup]="form"
                  >
                    <div class="form-body">
                      <h5 class="text-bold-600 mb-1">ข้อมูลสินค้า</h5>
                      <div class="row">
                        <div class="col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.product_type_id"
                            [submitted]="submitted"
                          >
                            <label>ประเภท <span class="danger">*</span></label>
                            <div class="input-group">
                              <div
                                class="d-inline-block custom-control custom-radio mr-1"
                                *ngFor="let product_type of productTypes"
                              >
                                <input
                                  type="radio"
                                  [value]="product_type.product_type_id"
                                  formControlName="product_type_id"
                                  class="custom-control-input"
                                  [id]="
                                    'prudct-type-' +
                                    product_type.product_type_id
                                  "
                                />
                                <label
                                  class="custom-control-label"
                                  [for]="
                                    'prudct-type-' +
                                    product_type.product_type_id
                                  "
                                  >{{ product_type.product_type_name }}</label
                                >
                              </div>
                            </div>
                          </form-group>

                          <form-group
                            [control]="form.controls.product_code"
                            [submitted]="submitted"
                          >
                            <label for="product_code"
                              >รหัสสินค้า <span class="danger">*</span></label
                            >
                            <input
                              type="text"
                              id="product_code"
                              class="form-control"
                              placeholder="รหัสสินค้า"
                              formControlName="product_code"
                            />
                          </form-group>

                          <form-group
                            [control]="form.controls.product_title"
                            [submitted]="submitted"
                          >
                            <label for="product_title">
                              ชื่อสินค้า <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="product_title"
                              class="form-control"
                              placeholder="ชื่อสินค้า"
                              formControlName="product_title"
                            />
                          </form-group>

                          <form-group
                            [control]="form.controls.product_description"
                            [submitted]="submitted"
                          >
                            <label for="product_description">คำอธิบาย</label>
                            <textarea
                              id="product_description"
                              class="form-control"
                              rows="3"
                              formControlName="product_description"
                            >
                            </textarea>
                          </form-group>
                        </div>
                      </div>

                      <div>
                        <h5 class="text-bold-600 mb-1">ราคาและหน่วยนับ</h5>
                        <div class="row">
                          <div class="col-xl-4 col-lg-6 col-md-12">
                            <form-group
                              [control]="form.controls.unit_id"
                              [submitted]="submitted"
                            >
                              <label for="unit_id"
                                >หน่วยนับ <span class="danger">*</span></label
                              >
                              <select
                                class="form-control"
                                id="unit_id"
                                formControlName="unit_id"
                              >
                                <option value="">โปรดเลือก...</option>
                                <optgroup
                                  [label]="
                                    unit.unit_type_info.unit_type_name_en
                                  "
                                  *ngFor="let unit of units"
                                >
                                  <option
                                    [value]="unitInfo.unit_id"
                                    *ngFor="let unitInfo of unit.units"
                                    ><span
                                      [innerHTML]="unitInfo.unit_name_en"
                                    ></span>
                                    (<span
                                      [innerHTML]="unitInfo.unit_short_name_en"
                                    ></span
                                    >)</option
                                  >
                                </optgroup>
                              </select>
                            </form-group>
                          </div>

                          <div class="col-xl-4 col-lg-6 col-md-12">
                            <form-group
                              [control]="form.controls.price"
                              [submitted]="submitted"
                            >
                              <label for="price"
                                >ราคาขาย <span class="danger">*</span></label
                              >
                              <div class="input-group">
                                <input
                                  type="text"
                                  currency
                                  id="price"
                                  class="form-control placeholder-right"
                                  placeholder="0.00"
                                  step="0.01"
                                  formControlName="price"
                                />
                                <div class="input-group-append">
                                  <span
                                    class="input-group-text bg-primary white"
                                    >บาท</span
                                  >
                                </div>
                              </div>
                            </form-group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <!-- </div>
                    <div
                      class="tab-pane"
                      id="service-info"
                      aria-labelledby="service-info-tab"
                      role="tabpanel"
                      *ngIf="this.form.value.product_type_id === '3'"
                    >
                      <form
                        autocomplete="off"
                        class="form form-horizontal"
                        role="form"
                        action=""
                        method="post"
                        [formGroup]="productDetailForm"
                      >
                        <div class="form-body">
                          <h5 class="text-bold-600 mb-1">งานบริการสอบเทียบ</h5>
                          <div class="row">
                            <div class="col-lg-6 col-md-12 mb-1">
                              <div class="form-group row">
                                <label class="col-md-3 label-control">
                                  Service <span class="danger">*</span> :
                                </label>
                                <div class="col-md-9">
                                  <form-group
                                    [submitted]="submitted"
                                    [control]="
                                      productDetailForm.controls.service
                                    "
                                  >
                                    <div class="input-group">
                                      <div
                                        class="d-inline-block custom-control custom-checkbox mr-3"
                                      >
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          formControlName="service_lab"
                                          id="service_lab"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="service_lab"
                                          >Lab</label
                                        >
                                      </div>
                                      <div
                                        class="d-inline-block custom-control custom-checkbox"
                                      >
                                        <input
                                          type="checkbox"
                                          class="custom-control-input"
                                          formControlName="service_onsite"
                                          id="service_onsite"
                                        />
                                        <label
                                          class="custom-control-label"
                                          for="service_onsite"
                                          >Onsite</label
                                        >
                                      </div>
                                    </div>
                                  </form-group>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label
                                  class="col-md-3 label-control"
                                  for="mit_scope_id"
                                >
                                  Scope <span class="danger">*</span> :
                                </label>
                                <div class="col-md-9">
                                  <form-group
                                    [submitted]="submitted"
                                    [control]="
                                      productDetailForm.controls.mit_scope_id
                                    "
                                  >
                                    <select
                                      class="form-control"
                                      id="mit_scope_id"
                                      formControlName="mit_scope_id"
                                      (change)="changeScope()"
                                    >
                                      <option value="">โปรดเลือก...</option>
                                      <option
                                        *ngFor="let scope of scopes"
                                        [value]="scope.mit_scope_id"
                                      >
                                        {{ scope.scope_name }}
                                      </option>
                                    </select>
                                  </form-group>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label
                                  class="col-md-3 label-control"
                                  for="mit_equipment_id"
                                >
                                  Equipment <span class="danger">*</span> :
                                </label>
                                <div class="col-md-9">
                                  <form-group
                                    [submitted]="submitted"
                                    [control]="
                                      productDetailForm.controls
                                        .mit_equipment_id
                                    "
                                  >
                                    <select
                                      class="form-control"
                                      id="mit_equipment_id"
                                      formControlName="mit_equipment_id"
                                    >
                                      <option value="">โปรดเลือก...</option>
                                      <option
                                        *ngFor="let equipment of equipments"
                                        [value]="equipment.mit_equipment_id"
                                      >
                                        {{ equipment.equipment_name }}
                                      </option>
                                    </select>
                                  </form-group>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label
                                  class="col-md-3 label-control"
                                  for="mit_method_id"
                                >
                                  Method <span class="danger">*</span> :
                                </label>
                                <div class="col-md-9">
                                  <form-group
                                    [submitted]="submitted"
                                    [control]="
                                      productDetailForm.controls.mit_method_id
                                    "
                                  >
                                    <select
                                      class="form-control"
                                      id="mit_method_id"
                                      formControlName="mit_method_id"
                                    >
                                      <option value="">โปรดเลือก...</option>
                                      <option
                                        *ngFor="let method of methods"
                                        [value]="method.mit_method_id"
                                      >
                                        {{ method.method_name }}
                                      </option>
                                    </select>
                                  </form-group>
                                </div>
                              </div>

                              <div class="form-group row">
                                <label
                                  class="col-md-3 label-control"
                                  for="mit_cp_id"
                                >
                                  CP <span class="danger">*</span> :
                                </label>
                                <div class="col-md-9">
                                  <form-group
                                    [submitted]="submitted"
                                    [control]="
                                      productDetailForm.controls.mit_cp_id
                                    "
                                  >
                                    <select
                                      class="form-control"
                                      id="mit_cp_id"
                                      formControlName="mit_cp_id"
                                    >
                                      <option value="">โปรดเลือก...</option>
                                      <option
                                        *ngFor="let cp of cps"
                                        [value]="cp.mit_cp_id"
                                        >{{ cp.cp_name }}
                                      </option>
                                    </select>
                                  </form-group>
                                </div>
                              </div>
                            </div>
                          </div>

                          <h5 class="text-bold-600 mb-1">Note</h5>
                          <div class="row">
                            <div class="col-lg-6 col-md-12 mb-1">
                              <form-group
                                [control]="productDetailForm.controls.note_1"
                                [submitted]="submitted"
                              >
                                <label for="note_1">Note 1</label>
                                <textarea
                                  id="note_1"
                                  class="form-control"
                                  rows="3"
                                  formControlName="note_1"
                                >
                                </textarea>
                              </form-group>
                            </div>
                            <div class="col-lg-6 col-md-12 mb-1">
                              <form-group
                                [control]="productDetailForm.controls.note_2"
                                [submitted]="submitted"
                              >
                                <label for="note_2">Note 2</label>
                                <textarea
                                  id="note_2"
                                  class="form-control"
                                  rows="3"
                                  formControlName="note_2"
                                >
                                </textarea>
                              </form-group>
                            </div>
                          </div>

                          <div
                            class="d-flex justify-content-between align-items-center mb-1"
                          >
                            <h5 class="text-bold-600">Calibration detail</h5>
                            <button
                              type="button"
                              class="btn btn-outline-primary"
                              data-toggle="modal"
                              data-target="#create-product-range-price"
                            >
                              <i class="fa fa-plus-circle icon-left"></i> เพิ่ม
                            </button>
                          </div>
                          <div class="table-responsive text-center">
                            <table class="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th rowspan="2" class="align-middle">#</th>
                                  <th colspan="2">Range</th>
                                  <th colspan="2">Calibration fee (Baht)</th>
                                  <th rowspan="2" class="align-middle">
                                    Min Point
                                  </th>
                                  <th rowspan="2" class="align-middle">ISO</th>
                                </tr>
                                <tr>
                                  <th>Min</th>
                                  <th>Max</th>
                                  <th>Start</th>
                                  <th>Point</th>
                                </tr>
                              </thead>
                              <tbody class="calibration-detail">
                                <tr *ngFor="let fee of fees; let i = index">
                                  <th>{{ i + 1 }}</th>
                                  <td>
                                    {{ fee.min_range }}
                                    {{ fee.unit_name }}
                                  </td>
                                  <td>
                                    {{ fee.max_range }}
                                    {{ fee.unit_name }}
                                  </td>
                                  <td>{{ fee.start_fee | number: "1.2-2" }}</td>
                                  <td>
                                    {{ fee.per_point_fee | number: "1.2-2" }}
                                  </td>
                                  <td>{{ fee.min_point }}</td>
                                  <td>
                                    {{ fee.isISO === "Y" ? "Yes" : "No" }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<!-- <app-product-range-price-create
  [units]="units"
  (done)="onAddFee($event)"
></app-product-range-price-create> -->
