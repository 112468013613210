
<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการรอเรียกเก็บ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/waiting-for-charge']">รอเรียกเก็บ</a>
                </li>
                <li class="breadcrumb-item active">รายการรอเรียกเก็บ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        </div>
        <div class="content-header-right col-md-12 col-12 mb-md-0 mb-2">
          <app-response-status *ngIf="status" [status]="status"></app-response-status>
            <section class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-header pb-0">
                      <div class="row">
                        <h4 class="card-title mt-1 col-12 col-sm-4 col-md-6 col-lg-9">รายการรอเรียกเก็บ</h4>
                        <span class="col-12 col-sm-8 col-md-6 col-lg-3">
                          <search-box (search)="search($event)" [placeholder]="'ค้นหาจากรหัส/ชื่อลูกค้า'"></search-box>
                        </span>
                      </div>
                    </div>
        
                    <div class="card-content collapse show">
                      <div class="card-body">
                          <div class="row justify-content-between">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-9 align-self-end">
                              <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                                <li class="nav-item border-primary mr-50">
                                    <a class="nav-link py-0 active" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchStatus()">ทั้งหมด</a>
                                </li>
                                <li class="nav-item border-primary mr-50">
                                    <a class="nav-link py-0" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchStatus('BANK')">เงินสด</a>
                                </li>
                                <li class="nav-item border-primary mr-50">
                                    <a class="nav-link py-0" id="pill3" data-toggle="pill" href="#pill3" aria-expanded="false" (click)="searchStatus('CREDIT')">เครดิต</a>
                                </li>
                              </ul>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                              <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
                                <div class="input-group mt-0 mb-1">
                                  <input
                                  date-picker
                                  type="text"
                                  id="real_return_date"
                                  [date]="form.value.real_return_date"
                                  class="form-control"
                                  formControlName="real_return_date"
                                  [max-date]="undefined"
                                  placeholder="วันที่ส่งคืน"
                                  />
                                  <div
                                  class="input-group-append"
                                  for="real_return_date"
                                  >
                                  <span class="input-group-text rounded-0 btn-primary text-white"
                                      ><i class="feather icon-calendar"></i
                                  ></span>
                                  </div>
                                  <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                      ><i class="fa fa-times"></i>
                                  </span>
                                </div>
                              </form>
                            </div>
                          </div>
                          <loading *ngIf="isLoading"></loading>
                          <div class="table-responsive">
                              <table class="table table-striped table-bordered">
                                  <thead>
                                      <tr>
                                        <th>รหัสลูกค้า</th>
                                        <th>ชื่อลูกค้า</th>
                                        <th class="text-right">จำนวนรอเรียกเก็บ (รายการ)</th>
                                        <th>วันที่ส่งคืน</th>
                                        <th>Action</th>
                                      </tr>
                                  </thead>

                                  <tbody>
                                    <tr *ngFor="let item of quotations; let i = index;">
                                       <td class="text-nowrap">{{item?.customer_info?.customer_code}}</td>
                                       <td>{{item?.customer_info?.company_name}} 
                                         <span>({{item?.customer_info?.credit_term_day || 0}} วัน)</span>
                                       </td>
                                       <td class="text-right">{{item.billing_count}}</td>
                                       <td>{{item?.real_return_date | thai_date: "short":"date" | dash}}</td>
                                       <td>
                                        <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm"
                                          [routerLink]="[
                                            '/waiting-for-charge/' + item.customer_id
                                          ]">
                                          <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                        </a>
                                       </td>
                                    </tr>
                                    <tr *ngIf="quotations.length === 0">
                                        <td colspan="7" class="text-center">ไม่มีข้อมูล</td>
                                    </tr>
                                  </tbody>
                              </table>
                          </div>
        
                          <app-paginator
                            [filter]="filter"
                            [reset]="reset"
                            [fetch]="load"
                            (changePage)="onChangePage($event)">
                          </app-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        </div>
      </div>
    </div>
</div>
