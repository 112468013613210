import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerAddressService, CustomerInfoService, UtilsService } from 'src/app/services';
import { AccCreditNoteInfoService, AccCreditNoteItemService } from 'src/app/services/credit-note';
import { AccInvoiceInfoService } from 'src/app/services/invoice';
import { CalculateFunctionService, QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';

@Component({
  selector: 'app-credit-note-create',
  templateUrl: './credit-note-create.component.html',
  styleUrls: ['./credit-note-create.component.css']
})
export class CreditNoteCreateComponent implements OnInit {
  form: FormGroup;
  customer_id: string;
  ref_doc_type: string;
  credit_note_type: string;
  ref_acc_document_info_id: string;
  
  pageLoad: boolean = false;
  submitted: boolean = false;
  disableSaveBtn: boolean = false;

  customerList = [];
  customerAddressList = [];
  tmpCustomerAddressList = [];

  state: any;
  status: any;

  customer_info = null;
  creditNoteInfo = null;
  creditNoteItem = null;
  info = null;
  subject: Subject<string> = new Subject();

  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private route: ActivatedRoute,
    private CustomerInfoService: CustomerInfoService,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private CustomerAddressService: CustomerAddressService,
    private AccCreditNoteInfoService: AccCreditNoteInfoService,

    private AccCreditNoteItemService: AccCreditNoteItemService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,

    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public UtilsService: UtilsService,
    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) {
    this.customer_id = 
    this.route.snapshot.paramMap.get("customer_id");  
    this.ref_doc_type = 
    this.route.snapshot.paramMap.get("ref_doc_type"); 
    this.credit_note_type = 
    this.route.snapshot.paramMap.get("credit_note_type"); 
    this.ref_acc_document_info_id = 
    this.route.snapshot.paramMap.get("ref_acc_document_info_id"); 
    this.state = this.router.getCurrentNavigation().extras.state;
   }

  async ngOnInit() {
    this.createForm();

    await this.CustomerInfoService.getById({customer_id: this.customer_id})
    .then(async res=>{

      let resultData = res.resultData || {};
      this.customer_info = res.resultData;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: `[${resultData?.customer_code || ''}] ${resultData?.company_name || ''} ${resultData?.branch?" (" + resultData?.branch + ")": ""}`,
      }
      if(resultData.customer_id){

        await this.setCustomerAddress(resultData.customer_id);
      }
      this.form.patchValue(data);
    })
    if(this.ref_doc_type == 'INVOICE'){
      await this.AccInvoiceInfoService.getById({acc_invoice_info_id: this.ref_acc_document_info_id})
      .then((res) => {
        this.info = res.resultData;
        this.form.get("ref_acc_document_doc_no").setValue(this.info.doc_no);
      });
    }else{
      await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: this.ref_acc_document_info_id})
      .then((res) => {
        this.info = res.resultData;
        this.form.get("ref_acc_document_doc_no").setValue(this.info.doc_no_company);
      });
    }

    if(this.state?.data){
      this.form.get("doc_note").setValue(this.state?.data.doc_reason);
      this.form.get("doc_reason").setValue(this.state?.data.doc_reason);
    }

    this.creditNoteInfo = this.form.getRawValue();
    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );

    this.pageLoad = true;
  }

  createForm(){
    this.form = this._fbd.group({
      ref_doc_type: [this.ref_doc_type],
      ref_acc_document_info_id: [this.ref_acc_document_info_id],
      ref_acc_document_doc_no: [""],
      credit_note_type: [this.credit_note_type],
      // doc_no: ["", [Validators.required]],
      revise_count: [""],
      doc_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      customer_id: ["", [Validators.required]],
      customer_name: [""],
      customer_address: [""],
      customer_address_id: ["", [Validators.required]],
      doc_note: [""],
      doc_reason: [""],
      ref_grand_total_before_vat: [0.00],
      new_amount: [0.00],
      difference: [0.00],
      difference_tax: [0.00],
      tax: ["7"],
      grand_total: [0.00],
      doc_status_id: ["", [Validators.required]],
    });
    this.form.controls['customer_address_id'].disable(); 
  }

  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];

      if(customer.length > 0){
        if(customer.length > 0){
          address = {
            id: customer[0].customer_address_id,
            customer_id: customer[0].customer_id,
            text: customer[0]?.branch?`${customer[0].address_name} (${customer[0]?.branch})` + ` แผนก: ${customer[0].department}`: `${customer[0].address_name}` + ` แผนก: ${customer[0].department}`,
            address_name: this.utils.genAddress(customer[0]),
          }
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
       this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];

       this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem => (
        {
          id: elem.customer_address_id,
          customer_id: elem.customer_id,
          text: elem.branch? `${elem.address_name} (${elem.branch})` + ` แผนก: ${elem.department}`: `${elem.address_name}` + ` แผนก: ${elem.department}`,
          address_name: this.utils.genAddress(elem)
        } 
      ));
      });
      let customerAddress = this.tmpCustomerAddressList;
      if(address){
        this.customerAddressList = [
          ...[address],
          ...customerAddress
        ];
      }else{
        this.customerAddressList = [
          ...customerAddress
        ];
      }
      if(this.customerAddressList.length > 0){
        const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        if(checkAddress.length == 0) {
          if(address){
            this.form.get('customer_address_id').setValue(address.id);
            this.selectAddressName(address.id);
          }else{
            this.form.controls['customer_address_id'].setValue(this.customerAddressList[0]?.id);
            this.selectAddressName(this.customerAddressList[0]?.id);
          }
        }
      }else{
        if(address){
          this.customerAddressList = [
            ...[address],
          ];
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address.address_name){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.get('customer_address').setValue("");
    }
  }

  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;

    let findPrice = this.creditNoteItem.items.find(item=> this.utils.convertStingToNumber(item.price) > item.previous_price);
    if(this.creditNoteInfo.credit_note_type == 'DISCOUNT') findPrice = null;
    if (this.form.invalid || findPrice) {
      return;
    };
    this.submit();
  }

  save(){
    this.form.get('doc_status_id').setValue("WAITING_FOR_APPROVE");
    this.submitted = true;
    
    let findPrice = this.creditNoteItem.items.find(item=> this.utils.convertStingToNumber(item.price) > item.previous_price);
    if(this.creditNoteInfo.credit_note_type == 'DISCOUNT') findPrice = null;
    if (this.form.invalid || findPrice) {
      return;
    };
    this.submit();
  }

  async submit(){
    let data = this.form.getRawValue();
    this.disableSaveBtn = true;

    let response = await this.AccCreditNoteInfoService.create(data);
    if(response.success){
      await Promise.all(
        this.creditNoteItem.items.map(async (value: any, index: number)=>{
  
          let itemResponse = await this.AccCreditNoteItemService
          .create({
            ...value,
            price: parseFloat(this.utils.convertStingToNumber(value.price)),
            discount: parseFloat(this.utils.convertStingToNumber(value.discount)),
            acc_credit_note_info_id: response.resultData.acc_credit_note_info_id,
            order_by: index
          });

          this.updateMitItem(value, response);
      }));
      await this.router.navigateByUrl("/credit-note", {
        state: {
          status: response.success,
        },
      })
    }else{
      this.status = response.error;
    }
    this.disableSaveBtn = false;
  }

  async updateMitItem(value, accCreditNoteResponse){
    if(value.item_type == 'calibration_service'){
      let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
      await this.QuotationMitItemService.update({
        ...quotationMitResponse.resultData[0],

        acc_credit_note_info_id: accCreditNoteResponse.resultData.acc_credit_note_info_id,
        acc_credit_note_info_doc_no: accCreditNoteResponse.resultData.doc_no,
        acc_credit_note_info_status: accCreditNoteResponse.resultData.doc_status_id
      })
    }else if(value.item_type == 'product'){
      let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
      await this.QuotationProductItemService.update({
        ...quotationProductResponse.resultData,

        acc_credit_note_info_id: accCreditNoteResponse.resultData.acc_credit_note_info_id,
        acc_credit_note_info_doc_no: accCreditNoteResponse.resultData.doc_no,
        acc_credit_note_info_status: accCreditNoteResponse.resultData.doc_status_id
      })
    }
  }

  getItems(event){
    if(event){
      this.creditNoteItem = event;
      if(this.creditNoteInfo?.credit_note_type =='FULL_AMOUNT'){
        this.form.get('ref_grand_total_before_vat').setValue(event.totalPrice);
      }else if((this.creditNoteInfo?.credit_note_type =='DISCOUNT' || this.creditNoteInfo?.credit_note_type =='PARTIALLY') && this.creditNoteItem.items.length > 0){
        this.form.get('ref_grand_total_before_vat').setValue(this.info.grand_total_before_vat);
      }
    }
    this.sumCount();
  }

  onKeyup(){
    this.subject.next();
  }

  sumCount(){
    let checkIsEditItem = this.creditNoteItem.items.find(item =>  item.previous_price != item.price || item.previous_quantity != item.quantity);
    // console.log(checkIsEditItem, this.creditNoteItem.items)
    if(checkIsEditItem){
      let new_amount = 0;

      this.creditNoteItem.items.map(item=>{
        if(item.item_type == 'product' || item.item_type == 'product_fee' ){
          new_amount = new_amount + this.calculate.sumProduct(item);
        }else{
          new_amount = new_amount + this.calculate.sumMitCredit(item);
        }
      });
      if(this.creditNoteInfo?.credit_note_type =='FULL_AMOUNT'){
        this.form.get('new_amount').setValue(this.creditNoteItem.totalPrice - new_amount); 
        this.form.get('difference').setValue(this.creditNoteItem.totalPrice - this.form.value.new_amount);
      }else{
        this.form.get('new_amount').setValue(this.info.grand_total_before_vat - new_amount); 
        this.form.get('difference').setValue(this.info.grand_total_before_vat - this.form.value.new_amount);
      }

      this.form.get('difference_tax').setValue(this.calculate.sumProductWithTax(this.form.value.difference, this.form.value));

      this.form.get('grand_total').setValue(this.form.value.difference_tax + this.form.value.difference);
    }else{
      let new_amount = 0;

      this.creditNoteItem.items.map(item=>{
        if(item.item_type == 'product' || item.item_type == 'product_fee' ){
          new_amount = new_amount + this.calculate.sumProduct(item);
        }else{
          new_amount = new_amount + this.calculate.sumMitCredit(item);
        }
      });
      
      if(this.creditNoteInfo?.credit_note_type =='FULL_AMOUNT'){
        this.form.get('new_amount').setValue(0);
        this.form.get('difference').setValue(this.creditNoteItem.totalPrice - this.form.value.new_amount);

        this.form.get('difference_tax').setValue(this.calculate.sumProductWithTax(this.form.value.ref_grand_total_before_vat, this.form.value));

        this.form.get('grand_total').setValue(this.calculate.sumProductTotal(this.form.value.ref_grand_total_before_vat, this.form.value));
      }else if(this.creditNoteInfo?.credit_note_type =='PARTIALLY'){
        if(this.creditNoteItem.items.length > 0){
          this.form.get('new_amount').setValue(this.info.grand_total_before_vat - new_amount);
          this.form.get('difference').setValue(this.info.grand_total_before_vat - this.form.value.new_amount);

          this.form.get('difference_tax').setValue(this.calculate.sumProductWithTax(this.form.value.difference, this.form.value));

          this.form.get('grand_total').setValue(this.form.value.difference_tax + this.form.value.difference);
        }
      }else{
        if(this.creditNoteItem.items.length > 0){
          this.form.get('new_amount').setValue(0);
          this.form.get('difference').setValue(0);

          this.form.get('difference_tax').setValue(this.calculate.sumProductWithTax(this.form.value.difference, this.form.value));

          this.form.get('grand_total').setValue(this.form.value.difference_tax + this.form.value.difference);
        }
      }
    }
  }
}
