<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="quotation-accept-edit"
  tabindex="-1"
  role="dialog"
  aria-labelledby="quotationAccept"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="quotationAccept">
          ไฟล์ยอมรับใบเสนอราคา
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="form">
          <div class="row justify-content-md-center">
            <div class="col-md-12">
              <div class="form-body">
                <form-group
                  [control]="form.controls.accepted_date"
                  [submitted]="submitted"
                >
                  <label for="accepted_date"
                    >ลูกค้ายอมรับเมื่อ <span class="danger">*</span>
                  </label>
                  <div class="input-group mt-0">
                    <input
                      date-picker
                      type="text"
                      *ngIf="form.value.accepted_date"
                      id="edit.accepted_date"
                      [date]="form.value.accepted_date"
                      class="form-control"
                      formControlName="accepted_date"
                    />
                    <div class="input-group-append" for="doc_validdate">
                      <span class="input-group-text"
                        ><i class="feather icon-calendar"></i
                      ></span>
                    </div>
                  </div>
                </form-group>
                <form-group
                [control]="form.controls.accepted_po_no"
                [submitted]="submitted"
              >
                <label for="accepted_po_no"
                  >เลขที่ PO <span class="danger">*</span>
                </label>
                <div class="input-group mt-0">
                  <input
                    type="text"
                    placeholder="เลขที่ PO"
                    id="edit.accepted_po_no"
                    class="form-control"
                    formControlName="accepted_po_no"
                  />
                </div>
              </form-group>
              <form-group
              [control]="form.controls.note"
              [submitted]="submitted">
              <label for="note">
                หมายเหตุ
              </label>
              <textarea
                placeholder="หมายเหตุ"
                class="form-control"
                id="note"
                rows="2"
                formControlName="note"
                >
              </textarea>
              </form-group>
                <form-group
                  [control]="form.controls.accepted_doc_path"
                  [submitted]="submitted"
                >
                  <label for="accepted_doc_path">เอกสารยืนยัน </label>
                  <div class="custom-file">
                    <input
                      type="file"
                      class="custom-file-input"
                      id="accepted_doc_path"
                      (change)="onFileChange($event.target.files)"
                    />
                    <label
                      class="custom-file-label"
                      #accepted_confirmdoc
                      for="accepted_confirmdoc"
                    >
                      เลือกไฟล์.....</label
                    >
                  </div>
                  <small>* จำกัดขนาดไฟล์ {{UtilsService.config.limitFile}} mb</small> 
                </form-group>
            
                <div class="table-responsive">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                          <th class="w-75">เอกสารยืนยัน</th>
                          <th class="w-25">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of files; let i = index;">
                        <td>
                          {{item.name || item.accepted_doc_name}}
                        </td>
                        <td>
                          <button
                          type="button"
                          (click)="removeFile(i)"
                          class="btn mr-1 mb-1 btn-danger btn-sm text-nowrap"
                          > <i class="fa fa-trash icon-left"></i> ลบ
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="files.length == 0">
                        <td colspan="2" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <span class="pl-1 text-danger w-100" *ngIf="submitted && checkFileInvalid">
                  ขนาดไฟล์เกินกว่าที่กำหนด
                </span>
                
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times "></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-floppy-o icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
