<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="debit-note-item-create"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreate1Modal">
          เลือกรายการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12 col-sm-12 text-right">
                    <h4>{{selectedCount}} รายการ</h4>
                  </div>
                </div>
            </div>

            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                  <th>
                                    <span>
                                      <input
                                        *ngIf="isShowCheckAll"
                                        type="checkbox"
                                        id="select_all"
                                        (change)="onCheckAll($event)"
                                      >
                                    </span>
                                  </th>
                                  <th>ใบเสนอราคา</th>
                                  <th>เลขที่ PO</th>
                                  <th>ชื่อสินค้า</th>
                                  <th>Serial No./Tag No.</th>
                                  <th class="text-right">ราคา (บาท)</th>
                                  <th>พนักงานขาย</th>
                                  <th>วันที่ส่งคืน</th>
                                </tr>
                            </thead>

                            <tbody>
                              <tr *ngFor="let item of billing; let i = index;">
                                  <td>
                                    <div [ngSwitch]="item.item_type">
                                      <span *ngSwitchCase="'product'">
                                        <input 
                                        type="checkbox"
                                        *ngIf="(!item?.job_order_product_item_info[0]?.lock_invoice_at || item?.job_order_product_item_info[0]?.unlock_invoice_at || item?.product_title.startsWith('Transport')) && !item.lock"
                                        [id]="'select_'+i"
                                        [(ngModel)]="item.select"
                                        [ngModelOptions]="{ standalone: true }"
                                        (click)="setCount()"
                                        >
                                        <span *ngIf="(item?.job_order_product_item_info[0]?.lock_invoice_at && !item?.job_order_product_item_info[0]?.unlock_invoice_at && !item?.product_title.startsWith('Transport')) || item.lock">
                                          <i class="fa fa-lock fa-lg" aria-hidden="true"></i>
                                        </span>
                                      </span>

                                      <span *ngSwitchCase="'calibration_service'">
                                        <input 
                                        type="checkbox"
                                        *ngIf="(!item?.job_order_calibration_item_info[0]?.lock_invoice_at || item?.job_order_calibration_item_info[0]?.unlock_invoice_at) && !item.lock"
                                        [id]="'select_'+i"
                                        [(ngModel)]="item.select"
                                        [ngModelOptions]="{ standalone: true }"
                                        (click)="setCount()"
                                        >
                                        <span *ngIf="(item?.job_order_calibration_item_info[0]?.lock_invoice_at && !item?.job_order_calibration_item_info[0]?.unlock_invoice_at) || item.lock">
                                          <i class="fa fa-lock fa-lg" aria-hidden="true"></i>
                                        </span>
                                      </span>
                                    </div>
                                   
                                  </td>
                                  <td class="text-nowrap">
                                      <div [ngSwitch]="item.item_type">
                                          <span *ngSwitchCase="'product'">{{item.quotation_info?.doc_no}}</span>
                                          <span *ngSwitchCase="'calibration_service'">{{item.quotation_info?.doc_no}}</span>
                                      </div>
                                  </td>
                                  <td>
                                      <span *ngFor="let po of item.quotation_info.quotation_accepted_info; let last = last;">
                                          {{po.accepted_po_no}}
                                          <span *ngIf="!last">,</span>
                                      </span>
                                  </td>
                                  <td>
                                      <div [ngSwitch]="item.item_type">
                                          <span *ngSwitchCase="'product'">{{item.product_title}}</span>
                                          <span *ngSwitchCase="'calibration_service'">{{item.scope_name}}: {{item.equipment_name}}</span>
                                        </div>
                                  </td>
                                  <td>
                                    <div [ngSwitch]="item.item_type">
                                      <span *ngSwitchCase="'product'"></span>
                                      <span *ngSwitchCase="'calibration_service'">{{item.serial_no}}/{{item.tag_no}}</span>
                                    </div>
                                  </td>
                                  <td class="text-right">
                                      <div *ngIf="item.item_type=='calibration_service'">
                                        {{calculate.sumMit(item) | number : '1.2-2'}}
                                    </div>
                                    <div *ngIf="item.item_type=='product'">
                                        {{calculate.sumProduct(item) | number : '1.2-2'}}
                                    </div>
                                  </td>
                                  <td>
                                    <div>
                                      {{item.quotation_info?.sale_info?.firstname_th}} {{item.quotation_info?.sale_info?.lastname_th}}
                                    </div>
                                    <small>เมื่อวันที่ {{item.ready_to_billing | thai_date: "short":"date" | dash}}</small>
                                  </td>
                                  <td>
                                    {{item.return_date | thai_date: "short":"date" | dash}}
                                  </td>

                              </tr>
                              <tr *ngIf="billing.length === 0">
                                  <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
                              </tr>
                          </tbody>
                        </table>
                    </div>
                  </div>
                </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>เพิ่มรายการ
        </button>
      </div>
    </div>
  </div>
</div>
