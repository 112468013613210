<loading *ngIf="pageLoad"></loading>
<div class="card mb-0" [hidden]="pageLoad">
    <div class="card-content collapse show">
        <div class="card-body p-0">

            <div class="d-flex justify-content-between mb-1">
                <div>
                    <!-- <button
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#received-voucher-item-create"
                    *ngIf="pageType !='show'"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบแจ้งหนี้
                    </button> -->
                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#received-voucher-item-tax-create"
                    *ngIf="pageType !='show'"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบกำกับภาษี
                    </button>
                </div>
            </div>

            <div class="table-responsive" style="max-height: 600px;">
                <table class="table table-striped table-bordered">
                    <thead> 
                        <tr>
                            <th>เลขที่เอกสาร</th>
                            <th>ประเภทการชำระเงิน</th>
                            <th>วันที่ชำระ</th>
                            <th>ธนาคาร</th>
                            <th>สาขา</th>
                            <th>เลขที่บัญชี</th>
                            <th class="text-right">จำนวนเงิน (บาท)</th>
                            <th *ngIf="pageType !='show'">
                                action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let receivedVoucher of receivedVoucherItems; let i = index">
                            <td class="text-nowrap">
                              {{receivedVoucher?.data?.acc_tax_invoice_info?.doc_no_company}}
                            </td>
                            <td>
                                {{getPaymentName(receivedVoucher?.data)}}
                            </td>
                            <td>
                                {{receivedVoucher?.data?.payment_date | thai_date: "short":"date" | dash}}
                            </td>
                            <td>
                                {{receivedVoucher?.data?.bank_name | dash}}
                            </td>
                            <td>
                                {{receivedVoucher?.data?.bank_branch | dash}}
                            </td>
                            <td>
                                {{receivedVoucher?.data?.evidence_ref_no | dash}}
                            </td>
                            <td class="text-right">
                                {{receivedVoucher?.data?.grand_total | number : '1.2-2' }}
                            </td>
                            <td *ngIf="pageType !='show'">
                                <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#modalConfirmDelMsgVoucherItem"
                                (click)="select(receivedVoucher)">
                                ลบ
                            </button>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="receivedVoucherItems.length == 0">
                        <tr>
                            <td [attr.colspan]="12" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-received-voucher-item-create *ngIf="customer_info" [customer_info]="customer_info" [paymentList]="paymentList" (done)="submitReceivedVoucherItem($event);">
</app-received-voucher-item-create>

<app-received-voucher-item-tax-create *ngIf="customer_info" [customer_info]="customer_info" [paymentList]="paymentList" (done)="submitReceivedVoucherItem($event);">
</app-received-voucher-item-tax-create>

<app-confirm-delete
  [id]="'modalConfirmDelMsgVoucherItem'"
  (save)="delete($event)"
></app-confirm-delete>