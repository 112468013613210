import { Injectable } from "@angular/core";
import { QuotationUtilsService } from "./quotations";

@Injectable({
   providedIn: "root"
})
export class generateMitItemLogService {

  constructor(
    public utils : QuotationUtilsService,
  ) {}

  async createMitItemLog(item: any, oldLogs = [], page: string = 'quotation'){
    if(item.description){
      let chk = oldLogs.filter(log=> log.key == 'description').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.description){
        item.mit_item_logs.push(this.createDescriptionLog(item.description, 'description', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.description, 'description'));
      }
    }
    if(item.due_month){
      let chk = oldLogs.filter(log=> log.key == 'due_month').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.due_month){
        item.mit_item_logs.push(this.createDescriptionLog(item.due_month, 'due_month', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.due_month, 'due_month'));
      }
    }
    if(item.marker){
      let chk = oldLogs.filter(log=> log.key == 'marker').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.marker){
        item.mit_item_logs.push(this.createDescriptionLog(item.marker, 'marker', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.marker, 'marker'));
      }
    }
    if(item.model){
      let chk = oldLogs.filter(log=> log.key == 'model').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.model){
        item.mit_item_logs.push(this.createDescriptionLog(item.model, 'model', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.model, 'model'));
      }
    }
    if(item.serial_no){
      let chk = oldLogs.filter(log=> log.key == 'serial_no').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.serial_no){
        item.mit_item_logs.push(this.createDescriptionLog(item.serial_no, 'serial_no', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.serial_no, 'serial_no'));
      }
    }
    if(item.tag_no){
      let chk = oldLogs.filter(log=> log.key == 'tag_no').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.tag_no){
        item.mit_item_logs.push(this.createDescriptionLog(item.tag_no, 'tag_no', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.tag_no, 'tag_no'));
      }
    }
    if(page == 'quotation'){
      await Promise.all(item.quotation_mit_item_details.map((detail)=>{
        detail['mit_item_logs'] = [];
        if(detail.total_amount > 0){
          if(detail.quotation_mit_item_detail_range.cus_unit_id || detail.quotation_mit_item_detail_points.length > 0){
            let chk = oldLogs.filter(log=> log.key == 'point' && detail.quotation_mit_item_detail_id == log.quotation_mit_item_detail_id).sort(function(a,b){
              if(a.created_at < b.created_at) return 1;
              if(a.created_at > b.created_at) return -1;
              return 0;
            });
            if(chk.length > 0 && chk[0]?.new_value != this.generatePointValue(detail) && chk[0]?.new_value != this.generateRangeValue(detail)){
              detail.mit_item_logs.push(this.createPointLog(detail, chk[0]?.new_value));
            }else if(chk.length == 0){
              detail.mit_item_logs.push(this.createPointLog(detail));
            }
          }
          if(detail.mpe){
            let chk = oldLogs.filter(log=> log.key == 'mpe' && detail.quotation_mit_item_detail_id == log.quotation_mit_item_detail_id).sort(function(a,b){
              if(a.created_at < b.created_at) return 1;
              if(a.created_at > b.created_at) return -1;
              return 0;
            });
            if(chk.length > 0 && chk[0].new_value != detail.mpe){
              detail.mit_item_logs.push(this.createMPELog(detail, chk[0].new_value));
            }else if(chk.length == 0){
              detail.mit_item_logs.push(this.createMPELog(detail));
            }
          }
        }
      }));
      if(item.quotation_mit_item_attributes){
        await Promise.all(item.quotation_mit_item_attributes.map((attribute)=>{
          if(attribute.attribute_name && attribute.attribute_value){
            let chk = oldLogs.filter(log=> log.key == attribute.attribute_name).sort(function(a,b){
              if(a.created_at < b.created_at) return 1;
              if(a.created_at > b.created_at) return -1;
              return 0;
            });
            if(chk.length > 0 && chk[0]?.new_value != item.serial_no){
              item.mit_item_logs.push(this.createDescriptionLog(attribute.attribute_value, attribute.attribute_name, chk[0]?.new_value));
            }else if(chk.length == 0){
              item.mit_item_logs.push(this.createDescriptionLog(attribute.attribute_value, attribute.attribute_name));
            }
          }
        }));
      }
    }else{
      await Promise.all(item.quotation_mit_item_detail_info.map(async (detail)=>{
        detail['mit_item_logs'] = [];
        if(detail.total_amount > 0){
          if(detail.quotation_mit_item_detail_range.cus_unit_id || detail.quotation_mit_item_detail_points.length > 0){
            let chk = oldLogs.filter(log=> log.key == 'point' && detail.quotation_mit_item_detail_id == log.quotation_mit_item_detail_id).sort(function(a,b){
              if(a.created_at < b.created_at) return 1;
              if(a.created_at > b.created_at) return -1;
              return 0;
            });
            if(chk.length > 0 && chk[0]?.new_value != this.generatePointValue(detail) && chk[0]?.new_value != this.generateRangeValue(detail)){
              detail.mit_item_logs.push(this.createPointLog(detail, chk[0]?.new_value));
            }else if(chk.length == 0){
              detail.mit_item_logs.push(this.createPointLog(detail));
            }
          }
          if(detail.mpe){
            let chk = oldLogs.filter(log=> log.key == 'mpe' && detail.quotation_mit_item_detail_id == log.quotation_mit_item_detail_id).sort(function(a,b){
              if(a.created_at < b.created_at) return 1;
              if(a.created_at > b.created_at) return -1;
              return 0;
            });
            if(chk.length > 0 && chk[0].new_value != detail.mpe){
              detail.mit_item_logs.push(this.createMPELog(detail, chk[0].new_value));
            }else if(chk.length == 0){
              detail.mit_item_logs.push(this.createMPELog(detail));
            }
          }
        }
      }));
      await Promise.all(item.quotation_mit_item_attribute_info.map((attribute)=>{
        if(attribute.attribute_name && attribute.attribute_value && (attribute.is_non_calibration == false || !attribute.is_non_calibration)){
          let chk = oldLogs.filter(log=> log.key == attribute.attribute_name).sort(function(a,b){
            if(a.created_at < b.created_at) return 1;
            if(a.created_at > b.created_at) return -1;
            return 0;
          });
          if(chk.length > 0 && chk[0]?.new_value != item.serial_no){
            item.mit_item_logs.push(this.createDescriptionLog(attribute.attribute_value, attribute.attribute_name, chk[0]?.new_value));
          }else if(chk.length == 0){
            item.mit_item_logs.push(this.createDescriptionLog(attribute.attribute_value, attribute.attribute_name));
          }
        }
      }));
    }
    return item;
  }

  async createTransportMitItemLog(item: any, oldLogs = []){

    if(item.noqt_marker){
      let chk = oldLogs.filter(log=> log.key == 'marker').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.noqt_marker){
        item.mit_item_logs.push(this.createDescriptionLog(item.noqt_marker, 'marker', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.noqt_marker, 'marker'));
      }
    }
    if(item.noqt_model){
      let chk = oldLogs.filter(log=> log.key == 'model').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.noqt_model){
        item.mit_item_logs.push(this.createDescriptionLog(item.noqt_model, 'model', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.noqt_model, 'model'));
      }
    }
    if(item.noqt_serial_no){
      let chk = oldLogs.filter(log=> log.key == 'serial_no').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.noqt_serial_no){
        item.mit_item_logs.push(this.createDescriptionLog(item.noqt_serial_no, 'serial_no', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.noqt_serial_no, 'serial_no'));
      }
    }
    if(item.noqt_tag_no){
      let chk = oldLogs.filter(log=> log.key == 'tag_no').sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      if(chk.length > 0 && chk[0]?.new_value != item.noqt_tag_no){
        item.mit_item_logs.push(this.createDescriptionLog(item.noqt_tag_no, 'tag_no', chk[0]?.new_value));
      }else if(chk.length == 0){
        item.mit_item_logs.push(this.createDescriptionLog(item.noqt_tag_no, 'tag_no'));
      }
    }
    return item;
  }

  createDescriptionLog(value, key, oldValue?): any{
    return {
      key: key,
      event: 'change_description',
      previous_value: oldValue || "",
      new_value: value.toString(),
      description: `กำหนดรายละเอียด: ${key} เป็น ${value}`,
      quotation_mit_item_detail_id: ''
    }
  }
  createMPELog(detail, oldValue?): any{
    return {
      key: 'mpe',
      event: 'change_description',
      previous_value: oldValue || "",
      new_value: detail.mpe.toString(),
      description: `กำหนดรายละเอียด: mpe เป็น ${detail.mpe}`,
      quotation_mit_item_detail_id: ''
    }
  }
  createPointLog(detail, oldValue?): any{
    let str = null;
    if(detail.detail_type == "point"){
      str = this.generatePointValue(detail);
    }else{
      str = this.generateRangeValue(detail);
    };
    return {
      key: 'point',
      event: 'change_pont',
      previous_value: oldValue || "",
      new_value: str.toString(),
      description: `กำหนดจุด: ${str}`,
      quotation_mit_item_detail_id: ''
    }
  }
  generateRangeValue(detail){
    let selectRange = detail.quotation_mit_item_detail_range;
    let str = `${selectRange.cus_min} - ${selectRange.cus_max}`
  
    return str;
  }
  generatePointValue(detail){
    let str = "";
    detail.quotation_mit_item_detail_points.map((item, index)=>{
      if(index == 0){
        str = `${item.cus_point}`
      }else{
        str = str + `, ${item.cus_point}`
      }
    });
    return str;
  }
}