<div class="container app-content overflow-container" [hidden]="!canAccess || !pageLoad">

    <div class="btn-section text-right border-bottom">
      <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(jobOrderInfo)">
          <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
      </button>
    </div>
  
    <div *ngFor="let page of pages; let i = index; let lastIndex = last">
      <div class="pdf"  id="job_order_{{i}}">
        <div class="container mt-5" *ngIf="jobOrderInfo && i == 0">
          <div class="row">
            <div class="col-6">
              <p>ชื่อ-ที่อยู่สำหรับออกใบรับรองผลการสอบเทียบ</p>
              <p>{{jobOrderInfo?.report_address_name}} <br>
                {{jobOrderInfo?.report_address}}
              </p>
              <br>
              <p>ชื่อ-ที่อยู่สำหรับออกใบกำกับภาษี</p>
              <p>บริษัท มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี จำกัด <br>
                ที่อยู่ 214 ถนนบางแวก แขวงบางไผ่ เขตบางแค กรุงเทพฯ 10160
              </p>

              <!-- <p>{{jobOrderInfo?.report_address_name}} <br>
                {{jobOrderInfo?.report_address}} <br>
                {{jobOrderInfo?.charge_contact}}
              </p> -->
            </div>
            <div class="col-6">
              <p class="text-right pr-5">CRF. No. {{jobOrderInfo?.doc_no}}</p>
              <div class="row form-group mx-sm-3 mb-0">
                <label class="mt-2 pl-2">ลงชื่อผู้รับ</label>
                <div class="col-sm-6 d-flex justify-content-end">
                  <div class="line-input"></div>
                </div>
                <label class="mt-2">วันที่</label>
                <div class="col-sm-3 d-flex justify-content-end">
                  <div class="line-input"></div>
                </div>
              </div>
              <div class="row form-group mx-sm-3 mb-0">
                <div class="col-sm-2">
                </div>
                <label class="mt-2">(</label>
                <div class="col-sm-6 d-flex justify-content-end">
                  <div class="line-input"></div>
                </div>
                <label class="mt-2">)</label>
              </div>
            </div>
          </div>
        </div>  
        <div class="container" *ngIf="jobOrderInfo && i == 0">
          <div class="row">
            <div class="col-6">
              <p>สอบถามข้อมูลกรุณาติดต่อ</p>
              <p>
                จัดซื้อ 02-865-4647-8, <span *ngIf="jobOrderInfo"> {{jobOrderInfo?.sales_assignment_info[0]?.user_info?.firstname_th}} {{jobOrderInfo?.sales_assignment_info[0]?.user_info?.lastname_th}} {{jobOrderInfo?.sales_assignment_info[0]?.user_info?.mobile_no}}</span>
                <!-- <span class="pl-0" *ngFor="let item of contactList; let i = index;">
                  {{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}}
                  <span class="pl-0" *ngIf="contactList?.length != i+1">,</span>
                </span>
                Tel : 
                <span class="pl-0" *ngFor="let item of contactList; let i = index;">
                  {{item?.customer_contact_info?.phonenumber}}
                  <span class="pl-0" *ngIf="contactList?.length != i+1">,</span>
                </span> -->
              </p>
            </div>
            <div class="col-6">
              <div class="row form-group mx-sm-3 mb-0">
                <label class="mt-2">เจ้าหน้าที่ MIT</label>
                <div class="col-sm-6 d-flex justify-content-end">
                  <div class="line-input"></div>
                </div>
                <label class="mt-2">วันที่</label>
                <div class="col-sm-3 d-flex justify-content-end">
                  <div class="line-input"></div>
                </div>
              </div>
              <div class="row form-group mx-sm-3 mb-0">
                <div class="col-sm-2">
                </div>
                <label class="mt-2">(</label>
                <div class="col-sm-6 d-flex justify-content-end">
                  <div class="line-input"></div>
                </div>
                <label class="mt-2">)</label>
              </div>
            </div>
          </div>
        </div>
        <div class="container" *ngIf="jobOrderInfo && i == 0">
          <div class="row">
            <div class="col-6">
              <u>เครื่องมือที่นำส่ง CAL: จำนวน {{jobOrderItems.length}} รายการ
              <br>เงื่อนไขพิเศษ: {{jobOrderInfo?.special_condition || '-'}}</u>
            </div>
          </div>
        </div> 
        <div class="container mt-4">
          <div class="row">
              <table class="mx-auto table table-bordered">
                <thead>
                  <tr>
                    <th class="text-center" scope="col">Lab</th>
                    <th scope="col">Description</th>
                    <th class="text-center" scope="col">MFG</th>
                    <th class="text-center" scope="col">Model</th>
                    <th class="text-center" scope="col">TAG</th>
                    <th class="text-center" scope="col">S/N</th>
                    <th class="text-center" width="20%">Calibration point</th>
                    <th class="text-center" scope="col">MPE</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of page; let j = index;">
                    <td class="text-center">{{item.quotation_mit_item_info.cp_list_cali}}</td>
                    <td>{{item.quotation_mit_item_info.description || item.quotation_mit_item_info?.equipment_name}}</td>
                    <td class="text-center">{{item.quotation_mit_item_info.marker || '-'}}</td>
                    <td class="text-center">{{item.quotation_mit_item_info.model || '-'}}</td>
                    <td class="text-center">{{item.quotation_mit_item_info.tag_no || '-'}}</td>
                    <td class="text-center">{{item.quotation_mit_item_info.serial_no || '-'}}</td>
                    <td class="text-left">
                      <span class="custom-inline" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index; first as isFirst;">
                        <ng-container [ngSwitch]="true">
                          <ng-container *ngSwitchCase="item?.quotation_mit_item_info?.cus_calibration_point != null && item?.quotation_mit_item_info?.cus_calibration_point != ''">
                            <span *ngIf="isFirst">{{item?.quotation_mit_item_info?.cus_calibration_point}}</span>
                          </ng-container>

                          <ng-container *ngSwitchDefault>
                            <span *ngIf="detail.detail_type == 'point'">
                              <span class="pb-2 custom-inline" *ngFor="let point of detail.quotation_mit_item_detail_points; let pointIndex = index;">
                                  <span class="danger" *ngIf="detail.isISO === 'N'">*</span>
                                  {{point.cus_point || 0 | number : '1.1-5'}}
                                  <span class="p-0 m-0" *ngIf="pointIndex + 1 != detail.quotation_mit_item_detail_points.length">,</span>
                                  <span [innerHTML]="point.cus_unit_name" *ngIf="pointIndex + 1 == detail.quotation_mit_item_detail_points.length"></span>
                              </span>
                            </span>
                            <span *ngIf="detail.detail_type == 'range'">
                                <span  class="pb-2 custom-inline">
                                    <span class="danger" *ngIf="detail.isISO === 'N'">*</span>
                                    {{_utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_min) || 0 | number : '1.1-5'}} 
                                </span>
                                &nbsp;-
                                <span  class="pb-2 custom-inline">
                                    {{_utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_max) || 0 | number : '1.1-5'}}
                                    <span [innerHTML]="detail?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                </span>
                            </span>
                            <br>
                          </ng-container>
                      </ng-container>
                      </span>
                      <div *ngIf="getMitAttribute(item.quotation_mit_item_attributes)">
                        <span class="font-weight-bold">Attributes:</span> {{getMitAttribute(item.quotation_mit_item_attributes)}}
                      </div>
                      <div *ngIf="getMitNote(item.quotation_mit_item_details)">
                        <span class="font-weight-bold">Note:</span> {{getMitNote(item.quotation_mit_item_details)}}
                      </div>
                    </td>
                    <td class="text-center">
                      <span class="custom-inline" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                        {{detail.mpe}}
                        <span class="pl-0" *ngIf="item.quotation_mit_item_details.length != detailIndex + 1">,</span>
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pt-0" *ngIf="lastIndex">
                <span class="p-0 text-bold">หมายเหตุ: </span> * นอกขอบข่ายการรับรอง  ISO/IEC 17025
              </div>
          </div>
        </div>    
      </div>
      
      <div class="btn-section text-right border-bottom">
      </div>
      <br>
    </div>
    
  </div>
  <div class="container app-content" *ngIf="canAccess == false && pageLoad">
    <div class="text-center">
        <h2>ไม่สามารถเข้าถึงได้</h2>
        <h5>โปรดติดต่อพนักงานขาย</h5>
    </div>
  </div>