import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { 
  QuotationUtilsService,
  QuotationMitItemService, 
  CalculateFunctionService,
} from '../../../../services/quotations';
import {
  UnitService, 
  MITCPService, 
  MITScopeService,
} from 'src/app/services';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { 
  MapMitItemService 
} from '../../work-orders/work-order-lab/work-order-lab-import/map-mit-item.service';
declare const $;

@Component({
  selector: 'app-quotation-prepare',
  templateUrl: './quotation-prepare.component.html',
  styleUrls: ['./quotation-prepare.component.css']
})
export class QuotationPrepareComponent implements OnInit {
  isCopy:boolean = false;
  isMapping:boolean = false;
  pageLoad: boolean = false;

  subject: Subject<object> = new Subject();

  @Output() done = new EventEmitter<any>();
  @Input() submitted = false;
  @Input() customer_id = "";
  @Input() customer_info;
  @Input() docStatusId = "";
  @Input() discount_percentage = "";

  quotationMitItems: Array<any>;
  quotationMitItemGroups = [];
  quotation_info_id: string;

  cps = [];
  units = [];
  scopeList = [];
  equipmentList = [];
  deleteMitItemList = [];

  mitItem = null;
  mitItemIndex = null;
  dropDownData = null;
  totalPrice:number = 0;

  routeUrl:string = "";
  pageType:string = "create";
  editType:string = "";

  mappingId:string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private UnitService: UnitService,
    private MITCPService: MITCPService,
    private MITScopeService: MITScopeService,
    private MapMitItemService: MapMitItemService,
    private QuotationMitItemService: QuotationMitItemService,
    private CalculateFunctionService: CalculateFunctionService,

    public utils : QuotationUtilsService
    ) 
  { 
    this.routeUrl = this.router.url;
    this.route.queryParams.subscribe(params => {
      this.isCopy = params['action'] == 'copy'? true: false;

      this.isMapping = params['type'] == 'mapping'? true: false;
    });
    if (this.routeUrl.includes('show')) {
      this.pageType = 'show';
    } else if (this.routeUrl.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  async ngOnInit() {

    this.pageLoad = false;
    let promises = [];

    promises.push(
      this.MITCPService.load().then((response) => {
        if (response.success) {
          this.cps = response.resultData || [];
        }
      })
    )

    promises.push(
      this.MITScopeService.load(null).then((res) => {
        this.scopeList = res.resultData;
      })
    )
    let units = [];
    promises.push(
      this.UnitService.load().then((response) => {
        if (response.success) {
          this.units = response.resultData || [];
          units = this.utils.groupUnit(response.resultData) || [];
        }
      })
    )
    await Promise.all(promises);
    
    this.dropDownData = {
      scopeList: this.scopeList,
      cps: this.cps,
      units: units
    };

    this.quotationMitItems = await this.getMitItem();

    this.quotationMitItems.sort(
      (a, b) => (a.order_by > b.order_by ? 1 : -1)
    );

    await this.setItem();

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(async object => {
      await this.setItem(object);
    });
    this.pageLoad = true;
  }

  ngOnChanges(changes: SimpleChanges) {}

  getTotalPrice(){
    this.totalPrice = 0;
    this.quotationMitItems.map(item=>{
      this.totalPrice = this.totalPrice + parseFloat(item.grand_total);
    })
  }

  async getMitItem(): Promise<any>{
    this.quotation_info_id = this.route.snapshot.paramMap.get("quotation_info_id");

    if(this.quotation_info_id){
      let quotationMitItems = [];
      
      await this.QuotationMitItemService
      .load(null ,{ quotation_info_id: this.quotation_info_id, is_parent_quotation_mit_item_id_not_null: false, 'order_by': 'order_by:asc' }).then(async (res) => {

        quotationMitItems = [
          ...res.resultData
        ];

        await Promise.all(quotationMitItems.map(async value=>{
          // console.log(
          //   value
          // )
          
          let is_non_calculate = "false";
          value['quotation_mit_item_details'] = [];
          value['quotation_mit_item_attributes'] = [];

          value.quotation_mit_item_attributes = value?.quotation_mit_item_attribute_info || [];

          if(this.docStatusId == 'REVISE' && value.job_order_calibration_item_info.find(item=> item.lock_invoice_at && !item.unlock_invoice_at) && value.quotation_mit_item_detail_info.find(item=> item.is_non_calculate =='true')){
            is_non_calculate = "true";
          }

          value.quotation_mit_item_details = value?.quotation_mit_item_detail_info.filter(item=> item.is_non_calculate == is_non_calculate) || [];

          value.quotation_mit_item_details = await Promise.all(value.quotation_mit_item_details.map(async (data: any)=>{
            let resultData = await this.mapMitItemData(data);

            return {
              ...resultData
            }
          }));

          value.quotation_mit_item_details.sort((a, b) => (a.order_by > b.order_by? 1 : -1));

          if(this.docStatusId == 'REVISE' && value.job_order_calibration_item_info.find(item=> item.lock_invoice_at && !item.unlock_invoice_at)){
            
            let calculate = await this.MapMitItemService.calculateTotalAmountItemDetailExcel(value);

            value.original_price = calculate?.original_price;
          }
        }));
      });
      return quotationMitItems;
    }else{
      return [];
    }
  }

  async groupMitItems(): Promise<any>{

    let quotationMitItemGroups = this.quotationMitItems;

    quotationMitItemGroups.map(value=>{
      const quotation_mit_item_details = [];
      if(value.quotation_mit_item_details.length >0){
        value.quotation_mit_item_details.map(item=>{
          if(item.detail_type == 'point'){
            if(item.quotation_mit_item_detail_points.length > 0){
              quotation_mit_item_details.push(item);
            }
          }else{
            quotation_mit_item_details.push(item);
          }
        })
      }
      value.quotation_mit_item_details = quotation_mit_item_details;
    })
    return quotationMitItemGroups;
  }

  async calculateMitPrice(mitList = [], object = null){
    mitList = await Promise.all(mitList.map(async (res, index)=>({
      ...res,
      index: index,
      price: object?.field == 'price' && index == object?.index? this.utils.transformDecimal(object?.value || 0): this.utils.transformDecimal(res.price != null? res.price: res.original_price),
      discount: object?.field == 'discount' && index == object?.index? this.utils.transformDecimal(object?.value || 0): this.utils.transformDecimal(res.discount != null? res.discount: this.discount_percentage? this.discount_percentage: 0),
    })));
    mitList = await Promise.all(mitList.map(async (res)=>({
      ...res,
      grand_total: this.CalculateFunctionService.sumMit(res)
    })));
    return mitList;
  }

  onKeyup(field: string, value: string, index: number){
    this.subject.next(
      {
        field: field, value: value, index: index
      });
  }

  async mapMitItemData(mit: any): Promise<any>{
    let points = [];
    let range = [];
    if(mit.total_amount > 0){
      if(mit.detail_input_type == 'point'){
        points = mit.quotation_mit_item_detail_points_info || [];

        points
        .sort((a, b) => (a.order_by > b.order_by? 1 : -1));
      }else{
        range = mit.quotation_mit_item_detail_ranges_info || [];
      }
    }

    points.map(point=>{

      if(point.cus_unit_id) point['cus_unit_info'] = this.units.find(unit=> unit.unit_id == point.cus_unit_id);
      if(point.mit_unit_id) point['mit_unit_info'] = this.units.find(unit=> unit.unit_id == point.mit_unit_id);

      if(point.cus_unit_info) point['cus_unit_name'] = point.cus_unit_info.unit_short_name_en 
      || point.cus_unit_info.unit_short_name_th;
      if(point.mit_unit_info) point['mit_unit_name'] = point.mit_unit_info.unit_short_name_en 
      || point.mit_unit_info.unit_short_name_th;

      if(this.docStatusId == 'REVISE'){
        point.is_non_calculate = 'false';
      }
    });

    range.map(range=>{

      if(range.cus_unit_id) range['cus_unit_info'] = this.units.find(unit=> unit.unit_id == range.cus_unit_id);
      if(range.mit_unit_id) range['mit_unit_info'] = this.units.find(unit=> unit.unit_id == range.mit_unit_id);
    });

    return {
      ...mit,
      detail_type: mit.detail_input_type,
      quotation_mit_item_detail_range: {
        cus_min: range[0]?.cus_min || 0,
        cus_max: range[0]?.cus_max || 0, 
        mit_min: range[0]?.mit_min || 0,
        mit_max: range[0]?.mit_max || 0,
        cus_unit_id: range[0]?.cus_unit_id || "",
        mit_unit_id: range[0]?.mit_unit_id || "",
        cus_unit_name: range[0]?.cus_unit_info?.unit_short_name_en || "",
        mit_unit_name: range[0]?.mit_unit_info?.unit_short_name_en || "", 
        is_non_calculate: this.docStatusId == "REVISE"? "false": range[0]?.is_non_calculate,
        quotation_mit_item_detail_range_id: range[0]?.quotation_mit_item_detail_range_id || "", 
      },
      cus_unit_id: mit.unit_id,
      unit_name: mit.unit_short_name_en,
      quotation_mit_item_detail_points: points,
      min_range: mit.product_calibration_fee_info?.min_range,
      max_range:  mit.product_calibration_fee_info?.max_range,
    }
  }
  async onCreateMitItem(event){
    event.quotation_mit_item_attributes = event.quotation_mit_item_attributes.filter(
      v=> v.attribute_value != null
    );
    this.quotationMitItems.push(event);
    
    await this.setItem();
  }
  async onCreateJobOrder(event){
    event.quotation_mit_item_attributes = event.quotation_mit_item_attributes.filter(
      v=> v.attribute_value != null
    );
    event['can_editable'] = 'false';

    this.quotationMitItems.push(event);
    
    await this.setItem();
  }
  async onImportMitItem(event){
    this.pageLoad = false;

    let promises = [];

    promises.push(
      event.map(v=>{
        this.quotationMitItems.push(v);
      })
    )
    promises.push(this.setItem());
    await Promise.all(promises);

    this.pageLoad = true;
  }
  async onEditMitItem(event){
    this.mitItem = null;
    this.mitItemIndex = null;
    let attributes = event.mitItem.quotation_mit_item_attributes;
    event.mitItem.quotation_mit_item_attributes = attributes.filter(
      v=> v.attribute_value != null && v.attribute_value != ''
    );
    if (event.delMitItemAttributeList){
      event.delMitItemAttributeList = [
        ...event.delMitItemAttributeList,
        ...attributes.filter(
            v=> (v.attribute_value == null || v.attribute_value == '') && v.quotation_mit_item_attribute_id != null 
        )
      ]
    }
    this.quotationMitItems[event.index] = event.mitItem;
    this.quotationMitItems[event.index]['delMitItemDetails'] 
    = event.delMitItemDetailList;
    this.quotationMitItems[event.index]['delMitItemDetailPoints'] 
    = event.delMitItemDetailPointList;
    this.quotationMitItems[event.index]['delMitItemDetailRanges'] 
    = event.delMitItemDetailRangeList;
    this.quotationMitItems[event.index]['delMitItemAttributes'] 
    = event.delMitItemAttributeList;

    await this.setItem();
  }
  select(index: number){
    this.mitItem = this.quotationMitItems.find(
      (v, i)=> v.index === index
    );
    this.mitItemIndex = this.quotationMitItems.indexOf(this.mitItem);
  }
  async copy(index: number){
    let tmpMitItem = this.quotationMitItems.find(
      v=> v.index == index
    );
    let tmpAttributes = [...tmpMitItem.quotation_mit_item_attributes];
    let tmpDetails = [...tmpMitItem.quotation_mit_item_details];
    let attributes = [];
    let details = [];
    tmpAttributes.map(v=>{
      const item = {...v};
      delete item.quotation_mit_item_attribute_id;
      delete item.quotation_mit_item_id;
      attributes.push({...item});
    })
    tmpDetails.map(v=>{
      const tmpPoints = [ ...v.quotation_mit_item_detail_points ];
      let points = [];
      
      tmpPoints.map(vp=>{
        const item = {...vp};
        delete item.quotation_mit_item_detail_id;
        delete item.quotation_mit_item_detail_point_id;
        points.push({...item});
      })
      const item = {...v};
      delete item.quotation_mit_item_detail_id;
      delete item.quotation_mit_item_id;

      const range = { ...v.quotation_mit_item_detail_range};
      delete range.quotation_mit_item_detail_range_id;
      details.push({
        ...item,
        quotation_mit_item_detail_points: points,
        quotation_mit_item_detail_range: range
      });
    })
    const item = {...tmpMitItem};
    delete item.quotation_info_id;
    delete item.quotation_mit_item_id;
    let mit = { 
      ...item,
      order_by: this.quotationMitItems.length + 1,
      quotation_mit_item_attributes: attributes,
      quotation_mit_item_details: details
    };
    this.quotationMitItems.push(mit);
    
    await this.setItem();
  }
  async deleteMitItem(closeModalEl){
    this.pageLoad = false;
    let promises = [];
    
    const mitIItem = this.mitItem;
    if(mitIItem.quotation_mit_item_id){
      promises.push(
        this.quotationMitItems.map((item, index)=>{
          if(index == this.mitItemIndex){
            this.deleteMitItemList.push(mitIItem);
            this.quotationMitItems.splice(index, 1);
          }
        })
      )
    }else{
      promises.push(
        this.quotationMitItems.map((item, index)=>{
          if(index == this.mitItemIndex){
            this.quotationMitItems.splice(index, 1);
          }
        })
      )
    }
    this.mitItem = null;
    this.mitItemIndex = null;

    promises.push(
      this.setItem()
    )
    await Promise.all(promises);

    this.pageLoad = true;
    closeModalEl.click();
  }
  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.quotationMitItems, event.previousIndex, event.currentIndex);
    this.quotationMitItemGroups = await this.groupMitItems();
  }
  sentData(){
    this.done.emit({
      quotationMitItems: this.quotationMitItems,
      deleteMitItemList: this.deleteMitItemList
    });
  }

  async setItem(object = null){

    this.quotationMitItems = await this.calculateMitPrice(this.quotationMitItems, object);

    if(object == null){
      this.quotationMitItemGroups = await this.groupMitItems();
    }else{
      this.quotationMitItemGroups[object.index] = this.quotationMitItems[object.index];
    }
    
    this.getTotalPrice();

    this.sentData();
  }

  resetMitItem(event){
    this.mitItem = null;
  }

  isLock(item): boolean {
    if(this.docStatusId == 'REVISE'){
      var lock = item?.lock_invoice_at && !item?.unlock_invoice_at;

      return (lock || item?.can_editable == 'false')? true: false;
    }else{
      return true;
    }
  }

  canDelete(item){
    if((item.job_order_calibration_item_info?.length == 0 && item.transport_receive_item_info?.length == 0) || 
    (item.transport_receive_item_status == 'CANCELED' && item.job_order_calibration_item_info?.length == 0) ||
    (item.transport_receive_item_info?.length == 0 && item.job_order_calibration_item_status == 'CRF-CANCELED') ||
    (item.transport_receive_item_status == 'CANCELED' && item.job_order_calibration_item_status == 'CRF-CANCELED')
    ){
      return true;
    }else{
      return false;
    }
  }

  canMapping(item){
    if(this.docStatusId =='REVISE' && this.isMapping &&
    item.service_location == 'Onsite' && item.job_order_calibration_item_info.length == 0
    ){
      return true;
    }else{
      return false;
    }
  }

  mapping(item){
    this.mappingId = item.quotation_mit_item_id;

    setTimeout(() => {
      $(`#modalMapping`).modal("show");
    }, 100);
  }

  async replaceMapping(e){
    if(e){
      let item = this.quotationMitItemGroups.find(item=> item.quotation_mit_item_id == this.mappingId);

      let promises = [];

      this.QuotationMitItemService.load(null, {quotation_mit_item_id: e}).then(async res=>{
        let mitItem = res.resultData[0];

        if(mitItem){
          this.deleteMitItemList.push(item);

          mitItem['quotation_mit_item_details'] = [];
          mitItem['quotation_mit_item_attributes'] = [];
          
          mitItem.order_by = item.order_by;
          mitItem.quotation_info_id = this.quotation_info_id;

          mitItem.quotation_mit_item_attributes = mitItem?.quotation_mit_item_attribute_info || [];

          mitItem.quotation_mit_item_details = mitItem?.quotation_mit_item_detail_info || [];

          mitItem.job_order_calibration_item_info = mitItem.job_order_calibration_item_info[0];
          mitItem.quotation_info_id = this.quotation_info_id;

          mitItem.quotation_mit_item_details = await Promise.all(mitItem.quotation_mit_item_details.map(async (data: any)=>{
            let resultData = await this.mapMitItemData(data);

            return {
              ...resultData
            }
          }));

          mitItem.quotation_mit_item_details.sort((a, b) => (a.order_by > b.order_by? 1 : -1));

          mitItem = await this.MapMitItemService.calculateTotalAmountItem(mitItem);
          mitItem.price = mitItem.original_price;
          mitItem['isMapping'] = true;

          this.quotationMitItems[item.index] = mitItem;

          promises.push(
            this.setItem()
          )
          await Promise.all(promises);
        }
      })
      this.mappingId = "";
    }else{
      this.mappingId = "";
    }
  }
}