import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from "@angular/core";

@Component({
  selector: 'app-equipment-unlock',
  templateUrl: './equipment-unlock.component.html',
  styleUrls: ['./equipment-unlock.component.css']
})
export class EquipmentUnlockComponent implements OnInit {
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") private closeModal: ElementRef;
  @Input() status: any;
  @Input() id = "modalConfirmUnlock";
  constructor() {}

  ngOnInit(): void {}

  async onSave() {
    this.save.emit(this.closeModal.nativeElement);
  }

  onClose() {
    this.close.emit(null);
  }
}
