import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuotationNoteMasterService } from 'src/app/services';

@Component({
  selector: 'app-quotation-note-create',
  templateUrl: './quotation-note-create.component.html',
  styleUrls: ['./quotation-note-create.component.css']
})
export class QuotationNoteCreateComponent implements OnInit {
  form: FormGroup;
  noteList: FormArray;
  noteMasterList = [];
  noteNormalList = [];
  notes = [];
  @Input("submitted") submitted: boolean;
  @Input("note_type") note_type: string;
  
  status: any;
  pageLoad =  false;

  @Output() done = 
  new EventEmitter<any>();

  constructor( 
    private formBuilder: FormBuilder,
    private QuotationNoteMasterService: QuotationNoteMasterService) { }

  async ngOnInit() {
    this.pageLoad = false;
    
    this.createForm();
    await this.getNoteList();
    await Promise.all(this.noteMasterList.map(async (value: any) =>{
      this.noteList.push(this.createNoteItem(value));
    }));
    this.sentData();
  }

  createForm(){
    this.form = this.formBuilder.group({
      notes: this.formBuilder.array([]),
    });
    this.noteList = 
    this.form.get('notes') as FormArray;
  }

  get noteItemFormGroup() {
    return this.form.get('notes') as FormArray;
  }

  createNoteItem(data:any = {}): FormGroup {
    return this.formBuilder.group({
      quotation_note_id: this.formBuilder.control(data.quotation_note_id || null),
      quotation_info_id: this.formBuilder.control(data.quotation_info_id || null),
      quotation_note: this.formBuilder.control(data.quotation_note || "", [Validators.required]),
      note_type: [this.note_type],
      order: this.formBuilder.control(data.order || null)
    });
  }

  async getNoteList(){
    await this.QuotationNoteMasterService.load(null, {'order_by': 'order:asc'})
    .then(async (res) => {
      let resultData = res.resultData.sort((a, b) => (parseInt(a.order) > parseInt(b.order)? 1 : -1));

      resultData = resultData.map(value=>{
        return {
          ...value,
          id: value.quotation_note,
          text: value.quotation_note,
        }
      })
      if(this.note_type == 'standard_in'){
        this.notes = resultData.filter(item=> item.note_type == 'standard_in');
      }else{
        this.notes = resultData.filter(item=> item.note_type == 'general');
      }

      await Promise.all(res.resultData.map(async (value: any)=>{
        if(value.note_type == this.note_type){
          if(value.note_type == 'standard_in'){
            this.noteMasterList.push(value);
          }
        }else if (value.note_type == 'general'){
          this.noteNormalList.push(value);
        }
      }));
    });
  }

  addNote(){
    this.noteList.push(this.createNoteItem());
    this.sentData();
  }

  removeNote(index: number, item: any){
    this.noteList.removeAt(index);
    this.sentData();
  }

  async drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.noteList.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.noteList.value, event.previousIndex, event.currentIndex);
  }

  sentData(){
    this.done.emit(this.form);
  }

  // groupNotes(notes) {
  //   if (this.note_type == "standard_in"){
  //     var typeCheck = "standard_out"
  //   }else{
  //     var typeCheck = "standard_in"
  //   }
  //   let groups = {};
  //   for (let note of notes) {
  //     if (!groups[note.note_type] && note.note_type != typeCheck) {
  //       groups[note.note_type] = note;
  //     }
  //   }
  //   let groupNotes= {};

  //   for (let note of notes) {
  //     if (!groupNotes[note.note_type]) {
  //       groupNotes[note.note_type] = [];
  //     }
  //     groupNotes[note.note_type].push(note);
  //   }
  //   let groupByNotes = [];
  //   for (let group in groups) {
  //     groupByNotes.push({
  //       id: groups[group].note_type,
  //       text: groups[group].note_type== this.note_type? 'มาตรฐาน': 'ทั่วไป',
  //       children: [...groupNotes[group]],
  //     });
  //   }

  //   groupByNotes.sort(function(x, y) {
  //     return (x.id === y.id)? 0 : x.id? -1 : 1;
  //   });
  //   return groupByNotes;
  // }
}
