import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { 
  TransportreceiveContactService,
  TransportreceiveInfoService
} from '../../../../services/transport-receives';
import moment from 'moment';
import { 
  CustomerAddressService,
  CustomerContactService,
  EmployeeService, 
} from 'src/app/services';
import { QuotationUtilsService, NgSelect2Customer } from 'src/app/services/quotations';
import { Observable } from 'rxjs';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import { Location } from "@angular/common";

@Component({
  selector: 'app-transport-receive-show',
  templateUrl: './transport-receive-show.component.html',
  styleUrls: ['./transport-receive-show.component.css']
})
export class TransportreceiveShowComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  pageLoad = true;
  status: any;

  customerContactList = [];
  transportContactList = [];

  transport_receive_info_id: string;

  transportreceive = null;
  customer_info = null;
  @Select(UserInfoState.getUser)
  userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private fbd: FormBuilder,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private EmployeeService: EmployeeService,
    private TransportreceiveContactService: TransportreceiveContactService,
    private CustomerAddressService: CustomerAddressService,
    private TransportreceiveInfoService: TransportreceiveInfoService,
    private CustomerContactService: CustomerContactService,
    
    public _utils : QuotationUtilsService,
    public _ngSelect2Customer: NgSelect2Customer,
  ) { 
    this.transport_receive_info_id = 
    this.route.snapshot.paramMap.get("transport_receive_info_id");
  }

  async ngOnInit() {
    this._ngSelect2Customer.initPopOver();
    this.createForm();

    this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      this.userInfo = userInfo;
    });


    await this.EmployeeService.load(null, { emp_department_id: 'TRAN_DEP' })
    .then(res=>{
      this.transportContactList = res.resultData || {}
      this.transportContactList = this.transportContactList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info.firstname_th} ${elem.user_info.lastname_th}`,
        }
      });
    })
    await this.setData();
  }

  ngOnDestroy() {
    this._ngSelect2Customer.clearPopOver();
  }

  async setData(){
    await this.TransportreceiveInfoService.getById({transport_receive_info_id: this.transport_receive_info_id})
    .then(async res=>{
      let resultData = res.resultData || {};
      this.transportreceive = resultData;

      this.customer_info = res.resultData.customer_info;
      let data = {
        ...resultData,
        transport_type_id: resultData?.transport_type_info?.transport_type_name_th || "",
        customer_name: `[${resultData?.customer_info?.customer_code || ''}] ${resultData?.customer_info?.company_name || ''} ${resultData?.customer_info.branch?" (" + resultData?.customer_info.branch + ")": ""}`,
      }
      this.form.patchValue(data);
      this.form.controls['doc_date'].setValue(moment(resultData.doc_date).format('YYYY-MM-DD'));
      this.form.controls['pickup_date'].setValue(moment(resultData.pickup_date).format('YYYY-MM-DD'));
      this.form.controls['return_date'].setValue(moment(resultData.return_date).format('YYYY-MM-DD'));

      if(resultData.customer_address_id){
        await this.CustomerAddressService.getById({ customer_address_id: resultData.customer_address_id }).then((res)=>{
          if(res.success){
            var resultData = res.resultData;
            var address = this._utils.genAddress(resultData);
            this.form.controls['customer_address_id'].setValue(resultData.address_name + ' ('+ resultData.branch +')' + ` แผนก: ${resultData.department}`);
            this.form.controls['customer_address'].setValue(address); 
          }else{
            this.form.controls['customer_address_id'].setValue(null);
            this.form.controls['customer_address'].setValue(null); 
          } 
        })
      }

      await this.CustomerContactService.load(null, {
        order_by: "isMaster:desc",
        customer_id: resultData.customer_info.customer_id
      }).then((res) => {
        this.customerContactList = res.resultData || [];
        this.customerContactList = this.customerContactList.map(function(elem) {
          return {
            id: elem.customer_contact_id,
            customer_id: elem.customer_id,
            text: `${elem.firstname} ${elem.lastname}`,
            data: elem
          }
        });
      });

      await this.TransportreceiveContactService.load(null, { transport_receive_info_id: this.transport_receive_info_id }).then((res) => {
        let ids = res.resultData.map(v=>{
          return v.customer_contact_id;
        })
        this.form.controls['customer_contact_ids'].setValue(ids);
      });
      this.pageLoad = false;
    })
  }

  createForm(){
    this.form = this.fbd.group({
      doc_no: [""],
      doc_date: [""],
      customer_id: [""],
      customer_name: [""],
      customer_address_id: [""],
      customer_address: [""],
      transport_type_id: ["", [Validators.required]],
      pickup_date: [""],
      return_date: [""],
      doc_status_id: [""],
      receiver_id: ["", [Validators.required]],
      note: [""],
      accessory: [""],
      extra_message: [""],

      customer_contact_ids: [[], [Validators.required]],
    });

    this.form.disable();
  }

  async cancel(closeModal){
    if(closeModal){
      var response = await this.TransportreceiveInfoService.update({
        ...this.transportreceive,
        doc_status_id:'VOIDED'
      });

      if(response.success){
        await this.router.navigateByUrl("/transport-receive", {
          state: {
            status: response.success,
          },
        })
      }else{
        this.status = response.error;
      }
      closeModal.click();
    }
  }

  openPdf(transportreceive) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/transport-document/' + transportreceive.transport_receive_info_id])
    );
  
    window.open(url, '_blank');
  }

  back(): void {
    this.location.back();
  }
}
