import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { CustomerAddressService, CustomerContactService, CustomerInfoService, UtilsService } from 'src/app/services';
import { CalculateFunctionService, NgSelect2Customer, QuotationUtilsService } from 'src/app/services/quotations';
import { AccBillingNoteInfoService, AccBillingNoteContactService } from 'src/app/services/billing-note';
import { CoreService } from 'src/app/services/billing-note/core.service';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';

declare var $: any;

@Component({
  selector: 'app-billing-note-edit',
  templateUrl: './billing-note-edit.component.html',
  styleUrls: ['./billing-note-edit.component.css']
})
export class BillingNoteEditComponent implements OnInit {
 form: FormGroup;

 submitted = false;
 pageLoad = false;
 disableSaveBtn = false;

 acc_billing_note_info_id: string;

 contacts = [];
 customerList: any = [];
 customerAddressList: any = [];
 customerContactList: any = [];
 tmpCustomerAddressList: any = [];
 tmpCustomerContactList: any = [];
 count: number = 0;
 //any
 status: any;
 select2Options = null;
 customer_info = null;
 billingNoteItem = null;
 billingNoteInfo = null;

 @Select(UserInfoState.getUser) userInfo$: Observable<any>;
 userInfo = null;

 constructor(
   private router: Router,
   private _fbd: FormBuilder,
   private route: ActivatedRoute,
   private CoreService: CoreService,
   private CustomerAddressService: CustomerAddressService,
   private CustomerContactService: CustomerContactService,
   private AccBillingNoteInfoService: AccBillingNoteInfoService,
   private AccBillingNoteContactService: AccBillingNoteContactService,

   public UtilsService: UtilsService,
   public util : QuotationUtilsService,
   public calculate: CalculateFunctionService,
   public NgSelect2Customer: NgSelect2Customer
 ) {
   this.acc_billing_note_info_id = 
   this.route.snapshot.paramMap.get("acc_billing_note_info_id");
   }

 async ngOnInit() {
   this.createForm();

   this.userInfo$.subscribe(user=>{
     if(!user) return;
     this.userInfo = user;
   })

   await this.AccBillingNoteInfoService.getById({acc_billing_note_info_id: this.acc_billing_note_info_id})
   .then(async res=>{

     let resultData = res.resultData || {};
     this.billingNoteInfo = resultData;

     this.customer_info = res.resultData.customer_info;
     let data = {
       ...resultData,
       customer_name: `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''} ${this.customer_info?.branch?" (" + this.customer_info?.branch + ")": ""}`,
     }
     this.form.patchValue(data);

     if(resultData.customer_id){
        await this.setCustomerContact(resultData.customer_id);
        await this.setCustomerAddress(resultData.customer_id);
        await this.selectAddressName(resultData.customer_address_id);
     }

     await this.AccBillingNoteContactService.load(null, { acc_billing_note_info_id: this.acc_billing_note_info_id }).then((res) => {
      this.contacts = res.resultData || [];
      let ids = res.resultData.map(v=>{
        return v.customer_contact_id;
      })
      this.form.controls['customer_contact_ids'].setValue(ids);
    });
   })
   this.pageLoad = true;
 }

 createForm(){
   this.form = this._fbd.group({
     acc_billing_note_info_id:  [""],
     doc_no:  [""],
     customer_id: ["", [Validators.required]],
     customer_name: [""],
     doc_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
     doc_status_id: ["", [Validators.required]],
     customer_address_id: ["", [Validators.required]],
     customer_address: [""],
     doc_note: [""],
     grand_total: [0.00],
     appointment_location: [""],
     appointment_time_from: ["00:00"],
     appointment_time_to: ["00:00"],
     sent_at: [""],
     appointment_date: ["", [Validators.required]],
     customer_contact_ids: [[], [Validators.required]],
   });
 }

 async setCustomerAddress(event){
   const customer = this.customer_info;
   let address = null;
   await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
   .then(async (res) => {
     let customer = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];

     if(customer.length > 0){
       if(customer.length > 0){
         address = {
           id: customer[0].customer_address_id,
           customer_id: customer[0].customer_id,
           text: customer[0]?.branch?`${customer[0].address_name} (${customer[0]?.branch})` + ` แผนก: ${customer[0].department}`: `${customer[0].address_name}` + ` แผนก: ${customer[0].department}`,
           address_name: this.util.genAddress(customer[0]),
         }
       } 
     }
   });
   
   if(customer){
     await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
     .then(async (res) => {
     this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];

     this.tmpCustomerAddressList= this.tmpCustomerAddressList.map(elem => (
       {
         id: elem.customer_address_id,
         customer_id: elem.customer_id,
         text: elem.branch? `${elem.address_name} (${elem.branch})` + ` แผนก: ${elem.department}`: `${elem.address_name}` + ` แผนก: ${elem.department}`,
         address_name: this.util.genAddress(elem)
       } 
     ));
     });
     let customerAddress = this.tmpCustomerAddressList;
      if(address){
        this.customerAddressList = [
          ...[address],
          ...customerAddress
        ];
      }else{
        this.customerAddressList = [
          ...customerAddress
        ];
      }

     if(this.customerAddressList.length > 0){
       const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
       if(checkAddress.length == 0) {
         if(address){
           this.form.get('customer_address_id').setValue(address.id);
           this.selectAddressName(address.id);
         }else{
          this.form.controls['customer_address_id'].setValue(this.customerAddressList[0]?.id);
          this.selectAddressName(this.customerAddressList[0]?.id);
         }
       }
     }else{
       if(address){
        this.customerAddressList = [
          ...[address],
        ];
         this.form.get('customer_address_id').setValue(address.id);
         this.selectAddressName(address.id);
       }
     }
   }
 }

 async setCustomerContact(event){
   await this.CustomerContactService.load(null, {
     order_by: "isMaster:desc",
     customer_id: event
   }).then((res) => {
     this.tmpCustomerContactList = res.resultData || [];
     this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
       return {
         id: elem.customer_contact_id,
         customer_id: elem.customer_id,
         text: `${elem.firstname} ${elem.lastname}`,
         data: elem
       }
     });
   });
   let customerContact = this.tmpCustomerContactList.filter(
     item => (item.customer_id === event)
   );
   this.customerContactList = [
     ...customerContact
   ];
 }


 async selectAddressName(event){
   let address = this.customerAddressList.find(v=> (v.id === event));
   if(address){
     this.form.get('customer_address').setValue(address.address_name);
   }else{
    this.form.controls['customer_address'].setValue("");
    this.form.controls['customer_address_id'].setValue("");
   }
   if(address && this.customerContactList.length > 0){
    let contacts = this.customerContactList.filter(item=> item.data.related_customer_address_id == address.id);
      const ids = contacts.map(v=>{
        return v.id;
      })
      this.form.get('customer_contact_ids').setValue(ids);
  }
 }

 saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
 }
 save(){
  this.form.get('doc_status_id').setValue("APPROVED");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
 }

 async submit(){
   let data = this.form.getRawValue();
   this.disableSaveBtn = true;

   if(data.customer_contact_ids.length > 0){
     data.customer_contact_ids = data.customer_contact_ids.map(v=>{
       let select = this.contacts.find(item=> item.customer_contact_id == v);
       if(select){
         return {...select};
       }else{
         return {customer_contact_id: v};
       }
     })
   }

   const deleteContactList =  this.contacts.map(v=>{
     let select = data.customer_contact_ids.find(item=> item.customer_contact_id == v.customer_contact_id);
     if(!select){
       return {...v};
     }
   }).filter(r => r != undefined);
   let billingNoteResponse = await this.CoreService.editBillingNote(data, deleteContactList, this.billingNoteItem);

   if(billingNoteResponse.status){
     await this.router.navigateByUrl("/billing-note", {
       state: {
         status: billingNoteResponse.message,
       },
     })
   }else{
     if(billingNoteResponse.message == 'codeDuplicate'){
      this.status = billingNoteResponse.error;
     }else{
      this.status = billingNoteResponse.message;
     }
   }
   this.disableSaveBtn = false;
  }

  getBillingNoteItems(event){
   this.billingNoteItem = event;
   let total = 0;
   this.billingNoteItem.billingNoteItems.map(item=>{
    if(item.data.grand_total){
      if(item.doc_type =='CREDIT_NOTE'){
        total = total - item.data.grand_total; 
      }else{
        total = total + item.data.grand_total; 
      }
    }
   })
   this.form.get('grand_total').setValue(total);
  }
}
