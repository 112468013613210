import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ProductService } from "src/app/services";

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.css"],
})
export class ProductListComponent implements OnInit {
  isLoading = true;
  products = [];
  status: string;
  product_id: string;
  reset: boolean = false;
  filter = {};
  load: Function = this.ProductService.load;
  constructor(private router: Router, private ProductService: ProductService) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit() {}
  onChangePage(products: Array<any>) {
    this.products = products;
    this.isLoading = false;
  }
  select(product_id: string) {
    this.product_id = product_id;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
      this.product_id = null;
    }, 50);
  }
  search(filter) {
    this.filter = filter;
  }
  async delete(closeModalEl) {
    let response = await this.ProductService.delete({
      product_id: this.product_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }
}
