import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { GETService } from 'src/app/services';
import { AccTaxInvoiceItemService } from 'src/app/services/tax-invoice';
import { AccPaymentService } from 'src/app/services/invoice';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccRecivedVoucherItemService } from 'src/app/services/received-voucher';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-tax-invoice-payment',
  templateUrl: './tax-invoice-payment.component.html',
  styleUrls: ['./tax-invoice-payment.component.css']
})
export class TaxInvoicePaymentComponent implements OnInit {
  pageLoad: boolean = true;

  status:string;
  acc_tax_invoice_info_id: string;

  @Input() creditNoteTotal: number;
  @Input() debitNoteTotal: number;
  @Input() tax_invoice_info: any;
  @Output() done = new EventEmitter<any>();

  paymentList = [];
  taxInvoiceItems = [];
  payments = [];

  payment = null;
  loadPayment = true

  balance:number = 0;
  sum_price:number = 0;
  sum_grand_total:number = 0;
  sum_value_before_wht:number = 0;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private GETService: GETService,
    private AccPaymentService: AccPaymentService,
    private AccTaxInvoiceItemService: AccTaxInvoiceItemService,
    private AccRecivedVoucherItemService: AccRecivedVoucherItemService,
    
    public calculate: CalculateFunctionService,
    public util : QuotationUtilsService,
  ) { 
    this.acc_tax_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_tax_invoice_info_id");
  }

  async ngOnInit() {
    await this.GETService.paymentMethod(null)
    .then(res=>{
      this.paymentList = res.resultData;
    })
    if(this.acc_tax_invoice_info_id){
      await this.AccTaxInvoiceItemService.load(null, {acc_tax_invoice_info_id: this.acc_tax_invoice_info_id})
      .then(async res=>{
        this.taxInvoiceItems = res.resultData || [];
        this.taxInvoiceItems.map(value=>{
          if(value.item_type=='calibration_service'){
            this.sum_price = this.sum_price + this.calculate.sumMit(value);
          }else{
            if(value.quotation_item_id == '' && value.product_id == ''){
              this.sum_price = this.sum_price - value.discount;
            }else{
              this.sum_price = this.sum_price + this.calculate.sumProduct(value);
            }
          }
        })
      })
    }
    this.sum_price = this.sum_price -  this.util.convertStingToNumber(this.tax_invoice_info.discount) + this.debitNoteTotal - this.creditNoteTotal;

    await this.getPayment();
    this.pageLoad = false;
  }
  
  ngOnChanges(changes: SimpleChanges) {}

  
  async getPayment(){
    this.sum_grand_total = 0;
    this.sum_value_before_wht = 0;
    this.balance = 0;
    
    await this.AccPaymentService.load(null, {acc_document_info_id: this.acc_tax_invoice_info_id, order_by: 'payment_date:asc,created_at:asc'})
    .then(async res=>{
      this.payments = res.resultData;
      await Promise.all(this.payments.map(async (item) =>{

        await this.AccRecivedVoucherItemService.load(null, {acc_document_payment_id: item.acc_payment_id})
        .then(res=>{
          if(res?.resultData?.length > 0){
            item.created_acc_received_voucher_item = true;
          }else{
            item.created_acc_received_voucher_item = false;
          }
        })

        this.sum_grand_total = this.sum_grand_total + item.grand_total;
        this.sum_value_before_wht = this.sum_value_before_wht + item.value_before_wht;
      }));

    });
    if(this.sum_price > this.sum_value_before_wht){
      this.balance = this.sum_price - this.sum_value_before_wht;
    }
    this.done.emit(this.payments);
  }

  async savePayment(event){
    if(event){
      this.status = event.success || event.error;
      await this.getPayment();
    }

    this.loadPayment = null;
    setTimeout(() => {
      this.loadPayment = true;
    }, 100);
  }

  select(payment){
    this.payment = payment;
  }

  resetPayment(event){
    this.payment = null;
  }

  getPaymentName(payment): string{
    let findPayment = this.paymentList.find(pay=> pay.payment_method_id == payment.payment_method_id)
    return findPayment.payment_method_name_th || "";
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.payments, event.previousIndex, event.currentIndex);
  }

  async updateReceivedPayment(payment){
    let data = {
      ...payment,
      wht_received_date: moment(new Date()).format("YYYY-MM-DD")
    }
    let response = await this.AccPaymentService.update(data);
    this.status = response.success || response.error;
    await this.getPayment();
  }

  openReceived(payment){
    this.router.navigate([`received-voucher/${this.tax_invoice_info.customer_id}/create`],{ queryParams: { acc_payment_id: payment.acc_payment_id }});
  }
}
