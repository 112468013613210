import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationContactService {
  async create({
    quotation_info_id,
    customer_contact_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-contact`, {
        quotation_info_id,
        customer_contact_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_contact_id,
        quotation_info_id,
        customer_contact_id
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/quotation-contact/${quotation_contact_id}`, {
        quotation_info_id,
        customer_contact_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_contact_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-contact/${quotation_contact_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-contact`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_contact_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-contact/${quotation_contact_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
