<div class="app-content content" *ngIf="customer">
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">ข้อมูลลูกค้า</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/customer']">จัดการข้อมูลลูกค้า</a>
              </li>
              <li class="breadcrumb-item active">ข้อมูลลูกค้า</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            [routerLink]="['/customer']"
            type="button"
            class="btn btn-light btn-min-width ml-1"
          >
            <i class="fa fa-arrow-left icon-left"></i> กลับ
          </button>
          <button
            *ngIf="
              customer.customer_state_info.customer_state_id === 'LEAD' &&
              canUnqualified"
            type="button"
            class="btn btn-light btn-min-width ml-1"
            data-toggle="modal"
            data-target="#unqualify"
          >
            <i class="feather icon-x icon-left"></i> Unqualified
          </button>
          <!-- <button
            *ngIf="
              customer.customer_state_info.customer_state_id === 'LEAD' &&
              canEditCustomer
            "
            type="button"
            class="btn btn-blue btn-min-width"
            data-toggle="modal"
            data-target="#qualify"
          >
            <i class="feather icon-repeat icon-left"></i> Convert
          </button> -->
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>
      <section class="basic-elements">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-head">
                <div class="card-header">
                  <h4 class="card-title d-inline mr-50">
                    [{{ customer.customer_code }}]
                    {{ customer.company_name | dash }} 
                    {{ customer.branch?  '('+customer.branch+')':''}}
                  </h4>
                  <span
                    class="badge badge-info"
                    [ngClass]="{
                      'bg-yellow bg-accent-4':
                        customer.customer_state_id === 'OPPORTUNITY',
                      ' badge-success':
                        customer.customer_state_id === 'CUSTOMER'
                    }"
                    >{{
                      customer.customer_state_info?.customer_state_name_en
                        | dash
                    }}</span
                  >

                  <ng-container [ngSwitch]="true">
                    <ng-container *ngSwitchCase="customer.customer_state_id === 'CUSTOMER' && (userInfo | async)?.emp_info?.emp_level_id.startsWith(
                      'MANAGER'
                    )">
                    <fieldset
                    class="form-group d-inline ml-1 mb-0 width-100"
                  >
                    <select
                      class="form-control form-control-sm"
                      id="basicSelect"
                      [(ngModel)]="customer.customer_level_id"
                      (change)="setCustomerLevel($event)"
                    >
                      <option
                        [value]="customer_level.customer_level_id"
                        *ngFor="let customer_level of customerLevels"
                      >
                        {{ customer_level.customer_level_name_en | dash }}
                      </option>
                    </select>
                
                  </fieldset>
                  </ng-container>
    
                    <ng-container *ngSwitchDefault>
                      <span class="badge btn-blue ml-1">
                        {{ getCustomerlevel().customer_level_name_en | dash }}
                      </span>
                    </ng-container>
                 </ng-container>

          

                  <!-- Opportunity -->
                  <!-- <div
                    class="heading-elements text-right"
                    *ngIf="customer.customer_state_id === 'OPPORTUNITY'"
                  >
                    <div class="badge bg-amber bg-accent-4 mb-50">
                      ความสนใจ: {{ customer.interest_level }}
                    </div>
                    <h2 class="text-primary">ประเมินยอดขาย: 250,000 บาท</h2>
                  </div> -->
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-md-7 col-12 mb-2">
                      <div class="border-primary p-1 h-100">
                        <h5 class="mb-1">
                          <i class="feather icon-info"></i> ข้อมูลบริษัท
                        </h5>
                        <table class="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td>เลขประจำตัวผู้เสียภาษี</td>
                              <td>{{ customer.tax_id | dash }}</td>
                            </tr>
                            <tr *ngIf="customer.is_see_all">
                              <td>ที่อยู่</td>
                              <td [ngSwitch]="customer.customer_address_info?.province_id">
                                <span *ngSwitchCase="'1'">
                                  {{ customer.customer_address_info?.address | dash }}
                                  {{(customer.customer_address_info?.district_name)?'แขวง': ''}}{{
                                    customer.customer_address_info?.district_name
                                  }}
                                  {{(customer.customer_address_info?.amphure_name)?'เขต': ''}}{{
                                    customer.customer_address_info?.amphure_name
                                  }}
                                  {{
                                    customer.customer_address_info?.province_name
                                  }}
                                  {{
                                    customer.customer_address_info?.zip_code
                                  }}
                                </span>
                                <span *ngSwitchDefault>
                                  {{ customer.customer_address_info?.address | dash }}
                                  {{(customer.customer_address_info?.district_name)?'ตำบล': ''}}{{
                                    customer.customer_address_info?.district_name
                                  }}
                                  {{(customer.customer_address_info?.amphure_name)?'อำเภอ': ''}}{{
                                    customer.customer_address_info?.amphure_name
                                  }}
                                  {{
                                    customer.customer_address_info?.province_name
                                  }}
                                  {{
                                    customer.customer_address_info?.zip_code
                                  }}
                                </span>
                              </td>
                            </tr>
                            <tr *ngIf="customer.is_see_all">
                              <td>เบอร์โทรศัพท์</td>
                              <td>
                                {{ customer.phonenumbers | dash }}
                              </td>
                            </tr>
                            <tr>
                              <td>พนักงานขาย</td>
                              <td class="d-flex flex-row align-items-end">
                                <!-- {{
                                  customer.sales_assignment_info?.user_info
                                    ?.firstname_th | dash
                                }}
                                {{
                                  customer.sales_assignment_info?.user_info
                                    ?.lastname_th
                                }} -->
                                <div style="margin-right: 2px">
                                  <employee-select
                                    *ngIf="isReady"
                                    [emp]="sales_assignment_info"
                                    [placeholder]="'รหัสผู้รับผิดชอบ'"
                                    (valueChanged)="setSalesUserId($event)"
                                    [disabled]="!isEditSales"
                                  ></employee-select>

                                  <!-- <employee-select
                                    *ngIf="isReady && isEditSales"
                                    [emp]="sales_assignment_info"
                                    [placeholder]="'รหัสผู้รับผิดชอบ'"
                                    (valueChanged)="setSalesUserId($event)"
                                    [disabled]="!isEditSales"
                                  ></employee-select> -->
                                </div>

                                <div
                                  *ngIf="(userInfo | async)?.is_sales_manager"
                                >
                                  <button
                                    class="btn btn-sm btn-warning"
                                    (click)="isEditSales = true"
                                    *ngIf="!isEditSales"
                                  >
                                    <i class="fa fa-pencil"></i>
                                  </button>
                                  <div class="d-flex flex-column">
                                    <button
                                      (click)="resetEditSales()"
                                      *ngIf="isEditSales"
                                      class="btn btn-sm btn-warning"
                                      style="margin-bottom: 1px"
                                    >
                                      <i class="fa fa-times"></i>
                                    </button>
                                    <button
                                      *ngIf="isEditSales"
                                      (click)="force()"
                                      class="btn btn-sm btn-primary"
                                    >
                                      <i class="fa fa-save"></i>
                                    </button>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div
                      class="col-md-5 col-12 mb-2"
                      *ngIf="
                        customer?.sales_assignment_info?.user_id ===
                          (userInfo | async)?.user_id ||
                        ((userInfo | async)?.is_co_sales && customer?.inCharge) ||
                        ((userInfo | async)?.is_sales_manager) ||
                        customer.is_free ||
                        (userInfo | async)?.emp_info?.emp_department_id.startsWith(
                          'ACC_DEP'
                        ) || 
                        ((userInfo | async)?.is_sales_supervisor && customer?.inCharge) ||
                        ((userInfo | async)?.is_co_sales_supervisor)
                      "
                    >
                      <div class="border-primary p-1 h-100">
                        <h5 class="mb-1">
                          <i class="feather icon-info"></i> ข้อมูลผู้ติดต่อหลัก
                        </h5>
                        <table class="table table-borderless mb-0">
                          <tbody>
                            <tr>
                              <td>ชื่อผู้ติดต่อ</td>
                              <td>
                                {{ customer.master_contact?.firstname | dash }}
                                {{ customer.master_contact?.lastname }}
                              </td>
                            </tr>
                            <tr>
                              <td>เบอร์โทรศัพท์</td>
                              <td>
                                {{
                                  customer.master_contact?.phonenumber | dash
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>E-mail</td>
                              <td>
                                {{ customer.master_contact?.email | dash }}
                              </td>
                            </tr>
                            <tr>
                              <td>Line Id</td>
                              <td>
                                {{ customer.master_contact?.line_id | dash }}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="mt-1">
                    <ul class="nav nav-tabs nav-justified"  
                    role="tablist">
                      <li class="nav-item" (click)="tab = 'activity-tab'"
                      
                      *ngIf="(customer.is_free || customer.is_see_all  ||
                      (userInfo | async)?.emp_info?.emp_department_id.startsWith('SALES_DEP')) &&
                       !(userInfo | async)?.emp_info?.emp_department_id.startsWith(
                          'ACC_DEP'
                        )">
                        <a
                          class="nav-link"
                          [class.active] = "tab == 'activity-tab'"
                          id="activity-tab"
                          data-toggle="tab"
                          href="#activity"
                          aria-controls="activity"
                          role="tab"
                          aria-selected="true"
                          >กิจกรรม</a
                        >
                      </li>
                      <li class="nav-item" (click)="tab = 'address-tab'">
                        <a
                          class="nav-link"
                          [class.px-4] = "(userInfo | async)?.emp_info?.emp_department_id.startsWith(
                            'ACC_DEP'
                          )" 
                          [class.active] = "tab == 'address-tab'"
                          id="address-tab"
                          data-toggle="tab"
                          href="#address"
                          aria-controls="address"
                          role="tab"
                          aria-selected="false"
                          >ข้อมูลบริษัท</a
                        >
                      </li>
                      <li
                        class="nav-item"
                        (click)="tab = 'address-more-tab'"
                        *ngIf="(customer.is_free || customer.is_see_all) || (userInfo | async)?.emp_info?.emp_department_id.startsWith(
                          'ACC_DEP'
                        )"
                      >
                        <a
                          class="nav-link"
                          [class.active] = "tab == 'address-more-tab'"
                          id="address-more-tab"
                          data-toggle="tab"
                          href="#address-more"
                          aria-controls="address-more"
                          role="tab"
                          aria-selected="false"
                          >ข้อมูลที่อยู่อื่น ๆ</a
                        >
                      </li>
                      <li
                        class="nav-item"
                        (click)="tab = 'contact-tab'"
                        *ngIf="customer.is_see_all || (userInfo | async)?.emp_info?.emp_department_id.startsWith(
                          'ACC_DEP'
                        )"
                      >
                        <a
                          class="nav-link"
                          [class.active] = "tab == 'contact-tab'"
                          id="contact-tab"
                          data-toggle="tab"
                          href="#contact"
                          aria-controls="contact"
                          role="tab"
                          aria-selected="false"
                          >ข้อมูลผู้ติดต่อ</a
                        >
                      </li>

                      <li class="nav-item" (click)="tab = 'marketing-tab'"
                      *ngIf="!(userInfo | async)?.emp_info?.emp_department_id.startsWith(
                        'ACC_DEP'
                      )">
                        <a
                          class="nav-link"
                          [class.active] = "tab == 'marketing-tab'"
                          id="marketing-tab"
                          data-toggle="tab"
                          href="#marketing"
                          aria-controls="marketing"
                          role="tab"
                          aria-selected="false"
                          >วิเคราะห์การขาย</a
                        >
                      </li>

                      <li class="nav-item" (click)="tab = 'quotation-tab'" 
                      *ngIf="customer.is_see_all && !(userInfo | async)?.emp_info?.emp_department_id.startsWith(
                        'ACC_DEP'
                      )">
                        <a
                          class="nav-link"
                          [class.active] = "tab == 'quotation-tab'"
                          id="quotation-tab"
                          data-toggle="tab"
                          href="#quotation"
                          aria-controls="quotation"
                          role="tab"
                          aria-selected="false"
                          >ใบเสนอราคา</a
                        >
                      </li>
                    </ul>
                    <div class="tab-content px-1 pt-1">
                      <div
                        class="tab-pane"
                        [class.active] = "tab == 'activity-tab'"
                        [class.in] = "tab == 'activity-tab'"
                        id="activity"
                        aria-labelledby="activity-tab"
                        role="tabpanel"
                      >
                        <app-customer-activity
                          *ngIf="tab === 'activity-tab' && isReady"
                          [can-edit-customer]="canEditCustomer"
                        ></app-customer-activity>
                      </div>
                      <div
                        class="tab-pane"
                        id="address"
                        [class.active] = "tab == 'address-tab'"
                        [class.in] = "tab == 'address-tab'"
                        aria-labelledby="address-tab"
                        role="tabpanel"
                      >
                        <app-customer-address
                          *ngIf="customer && tab === 'address-tab'"
                          [can_see_all]="customer.is_see_all"
                          [customer]="customer"
                          [can-edit-customer]="canEditCustomer"
                          (done)="ngOnInit()"
                        ></app-customer-address>
                      </div>

                      <div
                        class="tab-pane"
                        id="address-more"
                        [class.active] = "tab == 'address-more-tab'"
                        [class.in] = "tab == 'address-more-tab'"
                        aria-labelledby="address-more-tab"
                        role="tabpanel"
                      >
                        <app-customer-address-list
                          *ngIf="tab === 'address-more-tab'"
                          [provinces]="provinces"
                          [can-edit-customer]="canEditCustomer"
                        ></app-customer-address-list>
                      </div>
                      <div
                        class="tab-pane"
                        id="contact"
                        [class.active] = "tab == 'contact-tab'"
                        [class.in] = "tab == 'contact-tab'"
                        aria-labelledby="contact-tab"
                        role="tabpanel"
                      >
                        <app-customer-contact
                          *ngIf="tab === 'contact-tab'"
                          [can-edit-customer]="canEditCustomer"
                          (mark-master-contact)="ngOnInit()"
                        ></app-customer-contact>
                      </div>
                      <div
                        class="tab-pane"
                        id="marketing"
                        [class.active] = "tab == 'marketing-tab'"
                        [class.in] = "tab == 'marketing-tab'"
                        aria-labelledby="marketing-tab"
                        role="tabpanel"
                      >
                        <app-customer-marketing
                          *ngIf="tab === 'marketing-tab'"
                          [can-edit-customer]="canEditCustomer"
                        ></app-customer-marketing>
                      </div>

                      <div
                      class="tab-pane"
                      id="quotation"
                      [class.active] = "tab == 'quotation-tab'"
                      [class.in] = "tab == 'quotation-tab'"
                      aria-labelledby="quotation-tab"
                      role="tabpanel"
                    >
                      <app-customer-quotation 
                        *ngIf="tab === 'quotation-tab'">
                      </app-customer-quotation>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<!-- Qualified Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="qualify"
  tabindex="-1"
  role="dialog"
  aria-labelledby="qualifyModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary white">
        <h4 class="modal-title" id="qualifyModal">Qualified</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          ยืนยันการเปลี่ยนสถานะเป็น
          <span class="text-primary">"Opportunity"</span>
        </p>
        <form [formGroup]="qualifiedForm">
          <form-group
            [control]="qualifiedForm.controls.interest_level"
            [submitted]="submitted"
          >
            <label class="col-md-4 label-control" for="interest_level">
              ระดับความสนใจ <span class="danger">*</span>
            </label>
            <div class="col-md-8">
              <select
                class="form-control"
                id="interest_level"
                formControlName="interest_level"
              >
                <option value="">กรุณาเลือกข้อมูล</option>
                <option value="มาก">มาก</option>
                <option value="ปานกลาง">ปานกลาง</option>
                <option value="น้อย">น้อย</option>
              </select>
            </div>
          </form-group>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtnQualified
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button
          type="button"
          class="btn btn-primary"
          (click)="convert('qualified')"
        >
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Unqualified Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="unqualify"
  tabindex="-1"
  role="dialog"
  aria-labelledby="unqualifyModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary white">
        <h4 class="modal-title" id="unqualifyModal">Unqualified</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>
          ยืนยันการคัดกรองเป็น
          <span class="text-danger">"ไม่ผ่านการคัดกรอง"</span>
        </p>
        <form [formGroup]="unqualifiedForm">
          <form-group
            [control]="unqualifiedForm.controls.revoked_reason"
            [submitted]="submitted"
          >
            <label class="col-md-3 label-control" for="revoked_reason">
              เหตุผล <span class="danger">*</span>
            </label>
            <div class="col-md-9">
              <select
                class="form-control"
                id="revoked_reason"
                formControlName="revoked_reason"
                (change)="selectRevokedReason($event.target.value)"
              >
                <option value="">กรุณาเลือกข้อมูล</option>
                <option value="ไม่สามารถให้บริการได้">
                  ไม่สามารถให้บริการได้
                </option>
                <option value="เน้นราคา แข่งขันราคาไม่ไหว">
                  เน้นราคา แข่งขันราคาไม่ไหว
                </option>
                <option value="ไม่สามารถติดต่อลูกค้าได้ หรือไม่มีความคืบหน้าใด ๆ">
                  ไม่สามารถติดต่อลูกค้าได้ หรือไม่มีความคืบหน้าใด ๆ
                </option>
                <option value="เน้น ISO ทุกรายการ">เน้น ISO ทุกรายการ</option>
                <option value="ติดสัญญา หรือติดใช้บริการกับเจ้าเดิม">ติดสัญญา หรือติดใช้บริการกับเจ้าเดิม</option>
                <option value="Other">อื่นๆ</option>
              </select>
            </div>
          </form-group>

          <form-group
            [control]="unqualifiedForm.controls.revoked_other_reason"
            [submitted]="submitted"
            *ngIf="unqualifiedForm.value.revoked_reason === 'Other'"
          >
            <label class="col-md-3 label-control" for="revoked_other_reason"
              >หมายเหตุ <span class="danger">*</span></label
            >
            <div class="col-md-9">
              <textarea
                id="revoked_other_reason"
                class="form-control"
                rows="3"
                formControlName="revoked_other_reason"
              >
              </textarea>
            </div>
          </form-group>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtnUnqualified
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button
          type="button"
          class="btn btn-primary"
          (click)="convert('unqualified')"
        >
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
