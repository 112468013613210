<div class="container app-content overflow-container">
  <div class="btn-section text-right border-bottom">
    <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(receiveInfo)">
      <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
    </button>
  </div>
  <div  *ngFor="let page of pages; let i = index; let lastIndex = last">
    <div class="pdf" id="receive_{{i}}">
      <div class="container mt-5" *ngIf="i==0">
        <div class="row">
          <div class="col-2"></div>
          <img class="col-1 text-right p-0" src="/assets/app-assets/images/pdf/logo1.png" width="90px"
              height="110px">
          <div class="col-6 text-center pt-3">
              <h2>บริษัท มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี จำกัด</h2>
              <p>214 ถนนบางแวก แขวงบางไผ่ เขตบางแค กรุงเทพฯ 10160</p>
          </div>
          <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon2.jpg" width="90px"
              height="110px">
          <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon3.png" width="90px"
              height="110px">
          <div class="col-1"></div>
        </div>
        <p class="text-center">โทร. 0-2865-4647-8 ต่อ 201, 202 แฟ็กซ์. 02-865-4649 E-mail : account@mit.in.th</p>
      </div>
      <div class="container mt-2">
        <h2 class="text-center text-header pt-1" *ngIf="i==0">ใบสำคัญรับ(RECEIVED VOUCHER)</h2>
        <div class="row" *ngIf="i==0">
          <div class="col-12">
            <p class="text-right"><b>เลขที่ใบสำคัญรับ</b> {{receiveInfo?.doc_no}}</p>
          </div>
        </div>
        <div class="row" *ngIf="i==0">
          <div class="col-6 row">
            <div class="col-3">
              <p><b class="text-left">วันที่รับชำระ</b></p>
              <p><b class="text-left">ชื่อบริษัท</b></p>
            </div>
            <div class="col-9">
              <p class="text-left">{{receiveInfo?.doc_date | thai_date: "short":"date" | dash}}</p>
              <p class="text-left mb-0">{{receiveInfo?.customer_info.company_name}}</p>
              <div *ngFor="let item of receiveItems;">
                <div *ngIf="item.data.payment_method_info.payment_method_name_en !='เงินสด'">
                  <p class="text-left mb-0 custom-bullet">{{item.data.payment_method_info.payment_method_name_en =='เช็ค'?'เช็ค':'โอนเงิน'}}ธนาคาร {{item.data.bank_name | dash}} สาขา {{item.data.bank_branch | dash}}</p>
                  <p class="text-left pl-2">เลขที่บัญชี {{item.data.evidence_ref_no | dash}}</p>
                </div>
                <div *ngIf="item.data.payment_method_info.payment_method_name_en =='เงินสด'">
                  <p class="text-left mb-0 custom-bullet">เงินสด</p>
                </div>
              </div>
            </div>  
          </div>
          <div class="col-6">
          </div>
        </div>
        <div class="row">
          <table class="mx-auto table table-bordered">
            <thead>
              <tr>
                <th class="text-center" scope="col">ใบสำคัญรับ</th>
                <th class="text-center" scope="col">เลขที่ใบกำกับภาษี</th>
                <th class="text-center" scope="col">รายการ</th>
                <th class="text-center" scope="col">จำนวนเงิน</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center">{{receiveInfo?.doc_no}}</td>
                <td>
                  {{receiveInfo?.all_doc_no}}
                </td>
                <td class="text-left">ค่าสินค้าและบริการ</td>
                <td class="text-right">{{receiveInfo?.all_value_before_wht | number : '1.2-2'}}</td>
              </tr>
              <tr>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-left" style="color: red !important;" >ค่าธรรมเนียม</td>
                <td class="">
                  <div class="row">
                    <p class="col-6 text-left mb-0" style="color: red !important;">-</p>
                    <p class="col-6 text-right mb-0" style="color: red !important;">{{receiveInfo?.all_fee | number : '1.2-2'}}</p>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-left" style="color: red !important;" >ภาษีเงินได้ หัก ณ ที่จ่าย {{getPercent()}} %</td>
                <td class="">
                  <div class="row">
                    <p class="col-6 text-left mb-0" style="color: red !important;" >-</p>
                    <p class="col-6 text-right mb-0" style="color: red !important;" >
                      {{(receiveInfo?.all_wht_percent) | number : '1.2-2'}}
                    </p>
                  </div>  
                </td>
              </tr>
              <tr>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-left">ภาษีมูลค่าเพิ่ม</td>
                <td class="">
                  <div class="row">
                    <p class="col-6 text-left mb-0"></p>
                    <p class="col-6 text-right mb-0">
                      {{receiveInfo?.all_income_tax | number : '1.2-2'}}
                    </p>
                  </div>  
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr *ngIf="lastIndex">
                <td colspan="3" class="text-center">
                  <div class="row">
                    <div class="col-3">
                      <p class="text-center mb-0">ตัวอักษร</p>
                    </div>
                    <div class="col-6">
                      <p class="text-left mb-0">({{receiveInfo?.THBText}})</p>
                    </div>
                    <div class="col-3">
                      <p class="text-right mb-0">ยอดเงินสุทธิ</p>
                    </div>
                  </div>
                </td>
                <td class="text-right">{{receiveInfo?.grand_total | number : '1.2-2'}}</td>
              </tr>
            </tbody>
          </table> 
        </div>
      </div>
      <div class="container"  *ngIf="lastIndex">
        <div class="row">
          <div class="col-12 pb-2 pt-2">
            <div class="line-input">
            </div>
          </div>
          <div class="col-12 pb-2">
            <div class="line-input">
            </div>
          </div>
          <div class="col-12 pb-2">
            <div class="line-input"></div>
          </div>
          <div class="col-12 pb-2">
            <div class="line-input"></div>
          </div>
          <div class="col-12 pb-2">
            <div class="line-input"></div>
          </div>
          <div class="col-12 pb-2">
            <div class="line-input"></div>
          </div>
          <div class="col-12 pb-2">
            <div class="line-input"></div>
          </div>
        </div>
      </div>
      <div class="container mt-5" *ngIf="lastIndex">
        <div class="row">
          <div class="col-4">
            <div class="row mx-sm-1">
              <label for="inputPassword2" class="col-7 mt-2" style="font-weight: normal;">ผู้รับเงิน/Collector</label>
                  <div class="col-sm-5 d-flex justify-content-end">
                    <div class="line-input"></div>
                  </div>
            </div>
            <div class="row mx-sm-1">
              <label for="inputPassword2" class="col-7 mt-2" style="font-weight: normal;">วันที่/Date</label>
                  <div class="col-sm-5 d-flex justify-content-end">
                    <div class="line-input"></div>
                  </div>
            </div>   
          </div>
          <div class="col-4">
            <div class="row mx-sm-1">
              <label for="inputPassword2" class="col-7 mt-2" style="font-weight: normal;">ผู้ตรวจเงิน/Checked By</label>
                  <div class="col-sm-5 d-flex justify-content-end">
                    <div class="line-input"></div>
                  </div>
            </div> 
            <div class="row mx-sm-1">
              <label for="inputPassword2" class="col-7 mt-2" style="font-weight: normal;">วันที่/Date</label>
                  <div class="col-sm-5 d-flex justify-content-end">
                    <div class="line-input"></div>
                  </div>
            </div> 
          </div>
          <div class="col-4">
            <div class="row mx-sm-1">
              <label for="inputPassword2" class="col-7 mt-2" style="font-weight: normal;">ผู้อนุมัติ/Approved By</label>
                  <div class="col-sm-5 d-flex justify-content-end">
                    <div class="line-input"></div>
                  </div>
            </div> 
            <div class="row mx-sm-1">
              <label for="inputPassword2" class="col-7 mt-2" style="font-weight: normal;">วันที่/Date</label>
                  <div class="col-sm-5 d-flex justify-content-end">
                    <div class="line-input"></div>
                  </div>
            </div> 
          </div>
        </div>
      </div>
    </div>

    <div class="btn-section text-right border-bottom"></div>
    <br />
  </div>
</div>
