import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class EmployeeDepartmentService {
  async load() {
    let result = null;
    try {
      result = await Axios().get(`/employee-department`, {
        params: { isShow: 1 },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
