import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccBillingNoteItemService {
  async create({
    acc_billing_note_info_id,
    acc_document_info_id,
    doc_type,
    doc_no,
    appointment_date,
    note,
    appointment_location,
    appointment_time_from,
    appointment_time_to,
    sent_at,
    sent_by
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-billing-note-item`, {
        acc_billing_note_info_id,
        acc_document_info_id,
        doc_type,
        doc_no,
        appointment_date,
        note,
        appointment_location,
        appointment_time_from,
        appointment_time_to,
        sent_at,
        sent_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        acc_billing_note_item_id,
        acc_billing_note_info_id,
        acc_document_info_id,
        doc_type,
        doc_no,
        appointment_date,
        note,
        appointment_location,
        appointment_time_from,
        appointment_time_to,
        is_master_booking,
        sent_at,
        sent_by
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/acc-billing-note-item/${acc_billing_note_item_id}`, {
        acc_billing_note_info_id,
        acc_document_info_id,
        doc_type,
        doc_no,
        appointment_date,
        note,
        appointment_location,
        appointment_time_from,
        appointment_time_to,
        is_master_booking,
        sent_at,
        sent_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_billing_note_item_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-billing-note-item/${acc_billing_note_item_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-item`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ acc_billing_note_item_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-item/${acc_billing_note_item_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
