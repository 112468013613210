import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { CustomerContactService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { EmailValidator } from "src/app/validators";
import { Select2OptionData } from "ng-select2";
import { Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { Observable } from "rxjs";
declare var $: any;

@Component({
  selector: "app-customer-contact-edit",
  templateUrl: "./customer-contact-edit.component.html",
  styleUrls: ["./customer-contact-edit.component.css"],
})
export class CustomerContactEditComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  status = null;
  customer_id = null;
  tagInstance = null;
  @Output() done = new EventEmitter();
  @Input("customer-contact") customer_contact;
  @Input("customerAddress") customerAddress = [];
  @ViewChild("closeSidebar") closeSidebar: ElementRef;
  @Input("can-edit-customer") canEditCustomer = false;
  @Select(UserInfoState.getUser) userInfo: Observable<any>;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private CustomerContactService: CustomerContactService
  ) {
    this.setForm();
    this.customer_id = this.route.snapshot.paramMap.get("customer_id");
  }
  public templateContactResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id && !state['data']) {
      return state.text;
    }
    let mit_iplus_code = state['data'].mit_iplus_code?state['data'].mit_iplus_code:'-';
    let template = '<span>'+ state.text +'<br>MIT iPlus code: '+  mit_iplus_code +'</span>';
    return $(template);
  }
  setData() {
    for (let key in this.form.value) {
      let value = this.customer_contact[key];
      this.form.controls[key].setValue(value || "");
    }
    this.form.controls.date_of_birth.setValue(
      this.form.value.date_of_birth
        ? moment(this.form.value.date_of_birth).format("DD-MM-YYYY")
        : ""
    );

    if (this.tagInstance) {
      this.tagInstance.tagsinput("removeAll");
      let tags = this.form.value.memo ? this.form.value.memo.split(",") : [];
      for (let tag of tags) {
        this.tagInstance.tagsinput("add", tag);
      }
    }
    this.form.disable();
  }

  setForm() {
    this.form = this.formBuilder.group({
      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      nickname: [""],
      related_customer_address_id: ["", [Validators.required]],
      position: ["", [Validators.required]],
      gender: [""],
      date_of_birth: [""],
      phonenumber: ["", [Validators.required]],
      email: ["", [Validators.required, EmailValidator()]],
      line_id: [""],
      memo: [""],
    });
  }
  async ngOnInit() {
    if (!this.customer_contact) return;
    this.setData();
  }
  ngOnChanges(changes) {
    if (changes.customer_contact) {
      this.ngOnInit();
    }
  }
  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let result = await this.CustomerContactService.update({
      customer_id: this.customer_id,
      customer_contact_id: this.customer_contact.customer_contact_id,
      ...this.form.value,
      date_of_birth: this.form.value.date_of_birth
        ? moment(this.form.value.date_of_birth, "DD-MM-YYYY").format("YYYY-MM-DD")
        : undefined,
    });

    if (result.success) {
      this.done.emit();
      this.closeSidebar.nativeElement.click();
    }
  }

  async delete(closeModalEl) {
    await this.CustomerContactService.delete({
      customer_contact_id: this.customer_contact.customer_contact_id,
    });
    closeModalEl.click();
    this.closeSidebar.nativeElement.click();
    this.done.emit();
  }

  enableForm() {
    this.form.enable();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        setTimeout(() => {
          this.setForm();
        }, 500);
      } else {
        this.ngOnInit();
      }
    });
  }
}
