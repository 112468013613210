import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  Output,
  ElementRef,
  EventEmitter,
  Input,
} from "@angular/core";
import { MITCPService } from "src/app/services";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ENSignValidator } from "src/app/validators";

@Component({
  selector: "app-cp-create",
  templateUrl: "./cp-create.component.html",
  styleUrls: ["./cp-create.component.css"],
})
export class CpCreateComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  
  @Input("scopes") scopes: Array<any>;
  @Output() done = new EventEmitter();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private MITCPService: MITCPService
  ) {
    this.setForm();
  }
  setForm(): void {
    this.form = this.formBuilder.group({
      cp_name: ["", [Validators.required, ENSignValidator()]],
      mit_scope_id: [""],
    });
  }
  ngOnInit(): void {}
  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let response = await this.MITCPService.create(this.form.value);

    this.done.emit(response.success || response.error);
    this.submitted = false;
    this.closeModalBtn.nativeElement.click();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }
}
