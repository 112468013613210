<loading *ngIf="pageLoad"></loading>
<div class="card mb-0" [hidden]="pageLoad">
    <div class="card-content collapse show">
        <div class="card-body p-0">

            <div class="d-flex justify-content-between mb-1" *ngIf="!invoice_id">
                <div>
                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#invoice-item-create"
                    *ngIf="pageType !='show'"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบเสนอราคา
                    </button>
                </div>
            </div>

            <div class="table-responsive" style="max-height: 600px;">
                <table class="table table-striped table-bordered" cdkDropList (cdkDropListDropped)="drop($event)">
                    <thead> 
                        <tr>
                            <th class="px-1" style="width: 2%;" *ngIf="pageType !='show'"></th>
                            <th class="text-center">ลำดับ</th>
                            <th>สินค้าบริการ</th>
                            <th>คำอธิบาย</th>
                            <th class="text-right">ราคา</th>
                            <th class="text-right">ส่วนลด</th>
                            <th class="text-right">จำนวน</th>
                            <th class="text-right">รวม</th>
                            <th *ngIf="pageType !='show' && !invoice_id">
                                action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let invoice of invoiceItems; let i = index" cdkDrag>
                            <td class="px-1 py-0 pt-1" cdkDragHandle [hidden]="pageType =='show'">
                                <a style="color: #ccc;"><i class="fa fa-ellipsis-v"></i></a>
                            </td>
                            <div *cdkDragPreview>
                                <div class="card px-5 py-2">
                                  <div>
                                    <b>สินค้าบริการ:</b> {{invoice.product_title}}
                                  </div>
                                  <div>
                                    <b>คำอธิบาย:</b> {{invoice.product_description}}
                                  </div>
                                  <div>
                                    <b>ราคา:</b> {{utils.convertStingToNumber(invoice.price) | number : '1.2-2'}}
                                  </div>
                                </div>
                            </div>
                            <td class="text-center">
                                {{i + 1}}
                            </td>
                            <td>
                                {{invoice.product_title}}
                            </td>
                            <td>
                                <div *ngIf="invoice.item_type=='product'">
                                    {{invoice.product_description}}
                                  </div>
                                  <div *ngIf="invoice.item_type=='calibration_service'">
                                    <small>
                                      Description: {{invoice.data.description | dash}}<br/>
                                      Serial no: {{invoice.data.serial_no | dash}}<br/>
                                      Point: 
                                      <span *ngFor="let detail of invoice.data.quotation_mit_item_detail_info; let dLastIndex = last;">
                                        <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0">
                                            <span *ngFor="let point of detail.quotation_mit_item_detail_points_info; let pLastIndex = last;">
                                              {{point?.cus_point}} <span *ngIf="!pLastIndex">,</span>
                                            </span>
                                        </span>
                                        <span *ngIf="detail.quotation_mit_item_detail_ranges_info.length > 0">
                                          <span *ngFor="let range of detail.quotation_mit_item_detail_ranges_info; let rLastIndex = last;">
                                            {{range?.cus_min}} - {{range?.cus_max}}
                                          </span>
                                        </span>
                                        <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0" [innerHTML]="detail?.unit_info?.unit_short_name_th"></span>
                                        <span *ngIf="!dLastIndex && (detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0)">, </span>
                                      </span>
                                    </small>
                                  </div>
                            </td>
                            <td class="text-right">
                                <div>
                                    <input type="text" 
                                    placeholder="0.00"
                                    class="form-control text-right" 
                                    [id]="'price_'+i"
                                    [disabled]="pageType =='show'"
                                    (keyup)="onKeyup()"
                                    (click)="$event.target.select()"
                                    oninput="this.value = 
                                    this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    [(ngModel)]="invoice.price">
                                </div>
                            </td>
                            <td class="text-right">
                                {{invoice.discount | number : '1.2-2'}}
                            </td>
                            <td class="text-right">
                                {{invoice.quantity}}
                            </td>
                            <td class="text-right">
                                <div *ngIf="invoice.item_type=='calibration_service'">
                                    {{calculate.sumMit(invoice) | number : '1.2-2'}}
                                </div>
                                <div *ngIf="invoice.item_type=='product'">
                                    {{calculate.sumProduct(invoice) | number : '1.2-2'}}
                                </div>
                            </td>
                            <td *ngIf="pageType !='show' && !invoice_id">
                                <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#modalConfirmDelMsgInvoiceItem"
                                (click)="select(invoice)">
                                ลบ
                            </button>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="invoiceItems.length == 0">
                        <tr>
                            <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-invoice-item-create *ngIf="customer_info" [customer_info]="customer_info" (done)="submitInvoiceItem($event);">
</app-invoice-item-create>

<app-confirm-delete
  [id]="'modalConfirmDelMsgInvoiceItem'"
  (save)="delete($event)"
></app-confirm-delete>