
<div
modal
class="modal fade text-left"
id="confirm-calibration-item"
tabindex="-1"
(close)="close()"
role="dialog"
aria-labelledby="TransportreceiveFilterModal"
aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header bg-primary text-white">
      <h4 class="modal-title" id="TransportreceiveFilterModal">
        {{(template =='PRD' && status =='complete')?'บันทึกผล':'ยืนยันให้บริการ'}}
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form form-horizontal" [formGroup]="form">
          <div class="form-body">
            <div class="mt-1">
                <form-group
                [control]="form.controls.cost_of_sales"
                [submitted]="submitted"
                >
                  <label for="template">
                    บันทึกราคา <span class="danger">*</span>
                  </label>
                  <input 
                  placeholder="0.00"
                  class="form-control text-right" 
                  (keyup)="onKeyup()"
                  (click)="$event.target.select()"
                  oninput="this.value = 
                  this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  id="cost_of_sales" 
                  formControlName="cost_of_sales"/>
                </form-group>
  
              </div>
          </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-warning"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
        #closeConfirmBtn
      >
        <i class="fa fa-times icon-left"></i> ยกเลิก
      </button>

      <button type="button" class="btn btn-primary" (click)="save()">
        <i class="fa fa-check icon-left"></i>ยืนยัน
      </button>
    </div>
  </div>
</div>
</div>
