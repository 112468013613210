// show-errors.component.ts
import { Component, EventEmitter, Input, Output } from "@angular/core";
@Component({
  selector: "search-box",
  template: `
    <form>
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          [placeholder]="placeholder"
          [(ngModel)]="search_all"
          [ngModelOptions]="{ standalone: true }"
          (keyup.enter)="search.emit({ search_all: search_all })"
        />
        <div class="input-group-append">
          <a
            class="btn btn-primary d-flex justify-content-center align-items-center text-white"
            (click)="search.emit({ search_all: search_all })"
          >
            <i class="fa fa-search icon-left"></i> ค้นหา
          </a>
        </div>
      </div>
    </form>
  `,
})
export class SearchBoxComponent {
  @Input() placeholder: string = "ค้นหา...";
  @Output() search = new EventEmitter<any>();
  @Input() search_all: string = "";
}
