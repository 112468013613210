import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import moment from "moment";
declare const $;
@Directive({
  selector: "[date-picker]",
})
export class DatePickerDirective {
  @Output() input = new EventEmitter();
  @Input() date;
  @Input("max-date") maxDate = new Date();
  @Input("view-mode") viewMode = "days";
  @Input("show-clear") showClear = false;
  datetimepicker = null;

  constructor(el: ElementRef) {
    this.datetimepicker = $(el.nativeElement);
    this.datetimepicker.on("keydown", (e) => {
      e.preventDefault();
    });
    setTimeout(() => {
      this.datetimepicker
        .datetimepicker({
          format: "DD-MM-YYYY",
          viewMode: this.viewMode,
          maxDate: this.maxDate,
          useCurrent: false,
          showClear: this.showClear,
        })
        .on("dp.change", (e) => {
          this.input.emit({
            target: {
              value: e.date ? e.date.format("YYYY-MM-DD") : null,
            },
          });
        });

      this.datetimepicker.next().click(() => {
        this.datetimepicker.focus();
      });

      if (this.date) {
        this.datetimepicker
          .data("DateTimePicker")
          .date(moment(this.date).format("DD-MM-YYYY"));
        this.input.emit({
          target: {
            value: moment(this.date).format("YYYY-MM-DD"),
          },
        });
      }
    });
  }
}
