<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="received-voucher-item-tax-create"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreate1Modal">
          เลือกรายการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <h4 class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="description">
                    ใบกำกับภาษี <span class="danger">*</span> : 
                  </h4>
                  <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                      <form-group
                      [control]="form.controls.acc_invoice_info_id"
                      [submitted]="submitted">
                      <ng-select2
                          [data]="taxInvoiceList"
                          class="w-100"
                          placeholder="โปรดเลือกใบกำกับภาษี"
                          formControlName="acc_tax_invoice_info_id"
                          (valueChanged)="selectInvoice($event)">
                      </ng-select2>
                      </form-group>
                  </div>

                  <div class="col-12 col-sm-12 col-md-7 col-lg-7 text-right">
                    <h4>{{number_of_select}} รายการ</h4>
                  </div>
                </div>
            </div>

            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                  </div>
                  <div class="col-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="table-responsive" style="max-height: 600px;">
                        <table class="table table-striped table-bordered" formArrayName="received_voucher_item">
                            <thead> 
                                <tr>
                                    <th>
                                      <span>
                                        <input 
                                        *ngIf="receivedVoucherItems.length > 0 && isShowCheckAll"
                                        type="checkbox"
                                        id="select_all"
                                        (change)="onCheckAll($event)"
                                        >
                                      </span>
                                    </th>
                                    <th>ครั้งที่</th>
                                    <th>วันที่ชำระ</th>
                                    <th>วิธีการชำระ</th>
                                    <th class="text-right">จำนวนเงิน (บาท)</th>
                                    <th class="text-right">หัก ณ ที่จ่าย</th>
                                    <th class="text-right">ภาษีมูลค่าเพื่ม (%)</th>
                                    <th class="text-right">ยอดสุทธิ (บาท)</th>
                                    <th class="text-center">สถานะ</th>
                                </tr>
                            </thead>
        
                            <tbody>
                                <tr *ngFor="let item of receivedVoucherFormGroup.controls; let i = index;" [formGroupName]="i">
                                    <td>
                                        <input 
                                        *ngIf="!item.value.data.acc_received_voucher_info_id"
                                        type="checkbox"
                                        [id]="'select_'+i"
                                        formControlName="select"
                                        (change)="onCheckChange($event, i)"
                                        >                              
                                    </td>
                                    <td class="text-center">
                                        {{i + 1}}
                                    </td>
                                    <td>
                                        {{item.value.data.payment_date | thai_date: "short":"date" | dash}}
                                    </td>
                                    <td>
                                        {{getPaymentName(item.value.data)}}
                                        <br>
                                        <small class="text-muted" *ngIf="item.value.data.bank_name">
                                            ธนาคาร: {{item.value.data.bank_name}}
                                        </small>
                                        <br>
                                        <small class="text-muted" *ngIf="item.value.data.evidence_ref_no">
                                            เลขที่: {{item.value.data.evidence_ref_no}}
                                        </small>
                                    </td>
                                    <td class="text-right">
                                        {{item.value.data.value_before_wht | number : '1.2-2'}}
                                    </td>
                                    <td class="text-right">
                                        <div *ngIf="item.value.data.wht_percent !=0">
                                          {{item.value.data.wht_percent}}%
                                        </div>
                                        <div *ngIf="item.value.data.wht_percent ==0">
                                          -
                                        </div>
                                    </td>
                                    <td class="text-right">
                                        {{item.value.data.tax}}%
                                    </td>
                                    <td class="text-right">
                                        {{item.value.data.grand_total | number : '1.2-2'}}
                                    </td>
                                    <td class="text-center">
                                        <div *ngIf="!item.value.data.acc_received_voucher_info_id">
                                            -
                                          </div>
                                          <div *ngIf="item.value.data.acc_received_voucher_info_id">
                                            ออกใบสำคัญรับแล้ว
                                          </div>
                                    </td>
                                </tr>
                                <tr *ngIf="receivedVoucherItems.length === 0">
                                    <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                      
                        </table>
                    </div>
                  </div>
                </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>เพิ่มรายการ
        </button>
      </div>
    </div>
  </div>
</div>
