import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  NgZone
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NavigationStart, Router } from "@angular/router";
import { AccInvoiceInfoService } from "src/app/services/invoice";
import { NgSelect2Customer } from "src/app/services/quotations";
import { AccTaxInvoiceInfoService } from "src/app/services/tax-invoice";

@Component({
  selector: 'app-credit-note-create-filter',
  templateUrl: './credit-note-create-filter.component.html',
  styleUrls: ['./credit-note-create-filter.component.css']
})
export class CreditNoteCreateFilterComponent implements OnInit {
  form: FormGroup;

  reset: boolean = false;
  submitted: boolean = false;
  select2OptionCustomer = null;
  noteTypes = [
    {id: 'FULL_AMOUNT', text: 'ลดทั้งจำนวน'},
    {id: 'PARTIALLY', text: 'ลดบางรายการ'},
    {id: 'DISCOUNT', text: 'ส่วนลดภายหลัง'}
  ];
  items = [];

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private zone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,
    private NgSelect2Customer: NgSelect2Customer,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService
  ) {
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === "popstate") {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }

  async ngOnInit() {
    this.select2OptionCustomer = this.NgSelect2Customer.select2Options("?limit=10&&exclude_customer_state_id=Free&&is_exclude_address=true");
    this.form.controls.customer_id.valueChanges.subscribe(
      (value) => {

        if(value){
          this.form.get('ref_doc_type').setValue('INVOICE');
          this.form.get('ref_acc_document_info_id').setValue('');

          this.getInvoiceTaxInvoice(value);
        }
      }
    );
  }

  setForm() {
    this.form = this.formBuilder.group({
      customer_id: ["", [Validators.required]],
      ref_doc_type: ["INVOICE", [Validators.required]],
      ref_acc_document_info_id: ["", [Validators.required]],
      credit_note_type: ["", [Validators.required]],
      doc_reason: ["", [Validators.required]],
    });
    this.reset = false;
    setTimeout(() => {
      this.reset = true;
    }, 50);
  }

  changeTyoe(){
    setTimeout(() => {
      this.form.get('ref_acc_document_info_id').setValue('');

      this.getInvoiceTaxInvoice(this.form.value.customer_id);
    }, 100);
  }

  async getInvoiceTaxInvoice(customer_id){
    this.items = [];
    if(this.form.value.ref_doc_type == 'INVOICE'){
      await this.AccInvoiceInfoService.load(null, {customer_id: customer_id, doc_status_id:'WAITING_FOR_PAYMENT,PARTIAL_PAYMENT', 'order_by': 'doc_duedate:asc'}).then(res=>{
        this.items = res.resultData || [];
        this.items = this.items.map(elem => (
          {
            id: elem.acc_invoice_info_id,
            text: elem.doc_no,
          } 
        ));
      })
    }else{
      await this.AccTaxInvoiceInfoService.load(null, {customer_id: customer_id, doc_status_id:'WAITING_FOR_PAYMENT,PARTIAL_PAYMENT', 'order_by': 'doc_duedate:asc'}).then(res=>{
        this.items = res.resultData || [];
        this.items = this.items.map(elem => (
          {
            id: elem.acc_tax_invoice_info_id,
            text: elem.doc_no_company,
          } 
        ));
      })
    }
  }
  // PARTIAL_PAYMENT
  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.router.navigateByUrl("/credit-note/"+ this.form.value.customer_id +"/"+ this.form.value.ref_acc_document_info_id+"/"+ this.form.value.ref_doc_type +"/"+ this.form.value.credit_note_type+ "/create", {
      state: {
        data: this.form.getRawValue()
      },
    });
    this.closeModalBtn.nativeElement.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.items = [];
        this.setForm();
      }
    });
  }
}
