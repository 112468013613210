import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccBillingNoteContactService {
  async create({
    acc_billing_note_info_id,
    customer_contact_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-billing-note-contact`, {
        acc_billing_note_info_id,
        customer_contact_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      acc_billing_note_contact_id,
      acc_billing_note_info_id,
      customer_contact_id
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/acc-billing-note-contact/${acc_billing_note_contact_id}`, {
        acc_billing_note_info_id,
        customer_contact_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_billing_note_contact_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-billing-note-contact/${acc_billing_note_contact_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-contact`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ acc_invoice_contact_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-contact/${acc_invoice_contact_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
