import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, NgZone, Input } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AccInvoiceItemService } from 'src/app/services/invoice';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccTaxInvoiceItemService } from 'src/app/services/tax-invoice';

@Component({
  selector: 'app-credit-note-item-create',
  templateUrl: './credit-note-item-create.component.html',
  styleUrls: ['./credit-note-item-create.component.css']
})
export class CreditNoteItemCreateComponent implements OnInit {
  selectedCount:number = 0;

  productList = [];
  calibrationList = [];

  @Input('creditNoteInfo') creditNoteInfo: any;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private zone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,

    private AccInvoiceItemService: AccInvoiceItemService,
    private AccTaxInvoiceItemService: AccTaxInvoiceItemService,
    
    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) { }

  async ngOnInit() {
    if(this.creditNoteInfo.ref_doc_type == "INVOICE"){

      await this.AccInvoiceItemService.load(null, {acc_invoice_info_id: this.creditNoteInfo.ref_acc_document_info_id})
      .then(async res=>{
        let resultData = res.resultData;
        resultData.sort(function(a, b) {
          return a.order_by - b.order_by;
        });
        this.productList = resultData.filter(item=> item.item_type == 'product').map(value=>{
          return {
            ...value,
            data: {
              ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
              quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
            },
            previous_price: value.price,
            previous_quantity: value.quantity,
            select: false
          }
        })
        this.calibrationList = resultData.filter(item=> item.item_type == 'calibration_service').map(value=>{
          return {
            ...value,
            data: {
              ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
              quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
            },
            previous_price: value.price,
            previous_quantity: value.quantity,
            select: false
          }
        })
      })
    }else{
      await this.AccTaxInvoiceItemService.load(null, {acc_tax_invoice_info_id: this.creditNoteInfo.ref_acc_document_info_id}).then(res=>{
        let resultData = res.resultData;
        resultData.sort(function(a, b) {
          return a.order_by - b.order_by;
        });
        this.productList = resultData.filter(item=> item.item_type == 'product').map(value=>{
          return {
            ...value,
            data: {
              ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
              quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
            },
            previous_price: value.price,
            previous_quantity: value.quantity,
            select: false
          }
        })
        this.calibrationList = resultData.filter(item=> item.item_type == 'calibration_service').map(value=>{
          return {
            ...value,
            data: {
              ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
              quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
            },
            previous_price: value.price,
            previous_quantity: value.quantity,
            select: false
          }
        })
      })
    }
  }

  onCheckProductAll(event){
    this.productList.map(item=>{
      item.select = event.target.checked;
    });
  }

  onCheckCaliAll(event){
    this.calibrationList.map(item=>{
      item.select = event.target.checked;
    });
  }

  setCount(){
    setTimeout(() => {
      this.selectedCount = 0;
      this.productList.map(item=>{
        if(item.select){
          this.selectedCount = this.selectedCount + 1;
        }
      });
      this.calibrationList.map(item=>{
        if(item.select){
          this.selectedCount = this.selectedCount + 1;
        }
      });
    }, 100);
  }

  submit(){
    let items = [
      ...this.productList.filter(item=> item.select == true),
      ...this.calibrationList.filter(item=> item.select == true)
    ];

    this.done.emit(items);
    this.closeModalBtn.nativeElement.click();
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.selectedCount = 0;
        this.done.emit(false);
      }
    });
  }
}
