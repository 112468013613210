<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="work-order-non-item-create"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreate1Modal">
          เลือกรายการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <h4 class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="description">
                    ใบเสนอราคา <span class="danger">*</span> : 
                  </h4>
                  <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                    <form-group
                    [control]="form.controls.quotation_info_id"
                    [submitted]="submitted">
                    <ng-select2
                    [options]="
                    {
                      language: UtilsService.select2OptionsLanguage()
                    }"
                        [data]="quotationList"
                        class="w-100"
                        placeholder="โปรดเลือกใบเสนอราคา"
                        formControlName="quotation_info_id"
                        (valueChanged)="selectQuotation($event)">
                    </ng-select2>
                    </form-group>
                  </div>

                  <div class="col-12 col-sm-12 col-md-7 col-lg-7 text-right">
                    <h4>{{number_of_select}} รายการ</h4>
                  </div>
                </div>
            </div>

            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                  </div>
                  <div class="col-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="table-responsive" style="max-height: 600px;">
                        <table class="table table-striped table-bordered" formArrayName="job_order_item">
                            <thead> 
                                <tr>
                                    <th>
                                      <span>
                                        <input 
                                          *ngIf="isShowCheckAll"
                                          type="checkbox"
                                          id="select_all"
                                          (change)="onCheckAll($event)"
                                        >
                                      </span>
                                    </th>
                                    <th>เลขที่ใบเสนอราคา</th>
                                    <th>รายการ</th>
                                    <th>สถานะ</th>
                                </tr>
                            </thead>
        
                            <tbody>
                                <tr *ngFor="let item of productFormGroup.controls; let i = index;" [formGroupName]="i">
                                    <td>
                                      <input 
                                        *ngIf="!item.value.quotation_item_info.job_order_product_item_id && 
                                        checkSelected(item.value.quotation_item_info)"
                                        type="checkbox"
                                        [id]="'select_'+i"
                                        formControlName="select"
                                        (change)="onCheckChange($event, i)"
                                      >
                                    </td>
                                    <td>
                                        {{item.value.quotation_info?.doc_no}}
                                    </td>
                                    <td>
                                      {{item.value.quotation_item_info?.product_title}}
                                    </td>
                                    <td>
                                      <span *ngIf="!item.value.quotation_item_info.job_order_product_item_id">
                                        -
                                      </span>
                                      <span *ngIf="item.value.quotation_item_info.job_order_product_item_id">
                                        {{getStatus(item.value.quotation_item_info?.job_order_product_item_status)}}
                                      </span>
                                    </td>
                                </tr>
                                <tr *ngIf="jobOrderItems.length === 0">
                                    <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                      
                        </table>
                    </div>
                  </div>
                </div>
            </div>
            
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>เพิ่มรายการ
        </button>
      </div>
    </div>
  </div>
</div>
