
<div
modal
class="modal fade text-left"
id="save-calibration-item"
data-backdrop="static"
tabindex="-1"
role="dialog"
aria-labelledby="TransportreceiveFilterModal"
aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header bg-primary text-white">
      <h4 class="modal-title" id="TransportreceiveFilterModal">
        บันทึกผล
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form form-horizontal" [formGroup]="form">
          <div class="form-body">
            <div class="mt-1">
                <form-group
                [control]="form.controls.service_date"
                [submitted]="submitted">
                <label for="service_date">
                    วันที่สอบเทียบ <span class="danger">*</span>
                </label>
                <div class="input-group mt-0">
                    <input
                    *ngIf="reset"
                    date-time-picker
                    type="text"
                    id="save.service_date"
                    placeholder="วันที่สอบเทียบ"
                    [max-date]="undefined"
                    class="form-control"
                    [date]="form.value?.service_date"
                    formControlName="service_date"
                    />
                    <div
                    class="input-group-append"
                    for="service_date"
                    >
                    <span class="input-group-text"
                        ><i class="feather icon-calendar"></i
                    ></span>
                    </div>
                </div>
                </form-group>
            </div>

            <div class="row px-1">
              <div class="col-12 pl-0">
                <label for="service_date">
                  ผลการสอบเทียบ <span class="danger">*</span>
                </label>
              </div>
              <div class="col-4 py-1 border rounded-left text-center pointer" [ngClass]="(tab=='PASS')?'bg-success text-white':'bg-white text-dark'" (click)="setTab('PASS')">
                PASS
              </div>
              <div class="col-4 py-1 border text-center pointer" [ngClass]="(tab=='FAIL')?'bg-danger text-white':'bg-white text-dark'" (click)="setTab('FAIL')">
                FAIL
              </div>
              <div class="col-4 py-1 border rounded-right text-center pointer" [ngClass]="(tab=='CANCEL')?'bg-light text-white':'bg-white text-dark'" (click)="setTab('CANCEL')">
                CANCEL
              </div>
            </div>

            <div class="mt-2" *ngIf="form.value.status != 'PASS'">
              <form-group
              [control]="form.controls.reject_reason"
              [submitted]="submitted"
            >
              <label for="reject_reason">สาเหตุ
                <span class="danger">*</span>
              </label>
              <textarea
                *ngIf="form.value.status == 'FAIL'"
                placeholder="Fail"
                class="form-control"
                id="reject_reason.text"
                rows="3"
                formControlName="reject_reason"
              >
              </textarea>
              <ng-select2
                *ngIf="form.value.status == 'CANCEL'"
                [data]="cancelList"
                class="w-100"
                id="reject_reason"
                placeholder="กรุณาเลือกข้อมูล"
                formControlName="reject_reason"
              >
              </ng-select2>
            </form-group>
            </div>

            <div class="mt-1">
              <form-group
              [control]="form.controls.note"
              [submitted]="submitted"
              >
                <label for="template">
                  บันทึกรายละเอียด 
                </label>
                <textarea class="form-control" name="note" placeholder="ข้อความ" id="save.note" rows="4" formControlName="note"></textarea>
              </form-group>

            </div>

            <div class="mt-1">
              <form-group
              [control]="form.controls.std"
              [submitted]="submitted"
              >
                <label for="template">
                  STD <span class="danger">*</span>
                </label>
                <input type="text"    
                id="std"
                class="form-control"
                placeholder="STD" 
                formControlName="std">
              </form-group>

            </div>

            <div class="mt-1">
              <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                <div class="btn-group mr-1" role="group" aria-label="First group">
                    <label for="file">แนบไฟล์ </label>
                </div>
                <button 
                  class="btn btn-outline-primary mb-1" 
                  type="button"
                  (click)="uploadFile()">
                    <i class="feather icon-upload icon-left"></i>
                    Upload
                </button>
                <input type="file" style="display:none;" multiple id="importFileWork"
                (change)="importFile($event.target.files)">
              </div>
              <small>* จำกัดขนาดไฟล์ {{UtilsService.config.limitFile}} mb</small> 
              <div class="mt-1">
                <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                  <span style="cursor: pointer;" (click)="downloadFile(file)">
                    {{file.job_order_file_id? file.file_name: file.name}}
                  </span>
                  <span aria-hidden="true" style="cursor: pointer;" 
                  data-target="#modalSaveDelItemFile"
                  data-toggle="modal"
                  (click)="removeFile(file, i)">&times;</span>
                </span>
              </div>
              <!-- <span class="pl-1 text-danger w-100" *ngIf="(submitted && files.length == 0)">
                เลือกไฟล์อย่างน้อย 1 รายการ
              </span> -->
            </div>
          </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-warning"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
        #closeRejectBtn
      >
        <i class="fa fa-times icon-left"></i> ยกเลิก
      </button>

      <button type="button" class="btn btn-primary" (click)="save()">
        <i class="fa fa-check icon-left"></i>ยืนยัน
      </button>
    </div>
  </div>
</div>
</div>

<app-confirm-delete [id]="'modalSaveDelItemFile'" (save)="deleteFile($event)"></app-confirm-delete>