import Axios from "../axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class AccRecivedVoucherInfoService {
  async create({
    doc_no,
    revise_count,
    doc_date,
    doc_note,
    customer_id,
    customer_billing_day,
    customer_address_id,
    grand_total,
    doc_status_id,
    acc_payment_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-received-voucher-info`, {
        doc_no,
        revise_count,
        doc_date,
        doc_note,
        customer_id,
        customer_billing_day,
        customer_address_id,
        grand_total,
        doc_status_id,
        acc_payment_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    acc_received_voucher_info_id,
    doc_no,
    revise_count,
    doc_date,
    doc_note,
    customer_id,
    customer_billing_day,
    customer_address_id,
    grand_total,
    doc_status_id,
    acc_payment_id
  }) {
    let result = null;
    try {
      result = await Axios().put(`/acc-received-voucher-info/${acc_received_voucher_info_id}`, {
        doc_no,
        revise_count,
        doc_date,
        doc_note,
        customer_id,
        customer_billing_day,
        customer_address_id,
        grand_total,
        doc_status_id,
        acc_payment_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_received_voucher_info_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-received-voucher-info/${acc_received_voucher_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-received-voucher-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ acc_received_voucher_info_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-received-voucher-info/${acc_received_voucher_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getCode(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-received-voucher-code`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
