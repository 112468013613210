import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  CustomerAddressService,
  MITScopeService,
} from "src/app/services";
import {
  QuotationMitItemDetailPointService,
  QuotationMitItemDetailRangeService,
  QuotationMitItemDetailService,
  QuotationUtilsService,
} from "src/app/services/quotations";
import {
  JobOrderCalibrationItemService,
  JobOrderContactService,
  JobOrderInfoService,
  JobOrderWorkerEngineerService,
} from "src/app/services/job-orders";
import domtoimage from "dom-to-image-more";
import jsPDF from "jspdf";
import { Title } from "@angular/platform-browser";

@Component({
  selector: "app-service-report",
  templateUrl: "./service-report.component.html",
  styleUrls: ["./service-report.component.css"],
})
export class ServiceReportComponent implements OnInit {
  public contactList = [];
  //dropdown
  public reportAddressList = [];
  public sendReportAddressList = [];
  public jobOrderItems = [];
  public pages = [];
  public pageFinal = [];
  public scopeList = [];
  public workerList = [];
  //string
  public job_order_info_id: string = "";
  public textBottomPage: string =
    "FM-CTM-01, Revise 17, Effective Date 01/11/21";
  //any
  public customer_info: any = null;
  public jobOrderInfo: any = null;
  public isShowUnit: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private CustomerAddressService: CustomerAddressService,
    private JobOrderWorkerEngineerService: JobOrderWorkerEngineerService,
    private QuotationMitItemDetailPointService: QuotationMitItemDetailPointService,
    private QuotationMitItemDetailRangeService: QuotationMitItemDetailRangeService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService,
    private QuotationMitItemDetailService: QuotationMitItemDetailService,
    private JobOrderInfoService: JobOrderInfoService,
    private JobOrderContactService: JobOrderContactService,
    private MITScopeService: MITScopeService,
    private titleService: Title,
    public utils: QuotationUtilsService
  ) {
    this.job_order_info_id =
      this.route.snapshot.paramMap.get("job_order_info_id");
  }

  async ngOnInit() {
    await this.JobOrderInfoService.getById({
      job_order_info_id: this.job_order_info_id,
    }).then(async (res) => {
      this.jobOrderInfo = res.resultData || {};
      this.titleService.setTitle(
        this.jobOrderInfo.doc_no + " - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี"
      );
      if (this.jobOrderInfo.customer_address_info) {
        this.jobOrderInfo["customer_address"] = this.utils.genAddress(
          this.jobOrderInfo.customer_address_info
        );
      }

      await this.JobOrderCalibrationItemService.load(null, {
        job_order_info_id: this.job_order_info_id,
      }).then(async (res) => {
        this.jobOrderItems = res.resultData || [];

        await Promise.all(
          this.jobOrderItems.map(async (value) => {
            value["quotation_mit_item_details"] = [];

            if (value.quotation_mit_item_id) {
              await this.QuotationMitItemDetailService.load(null, {
                quotation_mit_item_id: value.quotation_mit_item_id,
                order_by: "order_by:asc",
              }).then(async (res) => {
                let resultData = res.resultData;

                value["quotation_mit_item_details"] = [];
                await Promise.all(
                  resultData.map(async (data: any) => {
                    let resultData = await this.mapMitItemData(data);

                    if (
                      resultData.quotation_mit_item_detail_points.length > 0 ||
                      resultData.quotation_mit_item_detail_range.cus_unit_id !=
                        ""
                    ) {
                      value.quotation_mit_item_details.push(resultData);
                    }
                  })
                );
                value.quotation_mit_item_info.quotation_mit_item_detail_info =
                  value.quotation_mit_item_details;
              });
            }
          })
        );
      });
      await Promise.all(
        this.jobOrderItems.map(async (item: any, index) => {
          item.index = index;
          item = await this.mapMitLog(item);
        })
      );
      this.jobOrderItems;
      for (var i = 0; i < this.jobOrderItems.length; i += 16) {
        this.pages.push(this.jobOrderItems.slice(i, i + 16));
      }
      for (var i = 0; i < this.jobOrderItems.length; i += 10) {
        this.pageFinal.push(this.jobOrderItems.slice(i, i + 10));
      }

      await this.MITScopeService.load().then((response) => {
        if (response.success) {
          let scope = response.resultData || [];
          for (var i = 0; i < this.jobOrderItems.length; i++) {
            let findScope = scope.find(
              (item) =>
                item.mit_scope_id ==
                this.jobOrderItems[i].quotation_mit_item_info.mit_scope_id
            );
            if (findScope.count) {
              findScope.count = findScope.count + 1;
            } else {
              findScope["count"] = 1;
            }
          }
          for (var i = 0; i < scope.length; i += 6) {
            this.scopeList.push(scope.slice(i, i + 6));
          }
        }
      });

      await this.CustomerAddressService.load(null, {
        is_registed_address: "false",
        customer_id: this.jobOrderInfo.customer_id,
      }).then(async (res) => {
        let resultData = res.resultData || [];
        this.sendReportAddressList = resultData
          .filter((item) => item.customer_address_type_id === 6)
          .map((elem) => ({
            id: elem.customer_address_id,
            text: this.utils.genAddress(elem),
            data: elem,
          }));
      });

      if (this.jobOrderInfo.send_report_address_id) {
        let findSendReportAddress = this.sendReportAddressList.find(
          (send) => send.id == this.jobOrderInfo.send_report_address_id
        );
        if (findSendReportAddress) {
          this.jobOrderInfo["send_report_address"] = findSendReportAddress.text;
          this.jobOrderInfo["send_report_address_name"] =
            findSendReportAddress.data.address_name;
        }
      }

      await this.JobOrderWorkerEngineerService.load(null, {
        job_order_info_id: this.job_order_info_id,
      }).then(async (res) => {
        await Promise.all(
          res.resultData.map(async (v: any) => {
            this.workerList.push(v);
          })
        );
      });

      await this.JobOrderContactService.load(null, {
        job_order_info_id: this.job_order_info_id,
      }).then((res) => {
        this.contactList = res.resultData || [];
      });

      this.isShowUnit = await this.checkJobOrderSize();
    });
  }

  async mapMitLog(jobOrderItem) {
    jobOrderItem["logs"] = [];
    let logs =
      jobOrderItem.quotation_mit_item_info.quotation_mit_item_log_info || [];

    let filterDescription = logs
      .filter((log) => log.key == "description")
      .sort(function (a, b) {
        if (a.created_at < b.created_at) return -1;
        if (a.created_at > b.created_at) return 1;
        return 0;
      });
    if (filterDescription.length > 1) {
      jobOrderItem.logs.push(this.createLog(filterDescription));
    }

    let filterMaker = logs
      .filter((log) => log.key == "marker")
      .sort(function (a, b) {
        if (a.created_at < b.created_at) return -1;
        if (a.created_at > b.created_at) return 1;
        return 0;
      });
    if (filterMaker.length > 1) {
      jobOrderItem.logs.push(this.createLog(filterMaker));
    }

    let filterModel = logs
      .filter((log) => log.key == "model")
      .sort(function (a, b) {
        if (a.created_at < b.created_at) return -1;
        if (a.created_at > b.created_at) return 1;
        return 0;
      });
    if (filterModel.length > 1) {
      jobOrderItem.logs.push(this.createLog(filterModel));
    }

    let filterSerialNo = logs
      .filter((log) => log.key == "serial_no")
      .sort(function (a, b) {
        if (a.created_at < b.created_at) return -1;
        if (a.created_at > b.created_at) return 1;
        return 0;
      });
    if (filterSerialNo.length > 1) {
      jobOrderItem.logs.push(this.createLog(filterSerialNo));
    }

    let filterTagNo = logs
      .filter((log) => log.key == "tag_no")
      .sort(function (a, b) {
        if (a.created_at < b.created_at) return -1;
        if (a.created_at > b.created_at) return 1;
        return 0;
      });
    if (filterTagNo.length > 1) {
      jobOrderItem.logs.push(this.createLog(filterTagNo));
    }

    if (jobOrderItem.quotation_mit_item_details.length > 0) {
      await Promise.all(
        jobOrderItem.quotation_mit_item_details.map(async (detail: any) => {
          let filterPoint = logs
            .filter(
              (log) =>
                log.key == "point" &&
                log.quotation_mit_item_detail_id ==
                  detail.quotation_mit_item_detail_id
            )
            .sort(function (a, b) {
              if (a.created_at < b.created_at) return -1;
              if (a.created_at > b.created_at) return 1;
              return 0;
            });
          if (filterPoint.length > 1) {
            jobOrderItem.logs.push(this.createLog(filterPoint));
          }
          let filterMPE = logs
            .filter(
              (log) =>
                log.key == "mpe" &&
                log.quotation_mit_item_detail_id ==
                  detail.quotation_mit_item_detail_id
            )
            .sort(function (a, b) {
              if (a.created_at < b.created_at) return -1;
              if (a.created_at > b.created_at) return 1;
              return 0;
            });
          if (filterMPE.length > 1) {
            jobOrderItem.logs.push(this.createLog(filterMPE));
          }
        })
      );
    }
    return jobOrderItem;
  }

  createLog(logs): any {
    var first_element = logs[0];
    var last_element = logs[logs.length - 1];
    return {
      key: first_element.key,
      quotation_mit_item_detail_id:
        first_element?.quotation_mit_item_detail_id || "",
      description: `change ${first_element.key} : as ${first_element.new_value} as ${last_element.new_value}`,
    };
  }

  highlight(logs, key): boolean {
    let chk = logs.find((log) => log.key == key);
    return chk ? true : false;
  }

  async checkJobOrderSize(): Promise<any> {
    let result = false;

    const job_order_sections = document.querySelectorAll(".job-order-pdf");
    await Promise.all(
      Array.prototype.slice
        .call(job_order_sections, 0)
        .map(async (value, index) => {
          if (job_order_sections.length === index + 1) {
            const job_order_section = document.getElementById(
              "job_order_" + index
            );
            const imgHeight =
              (job_order_section.clientHeight * 208) /
              job_order_section.clientWidth;
            if (imgHeight > 280) {
              result = true;
            } else {
              result = false;
            }
          }
        })
    );
    return result;
  }

  async mapMitItemData(mit: any): Promise<any> {
    let points = [];
    let range = [];
    if (mit.total_amount > 0) {
      if (mit.detail_input_type == "point") {
        points = await this.QuotationMitItemDetailPointService.load(null, {
          quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id,
          order_by: "order_by:asc",
        }).then((res) => {
          return res.resultData;
        });
      } else {
        range = await this.QuotationMitItemDetailRangeService.load(null, {
          quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id,
        }).then((res) => {
          return res.resultData;
        });
      }
    }
    await points.map((point) => {
      if (point.cus_unit_info)
        point["cus_unit_name"] =
          point.cus_unit_info.unit_short_name_en ||
          point.cus_unit_info.unit_short_name_th;
      if (point.mit_unit_info)
        point["mit_unit_name"] =
          point.mit_unit_info.unit_short_name_en ||
          point.mit_unit_info.unit_short_name_th;
    });

    return {
      ...mit,
      detail_type: mit.detail_input_type,
      quotation_mit_item_detail_range: {
        quotation_mit_item_detail_range_id:
          range[0]?.quotation_mit_item_detail_range_id || "",
        cus_min: range[0]?.cus_min || 0,
        cus_max: range[0]?.cus_max || 0,
        cus_unit_id: range[0]?.cus_unit_id || "",
        cus_unit_name: range[0]?.cus_unit_info.unit_short_name_en || "",
        mit_min: range[0]?.mit_min || 0,
        mit_max: range[0]?.mit_max || 0,
        mit_unit_id: range[0]?.mit_unit_id || "",
        mit_unit_name: range[0]?.mit_unit_info.unit_short_name_en || "",
      },
      cus_unit_id: mit.unit_id,
      unit_name: mit.unit_short_name_en,

      min_range: mit.product_calibration_fee_info.min_range,
      max_range: mit.product_calibration_fee_info.max_range,

      quotation_mit_item_detail_points: points,
    };
  }

  async print(jobOrderInfo) {
    const job_order_sections = document.querySelectorAll(".job-order-pdf");
    const unit_section = document.getElementById("unit_section");
    const job_order_final_sections = document.querySelectorAll(
      ".job-order-pdf-final"
    );

    let doc = new jsPDF("p", "mm", "a4", true);
    doc.setFontSize(8);
    let page = 1;
    let perPage = 0;
    if (this.isShowUnit) {
      perPage = perPage + 1;
    }
    if (Array.prototype.slice.call(job_order_sections, 0).length > 0) {
      perPage =
        perPage + Array.prototype.slice.call(job_order_sections, 0).length;
    }
    if (Array.prototype.slice.call(job_order_final_sections, 0).length > 0) {
      perPage =
        perPage +
        Array.prototype.slice.call(job_order_final_sections, 0).length;
    }

    let jobOrderList = [];
    await Promise.all(
      Array.prototype.slice
        .call(job_order_sections, 0)
        .map(async (value, index) => {
          let job_order_section = document.getElementById("job_order_" + index);
          await domtoimage
            .toJpeg(job_order_section, {
              background: "white",
              allowTaint: true,
              width: job_order_section.clientWidth * 2,
              height: job_order_section.clientHeight * 2,
              style: {
                transform: "scale(" + 2 + ")",
                transformOrigin: "top left",
              },
              quality: 0.95,
            })
            .then(async function (canvas) {
              jobOrderList.push({
                index: index,
                canvas: canvas,
                job_order_section: job_order_section,
              });
            });
        })
    );
    jobOrderList.sort(function (a, b) {
      return a.index - b.index;
    });
    await Promise.all(
      jobOrderList.map(async (value, index) => {
        var imgWidth = 208;
        var imgHeight =
          (value.job_order_section.clientHeight * imgWidth) /
          value.job_order_section.clientWidth;
        if (index != 0) doc.addPage("a4");
        doc.addImage(value.canvas, "JPEG", 0, 0, imgWidth, imgHeight, null);
        doc.text(this.textBottomPage, 130, 285);
        doc.text(page + " /  " + perPage, 200, 285);
        page = page + 1;
      })
    );
    if (this.isShowUnit) {
      await domtoimage
        .toJpeg(unit_section, {
          background: "white",
          allowTaint: true,
          width: unit_section.clientWidth * 2,
          height: unit_section.clientHeight * 2,
          style: {
            transform: "scale(" + 2 + ")",
            transformOrigin: "top left",
          },
          quality: 0.95,
        })
        .then(async function (canvas) {
          var imgWidth = 208;
          var imgHeight =
            (unit_section.clientHeight * imgWidth) / unit_section.clientWidth;
          doc.addPage("a4");
          doc.addImage(canvas, "JPEG", 0, 0, imgWidth, imgHeight, "FAST");
          doc.text(this.textBottomPage, 130, 285);
          doc.text(page + " /  " + perPage, 200, 285);
          page = page + 1;
        });
    }
    let jobOrderFinalList = [];
    await Promise.all(
      Array.prototype.slice
        .call(job_order_final_sections, 0)
        .map(async (value, index) => {
          let job_order_final_section = document.getElementById(
            "job_order_final_" + index
          );
          await domtoimage
            .toJpeg(job_order_final_section, {
              background: "white",
              allowTaint: true,
              width: job_order_final_section.clientWidth * 2,
              height: job_order_final_section.clientHeight * 2,
              style: {
                transform: "scale(" + 2 + ")",
                transformOrigin: "top left",
              },
              quality: 0.95,
            })
            .then(async function (canvas) {
              jobOrderFinalList.push({
                index: index,
                canvas: canvas,
                job_order_final_section: job_order_final_section,
              });
            });
        })
    );
    jobOrderFinalList.sort(function (a, b) {
      return a.index - b.index;
    });
    await Promise.all(
      jobOrderFinalList.map(async (value) => {
        var imgWidth = 208;
        var imgHeight =
          (value.job_order_final_section.clientHeight * imgWidth) /
          value.job_order_final_section.clientWidth;
        doc.addPage("a4");
        doc.addImage(value.canvas, "JPEG", 0, 0, imgWidth, imgHeight, null);
        doc.text(this.textBottomPage, 130, 285);
        doc.text(page + " /  " + perPage, 200, 285);
        page = page + 1;
      })
    );

    setTimeout(() => {
      doc.save(jobOrderInfo.doc_no);
    }, 1000);
  }
}
