import { Directive, ElementRef } from "@angular/core";
declare var $: any;

@Directive({
  selector: "[icheck]",
})
export class ICheckDirective {
  constructor(el: ElementRef) {
    $(el.nativeElement).iCheck({
      checkboxClass: "icheckbox_square-blue",
      radioClass: "iradio_square-blue",
    });
  }
}
