import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MITCPService, MITScopeService } from "src/app/services";

@Component({
  selector: "app-cp-list",
  templateUrl: "./cp-list.component.html",
  styleUrls: ["./cp-list.component.css"],
})
export class CpListComponent implements OnInit {
  cps = [];
  scopes = null;
  status: string;
  mit_cp_id: string;
  reset: boolean = false;
  filterLimit;
  initialPage = 1;
  filter = {
    order_by:'mit_cp_id:asc'
  };
  isLoading = true;

  load: Function = this.MITCPService.load;
  constructor(private router: Router, private MITCPService: MITCPService, private MITScopeService: MITScopeService) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.cps = [];
    await this.MITScopeService.load(null, {}).then(res=>{
      this.scopes = res.resultData || [];
      this.scopes= this.scopes.map(elem => (
        {
          id: elem.mit_scope_id,
          text:  elem?.scope_name,
          data: elem
        } 
      ));
    })
  }
  onChangePage(cps: Array<any>) {
    this.cps = cps;
    this.isLoading = false;
  }
  onFilterLimit(event: any){
    this.filterLimit = event;
  }
  select(mit_cp_id: string) {
    this.mit_cp_id = mit_cp_id;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
      this.mit_cp_id = null;
      if(this.filterLimit){
        this.initialPage = this.filterLimit.initialPage;
        this.filter = {
          ...this.filter,
          ...this.filterLimit,
          initialPage: undefined
        }
      }
    }, 50);
  }
  search(filter) {
    this.filter = filter;
  }
  async delete(closeModalEl) {
    let response = await this.MITCPService.delete({
      mit_cp_id: this.mit_cp_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }

  getStatus(cp): string{
    return this.scopes.find(scope=> scope.id == cp.mit_scope_id)?.text || "-";
  }
}
