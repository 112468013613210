import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
declare const $;
declare const Switchery;
@Directive({
  selector: "[toggle-switch]",
})
export class ToggleSwitchDirective {
  constructor(el: ElementRef) {
    setTimeout(() => {
      new Switchery(el.nativeElement);
    });
  }
}
