// show-errors.component.ts
import { Component, Input, HostBinding } from "@angular/core";
import { AbstractControlDirective, AbstractControl } from "@angular/forms";

@Component({
  selector: "form-group",
  template: `
    <div class="form-group" [class]="getClass()">
      <ng-content></ng-content>
      <show-errors
        *ngIf="showError() && displayError"
        [control]="control"
        [submitted]="submitted"
      ></show-errors>
    </div>
  `,
})
export class FormGroupComponent {
  @Input("displayError") displayError = true;
  @Input("class") class: string = "";
  @Input()
  control: AbstractControlDirective | AbstractControl | Array<any>;
  @Input()
  submitted: boolean;
  showError(): boolean {
    let controls = Array.isArray(this.control) ? this.control : [this.control];
    let invalid = false;
    for (let control of controls) {
      if (control && control.invalid) {
        invalid = true;
        break;
      }
    }

    return this.submitted && this.control && invalid;
  }

  getClass() {
    return `${this.class} ${this.showError() ? "error" : ""}`;
  }
}
