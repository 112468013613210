<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="receive-item-edit-all"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreateModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreateModal">
          {{isReview? 'ตรวจสอบการรับเครื่องมือทั้งหมด': 'บันทึกรับเครื่องมือทั้งหมด'}}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal"  autocomplete="off" class="form" [formGroup]="form">
            <div class="form-body">
                <div class="form-body">
                      <div class="form-group row mb-0">
                        <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="description">
                          สถานะ <span class="danger" *ngIf="form.controls['item_status_id'].enabled">*</span> : 
                        </label>
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                            <form-group
                            [control]="form.controls.item_status_id"
                            [submitted]="submitted"
                          >
                            <select
                              class="form-control"
                              id="item_status_id"
                              formControlName="item_status_id"
                            >
                              <option value="">โปรดเลือก...</option>
                              <option
                                *ngFor="let status of statusList"
                                [value]="status.item_status_id"
                                >{{ status.item_status_name_th }}</option
                              >
                            </select>
                          </form-group>
                        </div>
                      </div>
                </div>

              </div>
      
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ตกลง
        </button>
      </div>
    </div>
  </div>
</div>