import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationMitItemDetailService {
  async create({
    quotation_mit_item_id,
    mit_equipment_id,
    mit_scope_id,
    mit_method_id,
    unit_id,
    service_location,
    mit_cp_id,
    product_calibration_fee_id,
    price_start,
    price_point,
    min_point,
    total_amount,
    mpe,
    note,
    scope_name,
    equipment_name,
    method_name,
    cp_name,
    isISO,
    unit_short_name_en,
    is_non_calculate,
    is_non_calibration,
    detail_input_type,
    order_by
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-item-detail`, {
        quotation_mit_item_id,
        mit_equipment_id,
        mit_scope_id,
        mit_method_id,
        unit_id,
        service_location,
        mit_cp_id: parseInt(mit_cp_id),
        product_calibration_fee_id,
        price_start,
        price_point,
        min_point,
        total_amount,
        mpe: mpe? mpe.toString(): "",
        note: note? note.toString(): "",
        scope_name,
        equipment_name,
        method_name,
        cp_name: cp_name? cp_name: "",
        isISO,
        unit_short_name_en,
        is_non_calculate,
        is_non_calibration,
        detail_input_type,
        order_by
      });
      result = result?.data || {};
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async createArray(array) {
    array = array.map(item=>{
      return {
        quotation_mit_item_id: item.quotation_mit_item_id,
        mit_equipment_id: item.mit_equipment_id,
        mit_scope_id: item.mit_scope_id,
        mit_method_id: item.mit_method_id,
        unit_id: item.unit_id,
        service_location: item.service_location,
        mit_cp_id: parseInt(item.mit_cp_id),
        product_calibration_fee_id: item.product_calibration_fee_id,
        price_start:item.price_start,
        price_point:item.price_point,
        min_point:item.min_point,
        total_amount:item.total_amount,
        mpe: item.mpe? item.mpe.toString(): "",
        note: item.note? item.note.toString(): "",
        scope_name:  item.scope_name,
        equipment_name: item.equipment_name,
        method_name:  item.method_name,
        cp_name: item.cp_name? item.cp_name: "",
        isISO:  item.isISO,
        unit_short_name_en:  item.unit_short_name_en,
        is_non_calculate:  item.is_non_calculate,
        is_non_calibration:  item.is_non_calibration,
        detail_input_type:  item.detail_input_type,
        order_by:  item.order_by
      }
    })
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-item-detail`, array);
      result = result?.data || {};
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_mit_item_detail_id,
        quotation_mit_item_id,
        mit_equipment_id,
        mit_scope_id,
        mit_method_id,
        unit_id,
        service_location,
        mit_cp_id,
        product_calibration_fee_id,
        price_start,
        price_point,
        min_point,
        total_amount,
        mpe,
        note,
        scope_name,
        equipment_name,
        method_name,
        cp_name,
        isISO,
        unit_short_name_en,
        is_non_calculate,
        is_non_calibration,
        detail_input_type,
        order_by
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-mit-item-detail/${quotation_mit_item_detail_id}`, {
        quotation_mit_item_id,
        mit_equipment_id,
        mit_scope_id,
        mit_method_id,
        unit_id,
        service_location,
        mit_cp_id: parseInt(mit_cp_id),
        product_calibration_fee_id,
        price_start,
        price_point,
        min_point,
        total_amount,
        mpe: mpe? mpe.toString(): "",
        note: note? note.toString(): "",
        scope_name,
        equipment_name,
        method_name,
        cp_name: cp_name? cp_name: "",
        isISO,
        unit_short_name_en,
        is_non_calculate,
        is_non_calibration,
        detail_input_type,
        order_by
      });
      result = result?.data || {};
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async updateArray(array) {
    array = array.map(item=>{
      return {
        quotation_mit_item_detail_id: item.quotation_mit_item_detail_id,
        quotation_mit_item_id: item.quotation_mit_item_id,
        mit_equipment_id: item.mit_equipment_id,
        mit_scope_id: item.mit_scope_id,
        mit_method_id: item.mit_method_id,
        unit_id: item.unit_id,
        service_location: item.service_location,
        mit_cp_id: parseInt(item.mit_cp_id),
        product_calibration_fee_id: item.product_calibration_fee_id,
        price_start:item.price_start,
        price_point:item.price_point,
        min_point:item.min_point,
        total_amount:item.total_amount,
        mpe: item.mpe? item.mpe.toString(): "",
        note: item.note? item.note.toString(): "",
        scope_name:  item.scope_name,
        equipment_name: item.equipment_name,
        method_name:  item.method_name,
        cp_name: item.cp_name? item.cp_name: "",
        isISO:  item.isISO,
        unit_short_name_en:  item.unit_short_name_en,
        is_non_calculate:  item.is_non_calculate,
        is_non_calibration:  item.is_non_calibration,
        detail_input_type:  item.detail_input_type,
        order_by:  item.order_by
      }
    })
    let result = null;
    try {
      result = await Axios().put(`/quotation-mit-item-detail`, array);
      result = result?.data || {};
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_mit_item_detail_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-mit-item-detail/${quotation_mit_item_detail_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-detail`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async getById({ quotation_mit_item_detail_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-detail/${quotation_mit_item_detail_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
