import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";


@Component({
  selector: 'app-quotation-confirm-voided',
  templateUrl: './quotation-confirm-voided.component.html'
})
export class QuotationConfirmVoidedComponent implements OnInit, OnChanges {
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") private closeModal: ElementRef;
  @Input() quotation: any;

  data = null;

  constructor(private router: Router) {
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModal.nativeElement.click();
      }
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.data = changes.quotation.currentValue;
  }

  async onSave(type: string) {
    this.save.emit({
      type: type,
      closeModal: this.closeModal.nativeElement
    });
  }

  onClose() {
    this.close.emit(null);
  }
}
