import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccReceivedVoucherEditHistoryService } from 'src/app/services/received-voucher';

@Component({
  selector: 'app-received-voucher-history',
  templateUrl: './received-voucher-history.component.html',
  styleUrls: ['./received-voucher-history.component.css']
})
export class ReceivedVoucherHistoryComponent implements OnInit {
  //boolean
  submitted = false;
  pageLoad = true;
  //array
  memoList: any = [];
  //string
  acc_received_voucher_info_id: string;
  //any
  status: any;
  constructor(
    private route: ActivatedRoute,
    private AccReceivedVoucherEditHistoryService: AccReceivedVoucherEditHistoryService
  ) { }

  ngOnInit(): void {
    this.acc_received_voucher_info_id = 
    this.route.snapshot.paramMap.get("acc_received_voucher_info_id");
    this.getMemoList();
  }

  getMemoList(){
    this.pageLoad = true;
    this.memoList = [];

    this.AccReceivedVoucherEditHistoryService.load(null, { acc_received_voucher_info_id : this.acc_received_voucher_info_id  })
    .then(async (res)=>{
      this.memoList = res.resultData;
      this.memoList.sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      this.pageLoad = false;
    })
  }
}
