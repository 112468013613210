<div class="card p-0" [formGroup]="form">
    <div class="card-content collapse show">
        <div class="card-body p-0">
            <p class="font-weight-bold"> ข้อมูลทะเบียนรถ</p>

            <div class="table-responsive">
                <table class="table table-bordered" formArrayName="carInfos">
                  <thead>
                    <tr>
                        <th class="text-center" style="width: 7%;">ลำดับ</th>
                        <th style="width: 65%;">ทะเบียนรถ <span class="danger" *ngIf="(jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM' || jobOrderInfo?.doc_status_id=='CONFIRMED') && pageType != 'show'">*</span></th>
                        <th class="text-center py-0 align-middle" *ngIf="pageType !='show' && (jobOrderInfo?.doc_status_id !='COMPLETED' && jobOrderInfo?.doc_status_id !='REJECTED')">
                            <span class="mr-1">action</span>
                            <button
                            *ngIf="carInfoList.length === 0 && (jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM' || jobOrderInfo?.doc_status_id=='CONFIRMED')"
                            type="button"
                            class="btn btn-sm btn-icon btn-circle btn-success"
                            (click)="addCarInfo()">
                            <i class="fa fa-plus fa-2x"></i>
                          </button>
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of carInfoItemFormGroup.controls; let i = index;" [formGroupName]="i">
                          <td class="text-center">
                              {{i+1}}
                          </td>
                          <td>
                            <div *ngIf="pageType !='show' || jobOrderInfo?.doc_status_id =='COMPLETED'">
                              <form-group [control]="item.controls.car_info_id" [submitted]="submitted">    
                                <ng-select2 [data]="carList"
                                    [placeholder]="'ทะเบียนรถ'"
                                    formControlName="car_info_id"
                                    [disabled]="pageType =='show' || jobOrderInfo?.doc_status_id =='COMPLETED'" 
                                    (valueChanged)="setCar($event, i)"
                                    [width]="300">
                                  </ng-select2>
                              </form-group>
                            </div>
                            <div *ngIf="pageType =='show' && jobOrderInfo?.doc_status_id !='COMPLETED'">
                              {{getCar(item.value.car_info_id)}}
                            </div>
                          </td>
                          <td class="text-center" *ngIf="pageType !='show' && (jobOrderInfo?.doc_status_id !='COMPLETED' && jobOrderInfo?.doc_status_id !='REJECTED')">
                              <button
                              type="button"
                              class="btn round btn-icon btn-danger mr-1 mb-1"
                              (click)="removeCarInfo(i, item);"
                            >
                              <i class="fa fa-minus fa-2x"></i>
                            </button>
                            <button
                              *ngIf="carInfoList.length === i + 1"
                              type="button"
                              class="btn round btn-icon btn-success mb-1"
                              (click)="addCarInfo()"
                            >
                              <i class="fa fa-plus fa-2x"></i>
                            </button>
                          </td>
                      </tr>
                      <tr *ngIf="carInfoList.length === 0">
                          <td colspan="3" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                  </tbody>
                  <!-- <span class="pl-1 text-danger w-100" style="white-space: nowrap;" *ngIf="(submitted && !canCompleted) && jobOrderInfo?.doc_status_id === 'CONFIRMED'">
                    โปรดบันทึกรายการ
                  </span> -->
                </table>
              </div>
        </div>
        
    </div>
</div>

