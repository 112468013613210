import { Injectable } from "@angular/core";
import { MITEquipmentService } from "src/app/services";
import { QuotationUtilsService } from "src/app/services/quotations";

@Injectable({
   providedIn: "root"
})
export class MapMitItemService {

  constructor(
    private utils: QuotationUtilsService,
    private MITEquipmentService: MITEquipmentService,
  ) {}

  async mapPoint(data){
    await Promise.all(data.quotation_mit_item_detail_info.map(async (item)=>{
      if(item.detail_type == 'point'){
        item.quotation_mit_item_detail_points.map((point, index)=>{
          let i = index + 1;
          if(i > item.number_of_point){
            point.is_non_calculate = 'true';
          }
        })
      }
    }));
    return data;
  }

  async mapRange(excelData, data){
    excelData.data_point.map(async (item)=>{

      await Promise.all(item?.data?.map(async (rangeItem)=>{
        let detail = data.quotation_mit_item_detail_info.find(item=>item.product_calibration_fee_id == rangeItem.product_calibration_fee_id);

        if(detail){
          let oldRange = detail?.quotation_mit_item_detail_range?.cus_min + '-' + detail?.quotation_mit_item_detail_range?.cus_max+ '('+detail?.min_point+')';

          if(oldRange != rangeItem.data){
  
            data.quotation_mit_item_detail_info.map(item=>{
              if(item.product_calibration_fee_id == rangeItem.product_calibration_fee_id){
                item.is_non_calibration = "true";
              }
            });
    
            let min_point = rangeItem?.data?.toString().split("(")[1]?.split(")")[0] || 0;
            let min = "";
            let max = "";

            if(rangeItem?.data?.toString().split("-").length  == 3){
              min = '-'+rangeItem?.data?.toString().split("-")[1];
              max = rangeItem?.data?.toString().split("-")[2]?.split("(")[0];
            }else{
              min = rangeItem?.data?.toString().split("-")[0];
              max = rangeItem?.data?.toString().split("-")[1]?.split("(")[0];
            }

            let range = {
              ...detail.quotation_mit_item_detail_range,
              cus_min: min,
              cus_max: max,
              mit_max: max,
              mit_min: min,
              is_non_calculate: "false",
              is_non_calibration: "false",
              quotation_mit_item_detail_id: null,
              quotation_mit_item_detail_range_id: null,
            }
  
            data.quotation_mit_item_detail_info.push(
              {
                ...detail,
                is_non_calibration: "false",
                is_non_calculate: "true",
                min_point: parseInt(min_point),
                quotation_mit_item_detail_id: null,
                quotation_mit_item_detail_range: range
              }
            )
          }
        }
        }));
    })
    return data;
  }

  async addPoint(excelData, data){

    excelData.data_point.map(async (item, index)=>{
      let points = excelData.data_point[index].data;
      let detailInfo = data.quotation_mit_item_detail_info[index];

      if(detailInfo.detail_type == 'point'){
        if(points.length > detailInfo.quotation_mit_item_detail_points.length){
          await Promise.all(points.map(async (p, indexP)=>{
  
            let findPont = detailInfo.quotation_mit_item_detail_points.find(detail_point=> detail_point.mit_point == p.data);
            if(findPont == undefined){
  
              data.quotation_mit_item_detail_info[index].quotation_mit_item_detail_points.push(
                {
                  ...detailInfo.quotation_mit_item_detail_points[0],
                  cus_point: p.data,
                  mit_point: p.data,
                  quotation_mit_item_detail_point_id: undefined,
                  order_by: indexP
                }
              )
            }
          }));
        }else if(points.length < detailInfo.quotation_mit_item_detail_points.length){
  
          data.quotation_mit_item_detail_info[index].quotation_mit_item_detail_points.map((point, indexP)=>{
            if(!points.find(p=> p.data == point.mit_point)){
              point.is_non_calibration = 'true';
            }
          })
        }else if(points.length == detailInfo.quotation_mit_item_detail_points.length){
          let is_change_point = false;
          item.data.map(point=>{
            if(!detailInfo.quotation_mit_item_detail_points.find(item=> item.mit_point == point.data)){
              is_change_point = true;
            }
          })
          
          if(is_change_point){
            await Promise.all(detailInfo.quotation_mit_item_detail_points.map(async item=>{
              item.is_non_calibration = "true";
            }));
  
            await Promise.all(item.data.map(async  (p)=>{
              let custom = {
                ...detailInfo.quotation_mit_item_detail_points[0],
                cus_point: p.data,
                mit_point: p.data,
                is_non_calibration: "false",
                quotation_mit_item_detail_point_id: undefined,
              }
              data.quotation_mit_item_detail_info[index].quotation_mit_item_detail_points.push(
                custom
              )
            }));
          }
        }
      }else{
        data.quotation_mit_item_detail_info[index].is_non_calibration = "true";

        let calibrationFreeResponse = await this.MITEquipmentService.getById({mit_equipment_id: data.mit_equipment_id});
        let product_calibration_fee_info = calibrationFreeResponse.resultData.product_calibration_fee_info;

        let calibration_fee = product_calibration_fee_info.find(item=> item.product_calibration_fee_id == points[0].product_calibration_fee_id);

        let newPoints = [];
        await Promise.all(points.map(async  (p)=>{
          let custom = {
            cus_unit_id: calibration_fee.unit_id,
            cus_unit_name: calibration_fee.unit_info?.unit_short_name_en,
            mit_unit_id: calibration_fee.unit_id,
            mit_unit_name: calibration_fee.unit_info?.unit_short_name_en,
            cus_point: p.data,
            mit_point: p.data,
            is_non_calculate: "false",
            is_non_calibration: "false",
            quotation_mit_item_detail_id: null,
            quotation_mit_item_detail_point_id: null,
          }
          newPoints.push(
            custom
          )
        }));

        data.quotation_mit_item_detail_info.push(
          {
            ...this.newQuotationDetail(data, calibration_fee),
            is_non_calibration: "false",
            is_non_calculate: "true",
            quotation_mit_item_detail_id: null,
            quotation_mit_item_detail_points: [
              ...newPoints
            ]
          }
        )
      }
    })

    return data;
  }
  
  async addPointUnitDifferent(excelData, data){
    let createNewItem = false;
    if(excelData.data_point.length == data.quotation_mit_item_detail_info.length){
      excelData.data_point.map((ex, index)=>{
        ex.data.map(point=>{

          if(!data.quotation_mit_item_detail_info.find(item=> item.product_calibration_fee_id == point.product_calibration_fee_id)?.quotation_mit_item_detail_points.find(item=> item.mit_point == point.data)){
            createNewItem = true;
          }
        })
      })
    }else{
      createNewItem = true;
    }

    if(createNewItem){
      let calibrationFreeResponse = await this.MITEquipmentService.getById({mit_equipment_id: data.mit_equipment_id});
      let product_calibration_fee_info = calibrationFreeResponse.resultData.product_calibration_fee_info;

      data.quotation_mit_item_detail_info.map(item=>{
        item.is_non_calibration = "true";
      })
 
      excelData.data_point.map(async (point, index)=>{
        let calibration_fee = product_calibration_fee_info.find(item=> item.product_calibration_fee_id == point.data[0].product_calibration_fee_id);

        let points = [];
        await Promise.all(point.data.map(async  (p)=>{
          let custom = {
            cus_unit_id: calibration_fee.unit_id,
            cus_unit_name: calibration_fee.unit_info?.unit_short_name_en,
            mit_unit_id: calibration_fee.unit_id,
            mit_unit_name: calibration_fee.unit_info?.unit_short_name_en,
            cus_point: p.data,
            mit_point: p.data,
            is_non_calculate: "false",
            is_non_calibration: "false",
            quotation_mit_item_detail_id: null,
            quotation_mit_item_detail_point_id: null,
          }
          points.push(
            custom
          )
        }));

        data.quotation_mit_item_detail_info.push(
          {
            ...this.newQuotationDetail(data, calibration_fee),
            is_non_calibration: "false",
            is_non_calculate: "true",
            quotation_mit_item_detail_id: null,
            quotation_mit_item_detail_points: [
              ...points
            ]
          }
        )
      })
    }
    return data;
  }

  async calculateTotalAmountItemDetail(data){
    const getDetailList = data.quotation_mit_item_detail_info.filter(item =>(
      (item.min_point != 0 && item.min_point != null) && 
      (item.is_non_calibration == "false") &&
      (item.quotation_mit_item_detail_points.length > 0 ||
        (item.quotation_mit_item_detail_range.cus_unit_id != null &&
          item.quotation_mit_item_detail_range.cus_unit_id != '')
      )
    ));
    let detail_high_price_start = null;
    if(getDetailList.length > 0){
      detail_high_price_start = getDetailList.reduce((a, b) => a.price_start > b.price_start ? a : b);
    }
    data.quotation_mit_item_detail_info.filter(f=>f.is_non_calibration == "false").map((item, index)=>{
      const control = item;
      const number_of_detail_points = control.quotation_mit_item_detail_points.length;
      if(control.detail_type == 'point'){
        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
          if(control.min_point == number_of_detail_points){
            data.quotation_mit_item_detail_info.filter(f=>f.is_non_calibration == "false")[index].total_amount = control.price_start;
          }else if(control.min_point > number_of_detail_points){
            data.quotation_mit_item_detail_info.filter(f=>f.is_non_calibration == "false")[index].total_amount = number_of_detail_points * control.price_point;
          }else if (control.min_point < number_of_detail_points){
            const dif = number_of_detail_points - control.min_point;
            data.quotation_mit_item_detail_info.filter(f=>f.is_non_calibration == "false")[index].total_amount = (dif * control.price_point) + control.price_start;
          }
        }else{
          data.quotation_mit_item_detail_info.filter(f=>f.is_non_calibration == "false")[index].total_amount = number_of_detail_points * control.price_point;
        }
      }else if(control.detail_type == 'range'){
   
        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
          data.quotation_mit_item_detail_info.filter(f=>f.is_non_calibration == "false")[index].total_amount = control.price_start;
        }else{
          data.quotation_mit_item_detail_info.filter(f=>f.is_non_calibration == "false")[index].total_amount =control.min_point * control.price_point;
        }
      }
    })  
    data = this.calculateTotalAmountItem(data);
    return data;
  }

  async calculateTotalAmountItem(data){
    let countOfItem = 0;
    let priceStart = 0;
    let isMinPoint = false;
    let mostTotalAmount = 0;
    let mostTotalAmountItem = null;

    if(data.quotation_mit_item_detail_info.filter(f=>f.is_non_calibration == "false").length > 0){
      data.quotation_mit_item_detail_info.filter(f=>f.is_non_calibration == "false").map(item=>{
        if(item.total_amount != 0 && priceStart < item.price_start) {
          priceStart = item.price_start;
        }
        if(item.total_amount != 0 && item.min_point != 0) {
          isMinPoint = true;
        } 
        if(item.total_amount != 0 && mostTotalAmount < item.total_amount) {
          mostTotalAmount = item.total_amount;
          mostTotalAmountItem = item;
        } 
        countOfItem = countOfItem + item.total_amount;
      })
    }
    let count = countOfItem;
    if(data.quotation_mit_item_attribute_info.length > 0){
      data.quotation_mit_item_attribute_info.map(v=>{
        if(v.attribute_value){
          v.attribute_value = this.utils.transformDecimal(v.attribute_value, '1.0-0');
        }else{
          v.attribute_value = null;
        }
      })
    }


    if(isMinPoint && data.quotation_mit_item_attribute_info.find(attr=> attr.attribute_value != null)){
      let sumAttr = 0;
      data.quotation_mit_item_attribute_info.map(v=>{
        if(v.attribute_value) sumAttr = sumAttr + parseFloat(v.attribute_value);
      })
      sumAttr--;
      if(mostTotalAmountItem.detail_type == 'point'){
        count = count + ((mostTotalAmountItem.quotation_mit_item_detail_points.length * sumAttr) *  mostTotalAmountItem.price_point);
      }else{
        count = count + ((mostTotalAmountItem.min_point * sumAttr) *  mostTotalAmountItem.price_point);
      }
    }else{
      if(data.quotation_mit_item_attribute_info.length > 0){
        data.quotation_mit_item_attribute_info.map(v=>{
          if(v.attribute_value) count = count * parseFloat(v.attribute_value);
        })
      }
      if(!isMinPoint){
        count = count + priceStart;
      }
    }
    data.original_price = count;
    return data;
  }

  async calculateTotalAmountItemDetailExcel(data){
    const getDetailList = data.quotation_mit_item_details.filter(item =>(
      (item.min_point != 0 && item.min_point != null) && 
      (item.is_non_calibration == "false") &&
      (item.quotation_mit_item_detail_points.length > 0 ||
        (item.quotation_mit_item_detail_range.cus_unit_id != null &&
          item.quotation_mit_item_detail_range.cus_unit_id != '')
      )
    ));
    let detail_high_price_start = null;
    if(getDetailList.length > 0){
      detail_high_price_start = getDetailList.reduce((a, b) => a.price_start > b.price_start ? a : b);
    }
    data.quotation_mit_item_details.filter(f=>f.is_non_calibration == "false").map((item, index)=>{
      const control = item;
      const number_of_detail_points = control.quotation_mit_item_detail_points.length;
      if(control.detail_type == 'point'){
        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
          if(control.min_point == number_of_detail_points){
            data.quotation_mit_item_details.filter(f=>f.is_non_calibration == "false")[index].total_amount = control.price_start;
          }else if(control.min_point > number_of_detail_points){
            data.quotation_mit_item_details.filter(f=>f.is_non_calibration == "false")[index].total_amount = number_of_detail_points * control.price_point;
          }else if (control.min_point < number_of_detail_points){
            const dif = number_of_detail_points - control.min_point;
            data.quotation_mit_item_details.filter(f=>f.is_non_calibration == "false")[index].total_amount = (dif * control.price_point) + control.price_start;
          }
        }else{
          data.quotation_mit_item_details.filter(f=>f.is_non_calibration == "false")[index].total_amount = number_of_detail_points * control.price_point;
        }
      }else if(control.detail_type == 'range'){
   
        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
          data.quotation_mit_item_details.filter(f=>f.is_non_calibration == "false")[index].total_amount = control.price_start;
        }else{
          data.quotation_mit_item_details.filter(f=>f.is_non_calibration == "false")[index].total_amount =control.min_point * control.price_point;
        }
      }
    })  
    data = this.calculateTotalAmountItemExcel(data);
    return data;
  }

  async calculateTotalAmountItemExcel(data){
    let count_of_item = 0;
    let price_start = 0;
    let is_min_point = false;
    if(data.quotation_mit_item_details.filter(f=>f.is_non_calibration == "false").length > 0){
      data.quotation_mit_item_details.filter(f=>f.is_non_calibration == "false").map(item=>{
        if(item.total_amount != 0 && price_start < item.price_start) {
          price_start = item.price_start;
        }
        if(item.total_amount != 0 && item.min_point != 0) {
          is_min_point = true;
        } 
        count_of_item = count_of_item + item.total_amount;
      })
    }
    let count = count_of_item;
    if(is_min_point){
    }else{
      count = count + price_start;
    }
    data.original_price = count;
    return data;
  }  

  public newQuotationDetail(data, calibration_fee){
    return {
      ...data.quotation_mit_item_detail_info[0],
      quotation_mit_item_id: null,
      service_location: this.getServiceLocation(data.mit_equipment_info) || '',
      mit_cp_id: calibration_fee.product_calibration_fee_cp_info[0]?.mit_cp_id || null,
      mit_method_id:calibration_fee.mit_method_id,
      product_calibration_fee_id:calibration_fee.product_calibration_fee_id || null,
      price_start: calibration_fee.start_fee || null,
      price_point: calibration_fee.per_point_fee || 0,
      min_point: calibration_fee.min_point || 0,
      total_amount: 0,
      detail_type: 'point',
      detail_input_type: 'point',

      cus_unit_id: calibration_fee.unit_id || null,
      cus_unit_name: calibration_fee.unit_info.unit_short_name_en || calibration_fee.unit_info.unit_short_name_th || null,
      unit_id: calibration_fee.unit_id || null,
      unit_name: calibration_fee.unit_info.unit_short_name_en || calibration_fee.unit_info.unit_short_name_th || null,
      isISO: calibration_fee.isISO || null,
      min_range: calibration_fee.min_range || 0,
      max_range: calibration_fee.max_range || 0,
      cp_name: calibration_fee.product_calibration_fee_cp_info[0]?.mit_cp_info?.cp_name || null,
      method_name:calibration_fee.mit_method_info?.method_name || null,
      cpInfo: calibration_fee.product_calibration_fee_cp_info || [],
      quotation_mit_item_detail_points: [],
      quotation_mit_item_detail_range: {
        quotation_mit_item_detail_range_id: '',cus_min: 0,cus_max: 0,cus_unit_id: '',cus_unit_name: '',mit_min: 0,mit_max: 0,
        mit_unit_id: '', mit_unit_name: ''
      },
      unit_short_name_en: calibration_fee.unit_info.unit_short_name_en || null,
    }
  }

  async mapExcel(ex){
    ex.quotation_mit_item_info.point.data_point.map((data, index)=>{
      let unique = [...new Set(data.data.map(item => item.product_calibration_fee_id))];
      if(unique.length > 1){
  
        ex.quotation_mit_item_info.point.data_point.splice(index, 1);
        ex.quotation_mit_item_info.point.type = "unit_different";
        let unitInfo = ex.quotation_mit_item_info.point.unit_info;

        ex.quotation_mit_item_info.point.unit_info = [];
        unique.map(u=>{
          let points = data.data.filter(p=> p.product_calibration_fee_id == u);

          ex.quotation_mit_item_info.point.data_point = [{
            is_valid: true,
            data: points
          }].concat( ex.quotation_mit_item_info.point.data_point)

          if(ex.quotation_mit_item_info.point.unit_info){
            ex.quotation_mit_item_info.point.unit_info.push(unitInfo);
          }
        })
      }else{
        ex.quotation_mit_item_info.point.type = "point";
      }
    })
    return ex;
  }

  async addNewDetail(excelData, ex){
    let data = [];

    let unit_info = excelData.unit_info;
    let point = excelData.data_point[0].data;

    let details = point.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.product_calibration_fee_id === value.product_calibration_fee_id
      ))
    )

    await Promise.all(details.map(async (detail)=>{
      let new_mit_detail = {
        mpe: ex.quotation_mit_item_info?.quotation_mit_item_detail_info?.mpe,
        scope_name: detail.product_calibration_fee_info.mit_equipment_info?.mit_scope_info.scope_name,
        equipment_name: detail.product_calibration_fee_info.mit_equipment_info?.equipment_name,
        mit_scope_id: detail.product_calibration_fee_info.mit_equipment_info?.mit_scope_id,
        mit_equipment_id: detail.product_calibration_fee_info.mit_equipment_info?.mit_equipment_id,
        quotation_mit_item_id: null,
        service_location: this.getServiceLocation(detail.product_calibration_fee_info.mit_equipment_info) || '',
        mit_cp_id: detail.product_calibration_fee_info.product_calibration_fee_cp_info[0]?.mit_cp_id || null,
        mit_method_id: detail.product_calibration_fee_info.mit_method_id,
        product_calibration_fee_id: detail.product_calibration_fee_id || null,
        price_start: detail.product_calibration_fee_info.start_fee || null,
        price_point: detail.product_calibration_fee_info.per_point_fee || 0,
        min_point: detail.product_calibration_fee_info.min_point || 0,
        total_amount: 0,
        detail_type: excelData.type =='range'? 'range':'point',
        detail_input_type: excelData.type =='range'? 'range':'point',
        cus_unit_id: unit_info[0].unit_id || null,
        cus_unit_name: unit_info[0].unit_short_name_en || null,

        unit_id: unit_info[0].unit_id || null,
        unit_name: unit_info[0].unit_short_name_en || null,
        isISO: detail.product_calibration_fee_info.isISO || null,
        min_range: detail.product_calibration_fee_info.min_range || 0,
        max_range: detail.product_calibration_fee_info.max_range || 0,
        cp_name: detail.product_calibration_fee_info.product_calibration_fee_cp_info[0]?.mit_cp_info?.cp_name || null,
        method_name: detail.product_calibration_fee_info.mit_method_info?.method_name || null,
        cpInfo: detail.product_calibration_fee_info.product_calibration_fee_cp_info|| [],
        quotation_mit_item_detail_points: [],
        quotation_mit_item_detail_range: {
          quotation_mit_item_detail_range_id: '',
          cus_min: 0,
          cus_max: 0,
          cus_unit_id: '',
          cus_unit_name: '',
          mit_min: 0,
          mit_max: 0,
          mit_unit_id: '', 
          mit_unit_name: ''
        },
        unit_short_name_en: unit_info[0].unit_short_name_en || null,
      }
      data.push(new_mit_detail);
    }));

    if(excelData.type =='range'){
      await Promise.all(data.map(async (cali, index)=>{
        let rangeItem = point.find(p=> p.product_calibration_fee_id == cali.product_calibration_fee_id);
        
        let min_point = rangeItem?.data?.toString().split("(")[1]?.split(")")[0] || 0;
        let min = "";
        let max = "";

        if(rangeItem?.data?.toString().split("-").length  == 3){
          min = '-'+rangeItem?.data?.toString().split("-")[1];
          max = rangeItem?.data?.toString().split("-")[2]?.split("(")[0];
        }else{
          min = rangeItem?.data?.toString().split("-")[0];
          max = rangeItem?.data?.toString().split("-")[1]?.split("(")[0];
        }

        let range = {
          ...cali.quotation_mit_item_detail_range,
          cus_unit_id: cali.unit_id,
          cus_unit_name: cali?.unit_short_name_en,
          mit_unit_id: cali.unit_id,
          mit_unit_name: cali?.unit_short_name_en,
          cus_min: min,
          cus_max: max,
          mit_max: max,
          mit_min: min,
          is_non_calculate: "false",
          is_non_calibration: "false",
          quotation_mit_item_detail_id: null,
          quotation_mit_item_detail_range_id: null,
        }

        data[index] = {
          ...cali,
          is_non_calibration: "false",
          is_non_calculate: "false",
          min_point: parseInt(min_point),
          quotation_mit_item_detail_id: null,
          quotation_mit_item_detail_range: range
        }
      }));
    }else{

      await Promise.all(data.map(async (cali, index)=>{
        let points = [];
        await Promise.all(point.filter(p=> p.product_calibration_fee_id == cali.product_calibration_fee_id).map(async  (p)=>{
          let custom = {
            cus_unit_id: cali.unit_id,
            cus_unit_name: cali?.unit_short_name_en,
            mit_unit_id: cali.unit_id,
            mit_unit_name: cali?.unit_short_name_en,
            cus_point: p.data,
            mit_point: p.data,
            is_non_calculate: "false",
            is_non_calibration: "false",
            quotation_mit_item_detail_id: null,
            quotation_mit_item_detail_point_id: null,
          }
          points.push(
            custom
          )
        }));

        data[index] = {
          ...cali,
          is_non_calibration: "false",
          is_non_calculate: "false",
          quotation_mit_item_detail_id: null,
          quotation_mit_item_detail_points: [
            ...points
          ]
        }
      }));
    }

    return data;
  }

  public getServiceLocation(equipment: any): string{
    if (equipment.service_location.includes('ONSITE') && equipment.service_location.includes('LAB')){
      return 'LAB';
    }else if(equipment.service_location.includes('ONSITE')){
      return 'ONSITE';
    }else{
      return 'LAB';
    }
  }
}