import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
   QuotationService,
   QuotationMitItemService
} from 'src/app/services/quotations';

@Component({
  selector: 'app-add-quotation-item',
  templateUrl: './add-quotation-item.component.html',
  styleUrls: ['./add-quotation-item.component.css']
})
export class AddQuotationItemComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  quotationList = [];
  quotationMitItems = [];
  //any
  @Input() receive: any;
  @Input() customer_info:any;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private QuotationService: QuotationService,
    private QuotationMitItemService: QuotationMitItemService
  ) { }

  public ngOnInit(): void {}

  async ngOnChanges(changes: SimpleChanges) {
    this.setForm();
    await this.QuotationService.load(null, {customer_id: this.customer_info.customer_id, doc_status_id: 'ACCEPTED'})
    .then(res=>{
      this.quotationList = res.resultData || [];
      this.quotationList= this.quotationList.map(elem => (
        {
          id: elem.quotation_info_id,
          text:  `[${elem?.doc_no || ''}] (${elem?.customer_info.customer_code || ''}) ${elem?.customer_info.company_name || ''}`,
          data: elem
        } 
      ));
    })
  }

  public setForm(){
    this.form = this.formBuilder.group({
      quotation_info_id: ["", Validators.required],
      quotation_mit_item_id: ["", Validators.required],
      quotation_mit_item_info: [""],
    });
  }

  async selectQuotation(event){
    if(event){
      await this.QuotationMitItemService.load(null, {quotation_info_id: event})
      .then(res=>{
        this.quotationMitItems = res.resultData || [];

        this.form.get('quotation_mit_item_id').setValue(null);
        this.form.get('quotation_mit_item_info').setValue(null);
      })
    }
  }

  public submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.done.emit(this.form.value);
    this.closeModalBtn.nativeElement.click();
  }

  public close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.quotationMitItems = [];
        this.setForm();
      }
    });
  }

  public onCheckChange(index: number){
    let getMitItem = this.quotationMitItems[index];
    this.form.get('quotation_mit_item_id').setValue(getMitItem.quotation_mit_item_id);
    this.form.get('quotation_mit_item_info').setValue(getMitItem);
  }

  public getMethodName(mit){
    let item = mit.filter(item=> item.total_amount != 0);  

    item = mit.filter((mit, index, self) =>
      index === self.findIndex((t) => (
        t.method_name === mit.method_name 
      ))
    )
    let methodName = "";
    item.map((item, index)=>{
      if(index == 0){
        methodName = item.method_name;
      }else{
        methodName = methodName + ", "+ item.method_name;
      }
    })
    return methodName;
  }
}
