import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { 
  UtilsService,
  TransportPreBookingService
} from '../../../../services';

import { NgSelect2Customer } from 'src/app/services/quotations';
import { NavigationStart, Router } from "@angular/router";
import moment from "moment";

@Component({
  selector: 'app-pre-booking-create',
  templateUrl: './pre-booking-create.component.html',
  styleUrls: ['./pre-booking-create.component.css']
})
export class PreBookingCreateComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") closeModal: ElementRef;
  @Input() transportTypes = [];
  @Input() id = "modalPreBookingCreate";

  select2OptionCustomer = null;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private NgSelect2Customer: NgSelect2Customer,
    private TransportPreBookingService: TransportPreBookingService,
    
    public UtilsService: UtilsService,
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModal.nativeElement.click();
      }
    });
    this.setForm();
  }

  setForm(){
    this.form = this.formBuilder.group({
      booking_type: ["", [Validators.required]],
      booking_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      booking_time: [""],
      customer_id: [null, [Validators.required]],
      location: ["", [Validators.required]],
      extra_message: [""],
      quotation_info_doc_no: [""],
      job_order_info_doc_no: [""],
      transport_receive_info_doc_no: [""],
      transport_type_id: [""],
      contact_name: ["", [Validators.required]],
      contact_phonenumber: ["", [Validators.required]],
      doc_status_id: ["WAITING_FOR_APPROVE"]
    });
  }

  ngOnInit(): void {
    this.select2OptionCustomer = this.NgSelect2Customer
    .select2Options("?limit=10&&exclude_customer_state_id=Free&&is_exclude_address=true");
  }

  async onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let bookingResponse = await this.TransportPreBookingService.create(this.form.value);
    if (bookingResponse.success) {
      this.save.emit({
        status: bookingResponse,
        closeModal: this.closeModal
      })
    } else {
      this.save.emit({
        status: bookingResponse,
        closeModal: this.closeModal
      })
    }
    this.submitted = false;
    this.setForm();
  }

  onClose() {
    this.setForm();
    this.save.emit(false);
  }
}
