import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/auth.service";
import { environment } from "../../../environments/environment";
import { EmailValidator } from "src/app/validators";
import { ResponseStatusService } from "src/app/services/repsonseStatus.service";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  email: string;
  password: string;
  submitted: boolean = false;
  isLoading = true;
  return: string;

  title = environment?.app?.title;
  status: any;
  
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private AuthService: AuthService,
    private ResponseStatusService: ResponseStatusService
  ) {
  }
  ngOnInit(): void {
    if (localStorage.getItem("token")) {
      this.router.navigate(["/landing"]);
    }else{
      this.route.queryParams.subscribe(
        (params) => (this.return = params["return"] || "/landing")
      );
      document.body.className = "";
      
      this.form = this.formBuilder.group({
        email: ["", [Validators.required, EmailValidator()]],
        password: ["", Validators.required],
      });
  
      this.form.valueChanges.subscribe(() => {
        this.status = null;
      });
      this.isLoading = false;
    }
  }
  async auth() {
    this.submitted = true;
    if (this.form.valid) {
      let response = await this.AuthService.auth(this.form.value);
      if (response.success) {
        localStorage.setItem("token", response.resultData.access_token);
        this.router.navigateByUrl(this.return || "/landing");
      } else {
        this.status = response.error
        if(response.error.resultCode !=='40302'){
          this.status = this.ResponseStatusService.getResponseStatus("40102");
        }

      }
    }
  }
}
