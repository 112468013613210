<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">รายการข้อมูลสินค้า</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#"     [routerLink]="['/product']">จัดการข้อมูลสินค้า</a>
              </li>
              <li class="breadcrumb-item active">รายการข้อมูลสินค้า</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <a
            href="#"
            class="btn btn-outline-primary"
            [routerLink]="['/product/create']"
          >
            <i class="feather icon-plus-circle icon-left"></i> เพิ่มข้อมูลสินค้า
          </a>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">รายการข้อมูลสินค้า</h4>
              <div class="heading-elements">
                <search-box (search)="search($event)"></search-box>
              </div>
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>รหัสสินค้า</th>
                        <th>ชื่อสินค้า</th>
                        <th>ประเภท</th>
                        <th class="text-right">ราคาขาย</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of products; let i = index">
                        <th>{{ product.product_code }}</th>
                        <td>{{ product.product_title }}</td>
                        <td>
                          {{ product.product_type_info?.product_type_name }}
                        </td>
                        <td class="text-right">
                          <span *ngIf="product.product_type_id === '3'">-</span>
                          <span
                            *ngIf="product.product_price_unit_info"
                            class="d-flex justify-content-end"
                          >
                            {{
                              product.product_price_unit_info?.price
                                | number: "1.2-2"
                            }}
                            /&nbsp;

                            <span
                              [innerHTML]="
                                product.product_price_unit_info?.unit_info
                                  ?.unit_short_name_en || '-'
                              "
                            ></span>
                          </span>
                        </td>
                        <td>
                          <a
                            href="#"
                            class="btn mr-1 mb-1 btn-warning btn-sm btn-edit"
                            [routerLink]="[
                              '/product/' + product.product_id + '/edit'
                            ]"
                            ><i class="fa fa-pencil icon-left"></i> แก้ไข
                          </a>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 btn-danger btn-sm"
                            data-target="#modalConfirmDelMsg"
                            data-toggle="modal"
                            (click)="select(product.product_id)"
                          >
                            <i class="fa fa-trash icon-left"></i> ลบ
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="!products.length">
                        <td colspan="5" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<app-confirm-delete (save)="delete($event)"></app-confirm-delete>
