import { Injectable } from "@angular/core";
import { 
    QuotationUtilsService 
} from '../../../../../../services/quotations';
import { FormBuilder, FormGroup, ValidatorFn } from '@angular/forms';

@Injectable({
   providedIn: "root"
})
export class FormQuotationMitService {

  constructor(
    public utils: QuotationUtilsService,
    private fbd: FormBuilder
  ) {}

  createDetailItem(data:any, equipment:any): FormGroup {
    data?.product_calibration_fee_cp_info.sort(
      (a: any, b: any) => (a.mit_cp_id > b.mit_cp_id ? 1 : -1)
    );
    return this.fbd.group({
      quotation_mit_item_id: this.fbd.control(null),
      service_location: this.fbd.control(this.getServiceLocation(equipment) || ''),
      mit_cp_id: this.fbd.control(data?.product_calibration_fee_cp_info[0]?.mit_cp_id || null),
      mpe: this.fbd.control(''),
      note: this.fbd.control(data.note || null),
      mit_scope_id: this.fbd.control(equipment.mit_scope_id || null),
      mit_equipment_id: this.fbd.control(equipment.mit_equipment_id || null),
      mit_method_id: this.fbd.control(data.mit_method_id || null),
      product_calibration_fee_id:this.fbd.control(data.product_calibration_fee_id || null),
      price_start: this.fbd.control(data.start_fee || null),
      price_point: this.fbd.control(data.per_point_fee || 0),
      min_point: this.fbd.control(data.min_point || 0),
      total_amount: this.fbd.control(0),
      detail_type: this.fbd.control('point'),
      detail_input_type: this.fbd.control('point'),
      cus_unit_id: this.fbd.control(data.unit_id || null),
      cus_unit_name: this.fbd.control(data.unit_info.unit_short_name_en || data.unit_info.unit_short_name_th || null),
      unit_id: this.fbd.control(data.unit_id || null),
      unit_name: this.fbd.control(data.unit_info.unit_short_name_en || data.unit_info.unit_short_name_th || null),
      isISO: this.fbd.control(data.isISO || null),
      min_range: this.fbd.control(data.min_range || 0),
      max_range: this.fbd.control(data.max_range || 0),
      cp_name: this.fbd.control(data.cp_name || null),
      method_name: this.fbd.control(data.mit_method_info?.method_name || null),
      cpInfo: this.fbd.control(data?.product_calibration_fee_cp_info || []),
      quotation_mit_item_detail_points: this.fbd.array([]),
      quotation_mit_item_detail_range: this.fbd.group({
        quotation_mit_item_detail_range_id: this.fbd.control(''),cus_min: this.fbd.control(0),cus_max: this.fbd.control(0),
        cus_unit_id: this.fbd.control(''),cus_unit_name: this.fbd.control(''),mit_min: this.fbd.control(0),
        mit_max: this.fbd.control(0), mit_unit_id: this.fbd.control(''),mit_unit_name: this.fbd.control('')
      }),
      number_of_point: this.fbd.control(null),
      scope_name: this.fbd.control(equipment.mit_scope_info?.scope_name || null),
      equipment_name: this.fbd.control(equipment.equipment_name || null),
      unit_short_name_en: this.fbd.control(data.unit_info.unit_short_name_en || data.unit_info.unit_short_name_th || null),
    }, { 
      validator: [
        this.customValidatePoints(), 
        this.customValidateRangePoints()
      ]
    });
  }

  createDetailItemCurrent(data:any, dataInit: any): FormGroup {
    const product_calibration_fee_info = dataInit.product_calibration_fee_info.find((item: any)=>
      item.product_calibration_fee_id === data.product_calibration_fee_id
    );
    product_calibration_fee_info?.product_calibration_fee_cp_info.sort(
      (a: any, b: any) => (a.mit_cp_id > b.mit_cp_id ? 1 : -1)
    );

    return this.fbd.group({
      quotation_mit_item_detail_id:  this.fbd.control(data.quotation_mit_item_detail_id || null),
      quotation_mit_item_id: this.fbd.control(null),
      service_location: this.fbd.control(data.service_location.toUpperCase() || ''),
      mit_cp_id: this.fbd.control(data.mit_cp_id || null),
      mpe: this.fbd.control(data.mpe),
      note: this.fbd.control(data.note || null),
      mit_scope_id: this.fbd.control(data.mit_scope_id || null),
      mit_equipment_id: this.fbd.control(data.mit_equipment_id || null),
      mit_method_id: this.fbd.control(data.mit_method_id || null),
      product_calibration_fee_id:this.fbd.control(data.product_calibration_fee_id || null),
      price_start: this.fbd.control(data.price_start || null),
      price_point: this.fbd.control(data.price_point || 0),
      min_point: this.fbd.control(data.min_point || 0),
      total_amount: this.fbd.control(data.total_amount),
      old_total_amount: this.fbd.control(data.old_total_amount? data.old_total_amount: data.total_amount),
      quotation_mit_item_detail_points: this.fbd.array([]),
      detail_type: this.fbd.control(data.detail_type || 'point'),
      detail_input_type: this.fbd.control(data.detail_input_type || 'point'),
      cus_unit_id: this.fbd.control(data.cus_unit_id || null),
      cus_unit_name: this.fbd.control(data.unit_name || null),
      unit_id: this.fbd.control(data.unit_id || null),
      unit_name: this.fbd.control(data.unit_name || null),
      isISO: this.fbd.control(data.isISO || null),
      min_range: this.fbd.control(data.min_range || 0),
      max_range: this.fbd.control(data.max_range || 0),
      cp_name: this.fbd.control(data.cp_name || null),
      method_name: this.fbd.control(data.method_name || null),
      cpInfo: this.fbd.control(product_calibration_fee_info?.product_calibration_fee_cp_info || []),
      quotation_mit_item_detail_range: this.fbd.group({
        quotation_mit_item_detail_range_id: this.fbd.control(
          data.quotation_mit_item_detail_range?.quotation_mit_item_detail_range_id || ''
        ),
        cus_min: this.fbd.control(this.utils.transformDecimal(data.quotation_mit_item_detail_range?.cus_min || 0, '1.1-5')),
        cus_max: this.fbd.control(this.utils.transformDecimal(data.quotation_mit_item_detail_range?.cus_max || 0, '1.1-5')),
        cus_unit_id: this.fbd.control(data.quotation_mit_item_detail_range?.cus_unit_id || ''),
        cus_unit_name: this.fbd.control(data.quotation_mit_item_detail_range?.cus_unit_name || ''),
        mit_min: this.fbd.control(this.utils.transformDecimal(data.quotation_mit_item_detail_range?.mit_min || 0, '1.1-5')),
        mit_max: this.fbd.control(this.utils.transformDecimal(data.quotation_mit_item_detail_range?.mit_max || 0, '1.1-5')),
        mit_unit_id: this.fbd.control(data.quotation_mit_item_detail_range?.mit_unit_id || ''),
        mit_unit_name: this.fbd.control(data.quotation_mit_item_detail_range?.mit_unit_name || '')
      }),
      number_of_point: this.fbd.control(null),
      scope_name: this.fbd.control(data?.scope_name || null),
      equipment_name: this.fbd.control(data?.equipment_name || null),
      unit_short_name_en: this.fbd.control(data.unit_short_name_en || null),
    }, {
      validator: [
        this.customValidatePoints(), 
        this.customValidateRangePoints()
      ]
    });
  }

  createPointItem(data:any): FormGroup {
    return this.fbd.group({
      quotation_mit_item_detail_point_id: this.fbd.control(data.quotation_mit_item_detail_point_id || null),
      cus_point: this.fbd.control(data.cus_point || 0),
      cus_unit_id: this.fbd.control(data.cus_unit_id || null),
      cus_unit_name: this.fbd.control(data.cus_unit_name || null),
      mit_point: this.fbd.control(data.mit_point || 0),
      mit_unit_id: this.fbd.control(data.mit_unit_id || null),
      mit_unit_name: this.fbd.control(data.mit_unit_name || null),
      is_non_calculate: this.fbd.control(data.is_non_calculate || null),
    });
  }

  createAttributeItem(data:any): FormGroup {
    return this.fbd.group({
      quotation_mit_item_attribute_id: this.fbd.control(data.quotation_mit_item_attribute_id || null),
      attribute_name: this.fbd.control(data.attribute_name || null),
      attribute_value: this.fbd.control(data.attribute_value || null),
      is_non_calculate: this.fbd.control(data.is_non_calculate || null),
      is_non_calibration: this.fbd.control(data.is_non_calibration || null)
    });
  }
  
  public getServiceLocation(equipment: any): string{
    if (equipment.service_location.includes('ONSITE') && equipment.service_location.includes('LAB')){
      return 'LAB';
    }else if(equipment.service_location.includes('ONSITE')){
      return 'ONSITE';
    }else{
      return 'LAB';
    }
  }

  customValidateRequirePoints(): ValidatorFn {
    return (FormGroup:FormGroup):{[key: string]: any} | null=>{
      const form = FormGroup.value;
      let result = false;
      form.quotation_mit_item_detail_info.map(v=>{
        if(v.detail_type == 'point'){
          if(v.quotation_mit_item_detail_points.length > 0){
            result = true;
          }
        }else{
          result = true;
        }
      })
      return !result ? { invalidRequirePoint: true } : null;
    }
  };

  customValidatePoints(): ValidatorFn {
    return (FormGroup:FormGroup):{[key: string]: any} | null=>{
      const form = FormGroup.value;
      let result = true;
      if(form && form.min_point != 0 && form.quotation_mit_item_detail_points.length > 0){
        if(form.min_point > form.quotation_mit_item_detail_points.length){
          result = false;
        }else{
          result = true;
        }
      }
      return !result ? { invalidPoint: true } : null;
    }
  };

  customValidateRangePoints(): ValidatorFn {
    return (FormGroup:FormGroup):{[key: string]: any} | null=>{
      const form = FormGroup.value;
      let result = false;
      if(form.detail_type == 'range'){
        let range = form.quotation_mit_item_detail_range;
        if(parseFloat(this.convertStingToNumber(range.cus_min)) < parseFloat(form.min_range) 
        || parseFloat(this.convertStingToNumber(range.cus_max)) > parseFloat(form.max_range)
        || parseFloat(this.convertStingToNumber(range.cus_min)) > parseFloat(this.convertStingToNumber(range.cus_max))      
        ){
          result = true;
        }else{
          result = false;
        }
      }else{
        result = form.quotation_mit_item_detail_points.find(
          (item: any)=>  parseFloat(item.cus_point) < parseFloat(this.convertStingToNumber(this.utils.transformDecimal(form.min_range, '1.1-5'))) || parseFloat(item.cus_point) > parseFloat(this.convertStingToNumber(this.utils.transformDecimal(form.max_range, '1.1-5')))
        );
      }
      return result ? { invalidRangePoint: true } : null;
    }
  };

  convertStingToNumber(str){
    if(isNaN(str)) str = str.split(',').join('');
    return str;
  }
}