<div class="container app-content overflow-container">
  <div class="btn-section text-right border-bottom">
    <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(taxInvoiceInfo)">
        <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
    </button>
  </div>
  <div *ngFor="let page of pages; let i = index; let lastIndex = last">
    <div class="pdf" id="invoice_{{i}}">
      <div class="container">
        <div class="row">
          <img class="col-1 text-right p-0" src="/assets/app-assets/images/pdf/logo1.png" width="90px"
              height="110px">
          <div class="col-7 pt-1">
              <h2 class="text-left">บริษัท มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี จำกัด</h2>
              <h3 class="text-left">MIRACLE INTERNATIONAL TECHNOLOGY CO., LTD.</h3>
              <p class="text-left">214 ถนนบางแวก แขวงบางไผ่ เขตบางแค กรุงเทพฯ 10160 <br>
                โทร. 0-2865-4647-8 โทรสาร 0-2865-4649
              </p>
              <b class="text-left">เลขประจำตัวผู้เสียภาษี 0-1055-45112-91-9 สถานประกอบการ สำนักงานใหญ่</b>
          </div>
          <div class="col-4">
            <div class=""></div>
            <h3 class="text-center">ต้นฉบับใบเสร็จรับเงิน<br>
            ORIGINAL RECEIPT</h3>
            <h3 class="text-center">ลูกค้า</h3>
              <div class="row mt-2">
                <p class="text-left col-2">เลขที่</p>
                <p class="text-left col-5">{{taxInvoiceInfo?.doc_no_company}}</p>
                <p class="text-right col-5">(ไม่ใช่ใบกำกับภาษี)</p>
              </div>  
            <div class="row">
              <p class="text-left col-6">No.</p>
              <p class="text-right col-6">(เอกสารออกเป็นชุด)</p>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-1" *ngIf="i == 0">
        <div class="row">
            <table class="mx-auto table table-bordered">
              <thead>
                <tr>
                  <th class="text-center" scope="col">วันที่<br>DATE</th>
                  <th class="text-center" scope="col">ใบสั่งซื้อเลขที่<br>PURCHASE ORDER NO.</th>
                  <th class="text-center" scope="col">ผู้สั่งซื้อ<br>PURCHASE ORDER</th>
                  <th class="text-center" scope="col">พนักงานขาย<br>SALEMAN</th>
                  <th class="text-center" scope="col">เงื่อนไขการชำระเงิน<br>TERM</th>
                  <th class="text-center" scope="col">วันครบกำหนดชำระ<br>DUE DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">{{taxInvoiceInfo?.doc_date | thai_date: "short":"date" | dash}}</td>
                  <td class="text-center">{{getPoNo()}}</td>
                  <td class="text-center">{{getQuotationContact()}}</td>
                  <td class="text-center">{{taxInvoiceInfo?.sales_assignment_info[0]?.user_info?.firstname_th}} {{taxInvoiceInfo?.sales_assignment_info[0]?.user_info?.lastname_th || '-'}}</td>
                  <td class="text-center">{{taxInvoiceInfo?.customer_credit_term_day}}</td>
                  <td class="text-center">{{taxInvoiceInfo?.doc_duedate | thai_date: "short":"date" | dash}}</td>
                </tr>
              </tbody>
            </table>
            <table class="mx-auto table table-bordered" *ngIf="i == 0">
              <thead>
                <tr>
                  <td colspan="6" class="text-left pl-2">
                    <p class="mt-1">ชื่อลูกค้า {{taxInvoiceInfo?.customer_address_name}}</p>
                    <p>ที่อยู่ {{taxInvoiceInfo?.customer_address}}</p>
                    <div class="row">
                      <p class="text-left col-6">เลขประจำตัวผู้เสียภาษี {{taxInvoiceInfo?.customer_address_info.tax_id | dash}}</p>
                      <p class="text-left col-6">สถานประกอบการ {{taxInvoiceInfo?.customer_address_info.branch | dash}}</p>
                    </div>
                  </td>
                </tr>
              </thead>
            </table>
            <table class="mx-auto table table-bordered">
              <thead>
                <tr>
                  <th class="text-center" scope="col">ลำดับ<br>ITEM</th>
                  <th class="text-center" scope="col">รายการสินค้า<br>DESCRIPTION</th>
                  <th class="text-center" scope="col">จำนวน<br>QUANTITY</th>
                  <th class="text-center" scope="col">ราคาต่อหน่อย<br>UNIT PRICE</th>
                  <th colspan="2" class="text-center" scope="col">ส่วนลด<br>DISCOUNT</th>
                  <th class="text-center" scope="col">จำนวนเงิน<br>AMOUNT</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of page; let j = index;">
                  <td class="text-center">{{item.index}}</td>
                  <td class="text-left">
                    {{item.product_title}}
                    <div *ngIf="item.item_type=='product'">
                      {{item.product_description}}
                    </div>
                    <div *ngIf="item.item_type=='calibration_service'">
                      <small>
                        <div *ngIf="item.data.description">Description: {{item.data.description | dash}}</div>
                        
                        Serial no: {{item.data.serial_no | dash}} / Tag No. {{item.data.tag_no | dash}}
                      </small>
                    </div>
                  </td>
                  <td class="text-center">{{item.quantity}}</td>
                  <td class="text-right">{{item.price | number : '1.2-2'}}</td>
                  <td colspan="2" class="text-center">
                    <div *ngIf="item?.discount > 0">
                      {{item?.discount}}
                    </div>
                  </td>
                  <td class="text-right">

                    <div *ngIf="item.item_type=='calibration_service'">
                      {{calculate.sumMit(item) | number : '1.2-2'}}
                    </div>
                    <div *ngIf="item.item_type=='product'">
                      <ng-container [ngSwitch]="true">
                        <ng-container *ngSwitchCase="item.quotation_item_id == '' && item.product_id == ''">
                            -{{item.discount | number : '1.2-2'}}
                        </ng-container>
  
                        <ng-container *ngSwitchDefault>
                            {{calculate.sumProduct(item) | number : '1.2-2'}}
                        </ng-container>
                      </ng-container>
                    </div>
                  </td>
                </tr>

                <tr *ngIf="lastIndex">
                  <td>
                    <br>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td  colspan="2"></td>
                  <td></td>
                </tr>
                <tr *ngIf="lastIndex">
                  <td>
                    <br>
                  </td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td  colspan="2"></td>
                  <td></td>
                </tr>

                <tr>
                  <td class="text-center">
                  </td>
                  <td class="text-left">
                    อ้างอิงใบเสนอราคาเลขที่: {{getQuotationNo()}}
                  </td>
                  <td class="text-center"></td>
                  <td class="text-right"></td>
                  <td colspan="2" class="text-center">
                   
                  </td>
                  <td class="text-right">
                   
                  </td>
                </tr>

                <tr *ngIf="lastIndex">
                  <td rowspan="3" colspan="2" class="text-left">
                    หมายเหตุ
                  <div class="row form-group mx-sm-3 mt-4">
                      <div class="col-sm-12">
                        <div class="line-input"></div>
                      </div>
                  </div> 
                  </td>
                  <td class="text-center" colspan="4">ราคาสินค้า SUB TOTAL</td>
                  <td class="text-right">{{count - taxInvoiceInfo?.discount | number : '1.2-2'}}</td>
                </tr>
                <tr *ngIf="lastIndex">
                  <td class="text-center" colspan="4">จำนวนภาษีมูลค่าเพิ่ม {{taxInvoiceInfo?.tax}}% / VAT</td>
                  <td class="text-right">
                    <span *ngIf="calculate.sumProductWithTax(count, taxInvoiceInfo) > 0">
                      {{calculate.sumProductWithTax(count, taxInvoiceInfo) | number : '1.2-2'}}
                    </span>
                  </td>
                </tr>
                <tr *ngIf="lastIndex">
                    <td class="text-center" colspan="4">จำนวนเงินรวมทั้งสิ้น TOTAL AMOUNT</td>
                    <td class="text-right">{{taxInvoiceInfo?.grand_total | number : '1.2-2'}}</td>
                </tr>
                <tr *ngIf="lastIndex">
                  <td rowspan="2" colspan="2" class="text-left">
                    <p>- ใบเสร็จรับเงินนี้จะสมบูรณ์เมื่อมีลายเซ็นต์ผู้รับเงินและมีการโอนเงินเข้าบัญชี หรือเช็คนั้นได้เรียกเก็บเงินจากธนาคารเรียบร้อยแล้ว</p>
                    <p>- โปรดสั่งจ่ายขีดคร่อมในนาม <b>"บริษัท มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี จำกัด"</b></p>
                    <div class="row">
                      <div class="col-8">
                        <div class="form-row">
                          <div class="col-3">
                            <div class="form-check">
                              <label class="form-check-label" for="autoSizingCheck">
                                ชำระโดย<br>
                                PAYMENT BY
                              </label>
                              <input class="form-check-input ml-2" type="checkbox" id="autoSizingCheck" disabled>
                            </div>
                          </div>
                          <div class="col-2">
                            <div class="form-check">
                              <label class="form-check-label" for="autoSizingCheck">
                                เงินสด<br>
                                CASH
                              </label>
                              <input class="form-check-input ml-2" type="checkbox" id="autoSizingCheck" disabled>
                            </div>
                          </div>
                          <div class="col-7">
                            <div class="row">
                              <label for="inputPassword2" class="text-left pl-2" style="font-weight: normal;">เช็คเลขที่<br>CHEQUE NO.</label>
                                  <div class="col-sm-8">
                                      <input type="text" class="form-control line-input" placeholder="">
                                  </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <label for="inputPassword2" class="text-left pl-2" style="font-weight: normal;">ธนาคาร<br>BANK</label>
                              <div class="col-sm-8">
                                  <input type="text" class="form-control line-input" placeholder="">
                              </div>
                        </div> 
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8">
                        <div class="form-row">
                          <div class="col-3">
                           
                          </div>
                          <div class="col-2">
                           
                          </div>
                          <div class="col-7">
                            <div class="row">
                              <label for="inputPassword2" class="text-left pl-2" style="font-weight: normal;">สาขา<br>BRANCH</label>
                                  <div class="col-sm-8">
                                      <input type="text" class="form-control line-input" placeholder="">
                                  </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <label for="inputPassword2" class="text-left pl-2" style="font-weight: normal;">ลงวันที่<br>DUE</label>
                              <div class="col-sm-8">
                                  <input type="text" class="form-control line-input" placeholder="">
                              </div>
                        </div> 
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8">
                        <div class="form-row">
                          <div class="col-12">
                            <div class="row">
                              <label for="inputPassword2" class="text-left pl-2" style="font-weight: normal;margin-left: 10px;">ผู้รับเงิน<br>COLLECTOR</label>
                                  <div class="col-sm-9">
                                      <input type="text" class="form-control line-input" placeholder="">
                                  </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <label for="inputPassword2" class="text-left pl-2" style="font-weight: normal;">วันที่<br>DATE</label>
                              <div class="col-sm-8">
                                  <input type="text" class="form-control line-input" placeholder="">
                              </div>
                        </div> 
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-8">
                        <div class="form-row">
                          <div class="col-12">
                            <div class="row">
                              <label for="inputPassword2" class="text-left pl-2" style="font-weight: normal;margin-left: 10px;">ผู้รับสินค้า<br>RECEIVED BY</label>
                                  <div class="col-sm-9">
                                      <input type="text" class="form-control line-input" placeholder="">
                                  </div>
                            </div> 
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row">
                          <label for="inputPassword2" class="text-left pl-2" style="font-weight: normal;">วันที่<br>DATE</label>
                              <div class="col-sm-8">
                                  <input type="text" class="form-control line-input" placeholder="">
                              </div>
                        </div> 
                      </div>
                    </div>
                  </td>
                  <td class="text-center" rowspan="2" colspan="3">
                    <div class="row form-group">
                      <div class="col-sm-12">
                          <input type="text" class="form-control line-input mt-2" placeholder="">
                      </div>
                    </div>  
                    <label for="inputPassword2" class="col-12" style="font-weight: normal;">ผู้ส่งของ<br>DELIVERY BY</label>
                    <div class="row form-group">
                      <div class="col-sm-12">
                          <input type="text" class="form-control line-input mt-2" placeholder="">
                      </div>
                    </div>  
                    <label for="inputPassword2" class="col-12" style="font-weight: normal;">วันที่<br>DATE</label>
                  </td>
                  <td class="text-center" rowspan="2" colspan="2">
                    <div class="row mt-4">
                      <div class="col-sm-12">
                          <div class="mt-4" style="padding-top: 3px;"></div>
                      </div>
                    </div>  
                    <div class="row form-group">
                          <div class="col-sm-12">
                              <input type="text" class="form-control line-input mt-3" placeholder="">
                          </div>
                    </div>  
                    <label for="inputPassword2" class="col-12" style="font-weight: normal;">ผู้อนุมัติ<br>APPROVED BY</label>
                  </td>
                </tr>
                <tr>
                 
                </tr>
              </tbody>
            </table>
        </div>
      </div>
    </div>

    <div class="btn-section text-right border-bottom"></div>
    <br />
  </div>
</div>
