<div id="create-car" sidebar (close)="close($event)">
    <div
      trigger-sidebar
      data-target="#create-car"
      class="w-100 h-100 position-fixed"
      style="
        left: 0;
        top: 0;
        background-color: black;
        opacity: 0.3;
        display: none;
        z-index: 1;
      "
    ></div>
    <div class="custom-sidebar">
      <div class="card shadow-none p-0 m-0">
        <div class="card-header border-bottom py-1">
          <div
            class="task-header d-flex justify-content-between align-items-center"
          >
            <h5 class="new-task-title mb-0">เพิ่มรถ</h5>
            <span>
              <button
                type="button"
                class="close close-icon"
                trigger-sidebar
                data-target="#create-car"
                (close)="close($event)"
                #closeSidebar
              >
                <i class="feather icon-x align-middle"></i>
              </button>
            </span>
          </div>
        </div>
  
        <form class="form mt-1" [formGroup]="form">
          <div class="card-content">
            <div class="card-body">
                <form-group
                    [control]="form.controls.car_type_id"
                    [submitted]="submitted"
                >
                    <label for="car_type_id">ประเภท <span class="danger" *ngIf="form.enabled">*</span></label>
                    <select
                    class="form-control"
                    id="car_type_id"
                    formControlName="car_type_id"
                    >
                    <option value="">กรุณาเลือกข้อมูล</option>
                    <option
                      *ngFor="let carType of carTypes"
                      [value]="carType.car_type_id"
                    >
                      {{ carType.cart_type_name_th }}</option
                    >
                    </select>
                </form-group>

                <form-group
                [control]="form.controls.brand"
                [submitted]="submitted">
                  <label for="brand">ยี่ห้อ
                    <span class="danger" *ngIf="form.enabled">*</span>
                  </label>
                  <input
                    type="text"
                    id="brand"
                    class="form-control"
                    placeholder="ยี่ห้อ"
                    formControlName="brand"
                  />
                </form-group>
  
                <form-group
                [control]="form.controls.model"
                [submitted]="submitted">
                  <label for="model">รุ่น
                    <span class="danger" *ngIf="form.enabled">*</span>
                  </label>
                  <input
                    type="text"
                    id="model"
                    class="form-control"
                    placeholder="รุ่น"
                    formControlName="model"
                  />
                </form-group>
    
                <form-group
                [control]="form.controls.car_plate"
                [submitted]="submitted">
                    <label for="car_plate">ทะเบียนรถ
                    <span class="danger" *ngIf="form.enabled">*</span>
                    </label>
                    <input
                    type="text"
                    id="car_plate"
                    class="form-control"
                    placeholder="ทะเบียนรถ"
                    formControlName="car_plate"
                    />
                </form-group>
  
            </div>
            <div class="card-body pb-2" *ngIf="form.enabled">
              <div class="mt-1 d-flex justify-content-end">
                <button
                  type="button"
                  class="btn btn-warning mr-1"
                  trigger-sidebar
                  data-target="#create-car"
                >
                  <i class="fa fa-times"></i> ยกเลิก
                </button>
                <button type="button" class="btn btn-primary" (click)="submit()">
                  <i class="fa fa-save"></i> บันทึก
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  