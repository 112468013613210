<div class="card">
    <div class="card-content collapse show">
        <div class="card-body">
            
            <p>ประวัติการแก้ไข</p>
            <loading *ngIf="pageLoad"></loading>
            
            <div class="row" *ngIf="!pageLoad">
              <div class="col-12">
                <section id="timeline" class="timeline-center timeline-wrapper mt-1">
                  <ul class="timeline no-icon"
                    *ngFor="let memo of memoList;">
                  <li class="timeline-line bg-primary"></li>
                  <li class="timeline-item pt-2 pb-0" style="width: 30%;">
                      <div class="timeline-card">
                        <div class="card-content">
                            <div class="card-body">
                            <h5>{{memo.created_at | thai_date: "date" | dash }}</h5>
                            </div>
                        </div>
                      </div>
                  </li>
                  <li class="timeline-item pb-0" style="width: 70%;">
                      <div class="timeline-badge">
                          <span class="border-primary bg-white">
                          </span>
                        </div>
                    
                        <h5 class="mt-1">
                                {{memo.created_by_info?.firstname_th}} 
                                {{memo.created_by_info?.lastname_th}} 
                                {{memo.event_text?memo.event_text: 
                                    memo.document_event_info?.document_event_name_th}}
                        </h5>
                     
                  </li>
                  </ul>
                </section>
              </div>
            </div>
        </div>
    </div>
</div>