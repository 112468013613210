<div class="card">
    <div class="card-content collapse show">
        <div class="card-body">
            
            <p>ลูกค้าลงชื่อรับมอบเครื่องมือ</p>
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 ml-1 mb-1 align-items-center">
                    <label class="switch">
                        <input type="checkbox" [(ngModel)]="check"  (ngModelChange)="isCheckbox()"> 
                        <span class="slider round d-inline"></span>
                    </label>
                    <span style="padding-top: 5px;">
                        ได้ตรวจสอบความถูกต้องครบถ้วนแล้ว
                    </span>
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12">
                    <div class="card box-shadow-0 border-lighten-3 rounded bg-transparent mb-0">
                        <div class="card-content collapse show border">
                            <signature-pad class="mr-0 pr-0" [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
                        </div>
                    </div>
                    <div class="pl-1 text-danger" *ngIf="submitted === true && checkValidate()">
                        โปรดลงชื่อส่งคืนเครื่องมือ
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 col-sm-12 ">
                </div>
                <div class="col-lg-6 col-md-12 col-sm-12 text-right">
                  <label type="button" class="btn btn-light btn-min-width mt-1" (click)="reset()">
                    ลงชื่อใหม่
                  </label>
              </div>
            </div>

        </div>
    </div>
</div>