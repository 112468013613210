import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ResponseStatusService {
  getResponseStatus(code: string): object {
    let data = {
      "20000": {
        resultCode: "20000",
        bootstrapClass: "success",
        resultTitle: { th: "สำเร็จ !", en: "Success !" },
        resultDesc: { th: "ดำเนินการสำเร็จ !", en: "Process has completed." },
      },

      "20100": {
        resultCode: "20100",
        bootstrapClass: "success",
        resultTitle: { th: "สำเร็จ !", en: "Success !" },
        resultDesc: {
          th: "ระบบได้ทำการบันทึกข้อมูลแล้ว !",
          en: "Data has been save.",
        },
      },

      "202": {
        resultCode: "202",
        bootstrapClass: "success",
        resultTitle: { th: "สำเร็จ !", en: "Success !" },
        resultDesc: {
          th:
            "ระบบได้ทำการ Reset password ของผู้ใช้นี้แล้ว แจ้งให้เข้าใช้งานด้วยรหัสผ่านใหม่คือ : ",
          en:
            "The system has reset the password of this user. Let users access with a new password is : ",
        },
      },

      "40101": {
        resultCode: "40101",
        bootstrapClass: "danger",
        resultTitle: { th: "ลองใหม่อีกครั้ง ! ", en: "Please try again !" },
        resultDesc: {
          th: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
          en: "๊Username or password does not match",
        },
      },
      "40102": {
        resultCode: "40102",
        bootstrapClass: "danger",
        resultTitle: { th: "ลองใหม่อีกครั้ง ! ", en: "Please try again !" },
        resultDesc: {
          th: "ชื่อผู้ใช้หรือรหัสผ่านไม่ถูกต้อง",
          en: "๊Username or password does not match",
        },
      },
      "40302": {
        resultCode: "40302",
        bootstrapClass: "danger",
        resultTitle: { th: "ขออภัย ! ", en: "Sorry !" },
        resultDesc: {
          th: "ชื่อผู้ใช้นี้ถูกระงับการใช้งาน โปรดติดต่อผู้ดูแลระบบ",
          en: "๊This user is suspended, please contact admin",
        },
      },
      "40000": {
        resultCode: "40000",
        bootstrapClass: "danger",
        resultTitle: { th: "ข้อมูลไม่ถูกต้อง !", en: "Bad request !" },
        resultDesc: {
          th: "ข้อมูลไม่ถูกต้อง",
          en: "Bad request",
        },
      },
      "404": {
        resultCode: "404",
        bootstrapClass: "warning",
        resultTitle: { th: "ไม่พบข้อมูล !", en: "Data not found !" },
        resultDesc: {
          th: "ไม่พบข้อมูลที่ต้องการ ลองใหม่อีกครั้ง",
          en: "No data found, please try again.",
        },
      },

      "411": {
        resultCode: "411",
        bootstrapClass: "warning",
        resultTitle: { th: "โปรดลองใหม่อีกครั้ง !", en: "Please try again !" },
        resultDesc: {
          th: "คุณกรอกรหัสผ่านเดิมไม่ถูกต้อง",
          en: "The current password was incorrect.",
        },
      },

      "412": {
        resultCode: "412",
        bootstrapClass: "warning",
        resultTitle: { th: "โปรดลองใหม่อีกครั้ง !", en: "Please try again !" },
        resultDesc: {
          th: "คุณกรอกรหัสผ่านใหม่ไม่ตรงกัน",
          en: "You enter a new password does not match.",
        },
      },

      "413": {
        resultCode: "413",
        bootstrapClass: "warning",
        resultTitle: { th: "คำเตือน !", en: "Warning !" },
        resultDesc: {
          th:
            "ไม่สามารถเพิ่มผู้ใช้งานได้ เนื่องจากจำนวนผู้ใช้งาน เกินจำนวนที่กำหนด",
          en:
            "ไม่สามารถเพิ่มผู้ใช้งานได้ เนื่องจากจำนวนผู้ใช้งาน เกินจำนวนที่กำหนด",
        },
      },

      "414": {
        resultCode: "414",
        bootstrapClass: "warning",
        resultTitle: { th: "คำเตือน !", en: "Warning !" },
        resultDesc: {
          th: "ระบบตรวจพบว่ามีชื่อผู้ใช้นี้อยู่แล้ว",
          en: "ระบบตรวจพบว่ามีชื่อผู้ใช้นี้อยู่แล้ว",
        },
      },

      "415": {
        resultCode: "415",
        bootstrapClass: "warning",
        resultTitle: { th: "คำเตือน !", en: "Warning !" },
        resultDesc: {
          th:
            "ไม่สามารถเพิ่ม slider ได้ เนื่องจากจำนวน slider เกินจำนวนที่กำหนด",
          en:
            "ไม่สามารถเพิ่ม slider ได้ เนื่องจากจำนวน slider เกินจำนวนที่กำหนด",
        },
      },

      "416": {
        resultCode: "416",
        bootstrapClass: "warning",
        resultTitle: { th: "คำเตือน !", en: "Warning !" },
        resultDesc: {
          th: "URL Access ไม่สามารถซ้ำกับที่มีอยู่ในระบบได้",
          en: "URL Access can not duplicate the existing.",
        },
      },

      "4161": {
        resultCode: "4161",
        bootstrapClass: "warning",
        resultTitle: { th: "เกิดข้อผิดพลาด !", en: "Warning !" },
        resultDesc: {
          th: "ไม่พบ blade โปรดตรวจสอบใหม่อีกครั้ง",
          en: "Blade not found. Please try again.",
        },
      },

      "417": {
        resultCode: "417",
        bootstrapClass: "warning",
        resultTitle: { th: "โปรดลองใหม่อีกครั้ง !", en: "Please try again !" },
        resultDesc: {
          th: "ไม่สามารถเปลี่ยนรหัสผ่านเป็นรหัสผ่านเดียวกับปัจจุบันได้",
          en:
            "Cannot change the password to the same password as the current password.",
        },
      },

      "50000": {
        resultCode: "50000",
        bootstrapClass: "danger",
        resultTitle: { th: "เกิดข้อผิดพลาด !", en: "Something wrong !" },
        resultDesc: {
          th: "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง",
          en: "Please try again.",
        },
      },
      "509": {
        resultCode: "509",
        bootstrapClass: "danger",
        resultTitle: { th: "เกิดข้อผิดพลาด !", en: "Something wrong !" },
        resultDesc: {
          th: "รหัส re-captcha ไม่ถูกต้อง",
          en: "re-captcha error",
        },
      },
      "511": {
        resultCode: "511",
        bootstrapClass: "danger",
        resultTitle: { th: "เกิดข้อผิดพลาด !", en: "Something wrong !" },
        resultDesc: { th: "พบปัญหาในการ Upload file", en: "Upload file fail." },
      },

      "40900": {
        resultCode: "40900",
        bootstrapClass: "warning",
        resultTitle: {
          th: "พบข้อผิดพลาด !",
          en: "Something wrong !",
        },
        resultDesc: {
          th: "อีเมลนี้มีอยู่ในระบบ โปรดระบุอีเมลอื่น",
          en: "Email is already exist, please try other email",
        },
      },
      "40901": {
        resultCode: "40901",
        bootstrapClass: "warning",
        resultTitle: {
          th: "พบข้อผิดพลาด !",
          en: "Something wrong !",
        },
        resultDesc: {
          th: "วันที่ประมาณการสอบเทียบมีอยู่ในระบบแล้ว",
          en: "Calibration date is already exist.",
        },
      },
      "40902": {
        resultCode: "40902",
        bootstrapClass: "warning",
        resultTitle: {
          th: "พบข้อผิดพลาด !",
          en: "Something wrong !",
        },
        resultDesc: {
          th: "หน่วยย่อเทียบนี้บันทึกในระบบแล้ว",
          en: "Short unit is already exist.",
        },
      },
    };

    return data[code];
  }
}
