import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { 
    environment 
} from 'src/environments/environment';

@Injectable({
    providedIn: "root",
})
export class PDF {
  apiUrl: any = '';

  constructor(
    public http: HttpClient
  ) {
    this.apiUrl = environment.apiUrl ? environment.apiUrl : '';
  }

  get(token: string, url: any): Promise<any> {
    const headers = this.setHeader(token);
    return this.http.get<any>(this.apiUrl + url, { headers }).toPromise();
  }
  setHeader(token) {
    let headers = new HttpHeaders();
    headers = headers.set('Authorization', 'Bearer ' + token);
    return headers;
  }
}
