import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-receive-item-edit-all',
  templateUrl: './receive-item-edit-all.component.html'
})
export class ReceiveItemEditAllComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  isReview: boolean = false;

  @Output() done = new EventEmitter<any>();
  @Input() itemStatus: any;

  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  statusList = [];

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.route.queryParams.subscribe(params => {
      this.isReview = params['type'] == 'review'? true: false;
    });
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.setForm();
    this.statusList = changes.itemStatus?.currentValue;

    if(this.isReview){
      this.form.controls.item_status_id.setValue("WAITING_FOR_REVIEW");
    }else{
      this.form.controls.item_status_id.setValue("WAITING_FOR_RECEIVE");
    }
  }

  setForm(){
    this.form = this.formBuilder.group({
      item_status_id: ["", [Validators.required]]
    });
  }


  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.submitted = false;
    let data = {
      ...this.form.getRawValue()
    }
    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.done.emit(null);
      }
    });
  }
}
