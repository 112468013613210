import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { QuotationNoteMasterService } from 'src/app/services';

@Component({
  selector: 'app-note-list',
  templateUrl: './note-list.component.html',
  styleUrls: ['./note-list.component.css']
})
export class NoteListComponent implements OnInit {
  //form
  form: FormGroup;
  standard_in_list: FormArray;
  standard_out_list: FormArray;
  general_list: FormArray;
  //boolean
  submitted:boolean = false;
  pageLoad:boolean =  false;
  //array
  deleteNoteList = [];
  //any
  status: any;
  
  constructor(
    private formBuilder: FormBuilder,
    public QuotationNoteMasterService : QuotationNoteMasterService
  ) { }

  async ngOnInit() {
    this.initdata();
  }

  async initdata(){
    this.pageLoad = false;
    this.deleteNoteList = [];
    
    this.createForm();
    await this.getNoteList();
  }

  createForm(){
    this.form = this.formBuilder.group({
      standard_in_list: this.formBuilder.array([]),
      standard_out_list: this.formBuilder.array([]),
      general_list: this.formBuilder.array([])
    });
    this.standard_in_list = 
    this.form.get('standard_in_list') as FormArray;

    this.standard_out_list = 
    this.form.get('standard_out_list') as FormArray;

    this.general_list = 
    this.form.get('general_list') as FormArray;
  }

  async getNoteList(){
    await this.QuotationNoteMasterService.load(null)
    .then(async (res) => {
      let resultData = res.resultData || [];
      resultData.sort((a, b) => (parseInt(a.order) > parseInt(b.order)? 1 : -1));

      await Promise.all(resultData.map(async (value: any)=>{
        if(value.note_type == 'standard_out'){
          this.standard_out_list.push(this.createNoteItem(value));
        }else if(value.note_type == 'standard_in'){
          this.standard_in_list.push(this.createNoteItem(value));
        }else{
          this.general_list.push(this.createNoteItem(value));
        }
      }));
    });
    this.pageLoad = true;
  }

  get standardOutItemFormGroup() {
    return this.form.get('standard_out_list') as FormArray;
  }

  get standardInItemFormGroup() {
    return this.form.get('standard_in_list') as FormArray;
  }

  get generalItemFormGroup() {
    return this.form.get('general_list') as FormArray;
  }

  createNoteItem(data:any = {}): FormGroup {
    return this.formBuilder.group({
      quotation_note_master_id: this.formBuilder.control(data.quotation_note_master_id || null),
      quotation_note: this.formBuilder.control(data.quotation_note || null, [Validators.required]),
      note_type: this.formBuilder.control(data.note_type),
      order: this.formBuilder.control(data.order || null)
    });
  }

  async save(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    let value = this.form.value;

    await Promise.all(this.deleteNoteList.map(async (value)=>{
      if(value.quotation_note_master_id){
        await this.QuotationNoteMasterService
        .delete({quotation_note_master_id: value.quotation_note_master_id});
      }
    }));

    await Promise.all(value.general_list.map(async (value: any, index: number)=>{
      if(value.quotation_note_master_id){
        let QuotationNoteMasterResponse = await this.QuotationNoteMasterService
        .update({ ...value, order: index, note_type: "general"});
        if(QuotationNoteMasterResponse.success){
          this.status = QuotationNoteMasterResponse.success;
        }else{
          this.status = QuotationNoteMasterResponse.error;
        }
      }else{
        let QuotationNoteMasterResponse = await this.QuotationNoteMasterService
        .create({ ...value,order: index, note_type: "general"});
        if(QuotationNoteMasterResponse.success){
          this.status = QuotationNoteMasterResponse.success;
        }else{
          this.status = QuotationNoteMasterResponse.error;
        }
      }
    }));

    await Promise.all(value.standard_in_list.map(async (value: any, index: number)=>{
      if(value.quotation_note_master_id){
        let QuotationNoteMasterResponse = await this.QuotationNoteMasterService
        .update({ ...value, order: index, note_type: "standard_in"});
        if(QuotationNoteMasterResponse.success){
          this.status = QuotationNoteMasterResponse.success;
        }else{
          this.status = QuotationNoteMasterResponse.error;
        }
      }else{
        let QuotationNoteMasterResponse = await this.QuotationNoteMasterService
        .create({ ...value,order: index, note_type: "standard_in"});
        if(QuotationNoteMasterResponse.success){
          this.status = QuotationNoteMasterResponse.success;
        }else{
          this.status = QuotationNoteMasterResponse.error;
        }
      }
    }));

    await Promise.all(value.standard_out_list.map(async (value: any, index: number)=>{
      if(value.quotation_note_master_id){
        let QuotationNoteMasterResponse = await this.QuotationNoteMasterService
        .update({ ...value, order: index, note_type: "standard_out"});
        if(QuotationNoteMasterResponse.success){
          this.status = QuotationNoteMasterResponse.success;
        }else{
          this.status = QuotationNoteMasterResponse.error;
        }
      }else{
        let QuotationNoteMasterResponse = await this.QuotationNoteMasterService
        .create({ ...value, order: index, note_type: "standard_out"});
        if(QuotationNoteMasterResponse.success){
          this.status = QuotationNoteMasterResponse.success;
        }else{
          this.status = QuotationNoteMasterResponse.error;
        }
      }
    }));

    this.submitted = false;
    this.initdata();
  }

  addNote(type: string){
    if(type === 'standard_out'){
      this.standard_out_list.push(this.createNoteItem({note_type: type}));
    }else if(type === 'standard_in'){
      this.standard_in_list.push(this.createNoteItem({note_type: type}));
    }else{
      this.general_list.push(this.createNoteItem({note_type: type}));
    }
  }

  removeNote(index: number, item, type: string){
    if(type === 'standard_out'){
      this.standard_out_list.removeAt(index);
    }else if(type === 'standard_in'){
      this.standard_in_list.removeAt(index);
    }else{
      this.general_list.removeAt(index);
    }
    this.deleteNoteList.push(item.value);
  }

  async drop(event: CdkDragDrop<string[]>, type: string) {
    if (event.previousContainer === event.container) {
      if(type === 'standard_out'){
        moveItemInArray(this.standard_out_list.controls, event.previousIndex, event.currentIndex);
        moveItemInArray(this.standard_out_list.value, event.previousIndex, event.currentIndex);
      }else if(type === 'standard_in'){
        moveItemInArray(this.standard_in_list.controls, event.previousIndex, event.currentIndex);
        moveItemInArray(this.standard_in_list.value, event.previousIndex, event.currentIndex);
      }else{
        moveItemInArray(this.general_list.controls, event.previousIndex, event.currentIndex);
        moveItemInArray(this.general_list.value, event.previousIndex, event.currentIndex);
      }
    } else {
      if(type === 'standard_out'){
        if(event.previousContainer.id = "cdk-drop-list-2"){
          transferArrayItem(this.general_list.controls,this.standard_out_list.controls,event.previousIndex,event.currentIndex);
          transferArrayItem(this.general_list.value,this.standard_out_list.value,event.previousIndex,event.currentIndex);
        }else{
          transferArrayItem(this.standard_in_list.controls,this.standard_out_list.controls,event.previousIndex,event.currentIndex);
          transferArrayItem(this.standard_in_list.value,this.standard_out_list.value,event.previousIndex,event.currentIndex);
        }
      }else if(type === 'standard_in'){
        if(event.previousContainer.id = "cdk-drop-list-2"){
          transferArrayItem(this.general_list.controls,this.standard_in_list.controls,event.previousIndex,event.currentIndex);
          transferArrayItem(this.general_list.value,this.standard_in_list.value,event.previousIndex,event.currentIndex);
        }else{
          transferArrayItem(this.standard_out_list.controls,this.standard_in_list.controls,event.previousIndex,event.currentIndex);
          transferArrayItem(this.standard_out_list.value,this.standard_in_list.value,event.previousIndex,event.currentIndex);
        }
      }else{
        if(event.previousContainer.id = "cdk-drop-list-0"){
          transferArrayItem(this.standard_in_list.controls,this.general_list.controls,event.previousIndex,event.currentIndex);
          transferArrayItem(this.standard_in_list.value,this.general_list.value,event.previousIndex,event.currentIndex);
        }else{
          transferArrayItem(this.standard_out_list.controls,this.general_list.controls,event.previousIndex,event.currentIndex);
          transferArrayItem(this.standard_out_list.value,this.general_list.value,event.previousIndex,event.currentIndex);
        }
      }
    }
  }

}
