import { Component, OnInit } from "@angular/core";
import { CarInfoService, GETService } from "src/app/services";
import { Router } from "@angular/router";

@Component({
  selector: 'app-car-list',
  templateUrl: './car-list.component.html',
  styleUrls: ['./car-list.component.css']
})
export class CarListComponent implements OnInit {
  isLoading: boolean = true;
  cars: Array<any>;
  car = null;
  status: string;
  carTypes = [];
  reset: boolean = false;
  load: Function = this.CarInfoService.load;
  filter = {};
  constructor(private router: Router, public CarInfoService: CarInfoService, private GETService: GETService) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }
  async ngOnInit() {
    await this.GETService.carTypes({}).then(res=>{
      this.carTypes = res.resultData || [];
    });
    this.cars = [];
  }
  onChangePage(cars: Array<any>) {
    this.cars = cars;
    this.isLoading = false;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  select(car) {
    this.car = Object.assign({}, car);
  }
  search(filter: object) {
    this.filter = filter;
  }
}
