
<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายงานรอวางบิล-รับเช็ค</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/bill-cheque']">การส่งเครื่องมือ</a>
                </li>
                <li class="breadcrumb-item active">รายงานรอวางบิล-รับเช็ค</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-12 col-12 mb-md-0 mb-2">
          <app-response-status *ngIf="status" [status]="status"></app-response-status>
            <section class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <h4 class="card-title mt-1 col-12 col-sm-4 col-md-6 col-lg-9">รายงานรอวางบิล-รับเช็ค</h4>
                        <span class="col-12 col-sm-8 col-md-6 col-lg-3">
                          <search-box (search)="search($event)" [placeholder]="'ค้นหาเลขที่เอกสาร/รหัส/ชื่อลูกค้า'"></search-box>
                        </span>
                      </div>
                    </div>
        
                    <div class="card-content collapse show">
                      <div class="card-body">
                            <div class="row justify-content-between" *ngIf="!isLoading">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-9 align-self-end">
                                    <!-- <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                                        <li class="nav-item border-primary mr-50">
                                            <a class="nav-link py-0" [class.active] = "tab === 'bill'" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchFilter('bill')">วางบิล</a>
                                        </li>
                                        <li class="nav-item border-primary mr-50">
                                            <a class="nav-link py-0" [class.active] = "tab === 'cheque'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchFilter('cheque')">รับเช็ค</a>
                                        </li>
                                
                                    </ul> -->
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                                    <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
                                      <div class="input-group mt-0 mb-1">
                                        <input
                                        date-picker
                                        type="text"
                                        id="appointment_date"
                                        [date]="form.value.appointment_date"
                                        class="form-control"
                                        formControlName="appointment_date"
                                        [max-date]="undefined"
                                        placeholder="วันที่นัดรับเครื่องมือ"
                                        />
                                        <div
                                        class="input-group-append"
                                        for="appointment_date"
                                        >
                                        <span class="input-group-text rounded-0 btn-primary text-white"
                                            ><i class="feather icon-calendar"></i
                                        ></span>
                                        </div>
                                        <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                            ><i class="fa fa-times"></i>
                                        </span>
                                      </div>
                                    </form>
                                </div>
                            </div>
                            <loading *ngIf="isLoading"></loading>
                            <div class="table-responsive" [hidden]="isLoading">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                ประเภท
                                            </th>
                                            <th>
                                                กำหนดส่ง (วันนัดชำระ)
                                            </th>
                                            <th>
                                                สถานที่
                                            </th>
                                            <th class="text-center">
                                                เวลา
                                            </th>
                                            <th>
                                                Customer
                                            </th>
                                            <th>
                                                เลขที่ใบวางบิล
                                            </th>
                                            <th>
                                                Billing Day
                                            </th>
                                            <th>
                                                Note
                                            </th>
                                            <th>
                                                ส่งจริง
                                            </th>
                                            <th>
                                                Messenger
                                            </th>
                                        </tr>
                                    </thead>
                                    <thead *ngFor="let billing of billingList; let i = index">
                                        <tr *ngIf="billing.tab=='bill'">
                                            <td>วางบิล</td>
                                            <td class="text-nowrap">
                                                {{billing.appointment_date | thai_date: "short":"date" | dash}}
                                            </td>
                                            <td class="text-nowrap">
                                                {{billing.appointment_location}}
                                            </td>
                                            <td class="text-center text-nowrap">
                                                <span *ngIf="billing.appointment_time_from">
                                                    {{billing.appointment_time_from?.split(":")[0]}}:{{billing.appointment_time_from.split(":")[1]}} น.
                                                </span>
                                                -
                                                <span *ngIf="billing.appointment_time_to">
                                                    {{billing.appointment_time_to.split(":")[0]}}:{{billing.appointment_time_to.split(":")[1]}} น.
                                                </span>
                                            </td>
                                            <td class="text-nowrap">
                                                [{{billing?.customer_info?.customer_code}}] {{billing?.customer_info?.company_name}}
                                            </td>
                                            <td class="text-nowrap">
                                                {{billing?.doc_no}}
                                            </td>
                                            <td class="text-nowrap">
                                                {{billing?.customer_info?.billing_day | dash}}
                                            </td>
                                            <td>
                                                {{billing?.doc_note}}
                                            </td>
                                            <td>
                                                {{billing.sent_at | thai_date: "short":"date"}}
                                                <div class="primary pointer text-nowrap" *ngIf="!isMessenger"
                                                    (click)="select(billing, 'date')">
                                                    [กำหนดส่งจริง]
                                                </div>
                                            </td>
                                            <td class="text-nowrap">
                                                {{billing?.sent_by_info?.firstname_th}} {{billing?.sent_by_info?.lastname_th}}
                                                <div class="primary pointer text-nowrap" *ngIf="!isMessenger"
                                                    (click)="select(billing, 'passenger')">
                                                    [กำหนดผู้ส่ง]
                                                </div>
                                            </td>
                                
                                        </tr>
                                        
                                        <tr *ngIf="billing.tab=='cheque'">
                                            <td>รับเช็ค</td>
                                            <td class="text-nowrap">
                                                {{billing.appointment_date | thai_date: "short":"date" | dash}}
                                            </td>
                                            <td class="text-nowrap">
                                                {{billing.appointment_location}}
                                            </td>
                                            <td class="text-center text-nowrap">
                                                <span *ngIf="billing.appointment_time_from">
                                                    {{billing.appointment_time_from?.split(":")[0]}}:{{billing.appointment_time_from.split(":")[1]}} น.
                                                </span>
                                                -
                                                <span *ngIf="billing.appointment_time_to">
                                                    {{billing.appointment_time_to.split(":")[0]}}:{{billing.appointment_time_to.split(":")[1]}} น.
                                                </span>
                                            </td>
                                            <td class="text-nowrap">
                                                [{{billing?.acc_billing_note_info?.customer_info?.customer_code}}] {{billing?.acc_billing_note_info?.customer_info?.company_name}}
                                            </td>
                                            <td>
                                                {{billing?.acc_billing_note_info?.doc_no}}
                                            </td>
                                            <td class="text-nowrap">
                                                {{billing?.acc_billing_note_info?.customer_info?.billing_day | dash }}
                                            </td>
                                            <td class="text-nowrap">
                                                {{billing?.note}}
                                            </td>
                                            <td>
                                                {{billing.sent_at | thai_date: "short":"date"}}
                                                <div class="primary pointer text-nowrap" *ngIf="!isMessenger"
                                                    (click)="select(billing, 'date')">
                                                    [กำหนดส่งจริง]
                                                </div>
                                            </td>
                                            <td class="text-nowrap">
                                                {{billing?.sent_by_info?.firstname_th}} {{billing?.sent_by_info?.lastname_th}}
                                                <div class="primary pointer text-nowrap" *ngIf="!isMessenger"
                                                    (click)="select(billing, 'passenger')">
                                                    [กำหนดผู้ส่ง]
                                                </div>
                                            </td>
                                
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngIf="billingList.length ==0">
                                            <td colspan="15" class="text-center">ไม่มีข้อมูล</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
            
                            <!-- <app-paginator
                                [filter]="filter"
                                [reset]="reset"
                                [fetch]="load"
                                (changePage)="onChangePage($event)">
                            </app-paginator> -->
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        </div>
      </div>
    </div>
</div>

<app-bill-update-sent-info *ngIf="type && billing" [type]="type" [transportContactList]="transportContactList" [billing]="billing" (done)="updateSentInfo($event)">
</app-bill-update-sent-info>