import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CustomerAddressService } from 'src/app/services';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccRecivedVoucherInfoService } from 'src/app/services/received-voucher';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import { AccPaymentService } from 'src/app/services/invoice';
import { Location } from "@angular/common";

@Component({
  selector: 'app-received-voucher-show',
  templateUrl: './received-voucher-show.component.html',
  styleUrls: ['./received-voucher-show.component.css']
})
export class ReceivedVoucherShowComponent implements OnInit {
  form: FormGroup;

  submitted = false;
  pageLoad = false;

  acc_received_voucher_info_id: string;

  customerList: any = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];
  count: number = 0;
  //any
  status: any;
  select2Options = null;
  subject: Subject<string> = new Subject();
  options = { multiple: true };
  customer_info = null;
  receivedVoucherItem = null;
  receivedVoucherInfo = null;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private _fbd: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private CustomerAddressService: CustomerAddressService,
    private AccRecivedVoucherInfoService: AccRecivedVoucherInfoService,
    private AccPaymentService: AccPaymentService,
    
    public util : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) {
    this.acc_received_voucher_info_id = 
    this.route.snapshot.paramMap.get("acc_received_voucher_info_id");
  }

  async ngOnInit() {
    this.createForm();

    this.userInfo$.subscribe(user=>{
      if(!user) return;
      this.userInfo = user;
    })

    await this.AccRecivedVoucherInfoService.getById({acc_received_voucher_info_id: this.acc_received_voucher_info_id})
    .then(async res=>{

      let resultData = res.resultData || {};
      this.receivedVoucherInfo = resultData;
      this.customer_info = res.resultData.customer_info;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: this.customer_info? `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''} ${this.customer_info?.branch?" (" + this.customer_info?.branch + ")": ""}`: '',
        customer_credit_term_day: resultData.credit_term_day || 0,
        ...resultData
      }
      this.form.patchValue(data);

      if(resultData.customer_address_id){
        await this.CustomerAddressService.getById({ customer_address_id: resultData.customer_address_id }).then((res)=>{
          if(res.success){
            var resultData = res.resultData;
            var address = this.util.genAddress(resultData);

            this.form.controls['customer_address_id'].setValue(resultData.address_name + (resultData.branch?' ('+ resultData.branch +')': '') + ` แผนก: ${resultData.department}`);
            this.form.controls['customer_address'].setValue(address); 
          }else{
            this.form.controls['customer_address_id'].setValue(null);
            this.form.controls['customer_address'].setValue(null); 
          } 
        })
      }
    })

    this.pageLoad = true;
  }

  createForm(){
    this.form = this._fbd.group({
      customer_id: [""],
      customer_name: [""],
      doc_no: [""],
      doc_date: [""],
      doc_status_id: [""],
      customer_address_id: [""],
      customer_address: [""],
      doc_note: [""],
      grand_total: [0.00]
    });
    this.form.disable();
  }

  async approve(){
    let response = await this.AccRecivedVoucherInfoService.update({
      ...this.receivedVoucherInfo,
      doc_status_id: 'APPROVED'
    });
    if (response.success) {
      this.router.navigateByUrl("/received-voucher", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async reject(){
    let response = await this.AccRecivedVoucherInfoService.update({
      ...this.receivedVoucherInfo,
      doc_status_id: 'REJECTED'
    });
    if (response.success) {
      this.router.navigateByUrl("/received-voucher", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async cancel(){
    let response = await this.AccRecivedVoucherInfoService.update({
      ...this.receivedVoucherInfo,
      doc_status_id: 'CANCELED'
    });
    if (response.success) {
      await Promise.all(this.receivedVoucherItem.receivedVoucherItems.map(async (value)=>{
        if(value.acc_received_voucher_item_id){
          let  paymentResponse = await this.AccPaymentService
          .update({
            ...value.data,
            acc_received_voucher_info_id: "",
          });
        }
      }));

      await this.router.navigateByUrl("/received-voucher", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  getReceivedVoucherItems(event){
    this.receivedVoucherItem = event;
  }

  back(): void {
    this.location.back();
  }
}
