<div
  modal
  class="modal fade text-left"
  id="delivery-items-history"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreateModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="ItemCreateModal">
          กำหนดส่งจริง
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" autocomplete="off" class="form" [formGroup]="form">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <form-group
                    [control]="form.controls.new_date"
                    [submitted]="submitted">
                    <label for="new_date">
                        วันที่ส่งจริง <span class="danger">*</span>
                    </label>
                    <div class="input-group mt-0">
                        <input
                        date-picker
                        type="text"
                        id="new_date"
                        *ngIf="form.value.new_date"
                        placeholder="วันที่ส่งจริง"
                        [date]="form.value.new_date"
                        [max-date]="undefined"
                        class="form-control"
                        formControlName="new_date"
                        />
                        <div
                        class="input-group-append"
                        for="appointment_date"
                        >
                        <span class="input-group-text"
                            ><i class="feather icon-calendar"></i
                        ></span>
                        </div>
                    </div>
                    </form-group>
                  </div>

                  <div class="col-12">
                    <span class="pb-1">ประวัติการแก้ไข</span>
                    <div class="table-responsive" style="max-height: 600px;">
                      <table class="table table-striped table-bordered">
                          <thead> 
                              <tr>
                                  <th class="text-center">แก้ครั้งที่</th>
                                  <th>วันที่ส่งจริง</th>
                                  <th>ผู้บันทึก</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let his of history; let i = index">
                                  <td class="text-center">
                                      {{i + 1}}
                                  </td>
                                  <td>
                                    {{his.new_date | thai_date: "short":"date" | dash}}
                                  </td>
                                  <td>
                                    {{his.created_by_info.firstname_th}} {{his.created_by_info.lastname_th}}
                                  </td>
                              </tr>
                          </tbody>
      
                          <tbody *ngIf="history.length == 0">
                              <tr>
                                  <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                  </div>
                </div>
              </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
          (click)="close()"
        >
          <i class="fa fa-times icon-left"></i> ปิด
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="submit()"
        >
          <i class="fa fa-save icon-left"></i> บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
