import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";


@Component({
  selector: 'app-quotation-confirm',
  templateUrl: './quotation-confirm.component.html'
})
export class QuotationConfirmComponent implements OnInit {
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") private closeModal: ElementRef;
  @Input() status: any;
  @Input() id = "modalConfirmMsg";
  @Input() confirmMessage: any;

  constructor(private router: Router) {
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModal.nativeElement.click();
      }
    });
  }

  ngOnInit(): void {}

  async onSave() {
    this.save.emit(this.closeModal.nativeElement);
  }

  onClose() {
    this.close.emit(null);
  }
}
