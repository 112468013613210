<loading *ngIf="pageLoad"></loading>
<div class="card mb-0" [hidden]="pageLoad">
    <div class="card-content collapse show">
        <div class="card-body p-0">

            <div class="d-flex justify-content-between mb-1">
                <div>
                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#tax-invoice-item-create"
                    *ngIf="pageType !='show'"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบเสนอราคา
                    </button>
                </div>
            </div>

            <div class="table-responsive" style="max-height: 600px;">
                <table class="table table-striped table-bordered" cdkDropList (cdkDropListDropped)="drop($event)">
                    <thead> 
                        <tr>
                            <th class="px-1" style="width: 2%;" *ngIf="pageType !='show'"></th>
                            <th class="text-center">ลำดับ</th>
                            <th>สินค้าบริการ</th>
                            <th>คำอธิบาย</th>
                            <th class="text-right">ราคา</th>
                            <th class="text-right">ส่วนลด</th>
                            <th class="text-right">จำนวน</th>
                            <th class="text-right">รวม</th>
                            <th *ngIf="pageType !='show'">
                                action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let taxInvoice of taxInvoiceItems; let i = index" cdkDrag>
                            <td class="px-1 py-0 pt-1" cdkDragHandle [hidden]="pageType =='show'">
                                <a style="color: #ccc;"><i class="fa fa-ellipsis-v"></i></a>
                            </td>
                            <div *cdkDragPreview>
                                <div class="card px-5 py-2">
                                  <div>
                                    <b>สินค้าบริการ:</b> {{taxInvoice.product_title}}
                                  </div>
                                  <div>
                                    <b>คำอธิบาย:</b> {{taxInvoice.product_description}}
                                  </div>
                                  <div>
                                    <b>ราคา:</b> {{utils.convertStingToNumber(taxInvoice.price) | number : '1.2-2'}}
                                  </div>
                                </div>
                            </div>
                            <td class="text-center">
                                {{i + 1}}
                            </td>
                            <td>
                                {{taxInvoice.product_title}}
                            </td>
                            <td>
                                <div *ngIf="taxInvoice.item_type=='product'">
                                    {{taxInvoice.product_description | dash}}
                                </div>
                                <div *ngIf="taxInvoice.item_type=='calibration_service'">
                                    <small>
                                      Description: {{taxInvoice.data?.description | dash}}<br/>
                                      Serial no: {{taxInvoice.data?.serial_no | dash}}<br/>
                                      Point: 
                                      <span *ngFor="let detail of taxInvoice.data.quotation_mit_item_detail_info; let dLastIndex = last;">
                                        <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0">
                                            <span *ngFor="let point of detail.quotation_mit_item_detail_points_info; let pLastIndex = last;">
                                              {{point?.cus_point}} <span *ngIf="!pLastIndex">,</span>
                                            </span>
                                        </span>
                                        <span *ngIf="detail.quotation_mit_item_detail_ranges_info.length > 0">
                                          <span *ngFor="let range of detail.quotation_mit_item_detail_ranges_info; let rLastIndex = last;">
                                            {{range?.cus_min}} - {{range?.cus_max}}
                                          </span>
                                        </span>
                                        <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0"  [innerHTML]="detail?.unit_info?.unit_short_name_th"></span>
                                        <span *ngIf="!dLastIndex && (detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0)">, </span>
                                      </span>
                                    </small>
                                </div>
                            </td>
                            <td class="text-right">
                                <ng-container [ngSwitch]="true">
                                    <ng-container *ngSwitchCase="taxInvoice.quotation_item_id == '' && taxInvoice.product_id == ''">
                                        {{taxInvoice.price | number : '1.2-2'}}
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <input type="text" 
                                        placeholder="0.00"
                                        class="form-control text-right" 
                                        [id]="'price_'+i"
                                        [disabled]="pageType =='show'"
                                        (keyup)="onKeyup()"
                                        (click)="$event.target.select()"
                                        oninput="this.value = 
                                        this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        [(ngModel)]="taxInvoice.price">
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td class="text-right">
                                {{taxInvoice.discount | number : '1.2-2'}}
                            </td>
                            <td class="text-right">
                                {{taxInvoice.quantity}}
                            </td>
                            <td class="text-right">
                                <div *ngIf="taxInvoice.item_type=='calibration_service'">
                                    {{calculate.sumMit(taxInvoice) | number : '1.2-2'}}
                                </div>
                                <div *ngIf="taxInvoice.item_type=='product'">

                                    <ng-container [ngSwitch]="true">
                                        <ng-container *ngSwitchCase="taxInvoice.quotation_item_id == '' && taxInvoice.product_id == ''">
                                            -{{taxInvoice.discount | number : '1.2-2'}}
                                        </ng-container>
    
                                        <ng-container *ngSwitchDefault>
                                            {{calculate.sumProduct(taxInvoice) | number : '1.2-2'}}
                                        </ng-container>
                                    </ng-container>
                                </div>
                            </td>
                            <td *ngIf="pageType !='show'">
                                <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#modalConfirmDelMsgTaxInvoiceItem"
                                (click)="select(taxInvoice)">
                                ลบ
                            </button>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="taxInvoiceItems.length == 0">
                        <tr>
                            <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-tax-invoice-item-create *ngIf="customer_info" [customer_info]="customer_info" (done)="submitTaxInvoiceItem($event);">
</app-tax-invoice-item-create>

<app-confirm-delete
  [id]="'modalConfirmDelMsgTaxInvoiceItem'"
  (save)="delete($event)"
></app-confirm-delete>