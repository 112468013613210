import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
  EventEmitter,
  Output,
  Input,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  UploadService,
  CustomerInfoService,
  CustomerActivityService,
  CustomerActivityFileService,
  SalesAssignmentService,
  UtilsService,
  UserService,
} from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { environment } from "src/environments/environment";
import { Store, Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { Observable } from "rxjs";

@Component({
  selector: "app-customer-revoke",
  templateUrl: "./customer-revoke.component.html",
  styleUrls: ["./customer-revoke.component.css"],
})
export class CustomerRevokeComponent implements OnInit {
  environment = environment;
  form: FormGroup;
  submitted = false;
  status = null;
  files = [];
  exclude_user_ids = [];
  quill = null;
  maxDate = moment().toDate();
  fileToBeRemove = null;
  datetimePicker = null;
  @Input() user = null;
  @Input() customer = null;
  @Output() done = new EventEmitter();
  @Output() cancel = new EventEmitter();
  @Input("activity-types") activityTypes: Array<any> = [];
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  @Select(UserInfoState.getUser) userInfo: Observable<any>;

  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private CustomerInfoService: CustomerInfoService,
    private CustomerActivityService: CustomerActivityService,
    private UploadService: UploadService,
    private CustomerActivityFileService: CustomerActivityFileService,
    private SalesAssignmentService: SalesAssignmentService,
    private store: Store,
    private UtilsService: UtilsService,
    private UserService: UserService
  ) {
    this.setForm();
  }
  setForm() {
    if (this.activityTypes) {
      this.activityTypes = this.activityTypes.filter(
        (v) =>
          v.customer_activity_type_id === "CHAT" ||
          v.customer_activity_type_id === "EMAIL"
      );
    }

    this.form = this.formBuilder.group({
      activity_datetime: ["", [Validators.required]],
      customer_activity_type_id: ["", [Validators.required]],
      title: ["", [Validators.required]],
      description: ["", [Validators.required]],
      location_lat: [""],
      location_lng: [""],
      sales_assignment_id: ["", [Validators.required]],
    });
    this.files = [];

    let interval = setInterval(() => {
      if (this.quill) {
        this.quill.setText("");
        clearInterval(interval);
      }
    }, 100);

    let today = moment().set("second", 0);
    let interval2 = setInterval(() => {
      if (this.datetimePicker) {
        this.form.controls.activity_datetime.setValue(today.format());
        this.datetimePicker.data("DateTimePicker").date(today);
        this.datetimePicker.change();

        clearInterval(interval2);
      }
    }, 100);
  }
  ngOnInit(): void {
    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
    this.exclude_user_ids = [userInfo.user_id];
  }

  async submit() {
    this.submitted = true;

    if (this.form.invalid || !this.files.length) {
      return;
    }

    let result = await this.CustomerActivityService.create({
      ...this.form.value,
      customer_id: this.customer.customer_id,
    });

    if (result.success) {
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);

      for (let file of this.files) {
        await this.CustomerActivityFileService.create({
          customer_activities_id: result.resultData.customer_activities_id,
          file_name: file.file_name,
          file_path: file.file_path,
        });
      }
      const emp_position_id = (
        (userInfo["emp_info"] || {}).emp_position_info || {}
      ).emp_position_id;

      // if (this.customer.sales_assignment_id) {
      //   resSalesAssignment = await this.SalesAssignmentService.update({
      //     sales_assignment_id: this.customer.sales_assignment_id,
      //     customer_id: this.customer.customer_id,
      //     user_id: this.user.user_id,
      //   });
      // } else {
      let resSalesAssignment = await this.SalesAssignmentService.create({
        customer_id: this.customer.customer_id,
        user_id: this.form.value.sales_assignment_id
      });
      // }

      let resCustomerInfo = await this.CustomerInfoService.update({
        ...this.customer,
        customer_state_id: "LEAD",
        // customerActivities: {
        //   title: "เข้าถือครองลูกค้า",
        //   customer_activity_type_id: "SALES_ASSIGNMENT",
        // },
      });

      // this.form.value.sales_assignment_id
      let saleInfo = await this.UserService.getById({
        user_id: this.form.value.sales_assignment_id,
      });
      saleInfo = saleInfo.resultData || {};
      let activity_text = await this.UtilsService.customerActivityTitle(
        emp_position_id == "SALES_DEP-03"
          ? "FORCE_SALES_ASSIGNMENT"
          : "SALES_ASSIGNMENT",
        {
          $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
          ...(emp_position_id == "SALES_DEP-03"
            ? {
                $sales_name: `${saleInfo.firstname_th} ${saleInfo.lastname_th}`,
              }
            : {}),
        }
      );

      await this.CustomerActivityService.create({
        customer_id: this.customer.customer_id,
        customer_activity_type_id: "SALES_ASSIGNMENT",
        title: activity_text,
        activity_datetime: new Date(),
      });

      this.done.emit(resCustomerInfo.error || resCustomerInfo.success);
      this.closeModalBtn.nativeElement.click();
    }
  }

  async uploadFile(e) {
    let files = Array.from(e.target.files);
    for (let file of files) {
      let result = await this.UploadService.post(file, "customers");
      if (result.success) {
        this.files.push({
          file_name: result.resultData.originalname,
          file_path: result.resultData.path,
        });
      }
    }
    e.target.value = null;
  }

  setLocation(location) {
    this.form.controls.location_lat.setValue(String(location.lat));
    this.form.controls.location_lng.setValue(String(location.lng));
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        if (!this.submitted) {
          this.cancel.emit();
        }
        this.submitted = false;
        // this.setForm();
      } else {
        this.setForm();
      }
    });
  }

  async removeFile(closeModal) {
    if (this.fileToBeRemove.customer_activities_file_id) {
      let res = await this.CustomerActivityFileService.delete({
        customer_activities_file_id: this.fileToBeRemove
          .customer_activities_file_id,
      });
      if (res.success) {
        this.files = this.files.filter(
          (f) => f.file_path !== this.fileToBeRemove.file_path
        );
      }
    } else {
      this.files = this.files.filter(
        (f) => f.file_path !== this.fileToBeRemove.file_path
      );
    }
    closeModal.click();
  }
  queryEmployee(user_id) {
    this.form.controls.sales_assignment_id.setValue("");
    if (user_id) {
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
      if (
        !userInfo.is_co_sales ||
        (userInfo.is_co_sales && userInfo.user_id !== user_id)
      ) {
        this.form.controls.sales_assignment_id.setValue(user_id);
      }
    }
  }
}
