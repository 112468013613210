import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services';
import { 
  AccRecivedVoucherInfoService 
} from 'src/app/services/received-voucher'
import { UserInfoState } from 'src/app/store/user/user.state';


@Component({
  selector: 'app-received-voucher-list',
  templateUrl: './received-voucher-list.component.html',
  styleUrls: ['./received-voucher-list.component.css']
})
export class ReceivedVoucherListComponent implements OnInit {
  isLoading = true;
  
  receivedVouchers = [];
  receivedVoucher = null;

  number_all_status = 0;
  number_draft_status = 0;
  number_waiting_for_approve_status = 0;
  number_approve_status = 0;
  number_reject_status = 0;
  number_canceled_status = 0;

  status: string;
  reset: boolean = false;
  load: Function = this.AccRecivedVoucherInfoService.load;
  filter = {};
  params: any;
  initialPage: number = 1;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private AccRecivedVoucherInfoService: AccRecivedVoucherInfoService,

    public _UtilsService: UtilsService,
  ) { 
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
    this.route.queryParams
    .subscribe(params => {
      if(params){
        let page = parseInt(params.page);

        this.params = params;
        this.filter = {
          ...params,
          offset: page == 1 || isNaN(page)? 0: ((page-1)* 10),
          page: this._UtilsService.setUndefined()
        };

        this.initialPage = isNaN(page)? 1: page;
      }
    }
  );
  }
  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit(): void {
    this.getCountOfStatus(null);
  }

  getCountOfStatus(filter: object){
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;

      this.AccRecivedVoucherInfoService.load({limit:0}, { ...filter, doc_status_id: 'DRAFT'}).then(res=>{
        this.number_draft_status = res.rowCount;
      });
      this.AccRecivedVoucherInfoService.load({limit:0}, { ...filter, doc_status_id: 'APPROVED'}).then(res=>{
        this.number_approve_status = res.rowCount;
      });
      this.AccRecivedVoucherInfoService.load({limit:0}, { ...filter, doc_status_id: 'WAITING_FOR_APPROVE'}).then(res=>{
        this.number_waiting_for_approve_status = res.rowCount;
      });
      this.AccRecivedVoucherInfoService.load({limit:0}, { ...filter, doc_status_id: 'REJECTED'}).then(res=>{
        this.number_reject_status = res.rowCount;
      });
      this.AccRecivedVoucherInfoService.load({limit:0}, { ...filter, doc_status_id: 'CANCELED'}).then(res=>{
        this.number_canceled_status = res.rowCount;
      });
    });
  }

  onChangePage(receivedVouchers: Array<any>) {
    this.receivedVouchers = receivedVouchers
    this.isLoading = false;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = { ...this.filter, ...filter };

    
    this.setQueryUrl();
  }

  setQueryUrl(){
    let filter = {
      ...this.filter,
      page: this.initialPage,
      offset: this._UtilsService.setUndefined(),
    }
    this.router.navigate([], {
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
    this.params = filter;
  }

  getFilterLimit(filterLimit){

    if(this.initialPage != filterLimit.initialPage && filterLimit.initialPage != 0){
  
      let params = {
        ...this.filter,
        page: filterLimit.initialPage,
        offset: this._UtilsService.setUndefined()
      }

      this.router.navigate([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.params = params;
    }
  }
  searchStatus(status:string = null){
    let condition = {
      doc_status_id: status,
    };
    this.search(condition);
  }
  select(receivedVoucher: string) {
    this.receivedVoucher = Object.assign({}, receivedVoucher);
  }
}
