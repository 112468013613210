import { Component, OnInit, Output, ViewChild, ElementRef, NgZone, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { QuotationAcceptedFileService } from '../../../../../services/quotations';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { UtilsService } from 'src/app/services';

@Component({
  selector: 'app-quotation-accept-edit',
  templateUrl: './quotation-accept-edit.component.html',
  styleUrls: ['./quotation-accept-edit.component.css']
})
export class QuotationAcceptEditComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  @Input("accepted") accepted;
  @Output() onClose = new EventEmitter<any>();
  @Output() onSave = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  @ViewChild('accepted_confirmdoc')
  accepted_confirmdoc: ElementRef;
  files = [];
  directoryName = 'quotations';

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private QuotationAcceptedFileService: QuotationAcceptedFileService,
        
    public UtilsService: UtilsService,
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }
  setForm(){
    this.form = this.formBuilder.group({
      quotation_accepted_id: [""],
      accepted_date: ["", [Validators.required]],
      accepted_po_no: ["", [Validators.required]],
      note: [""],
    });
    if(this.accepted_confirmdoc){
      this.accepted_confirmdoc.nativeElement.innerText = 'เลือกไฟล์.....';
    }
  }
  ngOnInit() {
    this.form.patchValue(this.accepted);
    this.files.push(...this.accepted.quotation_accepted_file_info);
  }
  async submit(){
    this.submitted = true;
    if (this.form.invalid || this.checkFileInvalid) {
      return;
    };
    let data = {
      accept_data: this.form.value,
      files: this.files
    }
    this.submitted = false;
    this.onSave.emit(data);
    this.setForm();
    this.closeModalBtn.nativeElement.click();
  }
  public get checkFileInvalid(): boolean {
    if(this.files.find(file=> this.UtilsService.checkFileSize(file) == false)){
      return true;
    }else{
      return false;
    }
  }
  close(isClose: boolean){
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.onClose.emit(true);
        this.setForm();
      }
    });
  }
  onFileChange(files: FileList) {
    if(files.length == 0){
      return;
    }
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  async removeFile(i: number){
    let file = this.files[i];
    if(file.quotation_accepted_file_id){
      let fileResponse = await this.QuotationAcceptedFileService
      .delete({quotation_accepted_file_id: file.quotation_accepted_file_id});
    }
    this.files.splice(i,  1);
  }
}
