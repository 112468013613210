import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { UserService } from 'src/app/services';
import { GetUserInfo } from 'src/app/store/user/user.action';
import { UserInfoState } from 'src/app/store/user/user.state';

@Component({
  selector: 'app-secure-code',
  templateUrl: './secure-code.component.html',
  styleUrls: ['./secure-code.component.css']
})
export class SecureCodeComponent implements OnInit {
  form: FormGroup;
  status = null;
  submitted = false;

  @Select(UserInfoState.getUser) 
  userInfo$: Observable<any>;
  userSub: Subscription;

  constructor(private formBuilder: FormBuilder,
    private UserService: UserService,
    private store: Store) { 
    this.form = this.formBuilder.group(
      {
        user_id: [""],
        secure_code: [""],
        secure_code_last_update: [""],
        new_secure_code: ["", [Validators.required, Validators.minLength(4)]],
      }
    );
  }

  ngOnInit(): void {
    this.userSub = this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      this.form.patchValue(
        {...userInfo}
      );
    });
  }

  async submit(){
    this.submitted = true;
    this.status = null;
    if (this.form.invalid) {
      return;
    }
    const userResponse = await this.UserService.update({
      ...this.form.value,
      secure_code: this.form.value.new_secure_code,
      secure_code_last_update: new Date()
    })
    if(userResponse.success){
      this.status = userResponse.success;
      this.form.get("new_secure_code").setValue("");
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
      if (userInfo.user_id === userResponse.resultData.user_id) {
        this.store.dispatch(new GetUserInfo());
      }
    }else{
      this.status = userResponse.error;
    }
    this.submitted = false;
  }

  copySecureCode(){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = this.form.value.secure_code;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
