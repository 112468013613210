import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccRecivedVoucherItemService } from 'src/app/services/received-voucher';
import { AccPaymentService, AccInvoiceInfoService } from 'src/app/services/invoice';
import { GETService } from 'src/app/services';

@Component({
  selector: 'app-received-voucher-item',
  templateUrl: './received-voucher-item.component.html',
  styleUrls: ['./received-voucher-item.component.css']
})
export class ReceivedVoucherItemComponent implements OnInit {
  pageLoad = false;
  pageType: string = 'create';
  acc_received_voucher_info_id: string;
  acc_payment_id: string;

  receivedVoucherItems = [];
  paymentList = [];
  delelteReceivedVoucherItems: any = [];

  receivedVoucher = null;
  @Output() done = new EventEmitter<any>();
  @Input('customer_info') customer_info: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private AccRecivedVoucherItemService: AccRecivedVoucherItemService,
    private GETService: GETService,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccPaymentService: AccPaymentService,
  ) { 
    this.acc_received_voucher_info_id = 
    this.route.snapshot.paramMap.get("acc_received_voucher_info_id");

    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
    this.route.queryParams.subscribe(params => {
      this.acc_payment_id = params['acc_payment_id'];
    });
  }

  async ngOnInit() {
    this.pageLoad = false;
    await this.GETService.paymentMethod(null)
    .then(res=>{
      this.paymentList = res.resultData;
    })
    if(this.acc_received_voucher_info_id){
      await this.AccRecivedVoucherItemService.load(null, {acc_received_voucher_info_id: this.acc_received_voucher_info_id})
      .then(async res=>{
        this.receivedVoucherItems = res.resultData || [];
        await Promise.all(this.receivedVoucherItems.map(async (voucher)=>{
          await this.AccPaymentService.getById({acc_payment_id: voucher.acc_document_payment_id})
          .then(res=>{
            voucher['data'] = res?.resultData || {};
          })
        }));
        await this.sentData()
      })
    }else{
      if(this.acc_payment_id){
        await this.AccPaymentService.getById({acc_payment_id: this.acc_payment_id})
        .then(res=>{
          this.receivedVoucherItems.push({
            acc_received_voucher_info_id: "",
            acc_document_payment_id: this.acc_payment_id,
            doc_type: res.resultData.doc_type,
            created_acc_received_voucher_info_id: true,
            doc_no: res.resultData.acc_invoice_info?.doc_no || res.resultData.acc_tax_invoice_info?.doc_no,
            data: res.resultData
          })
        });
        await this.sentData()
      }
    }
  }

  async submitReceivedVoucherItem(event){
    if(event.length > 0){
      event.map(item=>{
        let checkDuplicate = this.receivedVoucherItems.find(value=> value.acc_document_payment_id == item.acc_document_payment_id);
        if(!checkDuplicate){
          this.receivedVoucherItems.push(item);
        }
      });
    }

    this.receivedVoucher = null;
    await this.sentData();
  }

  select(receivedVoucher){
    this.receivedVoucher = receivedVoucher;
  }

  async delete(closeModalEl){
    const index = this.receivedVoucherItems.indexOf(this.receivedVoucher);
    this.delelteReceivedVoucherItems.push(this.receivedVoucher);
    if (index > -1) {
      this.receivedVoucherItems.splice(index, 1);
    }
    await this.sentData();
    closeModalEl.click();
  }

  async sentData(){
    this.done.emit({
      receivedVoucherItems: this.receivedVoucherItems,
      delelteReceivedVoucherItems: this.delelteReceivedVoucherItems
    });
  }

  getPaymentName(payment): string {
    let findPayment = this.paymentList.find(pay=> pay?.payment_method_id == payment?.payment_method_id)
    return findPayment?.payment_method_name_th || "";
  }
}
