import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { 
  CustomerAddressService, 
  CustomerContactService,
  GETService,
  UtilsService
} from 'src/app/services';
import { 
  CalculateFunctionService,
  DownloadFileService,
  NgSelect2Customer,
  QuotationUtilsService 
} from 'src/app/services/quotations';
import { 
  JobOrderContactService,
  JobOrderFileService,
  JobOrderInfoService 
} from 'src/app/services/job-orders';
import { AbsNumberValidator } from 'src/app/validators';
import { CoreEditService } from 'src/app/services/job-orders/edit.service';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-work-order-lab-edit',
  templateUrl: './work-order-lab-edit.component.html',
  styleUrls: ['./work-order-lab-edit.component.css']
})
export class WorkOrderLabEditComponent implements OnInit {
  form: FormGroup;
  extraForm: FormGroup;

  isSale: boolean = false;
  isTranDep: boolean = false;

  submitted: boolean = false;
  invalid: boolean = false;
  pageLoad: boolean = true;
  showMessageLoading: boolean = false;
  resetdate: boolean = true;
  disableSaveBtn: boolean = false;

  files: any = [];
  contacts = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerContactList: any = [];

  reportAddressList = [];
  sendReportAddressList = [];
  chargeReportAddressList = [];
  calibrationAddressList = [];
 //string
  template: string = "";
  next_status: string = "";
  job_order_info_id: string = "";
 //any
  status: any;
  customer_info = null;
  jobOrderInfo = null;
  fileItem = null;
  jobOrderMitItem = null;
  workerItem = null;
  carInfoItem = null;
  maxReturnDate = undefined;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;
  constructor(
    private fbd: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private GETService: GETService,
    private CoreService: CoreEditService,
    private cdRef: ChangeDetectorRef,
    private DownloadFileService: DownloadFileService,
    private JobOrderFileService: JobOrderFileService,
    private JobOrderInfoService: JobOrderInfoService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private JobOrderContactService: JobOrderContactService,
    private CalculateFunctionService: CalculateFunctionService,

    public UtilsService: UtilsService,
    public utils : QuotationUtilsService,
    public NgSelect2Customer: NgSelect2Customer,
  ) { 
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
      this.next_status = params['status'];
    });
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
  }

  async ngOnInit() {
    this.createForm();
    this.userInfo$.subscribe(async (userInfo) => {
      this.userInfo = userInfo;

      await this.setData();

      this.disableForm(userInfo);
      
      this.pageLoad = false;
    });
  }

  disableForm(userInfo){
    if(userInfo){

      if(userInfo.emp_info.emp_department_id.startsWith("TRAN_DEP")){
        this.isTranDep = true;
        Object.keys(this.form.value).map(item=>{
          if(item == 'pickup_date' || item == 'return_date' || item == 'real_return_date'){
            this.form.controls[item].enable();
          }else{
            this.form.controls[item].disable();
          }
        })
      }else{
        if(!['ICS','ECS'].includes(this.template)){
          this.form.get('pickup_date').disable();
        }
        this.form.get('return_date').disable();
        this.form.get('real_return_date').disable();
      }
      if(!userInfo.emp_info.emp_department_id.startsWith("SALES_DEP")){
        this.extraForm.disable();
      }else{
        this.isSale = true;
      }
    }
    if(this.next_status == 'confirm' || this.next_status == 'complete' || this.next_status == 'cert' || userInfo.emp_info.emp_department_id.startsWith("CALIB_DEP")){


      if(
        ['ICS','ECS'].includes(this.template) &&
       ( userInfo.emp_info.emp_department_id.startsWith("CALIB_DEP") && 
        this.jobOrderInfo.doc_status_id == 'CONFIRMED' && 
        this.next_status != 'complete') ||
        (userInfo.emp_info.emp_department_id.startsWith("PUCHASE_DEP") && 
        this.jobOrderInfo.doc_status_id == 'WAITING_FOR_CONFIRM' &&
        this.next_status == 'confirm')
        
        ){

        Object.keys(this.form.value).map(item=>{
          if(item == 'service_time' || item == 'pickup_date'){
            this.form.controls[item].enable();
          }else{
            this.form.controls[item].disable();
          }
        })

        Object.keys(this.extraForm.value).map(item=>{
          if(item == 'special_condition' || item == 'note'){
            this.extraForm.controls[item].enable();
          }else{
            this.extraForm.controls[item].disable();
          }
        })
        
      }else if(this.next_status == 'confirm'){

        Object.keys(this.extraForm.value).map(item=>{
          if(item == 'special_condition' || item == 'note'){
            this.extraForm.controls[item].enable();
          }else{
            this.extraForm.controls[item].disable();
          }
        })
        if(['ICS'].includes(this.template)){
          Object.keys(this.form.value).map(item=>{
            if(item == 'service_time' || item == 'pickup_date'){
              this.form.controls[item].enable();
            }else{
              this.form.controls[item].disable();
            }
          })
        }else{
          this.form.disable();
        }
      }else{
        this.form.disable();
      }
      if(this.next_status == 'complete'){
        document.title = "บันทึกผล - MIT CRM";
      }
    }
  }

  async setData(){
    await this.JobOrderInfoService.getById({job_order_info_id: this.job_order_info_id})
    .then(async res=>{
      let resultData = res.resultData || {};
      this.jobOrderInfo = res.resultData || {};
      
      this.customer_info = res.resultData.customer_info;

      let data = {
        ...resultData,
        credit_term_day: resultData?.customer_info?.credit_term_day || 0,
        customer_name: `[${resultData?.customer_info?.customer_code || ''}] ${resultData?.customer_info?.company_name || ''} ${resultData.customer_info?.branch?" (" + resultData.customer_info?.branch + ")": ""}`,
        service_time: resultData?.service_time? resultData?.service_time?.split(":")[0]+ ':'+ resultData?.service_time?.split(":")[1]: "00:00"
      }
      this.form.patchValue(data);
      this.extraForm.patchValue(data);
   
      this.form.controls['doc_date'].setValue(moment(resultData.doc_date).format('YYYY-MM-DD'));
      this.form.controls['pickup_date'].setValue(moment(resultData.pickup_date).format('YYYY-MM-DD'));
      this.form.controls['return_date'].setValue(moment(resultData.return_date).format('YYYY-MM-DD'));
      if(this.template == 'ECL'){
        this.maxReturnDate = moment(new Date(resultData.pickup_date)).add(15,'d').format('YYYY-MM-DD');
      }else{
        this.maxReturnDate = moment(new Date(resultData.pickup_date)).add(10,'d').format('YYYY-MM-DD');
      }
      if(resultData.real_return_date){
        this.form.controls['real_return_date'].setValue(moment(resultData.real_return_date).format('YYYY-MM-DD'));
      }

      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: resultData.customer_id})
      .then(async (res) => {
        let resultData = res.resultData || [];

        this.chargeReportAddressList = resultData.filter(item=> item.customer_address_type_id === 3).map(elem => (
          {
            id: elem.customer_address_id,
            text: elem.address_name +' '+ this.utils.genAddress(elem),
            data: elem
          } 
        ));

        this.reportAddressList =  resultData.filter(item=> item.customer_address_type_id === 6).map(elem => (
          {
            id: elem.customer_address_id,
            text: elem.address_name +' '+ this.utils.genAddress(elem),
            data: elem
          } 
        ));

        this.sendReportAddressList =  resultData.filter(item=> item.customer_address_type_id === 7).map(elem => (
          {
            id: elem.customer_address_id,
            text: elem.address_name +' '+ this.utils.genAddress(elem),
            data: elem
          } 
        ));
       
        this.calibrationAddressList = resultData.filter(item=> item.customer_address_type_id === 5).map(elem => (
          {
            id: elem.customer_address_id,
            text: elem.address_name +' '+ this.utils.genAddress(elem),
            data: elem
          } 
        ));
      });
      
      if(resultData.customer_id){
        await this.setCustomerAddress(resultData.customer_id);
        await this.setCustomerContact(resultData.customer_id);
      }

      await this.JobOrderContactService.load(null, { job_order_info_id: this.job_order_info_id }).then((res) => {
        this.contacts = res.resultData || [];
        let ids = res.resultData.map(v=>{
          return v.customer_contact_id;
        })
        this.form.controls['customer_contact_ids'].setValue(ids);
      });
      await this.getFileList();
      this.reset();
    })
    setTimeout(() => {
      this.setDateTimePicker();
    }, 1000);
  }

  reset(){
    this.resetdate = false;
    setTimeout(() => {
      this.resetdate = true;
    }, 100);
  }

  async getFileList(){
    this.JobOrderFileService.load(null, { job_order_info_id: this.job_order_info_id  }).then((res)=>{
      this.files = res.resultData || [];
    })
  }
  
  setDateTimePicker(){
    let self = this;
    $('#pickup_date').on('dp.change', function(e){ 
      self.resetdate = false;
      setTimeout(() => {
        self.resetdate = true;
      }, 50);

      if(self.template == 'ECL'){
        $('#return_date').data("DateTimePicker").date(moment(new Date(e.date)).add(15, 'd'));

        self.form.get('return_date').setValue(moment(new Date(e.date)).add(15, 'd'));
        self.maxReturnDate = moment(new Date(e.date)).add(15,'d');
      }else{
        $('#return_date')?.data("DateTimePicker")?.date(moment(new Date(e.date)).add(10, 'd'));

        self.form.get('return_date').setValue(moment(new Date(e.date)).add(10, 'd'));
        self.maxReturnDate = moment(new Date(e.date)).add(10,'d');
      }
    });
  }

  createForm(){
    this.form = this.fbd.group({
      job_order_info_id:  [""],
      customer_id: ["", [Validators.required]],
      customer_name: [""],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      pickup_date: ["", [Validators.required]],
      service_time: ["00:00"],
      return_date: ["", [Validators.required]],
      real_return_date: [""],
      doc_status_id: ["", [Validators.required]],
      report_address_id: ["", [Validators.required]],
      send_report_address_id: ["", [Validators.required]],
      charge_address_id: ["", [Validators.required]],

      doc_no:  ["", [Validators.required]],
      doc_date:  ["", [Validators.required]],
      job_order_type: [""],
      location: [""],
      calibration_address_id: ["", this.template == 'ICS' || this.template == 'ECS'? [Validators.required]: null],
      no_of_day: ["", this.template == 'ICS'? [Validators.required, AbsNumberValidator()]: null],
      no_of_people: ["", this.template == 'ICS'? [Validators.required, AbsNumberValidator()]: null],

      customer_contact_ids: [[], [Validators.required]],
      credit_term_day: [""],
      sales_user_id: [""],
    });

    this.extraForm = this.fbd.group({
      no_of_cert: ["", [AbsNumberValidator()]],
      no_of_envelope: ["", [AbsNumberValidator()]],
      special_condition: [""],
      note: [""],
      accessory: [""],
    });
  }

  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData || []
      if(customer.length > 0){
        if(customer.length > 0){
          address = this.utils.genCustomerAddressList(customer[0]);
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
       this.tmpCustomerAddressList = res.resultData || [];
       this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem=>{
        return this.utils.genCustomerAddressList(elem);
      })
      });
      let customerAddress = this.tmpCustomerAddressList;
      this.customerAddressList = [
        ...[address],
        ...customerAddress
      ];

      if(this.customerAddressList.length > 0){
        const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        if(checkAddress.length == 0) {
          if(address){
            this.form.get('customer_address_id').setValue(address.id);
            this.selectAddressName(address.id);
          }else{
            this.form.controls['customer_address_id'].setValue("");
          }
        }
      }else{
        this.customerAddressList = [
          ...[address],
        ];
        if(address){
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }

  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address?.address_name){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.controls['customer_address'].setValue("");
      this.form.controls['customer_address_id'].setValue("");
    }
  }
  
  public get canSaveConfirm(): boolean{
    let result = this.jobOrderMitItem?.jobOrderItems
    .filter(item=> item.item_status_id != 'CRF-CONFIRMED' && item.item_status_id != 'CRF-REJECTED') || [];
    if(result.length == 0){
      return true;
    }else{
      return false;
    }
  }

  public get canSaveCompleted(): boolean{
    let result = this.jobOrderMitItem?.jobOrderItems
    .filter(item=> item.item_status_id != 'CRF-COMPLETED' && item.item_status_id != 'CRF-CANCELED') || [];
    if(result.length == 0){
      return true;
    }else{
      return false;
    }
  }

  public get canSaveWorkEngineer(): boolean{
    if(this.workerItem?.form?.value?.workers.length == 0 || this.workerItem == undefined){
      return false;
    }else{
      return true;
    }
  }

  public get checkFileInvalid(): boolean {
    if(this.files.find(file=> this.UtilsService.checkFileSize(file) == false)){
      return true;
    }else{
      return false;
    }
  }

  saveAsConfirm(is_send_mail = false){
    this.form.get('doc_status_id').setValue("CONFIRMED");
    this.submitted = true;
    this.invalid = false;

    if (this.form.invalid || this.checkFileInvalid ||
      ((this.template =='ICL' || this.template =='ICS') && !this.canSaveWorkEngineer) || 
      ((this.template =='ICL' || this.template =='ICS') && this.workerItem.form.invalid) || 
      ((this.template =='ECL' || this.template =='ECS') && !this.canSaveConfirm) || 
      ((this.template =='ICS') &&  this.carInfoItem.form.invalid)) {
        this.invalid = true;
      return;
    };

    let result = this.jobOrderMitItem?.jobOrderItems.filter(item=> item.item_status_id == 'CRF-REJECTED') || [];

    if(result.length > 0){
      this.form.get('doc_status_id').setValue("REJECTED");
    }

    this.jobOrderMitItem?.jobOrderItems.map(item=> {
      item.quotation_mit_item_info.return_date = this.form.getRawValue().return_date;
    })

    this.submit(is_send_mail);
  }

  saveAsCompleted(is_send_mail = false){
    this.pageLoad = true;
    this.showMessageLoading = true;
    if(['ECL','ECS'].includes(this.template)){
      this.form.get('doc_status_id').setValue("COMPLETED");
    }else{
      if(!this.canSaveCompleted){
        this.form.get('doc_status_id').setValue("CONFIRMED");
      }else{
        this.form.get('doc_status_id').setValue("WORK_DONE");
      }
    }
    this.submitted = true;
    this.invalid = false;

    if (this.form.invalid || this.extraForm.invalid || this.checkFileInvalid ||
      ((this.template =='ICL' || this.template =='ICS') && !this.canSaveWorkEngineer) || 
      ((this.template =='ICL' || this.template =='ICS') && this.workerItem.form.invalid) || 
      ((this.template =='ICS') &&  this.carInfoItem.form.invalid)) {
        this.invalid = true;
      return;
    };
    // let result = this.jobOrderMitItem?.jobOrderItems.filter(item=> item.item_status_id != 'CRF-CANCELED');
    // if(result.length == 0){
    //   this.form.get('doc_status_id').setValue("CANCELED");
    // }
    this.submit(is_send_mail);
  }

  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;
    this.invalid = false;

    if (this.form.invalid || this.checkFileInvalid || this.jobOrderMitItem?.jobOrderItems?.length == 0) {
      this.invalid = true;
      return;
    };
    this.submit();
  }

  save(){ 
    this.form.get('doc_status_id').setValue("WAITING_FOR_CONFIRM");
    this.submitted = true;
    this.invalid = false;

    if (this.form.invalid || this.checkFileInvalid || this.jobOrderMitItem?.jobOrderItems?.length == 0) {
      this.invalid = true;
      return;
    };
    this.jobOrderMitItem?.jobOrderItems.map(item=> {
      if(item.item_status_id == 'CRF-REJECTED'){
        item.item_status_id = 'CRF-WAITING_FOR_CONFIRM';
      }
    })
    this.submit();
  }

  saveAsEdit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    let result = this.jobOrderMitItem?.jobOrderItems.filter(item=> item.item_status_id != 'CRF-CANCELED');
    if(result.length == 0 || this.checkFileInvalid){
      this.form.get('doc_status_id').setValue("CANCELED");
    }
    this.submit();
  }

  saveAsCert(is_send_mail = false){

    let checkRejected = this.jobOrderMitItem.jobOrderItems.length == this.jobOrderMitItem.jobOrderItems.filter(item => item.item_status_id == 'CRF-REJECTED').length;

    if(checkRejected){
      this.form.get('doc_status_id').setValue("CANCELED");

      this.jobOrderMitItem?.jobOrderItems.map(item=> {
        item.item_status_id = 'CRF-CANCELED';
      })
    }else{
      let notEmpty = this.jobOrderMitItem.jobOrderItems.find(item => item.item_status_id != 'CRF-REJECTED' && item.item_status_id != 'CRF-CANCELED');
      if(notEmpty){
        this.form.get('doc_status_id').setValue("PREPARE_FOR_SHIP");

        this.jobOrderMitItem?.jobOrderItems.map(item=> {
          if(item.item_status_id != 'CRF-REJECTED' && item.item_status_id != 'CRF-CANCELED'){
            item.item_status_id = 'PREPARE_FOR_SHIP';
          }
        })
      }else{
        this.form.get('doc_status_id').setValue("CONFIRMED");
      }

      this.jobOrderMitItem?.jobOrderItems.map(item=> {
        if(parseFloat(this.utils.convertStingToNumber(item.quotation_mit_item_info.cost_of_sales)) > this.CalculateFunctionService.sumMit(item.quotation_mit_item_info)){
          item.lock_invoice_at = new Date();
        }else{
          item.lock_invoice_at = undefined;
        }
      });
    }

    this.submitted = true;
    this.invalid = false;

    if (
      this.form.invalid || 
      this.extraForm.invalid || 
      this.checkFileInvalid ||
      (this.template =='ECS' && !this.canSaveWorkEngineer) || 
      (this.template =='ECS' && this.workerItem.form.invalid)
      ) {
      this.invalid = true;
      return;
    };

    this.submit(is_send_mail);
  }
  
  async submit(is_send_mail = false){
    let data = {
      ...this.form.getRawValue(),
      ...this.extraForm.getRawValue(),
      is_send_mail: is_send_mail,
    };
    if(data.doc_status_id == "CONFIRMED"){
      data['confirmed_by'] = this.userInfo.user_id
    }

    this.disableSaveBtn = true;

    if(data.customer_contact_ids.length > 0){
      data.customer_contact_ids = data.customer_contact_ids.map(v=>{
        let select = this.contacts.find(item=> item.customer_contact_id == v);
        if(select){
          return {...select};
        }else{
          return {customer_contact_id: v};
        }
      })
    }

    const deleteContactList =  this.contacts.map(v=>{
      let select = data.customer_contact_ids.find(item=> item.customer_contact_id == v.customer_contact_id);
      if(!select){
        return {...v};
      }
    }).filter(r => r != undefined);
    
    console.log(this.jobOrderMitItem);

    let jobOrderResponse = await this.CoreService.editJobOrder(
      data, 
      this.files, 
      deleteContactList, 
      null, 
      this.jobOrderMitItem, 
      null,
      null, 
      this.workerItem?.form?.value?.workers || [], 
      this.workerItem?.deleteWorkerList,
      this.carInfoItem?.form?.value?.carInfos || [], 
      this.carInfoItem?.deleteCarInfoList,);
    if(jobOrderResponse.status){
      this.pageLoad = false;
      this.showMessageLoading = false;
      await this.router.navigateByUrl("/work-order", {
        state: {
          status: jobOrderResponse.message,
        },
      })
    }else{
      this.pageLoad = false;
      this.showMessageLoading = false;
      if(jobOrderResponse.message == 'codeDuplicate'){
        this.status = jobOrderResponse.error;
      }else{
        this.status = jobOrderResponse.message;
      }
    }
    this.disableSaveBtn = false;
  }

  async getJobOrderItems(event){
    this.jobOrderMitItem = event;
  }

  async getWorkers(event){
    this.workerItem = event;
  }

  async getCarInfos(event){
    this.carInfoItem = event;
  }

  uploadFile(){
    document.getElementById("importFile").click();
  }
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  downloadFile(file){
    if(file.job_order_file_id){
      this.DownloadFileService.downloadFileURL(file);
    }else{
      this.DownloadFileService.downloadFile(file);
    }
  }
  removeFile(file, index){
    this.fileItem = file;
  }
  async deleteFile(closeModalEl){
    let fileItem = this.fileItem;
    if(fileItem.job_order_file_id){
      let response = await this.JobOrderFileService.delete({
        job_order_file_id: fileItem.job_order_file_id,
      });
      this.getFileList();
    }else{
      this.files.map((item, index)=>{
        if(fileItem == item){
          this.files.splice(index, 1);
        }
      })
    }
    closeModalEl.click();
  }

  openPdf(jobOrder){
    let url = null;
    if(['ICL','ICS'].includes(jobOrder.job_order_type)){
      url = this.router.createUrlTree(['/job-order-document/icl-ics/'+ jobOrder.job_order_info_id])
    }else if(['ECL','ECS'].includes(jobOrder.job_order_type)){
      url = this.router.createUrlTree(['/job-order-document/ecl-ecs/'+ jobOrder.job_order_info_id])
    }else if(jobOrder.job_order_type == "PRD"){
      url = this.router.createUrlTree(['/job-order-document/purchase/'+ jobOrder.job_order_info_id])
    }

    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  openServiceReportPdf(jobOrder){
    let url = null;
    url = this.router.createUrlTree(['/job-order-document/service-report/'+ jobOrder.job_order_info_id])
    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  
  openOnsitePdf(jobOrder){
    let url = null;
    url = this.router.createUrlTree(['/job-order-document/onsite/'+ jobOrder.job_order_info_id])
    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  openPdfPurchase(jobOrder){
    let url = this.router.createUrlTree(['/job-order-document/purchase/'+ jobOrder.job_order_info_id]);
    window.open(url.toString(), '_blank');
  }

  openAddressCert(jobOrder){
    let url = this.router.createUrlTree(['/job-order-document/address-cert/'+ jobOrder.job_order_info_id]);
    window.open(url.toString(), '_blank');
  }

  async exportMasterReport(jobOrder){
    let name = "";
    if(this.genTransportDocNo()){
      name = jobOrder.doc_no + '_' + this.genTransportDocNo() + '_'+ jobOrder.customer_info.company_name + '.xlsx';
    }else{
      name = jobOrder.doc_no + '_' + jobOrder.customer_info.company_name + '.xlsx';
    }
    this.GETService.masterReport({job_order_info_id: jobOrder.job_order_info_id}).then(res=>{
      let url = window.URL.createObjectURL(res.data);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  genTransportDocNo(){

    let list = this.jobOrderMitItem?.jobOrderItems;
    if(list.length > 0){
      let item = list.filter((mit, index, self) =>
        index === self.findIndex((t) => (
          t.quotation_mit_item_info.transport_receive_info_doc_no === mit.quotation_mit_item_info.transport_receive_info_doc_no 
        ))
      )
      item = item.filter(f=> f.quotation_mit_item_info.transport_receive_info_doc_no).map(m=>{
        return m.quotation_mit_item_info.transport_receive_info_doc_no;
      })
      return item.join(",");
    }else{
      return "";
    }
  }


  disableBtn(event){
    this.disableSaveBtn = event;
    this.cdRef.detectChanges();
  }
}
