<loading *ngIf="isLoading"></loading>
<div *ngIf="!isLoading">
  <button
    *ngIf="canEditCustomer"
    type="button"
    class="btn btn-outline-primary btn-min-width mb-1"
    trigger-sidebar
    data-target="#customer-contact-create"
  >
    <i class="fa fa-plus-circle icon-left"></i> เพิ่มผู้ติดต่อ
  </button>

  <div class="table-responsive">
    <table class="table table-striped table-bordered">
      <thead>
        <tr>
          <th>#</th>
          <th style="width: 20%;">ชื่อ - นามสกุล</th>
          <th>สัมพันธ์กับที่อยู่</th>
          <th>ตำแหน่ง</th>
          <th>เบอร์โทรศัพท์</th>
          <th>E-mail</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let contact of customer_contacts; let i = index">
          <th>{{ contact.i }}</th>
          <td>
            <div
              class="badge bg-blue float-right"
              *ngIf="contact.isMaster === '1'"
            >
              ผู้ติดต่อหลัก
            </div>
            <p>
              {{ contact.firstname }} {{ contact.lastname }}
              {{ contact.nickname ? "(" + contact.nickname + ")" : "" }}
            </p>

            <p>
              <span
                class="badge badge-primary mr-1 mb-1"
                *ngFor="let tag of contact.memo?.split(',')"
                >{{ tag }}</span
              >
            </p>
          </td>
          <td>
            <div>
              {{ contact.related_customer_address_info?.customer_address_type_info?.customer_address_name | dash }} {{ contact.related_customer_address_info?.branch ? "(" + contact.related_customer_address_info?.branch + ")" : "" }}
            </div>
            <small *ngIf="contact.related_customer_address_info?.is_registed_address != 'true' && contact.related_customer_address_info?.customer_address_type_id !=1">
              แผนก: {{ contact.related_customer_address_info?.department }}
            </small>
            <div>
              <small>
                MIT iPLUS Code: {{ contact.related_customer_address_info?.mit_iplus_code | dash }}
              </small>
            </div>
          </td>
          <td>{{ contact.position | dash }}</td>
          <td>{{ contact.phonenumber }}</td>
          <td>{{ contact.email }}</td>
          <td>
            <button
              type="button"
              class="btn mr-1 mb-1 btn-primary btn-sm"
              (click)="select(contact)"
              trigger-sidebar
              data-target="#customer-contact-edit"
            >
              <i class="fa fa-eye icon-left"></i> ดูข้อมูล
            </button>
            <!--
          <button
            type="button"
            class="btn mr-1 mb-1 btn-warning btn-sm"
            trigger-sidebar
            data-target="#customer-contact-edit"
            (click)="select(contact)"
            *ngIf="canEditCustomer"
          >
            <i class="fa fa-pencil icon-left"></i> แก้ไข
          </button> -->

            <!-- <button
            type="button"
            class="btn mr-1 mb-1 btn-danger btn-sm"
            data-toggle="modal"
            data-target="#modalConfirmDelMsgContact"
            (click)="select(contact)"
            *ngIf="contact.isMaster !== '1' && canEditCustomer"
          >
            <i class="fa fa-trash icon-left"></i> ลบ
          </button> -->

            <button
              type="button"
              class="btn mr-1 mb-1 bg-blue text-white btn-sm d-block"
              (click)="markMaster(contact)"
              *ngIf="contact.isMaster !== '1' && canEditCustomer && !(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                'ACC_DEP'
              )"
            >
              <i class="fa fa-user icon-left"></i> กำหนดให้เป็นผู้ติดต่อหลัก
            </button>
          </td>
        </tr>

        <tr *ngIf="!customer_contacts.length">
          <td colspan="7" class="text-center">ไม่มีข้อมูล</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<app-paginator
  *ngIf="!isLoading"
  [filter]="filter"
  [reset]="reset"
  [fetch]="load"
  (changePage)="onChangePage($event)"
></app-paginator>

<app-customer-contact-create
  [customerAddress]="customerAddress"
  (done)="doReset($event)"
  *ngIf="canEditCustomer"
></app-customer-contact-create>

<app-customer-contact-edit
  [customerAddress]="customerAddress"
  [customer-contact]="customer_contact"
  (done)="doReset($event)"
  [can-edit-customer]="canEditCustomer"
></app-customer-contact-edit>

<app-confirm-delete
  [id]="'modalConfirmDelMsgContact'"
  (save)="delete($event)"
></app-confirm-delete>
