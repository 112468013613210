import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, SimpleChanges, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { UtilsService } from 'src/app/services';
import { DownloadFileService } from 'src/app/services/quotations';

@Component({
  selector: 'app-save-calibration-item',
  templateUrl: './save-calibration-item.component.html',
  styleUrls: ['./save-calibration-item.component.css']
})
export class SaveCalibrationItemComponent implements OnInit {
  public form: FormGroup
  //boolean
  public submitted:boolean = false;
  public reset:boolean = true;
  //array
  public files: any = [];
  public cancelList = [
    {id:'เครื่องจักรกำลังทำงาน ขอนัดสอบเทียบครั้งหน้า / machine is working, calibrate next time', text:'เครื่องจักรกำลังทำงาน ขอนัดสอบเทียบครั้งหน้า / machine is working, calibrate next time'},
    {id:'Standard ที่เตรียมไม่เหมาะสม / unsuitable standard', text:'Standard ที่เตรียมไม่เหมาะสม / unsuitable standard'},
    {id:'ไม่พบเครื่องมือ / instrument is not found', text:'ไม่พบเครื่องมือ / instrument is not found'},
    {id:'Standard ที่เตรียมมาไม่เหมาะสม / unsuitable standard', text:'Standard ที่เตรียมมาไม่เหมาะสม / unsuitable standard'},
    {id:'เครื่องมือชำรุด / ผิดปกติ / instrument is out of order', text:'เครื่องมือชำรุด / ผิดปกติ / instrument is out of order'},
    {id:'สอบเทียบไม่ทัน ขอนัดสอบเทียบครั้งหน้า / performance is not on-time, calibration next time', text:'สอบเทียบไม่ทัน ขอนัดสอบเทียบครั้งหน้า / performance is not on-time, calibration next time'},
    {id:'ยังไม่ครบรอบสอบเทียบ / instrument is not is due date', text:'ยังไม่ครบรอบสอบเทียบ / instrument is not is due date'},
    {id:'ยกเลิกการใช้งาน / instrument is canceled', text:'ยกเลิกการใช้งาน / instrument is canceled'},
  ];
  //string
  public tab:string = 'PASS';
  @Input('jobOrder') public jobOrder: any;
  @ViewChild("closeRejectBtn") public closeRejectBtn: ElementRef;
  @Output() public done = new EventEmitter<any>();

  public fileItem = null;
  constructor(
    private fbd: FormBuilder,
    private DownloadFileService: DownloadFileService,

    public UtilsService: UtilsService,
  ) { }

  ngOnInit() {
    this.createForm();
    if(this.jobOrder.service_date){
      this.tab = this.jobOrder.status || "PASS";
      let data ={
        ...this.jobOrder
      }
      this.form.patchValue(data);
      this.form.get('service_date').patchValue(moment(this.jobOrder.service_date).format(
        "YYYY-MM-DD HH:mm"
      ));
      this.resetdate();
    }else{}

    if(this.jobOrder?.files){
      this.files = [
        ...this.jobOrder?.files
      ];
    }else{
      this.files = [];
    }
  }

  createForm(){
    this.form = this.fbd.group({
      reject_reason: [""],
      status: ["PASS", [Validators.required]],
      service_date: [new Date(), [Validators.required]],
      std: ["", [Validators.required]],
      note: [""],
    });
  }

  setTab(tab){
    this.tab = tab;
    this.form.controls['status'].setValue(tab);
    if(this.tab == 'PASS'){
      this.form.controls['reject_reason'].setValidators(null);
    }else{
      this.form.controls['reject_reason'].setValidators([Validators.required]);
    }
    this.form.controls['reject_reason'].setValue("");
    this.form.controls['reject_reason'].updateValueAndValidity();
  }

  save(){
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }
    let data = {
      ...this.form.value,
      service_date: moment(new Date(this.form.value.service_date)).format()
    }
    
    this.done.emit({
      form: data,
      files: this.files
    });
    this.closeRejectBtn.nativeElement.click();
  }

  close(){
    this.submitted = false;
    this.createForm();
    this.done.emit(null);
    this.resetdate();
  }

  resetdate(){
    this.reset = false;
    setTimeout(() => {
      this.reset = true;
    }, 100);
  }
  
  uploadFile(){
    document.getElementById("importFileWork").click();
  }
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  downloadFile(file){
    if(file.job_order_file_id){
      this.DownloadFileService.downloadFileURL(file);
    }else{
      this.DownloadFileService.downloadFile(file);
    }
  }
  removeFile(file, index){
    this.fileItem = file;
  }
  async deleteFile(closeModalEl){
    this.files.map((item, index)=>{
      if(this.fileItem == item){
        this.files.splice(index, 1);
      }
    })
    closeModalEl.click();
  }
}
