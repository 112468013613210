<div class="card">
    <div class="card-content collapse show">
        <div class="card-body">
            <app-response-status *ngIf="status" [status]="status"></app-response-status>
            
            <div class="row">   
                <div class="col-6">
                    <h4 class="card-title mt-1">รายการชำระเงิน <small class="text-danger" *ngIf="balance > 0">ยอดค้างชำระ {{balance | number : '1.2-2'}} บาท</small></h4>
                </div>
                <div class="col-6 text-right pr-0" *ngIf="!(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                    'SALES_DEP'
                  )">
                    <button
                    type="button"
                    class="btn btn-outline-primary"
                    data-toggle="modal"
                    data-target="#invoice-payment-create"
                    *ngIf="invoice_info?.doc_status_id =='WAITING_FOR_PAYMENT' && balance > 0"
                    >
                    <i class="fa fa-save icon-left"></i> บันทึกการชำระเงิน
                    </button>
                </div>
            </div>
            <br>
            <loading *ngIf="pageLoad"></loading>
            
            <div class="row" *ngIf="!pageLoad">
                <div class="table-responsive" style="max-height: 600px;">
                    <table class="table table-striped table-bordered">
                        <thead> 
                            <tr>
                                <th>ครั้งที่</th>
                                <th>วันที่ชำระ</th>
                                <th>วิธีการชำระ</th>
                                <th class="text-right">จำนวนเงิน (บาท)</th>
                                <th class="text-center">หัก ณ ที่จ่าย (%)</th>
                                <th class="text-center">ภาษีมูลค่าเพื่ม (%)</th>
                                <th class="text-right">ยอดสุทธิ (บาท)</th>
                                <th *ngIf="!(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                                    'SALES_DEP'
                                  )">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let payment of payments; let i = index">
                                <td class="text-center" >
                                    {{i + 1 }}
                                </td>
                                <td>
                                    {{payment.payment_date | thai_date: "short":"date" | dash}}
                                </td>
                                <td>
                                    {{getPaymentName(payment)}}
                                    <br>
                                    <small class="text-muted" *ngIf="payment.bank_name">
                                        ธนาคาร: {{payment.bank_name}}
                                    </small>
                                    <br>
                                    <small class="text-muted" *ngIf="payment.evidence_ref_no">
                                        เลขที่: {{payment.evidence_ref_no}}
                                    </small>
                                </td>
                                <td class="text-right">
                                    {{payment.value_before_wht | number : '1.2-2'}}
                                </td>
                                <td class="text-center">
                                    <div *ngIf="payment.wht_percent != 0">
                                        {{payment.wht_percent}}%
                                        <div *ngIf="payment.wht_received_date">
                                            <div class="text-muted">
                                                ได้รับใบหัก ณ ที่จ่ายแล้ว
                                            </div>
                                        </div>
                                        <div *ngIf="!payment.wht_received_date">
                                            <div class="text-danger">
                                                ยังไม่ได้รับใบหัก ณ ที่จ่าย
                                            </div>
                                            <span class="text-primary pointer" (click)="updateReceivedPayment(payment)" *ngIf="!(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                                                'SALES_DEP'
                                              )">
                                                [คลิกยืนยันรับเอกสาร]
                                            </span>
                                        </div>
                                    </div>
                                    <div *ngIf="payment.wht_percent == 0">
                                        -
                                    </div>
                                </td>
                                <td class="text-center">
                                    {{payment.tax}}%
                                </td>
                                <td class="text-right">
                                    {{payment.grand_total | number : '1.2-2'}}
                                </td>
                                <td *ngIf="!(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                                    'SALES_DEP'
                                  )">
                                    <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm" 
                                    data-toggle="modal"
                                    data-target="#invoice-payment-show"
                                    (click)="select(payment)">
                                      <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                    </a>
                                    <button type="button" class="btn mr-1 mb-1 btn-blue btn-sm"  *ngIf="!payment.created_acc_received_voucher_info_id"
                                    (click)="openReceived(payment)">
                                        <i class="fa fa-file-o"></i> สร้างใบสำคัญรับ
                                    </button>
                                    <button type="button" class="btn mr-1 mb-1 btn-blue btn-sm" *ngIf="!payment.created_acc_tax_invoice_info_id"
                                    (click)="copyInvocie(payment)">
                                        <i class="fa fa-file-o"></i> สร้างใบกำกับภาษี
                                    </button>
                                </td>
                            </tr>
                            <tr *ngIf="payments.length > 0">
                                <td class="text-right" colspan="4">{{sum_value_before_wht| number : '1.2-2'}}</td>
                                <td class="text-right" colspan="3">{{sum_grand_total | number : '1.2-2'}}</td>
                                <td colspan="1" *ngIf="!(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                                    'SALES_DEP'
                                  )"></td>
                            </tr>
                        </tbody>
    
                        <tbody *ngIf="payments.length == 0">
                            <tr>
                                <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>

<app-invoice-payment-create *ngIf="!pageLoad && loadPayment" [paymentList]="paymentList" [payments]="payments" [sum_price]="sum_price" [invoice_info]="invoice_info" (done)="savePayment($event)">
</app-invoice-payment-create>

<app-invoice-payment-show *ngIf="!pageLoad && payment" [paymentList]="paymentList" [payment]="payment" (done)="resetPayment($event)"></app-invoice-payment-show>