<!-- Create Sales Team Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="edit-unit"
  tabindex="-1"
  role="dialog"
  aria-labelledby="editUnitModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="editUnitModal">
          แก้ไขหน่วยนับ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row justify-content-md-center">
          <div class="col-md-12">
            <div class="form-body">
              <form class="form" [formGroup]="form">
                <form-group
                  [control]="form.controls.unit_type_id"
                  [submitted]="submitted"
                >
                  <label for="unit_type_id">
                    ประเภท <span class="danger">*</span>
                  </label>
                  <select
                    class="form-control"
                    id="unit_type_id"
                    formControlName="unit_type_id"
                  >
                    <option value="">โปรดเลือก...</option>
                    <option
                      *ngFor="let unitType of unitTypes"
                      [value]="unitType.unit_type_id"
                      >{{ unitType.unit_type_name_th }}
                    </option>
                  </select>
                </form-group>

                <form-group
                  [control]="form.controls.unit_name_en"
                  [submitted]="submitted"
                >
                  <label for="unit_name_en">
                    ชื่อหน่วยนับ (ภาษาอังกฤษ) <span class="danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="unit_name_en"
                    class="form-control"
                    placeholder=""
                    formControlName="unit_name_en"
                  />
                </form-group>

                <form-group
                  class="mb-3"
                  [control]="form.controls.unit_short_name_quill"
                  [submitted]="submitted"
                >
                  <label for="unit_short_name_en">
                    หน่วยย่อสากล (ภาษาอังกฤษ) <span class="danger">*</span>
                  </label>

                  <div
                    quill
                    [toolbar]="[[{ script: 'sub' }, { script: 'super' }]]"
                    [html]="true"
                    (text-output)="
                      form.controls.unit_short_name_quill.setValue($event)
                    "
                    (change)="form.controls.unit_short_name_en.setValue($event)"
                    (instance)="quill = $event"
                    [text]="form.value.unit_short_name_en"
                  ></div>
                </form-group>
              </form>

              <label>
                หน่วยย่อเทียบ (ภาษาอังกฤษ) <span class="danger">*</span>
              </label>

              <table class="table table-striped table-bordered mt-1">
                <thead>
                  <tr>
                    <th class="text-center">ลำดับ</th>
                    <th>หน่วยย่อเทียบ</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let unit_label of unit_labels; let i = index">
                    <th class="text-center">{{ i + 1 }}</th>

                    <td>
                      <div
                        quill
                        [toolbar]="[[{ script: 'sub' }, { script: 'super' }]]"
                        [html]="true"
                        [text]="unit_label.unit_label"
                        (change)="unit_label.unit_label = $event"
                      ></div>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn round btn-icon btn-danger mr-1 mb-1"
                        data-target="#modalConfirmDelMsgUnitShortNameEdit"
                        data-toggle="modal"
                        (click)="select(i)"
                      >
                        <i class="fa fa-minus fa-2x"></i>
                      </button>
                      <button
                        *ngIf="unit_labels.length === i + 1"
                        type="button"
                        class="btn round btn-icon btn-success mb-1"
                        (click)="addMore()"
                      >
                        <i class="fa fa-plus fa-2x"></i>
                      </button>
                    </td>
                  </tr>
                  <tr *ngIf="!unit_labels.length">
                    <th></th>
                    <td>
                      <div
                        quill
                        [toolbar]="[[{ script: 'sub' }, { script: 'super' }]]"
                        [html]="true"
                        [readonly]="true"
                        (change)="unit_label = $event"
                        (instance)="quillAddMore = $event"
                        (text-output)="setLabel($event)"
                      ></div>
                      <form-group
                        [control]="[
                          addMoreForm.controls.unit_short_name_quill_add_more
                        ]"
                        [submitted]="submitted"
                      ></form-group>
                    </td>
                    <td>
                      <button
                        type="button"
                        class="btn round btn-icon btn-success mb-1"
                        (click)="addMore()"
                      >
                        <i class="fa fa-plus fa-2x"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>

<app-confirm-delete
  [id]="'modalConfirmDelMsgUnitShortNameEdit'"
  (save)="delete($event)"
></app-confirm-delete>
