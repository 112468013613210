import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'my-page-404',
  styles: [],
  templateUrl: './404.component.html'
})

export class Page404Component implements OnInit {

  ngOnInit() {
  }
}