<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการหมายเหตุ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/note']">ตั้งค่าระบบ</a>
                </li>
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/note']">ตั้งค่าหมายเหตุ</a>
                </li>
                <li class="breadcrumb-item active">รายการหมายเหตุ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <a class="btn btn-primary white" (click)="save()">
              <i class="feather icon-save icon-left"></i> บันทึก
            </a>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status
          *ngIf="status"
          [status]="status"
        ></app-response-status>
        
        <section class="row">
          <div class="col-12">
            <loading [hidden]="pageLoad"></loading>
          </div>
          <div class="col-12" [formGroup]="form" [hidden]="!pageLoad">

            <div class="card">
              <div class="card-content collapse show">
                <div class="card-body col-11">
                    <div>
                      <h5 style="font-weight: 500;">Remark 1</h5>
                        <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th style="width: 2%;"></th>
                                <th class="text-center" style="width: 7%;">ลำดับ</th>
                                <th class="w-75">หมายเหตุ <span class="danger">*</span></th>
                                <th class="text-center py-0 align-middle">
                                  <span class="mr-1">Action</span>
                                  <button
                                      *ngIf="standard_in_list.length === 0"
                                      type="button"
                                      class="btn btn-sm btn-icon btn-circle btn-success"
                                      (click)="addNote('standard_in');">
                                      <i class="fa fa-plus fa-2x"></i>
                                  </button>
                                </th>
                              </tr>
                            </thead>
                            <tbody   
                              formArrayName="standard_in_list"  
                              cdkDropList 
                              #standardInList="cdkDropList"
                              [cdkDropListData]="standardInItemFormGroup.controls"
                              [cdkDropListConnectedTo]="[generalList]"
                              (cdkDropListDropped)="drop($event, 'standard_in')">
                                <tr *ngFor="let item of standardInItemFormGroup.controls; let i = index;" [formGroupName]="i" cdkDrag>
                                    <td>
                                        <a style="color: #ccc;" cdkDragHandle><i class="fa fa-ellipsis-v"></i></a>
                                    </td>
                                    <div *cdkDragPreview>
                                        <div class="card px-5 py-2" style="width: 500px;">
                                          <div>
                                            <b>หมายเหตุ:</b> {{item.value.quotation_note || '-'}}
                                          </div>
                                        </div>
                                    </div>
                                    <td class="text-center">
                                        {{i+1}}
                                    </td>
                                    <td class="px-2">
                                      <form-group [control]="item.controls.quotation_note" [submitted]="submitted">
                                        <textarea
                                          class="form-control"
                                          rows="3"
                                          placeholder="หมายเหตุ"
                                          formControlName="quotation_note">
                                        </textarea>
                                      </form-group>
                                    </td>
                                    <td>
                                        <button
                                        type="button"
                                        class="btn round btn-icon btn-danger mr-1 mb-1"
                                        (click)="removeNote(i, item, 'standard_in');"
                                      >
                                        <i class="fa fa-minus fa-2x"></i>
                                      </button>
                                      <button
                                        *ngIf="standard_in_list.length === i + 1"
                                        type="button"
                                        class="btn round btn-icon btn-success mb-1"
                                        (click)="addNote('standard_in');"
                                      >
                                        <i class="fa fa-plus fa-2x"></i>
                                      </button>
                                    </td>
                                </tr>
                                <tr *ngIf="standard_in_list.length === 0">
                                    <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
              </div>
            </div>

            <!-- <div class="card">
              <div class="card-content collapse show">
                <div class="card-body col-11">
                    <div>
                        <h5 style="font-weight: 500;">Remark 2</h5>
                        <div class="table-responsive">
                        <table class="table table-striped table-bordered">
                            <thead>
                              <tr>
                                <th style="width: 2%;"></th>
                                <th class="text-center" style="width: 7%;">ลำดับ</th>
                                <th class="w-75">หมายเหตุ <span class="danger">*</span></th>
                                  <th class="text-center py-0 align-middle">
                                    <span class="mr-1">Action</span>
                                    <button
                                      *ngIf="standard_out_list.length === 0"
                                      type="button"
                                      class="btn btn-sm btn-icon btn-circle btn-success"
                                      (click)="addNote('standard_out');">
                                      <i class="fa fa-plus fa-2x"></i>
                                    </button>
                                  </th>
                              </tr>
                            </thead>
                            <tbody   
                              formArrayName="standard_out_list"  
                              cdkDropList 
                              #standardOutList="cdkDropList"
                              [cdkDropListData]="standardOutItemFormGroup.controls"
                              [cdkDropListConnectedTo]="[generalList, standardInList]"
                              (cdkDropListDropped)="drop($event, 'standard_out')">
                                <tr *ngFor="let item of standardOutItemFormGroup.controls; let i = index;" [formGroupName]="i" cdkDrag>
                                    <td>
                                        <a style="color: #ccc;" cdkDragHandle><i class="fa fa-ellipsis-v"></i></a>
                                    </td>
                                    <div *cdkDragPreview>
                                        <div class="card px-5 py-2" style="width: 500px;">
                                          <div>
                                            <b>หมายเหตุ:</b> {{item.value.quotation_note || '-'}}
                                          </div>
                                        </div>
                                    </div>
                                    <td class="text-center">
                                        {{i+1}}
                                    </td>
                                    <td class="px-2">
                                      <form-group [control]="item.controls.quotation_note" [submitted]="submitted">
                                        <textarea
                                            class="form-control"
                                            rows="2"
                                            placeholder="หมายเหตุ"
                                            formControlName="quotation_note">
                                        </textarea>
                                      </form-group>
                                    </td>
                                    <td>
                                        <button
                                        type="button"
                                        class="btn round btn-icon btn-danger mr-1 mb-1"
                                        (click)="removeNote(i, item, 'standard_out');"
                                      >
                                        <i class="fa fa-minus fa-2x"></i>
                                      </button>
                                      <button
                                        *ngIf="standard_out_list.length === i + 1"
                                        type="button"
                                        class="btn round btn-icon btn-success mb-1"
                                        (click)="addNote('standard_out');"
                                      >
                                        <i class="fa fa-plus fa-2x"></i>
                                      </button>
                                    </td>
                                </tr>
                                <tr *ngIf="standard_out_list.length === 0">
                                    <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                </div>
              </div>
            </div> -->

            <div class="card">
  
                <div class="card-content collapse show">
                  <div class="card-body col-11">
                    <div>
                        <h5 style="font-weight: 500;">Remark 2</h5>
                        <div class="table-responsive">
                        <table class="table table-striped table-bordered" >
                            <thead>
                              <tr>
                                  <th style="width: 2%;"></th>
                                  <th class="text-center" style="width: 7%;">ลำดับ</th>
                                  <th class="w-75">หมายเหตุ <span class="danger">*</span></th>
                                  <th class="text-center py-0 align-middle">
                                    <span class="mr-1">Action</span>
                                    <button
                                      *ngIf="general_list.length === 0"
                                      type="button"
                                      class="btn btn-sm btn-icon btn-circle btn-success"
                                      (click)="addNote('general');">
                                      <i class="fa fa-plus fa-2x"></i>
                                    </button>
                                  </th>
                              </tr>
                            </thead>
                            <tbody  
                              formArrayName="general_list"  
                              cdkDropList 
                              #generalList="cdkDropList"
                              [cdkDropListData]="generalItemFormGroup.controls"
                              [cdkDropListConnectedTo]="[standardInList]"
                              (cdkDropListDropped)="drop($event, 'general')">
                                <tr *ngFor="let item of generalItemFormGroup.controls; let i = index;" [formGroupName]="i" cdkDrag>
                                    <td>
                                        <a style="color: #ccc;" cdkDragHandle><i class="fa fa-ellipsis-v"></i></a>
                                    </td>
                                    <div *cdkDragPreview>
                                        <div class="card px-5 py-2" style="width: 500px;">
                                          <div>
                                            <b>หมายเหตุ:</b> {{item.value.quotation_note || '-'}}
                                          </div>
                                        </div>
                                    </div>
                                    <td class="text-center">
                                        {{i+1}}
                                    </td>
                                    <td class="px-2">
                                      <form-group [control]="item.controls.quotation_note" [submitted]="submitted">
                                        <textarea
                                          class="form-control"
                                          rows="3"
                                          placeholder="หมายเหตุ"
                                          formControlName="quotation_note">
                                        </textarea>
                                      </form-group>
                                    </td>
                                    <td>
                                        <button
                                        type="button"
                                        class="btn round btn-icon btn-danger mr-1 mb-1"
                                        (click)="removeNote(i, item, 'general');"
                                      >
                                        <i class="fa fa-minus fa-2x"></i>
                                      </button>
                                      <button
                                        *ngIf="general_list.length === i + 1"
                                        type="button"
                                        class="btn round btn-icon btn-success mb-1"
                                        (click)="addNote('general');"
                                      >
                                        <i class="fa fa-plus fa-2x"></i>
                                      </button>
                                    </td>
                                </tr>
                                <tr *ngIf="general_list.length === 0">
                                    <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                        </table>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </section>
      </div>
    </div>
  </div>
