import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  NgZone,
  Input,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GETService, CarInfoService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: 'app-car-create',
  templateUrl: './car-create.component.html',
  styleUrls: ['./car-create.component.css']
})
export class CarCreateComponent implements OnInit {
  form: FormGroup;
  submitted = false;

  @Output() done = new EventEmitter();
  @Input("carTypes") carTypes;
  @ViewChild("closeSidebar") closeSidebar: ElementRef;
  constructor(
    private zone: NgZone,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private GETService: GETService,
    private CarInfoService: CarInfoService
  ) { 
    this.setForm();
  }

  async setForm() {
    this.form = this.formBuilder.group({
      car_type_id: ["", [Validators.required]], 
      brand: ["", [Validators.required]], 
      model: ["", [Validators.required]],
      car_plate: ["", [Validators.required]],
    });
  }

  async ngOnInit() {}

  async submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let response = await this.CarInfoService.create({
      ...this.form.getRawValue()
    });
    this.done.emit(response.success || response.error);
    this.closeSidebar.nativeElement.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      } else {
        this.setForm();
      }
    });
  }
}
