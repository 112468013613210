import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobOrderWorkerEngineerService {
  async create({
    job_order_info_id,
    user_id,
    name,
    email,
    order
  }) {
    let result = null;
    try {
      result = await Axios().post(`/job-order-worker-engineer`, {
        job_order_info_id,
        user_id,
        name,
        email,
        order
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      job_order_worker_engineer_id,
      job_order_info_id,
      user_id,
      name,
      email,
      order
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/job-order-worker-engineer/${job_order_worker_engineer_id}`, {
        job_order_info_id,
        user_id,
        name,
        email,
        order
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ job_order_worker_engineer_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/job-order-worker-engineer/${job_order_worker_engineer_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-worker-engineer`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter, 'order_by': 'order:asc' },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ job_order_worker_engineer_id }) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-worker-engineer/${job_order_worker_engineer_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
