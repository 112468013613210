import { Component,Input, OnInit, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { QuotationMitItemLog } from 'src/app/services/quotations';

@Component({
  selector: 'app-mit-item-log',
  templateUrl: './mit-item-log.component.html',
  styleUrls: ['./mit-item-log.component.css']
})
export class MitItemLogComponent implements OnInit {
  public status: string;
  public load: Function = this.QuotationMitItemLog.load;
  public filter = {};
  //array
  public logs = [];
  //boolean
  public pageLoad: boolean = false;
  public reset: boolean = false;
  public isLoading: boolean = true;

  @Input("mitItem") mitItem: any;
  @Output() done = 
  new EventEmitter<any>();
  @ViewChild("closeModalBtn") 
  public closeModalBtn: ElementRef;

  constructor(
    private QuotationMitItemLog: QuotationMitItemLog
  ) {}

  async ngOnInit() {
    if(this.mitItem.quotation_mit_item_id){
      this.filter = { quotation_mit_item_id: this.mitItem.quotation_mit_item_id };
    }else{
      this.filter = { limit: 0, offset:0, quotation_mit_item_id: '00000000-0000-0000-0000-000000000000' };
    }
  }

  onChangePage(logs: Array<any>) {
    this.logs = logs;
    this.isLoading = false;
  }

  close() {
   this.done.emit(null);
  }
}
