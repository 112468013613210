import { Component, OnInit, Output, ViewChild, ElementRef, NgZone, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import moment from 'moment';
import { UtilsService } from 'src/app/services';

@Component({
  selector: 'app-quotation-accept-create',
  templateUrl: './quotation-accept-create.component.html',
  styleUrls: ['./quotation-accept-create.component.css']
})
export class QuotationAcceptCreateComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  pageLoad = false;

  @Output()
  onSave: EventEmitter<any> = new EventEmitter();
  @Output()
  onClose: EventEmitter<boolean> = new EventEmitter();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  @ViewChild('accepted_confirmdoc')
  accepted_confirmdoc: ElementRef;
  files = [];
  directoryName = 'quotations';

  constructor(
    private zone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,
    
    public UtilsService: UtilsService,
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }
  setForm(){
    const date = moment(new Date()).format("YYYY-MM-DD");
    this.form = this.formBuilder.group({
      accepted_date: [null, [Validators.required]],
      accepted_po_no: ["", [Validators.required]],
      note: [""],
    });
    if(this.form.get('accepted_date').value == null){
      this.form.get('accepted_date').setValue(date);
    }
    if(this.accepted_confirmdoc){
      this.accepted_confirmdoc.nativeElement.innerText = 'เลือกไฟล์.....';
    }
  }
  ngOnInit() {
  }
  async submit(){
    this.submitted = true;
    if (this.form.invalid || this.checkFileInvalid) {
      return;
    };
    let data = {
      accept_data: this.form.value,
      files: this.files
    }
    this.submitted = false;
    this.onSave.emit(data);
    this.setForm();
    this.closeModalBtn.nativeElement.click();
  }
  public get checkFileInvalid(): boolean {
    if(this.files.find(file=> this.UtilsService.checkFileSize(file) == false)){
      return true;
    }else{
      return false;
    }
  }
  close(isClose: boolean){
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.onClose.emit(true);
        this.setForm();
      }
    });
  }
  onFileChange(files: FileList) {
    if(files.length == 0){
      return;
    }
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }

  removeFile(i: number){
    this.files.splice(i,  1);
  }
}
