<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการใบกำกับภาษี</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <a [routerLink]="['/tax-invoice']">เอกสารทางบัญชี</a>
                </li>
                <li class="breadcrumb-item">
                    ใบกำกับภาษี
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">

        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mt-1">รายการใบกำกับภาษี</h4>
                <div class="heading-elements">
                  <search-box (search)="search($event)"></search-box>
                </div>
              </div>
  
              <div class="card-content collapse show">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-12 align-self-end">
                          <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "!params?.doc_status_id && !params?.due_date" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchStatus()">ทั้งหมด</a>
                            </li>

                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WAITING_FOR_PAYMENT'" id="pill4" data-toggle="pill" href="#pill4" aria-expanded="false" (click)="searchStatus('WAITING_FOR_PAYMENT')">รอชำระ ({{number_waiting_for_payment}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.due_date=='less_than'" id="pill4" data-toggle="pill" href="#pill9" aria-expanded="false" (click)="searchStatus('less_than')">เกินกำหนดชำระน้อยกว่า 30 วัน ({{number_lessthan_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.due_date=='greater_than'" id="pill4" data-toggle="pill" href="#pill10" aria-expanded="false" (click)="searchStatus('greater_than')">เกินกำหนดชำระมากกว่า 30 วัน ({{number_greaterthan_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='PARTIAL_PAYMENT'" id="pill5" data-toggle="pill" href="#pill5" aria-expanded="false" (click)="searchStatus('PARTIAL_PAYMENT')">ชำระบางส่วน ({{number_partial_payment_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='COMPLETED'" id="pill11" data-toggle="pill" href="#pill11" aria-expanded="false" (click)="searchStatus('COMPLETED')">ชำระแล้ว ({{number_complete_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='TOTAL_AMOUNT_CREDITED'"id="pill55" data-toggle="pill" href="#pill55" aria-expanded="false" (click)="searchStatus('TOTAL_AMOUNT_CREDITED')">ออกใบลดหนี้ทั้งจำนวน ({{number_total_amount_status}})</a>
                            </li>
                            <li class="nav-item border-light">
                              <a class="nav-link text-light py-0" [class.active] = "params?.doc_status_id=='CANCELED'" id="pill22" data-toggle="pill" href="#pill22" aria-expanded="false" (click)="searchStatus('CANCELED')">ยกเลิก ({{number_canceled_status}})</a>
                            </li>
                          </ul>
                        </div>
                    </div>
                    <loading *ngIf="isLoading"></loading>
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                  <th class="text-nowrap">เลขที่ใบกำกับภาษี</th>
                                  <th class="text-center text-nowrap">เลขที่เอกสาร</th>
                                  <th>ชื่อลูกค้า</th>
                                  <th>วันที่ออก</th>
                                  <th>วันนัดวางบิล</th>
                                  <th>วันนัดชำระ</th>
                                  <th class="text-right">มูลค่า (บาท)</th>
                                  <th class="text-center">สถานะ</th>
                                  <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let taxInvoice of taxInvoices; let i = index">
                                <td>{{taxInvoice.doc_no_company}}</td>
                                <td class="text-nowrap">{{taxInvoice.doc_no}}</td>
                                <td>
                                  <div>
                                    [{{taxInvoice?.customer_info?.customer_code}}] {{taxInvoice?.customer_info?.company_name}}
                                  </div>
                                  <small class="text-muted">
                                    พนักงานขาย: {{taxInvoice.sales_assignment_info?.user_info?.firstname_th}} {{taxInvoice.sales_assignment_info?.user_info?.lastname_th || '-'}}
                                  </small>
                                </td>
                                <td>{{taxInvoice.doc_date | thai_date: "short":"date" | dash }}</td>
                                <td>{{taxInvoice.doc_validdate | thai_date: "short":"date" | dash }}</td>
                                <td>{{taxInvoice.doc_duedate | thai_date: "short":"date" | dash }}</td>
                                <td class="text-right">{{taxInvoice.grand_total | number : '1.2-2'}}</td>
                                <td class="text-center">{{taxInvoice.doc_status_info?.document_status_name_th}}</td>
                                <td>
                                  <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm"
                                  [routerLink]="[
                                    '/tax-invoice/' + taxInvoice.acc_tax_invoice_info_id + '/show'
                                  ]">
                                    <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                </a>
                                  
                                  <a 
                                    type="button" class="btn mr-1 mb-1 btn-blue btn-sm"
                                    target="_blank"
                                    [routerLink]="[
                                    '/acc-document/tax_invoice/' + taxInvoice.acc_tax_invoice_info_id
                                    ]">
                                    <i class="fa fa-print icon-left"></i> พิมพ์ A4
                                  </a>

                                  <button 
                                    type="button" class="btn mr-1 mb-1 btn-blue btn-sm text-nowrap" (click)="printTaxInvoiceExcel(taxInvoice)">
                                    <i class="fa fa-print icon-left"></i> พิมพ์กระดาษต่อเนื่อง
                                  </button>
                                </td>
                              </tr>
                                <tr *ngIf="taxInvoices.length == 0">
                                    <td colspan="12" class="text-center">
                                        ไม่มีข้อมูล
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
  
                    <app-paginator
                      *ngIf="userInfo$ | async"
                      [filter]="filter"
                      [reset]="reset"
                      [fetch]="load"
                      [initialPage]="initialPage"
                      (filterLimit)="getFilterLimit($event)"
                      (changePage)="onChangePage($event)">
                    </app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>

<app-invoice-create-filter>
</app-invoice-create-filter>