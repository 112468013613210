import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class EmployeeService {
  async create({ user_id, emp_level_id, emp_position_id, emp_department_id }) {
    let result = null;
    try {
      result = await Axios().post(`/employees`, {
        user_id,
        emp_level_id,
        emp_position_id,
        emp_department_id,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    emp_id,
    user_id,
    emp_level_id,
    emp_position_id,
    emp_department_id,
  }) {
    let result = null;
    try {
      result = await Axios().put(`/employees/${emp_id}`, {
        user_id,
        emp_level_id,
        emp_position_id,
        emp_department_id,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ emp_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/employees/${emp_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/employees`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async manager(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/employees/sales/manager`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ emp_id }) {
    let result = null;
    try {
      result = await Axios().get(`/employees/${emp_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
