import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./pages/login/login.component";
import { DashboardComponent as DashboardLayout } from "./web-layout/dashboard/dashboard.component";
import { AuthGuardService } from "./authGuard.service";

import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import {
  ChangePasswordComponent,
  UnitListComponent,
  UnitTypeListComponent,
  SalesTeamListComponent,
  UserListComponent,
  UserCreateComponent,
  UserEditComponent,
  UnitConversionComponent,
  ScopeListComponent,
  EquipmentListComponent,
  ProductListComponent,
  ProductCreateComponent,
  ProductEditComponent,
  CustomerListComponent,
  CustomerCreateComponent,
  CustomerEditComponent,
  CustomerShowComponent,
  MyCalendarComponent,
  EquipmentCreateComponent,
  EquipmentEditComponent,
  QuotationListComponent,
  QuotationCreateComponent,
  QuotationShowComponent,
  QuotationEditComponent,
  MethodListComponent,
  CpListComponent,
  UserProfileComponent,
  QuotationPdfComponent,
  QuotationPdfPublicComponent,
  NoteListComponent,
  SecureCodeComponent,
  TransportreceiveListComponent,
  TransportreceiveCreateComponent,
  TransportreceiveShowComponent,
  TransportreceiveEditComponent,
  TransportPdfComponent,
  WorkOrderListComponent,
  WorkOrderLabCreateComponent,
  WorkOrderNonLabCreateComponent,
  WorkOrderLabShowComponent,
  WorkOrderNonLabShowComponent,
  WorkOrderLabEditComponent,
  WorkOrderNonLabEditComponent,
  ConfirmationLetterComponent,
  IclIcsComponent,
  EclEcsComponent,
  PrdComponent,
  ServiceReportComponent,
  InvoiceComponent,
  TaxInvoiceComponent,
  BillingNoteComponent,
  ReceivedVoucherComponent,
  InvoiceListComponent,
  InvoiceCreateComponent,
  InvoiceShowComponent,
  InvoiceEditComponent,
  BillingNoteListComponent,
  BillingNoteCreateComponent,
  BillingNoteShowComponent,
  BillingNoteEditComponent,
  ReceivedVoucherListComponent,
  ReceivedVoucherCreateComponent,
  ReceivedVoucherShowComponent,
  ReceivedVoucherEditComponent,
  PaymentListComponent,
  ChequeListComponent,
  TaxInvoiceListComponent,
  TaxInvoiceCreateComponent,
  TaxInvoiceShowComponent,
  TaxInvoiceEditComponent,
  EquipmentComponent,
  CarListComponent,
  BillingListComponent,
  BillingCreateComponent,
  DeliveryListComponent,
  CreditNoteListComponent,
  CreditNoteCreateComponent,
  CreditNoteShowComponent,
  CreditNoteEditComponent,
  DebitNoteListComponent,
  DebitNoteCreateComponent,
  DebitNoteShowComponent,
  DebitNoteEditComponent,
  OnsiteComponent,
  ProductComponent,
  BillChequeListComponent,
  CreditNoteComponent,
  AddressCertComponent,
  ReportListComponent,
  PreBookingListComponent,
  MasterBookingListComponent,
  StandardCreateComponent,
  StandardEditComponent,
  StandardListComponent,
  EmailTemplateAttachmentListComponent,
} from "./pages";
import { LandingComponent } from "./pages/landing/landing.component";
import { PermissionGuard } from "./permission.guard";
import { Page404Component } from "./pages/404/404.component";

const routes: Routes = [
  {
    path: "login",
    component: LoginComponent,
    data: {
      title: "Login",
    },
  },

  {
    path: "document/:quotation_info_id",
    canActivate: [AuthGuardService],
    component: QuotationPdfComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "doc/:quotation_info_id",
    component: QuotationPdfPublicComponent,
    data: {
      title: "QE",
    },
  },
  //start transport
  {
    path: "transport-document/:transport_receive_info_id",
    component: TransportPdfComponent,
    data: {
      title: "QE",
    },
  },
  //end transport
  //start job order
  {
    path: "job-order-document/icl-ics/:job_order_info_id",
    component: IclIcsComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "job-order-document/ecl-ecs/:job_order_info_id",
    component: EclEcsComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "job-order-document/purchase/:job_order_info_id",
    component: PrdComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "job-order-document/service-report/:job_order_info_id",
    component: ServiceReportComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "job-order-document/confirmation-letter/:job_order_info_id",
    component: ConfirmationLetterComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "job-order-document/onsite/:job_order_info_id",
    component: OnsiteComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "job-order-document/product/:job_order_info_id",
    component: ProductComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "job-order-document/address-cert/:job_order_info_id",
    component: AddressCertComponent,
    data: {
      title: "QE",
    },
  },
  //end job order
  {
    path: "acc-document/invoice/:acc_invoice_info_id",
    component: InvoiceComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "acc-document/tax_invoice/:acc_tax_invoice_info_id",
    component: TaxInvoiceComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "acc-document/billing_note/:acc_billing_note_info_id",
    component: BillingNoteComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "acc-document/credit-debit/:id/:type",
    component: CreditNoteComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "acc-document/received_voucher/:acc_received_voucher_info_id",
    component: ReceivedVoucherComponent,
    data: {
      title: "QE",
    },
  },
  {
    path: "pages",
    component: DashboardLayout,
    children: [
      {
        path: "404",
        component: Page404Component,
        data: {
          title: "404",
        },
      },
    ],
  },
  {
    path: "",
    component: DashboardLayout,
    canActivate: [AuthGuardService, PermissionGuard],
    children: [
      {
        path: "landing",
        component: LandingComponent,
        data: {
          title: "ภาพรวมระบบ",
        },
      },
      {
        path: "dashboard",
        component: DashboardComponent,
        data: {
          title: "ภาพรวมระบบ",
        },
      },
      {
        path: "my-calendar",
        component: MyCalendarComponent,
        data: {
          title: "ปฏิทินของฉัน",
        },
      },
      {
        path: "equipment",
        component: EquipmentComponent,
        data: {
          title: "รายการเครื่องมือ",
        },
      },
      {
        path: "quotation",
        component: QuotationListComponent,
        data: {
          title: "ใบเสนอราคา",
        },
      },
      {
        path: "quotation/create",
        component: QuotationCreateComponent,
        data: {
          title: "สร้างใบเสนอราคา",
        },
      },
      {
        path: "quotation/:quotation_info_id/show",
        component: QuotationShowComponent,
        data: {
          title: "ดูใบเสนอราคา",
        },
      },
      {
        path: "quotation/:quotation_info_id/edit",
        component: QuotationEditComponent,
        data: {
          title: "จัดการใบเสนอราคา",
        },
      },
      //start transport
      {
        path: "transport-receive",
        component: TransportreceiveListComponent,
        data: {
          title: "การรับเครื่องมือ",
        },
      },
      {
        path: "transport-receive/:quotation_info_id/create",
        component: TransportreceiveCreateComponent,
        data: {
          title: "สร้างใบรับเครื่องมือ",
        },
      },
      {
        path: "transport-receive/:transport_receive_info_id/show",
        component: TransportreceiveShowComponent,
        data: {
          title: "ดูใบรับเครื่องมือ",
        },
      },
      {
        path: "transport-receive/:transport_receive_info_id/edit",
        component: TransportreceiveEditComponent,
        data: {
          title: "แก้ไขรับเครื่องมือ",
        },
      },
      //end transport
      //start acc
      {
        path: "invoice",
        component: InvoiceListComponent,
        data: {
          title: "รายการใบแจ้งหนี้/ใบส่งของ",
        },
      },
      {
        path: "invoice/:customer_id/create",
        component: InvoiceCreateComponent,
        data: {
          title: "สร้างใบแจ้งหนี้/ใบส่งของ",
        },
      },
      {
        path: "invoice/:acc_invoice_info_id/show",
        component: InvoiceShowComponent,
        data: {
          title: "ดูใบแจ้งหนี้/ใบส่งของ",
        },
      },
      {
        path: "invoice/:acc_invoice_info_id/edit",
        component: InvoiceEditComponent,
        data: {
          title: "แก้ไขใบแจ้งหนี้/ใบส่งของ",
        },
      },
      {
        path: "tax-invoice",
        component: TaxInvoiceListComponent,
        data: {
          title: "รายการใบกำกับภาษี",
        },
      },
      {
        path: "tax-invoice/:customer_id/create",
        component: TaxInvoiceCreateComponent,
        data: {
          title: "สร้างใบกำกับภาษี",
        },
      },
      {
        path: "tax-invoice/:acc_tax_invoice_info_id/show",
        component: TaxInvoiceShowComponent,
        data: {
          title: "ดูใบกำกับภาษี",
        },
      },
      {
        path: "tax-invoice/:acc_tax_invoice_info_id/edit",
        component: TaxInvoiceEditComponent,
        data: {
          title: "แก้ไขใบกำกับภาษี",
        },
      },
      {
        path: "billing-note",
        component: BillingNoteListComponent,
        data: {
          title: "รายการใบวางบิล",
        },
      },
      {
        path: "billing-note/:customer_id/create",
        component: BillingNoteCreateComponent,
        data: {
          title: "สร้างใบวางบิล",
        },
      },
      {
        path: "billing-note/:acc_billing_note_info_id/show",
        component: BillingNoteShowComponent,
        data: {
          title: "ดูใบวางบิล",
        },
      },
      {
        path: "billing-note/:acc_billing_note_info_id/edit",
        component: BillingNoteEditComponent,
        data: {
          title: "แก้ไขใบวางบิล",
        },
      },

      {
        path: "received-voucher",
        component: ReceivedVoucherListComponent,
        data: {
          title: "รายการใบสำคัญรับ",
        },
      },
      {
        path: "received-voucher/:customer_id/create",
        component: ReceivedVoucherCreateComponent,
        data: {
          title: "สร้างใบสำคัญรับ",
        },
      },
      {
        path: "received-voucher/:acc_received_voucher_info_id/show",
        component: ReceivedVoucherShowComponent,
        data: {
          title: "ดูใบสำคัญรับ",
        },
      },
      {
        path: "received-voucher/:acc_received_voucher_info_id/edit",
        component: ReceivedVoucherEditComponent,
        data: {
          title: "แก้ไขใบสำคัญรับ",
        },
      },
      {
        path: "cheque",
        component: ChequeListComponent,
        data: {
          title: "รายการเช็ค",
        },
      },
      {
        path: "payment",
        component: PaymentListComponent,
        data: {
          title: "รายการชำระเงิน",
        },
      },
      //end acc
      {
        path: "work-order",
        component: WorkOrderListComponent,
        data: {
          title: "ใบสั่งงาน",
        },
      },
      {
        path: "work-order/calibration/:customer_id/create",
        component: WorkOrderLabCreateComponent,
        data: {
          title: "สร้างใบสั่งงาน",
        },
      },
      {
        path: "work-order/non-calibration/:customer_id/create",
        component: WorkOrderNonLabCreateComponent,
        data: {
          title: "สร้างใบสั่งงาน",
        },
      },
      {
        path: "work-order/calibration/:job_order_info_id/show",
        component: WorkOrderLabShowComponent,
        data: {
          title: "ดูใบสั่งงาน",
        },
      },
      {
        path: "work-order/non-calibration/:job_order_info_id/show",
        component: WorkOrderNonLabShowComponent,
        data: {
          title: "ดูใบสั่งงาน",
        },
      },
      {
        path: "work-order/calibration/:job_order_info_id/edit",
        component: WorkOrderLabEditComponent,
        data: {
          title: "แก้ไขใบสั่งงาน",
        },
      },
      {
        path: "work-order/non-calibration/:job_order_info_id/edit",
        component: WorkOrderNonLabEditComponent,
        data: {
          title: "แก้ไขใบสั่งงาน",
        },
      },
      {
        path: "users/change-password",
        component: ChangePasswordComponent,
        data: {
          title: "เปลี่ยนรหัสผ่าน",
        },
      },
      {
        path: "users/user-profile",
        component: UserProfileComponent,
        data: {
          title: "ข้อมูลของฉัน",
        },
      },
      {
        path: "users/create",
        component: UserCreateComponent,
        data: {
          title: "จัดการผู้ใช้งาน",
        },
      },
      {
        path: "users/secure-code",
        component: SecureCodeComponent,
        data: {
          title: "Secure Code",
        },
      },
      {
        path: "users/:user_id/show",
        component: UserEditComponent,
        data: {
          title: "ข้อมูลผู้ใช้งาน",
        },
      },
      {
        path: "users",
        component: UserListComponent,
        data: {
          title: "รายการผู้ใช้งานระบบ",
        },
      },
      {
        path: "sales-team",
        component: SalesTeamListComponent,
        data: {
          title: "รายการทีมขาย",
        },
      },

      {
        path: "unit",
        component: UnitListComponent,
        data: {
          title: "รายการหน่วยนับ",
        },
      },
      {
        path: "unit/unit-type/:unit_type_id/unit-conversion",
        component: UnitConversionComponent,
        data: {
          title: "การแปลงหน่วย",
        },
      },
      {
        path: "unit/unit-type",
        component: UnitTypeListComponent,
        data: {
          title: "รายการประเภทหน่วยนับ",
        },
      },
      {
        path: "scope/:mit_scope_id/equipment",
        component: EquipmentListComponent,
        data: {
          title: "รายการ Equipment",
        },
      },
      {
        path: "scope/:mit_scope_id/equipment/create",
        component: EquipmentCreateComponent,
        data: {
          title: "เพิ่ม Equipment",
        },
      },
      {
        path: "scope/:mit_scope_id/equipment/:mit_equipment_id/edit",
        component: EquipmentEditComponent,
        data: {
          title: "แก้ไข Equipment",
        },
      },
      {
        path: "scope",
        component: ScopeListComponent,
        data: {
          title: "รายการ Scope",
        },
      },

      {
        path: "scope/method",
        component: MethodListComponent,
        data: {
          title: "รายการ Method",
        },
      },
      {
        path: "scope/cp",
        component: CpListComponent,
        data: {
          title: "รายการ CP",
        },
      },
      {
        path: "product",
        component: ProductListComponent,
        data: {
          title: "รายการสินค้า",
        },
      },
      {
        path: "product/:product_id/edit",
        component: ProductEditComponent,
        data: {
          title: "แก้ไขสินค้า",
        },
      },
      {
        path: "product/create",
        component: ProductCreateComponent,
        data: {
          title: "เพิ่มสินค้า",
        },
      },

      {
        path: "customer",
        component: CustomerListComponent,
        data: {
          title: "รายการข้อมูลลูกค้า",
        },
      },
      {
        path: "customer/create",
        component: CustomerCreateComponent,
        data: {
          title: "เพิ่มข้อมูลลูกค้า",
        },
      },
      {
        path: "customer/:customer_id/edit",
        component: CustomerEditComponent,
        data: {
          title: "แก้ไขข้อมูลลูกค้า",
        },
      },
      {
        path: "customer/:customer_id/show",
        component: CustomerShowComponent,
        data: {
          title: "ดูข้อมูลลูกค้า",
        },
      },
      {
        path: "note",
        component: NoteListComponent,
        data: {
          title: "รายการหมายเหตุ",
        },
      },
      {
        path: "car-info",
        component: CarListComponent,
        data: {
          title: "รายการรถยนต์",
        },
      },
      {
        path: "standard",
        component: StandardListComponent,
        data: {
          title: "รายการเครื่องมือมาตรฐาน",
        },
      },
      {
        path: "email-template-attachment",
        component: EmailTemplateAttachmentListComponent,
        data: {
          title: "จัดการไฟล์แนบอีเมล",
        },
      },

      {
        path: "waiting-for-charge",
        component: BillingListComponent,
        data: {
          title: "รายการรอเรียกเก็บ",
        },
      },
      {
        path: "waiting-for-charge/:customer_id",
        component: BillingCreateComponent,
        data: {
          title: "รายการรอเรียกเก็บ",
        },
      },
      {
        path: "waiting-for-delivery",
        component: DeliveryListComponent,
        data: {
          title: "รายการรอส่ง",
        },
      },
      {
        path: "credit-note",
        component: CreditNoteListComponent,
        data: {
          title: "รายการใบลดหนี้",
        },
      },
      {
        path: "credit-note/:customer_id/:ref_acc_document_info_id/:ref_doc_type/:credit_note_type/create",
        component: CreditNoteCreateComponent,
        data: {
          title: "สร้างใบลดหนี้",
        },
      },
      {
        path: "credit-note/:acc_credit_note_info_id/show",
        component: CreditNoteShowComponent,
        data: {
          title: "ดูใบลดหนี้",
        },
      },
      {
        path: "credit-note/:acc_credit_note_info_id/edit",
        component: CreditNoteEditComponent,
        data: {
          title: "แก้ไขใบลดหนี้",
        },
      },

      {
        path: "debit-note",
        component: DebitNoteListComponent,
        data: {
          title: "รายการใบเพิ่มหนี้",
        },
      },
      {
        path: "debit-note/:customer_id/:ref_acc_document_info_id/:ref_doc_type/create",
        component: DebitNoteCreateComponent,
        data: {
          title: "สร้างใบเพิ่มหนี้",
        },
      },
      {
        path: "debit-note/:acc_debit_note_info_id/show",
        component: DebitNoteShowComponent,
        data: {
          title: "ดูใบเพิ่มหนี้",
        },
      },
      {
        path: "debit-note/:acc_debit_note_info_id/edit",
        component: DebitNoteEditComponent,
        data: {
          title: "แก้ไขใบเพิ่มหนี้",
        },
      },
      {
        path: "bill-cheque",
        component: BillChequeListComponent,
        data: {
          title: "รายงานรอวางบิล-รับเช็ค",
        },
      },
      {
        path: "report",
        component: ReportListComponent,
        data: {
          title: "รายงาน",
        },
      },
      {
        path: "transport/pre-booking",
        component: PreBookingListComponent,
        data: {
          title: "Pre Booking",
        },
      },
      {
        path: "transport/master-booking",
        component: MasterBookingListComponent,
        data: {
          title: "Master Booking",
        },
      },

      { path: "**", redirectTo: "landing" },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
