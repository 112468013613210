import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  MITScopeService,
  MITMethodService,
  MITCPService,
  UnitService,
  ProductCalibrationFeeService,
  MITEquipmentService,
  ProductCalibrationFeeCPService,
} from "src/app/services";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
@Component({
  selector: "app-equipment-edit",
  templateUrl: "./equipment-edit.component.html",
  styleUrls: ["./equipment-edit.component.css"],
})
export class EquipmentEditComponent implements OnInit {
  isReady = false;
  scopes = [];
  equipments = [];
  methods = [];
  fees = [];
  cps = [];
  units = [];
  mit_scope_info = null;

  removeFees = [];
  feeIndex = -1;
  fee = null;
  submitted = false;
  equipmentForm: FormGroup;
  status = null;
  services = [];
  url =
    "/" +
    this.route.snapshot.url
      .map((segment) => segment.path)
      .slice(0, -2)
      .join("/");

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private MITScopeService: MITScopeService,
    private MITMethodService: MITMethodService,
    private MITCPService: MITCPService,
    private UnitService: UnitService,
    private ProductCalibrationFeeService: ProductCalibrationFeeService,
    private ProductCalibrationFeeCPService: ProductCalibrationFeeCPService,
    private MITEquipmentService: MITEquipmentService
  ) {
    this.setForm();
  }

  setForm() {
    this.equipmentForm = this.formBuilder.group({
      // product detail
      mit_equipment_id: ["", [Validators.required]],
      service_lab: [false],
      service_onsite: [false],
      mit_scope_id: ["", [Validators.required]],
      equipment_name: ["", [Validators.required]],
      // mit_method_id: ["", [Validators.required]],
      // mit_cp_id: ["", [Validators.required]],
      note_1: [""],
      note_2: [""],
      service_location: ["", [Validators.required]],
      calibration_list: [[], [Validators.required]],
      lab_mit_cp_list: ["", [Validators.required]],
      onsite_mit_cp_list: ["", [Validators.required]],
    });

    this.equipmentForm.controls.service_lab.valueChanges.subscribe((isLab) => {
      if (isLab) {
        this.services.push("LAB");
        this.equipmentForm.get("lab_mit_cp_list").setValidators([Validators.required]);
      } else {
        this.services = this.services.filter((v) => v !== "LAB");
        this.equipmentForm.get("lab_mit_cp_list").setValue("");
        this.equipmentForm.get("lab_mit_cp_list").setValidators(null);
      }
      this.equipmentForm.get("lab_mit_cp_list").updateValueAndValidity();
      this.services = Array.from(new Set(this.services));
      this.equipmentForm.controls.service_location.setValue(
        this.services.join(", ")
      );
    });

    this.equipmentForm.controls.service_onsite.valueChanges.subscribe(
      (isOnsite) => {
        if (isOnsite) {
          this.services.push("ONSITE");
          this.equipmentForm.get("onsite_mit_cp_list").setValidators([Validators.required]);
        } else {
          this.services = this.services.filter((v) => v !== "ONSITE");
          this.equipmentForm.get("onsite_mit_cp_list").setValue("");
          this.equipmentForm.get("onsite_mit_cp_list").setValidators(null);
        }
        this.equipmentForm.get("onsite_mit_cp_list").updateValueAndValidity();
        this.services = Array.from(new Set(this.services));
        this.equipmentForm.controls.service_location.setValue(
          this.services.join(", ")
        );
      }
    );
  }
  async ngOnInit() {
    let promises = [];
    promises.push(
      this.MITScopeService.load().then((response) => {
        if (response.success) {
          this.scopes = response.resultData || [];
        }
      })
    );

    promises.push(
      this.MITMethodService.load().then((response) => {
        if (response.success) {
          this.methods = response.resultData || [];
        }
      })
    );

    promises.push(
      this.MITCPService.load().then((response) => {
        if (response.success) {
          this.cps = response.resultData || [];
        }
      })
    );

    promises.push(
      this.UnitService.load().then((response) => {
        if (response.success) {
          this.units = this.groupUnit(response.resultData) || [];
        }
      })
    );

    let mit_scope_id = this.route.snapshot.paramMap.get("mit_scope_id");
    this.equipmentForm.controls.mit_scope_id.setValue(mit_scope_id);
    this.equipmentForm.controls.mit_scope_id.disable();
    await Promise.all(promises);
    promises = [];
    promises.push(
      this.MITEquipmentService.getById({
        mit_equipment_id: this.route.snapshot.paramMap.get("mit_equipment_id"),
      }).then((res) => {
        let mit_equipment = res.resultData || null;

        this.mit_scope_info = mit_equipment.mit_scope_info;

        this.fees = mit_equipment.product_calibration_fee_info.map((v) => {
          // let mit_cps = this.cps
          //   .filter((cp) =>
          //     v.product_calibration_fee_cp_info
          //       .map((cpObj) => cpObj.mit_cp_id)
          //       .includes(cp.mit_cp_id)
          //   )
          //   .map((v) => v.cp_name)
          //   .join(",");

          return {
            ...v,
            mit_cp_ids: v.product_calibration_fee_cp_info.map(
              (cp) => cp.mit_cp_id
            ),
            unit_short_name_en: v.unit_info.unit_short_name_en,
            // mit_cps,
            method_name: v.mit_method_info.method_name,
          };
        });
        this.equipmentForm.controls.calibration_list.setValue(this.fees);

        let services = mit_equipment.service_location.split(", ");
        this.services = services;

        this.equipmentForm.controls.service_lab.setValue(
          services.includes("LAB")
        );
        this.equipmentForm.controls.service_onsite.setValue(
          services.includes("ONSITE")
        );

        this.equipmentForm.controls.mit_equipment_id.setValue(
          mit_equipment.mit_equipment_id
        );
        this.equipmentForm.controls.equipment_name.setValue(
          mit_equipment.equipment_name
        );
        // this.equipmentForm.controls.mit_method_id.setValue(
        //   mit_equipment.mit_method_id
        // );
        // this.equipmentForm.controls.mit_cp_id.setValue(mit_equipment.mit_cp_id);

        this.equipmentForm.controls.note_1.setValue(mit_equipment.note_1);
        this.equipmentForm.controls.note_2.setValue(mit_equipment.note_2);

        if(mit_equipment.lab_mit_cp_list){
          let cpLab = mit_equipment.lab_mit_cp_list.split(",");
  
          let lab_mit_cp_list = cpLab.map(cp=>{
            return this.cps.find(c=> c.cp_name == cp).mit_cp_id;
          })        
  
          this.equipmentForm.controls.lab_mit_cp_list.setValue(lab_mit_cp_list);   
        } 
        if(mit_equipment.onsite_mit_cp_list){
          let cpOnsite = mit_equipment.onsite_mit_cp_list.split(",");
  
          let onsite_mit_cp_list = cpOnsite.map(cp=>{
            return this.cps.find(c=> c.cp_name == cp).mit_cp_id;
          })        
  
          this.equipmentForm.controls.onsite_mit_cp_list.setValue(onsite_mit_cp_list);  
        }   
      })
    );
    await Promise.all(promises);
    this.isReady = true;
  }
  selectFee(i) {
    this.feeIndex = i;
    this.fee = this.fees[i];
  }

  onAddFee(fee) {
    this.fees.push(fee);
    if (this.fees.length) {
      this.equipmentForm.controls.calibration_list.setValue(this.fees);
    }
  }

  onEditFee(fee) {
    this.fees[this.feeIndex] = Object.assign({}, fee);
    if (this.fees.length) {
      this.equipmentForm.controls.calibration_list.setValue(this.fees);
    }
  }

  onRemoveFee(closeModal) {
    if (
      this.fees[this.feeIndex] &&
      this.fees[this.feeIndex].product_calibration_fee_id
    ) {
      this.removeFees.push(this.fees[this.feeIndex]);
    }
    this.fees = this.fees.filter((v, i) => i !== this.feeIndex);
    this.equipmentForm.controls.calibration_list.setValue(this.fees);
    this.feeIndex = -1;

    closeModal.click();
  }

  async submit() {

    this.submitted = true;
    this.equipmentForm.controls.mit_scope_id.enable();

    if (this.equipmentForm.invalid) {
      this.equipmentForm.controls.mit_scope_id.disable();
      return;
    }

    if(this.equipmentForm.value.lab_mit_cp_list){
      var lab_mit_cps = this.cps
      .filter((cp) =>
      this.equipmentForm.value.lab_mit_cp_list
        .map((id) => id)
        .includes(cp.mit_cp_id)
      )
      .map((v) => v.cp_name)
      .join(",");
    }else{
      var lab_mit_cps = "";
    }
    if(this.equipmentForm.value.onsite_mit_cp_list){
      var onsite_mit_cps = this.cps
      .filter((cp) =>
      this.equipmentForm.value.onsite_mit_cp_list
        .map((id) => id)
        .includes(cp.mit_cp_id)
      )
      .map((v) => v.cp_name)
      .join(",");
    }else{
      var onsite_mit_cps = "";
    }

    let equipmentResponse = await this.MITEquipmentService.update({
      ...this.equipmentForm.value,
      mit_cp_id: Number(1),
      lab_mit_cp_list: lab_mit_cps,
      onsite_mit_cp_list: onsite_mit_cps
    });

    if (equipmentResponse.success) {
      for (let fee of this.fees) {
        fee.mit_cp_ids = fee.service_location.trim() =='LAB'? this.equipmentForm.value.lab_mit_cp_list: this.equipmentForm.value.onsite_mit_cp_list;

        if (!fee.product_calibration_fee_id) {
          let feeResponse = await this.ProductCalibrationFeeService.create({
            mit_equipment_id: this.equipmentForm.value.mit_equipment_id,
            ...fee,
            mit_cp_list: fee.service_location.trim() =='LAB'? lab_mit_cps: onsite_mit_cps
          });
          if (feeResponse.error) {
            this.status = feeResponse.error;
            this.equipmentForm.controls.mit_scope_id.disable();
            return;
          }

          for (let mit_cp_id of fee.mit_cp_ids) {
            await this.ProductCalibrationFeeCPService.create({
              product_calibration_fee_id:
                feeResponse.resultData.product_calibration_fee_id,
              mit_cp_id: mit_cp_id,
            });
          }
        } else {
          let feeResponse = await this.ProductCalibrationFeeService.update({
            mit_equipment_id: this.equipmentForm.value.mit_equipment_id,
            product_calibration_fee_id: fee.product_calibration_fee_id,
            ...fee,
            mit_cp_list: fee.service_location.trim() =='LAB'? lab_mit_cps: onsite_mit_cps
          });
          if (feeResponse.error) {
            this.status = feeResponse.error;
            this.equipmentForm.controls.mit_scope_id.disable();
            return;
          }

          await this.ProductCalibrationFeeCPService.deleteByQueryString({
            product_calibration_fee_id:
              feeResponse.resultData.product_calibration_fee_id,
          });
          for (let mit_cp_id of fee.mit_cp_ids) {
            this.ProductCalibrationFeeCPService.create({
              product_calibration_fee_id:
                feeResponse.resultData.product_calibration_fee_id,
              mit_cp_id: mit_cp_id,
            });
          }
        }
      }

      for (let fee of this.removeFees) {
        let feeResponse = await this.ProductCalibrationFeeService.delete({
          product_calibration_fee_id: fee.product_calibration_fee_id,
        });
        if (feeResponse.error) {
          this.status = feeResponse.error;
          this.equipmentForm.controls.mit_scope_id.disable();
          return;
        }
      }
    } else {
      this.status = equipmentResponse.error;
      this.equipmentForm.controls.mit_scope_id.disable();
      return;
    }

    this.router.navigateByUrl(this.url, {
      state: {
        status: equipmentResponse.success,
      },
    });
  }
  groupUnit(units) {
    let groups = {};
    for (let unit of units) {
      if (!groups[unit.unit_type_id]) {
        groups[unit.unit_type_id] = unit.unit_type_info;
      }
    }
    let groupUnits = {};

    for (let unit of units) {
      if (!groupUnits[unit.unit_type_id]) {
        groupUnits[unit.unit_type_id] = [];
      }
      groupUnits[unit.unit_type_id].push(unit);
    }

    let groupByUnits = [];
    for (let group in groups) {
      groupByUnits.push({
        unit_type_info: groups[group],
        units: groupUnits[group],
      });
    }

    return groupByUnits;
  }

  back(){
    this.location.back();
  }
}
