import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccBillingNoteEditHistoryService } from 'src/app/services/billing-note';

@Component({
  selector: 'app-billing-note-history',
  templateUrl: './billing-note-history.component.html',
  styleUrls: ['./billing-note-history.component.css']
})
export class BillingNoteHistoryComponent implements OnInit {
 //boolean
 submitted = false;
 pageLoad = true;
 //array
 memoList: any = [];
 //string
 acc_billing_note_info_id: string;
 //any
 status: any;
 constructor(
   private route: ActivatedRoute,
   private AccBillingNoteEditHistoryService: AccBillingNoteEditHistoryService
 ) { }

 ngOnInit(): void {
   this.acc_billing_note_info_id = 
   this.route.snapshot.paramMap.get("acc_billing_note_info_id");
   this.getMemoList();
 }

 getMemoList(){
   this.pageLoad = true;
   this.memoList = [];

   this.AccBillingNoteEditHistoryService.load(null, { acc_billing_note_info_id : this.acc_billing_note_info_id  })
   .then(async (res)=>{
     this.memoList = res.resultData;
     this.memoList.sort(function(a,b){
       if(a.created_at < b.created_at) return 1;
       if(a.created_at > b.created_at) return -1;
       return 0;
     });
     this.pageLoad = false;
   })
 }
}
