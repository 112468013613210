import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationFileService {
  async create({
    quotation_info_id,
    file_name,
    file_path
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-file`, {
        quotation_info_id,
        file_name,
        file_path
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      quotation_file_id,
      quotation_info_id,
      file_name,
      file_path
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/quotation-file/${quotation_file_id}`, {
        quotation_file_id,
        quotation_info_id,
        file_name,
        file_path
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_file_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-file/${quotation_file_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-file`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_file_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-file/${quotation_file_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  async validateQuotationExcel(file) {
    let result = null;
    let formData = new FormData();
    formData.append("file", file);
    try {
      result = await Axios().post(`/validate-quotation/excel`, formData);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async validateQuotationData(data) {
    let result = null;
    try {
      result = await Axios().post(`/validate-quotation/data`,
        data
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
