import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccTaxInvoiceEditHistoryService } from 'src/app/services/tax-invoice';
@Component({
  selector: 'app-tax-invoice-history',
  templateUrl: './tax-invoice-history.component.html',
  styleUrls: ['./tax-invoice-history.component.css']
})
export class TaxInvoiceHistoryComponent implements OnInit {
 //boolean
 submitted = false;
 pageLoad = true;
 //array
 memoList: any = [];
 //string
 acc_tax_invoice_info_id: string;
 //any
 status: any;
 constructor(
   private route: ActivatedRoute,
   private AccTaxInvoiceEditHistoryService: AccTaxInvoiceEditHistoryService
 ) { }

 ngOnInit(): void {
   this.acc_tax_invoice_info_id = 
   this.route.snapshot.paramMap.get("acc_tax_invoice_info_id");
   this.getMemoList();
 }

 getMemoList(){
   this.pageLoad = true;
   this.memoList = [];

   this.AccTaxInvoiceEditHistoryService.load(null, { acc_tax_invoice_info_id : this.acc_tax_invoice_info_id  })
   .then(async (res)=>{
     this.memoList = res.resultData;
     this.memoList.sort(function(a,b){
       if(a.created_at < b.created_at) return 1;
       if(a.created_at > b.created_at) return -1;
       return 0;
     });
     this.pageLoad = false;
   })
 }
}
