<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">สร้างใบลดหนี้</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/credit-note']">เอกสารทางบัญชี</a>
                </li>
                <li class="breadcrumb-item active">สร้างใบลดหนี้</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <a href="#" [routerLink]="['/credit-note']" class="btn btn-warning mr-1">
                <i class="fa fa-times icon-left"></i> ยกเลิก
            </a>

            <button class="btn btn-primary mr-1" (click)="saveAsDraft()" [disabled]="disableSaveBtn">
                <i class="fa fa-save icon-left"></i> บันทึกแบบร่าง
            </button>

            <button class="btn btn-primary" (click)="save()" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึก และส่งอนุมัติ
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="!pageLoad"></loading>

        <div class="card" *ngIf="pageLoad">
          <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
            <div class="card-header">
              <div class="row">
                <div class="col-6"> 
                  <h4 class="card-title">สร้างใบลดหนี้ ({{creditNoteInfo?.credit_note_type =='FULL_AMOUNT'? 'ลดทั้งจำนวน': creditNoteInfo?.credit_note_type =='PARTIALLY'?'ลดบางรายการ':'ส่วนลดภายหลัง'}})</h4> 
                </div>
              </div>
            </div>

          <div class="card-content collapse show">
            <div class="card-body pt-0">
              <div class="form-body">
                <h4 class="badge btn-blue badge-customer-lvl" *ngIf="customer_info">
                  {{ customer_info.customer_level_id | dash }}
                </h4>
                <div class="row">
                    <div class="col-xl-3 col-lg-6 col-md-12">
                        <form-group
                            [control]="form.controls.customer_id"
                            [submitted]="submitted"
                          >
                          <label for="customer_id">
                            ชื่อลูกค้า
                          </label>
                          <input
                            type="text"
                            id="customer_name"
                            class="form-control"
                            placeholder="ชื่อลูกค้า"
                            formControlName="customer_name"
                            readonly
                          />
                        </form-group>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-12">
                        <form-group
                        [control]="form.controls.doc_date"
                        [submitted]="submitted">
                        <label for="doc_date">
                            วันที่ออก <span class="danger">*</span>
                        </label>
                        <div class="input-group mt-0">
                            <input
                            date-picker
                            type="text"
                            id="doc_date"
                            [max-date]="undefined"
                            [date]="form.value.doc_date"
                            class="form-control"
                            formControlName="doc_date"
                            />
                            <div
                            class="input-group-append"
                            for="doc_date"
                            >
                            <span class="input-group-text"
                                ><i class="feather icon-calendar"></i
                            ></span>
                            </div>
                        </div>
                        </form-group>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-12">
                      <form-group
                          [control]="form.controls.ref_acc_document_doc_no"
                          [submitted]="submitted"
                        >
                        <label for="ref_acc_document_doc_no">
                          อ้างถึง
                        </label>
                        <input
                          type="text"
                          id="ref_acc_document_doc_no"
                          class="form-control"
                          placeholder="อ้างถึง"
                          formControlName="ref_acc_document_doc_no"
                          readonly
                        />
                      </form-group>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-12">
                        <!-- <form-group
                        [control]="form.controls.doc_no"
                        [submitted]="submitted">
                        <label for="doc_no">
                            เลขที่เอกสาร
                        </label>
                        <input
                            type="text"
                            id="doc_no"
                            class="form-control"
                            placeholder="เลขที่เอกสาร"
                            formControlName="doc_no"
                            readonly
                        />
                        </form-group> -->
                    </div>

            
                </div>

                <div class="row">
                    <div class="col-lg-3 col-md-12">
                      <form-group
                        [control]="form.controls.customer_address_id"
                            [submitted]="submitted"
                          >
                            <label for="customer_address_id">
                              ออกเอกสารในนาม
                            </label>
                            <ng-select2
                              [data]="customerAddressList"
                              [options]="{
                                language: UtilsService.select2OptionsLanguage()
                              }"
                              class="w-100"
                              id="customer_address_id"
                              placeholder="ออกเอกสารในนาม"
                              formControlName="customer_address_id"
                              (valueChanged)="selectAddressName($event)"
                            >
                            </ng-select2>
                        </form-group>
                    </div>

                    <div class="col-xl-3 col-lg-6 col-md-12">
                      <form-group
                          [submitted]="submitted"
                        >
                        <label for="customer_id">
                          Billing Day
                        </label>
                        <input
                          type="text"
                          id="customer_name"
                          class="form-control"
                          placeholder="Billing Day"
                          [value]="customer_info?.billing_day"
                          readonly
                        />
                      </form-group>
                    </div>

                    <div class="col-lg-6 col-md-12">
                      <form-group
                            [control]="form.controls.customer_address"
                            [submitted]="submitted"
                          >
                            <label for="customer_address">
                              ที่อยู่ 
                            </label>
                            <input
                            type="text"
                            id="customer_address"
                            class="form-control"
                            placeholder="ที่อยู่"
                            formControlName="customer_address"
                            readonly
                            />
                        </form-group>
                    </div>
                </div>

              </div>

              <div>
                <app-credit-note-item *ngIf="creditNoteInfo" [creditNoteInfo]="creditNoteInfo" (done)="getItems($event)">
                </app-credit-note-item>
              </div>


              <div class="pb-2">
                  <div class="row">
                      <div class="col-md-6 col-sm-6 mt-75">
                        <div class="row">
                          <div class="col-12">
                            <form-group
                            [control]="form.controls.doc_note"
                            [submitted]="submitted">
                            <label for="doc_note">
                                หมายเหตุ
                            </label>
                            <textarea
                              placeholder="หมายเหตุ"
                              class="form-control"
                              rows="3"
                              id="doc_note"
                              formControlName="doc_note"
                              >
                            </textarea>
                          </form-group>
                          </div>

                      
                        </div>  
                      </div>

                      <div class="col-md-6 col-sm-6 d-flex justify-content-end mt-75">
                          <ul class="list-group cost-list">
                            <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                <span class="cost-title mr-2">มูลค่าของสินค้า/บริการตามใบกำกับภาษีเดิม</span>
                                <span class="cost-value">{{form.value.ref_grand_total_before_vat | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                            </li>
                            <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                <span class="cost-title mr-2">มูลค่าของสินค้าหรือบริการที่ถูกต้อง</span>
                                <span class="cost-value">{{form.value.new_amount | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                            </li>
                            <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                <span class="cost-title mr-2">ผลต่าง</span>
                                <span class="cost-value">{{form.value.difference | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                            </li>
                              
                            <li class="list-group-item border-0 p-50">
                                <div class="row">
                                      <div class="col-7">
                                        <div class="d-inline"><span class="cost-title mr-1">ภาษีมูลค่าเพิ่ม</span></div>
                                        <select class="" id="tax" formControlName="tax" (change)="onKeyup()">
                                          <option value="0">n/a</option>
                                          <option value="1">1%</option>
                                          <option value="7">7%</option>
                                        </select>
                                      </div>

                                      <div class="col-5 text-right">
                                          <span class="cost-value">{{(form.value.difference_tax) | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                      </div>
                                </div>
                              </li>
                              <li class="dropdown-divider"></li>
                              <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                  <span class="cost-title mr-2">ยอดรวมสุทธิ </span>
                                  <span class="cost-value">{{(form.value.grand_total) | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                              </li>
                              <li class="dropdown-divider"></li>
                              <li class="dropdown-divider mt-0"></li>
                          </ul>
                      </div>
                      
                  </div>
              </div>
            </div>
          </div>
          </form>
        </div>
      </div>
    </div>
</div>