import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { UtilsService, TransportPreBookingService, GETService } from 'src/app/services';
import { UserInfoState } from 'src/app/store/user/user.state';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-pre-booking-list',
  templateUrl: './pre-booking-list.component.html',
  styleUrls: ['./pre-booking-list.component.css']
})
export class PreBookingListComponent implements OnInit {
  form : FormGroup;
  bookings: Array<any> = [];
  status: string;
  reset: boolean = false;
  isLoading: boolean = true;
  verify: boolean = false;
  createBooking: boolean = false;
  load: Function = this.TransportPreBookingService.load;
  filter = {};
  params: any;
  booking: any = null;
  select2Options: any = null;
  settingLanguage = {
    errorLoading: function () {
      return "ไม่สามารถค้นข้อมูลได้";
    },
    loadingMore: function () {
      return "กำลังค้นข้อมูลเพิ่ม…";
    },
    noResults: function () {
      return "ไม่พบข้อมูล";
    },
    searching: function () {
      return "กำลังค้นข้อมูล…";
    }
  }
  transportTypes = [];

  initialPage: number = 1;
  @ViewChild("booking_date") booking_date: ElementRef;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  
  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private route: ActivatedRoute,
    private GETService: GETService,
    private TransportPreBookingService: TransportPreBookingService,

    public _UtilsService: UtilsService
  ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
    this.route.queryParams
    .subscribe(params => {
      if(params){

        let page = parseInt(params.page);

        this.params = params;
        this.filter = {
          ...params,
          offset: page == 1 || isNaN(page)? 0: ((page-1)* 10),
          page: this._UtilsService.setUndefined()
        };

        this.initialPage = isNaN(page)? 1: page;
      }
    }
    );
  }

   routeEvent(state): void {
    this.status = state?.status;
  }
  

  async ngOnInit() {
    this.form = this.fbd.group({
      booking_date: [""]
    });
    this.filter = {
      ...this.filter,
      doc_status_id: "WAITING_FOR_APPROVE,REJECTED"
    }
    this.form.controls.booking_date.valueChanges.subscribe(
      (value) => {

        this.filter = {
          ...this.filter,
          booking_date: moment($(this.booking_date.nativeElement).data('daterangepicker').startDate).format("YYYY-MM-DD")
        };
      }
    );
    await this.GETService.transportType(null)
    .then(res=>{
      this.transportTypes = res.resultData || {}
      this.transportTypes = this.transportTypes.map(function(elem) {
        return {
          id: elem.transport_type_id,
          text: `${elem.transport_type_name_th}`,
        }
      });
    })
    this.settingDropdown();
  }

  onChangePage(bookings: Array<any>) {
    this.bookings = bookings;
    this.isLoading = false;
  }

  getFilterLimit(filterLimit){

    if(this.initialPage != filterLimit.initialPage && filterLimit.initialPage != 0){
  
      let params = {
        ...this.filter,
        page: filterLimit.initialPage,
        offset: this._UtilsService.setUndefined()
      }

      this.router.navigate([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.params = params;
    }
  }

  search(filter: object) {
    this.filter = { ...this.filter, ...filter };

    this.setQueryUrl();
  }

  searchAll(filter: object) {
    this.filter = { ...this.filter, customer_id: filter? filter: undefined };

    this.setQueryUrl();
  }

  setQueryUrl(){
    let filter = {
      ...this.filter,
      page: this.initialPage,
      offset: this._UtilsService.setUndefined(),
    }
    this.router.navigate([], {
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
    this.params = filter;
  }

  clearDate(){
    this.form.get('booking_date').setValue('');
    let condition = {
      "booking_date": undefined
    };
    this.search(condition);
  }

  saveBooking(e){
    if(e){
      this.doReset(e.status.success || e.status.error);
      e.closeModal.nativeElement.click();
    }
    this.booking = null;
    this.createBooking = false;
  }

  select(booking){
    this.verify = false;
    this.booking = booking;
  }

  createPreBooking(){
    this.createBooking = true;
    setTimeout(() => {
      $('#modalPreBookingCreate').modal('toggle');
    }, 100);
  }

  verifyBooking(booking){
    this.verify = true;
    this.booking = booking;
  }

  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }

  btnToggleDate(){
    document.getElementById("booking_date_filter").classList.toggle("show");
  }

  settingDropdown(){
    this.select2Options = {
      language: this.settingLanguage,
      ajax: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        delay: 500,
        url: environment.apiUrl + "/customer-info?limit=10&&order_by=customer_code:desc",
        dataType: "json",
        data: (params) => {
          let query = null
          query= {
            search_all: params.term,
            isSearchAllCustomer: true
          };
          return query;
        },
        processResults: function (data) {
          let items = data.resultData.map((elem) => {
          return {
                id: elem.customer_id,
                text: `${elem.company_name} ${elem.branch?" (" + elem.branch + ")": ""}`
              }
          });
          return {
            results: items,
          };
        },
      },
    };
  }
}
