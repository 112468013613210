import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { CustomerAddressService, CustomerContactService } from 'src/app/services';
import moment from 'moment';
import { 
  QuotationService,
  DownloadFileService,
  QuotationFileService,
  QuotationMitItemService,
  QuotationUtilsService, 
  QuotationContactService, 
  CalculateFunctionService,
  QuotationProductItemService,
  NgSelect2Customer
} from '../../../../services/quotations';
import { Observable, Subscription } from 'rxjs';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import { Location } from "@angular/common";

declare const $: any;

@Component({
  selector: 'app-quotation-show',
  templateUrl: './quotation-show.component.html',
  styleUrls: ['./quotation-show.component.css']
})
export class QuotationShowComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  pageLoad: boolean =  false;
  loadBtn: boolean =  false;
  canDraft: boolean =  false;

  status: any;
  quotationInfo: any;
  quotationCode: any = [];
  documentStatus: any = [];
  productItemList: any = [];

  files: any = [];
  productList = [];
  acceptedList = [];
  customerContactList = [];
  mitItems: any;

  count: number = 0;
  quotation_info_id: string;
  customer_level_id: string;

  tab: string = "product-tab";
  mitReady: string = "no";
  number_of_mit_items: number = 0;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;
  userSub: Subscription;
  options = {
    multiple: true
  };

  confirmMessage = {
    type: "",
    title: "",
    text: "",
    smallText: ""
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private formBuilder: FormBuilder,
    private QuotationService: QuotationService,
    private DownloadFileService: DownloadFileService,
    private QuotationFileService: QuotationFileService,
    private CustomerContactService: CustomerContactService,
    private CustomerAddressService: CustomerAddressService,
    private QuotationMitItemService: QuotationMitItemService,
    private QuotationContactService: QuotationContactService,
    private QuotationProductItemService: QuotationProductItemService,

    public _utils : QuotationUtilsService,
    public _ngSelect2Customer: NgSelect2Customer,
    public _calculate: CalculateFunctionService,
  ) { 
  }

  async ngOnInit() {
    this._ngSelect2Customer.initPopOver();
    this.reload();
  }

  ngOnDestroy() {
    this._ngSelect2Customer.clearPopOver();
  }

  async reload(event = null){
    this.pageLoad = false;
    this.loadBtn = false;
    this.quotation_info_id = this.route.snapshot.paramMap.get("quotation_info_id");
    this.createForm();
    this.form.disable();
    this.userSub = this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      this.userInfo = userInfo;
    });

    let promises = [];
    promises.push(
      this.getQuotation()
    )
    promises.push(
      await this.QuotationMitItemService.load({limit: 0} , {
        quotation_info_id: this.quotation_info_id,
        is_parent_quotation_mit_item_id_not_null: false
      }).then(async (res) => {
        this.number_of_mit_items = res.rowCount;
      })
    )
    promises.push(
      this.checkCanDraft()
    )

    await Promise.all(promises);
    this.pageLoad = true;
  }

  async getQuotation(){
    await this.QuotationService.getById({ quotation_info_id: this.quotation_info_id }).then(async (res) => {
      if (res.success) {
        var resultData = res.resultData || {};
        this.quotationInfo = res.resultData || {};
        console.log(resultData)
        this.customer_level_id = resultData.customer_info.customer_level_id;

        let quotation = {
          ...resultData,
          customer_id: '['+ resultData.customer_info?.customer_code+'] '+ resultData.customer_info?.company_name + `${resultData?.customer_info.branch?" (" + resultData?.customer_info.branch + ")": ""}`,
          discount: this._utils.transformDecimal(resultData.discount),
          discount_percentage: this._utils.transformDecimal(
            this._utils.convertStingToNumber(this.quotationInfo.customer_info.discount_percentage_init || 0)),
        }
        this.form.patchValue(quotation);

        this.form.controls['doc_date'].setValue(moment(resultData.doc_date).format('DD-MM-YYYY'));
        this.form.controls['doc_validdate'].setValue(moment(resultData.doc_validdate).format('DD-MM-YYYY'));

        if(resultData.customer_address_id){
          await this.CustomerAddressService.getById({ customer_address_id: resultData.customer_address_id }).then((res)=>{
            if(res.success){
              var resultData = res.resultData;
              var address = this._utils.genAddress(resultData);
              this.form.controls['customer_address_id'].setValue(resultData.address_name + ' ('+ resultData.branch +')' + ` แผนก: ${resultData.department}`);
              this.form.controls['customer_address'].setValue(address); 
              this.form.controls['mit_iplus_code'].setValue(resultData.mit_iplus_code); 
            }else{
              this.form.controls['customer_address_id'].setValue(null);
              this.form.controls['customer_address'].setValue(null); 
              this.form.controls['mit_iplus_code'].setValue(null); 
            } 
          })
        }

        await this.CustomerContactService.load(null, {
          order_by: "isMaster:desc",
          customer_id: resultData.customer_info.customer_id
        }).then((res) => {
          this.customerContactList = res.resultData || [];
          this.customerContactList = this.customerContactList.map(function(elem) {
            return {
              id: elem.customer_contact_id,
              customer_id: elem.customer_id,
              text: `${elem.firstname} ${elem.lastname} ${elem.related_customer_address_info?.department?" (แผนก:" + elem.related_customer_address_info?.department + ")": ""}`,
              data: elem
            }
          });
        });
        await this.QuotationContactService.load(null, { quotation_info_id: this.quotation_info_id }).then((res) => {
          let ids = res.resultData.map(v=>{
            return v.customer_contact_id;
          })
          this.form.controls['customer_contact_ids'].setValue(ids);
        });
        if(resultData.quotation_sub_header_info){
          this.form.controls['quotation_sub_header_id'].setValue(resultData.quotation_sub_header_info.sub_header);
        }
        await this.QuotationFileService.load(null, { quotation_info_id: this.quotation_info_id  }).then((res)=>{
          this.files = res.resultData;
        })
        await this.QuotationProductItemService.load(null, {quotation_info_id: this.quotation_info_id,'order_by': 'order_by:asc'}).then((res)=>{
          this.productItemList = res.resultData;
          this.sumCount();
        });
      }
    });
  }

  createForm(){
    this.form = this.formBuilder.group({
      customer_id: ["", [Validators.required]],
      doc_no: ["", [Validators.required]],
      doc_date: ["", [Validators.required]],
      doc_validdate: ["", [Validators.required]],
      doc_status_id: ["n/a", [Validators.required]],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      mit_iplus_code: [""],
      quotation_sub_header_id: ["", [Validators.required]],
      customer_contact_ids: [[], [Validators.required]],
      discount_percentage: [""],
      grand_total: [""],
      discount: [0.00],
      tax: ["7"],
      sale_id: [""]
    });
  }
  async onGetMitItem(event){
    if(event){
      this.loadBtn = true;
    }
  }
  async saveAsWaitingForAccept(sendEmail){
    let quotationResponse = await this.QuotationService.update({
      ...this.quotationInfo,
      doc_status_id: 'WAITTING_FOR_ACCEPT',
      tax: this.quotationInfo.tax.toString(),
      approved_id: this.userInfo.user_id,
      email: sendEmail
    });
    if (quotationResponse.success) {

      this.router.navigateByUrl("/quotation", {
        state: {
          status: quotationResponse.success,
        },
      });
    } else {
      this.status = quotationResponse.error;
    }
  }
  async saveAsRejected(){
    let quotationResponse = await this.QuotationService.update({
      ...this.quotationInfo,
      doc_status_id:'DRAFT',
      tax: this.quotationInfo.tax.toString()
    });
    if (quotationResponse.success) {
      this.router.navigateByUrl("/quotation", {
        state: {
          status: quotationResponse.success,
        },
      });
    } else {
      this.status = quotationResponse.error;
    }
  }
  async Revise(){
    let quotationResponse = await this.QuotationService.update({
      ...this.quotationInfo,
      doc_status_id:'REVISE',
      tax: this.quotationInfo.tax.toString()
    });
    if (quotationResponse.success) {
      this.router.navigateByUrl("/quotation", {
        state: {
          status: quotationResponse.success,
        },
      });
    } else {
      this.status = quotationResponse.error;
    }
  }
  sumCount(){
    this.count = 0;
    this.productItemList.map(item=>{
      this.count = this.count + this._calculate.sumProduct(item);
    });
    let grand_total = this._calculate.sumProductTotal(this.count, this.form.value);
    this.form.get('grand_total').setValue(grand_total);
    this.addNumberRunning();
  }
  addNumberRunning(){
    let i = 1;
    this.productItemList.map(item=>{
      if (item.item_type != 'description' && item.item_type != 'note'){
        item['index'] = i;
        i++;
      }
    })
  }
  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }
  openPdf(quotationInfo) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/document/' + quotationInfo.quotation_info_id])
    );
  
    window.open(url, '_blank');
  }
  async sendEmail(quotationInfo){
    let quotationResponse =  await this.QuotationService.sendEmail({
      ...quotationInfo
    });
    if(quotationResponse.success){
      this.status = quotationResponse.success;
    }
  }

  async onSaveConfirmVoided(event: any){
    if(event){
      var response = await this.QuotationService.update({
        ...this.quotationInfo,
        doc_status_id:'VOIDED',
        doc_date: moment(this.quotationInfo.doc_date).format('YYYY/MM/DD'),
        doc_validdate: moment(this.quotationInfo.doc_validdate).format('YYYY/MM/DD'),
        tax: this.quotationInfo.tax.toString()
      });
      if(response.success){
      }
    }
    if(event.type == "voided"){
      if(response.success){
        await this.getQuotation();
        this.status = response.success;
      }else{
        this.status = response.error;
      }
    }else if(event.type == "voided-and-copy"){
      if(response.success){
        this.router.navigateByUrl("/quotation/" + this.quotationInfo.quotation_info_id + "/edit?action=copy", {
          state: {
            status: response.success,
        }});
      }else{
        this.status = response.success || response.error;
      }
    }else{
      if(response.success){
        this.router.navigateByUrl("/quotation/" + this.quotationInfo.quotation_info_id + "/edit?action=copy&&type=edit", {
          state: {
            status: response.success,
        }});
      }else{
        this.status = response.error;
      }
    }
    event.closeModal.click();
  }

  async approve(sendEmail){
    let quotationResponse = await this.QuotationService.update({
      ...this.quotationInfo,
      doc_status_id:'ACCEPTED',
      tax: this.quotationInfo.tax.toString(),
      email:sendEmail
    });

    if (quotationResponse.success) {
      if (quotationResponse.success) {
        this.router.navigateByUrl("/quotation", {
          state: {
            status: quotationResponse.success,
          },
        });
      } else {
        this.status = quotationResponse.error;
      }
    } else {
      this.status = quotationResponse.error;
    }
  }

  async checkCanDraft(){
    let count_of_mit_items = 0;

    let product = this.productItemList.find(item=> item.item_type == 'product' && (item.job_order_product_item_id || item.acc_invoice_info_id || item.acc_tax_invoice_info_id));

    await this.QuotationMitItemService.load({limit:0}, { quotation_info_id: this.quotation_info_id, is_parent_quotation_mit_item_id_not_null: false, transport_receive_item_id: "null", job_order_calibration_item_id: "null", acc_invoice_info_id: "null", acc_tax_invoice_info_id: "null"  }).then(res=>{
      count_of_mit_items = res.rowCount || 0;
    });

    if(product || this.number_of_mit_items != count_of_mit_items){
      this.canDraft = false;
    }else{
      this.canDraft = true;
    }
  }

  getAcceeptedList(event){
    if(event) this.acceptedList = event;
  }

  confirm(type: string){
    if(type == 'REVISE'){
      this.confirmMessage.type = type;
      this.confirmMessage.title = "ยืนยันการ Revise ใบเสนอราคา";
      this.confirmMessage.text = "คุณยืนยันที่จะ Revise ใบเสนอราคาใช่หรือไม่ ?";
      this.confirmMessage.smallText = "คุณจะสามารถแก้ไข Item ที่กระทบราคาได้เท่านั้น";
    }else{
      this.confirmMessage.type = type;
      this.confirmMessage.title = "ยืนยันการถอยสถานะไปเป็นร่าง";
      this.confirmMessage.text = "คุณยืนยันที่จะถอยสถานะใบเสนอราคาไปเป็นแบบร่างใช่หรือไม่ ?";
      this.confirmMessage.smallText = "";
    }
    $('#modalConfirmMsg').modal('toggle');
  }

  onSaveConfirm(closeModalEl){
    if(this.confirmMessage.type == "REVISE"){
      this.Revise();
    }else{
      this.saveAsRejected();
    }
    closeModalEl.click();
  }

  back(): void {
    this.location.back();
  }
}