import {
  Component,
  NgZone,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MITScopeService } from "src/app/services";
import { ENSignValidator } from "src/app/validators";

@Component({
  selector: "app-scope-edit",
  templateUrl: "./scope-edit.component.html",
  styleUrls: ["./scope-edit.component.css"],
})
export class ScopeEditComponent {
  form: FormGroup;
  submitted = false;
  @Input("mit-scope-id") mit_scope_id: string;
  @Output() done = new EventEmitter();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private MITScopeService: MITScopeService
  ) {
    this.setForm();
  }
  setForm(): void {
    this.form = this.formBuilder.group({
      scope_name: ["", [Validators.required, ENSignValidator()]],
      scope_name_short: ["", [Validators.required]],
    });
  }
  async init() {
    let result = await this.MITScopeService.getById({
      mit_scope_id: this.mit_scope_id,
    });
    if (result.success) {
      let unit = result.resultData;
      for (let key in this.form.value) {
        this.form.controls[key].setValue(unit[key]);
      }
    }
  }
  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let response = await this.MITScopeService.update({
      ...this.form.value,
      mit_scope_id: this.mit_scope_id,
    });

    this.done.emit(response.success || response.error);
    this.submitted = false;
    this.closeModalBtn.nativeElement.click();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      } else {
        this.init();
      }
    });
  }
}
