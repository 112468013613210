import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NgSelect2Customer } from 'src/app/services/quotations';
import { UserInfoState } from 'src/app/store/user/user.state';

@Component({
  selector: 'app-invoice-create-filter',
  templateUrl: './invoice-create-filter.component.html',
  styleUrls: ['./invoice-create-filter.component.css']
})
export class InvoiceCreateFilterComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  select2OptionCustomer= null;

  type: string;
  page: string = 'ใบแจ้งหนี้';

  reset = false;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;

  constructor(   
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private NgSelect2Customer: NgSelect2Customer,
    private router: Router
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }

  ngOnInit(): void {
    if(window.location.pathname.includes('billing-note')){
      this.page ='ใบวางบิล';
    }else if(window.location.pathname.includes('received-voucher')){
      this.page ='ใบสำคัญรับ';
    }else if(window.location.pathname.includes('tax-invoice')){
      this.page ='ใบกำกับภาษี';
    }
  }

  setForm(){
    this.form = this.formBuilder.group({
      customer_id: ["", [Validators.required]]
    });
    this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;

      this.select2OptionCustomer = this.NgSelect2Customer.select2Options("?limit=10&&exclude_customer_state_id=Free&&is_exclude_address=true");
    });
    this.reset = false;
    setTimeout(() => {
      this.reset = true;
    }, 50);
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.closeModalBtn.nativeElement.click();
    this.router.navigateByUrl(window.location.pathname + "/"+ this.form.value.customer_id +"/create");
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }
}