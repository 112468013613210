import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CustomerAddressService, CustomerContactService } from 'src/app/services';
import { CalculateFunctionService, NgSelect2Customer, QuotationUtilsService } from 'src/app/services/quotations';
import { AccBillingNoteInfoService, AccBillingNoteContactService } from 'src/app/services/billing-note';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import moment from 'moment';
import { AccInvoiceInfoService } from 'src/app/services/invoice';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';
import { Location } from "@angular/common";

@Component({
  selector: 'app-billing-note-show',
  templateUrl: './billing-note-show.component.html',
  styleUrls: ['./billing-note-show.component.css']
})
export class BillingNoteShowComponent implements OnInit {
  form: FormGroup;

  submitted = false;
  pageLoad = false;

  acc_billing_note_info_id: string;

  customerList: any = [];
  customerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerAddressList: any = [];
  tmpCustomerContactList: any = [];
  productList: any = [];

  count: number = 0;
  //any
  status: any;
  select2Options = null;
  subject: Subject<string> = new Subject();
  options = { multiple: true };
  customer_info = null;
  billingNoteInfo = null;

  billingNoteItem = null;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccBillingNoteInfoService: AccBillingNoteInfoService,
    private AccBillingNoteContactService: AccBillingNoteContactService,

    public _utils : QuotationUtilsService,
    public _calculate: CalculateFunctionService,
    public _ngSelect2Customer: NgSelect2Customer,
  ) {
    this.acc_billing_note_info_id = 
    this.route.snapshot.paramMap.get("acc_billing_note_info_id");
  }

  async ngOnInit() {
    this._ngSelect2Customer.initPopOver();
    this.createForm();

    this.userInfo$.subscribe(user=>{
      if(!user) return;
      this.userInfo = user;
    })

    await this.AccBillingNoteInfoService.getById({acc_billing_note_info_id: this.acc_billing_note_info_id})
    .then(async res=>{

      let resultData = res.resultData || {};
      this.billingNoteInfo = resultData;
      this.customer_info = res.resultData.customer_info;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: this.customer_info? `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''} ${this.customer_info?.branch?" (" + this.customer_info?.branch + ")": ""}`: '',
        customer_credit_term_day: resultData.credit_term_day || 0,
        ...resultData
      }
      this.form.patchValue(data);

      if(resultData.customer_address_id){
        await this.CustomerAddressService.getById({ customer_address_id: resultData.customer_address_id }).then((res)=>{
          if(res.success){
            var resultData = res.resultData;
            var address = this._utils.genAddress(resultData);

            this.form.controls['customer_address_id'].setValue(resultData.address_name + (resultData.branch?' ('+ resultData.branch +')': '') + ` แผนก: ${resultData.department}`);
            this.form.controls['customer_address'].setValue(address); 
          }else{
            this.form.controls['customer_address_id'].setValue(null);
            this.form.controls['customer_address'].setValue(null); 
          } 
        })
      }

      if(resultData.customer_id){
        await this.setCustomerContact(resultData.customer_id);
      }

      await this.AccBillingNoteContactService.load(null, { acc_billing_note_info_id: this.acc_billing_note_info_id }).then((res) => {
        let ids = res.resultData.map(v=>{
          return v.customer_contact_id;
        })
        this.form.controls['customer_contact_ids'].setValue(ids);
      });
    })

    this.pageLoad = true;
  }

  ngOnDestroy() {
    this._ngSelect2Customer.clearPopOver();
  }

  createForm(){
    this.form = this._fbd.group({
      customer_id: [""],
      customer_name: [""],
      doc_no: [""],
      doc_date: [""],
      doc_status_id: [""],
      customer_address_id: [""],
      customer_address: [""],
      doc_note: [""],
      grand_total: [0.00],
      appointment_location: [""],
      appointment_time_from: ["00:00"],
      appointment_time_to: ["00:00"],
      appointment_date: [""],
      sent_at: [""],
      customer_contact_ids: [[]],
    });
    this.form.disable();
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }

  async approve(){
    let response = await this.AccBillingNoteInfoService.update({
      ...this.billingNoteInfo,
      doc_status_id: 'APPROVED'
    });
    if (response.success) {
      this.router.navigateByUrl("/billing-note", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async reject(){
    let response = await this.AccBillingNoteInfoService.update({
      ...this.billingNoteInfo,
      doc_status_id: 'REJECTED'
    });
    if (response.success) {
      this.router.navigateByUrl("/billing-note", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async cancel(){
    let response = await this.AccBillingNoteInfoService.update({
      ...this.billingNoteInfo,
      doc_status_id: 'CANCELED'
    });
    if (response.success) {
      await Promise.all(this.billingNoteItem.billingNoteItems.map(async (value)=>{

        if(value.acc_billing_note_item_id){
          if (value.doc_type == 'INVOICE') {
            let  invoiceInfoResponse = await this.AccInvoiceInfoService
            .update({
              ...value.data,
              doc_duedate: moment(value.data.doc_duedate).format('YYYY-MM-DD'),
              acc_billing_note_info_id: "",
            });
          }else{
            let  invoiceTaxInfoResponse = await this.AccTaxInvoiceInfoService
            .update({
              ...value.data,
              doc_duedate: moment(value.data.doc_duedate).format('YYYY-MM-DD'),
              acc_billing_note_info_id: "",
            });
          }
        }
      }));

      await this.router.navigateByUrl("/billing-note", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  getBillingNoteItems(event){
    this.billingNoteItem = event;
  }

  back(): void {
    this.location.back();
  }
}
