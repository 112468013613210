<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-0">ข้อมูลของฉัน</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/users/user-profile']">ภาพรวมระบบ</a>
                </li>
                <li class="breadcrumb-item active">
                  <a href="#">ข้อมูลของฉัน</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status
          *ngIf="status"
          [status]="status"
        ></app-response-status>
        <div class="card col-6">
          <div class="card-body">
            <h6>ข้อมูลของฉัน</h6>
            <form [formGroup]="form" autocomplete="off">
                <div class="row mt-1">
                    <div class="col-xl-6 col-lg-6 col-md-12 mb-1">
                        <form-group
                          [control]="form.controls.firstname_th"
                          [submitted]="submitted"
                        >
                          <label for="firstname_th">
                            ชื่อ (ภาษาไทย) <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="firstname_th"
                            class="form-control"
                            placeholder="ชื่อ (ภาษาไทย)"
                            formControlName="firstname_th"
                          />
                        </form-group>
                    </div>
                    <div class="col-xl-6 col-lg-6 col-md-12 mb-1">
                        <form-group
                          [control]="form.controls.lastname_th"
                          [submitted]="submitted"
                        >
                          <label for="lastname_th">
                            นามสกุล (ภาษาไทย) <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="lastname_th"
                            class="form-control"
                            placeholder="นามสกุล (ภาษาไทย)"
                            formControlName="lastname_th"
                          />
                        </form-group>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-12 mb-1">
                        <form-group
                          [control]="form.controls.firstname_en"
                          [submitted]="submitted"
                        >
                          <label for="firstname_en">
                            ชื่อ (ภาษาอังกฤษ) <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="firstname_en"
                            class="form-control"
                            placeholder="ชื่อ (ภาษาอังกฤษ)"
                            formControlName="firstname_en"
                          />
                        </form-group>
                    </div>

                    <div class="col-xl-6 col-lg-6 col-md-12 mb-1">
                        <form-group
                          [control]="form.controls.lastname_en"
                          [submitted]="submitted"
                        >
                          <label for="lastname_en">
                            นามสกุล (ภาษาอังกฤษ) <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="lastname_en"
                            class="form-control"
                            placeholder="นามสกุล (ภาษาอังกฤษ)"
                            formControlName="lastname_en"
                          />
                        </form-group>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-md-12 mb-1">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-12 p-0">
                          <div class="col-12 mb-1">
                            <form-group
                              [control]="form.controls.mobile_no"
                              [submitted]="submitted"
                            >
                              <label for="mobile_no">
                                โทรศัพท์ <span class="danger">*</span>
                              </label>
                              <input
                                type="text"
                                id="mobile_no"
                                class="form-control"
                                placeholder="โทรศัพท์"
                                formControlName="mobile_no"
                              />
                            </form-group>
                          </div>
      
                          <div class="col-12 mb-1">
                            <form-group
                              [control]="form.controls.email"
                              [submitted]="submitted"
                            >
                              <label for="email">
                                อีเมล
                              </label>
                              <input
                                type="email"
                                id="email"
                                class="form-control"
                                placeholder="อีเมล"
                                formControlName="email"
                                readonly
                              />
                            </form-group>
                          </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-12 p-0">
                          <div class="col-12 mb-1">
                            <label>
                              ลายเซ็น <span class="danger">*</span>
                            </label>
                            <div class="mb-1" *ngIf="imageShow">
                              <img [src]="imageShow" class="image-size">
                            </div>
                            <div class="mb-1" *ngIf="imageShow == null">
                              <div class="image-empty"></div>
                              <div class="text-danger pl-2" *ngIf="imageShow == null && submitted">
                                โปรดระบุ
                              </div>
                            </div>
                            <span>
                              <a class="text-primary" (click)="uploadImage()">เลือกไฟล์.....</a>
                              <input 
                                type="file" 
                                class="form-control" 
                                #signature_file 
                                id="signature_file" 
                                name="signature_file"
                                (change)="processImage(signature_file)" 
                                style="display:none;" 
                                accept=".png, .jpg, .jpeg, .PNG, .JPG, .JPEG"
                              >
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                   
                </div>
  
                <a href="#" [routerLink]="['/dashboard']" class="btn btn-warning mr-1">
                    <i class="fa fa-times icon-left"></i> ยกเลิก
                </a>

                <button
                    type="button"
                    (click)="submit()"
                    class="btn btn-primary btn-min-width"
                >
                    <i class="fa fa-save"></i> บันทึก
                </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  