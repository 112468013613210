<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">แก้ไขใบแจ้งหนี้/ใบส่งของ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/invoice']">เอกสารทางบัญชี</a>
                </li>
                <li class="breadcrumb-item active">แก้ไขใบแจ้งหนี้/ใบส่งของ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <a href="#" [routerLink]="['/invoice']" class="btn btn-warning mr-1">
                <i class="fa fa-times icon-left"></i> ยกเลิก
            </a>

            <button class="btn btn-primary mr-1" (click)="saveAsDraft()" *ngIf="invoiceInfo?.doc_status_id =='DRAFT' || invoiceInfo?.doc_status_id =='REJECTED'" [disabled]="disableSaveBtn">
                <i class="fa fa-save icon-left"></i> บันทึกแบบร่าง
            </button>

            <button class="btn btn-primary" (click)="save()" *ngIf="invoiceInfo?.doc_status_id =='DRAFT' || invoiceInfo?.doc_status_id =='REJECTED'" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> {{(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                'SALES_DEP'
              )? 'บันทึก และส่งตรวจสอบ': 'บันทึก และส่งอนุมัติ'}}
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="!pageLoad"></loading>

        <div class="card" *ngIf="pageLoad">
          <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
            <div class="card-header">
              <div class="row">
                <div class="col-6"> 
                  <h4 class="card-title d-inline mr-50">แก้ไขใบแจ้งหนี้/ใบส่งของ</h4>
                  &nbsp;
                  <span [ngClass]="{'badge-info': invoiceInfo?.doc_status_id === 'DRAFT',
                  'badge-warning': ['WAITING_VERIFY','WAITING_FOR_APPROVE','WAITING_FOR_PAYMENT'].includes(invoiceInfo?.doc_status_id),
                  'badge-danger':invoiceInfo?.doc_status_id === 'REJECTED',
                  'badge-success': ['COMPLETED'].includes(invoiceInfo?.doc_status_id)
                  }"
                  class="badge">{{
                    invoiceInfo?.doc_status_info?.document_status_name_th
                  }}</span>
                </div>
  
                <!-- <div class="col-6">
                  <div class="float-md-right">
                    <div class="btn-toolbar">
                      <input
                        style="margin-right: 5px;margin-top: 5px;"
                        type="checkbox"
                        id="is_return_doc_can_create"
                        formControlName="is_return_doc_can_create"
                        />
                        <label for="is_return_doc_can_create"
                          >ส่งคืนเครื่องมือ</label
                      >
                  
                    </div>
  
                  </div>
                </div> -->
              </div>
            </div>
            
            <div class="card-content collapse show">
              <div class="card-body pt-0">
                <div class="form-body">
                  <h4 class="badge btn-blue badge-customer-lvl" *ngIf="customer_info">
                    {{ customer_info.customer_level_id | dash }}
                  </h4>
                  <div class="row">
                      <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                              [control]="form.controls.customer_id"
                              [submitted]="submitted"
                            >
                            <label for="customer_id">
                              ชื่อลูกค้า
                            </label>
                            <input
                              type="text"
                              id="customer_name"
                              class="form-control"
                              placeholder="ชื่อลูกค้า"
                              formControlName="customer_name"
                              readonly
                            />
                          </form-group>
                      </div>

                      <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_date"
                          [submitted]="submitted">
                          <label for="doc_date">
                              วันที่ออก <span class="danger">*</span>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              id="doc_date"
                              [date]="form.value.doc_date"
                              [max-date]="undefined"
                              class="form-control"
                              formControlName="doc_date"
                              />
                              <div
                              class="input-group-append"
                              for="doc_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                      </div>

                      <div class="col-xl-3 col-lg-6 col-md-12">
                        <form-group
                        [control]="form.controls.doc_validdate"
                        [submitted]="submitted">
                        <label for="doc_validdate">
                            วันที่ครบกำหนด
                        </label>
                        <div class="input-group mt-0">
                            <input
                            date-picker
                            *ngIf="resetdate"
                            type="text"
                            id="doc_validdate"
                            [max-date]="undefined"
                            placeholder="วันที่ครบกำหนด"
                            [date]="form.value.doc_validdate"
                            class="form-control"
                            formControlName="doc_validdate"
                            />
                            <div
                            class="input-group-append"
                            for="doc_validdate"
                            >
                            <span class="input-group-text rounded-0"
                                ><i class="feather icon-calendar"></i
                            ></span>
                            </div>
                            <span class="input-group-text rounded-0 border-left-0" (click)="clearDate()"
                            ><i class="fa fa-times"></i>
                            </span>
                        </div>
                        </form-group>
                    </div>

                      <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_no"
                          [submitted]="submitted">
                          <label for="doc_no">
                              เลขที่เอกสาร
                          </label>
                          <input
                              type="text"
                              id="doc_no"
                              class="form-control"
                              placeholder="เลขที่เอกสาร"
                              formControlName="doc_no"
                              readonly
                          />
                          </form-group>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-lg-3 col-md-12">
                        <form-group
                          [control]="form.controls.customer_address_id"
                              [submitted]="submitted"
                            >
                              <label for="customer_address_id">
                                ออกเอกสารในนาม <span class="danger">*</span>
                              </label>
                              <ng-select2
                                [data]="customerAddressList"
                                [options]="{
                                  language: UtilsService.select2OptionsLanguage()
                                }"
                                class="w-100"
                                id="customer_address_id"
                                placeholder="ออกเอกสารในนาม"
                                formControlName="customer_address_id"
                                (valueChanged)="selectAddressName($event)"
                              >
                              </ng-select2>
                          </form-group>
                      </div>

                      <div class="col-xl-3 col-lg-6 col-md-12">
                        <form-group
                            [submitted]="submitted"
                          >
                          <label for="customer_id">
                            Billing Day
                          </label>
                          <input
                            type="text"
                            id="customer_name"
                            class="form-control"
                            placeholder="Billing Day"
                            [value]="customer_info?.billing_day"
                            readonly
                          />
                        </form-group>
                      </div>

                      <div class="col-lg-6 col-md-12">
                        <form-group
                              [control]="form.controls.customer_address"
                              [submitted]="submitted"
                            >
                              <label for="customer_address">
                                ที่อยู่ 
                              </label>
                              <input
                              type="text"
                              id="customer_address"
                              class="form-control"
                              placeholder="ที่อยู่"
                              formControlName="customer_address"
                              readonly
                              />
                          </form-group>
                      </div>
                  </div>

                  <div class="row">
                      <div class="col-lg-3 col-md-12">
                        <form-group
                          [control]="form.controls.customer_credit_term_day"
                              [submitted]="submitted" 
                            >
                              <label for="customer_credit_term_day">
                                เครดิต (วัน) <span class="danger">*</span>
                              </label>
                              <input
                                  type="text"
                                  id="customer_credit_term_day"
                                  class="form-control"
                                  (keyup)="onCreditKeyup()"
                                  placeholder="เครดิต (วัน)"
                                  formControlName="customer_credit_term_day"
                              />
                          </form-group>
                      </div>

                      <div class="col-lg-3 col-md-12">
                          <form-group
                          [control]="form.controls.doc_duedate"
                          [submitted]="submitted">
                          <label for="doc_duedate">
                              วันนัดชำระ
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              *ngIf="resetdate"
                              type="text"
                              id="doc_duedate"
                              [date]="form.value.doc_duedate"
                              [max-date]="undefined"
                              class="form-control"
                              formControlName="doc_duedate"
                              />
                              <div
                              class="input-group-append"
                              for="doc_duedate"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                      </div>

                      <div class="col-lg-3 col-md-12">
                        <form-group
                        [control]="form.controls.return_date"
                        [submitted]="submitted">
                        <label for="return_date">
                            วันนัดส่งคืนเครื่อง
                        </label>
                        <div class="input-group mt-0">
                            <input
                            date-picker
                            type="text"
                            id="return_date"
                            [date]="form.value.return_date"
                            [max-date]="undefined"
                            class="form-control"
                            formControlName="return_date"
                            />
                            <div
                            class="input-group-append"
                            for="return_date"
                            >
                            <span class="input-group-text"
                                ><i class="feather icon-calendar"></i
                            ></span>
                            </div>
                        </div>
                        </form-group>
                      </div>

                      <div class="col-lg-3 col-md-12">

                          <form-group
                          [control]="form.controls.customer_contact_ids"
                              [submitted]="submitted"
                            >
                              <label for="customer_contact_ids">
                                ผู้ติดต่อ <span class="danger">*</span>
                              </label>
                              <ng-select2
                              [data]="customerContactList"
                              [options]="{
                                templateResult: NgSelect2Customer?.templateContactResult,
                                multiple: true,
                                language: UtilsService.select2OptionsLanguage()
                              }"
                              class="w-100"
                              id="customer_contact_ids"
                              placeholder="ผู้ติดต่อ"
                              formControlName="customer_contact_ids"
                              >
                              </ng-select2>
                          </form-group>

                      </div>
                  </div>
                </div>

                <div>
                  <app-invoice-item                         
                    *ngIf="customer_info"
                    [customer_info]="customer_info"
                    (done)="getInvoiceItems($event)">
                  </app-invoice-item>
                </div>

                <div class="pb-2">
                    <div class="row">
                      <div class="col-md-ภ col-sm-6 mt-75">
                        <div class="row">
                          <div class="col-12">
                            <form-group
                            [control]="form.controls.doc_note"
                            [submitted]="submitted">
                            <label for="doc_note">
                                หมายเหตุ
                            </label>
                            <textarea
                              placeholder="หมายเหตุ"
                              class="form-control"
                              rows="3"
                              id="doc_note"
                              formControlName="doc_note"
                              >
                            </textarea>
                          </form-group>
                          </div>
  
                          <!-- <div class="col-12">
                            <li class="dropdown-divider"></li>
                            <span><b>ข้อมูลยืนยันการสั่งซื้อ</b></span>
                            <div *ngIf="quotationItems.length > 0">
                              <div>
                                วันที่ยืนยันการสั่งซื้อ: 
                                <span *ngFor="let item of quotationItems; let i = index;">
                                  <span *ngIf="i==0">{{item?.quotation_info? item?.quotation_info[0]?.accepted_date: item.data?.quotation_info?.accepted_date | thai_date: "short":"date" | dash}}</span>
                                  <span *ngIf="i!=0">, {{item?.quotation_info? item?.quotation_info[0]?.accepted_date: item.data?.quotation_info?.accepted_date | thai_date: "short":"date" | dash}}</span>
                                </span>
                              </div>
                              <div>
                                เลขที่ยืนยันการสั่งซื้อ: 
                                <span *ngFor="let item of quotationItems; let i = index;">
                                  <span *ngIf="i==0">{{item?.quotation_info? item?.quotation_info[0]?.accepted_po_no: item.data?.quotation_info?.accepted_po_no}}</span>
                                  <span *ngIf="i!=0">, {{item?.quotation_info?item?.quotation_info[0]?.accepted_po_no: item.data?.quotation_info?.accepted_po_no}}</span>
                                </span>
                              </div>
                              <div>
                                เอกสารยืนยันการสั่งซื้อ: 
                                <span *ngFor="let item of quotationItems; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                                  <span style="cursor: pointer;" (click)="downloadFile({
                                    file_name: item?.quotation_info? item?.quotation_info[0]?.accepted_doc_name: item.data?.quotation_info?.accepted_doc_name,
                                    file_path: item?.quotation_info? item?.quotation_info[0]?.accepted_doc_path: item.data?.quotation_info?.accepted_doc_path
                                  })">
                                    {{item?.quotation_info? item?.quotation_info[0]?.accepted_doc_name: item.data?.quotation_info?.accepted_doc_name}}
                                  </span>
                                </span>
                              </div>
                            </div>
                            <div *ngIf="quotationItems.length == 0">
                              ไม่มีรายการ
                            </div>
                          </div> -->
                        </div>  
                      </div>

                      <div class="col-md-2 col-sm-2 mt-75">
                      </div>
                      
                      <div class="col-md-4 col-sm-4 mt-75">
                            <ul class="list-group cost-list">
                                <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                    <span class="cost-title mr-2">รวมเป็นเงิน </span>
                                    <span class="cost-value">{{count | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                </li>
                                <!-- <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between align-items-center">
                                    <span class="cost-title mr-2">ส่วนลดรวม </span>
                                    <span class="cost-value d-flex justify-content-between align-items-center">
                                        <input type="text" 
                                        placeholder="0.00"
                                        class="form-control text-right" 
                                        id="discount"
                                        (keyup)="onKeyup()"
                                        (click)="$event.target.select()"
                                        oninput="this.value = 
                                        this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                        formControlName="discount">
                                        <span class="ml-2">บาท</span>
                                    </span>
                                </li>
                                <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                    <span class="cost-title mr-2">ราคาสุทธิ </span>
                                    <span class="cost-value">{{count - util.convertStingToNumber(form.get('discount').value) | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                </li> -->
                                <li class="list-group-item border-0 p-50">
                                    <div class="row">
                                        <div class="col-7">
                                          <div class="d-inline"><span class="cost-title mr-1">ภาษีมูลค่าเพิ่ม</span></div>
                                          <select class="" id="tax" formControlName="tax" (change)="onKeyup()">
                                            <option value="0">n/a</option>
                                            <option value="7">7%</option>
                                            <option value="10">10%</option>
                                          </select>
                                        </div>

                                        <div class="col-5 text-right">
                                            <span class="cost-value">{{calculate.sumProductWithTax(count, form.value) | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                        </div>
                                    </div>

                                </li>
                                <li class="dropdown-divider"></li>
                                <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                    <span class="cost-title mr-2">รวมทั้งสิ้น </span>
                                    <span class="cost-value">{{(form.get('grand_total').value) | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                </li>
                                <li class="dropdown-divider"></li>
                                <li class="dropdown-divider mt-0"></li>
                            </ul>
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </form>
        </div>

        <app-invoice-po-item [poItems]="invoiceItem.invoiceItems" *ngIf="invoiceItem">
        </app-invoice-po-item>
      </div>

      <app-invoice-payment 
      *ngIf="pageLoad && (invoiceInfo?.doc_status_id =='WAITING_FOR_PAYMENT' || invoiceInfo?.doc_status_id =='COMPLETED')" 
      [invoice_info]="form.value">
      </app-invoice-payment>
      
      <app-invoice-history *ngIf="pageLoad">
      </app-invoice-history>
    </div>
</div>