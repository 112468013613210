import { Injectable } from "@angular/core";
import { 
  UploadService 
} from 'src/app/services';
import { 
  TransportreceiveInfoService,
  TransportreceiveContactService,
  TransportreceiveItemService,
  TransportReceiveItemFileService,
  TransportEditHistoryService
} from '.';
import { JobOrderCalibrationItemService, JobOrderInfoService } from "../job-orders";
import { 
  QuotationMitItemLog, 
  QuotationMitItemService 
} from "../quotations";

@Injectable({
   providedIn: "root"
})
export class CoreService {
  jobOrderInfo = null;
  constructor(
    private UploadService: UploadService,
    private JobOrderInfoService: JobOrderInfoService,
    private TransportreceiveInfoService: TransportreceiveInfoService,
    private TransportreceiveContactService: TransportreceiveContactService,
    private TransportreceiveItemService: TransportreceiveItemService,
    private TransportReceiveItemFileService: TransportReceiveItemFileService,
    private TransportEditHistoryService: TransportEditHistoryService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService, 
    private QuotationMitItemService: QuotationMitItemService,
    private QuotationMitItemLog: QuotationMitItemLog,
  ) {}

  async createTransport(
    data: any, 
    receiveItem?,
    edit_history?
    ){
    if(data){
        let transport_info = data;
        try {
          let transportResponse = await this.TransportreceiveInfoService.create({
            ...transport_info,
          });
          if (transportResponse.success) {
            if(edit_history){
              await this.TransportEditHistoryService
              .update({...edit_history, transport_receive_info_id: transportResponse.resultData.transport_receive_info_id})
            }

            if(data.customer_contact_ids){
              await this.saveCustomerContactList(data.customer_contact_ids, transportResponse);
            } 

            if(receiveItem?.receiveItems){
              await this.saveReceiveList(receiveItem.receiveItems, transportResponse);
            }   
            
            return {
              status: true,
              message: transportResponse.success
            }
          } else {
            if (transportResponse.resultCode === "40900") {
              return {
                status: false,
                message: 'codeDuplicate',
                error: transportResponse.error
              }
            } else {
              throw transportResponse.error;
            }
          }
        } catch (error) {
          return {
            status: false,
            message: error
          }
        }
      }
  }

  async editTransport(
    data: any, 
    deleteContactList?,
    receiveItem?
    ){
    if(data){

      let transport_info = data;
      try {
        let transportResponse = await this.TransportreceiveInfoService.update({
          ...transport_info,
        });
        if (transportResponse.success) {
          if(data.customer_contact_ids){
            await this.saveCustomerContactList(data.customer_contact_ids, transportResponse);
          } 
          if(deleteContactList){
            await Promise.all(deleteContactList.map(async (v)=>{
              let transportContactResponse = await this.TransportreceiveContactService
              .delete({
                 ...v
              });
              if (!transportContactResponse.success) {
                throw transportContactResponse.error;
              }
            }));
          }

          if(receiveItem?.receiveItems){
            await this.saveReceiveList(receiveItem.receiveItems, transportResponse);
          }   
          
          if(receiveItem?.delelteReceiveItems){
            await Promise.all(receiveItem.delelteReceiveItems.map(async (v)=>{

              if(v.transport_receive_item_id){
                if(v.files.length > 0){
                  await Promise.all(v.files.map(async (v)=>{
                    if(v.transport_receive_item_file_id){
                      await this.TransportReceiveItemFileService.delete(v);
                    }
                  }));
                }

                let transportReceiveItemResponse = await this.TransportreceiveItemService.delete({
                  ...v
               });
               if (!transportReceiveItemResponse.success) {
                 throw transportReceiveItemResponse.error;
               }
              }

              if(v.quotation_mit_item_info){
                let  QuotationMitItemResponse = await this.QuotationMitItemService
                .update({
                  ...v.quotation_mit_item_info,
            
                  transport_receive_info_id: "",
                  transport_receive_info_status: "",
                  transport_receive_info_doc_no: "",
                  transport_receive_item_id: "",
                  transport_receive_item_status: "",
                });

                if (!QuotationMitItemResponse.success) {
                  throw QuotationMitItemResponse.error;
                }
              }
            }));
          }     
          return {
            status: true,
            message: transportResponse.success
          }
        } else {
          if (transportResponse.resultCode === "40900") {
            return {
              status: false,
              message: 'codeDuplicate',
              error: transportResponse.error
            }
          } else {
            throw transportResponse.error;
          }
        }
      } catch (error) {
        return {
          status: false,
          message: error
        }
      }
    }
  }
  
  async saveCustomerContactList(contactList, transportResponse){
    await Promise.all(contactList.map(async (v)=>{
      if(v.transport_receive_contact_id){

      }else{
        let  transportContactResponse = await this.TransportreceiveContactService
        .create(
          {
            transport_receive_info_id: transportResponse.resultData.transport_receive_info_id,
            ...v
          });
        if (!transportContactResponse.success) {
          throw transportContactResponse.error;
        }
      }
    }));
  }

  async saveReceiveList(receiveList, transportResponse){

    await Promise.all(
      receiveList.map(async (value: any, index: number)=>{

      if(value.quotation_info_id){

        if(value.transport_receive_item_id){

          let transportItemResponse = await this.TransportreceiveItemService
          .update({
            ...value,
            transport_receive_id: transportResponse.resultData.transport_receive_info_id,
            order: index
          });

          if(transportItemResponse.success){

            if(value.quotation_mit_item_info.job_order_calibration_item_info.length > 0 && value.item_status_id == 'RETURNED'){

              value.quotation_mit_item_info.job_order_calibration_item_info.map(async item=>{
                let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService.update({
                  ...item,
                  item_status_id: 'RETURNED'
                });

                if(!jobOrderMitItemResponse.success){
                  throw transportItemResponse.error;
                }
              });
              let job_order_info_id = value.quotation_mit_item_info.job_order_calibration_item_info[0]?.job_order_info_id;

              this.jobOrderInfo = await this.JobOrderInfoService.getById({job_order_info_id: job_order_info_id});

              if(this.checkReturn(transportResponse)){
                if(this.jobOrderInfo.success){
                  let jobOrderInfoResponse = await this.JobOrderInfoService.update(
                    {
                      ...this.jobOrderInfo.resultData,
                      doc_status_id: 'RETURNED_SUCCESS'
                    }
                  )
                }
              }
            }

            if(value.files.length > 0){
              await this.saveFile(value.files, transportItemResponse);
            }

            if(value.quotation_mit_item_info){
              if(value.quotation_mit_item_info){
                let transport_item = Array.isArray(transportItemResponse.resultData)? transportItemResponse.resultData[0]: transportItemResponse.resultData;
                if(transport_item.item_status_id == 'CANCELED'){
                  if(transportResponse.resultData.transport_receive_info_id == value.quotation_mit_item_info.transport_receive_info_id){
                    if(!this.checkReturn(transportResponse)){
                      await this.updateMit(value, transportResponse, transportItemResponse, index);
                    }
                  }
                }else{
                  await this.updateMit(value, transportResponse, transportItemResponse, index);
                }
              }
            }
          }else{
            throw transportItemResponse.error;
          }
        }else{
          let transportItemResponse = await this.TransportreceiveItemService
          .create({
            ...value,
            transport_receive_id: transportResponse.resultData.transport_receive_info_id,
            order: index
          });

          if(transportItemResponse.success){
            if(value.files.length > 0){
              await this.saveFile(value.files, transportItemResponse);
            }

            if(value.quotation_mit_item_info){
              if(value.quotation_mit_item_info){
                let transport_item = Array.isArray(transportItemResponse.resultData)? transportItemResponse.resultData[0]: transportItemResponse.resultData;
                if(transport_item.item_status_id == 'CANCELED'){
                  if(transportResponse.resultData.transport_receive_info_id == value.quotation_mit_item_info.transport_receive_info_id){
                    if(!this.checkReturn(transportResponse)){
                      await this.updateMit(value, transportResponse, transportItemResponse, index);
                    }
                  }
                }else{
                  await this.updateMit(value, transportResponse, transportItemResponse, index);
                }
              }
            }
          }else{
            throw transportItemResponse.error;
          }
        }
      }else{

        if(value.transport_receive_item_id){
          let transportItemResponse = await this.TransportreceiveItemService
          .updateNoQT({
            transport_receive_id: transportResponse.resultData.transport_receive_info_id,
            ...value,
            order: index
          });

          if(transportItemResponse.success){
            if(value.files.length > 0){
              await this.saveFile(value.files, transportItemResponse);
            }
          }else{
            throw transportItemResponse.error;
          }
        }else{
          let  transportItemResponse = await this.TransportreceiveItemService
          .createNoQT({
            transport_receive_id: transportResponse.resultData.transport_receive_info_id,
            ...value,
            order: index
          });

          if(transportItemResponse.success){
            if(value.files.length > 0){
              await this.saveFile(value.files, transportItemResponse);
            }
          }else{
            throw transportItemResponse.error;
          }
        }
      }
    }));
  }

  async updateMit(value, transportResponse, transportItemResponse, index){
    let transportItem = 
    Array.isArray(transportItemResponse.resultData)? transportItemResponse.resultData[0]: transportItemResponse.resultData;

    let  QuotationMitItemResponse = await this.QuotationMitItemService
    .update({
      ...value.quotation_mit_item_info,
      serial_no: value.noqt_serial_no? value.noqt_serial_no: value.quotation_mit_item_info.serial_no,
      tag_no: value.noqt_tag_no? value.noqt_tag_no: value.quotation_mit_item_info.tag_no,
      marker: value.noqt_marker? value.noqt_marker: value.quotation_mit_item_info.marker,
      model: value.noqt_model? value.noqt_model: value.quotation_mit_item_info.model,

      transport_receive_info_id: transportResponse.resultData.transport_receive_info_id,
      transport_receive_info_status: transportResponse.resultData.doc_status_id,
      transport_receive_info_doc_no: transportResponse.resultData.doc_no,
      transport_receive_item_id: transportItem.transport_receive_item_id,
      transport_receive_item_status: transportItem.item_status_id,

      job_order_info_status: this.checkReturn(transportResponse)? 'RETURNED_SUCCESS': value.quotation_mit_item_info.job_order_info_status,
      job_order_calibration_item_status: this.checkReturn(transportResponse)? 'RETURNED': value.quotation_mit_item_info.job_order_calibration_item_status,

      order: index
    });

    if(QuotationMitItemResponse.success){
      if(value?.mit_item_logs?.length >0){
        this.saveLog(value?.mit_item_logs, QuotationMitItemResponse, '');
      }
    }else{
      throw QuotationMitItemResponse.error;
    }
  }

  checkReturn(transportResponse){
    return transportResponse.resultData.doc_status_id == 'RETURNED_SUCCESS'? true: false;
  }

  async saveLog(logList?, quotationMitItemResponse?, quotation_mit_item_detail_id?){
    await Promise.all(logList.map(async (log) =>{
      let quotationMitItemLog = await this.QuotationMitItemLog.create(
        {  
          ...log,
          quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
          quotation_mit_item_detail_id: quotation_mit_item_detail_id
        });
        if (!quotationMitItemLog.success) {
          throw quotationMitItemLog.error;
        }
    }));
  }

  async saveFile(files, transportResponse){
    await Promise.all(files.map(async v=>{
      if(!v.transport_receive_item_file_id){
        let uploadResponse = await this.UploadService.post(v, 'transport_receives');

        let transportFileResponse = await this.TransportReceiveItemFileService.create(
          {
            transport_receive_item_id: Array.isArray(transportResponse.resultData)? transportResponse?.resultData[0]?.transport_receive_item_id: transportResponse.resultData.transport_receive_item_id,
            file_name: uploadResponse.resultData.originalname,
            file_path: uploadResponse.resultData.path
          });
        if (!transportFileResponse.success) {
          throw transportResponse.error;
        }
      }
    }));
  }
}
