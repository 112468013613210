<div
  class="modal fade text-left"
  [id]="id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="myModalLabel1">ออกรายงาน</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">

        <form class="form" [formGroup]="form">
            <div class="row justify-content-md-center">
              <div class="col-md-12">
                <div class="form-body">
  
                  <form-group
                    [control]="form.controls.report_type_id"
                    [submitted]="submitted">
                    <label for="report_type_id">ประเภทรายงาน <span class="danger">*</span></label>
                    <div class="input-group">
                      <ng-select2
                        [options]="{
                          language: UtilsService.select2OptionsLanguage()
                        }"
                        id="report_type_id"
                        [data]="reportTypes"
                        class="w-100"
                        placeholder="ประเภทรายงาน"
                        formControlName="report_type_id"
                      >
                      </ng-select2>
                    </div>
                  </form-group>

                  <form-group
                  [control]="form.controls.sale_id"
                  [submitted]="submitted">
                  <label for="sale_id">พนักงานขาย </label>
                  <div class="input-group">
                    <ng-select2
                      formControlName="sale_id"
                      class="w-100"
                      [options]="
                      {
                        language: UtilsService.select2OptionsLanguage()
                      }"
                      [data]="employeeList"
                      placeholder="พนักงานขาย"
                      [allowClear]="true"
                    >
                    </ng-select2>
                  </div>
                  </form-group>
                
                  <form-group
                  [control]="form.controls.cuscode_prefix"
                  [submitted]="submitted">
                  <label for="cuscode_prefix">ปี</label>
                  <div class="input-group">
                    <ng-select2
                      [options]="{
                        language: UtilsService.select2OptionsLanguage()
                      }"
                      id="cuscode_prefix"
                      [data]="form.value.report_type_id == 'CALIBRATION_ITEM'? tranYears: years"
                      class="w-100"
                      placeholder="ปี"
                      formControlName="cuscode_prefix"
                    >
                    </ng-select2>
                  </div>
                  </form-group>

                  <!-- <div class="row" *ngIf="form.value.report_type_id=='CALIBRATION_ITEM'">
                    <div class="col-4">
                      <form-group
                  
                      [control]="form.controls.cuscode_prefix"
                      [submitted]="submitted">
                      <label for="cuscode_prefix">ใบเสนอราคาที่ออกเมื่อ</label>
                      <div class="input-group">
                        <ng-select2
                          [options]="{
                            language: UtilsService.select2OptionsLanguage()
                          }"
                          id="cuscode_prefix"
                          [data]="tranYears"
                          class="w-100"
                          placeholder="ปีลูกค้า"
                          formControlName="cuscode_prefix"
                        >
                        </ng-select2>
                      </div>
                      </form-group>
                    </div>
                    <div class="col-8">
                      <form-group
                  
                      [control]="form.controls.month"
                      [submitted]="submitted">
                      <label for="month">
                          <br>
                      </label>
                      <div class="input-group">
                        <ng-select2
                          [options]="{
                            language: UtilsService.select2OptionsLanguage()
                          }"
                          id="month"
                          [data]="months"
                          class="w-100"
                          placeholder="เดือนลูกค้า"
                          formControlName="month"
                        >
                        </ng-select2>
                      </div>
                      </form-group>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </form>
      </div>
      <div class="modal-footer">

        <button
        type="button"
        class="btn btn-warning"
        data-dismiss="modal"
        (click)="onClose()"
      >
        <i class="fa fa-times icon-left"></i> ยกเลิก
      </button>

      <button type="button" class="btn btn-primary" (click)="onSave()">
        <i class="fa fa-plus icon-left"></i>ยืนยัน
      </button>
      </div>
    </div>
  </div>
</div>
