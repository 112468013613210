import { Component, ElementRef, OnInit, ViewChild, EventEmitter,Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { QuotationMitItemsHistoryService } from 'src/app/services/quotations';

@Component({
  selector: 'app-delivery-items-history',
  templateUrl: './delivery-items-history.component.html',
  styleUrls: ['./delivery-items-history.component.css']
})
export class DeliveryItemsHistoryComponent implements OnInit {
  form: FormGroup;
  history = [];
  submitted: boolean = false;

  @Input('quotation_item') quotation_item: any;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private _fbd: FormBuilder,
    private QuotationMitItemsHistoryService: QuotationMitItemsHistoryService
  ) {}

  ngOnInit(): void {
    this.form = this._fbd.group({
      new_date:  ["", Validators.required],
    });

    this.QuotationMitItemsHistoryService
    .load(null, {quotation_mit_item_id: this.quotation_item.quotation_mit_item_id})
    .then(res=>{
      this.history = res.resultData || [];
      this.history.sort(function(a,b){
        if(a.created_at < b.created_at) return -1;
        if(a.created_at > b.created_at) return 1;
        return 0;
      });
      if(this.history.length == 0){
        this.form.get('new_date').setValue(moment(new Date()).format());
      }else{
        this.form.get('new_date').setValue(moment(this.history[this.history.length-1].new_date).format());
      }
    })
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submitted = false;
    let data = {
      ...this.form.value,
      previous_date: this.history.length > 0? this.history[this.history.length-1].new_date: undefined
    }
    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }

  close(){
    this.done.emit(false);
    this.closeModalBtn.nativeElement.click();
  }
}
