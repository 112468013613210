import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { 
  CustomerAddressService,
  ProductService, 
  CustomerContactService,
  CustomerInfoService,
  UtilsService,
} from 'src/app/services';
import moment from 'moment';
import { Subject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { 
  QuotationUtilsService, 
  QuotationService,
  DownloadFileService, CalculateFunctionService, NgSelect2Customer
} from '../../../../services/quotations';
import { docValiddateAfterOrEqualValidator } from 'src/app/validators'
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import { CoreService } from '../../../../services/quotations/core.service';
declare var $: any;

@Component({
  selector: 'app-quotation-create',
  templateUrl: './quotation-create.component.html',
  styleUrls: ['./quotation-create.component.css'],
})
export class QuotationCreateComponent implements OnInit {
  form: FormGroup;
  formMemo: FormGroup;
  formNoteIn: FormGroup;
  formNoteOut: FormGroup;

  submitted:boolean = false;
  pageLoad:boolean = false;
  disableSaveBtn:boolean = false;

  customer_id: string;
  customer_level_id: string;
  //array
  customerList: any = [];
  customerAddressList: any = [];
  customerContactList: any = [];
  subHeaderList: any = [];
  tmpCustomerAddressList: any = [];
  tmpCustomerContactList: any = [];
  quotationCode: any = [];
  documentStatus: any = [];
  productItemList: any = [];
  files: any = [];
  productList: any = [];
  noteMasterList = [];
  noteNormalList = [];
  notes = [];
  //number
  count:number = 0;
  //any
  status: any;
  productItemIndex = null;
  fileIndex = null;
  select2Options = null;
  userSub: Subscription;
  mitItems: any;

  subject: Subject<string> = new Subject();
  @Output()  done = new EventEmitter<any>();
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;

  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private route: ActivatedRoute,
    private CoreService: CoreService,
    private ProductService:ProductService,
    private QuotationService: QuotationService,
    private CustomerInfoService: CustomerInfoService,
    private DownloadFileService: DownloadFileService,
    private CustomerContactService: CustomerContactService,
    private CustomerAddressService: CustomerAddressService,

    public UtilsService: UtilsService,
    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
    public NgSelect2Customer: NgSelect2Customer
  ) {
    this.route.queryParams.subscribe(params => {
      this.customer_id = params['customer_id'];
    });
  }

  async ngOnInit() {
    this.pageLoad = false;
    this.createForm();
    this.userSub = this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      
      if(userInfo?.is_sales_supervisor || userInfo?.is_co_sales_supervisor || userInfo?.is_co_sales || userInfo?.is_sales_manager){
        this.select2Options = this.NgSelect2Customer.select2Options("?limit=10&exclude_customer_state_id='Free'&is_exclude_address=true");
      }else{
        this.select2Options = this.NgSelect2Customer.select2Options("?limit=10&exclude_customer_state_id='Free'&is_exclude_address=true&sales_assignment_info.user_id=" + userInfo.user_id);
      }

      if(this.customer_id){
        await this.CustomerInfoService.getById({customer_id: this.customer_id}).then(async res=>{

          this.customerList.push(
            {
              id: this.customer_id,
              text: `[${res.resultData?.customer_code || ''}] ${res.resultData?.company_name || ''} ${res.resultData?.branch?" (" + res.resultData?.branch + ")": ""}`,
              data: res.resultData
            }
          )
          this.form.get("customer_id").setValue(this.customer_id);

          await this.selectCustomer(this.customer_id);
          if(res.resultData.customer_address_info){
            this.selectAddressName(res.resultData.customer_address_info.customer_address_id);
          }
        })
      }

    });
    await this.QuotationService.getSubHeader(null).then((res) => {
      this.subHeaderList = res.resultData || [];
      this.subHeaderList = this.subHeaderList.map(function(elem) {
        return {
          id: elem.quotation_sub_header_id,
          text: `${elem.sub_header}`,
        }
      });
    });
    
    await this.ProductService.load(null, {
      order_by:'product_code:asc'
    }).then((res) => {
      this.productList = res.resultData || [];
    });

    this.pageLoad = true;
    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );
    setTimeout(() => {
      this.setDateTimePicker();
    }, 1000);
  }
  async selectCustomer(event){
    if(event){
      await this.setCustomerContact(event);
      await this.setCustomerAddress(event);
      await this.setDiscountPercentage(event);
    }
  }
  async setCustomerAddress(event){
    this.form.get('customer_address_id').enable();
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true",customer_id: event})
    .then(async (res) => {
      let customer = res.resultData.filter(item=> item.customer_address_type_id == 2) || [];

      if(customer.length > 0){
        address = this.utils.genCustomerAddressList(customer[0]);
      } 
    });

    await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
    .then(async (res) => {
      this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 2) || [];

      this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem=>{
        return this.utils.genCustomerAddressList(elem);
      })
    });

    let customerAddress = this.tmpCustomerAddressList;

    if(address){
      this.customerAddressList = [
        ...[address],
        ...customerAddress
      ];
    }else{
      this.customerAddressList = [
        ...customerAddress
      ];
    }
    // if(address){
    //   this.form.get('customer_address_id').setValue(address.id);
    // }else{
    //   this.form.get('customer_address_id').setValue(this.customerAddressList[0]?.id);
    // }
  }

  selectAddressName(event){
    let address = this.customerAddressList.find(v=> v.id === event);
    if(address){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.get('customer_address').setValue("");
    }
  }
  async setCustomerContact(event){
    this.form.get('customer_contact_ids').enable();
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname} ${elem.related_customer_address_info?.department?" (แผนก:" + elem.related_customer_address_info?.department + ")": ""}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );

    this.customerContactList = [
      ...customerContact
    ];
  }

  async setDiscountPercentage(event){
    await this.CustomerInfoService.getById({
      customer_id: event
    }).then(async (res) => {
      if(res.resultData){
        this.customer_level_id = res.resultData.customer_level_id;

        this.form.get("sale_id").setValue(this.utils.convertStingToNumber(res.resultData?.sales_assignment_info?.user_id || ""));
        this.form.get("discount_percentage").setValue(this.utils.convertStingToNumber(res.resultData?.discount_percentage_init || 0));
      }
    });
  }
  onKeyup(){
    this.subject.next();
  }
  setDateTimePicker(){
    $('#doc_date').on('dp.change', function(e){ 
      $('#doc_validdate').data("DateTimePicker").date(moment(new Date(e.date)).add(1, 'M'));
    });
  }
  createForm(){
    this.form = this.fbd.group({
      customer_id: ["", [Validators.required]],
      doc_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      doc_validdate:[moment(new Date()).add(1, 'M').format('YYYY-MM-DD'), [Validators.required]],
      doc_status_id: ["n/a", [Validators.required]],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      discount: [this.utils.transformDecimal(0)],
      grand_total: [0.00],
      tax: ["7"],
      customer_contact_ids: [[], [Validators.required]],
      quotation_sub_header_id: ["", [Validators.required]],
      discount_percentage: [""],
      quotation_product_items: this.fbd.array([]),
      sale_id: [""]
    },
    {validator: docValiddateAfterOrEqualValidator});
    this.form.get('customer_address_id').disable();
    this.form.get('customer_contact_ids').disable();
    this.productItemList = this.form.get('quotation_product_items') as FormArray;
  }
  getProductItemFormGroup(index): FormGroup {
    this.productItemList = this.form.get('quotation_product_items') as FormArray;
    const formGroup = this.form.controls[index] as FormGroup;
    return formGroup;
  }
  get productItemFormGroup() {
    return this.form.get('quotation_product_items') as FormArray;
  }
  createProductItem(data:any = {}): FormGroup {
    return this.fbd.group({
      product_id: this.fbd.control(data.product_item?.product_id),
      product_code: this.fbd.control(data.product_code),
      product_title: this.fbd.control(data.product_item?.product_title),
      product_description: this.fbd.control(data.product_item?.product_description),
      original_price: this.fbd.control(data.price),
      price: this.fbd.control(this.utils.transformDecimal(data.price)),
      item_type: this.fbd.control('product'),
      quantity: this.fbd.control(1),
      unit_id:this.fbd.control(data.product_item?.product_price_unit_info?.unit_info?.unit_id),
      unit_short_name_en:this.fbd.control(data.product_item?.product_price_unit_info?.unit_info?.unit_short_name_en),
      discount:this.fbd.control(this.utils.transformDecimal(this.form.value.discount_percentage)),
      order_by: this.fbd.control(null),
      related_job_order_info_id: this.fbd.control(data?.related_job_order_info_id),
      related_job_order_info: this.fbd.control(data?.related_job_order_info),
      index: this.fbd.control(null)
    });
  }
  createProductMitItem(total_price): FormGroup {
    return this.fbd.group({
      product_title: this.fbd.control('บริการสอบเทียบ'),
      product_description: this.fbd.control(""),
      price: this.fbd.control(total_price),
      item_type: this.fbd.control('calibration_service'),
      quantity: this.fbd.control(1),
      discount:this.fbd.control(0),
      order_by: this.fbd.control(null),
      index: this.fbd.control(null)
    });
  }
  createDescriptionItem(): FormGroup {
    return this.fbd.group({
      price: this.fbd.control(0),
      product_description: this.fbd.control(""),
      item_type: this.fbd.control('description'),
      discount:this.fbd.control(0),
      order_by: this.fbd.control(null),
    });
  }
  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;

    if (this.form.invalid || this.formNoteIn.invalid || this.formNoteOut.invalid || this.checkFileInvalid) {
      return;
    };
    this.submit();
  }
  save(){
    this.form.get('doc_status_id').setValue("WAITING_FOR_APPROVE");
    this.submitted = true;

    if (this.form.invalid 
      || this.formNoteIn.invalid || this.formNoteOut.invalid
      || this.productItemList.length === 0 || this.checkFileInvalid) {
      return;
    };
    this.submit();
  }
  async submit(){
    this.pageLoad = false;
    this.disableSaveBtn = true;
    let data = this.form.value;

    if(data.customer_contact_ids.length > 0){
      data.customer_contact_ids = data.customer_contact_ids.map(v=>{
        return {customer_contact_id: v};
      })
    }

    let quotationResponse = await this.CoreService.createQuotation(data, this.files, this.mitItems, this.formNoteIn.value.notes, this.formNoteOut.value.notes);
    if(quotationResponse.status){
      await this.router.navigateByUrl("/quotation", {
        state: {
          status: quotationResponse.message,
        },
      }).then(res=>{
        this.disableSaveBtn = false;
        this.pageLoad = true;
      })
    }else{
      if(quotationResponse.message == 'codeDuplicate'){
        this.status = quotationResponse.error;
      }else{
        this.status = quotationResponse.message;
      }
      this.disableSaveBtn = false;
      this.pageLoad = true;
    }
  }
  public get checkFileInvalid(): boolean {
    if(this.files.find(file=> this.UtilsService.checkFileSize(file) == false)){
      return true;
    }else{
      return false;
    }
  }
  submitProduct(event){
    let duplicateProduct = false;

    if(!duplicateProduct){
      this.productItemList.push(this.createProductItem(event));
    }
    this.sumCount();
  }

  select(i){
    this.productItemIndex = i;
  }
  async deleteProductItem(closeModalEl){
    this.productItemList.removeAt(this.productItemIndex);
    this.sumCount();
    closeModalEl.click();
  }
  sumCount(){
    this.count = 0;
    this.productItemList.value.map(product=>{
      if(product.price && product.item_type != 'description' && product.item_type != 'note') 
      this.count = this.count + this.calculate.sumProduct(product);
    })
    this.form.get('discount').setValue(this.utils.transformDecimal(this.form.get('discount').value));
    this.productItemList.controls.map(product=>{
      if (product.value.quantity == null && product.value.item_type != 'description' && product.value.item_type != 'note'){
        product.get('quantity').setValue(0);
      } 
      product.get('price').setValue(this.utils.transformDecimal(product.value.price));
      product.get('discount').setValue(this.utils.transformDecimal(product.value.discount));
    })
    let total = this.calculate.sumProductTotal(this.count, this.form.value);
    this.form.get('grand_total').setValue(total);
    this.addNumberRunning();
  }
  uploadFile(){
    document.getElementById("importFile").click();
  }
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  downloadFile(file){
    this.DownloadFileService.downloadFile(file);
  }
  removeFile(index){
    this.fileIndex = index; 
  }
  async deleteFile(closeModalEl){
    let index = this.fileIndex;
    this.files.splice(index, 1);
    closeModalEl.click();
  }
  addDescription(){
    this.productItemList.push(this.createDescriptionItem());
  }
  addNumberRunning(){
    let i = 1;
    this.productItemList.controls.map(item=>{
      let item_type = item.get('item_type').value;
      if (item_type != 'description' && item_type != 'note'){
        item.get('index').setValue(i);
        i++;
      }
    })
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.productItemList.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.productItemList.value, event.previousIndex, event.currentIndex);
    this.addNumberRunning();
  }
  onSubmitMitItem(event){
    this.mitItems = event;
    let checkMitItem = this.productItemList.value.find(
      value => value.item_type =='calibration_service'
    );
    if(this.mitItems.quotationMitItems.length > 0){
      var total_price = this.mitItems.quotationMitItems?.map(item => parseFloat(item.grand_total)).reduce((prev, next) => prev + next);
      if(checkMitItem){
        this.productItemList.controls.map(value =>{
          if(value.value == checkMitItem){
            value.get('price').setValue(total_price);
          }
        })
      }else{
        this.productItemList.insert(0,this.createProductMitItem(total_price));
      }
      this.sumCount();
    }else{
      if(checkMitItem){
        this.productItemList.controls.map((value, index) =>{
          if(value.value.quotation_item_id == checkMitItem.quotation_item_id) {
            this.productItemList.removeAt(index);
          }
        })
      }
      this.sumCount();
    }
  }

  addNoteIn(event){
    this.formNoteIn = event;
  }

  addNoteOut(event){
    this.formNoteOut = event;
  }
}