import { Component, OnInit } from "@angular/core";
import { Validators, FormBuilder, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  GETService,
  CustomerContactService,
  CustomerInfoService,
  UserService,
} from "src/app/services";
import { EmailValidator, AbsNumberValidator } from "src/app/validators";
import { Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { Observable } from "rxjs";

@Component({
  selector: "app-customer-edit",
  templateUrl: "./customer-edit.component.html",
  styleUrls: ["./customer-edit.component.css"],
})
export class CustomerEditComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  status: any;

  industryGroups = [];
  districts = [];
  amphures = [];
  provinces = [];

  customer = null;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private GETService: GETService,
    private CustomerContactService: CustomerContactService,
    private CustomerInfoService: CustomerInfoService,
    private UserService: UserService
  ) {
    this.setForm();
  }
  setForm() {
    this.form = this.formBuilder.group({
      isMaster: ["1"],
      customer_level_id: ["NEW", [Validators.required]],
      customer_state_id: ["LEAD", [Validators.required]],
      tax_id: ["", [AbsNumberValidator(), Validators.minLength(13), Validators.maxLength(13)]],
      company_name: ["", [Validators.required]],
      branch: ["", [Validators.required]],
      department: ["", [Validators.required]],
      industry_id: ["", [Validators.required]],
      customer_source: ["", [Validators.required]],
      address: ["", [Validators.required]],
      district_id: [""],
      amphure_id: [""],
      province_id: [""],
      geography_id: [""],

      phonenumber_1: ["", [Validators.required]],
      phonenumber_2: [""],
      email_1: ["", [EmailValidator()]],
      email_2: ["", [EmailValidator()]],

      firstname: ["", [Validators.required]],
      lastname: [""],
      phonenumber: ["", [Validators.required]],
      line_id: [""],
      nickname: [""],
      email: ["", [Validators.required, EmailValidator()]],
      fax_1: [""],

      emp_code: [""],
      sales_assignment_id: [""],
      sale_name: [""],
    });
  }
  async setData() {
    this.form.controls.customer_level_id.setValue(
      this.customer.customer_level_id
    );
    this.form.controls.customer_state_id.setValue(
      this.customer.customer_state_id
    );
    this.form.controls.tax_id.setValue(this.customer.tax_id);
    this.form.controls.company_name.setValue(this.customer.company_name);
    this.form.controls.branch.setValue(this.customer.branch);
    this.form.controls.department.setValue(this.customer.department);
    this.form.controls.industry_id.setValue(this.customer.industry_id);
    this.form.controls.customer_source.setValue(this.customer.customer_source);
    this.form.controls.address.setValue(this.customer.address);

    // master contact
    this.form.controls.firstname.setValue(
      this.customer.master_contact.firstname
    );
    this.form.controls.lastname.setValue(this.customer.master_contact.lastname);
    this.form.controls.phonenumber.setValue(
      this.customer.master_contact.phonenumber
    );
    this.form.controls.line_id.setValue(this.customer.master_contact.line_id);
    this.form.controls.nickname.setValue(this.customer.master_contact.nickname);
    this.form.controls.email.setValue(this.customer.master_contact.email);

    // sales assignment
    this.form.controls.emp_code.setValue(
      this.customer.sales_assignment_info.emp_code
    );
    this.form.controls.sales_assignment_id.setValue(
      this.customer.sales_assignment_info.user_id
    );

    // set province

    let result = await this.GETService.amphures({
      province_id: this.customer.province_id,
    });
    this.amphures = result.resultData;

    result = await this.GETService.districts({
      amphure_id: this.customer.amphure_id,
    });
    this.districts = result.resultData;

    let district = this.districts.filter(
      (v) => String(v.district_id) === String(this.customer.district_id)
    )[0];

    setTimeout(() => {
      this.form.controls.province_id.setValue(this.customer.province_id);
      this.form.controls.amphure_id.setValue(this.customer.amphure_id);
      this.form.controls.district_id.setValue(this.customer.district_id);
      this.form.controls.geography_id.setValue(district.zip_code || "");
    });
  }
  async ngOnInit() {
    let customer_id = this.route.snapshot.paramMap.get("customer_id");
    let initApis = [];
    initApis.push(
      this.CustomerInfoService.getById({ customer_id }).then((result) => {
        this.customer = result.resultData || {};
      })
    );

    initApis.push(
      this.GETService.industryGroups().then((result) => {
        this.industryGroups = result.resultData;
      })
    );

    initApis.push(
      this.GETService.provinces().then((result) => {
        this.provinces = result.resultData;
      })
    );

    await Promise.all(initApis);
    this.setData().then(() => {
      this.queryEmployee(this.customer.sales_assignment_info.user_id);

      this.form.controls.province_id.valueChanges.subscribe((province_id) => {
        this.districts = [];
        this.amphures = [];
        this.form.controls.amphure_id.setValue("");
        this.form.controls.district_id.setValue("");

        this.GETService.amphures({
          province_id: province_id,
        }).then((result) => {
          this.amphures = result.resultData;
        });
      });

      this.form.controls.amphure_id.valueChanges.subscribe((amphure_id) => {
        this.districts = [];
        this.form.controls.district_id.setValue("");
        this.GETService.districts({ amphure_id: amphure_id }).then((result) => {
          this.districts = result.resultData;
        });
      });

      this.form.controls.district_id.valueChanges.subscribe((district_id) => {
        let district = this.districts.filter(
          (v) => v.district_id === district_id
        )[0];
        this.form.controls.geography_id.setValue(district.zip_code || "");
      });
    });
  }

  async submit() {
    this.submitted = true;
    // console.log(this.form.value);
    if (this.form.invalid) {
      return;
    }
    let customer_id = this.customer.customer_id;
    let customerResponse = await this.CustomerInfoService.update({
      ...this.form.value,
      province_id: Number(this.form.value.province_id),
      amphure_id: Number(this.form.value.amphure_id),
      district_id: Number(this.form.value.district_id),
      customer_id,
      customerActivities: {
        title: "Test",
        description: "",
        customer_activity_type_id: "UPDATED_CUSTOMER_INFO",
      },
    });
    if (customerResponse.success) {
      let customerContactResponse = await this.CustomerContactService.update({
        customer_id,
        customer_contact_id: this.customer.master_contact.customer_contact_id,
        ...this.form.value,
      });

      // let salesAssignmentResponse = await this.SalesAssignmentService.update({
      //   customer_id,
      //   sales_assignment_id: this.customer.sales_assignment_info
      //     .sales_assignment_id,
      //   user_id: this.form.value.sales_assignment_id,
      // });

      this.router.navigateByUrl("/customer", {
        state: {
          status: customerContactResponse.success,
        },
      });
    }
  }

  queryEmployee(user_id) {
    this.form.controls.sale_name.setValue("");
    this.form.controls.sales_assignment_id.setValue("");
    if (user_id) {
      this.form.controls.emp_code.setValue(user_id);

      this.UserService.getById({ user_id }).then((response) => {
        if (response.success) {
          let userInfo = response.resultData;
          if (userInfo) {
            this.form.controls.sale_name.setValue(
              `${userInfo?.firstname_th} ${userInfo?.lastname_th}`
            );
            this.form.controls.sales_assignment_id.setValue(
              // empInfo.user_info?.user_id
              user_id
            );
          }
        }
      });
    }
  }
}
