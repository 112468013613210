<div class="card p-0" [formGroup]="form">
    <div class="card-content collapse show">
        <div class="card-body p-0">
            <p class="font-weight-bold">รายชื่อผู้เข้าร่วมอบรม <span class="danger" *ngIf="(jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM') && pageType != 'show'">*</span></p>

            <div class="table-responsive">
                <table class="table table-bordered" formArrayName="trainings">
                  <thead>
                    <tr>
                        <th class="text-center" style="width: 7%;">ลำดับ</th>
                        <th>ชื่อ - นามสกุล <span class="danger" *ngIf="(jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM') && pageType != 'show'">*</span></th>
                        <th class="text-center" *ngIf="jobOrderInfo?.doc_status_id!='WAITING_FOR_CONFIRM'">สถานะ</th>
                        <th class="text-center py-0 align-middle w-25" *ngIf="pageType !='show'">
                            <span class="mr-1">action</span>
                            <button
                            *ngIf="trainingList.length === 0 && (jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM')"
                            type="button"
                            class="btn btn-sm btn-icon btn-circle btn-success"
                            (click)="addTraining()">
                            <i class="fa fa-plus fa-2x"></i>
                          </button>
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of trainingItemFormGroup.controls; let i = index;" [formGroupName]="i">
                          <td class="text-center">
                              {{i+1}}
                          </td>
                          <td>
                            <div *ngIf="pageType !='show'">
                              <form-group [control]="item.controls.user_id" [submitted]="submitted">    
                                <ng-select2 [data]="employeeList"
                                    [placeholder]="'ชื่อ - นามสกุล'"
                                    formControlName="user_id"
                                    [disabled]="pageType =='show'" 
                                    [width]="300">
                                  </ng-select2>
                              </form-group>
                            </div>
                            <div *ngIf="pageType =='show'">
                              {{getEmplyeeName(item.value.user_id)}}
                            </div>
                          </td>
                          <td class="text-center" *ngIf="jobOrderInfo?.doc_status_id!='WAITING_FOR_CONFIRM'">
                            <div [ngClass]="{'text-warning': item.value.status == 'WAITING',
                            'text-danger':item.value.status == 'FAIL' ,
                            'text-success':item.value.status == 'PASS' }">
                              {{item.value.status =='PASS'? 'สำเร็จ':'ไม่สำเร็จ'}}
                            </div>
                          </td>
                          <td class="text-center text-nowrap" *ngIf="pageType !='show'">
                            <button
                                (click)="setStatus('PASS', i)"
                                *ngIf="jobOrderInfo?.doc_status_id === 'CONFIRMED'"
                                type="button"
                                class="btn mr-1 mb-1 btn-success btn-sm"
                                > <i class="fa fa-check-circle-o icon-left"></i> สำเร็จ
                            </button>
                            <button
                                (click)="setStatus('FAIL', i)"
                                *ngIf="jobOrderInfo?.doc_status_id === 'CONFIRMED'"
                                type="button"
                                class="btn mr-1 mb-1 btn-danger btn-sm"
                            > <i class="fa fa-times-circle-o icon-left"></i> ไม่สำเร็จ
                            </button>
                              <button
                              *ngIf="(jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM')"
                              type="button"
                              class="btn round btn-icon btn-danger mr-1 mb-1"
                              (click)="removeTraining(i, item);"
                            >
                              <i class="fa fa-minus fa-2x"></i>
                            </button>
                            <button
                              *ngIf="trainingList.length === i + 1 && (jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM')"
                              type="button"
                              class="btn round btn-icon btn-success mr-1 mb-1"
                              (click)="addTraining()"
                            >
                              <i class="fa fa-plus fa-2x"></i>
                            </button>
                          </td>
                      </tr>
                      <tr *ngIf="trainingList.length === 0">
                          <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                  </tbody>
                  <span class="pl-1 text-danger w-100" style="white-space: nowrap;" *ngIf="(submitted && !canSaveTraining) && (jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM' || jobOrderInfo?.doc_status_id === 'CONFIRMED')">
                    โปรดระบุ
                  </span>
                  <span class="pl-1 text-danger w-100" style="white-space: nowrap;" *ngIf="(submitted && !canCompleted) && jobOrderInfo?.doc_status_id === 'CONFIRMED'">
                    โปรดบันทึกรายการ
                  </span>
                </table>
              </div>
        </div>
        
    </div>
</div>

