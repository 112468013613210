<div
  class="modal fade text-left"
  [id]="id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="myModalLabel1">{{verify? 'ตรวจสอบ':'แก้ไข'}} Master Booking</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="pageLoad">

        <form class="form" [formGroup]="form">
            <div class="row justify-content-md-center">
              <div class="col-md-12">
                <div class="form-body">
                    <form-group [control]="form.controls.customer_id" [submitted]="submitted">
                        <label for="customer_id">ชื่อหน่วยงาน/บริษัท </label>
                        <div class="input-group">
                            <ng-select2 formControlName="customer_id" class="w-100" [data]="customerList" disabled="true"
                                placeholder="ชื่อหน่วยงาน/บริษัท" [allowClear]="true">
                            </ng-select2>
                        </div>
                    </form-group>
                

                    <form-group [control]="form.controls.location" [submitted]="submitted">
                        <label for="location">
                            สถานที่ <span class="danger" *ngIf="!verify">*</span>
                        </label>
                        <input type="text" id="location" class="form-control" placeholder="สถานที่"
                            formControlName="location" />
                    </form-group>
            

                    <form-group
                    [control]="form.controls.transport_type_id"
                    [submitted]="submitted"
                    >
                      <label for="transport_type_id">
                        ประเภทรถ
                      </label>
                      <ng-select2
                        [options]="
                        {
                          language: UtilsService.select2OptionsLanguage()
                        }"
                        [data]="transportTypes"
                        class="w-100"
                        id="transport_type_id"
                        formControlName="transport_type_id"
                        placeholder="ประเภทรถ"
                      >
                      </ng-select2>
                    </form-group>

                    <form-group
                    [control]="form.controls.messenger_id"
                    [submitted]="submitted"
                    >
                      <label for="messenger_id">
                        Messenger
                      </label>
                      <ng-select2
                        [options]="
                        {
                          language: UtilsService.select2OptionsLanguage()
                        }"
                        [data]="transportContactList"
                        class="w-100"
                        id="messenger_id"
                        formControlName="messenger_id"
                        placeholder="Messenger"
                      >
                      </ng-select2>
                    </form-group>

                    <form-group [control]="form.controls.booking_date" [submitted]="submitted">
                        <label for="booking_date">
                            วันที่รับส่งงาน <span class="danger" >*</span>
                        </label>
                        <div class="input-group mt-0">
                            <input date-picker type="text" id="booking_date" 
                            [date]="form.value.booking_date"
                            [max-date]="undefined"
                            *ngIf="form.value.booking_date"
                             class="form-control"
                                formControlName="booking_date" />
                            <div class="input-group-append" for="doc_date">
                                <span class="input-group-text"><i class="feather icon-calendar"></i></span>
                            </div>
                        </div>
                    </form-group>

                    <form-group [control]="form.controls.booking_time" [submitted]="submitted">
                        <label for="booking_time">
                            เวลา
                        </label>
                        <input type="text" id="booking_time" class="form-control" placeholder="เวลา" formControlName="booking_time" />
                    </form-group>

                                    
                    <form-group [control]="form.controls.extra_message" [submitted]="submitted">
                        <label for="extra_message">
                            ข้อความพิเศษถึงจัดส่ง
                        </label>
                        <input type="text" id="extra_message" class="form-control" placeholder="ข้อความพิเศษถึงจัดส่ง"
                            formControlName="extra_message" />
                    </form-group>
                
                    <form-group [control]="form.controls.contact_name" [submitted]="submitted">
                        <label for="contact_name">
                            ชื่อผู้ติดต่อ <span class="danger" *ngIf="!verify">*</span>
                        </label>
                        <input type="text" id="contact_name" class="form-control" placeholder="ชื่อผู้ติดต่อ"
                            formControlName="contact_name" />
                    </form-group>
                
                    <form-group [control]="form.controls.contact_phonenumber" [submitted]="submitted">
                        <label for="contact_phonenumber">
                            เบอร์ผู้ติดต่อ <span class="danger" *ngIf="!verify">*</span>
                        </label>
                        <input type="text" id="contact_phonenumber" class="form-control" placeholder="เบอร์ผู้ติดต่อ"
                            formControlName="contact_phonenumber" />
                    </form-group>
                
                
                </div>
              </div>
            </div>
          </form>
      </div>

      <div class="modal-footer">

        <button
        type="button"
        class="btn btn-warning"
        data-dismiss="modal"
        (click)="onClose()"
      >
        <i class="fa fa-times icon-left"></i> ยกเลิก
      </button>

      <button type="button" class="btn btn-primary" (click)="onSave()">
        <i class="fa fa-plus icon-left"></i>ยืนยัน
      </button>
      </div>
    </div>
  </div>
</div>
