import { Subscription } from "rxjs";
import { Router, ActivationEnd } from "@angular/router";
import { Component } from "@angular/core";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "crm-system";
  route: Subscription;
  constructor(private router: Router) {}
  ngOnInit() {
    this.route = this.router.events.subscribe((route) => {
      if (route instanceof ActivationEnd) {
        if (route.snapshot.routeConfig.data?.title) {
          document.title = `${route.snapshot.routeConfig.data.title} - ${environment.app.title}`;
        }
      }
    });
  }
  getTitle(title) {
    let newTitle = title.charAt(0).toUpperCase() + title.slice(1);
    newTitle = newTitle.replace(/-/, " ");
    return newTitle;
  }

  ngOnDestroy() {
    this.route.unsubscribe();
  }
}
