import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "src/app/services/users.service";
import { environment } from "../../../environments/environment";
import { Store, Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { GetUserInfo, UpdateUserInfo } from "src/app/store/user/user.action";
import { Observable } from "rxjs";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
  providers: [UserService],
})
export class NavbarComponent implements OnInit {
  name: string;
  title = environment?.app?.title;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(private router: Router, private store: Store) {}

  ngOnInit(): void {
    // this.me();
  }
  me() {
    this.store.dispatch(new GetUserInfo());
  }
  logout() {
    this.store.dispatch(new UpdateUserInfo(null));
    localStorage.removeItem("token");
    this.router.navigateByUrl("/login");
  }
}
