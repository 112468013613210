<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">สร้างใบสั่งงาน</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/work-order']">ใบสั่งงาน </a>
                </li>
                <li class="breadcrumb-item active">สร้างใบสั่งงาน</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <a href="#" [routerLink]="['/work-order']" class="btn btn-warning mr-1">
                <i class="fa fa-times icon-left"></i> ยกเลิก
            </a>

            <button class="btn btn-primary mr-1" (click)="saveAsDraft()" [disabled]="disableSaveBtn">
                <i class="fa fa-save icon-left"></i> บันทึกแบบร่าง
            </button>

            <button class="btn btn-primary" (click)="save()" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึกและส่งตรวจสอบ
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="alert alert-danger" role="alert" *ngIf="submitted && (form.invalid || checkFileInvalid || jobOrderMitItem?.jobOrderItems?.length == 0 || jobOrderMitItem == null)">
          เกิดข้อผิดพลาด! กรุณากรอกข้อมูลให้ครบถ้วน
        </div>
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="pageLoad"></loading>

        <div class="card" [hidden]="pageLoad">
          <div class="card-header">
              <h4 class="card-title mt-1">สร้างใบสั่งงาน ({{template}})</h4>
              <div class="heading-elements">
              </div>
          </div>

          <div class="card-content collapse show">
              <form autocomplete="off" class="form" [formGroup]="form">
                  <div class="card-body">
                    <div class="form-body">
                      <h4 class="badge btn-blue badge-customer-lvl" *ngIf="customer_info">
                        {{ customer_info.customer_level_id | dash }}
                      </h4>
                        <div class="row">
                          <div class="col-xl-3 col-lg-6 col-md-12">
                            <form-group
                              [control]="form.controls.customer_id"
                              [submitted]="submitted"
                              >
                                <label for="customer_id">
                                  ชื่อลูกค้า
                                </label>
                                <input
                                  type="text"
                                  id="customer_name"
                                  class="form-control"
                                  placeholder="ชื่อลูกค้า"
                                  formControlName="customer_name"
                                  readonly
                                />
                            </form-group>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.customer_address_id"
                            [submitted]="submitted"
                            >
                              <label for="customer_address_id">
                                ออกเอกสารในนาม <span class="danger">*</span>
                              </label>
                              <ng-select2
                              [options]="
                              {
                                templateResult: NgSelect2Customer?.templateAddressResult,
                                language: UtilsService.select2OptionsLanguage()
                              }"
                                [data]="customerAddressList"
                                class="w-100"
                                id="customer_address_id"
                                placeholder="ออกเอกสารในนาม"
                                formControlName="customer_address_id"
                                (valueChanged)="selectAddressName($event)"
                              >
                              </ng-select2>
                          </form-group>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_date"
                          [submitted]="submitted">
                          <label for="doc_date">
                              วันที่ออก <span class="danger">*</span>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              id="doc_date"
                              placeholder="วันที่ออก"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.doc_date"
                              formControlName="doc_date"
                              />
                              <div
                              class="input-group-append"
                              for="doc_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                      </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <!-- <form-group
                          [control]="form.controls.doc_no"
                          [submitted]="submitted">
                          <label for="doc_no">
                              เลขที่เอกสาร
                          </label>
                          <input
                              type="text"
                              id="doc_no"
                              class="form-control"
                              placeholder="เลขที่เอกสาร"
                              formControlName="doc_no"
                              readonly
                          />
                          </form-group> -->
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <p class="font-weight-bold"> ที่อยู่ในการติดต่อลูกค้า</p>
                          <form-group
                              [control]="form.controls.customer_address"
                              [submitted]="submitted"
                              >
                              <label for="customer_address">
                                  ที่อยู่
                              </label>
                              <input
                                  type="text"
                                  id="customer_address"
                                  class="form-control"
                                  placeholder="ที่อยู่"
                                  formControlName="customer_address"
                                  readonly
                              />
                            </form-group>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <p>
                            <br>
                          </p>
                          <form-group
                            [control]="form.controls.customer_contact_ids"
                            [submitted]="submitted">
                              <label for="customer_contact_ids">
                                ผู้ติดต่อ <span class="danger">*</span>
                              </label>
                              <ng-select2
                                  [data]="customerContactList"

                                  [options]="{
                                    templateResult: NgSelect2Customer?.templateContactResult,
                                    multiple: true,
                                    maximumSelectionLength: 1,
                                    language: UtilsService.select2OptionsLanguage()
                                  }"
                                  class="w-100"
                                  id="customer_contact_ids"
                                  placeholder="ผู้ติดต่อ"
                                  formControlName="customer_contact_ids"
                                  >
                              </ng-select2>
                          </form-group>

                        </div>

                      </div>

                      <div class="row" *ngIf="template=='ICS' || template=='ECS'">
                        <div class="col-lg-6 col-md-12">
                          <p class="font-weight-bold"> สถานที่ในการสอบเทียบ</p>
                          <form-group
                          [control]="form.controls.calibration_address_id"
                          [submitted]="submitted">
                          <label for="calibration_address_id">
                              ที่อยู่ <span class="danger">*</span>
                          </label>
                            <ng-select2
                            [options]="
                            {
                              templateResult: NgSelect2Customer?.templateAddressResult,
                              language: UtilsService.select2OptionsLanguage()
                            }"
                              [data]="calibrationAddressList"
                              class="w-100"
                              id="calibration_address_id"
                              placeholder="ที่อยู่"
                              formControlName="calibration_address_id"
                            >
                            </ng-select2>
                          </form-group>
                    
                        </div>
                        
                        <div class="col-lg-6 col-md-12" *ngIf="template=='ECS'">
                          <p class="font-weight-bold"> ที่อยู่ในการ ออกใบรับรอง </p>
                          <form-group
                              [control]="form.controls.report_address_id"
                              [submitted]="submitted"
                              >
                                <label for="report_address_id">
                                  ที่อยู่ <span class="danger">*</span>
                                </label>
                                <ng-select2
                                [options]="
                                {
                                  templateResult: NgSelect2Customer?.templateAddressResult,
                                  language: UtilsService.select2OptionsLanguage()
                                }"
                                  [data]="reportAddressList"
                                  class="w-100"
                                  id="report_address_id"
                                  placeholder="ที่อยู่"
                                  formControlName="report_address_id"
                                >
                                </ng-select2>
                          </form-group>
                        </div>


                        <div class="col-lg-3 col-md-12" *ngIf="template=='ICS'">
                          <p>
                            <br>
                          </p>
                          <form-group
                          [control]="form.controls.no_of_people"
                          [submitted]="submitted">
                          <label for="no_of_people">
                              จำนวนคน <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="จำนวนคน"
                            formControlName="no_of_people"
                            id="no_of_people"
                            />
                          </form-group>

                        </div>

                        <div class="col-lg-3 col-md-12" *ngIf="template=='ICS'">
                          <p>
                            <br>
                          </p>
                          <form-group
                          [control]="form.controls.no_of_day"
                          [submitted]="submitted">
                          <label for="no_of_day">
                              จำนวนวัน <span class="danger">*</span>
                          </label>
                          <input
                          type="text"
                          class="form-control"
                          placeholder="จำนวนวัน"
                          formControlName="no_of_day"
                          id="no_of_day"
                            />
                          </form-group>

                        </div>

                      </div>

                      <ng-container [ngSwitch]="template">
                        <ng-container *ngSwitchCase="'ECS'">
                          <div class="row">
  
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ส่งใบรายงานผล</p>
                              <form-group
                              [control]="form.controls.send_report_address_id"
                              [submitted]="submitted"
                                >
                                  <label for="send_report_address_id">
                                    ที่อยู่ <span class="danger">*</span>
                                  </label> 
                                  <ng-select2
                                  [options]="
                                  {
                                    templateResult: NgSelect2Customer?.templateAddressResult,
                                    language: UtilsService.select2OptionsLanguage()
                                  }"
                                    [data]="sendReportAddressList"
                                    class="w-100"
                                    id="send_report_address_id"
                                    placeholder="ที่อยู่"
                                    formControlName="send_report_address_id"
                                  >
                                </ng-select2>
                              </form-group>
    
                            </div>
    
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ในการ เก็บค่าบริการ</p>
                              <form-group
                                [control]="form.controls.charge_address_id"
                                [submitted]="submitted">
                                    <label for="charge_address_id">
                                      ที่อยู่ <span class="danger">*</span>
                                    </label>
                                    <ng-select2
                                    [options]="
                                    {
                                      templateResult: NgSelect2Customer?.templateAddressResult,
                                      language: UtilsService.select2OptionsLanguage()
                                    }"
                                      [data]="chargeReportAddressList"
                                      class="w-100"
                                      id="charge_address_id"
                                      placeholder="ที่อยู่"
                                      formControlName="charge_address_id"
                                    >
                                    </ng-select2>
                                </form-group>
                            </div>
                          </div>
    
                          <div class="row">           
                            <div class="col-lg-3 col-md-12">
                              <form-group
                              [control]="form.controls.credit_term_day"
                              [submitted]="submitted"
                                >
                                  <label for="credit_term_day">
                                    เครดิต (วัน)
                                  </label> 
                                  <input type="text"    
                                  id="credit_term_day"
                                  class="form-control"
                                  placeholder="เครดิต (วัน)" 
                                  formControlName="credit_term_day"
                                  readonly>
                              </form-group>
    
                            </div>
    
                          </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ในการ ออกใบรับรอง </p>
                              <form-group
                                  [control]="form.controls.report_address_id"
                                  [submitted]="submitted"
                                  >
                                    <label for="report_address_id">
                                      ที่อยู่ <span class="danger">*</span>
                                    </label>
                                    <ng-select2
                                    [options]="
                                    {
                                      templateResult: NgSelect2Customer?.templateAddressResult,
                                      language: UtilsService.select2OptionsLanguage()
                                    }"
                                      [data]="reportAddressList"
                                      class="w-100"
                                      id="report_address_id"
                                      placeholder="ที่อยู่"
                                      formControlName="report_address_id"
                                    >
                                    </ng-select2>
                                </form-group>
                            </div>
    
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ส่งใบรายงานผล</p>
                              <form-group
                              [control]="form.controls.send_report_address_id"
                              [submitted]="submitted"
                                >
                                  <label for="send_report_address_id">
                                    ที่อยู่ <span class="danger">*</span>
                                  </label> 
                                  <ng-select2
                                  [options]="
                                  {
                                    templateResult: NgSelect2Customer?.templateAddressResult,
                                    language: UtilsService.select2OptionsLanguage()
                                  }"
                                      [data]="sendReportAddressList"
                                      class="w-100"
                                      id="send_report_address_id"
                                      placeholder="ที่อยู่"
                                      formControlName="send_report_address_id"
                                    >
                                  </ng-select2>
                              </form-group>
    
                            </div>
    
                          </div>
    
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ในการ เก็บค่าบริการ</p>
                              <form-group
                                [control]="form.controls.charge_address_id"
                                [submitted]="submitted">
                                    <label for="charge_address_id">
                                      ที่อยู่ <span class="danger">*</span>
                                    </label>
                                    <ng-select2
                                    [options]="
                                    {
                                      templateResult: NgSelect2Customer?.templateAddressResult,
                                      language: UtilsService.select2OptionsLanguage()
                                    }"
                                      [data]="chargeReportAddressList"
                                      class="w-100"
                                      id="charge_address_id"
                                      placeholder="ที่อยู่"
                                      formControlName="charge_address_id"
                                    >
                                    </ng-select2>
                                </form-group>
                            </div>
    
                            <div class="col-lg-3 col-md-12">
                              <p class="font-weight-bold">
                                <br>  
                              </p>
                              <form-group
                              [control]="form.controls.credit_term_day"
                              [submitted]="submitted"
                                >
                                  <label for="credit_term_day">
                                    เครดิต (วัน)
                                  </label> 
                                  <input type="text"    
                                  id="credit_term_day"
                                  class="form-control"
                                  placeholder="เครดิต (วัน)" 
                                  formControlName="credit_term_day"
                                  readonly>
                              </form-group>
    
                            </div>
    
                          </div>
                        </ng-container>
                      </ng-container>
                      
                      <div  class="dropdown-divider mb-2">
                      </div>
                      
                      <div class="row">
                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"> ข้อมูลการสอบเทียบ</p>
                          <form-group
                            [control]="form.controls.pickup_date"
                            [submitted]="submitted">
                            <label for="pickup_date">
                              <ng-container [ngSwitch]="template">
                                <ng-container *ngSwitchCase="'ICL'">วันที่รับเครื่องมือ</ng-container>
                                <ng-container *ngSwitchCase="'ECL'">วันที่รับเครื่องมือ</ng-container>
                                <ng-container *ngSwitchDefault>วันที่เข้าบริการ</ng-container>
                              </ng-container>
      
                            </label>
                            <div class="input-group mt-0">
                                <input
                                date-picker
                                type="text"
                                id="pickup_date"
                                placeholder="วันที่รับเครื่องมือ"
                                [max-date]="undefined"
                                class="form-control"
                                *ngIf="form.value.pickup_date"
                                [date]="form.value.pickup_date"
                                formControlName="pickup_date"
                                [readonly]='!editPickupDate'
                                />
                                <div
                                class="input-group-append"
                                for="pickup_date"
                                >
                                <span class="input-group-text"
                                    ><i class="feather icon-calendar"></i
                                ></span>
                                </div>
                            </div>
                          </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12"  [hidden]="template!='ICS' && template!='ECS'">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                            [control]="form.controls.service_time"
                            [submitted]="submitted">
                            <label for="service_time">
                              เวลาเข้าบริการ
      
                            </label>
                            <div class="input-group mt-0">
                              <input
                                  time-picker
                                  type="text"
                                  placeholder="เวลาเข้าบริการ"
                                  id="service_time"
                                  #service_time
                                  class="form-control"
                                  formControlName="service_time"
                              />
                              <div class="input-group-append border-left-0 pointer" (click)="service_time.click()">
                                  <span class="input-group-text icon-input input-2-box-left"><i class="fa fa-clock-o" aria-hidden="true"></i></span>
                              </div>
                            </div>
                          </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                          [control]="form.controls.return_date"
                          [submitted]="submitted">
                          <label for="return_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'ICL'">วันที่ส่งคืนเครื่องมือ</ng-container>
                              <ng-container *ngSwitchCase="'ECL'">วันที่ส่งคืนเครื่องมือ</ng-container>
                              <ng-container *ngSwitchDefault>วันที่เสร็จสิ้น</ng-container>
                            </ng-container>

                          </label>
                          <div class="input-group mt-0">
                              <input
                              *ngIf="resetdate && form.value.return_date"
                              date-picker
                              type="text"
                              id="return_date"
                              [max-date]="undefined"
                              placeholder="วันที่ส่งคืนเครื่องมือ"
                              class="form-control"
                              [date]="form.value.return_date"
                              formControlName="return_date"
                              [readonly]='!isTranDep'
                              />
                              <div
                              class="input-group-append"
                              for="pickup_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>

                        </div>

                        <!-- <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                          [control]="form.controls.real_return_date"
                          [submitted]="submitted">
                          <label for="real_return_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'ICL'">วันส่งคืนเครื่องมือจริง</ng-container>
                              <ng-container *ngSwitchCase="'ECL'">วันส่งคืนเครื่องมือจริง</ng-container>
                              <ng-container *ngSwitchDefault>วันที่เสร็จสิ้นจริง</ng-container>
                            </ng-container>
  
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              id="real_return_date"
                              [placeholder]="(template =='ICL' || template == 'ECL')? 'วันส่งคืนเครื่องมือจริง': 'วันที่เสร็จสิ้นจริง'"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.real_return_date"
                              formControlName="real_return_date"
                              [readonly]='!isTranDep'
                              />
                              <div
                              class="input-group-append"
                              for="real_return_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>

                        </div> -->
                      </div>
                    </div>

                    <div class="my-1">
                      <app-work-order-lab-item 
                        *ngIf="customer_info" 
                        [customer_info]="customer_info" 
                        (disableBtn)="disableBtn($event)"
                        (done)="getJobOrderItems($event)">
                      </app-work-order-lab-item>

                      <div class="text-danger" *ngIf="(jobOrderMitItem?.jobOrderItems?.length == 0 || jobOrderMitItem == null) && submitted">
                        <span class="pl-1">
                          โปรดระบุอย่างน้อย 1 รายการ
                        </span>
                      </div>
                    </div>
                    
                    <div class="form-body">
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.note"
                          [submitted]="submitted">
                          <label for="doc_date">
                              หมายเหตุ
                          </label>
                          <textarea
                            placeholder="หมายเหตุ"
                            class="form-control"
                            rows="3"
                            id="note"
                            formControlName="note"
                            >
                          </textarea>
                          </form-group>
                          
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="btn-group mr-1" role="group" aria-label="First group">
                                <label for="file">แนบไฟล์</label>
                            </div>
                            <button
                              class="btn btn-outline-primary mb-1"
                              type="button"
                              (click)="uploadFile()">
                                <i class="feather icon-upload icon-left"></i>
                                Upload
                            </button>
                            <input type="file" style="display:none;" multiple id="importFile"
                            (change)="importFile($event.target.files)">
                          </div>
                          <small>* จำกัดขนาดไฟล์ {{UtilsService.config.limitFile}} mb</small> 
                          <div class="mt-1">
                              <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                                  <span style="cursor: pointer;" (click)="downloadFile(file)">
                                    {{file.name}}
                                  </span>
                                  <span aria-hidden="true" style="cursor: pointer;"
                                    data-target="#modalConfirmDelFile"
                                    data-toggle="modal"
                                  (click)="removeFile(i)">&times;</span>
                              </span>
                          </div>
                          <span class="pl-1 text-danger w-100" *ngIf="submitted && checkFileInvalid">
                            ขนาดไฟล์เกินกว่าที่กำหนด
                          </span>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.special_condition"
                          [submitted]="submitted">
                          <label for="special_condition">
                              Special Condition
                          </label>
                          <textarea
                            placeholder="Special Condition"
                            class="form-control"
                            rows="3"
                            id="special_condition"
                            formControlName="special_condition"
                            >
                          </textarea>
                          </form-group>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-lg-6 col-md-12">

                          <form-group
                          [control]="form.controls.accessory"
                          [submitted]="submitted">
                          <label for="doc_date">
                            Accessory
                          </label>
                          <textarea
                            placeholder="Accessory"
                            class="form-control"
                            id="accessory"
                            rows="3"
                            formControlName="accessory"
                            >
                          </textarea>
                          </form-group>
                        </div>

                      </div>
                    </div>
                  </div>
              </form>
          </div>
      </div>
      </div>

    </div>
</div>

<app-confirm-delete [id]="'modalConfirmDelFile'" (save)="deleteFile($event)"></app-confirm-delete>