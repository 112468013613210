import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class CustomerInfoService {
  async create({
    customer_level_id,
    customer_state_id,
    tax_id,
    company_name,
    branch,
    department,
    industry_id,
    customer_source,
    customer_source_text,
    // address,
    // district_id,
    // amphure_id,
    // province_id,
    // phonenumber_1,
    // phonenumber_2,
    // email_1,
    // email_2,
    credit_term_day,
    term_of_service,
    customerActivities,
    // discount_percentage_init,
    // fax_1
    // billing_day,
    selling_type,
    customer_level_change_at,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/customer-info`, {
        customer_level_id,
        customer_state_id,
        tax_id,
        company_name,
        branch,
        department,
        industry_id,
        customer_source,
        customer_source_text,
        // address,
        // district_id,
        // amphure_id,
        // province_id,
        // phonenumber_1,
        // phonenumber_2,
        // email_1,
        // email_2,
        credit_term_day,
        term_of_service,
        customerActivities,
        // discount_percentage_init,
        // fax_1
        // billing_day,
        selling_type,
        customer_level_change_at,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    customer_id,
    customer_level_id,
    customer_state_id,
    tax_id,
    company_name,
    branch,
    department,
    industry_id,
    customer_source,
    customer_source_text,
    // address,
    // district_id,
    // amphure_id,
    // province_id,
    // phonenumber_1,
    // phonenumber_2,
    // email_1,
    // email_2,
    credit_term_day,
    term_of_service,
    interest_level,
    billing_day,
    billing_location,
    check_location,
    discount_percentage_init,
    // fax_1
    // customerActivities /*: { title:"", description:"", customer_activity_type_id:"" }*/,
    selling_type
  }) {
    let result = null;
    try {
      result = await Axios().put(`/customer-info/${customer_id}`, {
        customer_level_id,
        customer_state_id,
        tax_id,
        company_name,
        branch,
        department,
        industry_id,
        customer_source,
        customer_source_text,
        // address,
        // district_id,
        // amphure_id,
        // province_id,
        // phonenumber_1,
        // phonenumber_2,
        // email_1,
        // email_2,
        credit_term_day,
        term_of_service,
        interest_level,
        billing_day,
        billing_location,
        check_location,
        discount_percentage_init,
        // fax_1
        // customerActivities,
        selling_type
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ customer_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/customer-info/${customer_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/customer-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ customer_id }) {
    let result = null;
    try {
      result = await Axios().get(`/customer-info/${customer_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async convert({ customer_id, revoked_reason, event }) {
    // console.log({ customer_id, revoked_reason, event });
    let result = null;
    try {
      result = await Axios().put(
        `/customer-info/${customer_id}/convert/${event}`,
        {
          revoked_reason,
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
