<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="add-item"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addItemModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="addItemModal">
          เพิ่มรายการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="form">
          <div class="row justify-content-md-center">
            <div class="col-md-12">
              <div class="form-body">

                <form-group
                  [control]="form.controls.product_title"
                  [submitted]="submitted">
                  <label for="product_title">ชื่อสินค้า <span class="danger">*</span></label>
                  <div class="input-group">
                    <ng-select2
                      [options]="{
                        language: UtilsService.select2OptionsLanguage()
                      }"
                      id="product_title"
                      [data]="productNameList"
                      class="w-100"
                      placeholder="เลือกชื่อสินค้า"
                      formControlName="product_title"
                      (valueChanged)="selectProductName($event)"
                    >
                    </ng-select2>
                  </div>
                </form-group>
                
                <form-group
                *ngIf="product?.product_code == '000000'"
                [control]="form.controls.related_job_order_info_id"
                [submitted]="submitted">
                <label for="related_job_order_info_id">ใบสั่งงาน <span class="danger">*</span></label>
                <div class="input-group">
                  <ng-select2
                    [options]="{
                      language: UtilsService.select2OptionsLanguage()
                    }"
                    id="related_job_order_info_id"
                    [data]="jobOrderList"
                    class="w-100"
                    placeholder="ใบสั่งงาน"
                    formControlName="related_job_order_info_id"
                    (valueChanged)="selectJobOrder($event)"
                  >
                  </ng-select2>
                </div>
              </form-group>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>เพิ่ม
        </button>
      </div>
    </div>
  </div>
</div>
