import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  QuotationMitItemService,
  QuotationProductItemService,
  QuotationUtilsService,
  QuotationService
} from '../../../../services/quotations';
import { EmployeeService } from "src/app/services/index";
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import {
  TransportreceiveInfoService,
  TransportreceiveItemService
} from 'src/app/services/transport-receives';
import { UtilsService } from 'src/app/services';

@Component({
  selector: 'app-quotation-list',
  templateUrl: './quotation-list.component.html',
  styleUrls: ['./quotation-list.component.css']
})
export class QuotationListComponent implements OnInit {
  quotations: Array<any>;
  status: string;
  user_id: string;
  reset: boolean = false;
  isLoading = true;
  load: Function = this.QuotationService.load;
  employeeList = [];
  filter = {};
  params: any;

  @Output() done = new EventEmitter<any>();

  quotation = null;

  number_expired: number = 0;
  number_all_status: number = 0;
  number_draft_status: number = 0;
  number_no_po_status: number = 0;
  number_voided_status: number = 0;

  number_accepted_status: number = 0;
  number_revise_status: number = 0;
  number_revise_approve_status: number = 0;

  number_waiting_for_approve_status: number = 0;
  number_waiting_for_accept_status: number = 0;
  initialPage: number = 1;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private EmployeeService: EmployeeService,
    private QuotationMitItemService: QuotationMitItemService,
    private TransportreceiveInfoService: TransportreceiveInfoService,
    private TransportreceiveItemService: TransportreceiveItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public _UtilsService: UtilsService,
    public _utils : QuotationUtilsService,
    public QuotationService: QuotationService,
    )
  {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
    this.route.queryParams
      .subscribe(params => {
        if(params){
          let page = parseInt(params.page);

          this.params = params;
          this.filter = {
            ...params,
            offset: page == 1 || isNaN(page)? 0: ((page-1)* 10),
            page: this._UtilsService.setUndefined()
          };

          this.initialPage = isNaN(page)? 1: page;
        }
      }
    );
  }
  routeEvent(state): void {
    this.status = state?.status;
  }
  ngOnInit(): void {
    this.quotations = [];
    this.EmployeeService.load(null, {emp_department_id: 'SALES_DEP'}).then(res=>{
      this.employeeList = res.resultData;
      this.employeeList = this.employeeList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `[${elem.emp_code}] ${elem.user_info?.firstname_th} ${elem.user_info?.lastname_th}`,
        }
      })
    });
    this.getCountOfStatus(null);
  }

  getCountOfStatus(filter: object){
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;

      if (
        !userInfo.is_sales_supervisor &&
        !userInfo.is_co_sales_supervisor &&
        !userInfo.is_sales_manager &&
        !userInfo.is_co_sales &&
        userInfo?.is_sales
      ) {
        this.filter = {
          user_id: userInfo.user_id
        }
      }

      let searchFilter =  {
        user_id: userInfo.user_id,
      };

      this.number_all_status = 0;
      if (userInfo.is_sales_supervisor || userInfo.is_sales_supervisor || userInfo.is_sales_manager|| userInfo.is_co_sales || userInfo.is_co_sales_supervisor) delete searchFilter.user_id;
      this.QuotationService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'DRAFT'}).then(res=>{
        this.number_draft_status = res.rowCount || 0;
      });
      this.QuotationService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'WAITING_FOR_APPROVE'}).then(res=>{
        this.number_waiting_for_approve_status = res.rowCount || 0;
      });
      this.QuotationService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'ACCEPTED'}).then(res=>{
        this.number_accepted_status = res.rowCount || 0;
      });
      this.QuotationService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'WAITTING_FOR_ACCEPT'}).then(res=>{
        this.number_waiting_for_accept_status = res.rowCount || 0;
      });
      this.QuotationService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'EXPIRED'}).then(res=>{
        this.number_expired = res.rowCount || 0;
      });
      this.QuotationService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'ACCEPTED', is_no_file_attached: true}).then(res=>{
        this.number_no_po_status = res.rowCount || 0;
      });
      this.QuotationService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'REVISE'}).then(res=>{
        this.number_revise_status = res.rowCount || 0;
      });
      this.QuotationService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'REVISE_WAITING_FOR_APPROVE'}).then(res=>{
        this.number_revise_approve_status = res.rowCount || 0;
      });
      this.QuotationService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'VOIDED'}).then(res=>{
        this.number_voided_status = res.rowCount || 0;
      });
      this.cdRef.detectChanges();
    });
  }
  onChangePage(quotations: Array<any>) {
    this.quotations = quotations;
    this.isLoading = false;
  }

  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = { ...this.filter, ...filter };

    this.setQueryUrl();
  }

  setQueryUrl(){
    let filter = {
      ...this.filter,
      page: this.initialPage,
      offset: this._UtilsService.setUndefined(),
    }
    this.router.navigate([], {
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
    this.params = filter;
  }

  getFilterLimit(filterLimit){

    if(this.initialPage != filterLimit.initialPage && filterLimit.initialPage != 0){

      let params = {
        ...this.filter,
        page: filterLimit.initialPage,
        offset: this._UtilsService.setUndefined()
      }

      this.router.navigate([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.params = params;
    }
  }

  searchStatus(status:string = null){
    let condition = null;
    if(status =='NO_PO'){
      condition = {
        doc_status_id: 'ACCEPTED',
        is_no_file_attached: true
      };
    }else{
      condition = {
        doc_status_id: status,
        is_no_file_attached: this._UtilsService.setUndefined()
      };
    }
    this.search(condition);
  }
  filterByUserId(user_id) {
    if(user_id !== this._UtilsService.setUndefined()){
      let condition = {
        "user_id": user_id
      };
      this.search(condition);
      this.getCountOfStatus(condition);
    }
  }
  select(quotation: string) {
    this.quotation = Object.assign({}, quotation);
  }
  exportPDF(){
    this.quotation = null;
  }
  async onSaveConfirmVoided(event: any) {
    if(event){
      var response = await this.QuotationService.update({
        ...this.quotation,
        doc_status_id:'VOIDED',
        tax: this.quotation.tax.toString()
      });
      if(this.quotation.quotation_info_id && response.success){
        let mitResponse = await this.QuotationMitItemService.load(null, {quotation_info_id: this.quotation.quotation_info_id});
        if(mitResponse.success){
          await Promise.all(mitResponse.resultData.map(async (v:any, index: number) =>{
            this.updateMitItem(v, response);

            if(index == 0){
              if(v.transport_receive_item_info.length > 0){
                let transportInfoResponse = await this.TransportreceiveInfoService.getById({transport_receive_info_id: v.transport_receive_item_info[0]?.transport_receive_id});
                if(transportInfoResponse.success){
                  let updateResponse =  await this.TransportreceiveInfoService.update(
                    {
                      ...transportInfoResponse.resultData,
                      doc_status_id: 'RECEIVE_WAITING_FOR_CONFIRM'
                    }
                  );
                }
              }
            }
            if(v.transport_receive_item_id){
              let transportResponse = await this.TransportreceiveItemService.getById({transport_receive_item_id: v.transport_receive_item_id});
              if(transportResponse.success){
                await this.TransportreceiveItemService.updateNoQT(
                  {
                  ...transportResponse.resultData,
                  item_status_id : 'WAITING_FOR_CONFIRM',
                  quotation_mit_item_id : "",
                  noqt_quotation_info_id : ""
                  }
                );
              }
            }
          }));
        }

        let productResponse = await this.QuotationProductItemService.load(null, {quotation_info_id: this.quotation.quotation_info_id});
        if(productResponse.success){
          await Promise.all(productResponse.resultData.map(async (v:any, index: number) =>{
            this.updateProductItem(v, response);

          }));
        }
      }
    }
    if(event.type == "voided"){
      this.getCountOfStatus(null);
      this.doReset(response.success || response.error);
    }else if(event.type == "voided-and-copy"){
      if(response.success){
        this.router.navigateByUrl("/quotation/" + this.quotation.quotation_info_id + "/edit?action=copy", {
          state: {
            status: response.success,
        }});
      }else{
        this.doReset(response.success || response.error);
      }
    }else{
      if(response.success){
        this.router.navigateByUrl("/quotation/" + this.quotation.quotation_info_id + "/edit?action=copy&&type=edit", {
          state: {
            status: response.success,
        }});
      }else{
        this.doReset(response.success || response.error);
      }
    }
    event.closeModal.click();
  }

  async updateMitItem(v, quotationResponse){
    if(v.quotation_mit_item_id){
      await this.QuotationMitItemService.update({
        ...v,
        price: this._utils.convertStingToNumber(v.price),
        discount: this._utils.convertStingToNumber(v.discount),
        quotation_info_doc_no: quotationResponse.resultData.doc_no,
        quotation_info_status: quotationResponse.resultData.doc_status_id,
      });
    }
  }

  async updateProductItem(v, quotationResponse){
    if(v.quotation_item_id){
       await this.QuotationProductItemService.update(
        {
          ...v,
          quotation_info_doc_no: quotationResponse.resultData.doc_no,
          quotation_info_status: quotationResponse.resultData.doc_status_id,
        });
    }
  }
}
