import {
  Component,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
  ElementRef,
  NgZone,
  Input,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AbsNumberValidator } from "src/app/validators";

@Component({
  selector: "app-product-range-price-create",
  templateUrl: "./product-range-price-create.component.html",
  styleUrls: ["./product-range-price-create.component.css"],
})
export class ProductRangePriceCreateComponent implements OnInit {
  isReady = false
  form: FormGroup;
  submitted = false;
  @Input() methods = [];
  @Input() cps = [];
  @Input() units = [];
  @Output() done = new EventEmitter();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(private zone: NgZone, private formBuilder: FormBuilder) {
    this.setForm();
  }
  setForm(): void {
    this.form = this.formBuilder.group(
      {
        mit_method_id: ["", [Validators.required]],
        min_range: ["", [Validators.required]],
        max_range: ["", [Validators.required]],
        unit_id: ["", [Validators.required]],
        start_fee: ["", [Validators.required, Validators.min(0)]],
        per_point_fee: ["", [Validators.required, Validators.min(0)]],
        min_point: [
          "",
          [Validators.required, Validators.min(0), AbsNumberValidator()],
        ],
        isISO: ["N", [Validators.required]],
        service_location: ["LAB", [Validators.required]],
        // mit_cp_ids: [[], [Validators.required]],
      },
      {
        validators: this.validateMinMaxRange,
      }
    );
  }

  ngOnInit(): void {}

  validateNumber(key){
    this.form.patchValue({
      [key]: Number(this.form.value[key].replace(/\,/g, '')).toLocaleString('en-GB', { maximumFractionDigits: 10 })
    })
  }

  submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let allUnits = [];
    for (let unit of this.units) {
      allUnits = allUnits.concat(unit.units);
    }

    let unit_short_name_en = allUnits.filter(
      (unit) => unit.unit_id === this.form.value.unit_id
    )[0].unit_short_name_en;

    // let mit_cps = this.cps
    //   .filter((v) => this.form.value.mit_cp_ids.includes(v.mit_cp_id))
    //   .map((v) => v.cp_name)
    //   .join(",");

    let method_name = this.methods.filter(
      (mit_method_info) =>
        mit_method_info.mit_method_id === this.form.value.mit_method_id
    )[0].method_name;

    let data = {
      ...this.form.value,
      unit_short_name_en,
      min_range: Number(this.form.value.min_range.replace(/\,/g, '')),
      max_range: Number(this.form.value.max_range.replace(/\,/g, '')),
      start_fee: Number(this.form.value.start_fee),
      per_point_fee: Number(this.form.value.per_point_fee),
      min_point: Number(this.form.value.min_point),
      // mit_cps,
      method_name,
    };
    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.form = null;
        this.setForm();
        this.submitted = false;
        this.isReady = false
      } else {
        this.isReady = true
      }
    });
  }
  validateMinMaxRange(group: FormGroup) {
    // here we have the 'passwords' group
    let min_range = group.get("min_range").value.replace(/\,/g, '');
    let max_range = group.get("max_range").value.replace(/\,/g, '');
    if (
      min_range === undefined ||
      min_range === null ||
      max_range === undefined ||
      max_range === null
    ) {
      return null;
    }
    
    return parseFloat(max_range) >= parseFloat(min_range) ? null : { lessThan: true };
  }
}
