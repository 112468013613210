import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TransportreceiveContactService {
  async create({
    transport_receive_info_id,
    customer_contact_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/transport-receive-contact`, {
        transport_receive_info_id,
        customer_contact_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        transport_receive_contact_id,
        transport_receive_info_id,
        customer_contact_id
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/transport-receive-contact/${transport_receive_contact_id}`, {
        transport_receive_info_id,
        customer_contact_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ transport_receive_contact_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/transport-receive-contact/${transport_receive_contact_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-contact`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ transport_receive_contact_id }) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-contact/${transport_receive_contact_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
