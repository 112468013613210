import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CustomerAddressService } from 'src/app/services';
import { 
  AccBillingNoteInfoService,
  AccBillingNoteItemService
} from 'src/app/services/billing-note';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import domtoimage from 'dom-to-image-more'
import jsPDF from 'jspdf'; 
import THBText from 'thai-baht-text'
import { AccInvoiceInfoService } from 'src/app/services/invoice';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';
import { AccCreditNoteInfoService } from 'src/app/services/credit-note';
import { AccDebitNoteInfoService } from 'src/app/services/debit-note';

@Component({
  selector: 'app-billing-note',
  templateUrl: './billing-note.component.html',
  styleUrls: ['./billing-note.component.css']
})
export class BillingNoteComponent implements OnInit {
  billingItems = [];
  pages = [];

  acc_billing_note_info_id: string = "";
  //any
  customer_info = null;
  billingInfo = null;
  count = 0;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private CustomerAddressService: CustomerAddressService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccBillingNoteInfoService: AccBillingNoteInfoService,
    private AccBillingNoteItemService: AccBillingNoteItemService,
    private AccDebitNoteInfoService: AccDebitNoteInfoService,
    private AccCreditNoteInfoService: AccCreditNoteInfoService,
    
    public utils: QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) { 
    this.acc_billing_note_info_id = 
    this.route.snapshot.paramMap.get("acc_billing_note_info_id");
  }

  async ngOnInit() {
    await this.AccBillingNoteInfoService.getById({acc_billing_note_info_id: this.acc_billing_note_info_id})
    .then(async res=>{
      this.billingInfo = res.resultData || {};
      this.titleService.setTitle(this.billingInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
      if(this.billingInfo.customer_address_id){
        await this.CustomerAddressService.getById({customer_address_id: this.billingInfo.customer_address_id})
        .then(res=>{
          this.billingInfo['customer_address'] = this.utils.genAddress(res.resultData);
          this.billingInfo['customer_address_info'] = res.resultData;
        })
      }

      await this.AccBillingNoteItemService.load(null, {acc_billing_note_info_id: this.acc_billing_note_info_id})
      .then(async res=>{
        this.billingItems = res.resultData || [];

        this.billingItems.sort(function(a,b){
          if(a.doc_no_company > b.doc_no_company) return 1;
          if(a.doc_no_company < b.doc_no_company) return -1;
          return 0;
        });

        this.billingItems.sort(function(a,b){
          if(a.doc_no > b.doc_no) return 1;
          if(a.doc_no < b.doc_no) return -1;
          return 0;
        });

        await Promise.all(this.billingItems.map(async (bill)=>{
          if(bill.doc_type == 'TAX_INVOICE'){
            await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: bill.acc_document_info_id})
            .then(res=>{
              bill['data'] = res.resultData;
            })
          }else if(bill.doc_type == 'INVOICE'){
            await this.AccInvoiceInfoService.getById({acc_invoice_info_id: bill.acc_document_info_id})
            .then(res=>{
              bill['data'] = res.resultData;
            })
          }else if(bill.doc_type == 'CREDIT_NOTE'){
            await this.AccCreditNoteInfoService.getById({acc_credit_note_info_id: bill.acc_document_info_id})
            .then(res=>{
              bill['data'] = res.resultData;
            })
          }else{
            await this.AccDebitNoteInfoService.getById({acc_debit_note_info_id: bill.acc_document_info_id})
            .then(res=>{
              bill['data'] = res.resultData;
            })
          }
        }));
      })
      for (var i=0; i<this.billingItems.length; i++) {
        this.billingItems[i].index = i;
      }
      this.sumCount();
      for (var i=0; i<this.billingItems.length; i+=20) {
        this.pages.push(this.billingItems.slice(i, i+20));
      }  
    })
  }

  sumCount(){
    this.billingItems.map(v=>{
      if(v.data.grand_total){
        if(v.doc_type =='CREDIT_NOTE'){
          this.count = this.count - v.data?.grand_total;
        }else{
          this.count = this.count + v.data?.grand_total;
        }
      }
    });
    this.billingInfo.grand_total = this.count;
    
    if (this.billingInfo.grand_total > 0) {
      this.billingInfo['THBText'] = THBText(Math.abs(this.billingInfo.grand_total));
    }else if(this.billingInfo.grand_total < 0){
      this.billingInfo['THBText'] = 'ลบ' + THBText(Math.abs(this.billingInfo.grand_total));
    }else{
      this.billingInfo['THBText'] = 'ศูนย์บาทถ้วน';
    }
  }

  async print(billingInfo){
    const billing_sections = document.querySelectorAll('.pdf');
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let page = 1;
    let perPage = Array.prototype.slice.call(billing_sections,0).length;
    
    let billingList = [];
    await Promise.all(Array.prototype.slice.call(billing_sections,0).map(async (value, index) =>{
      let billing_section = document.getElementById('billing_' + (index));
      await domtoimage.toJpeg(billing_section, {
        background: 'white', 
        allowTaint: true,
        width: billing_section.clientWidth * 2,
        height: billing_section.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        billingList.push({index: index, canvas: canvas, billing_section: billing_section});
      })
    }));
    billingList.sort(function(a, b){return a.index - b.index});
    await Promise.all(billingList.map(async (value, index) =>{

      var imgWidth = 208;
      var imgHeight = value.billing_section.clientHeight * imgWidth / value.billing_section.clientWidth;
      if((index) != 0) doc.addPage('a4');
      doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);
      doc.setFontSize(8);
      doc.text(page + ' /  ' + perPage, 200, 285);
      page = page+1;
    }));

    setTimeout(() => {
      doc.save(billingInfo.doc_no);
    }, 1000);
  }
}
