<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-4 col-12 mb-2">
          <h3 class="content-header-title mb-1">สร้างใบรับเครื่องมือ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/transport-receive']">การรับเครื่องมือ</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#" [routerLink]="['/transport-receive']">รายการรับเครื่องมือ</a>
                </li>
                <li class="breadcrumb-item active">สร้างใบรับเครื่องมือ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-8 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <a href="#" [routerLink]="['/transport-receive']" class="btn btn-warning mr-1">
                <i class="fa fa-times icon-left"></i> ไม่บันทึก
            </a>

            <button class="btn btn-primary mr-1" (click)="saveAsDraft()" [disabled]="disableSaveBtn">
                <i class="fa fa-save icon-left"></i> บันทึกแบบร่าง
            </button>

            <button class="btn btn-primary mr-1" (click)="save(true)" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึก และส่งตรวจสอบ
            </button>

            <button class="btn btn-primary" (click)="save(false)" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึก และส่งตรวจสอบโดยไม่ส่งอีเมล
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="pageLoad"></loading>

        <div class="card" [hidden]="pageLoad">
          <div class="card-header">
              <h4 class="card-title mt-1">สร้างใบรับเครื่องมือ</h4>
              <div class="heading-elements">
              </div>
          </div>

          <div class="card-content collapse show">
              <form autocomplete="off" class="form" [formGroup]="form">
                  <div class="card-body">
                    <div class="form-body">
                      <h4 class="badge btn-blue badge-customer-lvl" *ngIf="customer_info">
                        {{ customer_info.customer_level_id | dash }}
                      </h4>
                        <div class="row">
                          <div class="col-xl-3 col-lg-6 col-md-12">
                            <form-group
                                [control]="form.controls.customer_id"
                                [submitted]="submitted"
                              >
                                <label for="customer_id">
                                  ชื่อลูกค้า
                                </label>
                                <input
                                  type="text"
                                  id="customer_name"
                                  class="form-control"
                                  placeholder="ชื่อลูกค้า"
                                  formControlName="customer_name"
                                  readonly
                                />
                            </form-group>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.customer_address_id"
                            [submitted]="submitted"
                            >
                              <label for="customer_address_id">
                                ออกเอกสารในนาม <span class="danger" *ngIf="type =='customer'">*</span>
                              </label>
                              <ng-select2
                                [options]="
                                {
                                  language: UtilsService.select2OptionsLanguage()
                                }"
                                [data]="customerAddressList"
                                class="w-100"
                                id="customer_address_id"
                                placeholder="ออกเอกสารในนาม"
                                formControlName="customer_address_id"
                                (valueChanged)="selectAddressName($event)"
                              >
                              </ng-select2>
                          </form-group>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_date"
                          [submitted]="submitted">
                          <label for="doc_date">
                              วันที่ออก <span class="danger">*</span>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              id="doc_date"
                              placeholder="วันที่ออก"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.doc_date"
                              formControlName="doc_date"
                              />
                              <div
                              class="input-group-append"
                              for="doc_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <!-- <form-group
                          [control]="form.controls.doc_no"
                          [submitted]="submitted">
                          <label for="doc_no">
                              เลขที่เอกสาร
                          </label>
                          <input
                              type="text"
                              id="doc_no"
                              class="form-control"
                              placeholder="เลขที่เอกสาร"
                              formControlName="doc_no"
                              readonly
                          />
                          </form-group> -->
                        </div>
                        </div>

                        <div class="row">
                                  <div class="col-lg-6 col-md-12">
                                    <form-group
                                      [control]="form.controls.customer_address"
                                      [submitted]="submitted"
                                        >
                                          <label for="customer_address">
                                            ที่อยู่ในการรับเครื่องมือ
                                          </label>
                                          <input
                                            type="text"
                                            id="customer_address"
                                            class="form-control"
                                            placeholder="ที่อยู่ในการรับเครื่องมือ"
                                            formControlName="customer_address"
                                            readonly
                                            />
                                      </form-group>
                                  </div>

                                  <div class="col-lg-3 col-md-12">

                                    <form-group
                                      [control]="form.controls.receiver_id"
                                      [submitted]="submitted"
                                      >
                                        <label for="receiver_id">
                                          ผู้รับเครื่องมือ
                                        </label>
                                        <ng-select2
                                          [options]="
                                          {
                                            language: UtilsService.select2OptionsLanguage()
                                          }"
                                          [data]="transportContactList"
                                          class="w-100"
                                          id="receiver_id"
                                          formControlName="receiver_id"
                                          placeholder="ผู้รับเครื่องมือ"
                                        >
                                        </ng-select2>
                                    </form-group>
 
                                  </div>

                                  <div class="col-lg-3 col-md-12">

                                    <form-group
                                      [control]="form.controls.transport_type_id"
                                      [submitted]="submitted"
                                      >
                                        <label for="transport_type_id">
                                          วิธีรับเครื่องมือ <span class="danger">*</span>
                                        </label>
                                        <ng-select2
                                          [options]="
                                          {
                                            language: UtilsService.select2OptionsLanguage()
                                          }"
                                          [data]="transportTypes"
                                          class="w-100"
                                          id="transport_type_id"
                                          formControlName="transport_type_id"
                                          placeholder="วิธีรับเครื่องมือ"
                                        >
                                        </ng-select2>
                                    </form-group>
 
                                </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-3 col-md-12">
                            <form-group
                            [control]="form.controls.pickup_date"
                            [submitted]="submitted">
                            <label for="pickup_date">
                              วันที่นัดรับเครื่องมือ 
                              <!-- <span class="danger" *ngIf="!(userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                'TRAN_DEP-03'
                            )">*</span>
                              <a class="primary pointer"
                                *ngIf="canAccessDate && !(userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                  'TRAN_DEP-03'
                              )"
                                data-target="#transport-receive-unlock"
                                data-toggle="modal"> [Unlock]
                              </a> -->
                            </label>
                            <div class="input-group mt-0">
                                <input
                                [readonly]="!userInfo?.emp_info?.emp_department_id.startsWith('TRAN_DEP')"
                                date-picker
                                type="text"
                                id="pickup_date"
                                placeholder="วันที่นัดรับเครื่องมือ"
                                [max-date]="undefined"
                                class="form-control"
                                [date]="form.value.pickup_date"
                                formControlName="pickup_date"
                                />
                                <div
                                class="input-group-append"
                                for="pickup_date"
                                >
                                <span class="input-group-text"
                                    ><i class="feather icon-calendar"></i
                                ></span>
                                </div>
                            </div>
                            </form-group>

                          </div>

                          <div class="col-lg-3 col-md-12">
                            <form-group
                            [control]="form.controls.return_date"
                            [submitted]="submitted">
                            <label for="return_date">
                              กำหนดวันส่งเครื่องคืน 
                              <!-- <span class="danger">*</span> -->
                            </label>
                            <div class="input-group mt-0">
                                <input
                                *ngIf="resetdate"
                                [readonly]="form.value.pickup_date == null || !userInfo?.emp_info?.emp_department_id.startsWith('TRAN_DEP')"
                                date-picker
                                type="text"
                                id="return_date"
                                placeholder="กำหนดวันส่งเครื่องคืน"
                                class="form-control"
                                [max-date]="undefined"
                                [date]="form.value.return_date"
                                formControlName="return_date"
                                />
                                <div
                                class="input-group-append"
                                for="pickup_date"
                                >
                                <span class="input-group-text"
                                    ><i class="feather icon-calendar"></i
                                ></span>
                                </div>
                            </div>
                            </form-group>

                          </div>

                          <div class="col-lg-6 col-md-12" *ngIf="!pageLoad">

                              <form-group
                              [control]="form.controls.customer_contact_ids"
                                  [submitted]="submitted"
                                >
                                  <label for="customer_contact_ids">
                                    ผู้ติดต่อ <span class="danger">*</span>
                                  </label>
                                  <ng-select2
                                  [data]="customerContactList"
                                  [options]="{
                                    templateResult: NgSelect2Customer?.templateContactResult,
                                    multiple: true,
                                    language: UtilsService.select2OptionsLanguage()
                                  }"
                                  class="w-100"
                                  id="customer_contact_ids"
                                  placeholder="ผู้ติดต่อ"
                                  formControlName="customer_contact_ids"

                                  >
                                  </ng-select2>
                              </form-group>

                          </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <form-group
                              [control]="form.controls.extra_message"
                              [submitted]="submitted"
                                >
                                  <label for="extra_message">
                                    ข้อความพิเศษ
                                  </label>
                                  <input
                                    type="text"
                                    id="extra_message"
                                    class="form-control"
                                    placeholder="ข้อความพิเศษ"
                                    formControlName="extra_message"
                                    />
                              </form-group>
                          </div>
                        </div>
                    </div>

                    <div class="mt-1">
                      <app-transport-receive-item 
                        *ngIf="customer_info"
                        [customer_info]="customer_info"
                        (done)="getReceiveItems($event)">
                      </app-transport-receive-item>
                    </div>

                    <div class="form-body">
                      <div class="row">
                        <div class="col-lg-6 col-md-12">

                          <form-group
                          [control]="form.controls.accessory"
                          [submitted]="submitted">
                          <label for="doc_date">
                            Special condition
                          </label>
                          <textarea
                            placeholder=" Special condition"
                            class="form-control"
                            id="note"
                            rows="3"
                            formControlName="note"
                            >
                          </textarea>
                          </form-group>
                        </div>

                      </div>
                      
                      <div class="row">
                        <div class="col-lg-6 col-md-12">

                          <form-group
                          [control]="form.controls.accessory"
                          [submitted]="submitted">
                          <label for="doc_date">
                            Accessory
                          </label>
                          <textarea
                            placeholder="Accessory"
                            class="form-control"
                            id="accessory"
                            rows="3"
                            formControlName="accessory"
                            >
                          </textarea>
                          </form-group>
                        </div>

                      </div>
                    </div>
                      
                  </div>
              </form>
          </div>
      </div>
      </div>

    </div>
</div>

<app-transport-receive-unlock 
(done)="unlock($event)">
</app-transport-receive-unlock>