import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({ name: "thai_date" })
export class ThaiDatehPipe implements PipeTransform {
  transform(date: string, monthMode?: string, format?: string): string {
    if (!date) return "";

    let momentDate = moment(date);
    let dateStr = "";
    let months = [
      "มกราคม",
      "กุมภาพันธ์",
      "มีนาคม",
      "เมษายน",
      "พฤษภาคม",
      "มิถุนายน",
      "กรกฎาคม",
      "สิงหาคม",
      "กันยายน",
      "ตุลาคม",
      "พฤศจิกายน",
      "ธันวาคม",
    ];
    if (monthMode === "short") {
      months = [
        "ม.ค.",
        "ก.พ.",
        "มี.ค.",
        "เม.ย.",
        "พ.ค.",
        "มิ.ย.",
        "ก.ค.",
        "ส.ค.",
        "ก.ย.",
        "ต.ค.",
        "พ.ย.",
        "ธ.ค.",
      ];
    }

    if (format === "date") {
      dateStr = `${momentDate.date()} ${months[momentDate.month()]} ${
        momentDate.year() + 543
      }`;
    } else {
      dateStr = `${momentDate.date()} ${months[momentDate.month()]} ${
        momentDate.year() + 543
      } ${momentDate.format("HH:mm น.")}`;
    }

    return dateStr;
  }
}
