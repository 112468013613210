import {
    Directive,
    ElementRef,
    Output,
    EventEmitter,
    Input,
  } from "@angular/core";
  import moment from "moment";
  declare const $;

  @Directive({
    selector: "[date-range-picker]",
  })
  export class DateRangePickerDirective {
    @Output() input = new EventEmitter();
    @Input() date;
    @Input() singleDatePicker = false;
    @Input() timePickerIncrement = 30;
    @Input() format = 'DD-MM-YYYY HH:mm';
    @Input() timePicker = true;
    @Input() datePicker = true;
    @Input() position = 'down';
    @Input("check-min-date") checkMinDate;
    @Input("check-max-date") checkMaxDate;
    @Input("max-date") maxDate = null;
    @Input("min-date") minDate = null;
    @Input("view-mode") viewMode = "days";
    daterangepicker = null;
  
    constructor(el: ElementRef) {

      this.daterangepicker = $(el.nativeElement);
      
      this.daterangepicker.on("keydown", (e) => {
        e.preventDefault();
      });
      setTimeout(() => {
        this.daterangepicker
          .daterangepicker({
            locale: {
              format: this.format
            },
            datePicker: this.datePicker,
            minDate: this.checkMinDate? this.minDate? this.minDate: new Date(): undefined,
            maxDate:  this.checkMaxDate? this.maxDate? this.maxDate: new Date(): undefined,
            singleDatePicker: this.singleDatePicker,
            format: this.format,
            showDropdowns: true,
            timePicker: this.timePicker,
            drops: this.position,
            timePicker24Hour: true,
            timePickerIncrement:this.timePickerIncrement,
            autoUpdateInput: false
        }, async (start, end) =>{
          
          if(this.singleDatePicker){
            this.daterangepicker.val(moment(start).format(this.format));
            {
              this.input.emit({
                target: {
                  value: moment(start).format(this.format)
                },
              });
            }
          }else{
            this.daterangepicker.val(moment(start).format(this.format) + ' - ' + moment(end).format(this.format));
            {
              this.input.emit({
                target: {
                  value: moment(start).format(this.format) + ' - ' + moment(end).format(this.format)
                },
              });
            }
          }
        });

      }, 500);
    }
  }
  