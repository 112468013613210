<div class="card p-0" [formGroup]="form">
    <div class="card-content collapse show">
        <div class="card-body p-0">
            <p class="font-weight-bold"> รายชื่อ Engineers <span class="danger" *ngIf="(jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM' || jobOrderInfo?.doc_status_id=='CONFIRMED') && pageType != 'show'">*</span></p>

            <div class="table-responsive">
                <table class="table table-bordered" formArrayName="workers">
                  <thead>
                    <tr>
                        <th class="text-center" style="width: 7%;">ลำดับ</th>
                        <th [style.width]="type == 'user_id' ? '65%' : null">
                          ชื่อ - นามสกุล <span class="danger" *ngIf="(jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM' || jobOrderInfo?.doc_status_id=='CONFIRMED') && pageType != 'show'">*</span>
                        </th>
                        <th *ngIf="type=='text'">
                          อีเมล
                        </th>
                        <th class="text-center py-0 align-middle" *ngIf="pageType !='show' && (jobOrderInfo?.doc_status_id !='COMPLETED' && jobOrderInfo?.doc_status_id !='REJECTED')">
                            <span class="mr-1">action</span>
                            <button
                            *ngIf="workerList.length === 0 && (jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='WAITING_FOR_CONFIRM' || jobOrderInfo?.doc_status_id=='CONFIRMED')"
                            type="button"
                            class="btn btn-sm btn-icon btn-circle btn-success"
                            (click)="addWorker()">
                            <i class="fa fa-plus fa-2x"></i>
                          </button>
                        </th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr *ngFor="let item of workerItemFormGroup.controls; let i = index;" [formGroupName]="i">
                          <td class="text-center">
                              {{i+1}}
                              <div *ngIf="i==0 && pageType == 'edit'">
                                (หัวหน้างาน)
                              </div>
                          </td>
                          <td>
                            <div *ngIf="pageType !='show' || jobOrderInfo?.doc_status_id =='COMPLETED'">
                              <ng-container [ngSwitch]="true">
                                <ng-container *ngSwitchCase="type=='text'">
                                  <form-group
                                  [submitted]="submitted"
                                  [control]="item.controls.name"
                                >
                                  <input
                                    type="text"
                                    [placeholder]="'ชื่อ - นามสกุล'"
                                    id="name"
                                    formControlName="name"
                                    class="form-control"
                                  />
                                </form-group>
                                </ng-container>
          
                                <ng-container *ngSwitchDefault>
                                  <form-group [control]="item.controls.user_id" [submitted]="submitted">    
                                    <ng-select2 [data]="employeeList"
                                        [placeholder]="'ชื่อ - นามสกุล'"
                                        formControlName="user_id"
                                        [disabled]="pageType =='show' || jobOrderInfo?.doc_status_id =='COMPLETED'" 
                                        [width]="300">
                                      </ng-select2>
                                  </form-group>
                                </ng-container>
                              </ng-container>                      
                            </div>
                            <div *ngIf="pageType =='show' && jobOrderInfo?.doc_status_id !='COMPLETED'">
                              <ng-container [ngSwitch]="true">
                                <ng-container *ngSwitchCase="type=='text'">
                                  {{item.value?.name}}

                                  <span *ngIf="i==0 && pageType == 'show'">
                                    (หัวหน้างาน)
                                  </span>
                                </ng-container>
          
                                <ng-container *ngSwitchDefault>
                                  {{getEmplyeeName(item.value.user_id)}}

                                  <span *ngIf="i==0 && pageType == 'show'">
                                    (หัวหน้างาน)
                                  </span>
                                </ng-container>
                              </ng-container>
                            </div>
                          </td>
                          <td *ngIf="type=='text'">

                            <div *ngIf="pageType !='show' || jobOrderInfo?.doc_status_id =='COMPLETED'">
                              <form-group
                              [submitted]="submitted"
                              [control]="item.controls.email"
                            >
                              <input
                                type="text"
                                [placeholder]="'อีเมล'"
                                id="email"
                                formControlName="email"
                                class="form-control"
                              />
                            </form-group>   
                            </div>
                            <div *ngIf="pageType =='show' && jobOrderInfo?.doc_status_id !='COMPLETED'">
                              {{item.value?.email | dash}}
                            </div>
                           
                          </td>
                          <td class="text-center" *ngIf="pageType !='show' && (jobOrderInfo?.doc_status_id !='COMPLETED' && jobOrderInfo?.doc_status_id !='REJECTED')">
                              <button
                              type="button"
                              class="btn round btn-icon btn-danger mr-1 mb-1"
                              (click)="removeWorker(i, item);"
                            >
                              <i class="fa fa-minus fa-2x"></i>
                            </button>
                            <button
                              *ngIf="workerList.length === i + 1"
                              type="button"
                              class="btn round btn-icon btn-success mb-1"
                              (click)="addWorker()"
                            >
                              <i class="fa fa-plus fa-2x"></i>
                            </button>
                          </td>
                      </tr>
                      <tr *ngIf="workerList.length === 0">
                          <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                  </tbody>
                  <span class="pl-1 text-danger w-100" style="white-space: nowrap;" *ngIf="(submitted && !canSaveWorkEngineer) && (jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM' || jobOrderInfo?.doc_status_id === 'CONFIRMED')">
                    โปรดระบุ
                  </span>
                </table>
              </div>
        </div>
        
    </div>
</div>

