import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
declare const $;
@Directive({
  selector: "[trigger-sidebar]",
})
export class TriggerSidebarDirective {
  @Input() clickable = true;
  constructor(el: ElementRef) {
    let id = el.nativeElement.getAttribute("data-target");

    $(el.nativeElement).click(() => {
      if (this.clickable) {
        $(id + " > div").toggleClass("d-block");
        $(id + " .custom-sidebar")
          .toggleClass("show")
          .trigger("classChange");
      }
    });
  }
}
