<loading *ngIf="pageLoad"></loading>
<div class="card mb-0" [hidden]="pageLoad">
    <div class="card-content collapse show">
        <div class="card-body p-0">

            <div class="d-flex justify-content-between mb-1" *ngIf="transportreceive?.doc_status_id !== 'RECEIVED_SUCCESS' && transportreceive?.doc_status_id !== 'RECEIVE_WAITING_FOR_REVIEW'">
                <div *ngIf="pageType !='show'">
                    <button
                    *ngIf="receive_type =='WITH_QT'"
                    type="button"
                    class="btn btn-primary mr-1"
                    (click)="createTransportItem()"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบเสนอราคา
                    </button>

                    <button
                        *ngIf="receive_type =='WITTHOUT_QT'"
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#transport-receive-item-create-no-item"
                        >
                        <i class="fa fa-plus icon-left"></i> เพิ่มรายการ (ไม่มีใบเสนอราคา)
                    </button>
                </div>
                <h3>ทั้งหมด {{receiveItems.length}} รายการ</h3>
            </div>

            <div class="table-responsive" style="max-height: 600px;">
                <table class="table table-striped table-bordered">
                    <thead> 
                        <tr>
                            <th>ลำดับ</th>
                            <th>ชื่อเครื่องมือ</th>
                            <th>Service Location</th>
                            <th>Maker</th>
                            <th>Model</th>
                            <th>Serial No.</th>
                            <th>Tag No.</th>
                            <th>สถานะ</th>
                            <th>หมายเหตุ</th>
                            <th>
                                action

                                <button
                                *ngIf="(isReceive || isReview) && canSubmitAll"
                                type="button"
                                (click)="selectAll()"
                                data-target="#receive-item-edit-all"
                                data-toggle="modal"
                                class="btn btn-primary btn-sm text-nowrap"
                            ><i class="fa fa-archive icon-left"></i> {{isReceive? 'บันทึกรับเครื่องมือทั้งหมด': 'ตรวจสอบการรับเครื่องมือทั้งหมด'}}
                            </button>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let receive of receiveItems; let i = index">
                        <tr *ngIf="receive.isShow && receive_type == 'WITH_QT'">
                            <td colspan="12">
                                <span class="custom-bullet" *ngIf="receive.quotation_info_id || receive.noqt_quotation_info_id">
                                    เลขที่ใบเสนอราคา: {{receive.quotation_info?.doc_no || receive.noqt_quotation_info?.doc_no}}
                                </span>
                                <span class="custom-bullet" *ngIf="!receive.quotation_info_id && !receive.noqt_quotation_info_id">
                                    ไม่มีใบเสนอราคา
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="receive_type == 'WITH_QT'">
                            <td class="text-center">
                                {{i+1}}
                            </td>
                            <td>
                                {{receive.quotation_mit_item_info?.description || receive.quotation_mit_item_info?.equipment_name || receive?.noqt_description }}
                            </td>
                            <td>
                                <span *ngIf="receive?.quotation_mit_item_detail_info">
                                    {{receive?.quotation_mit_item_detail_info[0]?.service_location.toUpperCase()}}
                                </span>
                                <span *ngIf="!receive?.quotation_mit_item_detail_info">
                                    {{receive?.quotation_mit_item_info?.quotation_mit_item_detail_info[0]?.service_location.toUpperCase()}}
                                </span>
                            <td>
                                {{receive.quotation_mit_item_info?.marker || receive.noqt_marker || '-'}}
                            </td>
                            <td>
                                {{receive.quotation_mit_item_info?.model || receive.noqt_model || '-'}}
                            </td>
                            <td>
                                {{receive.quotation_mit_item_info?.serial_no || receive.noqt_serial_no || '-'}}
                            </td>
                            <td>
                                {{receive.quotation_mit_item_info?.tag_no || receive.noqt_tag_no || '-'}}
                            </td>
                            <td class="text-nowrap">
                                {{getStatus(receive.item_status_id)}}
                            </td>
                            <td>
                                {{receive.note || '-'}}
                            </td>
                            <td>
                                <button
                                    *ngIf="isReceive || (isReview && receive.item_status_id !='CANCELED')"
                                    type="button"
                                    (click)="select(receive)"
                                    data-target="#receive-item-edit"
                                    data-toggle="modal"
                                    class="btn mr-1 mb-1 btn-primary btn-sm"
                                    style="white-space: nowrap;"
                                > <i class="fa fa-archive icon-left"></i> {{isReceive? 'บันทึกรับเครื่องมือ': 'ตรวจสอบการรับเครื่องมือ'}}
                                </button>
                                <button
                                    *ngIf="pageType !='show' && transportreceive?.doc_status_id !== 'RECEIVED_SUCCESS' && transportreceive?.doc_status_id !== 'WAITING_FOR_RECEIVE' && transportreceive?.doc_status_id !== 'RECEIVE_WAITING_FOR_REVIEW'"
                                    type="button"
                                    data-target="#modalConfirmDelMsgReceiveItem"
                                    data-toggle="modal"
                                    (click)="select(receive)"
                                    class="btn mr-1 mb-1 btn-danger btn-sm"
                                > <i class="fa fa-trash icon-left"></i> ลบ
                                </button>
                                <button
                                    *ngIf="pageType !='show' && !isReturn && transportreceive?.doc_status_id === 'RECEIVED_SUCCESS'"
                                    type="button"
                                    (click)="select(receive)"
                                    data-target="#receive-item-edit"
                                    data-toggle="modal"
                                    class="btn mr-1 mb-1 btn-warning btn-sm"
                                > <i class="fa fa-pencil icon-left"></i> แก้ไข
                                </button>
                                <button
                                    *ngIf="pageType !='show' && isReturn && transportreceive?.doc_status_id === 'RECEIVED_SUCCESS' && !['RETURNED'].includes(receive.item_status_id)"
                                    type="button"
                                    (click)="select(receive)"
                                    [disabled]="(receive?.quotation_mit_item_info?.job_order_info_status != '' && receive?.quotation_mit_item_info?.job_order_info_status != null && !['CANCELED', 'CRF_COMPLETED', 'PREPARE_FOR_SHIP'].includes(receive?.quotation_mit_item_info?.job_order_info_status)) && !['CRF-CANCELED'].includes(receive?.quotation_mit_item_info.job_order_calibration_item_status)"
                                    data-target="#return-item-edit"
                                    data-toggle="modal"
                                    class="btn mr-1 mb-1 btn-primary btn-sm text-nowrap"
                                    [class.btn-secondary] = "(receive?.quotation_mit_item_info?.job_order_info_status != '' && receive?.quotation_mit_item_info?.job_order_info_status != null && !['CANCELED', 'CRF_COMPLETED', 'PREPARE_FOR_SHIP'].includes(receive?.quotation_mit_item_info?.job_order_info_status)) && !['CRF-CANCELED'].includes(receive?.quotation_mit_item_info.job_order_calibration_item_status)"
                                > <i class="fa fa-pencil icon-left"></i> บันทึกส่งเครื่องมือ <i class="fa fa-question"  matTooltip="ยังไม่อนุมัติใบรายงานผล" *ngIf="(receive?.quotation_mit_item_info?.job_order_info_status != '' && receive?.quotation_mit_item_info?.job_order_info_status != null && !['CANCELED', 'CRF_COMPLETED', 'PREPARE_FOR_SHIP'].includes(receive?.quotation_mit_item_info?.job_order_info_status)) && !['CRF-CANCELED'].includes(receive?.quotation_mit_item_info.job_order_calibration_item_status)"></i>
                                </button>
                                <button
                                type="button"
                                class="btn mr-1 mb-1 btn-primary btn-sm btn-edit text-nowrap"
                                data-toggle="modal"
                                data-target="#mit-item-log"
                                (click)="select(receive)"
                                ><i class="fa fa-history icon-left"></i> ดูประวัติ
                                </button>
                                <p class="text-warning pointer" (click)="downloadZip(receive)" *ngIf="receive?.files?.length > 0">
                                    [Download]
                                </p>
                            </td>
                        </tr>
                        <tr *ngIf="receive.isShow && receive_type == 'WITTHOUT_QT'">
                            <td colspan="12">
                                <span class="custom-bullet" *ngIf="receive.noqt_quotation_info_id">
                                    เลขที่ใบเสนอราคา: {{receive.noqt_quotation_info?.doc_no}}
                                </span>
                                <span class="custom-bullet" *ngIf="!receive.noqt_quotation_info_id">
                                    ไม่มีใบเสนอราคา
                                </span>
                            </td>
                        </tr>
                        <tr *ngIf="receive_type == 'WITTHOUT_QT'">
                            <td class="text-center">
                                {{i+1}}
                            </td>
                            <td>
                                {{receive.noqt_description || '-'}}
                            </td>
                            <td>
                                <span *ngIf="receive?.quotation_mit_item_detail_info">
                                    {{receive?.quotation_mit_item_detail_info[0]?.service_location.toUpperCase()}}
                                </span>
                                <span *ngIf="!receive?.quotation_mit_item_detail_info">
                                    {{receive?.quotation_mit_item_info?.quotation_mit_item_detail_info[0]?.service_location.toUpperCase()}}
                                </span>
                            </td>
                            <td>
                                {{receive.noqt_marker || '-'}}
                            </td>
                            <td>
                                {{receive.noqt_model || '-'}}
                            </td>
                            <td>
                                {{receive.noqt_serial_no || '-'}}
                            </td>
                            <td>
                                {{receive.noqt_tag_no || '-'}}
                            </td>
                            <td class="text-nowrap">
                                {{getStatus(receive.item_status_id)}}
                            </td>
                            <td>
                                {{receive.note || '-'}}
                            </td>
                            <td class="text-nowrap">
                                <button
                                    *ngIf="isReceive || (isReview && receive.item_status_id !='CANCELED')"
                                    type="button"
                                    (click)="select(receive)"
                                    data-target="#receive-item-edit"
                                    data-toggle="modal"
                                    class="btn mr-1 mb-1 btn-primary btn-sm text-nowrap"
                                ><i class="fa fa-archive icon-left"></i> {{isReceive? 'บันทึกรับเครื่องมือ': 'ตรวจสอบการรับเครื่องมือ'}}
                                </button>
                                <button
                                    *ngIf="pageType !='show' && transportreceive?.doc_status_id !== 'RECEIVED_SUCCESS' && transportreceive?.doc_status_id !== 'WAITING_FOR_RECEIVE' && transportreceive?.doc_status_id !== 'RECEIVE_WAITING_FOR_REVIEW'"
                                    type="button"
                                    data-target="#modalConfirmDelMsgReceiveItem"
                                    data-toggle="modal"
                                    (click)="select(receive)"
                                    class="btn mr-1 mb-1 btn-danger btn-sm"
                                > <i class="fa fa-trash icon-left"></i> ลบ
                                </button>
                                <button
                                *ngIf="pageType !='show' && transportreceive?.doc_status_id === 'RECEIVED_SUCCESS'"
                                    type="button"
                                    (click)="select(receive)"
                                    data-target="#receive-item-edit"
                                    data-toggle="modal"
                                    class="btn mr-1 mb-1 btn-warning btn-sm"
                                > <i class="fa fa-pencil icon-left"></i> แก้ไข
                                </button>
                                <button
                                    *ngIf="pageType !='show' && isReturn && transportreceive?.doc_status_id === 'RECEIVED_SUCCESS' && !['RETURNED'].includes(receive.item_status_id)"
                                    type="button"
                                    (click)="select(receive)"
                                    [disabled]="(receive?.quotation_mit_item_info.job_order_info_status != '' && receive?.quotation_mit_item_info.job_order_info_status != null && !['CANCELED', 'CRF_COMPLETED', 'PREPARE_FOR_SHIP'].includes(receive?.quotation_mit_item_info.job_order_info_status)) && !['CRF-CANCELED'].includes(receive?.quotation_mit_item_info.job_order_calibration_item_status)"
                                    data-target="#return-item-edit"
                                    data-toggle="modal"
                                    class="btn mr-1 mb-1 btn-primary btn-sm text-nowrap"
                                    [class.btn-secondary] = "(receive?.quotation_mit_item_info.job_order_info_status != '' && receive?.quotation_mit_item_info.job_order_info_status != null && !['CANCELED', 'CRF_COMPLETED', 'PREPARE_FOR_SHIP'].includes(receive?.quotation_mit_item_info.job_order_info_status)) && !['CRF-CANCELED'].includes(receive?.quotation_mit_item_info.job_order_calibration_item_status)"
                                > <i class="fa fa-pencil icon-left"></i> บันทึกส่งเครื่องมือ  <i class="fa fa-question"  matTooltip="ยังไม่อนุมัติใบรายงานผล" *ngIf="(receive?.quotation_mit_item_info.job_order_info_status != '' && receive?.quotation_mit_item_info.job_order_info_status != null && !['CANCELED', 'CRF_COMPLETED', 'PREPARE_FOR_SHIP'].includes(receive?.quotation_mit_item_info.job_order_calibration_item_status)) && !['CRF-CANCELED'].includes(receive?.quotation_mit_item_info.job_order_calibration_item_status)"></i>
                                </button>
                                <button
                                    type="button"
                                    class="btn mr-1 mb-1 btn-primary btn-sm btn-edit text-nowrap"
                                    data-toggle="modal"
                                    data-target="#mit-item-log"
                                    (click)="select(receive)"
                                ><i class="fa fa-history icon-left"></i> ดูประวัติ
                                </button>
                                <button
                                    *ngIf="pageType =='show' && transportreceive?.doc_status_id =='RECEIVE_WAITING_FOR_CONFIRM' && receive.item_status_id =='WAITING_FOR_CONFIRM' && (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                        'SALES_DEP'
                                      )"
                                    type="button"
                                    data-toggle="modal"
                                    data-target="#add-quotation-item"
                                    class="btn mr-1 mb-1 btn-primary btn-sm btn-edit text-nowrap"
                                    (click)="select(receive)"
                                ><i class="fa fa-plus icon-left"></i> Mapping ใบเสนอราคา
                                </button>
                                <p class="text-warning pointer" (click)="downloadZip(receive)" *ngIf="receive?.files?.length > 0">
                                    [Download]
                                </p>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="receiveItems.length == 0">
                        <tr>
                            <td [attr.colspan]="12" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-add-quotation-item 
*ngIf="customer_info && receive"
[receive]="receive"
[customer_info]="customer_info"
(done)="addMitItem($event);">
</app-add-quotation-item>

<app-confirm-delete
  [id]="'modalConfirmDelMsgReceiveItem'"
  (save)="delete($event)"
></app-confirm-delete>

<app-receive-item-edit 
    *ngIf="itemStatus && receive"
    [transportreceive]="transportreceive"
    [itemStatus]="itemStatus" 
    [receive]="receive"
    (done)="editReceiveItem($event);">
</app-receive-item-edit>

<app-receive-item-edit-all 
*ngIf="itemStatus"
[itemStatus]="itemStatus" 
(done)="editReceiveItemAll($event);"
>
</app-receive-item-edit-all>

<app-return-item-edit
    *ngIf="itemStatus && receive && isReturn"
    [transportreceive]="transportreceive"
    [itemStatus]="itemStatus" 
    [receive]="receive"
    (done)="editReturnItem($event);">
</app-return-item-edit>

<app-transport-receive-item-create 
    *ngIf="customer_info && olditemStatus && userInfo && isCreateTranportItem"
    [receiveItems]="receiveItems"
    [userInfo]="userInfo"
    [itemStatus]="olditemStatus"
    [customer_info]="customer_info"
    (done)="submitReceiveItem($event);">
</app-transport-receive-item-create>

<app-transport-receive-item-create-no-item 
    (done)="submitReceiveItemNoQT($event);">
</app-transport-receive-item-create-no-item>

<app-mit-item-log 
*ngIf="receive"
(done)="resetMitItem($event)"
[mitItem]="receive">
</app-mit-item-log>