<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">รายการหน่วยนับ</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/unit']">ตั้งค่าหน่วยนับ</a>
              </li>
              <li class="breadcrumb-item active">รายการหน่วยนับ</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            type="button"
            class="btn btn-outline-primary mr-1"
            [routerLink]="['/unit/unit-type']"
          >
            <i class="fa fa-wrench icon-left"></i> จัดการประเภท
          </button>

          <button
            type="button"
            class="btn btn-outline-primary"
            data-toggle="modal"
            data-target="#create-unit"
          >
            <i class="fa fa-plus-circle icon-left"></i> เพิ่มหน่วยนับ
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">หน่วยนับทั้งหมด</h4>
              <div class="heading-elements">
                <search-box (search)="search($event)"></search-box>
              </div>
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center">ลำดับ</th>

                        <th>ชื่อหน่วยนับ</th>
                        <th class="text-center">หน่วยย่อสากล</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody class="unit-list">
                      <tr *ngFor="let unit of units; let i = index">
                        <th class="text-center">{{ unit.i }}</th>

                        <td>
                          {{ unit.unit_name_en }}
                          <p>
                            <small
                              >ประเภท :
                              {{ unit.unit_type_info.unit_type_name_en }}</small
                            >
                          </p>
                        </td>
                        <td
                          class="text-center"
                          [innerHTML]="unit.unit_short_name_en"
                        ></td>
                        <td>
                          <button
                            class="btn mr-1 mb-1 btn-warning btn-sm btn-edit"
                            data-target="#edit-unit"
                            data-toggle="modal"
                            (click)="select(unit)"
                          >
                            <i class="fa fa-pencil icon-left"></i> แก้ไข
                          </button>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 btn-danger btn-sm"
                            data-target="#modalConfirmDelMsgUnit"
                            data-toggle="modal"
                            (click)="select(unit)"
                            [disabled]="unit.isUsed"
                          >
                            <i class="fa fa-trash icon-left"></i> ลบ
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="!units.length">
                        <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
      <app-unit-create
        [unit-types]="unitTypes"
        (done)="doReset($event)"
      ></app-unit-create>
      <app-unit-edit
        *ngIf="unit"
        [unit]="unit"
        [unit-types]="unitTypes"
        (done)="doReset($event)"
      ></app-unit-edit>
    </div>
  </div>
</div>

<app-confirm-delete
  [id]="'modalConfirmDelMsgUnit'"
  (save)="delete($event)"
></app-confirm-delete>
