import Axios from "../axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class AccBillingNoteInfoService {
  async create({
    doc_no,
    revise_count,
    doc_date,
    doc_note,
    customer_id,
    customer_address_id,
    grand_total,
    doc_status_id,
    appointment_date,
    appointment_location,
    appointment_time_from,
    appointment_time_to,
    sent_at,
    sent_by
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-billing-note-info`, {
        doc_no,
        revise_count,
        doc_date,
        doc_note,
        customer_id,
        customer_address_id,
        grand_total,
        doc_status_id,
        appointment_date,
        appointment_location,
        appointment_time_from,
        appointment_time_to,
        sent_at: sent_at? sent_at: undefined,
        sent_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    acc_billing_note_info_id,
    doc_no,
    revise_count,
    doc_date,
    doc_note,
    customer_id,
    customer_address_id,
    grand_total,
    doc_status_id,
    appointment_date,
    appointment_location,
    appointment_time_from,
    appointment_time_to,
    is_master_booking,
    sent_at,
    sent_by
  }) {
    let result = null;
    try {
      result = await Axios().put(`/acc-billing-note-info/${acc_billing_note_info_id}`, {
        doc_no,
        revise_count,
        doc_date,
        doc_note,
        customer_id,
        customer_address_id,
        grand_total,
        doc_status_id,
        appointment_date,
        appointment_location,
        appointment_time_from,
        appointment_time_to,
        is_master_booking,
        sent_at: sent_at? sent_at: undefined,
        sent_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_billing_note_info_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-billing-note-info/${acc_billing_note_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ acc_billing_note_info_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-info/${acc_billing_note_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getCode(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-code`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
