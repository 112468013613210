<div
  modal
  class="modal fade text-left"
  id="billing-update-sent-info"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreateModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="ItemCreateModal">
            {{type == 'passenger'? 'กำหนด Messenger': 'กำหนดส่งจริง'}}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" autocomplete="off" class="form" [formGroup]="form">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <form-group
                      *ngIf="type == 'passenger'"
                      [control]="form.controls.sent_by"
                      [submitted]="submitted"
                      >
                        <label for="sent_by">
                          Messenger <span class="danger">*</span>
                        </label>
                        <ng-select2
                          [data]="transportContactList"
                          class="w-100"
                          id="sent_by"
                          formControlName="sent_by"
                          placeholder="ผู้ส่งเอกสาร"
                        >
                        </ng-select2>
                    </form-group>

                    <form-group
                        *ngIf="type == 'date'"
                        [control]="form.controls.sent_at"
                        [submitted]="submitted">
                        <label for="sent_at">
                            วันที่ส่งจริง <span class="danger">*</span>
                        </label>
                        <div class="input-group mt-0">
                            <input
                            date-picker
                            type="text"
                            placeholder="วันที่ส่งจริง"
                            id="sent_at"
                            [max-date]="undefined"
                            [date]="form.value.sent_at"
                            class="form-control"
                            formControlName="sent_at"
                            />
                            <div
                            class="input-group-append"
                            for="sent_at"
                            >
                            <span class="input-group-text"
                                ><i class="feather icon-calendar"></i
                            ></span>
                            </div>
                        </div>
                    </form-group>

                    <!-- <form-group
                      [control]="form.controls.return_transport_type_id"
                      [submitted]="submitted"
                      >
                        <label for="return_transport_type_id">
                          ด้วยวิธี <span class="danger">*</span>
                        </label>
                        <ng-select2
                          [data]="transportTypes"
                          class="w-100"
                          id="return_transport_type_id"
                          formControlName="return_transport_type_id"
                          placeholder="วิธีรับเครื่องมือ"
                        >
                        </ng-select2>
                    </form-group> -->
                  </div>

                </div>
              </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
          (click)="close()"
        >
          <i class="fa fa-times icon-left"></i> ปิด
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="submit()"
        >
          <i class="fa fa-save icon-left"></i> บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
