import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class ReportService {
  async create({
    report_type_id,
    report_name,
    status,
    http_body,
    report_desc,
    doc_url
  }) {
    let result = null;
    try {
      result = await Axios().post(`/reports`, {
        report_type_id,
        report_name,
        status,
        http_body,
        report_desc,
        doc_url
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/reports`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getReportType() {
    let result = null;
    try {
      result = await Axios().get(`/report-type`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
