<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-8 col-12 mb-2">
        <h3 class="content-header-title mb-1">
          รายการ Scope
        </h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/scope']">ข้อมูลบริการสอบเทียบ</a>
              </li>
              <li class="breadcrumb-item active">
                รายการ Scope
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-4 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            type="button"
            class="btn btn-outline-primary mr-1"
            [routerLink]="['/scope/cp']"
          >
            <i class="fa fa-wrench icon-left"></i> จัดการ CP
          </button>

          <button
            type="button"
            class="btn btn-outline-primary mr-1"
            [routerLink]="['/scope/method']"
          >
            <i class="fa fa-wrench icon-left"></i> จัดการ Method
          </button>

          <button
            type="button"
            class="btn btn-outline-primary"
            data-toggle="modal"
            data-target="#create-scope"
          >
            <i class="fa fa-plus-circle icon-left"></i> เพิ่ม Scope
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">
                รายการ Scope
              </h4>
              <div class="heading-elements">
                <search-box (search)="search($event)"></search-box>
              </div>
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="5%" class="text-center">ลำดับ</th>
                        <th>Scope name</th>
                        <th width="20%" class="text-center">จำนวน Equipment</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let scope of scopes; let i = index">
                        <th class="text-center">{{ scope.i }}</th>
                        <td>{{ scope.scope_name }} ({{scope.scope_name_short}})</td>
                        <td class="text-center">
                          {{ scope.count_mit_equipment }}
                        </td>
                        <td>
                          <button
                            class="btn mr-1 mb-1 btn-warning btn-sm btn-edit"
                            data-target="#edit-scope"
                            data-toggle="modal"
                            (click)="select(scope.mit_scope_id)"
                          >
                            <i class="fa fa-pencil icon-left"></i> แก้ไข
                          </button>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 btn-info btn-sm"
                            [routerLink]="[
                              '/scope/' + scope.mit_scope_id + '/equipment'
                            ]"
                          >
                            <i class="fa fa-pencil icon-left"></i> จัดการ
                            Equipment
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="!scopes.length">
                        <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
      <app-scope-create (done)="doReset($event)"></app-scope-create>
      <app-scope-edit
        [mit-scope-id]="mit_scope_id"
        *ngIf="mit_scope_id"
        (done)="doReset($event)"
      ></app-scope-edit>
      <!-- <app-method-crud (done)="doReset($event)"></app-method-crud> -->
    </div>
  </div>
</div>
