import { Directive, ElementRef } from "@angular/core";
declare const $;
@Directive({
  selector: "[dropdown-select]",
})
export class DropdownSelectDirective {

  constructor(el: ElementRef) {
    setTimeout(() => {
      let jqueryEl = $(el.nativeElement)
      jqueryEl.selectpicker();
    },500);
  }

}
