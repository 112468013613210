import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  EmployeeService,
  EmployeePositionService,
  EmployeeLevelService,
  EmployeeDepartmentService,
  UserService,
  UtilsService,
  UploadService,
} from "src/app/services";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";
import {
  THValidator,
  ENValidator,
  EmailValidator,
  AbsNumberValidator,
} from "src/app/validators";
import moment from "moment";
import { DownloadFileService } from "src/app/services/quotations";
@Component({
  selector: "app-user-create",
  templateUrl: "./user-create.component.html",
  styleUrls: ["./user-create.component.css"],
})
export class UserCreateComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  status: any;

  departments: [];
  levels: [];
  positions: [];

  emp_department_id: Subscription;
  file = null;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private UserService: UserService,
    private EmployeeService: EmployeeService,
    private EmployeeDepartmentService: EmployeeDepartmentService,
    private EmployeePositionService: EmployeePositionService,
    private EmployeeLevelService: EmployeeLevelService,
    private DownloadFileService: DownloadFileService,
    private UploadService: UploadService,
    private UtilsService: UtilsService
  ) {}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, EmailValidator()]],
      password: ["", [Validators.required, Validators.minLength(6)]],
      firstname_th: ["", [Validators.required, THValidator()]],
      lastname_th: ["", [Validators.required, THValidator()]],
      firstname_en: ["", [Validators.required, ENValidator()]],
      lastname_en: ["", [Validators.required, ENValidator()]],

      date_of_birth: ["", Validators.required],
      mobile_no: ["", [Validators.required]],
      user_role_id: ["USER"],
      user_state_id: ["ACTIVE"],

      // employee
      emp_department_id: ["", Validators.required],
      emp_level_id: ["", Validators.required],
      emp_position_id: ["", Validators.required],
      is_admin: [""],
      secure_code: [""],
      secure_code_last_update: [""],
      name_code: [""],
      nickname_th: ["", [THValidator()]],
      nickname_en: ["", [ENValidator()]],
      idcard_file: [""],
      iplus_signature_filename: [""],
    });

    this.EmployeeDepartmentService.load().then((result) => {
      this.departments = result.resultData;
    });
    this.EmployeeLevelService.load().then((result) => {
      this.levels = result.resultData;
    });

    this.emp_department_id = this.form
      .get("emp_department_id")
      .valueChanges.subscribe((emp_department_id) => {
        this.form.controls.emp_position_id.setValue("");
        if (!emp_department_id) {
          this.positions = [];
          return;
        }

        this.EmployeePositionService.load({ emp_department_id }).then(
          (result) => {
            this.positions = result.resultData;
          }
        );
      });
  }

  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.generateSecureCode();
    let data = {
      ...this.form.value,
      user_role_id: this.form.value.is_admin ? "ADMIN" : "USER",
      secure_code_last_update: new Date()
    };
    if(this.file?.name){
      let result = await this.UploadService.post(this.file, 'users');
      if (result.success) {
        data.idcard_file = result.resultData.path;
      }
    };

    let userResponse = await this.UserService.create(data);
    if (userResponse.success) {
      let employeeResponse = await this.EmployeeService.create({
        user_id: userResponse.resultData.user_id,
        emp_department_id: this.form.get("emp_department_id").value,
        emp_level_id: this.form.get("emp_level_id").value,
        emp_position_id: this.form.get("emp_position_id").value,
      });
      if (employeeResponse.success) {
        this.form.reset();
        this.router.navigateByUrl("/users", {
          state: {
            status: employeeResponse.success,
          },
        });
      } else {
        await this.UserService.delete({
          user_id: userResponse.resultData.user_id,
        });
      }
    } else {
      if (userResponse.resultCode === "40900") {
        Swal.fire(
          userResponse.error.resultTitle.th,
          userResponse.error.resultDesc.th,
          userResponse.error.bootstrapClass
        );
      } else {
        this.status = userResponse.error;
      }
    }
  }

  generatePassword() {
    this.form.controls.password.setValue(this.UtilsService.generatePassword(6));
  }
  generateSecureCode(){
    this.form.controls.secure_code.setValue(this.UtilsService.generatePassword(4));
  }
  ngOnDestroy() {
    this.emp_department_id.unsubscribe();
  }

  uploadFile(){
    document.getElementById("importFile").click();
  }
  
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.file = f;
    });
  }
  downloadFile(file){
    if(file.name){
      this.DownloadFileService.downloadFile(file);
    }else{
      this.DownloadFileService.downloadFileURL({file_path: file, file_name: 'ID Card.' + file.split('.')[1]});
    }
  }
}
