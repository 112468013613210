<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">ใบสำคัญรับ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/billing-note']">เอกสารทางบัญชี</a>
                </li>
                <li class="breadcrumb-item active">ใบสำคัญรับ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button
            (click)="back()"
            type="button"
            class="btn btn-light btn-min-width"
            >
            <i class="fa fa-arrow-left"></i>  กลับ
            </button>
            <button
              *ngIf="(receivedVoucherInfo?.doc_status_id === 'DRAFT' || receivedVoucherInfo?.doc_status_id === 'REJECTED')"
              type="button"
              class="btn btn-danger btn-min-width ml-1"
              (click)="cancel()"
              >
              <i class="fa fa-times icon-left"></i> ยกเลิก
            </button>
            <button
            *ngIf="(receivedVoucherInfo?.doc_status_id === 'DRAFT' || receivedVoucherInfo?.doc_status_id === 'REJECTED')"
            [routerLink]="['/received-voucher/' + receivedVoucherInfo.acc_received_voucher_info_id + '/edit']"
            type="button"
            class="btn btn-warning btn-min-width ml-1"
            >
            <i class="fa fa-pencil icon-left"></i>  แก้ไข
            </button>
            <button
              *ngIf="receivedVoucherInfo?.doc_status_id =='WAITING_FOR_APPROVE' && (userInfo.emp_info?.emp_department_id.startsWith(
                'ACC_DEP'
              ) && (userInfo?.emp_info?.emp_level_id === 'MANAGER' || userInfo?.emp_info?.emp_level_id === 'SUPERVISOR'))"
              (click)="approve()"
              type="button"
              class="btn btn-success btn-min-width ml-1"
              >
              <i class="fa fa-check-circle-o"></i>  อนุมัติ
            </button>
            <button
              *ngIf="receivedVoucherInfo?.doc_status_id =='WAITING_FOR_APPROVE' && (userInfo.emp_info?.emp_department_id.startsWith(
                'ACC_DEP'
              ) && (userInfo?.emp_info?.emp_level_id === 'MANAGER' || userInfo?.emp_info?.emp_level_id === 'SUPERVISOR'))"
              (click)="reject()"
              type="button"
              class="btn btn-danger btn-min-width ml-1"
              >
              <i class="fa fa-times-circle-o"></i>  ไม่อนุมัติ
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="!pageLoad"></loading>

        <div class="card" *ngIf="pageLoad">
          <div class="card-header">
              <h4 class="card-title d-inline mt-1">ใบสำคัญรับ</h4>
              <div class="heading-elements">
              </div>
              <div class="float-md-right">
                <button 
                  type="button"
                  class="btn btn-blue btn-min-width mr-1 mb-1 "
                  target="_blank"
                  [routerLink]="[
                  '/acc-document/received_voucher/' + receivedVoucherInfo?.acc_received_voucher_info_id
                  ]"
                  >
                      <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                </button>
              </div>
          </div>

          <div class="card-content collapse show">
              <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
                  <div class="card-body">
                      <div class="form-body">
                        <div class="row">
                            <div class="col-xl-3 col-lg-6 col-md-12">
                                <form-group
                                    [control]="form.controls.customer_id"
                                    [submitted]="submitted"
                                  >
                                  <label for="customer_id">
                                    ชื่อลูกค้า
                                  </label>
                                  <input
                                    type="text"
                                    id="customer_name"
                                    class="form-control"
                                    placeholder="ชื่อลูกค้า"
                                    formControlName="customer_name"
                                    readonly
                                  />
                                </form-group>
                            </div>

                            <div class="col-xl-3 col-lg-6 col-md-12">
                                <form-group
                                [control]="form.controls.customer_address_id"
                                [submitted]="submitted"
                                >
                                  <label for="customer_address_id">
                                    ออกเอกสารในนาม
                                  </label>
                                  <input
                                  type="text"
                                  id="customer_address_id"
                                  class="form-control"
                                  placeholder="ออกเอกสารในนาม"
                                  formControlName="customer_address_id"
                                  readonly
                                />
                              </form-group>
                            </div>

                            <div class="col-xl-3 col-lg-6 col-md-12">
                                <form-group
                                [control]="form.controls.doc_date"
                                [submitted]="submitted">
                                <label for="doc_date">
                                    วันที่ออก 
                                </label>
                                <div class="input-group mt-0">
                                    <input
                                    date-picker
                                    type="text"
                                    id="doc_date"
                                    [max-date]="undefined"
                                    [date]="form.value.doc_date"
                                    class="form-control"
                                    formControlName="doc_date"
                                    />
                                    <div
                                    class="input-group-append"
                                    for="doc_date"
                                    >
                                    <span class="input-group-text"
                                        ><i class="feather icon-calendar"></i
                                    ></span>
                                    </div>
                                </div>
                                </form-group>
                            </div>

                            <div class="col-xl-3 col-lg-6 col-md-12">
                                <form-group
                                [control]="form.controls.doc_no"
                                [submitted]="submitted">
                                <label for="doc_no">
                                    เลขที่เอกสาร
                                </label>
                                <input
                                    type="text"
                                    id="doc_no"
                                    class="form-control"
                                    placeholder="เลขที่เอกสาร"
                                    formControlName="doc_no"
                                    readonly
                                />
                                </form-group>
                            </div>
                        </div>

                        <div class="row">
                        
                            <div class="col-lg-6 col-md-12">
                              <form-group
                                    [control]="form.controls.customer_address"
                                    [submitted]="submitted"
                                  >
                                    <label for="customer_address">
                                      ที่อยู่ 
                                    </label>
                                    <input
                                    type="text"
                                    id="customer_address"
                                    class="form-control"
                                    placeholder="ที่อยู่"
                                    formControlName="customer_address"
                                    readonly
                                    />
                                </form-group>
                            </div>

                        </div>

                      </div>

                      <div>
                        <app-received-voucher-item 
                        *ngIf="customer_info"
                        [customer_info]="customer_info"
                        (done)="getReceivedVoucherItems($event)">
                        </app-received-voucher-item>
                      </div>

                      <div class="pb-2">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 mt-75">
                                <form-group
                                [control]="form.controls.doc_note"
                                [submitted]="submitted">
                                <label for="doc_note">
                                    หมายเหตุ
                                </label>
                                <textarea
                                  placeholder="หมายเหตุ"
                                  class="form-control"
                                  rows="3"
                                  id="doc_note"
                                  formControlName="doc_note"
                                  >
                                </textarea>
                                </form-group>
                            </div>

                        </div>
                    </div>
                  </div>
              </form>
          </div>
      </div>
      </div>

      <app-received-voucher-history *ngIf="pageLoad">
      </app-received-voucher-history>
    </div>
</div>


