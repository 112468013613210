<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="credit-note-item-create"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreate1Modal">
          เลือกรายการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12 col-sm-12 text-right">
                    <h4>{{selectedCount}} รายการ</h4>
                  </div>
                </div>
            </div>

            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <ul class="nav nav-tabs" role="tablist">
                        <li class="nav-item">
                          <a
                            class="nav-link active px-4"
                            id="product-tab"
                            data-toggle="tab"
                            href="#product"
                            aria-controls="product"
                            role="tab"
                            aria-selected="true"
                            >Product</a
                          >
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link px-4"
                            id="calibration-tab"
                            data-toggle="tab"
                            href="#calibration"
                            aria-controls="calibration"
                            role="tab"
                            aria-selected="false"
                            >Calibration</a
                          >
                        </li>
                      </ul>

                      <div class="tab-content pt-1">
                        <div
                          class="tab-pane active in"
                          id="product"
                          aria-labelledby="product-tab"
                          role="tabpanel">
                          <div class="table-responsive" style="max-height: 600px;">
                            <table class="table table-striped table-bordered">
                                <thead> 
                                    <tr>
                                        <th>
                                          <span>
                                            <input 
                                            *ngIf="productList.length > 0"
                                            type="checkbox"
                                            id="select_product_all"
                                            (change)="onCheckProductAll($event)"
                                            >
                                          </span>
                                        </th>
                                        <th>สินค้าบริการ</th>
                                        <th>คำอธิบาย</th>
                                        <th class="text-right">ราคา</th>
                                        <th class="text-right">ส่วนลด</th>
                                        <th class="text-right">จำนวน</th>
                                        <th class="text-right">รวม</th>
                                    </tr>
                                </thead>
                                <thead *ngFor="let product of productList; let i = index" >
                                    <tr>
                                        <td>
                                          <input 
                                          type="checkbox"
                                          [id]="'select_'+i"
                                          [(ngModel)]="product.select"
                                          [ngModelOptions]="{standalone: true}"
                                          (click)="setCount()"
                                          >
                                        </td>
                                     
                                        <td>
                                          {{product.product_title}}
                                        </td>
                                        <td>
                                          {{product.product_description}}
                                        </td>
                                        <td class="text-right">
                                          {{product.price | number : '1.2-2'}}
                                        </td>
                                        <td class="text-right">
                                          {{product.discount | number : '1.2-2'}}
                                        </td>
                                        <td class="text-right">
                                          {{product.quantity}}
                                        </td>
                                        <td class="text-right">
                                          {{calculate.sumProduct(product) | number : '1.2-2'}}
                                        </td>
                                    </tr>
                                </thead>
            
                                <tbody *ngIf="productList.length == 0">
                                    <tr>
                                        <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
            
                        </div>
                        <div
                          class="tab-pane"
                          id="calibration"
                          aria-labelledby="calibration-tab"
                          role="tabpanel">
                          <div class="table-responsive" style="max-height: 600px;">
                            <table class="table table-striped table-bordered">
                                <thead> 
                                    <tr>
                                        <th>
                                          <span>
                                            <input 
                                            *ngIf="calibrationList.length > 0"
                                            type="checkbox"
                                            id="select_cali_all"
                                            (change)="onCheckCaliAll($event)"
                                            >
                                          </span>
                                        </th>
                                        <th>สินค้าบริการ</th>
                                        <th>คำอธิบาย</th>
                                        <th class="text-right">ราคา</th>
                                        <th class="text-right">ส่วนลด</th>
                                        <th class="text-right">จำนวน</th>
                                        <th class="text-right">รวม</th>
                                    </tr>
                                </thead>
                                <thead *ngFor="let calibration of calibrationList; let i = index" >
                                    <tr>
                                        <td>
                                          <input 
                                          type="checkbox"
                                          [id]="'select_'+i"
                                          [(ngModel)]="calibration.select"
                                          [ngModelOptions]="{standalone: true}"
                                          (click)="setCount()"
                                          >
                                        </td>
                                        <td>
                                          {{calibration.product_title}}
                                        </td>
                                        <td>
                                          <small>
                                            Description: {{calibration.data.description | dash}}<br/>
                                            Serial no: {{calibration.data.serial_no | dash}}<br/>
                                            Point: 
                                            <span *ngFor="let detail of calibration.data.quotation_mit_item_detail_info; let dLastIndex = last;">
                                              <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0">
                                                  <span *ngFor="let point of detail.quotation_mit_item_detail_points_info; let pLastIndex = last;">
                                                    {{point?.cus_point}} <span *ngIf="!pLastIndex">,</span>
                                                  </span>
                                              </span>
                                              <span *ngIf="detail.quotation_mit_item_detail_ranges_info.length > 0">
                                                <span *ngFor="let range of detail.quotation_mit_item_detail_ranges_info; let rLastIndex = last;">
                                                  {{range?.cus_min}} - {{range?.cus_max}}
                                                </span>
                                              </span>
                                              <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0">{{detail?.unit_info?.unit_short_name_th}}</span>
                                              <span *ngIf="!dLastIndex && (detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0)">, </span>
                                            </span>
                                          </small>
                                        </td>
                                        <td class="text-right">
                                          {{calibration.price | number : '1.2-2'}}
                                        </td>
                                        <td class="text-right">
                                          {{calibration.discount | number : '1.2-2'}}
                                        </td>
                                        <td class="text-right">
                                          {{calibration.quantity}}
                                        </td>
                                        <td class="text-right">
                                          {{calculate.sumMit(calibration) | number : '1.2-2'}}
                                        </td>
                                    </tr>
                                </thead>
            
                                <tbody *ngIf="calibrationList.length == 0">
                                    <tr>
                                        <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                  </div>
                </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>เพิ่มรายการ
        </button>
      </div>
    </div>
  </div>
</div>
