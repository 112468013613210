import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  NgZone,
  Input,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SalesTeamService } from "src/app/services";

@Component({
  selector: "app-sales-team-create",
  templateUrl: "./sales-team-create.component.html",
  styleUrls: ["./sales-team-create.component.css"],
})
export class SalesTeamCreateComponent implements OnInit {
  status = null;
  form: FormGroup;
  submitted = false;
  isShow: boolean;
  @Input() employees = [];
  @Input() coSales = [];
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,

    private formBuilder: FormBuilder,
    private SalesTeamService: SalesTeamService
  ) {
    this.setForm();
  }
  setForm(): void {
    this.form = this.formBuilder.group({
      sales_team_name: ["", Validators.required],
      team_leader_id: ["", Validators.required],
      team_cosale_id: ["", Validators.required],
    });
  }
  ngOnInit(): void {}

  async onSubmit() {
    this.status = null;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let response = await this.SalesTeamService.create(this.form.value);
    // if (response.resultCode === "40900") {
    //   response.error.resultDesc.th =
    //     "มี user นี้อยู่ในรายการ หรือ เป็นหัวหน้าทีมขายแล้ว";
    //   this.status = response.error || response.success;
    //   return;
    // }

    this.done.emit(response.success || response.error);
    this.closeModalBtn.nativeElement.click();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.status = null;
        this.setForm();
      }
    });
  }
}
