
import { Injectable } from "@angular/core";
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: "root",
})
export class DownloadFileService {

    constructor() {}

    downloadFile(file) {
        var fr = new FileReader();
        fr.readAsDataURL(file);

        var blob = new Blob([file]);

        var objectURL = window.URL.createObjectURL(blob);

        var link = document.createElement('a');
        link.href = objectURL;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    downloadFileURL(file) {
        var uploadPath = environment.uploadPath;
        let file_path = file.file_path.charAt(0) == '/' ? file.file_path.substring(1) : file.file_path;
        var url = uploadPath + file_path;
        var xhr = new XMLHttpRequest();

        xhr.open('GET', url, true);
        xhr.responseType = 'blob';

        xhr.onprogress = function (event) {
            if (event.lengthComputable) {
                var percentComplete = (event.loaded / event.total) * 100;
            }
        };

        xhr.onload = function (event) {
            if (this.status == 200) {
                _saveBlob(this.response, file.file_name);
            }
            else {}
        };

        xhr.onerror = function (event) {
        };

        xhr.send();
    }

    downloadReportURL(report) {
        var xhr = new XMLHttpRequest();

        xhr.open('GET', report.doc_url, true);
        xhr.responseType = 'blob';

        xhr.onprogress = function (event) {
            if (event.lengthComputable) {
                var percentComplete = (event.loaded / event.total) * 100;
            }
        };

        xhr.onload = function (event) {
            if (this.status == 200) {
                _saveBlob(this.response, report.report_desc);
            }
            else {}
        };

        xhr.onerror = function (event) {
        };

        xhr.send();
    }
    
    isFileImage(file) {
        return file && file['type'].split('/')[0] === 'image';
    }
}

function _saveBlob(response, fileName) {
    if (navigator.msSaveBlob) {
        navigator.msSaveBlob(response, fileName);
    }
    else {
        _html5Saver(response, fileName);
    }
}

function _html5Saver(blob, fileName) {
    var link = document.createElement("a");
    document.body.appendChild(link);
    var url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = fileName;
    link.click();
    document.body.removeChild(link);
}
