import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class GETService {
  async districts(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/geo-districts`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async amphures(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/geo-amphures`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async provinces(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/geo-provinces`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async graphies(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/geo-graphies`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async industryGroups(filter?) {
    let result = null;
    try {
      result = await Axios().get(
        `/industry-group
`,
        {
          params: { ...filter },
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async industrys(filter?) {
    let result = null;
    try {
      result = await Axios().get(
        `/industry
`,
        {
          params: { ...filter },
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async customerAddressType(filter?){
    let result = null;
    try {
      result = await Axios().get(
        `/customer-address-type
`,
        {
          params: { ...filter },
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async activityTypes(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/customer-activity-type`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getCustomerActivityTypeById(customer_acitivity_type_id) {
    let result = null;
    try {
      result = await Axios().get(
        `/customer-activity-type/${customer_acitivity_type_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async customerLevels(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/customer-level`, {
        params: { ...filter, 'order_by': 'order_by:asc'  },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async customerStates(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/customer-state`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  
  async transportType(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/transport-type`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async itemStatus(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/item-status`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async carTypes(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/car-type`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async paymentMethod(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/payment-method`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async zip(path) {
    let result = null;
    try {
      result = await Axios().post(
        `/zip`, {
        paths: path,
      },
      {
        responseType: 'blob'
      }
      );
      result = result;
    } catch (error) {
      result = error;
    }
    return result;
  }

  async taxInvoiceExcel(acc_tax_invoice_info_id) {
    let result = null;
    try {
      result = await Axios().get(`/acc-tax-invoice-info/${acc_tax_invoice_info_id}/excel`, {
        responseType: 'blob'
      })
      result = result;
    } catch (error) {
      result = error;
    }
    return result;
  }

  async masterBookingExcel(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/transport-masterbooking/excel`, {
        params: { ...filter },
        responseType: 'blob'
      })
      result = result;
    } catch (error) {
      result = error;
    }
    return result;
  }

  async masterReport(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/master-report`, {
        params: { ...filter },
        responseType: 'blob'
      })
      result = result;
    } catch (error) {
      result = error;
    }
    return result;
  }

  async uploadMasterReport(file?, job_order_info_id?) {
    let result = null;
    let formData = new FormData();
    formData.append("file", file);
    try {
      result = await Axios().post(`/upload-master-report?job_order_info_id=${job_order_info_id}`, formData);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
