<div id="customer-activity-create" sidebar (close)="close($event)">
  <div
    trigger-sidebar
    data-target="#customer-activity-create"
    class="w-100 h-100 position-fixed"
    style="
      left: 0;
      top: 0;
      background-color: black;
      opacity: 0.3;
      display: none;
      z-index: 1;
    "
  ></div>
  <div class="custom-sidebar">
    <div class="card shadow-none p-0 m-0">
      <div class="card-header border-bottom py-1">
        <div
          class="task-header d-flex justify-content-between align-items-center"
        >
          <h5 class="new-task-title mb-0">บันทึกกิจกรรม</h5>
          <span>
            <button
              type="button"
              class="close close-icon"
              trigger-sidebar
              data-target="#customer-activity-create"
              (close)="close($event)"
              #closeSidebar
            >
              <i class="feather icon-x align-middle"></i>
            </button>
          </span>
        </div>
      </div>
      <!-- form start -->
      <form
        id="compose-form"
        class="mt-1"
        [formGroup]="form"
        autocomplete="off"
      >
        <div class="card-content">
          <div class="card-body py-0">
            <form-group
              [control]="form.controls.activity_datetime"
              [submitted]="submitted"
            >
              <label for="activity_datetime">
                วันที่ - เวลา <span class="danger">*</span>
              </label>
              <div class="input-group mt-0">
                <input
                  date-time-picker
                  type="text"
                  id="activity_datetime"
                  class="form-control"
                  formControlName="activity_datetime"
                  (instance)="datetimePicker = $event"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i class="feather icon-calendar"></i
                  ></span>
                </div>
              </div>
            </form-group>

            <form-group
              [control]="form.controls.customer_activity_type_id"
              [submitted]="submitted"
            >
              <label for="customer_activity_type_id">
                ประเภท <span class="danger">*</span>
              </label>
              <select
                class="form-control"
                id="customer_activity_type_id"
                formControlName="customer_activity_type_id"
              >
                <option value="">กรุณาเลือกข้อมูล</option>
                <option
                  [value]="activityType.customer_activity_type_id"
                  *ngFor="let activityType of activityTypes"
                >
                  {{ activityType.activity_type_name_th }}</option
                >
              </select>
            </form-group>

            <form-group [control]="form.controls.title" [submitted]="submitted">
              <label for="title"> หัวข้อ <span class="danger">*</span> </label>
              <input
                type="text"
                id="title"
                class="form-control"
                placeholder="หัวข้อ"
                formControlName="title"
              />
            </form-group>
          </div>

          <div class="card-body pb-1">
            <form-group
              [control]="form.controls.description"
              [submitted]="submitted"
            >
              <label for="description">
                รายละเอียด
              </label>
              <div
                quill
                [html]="true"
                class="quill-custom-height"
                (change)="form.controls.description.setValue($event)"
                (instance)="quill = $event"
              ></div>
            </form-group>
          </div>

          <div class="card-body pb-1">
            <div class="mb-2">
              <div class="card-title d-inline mr-2">แนบไฟล์</div>
              <label
                for="customer_activities_file_id"
                class="btn btn-outline-primary btn-min-width mr-1 mb-1 d-inline"
              >
                <i class="feather icon-upload icon-left"></i> Upload
              </label>
              <input
                type="file"
                class="form-control-file d-none"
                id="customer_activities_file_id"
                (change)="uploadFile($event)"
                multiple
              />
            </div>

            <app-file-list
              [confirm-modal-target]="'#confirmDelModalCreateRemoveFile'"
              [files]="files"
              (remove)="fileToBeRemove = $event"
            ></app-file-list>
          </div>

          <div
            class="card-body pb-1"
            *ngIf="form.value.customer_activity_type_id === 'VISIT'"
          >
            <div class="card-title">ตำแหน่ง</div>
            <!-- Map -->
            <div class="google-map mb-2">
              <input
                type="text"
                class="map-autocomplete-box form-control mb-1"
              />
              <div
                google-map
                [disable-click]="form.disabled"
                id="activity-map-create"
                (change)="setLocation($event)"
              ></div>
            </div>
          </div>

          <div class="card-body pb-2">
            <div class="mt-1 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-warning mr-1"
                trigger-sidebar
                data-target="#customer-activity-create"
              >
                <i class="fa fa-times"></i> ยกเลิก
              </button>
              <button type="button" class="btn btn-primary" (click)="submit()">
                <i class="fa fa-save"></i> บันทึก
              </button>
            </div>
          </div>
        </div>
      </form>
      <!-- form start end-->
    </div>
  </div>
</div>

<app-confirm-delete
  [id]="'confirmDelModalCreateRemoveFile'"
  (save)="removeFile($event)"
  (close)="fileToBeRemove = null"
>
</app-confirm-delete>
