<!-- Revoke Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="revoke"
  tabindex="-1"
  role="dialog"
  aria-labelledby="revokeModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary white">
        <h4 class="modal-title" id="revokeModal">บันทึกการเข้าถือครอง</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="form" autocomplete="off">
          <div class="form-body">
            <div class="row">
              <div class="col-md-6">
                <form-group
                  [control]="form.controls.activity_datetime"
                  [submitted]="submitted"
                >
                  <label for="activity_datetime">
                    วันที่ - เวลา <span class="danger">*</span>
                  </label>
                  <div class="input-group mt-0">
                    <input
                      date-time-picker
                      [max-date]="maxDate"
                      type="text"
                      id="activity_datetime"
                      class="form-control"
                      formControlName="activity_datetime"
                      (instance)="datetimePicker = $event"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text"
                        ><i class="feather icon-calendar"></i
                      ></span>
                    </div>
                  </div>
                </form-group>
              </div>

              <div class="col-md-6">
                <form-group
                  [control]="form.controls.customer_activity_type_id"
                  [submitted]="submitted"
                >
                  <label for="customer_activity_type_id">
                    ประเภท <span class="danger">*</span>
                  </label>
                  <select
                    class="form-control"
                    id="customer_activity_type_id"
                    formControlName="customer_activity_type_id"
                  >
                    <option value="">กรุณาเลือกข้อมูล</option>
                    <option
                      [value]="activityType.customer_activity_type_id"
                      *ngFor="let activityType of activityTypes"
                    >
                      {{ activityType.activity_type_name_th }}
                    </option>
                  </select>
                </form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <form-group
                  [control]="form.controls.emp_code"
                  [submitted]="submitted"
                >
                  <label for="emp_code">
                    ผู้รับผิดชอบ <span class="danger">*</span>
                  </label>

                  <employee-select
                    *ngIf="exclude_user_ids.length"
                    [emp]="
                      (userInfo | async)?.is_co_sales
                        ? null
                        : (userInfo | async)
                    "
                    [placeholder]="'รหัสผู้รับผิดชอบ'"
                    [exclude_user_ids]="exclude_user_ids"
                    (valueChanged)="queryEmployee($event)"
                  >
                  </employee-select>
                </form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <form-group
                  [control]="form.controls.title"
                  [submitted]="submitted"
                >
                  <label for="title">
                    หัวข้อ <span class="danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="title"
                    class="form-control"
                    placeholder="หัวข้อ"
                    formControlName="title"
                  />
                </form-group>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <form-group
                  [control]="form.controls.description"
                  [submitted]="submitted"
                >
                  <label for="description">
                    รายละเอียด <span class="danger">*</span>
                  </label>
                  <div
                    class="quill-custom-height"
                    quill
                    (instance)="quill = $event"
                    [html]="true"
                    (change)="form.controls.description.setValue($event)"
                  ></div>
                </form-group>
              </div>
            </div>

            <div class="row mt-1">
              <div class="col-md-12">
                <div class="mb-2">
                  <span class="mr-1"
                    >แนบไฟล์ <span class="danger">*</span></span
                  >
                  <label
                    for="customer_activities_file_id"
                    class="btn btn-outline-primary btn-min-width mr-1 mb-1 d-inline"
                  >
                    <i class="feather icon-upload icon-left"></i> Upload
                  </label>
                  <input
                    type="file"
                    class="form-control-file d-none"
                    id="customer_activities_file_id"
                    (change)="uploadFile($event)"
                    multiple
                  />
                </div>

                <app-file-list
                  [files]="files"
                  [canRemove]="true"
                  (remove)="fileToBeRemove = $event"
                  [confirm-modal-target]="'#confirmDelModalRemoveFileRevoke'"
                ></app-file-list>

                <div class="help-block" *ngIf="!files.length && submitted">
                  <ul role="alert">
                    <li class="text-danger">โปรดอัพโหลดไฟล์แนบ</li>
                  </ul>
                </div>
              </div>
            </div>

            <div
              class="row mt-3 mb-4"
              *ngIf="form.value.customer_activity_type_id === 'VISIT'"
            >
              <div class="col-md-12">
                <p>ตำแหน่ง</p>
                <div class="google-map">
                  <input
                    type="text"
                    class="map-autocomplete-box form-control mb-1"
                  />
                  <div
                    google-map
                    id="activity-map-create"
                    (change)="setLocation($event)"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>

<app-confirm-delete
  [id]="'confirmDelModalRemoveFileRevoke'"
  (save)="removeFile($event)"
  (close)="fileToBeRemove = null"
>
</app-confirm-delete>
