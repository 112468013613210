import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class UploadService {
  async post(file, directoryName = "others", random = true) {
    let result = null;
    let formData = new FormData();
    formData.append("file", file);
    try {
      result = await Axios().post(`/upload-file/${directoryName}`, formData, {
        params: { random },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
