import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
  QuotationMitItemDetailPointService,
  QuotationMitItemDetailRangeService,
  QuotationMitItemDetailService,
  QuotationUtilsService,
} from "src/app/services/quotations";
import {
  TransportreceiveContactService,
  TransportreceiveInfoService,
  TransportreceiveItemService,
} from "src/app/services/transport-receives";
import jsPDF from "jspdf";
import domtoimage from "dom-to-image-more";
import { UserService } from "src/app/services";
import { Title } from "@angular/platform-browser";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-transport-pdf",
  templateUrl: "./transport-pdf.component.html",
  styleUrls: ["./transport-pdf.component.css"],
})
export class TransportPdfComponent implements OnInit {
  pages = [];
  contactList = [];
  receiveItems = [];
  uploadPath: string;
  textBottomPage: string = "FM-CTM-02, Revise 8, Effective Date 01/11/21";
  transportInfo = null;
  transport_receive_info_id: string;

  constructor(
    private route: ActivatedRoute,
    private TransportreceiveInfoService: TransportreceiveInfoService,
    private TransportreceiveContactService: TransportreceiveContactService,
    private TransportreceiveItemService: TransportreceiveItemService,
    private QuotationMitItemDetailService: QuotationMitItemDetailService,
    private QuotationMitItemDetailPointService: QuotationMitItemDetailPointService,
    private QuotationMitItemDetailRangeService: QuotationMitItemDetailRangeService,
    private UserService: UserService,
    private titleService: Title,
    public utils: QuotationUtilsService
  ) {
    this.transport_receive_info_id = this.route.snapshot.paramMap.get(
      "transport_receive_info_id"
    );
    this.uploadPath = environment.uploadPath;
  }

  async ngOnInit() {
    await this.TransportreceiveInfoService.getById({
      transport_receive_info_id: this.transport_receive_info_id,
    }).then(async (res) => {
      this.transportInfo = res.resultData || {};

      this.titleService.setTitle(
        this.transportInfo.doc_no + " - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี"
      );

      if (this.transportInfo.customer_address_info) {
        this.transportInfo["address"] = this.utils.genAddress(
          this.transportInfo.customer_address_info
        );
      }
      if (this.transportInfo.receiver_id) {
        await this.UserService.getById({
          user_id: this.transportInfo.receiver_id,
        }).then((res) => {
          let data = {
            ...res.resultData,
            signature_file: res.resultData.signature_file
              ? this.uploadPath + res.resultData.signature_file
              : "",
          };
          this.transportInfo["receiver_info"] = data;
        });
      }

      await this.TransportreceiveContactService.load(null, {
        transport_receive_info_id: this.transport_receive_info_id,
      }).then((res) => {
        this.contactList = res.resultData || [];
      });

      await this.TransportreceiveItemService.load(null, {
        transport_receive_id: this.transport_receive_info_id,
        order_by: "order:asc",
      }).then(async (res) => {
        this.receiveItems =
          res.resultData.filter((item) => item.item_status_id != "CANCELED") ||
          [];
        this.receiveItems.sort(function (a, b) {
          if (a.quotation_mit_item_id === b.quotation_mit_item_id) {
            return 0;
          } else if (a.quotation_mit_item_id === null) {
            return -1;
          } else if (b.quotation_mit_item_id === null) {
            return 1;
          }
        });

        this.receiveItems.sort(function (a, b) {
          if (a?.quotation_info?.doc_no < b?.quotation_info?.doc_no) {
            return -1;
          }
          if (a?.quotation_info?.doc_no > b?.quotation_info?.doc_no) {
            return 1;
          }
          return 0;
        });

        await Promise.all(
          this.receiveItems.map(async (value) => {
            if (value.quotation_mit_item_id) {
              await this.QuotationMitItemDetailService.load(null, {
                quotation_mit_item_id: value.quotation_mit_item_id,
                is_non_calculate: "false",
                order_by: "order_by:asc",
              }).then(async (res) => {
                let resultData = res.resultData;

                resultData = resultData.filter(
                  (value) => value.total_amount != 0
                );

                value["quotation_mit_item_details"] = [];
                await Promise.all(
                  resultData.map(async (data) => {
                    let resultData = await this.mapMitItemData(data);

                    value.quotation_mit_item_details.push(resultData);
                  })
                );

                value["quotation_mit_item_details"].sort((a, b) =>
                  a.order_by > b.order_by ? 1 : -1
                );
              });
            } else {
              value["quotation_mit_item_details"] = [];
            }
          })
        );
      });

      for (var i = 0; i < this.receiveItems.length; i++) {
        this.receiveItems[i].index = i;
      }
      for (var i = 0; i < this.receiveItems.length; i += 13) {
        this.pages.push(this.receiveItems.slice(i, i + 13));
      }
      if (this.pages.length == 0) {
        this.pages.push({});
      }
    });
  }

  async mapMitItemData(mit: any): Promise<any> {
    let points = [];
    let range = [];
    if (mit.total_amount > 0) {
      if (mit.detail_input_type == "point") {
        points = await this.QuotationMitItemDetailPointService.load(null, {
          quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id,
          order_by: "order_by:asc",
        }).then((res) => {
          return res.resultData;
        });
      } else {
        range = await this.QuotationMitItemDetailRangeService.load(null, {
          quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id,
        }).then((res) => {
          return res.resultData;
        });
      }
    }

    await points.map((point) => {
      if (point.cus_unit_info)
        point["cus_unit_name"] =
          point.cus_unit_info.unit_short_name_en ||
          point.cus_unit_info.unit_short_name_th;
      if (point.mit_unit_info)
        point["mit_unit_name"] =
          point.mit_unit_info.unit_short_name_en ||
          point.mit_unit_info.unit_short_name_th;
    });
    return {
      ...mit,
      detail_type: mit.detail_input_type,
      quotation_mit_item_detail_range: {
        quotation_mit_item_detail_range_id:
          range[0]?.quotation_mit_item_detail_range_id || "",
        cus_min: range[0]?.cus_min || 0,
        cus_max: range[0]?.cus_max || 0,
        cus_unit_id: range[0]?.cus_unit_id || "",
        cus_unit_name: range[0]?.cus_unit_info.unit_short_name_en || "",
        mit_min: range[0]?.mit_min || 0,
        mit_max: range[0]?.mit_max || 0,
        mit_unit_id: range[0]?.mit_unit_id || "",
        mit_unit_name: range[0]?.mit_unit_info.unit_short_name_en || "",
      },
      isISO: mit.product_calibration_fee_info.isISO,
      quotation_mit_item_detail_points: points,
    };
  }

  async print(transportInfo) {
    let doc = new jsPDF("p", "mm", "a4", true);
    doc.setFontSize(8);

    let pages = document.querySelectorAll(".pdf");
    let list = [];
    await Promise.all(
      Array.prototype.slice.call(pages, 0).map(async (value, index) => {
        let transport = document.getElementById("transport_" + index);
        await domtoimage
          .toJpeg(transport, {
            background: "white",
            allowTaint: true,
            width: transport.clientWidth * 2,
            height: transport.clientHeight * 2,
            style: {
              transform: "scale(" + 2 + ")",
              transformOrigin: "top left",
            },
            quality: 0.95,
          })
          .then(async function (canvas) {
            list.push({ index: index, canvas: canvas, transport: transport });
          });
      })
    );

    list.sort(function (a, b) {
      return a.index - b.index;
    });
    await Promise.all(
      list.map(async (value, index) => {
        var imgWidth = 208;
        var imgHeight =
          (value.transport.clientHeight * imgWidth) /
          value.transport.clientWidth;

        if (index != 0) doc.addPage("a4");
        doc.addImage(value.canvas, "JPEG", 0, 0, imgWidth, imgHeight, null);
        let page = index + 1;

        doc.text(this.textBottomPage, 130, 285);
        doc.text(page + "", 200, 285);
      })
    );
    setTimeout(() => {
      doc.save(transportInfo.doc_no);
    }, 1000);
  }

  pointLength(points): number {
    return points.filter((point) => point.is_non_calculate == "false").length;
  }
}
