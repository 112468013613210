<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="billing-note-item-create"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreate1Modal">
          เลือกรายการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-body">
            <div class="form-group row mb-1">
              <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                <search-box (search)="search($event)"></search-box>
              </div>

              <div class="col-12 col-sm-12 col-md-9 col-lg-9 text-right">
                <h4>{{number_of_select}} รายการ</h4>
              </div>
            </div>
        </div>

        <div class="form-body">
            <div class="form-group row mb-0">
              <div class="col-12 col-sm-12">
                <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                              <th>
                                <span>
                                  <input 
                                  *ngIf="isShowCheckAll"
                                  type="checkbox"
                                  id="select_all"
                                  (change)="onCheckAll($event)"
                                  >
                                </span>
                              </th>
                              <th class="text-center">เลขที่เอกสาร</th>
                              <th>วันที่ออก</th>
                              <th>วันนัดชำระ</th>
                              <th class="text-right">มูลค่า (บาท)</th>
                              <th class="text-center">สถานะ</th>
                            </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let invoice of invoices; let i = index">
                            <td>
                              <div *ngIf="!invoice.acc_billing_note_info_id">
                                <input 
                                type="checkbox"
                                [id]="'select_'+i"
                                (change)="onCheckChange($event, i)"
                                [checked]="invoice?.select"
                                >
                              </div>
                            </td>
                            <td>{{invoice.doc_no}}</td>
                            <td>{{invoice.doc_date | thai_date: "short":"date" | dash }}</td>
                            <td>{{invoice.doc_validdate | thai_date: "short":"date" | dash }}</td>
                            <td class="text-right">{{invoice.grand_total | number : '1.2-2'}}</td>
                            <td class="text-center">
                              <div *ngIf="!invoice.acc_billing_note_info_id">
                                -
                              </div>
                              <div *ngIf="invoice.acc_billing_note_info_id">
                                วางบิลแล้ว
                              </div>
                            </td>
                          </tr>
                            <tr *ngIf="invoices.length == 0">
                                <td colspan="12" class="text-center">
                                    ไม่มีข้อมูล
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)">
                </app-paginator>
              </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>เพิ่มรายการ
        </button>
      </div>
    </div>
  </div>
</div>
