import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { 
  CustomerAddressService, 
  CustomerContactService,
  GETService, 
} from 'src/app/services';
import { 
  DownloadFileService,
  NgSelect2Customer,
  QuotationMitItemService,
  QuotationUtilsService 
} from 'src/app/services/quotations';
import { 
  JobOrderCalibrationItemService,
  JobOrderContactService,
  JobOrderFileService,
  JobOrderInfoService 
} from 'src/app/services/job-orders';
import { Observable, Subscription } from 'rxjs';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import { Location } from "@angular/common";
declare const $;

@Component({
  selector: 'app-work-order-lab-show',
  templateUrl: './work-order-lab-show.component.html',
  styleUrls: ['./work-order-lab-show.component.css']
})
export class WorkOrderLabShowComponent implements OnInit {
  form: FormGroup;

  submitted: boolean = false;
  pageLoad: boolean = true;
  resetdate: boolean = true;
  disableSaveBtn: boolean = false;

  files: any = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerContactList: any = [];

  reportAddressList = [];
  sendReportAddressList = [];
  chargeReportAddressList = [];
  calibrationAddressList = [];

  job_order_info_id: string = "";
  template: string = "";
  customer_level_id: string;
  //any
  status: any;
  customer_info = null;
  jobOrderInfo = null;
  jobOrderMitItem = null;

  isSalesManager:boolean = false;
  isSalesCoordinator:boolean = false;
  isSales:boolean = false;
  isAccDep:boolean = false;
  isPuchase:boolean = false;
  isCalibration:boolean = false;
  isTranDep:boolean = false;
 
  @Select(UserInfoState.getUser)  userInfo$: Observable<any>;
  userInfo;
  userSub: Subscription;

  constructor(
    private fbd: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private cdRef: ChangeDetectorRef,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private JobOrderInfoService: JobOrderInfoService,
    private JobOrderContactService: JobOrderContactService,
    private JobOrderFileService: JobOrderFileService,
    private DownloadFileService: DownloadFileService,
    private QuotationMitItemService: QuotationMitItemService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService,
    private GETService: GETService,


    public _utils : QuotationUtilsService,
    public _ngSelect2Customer: NgSelect2Customer,
  ) { 
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
    });
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
  }

  async ngOnInit() {
    this._ngSelect2Customer.initPopOver();
    this.createForm();
    await this.setData();
  }

  ngOnDestroy() {
    this._ngSelect2Customer.clearPopOver();
  }

  async setData(){
    this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      this.userInfo = userInfo;
      this.isSalesManager =
      userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP") &&
      userInfo?.emp_info?.emp_level_id === "MANAGER";
      this.isSalesCoordinator =
      userInfo?.emp_info?.emp_level_id.startsWith("OPERATION") &&
      userInfo?.emp_info?.emp_position_info?.emp_position_name_en.includes("Sale coordinator");
      this.isSales = 
      userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP");
      this.isPuchase =
      userInfo?.emp_info?.emp_department_id.startsWith("PUCHASE_DEP");
      this.isCalibration =
      userInfo?.emp_info?.emp_department_id.startsWith("CALIB_DEP");
      this.isTranDep =
      userInfo?.emp_info?.emp_department_id.startsWith("TRAN_DEP");
      this.isAccDep = 
      userInfo?.emp_info?.emp_department_id.startsWith("ACC_DEP");

      await this.JobOrderInfoService.getById({job_order_info_id: this.job_order_info_id})
      .then(async res=>{
        let resultData = res?.resultData || {};
        this.jobOrderInfo = res?.resultData || {};

        this.customer_level_id = resultData.customer_info.customer_level_id;
        
        await this.CustomerAddressService.load(null, {  customer_id: resultData.customer_info.customer_id }).then((res)=>{
          if(res.success){
  
            this.customerAddressList = res.resultData || [];
            this.customerAddressList = this.customerAddressList.map(elem=>{
              return this._utils.genCustomerAddressList(elem);
            })
          }
        })
  
        let data = {
          ...resultData,
          credit_term_day: resultData?.customer_info?.credit_term_day || 0,
          customer_name: `[${resultData?.customer_info?.customer_code || ''}] ${resultData?.customer_info?.company_name || ''} ${resultData.customer_info?.branch?" (" + resultData.customer_info?.branch + ")": ""}`,
          service_time: resultData?.service_time? resultData?.service_time?.split(":")[0]+ ':'+ resultData?.service_time?.split(":")[1]: "00:00"
        }
        this.form.patchValue(data);
        this.form.controls['doc_date'].setValue(moment(resultData.doc_date).format('YYYY-MM-DD'));
        this.form.controls['pickup_date'].setValue(moment(resultData.pickup_date).format('YYYY-MM-DD'));
        this.form.controls['return_date'].setValue(moment(resultData.return_date).format('YYYY-MM-DD'));
        if(resultData.real_return_date){
          this.form.controls['real_return_date'].setValue(moment(resultData.real_return_date).format('YYYY-MM-DD'));
        }

        if(resultData.customer_address_id){
          let response = this.customerAddressList.find(item=> item.id == resultData.customer_address_id);
          if(response){
            this.form.controls['customer_address'].setValue(response.address_name); 
            this.form.controls['mit_iplus_code'].setValue(response.data.mit_iplus_code);   
          }
        }
  
        await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: resultData.customer_id})
        .then(async (res) => {
          let resultData = res.resultData || [];
  
          this.chargeReportAddressList = resultData.filter(item=> item.customer_address_type_id === 3).map(elem => (
            {
              id: elem.customer_address_id,
              text: elem.address_name +' '+ this._utils.genAddress(elem),
            } 
          ));
  
          this.reportAddressList =  resultData.filter(item=> item.customer_address_type_id === 6).map(elem => (
            {
              id: elem.customer_address_id,
              text: elem.address_name +' '+ this._utils.genAddress(elem),
            } 
          ));
  
          this.sendReportAddressList =  resultData.filter(item=> item.customer_address_type_id === 7).map(elem => (
            {
              id: elem.customer_address_id,
              text: elem.address_name +' '+ this._utils.genAddress(elem),
            } 
          ));
  
          this.calibrationAddressList = resultData.filter(item=> item.customer_address_type_id === 5).map(elem => (
            {
              id: elem.customer_address_id,
              text: elem.address_name +' '+ this._utils.genAddress(elem),
            } 
          ));
        });
  
        await this.CustomerContactService.load(null, {
          order_by: "isMaster:desc",
          customer_id: resultData.customer_info.customer_id
        }).then((res) => {
          this.customerContactList = res.resultData || [];
          this.customerContactList = this.customerContactList.map(function(elem) {
            return {
              id: elem.customer_contact_id,
              customer_id: elem.customer_id,
              text: `${elem.firstname} ${elem.lastname}`,
              data: elem
            }
          });
        });
  
        await this.JobOrderContactService.load(null, { job_order_info_id: this.job_order_info_id }).then((res) => {
          let ids = res.resultData.map(v=>{
            return v.customer_contact_id;
          })
          this.form.controls['customer_contact_ids'].setValue(ids);
        });
  
        await this.JobOrderFileService.load(null, { job_order_info_id: this.job_order_info_id  }).then((res)=>{
          this.files = res.resultData || [];
        })
        this.reset();
        this.pageLoad = false;
      })
    });
  }

  reset(){
    this.resetdate = false;
    setTimeout(() => {
      this.resetdate = true;
    }, 100);
  }

  createForm(){
    this.form = this.fbd.group({
      customer_id: [""],
      customer_name: [""],
      customer_address_id: [""],
      customer_address: [""],
      mit_iplus_code: [""],
      pickup_date: [""],
      service_time: ["00:00"],
      return_date: [""],
      real_return_date: [""],
      doc_status_id: [""],
      report_address_id: [""],
      send_report_address_id: [""],
      charge_address_id: [""],

      doc_no: [""],
      doc_date: [""],
      job_order_type: [""],
      special_condition: [""],
      note: [""],
      accessory: [""],
      location: [""],
      calibration_address_id: [],
      no_of_day: [""],
      no_of_people: [""],
      no_of_cert: [""],
      no_of_envelope: [""],

      customer_contact_ids: [[], [Validators.required]],
      credit_term_day: [""],
      sales_user_id: [""],
    });

    this.form.disable();
  }

  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData || []
      if(customer.length > 0){
        if(customer.length > 0){
          address = this._utils.genCustomerAddressList(customer[0]);
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
       this.tmpCustomerAddressList = res.resultData || [];
       this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem=>{
        return this._utils.genCustomerAddressList(elem);
      })
      });
      let customerAddress = this.tmpCustomerAddressList;
      this.customerAddressList = [
        ...[address],
        ...customerAddress
      ];

      if(this.customerAddressList.length > 0){
        const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        if(checkAddress.length == 0) {
          if(address){
            this.form.get('customer_address_id').setValue(address.id);
            this.selectAddressName(address.id);
          }else{
            this.form.controls['customer_address_id'].setValue("");
          }
        }
      }else{
        this.customerAddressList = [
          ...[address],
        ];
        if(address){
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
    const result =  this.customerContactList.map(v=>{
      let select = this.form.value.customer_contact_ids.find(item=> item == v.id);
      if(select){
        return {...select};
      }
    }).filter(r => r != undefined);
    if(result.length == 0){
      this.form.get('customer_contact_ids').setValue([customerContact[0].id]);;
    }
  }

  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address.address_name){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.get('customer_address').setValue("");
    }
  }
  
  async getJobOrderItems(event){
    this.jobOrderMitItem = event;
  }

  async cancel(){
    if(this.jobOrderInfo){
      let jobInfoResponse = await this.JobOrderInfoService.update({
        ...this.jobOrderInfo,
        doc_status_id: 'CANCELED',
        service_time: this.form.getRawValue().service_time.length > 10? moment(this.form.getRawValue().service_time).second(0).format("HH:mm:ss"): this.form.getRawValue().service_time + ":00",
      });
      if(jobInfoResponse.success){
        let promises = [];

        promises.push(

          await Promise.all(this.jobOrderMitItem?.jobOrderItems.map(async (item) =>{
            let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
            .update({
              ...item,
              item_status_id: 'CRF-CANCELED',
              job_order_info_id: jobInfoResponse.resultData.job_order_info_id,
            });
            await this.updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse);
          }))
        )
        promises.push(
          this.router.navigateByUrl("/work-order", {
            state: {
              status: jobInfoResponse.success,
            },
          })
        )
        await Promise.all(promises);
      
      }
    }
  }

  async saveCert(event){
    if(event){
      let data = {
        ...event,
        cert_verify_by: this.userInfo.user_id,
        cert_verify_at: new Date()
      }
      let time = this.form.getRawValue().service_time.length > 10? moment(this.form.getRawValue().service_time).second(0).format("HH:mm:ss"): this.form.getRawValue().service_time + ":00";
      let jobInfoResponse = await this.JobOrderInfoService.update({
        ...this.jobOrderInfo,
        doc_status_id: 'CRF_COMPLETED',
        service_time: time
      });
      if(jobInfoResponse.success){
        let promises = [];

        promises.push(
          await Promise.all(this.jobOrderMitItem?.jobOrderItems.map(async (item) =>{
            if(item.item_status_id !='CRF-CANCELED'){
  
              let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
              .update({
                ...item,
                job_order_info_id: jobInfoResponse.resultData.job_order_info_id,
                ...data
              });
              await this.updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse);
            }else{
              let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
              .update({
                ...item,
                job_order_info_id: jobInfoResponse.resultData.job_order_info_id
              });
              await this.updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse);
            }
          }))
        )
        promises.push(
          this.router.navigateByUrl("/work-order", {
            state: {
              status: jobInfoResponse.success,
            },
          })
        )
        await Promise.all(promises);
      }
    };
  }

  async saveAsShip(){
    let data = {
      deliverable_by: this.userInfo.user_id,
      deliverable_at: new Date()
    }
    let jobInfoResponse = await this.JobOrderInfoService.update({
      ...this.jobOrderInfo,
      doc_status_id: 'PREPARE_FOR_SHIP',
      service_time: this.form.getRawValue().service_time.length > 10? moment(this.form.getRawValue().service_time).second(0).format("HH:mm:ss"): this.form.getRawValue().service_time + ":00",
    });
    if(jobInfoResponse.success){
      let promises = [];
      promises.push(
        await Promise.all(this.jobOrderMitItem?.jobOrderItems.map(async (item) =>{
          if(item.item_status_id !='CRF-CANCELED'){
  
            let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
            .update({
              ...item,
              item_status_id: 'PREPARE_FOR_SHIP',
              job_order_info_id: jobInfoResponse.resultData.job_order_info_id,
              ...data
            });
            await this.updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse);
          }else{
            let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
            .update({
              ...item,
              job_order_info_id: jobInfoResponse.resultData.job_order_info_id
            });
            await this.updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse);
          }
        }))
      )
      promises.push(
        this.router.navigateByUrl("/work-order", {
          state: {
            status: jobInfoResponse.success,
          },
        })
      )
      await Promise.all(promises);
    }
  }

  async updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse){
    let jobOrderMitItem = Array.isArray(jobOrderMitItemResponse.resultData)? jobOrderMitItemResponse.resultData[0]: jobOrderMitItemResponse.resultData;
    
    if(item.quotation_mit_item_info){
      let data = {
        ...item.quotation_mit_item_info,
        // Update
        job_order_info_doc_no: jobInfoResponse.resultData.doc_no,
        job_order_calibration_item_status: jobOrderMitItem.item_status_id,
        job_order_info_id: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobInfoResponse.resultData.job_order_info_id:"",
        job_order_info_status: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobInfoResponse.resultData.doc_status_id:"",
        job_order_info_type: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobInfoResponse.resultData.job_order_type: "",
        // Update
        lock_invoice_at: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobOrderMitItem.lock_invoice_at:"",
        unlock_invoice_at: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobOrderMitItem.unlock_invoice_at:"",
        job_order_calibration_item_id: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobOrderMitItem.job_order_calibration_item_id:"",
      }
      await this.QuotationMitItemService.update(data);
    }
  }

  async saveAsReturn(event){
    if(event){
      let jobInfoResponse = await this.JobOrderInfoService.update({
        ...this.jobOrderInfo,
        doc_status_id: 'RETURNED_SUCCESS',
        returned_customer_signature: event,
        returned_customer_signature_date: new Date(),
        service_time: this.form.getRawValue().service_time.length > 10? moment(this.form.getRawValue().service_time).second(0).format("HH:mm:ss"): this.form.getRawValue().service_time + ":00",
      });
      if(jobInfoResponse.success){
        //////
        let promises = [];

        promises.push(
          await Promise.all(this.jobOrderMitItem?.jobOrderItems.map(async (item) =>{
            let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
            .update({
              ...item,
              item_status_id: 'RETURNED',
              job_order_info_id: jobInfoResponse.resultData.job_order_info_id,
            });
            await this.updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse);
          }))
        )

        promises.push(
          await this.router.navigateByUrl("/work-order", {
            state: {
              status: jobInfoResponse.success,
            },
          })
        )
        await Promise.all(promises);
      }
    }
  }

  async saveAsReject(){
    let jobInfoResponse = await this.JobOrderInfoService.update({
      ...this.jobOrderInfo,
      doc_status_id: 'REJECTED_VERIFY_CRF',
      service_time: this.form.getRawValue().service_time.length > 10? moment(this.form.getRawValue().service_time).second(0).format("HH:mm:ss"): this.form.getRawValue().service_time + ":00",
    });
    if(jobInfoResponse.success){
      let promises = [];

      promises.push(

        await Promise.all(this.jobOrderMitItem?.jobOrderItems.map(async (item) =>{
          let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
          .update({
            ...item,
            job_order_info_id: jobInfoResponse.resultData.job_order_info_id,
          });
          await this.updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse);
        }))
      )
      promises.push(
        await this.router.navigateByUrl("/work-order", {
          state: {
            status: jobInfoResponse.success,
          },
        })
      )
      await Promise.all(promises);
    }
  }

  async saveDone(){
    let jobInfoResponse = await this.JobOrderInfoService.update({
      ...this.jobOrderInfo,
      doc_status_id: 'WAITING_VERIFY_CRF',
    });
    if(jobInfoResponse.success){
      let promises = [];

      promises.push(
        await Promise.all(this.jobOrderMitItem?.jobOrderItems.map(async (item) =>{
          let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
          .update({
            ...item,
            job_order_info_id: jobInfoResponse.resultData.job_order_info_id,
          });
          await this.updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse);
        }))
      )
      promises.push(
        await this.router.navigateByUrl("/work-order", {
          state: {
            status: jobInfoResponse.success,
          },
        })
      )
      await Promise.all(promises);
    }
  }

  async saveNotDone(){
    let jobInfoResponse = await this.JobOrderInfoService.update({
      ...this.jobOrderInfo,
      doc_status_id: 'WORK_INCOMPLETE',
    });
    if(jobInfoResponse.success){
      let promises = [];

      promises.push(
        await Promise.all(this.jobOrderMitItem?.jobOrderItems.map(async (item) =>{
          let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
          .update({
            ...item,
            job_order_info_id: jobInfoResponse.resultData.job_order_info_id,
          });
          await this.updateMitItem(item, jobInfoResponse, jobOrderMitItemResponse);
        }))
      )
      promises.push(
        await this.router.navigateByUrl("/work-order", {
          state: {
            status: jobInfoResponse.success,
          },
        })
      )
      await Promise.all(promises);
    }
  }

  openModalReturn(jobOrder){
    if(jobOrder.job_order_type == "ICS"){
      if(this.jobOrderMitItem.jobOrderItems.find(item=> item.quotation_info_id == "new")){
        $(`#warningSaveReturn`).modal("show");
      }else{
        $(`#work-order-return`).modal("show");
      }
    }else{
      $(`#work-order-return`).modal("show");
    }
  }

  edit(jobOrder){
    this.router.navigateByUrl("/work-order/calibration/"+ jobOrder.job_order_info_id  +"/edit?template=" +jobOrder.job_order_type);
  }

  editCert(jobOrder, status = ''){
    if(jobOrder.job_order_type == "TRN" || jobOrder.job_order_type == "PRD"){
      this.router.navigateByUrl("/work-order/non-calibration/"+ jobOrder.job_order_info_id +"/edit?template=" +jobOrder.job_order_type +"&&status=" + status);
    }else{
      this.router.navigateByUrl("/work-order/calibration/"+ jobOrder.job_order_info_id  +"/edit?template=" +jobOrder.job_order_type +"&&status=" + status);
    }
  }

  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }

  confirmJoborder(jobOrder, status = ''){
    if(jobOrder.job_order_type == "TRN" || jobOrder.job_order_type == "PRD"){
      this.router.navigateByUrl("/work-order/non-calibration/"+ jobOrder.job_order_info_id +"/edit?template=" +jobOrder.job_order_type +"&&status=" + status);
    }else{
      this.router.navigateByUrl("/work-order/calibration/"+ jobOrder.job_order_info_id  +"/edit?template=" +jobOrder.job_order_type +"&&status=" + status);
    }
  }

  openPdf(jobOrder){
    let url = null;
    if(['ICL','ICS'].includes(jobOrder.job_order_type)){
      url = this.router.createUrlTree(['/job-order-document/icl-ics/'+ jobOrder.job_order_info_id])
    }else if(['ECL','ECS'].includes(jobOrder.job_order_type)){
      url = this.router.createUrlTree(['/job-order-document/ecl-ecs/'+ jobOrder.job_order_info_id])
    }else if(jobOrder.job_order_type == "PRD"){
      url = this.router.createUrlTree(['/job-order-document/purchase/'+ jobOrder.job_order_info_id])
    }

    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  openOnsitePdf(jobOrder){
    let url = null;
    url = this.router.createUrlTree(['/job-order-document/onsite/'+ jobOrder.job_order_info_id])
    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  openServiceReportPdf(jobOrder){
    let url = null;
    url = this.router.createUrlTree(['/job-order-document/service-report/'+ jobOrder.job_order_info_id])
    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  openPdfPurchase(jobOrder){
    let url = this.router.createUrlTree(['/job-order-document/purchase/'+ jobOrder.job_order_info_id]);
    window.open(url.toString(), '_blank');
  }

  openAddressCert(jobOrder){
    let url = this.router.createUrlTree(['/job-order-document/address-cert/'+ jobOrder.job_order_info_id]);
    window.open(url.toString(), '_blank');
  }

  async exportMasterReport(jobOrder){
    let name = "";
    if(this.genTransportDocNo()){
      name = jobOrder.doc_no + '_' + this.genTransportDocNo() + '_'+ jobOrder.customer_info.company_name + '.xlsx';
    }else{
      name = jobOrder.doc_no + '_' + jobOrder.customer_info.company_name + '.xlsx';
    }
    this.GETService.masterReport({job_order_info_id: jobOrder.job_order_info_id}).then(res=>{
      let url = window.URL.createObjectURL(res.data);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  genTransportDocNo(){

    let list = this.jobOrderMitItem?.jobOrderItems;
    if(list.length > 0){
      let item = list.filter((mit, index, self) =>
        index === self.findIndex((t) => (
          t.quotation_mit_item_info.transport_receive_info_doc_no === mit.quotation_mit_item_info.transport_receive_info_doc_no 
        ))
      )
      item = item.filter(f=> f.quotation_mit_item_info.transport_receive_info_doc_no).map(m=>{
        return m.quotation_mit_item_info.transport_receive_info_doc_no;
      })
      return item.join(",");
    }else{
      return "";
    }
  }

  disableBtn(event){
    this.disableSaveBtn = event;
    this.cdRef.detectChanges();
  }

  back(): void {
    this.location.back();
  }
}
