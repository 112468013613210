<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-0">สร้างผู้ใช้งาน</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/users']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/users']">จัดการผู้ใช้งาน</a>
              </li>
              <li class="breadcrumb-item active">สร้างผู้ใช้งาน</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            [routerLink]="['/users']"
            type="button"
            class="btn btn-warning btn-min-width mr-1"
          >
            <i class="fa fa-times"></i> ยกเลิก
          </button>
          <button
            (click)="submit()"
            type="button"
            class="btn btn-primary btn-min-width"
          >
            <i class="fa fa-save"></i> บันทึก
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>
      <section class="basic-elements">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title" id="">สร้างผู้ใช้งาน</h4>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <form
                    autocomplete="off"
                    class="form"
                    role="form"
                    action="/dashboard/user-accounts"
                    method="post"
                    [formGroup]="form"
                  >
                    <div class="form-body">
                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.name_code"
                            [submitted]="submitted"
                          >
                            <label for="name_code">
                              Name code
                            </label>
                            <input
                              type="text"
                              id="name_code"
                              class="form-control"
                              placeholder="Name code"
                              formControlName="name_code"
                            />
                          </form-group>
                        </div>

                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.emp_department_id"
                            [submitted]="submitted"
                          >
                            <label for="emp_department_id"
                              >แผนก <span class="danger">*</span></label
                            >
                            <select
                              class="form-control"
                              id="emp_department_id"
                              formControlName="emp_department_id"
                            >
                              <option value="">กรุณาเลือกข้อมูล</option>
                              <option
                                *ngFor="let item of departments"
                                [value]="item.emp_department_id"
                                >{{ item.emp_department_name_th }}</option
                              >
                            </select>
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.emp_level_id"
                            [submitted]="submitted"
                          >
                            <label for="emp_level_id"
                              >ระดับงาน <span class="danger">*</span></label
                            >
                            <select
                              class="form-control"
                              id="emp_level_id"
                              formControlName="emp_level_id"
                            >
                              <option value="">กรุณาเลือกข้อมูล</option>
                              <option
                                *ngFor="let item of levels"
                                [value]="item.emp_level_id"
                                >{{ item.emp_level_name_th }}</option
                              >
                            </select>
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.emp_position_id"
                            [submitted]="submitted"
                          >
                            <label for="emp_position_id"
                              >ตำแหน่งงาน <span class="danger">*</span></label
                            >
                            <select
                              class="form-control"
                              id="emp_position_id"
                              formControlName="emp_position_id"
                            >
                              <option value="">กรุณาเลือกข้อมูล</option>
                              <option
                                *ngFor="let item of positions"
                                [value]="item.emp_position_id"
                                >{{ item.emp_position_name_th }}</option
                              >
                            </select>
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.firstname_th"
                            [submitted]="submitted"
                          >
                            <label for="firstname_th">
                              ชื่อ (ภาษาไทย) <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="firstname_th"
                              class="form-control"
                              placeholder="ชื่อ (ภาษาไทย)"
                              formControlName="firstname_th"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.lastname_th"
                            [submitted]="submitted"
                          >
                            <label for="lastname_th">
                              นามสกุล (ภาษาไทย) <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="lastname_th"
                              class="form-control"
                              placeholder="นามสกุล (ภาษาไทย)"
                              formControlName="lastname_th"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.nickname_th"
                            [submitted]="submitted"
                          >
                            <label for="nickname_th">
                              ชื่อเล่น (ภาษาไทย)
                            </label>
                            <input
                              type="text"
                              id="nickname_th"
                              class="form-control"
                              placeholder="ชื่อเล่น (ภาษาไทย)"
                              formControlName="nickname_th"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.firstname_en"
                            [submitted]="submitted"
                          >
                            <label for="firstname_en">
                              ชื่อ (ภาษาอังกฤษ) <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="firstname_en"
                              class="form-control"
                              placeholder="ชื่อ (ภาษาอังกฤษ)"
                              formControlName="firstname_en"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.lastname_en"
                            [submitted]="submitted"
                          >
                            <label for="lastname_en">
                              นามสกุล (ภาษาอังกฤษ) <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="lastname_en"
                              class="form-control"
                              placeholder="นามสกุล (ภาษาอังกฤษ)"
                              formControlName="lastname_en"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.nickname_en"
                            [submitted]="submitted"
                          >
                            <label for="nickname_en">
                              ชื่อเล่น (ภาษาอังกฤษ)
                            </label>
                            <input
                              type="text"
                              id="nickname_en"
                              class="form-control"
                              placeholder="ชื่อเล่น (ภาษาอังกฤษ)"
                              formControlName="nickname_en"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.date_of_birth"
                            [submitted]="submitted"
                          >
                            <label for="date_of_birth">
                              วันเกิด <span class="danger">*</span>
                            </label>
                            <div class="input-group mt-0">
                              <input
                                date-picker
                                [view-mode]="'years'"
                                type="text"
                                id="date_of_birth"
                                class="form-control"
                                formControlName="date_of_birth"
                              />
                              <div
                                class="input-group-append"
                                for="date_of_birth"
                              >
                                <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                                ></span>
                              </div>
                            </div>
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                            <div class="btn-group mr-1" role="group" aria-label="First group">
                                <label for="file">ID Card</label>
                            </div>
                            <button
                              *ngIf="form.enabled"
                              class="btn btn-outline-primary btn-sm"
                              type="button"
                              (click)="uploadFile()"
                              >
                                <i class="feather icon-upload icon-left"></i>
                                Upload
                            </button>
                            <input type="file" style="display:none;" id="importFile" accept="image/*"
                            (change)="importFile($event.target.files)"
                           >
                          </div>
                          <div class="mt-1" *ngIf="file">
                            <span class="btn btn-sm btn-primary nowrap mr-1 mb-1 pointer" (click)="downloadFile(file)">
                              Download
                            </span>
                          </div>
                          <div class="mt-1" *ngIf="!file">
                            ไม่มีไฟล์
                          </div>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.iplus_signature_filename"
                            [submitted]="submitted"
                          >
                            <label for="iplus_signature_filename">
                              ลายเซ็น IPlus
                            </label>
                            <input
                              type="text"
                              id="iplus_signature_filename"
                              class="form-control"
                              placeholder="ลายเซ็น IPlus"
                              formControlName="iplus_signature_filename"
                            />
                          </form-group>
                        </div>
                      </div>

                      <h5 class="text-bold-600 mb-1">ข้อมูลติดต่อ</h5>
                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.mobile_no"
                            [submitted]="submitted"
                          >
                            <label for="mobile_no">
                              โทรศัพท์ <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="mobile_no"
                              class="form-control"
                              placeholder="โทรศัพท์"
                              formControlName="mobile_no"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.email"
                            [submitted]="submitted"
                          >
                            <label for="email">
                              อีเมล <span class="danger">*</span>
                            </label>
                            <input
                              type="email"
                              id="email"
                              class="form-control"
                              placeholder="อีเมล"
                              formControlName="email"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.password"
                            [submitted]="submitted"
                          >
                            <label for="password"
                              >รหัสผ่านเริ่มต้น
                              <span class="danger">*</span></label
                            >
                            <div class="input-group">
                              <input
                                type="text"
                                id="password"
                                class="form-control"
                                placeholder="รหัสผ่านเริ่มต้น"
                                formControlName="password"
                              />
                              <div
                                class="input-group-append"
                                id="button-addon2"
                              >
                                <button
                                  class="btn btn-light"
                                  type="button"
                                  (click)="generatePassword()"
                                >
                                  <i class="fa fa-undo"></i>
                                </button>
                              </div>
                            </div>
                            <p><small class="text-muted placeholder-color">โปรดระบุ อย่างน้อย 6 ตัวอักษร</small></p>

                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-12">
                          <form-group
                            [control]="form.controls.is_admin"
                            [submitted]="submitted"
                          >
                            <input
                              style="margin-right: 5px;"
                              type="checkbox"
                              id="is_admin"
                              formControlName="is_admin"
                            />
                            <label for="is_admin"
                              >กำหนดให้เป็นผู้ดูแลระบบ</label
                            >
                          </form-group>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
