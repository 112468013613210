import { Component, OnInit } from "@angular/core";
import {
  MITEquipmentService,
  MITMethodService,
  MITScopeService,
  MITCPService,
} from "src/app/services";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-equipment-list",
  templateUrl: "./equipment-list.component.html",
  styleUrls: ["./equipment-list.component.css"],
})
export class EquipmentListComponent implements OnInit {
  isReady = false;
  isLoading = true;
  equipments = [];
  status: string;
  mit_equipment_id: string;
  reset: boolean = false;
  filter = {};
  methods = [];
  mit_scope_id: string;
  mit_cps = [];
  scope = null;
  load: Function = this.MITEquipmentService.load;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private MITEquipmentService: MITEquipmentService,
    private MITMethodService: MITMethodService,
    private MITScopeService: MITScopeService,
    private MITCPService: MITCPService
  ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.mit_scope_id = this.route.snapshot.paramMap.get("mit_scope_id");
    let promises = [];
    promises.push(
      this.MITScopeService.getById({ mit_scope_id: this.mit_scope_id }).then(
        (res) => {
          this.scope = res.resultData;
        }
      )
    );
    this.search({ mit_scope_id: this.mit_scope_id });

    promises.push(
      this.MITCPService.load().then((res) => {
        this.mit_cps = res.resultData || [];
      })
    );

    let methodResponse = await this.MITMethodService.load();
    if (methodResponse.success) {
      this.methods = methodResponse.resultData || [];
    }

    await Promise.all(promises);
    this.isReady = true;
  }
  onChangePage(equipments: Array<any>) {
    this.equipments = equipments;
    this.isLoading = false;
  }
  select(mit_equipment_id: string) {
    this.mit_equipment_id = mit_equipment_id;
  }
  doReset(status?) {
    this.reset = !this.reset;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.mit_equipment_id = null;
    }, 50);
  }
  search(filter) {
    this.filter = { ...this.filter, ...filter };
  }
  async delete(closeModalEl) {
    let response = await this.MITEquipmentService.delete({
      mit_equipment_id: this.mit_equipment_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }
  submit() {}
}
