<div
  class="modal fade text-left"
  [id]="id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="myModalLabel1">แก้ไขเครื่องมือมาตรฐาน</h4>
        <button
          type="button"
          class="close"
          (click)="onClose()"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="form">
          <div class="row justify-content-md-center">
            <div class="col-md-12">
              <div class="modal-body">
                <form class="form" [formGroup]="form">
                  <div class="row justify-content-md-center">
                    <div class="col-md-12">
                      <div class="form-body">
                        <form-group
                          [control]="form.controls.tag_no"
                          [submitted]="submitted"
                        >
                          <label for="tag_no">
                            Tag No <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="tag_no"
                            class="form-control"
                            placeholder="Tag No"
                            formControlName="tag_no"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.description"
                          [submitted]="submitted"
                        >
                          <label for="description">
                            Description <span class="danger">*</span>
                          </label>
                          <textarea
                            type="text"
                            id="description"
                            class="form-control"
                            placeholder="Description"
                            formControlName="description"
                          ></textarea>
                        </form-group>

                        <form-group
                          [control]="form.controls.manufacturer"
                          [submitted]="submitted"
                        >
                          <label for="manufacturer">
                            Manufacturer <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="manufacturer"
                            class="form-control"
                            placeholder="Manufacturer"
                            formControlName="manufacturer"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.model"
                          [submitted]="submitted"
                        >
                          <label for="model">
                            Model <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="model"
                            class="form-control"
                            placeholder="Model"
                            formControlName="model"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.serial_no"
                          [submitted]="submitted"
                        >
                          <label for="serial_no">
                            Serial No <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="serial_no"
                            class="form-control"
                            placeholder="Serial No"
                            formControlName="serial_no"
                          />
                        </form-group>
                        <form-group
                          [control]="form.controls.range"
                          [submitted]="submitted"
                        >
                          <label for="range">
                            Range <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="range"
                            class="form-control"
                            placeholder="Range"
                            formControlName="range"
                          />
                        </form-group>
                        <form-group
                          [control]="form.controls.accuracy"
                          [submitted]="submitted"
                        >
                          <label for="accuracy">
                            Accuracy <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="accuracy"
                            class="form-control"
                            placeholder="Accuracy"
                            formControlName="accuracy"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.calibration_period"
                          [submitted]="submitted"
                        >
                          <label for="calibration_period">
                            Period Calibrate <span class="danger">*</span>
                          </label>
                          <input
                            type="number"
                            id="calibration_period"
                            class="form-control"
                            placeholder="Period Calibrate"
                            formControlName="calibration_period"
                          />
                        </form-group>
                        <form-group
                          [control]="form.controls.calibrate_date"
                          [submitted]="submitted"
                        >
                          <label for="calibrate_date"> Date Calibrated </label>
                          <div class="input-group mt-0">
                            <input
                              date-picker
                              [show-clear]="true"
                              type="text"
                              id="calibrate_date"
                              [date]="form.value.calibrate_date"
                              class="form-control"
                              formControlName="calibrate_date"
                              [max-date]="undefined"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text"
                                ><i class="feather icon-calendar"></i
                              ></span>
                            </div>
                          </div>
                        </form-group>

                        <form-group
                          [control]="form.controls.due_date"
                          [submitted]="submitted"
                        >
                          <label for="due_date"> Due Date </label>
                          <div class="input-group mt-0">
                            <input
                              date-picker
                              [show-clear]="true"
                              type="text"
                              id="due_date"
                              [date]="form.value.due_date"
                              class="form-control"
                              formControlName="due_date"
                              [max-date]="undefined"
                            />
                            <div class="input-group-append">
                              <span class="input-group-text"
                                ><i class="feather icon-calendar"></i
                              ></span>
                            </div>
                          </div>
                        </form-group>

                        <form-group
                          [control]="form.controls.certificate_no"
                          [submitted]="submitted"
                        >
                          <label for="certificate_no">
                            Certificate No <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="certificate_no"
                            class="form-control"
                            placeholder="Certificate No"
                            formControlName="certificate_no"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.calibrated_by"
                          [submitted]="submitted"
                        >
                          <label for="calibrated_by">
                            Calibrated By <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="calibrated_by"
                            class="form-control"
                            placeholder="Calibrated By"
                            formControlName="calibrated_by"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.traceability"
                          [submitted]="submitted"
                        >
                          <label for="traceability">
                            Traceability <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="traceability"
                            class="form-control"
                            placeholder="Traceability"
                            formControlName="traceability"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.accessory"
                          [submitted]="submitted"
                        >
                          <label for="accessory">
                            Accessory <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="accessory"
                            class="form-control"
                            placeholder="Accessory"
                            formControlName="accessory"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.cost"
                          [submitted]="submitted"
                        >
                          <label for="cost">
                            Cost <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="cost"
                            class="form-control"
                            placeholder="0.00"
                            oninput="this.value =
                                    this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                            formControlName="cost"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.status"
                          [submitted]="submitted"
                        >
                          <label for="status">
                            Status <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="status"
                            class="form-control"
                            placeholder="Status"
                            formControlName="status"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.owner"
                          [submitted]="submitted"
                        >
                          <label for="owner">
                            Owner <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="owner"
                            class="form-control"
                            placeholder="Owner"
                            formControlName="owner"
                          />
                        </form-group>

                        <form-group
                          [control]="form.controls.location"
                          [submitted]="submitted"
                        >
                          <label for="location">
                            Location <span class="danger">*</span>
                          </label>
                          <input
                            type="text"
                            id="location"
                            class="form-control"
                            placeholder="Location"
                            formControlName="location"
                          />
                        </form-group>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          (click)="onClose()"
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="onSave()">
          <i class="fa fa-plus icon-left"></i>ยืนยัน
        </button>
      </div>
    </div>
  </div>
</div>
