import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserInfoState } from 'src/app/store/user/user.state';
import { CarInfoService, JobOrderCarInfoService } from '../../../../services/job-orders/index';

@Component({
  selector: 'app-car-info',
  templateUrl: './car-info.component.html',
  styleUrls: ['./car-info.component.css']
})
export class CarInfoComponent implements OnInit {
  public form: FormGroup;
  public carInfoList: FormArray;
  public carList = [];
  public deleteCarInfoList = [];

  public pageLoad: boolean = false;
  public job_order_info_id: string = "";
  public pageType: string;

  @Input("submitted") submitted: boolean;
  @Output() done = new EventEmitter<any>();
  @Input("jobOrderInfo") jobOrderInfo: any;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private CarInfoService: CarInfoService,
    private JobOrderCarInfoService: JobOrderCarInfoService,
    private route: ActivatedRoute
  ) {
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  async ngOnInit() {
    this.createForm();
    await this.CarInfoService.load(null, {}).then(res=>{
      this.carList = res.resultData || [];
      this.carList = this.carList.map(function(elem) {
        return {
          id: elem.car_info_id,
          text: `${elem?.car_plate}`,
        }
      })
    });

    await this.getCarInfo();
    this.sentData();
    this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      if(userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP")){
        this.pageType = 'show';
      }
    });
  }

  async getCarInfo(){
    await this.JobOrderCarInfoService.load(null, 
      { 
        job_order_info_id: this.job_order_info_id
      }).then(async (res)=>{
        
        await Promise.all(res.resultData.map(async (v: any) =>{
          this.carInfoList.push(this.createCarInfoItem(v));
        }));
        this.pageLoad = true;
      })
  }

  public createForm(){
    this.form = this.formBuilder.group({
      carInfos: this.formBuilder.array([]),
    });
    this.carInfoList = 
    this.form.get('carInfos') as FormArray;
  }

  get carInfoItemFormGroup() {
    return this.form.get('carInfos') as FormArray;
  }

  public createCarInfoItem(data:any = {}): FormGroup {
    return this.formBuilder.group({
      job_order_car_info_id: this.formBuilder.control(data.job_order_car_info_id || null),
      job_order_info_id: this.formBuilder.control(this.job_order_info_id),
      car_info_id: this.formBuilder.control(data.car_info_id || "", [Validators.required])
    });
  }

  public addCarInfo(){
    this.carInfoList.push(this.createCarInfoItem());
    this.sentData();
  }

  public setCar(event, i){
    if(event){
      this.carInfoList.controls[i].get('car_info_id').setValue(event);
      this.sentData();
    }
  }

  public removeCarInfo(index: number, item: any){
    this.carInfoList.removeAt(index);
    this.deleteCarInfoList.push(item.value);
    this.sentData();
  }

  async sentData(){
    this.done.emit({
      form: this.form,
      deleteCarInfoList: this.deleteCarInfoList
    });
  }

  public getCar(id: string): string{
    let car = this.carList.find(car=> car.id == id);
    if(car) return car.text || "";
  }
}
