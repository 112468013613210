import { Component, OnInit } from "@angular/core";
import { Select } from "@ngxs/store";
import moment from "moment";
import { Observable } from "rxjs";
import { MITScopeService, SystemSummaryService } from "src/app/services";
import { UserInfoState } from "src/app/store/user/user.state";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  //any
  pageLoad: boolean = false;
  canAccess: boolean = true;

  date = null;
  invoiceData = {};
  incomeData = {};
  quotationData = {};
  jobOrderData = {};

  scopeList = [];

  sumCount: number = 0;
  sumPrice: number = 0;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;

  constructor(
    private MITScopeService: MITScopeService,
    private SystemSummaryService: SystemSummaryService
  ) {}

  async ngOnInit() {
    this.userInfo$.subscribe(async user=>{
      if(user.emp_info.emp_level_id === 'MANAGER'){
        await this.getInvoiceSection();
        await this.getQuotationData();
        await this.getJobOrderData();
        await this.getIncomeData();
        await this.geJobData();
    
        this.pageLoad = true;
      }else{
        this.canAccess = false;
        this.pageLoad = true;
      }
    })

  }

  async getInvoiceSection(){
    await this.SystemSummaryService.load(null, {syssum_type: '1,2,3'}).then(res=>{
      this.date = res.resultData[0].syssum_date;
      this.invoiceData['syssum_type_1'] = res.resultData.find(data=> data.syssum_type == "1");
      this.invoiceData['syssum_type_2'] = res.resultData.find(data=> data.syssum_type == "2");
      this.invoiceData['syssum_type_3'] = res.resultData.find(data=> data.syssum_type == "3");
    });
  }
  
  async geJobData(){
    await this.MITScopeService.load(null).then(res=>{
      this.scopeList = res.resultData;

      this.scopeList.map(async scope=>{
        await this.SystemSummaryService.load(null, {syssum_type: '7,8', parameter_1: scope.mit_scope_id}).then(sys=>{

          this.sumCount = this.sumCount + sys.resultData.find(data=> data.syssum_type == "7").value;
          this.sumPrice = this.sumPrice + sys.resultData.find(data=> data.syssum_type == "8").value;

          scope['syssum_type_7'] = sys.resultData.find(data=> data.syssum_type == "7");
          scope['syssum_type_8'] = sys.resultData.find(data=> data.syssum_type == "8");
        })
      })
    })
  }

  async getIncomeData(){
    var date = new Date();

    await this.SystemSummaryService.sum(null, {syssum_type: '4', start_date: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD"), end_date: moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format("YYYY-MM-DD")}).then(sys=>{
      this.incomeData['month'] = sys.resultData['4'];
    })

    await this.SystemSummaryService.sum(null, {syssum_type: '4', start_date: moment(new Date(date.getFullYear(), 0, 1)).format("YYYY-MM-DD"), end_date: moment(new Date(date.getFullYear(), 11, 31)).format("YYYY-MM-DD")}).then(sys=>{
      this.incomeData['year'] = sys.resultData['4'];
    })

    await this.SystemSummaryService.sum(null, {syssum_type: '4', start_date: moment(new Date(date.setDate(date.getDate() - date.getDay()))).format("YYYY-MM-DD"), end_date: moment(new Date(date.setDate((date.getDate() - date.getDay())+6)).toUTCString()).format("YYYY-MM-DD")}).then(sys=>{
      this.incomeData['week'] = sys.resultData['4'];
    })
  }

  async getQuotationData(){
    var date = new Date();

    await this.SystemSummaryService.sum(null, {syssum_type: '5', start_date: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD"), end_date: moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format("YYYY-MM-DD")}).then(sys=>{
      this.quotationData['month'] = sys.resultData['5'];
    })

    await this.SystemSummaryService.sum(null, {syssum_type: '5', start_date: moment(new Date(date.getFullYear(), 0, 1)).format("YYYY-MM-DD"), end_date: moment(new Date(date.getFullYear(), 11, 31)).format("YYYY-MM-DD")}).then(sys=>{
      this.quotationData['year'] = sys.resultData['5'];
    })

    await this.SystemSummaryService.sum(null, {syssum_type: '5', start_date: moment(new Date(date.setDate(date.getDate() - date.getDay()))).format("YYYY-MM-DD"), end_date: moment(new Date(date.setDate((date.getDate() - date.getDay())+6)).toUTCString()).format("YYYY-MM-DD")}).then(sys=>{
      this.quotationData['week'] = sys.resultData['5'];
    })
  }


  async getJobOrderData(){
    var date = new Date();

    await this.SystemSummaryService.sum(null, {syssum_type: '6', start_date: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format("YYYY-MM-DD"), end_date: moment(new Date(date.getFullYear(), date.getMonth() + 1, 0)).format("YYYY-MM-DD")}).then(sys=>{
      this.jobOrderData['month'] = sys.resultData['6'];
    })

    await this.SystemSummaryService.sum(null, {syssum_type: '6', start_date: moment(new Date(date.getFullYear(), 0, 1)).format("YYYY-MM-DD"), end_date: moment(new Date(date.getFullYear(), 11, 31)).format("YYYY-MM-DD")}).then(sys=>{
      this.jobOrderData['year'] = sys.resultData['6'];
    })

    await this.SystemSummaryService.sum(null, {syssum_type: '6', start_date: moment(new Date(date.setDate(date.getDate() - date.getDay()))).format("YYYY-MM-DD"), end_date: moment(new Date(date.setDate((date.getDate() - date.getDay())+6)).toUTCString()).format("YYYY-MM-DD")}).then(sys=>{
      this.jobOrderData['week'] = sys.resultData['6'];
    })
  }
}
