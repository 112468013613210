<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="transport-receive-item-create-no-item"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreateModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreateModal">
          เพิ่มรายการ (ไม่มีใบเสนอราคา)
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal"  autocomplete="off" class="form" [formGroup]="form">
            <div class="form-body">
                <div class="form-body pt-0">
                    <div class="form-group row">
                      <h4 class="col-12 col-sm-12 col-md-12 col-lg-12 label-control text-left">
                        รายละเอียดเครื่องมือ
                      </h4>
                    </div>
                  </div>
                  <div>
                    <div class="form-body">
                      <div class="form-group row mb-0">
                        <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="description">
                          ชื่อเครื่องมือ <span class="danger">*</span> : 
                        </label>
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                            <form-group
                            [control]="form.controls.noqt_description"
                            [submitted]="submitted">
                            <input
                                type="text"
                                placeholder="ชื่อเครื่องมือ"
                                id="marker"
                                class="form-control"
                                formControlName="noqt_description"
                            />
                            </form-group>
                        </div>
                      </div>
                    </div>
                    <div class="form-body">
                      <div class="form-group row mb-0">
                        <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="marker">
                          Maker <span class="danger">*</span> : 
                        </label>
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                          <form-group
                          [control]="form.controls.noqt_marker"
                          [submitted]="submitted">
                          <input
                              type="text"
                              placeholder="Maker"
                              id="noqt_marker"
                              class="form-control"
                              formControlName="noqt_marker"
                          />
                          </form-group>
                        </div>
                      </div>
                    </div>
                    <div class="form-body">
                      <div class="form-group row mb-0">
                        <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="model">
                          Model <span class="danger">*</span> : 
                        </label>
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                          <form-group
                          [control]="form.controls.noqt_model"
                          [submitted]="submitted">
                          <input
                              type="text"
                              placeholder="Model"
                              id="noqt_model"
                              class="form-control"
                              formControlName="noqt_model"
                          />
                          </form-group>
                        </div>
                      </div>
                    </div>
                    
                    <div class="form-body">
                      <div class="form-group row mb-0">
                        <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="serial_no">
                          Serial No. <span class="danger">*</span> : 
                        </label>
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                          <form-group
                          [control]="form.controls.noqt_serial_no"
                          [submitted]="submitted">
                          <input
                              type="text"
                              placeholder="Serial No."
                              id="noqt_serial_no"
                              class="form-control"
                              formControlName="noqt_serial_no"
                          />
                          </form-group>
                        </div>
                      </div>
                    </div>

                    <div class="form-body">
                      <div class="form-group row mb-0">
                        <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="noqt_tag_no">
                          Tag No. <span class="danger">*</span> : 
                        </label>
                        <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                          <form-group
                          [control]="form.controls.noqt_tag_no"
                          [submitted]="submitted">
                          <input
                              type="text"
                              placeholder="Tag No."
                              id="noqt_tag_no"
                              class="form-control"
                              formControlName="noqt_tag_no"
                          />
                          </form-group>
                        </div>
                      </div>
                    </div>

                  </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ตกลง
        </button>
      </div>
    </div>
  </div>
</div>
