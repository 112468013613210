import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationMitItemLog {
  async create({
    created_at,
    created_by,
    quotation_mit_item_id,
    description,
    quotation_mit_item_detail_id,
    event,
    key,
    previous_value,
    new_value
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-item-log`, {
        created_at,
        created_by,
        quotation_mit_item_id,
        description,
        quotation_mit_item_detail_id,
        event,
        key,
        previous_value,
        new_value
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async createArray(array) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-item-log`, array);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_mit_item_log_id,
        quotation_mit_item_id,
        description,
        quotation_mit_item_detail_id,
        event,
        key,
        previous_value,
        new_value
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-mit-item-log/${quotation_mit_item_log_id}`, {
        quotation_mit_item_id,
        description,
        quotation_mit_item_detail_id,
        event,
        key,
        previous_value,
        new_value
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_mit_item_log_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-mit-item-log/${quotation_mit_item_log_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-log`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_mit_item_log_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-log/${quotation_mit_item_log_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
