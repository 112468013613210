<!-- BEGIN: Main Menu-->
<!-- main menu-->
<div
  class="main-menu menu-fixed menu-dark menu-accordion menu-shadow"
  data-scroll-to-active="true"
>
  <!-- main menu header-->
  <!-- / main menu header-->
  <!-- main menu content-->
  <div class="main-menu-content">
    <ul
      class="navigation navigation-main"
      id="main-menu-navigation"
      data-menu="menu-navigation"
    >
      <li class="nav-item" [class]="{ active: path.startsWith('/dashboard') }"
      *ngIf="(userInfo$ | async)?.emp_info?.emp_level_id === 'MANAGER'">
        <a href="/dashboard"
          ><i class="feather icon-home"></i
          ><span class="menu-title">ภาพรวมระบบ</span>
        </a>
      </li>

      <li
        *ngIf="!(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'TRAN_DEP'
        ) &&
        !(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'CALIB_DEP'
        ) &&
        !(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'PUCHASE_DEP'
        ) &&
        !(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'ACC_DEP'
        )"
        class="nav-item"
        [class]="{ active: path.startsWith('/my-calendar') }"
      >
        <a href="/my-calendar"
          ><i class="feather icon-calendar"></i
          ><span class="menu-title">ปฏิทินของฉัน</span>
        </a>
      </li>

      <li
        *ngIf="
          (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
            'SALES_DEP'
          )
          ||
          (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
            'ACC_DEP'
          )
           || (userInfo$ | async)?.user_role_id === 'ADMIN'
        "
        class="nav-item"
        [class]="{ active: path.startsWith('/customer') }"
      >
        <a href="/customer"
          ><i class="fa fa-users"></i
          ><span class="menu-title">จัดการข้อมูลลูกค้า</span>
        </a>
      </li>

      <li
      *ngIf="
        (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'SALES_DEP'
        )
      "
      class="nav-item"
      [class]="{ active: path.startsWith('/quotation') }"
    >
      <a href="/quotation"
        ><i class="feather icon-file-text"></i
        ><span class="menu-title">ใบเสนอราคา</span>
      </a>
    </li>

    <li
    *ngIf="
        (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'SALES_DEP'
        ) ||
        (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
          'TRAN_DEP-01'
        ) ||
        (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
          'ACC_DEP-01'
        ) ||
        (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
          'TRAN_DEP-02'
        ) ||
        (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'CALIB_DEP'
        )
      "
      class="nav-item"
      [class]="{ active: path.startsWith('/equipment') }"
    >
      <a href="/equipment"
        ><i class="fa fa-wrench"></i>
        <span class="menu-title">รายการเครื่องมือ</span>
      </a>
    </li>

    <li
        class="nav-item has-sub"
        [class]="{ open: openTransportMenu }"
        *ngIf="(
          (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
            'TRAN_DEP'
          ) ||
          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'ACC_DEP-01'
          ) ||
          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'SALES_DEP-03'
          ) ||
          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'SALES_DEP'
          ) ||
          (userInfo$ | async)?.user_role_id === 'ADMIN')

          && !((userInfo$ | async)?.emp_info?.emp_level_id === 'MANAGER' && (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'ACC_DEP-01'
          ))
        "
      >
        <a href="#" (click)="$event.preventDefault()"
          ><i class="fa fa-truck"></i
          ><span class="menu-title">การรับ-ส่งเครื่องมือ</span></a
        >
        <ul class="menu-content"
        >
          <li [class]="{ active: path.startsWith('/transport-receive') }" >
            <a href="/transport-receive" class="menu-item">รายการรับเครื่องมือ</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/waiting-for-delivery') }">
            <a href="/waiting-for-delivery" class="menu-item">รายการรอส่ง</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/bill-cheque') }">
            <a href="/bill-cheque" class="menu-item">รายการรอวางบิล-เช็ค</a>
          </li>
        </ul>
    </li>

    <li
      class="nav-item has-sub"
      [class]="{ open: openBookingMenu }"

    >
      <a href="#" (click)="$event.preventDefault()"
        ><i class="fa fa-truck"></i
        ><span class="menu-title">Booking</span></a
      >
      <ul class="menu-content">
        <li [class]="{ active: path.startsWith('/transport/pre-booking') }">
          <a href="/transport/pre-booking" class="menu-item">Pre Booking</a>
        </li>
      </ul>
      <ul class="menu-content"
        *ngIf="((userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'ACC_DEP-01'
          ) ||
          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'CC_DEP-02'
          )||
          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'CALIB_DEP-01'
          )||
          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'CC_DEP-05'
          )||
          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'EXECUTIVE'
          )||
          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'PUCHASE_DEP-01'
          )||
          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
            'PUCHASE_DEP-02'
          )||
          (userInfo$ | async)?.is_tran_dep
          ||
          (userInfo$ | async)?.is_sales)" >
        <li [class]="{ active: path.startsWith('/transport/master-booking') }">
          <a href="/transport/master-booking" class="menu-item">Master Booking</a>
        </li>
      </ul>
    </li>

      <li
        *ngIf="
        ((userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
            'TRAN_DEP'
        ) && !(userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
          'TRAN_DEP-03'
        )) ||
        (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'CALIB_DEP'
        ) ||
        (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'PUCHASE_DEP'
        ) ||
        (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
          'SALES_DEP'
        ) ||
        (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'ACC_DEP'
        ) ||
          (userInfo$ | async)?.user_role_id === 'ADMIN'
        "
        class="nav-item"
        [class]="{ active: path.startsWith('/work-order') }"
      >
        <a href="/work-order"
          ><i class="feather icon-file-text"></i
            ><span class="menu-title">ใบสั่งงาน </span>
        </a>
      </li>

      <li
      *ngIf="
      (userInfo$ | async)?.user_role_id === 'ADMIN'
      "
      class="nav-item"
      [class]="{ active: path.startsWith('/report') }"
    >
      <a href="/report"
        ><i class="feather icon-file-text"></i
          ><span class="menu-title">รายงาน </span>
      </a>
    </li>
      <li
        class="nav-item has-sub"
        [class]="{ open: openAccMenu }"
        *ngIf="
        (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'ACC_DEP'
        )"
      >
        <a href="#" (click)="$event.preventDefault()"
          ><i class="feather icon-file-text"></i
            ><span class="menu-title">เอกสารทางบัญชี</span></a
        >
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/waiting-for-charge') }">
            <a href="/waiting-for-charge" class="menu-item">รายการรอเรียกเก็บ</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/invoice') }">
            <a href="/invoice" class="menu-item">ใบแจ้งหนี้/ส่งของ</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/tax-invoice') }">
            <a href="/tax-invoice" class="menu-item">ใบกำกับภาษี</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/credit-note') }">
            <a href="/credit-note" class="menu-item">ใบลดหนี้</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/debit-note') }">
            <a href="/debit-note" class="menu-item">ใบเพิ่มหนี้</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/billing-note') }">
            <a href="/billing-note" class="menu-item">ใบวางบิล</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/received-voucher') }">
            <a href="/received-voucher" class="menu-item">ใบสำคัญรับ</a>
          </li>
        </ul>
        <!--  -->
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/bill-cheque') }">
            <a href="/bill-cheque" class="menu-item">รายงานรอวางบิล-รับเช็ค</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/payment') }">
            <a href="/payment" class="menu-item">รายงานการชำระเงิน</a>
          </li>
        </ul>
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/cheque') }">
            <a href="/cheque" class="menu-item">รายงานเช็ครับ</a>
          </li>
        </ul>
      </li>

      <li class="navigation-header" *ngIf="(userInfo$ | async)?.is_admin">
        <span class="font-weight-bold">ตั้งค่าระบบ</span
        ><i class="feather icon-minus"></i>
      </li>
      <li
        class="nav-item"
        [class]="{ active: path.startsWith('/users') }"
        *ngIf="(userInfo$ | async)?.is_admin"
      >
        <a href="/users"
          ><i class="feather icon-users"></i
          ><span class="menu-title" data-i18n="">จัดการผู้ใช้งาน</span>
        </a>
      </li>
      <li
        class="nav-item"
        [class]="{ active: path.startsWith('/sales-team') }"
        *ngIf="(userInfo$ | async)?.is_admin"
      >
        <a href="/sales-team"
          ><i class="feather icon-users"></i
          ><span class="menu-title" data-i18n="">จัดการทีมขาย</span>
        </a>
      </li>
      <li
        class="nav-item has-sub"
        [class]="{ open: openProductMenu }"
        *ngIf="(userInfo$ | async)?.is_admin"
      >
        <a href="#" (click)="$event.preventDefault()"
          ><i class="fa fa-cubes"></i
          ><span class="menu-title">จัดการข้อมูลสินค้า</span></a
        >
        <ul class="menu-content">
          <li [class]="{ active: path.startsWith('/product') }">
            <a href="/product" class="menu-item">ข้อมูลสินค้า</a>
          </li>
          <li [class]="{ active: path.startsWith('/scope') }">
            <a href="/scope" class="menu-item">ข้อมูลบริการสอบเทียบ</a>
          </li>
          <li [class]="{ active: path.startsWith('/unit') }">
            <a href="/unit" class="menu-item">ข้อมูลหน่วยนับ</a>
          </li>
        </ul>
      </li>

      <li
      class="nav-item"
      [class]="{ active: path.startsWith('/note') }"
      *ngIf="(userInfo$ | async)?.is_admin"
    >
      <a href="/note"
        ><i class="fa fa-asterisk"></i>
        <span class="menu-title" data-i18n="">จัดการหมายเหตุ</span>
      </a>
    </li>

    <li
      class="nav-item"
      [class]="{ active: path.startsWith('/car-info') }"
      *ngIf="(userInfo$ | async)?.is_admin"
    >
      <a href="/car-info"
        ><i class="fa fa-car"></i>
        <span class="menu-title" data-i18n="">จัดการข้อมูลรถยนต์</span>
      </a>
    </li>

    <li
      class="nav-item"
      [class]="{ active: path.startsWith('/standard') }"
      *ngIf="
      (userInfo$ | async)?.is_admin ||
      (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
        'CALIB_DEP'
      )"
    >
      <a href="/standard"
        ><i class="fa fa-cube"></i>
        <span class="menu-title" data-i18n=""
          >จัดการเครื่องมือมาตรฐาน</span
        >
      </a>
    </li>

    <li
      class="nav-item"
      [class]="{ active: path.startsWith('/email-template-attachment') }"
      *ngIf="(userInfo$ | async)?.is_admin"
    >
      <a href="/email-template-attachment"
        ><i class="fa fa-file-pdf-o"></i>
        <span class="menu-title" data-i18n="">จัดการไฟล์แนบ</span>
      </a>
    </li>

    <li
      class="nav-item"
    >
      <a href="http://202.129.207.222/manual-document/คู่มือการใช้งาน_MIT-CRM.pdf" target="_blank"
        ><i class="fa fa-book"></i>
        <span class="menu-title" data-i18n="">คู่มือการใช้งาน</span>
      </a>
    </li>

    </ul>
  </div>
  <!-- /main menu content-->
  <!-- main menu footer-->
  <!-- main menu footer-->
</div>
<!-- / main menu-->
<!-- END: Main Menu-->
