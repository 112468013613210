<div class="card mb-0">
    <div class="card-content collapse show">
        <div class="card-body p-0">

            <div class="d-flex justify-content-between mb-1">
                <div>
                    <button
                    *ngIf="pageType !='show' 
                    && (jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' && jobOrderInfo?.doc_status_id !== 'CONFIRMED' && jobOrderInfo?.doc_status_id !== 'COMPLETED' && jobOrderInfo?.doc_status_id !== 'WAITING_VERIFY_CRF' && jobOrderInfo?.doc_status_id !== 'REJECTED_VERIFY_CRF' && jobOrderInfo?.doc_status_id !== 'WORK_INCOMPLETE') 
                    && (template!='ICL' && template!='ECL')"
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#work-order-item-create"
                    (click)="popupType = 'mit'"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบเสนอราคา
                    </button>

                    <button
                        *ngIf="pageType !='show' 
                        && (jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' && jobOrderInfo?.doc_status_id !== 'CONFIRMED' && jobOrderInfo?.doc_status_id !== 'COMPLETED' && jobOrderInfo?.doc_status_id !== 'WAITING_VERIFY_CRF' && jobOrderInfo?.doc_status_id !== 'REJECTED_VERIFY_CRF' && jobOrderInfo?.doc_status_id !== 'WORK_INCOMPLETE') 
                        && (template=='ICL' || template=='ECL')"
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#work-order-item-create"
                        (click)="popupType = 'transport'"
                        >
                        <i class="fa fa-plus icon-left"></i> เลือกจากใบรับเครื่องมือ
                    </button>

                    <button
                        *ngIf="pageType !='show' && next_status=='complete' 
                        && (jobOrderInfo?.doc_status_id === 'CONFIRMED' || jobOrderInfo?.doc_status_id === 'REJECTED_VERIFY_CRF' || jobOrderInfo?.doc_status_id === 'WORK_INCOMPLETE') 
                        && (template=='ICL' || template=='ICS') 
                        && isCalibration"
                        type="button"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#work-order-import"
                        >
                        <i class="fa fa-upload icon-left"></i> บันทึกผลด้วย File master
                    </button>
                </div>
            </div>
            <div class="table-responsive" style="max-height: 600px;">
                <table class="table table-bordered">
                    <thead> 
                        <tr>
                            <th class="fixed-column">ลำดับ</th>
                            <th class="fixed-column" *ngIf="['WAITING_VERIFY_CRF','CONFIRMED', 'PREPARE_FOR_SHIP', 'COMPLETED', 'REJECTED_VERIFY_CRF', 'WORK_INCOMPLETE'].includes(jobOrderInfo?.doc_status_id)">cert no.</th>
                            <th class="fixed-column" *ngIf="showColumn">เลขที่ใบเสนอราคา</th>
                            <th class="fixed-column">
                                <span>Description </span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th>
                                <span>Scope </span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th *ngIf="showColumn">
                                <span>Equipment </span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th class="text-left" *ngIf="showColumn">Method</th>
                            <th *ngIf="showColumn">Service Location</th>
    
                            <th *ngIf="showColumn">Calibration Point by customer</th>
                            <th>
                                <span>Calibration Point</span>
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-2">&nbsp;</span>
                            </th>
                            <th>MPE (&plusmn;)</th>
                            <th>
                                <span>Note</span> 
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th class="text-center">
                                <span class="pr-2">&nbsp;</span>
                                <span>CP </span>
                                <span class="pr-2">&nbsp;</span>
                            </th>
                            <th>
                                <span>Attributes</span> 
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th *ngIf="['ICL','ICS'].includes(template) && ['WAITING_VERIFY_CRF', 'PREPARE_FOR_SHIP', 'COMPLETED', 'CONFIRMED','RETURNED_SUCCESS', 'REJECTED_VERIFY_CRF', 'WORK_INCOMPLETE'].includes(jobOrderInfo?.doc_status_id)">
                                <span>STD</span> 
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th class="text-right" *ngIf="!isCalibration && !isTranDep">Price</th>
                            <th class="text-right" *ngIf="!isCalibration && !isTranDep">
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-5">&nbsp;</span>
                                ราคาขาย
                            </th>
                            <th class="text-right" *ngIf="!isCalibration && !isTranDep">
                                <span class="pr-5">&nbsp;</span>
                                ส่วนลด(%)
                            </th>
                            <th class="text-right" *ngIf="!isCalibration && !isTranDep">รวมเงิน</th>
                            <th>
                                สถานะ
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th *ngIf="['ICL','ICS'].includes(template) && jobOrderInfo?.doc_status_id !== 'DRAFT' && jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' && jobOrderInfo?.doc_status_id !== 'REJECTED' && jobOrderInfo?.doc_status_id !== 'CANCELED' && pageType !='create'">
                                ผลการให้บริการ
                                <span class="pr-5">&nbsp;</span>
                            </th>
     
                            <th *ngIf="pageType !='show' &&  jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM'">
                                ยืนยันการให้บริการ
                                <span class="pr-5">&nbsp;</span>
                                <div>
                                    <button
                                        *ngIf="['ICL','ICS'].includes(template)"
                                        (click)="confirmAllItem()"
                                        type="button"
                                        class="btn mr-1 mb-1 mt-1 btn-success btn-sm"
                                    > <i class="fa fa-check-circle-o icon-left"></i> ยืนยันให้บริการได้ทั้งหมด
                                    </button>
                                    <button
                                        *ngIf="['ECL','ECS'].includes(template)"
                                        (click)="isConfirmAll = true"
                                        data-target="#confirm-calibration-item"
                                        data-toggle="modal"
                                        type="button"
                                        class="btn mr-1 mb-1 mt-1 btn-success btn-sm"
                                    > <i class="fa fa-check-circle-o icon-left"></i> ยืนยันให้บริการได้ทั้งหมด
                                    </button>
                                </div>
                                <div *ngIf="pageType !='show' &&  jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM'">
                                    <button
                                        (click)="isRejectAll = true"
                                        data-target="#reject-calibration-item"
                                        data-toggle="modal"
                                        type="button"
                                        class="btn mr-1 btn-danger btn-sm"
                                    > <i class="fa fa-times-circle-o icon-left"></i> ไม่สามารถให้บริการได้ทั้งหมด
                                    </button>
                                </div>
                            </th>
                            <th>
                                action
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of groupJobOrderItems; let i = index;"  [hidden]="pageLoad">
                        <tr>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="pt-1 text-center fixed-column">
                                <span class="text-bold-600">
                                    {{i+ 1}}
                                </span>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="fixed-column text-nowrap"  *ngIf="['WAITING_VERIFY_CRF','CONFIRMED', 'PREPARE_FOR_SHIP', 'COMPLETED', 'REJECTED_VERIFY_CRF', 'WORK_INCOMPLETE'].includes(jobOrderInfo?.doc_status_id)">
                                <span *ngIf="item.quotation_info_id !='new'">
                                    {{item?.cert_no}}
                                </span>
                                <span *ngIf="item.quotation_info_id =='new'">
                                    <div>
                                        {{item?.cert_no}}
                                    </div>
                                    <span class="text-warning">รายการใหม่</span>
                                </span>
                             </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="fixed-column" *ngIf="showColumn">
                               {{item.quotation_info?.doc_no}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="fixed-column">
                                <h5 class="text-bold-600">{{item.quotation_mit_item_info?.description || item.quotation_mit_item_info?.equipment_name}}</h5>
                                <p>Maker : {{item.quotation_mit_item_info.marker || '-'}}</p>
                                <p>Model : {{item.quotation_mit_item_info.model || '-'}}</p>
                                <p>Serial No : {{item.quotation_mit_item_info.serial_no || '-'}}</p>
                                <p>Tag No : {{item.quotation_mit_item_info.tag_no || '-'}}</p>
                                <p>Due (Month): {{item.quotation_mit_item_info.due_month || '-'}}</p>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1">
                                {{item.quotation_mit_item_info?.scope_name}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" *ngIf="showColumn">
                                {{item.quotation_mit_item_info?.equipment_name}}
                            </td>
                            <td *ngIf="showColumn">
                                {{item.quotation_mit_item_details[0]?.method_name || ''}}
                            </td>
                            <td class="text-center" *ngIf="showColumn">
                                {{item.quotation_mit_item_details[0]?.service_location.toUpperCase() || ''}}
                            </td>
           
                            <td *ngIf="showColumn">
                                <ng-container [ngSwitch]="true">
                                    <ng-container *ngSwitchCase="item?.quotation_mit_item_info?.cus_calibration_point != null && item?.quotation_mit_item_info?.cus_calibration_point != ''">
                                        {{item?.quotation_mit_item_info?.cus_calibration_point}}
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <div *ngIf="item.quotation_mit_item_details[0]?.detail_type == 'point'">
                                            <span  class="pb-2" *ngFor="let point of item.quotation_mit_item_details[0]?.quotation_mit_item_detail_points; let pointIndex = index;">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline" *ngIf="point.is_non_calibration =='false' || !point?.is_non_calibration">
                                                    {{point.cus_point || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="point.cus_unit_name"></span>
                                                </span>
                                            </span>
                                        </div>
                                        <div *ngIf="item.quotation_mit_item_details[0]?.detail_type == 'range'">
                                            <span  class="pb-2">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                                    {{_utils.convertStingToNumber(item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.cus_min) || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                                </span>
                                            </span>
                                            - &nbsp;
                                            <span  class="pb-2">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                                    {{_utils.convertStingToNumber(item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.cus_max) || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                                </span>
                                            </span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                
                            </td>
                            <td>
                                <div *ngIf="item.quotation_mit_item_details[0]?.detail_type == 'point'">
                                    <span  class="pb-2" *ngFor="let point of item.quotation_mit_item_details[0]?.quotation_mit_item_detail_points; let pointIndex = index;">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline"  *ngIf="point.is_non_calibration =='false' || !point?.is_non_calibration">
                                            {{point.mit_point || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="point.mit_unit_name"></span>
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="item.quotation_mit_item_details[0]?.detail_type == 'range'">
                                    <span  class="pb-2">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                            {{_utils.convertStingToNumber(item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.mit_min) || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.mit_unit_name"></span>
                                        </span>
                                    </span>
                                     - &nbsp;
                                    <span  class="pb-2">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                            {{_utils.convertStingToNumber(item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.mit_max) || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.mit_unit_name"></span>
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <span *ngIf="item.quotation_mit_item_details.length != 0">
                                    {{item.quotation_mit_item_details[0]?.mpe}}
                                </span>
                            </td>
                            <td>{{item.quotation_mit_item_details[0]?.note || ''}}</td>
                            <td class="text-center" [attr.rowspan]="item.quotation_mit_item_details.length + 1">
                                {{item.quotation_mit_item_info?.cp_list_cali || ''}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1">
                                <div *ngFor="let attribute of item.quotation_mit_item_info.quotation_mit_item_attribute_info; let j = index;">
                                    <p *ngIf="attribute.attribute_name && (attribute.is_non_calibration =='false' || !attribute?.is_non_calibration)">&#9679; {{attribute.attribute_name}} = {{attribute.attribute_value | number : '1.0-2'}}</p>
                                </div>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" *ngIf="['ICL','ICS'].includes(template) && ['WAITING_VERIFY_CRF', 'PREPARE_FOR_SHIP', 'COMPLETED', 'CONFIRMED','RETURNED_SUCCESS', 'REJECTED_VERIFY_CRF', 'WORK_INCOMPLETE'].includes(jobOrderInfo?.doc_status_id)">
                                {{item.std}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="text-right" *ngIf="!isCalibration && !isTranDep">
                                {{item.quotation_mit_item_info.original_price | number : '1.2-2'}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="text-right" *ngIf="!isCalibration && !isTranDep">
                                <div>
                                   {{item.quotation_mit_item_info.price | number : '1.2-2'}}
                                </div>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="text-right" *ngIf="!isCalibration && !isTranDep">
                                {{item.quotation_mit_item_info.discount}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="text-right" *ngIf="!isCalibration && !isTranDep">
                                {{_calculateFunctionService.sumMit(item.quotation_mit_item_info) | number : '1.2-2'}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1">
                                <div
                                    [ngClass]="{
                                    'text-success': (item?.item_status_id == 'CRF-CONFIRMED' || item?.item_status_id == 'CRF-COMPLETED' || item?.item_status_id == 'PREPARE_FOR_SHIP'),
                                    'text-danger': (item?.item_status_id == 'CRF-REJECTED' || item?.item_status_id == 'CRF-CANCELED') }">
                                    {{getStatus(item.item_status_id)}}
                                    <span *ngIf="item?.item_status_id == 'CRF-REJECTED'" data-toggle="popover" data-popover="true" [attr.data-content]="setContent(item)">
                                        <i class="fa fa-comment pointer" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <span *ngIf="item.quotation_mit_item_info?.cost_of_sales != null && !isTranDep">
                                    (ต้นทุน: {{_utils.convertStingToNumber(item.quotation_mit_item_info?.cost_of_sales) | number : '1.2-2' }} บาท)
                                </span>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" *ngIf="['ICL','ICS'].includes(template) && jobOrderInfo?.doc_status_id !== 'DRAFT' && jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM' && jobOrderInfo?.doc_status_id !== 'REJECTED' && jobOrderInfo?.doc_status_id !== 'CANCELED' && pageType !='create'">
                                <div *ngIf="!item?.created_by_info && !item?.service_date && !item?.status">
                                    <!-- note -->
                                    <div [ngClass]="{
                                    'text-danger': item?.job_order_status_work_info[0]?.status == 'FAIL',
                                    'text-success': item?.job_order_status_work_info[0]?.status == 'PASS' }">
                                        {{item?.job_order_status_work_info[0]?.status || 'WAITING'}}
                                        <small *ngIf="item?.job_order_status_work_info[0]?.reject_reason && item?.job_order_status_work_info[0]?.status == 'CANCEL'">{{item?.job_order_status_work_info[0]?.reject_reason}}</small>
                                    </div>
                                    <small *ngIf="item?.job_order_status_work_info[0]?.service_date">
                                        วันที่ {{item?.job_order_status_work_info[0]?.service_date | thai_date: "short" | dash}}
                                    </small>
                                    <br>
                                    <small *ngIf="item?.job_order_status_work_info[0]?.service_date">
                                        โดย: {{item?.job_order_status_work_info[0].created_by_info?.firstname_th}} {{item?.job_order_status_work_info[0].created_by_info?.lastname_th}}
                                    </small>
                                    <br>
                                    <small *ngIf="item?.job_order_status_work_info[0]?.service_date">
                                        PIC: {{item.person_in_charge_info.firstname_th}} {{item.person_in_charge_info.lastname_th}}
                                    </small>
                                    <br>
                                    <small *ngIf="item?.job_order_status_work_info[0]?.service_date">
                                        Note: {{item.job_order_status_work_info[0]?.note | dash}}
                                    </small>
                                    <br>
                                    <small *ngIf="item?.job_order_status_work_info[0]?.service_date">
                                        <b>การตรวจสอบ Cert:</b> {{item?.cert_verify_note | dash}}
                                    </small>
                                </div>
                                <div *ngIf="item?.created_by_info || item?.service_date || item?.status">
                     
                                    <div [ngClass]="{'text-warning': item?.status == undefined,
                                    'text-danger':item?.status == 'FAIL' ,
                                    'text-success':item?.status == 'PASS' }">
                                        {{item?.status || 'WAITING'}} 
                                        <small *ngIf="item.reject_reason && item?.status == 'CANCEL'">{{item.reject_reason}}</small>
                                    </div>
                                    <small *ngIf="item?.service_date">
                                        วันที่ {{item?.service_date | thai_date: "short" | dash}}
                                    </small>
                                    <br>
                                    <small *ngIf="item?.service_date">
                                        โดย: {{item?.created_by_info?.firstname_th}} {{item?.created_by_info?.lastname_th}}
                                    </small>
                                    <br>
                                    <small *ngIf="item?.service_date">
                                        PIC: {{item.person_in_charge_info.firstname_th}} {{item.person_in_charge_info.lastname_th}}
                                    </small>
                                    <br>
                                    <small *ngIf="item?.service_date">
                                        Note: {{item.note | dash}}
                                    </small>
                                    <br>
                                    <small *ngIf="item?.service_date">
                                        <b>การตรวจสอบ Cert:</b> {{item.cert_verify_note | dash}}
                                    </small>
                                </div>
                            </td>

                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" *ngIf="pageType !='show' &&  jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM'">
                                <button
                                    *ngIf="['ICL','ICS'].includes(template)"
                                    (click)="confirmItem(item, i)"
                                    type="button"
                                    class="btn mr-1 mb-1 btn-success btn-sm"
                                > <i class="fa fa-check-circle-o icon-left"></i> ยืนยันให้บริการ
                                </button>
                                <button
                                    *ngIf="['ECL','ECS'].includes(template)"
                                    (click)="select(item)"
                                    data-target="#confirm-calibration-item"
                                    data-toggle="modal"
                                    type="button"
                                    class="btn mr-1 mb-1 btn-success btn-sm"
                                > <i class="fa fa-check-circle-o icon-left"></i> ยืนยันให้บริการ
                                </button>
                                <button
                                    (click)="select(item)"
                                    data-target="#reject-calibration-item"
                                    data-toggle="modal"
                                    type="button"
                                    class="btn mr-1 mb-1 btn-danger btn-sm"
                                > <i class="fa fa-times-circle-o icon-left"></i> ไม่สามารถให้บริการได้
                                </button>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1">
                                <span>
                                    <button
                                    type="button"
                                    class="btn mr-1 mb-1 btn-primary btn-sm btn-edit"
                                    data-toggle="modal"
                                    data-target="#mit-item-log"
                                    (click)="select(item)"
                                    > <i class="fa fa-history icon-left"></i> ดูประวัติ
                                    </button>
                                </span>
                                <span *ngIf="!isTranDep && !isSale && (jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM')">
                                    <button
                                    *ngIf="((jobOrderInfo?.doc_status_id === 'PREPARE_FOR_SHIP' && ['ECL','ECS'].includes(template) && item.item_status_id != 'CRF-REJECTED')
                                    || (jobOrderInfo?.doc_status_id === 'CONFIRMED' &&  ['ECL','ECS'].includes(template) && item.item_status_id != 'CRF-REJECTED'))"
                                        data-target="#upload-cert"
                                        data-toggle="modal"
                                        type="button"
                                        class="btn mr-1 mb-1 btn-info btn-sm"
                                        [ngClass]="{'btn-primary': pageType=='show', 'btn-info' : pageType!='show' }"
                                        (click)="uploadCertEvent(item)"
                                    > 
                                    <span *ngIf="pageType=='show'">
                                        <i class="fa fa-eye icon-left"></i> ดูข้อมูลการดำเนินการ 
                                    </span>
                                    <span *ngIf="pageType!='show'">
                                        <i class="fa fa-file-o icon-left"></i> บันทึกการดำเนินการ
                                    </span>
                                    <span *ngIf="item.job_order_cert.length > 0">
                                        ({{item.job_order_cert.length}})
                                    </span>
                                    </button>
                                
                                </span>

                                <span class="btn-group" *ngIf="!isTranDep && pageType !='show' && (jobOrderInfo?.doc_status_id !== 'WAITING_FOR_CONFIRM')">
                                    <button
                                        *ngIf="((jobOrderInfo?.doc_status_id === 'CONFIRMED' && !isSale && ['ECL','ECS'].includes(template) && item.item_status_id != 'CRF-REJECTED'))"
                                        (click)="select(item)"
                                        data-target="#reject-calibration-item"
                                        data-toggle="modal"
                                        type="button"
                                        class="btn mr-1 mb-1 btn-danger btn-sm"
                                    > <i class="fa fa-times-circle-o icon-left"></i> ยกเลิก
                                    </button>
                                    <button
                                        *ngIf="next_status!='complete' && ((pageType =='create' || jobOrderInfo?.doc_status_id=='CONFIRMED' || jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='REJECTED') 
                                        && ['ICL','ICS'].includes(template)) || (['ECL','ECS'].includes(template) && (pageType =='create' || jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='REJECTED'))"
                                        type="button"
                                        data-target="#edit-quotation-mit-item"
                                        data-toggle="modal"
                                        class="btn mr-1 mb-1 btn-warning btn-sm"
                                        (click)="select(item)"
                                    > <i class="fa fa-pencil icon-left"></i> แก้ไข
                                    </button>
                                    <button
                                        *ngIf="((jobOrderInfo?.doc_status_id === 'CONFIRMED' && isSale && item.item_status_id != 'CRF-REJECTED'))"
                                        (click)="cancel(item)"
                                        type="button"
                                        class="btn mr-1 mb-1 btn-danger btn-sm"
                                    > <i class="fa fa-times-circle-o icon-left"></i> ยกเลิก
                                    </button>

                                    <button
                                        *ngIf="jobOrderInfo?.doc_status_id!='CONFIRMED' && jobOrderInfo?.doc_status_id !== 'COMPLETED' && jobOrderInfo?.doc_status_id !== 'WAITING_VERIFY_CRF'"
                                        type="button"
                                        class="btn mr-1 mb-1 btn-danger btn-sm"
                                        data-target="#modalConfirmDelMsgReceiveItem"
                                        data-toggle="modal"
                                        (click)="select(item)"
                                    > <i class="fa fa-trash icon-left"></i> ลบ
                                    </button>
                                </span>
                            </td>
                           
                        </tr>
                        <tr *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                            <td *ngIf="detailIndex != 0 && showColumn">
                                {{detail.method_name}}
                            </td>
                            <td *ngIf="detailIndex != 0 && showColumn" class="text-center">
                                {{detail.service_location.toUpperCase()}}
                            </td>
                            <td *ngIf="detailIndex != 0 && showColumn">
                                <ng-container [ngSwitch]="true">
                                    <ng-container *ngSwitchCase="item?.quotation_mit_item_info?.cus_calibration_point != null && item?.quotation_mit_item_info?.cus_calibration_point != ''">
                                        {{item?.quotation_mit_item_info?.cus_calibration_point}}
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <div *ngIf="detail.detail_type == 'point'">
                                            <span class="pb-2" *ngFor="let point of detail.quotation_mit_item_detail_points; let pointIndex = index;">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline" *ngIf="point.is_non_calibration =='false' || !point?.is_non_calibration">
                                                    {{point.cus_point || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="point.cus_unit_name"></span>
                                                </span>
                                            </span>
                                        </div>
                                        <div *ngIf="detail.detail_type == 'range'">
                                            <span  class="pb-2">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                                    {{_utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_min) || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="detail?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                                </span>
                                            </span>
                                            - &nbsp;
                                            <span  class="pb-2">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                                    {{_utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_max) || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="detail?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                                </span>
                                            </span>
                                        </div>
                                    </ng-container>
                                </ng-container>

                            </td>
                            <td *ngIf="detailIndex != 0">
                                <div *ngIf="detail.detail_type == 'point'">
                                    <span class="pb-2" *ngFor="let point of detail.quotation_mit_item_detail_points; let pointIndex = index;">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline" *ngIf="point.is_non_calibration =='false' || !point?.is_non_calibration">
                                            {{point.mit_point || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="point.mit_unit_name"></span>
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="detail.detail_type == 'range'">
                                    <span  class="pb-2">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                            {{_utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.mit_min) || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="detail?.quotation_mit_item_detail_range.mit_unit_name"></span>
                                        </span>
                                    </span>
                                    - &nbsp;
                                    <span  class="pb-2">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                            {{_utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.mit_max) || 0 | number : '1.1-5'}}
                                            <span [innerHTML]="detail?.quotation_mit_item_detail_range.mit_unit_name"></span>
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td *ngIf="detailIndex != 0">{{detail.mpe}}</td>
           
                            <td *ngIf="detailIndex != 0">{{detail.note || ''}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="pageLoad">
                        <loading class="ml-5"></loading>
                    </tbody>
                    <tbody *ngIf="groupJobOrderItems.length === 0 && !pageLoad">
                        <tr>
                            <td [attr.colspan]="25" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <span class="pl-1 text-danger w-100">
                * ราคา Lock เมื่อ มีการบันทึก CP นอก Scope หรือ มีการเปลี่ยนแปลง Point แล้วราคาใหม่สูงกว่าราคาเดิม
            </span>
            <span class="pl-1 text-danger w-100" *ngIf="(submitted && !canConfirm) && ['ECL','ECS'].includes(template) && jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM'">
                โปรดยืนยันรายการ
            </span>
            <span class="pl-1 text-danger w-100" *ngIf="(submitted && !canCompleted) && jobOrderInfo?.doc_status_id === 'CONFIRMED'">
                โปรดบันทึกรายการ
            </span>
        </div>
    </div>
</div>


<!-- all modal -->
<app-confirm-delete
  [id]="'modalConfirmDelMsgReceiveItem'"
  (save)="delete($event)"
></app-confirm-delete>

<app-work-order-lab-item-create 
*ngIf="customer_info && popupType" 
[jobOrderItems]="jobOrderItems"
[popupType]="popupType"
[itemStatus]="itemStatus" 
[customer_info]="customer_info" 
(done)="submitJobOrderItem($event);">
</app-work-order-lab-item-create>

<app-reject-calibration-item
*ngIf="jobOrderInfo"
[isRejectAll]="isRejectAll"
[jobOrderInfo]="jobOrderInfo"
(done)="rejectItem($event);">
</app-reject-calibration-item>

<app-confirm-calibration-item 
[isConfirmAll]="isConfirmAll"
(done)="confirmItemCostOfSales($event);">
</app-confirm-calibration-item>

<app-work-order-lab-import 
*ngIf="jobOrderInfo" 
[jobOrderInfo]="jobOrderInfo" 
(done)="mapImportExcel($event);">
</app-work-order-lab-import>

<app-upload-cert 
*ngIf="jobOrder && isUploadCert && pageType !='create'"
(done)="uploadCert($event);"
[jobOrder]="jobOrder">
</app-upload-cert>

<app-mit-item-log 
*ngIf="jobOrder"
(done)="resetJobOrder($event)"
[mitItem]="jobOrder">
</app-mit-item-log>

<app-edit-quotation-mit-item 
*ngIf="jobOrder 
&& dropDownData 
&& (((pageType =='create' || jobOrderInfo?.doc_status_id=='CONFIRMED' || jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='REJECTED') 
&& ['ICL','ICS'].includes(template)) || (['ECL','ECS'].includes(template) && (pageType =='create' || jobOrderInfo?.doc_status_id=='DRAFT' || jobOrderInfo?.doc_status_id=='REJECTED')))"
[isCalibration]="isCalibration"
[dropDownData]="dropDownData"
(done)="onEditMitItem($event)"
[jobOrder]="jobOrder">
</app-edit-quotation-mit-item>
<!-- all modal -->