<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">รายการเครื่องมือมาตรฐาน</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li
                class="breadcrumb-item"
                *ngIf="(userInfo$ | async)?.user_role_id === 'ADMIN'"
              >
                <a [routerLink]="['/standard']">ตั้งค่าระบบ</a>
              </li>
              <li
                class="breadcrumb-item"
                *ngIf="(userInfo$ | async)?.user_role_id === 'ADMIN'"
              >
                <a [routerLink]="['/standard']"
                  >จัดการข้อมูลเครื่องมือมาตรฐาน</a
                >
              </li>
              <li class="breadcrumb-item active">รายการเครื่องมือมาตรฐาน</li>
            </ol>
          </div>
        </div>
      </div>
      <div
        class="content-header-right col-md-6 col-12 mb-md-0 mb-2"
        *ngIf="(userInfo$ | async)?.user_role_id === 'ADMIN'"
      >
        <div class="float-md-right">
          <button
            type="button"
            class="btn btn-blue btn-min-width mr-1"
            (click)="download()"
          >
            <i class="fa fa-download icon-left"></i> Download
          </button>

          <button class="btn btn-outline-primary" (click)="create()">
            <i class="feather icon-plus-circle icon-left"></i>
            เพิ่มเครื่องมือมาตรฐาน
          </button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">รายการเครื่องมือมาตรฐาน</h4>
              <div class="heading-elements">
                <search-box (search)="search($event)"></search-box>
              </div>
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>Tag No.</th>
                        <th>Description</th>
                        <th>Cal Date</th>
                        <th>Due Date</th>
                        <th>Cal By</th>
                        <th>Status</th>
                        <th>Owner</th>
                        <th
                          *ngIf="(userInfo$ | async)?.user_role_id === 'ADMIN'"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="let standard of standards"
                        [ngClass]="{
                          'text-danger': validateDue(standard.due_date)
                        }"
                      >
                        <td class="text-nowrap">{{ standard.tag_no }}</td>

                        <td>
                          <p><b>Description</b>: {{ standard.description }}</p>
                          <p>
                            <b>Manufacturer</b>: {{ standard.manufacturer }}
                          </p>
                          <p><b>Model</b>: {{ standard.model }}</p>
                          <p><b>Serial No</b>: {{ standard.serial_no }}</p>
                        </td>
                        <td>
                          {{
                            standard.calibrate_date
                              | thai_date : "short" : "date"
                              | dash
                          }}
                        </td>
                        <td>
                          {{
                            standard.due_date
                              | thai_date : "short" : "date"
                              | dash
                          }}
                        </td>
                        <td>{{ standard.calibrated_by }}</td>
                        <td>{{ standard.status }}</td>
                        <td>{{ standard.owner }}</td>

                        <td
                          *ngIf="(userInfo$ | async)?.user_role_id === 'ADMIN'"
                        >
                          <button
                            type="button"
                            class="btn mr-1 mb-1 btn-primary btn-sm"
                            (click)="edit(standard)"
                          >
                            <i class="fa fa-edit icon-left"></i> แก้ไขข้อมูล
                          </button>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 bg-danger bg-darken-1 text-white btn-sm"
                            data-target="#modalConfirmDelMsg"
                            data-toggle="modal"
                            (click)="select(standard.standard_id)"
                            [disabled]=""
                          >
                            <i class="fa fa-trash icon-left"></i> ลบ
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="!standards.length">
                        <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<app-standard-create
  (save)="saveStandard($event)"
  (close)="close()"
  *ngIf="createStandard"
></app-standard-create>

<app-standard-edit
  [standard]="standard"
  (save)="saveStandard($event)"
  (close)="close()"
  *ngIf="standard"
>
</app-standard-edit>

<app-confirm-delete (save)="delete($event)"></app-confirm-delete>
