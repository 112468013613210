import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { 
  DownloadFileService,
} from 'src/app/services/quotations';

@Component({
  selector: 'app-invoice-po-item',
  templateUrl: './invoice-po-item.component.html',
  styleUrls: ['./invoice-po-item.component.css']
})
export class InvoicePoItemComponent implements OnInit {
  @Input() poItems = null;

  pageType: string = 'create';
  poList = []
  constructor(
    private router: Router,
    private DownloadFileService: DownloadFileService,
  ) { 
    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.mapPo();
  }

  mapPo(){
    let poitems = this.poItems.filter((po, index, self) =>
    index === self.findIndex((t) => (
      t.quotation_info_id === po.quotation_info_id 
    )));

    if(this.pageType == 'show' || this.pageType == 'edit'){

      poitems = poitems.map(po=>{
        return{
          ...po,
          data: {
            quotation_info: po.quotation_info[0]
          }
        }
      })
    }

    poitems.map(po=>{
      po.data.quotation_info.quotation_accepted_info.map(accepted=>{
        this.poList.push(
          {
            doc_no: po.data.quotation_info?.doc_no,
            data: accepted
          }
        )
      })
    })
  }

  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }
}
