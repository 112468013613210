import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { 
  ReportService,
  UtilsService
} from '../../../../services';
import { EmployeeService } from "src/app/services/index";
import moment from "moment";
@Component({
  selector: 'app-report-create',
  templateUrl: './report-create.component.html',
  styleUrls: ['./report-create.component.css']
})
export class ReportCreateComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") closeModal: ElementRef;
  @Input() status: any;
  @Input() id = "modalReportCreate";
  @Input() reportTypes = [];

  years = [
  ];

  tranYears = [
  ];
  months = [
    {id: "01", text: "มกราคม"},
    {id: "02", text: "กุมภาพันธ์"},
    {id: "03", text: "มีนาคม"},
    {id: "04", text: "เมษายน"},
    {id: "05", text: "พฤษภาคม"},
    {id: "06", text: "มิถุนายน"},
    {id: "07", text: "กรกฎาคม"},
    {id: "08", text: "สิงหาคม"},
    {id: "09", text: "กันยายน"},
    {id: "10", text: "ตุลาคม"},
    {id: "11", text: "พฤศจิกายน"},
    {id: "12", text: "ธันวาคม"},
  ];
  employeeList = [];
  
  constructor(
    private formBuilder: FormBuilder,

    public UtilsService: UtilsService,
    private ReportService: ReportService,
    private EmployeeService: EmployeeService,
  ) {
    this.setForm();
  }

  setForm(){
    this.form = this.formBuilder.group({
      report_type_id: ["", [Validators.required]],
      sale_id: [""],
      cuscode_prefix: [""],
      month: [""],
    });
    

    for (let index = 0; index <= moment().year()-2021; index++) {

      this.years.push(
        {
          id: (moment().year()- index).toString().charAt(2) + (moment().year()- index).toString().charAt(3),
          text: (moment().year()- index).toString()
        }
      )
    }

    for (let index = 0; index <= moment().year()-2022; index++) {

      this.tranYears.push(
        {
          id: (moment().year()- index).toString().charAt(2) + (moment().year()- index).toString().charAt(3),
          text: (moment().year()- index).toString()
        }
      )
    }

    this.form.controls.report_type_id.valueChanges.subscribe(
      (value) => {
        this.form.get('sale_id').setValue("all");
        this.form.get('cuscode_prefix').setValue(this.years[0].id);
        this.form.get('month').setValue("");
      }
    );
    
  }

  ngOnInit(): void {
    this.EmployeeService.load(null, {emp_department_id: 'SALES_DEP'}).then(res=>{
      this.employeeList = res.resultData;
      this.employeeList = this.employeeList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `[${elem.emp_code}] ${elem.user_info?.firstname_th} ${elem.user_info?.lastname_th}`,
          data: elem
        }
      })

      this.employeeList.unshift(
        {
          id: "NO_ASSIGN",
          text: "ไม่มีผู้ถือครอง"
        }
      );
      this.employeeList.unshift(
        {
          id: "all",
          text: "ทั้งหมด"
        }
      );
    });
  }

  async onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    var data = this.form.value;
    let http_body = null;
    if(data.sale_id){
      http_body = {
        sale_id: data.sale_id
      };
    }
    let number_text = "และลูกค้าขึ้นทะเบียนปี";
    if(data.report_type_id == 'CALIBRATION_ITEM'){
      number_text = "และรายการเครื่องมือปี";
    }
    if(data.cuscode_prefix){
      http_body = {
        ...http_body,
        cuscode_prefix: data.cuscode_prefix
      };
    }
    if(data.month){
      http_body = {
        ...http_body,
        month: data.month
      };
    }
    if(http_body){
      http_body = JSON.stringify(http_body);
    }

    let report_desc = "";
    if(data.sale_id == "all"){
      report_desc = "รายงานของพนักงานขายทั้งหมด "+ number_text +" "+ data.cuscode_prefix;
    }else if(data.sale_id == "NO_ASSIGN") {
      let sale = this.employeeList.find(emp=> emp.id == data.sale_id);
      report_desc = "รายงานของพนักงานขาย: "+ sale.text +" "+ number_text +" "+ data.cuscode_prefix;
    }else{

      let sale = this.employeeList.find(emp=> emp.id == data.sale_id);
      report_desc = "รายงานของพนักงานขาย: "+sale.data.user_info?.firstname_th + " "+ sale.data.user_info?.lastname_th +" "+ number_text +" "+ data.cuscode_prefix;
    }
   
    let reportData = {
      ...data,
      http_body: http_body,
      doc_url: null,
      status: "QUEUE",
      report_desc: report_desc,
      report_name: this.reportTypes.find(re=> re.id == data.report_type_id).text,
    }

    let reportResponse = await this.ReportService.create(reportData);
    if (reportResponse.success) {
      this.save.emit({
        status: reportResponse,
        closeModal: this.closeModal
      })
    } else {
      this.save.emit({
        status: reportResponse,
        closeModal: this.closeModal
      })
    }
  }

  onClose() {
    this.setForm();
  }
}
