import { Component, OnInit, NgZone, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { QuotationFileService, QuotationUtilsService } from '../../../../../services/quotations';
import { FormMitService } from '../quotation-item.service';
import * as fs from 'file-saver';
import * as Excel from "exceljs";
import { generateMitItemLogService, MITEquipmentService } from 'src/app/services';
import { NavigationStart, Router } from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-quotation-item-import-create',
  templateUrl: './quotation-item-import-create.component.html',
  styleUrls: ['./quotation-item-import-create.component.css']
})
export class QuotationItemImportCreateComponent implements OnInit {
  willDownload: boolean = false;
  isSubmit: boolean = false;
  status: boolean = true;
  isLoading: boolean = false; 

  number_of_success: number = 0;
  number_of_fails: number = 0;
  //array
  cps = [];
  units = [];
  mitItems = [];
  quotationMitItems = [];
  mitBodyList = [];
  mitHeaderList = [];
  scopeList = [];
  equipmentList = [];
  oldEquipmentList = [];
  //any
  @Input('dropDownData') dropDownData: any;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  file = null;

  constructor(
    private zone: NgZone,
    private router: Router,
    private formMitService: FormMitService,
    private MITEquipmentService: MITEquipmentService,
    private QuotationFileService: QuotationFileService,
    private generateMitItemLogService: generateMitItemLogService,

    public _utils : QuotationUtilsService,
  ) { 
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
  }

  async ngOnInit() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip();
    });

    if(this.dropDownData){
      this.scopeList = this.dropDownData.scopeList;
      this.scopeList = this.scopeList.map(function(elem) {
        return {
          id: elem.mit_scope_id,
          text: elem.scope_name
        }
      });
      this.cps = this.dropDownData.cps || [];
      this.units = this.dropDownData.units || [];
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  async setDataToForm(mitItems: any){
    this.quotationMitItems = [];
    mitItems.map(async v=>{

      let mitItem = null;
      mitItem = {
        mit_scope_id: v.scope?.mit_scope_id || '',
        mit_equipment_id: v.equipment?.mit_equipment_id || '',
        mit_method_id: v.method[0]?.mit_method_info?.mit_method_id,
        method_name: v.method[0]?.mit_method_info?.method_name,
        service_lab: true,
        service_onsite: true,
        service_location: this.capitalizeFirstLetter(v.location),
        marker: v.marker || '',
        description: v.description || '',
        model: v.model || '',
        serial_no: v.serial_no || '',
        tag_no: v.tag_no || '',
        due_month: v.due_month || 0,
        order_by: 0,
        original_price: 0,
        scope_name: v.scope?.scope_name || '', 
        equipment_name: v.equipment?.equipment_name || '', 
        note_1: v.equipment?.note_1 || '', 
        note_2: v.equipment?.note_2 || '',
        cp_list_init: v.method[0]?.mit_cp_list || '',
        cp_list_cali: v.method[0]?.mit_cp_list || '',
        price: null,
        discount: null,
        quotation_mit_item_attributes: [],
        quotation_mit_item_details: [],
        mit_item_logs: [],
        cus_calibration_point: v.cus_calibration_point || undefined
      }

      const equipment = 
      this.oldEquipmentList.filter(item => (
        item.id === mitItem?.mit_equipment_id)
      );

      const detailList = equipment[0].data?.product_calibration_fee_info || [];

      detailList.map(item=>{
        mitItem.quotation_mit_item_details.push(
          this.formMitService.createDetailItemForExcel(item, equipment[0].data, v)
        );
      })
      if(v.channel != ''){
        mitItem.quotation_mit_item_attributes.push({
          attribute_name: 'Channel',
          attribute_value: v.channel
        });
      }
      if(v.display != ''){
        mitItem.quotation_mit_item_attributes.push({
          attribute_name: 'Display',
          attribute_value: v.display
        });
      }
      if(v.sensor != ''){
        mitItem.quotation_mit_item_attributes.push({
          attribute_name: 'Sensor',
          attribute_value: v.sensor
        });
      }
      if(v.function != ''){
        mitItem.quotation_mit_item_attributes.push({
          attribute_name: 'Function',
          attribute_value: v.function
        });
      }
      if(v.element != ''){
        mitItem.quotation_mit_item_attributes.push({
          attribute_name: 'Element',
          attribute_value: v.element
        });
      }

      if(v.range.length > 0){
        const point = v.range[0];
        
        let ranges = mitItem.quotation_mit_item_details.filter(
          d=> parseFloat(point.data) >= d.min_range && parseFloat(point.data) <= d.max_range
          && point.unitInfo.unit_id == d.unit_id && point.methodInfo.mit_method_id === d.mit_method_id 
          && d.service_location.toLowerCase() == mitItem.service_location.toLowerCase()
        );
        if(ranges.length > 1){
          var sorted = ranges.sort(function(a, b) {
            if(a.max_range > b.max_range) return 1;
            else if(a.max_range < b.max_range) return -1;
            else return 0;
          });
          var selected = sorted[0];
        }else{
          var selected = ranges[0];
        }
   
        mitItem.quotation_mit_item_details.map(d=>{
          if(d === selected){
            let units = this.getUnits(d.unit_id);
            const unit = units.find(item=>
               item.unit_id == d.unit_id
            );
            d.detail_type = 'range';
            d.detail_input_type = 'range';
            d.quotation_mit_item_detail_range = {
              cus_max: parseFloat(point.data),
              cus_min: selected?.min_range,
              cus_unit_id: unit.unit_id || "",
              cus_unit_name: unit.unit_short_name_en || "",
              mit_max: parseFloat(point.data),
              mit_min: selected?.min_range,
              mit_unit_id: unit.unit_id || "",
              mit_unit_name: unit.unit_short_name_en || "",
              quotation_mit_item_detail_range_id: ""
            }
          }
        })
      }else{
        const points = v.points;

        points.map(item=>{

          const selected = mitItem.quotation_mit_item_details.find(
            (d: any)=> parseFloat(item.data) >= d.min_range && parseFloat(item.data) <= d.max_range
            && item.unitInfo.unit_id == d.unit_id && item.methodInfo.mit_method_id === d.mit_method_id
            && d.service_location.toLowerCase() == mitItem.service_location.toLowerCase()
          );

          mitItem.quotation_mit_item_details.map((d: any)=>{
            if(d.product_calibration_fee_id === selected.product_calibration_fee_id){
              let units = this.getUnits(d.unit_id);
              const unit = units.find((item: any)=>
                 item.unit_id == d.unit_id
              );
              let data = {
                cus_point: parseFloat(item.data),
                cus_unit_id: unit.unit_id || "",
                cus_unit_name: unit.unit_short_name_en || "",
                mit_point: parseFloat(item.data),
                mit_unit_id: unit.unit_id || "",
                mit_unit_name: unit.unit_short_name_en || "",
              }
              d.quotation_mit_item_detail_points.push(data);
            }
          })
        })
      }
      this.quotationMitItems.push(mitItem);
    })
  }

  uploadFile(){
    document.getElementById("importMitFile").click();
  }

  downloadTemplate(){
    var header = ["","Item ลำดับที่", "Description รายการ", "Maker ผู้ผลิต", "Model รุ่นเครื่อง", "Serial No. เลขที่เครื่อง", "Tag No. หมายเลขชี้บ่ง",
    "Calibration Pointจุดสอบเทียบ", "Unit", "MPE(±) ค่ายอมรับได้", "Due (Month)", "Scope", "Equipment", "Method", "Location", "Calibration Point",
    "เหมา range (max point)", "Unit2", "Note", "Channel", "Sensor", "Display", "Function", "Element"];
    let wb = new Excel.Workbook();
    let ws = wb.addWorksheet('calibration_template');  

    let headerRow = ws.addRow(header);
    headerRow.eachCell((cell, number) => {
      let style_1 = [2,3,4,5,6,7,8,9,10,11]; 
      let style_2 = [12,13,14,15,18,19,20,21,22,23, 24]; 
      let style_3 = [16, 17]; 
      if(number !== 1){
        ws.getColumn(number).width = 20;
        if(style_1.includes(number)){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'ff6600' },
          }
        }else if(style_2.includes(number)){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '009933' },
          }
        }else if (style_3.includes(number)){
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFC000' },
          };
        }
        cell.border = { 
          top: { style: 'thin', color: {argb:'FFFFFF'} }, 
          left: { style: 'thin', color: {argb:'FFFFFF'} }, 
          bottom: { style: 'thin', color: {argb:'FFFFFF'} }, 
          right: { style: 'thin', color: {argb:'FFFFFF'} } 
        }
        cell.font = {
          name: 'Tahoma',
          color: { 
            argb: 'FFFFFF'
          },
          bold: true,
          size: 11
        };
        cell.alignment = { vertical: 'middle', horizontal: 'center' }
      }
    });
    wb.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'calibration_template.xlsx');
    });
  }

  onFileChange(ev) {
    this.clearItem();
    const reader = new FileReader();
    this.file = ev.target.files[0];
    reader.onload = (event) => {
      this.validateFileByExcel();
    }
    reader.readAsBinaryString(this.file);
    $("#importMitFile").val('');
  }

  async validateFileByExcel() {
    this.isLoading  = true;
    this.status = true;
    if(this.file){
      const response = await this.QuotationFileService.validateQuotationExcel(this.file);
      if(response.success && response.resultData.body.length > 0){
        this.mitHeaderList = response.resultData.header;
        this.mitBodyList = this.getValidateField(response.resultData.body);
        this.getNumberOfStatus(this.mitBodyList);
        this.willDownload = true;
      }else{
        this.clearItem();
      }
    }
    this.isLoading  = false;
  }

  async validateFileByData() {
    this.isLoading  = true;
    this.status = true;
    if(this.mitBodyList.length > 0){
      const response = await this.QuotationFileService.validateQuotationData(this.mitBodyList);
      if(response.success && response.resultData.body.length > 0){
        this.mitBodyList = this.getValidateField(response.resultData.body);
        this.getNumberOfStatus(this.mitBodyList);
        this.willDownload = true;
      }else{
        this.clearItem();
      }
    }
    this.isLoading  = false;
  }

  getValidateField(mitBodyList){
    if(mitBodyList.length > 0){
      for (let i = 0; i < mitBodyList.length; i++){
        for (let j = 0; j < mitBodyList[i].length; j++){
          const points = mitBodyList[i][j].dataCPoint;
          if(points){
            let isValidPoint = null, isValid = null;
            points.map(v =>{
              const res = v.data.find((v: any)=> v.reason);
              if(res) isValidPoint = res;
            })
            points.map((v: any) =>{
              if(v.isValid == false) isValid = v;
            })
            if(isValidPoint){
              mitBodyList[i][j].isValid = false;
              mitBodyList[i][j].reason = isValidPoint.reason;
            }else if(isValid){
              mitBodyList[i][j].isValid = false;
              mitBodyList[i][j].reason = isValid.reason;
            }
          }
        }
      }
    }
    return mitBodyList;
  }

  async submit(){
    this.isLoading  = true;
    this.isSubmit = true;
    this.status = true;
    if(this.checkValidFile(this.mitBodyList)){
      this.mitItems = await this.setMitItem(this.mitHeaderList, this.mitBodyList);
      await this.setMethodInfo(this.mitItems);
      await Promise.all(this.mitItems.map(async v=>{
        await this.MITEquipmentService.getById({mit_equipment_id: v.equipment.mit_equipment_id}).then((res) => {
          if(res.resultData){
            this.oldEquipmentList.push(res.resultData);
          }
        });
      }));
      this.oldEquipmentList = await Promise.all(this.oldEquipmentList.map(async elem=>{
        return {
          data: elem,
          id: elem.mit_equipment_id,
          text: elem.equipment_name
        }
      }));
      this.setDataToForm(this.mitItems);
      this.calculateTotalAmountItemDetail();
    }
    if(!this.checkValidFile(this.mitBodyList) || this.quotationMitItems.length == 0){
      this.status = false;
      return;
    }

    this.quotationMitItems.map(async item=>{
      item = await this.generateMitItemLogService.createMitItemLog(item);
    });
    this.isLoading  = false;
    this.isSubmit = false;
    this.done.emit(this.quotationMitItems);
    this.closeModalBtn.nativeElement.click();
  }

  checkValidFile(mitBodyList): boolean {
    let result = mitBodyList.map((v) => {
      return v.find(item => item.isValid == false);
    }).filter(res => res != undefined);
    return result.length > 0? false: true;
  }

  async setMitItem(headers, contents){
    const units = [];
    for(let i = 0; i < contents.length; i++){
      for (let j = 0; j < headers.length; j++) {
        if(headers[j].toLowerCase() == 'unit2'){
          units.push(contents[i][j]);
        }
      }
    }
    const mitItems = [];
    for(let i = 0; i < contents.length; i++){
      let mitItem = {};
      for (let j = 0; j < headers.length; j++) {
  
        if(headers[j].toLowerCase().includes('description')){
          mitItem['description'] = contents[i][j].data;
        }else if(headers[j].toLowerCase().includes('maker')){
          mitItem['marker'] = contents[i][j].data;
        }else if(headers[j].toLowerCase().includes('model')){
          mitItem['model'] = contents[i][j].data;
        }else if(headers[j].toLowerCase().includes('serial no')){
          mitItem['serial_no'] = contents[i][j].data;
        }else if(headers[j].toLowerCase().includes('tag no')){
          mitItem['tag_no'] = contents[i][j].data;
        }else if(headers[j].toLowerCase().includes('mpe')){
          mitItem['mpe'] = contents[i][j].data;
        }else if(headers[j].toLowerCase().includes('due')){
          mitItem['due_month'] = contents[i][j].data;
        }else if(headers[j].toLowerCase().includes('iso')){
          mitItem['iso'] = contents[i][j].data;
        }else if(headers[j].toLowerCase().includes('scope')){
          mitItem['scope'] = contents[i][j].dataFromMysql;
        }else if(headers[j].toLowerCase().includes('equipment')){
          mitItem['equipment'] = contents[i][j].dataFromMysql;
        }else if(headers[j].toLowerCase().includes('method')){
          mitItem['method'] = contents[i][j].dataFromMysql || [];
        }else if(headers[j].toLowerCase().includes('location')){
          mitItem['location'] = contents[i][j].data;
        }else if(headers[j].toLowerCase().includes('range')){
          mitItem['range'] = [];
          const points = contents[i][j]?.dataCPoint;
          if(points){
            for (let k = 0; k < points.length; k++){
              for(let l = 0; l < points[k].data.length; l++){
                mitItem['range'].push({
                  data: points[k]?.data[l]?.data, 
                  unitInfo: units[i].unit_info,
                  methodInfo: points[k]?.method
                });
              }
            }
          }
        }else if(headers[j].toLowerCase() == 'calibration point'){
          mitItem['points'] = [];
          const points = contents[i][j]?.dataCPoint;
          if(points){
            for (let k = 0; k < points.length; k++){
              for(let l = 0; l < points[k].data.length; l++){    
                if(units[i].unit_info.length > 0){
                  mitItem['points'].push({
                    data: points[k]?.data[l]?.data, 
                    unitInfo: units[i].unit_info[k],
                    methodInfo: points[k]?.method
                  });
                }else{
                  mitItem['points'].push({
                    data: points[k]?.data[l]?.data, 
                    unitInfo: units[i].unit_info,
                    methodInfo: points[k]?.method
                  });
                }
              }
            }
          }
        }else if(headers[j].toLowerCase() == 'unit2'){
          mitItem['unit'] = contents[i][j].unit_info;
        }else if(headers[j].toLowerCase() == 'note'){
          mitItem['note'] = contents[i][j].data;
        }else if(headers[j].toLowerCase() == 'channel'){
          mitItem['channel'] = contents[i][j].data;
        }else if(headers[j].toLowerCase() == 'sensor'){
          mitItem['sensor'] = contents[i][j].data;
        }else if(headers[j].toLowerCase() == 'display'){
          mitItem['display'] = contents[i][j].data;
        }else if(headers[j].toLowerCase() == 'function'){
          mitItem['function'] = contents[i][j].data;
        }else if(headers[j].toLowerCase() == 'element'){
          mitItem['element'] = contents[i][j].data;
        }else if(headers[j] ==  'Calibration Pointจุดสอบเทียบ'){
          mitItem['cus_calibration_point'] = contents[i][j].data;
        }
      }
      mitItems.push(mitItem);
    }
    return mitItems;
  }

  async setMethodInfo(mitItems: any){
    await Promise.all(mitItems.map(async (item: any, i: number)=>{
      await Promise.all(item.points.map(async (point: any, j: number)=>{
        if(point.methodInfo === null){
          this.mitItems[i].points[j].methodInfo = item.method[0];
        }
      }));
      await Promise.all(item.range.map(async (range: any, j: number)=>{
        if(range.methodInfo === null){
          this.mitItems[i].range[j].methodInfo = item.method[0];
        }
      }));
    }));
  }

  calculateTotalAmountItemDetail(){
    for (let i = 0; i < this.quotationMitItems.length; i++){
      const getDetailList = this.quotationMitItems[i].quotation_mit_item_details.filter(item =>(
        (item.min_point != 0 && item.min_point != null) && 
        (item.quotation_mit_item_detail_points.length > 0 ||
          (item.quotation_mit_item_detail_range.cus_unit_id != null &&
            item.quotation_mit_item_detail_range.cus_unit_id != '')
        )
      ));
      let detail_high_price_start = null;
      if(getDetailList.length > 0){
        detail_high_price_start = getDetailList.reduce((a, b) => a.price_start > b.price_start ? a : b);
      }
      this.quotationMitItems[i].quotation_mit_item_details.map((item, index)=>{
        const control = item;
        const number_of_detail_points = control.quotation_mit_item_detail_points.length;
        if(control.detail_type == 'point'){
          if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
            if(control.min_point == number_of_detail_points){
              this.quotationMitItems[i].quotation_mit_item_details[index].total_amount = control.price_start;
            }else if(control.min_point > number_of_detail_points){
              this.quotationMitItems[i].quotation_mit_item_details[index].total_amount = number_of_detail_points * control.price_point;
            }else if (control.min_point < number_of_detail_points){
              const dif = number_of_detail_points - control.min_point;
              this.quotationMitItems[i].quotation_mit_item_details[index].total_amount = (dif * control.price_point) + control.price_start;
            }
          }else{
            this.quotationMitItems[i].quotation_mit_item_details[index].total_amount = number_of_detail_points * control.price_point;
          }
        }else if(control.detail_type == 'range'){
     
          if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
            this.quotationMitItems[i].quotation_mit_item_details[index].total_amount = control.price_start;
          }else{
            this.quotationMitItems[i].quotation_mit_item_details[index].total_amount =control.min_point * control.price_point;
          }
        }
      })  
    }
    this.calculateTotalAmountItem();
  }

  calculateTotalAmountItem(){
    for (let i = 0; i < this.quotationMitItems.length; i++){
      let countOfItem = 0;
      let priceStart = 0;
      let isMinPoint = false;
      let mostTotalAmount = 0;
      let mostTotalAmountItem = null;

      if(this.quotationMitItems[i].quotation_mit_item_details.length > 0){
        this.quotationMitItems[i].quotation_mit_item_details.map(item=>{
          if(item.total_amount != 0 && priceStart < item.price_start) {
            priceStart = item.price_start;
          }
          if(item.total_amount != 0 && item.min_point != 0) {
            isMinPoint = true;
          } 
          if(item.total_amount != 0 && mostTotalAmount < item.total_amount) {
            mostTotalAmount = item.total_amount;
            mostTotalAmountItem = item;
          } 
          countOfItem = countOfItem + item.total_amount;
        })
      }
      let count = countOfItem;
      if(this.quotationMitItems[i].quotation_mit_item_attributes.length > 0){
        this.quotationMitItems[i].quotation_mit_item_attributes.map(v=>{
          if(v.attribute_value){
            v.attribute_value = this._utils.transformDecimal(v.attribute_value, '1.0-0');
          }else{
            v.attribute_value = null;
          }
        })
      }

      if(isMinPoint && this.quotationMitItems[i].quotation_mit_item_attributes.find(attr=> attr.attribute_value != null)){
        let sumAttr = 0;
        this.quotationMitItems[i].quotation_mit_item_attributes.map(v=>{
          if(v.attribute_value) sumAttr = sumAttr + parseFloat(v.attribute_value);
        })
        sumAttr--;
        if(mostTotalAmountItem.detail_type == 'point'){
          count = count + ((mostTotalAmountItem.quotation_mit_item_detail_points.length * sumAttr) *  mostTotalAmountItem.price_point);
        }else{
          count = count + ((mostTotalAmountItem.min_point * sumAttr) *  mostTotalAmountItem.price_point);
        }
      }else{
        if(this.quotationMitItems[i].quotation_mit_item_attributes.length > 0){
          this.quotationMitItems[i].quotation_mit_item_attributes.map(v=>{
            if(v.attribute_value) count = count * parseFloat(v.attribute_value);
          })
        }
        if(!isMinPoint){
          count = count + priceStart;
        }
      }
  
      this.quotationMitItems[i].original_price = count;
    }
  }

  getUnits(unit_id: string): any{
    let result = [];
    this.units.map(item=>{
      const unit = item.units.filter(unit => unit.unit_id == unit_id);
      if (unit.length > 0){
        result = item.units;
      }
    })
    return result;
  }

  getNumberOfStatus(mitBodyList){
    this.number_of_success = 0;
    this.number_of_fails = 0;
    mitBodyList.map(v=>{
      let isInvalid = v.find(item=> item.isValid == false);
      if(isInvalid) {
        this.number_of_fails = this.number_of_fails + 1;
      }else{
        this.number_of_success = this.number_of_success + 1;
      }
    })
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.clearItem();
      }
    });
  }

  clearItem(){
    this.quotationMitItems = [];
    this.mitHeaderList = [];
    this.mitBodyList = [];
    this.willDownload = false;
    this.status = true;
    this.file = null;
  }

  public textCenter(header: string): boolean{
    if(header.includes('range') ||
      header.includes('unit') ||
      header.includes('due') ||
      header.includes('iso') ||
      header.includes('item') ||
      header.includes('channel')||
      header.includes('sensor')||
      header.includes('display')||
      header.includes('function')||
      header.includes('element')){
        return true;
    }else{
      return false;
    }
  }

  public canEdit(header: string): boolean{
    if(header.includes('range') ||
      header.includes('description') ||
      header.includes('maker') ||
      header.includes('model') ||
      header.includes('serial') ||
      header.includes('tag') ||
      header.includes('mpe') ||
      header.includes('scope') ||
      header.includes('equipment') ||
      header.includes('method') ||
      header.includes('location') ||
      header.includes('note') ||
      header == 'calibration point' ||
      header.includes('unit2') ||
      header.includes('due') ||
      header.includes('channel')||
      header.includes('sensor')||
      header.includes('display')||
      header.includes('function')||
      header.includes('element')){
        return true;
    }else{
      return false;
    }
  }
}