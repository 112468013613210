<!-- Create CP Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="create-cp"
  tabindex="-1"
  role="dialog"
  aria-labelledby="createCPModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="createCPModal">
          เพิ่ม CP
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="form">
          <div class="row justify-content-md-center">
            <div class="col-md-12">
              <div class="form-body">
                <form-group
                  [control]="form.controls.cp_name"
                  [submitted]="submitted"
                >
                  <label for="cp_name">
                    CP name (ภาษาอังกฤษ) <span class="danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="cp_name"
                    class="form-control"
                    placeholder="CP name (ภาษาอังกฤษ)"
                    formControlName="cp_name"
                  />
                </form-group>

                <form-group
                  [control]="form.controls.mit_scope_id"
                  [submitted]="submitted"
                >
                  <label for="mit_scope_id">
                    Scope (ภาษาอังกฤษ)
                  </label>
                  <ng-select2
                    [data]="scopes"
                    class="w-100"
                    id="mit_scope_id"
                    placeholder="Scope (ภาษาอังกฤษ)"
                    formControlName="mit_scope_id"
                  >
                  </ng-select2>
                </form-group>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
