<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="transport-receive-item-create"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreate1Modal">
          เลือกรายการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <h4 class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="description">
                    ใบเสนอราคา <span class="danger">*</span> : 
                  </h4>
                  <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                      <form-group
                      [control]="form.controls.quotation_info_id"
                      [submitted]="submitted">
                      <ng-select2
                          [options]="select2OptionQuotation"
                          class="w-100"
                          placeholder="โปรดเลือกใบเสนอราคา"
                          formControlName="quotation_info_id"
                          (valueChanged)="selectQuotation($event)"
                          [allowClear]="true">
                      </ng-select2>
                      </form-group>
                  </div>

                  <div class="col-12 col-sm-12 col-md-7 col-lg-7 text-right">
                    <h4>{{number_of_select}} รายการ</h4>
                  </div>
                </div>
            </div>

            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                  </div>
                  <div class="col-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="table-responsive" style="max-height: 600px;">
                        <table class="table table-striped table-bordered" formArrayName="transport_receive_item">
                            <thead> 
                                <tr>
                                    <th>
                                      <span>
                                        <input 
                                        *ngIf="showCbxAll"
                                        type="checkbox"
                                        id="select_all"
                                        (change)="onCheckAll($event)"
                                        >
                                      </span>
                                    </th>
                                    <th>ชื่อเครื่องมือ</th>
                                    <th>Method</th>
                                    <th>Service Location</th>
                                    <th>Maker</th>
                                    <th>Model</th>
                                    <th>Serial No.</th>
                                    <th>Tag No.</th>
                                    <th>สถานะ</th>
                                </tr>
                            </thead>
        
                            <tbody>
                                <tr *ngFor="let item of receiveFormGroup.controls; let i = index;" [formGroupName]="i">
                                    <td>
                                        <input 
                                        *ngIf="item.value.quotation_mit_item_info.transport_receive_item_info?.length == 0 &&
                                        checkSelected(item.value?.quotation_mit_item_info) && item.value?.quotation_mit_item_info.service_location.toUpperCase() =='LAB'"
                                        type="checkbox"
                                        [id]="'select_'+i"
                                        formControlName="select"
                                        (change)="onCheckChange($event, i)"
                                        >
                                    </td>
                                    <td>
                                        {{item.value.quotation_mit_item_info.description || item.value.quotation_mit_item_info.equipment_name}}
                                    </td>
                                    <td>
                                      {{getMethodName(item.value?.quotation_mit_item_info?.quotation_mit_item_detail_info)}}
                                    </td>
                                    <td>
                                      {{item.value?.quotation_mit_item_info?.quotation_mit_item_detail_info[0]?.service_location.toUpperCase() || ''}}
                                    </td>
                                    <td>
                                      {{item.value.quotation_mit_item_info.marker || '-'}}
                                    </td>
                                    <td>
                                        {{item.value.quotation_mit_item_info.model || '-'}}
                                    </td>
                                    <td>
                                        {{item.value.quotation_mit_item_info.serial_no || '-'}}
                                    </td>
                                    <td>
                                        {{item.value.quotation_mit_item_info.tag_no || '-'}}
                                    </td>
                                    <td>
                                      <span *ngIf="item.value.quotation_mit_item_info.transport_receive_item_info?.length == 0">
                                        -
                                      </span>
                                      <span *ngIf="item.value.quotation_mit_item_info.transport_receive_item_info?.length > 0">
                                        {{getStatus(item.value.quotation_mit_item_info?.transport_receive_item_info[0]?.item_status_id)}}
                                      </span>
                                    </td>
                                </tr>
                                <tr *ngIf="receiveItems.length === 0">
                                    <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                      
                        </table>
                    </div>
                  </div>
                </div>
            </div>
            
            <div class="form-body">
                <div class="form-group row mb-0">
                  <h4 class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="description">
                    ข้อมูลค่าขนส่ง 
                  </h4>          
                </div>
            </div>

            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                  </div>
                  <div class="col-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="table-responsive" style="max-height: 600px;">
                        <table class="table table-striped table-bordered">
                            <thead> 
                                <tr>
                                    <th>ลำดับ</th>
                                    <th>รายการ</th>
                                    <th class="text-center">จำนวน</th>
                                    <th class="text-right" *ngIf="userInfo.emp_info?.emp_position_id != 'TRAN_DEP-03'">ราคา</th>
                                </tr>
                            </thead>
        
                            <tbody>
                                <tr *ngFor="let item of productList; let i = index;">
                                    <td>
                                        {{i+1}}
                                    </td>
                                    <td>
                                        {{item.product_title || item.product_description}}
                                    </td>
                                    <td class="text-center">
                                        {{item.quantity}}
                                    </td>
                                    <td class="text-right" *ngIf="userInfo.emp_info?.emp_position_id != 'TRAN_DEP-03'">
                                      {{item.price | number : '1.2-2'}}
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="productList.length == 0">
                                <tr>
                                    <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                  </div>
                </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>เพิ่มรายการ
        </button>
      </div>
    </div>
  </div>
</div>
