<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">ดูใบสั่งงาน</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/work-order']">ใบสั่งงาน </a>
                </li>
                <li class="breadcrumb-item active">ดูใบสั่งงาน</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button
            (click)="back()"
            type="button"
            class="btn btn-light btn-min-width ml-1"
            >
            <i class="fa fa-arrow-left"></i>  กลับ
            </button>
            <button 
              *ngIf="(isSalesManager || isSalesCoordinator || isSales) && (jobOrderInfo?.doc_status_id === 'DRAFT' || jobOrderInfo?.doc_status_id === 'REJECTED') && !isAccDep"
              (click)="cancel()"
              type="button"
              class="btn btn-danger btn-min-width ml-1"
              [disabled]="disableSaveBtn"
              >
              <i class="fa fa-times icon-left"></i>  ยกเลิก
            </button>
            <button 
              *ngIf="((isSalesManager || isSalesCoordinator || isSales || isCalibration) && (jobOrderInfo?.doc_status_id === 'DRAFT' || jobOrderInfo?.doc_status_id === 'REJECTED' || jobOrderInfo?.doc_status_id === 'CONFIRMED') || isTranDep) && !isAccDep"
              (click)="edit(jobOrderInfo)"
              type="button"
              class="btn btn-warning btn-min-width ml-1"
              [disabled]="disableSaveBtn"
              >
              <i class="fa fa-pencil"></i>  แก้ไข
            </button>
            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'WAITING_FOR_CONFIRM' && ((isCalibration && ['ICL','ICS','TRN'].includes(jobOrderInfo?.job_order_type)) || (isPuchase && ['PRD','ECL','ECS'].includes(jobOrderInfo?.job_order_type))) && !isAccDep"
              type="button" class="btn btn-info btn-min-width ml-1" 
              (click)="confirmJoborder(jobOrderInfo, 'confirm')"
              [disabled]="disableSaveBtn">
              <i class="fa fa-pencil"></i> ตรวจสอบรายการ
            </button>

            <button 
              *ngIf="isCalibration && (jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM')"
              type="button"
              (click)="cancel()"
              class="btn btn-danger btn-min-width ml-1"
              [disabled]="disableSaveBtn"
              >
              <i class="fa fa-times icon-left"></i>  ยกเลิก
            </button>
            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'WAITING_VERIFY_CRF' && ['ICS','ICL'].includes(jobOrderInfo?.job_order_type) && !isAccDep"
              type="button" class="btn btn-danger btn-min-width ml-1" (click)="saveAsReject()"
              [disabled]="disableSaveBtn">
            <i class="fa fa-times icon-left"></i> ไม่อนุมัติ
            </button>

            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'WAITING_VERIFY_CRF' && isCalibration && ['ICL','ICS'].includes(jobOrderInfo?.job_order_type) && !isAccDep"
              type="button" class="btn btn-info btn-min-width ml-1"
              data-target="#cert-verify"
              data-toggle="modal"
              [disabled]="disableSaveBtn">
            <i class="fa fa-file-o icon-left"></i> ตรวจสอบความถูกต้อง
            </button>

            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'CONFIRMED' && isPuchase && ['ECL','ECS'].includes(jobOrderInfo?.job_order_type) && !isAccDep"
              type="button" class="btn btn-info btn-min-width ml-1" (click)="editCert(jobOrderInfo, 'cert')"
              [disabled]="disableSaveBtn">
            <i class="fa fa-file-o icon-left"></i> บันทึกการดำเนินการ
            </button>

            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'PREPARE_FOR_SHIP' && ['ICS','ECS'].includes(jobOrderInfo?.job_order_type) && isTranDep"
              (click)="openModalReturn(jobOrderInfo)"
              type="button" class="btn btn-info btn-min-width ml-1"
              [disabled]="disableSaveBtn">
            <i class="fa fa-file-o icon-left"></i> บันทึกส่งงาน
            </button>

            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'WORK_DONE' && ['ICS','ICL'].includes(jobOrderInfo?.job_order_type) && (userInfo$ | async)?.admin_cert"
              type="button" class="btn btn-danger btn-min-width ml-1" 
              [disabled]="disableSaveBtn"
              (click)="saveNotDone()">
            <i class="fa fa-times icon-left"></i> ตรวจสอบไม่ผ่าน
            </button>

            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'WORK_DONE' && ['ICL','ICS'].includes(jobOrderInfo?.job_order_type) && (userInfo$ | async)?.admin_cert"
              type="button" class="btn btn-success btn-min-width ml-1"
              [disabled]="disableSaveBtn"
              (click)="saveDone()">
            <i class="fa fa-check icon-left"></i> ตรวจสอบผ่าน
            </button>

            <button 
            *ngIf="jobOrderInfo?.doc_status_id == 'CRF_COMPLETED' && ['ICS','ICL'].includes(jobOrderInfo?.job_order_type) && isTranDep"
            (click)="saveAsShip()"
            type="button" class="btn btn-success btn-min-width ml-1"
            [disabled]="disableSaveBtn">
            <i class="fa fa-check icon-left"></i> ดำเนินการส่งได้
          </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="pageLoad"></loading>

        <div class="card" [hidden]="pageLoad">
          <div class="card-header">
              <h4 class="card-title d-inline mr-50">ดูใบสั่งงาน ({{template}})</h4>
              &nbsp;
              <span [ngClass]="{'badge-info': jobOrderInfo?.doc_status_id === 'DRAFT',
              'badge-warning':jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM',
              'badge-danger':jobOrderInfo?.doc_status_id === 'REJECTED',
              'badge-blue': ['CONFIRMED','WAITING_VERIFY_CRF'].includes(jobOrderInfo?.doc_status_id),
              'badge-success': ['COMPLETED'].includes(jobOrderInfo?.doc_status_id)
              }"
              class="badge">{{
                jobOrderInfo?.doc_status_info?.document_status_name_th
              }}</span>
              <div class="heading-elements">
              </div>
              <div class="float-md-right" *ngIf="!isAccDep">
                <button 
                  *ngIf="jobOrderInfo?.job_order_type != 'TRN'"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1 "
                  (click)="openPdf(jobOrderInfo)"
                  >
                      <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                </button>

                <button 
                  type="button"
                  (click)="openAddressCert(jobOrderInfo)"
                  class="btn btn-blue btn-min-width ml-1 mb-1"
                  >
                      <i class="fa fa-print icon-left"></i> พิมพ์ที่อยู่ส่ง CERT
                </button>

                <button 
                  *ngIf="['ICS','ECS'].includes(jobOrderInfo?.job_order_type) && (jobOrderInfo?.doc_status_id === 'WAITING_VERIFY_CRF' || jobOrderInfo?.doc_status_id === 'PREPARE_FOR_SHIP' || jobOrderInfo?.doc_status_id === 'COMPLETED' || jobOrderInfo?.doc_status_id === 'RETURNED_SUCCESS')"
                  type="button"
                  (click)="openOnsitePdf(jobOrderInfo)"
                  class="btn btn-blue btn-min-width ml-1 mb-1"
                  >
                      <i class="fa fa-print icon-left"></i> พิมพ์เอกสารรายงาน
                </button>

                <button 
                  *ngIf="['ECL','ECS'].includes(jobOrderInfo?.job_order_type)"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1 "
                  (click)="openPdfPurchase(jobOrderInfo)"
                  >
                      <i class="fa fa-print icon-left"></i> พิมพ์ใบขอซื้อ
                </button>

                <button 
                *ngIf="jobOrderInfo?.job_order_type == 'ICL' || jobOrderInfo?.job_order_type == 'ICS'"
                type="button"
                class="btn btn-blue btn-min-width ml-1 mb-1"
                (click)="exportMasterReport(jobOrderInfo)"
                >
                <i class="fa fa-download icon-left"></i> Download ข้อมูลสอบเทียบ
              </button>
              </div>
          </div>

          <div class="card-content collapse show">
              <form autocomplete="off" class="form" [formGroup]="form">
                  <div class="card-body">
                    <div class="form-body">
                      <h4 class="badge btn-blue badge-customer-lvl" *ngIf="customer_level_id">
                        {{ customer_level_id | dash }}
                      </h4>
                        <div class="row">
                          <div class="col-xl-3 col-lg-6 col-md-12">
                            <form-group
                              [control]="form.controls.customer_id"
                              [submitted]="submitted"
                              >
                                <label for="customer_id">
                                  ชื่อลูกค้า
                                </label>
                                <input
                                  type="text"
                                  id="customer_name"
                                  class="form-control"
                                  placeholder="ชื่อลูกค้า"
                                  formControlName="customer_name"
                                  readonly
                                />
                            </form-group>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.customer_address_id"
                            [submitted]="submitted"
                            >
                              <label for="customer_address_id">
                                ออกเอกสารในนาม
                              </label>
                              <ng-select2
                                [data]="customerAddressList"
                                class="w-100"
                                id="customer_address_id"
                                placeholder="ออกเอกสารในนาม"
                                formControlName="customer_address_id"
                              >
                              </ng-select2>
                              <small>mit_iplus_code: {{form.value.mit_iplus_code | dash}}</small>
                          </form-group>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_date"
                          [submitted]="submitted">
                          <label for="doc_date">
                              วันที่ออก 
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              *ngIf="form.value.doc_date"
                              id="doc_date"
                              placeholder="วันที่ออก"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.doc_date"
                              formControlName="doc_date"
                              />
                              <div
                              class="input-group-append"
                              for="doc_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                      </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_no"
                          [submitted]="submitted">
                          <label for="doc_no">
                              เลขที่เอกสาร
                          </label>
                          <input
                              type="text"
                              id="doc_no"
                              class="form-control"
                              placeholder="เลขที่เอกสาร"
                              formControlName="doc_no"
                              readonly
                          />
                          </form-group>
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <p class="font-weight-bold"> ที่อยู่ในการติดต่อลูกค้า</p>
                          <form-group
                              [control]="form.controls.customer_address"
                              [submitted]="submitted"
                              >
                              <label for="customer_address">
                                  ที่อยู่
                              </label>
                              <input
                                  type="text"
                                  id="customer_address"
                                  class="form-control"
                                  placeholder="ที่อยู่"
                                  formControlName="customer_address"
                                  readonly
                              />
                            </form-group>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <p>
                            <br>
                          </p>
                          <form-group
                            [control]="form.controls.customer_contact_ids"
                            [submitted]="submitted">
                              <label for="customer_contact_ids">
                                ผู้ติดต่อ 
                              </label>
                              <ng-select2
                                  [data]="customerContactList"
                                  [options]="{
                                    templateSelection: _ngSelect2Customer?.templateSelection,
                                    multiple: true,
                                    maximumSelectionLength: 1
                                  }"
                                  class="w-100"
                                  id="customer_contact_ids"
                                  placeholder="ผู้ติดต่อ"
                                  formControlName="customer_contact_ids"
                                  >
                              </ng-select2>
                          </form-group>

                        </div>

                      </div>

                      <div class="row" *ngIf="template=='ICS' || template=='ECS'">
                        <div class="col-lg-6 col-md-12">
                          <p class="font-weight-bold"> สถานที่ในการสอบเทียบ</p>
                          <form-group
                          [control]="form.controls.calibration_address_id"
                          [submitted]="submitted">
                          <label for="calibration_address_id">
                              ที่อยู่ 
                          </label>
                            <ng-select2
                              [data]="calibrationAddressList"
                              class="w-100"
                              id="calibration_address_id"
                              placeholder="ที่อยู่"
                              formControlName="calibration_address_id"
                            >
                            </ng-select2>
                          </form-group>
                    
                        </div>

                        <div class="col-lg-6 col-md-12" *ngIf="template=='ECS'">
                          <p class="font-weight-bold"> ที่อยู่ในการ ออกใบรับรอง </p>
                          <form-group
                              [control]="form.controls.report_address_id"
                              [submitted]="submitted"
                              >
                                <label for="report_address_id">
                                  ที่อยู่
                                </label>
                                <ng-select2
                                  [data]="reportAddressList"
                                  class="w-100"
                                  id="report_address_id"
                                  placeholder="ที่อยู่"
                                  formControlName="report_address_id"
                                >
                                </ng-select2>
                            </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12" *ngIf="template=='ICS'">
                          <p>
                            <br>
                          </p>
                          <form-group
                          [control]="form.controls.no_of_people"
                          [submitted]="submitted">
                          <label for="no_of_people">
                              จำนวนคน 
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="จำนวนคน"
                            formControlName="no_of_people"
                            id="no_of_people"
                            />
                          </form-group>

                        </div>

                        <div class="col-lg-3 col-md-12" *ngIf="template=='ICS'">
                          <p>
                            <br>
                          </p>
                          <form-group
                          [control]="form.controls.no_of_day"
                          [submitted]="submitted">
                          <label for="no_of_day">
                              จำนวนวัน 
                          </label>
                          <input
                          type="text"
                          class="form-control"
                          placeholder="จำนวนคน"
                          formControlName="no_of_day"
                          id="no_of_day"
                            />
                          </form-group>

                        </div>

                      </div>

                      <ng-container [ngSwitch]="template">
                        <ng-container *ngSwitchCase="'ECS'">
                          <div class="row">
  
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ส่งใบรายงานผล</p>
                              <form-group
                              [control]="form.controls.send_report_address_id"
                              [submitted]="submitted"
                                >
                                  <label for="send_report_address_id">
                                    ที่อยู่
                                  </label> 
                                  <ng-select2
                                    [data]="sendReportAddressList"
                                    class="w-100"
                                    id="send_report_address_id"
                                    placeholder="ที่อยู่"
                                    formControlName="send_report_address_id"
                                  >
                                </ng-select2>
                              </form-group>
    
                            </div>
    
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ในการ เก็บค่าบริการ</p>
                              <form-group
                                [control]="form.controls.charge_address_id"
                                [submitted]="submitted">
                                    <label for="charge_address_id">
                                      ที่อยู่
                                    </label>
                                    <ng-select2
                                      [data]="chargeReportAddressList"
                                      class="w-100"
                                      id="charge_address_id"
                                      placeholder="ที่อยู่"
                                      formControlName="charge_address_id"
                                    >
                                    </ng-select2>
                                </form-group>
                            </div>
                          </div>
    
                          <div class="row">           
                            <div class="col-lg-3 col-md-12">
                              <form-group
                              [control]="form.controls.credit_term_day"
                              [submitted]="submitted"
                                >
                                  <label for="credit_term_day">
                                    เครดิต (วัน)
                                  </label> 
                                  <input type="text"    
                                  id="credit_term_day"
                                  class="form-control"
                                  placeholder="เครดิต (วัน)" 
                                  formControlName="credit_term_day"
                                  readonly>
                              </form-group>
    
                            </div>
    
                          </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ในการ ออกใบรับรอง </p>
                              <form-group
                                  [control]="form.controls.report_address_id"
                                  [submitted]="submitted"
                                  >
                                    <label for="report_address_id">
                                      ที่อยู่ 
                                    </label>
                                    <ng-select2
                                      [data]="reportAddressList"
                                      class="w-100"
                                      id="report_address_id"
                                      placeholder="ที่อยู่"
                                      formControlName="report_address_id"
                                    >
                                    </ng-select2>
                                </form-group>
                            </div>
    
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ส่งใบรายงานผล</p>
                              <form-group
                              [control]="form.controls.send_report_address_id"
                              [submitted]="submitted"
                                >
                                  <label for="send_report_address_id">
                                    ที่อยู่ 
                                  </label> 
                                  <ng-select2
                                    [data]="sendReportAddressList"
                                    class="w-100"
                                    id="send_report_address_id"
                                    placeholder="ที่อยู่"
                                    formControlName="send_report_address_id"
                                  >
                                </ng-select2>
                              </form-group>
    
                            </div>
    
                          </div>
    
                          <div class="row">
                            <div class="col-lg-6 col-md-12">
                              <p class="font-weight-bold"> ที่อยู่ในการ เก็บค่าบริการ</p>
                              <form-group
                                [control]="form.controls.charge_address_id"
                                [submitted]="submitted">
                                    <label for="charge_address_id">
                                      ที่อยู่ 
                                    </label>
                                    <ng-select2
                                      [data]="chargeReportAddressList"
                                      class="w-100"
                                      id="charge_address_id"
                                      placeholder="ที่อยู่"
                                      formControlName="charge_address_id"
                                    >
                                    </ng-select2>
                                </form-group>
                            </div>
    
                            <div class="col-lg-3 col-md-12">
                              <p class="font-weight-bold">
                                <br>  
                              </p>
                              <form-group
                              [control]="form.controls.credit_term_day"
                              [submitted]="submitted"
                                >
                                  <label for="credit_term_day">
                                    เครดิต (วัน)
                                  </label> 
                                  <input type="text"    
                                  id="credit_term_day"
                                  class="form-control"
                                  placeholder="เครดิต (วัน)" 
                                  formControlName="credit_term_day"
                                  readonly>
                              </form-group>
    
                            </div>
    
                          </div>
                        </ng-container>
                      </ng-container>
                      
                      <div  class="dropdown-divider mb-2">
                      </div>
                      
                      <div class="row">
                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"> ข้อมูลการสอบเทียบ</p>
                          <form-group
                            [control]="form.controls.pickup_date"
                            [submitted]="submitted">
                            <label for="pickup_date">
                              <ng-container [ngSwitch]="template">
                                <ng-container *ngSwitchCase="'ICL'">วันที่รับเครื่องมือ</ng-container>
                                <ng-container *ngSwitchCase="'ECL'">วันที่รับเครื่องมือ</ng-container>
                                <ng-container *ngSwitchDefault>วันที่เข้าบริการ</ng-container>
                              </ng-container>
                            </label>
                            <div class="input-group mt-0">
                                <input
                                date-picker
                                type="text"
                                id="pickup_date"
                                placeholder="วันที่รับเครื่องมือ"
                                [max-date]="undefined"
                                class="form-control"
                                *ngIf="form.value.pickup_date"
                                [date]="form.value.pickup_date"
                                formControlName="pickup_date"
                                />
                                <div
                                class="input-group-append"
                                for="pickup_date"
                                >
                                <span class="input-group-text"
                                    ><i class="feather icon-calendar"></i
                                ></span>
                                </div>
                            </div>
                          </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12"  [hidden]="template!='ICS' && template!='ECS'">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                            [control]="form.controls.service_time"
                            [submitted]="submitted">
                            <label for="service_time">
                              เวลาเข้าบริการ
      
                            </label>
                            <div class="input-group mt-0">
                              <input
                                  time-picker
                                  type="text"
                                  placeholder="เวลาเข้าบริการ"
                                  id="service_time"
                                  #service_time
                                  class="form-control"
                                  formControlName="service_time"
                              />
                              <div class="input-group-append border-left-0 pointer" (click)="service_time.click()">
                                  <span class="input-group-text icon-input input-2-box-left"><i class="fa fa-clock-o" aria-hidden="true"></i></span>
                              </div>
                            </div>
                          </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                          [control]="form.controls.return_date"
                          [submitted]="submitted">
                          <label for="return_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'ICL'">วันที่ส่งคืนเครื่องมือ</ng-container>
                              <ng-container *ngSwitchCase="'ECL'">วันที่ส่งคืนเครื่องมือ</ng-container>
                              <ng-container *ngSwitchDefault>วันที่เสร็จสิ้น</ng-container>
                            </ng-container>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              id="return_date"
                              *ngIf="form.value.return_date"
                              placeholder="วันที่ส่งคืนเครื่องมือ"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.return_date"
                              formControlName="return_date"
                              />
                              <div
                              class="input-group-append"
                              for="pickup_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>

                        </div>

                        <!-- <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                          [control]="form.controls.real_return_date"
                          [submitted]="submitted">
                          <label for="real_return_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'ICL'">วันส่งคืนเครื่องมือจริง</ng-container>
                              <ng-container *ngSwitchCase="'ECL'">วันส่งคืนเครื่องมือจริง</ng-container>
                              <ng-container *ngSwitchDefault>วันที่เสร็จสิ้นจริง</ng-container>
                            </ng-container>
  
                          </label>
                          <div class="input-group mt-0">
                              <input
                              *ngIf="resetdate"
                              date-picker
                              type="text"
                              id="real_return_date"
                              [placeholder]="(template =='ICL' || template == 'ECL')? 'วันส่งคืนเครื่องมือจริง': 'วันที่เสร็จสิ้นจริง'"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.real_return_date"
                              formControlName="real_return_date"
                              />
                              <div
                              class="input-group-append"
                              for="real_return_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>

                        </div> -->
                      </div>

                      <div  class="dropdown-divider mb-2" *ngIf="(jobOrderInfo?.doc_status_id === 'COMPLETED' || (jobOrderInfo?.doc_status_id === 'CONFIRMED' &&  ['ECL','ECS'].includes(template)))">
                      </div>
  
                      <div class="row" *ngIf="(jobOrderInfo?.doc_status_id === 'COMPLETED' || (jobOrderInfo?.doc_status_id === 'CONFIRMED' &&  ['ECL','ECS'].includes(template)))">
                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"> ข้อมูลการจัดส่ง</p>
                          <form-group
                          [control]="form.controls.no_of_envelope"
                          [submitted]="submitted"
                            >
                              <label for="no_of_envelope">
                                จำนวนซอง
                              </label> 
                              <input type="text"    
                              id="no_of_envelope"
                              class="form-control"
                              placeholder="จำนวนซอง" 
                              formControlName="no_of_envelope">
                          </form-group>
                        </div>
  
                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                          [control]="form.controls.no_of_cert"
                          [submitted]="submitted"
                            >
                              <label for="no_of_cert">
                                จำนวน Cert
                              </label> 
                              <input type="text"    
                              id="no_of_cert"
                              class="form-control"
                              placeholder="จำนวน Cert" 
                              formControlName="no_of_cert">
                          </form-group>
  
                        </div>
                      </div>
                    </div>

                    <div class="my-1">
                      <app-work-order-lab-item 
                      [jobOrderInfo]="jobOrderInfo" 
                      (done)="getJobOrderItems($event)"
                      (disableBtn)="disableBtn($event)"
                      *ngIf="jobOrderInfo">
                      </app-work-order-lab-item>
                    </div>
                    
                    <div class="col-8 p-0 my-1" *ngIf="!isTranDep && ['ICL','ICS','ECS'].includes(template) && (jobOrderInfo?.doc_status_id != 'DRAFT')">
                      <app-worker-engineer 
                      [type]="template=='ECS'?'text':'user_id'">
                      </app-worker-engineer>
                    </div>

                    <div class="col-8 p-0 my-1" *ngIf="!isTranDep && (template=='ICS') && (jobOrderInfo?.doc_status_id != 'DRAFT')">
                      <app-car-info>
                      </app-car-info>
                    </div>

                    <div class="form-body">
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.note"
                          [submitted]="submitted">
                          <label for="doc_date">
                              หมายเหตุ
                          </label>
                          <textarea
                            placeholder="หมายเหตุ"
                            class="form-control"
                            rows="3"
                            id="note"
                            formControlName="note"
                            >
                          </textarea>
                          </form-group>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <label for="file">แนบไฟล์</label>
                          <div class="mt-1 mb-1">
                            <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                                <span style="cursor: pointer;" (click)="downloadFile(file)">
                                    {{file.file_name}}
                                </span>
                            </span> 
                            <span *ngIf="files.length == 0" class="text-dark">
                                ไม่มีไฟล์
                            </span> 
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.special_condition"
                          [submitted]="submitted">
                          <label for="special_condition">
                              Special Condition
                          </label>
                          <textarea
                            placeholder="Special Condition"
                            class="form-control"
                            rows="3"
                            id="special_condition"
                            formControlName="special_condition"
                            >
                          </textarea>
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-lg-6 col-md-12">

                          <form-group
                          [control]="form.controls.accessory"
                          [submitted]="submitted">
                          <label for="doc_date">
                            Accessory
                          </label>
                          <textarea
                            placeholder="Accessory"
                            class="form-control"
                            id="accessory"
                            rows="3"
                            formControlName="accessory"
                            >
                          </textarea>
                          </form-group>
                        </div>

                      </div>
                    </div>
                  </div>
              </form>
          </div>
      </div>
      </div>

      <app-work-order-memo *ngIf="!pageLoad">
      </app-work-order-memo>
    </div>
</div>

<app-work-order-lab-verify 
  (done)="saveCert($event)"
  *ngIf="jobOrderInfo?.doc_status_id == 'WAITING_VERIFY_CRF' && isCalibration && ['ICL','ICS'].includes(jobOrderInfo?.job_order_type)">
</app-work-order-lab-verify>

<app-work-order-return (done)="saveAsReturn($event)">
</app-work-order-return>

<div
  class="modal fade text-left"
  id="warningSaveReturn"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">แจ้งทราบ</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body-content">
          <p>ไม่สามารถบันทึกได้ เนื่องจาก มีรายการที่ยังไม่ Mapping ใบเสนอราคา</p>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn grey btn-outline-secondary"
          data-dismiss="modal"
        >
          ปิด
        </button>
      </div>
    </div>
  </div>
</div>
