import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  GETService,
  CustomerInfoService,
  CustomerAddressService,
  UtilsService,
  CustomerActivityService,
} from "src/app/services";
import { EmailValidator, AbsNumberValidator } from "src/app/validators";
import { Subscription, Observable } from "rxjs";
import { Store, Select } from "@ngxs/store";
import { GetCustomer } from "src/app/store/customer/customer.action";
import { UserInfoState } from "src/app/store/user/user.state";
import Swal from 'sweetalert2'

@Component({
  selector: "app-customer-address",
  templateUrl: "./customer-address.component.html",
  styleUrls: ["./customer-address.component.css"],
})
export class CustomerAddressComponent implements OnInit {
  isLoading = true;
  form: FormGroup;
  submitted = false;
  status: any;
  mode = "view";

  industryGroups = [];
  industrys = [];
  sources = [];
  districts = [];
  amphures = [];
  provinces = [];
  province$: Subscription;
  amphure$: Subscription;
  district$: Subscription;
  address = null;

  @Output() done = new EventEmitter();
  @Input("can-edit-customer") canEditCustomer = false;
  @Input("can_see_all") canSeeAll = false;
  @Input() customer;
  initCustomer = null;
  @Select(UserInfoState.getUser) userInfo: Observable<any>;
  constructor(
    private formBuilder: FormBuilder,
    private GETService: GETService,
    private CustomerInfoService: CustomerInfoService,
    private CustomerAddressSerivce: CustomerAddressService,
    private store: Store,
    private UtilsService: UtilsService,
    private CustomerActivityService: CustomerActivityService
  ) {
    this.setForm();
  }
  setForm() {
    this.form = this.formBuilder.group({
      isMaster: ["1"],
      customer_level_id: ["NEW", [Validators.required]],
      customer_state_id: ["LEAD", [Validators.required]],
      tax_id: ["", [Validators.required, AbsNumberValidator(), Validators.minLength(13), Validators.maxLength(13)]],
      company_name: ["", [Validators.required]],
      branch: ["", [Validators.required]],
      // department: ["", [Validators.required]],
      industry_id: [""],
      customer_source: ["", [Validators.required]],
      customer_source_text: [""],
      address: ["", [Validators.required]],
      district_id: [""],
      amphure_id: [""],
      province_id: [""],
      geography_id: [""],

      // firstname: ["", [Validators.required]],
      // lastname: [""],
      // line_id: [""],
      // nickname: [""],
      // email: ["", [Validators.required, Validators.email]],

      tel_1: ["", [Validators.required]],
      tel_2: [""],
      email_1: ["", [EmailValidator()]],
      email_2: ["", [EmailValidator()]],
      fax_1: [""],
      fax_2: [""],

      term_of_service: [""],
      credit_term_day: ["0"],
      discount_percentage_init: ["0"],
      billing_day: [""],
      billing_location: [""],
      check_location: [""],
      selling_type: [""],
    });
  }
  async setData() {
    if (!this.customer || !this.address) return;

    // set province
    let tax_id = this.customer.tax_id;
    if(this.address.province_id){
      var result = await this.GETService.amphures({
        province_id: this.address.province_id,
      });
      this.amphures = result.resultData;
      if(this.address.amphure_id){
        result = await this.GETService.districts({
          amphure_id: this.address.amphure_id,
        });

        this.districts = result.resultData;
        if(this.address.district_id){
          var district = this.districts.filter(
            (v) => Number(v.district_id) === Number(this.address.district_id)
          )[0];
        }
      }
    }

    setTimeout(() => {
      this.form.controls.geography_id.setValue(
        // district.zip_code ? district.zip_code : ""
        this.address.zip_code?this.address.zip_code: district? district.zip_code: ""
      );
      // this.customer.
      let customer = {
        ...this.customer,
        ...this.address,
        tax_id,
        discount_percentage_init: this.customer.discount_percentage_init || "0",
        credit_term_day: this.customer.credit_term_day || "0",
      };

      this.form.patchValue(customer);
      if(this.form.value.province_id){
        this.GETService.amphures({
          province_id: this.form.value.province_id,
        }).then((res) => {
          let result = res.resultData || [];
          result = result.map(item=>{
            return {
              ...item,
              id: item.amphure_id,
              text: item.amphure_name_th,
            }
          })
          this.amphures = result;
        });
      }
      if (this.form.value.amphure_id) {
        this.GETService.districts({ amphure_id: this.form.value.amphure_id }).then(
          (res) => {
            let result = res.resultData || [];
            result = result.map(item=>{
              return {
                ...item,
                id: item.district_id,
                text: item.district_name_th,
              }
            })
            this.districts = result;
          }
        );
      }
      this.isLoading = false;
    });
    this.form.disable();
  }
  async ngOnInit() {
    this.isLoading = true;
    this.submitted = false;
    this.sources = this.getSource();
    let initApis = [];
    initApis.push(
      await this.GETService.industryGroups().then((result) => {
        this.industryGroups = result.resultData;
      })
    );

    initApis.push(
      await this.GETService.industrys().then((res) => {
        let result = res.resultData || [];
        result = result.map(item=>{
          return {
            ...item,
            id: item.industry_id,
            text: item.industry_name,
          }
        })
        this.industrys = this.groupIndustrys(result);
      })
    );

    initApis.push(
      this.GETService.provinces().then((res) => {
        let result = res.resultData || [];
        result = result.map(item=>{
          return {
            ...item,
            id: item.province_id,
            text: item.province_name_th,
          }
        })
        this.provinces = result;
      })
    );
    await Promise.all(initApis);

    this.form.controls.customer_source.valueChanges.subscribe(
      (customer_source) => {
        if(!customer_source.includes('...')){
          this.form.get('customer_source_text').setValue("");
          this.form.get('customer_source_text').setValidators(null);
        }else{
          this.form.get('customer_source_text').setValidators([Validators.required]);
        }
        this.form.get('customer_source_text').updateValueAndValidity();
      }
    );
    
    this.address = this.customer.customer_address_info || {};
    if(this.address.amphure_id == null ) this.address.amphure_id = "";
    if(this.address.district_id == null ) this.address.district_id = "";
    if(this.address.province_id == null ) this.address.province_id = "";
    if(this.address.zip_code == null ) this.address.zip_code = "";
    this.setData();
  }
  async submit() {
    this.status = null;
    this.submitted = true;
    this.form.enable({
      onlySelf: true,
      emitEvent: false,
    });
    if (this.form.invalid) {
      this.disableCreditACCDEP();
      return;
    }

    let result = await this.CustomerInfoService.update({
      ...this.form.value,
      customer_id: this.customer.customer_id,
      credit_term_day: Number(this.form.value.credit_term_day),
      term_of_service: this.form.value.term_of_service || "",
    });

    if (result.success) {
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
      let activity_text = await this.UtilsService.customerActivityTitle(
        "UPDATED_CUSTOMER_INFO",
        {
          $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
        }
      );
      await this.CustomerActivityService.create({
        customer_id: this.customer.customer_id,
        customer_activity_type_id: "FORCE_SALES_ASSIGNMENT",
        title: activity_text,
        activity_datetime: new Date(),
      });

      this.customer = result.resultData;
      let address_name = {
        district_name: "",
        amphure_name: "",
        province_name: "",
      }
      if (this.form.value.amphure_id == ""){
        this.form.value.amphure_id = null;
      }else{
        address_name.amphure_name = this.amphures.find(am=> am.id == this.form.value.amphure_id).text;
      }
      if (this.form.value.district_id == ""){
        this.form.value.district_id = null;
      }else{
        address_name.district_name = this.districts.find(ds=> ds.id == this.form.value.district_id).text;
      } 
      if (this.form.value.province_id == ""){
        this.form.value.province_id = null;
      }else{
        address_name.province_name = this.provinces.find(pr=> pr.id == this.form.value.province_id).text;
      }
      if(this.form.value.zip_code == "" ) this.form.value.zip_code = null;
      await this.CustomerAddressSerivce.update({
        is_registed_address: "true",
        address_name: this.form.value.company_name,
        customer_id: this.customer.customer_id,
        customer_address_id: this.address.customer_address_id,
        ...this.form.value,
        ...address_name
      });

      const customer$ = this.store
        .dispatch(new GetCustomer({ customer_id: this.customer.customer_id }))
        .subscribe(async () => {
          await this.cancel();
          customer$.unsubscribe();
        });
      this.status = result.success;
    }else{
      if(result.resultCode == '40301'){
        Swal.fire({
          icon: 'warning',
          title: 'ข้อผิดพลาด !',
          text: 'สาขานี้มีอยู่ในระบบ โปรดตรวจสอบสาขาอีกครั้ง'
        })
      }else{
        this.status = result.error;
      }
    }
    // this.done.emit(this.status);
    this.submitted = false;
  }
  async cancel() {
    this.province$?.unsubscribe();
    this.amphure$?.unsubscribe();
    this.district$?.unsubscribe();

    // this.setForm();

    await this.ngOnInit();
  }

  disableCreditACCDEP() {
    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
    if (userInfo.emp_info.emp_department_id === "ACC_DEP") {
      Object.keys(this.form.value).map(item=>{
        if(item == 'credit_term_day' || item == 'tax_id' || item == 'billing_day' || item == 'discount_percentage_init' || item == 'billing_location' || item == 'check_location'){
          this.form.controls[item].enable();
        }else{
          this.form.controls[item].disable();
        }
      })
    } else {
      this.form.controls["billing_day"].disable();
      this.form.controls["credit_term_day"].disable();
    }
  }
  enableForm() {
    this.form.enable({
      onlySelf: true,
      emitEvent: false,
    });

    this.disableCreditACCDEP();

    this.province$ = this.form.controls.province_id.valueChanges.subscribe(
      (province_id) => {
        this.districts = [];
        this.amphures = [];
        this.form.patchValue(
          {
            amphure_id: "",
            district_id: "",
            geography_id: "",
          },
          {
            onlySelf: true,
            emitEvent: false,
          }
        );
        if(province_id){
          this.GETService.amphures({
            province_id: province_id,
          }).then((res) => {
            let result = res.resultData || [];
            result = result.map(item=>{
              return {
                ...item,
                id: item.amphure_id,
                text: item.amphure_name_th,
              }
            })
            this.amphures = result;
          });
        }
      }
    );

    this.amphure$ = this.form.controls.amphure_id.valueChanges.subscribe(
      (amphure_id) => {
        this.districts = [];
        this.form.patchValue(
          {
            district_id: "",
            geography_id: "",
          },
          {
            onlySelf: true,
            emitEvent: false,
          }
        );
        if (amphure_id) {
          this.GETService.districts({ amphure_id: amphure_id }).then(
            (res) => {
              let result = res.resultData || [];
              result = result.map(item=>{
                return {
                  ...item,
                  id: item.district_id,
                  text: item.district_name_th,
                }
              })
              this.districts = result;
            }
          );
        }
      }
    );

    this.district$ = this.form.controls.district_id.valueChanges.subscribe(
      (district_id) => {
        let district = this.districts.filter(
          (v) => v.district_id == district_id
        )[0];
        if (district) {
          this.form.controls.geography_id.setValue(district.zip_code);
        } else {
          this.form.controls.geography_id.setValue("");
        }
      }
    );
  }

  groupIndustrys(industrys) {
    let groups = {};
    for (let industry of industrys) {
      if (!groups[industry.industry_group_id]) {
        groups[industry.industry_group_id] = industry;
      }
    }
    let groupIndustrys = {};

    for (let industry of industrys) {
      if (!groupIndustrys[industry.industry_group_id]) {
        groupIndustrys[industry.industry_group_id] = [];
      }
      groupIndustrys[industry.industry_group_id].push(industry);
    }
    let groupByIndustrys = [];
    for (let group in groups) {
      let industry_group = this.industryGroups.find((v: any)=>
        groups[group].industry_group_id == v.industry_group_id
      )
      groupByIndustrys.push({
        id: groups[group].industry_group_id,
        text: industry_group.industry_group_name,
        children: [...groupIndustrys[group]],
      });
    }
    return groupByIndustrys;
  }

  getSource(){
    return [
      {id:"Google", text: "Google"},
      {id:"Line", text: "Line"},
      {id:"Facebook", text: "Facebook"},
      {id:"CS", text: "CS"},
      {id:"การแนะนำบอกต่อ", text: "การแนะนำบอกต่อ"},
      {id:"เคยใช้บริการมาก่อน", text: "เคยใช้บริการมาก่อน"},
      {id:"#MIT SAVE U", text: "#MIT SAVE U"},
      {id:"ออกบูธที่.", text: "ออกบูธที่."},
      {id:"อบรมที่.", text: "อบรมที่."},
      {id:"SALE", text: "SALE"},      
      {id:"อื่น ๆ คือ.", text: "อื่น ๆ คือ."},
    ]
  }
}
