import { Injectable } from "@angular/core";
import { 
  QuotationUtilsService
} from './quotation-utils.service';

@Injectable({
   providedIn: "root"
})
export class CalculateFunctionService {
  constructor(private utils : QuotationUtilsService) {
  }

  public sumProduct(data: any): number{
    const price = this.utils.convertStingToNumber(data.price);
    const quantity = this.utils.convertStingToNumber(data.quantity);
    const discount = this.utils.convertStingToNumber(data.discount || 0);
    const count = price * quantity;
    const dis_count = (count * parseFloat(discount)) / 100;
    return (count - dis_count);
  }

  public sumProductWithTax(count: number, data: any): number{
    const discount = this.utils.convertStingToNumber(data.discount || 0);
    const tax = this.utils.convertStingToNumber(data.tax);
    return ((count - discount) * tax) / 100;
  }
  
  public sumProductTotal(count: number, data: any): number{
    const discount = this.utils.convertStingToNumber(data.discount || 0);
    const tax = this.utils.convertStingToNumber(data.tax);
    return (count- discount + (((count - discount) * tax) / 100 ));
  }

  public sumMit(data: any): number{
    const price = this.utils.convertStingToNumber(data.price);
    const discount = this.utils.convertStingToNumber(data.discount || 0);
    const count = price;
    const dis_count = (count * parseFloat(discount)) / 100;
    return (count - dis_count);
  }

  public sumMitCredit(data: any): number{
    const price = this.utils.convertStingToNumber(data.price);
    const quantity = this.utils.convertStingToNumber(data.quantity);
    const discount = this.utils.convertStingToNumber(data.discount || 0);
    const count = price * quantity;
    const dis_count = (count * parseFloat(discount)) / 100;
    return (count - dis_count);
  }

  public sumProductPrevious(data: any): number{
    const price = this.utils.convertStingToNumber(data.previous_price);
    const quantity = this.utils.convertStingToNumber(data.previous_quantity);
    const discount = this.utils.convertStingToNumber(data.discount || 0);
    const count = price * quantity;
    const dis_count = (count * parseFloat(discount)) / 100;
    return (count - dis_count);
  }

  public sumMitPrevious(data: any): number{
    const price = this.utils.convertStingToNumber(data.previous_price);
    const discount = this.utils.convertStingToNumber(data.discount || 0);
    const count = price;
    const dis_count = (count * parseFloat(discount)) / 100;
    return (count - dis_count);
  }
}
