import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserInfoState } from './store/user/user.state';


@Injectable({ providedIn: 'root' })
export class PermissionGuard implements CanActivate {
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(
    private router: Router,
    private store: Store,
  ) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return new Observable<boolean>(obs => {
      var pathName = state.url;

      this.userInfo$.subscribe(
        data => {
            if (data) {

              if(pathName.includes('/quotation')){
                if(data.emp_info.emp_department_id == 'CALIB_DEP' || data.emp_info.emp_department_id == 'TRAN_DEP' || data.emp_info.emp_department_id == 'PUCHASE_DEP' || data.emp_info.emp_department_id == 'ACC_DEP'){
                  this.router.navigate(['/pages/404']);
                }else{
                  obs.next(true);
                }
              }else if(pathName.includes('/waiting-for-charge') || pathName.includes('/invoice') || pathName.includes('/tax-invoice') || pathName.includes('/credit-note') || pathName.includes('/debit-note') || pathName.includes('/billing-note') || pathName.includes('/received-voucher') || pathName.includes('/payment') || pathName.includes('/cheque')){
                if(data.emp_info.emp_department_id == 'TRAN_DEP' || data.emp_info.emp_department_id == 'CALIB_DEP' || data.emp_info.emp_department_id == 'PUCHASE_DEP'){
                  this.router.navigate(['/pages/404']);
                }else{
                  obs.next(true);
                }
              }else if(pathName.includes('/transport-receive') || pathName.includes('/waiting-for-delivery')){
                if(data.emp_info.emp_department_id == 'CALIB_DEP' || data.emp_info.emp_department_id == 'PUCHASE_DEP'){
                  this.router.navigate(['/pages/404']);
                }else{
                  obs.next(true);
                }
              }else if(pathName.includes('/bill-cheque')){
                if(data.emp_info.emp_department_id == 'CALIB_DEP' || data.emp_info.emp_department_id == 'PUCHASE_DEP'){
                  this.router.navigate(['/pages/404']);
                }else{
                  obs.next(true);
                }
              }
              obs.next(true);
            } 
        }
    );
    });
  }
}

