import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { 
  CustomerInfoService, 
  CustomerAddressService,
  ProductService, CustomerContactService, UtilsService
} from 'src/app/services';
import moment from 'moment';
import { Subject, Observable, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { 
  QuotationService,
  DownloadFileService,
  QuotationFileService,
  QuotationUtilsService, 
  QuotationProductItemService,
  QuotationContactService, 
  CalculateFunctionService, 
  NgSelect2Customer
} from '../../../../services/quotations';
import { docValiddateAfterOrEqualValidator } from 'src/app/validators'
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import { CoreService } from '../../../../services/quotations/core.service';

declare var $: any;

@Component({
  selector: 'app-quotation-edit',
  templateUrl: './quotation-edit.component.html',
  styleUrls: ['./quotation-edit.component.css'],
})
export class QuotationEditComponent implements OnInit {
  form: FormGroup;
  formNoteIn: FormGroup;
  formNoteOut: FormGroup;

  isCopy:boolean = false;
  isEdit:boolean = false;

  pageLoad:boolean = false;
  itemLoad:boolean = false;
  submitted:boolean = false;

  disableSaveBtn:boolean = false;

  tab: string = "product-tab";
  quotation_info_id: string;
  customer_level_id: string;

  subject: Subject<string> = new Subject();

  count:number = 0;

  customerList: any = [];
  customerAddressList: any = [];
  customerContactList: any = [];
  subHeaderList: any = [];
  tmpCustomerAddressList: any = [];
  tmpCustomerContactList: any = [];
  quotationCode: any = [];
  documentStatus: any = [];
  tmpProductItemList: any = [];
  productItemList: any = [];
  deleteProductItemList: any = [];
  files: any = [];
  productList: any = [];
  contacts: any = [];
  deleteNoteList: any = [];
  noteMasterList = [];
  noteNormalList = [];
  notes = [];
  //any
  status: any;
  address = null;
  select2Options = null;
  quotationInfo: any;
  productItem = null;
  fileItem = null;
  userSub: Subscription;
  mitItems = null;
  
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private route: ActivatedRoute,
    private CoreService: CoreService,
    private ProductService: ProductService,
    private activatedRoute: ActivatedRoute,
    private QuotationService: QuotationService,
    private CustomerInfoService: CustomerInfoService,
    private DownloadFileService: DownloadFileService,
    private QuotationFileService: QuotationFileService,
    private CustomerContactService: CustomerContactService,
    private CustomerAddressService: CustomerAddressService,
    private QuotationContactService: QuotationContactService,
    private QuotationProductItemService: QuotationProductItemService,

    public UtilsService: UtilsService,
    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
    public NgSelect2Customer: NgSelect2Customer
  ) { 
    this.activatedRoute.queryParams.subscribe(params => {
      this.isCopy = params['action'] == 'copy'? true: false;
      this.isEdit = params['type'] == 'edit'? true: false;
    });
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }
  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.pageLoad = false;
    this.createForm();
    this.quotation_info_id = 
    this.route.snapshot.paramMap.get("quotation_info_id");
    
    this.userSub = this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      this.userInfo = userInfo;
 
      if(userInfo?.is_sales_supervisor || userInfo?.is_co_sales_supervisor || userInfo?.is_co_sales || userInfo?.is_sales_manager){
        this.select2Options = this.NgSelect2Customer.select2Options("?limit=10&&exclude_customer_state_id='Free'&&is_exclude_address=true");
      }else{
        this.select2Options = this.NgSelect2Customer.select2Options("?limit=10&&exclude_customer_state_id='Free'&&is_exclude_address=true&&sales_assignment_info.user_id=" + userInfo.user_id);
      }

      await this.QuotationContactService.load(null, { quotation_info_id: this.quotation_info_id }).then((res) => {
        this.contacts = res.resultData || [];
        const ids = this.contacts.map(v=>{
          return v.customer_contact_info.customer_contact_id;
        })
        this.form.controls['customer_contact_ids'].setValue(ids);
      });
      await this.QuotationService.getSubHeader(null).then((res) => {
        this.subHeaderList = res.resultData || [];
        this.subHeaderList = this.subHeaderList.map(function(elem) {
          return {
            id: elem.quotation_sub_header_id,
            text: `${elem.sub_header}`,
          }
        });
      });
      await this.QuotationService.getById({ quotation_info_id: this.quotation_info_id }).then(async (res) => {
        if (res.success) {
          var resultData = res.resultData || {};
          this.quotationInfo = res.resultData || {};

          let quotation = {
            ...resultData,
            tax: resultData.tax.toString(),
            discount: this.utils.transformDecimal(resultData.discount)
          }
          this.form.patchValue(quotation);
          this.form.controls['doc_date'].setValue(moment(resultData.doc_date).format('YYYY-MM-DD'));
          this.form.controls['doc_validdate'].setValue(moment(resultData.doc_validdate).format('YYYY-MM-DD'));

          if(resultData.quotation_sub_header_id){
            this.form.controls['quotation_sub_header_id'].setValue(resultData.quotation_sub_header_id);
          }
          if(resultData.customer_id){
            await this.CustomerInfoService.getById({customer_id: resultData.customer_id}).then(res=>{
              this.customerList.push(
                {
                  id: resultData.customer_id,
                  text: `[${res.resultData?.customer_code || ''}] ${res.resultData?.company_name || ''} ${res.resultData?.branch?" (" + res.resultData?.branch + ")": ""}`,
                }
              )
            })
            await this.selectCustomer(resultData.customer_id);
          }
          if(resultData.customer_address_id){
            let address = this.customerAddressList.find(v=> v?.customer_id == resultData.customer_id);
            if(address) this.form.controls['customer_address_id'].setValue(resultData.customer_address_id);

            await this.selectAddressName(resultData.customer_address_id);
          }
          await this.QuotationContactService.load(null, { quotation_info_id: this.quotation_info_id }).then((res) => {
            let ids = res.resultData.map(v=>{
              return v.customer_contact_id;
            })
            this.form.controls['customer_contact_ids'].setValue(ids);
          });
          await this.getFileList();
          await this.getProductItemList();
        }
      });

      await this.ProductService.load(null, {
        order_by:'product_code:asc'
      }).then((res) => {
        this.productList = res.resultData || [];
      });

      setTimeout(() => {
        this.setDateTimePicker();
      }, 1000);

      if (this.isCopy) {

        this.form.controls['doc_no'].setValue("");
        this.form.controls['doc_no'].setValidators(null);
      }
      this.disableForm();
      
      this.pageLoad = true;
    });

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );
  }

  disableForm(){
    if(this.quotationInfo.doc_status_id == 'REVISE'){

      Object.keys(this.form.value).map(item=>{
        if(item == 'quotation_product_items'){
          this.form.controls[item].enable();
        }else{
          this.form.controls[item].disable();
        }
      })

      this.productItemList.controls.map((product, index)=>{
        let productItem = this.tmpProductItemList[index];
        if(
          (productItem.lock_invoice_at != null) && (productItem.ready_to_billing == null || productItem.ready_to_billing == "")){
            let control = (<FormArray>this.form.get('quotation_product_items')).controls[index];
            Object.keys(control.value).map(item=>{
              if(item == 'price'){
                control['controls'][item].enable();
              }else{
                control['controls'][item].disable();
              }
            })
        }else{
          let control = (<FormArray>this.form.get('quotation_product_items')).controls[index];
          Object.keys(control.value).map(item=>{
            control['controls'][item].disable();
          })
        }
      })
    }
  }

  async selectCustomer(event){
    if(event){
      await this.setCustomerAddress(event);
      await this.setCustomerContact(event);
      await this.setDiscountPercentage(event);
    }
  }
  async setCustomerAddress(event){
    const customer = this.quotationInfo.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true",customer_id: event})
    .then(async (res) => {
      let customer = res.resultData.filter(item=> item.customer_address_type_id == 2) || [];

      if(customer.length > 0){
        if(customer.length > 0){
          address = this.utils.genCustomerAddressList(customer[0]);
        } 
      }
    });
    //
    if(customer){
      this.form.get('customer_address_id').enable();
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
        this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 2) || [];
        
        this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem=>{
          return this.utils.genCustomerAddressList(elem);
        })
      });
      let customerAddress = this.tmpCustomerAddressList;
      if(address){
        this.customerAddressList = [
          ...[address],
          ...customerAddress
        ];
      }else{
        this.customerAddressList = [
          ...customerAddress
        ];
      }
      if(this.customerAddressList.length > 0){
        const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        if(checkAddress.length == 0) {
          if(address){
            this.form.get('customer_address_id').setValue(address.id);
            this.selectAddressName(address.id);
          }else{
            this.form.controls['customer_address_id'].setValue(this.customerAddressList[0]?.id);
          }
        }
      }else{
        if(address){
          this.customerAddressList = [
            ...[address]
          ];
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }else{
      this.customerAddressList = [
        ...[address],
      ];
      this.form.get('customer_address_id').disable();
      this.form.controls['customer_id'].setValue("");
      this.form.controls['customer_address_id'].setValue("");
    }
  }
  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address?.address_name){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.controls['customer_address'].setValue("");
      this.form.controls['customer_address_id'].setValue("");
    }

  }
  async setCustomerContact(event){
    this.form.get('customer_contact_ids').enable();
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname} ${elem.related_customer_address_info?.department?" (แผนก:" + elem.related_customer_address_info?.department + ")": ""}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }
  async setDiscountPercentage(event){
    await this.CustomerInfoService.getById({
      customer_id: event
    }).then(async (res) => {
      if(res.resultData){
        this.customer_level_id = res.resultData.customer_level_id;

        this.form.get("sale_id").setValue(this.utils.convertStingToNumber(res.resultData.sales_assignment_info.user_id || 0));
        this.form.get("discount_percentage").setValue(this.utils.convertStingToNumber(res.resultData.discount_percentage_init || 0));
      }
    });
  }

  onKeyup(){
    this.subject.next();
  }
  setDateTimePicker(){
    $('#doc_date').on('dp.change', function(e){ 
      $('#doc_validdate').data("DateTimePicker").date(moment(new Date(e.date)).add(1, 'M'));
    });
  }
  async getFileList(){
    this.QuotationFileService.load(null, { quotation_info_id: this.quotation_info_id  }).then((res)=>{
      this.files = res.resultData || [];
    })
  }
  async getProductItemList(){
    let quotationProductItemResponse = await this.QuotationProductItemService.load(null, {
       quotation_info_id: this.quotation_info_id,
       'order_by': 'order_by:asc'
    });
    this.tmpProductItemList = quotationProductItemResponse.resultData;
    if (this.tmpProductItemList){
      this.tmpProductItemList.map(async item =>{
        let price = 0;
        // if(this.quotationInfo.doc_status_id == 'REVISE' && item.item_type == 'product' && item.cost_of_sales){
        //   price = item.cost_of_sales
        // }else{
        //   price = item.price;
        // }
        price = item.price;
        let data = {
          product_code: item.product_info?.product_code,
          product_title: item.product_info?.product_title,
          price: price,
          product_item: item,
          related_job_order_info: item?.related_job_order_info,
          lock_invoice_at: item?.lock_invoice_at,
          unlock_invoice_at: item?.unlock_invoice_at,
          cost_of_sales: item?.cost_of_sales
        }
        if(data.product_item.item_type == 'product'){
          await this.productItemList.push(this.createProductItem(data));
        }else if(data.product_item.item_type == 'calibration_service'){
          await this.productItemList.push(this.createProductMitItem(data));
        }else if (data.product_item.item_type == 'description'){
          await this.productItemList.push(this.createDescriptionItem(data));
        }
      });
      this.sumCount();
    }
  }
  createForm(){
    this.form = this.fbd.group({
      customer_id: ["", [Validators.required]],
      doc_no: ["", [Validators.required]],
      doc_date: ["", [Validators.required]],
      doc_validdate: ["", [Validators.required]],
      doc_status_id: ["n/a", [Validators.required]],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      grand_total: [""],
      discount: [0.00],
      tax: ["7"],
      customer_contact_ids: [[], [Validators.required]],
      quotation_sub_header_id: ["", [Validators.required]],
      discount_percentage: [""],
      sale_id: [""],
      quotation_product_items: this.fbd.array([])
    },
    { validator: docValiddateAfterOrEqualValidator });
    this.productItemList = this.form.get('quotation_product_items') as FormArray;
  }
  getProductItemFormGroup(index): FormGroup {
    this.productItemList = this.form.get('quotation_product_items') as FormArray;
    const formGroup = this.form.controls[index] as FormGroup;
    return formGroup;
  }
  get productItemFormGroup() {
    return this.form.get('quotation_product_items') as FormArray;
  }
  createProductItem(value:any = {}): FormGroup {

    return this.fbd.group({
      quotation_item_id: this.fbd.control(value.product_item?.quotation_item_id || null),
      product_id: this.fbd.control(value.product_item?.product_id),
      product_code: this.fbd.control(value.product_code),
      product_title: this.fbd.control(value.product_item?.product_title),
      product_description: this.fbd.control(value.product_item?.product_description),
      original_price: this.fbd.control(value.product_item?.original_price || value.price),
      price: this.fbd.control(value.price),
      item_type: this.fbd.control(value.product_item?.item_type || 'product'),
      quantity: this.fbd.control(value.product_item?.quantity || 1),
      unit_id:this.fbd.control(value.product_item?.unit_info?.unit_id || value.product_item?.product_price_unit_info?.unit_info?.unit_id),
      unit_short_name_en: this.fbd.control(value.product_item?.unit_short_name_en || value.product_item?.product_price_unit_info?.unit_info?.unit_short_name_en),
      discount:this.fbd.control(value.product_item?.discount != null? value.product_item?.discount: this.utils.transformDecimal(this.form.value.discount_percentage)),
      revised_at: this.fbd.control(value.product_item?.revised_at || null),
      order_by: this.fbd.control(value.product_item?.order_by || 0),
      related_job_order_info_id: this.fbd.control(value?.related_job_order_info_id),
      related_job_order_info: this.fbd.control(value?.related_job_order_info),
      lock_invoice_at: this.fbd.control(value?.lock_invoice_at),
      unlock_invoice_at: this.fbd.control(value?.unlock_invoice_at),
      cost_of_sales: this.fbd.control(value?.cost_of_sales),
      index: this.fbd.control(null),
    });
  }
  createProductMitItem(value:any = {}): FormGroup {

    return this.fbd.group({
      quotation_item_id: this.fbd.control(value.product_item?.quotation_item_id || value?.quotation_item_id || null),
      product_description: this.fbd.control(""),
      product_title: this.fbd.control(value.product_item?.product_title || 'บริการสอบเทียบ'),
      price: this.fbd.control(value.price),
      item_type: this.fbd.control(value.product_item?.item_type || 'calibration_service'),
      quantity: this.fbd.control(value.product_item?.quantity || 1),
      discount:this.fbd.control(value.product_item?.discount || 0),
      order_by: this.fbd.control(value.product_item?.order_by || 0),
      index: this.fbd.control(null)
    });
  }
  createDescriptionItem(value:any = {}): FormGroup {
    return this.fbd.group({
      quotation_item_id: this.fbd.control(value.product_item?.quotation_item_id || null),
      price: this.fbd.control(0),
      product_description: this.fbd.control(value.product_item?.product_description),
      item_type: this.fbd.control(value.product_item?.item_type || 'description'),
      discount:this.fbd.control(0),
      order_by: this.fbd.control(value.product_item?.order_by || 0),
    });
  };
  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;
    if (this.form.invalid || this.formNoteIn.invalid || this.formNoteOut.invalid || this.checkFileInvalid) {
      return;
    };
    this.submit();
  }
  saveAsRevise(){
    this.form.get('doc_status_id').setValue("REVISE");
    this.submitted = true;
    if (this.form.invalid || this.formNoteIn.invalid || this.formNoteOut.invalid || this.checkFileInvalid) {
      return;
    };
    this.submit();
  }
  save(){
    this.form.get('doc_status_id').setValue("WAITING_FOR_APPROVE");
    this.submitted = true;
    if (this.form.invalid 
      || this.formNoteIn.invalid || this.formNoteOut.invalid
      || this.productItemList.length === 0 || this.checkFileInvalid) {
      return;
    };
    this.submit();
  }
  saveRevise(){
    this.form.get('doc_status_id').setValue("REVISE_WAITING_FOR_APPROVE");
    this.submitted = true;
    if (this.form.invalid 
      || this.productItemList.length === 0 || this.checkFileInvalid) {
      return;
    };
    this.submit();
  }
  async submit(){
    let data = this.form.getRawValue();
    this.pageLoad = false;
    this.disableSaveBtn = true;
    
    if(data.customer_contact_ids.length > 0){
      data.customer_contact_ids = data.customer_contact_ids.map(v=>{
        let select = this.contacts.find(item=> item.customer_contact_id == v);
        if(select){
          return {...select};
        }else{
          return {customer_contact_id: v};
        }
      })
    }
    const deleteContactList =  this.contacts.map(v=>{
      let select = data.customer_contact_ids.find(item=> item.customer_contact_id == v.customer_contact_id);
      if(!select){
        return {...v};
      }
    }).filter(r => r != undefined);

    if(this.isCopy){
      if(data.customer_contact_ids.length > 0){
        data.customer_contact_ids = data.customer_contact_ids.map(v=>{
          return {customer_contact_id: v.customer_contact_id};
        })
      }
      data = {
        ...data,
        doc_no_old: this.isEdit? this.quotationInfo.doc_no: undefined
      }
      let quotationResponse = await this.CoreService.copyDocumentToNewQuotation(
        data, 
        this.files,
        this.mitItems,
        this.formNoteIn.value.notes,
        this.formNoteOut.value.notes
      );

      if(quotationResponse.status){
        await this.router.navigateByUrl("/quotation", {
          state: {
            status: quotationResponse.message,
          },
        });
      }else{
        this.status = quotationResponse.message;
      }
      this.disableSaveBtn = false;
      this.pageLoad = true;
    }else{

      let quotationResponse = await this.CoreService.editQuotation(
        data, 
        deleteContactList,
        this.files,
        this.mitItems, 
        this.formNoteIn.value.notes,
        this.formNoteOut.value.notes,
        this.deleteProductItemList, 
        this.deleteNoteList,
        this.quotation_info_id
      );
      if(quotationResponse.status){
        await this.router.navigateByUrl("/quotation", {
          state: {
            status: quotationResponse.message,
          },
        });
      }else{
        this.status = quotationResponse.message;
      }
      this.disableSaveBtn = false;
      this.pageLoad = true;
    }
  }
  public get checkFileInvalid(): boolean {
    if(this.files.find(file=> this.UtilsService.checkFileSize(file) == false)){
      return true;
    }else{
      return false;
    }
  }
  submitProduct(event){
    let duplicateProduct = false;
    if(!duplicateProduct){

      this.productItemList.push(this.createProductItem(event));
    }
    this.sumCount();
  }
  async deleteProductItem(closeModalEl){
    let productItem = this.productItem.value;
    this.productItemList.getRawValue().map((item, index)=>{

      if(item.index == productItem.index){
        this.deleteProductItemList.push(productItem);
        this.productItemList.removeAt(index);
      }
    });
    this.sumCount();
    closeModalEl.click();
  }
  select(productItem){
    this.productItem = Object.assign({}, productItem);
  }
  sumCount(){
    this.count = 0;
    this.productItemList.getRawValue().map(product=>{
      if(product.price && product.item_type != 'description' && product.item_type != 'note') 
      this.count = this.count + this.calculate.sumProduct(product);
    })
    this.form.get('discount').setValue(this.utils.transformDecimal(this.form.get('discount').value));
    this.productItemList.controls.map(product=>{
      let productValue = product.getRawValue();

      if (productValue.quantity == null && productValue.item_type != 'description' && productValue.item_type != 'note'){
        product.get('quantity').setValue(0);
      }

      product.get('price').setValue(this.utils.transformDecimal(productValue.price));
      product.get('discount').setValue(this.utils.transformDecimal(productValue.discount));

      if(this.quotationInfo.doc_status_id == 'REVISE' && productValue.item_type == 'product'){

        if(this.utils.transformDecimal(productValue.price) != this.utils.transformDecimal(productValue.original_price)){
          product.get('revised_at').setValue(new Date());
        }
      }
    })
    let total = this.calculate.sumProductTotal(this.count, this.form.getRawValue());
    this.form.get('grand_total').setValue(total);
    this.addNumberRunning();
  }
  uploadFile(){
    document.getElementById("importFile").click();
  }
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  downloadFile(file){
    if(file.quotation_file_id){
      this.DownloadFileService.downloadFileURL(file);
    }else{
      this.DownloadFileService.downloadFile(file);
    }
  }
  removeFile(file, index){
    this.fileItem = file;
  }
  async deleteFile(closeModalEl){
    let fileItem = this.fileItem;
    if(fileItem.quotation_file_id && !this.isCopy){
      let response = await this.QuotationFileService.delete({
        quotation_file_id: fileItem.quotation_file_id,
      });
      this.getFileList();
    }else{
      this.files.map((item, index)=>{
        if(fileItem == item){
          this.files.splice(index, 1);
        }
      })
    }
    closeModalEl.click();
  }
  addDescription(){
    this.productItemList.push(this.createDescriptionItem());
  }
  addNumberRunning(){
    let i = 1;
    this.productItemList.controls.map(item=>{
      let item_type = item.get('item_type').value;
      if (item_type != 'description' && item_type != 'note'){
        item.get('index').setValue(i);
        i++;
      }
    })
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.productItemList.controls, event.previousIndex, event.currentIndex);
    moveItemInArray(this.productItemList.value, event.previousIndex, event.currentIndex);
    this.productItemList = this.utils.calculateOrderBy(event.currentIndex, this.productItemList, 'order_by');
    this.addNumberRunning();
  }
  onSubmitMitItem(event){
    this.mitItems = event;
    if(this.mitItems){
      let checkMitItem = this.productItemList.getRawValue().find(
        value => value.item_type =='calibration_service'
      );
  
      if(this.mitItems.quotationMitItems.length > 0){
    
        var total_price = this.mitItems.quotationMitItems?.map(item => parseFloat(item.grand_total)).reduce((prev, next) => prev + next);
        
        if(checkMitItem){
          this.productItemList.controls.map(value =>{

            if(value.value.item_type == checkMitItem.item_type) value.get('price').setValue(total_price);
          })
        }else{
          let checkMitItem = this.deleteProductItemList.find(
            value => value.item_type =='calibration_service'
          );

          if(checkMitItem){
            this.productItemList.insert(
              0,this.createProductMitItem({...checkMitItem, price: total_price })
            );

            const index = this.deleteProductItemList.indexOf(checkMitItem);
            if (index > -1) { 
              this.deleteProductItemList.splice(index, 1);
            }
          }else{
            this.productItemList.insert(
              0,this.createProductMitItem({price: total_price })
            );
            this.productItemList = this.utils.calculateOrderBy(0, this.productItemList, 'order_by');
          }
        }
      }else{
        if(checkMitItem){
          this.productItemList.controls.map((value, index) =>{
            if(value.value.quotation_item_id == checkMitItem.quotation_item_id) {
              this.deleteProductItemList.push(checkMitItem);
              this.productItemList.removeAt(index);
            }
          })
        }
      }
      
      this.sumCount();
      this.itemLoad = true;
    }
  }
  openPdf(quotationInfo) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/document/' + quotationInfo.quotation_info_id])
    );
  
    window.open(url, '_blank');
  }
  async sendEmail(quotationInfo){
    let quotationResponse =  await this.QuotationService.sendEmail({
      ...quotationInfo
    });
    if(quotationResponse.success){
      this.status = quotationResponse.success;
    }
  }

  addNoteIn(event){
    if(event){
      this.formNoteIn = event.form;
      this.deleteNoteList = [...this.deleteNoteList, ...event.deleteNoteList];
    }
  }

  addNoteOut(event){
    if(event){
      this.formNoteOut = event.form;
      this.deleteNoteList = [...this.deleteNoteList, ...event.deleteNoteList];
    }
  }
}