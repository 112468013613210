<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="invoice-create-filter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="InvoiceFilterModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="InvoiceFilterModal">
          สร้าง{{page}}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div class="form-body">
              <div class="mt-1">
                <div class="p-2">
                  <form-group
                  [control]="form.controls.customer_id"
                  [submitted]="submitted"
                  >
                    <label for="">
                      ค้นหาจาก Customer name
                    </label>
                    <ng-select2
                      *ngIf="reset"
                      [options]="select2OptionCustomer"
                      class="w-100"
                      placeholder="Customer name"
                      formControlName="customer_id"
                      [allowClear]="true"
                      >
                    </ng-select2>
                  </form-group>
                </div>
            </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ตกลง
        </button>
      </div>
    </div>
  </div>
</div>
