import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import {
  UnitService,
  UnitTypeService,
  UnitConversionService,
} from "src/app/services";

@Component({
  selector: "app-unit-list",
  templateUrl: "./unit-list.component.html",
  styleUrls: ["./unit-list.component.css"],
})
export class UnitListComponent implements OnInit {
  isLoading = true;
  units = [];
  status: string;
  unit = null;
  reset: boolean = false;
  filter = {};
  unitTypes = [];
  load: Function = this.UnitService.load;
  constructor(
    private router: Router,
    private UnitService: UnitService,
    private UnitTypeService: UnitTypeService,
    private UnitConversionService: UnitConversionService
  ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.units = [];

    let response = await this.UnitTypeService.load();
    if (response.success) {
      this.unitTypes = response.resultData || [];
    }
  }
  onChangePage(units: Array<any>) {
    this.units = units;
    this.isLoading = false;
  }
  select(unit) {
    this.unit = Object.assign({}, unit);
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
      this.unit = null;
    }, 50);
  }
  search(filter) {
    this.filter = filter;
  }
  async delete(closeModalEl) {
    let removeEl = this.units.filter(
      (unit) => unit.unit_id === this.unit.unit_id
    )[0];
    if (removeEl && !removeEl.isUsed) {
      let response = await this.UnitService.delete({
        unit_id: this.unit.unit_id,
      });

      if (response.success) {
        await this.UnitConversionService.deleteByParams({
          unit_id: this.unit.unit_id,
        });
      }

      this.doReset(response.success || response.error);
    }
    closeModalEl.click();
  }
}
