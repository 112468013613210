<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="add-mit-import-item"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addMitImportItemModal"
  aria-hidden="true"
>
  <div class="modal-dialog" [ngClass]="{'modal-xl': willDownload && !isSubmit, 'modal-md': !willDownload || isSubmit }" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="addMitImportItemModal">
          นำเข้ารายการ MIT
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body px-1">
        <div class="row">
          <div class="text-center col-12" *ngIf="!willDownload">
            <button 
            class="btn btn-outline-primary" 
            type="button"
            (click)="uploadFile()">
              <i class="feather icon-upload icon-left"></i>
              Upload
            </button>
            <input type="file" style="display:none;" id="importMitFile"
            (change)="onFileChange($event)">
          </div>
          <div  class="text-center col-12 mt-1" *ngIf="!willDownload">
            <span>
              Template การนำเข้าข้อมูล <a href='javascript:;' (click)="downloadTemplate()">[Download]</a>
            </span>
          </div>
          <div  class="text-center text-danger col-12 mt-1"  *ngIf="!willDownload">
            ไม่รองรับการแปลงหน่วยโปรดระบุให้ตรงกับหน่วยฐาน
          </div>
          <div class="pt-1 col-12">
            <loading *ngIf="isLoading"></loading>
          </div>
          <div class="pt-1 col-12" *ngIf="willDownload && !isSubmit">
            <h6>
              <span class="badge badge-primary mr-1">ทั้งหมด {{mitBodyList.length}} รายการ</span>
              <span class="badge badge-success mr-1">ผ่าน {{number_of_success}} รายการ</span>
              <span class="badge badge-danger mr-1">ไม่ผ่าน {{number_of_fails}} รายการ</span>
            </h6>
            <div class="table-responsive" style="max-height: 540px;">
              <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th class="fixed-column" [ngClass]="{'px-5': textCenter(header.toLowerCase())}" 
                      *ngFor="let header of mitHeaderList; let i = index;">
                        <div>
                          {{header}}
                          <span *ngIf="!textCenter(header.toLowerCase()) && header.length < 20" class="pr-4">&nbsp;</span>
                          <span *ngIf="!textCenter(header.toLowerCase()) && header.length < 20" class="pr-4">&nbsp;</span>
                          <span *ngIf="!textCenter(header.toLowerCase()) && header.length < 20" class="pr-2">&nbsp;</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let body of mitBodyList; let i = index;">
                      <td class="fixed-column" *ngFor="let header of mitHeaderList; let j = index;">
                          <div [ngClass]="{'text-danger': body[j]?.isValid == false}">
                            <div [ngClass]="{'text-center': textCenter(header.toLowerCase())}" 
                            *ngIf=" body[j]?.isValid == true">
                              <div *ngIf="canEdit(header.toLowerCase())">
                                <input 
                                type="text"
                                class="form-control" 
                                placeholder="{{header}}"
                                (change)="validateFileByData()"
                                [ngClass]="{'text-center': textCenter(header.toLowerCase())}" 
                                [(ngModel)]="body[j].data">
                              </div>
                              <div *ngIf="!canEdit(header.toLowerCase())">
                                {{body[j]?.data}}
                              </div>
                            </div>
                            <div [ngClass]="{'text-center': textCenter(header.toLowerCase())}" 
                            *ngIf=" body[j]?.isValid == false">
                              <div *ngIf="canEdit(header.toLowerCase())">
                                <input 
                                type="text"
                                class="form-control validate-fail" 
                                placeholder="{{header}}"
                                (change)="validateFileByData()"
                                [ngClass]="{'text-center': textCenter(header.toLowerCase())}" 
                                [(ngModel)]="body[j].data">
                              </div>
                              <div class="alert alert-danger my-0" *ngIf="!canEdit(header.toLowerCase())">
                                {{body[j]?.data}}
                              </div>
                            </div>
                            <div>
                              <div *ngIf="body[j]?.isValid == false">
                                เหตุผล: {{body[j]?.reason}}
                              </div>
                            </div>
                          </div>
                      </td>
                    </tr>
                  </tbody>
                  <tbody *ngIf="mitBodyList.length == 0">
                    <tr>
                      <td attr.colspan="{{mitHeaderList.length}}" class="text-center">ไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
              </table>

              <br>
            </div>
          </div>
          <div class="pt-1 col-12" *ngIf="willDownload && status == false && isSubmit">
            <div class="pl-1 text-danger">
              ข้อมูลไม่ถูกต้อง
            </div>
          </div>
          <div class="pt-1 col-12" *ngIf="quotationMitItems.length == 0 
          && mitBodyList.length == 0 && status == false && isSubmit">
            <div class="pl-1 text-danger">
              ยังไม่ได้เลือกไฟล์
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-save icon-left"></i>บันทึกรายการ
        </button>
      </div>
    </div>
  </div>
</div>
