import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { 
  CustomerAddressService, 
  CustomerContactService,
  UtilsService
} from 'src/app/services';
import { 
  DownloadFileService,
  NgSelect2Customer,
  QuotationProductItemService,
  QuotationUtilsService 
} from 'src/app/services/quotations';
import { 
  JobOrderContactService,
  JobOrderFileService,
  JobOrderInfoService, 
  JobOrderProductItemService,
  JobOrderStatusConfirmService
} from 'src/app/services/job-orders';
import { AbsNumberValidator } from 'src/app/validators';
import { CoreEditService } from 'src/app/services/job-orders/edit.service';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Observable } from 'rxjs';
declare var $: any;

@Component({
  selector: 'app-work-order-non-lab-edit',
  templateUrl: './work-order-non-lab-edit.component.html',
  styleUrls: ['./work-order-non-lab-edit.component.css']
})
export class WorkOrderNonLabEditComponent implements OnInit {
  form: FormGroup;
  extraForm: FormGroup;

  submitted: boolean = false;
  invalid: boolean = false;
  pageLoad: boolean = true;
  reset: boolean = true;
  resetdate: boolean = true;
  disableSaveBtn: boolean = false;

  files = [];
  contacts = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerContactList: any = [];

  chargeReportAddressList = [];
  addressList = [];

  job_order_info_id: string = "";
  template: string = "";
  next_status: string = "";

  status: any;
  customer_info = null;
  jobOrderInfo = null;
  fileItem = null;
  jobOrderProductItem = null;
  trainingItem = null;
  carInfoItem = null;
  maxReturnDate = undefined;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;
  constructor(
    private fbd: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private JobOrderInfoService: JobOrderInfoService,
    private JobOrderContactService: JobOrderContactService,
    private DownloadFileService: DownloadFileService,
    private JobOrderFileService: JobOrderFileService,
    private JoborderProductItemService: JobOrderProductItemService,
    private JobOrderStatusConfirmService: JobOrderStatusConfirmService,
    private QuotationProductItemService: QuotationProductItemService,
    private CoreService: CoreEditService,

    public UtilsService: UtilsService,
    public utils : QuotationUtilsService,
    public NgSelect2Customer: NgSelect2Customer
  ) { 
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
      this.next_status = params['status'];
    });
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
  }

  async ngOnInit() {
    this.createForm();
    this.userInfo$.subscribe(async (userInfo) => {
      if(userInfo){
        this.userInfo = userInfo;
        if(userInfo.is_tran_dep){
          Object.keys(this.form.value).map(item=>{
            if(item == 'pickup_date' || item == 'return_date' || item == 'real_return_date'){
              this.form.controls[item].enable();
            }else{
              this.form.controls[item].disable();
            }
          })
        }else{
          this.form.get('return_date').disable();
          this.form.get('real_return_date').disable();
        }
        if(!userInfo.is_sales){
          this.extraForm.disable();
        }
      }
      if(this.next_status == 'confirm' || this.next_status == 'complete'){
        this.form.disable();
        
        if(this.next_status == 'confirm'){
          Object.keys(this.extraForm.value).map(item=>{
            if(item == 'special_condition' || item == 'note'){
              this.extraForm.controls[item].enable();
            }else{
              this.extraForm.controls[item].disable();
            }
          })
        }
      }
    });
    await this.getData();
    await this.setData();

    this.pageLoad = false;
  }

  async getData(){}

  async setData(){
    await this.JobOrderInfoService.getById({job_order_info_id: this.job_order_info_id})
    .then(async res=>{
      let resultData = res.resultData || {};
      this.jobOrderInfo = res.resultData || {};
      
      // if(this.userInfo.emp_info.emp_position_id == 'SALES_DEP-02'){
      //   if(this.jobOrderInfo.created_by != this.userInfo.user_id){
      //     this.router.navigate(['/pages/404']);
      //   }
      // }

      this.customer_info = res.resultData.customer_info;
      let data = {
        ...resultData,
        credit_term_day: resultData?.customer_info?.credit_term_day || 0,
        customer_name: `[${resultData?.customer_info?.customer_code || ''}] ${resultData?.customer_info?.company_name || ''} ${resultData.customer_info?.branch?" (" + resultData.customer_info?.branch + ")": ""}`,
        service_time: resultData?.service_time? resultData?.service_time?.split(":")[0]+ ':'+ resultData?.service_time?.split(":")[1]: "00:00"
      }
      this.form.patchValue(data);
      this.extraForm.patchValue(data);
      this.form.controls['doc_date'].setValue(moment(resultData.doc_date).format('YYYY-MM-DD'));
      this.form.controls['pickup_date'].setValue(moment(resultData.pickup_date).format('YYYY-MM-DD'));
      this.form.controls['return_date'].setValue(moment(resultData.return_date).format('YYYY-MM-DD'));
      if(resultData.pickup_date){
        this.maxReturnDate = moment(new Date(resultData.pickup_date)).add(10,'d').format('YYYY-MM-DD');
      }
      if(resultData.real_return_date){
        this.form.controls['real_return_date'].setValue(moment(resultData.real_return_date).format('YYYY-MM-DD'));
      }

      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: resultData.customer_id})
      .then(async (res) => {
        let resultData = res.resultData || [];

        this.chargeReportAddressList = resultData.filter(item=> item.customer_address_type_id === 3).map(elem => (
          {
            id: elem.customer_address_id,
            text: elem.address_name +' '+ this.utils.genAddress(elem),
            data: elem
          } 
        ));

        this.addressList =  resultData.map(elem => (
          {
            id: elem.customer_address_id,
            text: elem.address_name +' '+ this.utils.genAddress(elem),
            data: elem
          } 
        ));
      });
      
      if(resultData.customer_id){
        await this.setCustomerAddress(resultData.customer_id);
        await this.setCustomerContact(resultData.customer_id);
      }
      
      await this.JobOrderContactService.load(null, { job_order_info_id: this.job_order_info_id }).then((res) => {
        this.contacts = res.resultData || [];
        let ids = res.resultData.map(v=>{
          return v.customer_contact_id;
        })
        this.form.controls['customer_contact_ids'].setValue(ids);
      });
      await this.getFileList();
      this.resetdate = false;
      setTimeout(() => {
        this.resetdate = true;
      }, 100);
    })
    setTimeout(() => {
      this.setDateTimePicker();
    }, 1000);
  }

  async getFileList(){
    this.JobOrderFileService.load(null, { job_order_info_id: this.job_order_info_id  }).then((res)=>{
      this.files = res.resultData || [];
    })
  }
    
  public setDateTimePicker(){
    let self = this;
    $('#pickup_date').on('dp.change', function(e){ 
      self.resetdate = false;
      setTimeout(() => {
        self.resetdate = true;
      }, 50);

      $('#return_date').data("DateTimePicker").date(moment(new Date(e.date)).add(10, 'd'));
      
      self.form.get('return_date').setValue(moment(new Date(e.date)).add(10, 'd'));
      self.maxReturnDate = moment(new Date(e.date)).add(10,'d');
    });
  }

  public createForm(){
    this.form = this.fbd.group({
      job_order_info_id:  [""],
      customer_id: ["", [Validators.required]],
      customer_name: [""],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      pickup_date: ["", [Validators.required]],
      return_date: ["", [Validators.required]],
      real_return_date: [""],
      doc_status_id: ["", [Validators.required]],
      charge_address_id: ["", [Validators.required]],
      service_time: ["00:00"],

      doc_no:  ["", [Validators.required]],
      doc_date:  ["", [Validators.required]],
      job_order_type: [""],
      location: ["", this.template == 'TRN'? [Validators.required]: null],
      no_of_day: ["", this.template == 'TRN'? [Validators.required, AbsNumberValidator()]: null],
      no_of_people: ["", this.template == 'TRN'? [Validators.required, AbsNumberValidator()]: null],

      customer_contact_ids: [[], [Validators.required]],
      credit_term_day: [""],
      sales_user_id: [""],
    });

    this.extraForm = this.fbd.group({
      special_condition: [""],
      note: [""],
      accessory: [""],
    });
  }

  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData || []
      if(customer.length > 0){
        if(customer.length > 0){
          address = this.utils.genCustomerAddressList(customer[0]);
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
       this.tmpCustomerAddressList = res.resultData || [];
       this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem=>{
        return this.utils.genCustomerAddressList(elem);
      })
      });
      let customerAddress = this.tmpCustomerAddressList;
      this.customerAddressList = [
        ...[address],
        ...customerAddress
      ];

      if(this.customerAddressList.length > 0){
        const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        if(checkAddress.length == 0) {
          if(address){
            this.form.get('customer_address_id').setValue(address.id);
            this.selectAddressName(address.id);
          }else{
            this.form.controls['customer_address_id'].setValue("");
          }
        }
      }else{
        this.customerAddressList = [
          ...[address],
        ];
        if(address){
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
    // const result =  this.customerContactList.map(v=>{
    //   let select = this.form.getRawValue().customer_contact_ids.find(item=> item == v.id);
    //   if(select){
    //     return {...select};
    //   }
    // }).filter(r => r != undefined);
    // if(result.length == 0){
    //   this.form.get('customer_contact_ids').setValue([customerContact[0].id]);;
    // }
  }

  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address?.address_name){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.controls['customer_address'].setValue("");
      this.form.controls['customer_address_id'].setValue("");
    }
  }

  public get canSaveConfirm(): boolean{
    let result = this.jobOrderProductItem?.jobOrderItems
    .filter(item=> item.item_status_id != 'CRF-CONFIRMED' && item.item_status_id != 'CRF-REJECTED') || [];
    if(result.length == 0){
      return true;
    }else{
      return false;
    }
  }

  public get canSaveCompleted(): boolean{
    if(this.template == 'PRD'){
      let result = this.jobOrderProductItem?.jobOrderItems
      .filter(item=> item.item_status_id != 'CRF-COMPLETED' && item.item_status_id != 'CRF-CANCELED') || [];
      if(result.length == 0){
        return true;
      }else{
        return false;
      }
    }else{
      let result = this.trainingItem?.form?.value?.trainings
      .filter(item=> item.status == 'WAITING') || [];
      if(result.length == 0){
        return true;
      }else{
        return false;
      }
    }
  }

  public get canSaveTraining(): boolean{
    if(this.trainingItem?.form?.value?.trainings.length == 0 || this.trainingItem == undefined){
      return false;
    }else{
      return true;
    }
  }

  public get checkFileInvalid(): boolean {
    if(this.files.find(file=> this.UtilsService.checkFileSize(file) == false)){
      return true;
    }else{
      return false;
    }
  }

  public saveAsCompleted(is_send_mail = false){
    if(['PRD'].includes(this.template)){
      this.form.get('doc_status_id').setValue("PREPARE_FOR_SHIP");
    }else{
      this.form.get('doc_status_id').setValue("RETURNED_SUCCESS");
    }
    this.submitted = true;
    this.invalid = false;
    
    if(this.template == 'PRD'){
      if (this.form.invalid || !this.canSaveCompleted || this.files.length == 0 || this.checkFileInvalid) {
        this.invalid = true;
        return;
      };
      let result = this.jobOrderProductItem?.jobOrderItems.filter(item=> item.item_status_id == 'CRF-CANCELED') || [];
  
      if(result.length > 0){
        this.form.get('doc_status_id').setValue("CANCELED");
        this.jobOrderProductItem?.jobOrderItems.map(item=> {
          item.item_status_id = 'CRF-CANCELED';
        });
      }else{
        this.jobOrderProductItem?.jobOrderItems.map(item=> {
          item.item_status_id = 'PREPARE_FOR_SHIP';
        });
      }
    }else{
      if (this.form.invalid || this.trainingItem.form.invalid || !this.canSaveCompleted || this.carInfoItem.form.invalid || this.checkFileInvalid) {
        this.invalid = true;
        return;
      };

      let result = this.trainingItem?.form?.value?.trainings.filter(item=> item.status == 'PASS') || [];
  
      if(result.length > 0){
        this.jobOrderProductItem?.jobOrderItems.map(item=> {
          item.item_status_id = 'CRF-COMPLETED';
        });
      }else{
        this.form.get('doc_status_id').setValue("CANCELED");
        this.jobOrderProductItem?.jobOrderItems.map(item=> {
          item.item_status_id = 'CRF-CANCELED';
        });
      }
    }
    this.submit(is_send_mail);
  }

  public saveAsConfirm(is_send_mail = false){
    this.form.get('doc_status_id').setValue("CONFIRMED");
    this.submitted = true;
    this.invalid = false;

    if(this.template == 'PRD'){
      if (this.form.invalid || !this.canSaveConfirm || this.checkFileInvalid) {
        this.invalid = true;
        return;
      };

      let result = this.jobOrderProductItem?.jobOrderItems.filter(item=> item.item_status_id == 'CRF-REJECTED') || [];
  
      if(result.length > 0){
        this.form.get('doc_status_id').setValue("REJECTED");
      }
    }else{
      if (this.form.invalid || this.trainingItem.form.invalid || !this.canSaveTraining || this.carInfoItem.form.invalid || this.checkFileInvalid) {
        this.invalid = true;
        return;
      };
      this.jobOrderProductItem?.jobOrderItems.map(item=> {
        item.item_status_id = 'CRF-CONFIRMED';
      })
    }
    this.jobOrderProductItem?.jobOrderItems.map(item=> {
      item.quotation_item_info.return_date = this.form.getRawValue().return_date;
    })
    this.submit(is_send_mail);
  }

  public saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;
    this.invalid = false;

    if (this.form.invalid || this.checkFileInvalid || this.jobOrderProductItem?.jobOrderItems?.length == 0) {
      this.invalid = true;
      return;
    };
    this.submit();
  }

  async saveReject(event){
    this.form.get('doc_status_id').setValue("REJECTED");
    let data = {
      ...this.form.getRawValue(),
      ...this.extraForm.getRawValue(),
      ...event.data,
      service_time: this.form.getRawValue().service_time.length > 10? moment(this.form.getRawValue().service_time).second(0).format("HH:mm:ss"): this.form.getRawValue().service_time + ":00",
    };

    let jobOrderResponse = await this.JobOrderInfoService.update(
      data
    );

    if(jobOrderResponse.success){
      await Promise.all(
        this.jobOrderProductItem.jobOrderItems.map(async (value: any, index: number)=>{
        let jobOrderProductItemResponse = await this.JoborderProductItemService
        .update({
          ...value,
          item_status_id: 'CRF-REJECTED',
          job_order_info_id: jobOrderResponse.resultData.job_order_info_id,
        });
        
        this.updateProductItem(value, jobOrderResponse, jobOrderProductItemResponse);

        if(jobOrderProductItemResponse.success && jobOrderResponse.resultData.doc_status_id == 'REJECTED'){

          if (value.item_status_id == 'CRF-WAITING_FOR_CONFIRM' || value.item_status_id == 'CRF-CONFIRMED'){

            await this.JobOrderStatusConfirmService.load(null, {
              job_order_info_id: Array.isArray(jobOrderResponse.resultData)? jobOrderResponse?.resultData[0]?.job_order_info_id: jobOrderResponse.resultData.job_order_info_id
            }).then(async res=>{
              let resultData = res.resultData || [];
              resultData.sort(function(a,b){
                if(a.updated_at < b.updated_at) return 1;
                if(a.updated_at > b.updated_at) return -1;
                return 0;
              });
              let status = resultData.find(item=> item.item_id == value.job_order_product_item_id);

              if(resultData.length > 0 && status){
                let  jobOrderStatusConfirmResponse = await this.JobOrderStatusConfirmService
                .update({
                  ...jobOrderProductItemResponse,
                  ...value,
                  item_status_id: 'CRF-REJECTED',
                  job_order_status_confirm_id: status.job_order_status_confirm_id,
                  item_id: jobOrderProductItemResponse.resultData.job_order_product_item_id,
                  item_type: "product",
                  reject_reason: data.reject_reason,
                  reject_service_date: data.reject_service_date,
                  reject_solution: data.reject_solution,
                });
      
                if(!jobOrderStatusConfirmResponse.success){
                  throw jobOrderStatusConfirmResponse.error;
                }
              }else{
                let  jobOrderStatusConfirmResponse = await this.JobOrderStatusConfirmService
                .create({
                  ...jobOrderProductItemResponse,
                  ...value,
                  item_status_id: 'CRF-REJECTED',
                  item_id: jobOrderProductItemResponse.resultData.job_order_product_item_id,
                  item_type: "product",
                  reject_reason: data.reject_reason,
                  reject_service_date: data.reject_service_date,
                  reject_solution: data.reject_solution,
                });
      
                if(!jobOrderStatusConfirmResponse.success){
                  throw jobOrderStatusConfirmResponse.error;
                }
              }
            })
          }
        }
        this.updateProductItem(value, jobOrderResponse, jobOrderProductItemResponse);
      }));

      await this.router.navigateByUrl("/work-order", {
        state: {
          status: jobOrderResponse.success,
        },
      })
    }else{
      this.status = jobOrderResponse.error;
    }
  }

  public save(){ 
    this.form.get('doc_status_id').setValue("WAITING_FOR_CONFIRM");
    this.submitted = true;
    this.invalid = false;

    if (this.form.invalid || this.checkFileInvalid || this.jobOrderProductItem?.jobOrderItems?.length == 0) {
      this.invalid = true;
      return;
    };
    this.jobOrderProductItem?.jobOrderItems.map(item=> {
      if(item.item_status_id == 'CRF-REJECTED'){
        item.item_status_id = 'CRF-WAITING_FOR_CONFIRM';
      }
    })
    this.submit();
  }

  public editDate(){
    this.submitted = true;
    if (this.form.invalid || this.checkFileInvalid) {
      return;
    };
    let result = this.jobOrderProductItem?.jobOrderItems.filter(item=> item.item_status_id != 'CRF-CANCELED');
    if(result.length == 0){
      this.form.get('doc_status_id').setValue("CANCELED");
    }
    this.submit();
  }

  async submit(is_send_mail = false){
    let data = {
      ...this.form.getRawValue(),
      ...this.extraForm.getRawValue(),
      is_send_mail: is_send_mail
    };

    if(data.doc_status_id == "CONFIRMED"){
      data['confirmed_by'] = this.userInfo.user_id
    }
    
    this.disableSaveBtn = true;

    if(data.customer_contact_ids.length > 0){
      data.customer_contact_ids = data.customer_contact_ids.map(v=>{
        let select = this.contacts.find(item=> item.customer_contact_id == v);
        if(select){
          return {...select};
        }else{
          return {customer_contact_id: v};
        }
      })
    }

    const deleteContactList =  this.contacts.map(v=>{
      let select = data.customer_contact_ids.find(item=> item.customer_contact_id == v.customer_contact_id);
      if(!select){
        return {...v};
      }
    }).filter(r => r != undefined);
    let jobOrderResponse = await this.CoreService.editJobOrder(
      data, 
      this.files, 
      deleteContactList,  
      this.jobOrderProductItem, 
      null, 
      this.trainingItem?.form?.value?.trainings || [], 
      this.trainingItem?.deleteTrainingList,
      null,
      null,
      this.carInfoItem?.form?.value?.carInfos || [], 
      this.carInfoItem?.deleteCarInfoList,);

    if(jobOrderResponse.status){
      await this.router.navigateByUrl("/work-order", {
        state: {
          status: jobOrderResponse.message,
        },
      })
    }else{
      if(jobOrderResponse.message == 'codeDuplicate'){
        this.status = jobOrderResponse.error;
      }else{
        this.status = jobOrderResponse.message;
      }
    }
    this.disableSaveBtn = false;
  }

  async getCarInfos(event){
    this.carInfoItem = event;
  }

  async getJobOrderItems(event){
    this.jobOrderProductItem = event;
  }

  async getTrainings(event){
    this.trainingItem = event;
  }

  public uploadFile(){
    document.getElementById("importFile").click();
  }
  public importFile(files: FileList){
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  public downloadFile(file){
    if(file.job_order_file_id){
      this.DownloadFileService.downloadFileURL(file);
    }else{
      this.DownloadFileService.downloadFile(file);
    }
  }
  public removeFile(file, index){
    this.fileItem = file;
  }
  async deleteFile(closeModalEl){
    let fileItem = this.fileItem;
    if(fileItem.job_order_file_id){
      let response = await this.JobOrderFileService.delete({
        job_order_file_id: fileItem.job_order_file_id,
      });
      this.getFileList();
    }else{
      this.files.map((item, index)=>{
        if(fileItem == item){
          this.files.splice(index, 1);
        }
      })
    }
    closeModalEl.click();
  }

  openPdf(jobOrder){
    let url = null;
    if(['ICL','ICS'].includes(jobOrder.job_order_type)){
      url = this.router.createUrlTree(['/job-order-document/icl-ics/'+ jobOrder.job_order_info_id])
    }else if(['ECL','ECS'].includes(jobOrder.job_order_type)){
      url = this.router.createUrlTree(['/job-order-document/ecl-ecs/'+ jobOrder.job_order_info_id])
    }else if(jobOrder.job_order_type == "PRD"){
      url = this.router.createUrlTree(['/job-order-document/purchase/'+ jobOrder.job_order_info_id])
    }

    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  openServiceReportPdf(jobOrder){
    let url = null;
    url = this.router.createUrlTree(['/job-order-document/service-report/'+ jobOrder.job_order_info_id])
    if(url){
      window.open(url.toString(), '_blank');
    }
  }

  async updateProductItem(item, jobOrderInfoResponse, jobOrderProductItem){
    if(item.quotation_item_info){
      let data = {
        ...item.quotation_item_info,
        // Update
        job_order_info_id: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.job_order_info_id: "",
        job_order_info_status: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.doc_status_id: "",
        job_order_info_doc_no: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.doc_no: "",
        job_order_info_type: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.job_order_type: "",

        job_order_product_item_status: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderProductItem.resultData.item_status_id: "",
        job_order_product_item_id: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderProductItem.resultData.job_order_product_item_id: "",
        lock_invoice_at: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderProductItem.resultData.lock_invoice_at: "",
        unlock_invoice_at: jobOrderProductItem.resultData.item_status_id!='CRF-CANCELED'? jobOrderProductItem.resultData.unlock_invoice_at: "",
      }
      let QuotationProductItemResponse = await this.QuotationProductItemService.update(data);
    }
  }
}
