import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import moment from 'moment';
import { UploadService } from 'src/app/services';
import { JobOrderCalibrationItemService } from 'src/app/services/job-orders';
import { 
  DownloadFileService,
  QuotationAcceptedFileService, 
  QuotationAcceptedService, 
  QuotationMitItemService, 
  QuotationProductItemService, 
  QuotationService, 
  QuotationUtilsService
} from 'src/app/services/quotations';
import { TransportreceiveItemService } from 'src/app/services/transport-receives';

@Component({
  selector: 'app-quotation-accept-list',
  templateUrl: './quotation-accept-list.component.html',
  styleUrls: ['./quotation-accept-list.component.css']
})
export class QuotationAcceptListComponent implements OnInit {
  @Input() quotationInfo: any;
  @Input() quotationMitItems: any;
  @Input() quotationProductItems: any;

  @Output() done: EventEmitter<any> = new EventEmitter();
  @Output() onSave: EventEmitter<any> = new EventEmitter();

  acceptedList = [];
  accepted = null;
  showModal = null;
  directoryName = 'quotations';

  constructor(
    private QuotationService: QuotationService,
    private DownloadFileService: DownloadFileService,
    private QuotationMitItemService: QuotationMitItemService,
    private QuotationAcceptedService: QuotationAcceptedService,
    private QuotationProductItemService: QuotationProductItemService,
    private QuotationAcceptedFileService: QuotationAcceptedFileService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService,
    private TransportreceiveItemService: TransportreceiveItemService,
    private UploadService: UploadService,
    
    public utils : QuotationUtilsService
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    this.getAcceptedList();
  }

  async getAcceptedList(){
    await this.QuotationAcceptedService.load(null, {quotation_info_id: this.quotationInfo.quotation_info_id})
    .then(res=>{
      this.acceptedList = res.resultData || [];
    })
    this.sentData();
  }

  sentData(){
    this.done.emit(this.acceptedList);
  }

  openModal(){
    this.showModal = true;
  }

  closeModal(event: boolean){
    this.showModal = null;
    this.accepted = null;
    setTimeout(async () => {
      await this.getAcceptedList();
    }, 500);
  }

  async saveAccepted(data: any){
    if(data.accept_data){
      let quotationResponse = await this.QuotationService.update({
        ...this.quotationInfo,
        doc_status_id: 'ACCEPTED',
        tax: this.quotationInfo.tax.toString()
      });

      if(quotationResponse.success){
        let QuotationAcceptedResponse = await this.QuotationAcceptedService.create({
          ...data.accept_data,
          accepted_date: moment(data.accept_data.accepted_date).format(),
          quotation_info_id: this.quotationInfo.quotation_info_id
        });
        if(QuotationAcceptedResponse.success){
          data.files.map(async (file: any, index: number)=>{
            var uploadResponse = await this.UploadService.post(file, this.directoryName);
            if(uploadResponse.success){
              let QuotationAcceptedFileResponse = await this.QuotationAcceptedFileService.create({
                accepted_doc_name: uploadResponse.resultData.originalname,
                accepted_doc_path: uploadResponse.resultData.path.toString(),
                quotation_accepted_id: QuotationAcceptedResponse.resultData.quotation_accepted_id,
                order: index
              });
            }
          })
        };
      }
    }
    await this.getAcceptedList();
    this.onSave.emit(true);
    this.showModal = null;
  }

  async editAccepted(data: any){
    if(data.accept_data){
      let QuotationAcceptedResponse = await this.QuotationAcceptedService.update({
        ...data.accept_data,
        accepted_date: moment(data.accept_data.accepted_date).format(),
        quotation_info_id: this.quotationInfo.quotation_info_id
      });
      if(QuotationAcceptedResponse.success){
        data.files.map(async (file: any, index: number)=>{
          if(!file.quotation_accepted_file_id){
            var uploadResponse = await this.UploadService.post(file, this.directoryName);
            if(uploadResponse.success){
              let QuotationAcceptedFileResponse = await this.QuotationAcceptedFileService.create({
                accepted_doc_name: uploadResponse.resultData.originalname,
                accepted_doc_path: uploadResponse.resultData.path.toString(),
                quotation_accepted_id: QuotationAcceptedResponse.resultData.quotation_accepted_id,
                order: index
              });
            }
          }
        })
      }
    }
    setTimeout(async () => {
      await this.getAcceptedList();
    }, 500);
    this.accepted = null;
  }

  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }

  select(accepted){
    this.accepted = accepted;
  }

  async delete(closeModalEl){
    if(this.accepted.quotation_accepted_file_info.length > 0){
      this.accepted.quotation_accepted_file_info.map(async file=>{
        let fileResponse = await this.QuotationAcceptedFileService.delete({quotation_accepted_file_id: file.quotation_accepted_file_id});
      });
    }
    let acceptedResponse = await this.QuotationAcceptedService.delete({quotation_accepted_id: this.accepted.quotation_accepted_id});
    if(acceptedResponse.success){
      await this.getAcceptedList();
    }
    closeModalEl.click();
  }

  // async updateMitItem(v, quotationResponse){
  //   if(v.quotation_mit_item_id){
  //     if(v.transport_receive_item_id){
  //       let transportItemResponse = await this.TransportreceiveItemService.getById({transport_receive_item_id: v.transport_receive_item_id});

  //       let quotationMitItemResponse = await this.QuotationMitItemService.update({
  //         ...v,
  //         unlock_invoice_at: this.unlock(v),
  //         price: this.utils.convertStingToNumber(v.price),
  //         discount: this.utils.convertStingToNumber(v.discount),
  //         quotation_info_doc_no: quotationResponse.resultData.doc_no,
  //         quotation_info_status: quotationResponse.resultData.doc_status_id,

  //         transport_receive_info_id: transportItemResponse.resultData?.transport_receive_id || "",
  //         transport_receive_info_status: transportItemResponse.resultData?.transport_receive_info?.doc_status_id || "",
  //         transport_receive_info_doc_no: transportItemResponse.resultData?.transport_receive_info?.doc_no || "",
  //         transport_receive_item_status: transportItemResponse.resultData.item_status_id || "",
  //       });
  //     }else{
  //       let quotationMitItemResponse = await this.QuotationMitItemService.update({
  //         ...v,
  //         unlock_invoice_at: this.unlock(v),
  //         price: this.utils.convertStingToNumber(v.price),
  //         discount: this.utils.convertStingToNumber(v.discount),
  //         quotation_info_doc_no: quotationResponse.resultData.doc_no,
  //         quotation_info_status: quotationResponse.resultData.doc_status_id,
  //       });
  //     }
  //   }
  // }

  // unlock(v){
  //   if(v.lock_invoice_at){
  //     return new Date();
  //   }else{
  //     return undefined
  //   }
  // }

  // async updateProductItem(v, quotationResponse){
  //   if(v.quotation_item_id){
  //     let quotationProductItemResponse = await this.QuotationProductItemService.update(
  //       {
  //         ...v,
  //         quotation_info_doc_no: quotationResponse.resultData.doc_no,
  //         quotation_info_status: quotationResponse.resultData.doc_status_id,
  //       });
  //   }
  // }
}
