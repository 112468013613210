import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { GETService } from 'src/app/services';
import { 
  AccPaymentService 
} from 'src/app/services/invoice';
import { UserInfoState } from 'src/app/store/user/user.state';

@Component({
  selector: 'app-cheque-list',
  templateUrl: './cheque-list.component.html',
  styleUrls: ['./cheque-list.component.css']
})
export class ChequeListComponent implements OnInit {
  isLoading = true;
  
  payments = [];
  paymentList = [];
  payment = null;

  status: string;
  reset: boolean = false;
  load: Function = this.AccPaymentService.load;
  filter = {};

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;
  constructor(
    private router: Router,
    private AccPaymentService: AccPaymentService,
    private GETService: GETService
  ) { 
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }
  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.filter = { payment_method_id: '73c03238-0774-428c-a4c3-dc767f77a9ca', order_by: 'payment_date:desc'};
  }

  onChangePage(payments: Array<any>) {
    this.payments = payments
    this.isLoading = false;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = { ...this.filter, ...filter };
  }
  searchStatus(status:string = null){
    let condition = {
      doc_status_id: status,
    };
    this.search(condition);
  }
  select(payment: string) {
    this.payment = Object.assign({}, payment);
  }

  submitCheque(response){
    if(response){
      this.doReset(response.success || response.error);
    }
    this.payment = null;
  }
}
