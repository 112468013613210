import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import {
  CustomerActivityService,
  UploadService,
  CustomerActivityFileService,
} from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import moment from "moment";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-customer-activity-create",
  templateUrl: "./customer-activity-create.component.html",
  styleUrls: ["./customer-activity-create.component.css"],
})
export class CustomerActivityCreateComponent implements OnInit {
  environment = environment;
  form: FormGroup;
  submitted = false;
  status = null;
  customer_id = null;
  files = [];
  datetimePicker = null;
  quill = null;
  fileToBeRemove = null;
  @Output() done = new EventEmitter();
  @Input("activity-types") activityTypes: Array<any> = [];
  @ViewChild("closeSidebar") closeSidebar: ElementRef;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private CustomerActivityService: CustomerActivityService,
    private UploadService: UploadService,
    private CustomerActivityFileService: CustomerActivityFileService
  ) {
    this.setForm();
    this.customer_id = this.route.snapshot.paramMap.get("customer_id");
  }
  setForm() {
    this.form = this.formBuilder.group({
      activity_datetime: ["", [Validators.required]],
      customer_activity_type_id: ["", [Validators.required]],
      title: ["", [Validators.required]],
      description: [""],
      location_lat: [""],
      location_lng: [""],
    });
    this.files = [];
    let today = moment().set("second", 0);
    let interval = setInterval(() => {
      if (this.datetimePicker) {
        this.form.controls.activity_datetime.setValue(today.format());
        this.datetimePicker.data("DateTimePicker").date(today);
        this.datetimePicker.change();

        clearInterval(interval);
      }
    }, 100);

    if (this.quill) {
      this.quill.setText("");
    }
  }
  ngOnInit(): void {}

  async submit() {
    this.submitted = true;
    // console.log(this.form.value);
    if (this.form.invalid) {
      return;
    }

    let result = await this.CustomerActivityService.create({
      ...this.form.value,
      customer_id: this.customer_id,
    });

    if (result.success) {
      for (let file of this.files) {
        await this.CustomerActivityFileService.create({
          customer_activities_id: result.resultData.customer_activities_id,
          file_name: file.file_name,
          file_path: file.file_path,
        });
      }

      this.done.emit();
      this.closeSidebar.nativeElement.click();
    }
  }

  async uploadFile(e) {
    let files = Array.from(e.target.files);
    for (let file of files) {
      let result = await this.UploadService.post(file, 'customers');
      if (result.success) {
        this.files.push({
          file_name: result.resultData.originalname,
          file_path: result.resultData.path,
        });
      }
    }
    e.target.value = null;
  }

  setLocation(location) {
    this.form.controls.location_lat.setValue(String(location.lat));
    this.form.controls.location_lng.setValue(String(location.lng));
  }

  async removeFile(closeModal) {
    if (this.fileToBeRemove.customer_activities_file_id) {
      let res = await this.CustomerActivityFileService.delete({
        customer_activities_file_id: this.fileToBeRemove
          .customer_activities_file_id,
      });
      if (res.success) {
        this.files = this.files.filter(
          (f) => f.file_path !== this.fileToBeRemove.file_path
        );
      }
    } else {
      this.files = this.files.filter(
        (f) => f.file_path !== this.fileToBeRemove.file_path
      );
    }
    closeModal.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
      } else {
        this.setForm();
      }
    });
  }
}
