import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationMitItemService {
  async create({
    quotation_info_id,
    transport_receive_item_id,
    mit_equipment_id,
    mit_scope_id,
    service_location,
    original_price,
    scope_name, 
    equipment_name, 
    note_1, 
    note_2,
    price,
    discount,
    marker,
    description,
    model,
    serial_no,
    tag_no,
    due_month,
    ready_to_billing,
    acc_debit_note_info_id,
    order_by,
    child_quotation_mit_item_id,
    parent_quotation_mit_item_id,
    add_detail,
    add_marker, 
    add_model, 
    add_serial_no, 
    add_tag_no,
    cus_calibration_point,
    cp_list_init,
    cp_list_cali,
    mit_method_id,
    return_date,
    method_name,
    customer_id,
    transport_receive_info_id,
    transport_receive_info_doc_no,
    transport_receive_item_status,
    transport_receive_info_status,

    job_order_info_id,
    job_order_info_status,
    job_order_info_doc_no,
    job_order_info_type,
    job_order_calibration_item_status,
    job_order_calibration_item_id,
    lock_invoice_at,
    unlock_invoice_at,

    acc_invoice_info_id,
    acc_invoice_info_doc_no,
    acc_invoice_info_status,
    acc_tax_invoice_info_id,
    acc_tax_invoice_info_doc_no,
    acc_tax_invoice_info_status,

    acc_debit_note_info_doc_no,
    acc_debit_note_info_status,
    acc_credit_note_info_id,
    acc_credit_note_info_doc_no,
    acc_credit_note_info_status,

    quotation_info_doc_no,
    quotation_info_status
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-items`, {
        quotation_info_id,
        transport_receive_item_id,
        mit_equipment_id,
        mit_scope_id,
        service_location,
        original_price,
        scope_name, 
        equipment_name, 
        note_1, 
        note_2,
        price: parseFloat(price),
        discount: parseFloat(discount),
        marker: marker.toString(),
        description: description.toString(),
        model: model.toString(),
        serial_no: serial_no.toString(),
        tag_no: tag_no.toString(),
        due_month: due_month.toString(),
        ready_to_billing,
        acc_debit_note_info_id,
        order_by,
        child_quotation_mit_item_id,
        parent_quotation_mit_item_id,
        add_detail: add_detail?.toString() || "", 
        add_marker: add_marker?.toString() || "",  
        add_model: add_model?.toString() || "", 
        add_serial_no: add_serial_no?.toString() || "",  
        add_tag_no: add_tag_no?.toString() || "", 
        cus_calibration_point: cus_calibration_point?.toString() || "",
        cp_list_init,
        cp_list_cali,
        mit_method_id,
        return_date,
        method_name,
        customer_id,
        transport_receive_info_id,
        transport_receive_info_doc_no,
        transport_receive_item_status,
        transport_receive_info_status,

        job_order_info_id,
        job_order_info_status,
        job_order_info_doc_no,
        job_order_info_type,
        job_order_calibration_item_status,
        job_order_calibration_item_id,
        lock_invoice_at,
        unlock_invoice_at,

        acc_invoice_info_id,
        acc_invoice_info_doc_no,
        acc_invoice_info_status,
        acc_tax_invoice_info_id,
        acc_tax_invoice_info_doc_no,
        acc_tax_invoice_info_status,
    
        acc_debit_note_info_doc_no,
        acc_debit_note_info_status,
        acc_credit_note_info_id,
        acc_credit_note_info_doc_no,
        acc_credit_note_info_status,

        quotation_info_doc_no,
        quotation_info_status
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_mit_item_id,
        transport_receive_item_id,
        quotation_info_id,
        mit_equipment_id,
        service_location,
        mit_scope_id,
        original_price,
        scope_name, 
        equipment_name, 
        note_1, 
        note_2,
        price,
        discount,
        marker,
        description,
        model,
        serial_no,
        tag_no,
        due_month,
        ready_to_billing,
        order_by,
        cost_of_sales,
        return_date,
        acc_invoice_info_id,
        return_messenger_id,
        return_transport_type_id,
        acc_debit_note_info_id,
        child_quotation_mit_item_id,
        parent_quotation_mit_item_id,
        add_detail,
        add_marker, 
        add_model, 
        add_serial_no, 
        add_tag_no,
        cus_calibration_point,
        cp_list_init,
        cp_list_cali,
        mit_method_id,
        method_name,
        customer_id,
        can_editable,
        
        transport_receive_info_id,
        transport_receive_info_doc_no,
        transport_receive_item_status,
        transport_receive_info_status,

        job_order_info_id,
        job_order_info_status,
        job_order_info_doc_no,
        job_order_info_type,
        job_order_calibration_item_status,
        job_order_calibration_item_id,

        lock_invoice_at,
        unlock_invoice_at,

        acc_invoice_info_doc_no,
        acc_invoice_info_status,
        acc_tax_invoice_info_id,
        acc_tax_invoice_info_doc_no,
        acc_tax_invoice_info_status,
    
        acc_debit_note_info_doc_no,
        acc_debit_note_info_status,
        acc_credit_note_info_id,
        acc_credit_note_info_doc_no,
        acc_credit_note_info_status,

        quotation_info_doc_no,
        quotation_info_status
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-mit-items/${quotation_mit_item_id}`, {
        quotation_info_id,
        transport_receive_item_id,
        mit_equipment_id,
        mit_scope_id,
        service_location,
        original_price,
        scope_name, 
        equipment_name, 
        note_1, 
        note_2,
        price: parseFloat(price),
        discount: parseFloat(discount),
        marker: marker.toString(),
        description: description.toString(),
        model: model.toString(),
        serial_no: serial_no.toString(),
        tag_no: tag_no.toString(),
        due_month: due_month.toString(),
        order_by,
        ready_to_billing,
        cost_of_sales: cost_of_sales? parseFloat(cost_of_sales): undefined,
        return_date,
        acc_invoice_info_id,
        return_messenger_id,
        return_transport_type_id,
        acc_debit_note_info_id,
        child_quotation_mit_item_id,
        parent_quotation_mit_item_id,
        add_detail: add_detail?.toString() || "", 
        add_marker: add_marker?.toString() || "",  
        add_model: add_model?.toString() || "", 
        add_serial_no: add_serial_no?.toString() || "",  
        add_tag_no: add_tag_no?.toString() || "", 
        cus_calibration_point: cus_calibration_point?.toString() || "",
        cp_list_init,
        cp_list_cali,
        mit_method_id,
        method_name,
        customer_id,
        can_editable,

        transport_receive_info_id,
        transport_receive_info_doc_no,
        transport_receive_item_status,
        transport_receive_info_status,

        job_order_info_id,
        job_order_info_status,
        job_order_info_doc_no,
        job_order_info_type,
        job_order_calibration_item_status,
        job_order_calibration_item_id,

        lock_invoice_at: lock_invoice_at || "null",
        unlock_invoice_at: unlock_invoice_at || "null",
        
        acc_invoice_info_doc_no,
        acc_invoice_info_status,
        acc_tax_invoice_info_id,
        acc_tax_invoice_info_doc_no,
        acc_tax_invoice_info_status,
    
        acc_debit_note_info_doc_no,
        acc_debit_note_info_status,
        acc_credit_note_info_id,
        acc_credit_note_info_doc_no,
        acc_credit_note_info_status,

        quotation_info_doc_no,
        quotation_info_status
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async delete({ quotation_mit_item_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-mit-items/${quotation_mit_item_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-items`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async equipment(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/mit-items`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_mit_item_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-items/${quotation_mit_item_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
