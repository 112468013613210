export * from "./dashboard/sales-teams/sales-team-edit/sales-team-edit.component";
export * from "./dashboard/units/unit-type-edit/unit-type-edit.component";
export * from "./dashboard/units/unit-edit/unit-edit.component";
export * from "./dashboard/products/product-create/product-create.component";
export * from "./dashboard/products/product-range-price-create/product-range-price-create.component";
export * from "./dashboard/products/product-range-price-edit/product-range-price-edit.component";
export * from "./dashboard/scopes/scope-list/scope-list.component";
export * from "./dashboard/scopes/scope-create/scope-create.component";
export * from "./dashboard/scopes/scope-edit/scope-edit.component";
export * from "./dashboard/scopes/method-crud/method-crud.component";
export * from "./dashboard/scopes/equipment-create/equipment-create.component";
export * from "./dashboard/units/unit-create/unit-create.component";
export * from "./dashboard/units/unit-list/unit-list.component";
export * from "./dashboard/sales-teams/sales-team-member-create/sales-team-member-create.component";
export * from "./dashboard/sales-teams/sales-team-list/sales-team-list.component";
export * from "./dashboard/sales-teams/sales-team-create/sales-team-create.component";
export * from "./dashboard/units/unit-type-list/unit-type-list.component";
export * from "./dashboard/units/unit-type-create/unit-type-create.component";
export * from "./dashboard/units/unit-conversion/unit-conversion.component";
export * from "./dashboard/products/product-list/product-list.component";
export * from "./dashboard/products/product-edit/product-edit.component";
export * from "./dashboard/scopes/equipment-list/equipment-list.component";
export * from "./dashboard/scopes/equipment-edit/equipment-edit.component";

export * from "./dashboard/users/user-list/user-list.component";
export * from "./dashboard/users/user-create/user-create.component";
export * from "./dashboard/users/user-edit/user-edit.component";
export * from "./dashboard/users/user-show/user-show.component";
export * from "./dashboard/users/change-password/change-password.component";
export * from "./dashboard/users/user-profile/user-profile.component";
export * from "./dashboard/users/secure-code/secure-code.component";

export * from "./dashboard/customers/customer-list/customer-list.component";
export * from "./dashboard/customers/customer-create/customer-create.component";
export * from "./dashboard/customers/customer-edit/customer-edit.component";
export * from "./dashboard/customers/customer-show/customer-show.component";

export * from "./dashboard/customers/customer-activity/customer-activity.component";
export * from "./dashboard/customers/customer-activity/customer-activity-create/customer-activity-create.component";
export * from "./dashboard/customers/customer-activity/customer-activity-edit/customer-activity-edit.component";
export * from "./dashboard/customers/customer-activity/customer-activity-show/customer-activity-show.component";

export * from "./dashboard/customers/customer-address/customer-address.component";
export * from "./dashboard/customers/customer-address-list/customer-address-list.component";
export * from "./dashboard/customers/customer-address-create/customer-address-create.component";
export * from "./dashboard/customers/customer-address-edit/customer-address-edit.component";

export * from "./dashboard/customers/customer-contact/customer-contact.component";
export * from "./dashboard/customers/customer-contact-create/customer-contact-create.component";
export * from "./dashboard/customers/customer-contact-edit/customer-contact-edit.component";

export * from "./dashboard/customers/customer-marketing/customer-marketing.component";
export * from "./dashboard/customers/customer-revoke/customer-revoke.component";
export * from "./dashboard/customers/customer-quotation/customer-quotation.component";

export * from "./dashboard/my-calendar/my-calendar.component";

export * from "./dashboard/quotation/quotation-list/quotation-list.component";
export * from "./dashboard/quotation/quotation-create/quotation-create.component";
export * from "./dashboard/quotation/quotation-prepare/quotation-prepare.component";
export * from "./dashboard/quotation/quotation-prepare/quotation-item-create/quotation-item-create.component";
export * from "./dashboard/quotation/quotation-prepare/quotation-item-edit/quotation-item-edit.component";

export * from "./dashboard/quotation/quotation-price/quotation-price-create/quotation-price-create.component";

export * from "./dashboard/quotation/quotation-note/quotation-note-create/quotation-note-create.component";
export * from "./dashboard/quotation/quotation-note/quotation-note-edit/quotation-note-edit.component";
export * from "./dashboard/quotation/quotation-note/quotation-note-show/quotation-note-show.component";

export * from "./dashboard/quotation/quotation-show/quotation-show.component";
export * from "./dashboard/quotation/quotation-accept/quotation-accept-list/quotation-accept-list.component";
export * from "./dashboard/quotation/quotation-accept/quotation-accept-create/quotation-accept-create.component";
export * from "./dashboard/quotation/quotation-accept/quotation-accept-edit/quotation-accept-edit.component";
export * from "./dashboard/quotation/quotation-edit/quotation-edit.component";
export * from "./dashboard/quotation/quotation-memo/quotation-memo.component";
export * from "./dashboard/quotation/quotation-prepare/quotation-item-import-create/quotation-item-import-create.component";
export * from "./dashboard/quotation/quotation-confirm-voided/quotation-confirm-voided.component";
export * from "./dashboard/quotation/quotation-pdf/quotation-pdf.component";
export * from "./dashboard/quotation/quotation-pdf/quotation-pdf-public.component";
export * from "./dashboard/quotation/quotation-prepare/quotation-item-log/mit-item-log.component";
export * from "./dashboard/quotation/quotation-prepare/quotation-item-job-order-create/quotation-item-job-order-create.component";
export * from "./dashboard/quotation/quotation-confirm/quotation-confirm.component";
export * from "./dashboard/quotation/quotation-mit/quotation-mit-item/quotation-mit-item.component";

export * from "./dashboard/scopes/method-list/method-list.component";
export * from "./dashboard/scopes/method-create/method-create.component";
export * from "./dashboard/scopes/method-edit/method-edit.component";

export * from "./dashboard/scopes/cp-list/cp-list.component";
export * from "./dashboard/scopes/cp-create/cp-create.component";
export * from "./dashboard/scopes/cp-edit/cp-edit.component";

export * from "./dashboard/notes/note-list/note-list.component";

export * from "./dashboard/transport-receive/transport-receive-list/transport-receive-list.component";
export * from "./dashboard/transport-receive/transport-receive-create/transport-receive-create.component";
export * from "./dashboard/transport-receive/transport-receive-show/transport-receive-show.component";
export * from "./dashboard/transport-receive/transport-receive-edit/transport-receive-edit.component";
export * from "./dashboard/transport-receive/transport-receive-item/transport-receive-item.component";
export * from "./dashboard/transport-receive/transport-receive-memo/transport-receive-memo.component";
export * from "./dashboard/transport-receive/transport-receive-item-create/transport-receive-item-create.component";
export * from "./dashboard/transport-receive/transport-receive-item-create-no-item/transport-receive-item-create-no-item.component";
export * from "./dashboard/transport-receive/transport-receive-create-filter/transport-receive-create-filter.component";
export * from "./dashboard/transport-receive/transport-receive-unlock/transport-receive-unlock.component";
export * from "./dashboard/transport-receive/transport-receive-signature/transport-receive-signature.component";
export * from "./dashboard/transport-receive/transport-cancel/transport-cancel.component";
export * from "./dashboard/transport-receive/transport-receive-item/receive-item-edit/receive-item-edit.component";
export * from "./dashboard/transport-receive/transport-receive-item/add-quotation-item/add-quotation-item.component";
export * from "./dashboard/transport-receive/transport-receive-item/receive-item-edit-all/receive-item-edit-all.component";
export * from "./dashboard/transport-receive/transport-pdf/transport-pdf.component";

export * from "./dashboard/work-orders/work-order-list/work-order-list.component";
export * from "./dashboard/work-orders/work-order-create-filter/work-order-create-filter.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-edit/work-order-lab-edit.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-show/work-order-lab-show.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-create/work-order-lab-create.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-item/work-order-lab-item.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-item-create/work-order-lab-item-create.component";

export * from "./dashboard/work-orders/work-order-non-lab/work-order-non-lab-edit/work-order-non-lab-edit.component";
export * from "./dashboard/work-orders/work-order-non-lab/work-order-non-lab-create/work-order-non-lab-create.component";
export * from "./dashboard/work-orders/work-order-non-lab/work-order-non-lab-show/work-order-non-lab-show.component";
export * from "./dashboard/work-orders/work-order-non-lab/work-order-non-lab-item/work-order-non-lab-item.component";
export * from "./dashboard/work-orders/work-order-non-lab/work-order-non-lab-item-create/work-order-non-lab-item-create.component";

export * from "./dashboard/work-orders/work-order-memo/work-order-memo.component";
export * from "./dashboard/work-orders/training-engineer/training-engineer.component";
export * from "./dashboard/work-orders/worker-engineer/worker-engineer.component";
export * from "./dashboard/work-orders/car-info/car-info.component";

export * from "./dashboard/work-orders/work-order-lab/work-order-lab-item/save-calibration-item/save-calibration-item.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-item/confirm-calibration-item/confirm-calibration-item.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-item/reject-calibration-item/reject-calibration-item.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-item/edit-quotation-mit-item/edit-quotation-mit-item.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-item/upload-cert-item/upload-cert.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-import/work-order-lab-import.component";
export * from "./dashboard/work-orders/work-order-lab/work-order-lab-verify/work-order-lab-verify.component";

export * from "./dashboard/work-orders/work-order-pdf/ecl-ecs/ecl-ecs.component";
export * from "./dashboard/work-orders/work-order-pdf/icl-ics/icl-ics.component";
export * from "./dashboard/work-orders/work-order-pdf/prd/prd.component";
export * from "./dashboard/work-orders/work-order-pdf/service-report/service-report.component";
export * from "./dashboard/work-orders/work-order-pdf/confirmation-letter/confirmation-letter.component";

export * from "./dashboard/acc-document/acc-pdf/billing-note/billing-note.component";
export * from "./dashboard/acc-document/acc-pdf/invoice/invoice.component";
export * from "./dashboard/acc-document/acc-pdf/received-voucher/received-voucher.component";
export * from "./dashboard/acc-document/acc-pdf/tax-invoice/tax-invoice.component";
export * from "./dashboard/acc-document/acc-pdf/credit-note/credit-note.component";

export * from "./dashboard/acc-document/invoice/invoice-create/invoice-create.component";
export * from "./dashboard/acc-document/invoice/invoice-edit/invoice-edit.component";
export * from "./dashboard/acc-document/invoice/invoice-show/invoice-show.component";
export * from "./dashboard/acc-document/invoice/invoice-list/invoice-list.component";
export * from "./dashboard/acc-document/invoice/invoice-create-filter/invoice-create-filter.component";
export * from "./dashboard/acc-document/invoice/invoice-item/invoice-item.component";
export * from "./dashboard/acc-document/invoice/invoice-item-create/invoice-item-create.component";
export * from "./dashboard/acc-document/invoice/invoice-history/invoice-history.component";
export * from "./dashboard/acc-document/invoice/invoice-payment/invoice-payment.component";
export * from "./dashboard/acc-document/invoice/invoice-payment-create/invoice-payment-create.component";
export * from "./dashboard/acc-document/invoice/invoice-show/invoice-show.component";
export * from "./dashboard/acc-document/invoice/invoice-payment-show/invoice-payment-show.component";
export * from "./dashboard/acc-document/invoice/invoice-po-item/invoice-po-item.component";

export * from "./dashboard/acc-document/billing-note/billing-note-list/billing-note-list.component";
export * from "./dashboard/acc-document/billing-note/billing-note-create/billing-note-create.component";
export * from "./dashboard/acc-document/billing-note/billing-note-edit/billing-note-edit.component";
export * from "./dashboard/acc-document/billing-note/billing-note-show/billing-note-show.component";
export * from "./dashboard/acc-document/billing-note/billing-note-item/billing-note-item.component";
export * from "./dashboard/acc-document/billing-note/billing-note-history/billing-note-history.component";
export * from "./dashboard/acc-document/billing-note/billing-note-item-create/billing-note-item-create.component";
export * from "./dashboard/acc-document/billing-note/billing-note-item-history/billing-note-item-history.component";

export * from "./dashboard/acc-document/received-voucher/received-voucher-create/received-voucher-create.component";
export * from "./dashboard/acc-document/received-voucher/received-voucher-edit/received-voucher-edit.component";
export * from "./dashboard/acc-document/received-voucher/received-voucher-show/received-voucher-show.component";
export * from "./dashboard/acc-document/received-voucher/received-voucher-history/received-voucher-history.component";
export * from "./dashboard/acc-document/received-voucher/received-voucher-item/received-voucher-item.component";
export * from "./dashboard/acc-document/received-voucher/received-voucher-item-create/received-voucher-item-create.component";
export * from "./dashboard/acc-document/received-voucher/received-voucher-list/received-voucher-list.component";

export * from "./dashboard/acc-document/payment/payment-list/payment-list.component";
export * from "./dashboard/acc-document/payment/cheque-list/cheque-list.component";
export * from "./dashboard/acc-document/payment/cheque-edit/cheque-edit.component";

export * from "./dashboard/acc-document/tax-invoice/tax-invoice-create/tax-invoice-create.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-edit/tax-invoice-edit.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-list/tax-invoice-list.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-show/tax-invoice-show.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-item/tax-invoice-item.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-item-create/tax-invoice-item-create.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-history/tax-invoice-history.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-payment/tax-invoice-payment.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-payment-create/tax-invoice-payment-create.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-payment-show/tax-invoice-payment-show.component";

export * from "./dashboard/acc-document/billing-note/billing-note-item-tax-create/billing-note-item-tax-create.component";
export * from "./dashboard/acc-document/received-voucher/received-voucher-item-tax-create/received-voucher-item-tax-create.component";

export * from "./dashboard/equipment/equipment/equipment.component";
export * from "./dashboard/equipment/equipment-unlock/equipment-unlock.component";

export * from "./dashboard/cars/car-list/car-list.component";
export * from "./dashboard/cars/car-edit/car-edit.component";
export * from "./dashboard/cars/car-create/car-create.component";

export * from "./dashboard/billing/billing-list/billing-list.component";
export * from "./dashboard/billing/billing-create/billing-create.component";

export * from "./dashboard/delivery/delivery-list/delivery-list.component";
export * from "./dashboard/delivery/delivery-cert-history/delivery-cert-history.component";
export * from "./dashboard/delivery/delivery-items-history/delivery-items-history.component";

export * from "./dashboard/acc-document/credit-note/credit-note-show/credit-note-show.component";
export * from "./dashboard/acc-document/credit-note/credit-note-edit/credit-note-edit.component";
export * from "./dashboard/acc-document/credit-note/credit-note-create/credit-note-create.component";
export * from "./dashboard/acc-document/credit-note/credit-note-item/credit-note-item.component";
export * from "./dashboard/acc-document/credit-note/credit-note-list/credit-note-list.component";
export * from "./dashboard/acc-document/credit-note/credit-note-item-create/credit-note-item-create.component";
export * from "./dashboard/acc-document/credit-note/credit-note-create-filter/credit-note-create-filter.component";
export * from "./dashboard/acc-document/credit-note/credit-note-item-history/credit-note-item-history.component";

export * from "./dashboard/acc-document/billing-note/billing-note-item-credit-create/billing-note-item-credit-create.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-item/tax-invoice-item.component";

export * from "./dashboard/acc-document/debit-note/debit-note-create/debit-note-create.component";
export * from "./dashboard/acc-document/debit-note/debit-note-create-filter/debit-note-create-filter.component";
export * from "./dashboard/acc-document/debit-note/debit-note-edit/debit-note-edit.component";
export * from "./dashboard/acc-document/debit-note/debit-note-item/debit-note-item.component";
export * from "./dashboard/acc-document/debit-note/debit-note-item-create/debit-note-item-create.component";
export * from "./dashboard/acc-document/debit-note/debit-note-item-history/debit-note-item-history.component";
export * from "./dashboard/acc-document/debit-note/debit-note-list/debit-note-list.component";
export * from "./dashboard/acc-document/debit-note/debit-note-show/debit-note-show.component";

export * from "./dashboard/delivery/delivery-job-order-history/delivery-job-order-history.component";
export * from "./dashboard/delivery/delivery-passenger-create/delivery-passenger-create.component";

export * from "./dashboard/work-orders/work-order-return/work-order-return.component";
export * from "./dashboard/transport-receive/transport-receive-item/return-item-edit/return-item-edit.component";
export * from "./dashboard/transport-receive/transport-receive-return-signature/transport-receive-return-signature.component";

export * from "./dashboard/work-orders/work-order-pdf/onsite/onsite.component";
export * from "./dashboard/work-orders/work-order-pdf/product/product.component";
export * from "./dashboard/acc-document/bill-cheque-list/bill-cheque-list.component";
export * from "./dashboard/acc-document/bill-cheque-list/bill-update-sent-info/bill-update-sent-info.component";
export * from "./dashboard/work-orders/work-order-pdf/address-cert/address-cert.component";
export * from "./dashboard/acc-document/tax-invoice/tax-invoice-credit-item/tax-invoice-credit-item.component";

export * from "./dashboard/reports/report-list/report-list.component";
export * from "./dashboard/reports/report-create/report-create.component";
export * from "./dashboard/equipment/equipment-mapping-filter/equipment-mapping-filter.component";
export * from "./dashboard/quotation/quotation-prepare/quotation-item-mapping/quotation-item-mapping.component";

export * from "./dashboard/pre-booking/pre-booking-create/pre-booking-create.component";
export * from "./dashboard/pre-booking/pre-booking-edit/pre-booking-edit.component";
export * from "./dashboard/pre-booking/pre-booking-list/pre-booking-list.component";
export * from "./dashboard/pre-booking/master-booking-list/master-booking-list.component";
export * from "./dashboard/pre-booking/master-booking-edit/master-booking-edit.component";
export * from "./dashboard/pre-booking/master-booking-status/master-booking-status.component";

export * from "./dashboard/standard/standard-list/standard-list.component";
export * from "./dashboard/standard/standard-create/standard-create.component";
export * from "./dashboard/standard/standard-edit/standard-edit.component";

export * from "./dashboard/email-template-attachment/email-template-attachment-list/email-template-attachment-list.component";
