<div class="card">
    <div class="card-content collapse show">
        <div class="card-body">
            <app-response-status *ngIf="status" [status]="status"></app-response-status>
            
            <p>บันทึกภายใน</p>
            <div class="row" autocomplete="off" role="form" action="" method="post" [formGroup]="form">
                <div class="col-md-6 col-sm-12">
                    <div class="card box-shadow-0 border-lighten-3 rounded bg-transparent mb-0" [ngClass]="(form.get('memo').errors && submitted)?'border-danger':'border-blue-grey'">
                        <div class="card-content collapse show">
                            <div class="card-body p-0">
                                <label for="memo" class="sr-only">บันทึกภายใน</label>
                                <textarea
                                  id="memo"
                                  class="form-control border-0"
                                  rows="3"
                                  formControlName="memo"
                                  style="resize: none;">
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <div class="pt-0 mt-0 text-danger pl-2" *ngIf="form.get('memo').errors && submitted">
                      โปรดระบุ
                    </div>
                </div>

                <div class="col-md-6 col-sm-12">
                </div>
                <div class="col-md-6 col-sm-12 text-right">
                  <label type="button" class="btn btn-primary btn-min-width mt-1" (click)="save()">
                      บันทึก
                  </label>
              </div>
            </div>
            <loading *ngIf="pageLoad"></loading>
            
            <div class="row" *ngIf="!pageLoad">
              <div class="col-12">
                <section id="timeline" class="timeline-center timeline-wrapper mt-1">
                  <ul class="timeline" [class]="{ 'no-icon': !memo.job_order_memo_id }" 
                    *ngFor="let memo of memoList;">
                  <li class="timeline-line bg-primary"></li>
                  <li class="timeline-item pt-2 pb-0" style="width: 30%;">
                      <div class="timeline-card">
                        <div class="card-content">
                            <div class="card-body">
                            <h5>{{memo.created_at | thai_date: "date" | dash }}</h5>
                            </div>
                        </div>
                      </div>
                  </li>
                  <li class="timeline-item pb-0" style="width: 70%;">
                      <div class="timeline-badge">
                          <span class="border-primary bg-white">
                            <i
                              class="fa fa-comments text-primary"
                              *ngIf="memo.job_order_memo_id"
                            ></i>
                          </span>
                        </div>
                      <div
                      class="timeline-card card bg-blue-grey bg-lighten-5 w-50"
                      *ngIf="memo.job_order_memo_id">
                      <div class="card-content">
                          <div class="card-body">
                          <h5>
                              {{memo.created_by_info?.firstname_th}} {{memo.created_by_info?.lastname_th}}
                          </h5>
                          <p class="blue-grey mb-0">
                            {{memo.memo}}
                          </p>
                          </div>
                      </div>
                      </div>
                        <h5 class="mt-1" *ngIf="!memo.job_order_memo_id">
                                {{memo.created_by_info?.firstname_th}} 
                                {{memo.created_by_info?.lastname_th}} 
                                {{memo.event_text?memo.event_text: 
                                    memo.document_event_info?.document_event_name_th}}
                        </h5>
                     
                  </li>
                  </ul>
                </section>
              </div>
            </div>
        </div>
    </div>
</div>