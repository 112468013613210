import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
  NgZone,
} from "@angular/core";
import { 
  NgSelect2Customer
} from '../../../../services/quotations';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

@Component({
  selector: 'app-equipment-mapping-filter',
  templateUrl: './equipment-mapping-filter.component.html',
  styleUrls: ['./equipment-mapping-filter.component.css']
})
export class EquipmentMappingFilterComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") private closeModal: ElementRef;
  @Input() id = "modalMappingFilter";
  @Input() item = null;

  select2OptionQuotation = null;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private NgSelect2Customer: NgSelect2Customer,
  ) {
    this.setForm();
  }

  ngOnInit(): void {
    if(this.item.customer_info.customer_id){
      this.select2OptionQuotation = this.NgSelect2Customer.selectQuotations(`?limit=20&&doc_status_id=REVISE&&customer_id=${this.item.customer_info.customer_id}&&order_by=doc_no:desc`);
    }
  }

  setForm(){
    this.form = this.formBuilder.group({
      quotation_info_id: ["", Validators.required]
    });

  }

  async onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.closeModal.nativeElement.click();

    this.router.navigateByUrl("/quotation/"+ this.form.value.quotation_info_id+"/edit?type=mapping");
  }

  onClose() {
    this.close.emit(null);
  }
}
