import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  NgZone
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NavigationStart, Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import { NgSelect2Customer } from "src/app/services/quotations";

import { UserInfoState } from "src/app/store/user/user.state";

@Component({
  selector: "app-work-order-create-filter",
  templateUrl: "./work-order-create-filter.component.html",
  styleUrls: ["./work-order-create-filter.component.css"],
})
export class WorkOrderCreateFilterComponent implements OnInit {
  form: FormGroup;

  submitted: boolean = false;
  reset: boolean = false;

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  select2OptionCustomer = null;

  templateList = [
    { id: "ICL", text: "Code: [ICL] : Internal calibration (Lab)"},
    { id: "ECL", text: "Code: [ECL] : External calibration (Lab)" },
    { id: "ICS", text: "Code: [ICS] : Internal calibration (On-site)" },
    { id: "ECS", text: "Code: [ECS] : External calibration (On-site)" },
    { id: "TRN", text: "Code: [TRN] : Training" },
    { id: "PRD", text: "Code: [PRD] : Product" },
  ];
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;

  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private NgSelect2Customer: NgSelect2Customer,
    private router: Router
  ) {
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === "popstate") {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }

  async ngOnInit() {}

  setForm() {
    this.form = this.formBuilder.group({
      customer_id: ["", [Validators.required]],
      template: ["", [Validators.required]],
    });
    this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;


      if(userInfo?.is_sales_supervisor || userInfo?.is_co_sales_supervisor || userInfo?.is_co_sales || userInfo?.is_sales_manager){
        this.select2OptionCustomer = this.NgSelect2Customer.select2Options("?limit=10&&exclude_customer_state_id=Free&&is_exclude_address=true");
      }else{
        this.select2OptionCustomer = this.NgSelect2Customer.select2Options("?limit=10&&exclude_customer_state_id='Free'&&is_exclude_address=true&&sales_assignment_info.user_id=" + userInfo.user_id);
      }
    });
    this.reset = false;
    setTimeout(() => {
      this.reset = true;
    }, 50);
  }

  select(event, type: string) {}

  selectTemplate(event) {
    this.form.get("template").setValue(event);
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    this.closeModalBtn.nativeElement.click();
    if(this.form.value.template == "TRN" || this.form.value.template == "PRD"){
      this.router.navigateByUrl("/work-order/non-calibration/"+ (this.form.value.customer_id || this.form.value.receive_info_id) +"/create?template=" +this.form.value.template);
    }else{
      this.router.navigateByUrl("/work-order/calibration/"+ (this.form.value.customer_id || this.form.value.receive_info_id) +"/create?template=" +this.form.value.template);
    }
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }
}
