import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { UnitService, UnitLabelService, ResponseStatusService } from "src/app/services";
import { ENSignValidator } from "src/app/validators";

@Component({
  selector: "app-unit-edit",
  templateUrl: "./unit-edit.component.html",
  styleUrls: ["./unit-edit.component.css"],
})
export class UnitEditComponent {
  form: FormGroup;
  addMoreForm: FormGroup;
  submitted = false;

  removeList = [];
  removeIndex: number;
  unit_labels = [];
  unit_label: string;

  quill = null;
  quillAddMore = null;
  addMoreSubmitted = false;

  @Input("unit-types") unitTypes = [];
  @Input() unit;
  @Output() done = new EventEmitter();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private UnitService: UnitService,
    private UnitLabelService: UnitLabelService,
    private ResponseStatusService: ResponseStatusService
  ) {
    this.setForm();
  }

  setForm(): void {
    this.addMoreForm = this.formBuilder.group({
      unit_short_name_quill_add_more: ["", [ENSignValidator()]],
    });

    this.form = this.formBuilder.group({
      unit_type_id: ["", Validators.required],
      unit_name_en: ["", [Validators.required]],
      unit_name_th: [""],
      unit_short_name_quill: ["", [Validators.required, ENSignValidator()]],
      unit_short_name_en: ["", [Validators.required]],
      unit_short_name_th: [""],
      unit_description: [""],
    });

    this.removeList = [];
  }

  async init() {
    this.submitted = false;
    this.addMoreSubmitted = false;

    if (this.unit) {
      for (let key in this.form.value) {
        this.form.controls[key].setValue(this.unit[key]);
      }

      this.unit_labels = this.unit.unit_label_info.slice(0) || [];
      if (!this.unit_labels.length) {
        this.unit_labels.push({
          unit_label: "",
        });
      }

      // quill
      let interval = setInterval(() => {
        if (this.quill) {
          const delta = this.quill.clipboard.convert(
            this.form.value.unit_short_name_en
          );
          this.quill.setContents(delta, "silent");

          this.form.controls.unit_short_name_quill.setValue(
            this.quill.getText().replace(/\n$/, "")
          );
          clearInterval(interval);
        }

        if (this.quillAddMore) {
          this.quillAddMore.setText("");
        }
      }, 100);
    }
  }

  async submit() {
    this.submitted = true;
    this.setLabel(this.addMoreForm.value.unit_short_name_quill_add_more);

    if (
      !this.addMoreForm.value.unit_short_name_quill_add_more &&
      !this.unit_labels.length
    ) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    let data = {
      ...this.form.value,
      unit_id: this.unit.unit_id,
      unit_name_th: this.form.value.unit_name_en,
      unit_short_name_th: this.form.value.unit_short_name_en,
    };
    let response = await this.UnitService.update(data);
    let status = response.error || response.success;
    
    for (let unit_label of this.removeList) {
      if (unit_label.unit_label_id) {
        await this.UnitLabelService.delete({
          unit_label_id: unit_label.unit_label_id,
        });
      }
    }

    let remain_unit_labels = this.unit_labels.filter(
      (unit_label) =>
        !this.removeList
          .map((v) => v.unit_label_id)
          .includes(unit_label.unit_label_id)
    );

    let updated = remain_unit_labels.filter((unit) => unit.unit_label_id);
    for (let unit_label of updated) {
      await this.UnitLabelService.update({
        unit_id: this.unit.unit_id,
        ...unit_label,
      });
    }

    let added = remain_unit_labels.filter(
      (unit_label) => !unit_label.unit_label_id
    )
    added = added.filter((unit) => unit.unit_label)
    for (let unit_label of added) {
      let res = await this.UnitLabelService.create({
        unit_id: this.unit.unit_id,
        unit_label: unit_label.unit_label,
      })

      if (res.error && res.error.resultCode === "40900") {
        status = this.ResponseStatusService.getResponseStatus("40902")
        break
      }
    }

    // if (this.unit_label) {
    //   let res = await this.UnitLabelService.create({
    //     unit_id: this.unit.unit_id,
    //     unit_label: this.unit_label,
    //   });
    // }

    this.done.emit(status);
    this.closeModalBtn.nativeElement.click();
  }

  async delete(closeModalEl) {
    if (this.removeIndex >= 0) {
      let removeEl = this.unit_labels[this.removeIndex];
      if (removeEl) {
        this.removeList.push(this.unit_labels.slice(0)[this.removeIndex]);

        this.unit_labels = this.unit_labels.filter(
          (v, index) => index !== this.removeIndex
        );
        // this.form.controls.unit_labels.setValue(this.unit_labels);
        this.removeIndex = null;
      }
    }

    if (!this.unit_labels.length) {
      this.unit_labels.push({
        unit_label: "",
      });
    }
    closeModalEl.click();
  }

  addMore() {
    this.unit_labels.push({
      unit_label: this.unit_label,
    });

    // this.addMoreSubmitted = true;
    // if (this.addMoreForm.invalid) {
    //   return;
    // }
    // if (this.unit_label) {
    //   this.unit_labels.push({
    //     unit_label: this.unit_label,
    //   });
    //   // this.form.controls.unit_labels.setValue(this.unit_labels);
    //   if (this.quillAddMore) {
    //     this.quillAddMore.setText("");
    //   }
    //   this.unit_label = "";
    //   this.setLabel(this.addMoreForm.value.unit_short_name_quill_add_more);
    // }
    // this.addMoreSubmitted = false;
  }

  select(index: number) {
    this.removeIndex = index;
  }

  setLabel(unit_label) {
    unit_label = unit_label.trim();
    this.addMoreForm.controls.unit_short_name_quill_add_more.setValue(
      unit_label
    );
    if (this.unit_labels.length) {
      if (unit_label && unit_label.length) {
        this.addMoreForm.controls.unit_short_name_quill_add_more.setValidators([
          ENSignValidator(),
        ]);
      } else {
        this.addMoreForm.controls.unit_short_name_quill_add_more.setValidators(
          []
        );
      }

      this.addMoreForm.controls.unit_short_name_quill_add_more.updateValueAndValidity();
    } else {
      this.addMoreForm.controls.unit_short_name_quill_add_more.setValidators([
        Validators.required,
        ENSignValidator(),
      ]);
      this.addMoreForm.controls.unit_short_name_quill_add_more.updateValueAndValidity();
    }
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.setForm();
      } else {
        this.init();
      }
    });
  }
}
