<!-- BEGIN: Header-->
<nav
  class="header-navbar navbar-expand-lg navbar navbar-with-menu fixed-top navbar-semi-dark"
>
  <div class="navbar-wrapper">
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mobile-menu d-lg-none mr-auto">
          <a class="nav-link nav-menu-main menu-toggle hidden-xs" href="#"
            ><i class="feather icon-menu font-large-1"></i
          ></a>
        </li>
        <li class="nav-item mr-auto">
          <a
            class="navbar-brand"
            href="/assets/html/ltr/vertical-modern-menu-template/index.html"
            [routerLink]="['/dashoard']"
          >
            <!-- <img
              class="brand-logo"
              alt="stack admin logo"
              src="/assets/app-assets/images/logo/stack-logo-light.png"
            /> -->
            <h2 class="brand-text">{{ title }}</h2>
          </a>
        </li>
        <!-- <li class="nav-item d-none d-lg-block float-right">
          <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse"
            ><i
              class="toggle-icon feather icon-toggle-right font-medium-3 white"
              data-ticon="feather.icon-toggle-right"
            ></i
          ></a>
        </li> -->
        <li class="nav-item d-lg-none">
          <a
            class="nav-link open-navbar-container"
            data-toggle="collapse"
            data-target="#navbar-mobile"
            ><i class="fa fa-ellipsis-v"></i
          ></a>
        </li>
      </ul>
    </div>
    <div class="navbar-container content">
      <div class="collapse navbar-collapse" id="navbar-mobile">
        <ul class="nav navbar-nav float-right ml-auto">
          <li class="dropdown dropdown-user nav-item">
            <a
              class="dropdown-toggle nav-link dropdown-user-link"
              href="#"
              data-toggle="dropdown"
            >
              <div class="avatar">
                <!-- <img
                  src="/assets/app-assets/images/portrait/small/avatar-s-1.png"
                  alt="avatar"
                /><i></i> -->
              </div>
              <span class="user-name" *ngIf="(userInfo$ | async)">
                <!-- {{ userInfo$ | async | json }} -->

                {{ (userInfo$ | async)?.firstname_en }}
                {{ (userInfo$ | async)?.lastname_en }} 
                <span *ngIf="(userInfo$ | async)?.emp_info?.emp_level_info">
                  [({{ (userInfo$ | async)?.emp_info?.emp_level_info?.emp_level_name_en }}) 
                  {{(userInfo$ | async)?.emp_info?.emp_position_info?.emp_position_name_en}}]
                </span>
                </span
              >
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a
              class="dropdown-item"
              href="#"
              [routerLink]="['/users/user-profile']"
              ><i class="feather icon-user"></i> ข้อมูลของฉัน</a
              >
              <a
                class="dropdown-item"
                href="#"
                [routerLink]="['/users/change-password']"
                ><i class="feather icon-lock"></i> เปลี่ยนรหัสผ่าน</a
              >
              <a
                class="dropdown-item"
                href="#"
                style="font-weight: 500;"
                [routerLink]="['/users/secure-code']"
                ><i class="feather icon-lock"></i> Secure Code</a
              >
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()"
                ><i class="feather icon-power"></i> ออกจากระบบ</a
              >
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</nav>
<!-- END: Header-->
