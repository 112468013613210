
<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการรอเรียกเก็บ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/waiting-for-charge']">รอเรียกเก็บ</a>
                </li>
                <li class="breadcrumb-item active">รายการรอเรียกเก็บ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <a href="#" [routerLink]="['/waiting-for-charge']" class="btn btn-light btn-min-width ml-1">
              <i class="fa fa-arrow-left"></i> กลับ
            </a>
          </div>
        </div>
        <div class="content-header-right col-md-12 col-12 mb-md-0 mb-2">

            <section class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <h4 class="card-title mt-1 col-12 col-sm-4 col-md-6 col-lg-9">[{{customerInfo?.customer_code}}] {{customerInfo?.company_name}}</h4>
                      </div>

                    </div>
        
                    <div class="card-content collapse show">
                      <div class="card-body">
                          <div class="row justify-content-between mb-1">
                            <span class="col-12 col-sm-8 col-md-6 col-lg-3">
                                <button class="btn btn-outline-primary" (click)="createInvoice()">
                                    <i class="feather icon-plus-circle icon-left"></i> สร้างใบแจ้งหนี้ ({{selectedCount}})
                                </button>
                            </span>
                            <span class="col-12 col-sm-8 col-md-6 col-lg-3 text-right">
                                <search-box (search)="search($event)" [placeholder]="'ค้นหาจากใบเสนอราคา/เลขที่ PO'"></search-box>
                            </span>
                          </div>
                          <div class="table-responsive">
                              <table class="table table-striped table-bordered">
                                  <thead>
                                      <tr>
                                        <th>
                                          <span>
                                            <input
                                              *ngIf="isShowCheckAll"
                                              type="checkbox"
                                              id="select_all"
                                              (change)="onCheckAll($event)"
                                            >
                                          </span>
                                        </th>
                                        <th>ใบเสนอราคา</th>
                                        <th>เลขที่ PO</th>
                                        <th>ชื่อสินค้า</th>
                                        <th>Serial No./Tag No.</th>
                                        <th class="text-right">ราคา (บาท)</th>
                                        <th>พนักงานขาย</th>
                                        <th>วันที่ส่งคืนเครื่อง</th>
                                        <th>วันที่ส่งคืน Cert</th>
                                      </tr>
                                  </thead>

                                  <tbody>
                                    <tr *ngFor="let item of billing; let i = index;">
                                        <td>
                                          <div [ngSwitch]="item.item_type">
                                            <span *ngSwitchCase="'product'">
                                              <input 
                                              type="checkbox"
                                              *ngIf="(!item?.lock_invoice_at || item?.unlock_invoice_at || item?.product_title.startsWith('Transport')) && !item.lock"
                                              [id]="'select_'+i"
                                              [(ngModel)]="item.select"
                                              [ngModelOptions]="{ standalone: true }"
                                              (click)="setCount()"
                                              >
                                              <span *ngIf="(item?.lock_invoice_at && !item?.unlock_invoice_at && !item?.product_title.startsWith('Transport')) || item.lock">
                                                <i class="fa fa-lock fa-lg" aria-hidden="true"></i>
                                              </span>
                                            </span>

                                            <span *ngSwitchCase="'calibration_service'">
                                              <input 
                                              type="checkbox"
                                              *ngIf="(!item?.lock_invoice_at || item?.unlock_invoice_at) && !item.lock"
                                              [id]="'select_'+i"
                                              [(ngModel)]="item.select"
                                              [ngModelOptions]="{ standalone: true }"
                                              (click)="setCount()"
                                              >
                                              <span *ngIf="(item?.lock_invoice_at && !item?.unlock_invoice_at) || item.lock">
                                                <i class="fa fa-lock fa-lg" aria-hidden="true"></i>
                                              </span>
                                            </span>
                                          </div>
                                         
                                        </td>
                                        <td class="text-nowrap">
                                            <div [ngSwitch]="item.item_type">
                                                <span *ngSwitchCase="'product'">{{item.quotation_info?.doc_no}}</span>
                                                <span *ngSwitchCase="'calibration_service'">{{item.quotation_info?.doc_no}}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span class="custom-bullet" *ngFor="let po of item.quotation_info.quotation_accepted_info;">
                                              {{po.accepted_po_no}}
                                              <span *ngFor="let file of po.quotation_accepted_file_info;; let last = last;">
                                               <span class="text-primary" style="cursor: pointer;" 
                                                (click)="downloadFile({file_path: file.accepted_doc_path,file_name: file.accepted_doc_name})">
                                                    {{file.accepted_doc_name}} 
                                                    <span *ngIf="!last">, </span>
                                                </span>
                                              </span>
                                            </span>
                                        </td>
                                        <td>
                                            <div [ngSwitch]="item.item_type">
                                                <span *ngSwitchCase="'product'">{{item.product_title}}</span>
                                                <span *ngSwitchCase="'calibration_service'">{{item.scope_name}}: {{item.equipment_name}}</span>
                                              </div>
                                        </td>
                                        <td>
                                          <div [ngSwitch]="item.item_type">
                                            <span *ngSwitchCase="'product'"></span>
                                            <span *ngSwitchCase="'calibration_service'">{{item.serial_no}}/{{item.tag_no}}</span>
                                          </div>
                                        </td>
                                        <td class="text-right">
                                            <div *ngIf="item.item_type=='calibration_service'">
                                              {{calculate.sumMit(item) | number : '1.2-2'}}
                                          </div>
                                          <div *ngIf="item.item_type=='product'">
                                              {{calculate.sumProduct(item) | number : '1.2-2'}}
                                          </div>
                                        </td>
                                        <td class="text-nowrap">
                                          <div>
                                            {{item.quotation_info?.sale_info?.firstname_th}} {{item.quotation_info?.sale_info?.lastname_th}}
                                          </div>
                                          <small>เมื่อวันที่ {{item.ready_to_billing | thai_date: "short":"date" | dash}}</small>
                                        </td>
                                        <td class="text-nowrap">{{item?.real_return_date | thai_date: "short":"date" | dash}}</td>
                                        <td class="text-nowrap">{{item?.real_cert_return_date | thai_date: "short":"date" | dash}}</td>

                                    </tr>
                                    <tr *ngIf="billing.length === 0">
                                        <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
                                    </tr>
                                </tbody>
                              </table>
                          </div>
        
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        </div>
      </div>
    </div>
</div>
