import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { Router } from "@angular/router";
import { JobOrderCertReturnHistoryService, JobOrderInfoService, JobOrderReturnHistoryService } from "src/app/services/job-orders";
import { QuotationMitCertHistoryService, QuotationMitItemService, QuotationMitItemsHistoryService, QuotationUtilsService } from "src/app/services/quotations";
import { CustomerAddressService, EmployeeService, GETService, TransportMasterBookingService } from 'src/app/services';
import { Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { Observable } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import moment from "moment";
import { TransportreceiveInfoService } from "src/app/services/transport-receives";
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-delivery-list',
  templateUrl: './delivery-list.component.html',
  styleUrls: ['./delivery-list.component.css']
})
export class DeliveryListComponent implements OnInit {
  form : FormGroup;
  isLoading:boolean = true;
  isMessenger: boolean = false;

  itemLabJobStatus = [
    {id:"CONFIRMED", text: "อยู่ระหว่างให้บริการ"},
    {id:"WAITING_VERIFY_CRF", text: "รออนุมัติใบรายงานผล"},
    {id:"CRF_COMPLETED", text: "ใบรายงานผลเรียบร้อย"},
    {id:"PREPARE_FOR_SHIP", text: "ดำเนินการจัดส่ง"},
    {id:"COMPLETED", text: "เสร็จสิ้น"},
  ];
  itemJobStatus = [
    {id:"DRAFT", text: "ร่าง"},
    {id:"WAITING_FOR_CONFIRM", text: "รอการยืนยัน"},
    {id:"REJECTED", text: "ไม่สามารถให้บริการ"},
    {id:"CONFIRMED", text: "สามารถให้บริการ"},
    {id:"REJECTED_VERIFY_CRF", text: "ไม่อนุมัติใบรายงานผล"},
    {id:"WAITING_VERIFY_CRF", text: "รออนุมัติใบรายงานผล"},
    {id:"CRF_COMPLETED", text: "ใบรายงานผลเรียบร้อย"},
    {id:"PREPARE_FOR_SHIP", text: "ดำเนินการจัดส่ง"},
    {id:"COMPLETED", text: "เสร็จสิ้น"},
    {id:"RETURNED_SUCCESS", text: "ส่งคืนเสร็จสิ้น"},
    {id:"CANCELED", text: "ยกเลิก"},
  ];
  deliveryList = [];
  transportTypes = [];
  transportContactList = [];

  delivery = null;
  deliveryIndex = null;
  type: string = null;

  tab: string = 'lab';
  status: string;
  user_id: string;
  reset: boolean = false;
  load: Function = null;

  filter = {};
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  @ViewChild("real_cert_return_date") real_cert_return_date: ElementRef;
  
  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private GETService: GETService,

    private EmployeeService: EmployeeService,
    private JobOrderInfoService: JobOrderInfoService,
    private CustomerAddressService: CustomerAddressService,
    private QuotationMitItemService: QuotationMitItemService,
    private TransportreceiveInfoService: TransportreceiveInfoService,
    private JobOrderReturnHistoryService: JobOrderReturnHistoryService,
    private JobOrderCertReturnHistoryService: JobOrderCertReturnHistoryService,
    private QuotationMitCertHistoryService: QuotationMitCertHistoryService,
    private QuotationMitItemsHistoryService: QuotationMitItemsHistoryService,
    private TransportMasterBookingService: TransportMasterBookingService,

    public utils: QuotationUtilsService,
    ) { 
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.form = this.fbd.group({
      return_date: [""],
      status:[""],
      real_cert_return_date: [""]
    });
    this.getUser();

    this.load = this.QuotationMitItemService.equipment;
    this.filter = { 
      'transport_receive_item_status':'WAITING_FOR_CONFIRM,WAITING_FOR_REVIEW,RECEIVED', 
      'job_order_info_type': 'ICL,ECL',
      is_child_quotation_mit_item_id_not_null: false,
      return_messenger_id: this.isMessenger? this.userInfo.user_id: undefined,
      'order_by': 'return_date:asc,transport_receive_info_doc_no:asc'
    };

    await this.GETService.transportType(null)
    .then(res=>{
      this.transportTypes = res.resultData || {}
      this.transportTypes = this.transportTypes.map(function(elem) {
        return {
          id: elem.transport_type_id,
          text: `${elem.transport_type_name_th}`,
        }
      });
    });

    await this.EmployeeService.load(null, { emp_department_id: 'TRAN_DEP' })
    .then(res=>{
      this.transportContactList = res.resultData || {}
      this.transportContactList = this.transportContactList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info.firstname_th} ${elem.user_info.lastname_th}`,
        }
      });
    });

    this.form.controls.return_date.valueChanges.subscribe(
      (return_date) => {
        if(return_date){
          this.searchDate(return_date);
        }
      }
    );

    this.form.controls.real_cert_return_date.valueChanges.subscribe(
      (value) => {

        this.filter = {
          ...this.filter,
          real_cert_return_date: moment($(this.real_cert_return_date.nativeElement).data('daterangepicker').startDate).format("YYYY-MM-DD"),
        };
      }
    );
  }

  getUser(){
    this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;
      this.userInfo = userInfo;
      if(userInfo?.emp_info?.emp_position_info?.emp_position_id.startsWith("TRAN_DEP-03")){
        this.isMessenger = true;
      }
    });
  }
  async onChangePage(deliveryList: Array<any>) {
    this.deliveryList = await Promise.all(deliveryList.map(async (delivery)=>{
      if(this.tab == 'lab'){
        let cert_history = delivery.quotation_mit_cert_return_history_info || [];
        let items_history = delivery.quotation_mit_items_return_history_info || [];

        cert_history.sort(function(a,b){
          if(a.created_at < b.created_at) return -1;
          if(a.created_at > b.created_at) return 1;
          return 0;
        });
        items_history.sort(function(a,b){
          if(a.created_at < b.created_at) return -1;
          if(a.created_at > b.created_at) return 1;
          return 0;
        });
        if(delivery.transport_receive_info_id){
          await this.TransportreceiveInfoService.getById({transport_receive_info_id: delivery.transport_receive_info_id}).then(res=>{
            delivery['transport_receive_info'] = {
              ...res.resultData,
              address: this.utils.genAddress(res.resultData.customer_address_info)
            };
          })
        }
        if(delivery.job_order_info_id){
          await this.JobOrderInfoService.getById({job_order_info_id: delivery.job_order_info_id}).then(async res=>{

            let addressResponse = await this.CustomerAddressService.getById({customer_address_id: res.resultData.send_report_address_id});

            delivery['job_order_info'] = {
              ...res.resultData,
              address: this.utils.genAddress(addressResponse.resultData)
            };
          })
        }

        return {
          ...delivery,
          cert_date: cert_history?.length > 0? cert_history[cert_history.length-1].new_date: undefined,
          items_date: items_history?.length > 0? items_history[items_history.length-1].new_date: undefined
        };
      }else{
        let cert_return_history = delivery.job_order_cert_return_history_info;
        let return_history = delivery.job_order_return_history_info;
        
        cert_return_history.sort(function(a,b){
          if(a.created_at < b.created_at) return -1;
          if(a.created_at > b.created_at) return 1;
          return 0;
        });
        return_history.sort(function(a,b){
          if(a.created_at < b.created_at) return -1;
          if(a.created_at > b.created_at) return 1;
          return 0;
        });
        if(this.tab == 'onsite'){

          if(delivery.job_order_info_id){
            await this.JobOrderInfoService.getById({job_order_info_id: delivery.job_order_info_id}).then(async res=>{
             
              let addressResponse = await this.CustomerAddressService.getById({customer_address_id: res.resultData.send_report_address_id});

              delivery['job_order_info'] = {
                ...res.resultData,
                address: this.utils.genAddress(addressResponse.resultData)
              };
            })
          }
        }
        return {
          ...delivery,
          cert_return_history_date: cert_return_history?.length > 0? cert_return_history[cert_return_history.length-1].new_date: undefined,
          return_history_date: return_history?.length > 0? return_history[return_history.length-1].new_date: undefined
        };
      }
    }));
    // console.log(this.deliveryList)
    this.isLoading = false;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = { ...this.filter, ...filter };
  }
  searchFilter(filter){
    this.isLoading = true;
    this.tab = filter;

    if(document.getElementById("status").classList.toggle("show")){
      document.getElementById("status").classList.toggle("show")
    }

    if(document.getElementById("date_filter").classList.toggle("show")){
      document.getElementById("date_filter").classList.toggle("show")
    }
    this.form.get("status").setValue("");
    this.form.get("real_cert_return_date").setValue("");

    this.deliveryList = [];
    if(filter == 'lab'){
      this.load = this.QuotationMitItemService.equipment;
      this.filter = {
        'transport_receive_item_status':'WAITING_FOR_CONFIRM,WAITING_FOR_REVIEW,RECEIVED', 
        'job_order_info_type': 'ICL,ECL',
        is_child_quotation_mit_item_id_not_null: false,
        return_messenger_id: this.isMessenger? this.userInfo.user_id: undefined,
        'order_by': 'return_date:asc,transport_receive_info_doc_no:asc'
      };
      this.isLoading = false;
    }else if(filter == 'onsite'){
      this.load = this.JobOrderInfoService.load;
      this.filter = {
        "job_order_type": "ICS,ECS",
        'doc_status_id':'WAITING_VERIFY_CRF,PREPARE_FOR_SHIP,CRF_COMPLETED', 
        return_messenger_id: this.isMessenger? this.userInfo.user_id: undefined,
        'order_by': 'return_date:asc'
      };
      this.isLoading = false;
    }else{
      this.load = this.JobOrderInfoService.load;
      this.filter = {
        "job_order_type": "PRD",
        'doc_status_id':'PREPARE_FOR_SHIP', 
        return_messenger_id: this.isMessenger? this.userInfo.user_id: undefined,
        'order_by': 'return_date:asc'
      };
      this.isLoading = false;
    }
    this.clearDate();
  }
  searchDate(date){
    let condition = {
      "return_date": date
    };
    this.search(condition);
  }
  clearDate(){
    this.form.get('return_date').setValue('');
    let condition = {
      "return_date": undefined
    };
    this.search(condition);
  }
  clearCertDate(){
    this.form.get('real_cert_return_date').setValue('');
    let condition = {
      "real_cert_return_date": undefined
    };
    this.search(condition);
  }
  async select(delivery, type:string, index: number){
 
    if(await this.checkMasterBooking(delivery, type)){
      this.delivery = delivery;
      this.deliveryIndex = index;
      this.type = type;

      setTimeout(() => {
        if(this.tab == "lab"){

          if(type == "items"){
            $('#delivery-items-history').modal('show');
          }else{
            $('#delivery-cert-history').modal('show');
          }
        }else if(this.tab == "product"){
          $('#delivery-job-order-history').modal('show');
        }else{
          $('#delivery-job-order-history').modal('show');
        }

      }, 100);
    }else{
      Swal.fire({
        icon: "warning",
        confirmButtonText: "ตกลง",
        title: 'แจ้งทราบ',
        text: 'รายการนี้ถูกกำหนดวันที่ส่งคืนแล้ว หากต้องการแก้ไขโปรดแก้ไขที่ Master Booking'
      })
    }
  }
  async selectJob(delivery, index){
    if(await this.checkMasterBooking(delivery)){
      this.delivery = delivery;
      this.deliveryIndex = index;

      setTimeout(() => {
        $('#delivery-passenger-create').modal('show');
      }, 100);
    }else{
      Swal.fire({
        icon: "warning",
        confirmButtonText: "ตกลง",
        title: 'แจ้งทราบ',
        text: 'รายการนี้ถูกกำหนดวันที่ส่งคืนแล้ว หากต้องการแก้ไขโปรดแก้ไขที่ Master Booking'
      })
    }
  }

  async checkMasterBooking(delivery, type?: string): Promise<boolean>{
    let result = false;
    if(this.tab == "lab"){
      if(delivery.quotation_mit_item_id){
        console.log(type)
        if(type){
          await this.TransportMasterBookingService.load(null, {ref_doc_id: delivery.quotation_mit_item_id, ref_doc_type: type=="items"? "MITITEM": "MITCERT"})
          .then(res=>{
    
            if(res.resultData.find(master=> master.doc_status_id != "CANCELED")){
              result = false;
            }else{
              result = true;
            }
          })
        }else{
          await this.TransportMasterBookingService.load(null, {ref_doc_id: delivery.quotation_mit_item_id})
          .then(res=>{
    
            if(res.resultData.find(master=> master.doc_status_id != "CANCELED")){
              result = false;
            }else{
              result = true;
            }
          })
        }
      }else{
        result = true;
      }
    }else if(this.tab == "onsite"){
      if(delivery.job_order_info_id){
        await this.TransportMasterBookingService.load(null, {ref_doc_id: delivery.job_order_info_id})
        .then(res=>{
          if(res.resultData.find(master=> master.doc_status_id != "CANCELED")){
            result = false;
          }else{
            result = true;
          }
        })
      }else{
        result = true;
      }
    }else{
      result = true;
    }
    return result;
  }
  async submitCert(event){
    if(event){
      let  mitCertResponse = await this.QuotationMitCertHistoryService
      .create({
        quotation_mit_item_id: this.delivery.quotation_mit_item_id,
        ...event
      });
      this.deliveryList[this.deliveryIndex] = {
        ...this.delivery,
        cert_date: event.new_date
      }
      this.status  = mitCertResponse.success || mitCertResponse.error;
    }
    this.type = null;
  }
  async submitItems(event){
    if(event){
      let  mitItemsResponse = await this.QuotationMitItemsHistoryService
      .create({
        quotation_mit_item_id: this.delivery.quotation_mit_item_id,
        ...event
      });
      this.deliveryList[this.deliveryIndex] = {
        ...this.delivery,
        items_date: event.new_date
      }
      this.status  = mitItemsResponse.success || mitItemsResponse.error;
    }
    this.type = null;
  }
  //
  async submitJobOrder(event){
    if(event){
      if(this.tab == 'onsite'){
        let  jobResponse = await this.JobOrderCertReturnHistoryService
        .create({
          job_order_info_id: this.delivery.job_order_info_id,
          ...event
        });
        this.deliveryList[this.deliveryIndex] = {
          ...this.delivery,
          cert_return_history_date: event.new_date
        }
        this.status  = jobResponse.success || jobResponse.error;
      }else{
        let  jobResponse = await this.JobOrderReturnHistoryService
        .create({
          job_order_info_id: this.delivery.job_order_info_id,
          ...event
        });
        this.deliveryList[this.deliveryIndex] = {
          ...this.delivery,
          return_history_date: event.new_date
        }
        this.status  = jobResponse.success || jobResponse.error;
      }
    }
    this.type = null;
  }
  async submitPassenger(event){
    if(event){
      if(this.tab == 'lab'){
        let mitResponse = await this.QuotationMitItemService.update(
          {
            ...this.delivery,
            ...event
          }
        )
        this.deliveryList[this.deliveryIndex] = {
          ...this.delivery,
          ...event
        }
        this.delivery = null;
        this.deliveryIndex = null;
        this.status  = mitResponse.success || mitResponse.error;
      }else{
        let jobResponse = await this.JobOrderInfoService.update(
          {
            ...this.delivery,
            ...event
          }
        )
        this.deliveryList[this.deliveryIndex] = {
          ...this.delivery,
          ...event
        }
        this.delivery = null;
        this.deliveryIndex = null;
        this.status  = jobResponse.success || jobResponse.error;
      }
    }
  }

  getPassenger(id:string): string{
    let user_info = this.transportContactList.find(tran=>tran.id == id);
    if(user_info){
      return user_info?.text;
    }else{
      return "-";
    }
  }

  getTransport(id:string): string{
    let tran = this.transportTypes.find(tran=>tran.id == id);
    if(tran){
      return tran?.text;
    }else{
      return "-";
    }
  }

  view(jobOrder){
    if(jobOrder.job_order_type == "TRN" || jobOrder.job_order_type == "PRD"){
      this.router.navigateByUrl("/work-order/non-calibration/"+ jobOrder.job_order_info_id +"/show?template=" +jobOrder.job_order_type);
    }else{
      this.router.navigateByUrl("/work-order/calibration/"+ jobOrder.job_order_info_id  +"/show?template=" +jobOrder.job_order_type);
    }
  }

  canServiceCharge(item): boolean {
    if(this.tab == 'lab'){
      var files = item.quotation_info?.quotation_accepted_info.find(file => file?.quotation_accepted_file_info?.length > 0);
    }else{
      var files = item.isAtachPO;
    }
    return files? true: false;
  }

  btnToggleStatus(){
    if(document.getElementById("date_filter").classList.toggle("show")){
      document.getElementById("date_filter").classList.toggle("show")
    }
    document.getElementById("status").classList.toggle("show");
  }

  btnToggleDate(){
    if(document.getElementById("status").classList.toggle("show")){
      document.getElementById("status").classList.toggle("show")
    }
    document.getElementById("date_filter").classList.toggle("show");
  }

  filterStatus(filter?){
    if(this.tab == 'lab'){
      let newFilter = filter?.filter(item=> item!='-').join();

      if(newFilter){
        this.filter = {
          ...this.filter,
          "job_order_info_status": newFilter,
          "doc_status_id": undefined,
        };
      }else{
        this.filter = {
          ...this.filter,
          "job_order_info_status": undefined,
          "doc_status_id": undefined,
        };
      }
    }else if(this.tab == 'onsite' || this.tab == 'product'){
      let newFilter = filter?.filter(item=> item!='-').join();

      if(newFilter){
        this.filter = {
          ...this.filter,
          "job_order_info_status": undefined,
          "doc_status_id": newFilter,
        };
      }else{
        this.filter = {
          ...this.filter,
          "job_order_info_status": undefined,
          "doc_status_id": undefined,
        };
      }
    }
  }
}
