import { Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { UnitTypeService } from "src/app/services/unit-type.service";

@Component({
  selector: "app-unit-type-list",
  templateUrl: "./unit-type-list.component.html",
  styleUrls: ["./unit-type-list.component.css"],
})
export class UnitTypeListComponent implements OnInit {
  isLoading = true;
  unitTypes = [];
  status: string;
  unit_type_id: string;
  reset: boolean = false;
  filter = {};
  load: Function = this.UnitTypeService.load;
  constructor(
    private router: Router,
    private UnitTypeService: UnitTypeService
  ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit(): void {
    this.unitTypes = [];
  }
  onChangePage(unitTypes: Array<any>) {
    this.unitTypes = unitTypes;
    this.isLoading = false;
  }
  select(unit_type_id: string) {
    this.unit_type_id = unit_type_id;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter) {
    this.filter = filter;
  }
  async delete(closeModalEl) {
    let unitType = this.unitTypes.filter(
      (v) => v.unit_type_id === this.unit_type_id
    )[0];
    if (unitType && unitType.unit_conversion_count) {
      return;
    }
    let response = await this.UnitTypeService.delete({
      unit_type_id: this.unit_type_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }
}
