import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import moment from "moment";
import { 
  CalculateFunctionService,
  QuotationService, 
  QuotationUtilsService
} from "src/app/services/quotations";

@Component({
  selector: 'app-billing-list',
  templateUrl: './billing-list.component.html',
  styleUrls: ['./billing-list.component.css']
})
export class BillingListComponent implements OnInit {
  form : FormGroup;
  isLoading:boolean = false;
  quotations = [];
  status: string;
  reset: boolean = false;
  filter = {};

  load: Function = this.QuotationService.load;
  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private QuotationService: QuotationService,
    
    public calculate: CalculateFunctionService,
    public utils : QuotationUtilsService,
    ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }
  routeEvent(state): void {
    this.status = state?.status;
  }
  ngOnInit(): void {
    this.form = this.fbd.group({
      real_return_date: [""],
    });
    this.filter = { doc_status_id:'ACCEPTED',billing_gt_zero: true, group:'customer_id'};

    if(JSON.parse(localStorage.getItem('mit_cache'))?.real_return_date){
      this.form.get('real_return_date').setValue(moment(JSON.parse(localStorage.getItem('mit_cache')).real_return_date).format('YYYY MM DD'));
    }else{
      this.form.get('real_return_date').setValue(moment(new Date()).format('YYYY MM DD'));
    }
    this.form.controls.real_return_date.valueChanges.subscribe(
      (real_return_date) => {
        if(real_return_date){
          this.searchDate(real_return_date);
        }
      }
    );
  }
  search(filter: object) {
    this.filter = { ...this.filter, ...filter };
  }
  onChangePage(quotations: Array<any>) {
    this.quotations = quotations;
    this.isLoading = false;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  searchDate(date){
    let mit_store = {
      ...JSON.parse(localStorage.getItem('mit_cache')),
      real_return_date: date
    }
    localStorage.setItem('mit_cache', JSON.stringify(mit_store));

    let condition = {
      "real_return_date": date
    };
    this.search(condition);
  }
  searchStatus(status:string = null){
    let condition = null;
    if(status =='CREDIT'){
      condition = {
        credit_term_day_gt: 0, 
        credit_term_day_eq: undefined,
      };
    }else if(status =='BANK'){
      condition = {
        credit_term_day_eq: 0, 
        credit_term_day_gt: undefined
      };
    }else{
      condition = {
        credit_term_day_eq: undefined,
        credit_term_day_gt: undefined
      };
    }
    this.search(condition);
  }
  clearDate(){
    this.form.get('real_return_date').setValue('');
    let condition = {
      "real_return_date": undefined
    };
    this.search(condition);
  }
}
