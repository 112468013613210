import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { CustomerAddressService, CustomerContactService, CustomerInfoService, UtilsService } from 'src/app/services';
import { CalculateFunctionService, DownloadFileService, NgSelect2Customer, QuotationUtilsService } from 'src/app/services/quotations';
import { AccTaxInvoiceContactService, AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';
import { CoreService } from 'src/app/services/tax-invoice/core.service';
import { debounceTime } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { AccInvoiceContactService, AccInvoiceInfoService } from 'src/app/services/invoice';
declare var $: any;


@Component({
  selector: 'app-tax-invoice-edit',
  templateUrl: './tax-invoice-edit.component.html',
  styleUrls: ['./tax-invoice-edit.component.css']
})
export class TaxInvoiceEditComponent implements OnInit {

  form: FormGroup;

  resetdate: boolean = true;
  submitted:boolean = false;
  pageLoad:boolean = false;
  disableSaveBtn: boolean = false;
  isSalesManager:boolean = false;
  isSalesCoordinator:boolean = false;
  isShowCheckbox:boolean = false;

  acc_tax_invoice_info_id: string;
  acc_payment_id: string;
  //array
  contacts = [];
  customerList: any = [];
  customerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerAddressList: any = [];
  tmpCustomerContactList: any = [];
  productList: any = [];
  quotationItems = [];
  //number
  count: number = 0;
  //any
  status: any;
  select2Options = null;
  subject: Subject<string> = new Subject();
  credit: Subject<string> = new Subject();
  customer_info = null;
  taxInvoiceItem = null;
  taxInvoiceInfo = null;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private route: ActivatedRoute,
    private CoreService: CoreService,
    private DownloadFileService: DownloadFileService,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccInvoiceContactService: AccInvoiceContactService,
    private AccTaxInvoiceContactService: AccTaxInvoiceContactService,

    public UtilsService: UtilsService,
    public util : QuotationUtilsService,
    public calculate: CalculateFunctionService,
    public NgSelect2Customer: NgSelect2Customer
  ) {
    this.acc_tax_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_tax_invoice_info_id");
    this.route.queryParams.subscribe(params => {
      this.acc_payment_id = params['acc_payment_id'] || "";
    });
    }

  async ngOnInit() {
    this.createForm();

    this.userInfo$.subscribe(user=>{
      if(!user) return;
      this.userInfo = user;
    })
    if(this.acc_payment_id){
      let acc_invoice_info_id = this.acc_tax_invoice_info_id;
      await this.AccInvoiceInfoService.getById({acc_invoice_info_id: acc_invoice_info_id})
      .then(async res=>{
  
        let resultData = res.resultData || {};
  
        this.customer_info = res.resultData.customer_info;
        let data = {
          ...resultData,
          customer_name: `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''} ${this.customer_info?.branch?" (" + this.customer_info?.branch + ")": ""}`,
        }
        this.form.patchValue(data);
  
        if(resultData.customer_id){
          await this.setCustomerContact(resultData.customer_id);
          await this.setCustomerAddress(resultData.customer_id);
          await this.selectAddressName(resultData.customer_address_id);
        }
  
        await this.AccInvoiceContactService.load(null, { acc_invoice_info_id: acc_invoice_info_id }).then((res) => {
          this.contacts = res.resultData || [];
          let ids = res.resultData.map(v=>{
            return v.customer_contact_id;
          })
          this.form.controls['customer_contact_ids'].setValue(ids);
        });
      })
      await this.AccTaxInvoiceInfoService.getCode(null, {customer_credit_term_day: this.customer_info.credit_term_day || 0})
      .then((res) => {
        let resultData = res.resultData || [];
        this.form.get("doc_no").setValue(resultData.acc_tax_invoice_code);
      });
     }else{
      await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: this.acc_tax_invoice_info_id})
      .then(async res=>{
  
        let resultData = res.resultData || {};
        this.taxInvoiceInfo = resultData;
  
        this.customer_info = res.resultData.customer_info;
        let data = {
          ...resultData,
          customer_name: `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''}`,
        }
        this.form.patchValue(data);
  
        if(resultData.customer_id){
  
          await this.setCustomerAddress(resultData.customer_id);
          await this.setCustomerContact(resultData.customer_id);
          await this.selectAddressName(resultData.customer_address_id);
        }
  
        await this.AccTaxInvoiceContactService.load(null, { acc_tax_invoice_info_id: this.acc_tax_invoice_info_id }).then((res) => {
          this.contacts = res.resultData || [];
          let ids = res.resultData.map(v=>{
            return v.customer_contact_id;
          })
          this.form.controls['customer_contact_ids'].setValue(ids);
        });
      })
     }

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );

    this.credit.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.changeDate()  
    );

    this.pageLoad = true;
  }

  createForm(){
    this.form = this._fbd.group({
      acc_tax_invoice_info_id:  [""],
      customer_id: ["", [Validators.required]],
      customer_name: [""],
      doc_no: ["", [Validators.required]],
      doc_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      doc_validdate:[""],
      doc_duedate: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      return_date:[moment(new Date()).format('YYYY-MM-DD')],
      doc_status_id: ["", [Validators.required]],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      doc_note: [""],
      discount: [this.util.transformDecimal(0)],
      customer_credit_term_day: ["", [Validators.required]],
      tax: ["7"],
      grand_total_before_vat: [0.00],
      grand_total: [0.00],
      revise_count: [""],
      customer_contact_ids: [[], [Validators.required]],
      is_return_doc_can_create: [""],
    });
  }

  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];
      
      if(customer.length > 0){
        if(customer.length > 0){
          address = {
            id: customer[0].customer_address_id,
            customer_id: customer[0].customer_id,
            text: customer[0]?.branch?`${customer[0].address_name} (${customer[0]?.branch})`  + ` แผนก: ${customer[0].department}`: `${customer[0].address_name}`  + ` แผนก: ${customer[0].department}`,
            address_name: this.util.genAddress(customer[0]),
          }
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
      this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];

      this.tmpCustomerAddressList= this.tmpCustomerAddressList.map(elem => (
        {
          id: elem.customer_address_id,
          customer_id: elem.customer_id,
          text: elem.branch? `${elem.address_name} (${elem.branch})` + ` แผนก: ${elem.department}`: `${elem.address_name}` + ` แผนก: ${elem.department}`,
          address_name: this.util.genAddress(elem)
        } 
      ));
      });
      let customerAddress = this.tmpCustomerAddressList;
      if(address){
        this.customerAddressList = [
          ...[address],
          ...customerAddress
        ];
      }else{
        this.customerAddressList = [
          ...customerAddress
        ];
      }

      if(this.customerAddressList.length > 0){
        const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        if(checkAddress.length == 0) {
          if(address){
            this.form.get('customer_address_id').setValue(address.id);
            this.selectAddressName(address.id);
          }else{
            this.form.controls['customer_address_id'].setValue(this.customerAddressList[0]?.id);
            this.selectAddressName(this.customerAddressList[0]?.id);
          }
        }
      }else{
        if(address){
          this.customerAddressList = [
            ...[address],
          ];
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }


  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.controls['customer_address'].setValue("");
      this.form.controls['customer_address_id'].setValue("");
    }
    if(address && this.customerContactList.length > 0){
      let contacts = this.customerContactList.filter(item=> item.data.related_customer_address_id == address.id);
      const ids = contacts.map(v=>{
        return v.id;
      })
      this.form.get('customer_contact_ids').setValue(ids);
    }
  }

  onKeyup(){
    this.subject.next();
  }

  onCreditKeyup(){
    this.credit.next();
  }

  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
  }
  save(){
    if(this.userInfo.emp_info?.emp_department_id.startsWith(
      'ACC_DEP'
    )){
      this.form.get('doc_status_id').setValue("WAITING_FOR_APPROVE");
    }else{
      this.form.get('doc_status_id').setValue("WAITING_VERIFY");
    }
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
  }

  async submit(){
    let data = this.form.getRawValue();
    this.disableSaveBtn = true;

    if(this.acc_payment_id){
      if(data.customer_contact_ids.length > 0){
        data.customer_contact_ids = data.customer_contact_ids.map(v=>{
          return {customer_contact_id: v};
        })
      }
      if(this.acc_payment_id){
        data['acc_payment_id'] = this.acc_payment_id;
      }
      let invoiceResponse = await this.CoreService.createInvoice(data, this.taxInvoiceItem);
   
      if(invoiceResponse.status){
        await this.router.navigateByUrl("/tax-invoice", {
          state: {
            status: invoiceResponse.message,
          },
        })
      }else{
        this.status = invoiceResponse.message;
      }
    }else{
      if(data.customer_contact_ids.length > 0){
        data.customer_contact_ids = data.customer_contact_ids.map(v=>{
          let select = this.contacts.find(item=> item.customer_contact_id == v);
          if(select){
            return {...select};
          }else{
            return {customer_contact_id: v};
          }
        })
      }
  
      const deleteContactList =  this.contacts.map(v=>{
        let select = data.customer_contact_ids.find(item=> item.customer_contact_id == v.customer_contact_id);
        if(!select){
          return {...v};
        }
      }).filter(r => r != undefined);
      let invoiceResponse = await this.CoreService.editInvoice(data, deleteContactList, this.taxInvoiceItem);
  
      if(invoiceResponse.status){
        await this.router.navigateByUrl("/tax-invoice", {
          state: {
            status: invoiceResponse.message,
          },
        })
      }else{
        if(invoiceResponse.message == 'codeDuplicate'){
          this.status = invoiceResponse.error;
        }else{
          this.status = invoiceResponse.message;
        }
      }
    }
    this.disableSaveBtn = false;
  }

  sumCount(){
    this.count = 0;
    this.taxInvoiceItem.taxInvoiceItems.map(product=>{

      if(product.quotation_item_id == '' && product.product_id == ''){
        this.count = this.count - product.discount;
      }else{
        this.count = this.count + this.calculate.sumProduct(product);
      }
    })
    this.form.get('grand_total_before_vat').setValue(this.count);
    this.form.get('discount').setValue(this.util.transformDecimal(this.form.get('discount').value));
    this.form.get('grand_total').setValue(this.calculate.sumProductTotal(this.count, this.form.value));
  }

  changeDate(){
    if(this.form.value.customer_credit_term_day !== '' && !isNaN(this.form.value.customer_credit_term_day)){
      this.form.controls['doc_validdate'].setValue(moment(new Date()).add(parseInt(this.form.value.customer_credit_term_day) || 0, 'days').format('YYYY-MM-DD'));
      this.form.controls['doc_duedate'].setValue(moment(new Date()).add(parseInt(this.form.value.customer_credit_term_day) || 0, 'days').format('YYYY-MM-DD'));

      this.resetdate = false;
      setTimeout(() => {
        this.resetdate = true;
      }, 100);
    }
  }

  getTaxInvoiceItems(event){
    this.taxInvoiceItem = event;
    this.quotationItems = this.taxInvoiceItem.taxInvoiceItems.filter((invoice, index, self) =>
    index === self.findIndex((t) => (
      t.quotation_info_id === invoice.quotation_info_id 
    )));
    let findCalibration = this.taxInvoiceItem.taxInvoiceItems.find(item => item.item_type == 'calibration_service');
    if(findCalibration){
      this.form.controls['is_return_doc_can_create'].setValue('true'); 
      this.form.controls['is_return_doc_can_create'].enable(); 
    }else{
      this.form.controls['is_return_doc_can_create'].setValue(''); 
      this.form.controls['is_return_doc_can_create'].disable(); 
    }
    this.form.controls['is_return_doc_can_create'].updateValueAndValidity(); 
    this.sumCount();
  }

  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }

  clearDate(){
    this.form.controls['doc_validdate'].setValue(""); 
  }
}
