<div>
    <div class="card">
        <div class="card-header">
          <h4 class="card-title mt-1">รายการใบเสนอราคา</h4>
          <div class="heading-elements">
            <search-box (search)="search($event)"></search-box>
          </div>
        </div>

        <div class="card-content collapse show">
          <div class="card-body">
              <div class="row justify-content-between">
                  <div class="col-12 col-sm-12 col-md-12 col-lg-8 align-self-end">
                    <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                      <li class="nav-item border-primary mr-50">
                          <a class="nav-link py-0 active" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchStatus()">ทั้งหมด ({{number_all_status}})</a>
                      </li>
                      <li class="nav-item border-primary mr-50">
                          <a class="nav-link py-0" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchStatus('DRAFT')">ร่าง ({{number_draft_status}})</a>
                      </li>
                      <li class="nav-item border-primary mr-50">
                          <a class="nav-link py-0" id="pill3" data-toggle="pill" href="#pill3" aria-expanded="false" (click)="searchStatus('WAITING_FOR_APPROVE')">รออนุมัติ ({{number_waiting_for_approve_status}})</a>
                      </li>
                      <li class="nav-item border-primary mr-50">
                          <a class="nav-link py-0" id="pill4" data-toggle="pill" href="#pill4" aria-expanded="false" (click)="searchStatus('WAITTING_FOR_ACCEPT')">รอตอบรับ ({{number_waiting_for_accept_status}})</a>
                      </li>
                      <li class="nav-item border-primary mr-50">
                          <a class="nav-link py-0" id="pill5" data-toggle="pill" href="#pill5" aria-expanded="false" (click)="searchStatus('EXPIRED')">พ้นกำหนด ({{number_expired}})</a>
                      </li>
                      <li class="nav-item border-primary mr-50">
                          <a class="nav-link py-0" id="pill6" data-toggle="pill" href="#pill6" aria-expanded="false" (click)="searchStatus('ACCEPTED')">ยอมรับ ({{number_accepted_status}})</a>
                      </li>
                      <li class="nav-item border-light">
                          <a class="nav-link text-light py-0" id="pill7" data-toggle="pill" href="#pill7" aria-expanded="false" (click)="searchStatus('VOIDED')">ยกเลิก ({{number_voided_status}})</a>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-sm-12 col-md-12 col-lg-3 text-right">
                    <a class="nav-link text-primary pr-0" [routerLink]="['/quotation/create']"  [queryParams]="{customer_id: customer_id}">
                        <h4><i class="fa fa-plus"></i>&nbsp;สร้างใบเสนอราคา</h4>
                    </a>
                  </div>
              </div>
              <loading *ngIf="isLoading"></loading>
              <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                      <thead>
                          <tr>
                          <th class="text-center">เลขที่เอกสาร</th>
                          <th style="width: 25%;">ชื่อลูกค้า</th>
                          <th>วันที่ออก</th>
                          <th>ใช้ได้ถึง</th>
                          <th class="text-right">มูลค่าก่อน Vat (บาท)</th>
                          <th class="text-center">สถานะ</th>
                          <th>Action</th>
                          </tr>
                      </thead>
                      <tbody *ngIf="quotations">
                          <tr *ngFor="let quotation of quotations; let i = index">
                              <th>{{quotation.doc_no}}</th>
                              <td>
                                <div>
                                  [{{quotation?.customer_info?.customer_code}}] {{quotation?.customer_info?.company_name}}
                                  <br>
                                  <small class="text-muted">
                                    ออกเอกสารในนาม: {{quotation.customer_address_info?.address_name || '-'}}  (iplus: {{ quotation.customer_address_info?.mit_iplus_code}})
                                  </small>
                                  <br>
                                  <small class="text-muted">
                                    พนักงานขาย: {{quotation.sales_assignment_info?.user_info?.firstname_th}} {{quotation.sales_assignment_info?.user_info?.lastname_th || '-'}}
                                  </small>
                                </div>
                               
                              </td>
                              <td>{{quotation.doc_date | thai_date: "short":"date" | dash }}</td>
                              <td>{{quotation.doc_validdate | thai_date: "short":"date" | dash}}</td>
                              <td class="text-right">
                                {{(quotation.grand_total * 100) /107 | number : '1.2-2'}}
                              </td>
                              <td class="text-center">{{quotation.doc_status_info.document_status_name_th}}</td>
                              <td>
                                  <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm"
                                  [routerLink]="[
                                    '/quotation/' + quotation.quotation_info_id + '/show'
                                  ]">
                                    <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                  </a>
                                  
                                  <button 
                                    *ngIf="quotation.sales_assignment_info && ((userInfo$ | async)?.is_co_sales_supervisor || (userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales
                                    ||  quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                                    type="button"
                                    [routerLink]="[
                                    '/quotation/' + quotation.quotation_info_id + '/edit'
                                    ]"
                                    [queryParams]="{action:'copy'}"
                                    class="btn mr-1 mb-1 btn-light btn-sm">
                                    <i class="fa fa-copy icon-left"></i> คัดลอก
                                  </button>
                                  <a 
                                    *ngIf="quotation.doc_status_id != 'VOIDED' 
                                    && ((userInfo$ | async)?.is_co_sales_supervisor || (userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales
                                    ||  quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                                    type="button" class="btn mr-1 mb-1 btn-blue btn-sm"
                                    target="_blank"
                                    [routerLink]="[
                                    '/document/' + quotation.quotation_info_id
                                    ]">
                                    <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                                  </a>
                                  <button
                                    *ngIf="(quotation.doc_status_id === 'DRAFT' || quotation.doc_status_id === 'WAITTING_FOR_ACCEPT')
                                    && (((userInfo$ | async)?.is_sales_manager && quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id) 
                                    || ((userInfo$ | async)?.is_co_sales && quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id) 
                                    || ((userInfo$ | async)?.is_co_sales_supervisor && quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)
                                    ||  quotation.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                                    type="button"
                                    class="btn mr-1 mb-1 btn-danger btn-sm"
                                    data-target="#quotation-confirm-voided"
                                    data-toggle="modal"
                                    (click)="select(quotation)"
                                  >
                                  <i class="fa fa-times icon-left"></i> ยกเลิก
                                </button>
                              </td>
                          </tr>
                          <tr *ngIf="quotations.length === 0">
                              <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
                          </tr>
                      </tbody>
                  </table>
              </div>

              <app-paginator
                *ngIf="userInfo$ | async"
                [filter]="filter"
                [reset]="reset"
                [fetch]="load"
                (changePage)="onChangePage($event)">
              </app-paginator>
          </div>
        </div>
      </div>
</div>

<app-quotation-confirm-voided 
  *ngIf="quotation"
  (save)="onSaveConfirmVoided($event)" 
  [quotation]="quotation">
</app-quotation-confirm-voided>
