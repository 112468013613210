import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  EventEmitter,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import {
  CustomerActivityService,
  UploadService,
  CustomerActivityFileService,
} from "src/app/services";
import moment from "moment";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-customer-activity-edit",
  templateUrl: "./customer-activity-edit.component.html",
  styleUrls: ["./customer-activity-edit.component.css"],
})
export class CustomerActivityEditComponent implements OnInit {
  environment = environment;
  form: FormGroup;
  submitted = false;
  status = null;
  quill = null;
  customer_id = null;
  files = [];
  fileToBeRemove = null;
  centerMap: google.maps.LatLngLiteral;
  @Output() done = new EventEmitter();
  @Output("close-sidebar") closeSidebarEvent = new EventEmitter();

  @Input("activity-types") activityTypes: Array<any> = [];
  @Input("customer-activity") customer_activity = null;
  @ViewChild("closeSidebar") closeSidebar: ElementRef;
  @Input("can-edit-customer") canEditCustomer = false;
  @Input("see-company-name") seeCompanyName = false;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private CustomerActivityService: CustomerActivityService,
    private UploadService: UploadService,
    private CustomerActivityFileService: CustomerActivityFileService
  ) {
    this.setForm();
    this.customer_id = this.route.snapshot.paramMap.get("customer_id");
  }
  setForm() {
    this.form = this.formBuilder.group({
      activity_datetime: ["", [Validators.required]],
      customer_activity_type_id: ["", [Validators.required]],
      title: ["", [Validators.required]],
      company_name: [""],
      description: [""],
      location_lat: [""],
      location_lng: [""],
      customer_id: [""],
    });
  }

  setData() {
    if (!this.customer_activity) return;

    this.form.controls.activity_datetime.setValue(
      this.customer_activity.activity_datetime
        ? moment(this.customer_activity.activity_datetime).format(
            "DD-MM-YYYY HH:mm"
          )
        : ""
    );
    this.form.controls.company_name.setValue(
      this.customer_activity.customer_info.company_name
    );
    this.form.controls.customer_id.setValue(
      this.customer_activity.customer_id
    );
    this.form.controls.customer_activity_type_id.setValue(
      this.customer_activity.customer_activity_type_id
    );
    this.form.controls.title.setValue(this.customer_activity.title || "");
    this.form.controls.description.setValue(
      this.customer_activity.description || ""
    );

    // quill will be set on enabling

    this.form.controls.location_lat.setValue(
      this.customer_activity.location_lat
    );
    this.form.controls.location_lng.setValue(
      this.customer_activity.location_lng
    );

    this.files = this.customer_activity.customer_activities_file_info;
  }

  ngOnInit(): void {
    this.setData();
    this.form.disable();
  }

  // ngOnChanges(changes) {
  //   if (changes.customer_activity) {
  //     this.ngOnInit();
  //   }
  // }

  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let result = await this.CustomerActivityService.update({
      ...this.form.value,
      customer_id: this.form.value.customer_id? this.form.value.customer_id: this.customer_id,
      activity_datetime: moment(this.form.value.activity_datetime).format(),
      customer_activities_id: this.customer_activity.customer_activities_id,
    });

    if (this.files.length) {
      for (let file of this.files) {
        if (!file.customer_activities_file_id) {
          await this.CustomerActivityFileService.create({
            customer_activities_id: this.customer_activity
              .customer_activities_id,
            file_name: file.file_name,
            file_path: file.file_path,
          });
        }
      }
    }

    if (result.success) {
      this.done.emit();
      this.closeSidebar.nativeElement.click();
    }
  }

  async delete(closeModal) {
    await this.CustomerActivityService.delete({
      customer_activities_id: this.customer_activity.customer_activities_id,
    });
    closeModal.click();
    this.closeSidebar.nativeElement.click();
    this.done.emit();
  }

  async uploadFile(e) {
    let files = Array.from(e.target.files);
    debugger;
    for (let file of files) {
      let result = await this.UploadService.post(file, 'customers');
      if (result.success) {
        this.files.push({
          file_name: result.resultData.originalname,
          file_path: result.resultData.path,
        });
      }
    }
    e.target.value = null;
  }

  enableForm() {
    this.form.enable();

    if (this.quill) {
      const delta = this.quill.clipboard.convert(
        this.customer_activity.description || ""
      );
      this.quill.setContents(delta, "silent");
      this.quill.enable(true);
    }
  }

  setLocation(location) {
    this.form.controls.location_lat.setValue(String(location.lat));
    this.form.controls.location_lng.setValue(String(location.lng));
  }

  async removeFile(closeModal) {
    if (this.fileToBeRemove.customer_activities_file_id) {
      let res = await this.CustomerActivityFileService.delete({
        customer_activities_file_id: this.fileToBeRemove
          .customer_activities_file_id,
      });
      if (res.success) {
        this.files = this.files.filter(
          (f) => f.file_path !== this.fileToBeRemove.file_path
        );
      }
    } else {
      this.files = this.files.filter(
        (f) => f.file_path !== this.fileToBeRemove.file_path
      );
    }
    closeModal.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.closeSidebarEvent.emit();
        setTimeout(() => {
          this.setForm();
        }, 500);
      } else {
        setTimeout(() => {
          this.ngOnInit();
        });
      }
    });
  }
}
