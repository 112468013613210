import { Component, NgZone, OnInit, Output, ViewChild, EventEmitter, ElementRef, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccInvoiceInfoService, AccPaymentService } from 'src/app/services/invoice';
import { AccTaxInvoiceInfoService, AccTaxInvoiceItemService } from 'src/app/services/tax-invoice';
import moment from 'moment';


@Component({
  selector: 'app-tax-invoice-payment-create',
  templateUrl: './tax-invoice-payment-create.component.html',
  styleUrls: ['./tax-invoice-payment-create.component.css']
})
export class TaxInvoicePaymentCreateComponent implements OnInit {
  form: FormGroup;
  //boolean
  submitted:boolean = false;
  disableSaveBtn:boolean  = false;

  acc_tax_invoice_info_id: string;
  tab: string = "เงินสด";
  sum_payment = 0;

  @Input() paymentList: any;
  @Input() payments: any;
  @Input() tax_invoice_info: any;
  @Input() sum_price: number;

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  subject: Subject<string> = new Subject();

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private AccPaymentService: AccPaymentService,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccTaxInvoiceItemService: AccTaxInvoiceItemService,

    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public util : QuotationUtilsService,
  ) {
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.acc_tax_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_tax_invoice_info_id");
    this.setForm();
  }

  ngOnInit(): void {
    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    this.form.get('tax').setValue(changes.tax_invoice_info.currentValue.tax);
    this.form.get('tax').updateValueAndValidity();
    if(changes.payments?.currentValue.length > 0){
      this.sum_payment = 0;
      changes.payments.currentValue.map(item=>{
        this.sum_payment = this.sum_payment + item.value_before_wht;
      });
    }
  }

  setForm(){
    this.form = this.formBuilder.group({
      acc_document_info_id: [this.acc_tax_invoice_info_id],
      doc_type: ["TAX_INVOICE"],
      payment_method_id: [""],
      payment_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      bank_name: [""],
      bank_branch: [""],
      evidence_date: [""],
      evidence_ref_no: [""],
      value_before_vat: [""],
      wht_percent: ["0"],
      value_before_wht: ["", [Validators.required]],
      fee: ["0.00"],
      grand_total: [""],
      note: [""],
      tax: [""],
      order: [1],
      deduction: [""],
    });
  }

  changetab(tab:string){
    this.tab = tab;
    let data = this.form.getRawValue();
    this.form.patchValue(data);
    if(tab == 'เงินสด'){
      this.form.controls["bank_name"].clearValidators();
      this.form.controls["bank_branch"].clearValidators();
      this.form.controls["evidence_date"].clearValidators();
      this.form.controls["evidence_ref_no"].clearValidators();
    }else if(tab == 'เช็ค'){
      this.form.controls["bank_name"].setValidators([Validators.required]);
      this.form.controls["bank_branch"].setValidators([Validators.required]);
      this.form.controls["evidence_date"].setValidators([Validators.required]);
      this.form.controls["evidence_ref_no"].setValidators([Validators.required]);
    }else{
      this.form.controls["bank_name"].setValidators([Validators.required]);
      this.form.controls["bank_branch"].clearValidators();
      this.form.controls["evidence_date"].clearValidators();
      this.form.controls["evidence_ref_no"].setValidators([Validators.required]);
    }
 
    this.form.get("bank_name").updateValueAndValidity();
    this.form.get("bank_branch").updateValueAndValidity();
    this.form.get("evidence_date").updateValueAndValidity();
    this.form.get("evidence_ref_no").updateValueAndValidity();
  }

  onKeyup(){
    this.subject.next();
  }
  
  sumCount(){
    let data = {
      ...this.form.getRawValue()
    }
    this.form.get('value_before_wht').setValue(this.util.transformDecimal(data.value_before_wht));
  
    if(data.fee == "") data.fee = 0;
    this.form.get('fee').setValue(this.util.transformDecimal(data.fee));

    if(data.wht_percent){
      let sum = (this.util.convertStingToNumber(data.value_before_wht) * this.util.convertStingToNumber(data.wht_percent)) /100;
      this.form.get('deduction').setValue(this.util.transformDecimal(sum));
  
      if(data.value_before_wht){
        let value_before_vat = this.util.convertStingToNumber(data.value_before_wht) - sum;
        this.form.get('value_before_vat').setValue(value_before_vat);
  
        let grand_total = (this.util.convertStingToNumber(value_before_vat)+(this.util.convertStingToNumber(data.value_before_wht) * this.util.convertStingToNumber(data.tax)) /100)-this.util.convertStingToNumber(this.form.value.fee);
        this.form.get('grand_total').setValue(this.util.transformDecimal(grand_total));
      }
    }
  }

  async save(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.disableSaveBtn = true;
    let value = {
      ...this.form.getRawValue()
    }
    
    if(this.tab =='เงินสด'){
      value.bank_name = "";
      value.bank_branch = "";
      value.evidence_date = "";
      value.evidence_ref_no = "";
    }

    let method = this.paymentList.find(pay=> pay.payment_method_name_en == this.tab);

    let data = {
      ...value,
      payment_method_id: method.payment_method_id,
      grand_total: parseFloat(this.util.convertStingToNumber(value.grand_total)),
      value_before_vat: parseFloat(this.util.convertStingToNumber(value.value_before_vat)),
      value_before_wht: parseFloat(this.util.convertStingToNumber(value.value_before_wht)),
      wht_percent: parseFloat(value.wht_percent),
      evidence_date: value.evidence_date == ""? undefined: value.evidence_date,
      fee: parseFloat(this.util.convertStingToNumber(value.fee)),
    }

    this.sum_payment = this.sum_payment + data.value_before_wht;
    let response = await this.AccPaymentService.create(data);
    if(response.success){
      let invoiceResponse = await this.AccInvoiceInfoService.getById({acc_invoice_info_id: this.tax_invoice_info.acc_invoice_info_id});
      
      if(this.sum_payment >= this.sum_price){
        let taxResponse = await this.AccTaxInvoiceInfoService.update({
          ...this.tax_invoice_info,
          doc_status_id: 'COMPLETED',
          doc_duedate : moment(this.tax_invoice_info.doc_duedate).format('YYYY-MM-DD')
        });
        if (taxResponse.success) {
          if(invoiceResponse.success){
            let response = await this.AccInvoiceInfoService.update({
              ...invoiceResponse.resultData,
              acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
              doc_status_id: 'COMPLETED',
              doc_duedate : moment(invoiceResponse.resultData.doc_duedate).format('YYYY-MM-DD')
            });
            this.updateMitItem(response, taxResponse);
            this.done.emit(response);
            this.closeModalBtn.nativeElement.click();
          }
        } 
      }else{
        let taxResponse = await this.AccTaxInvoiceInfoService.update({
          ...this.tax_invoice_info,
          doc_status_id: 'PARTIAL_PAYMENT',
          doc_duedate : moment(this.tax_invoice_info.doc_duedate).format('YYYY-MM-DD')
        });
        if (taxResponse.success) {
          if(invoiceResponse.success){
            let response = await this.AccInvoiceInfoService.update({
              ...invoiceResponse.resultData,
              acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
              doc_status_id: 'PARTIAL_PAYMENT',
              doc_duedate : moment(invoiceResponse.resultData.doc_duedate).format('YYYY-MM-DD')
            });
            this.updateMitItem(response, taxResponse);
            this.done.emit(response);
            this.closeModalBtn.nativeElement.click();
          }
        } 
      }
      this.disableSaveBtn = false;
    }else{
      this.disableSaveBtn = false;
    }
  }

  async updateMitItem(response, taxResponse){

    let taxInvoiceItemResponse = await this.AccTaxInvoiceItemService.load(null, {acc_tax_invoice_info_id: this.acc_tax_invoice_info_id});

    await Promise.all(
      taxInvoiceItemResponse.resultData.map(async (value: any, index: number)=>{
        if(value.item_type == 'calibration_service'){
          let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
          await this.QuotationMitItemService.update({
            ...quotationMitResponse.resultData[0],
            acc_invoice_info_id: response.resultData.acc_invoice_info_id,
            acc_invoice_info_doc_no: response.resultData.doc_no,
            acc_invoice_info_status: response.resultData.doc_status_id,
            acc_tax_invoice_info_id: taxResponse.resultData.acc_tax_invoice_info_id,
            acc_tax_invoice_info_doc_no: taxResponse.resultData.doc_no,
            acc_tax_invoice_info_status: taxResponse.resultData.doc_status_id,
          })
        }else{
          let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
          await this.QuotationProductItemService.update({
            ...quotationProductResponse.resultData,
            acc_invoice_info_id: response.resultData.acc_invoice_info_id,
            acc_invoice_info_doc_no: response.resultData.doc_no,
            acc_invoice_info_status: response.resultData.doc_status_id,
            acc_tax_invoice_info_id: taxResponse.resultData.acc_tax_invoice_info_id,
            acc_tax_invoice_info_doc_no: taxResponse.resultData.doc_no,
            acc_tax_invoice_info_status: taxResponse.resultData.doc_status_id,
          })
        }
    }));
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }
  
  reset(){
    this.done.emit(false);
  }
}
