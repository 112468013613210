import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { 
  QuotationUtilsService 
} from 'src/app/services/quotations';
import { 
  JobOrderContactService,
  JobOrderInfoService, 
  JobOrderProductItemService
} from 'src/app/services/job-orders';
import domtoimage from 'dom-to-image-more'
import jsPDF from 'jspdf';  
import { Title } from '@angular/platform-browser';
import { CustomerContactService } from 'src/app/services';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  public jobOrderItems = [];
  public joborderContactList = [];
  public customerContactList = [];
  public pages = [];

  public job_order_info_id: string = "";
  public token: string = "";

  public customer_info = null;
  public jobOrderInfo = null;

  public canAccess: boolean = false;
  public pageLoad: boolean = false;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private JobOrderInfoService: JobOrderInfoService,
    private CustomerContactService: CustomerContactService,
    private JobOrderContactService: JobOrderContactService,
    private JobOrderProductItemService: JobOrderProductItemService,

    public utils : QuotationUtilsService
  ) {
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
   }

   async ngOnInit() {
    await this.JobOrderInfoService.getById({job_order_info_id: this.job_order_info_id})
      .then(async res=>{
        this.canAccess = true;
        this.pageLoad = true;
        this.jobOrderInfo = res.resultData || {};
        if(this.jobOrderInfo.customer_address_info){
          this.jobOrderInfo['customer_address'] = this.utils.genAddress(this.jobOrderInfo.customer_address_info);
        }

        await this.JobOrderProductItemService.load(null, {job_order_info_id: this.job_order_info_id})
        .then(async res=>{
          this.jobOrderItems = res.resultData || [];
        });

        await this.CustomerContactService.load(null, {
          order_by: "isMaster:desc",
          customer_id: this.jobOrderInfo.customer_info.customer_id
        }).then((res) => {
          this.customerContactList = res.resultData;
        });
        await this.JobOrderContactService
        .load(null, { job_order_info_id: this.jobOrderInfo.job_order_info_id })
        .then((res) => {
          let result = res.resultData;
          this.customerContactList.map(v=> {
            const res = result.find(item => item.customer_contact_id == v.customer_contact_id);
            if(res) this.joborderContactList.push(res);
          })
        });

        this.setData();
    })
  }

  setData(){
    this.titleService.setTitle(this.jobOrderInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
      
    for (var i=0; i<this.jobOrderItems.length; i++) {
      this.jobOrderItems[i].index = i + 1;
    }
    for (var i=0; i<this.jobOrderItems.length; i+=20) {
      this.pages.push(this.jobOrderItems.slice(i, i+20));
    }  
  }

  async print(jobOrderInfo){
    const job_order_sections = document.querySelectorAll('.pdf');
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let page = 1;
    let perPage = Array.prototype.slice.call(job_order_sections,0).length;
    
    let jobOrderList = [];
    await Promise.all(Array.prototype.slice.call(job_order_sections,0).map(async (value, index) =>{
      let job_order_section = document.getElementById('job_order_' + (index));
      await domtoimage.toJpeg(job_order_section, {
        background: 'white', 
        allowTaint: true,
        width: job_order_section.clientWidth * 2,
        height: job_order_section.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        jobOrderList.push({index: index, canvas: canvas, job_order_section: job_order_section});
      })
    }));
    jobOrderList.sort(function(a, b){return a.index - b.index});
    await Promise.all(jobOrderList.map(async (value, index) =>{

      var imgWidth = 208;
      var imgHeight = value.job_order_section.clientHeight * imgWidth / value.job_order_section.clientWidth;
      if((index) != 0) doc.addPage('a4');
      doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);
      doc.setFontSize(8);
      doc.text(page + ' /  ' + perPage, 200, 285);
      page = page+1;
    }));

    setTimeout(() => {
      doc.save(jobOrderInfo.doc_no);
    }, 1000);
  }
}
