import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';

import moment from 'moment';
import { Observable } from 'rxjs';
import { 
  CustomerAddressService,
  CustomerContactService,
  CustomerInfoService,
  EmployeeService,
  GETService, 
  UtilsService
} from 'src/app/services';
import { NgSelect2Customer, QuotationProductItemService, QuotationService, QuotationUtilsService } from 'src/app/services/quotations';
import { TransportreceiveInfoService } from 'src/app/services/transport-receives';
import { UserInfoState } from 'src/app/store/user/user.state';
import { CoreService } from '../../../../services/transport-receives/core.service';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-transport-receive-create',
  templateUrl: './transport-receive-create.component.html',
  styleUrls: ['./transport-receive-create.component.css']
})
export class TransportreceiveCreateComponent implements OnInit {
  form: FormGroup;
  pageLoad: boolean = true;
  resetdate: boolean = true;
  submitted: boolean = false;
  disableSaveBtn = false;
  canAccessDate: boolean = true;

  type: string;
  customer_id: string;
  receive_type: string;
  quotation_info_id: string;

  transportTypes = [];
  transportContactList = [];
  customerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerAddressList: any = [];
  tmpCustomerContactList: any = [];

  status: any;
  userInfo = null;
  receiveItem = null;
  customer_info = null;
  edit_history = null;
  maxReturnDate = undefined;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;

  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private route: ActivatedRoute,
    private GETService: GETService,
    private QuotationService: QuotationService,
    private EmployeeService: EmployeeService,
    private CustomerInfoService: CustomerInfoService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private TransportreceiveInfoService: TransportreceiveInfoService,
    private QuotationProductItemService: QuotationProductItemService,
    
    private CoreService: CoreService,

    public UtilsService: UtilsService,
    public utils : QuotationUtilsService,
    public NgSelect2Customer: NgSelect2Customer
  ) {
    this.route.queryParams.subscribe(params => {
      this.type = params['type'];
      this.receive_type = params['receive_type'];
    });
    if(this.type =="quotation"){
      this.quotation_info_id = 
      this.route.snapshot.paramMap.get("quotation_info_id");
    }else{
      this.customer_id = 
      this.route.snapshot.paramMap.get("quotation_info_id");
    }
  }

  async ngOnInit() {
    this.createForm();
    await this.getData();
    await this.setData();

    this.pageLoad = false;
  }

  async getData(){
    this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;
      this.userInfo = userInfo;
      if(userInfo?.emp_info?.emp_position_info?.emp_position_id.startsWith("TRAN_DEP-03")){
        this.form.controls['receiver_id'].setValue(userInfo?.user_id);
        this.form.controls['receiver_id'].disable();
      }
    });

    await this.GETService.transportType(null)
    .then(res=>{
      this.transportTypes = res.resultData || {}
      this.transportTypes = this.transportTypes.map(function(elem) {
        return {
          id: elem.transport_type_id,
          text: `${elem.transport_type_name_th}`,
        }
      });
    })

    await this.EmployeeService.load(null, { emp_department_id: 'TRAN_DEP' })
    .then(res=>{
      this.transportContactList = res.resultData || {}
      this.transportContactList = this.transportContactList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info.firstname_th} ${elem.user_info.lastname_th}`,
        }
      });
    })
  }

  async setData(){
    if(this.type == "quotation"){
      await this.QuotationService.getById({quotation_info_id: this.quotation_info_id})
      .then(async res=>{

        let resultData = res.resultData || {};
        this.customer_info = res.resultData.customer_info;

        let data = {
          customer_id: resultData.customer_id,
          customer_name: `[${resultData?.customer_info?.customer_code || ''}] ${resultData?.customer_info?.company_name || ''} ${resultData.branch?" (" + resultData.branch + ")": ""}`,
          customer_address_id: resultData.customer_address_id,
          note: resultData?.customer_info?.term_of_service
        }
        if(resultData.customer_id){

          await this.setCustomerContact(resultData.customer_id);
          await this.setCustomerAddress(resultData.customer_id);
          this.form.get("customer_address_id").disable();
        }
        this.form.patchValue(data);

      })
    }else{
      await this.CustomerInfoService.getById({customer_id: this.customer_id})
      .then(async res=>{

        let resultData = res.resultData || {};
        this.customer_info = res.resultData;
   
        let data = {
          customer_id: resultData.customer_id,
          customer_name: `[${resultData?.customer_code || ''}] ${resultData?.company_name || ''} ${resultData.branch?" (" + resultData.branch + ")": ""}`,
          note: resultData?.term_of_service
        }
        if(resultData.customer_id){

          await this.setCustomerContact(resultData.customer_id);
          await this.setCustomerAddress(resultData.customer_id);
        }
        this.form.patchValue(data);

      })
    }

    setTimeout(() => {
      this.setDateTimePicker();
    }, 1000);
  }

  setDateTimePicker(){
    let self = this;
    $('#pickup_date').on('dp.change', function(e){ 
      self.resetdate = false;
      setTimeout(() => {
        self.resetdate = true;
      }, 50);

      $('#return_date').data("DateTimePicker").date(moment(new Date(e.date)).add(17, 'd'));
      self.maxReturnDate = moment(new Date(e.date)).add(17,'d');
    });
  }


  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData.filter(item=> item.customer_address_type_id == 4) || [];

      if(customer.length > 0){
        if(customer.length > 0){
          address = this.utils.genCustomerAddressList(customer[0]);
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
       this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 4) || [];

       this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem=>{
        return this.utils.genCustomerAddressList(elem);
        })
      });
      let customerAddress = this.tmpCustomerAddressList;

      if(address){
        this.customerAddressList = [
          ...[address],
          ...customerAddress
        ];
      }else{
        this.customerAddressList = [
          ...customerAddress
        ];
      }

      if(this.customerAddressList.length > 0){
        // const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        // if(checkAddress.length == 0) {
        //   if(address){
        //     this.selectAddressName(address.id);
        //   }else{
        //     this.selectAddressName(this.customerAddressList[0]?.id);
        //   }
        // }
      }else{
        if(address){
          this.customerAddressList = [
            ...[address],
          ];
          // this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }

  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.get('customer_address').setValue("");
    }
  }

  createForm(){
    this.form = this.fbd.group({
      doc_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      customer_id: ["", [Validators.required]],
      customer_name: [""],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      transport_type_id: ["", [Validators.required]],
      pickup_date: [null],
      return_date: [null],
      doc_status_id: ["", [Validators.required]],
      receiver_id: [""],
      note: [""],
      accessory: [""],
      extra_message: [""],
      receive_type: [this.receive_type],

      customer_contact_ids: [[], [Validators.required]]
    });
    // this.maxReturnDate = moment(new Date()).add(17,'d').format('YYYY-MM-DD');
  }

  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
  }

  save(sendEmail){ 
    this.form.get('doc_status_id').setValue("WAITING_FOR_RECEIVE");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit(sendEmail);
  }

  async submit(sendEmail = false){
    let data = this.form.getRawValue();
    this.disableSaveBtn = true;

    data['email'] = sendEmail;
    if(data.customer_contact_ids.length > 0){
      data.customer_contact_ids = data.customer_contact_ids.map(v=>{
        return {customer_contact_id: v};
      })
      data.contacts = data.customer_contact_ids.map(v=>{
        return v.customer_contact_id;
      })
    }

    let transportResponse = await this.CoreService.createTransport(data, this.receiveItem, this.edit_history);

    if(transportResponse.status){
      await this.router.navigateByUrl("/transport-receive", {
        state: {
          status: transportResponse.message,
        },
      })
    }else{
      if(transportResponse.message == 'codeDuplicate'){
        this.status = transportResponse.error;
      }else{
        this.status = transportResponse.message;
      }
    }
    this.disableSaveBtn = false;
  }

  async getReceiveItems(event){
    console.log(
      event
    );

    this.receiveItem = event;
    // let result = null;
    // let receiveItems = this.receiveItem.receiveItems;
    // receiveItems = receiveItems.filter((receive, index, self) =>
    // index === self.findIndex((t) => (
    //   t.quotation_info_id === receive.quotation_info_id
    // )))

    // await Promise.all(receiveItems.map(async value=>{
    //   if(value.quotation_info_id){
    //     await this.QuotationProductItemService
    //     .load(null,  {quotation_info_id: value.quotation_info_id, '$product_info.product_title$': 'transport' })
    //     .then(res=>{
    //       if(res.resultData.length > 0){
    //         result = true;
    //       }
    //     })
    //   }
    // }));
    
    // if(result){
    //   this.canAccessDate = false;

    // }else{

    //   this.canAccessDate = true;
    //   if(
    //     this.receiveItem.delelteReceiveItems.length == 0 
    //     && 
    //     this.form.value.receive_type == 'WITH_QT'
    //     &&
    //     this.form.value.transport_type_id == ''){
    //     Swal.fire({
    //       icon: "warning",
    //       confirmButtonText: "ตกลง",
    //       title: 'แจ้งทราบ',
    //       text: 'รายการที่เลือกจากใบเสนอราคา ที่ไม่มีค่าขนส่ง'
    //     })
    //   }
    // }
  }

  async unlock(event){
    await this.TransportreceiveInfoService.validateSecureCode(event)
    .then(res=>{
      if(res.success){
        this.canAccessDate = false;
        this.edit_history = res.resultData || {};
      }else{
        alert("secure code not match.")
      }
    })
  }
}
