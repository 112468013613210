import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class ProductTypeService {
  async create({ product_type_name }) {
    let result = null;
    try {
      result = await Axios().post(`/product-type`, {
        product_type_name,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({ product_type_id, product_type_name }) {
    let result = null;
    try {
      result = await Axios().put(`/product-type/${product_type_id}`, {
        product_type_name,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ product_type_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/product-type/${product_type_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/product-type`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ product_type_id }) {
    let result = null;
    try {
      result = await Axios().get(`/product-type/${product_type_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
