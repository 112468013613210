<div
  class="modal fade text-left"
  [id]="id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="myModalLabel1">สร้างไฟล์แนบ</h4>
        <button
          type="button"
          class="close"
          (click)="onClose()"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="form" autocomplete="off">
          <div class="row justify-content-md-center">
            <div class="col-md-12">
              <div class="form-body">
                <form-group
                  [control]="form.controls.order"
                  [submitted]="submitted"
                >
                  <label for="order">
                    ลำดับ <span class="danger">*</span>
                  </label>
                  <input
                    type="number"
                    id="order"
                    class="form-control"
                    placeholder="Order"
                    formControlName="order"
                  />
                </form-group>

                <form-group
                  [control]="form.controls.description"
                  [submitted]="submitted"
                >
                  <label for="description">
                    รายละเอียด <span class="danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="description"
                    class="form-control"
                    placeholder="Description"
                    formControlName="description"
                  />
                </form-group>

                <form-group
                  [control]="form.controls.file_path"
                  [submitted]="submitted"
                >
                  <label for="file_path">
                    ไฟล์แนบ <span class="danger">*</span><br /><small
                      >รูปภาพ หรือ pdf</small
                    >
                  </label>

                  <div class="custom-file">
                    <input
                      type="file"
                      class="form-control custom-file-input"
                      id="file_path"
                      formControlName="file_path"
                      (change)="selectFile($event)"
                      accept="image/*, application/pdf"
                    />
                    <label class="custom-file-label" for="file_path">{{
                      form.controls.file_name.value
                        ? form.controls.file_name.value
                        : "Choose file"
                    }}</label>
                  </div>
                </form-group>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          (click)="onClose()"
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="onSave()">
          <i class="fa fa-plus icon-left"></i>ยืนยัน
        </button>
      </div>
    </div>
  </div>
</div>
