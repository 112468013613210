import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class UnitConversionService {
  async create({ unit_type_id, unit_id, order_number }) {
    let result = null;
    try {
      result = await Axios().post(`/unit-conversion`, {
        unit_type_id,
        unit_id,
        order_number,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    unit_conversion_id,
    unit_type_id,
    unit_id,
    base_value,
    order_number,
  }) {
    let result = null;
    try {
      result = await Axios().put(`/unit-conversion/${unit_conversion_id}`, {
        unit_type_id,
        order_number,
        unit_id,
        base_value,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ unit_conversion_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/unit-conversion/${unit_conversion_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async deleteByParams(params = {}) {
    let result = null;
    try {
      result = await Axios().delete(`/unit-conversion`, {
        params: params,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/unit-conversion`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ unit_conversion_id }) {
    let result = null;
    try {
      result = await Axios().get(`/unit-conversion/${unit_conversion_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
