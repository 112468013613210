import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class CustomerEstDateCaliService {
  async create({ customer_id, est_date }) {
    let result = null;
    try {
      result = await Axios().post(`/customer-est-date-cali`, {
        customer_id,
        est_date,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({ customer_est_date_cali_id, customer_id, est_date }) {
    let result = null;
    try {
      result = await Axios().put(
        `/customer-est-date-cali/${customer_est_date_cali_id}`,
        {
          customer_id,
          est_date,
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ customer_est_date_cali_id }) {
    let result = null;
    try {
      result = await Axios().delete(
        `/customer-est-date-cali/${customer_est_date_cali_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/customer-est-date-cali`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ customer_est_date_cali_id }) {
    let result = null;
    try {
      result = await Axios().get(
        `/customer-est-date-cali/${customer_est_date_cali_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
