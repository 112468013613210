import { Component, OnInit } from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import { Subscription, Observable } from "rxjs";
import {
  UtilsService,
  EmployeeService,
  EmployeeLevelService,
  EmployeePositionService,
  EmployeeDepartmentService,
  UserService,
  UploadService,
} from "src/app/services";
import Swal from "sweetalert2";
import {
  THValidator,
  ENValidator,
  EmailValidator,
  AbsNumberValidator,
} from "src/app/validators";
import { Store, Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { GetUserInfo } from "src/app/store/user/user.action";
import { AuthService } from "src/app/services/auth.service";
import { DownloadFileService } from "src/app/services/quotations";
import { environment } from "../../../../../environments/environment";

@Component({
  selector: "app-user-edit",
  templateUrl: "./user-edit.component.html",
  styleUrls: ["./user-edit.component.css"],
})
export class UserEditComponent implements OnInit {
  isReady = false;
  form: FormGroup;
  submitted = false;
  user_id: any;
  status = null;
  departments: [];
  levels: [];
  positions: [];
  user: any;
  emp_department_id: Subscription;
  file = null;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private AuthService: AuthService,
    private UserService: UserService,
    private router: Router,
    private EmployeeService: EmployeeService,
    private EmployeeDepartmentService: EmployeeDepartmentService,
    private EmployeePositionService: EmployeePositionService,
    private EmployeeLevelService: EmployeeLevelService,
    private DownloadFileService:DownloadFileService,
    private UploadService: UploadService,
    private UtilsService: UtilsService,
    private store: Store
  ) {}

  async ngOnInit() {
    if (this.emp_department_id) {
      this.emp_department_id.unsubscribe();
    }
    this.isReady = false;
    this.form = this.formBuilder.group({
      email: ["", [Validators.required, EmailValidator()]],
      // password: ["", [Validators.required, Validators.minLength(6)]],
      firstname_th: ["", [Validators.required, THValidator()]],
      lastname_th: ["", [Validators.required, THValidator()]],
      firstname_en: ["", [Validators.required, ENValidator()]],
      lastname_en: ["", [Validators.required, ENValidator()]],

      date_of_birth: ["", Validators.required],
      mobile_no: ["", [Validators.required]],
      user_role_id: ["USER"],
      user_state_id: ["ACTIVE"],

      // employee
      emp_department_id: ["", Validators.required],
      emp_level_id: ["", Validators.required],
      emp_position_id: ["", Validators.required],
      emp_code: [""],
      is_admin: [""],
      name_code: [""],
      nickname_th: ["", [THValidator()]],
      nickname_en: ["", [ENValidator()]],
      idcard_file: [""],
      iplus_signature_filename: [""],
    });

    let promises = [];
    promises.push(
      this.EmployeeDepartmentService.load().then((result) => {
        this.departments = result.resultData;
      })
    );
    promises.push(
      this.EmployeeLevelService.load().then((result) => {
        this.levels = result.resultData;
      })
    );

    await Promise.all(promises);

    this.user_id = this.route.snapshot.paramMap.get("user_id");
    let result = await this.UserService.getById({ user_id: this.user_id });
    if (result.success) {
      let user = result.resultData;
      this.user = {
        ...user,
        toggle_user_state: user.user_state_id === "ACTIVE",
      };
      if(user.idcard_file){
        this.file = user.idcard_file;
      }

      result = await this.EmployeePositionService.load({
        emp_department_id: this.user.emp_info.emp_department_id,
      });

      this.positions = result.resultData;
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 100);
      });

      for (let key in this.form.value) {
        if (key === "password") {
        } else if (key === "date_of_birth") {
          this.form.controls[key].patchValue(
            moment(user[key]).format("YYYY-MM-DD"),
            {
              onlySelf: true,
              emitEvent: false,
            }
          );
        } else if (
          key === "emp_department_id" ||
          key === "emp_position_id" ||
          key === "emp_level_id" ||
          key === "emp_code"
        ) {
          this.form.controls[key].patchValue(user.emp_info[key], {
            onlySelf: true,
            emitEvent: false,
          });
        } else {
          this.form.controls[key].patchValue(user[key], {
            onlySelf: true,
            emitEvent: false,
          });
        }
      }

      this.form.controls.is_admin.setValue(user.user_role_id === "ADMIN");
      this.form.get("emp_code").disable();
    }
    this.form.get("email").disable();

    this.form.disable();

    this.emp_department_id = this.form
      .get("emp_department_id")
      .valueChanges.subscribe((emp_department_id) => {
        this.form.controls.emp_position_id.setValue("");
        if (!emp_department_id) {
          this.positions = [];
          return;
        }

        this.EmployeePositionService.load({ emp_department_id }).then(
          (result) => {
            this.positions = result.resultData;
          }
        );
      });
    this.isReady = true;
  }

  enableForm() {
    this.form.enable({ onlySelf: true, emitEvent: false });
    this.form.get("emp_code").disable();
    this.form.get("email").disable();
  }

  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let data = {
      ...this.form.value,
      user_role_id: this.form.value.is_admin ? "ADMIN" : "USER",
    };

    if(this.file?.name){
      let result = await this.UploadService.post(this.file, 'users');
      if (result.success) {
        data.idcard_file = result.resultData.path;
      }
    };
    this.status = "";
    let userResponse = await this.UserService.update({
      ...data,
      email:
        this.user.email === this.form.value.email
          ? undefined
          : this.form.value.email,
      user_id: this.user_id,
    });

    if (userResponse.success) {
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
      if (userInfo.user_id === userResponse.resultData.user_id) {
        this.store.dispatch(new GetUserInfo());
      }

      let employeeResponse = await this.EmployeeService.update({
        emp_id: this.user.emp_info.emp_id,
        user_id: userResponse.resultData.user_id,
        emp_department_id: this.form.value.emp_department_id,
        emp_level_id: this.form.value.emp_level_id,
        emp_position_id: this.form.value.emp_position_id,
      });
      if (employeeResponse.success) {
        this.router.navigateByUrl("/users", {
          state: {
            status: employeeResponse.success,
          },
        });
      } else {
        this.status = employeeResponse.error;
      }
    } else {
      if (userResponse.resultCode === "40900") {
        Swal.fire(
          userResponse.error.resultTitle.th,
          userResponse.error.resultDesc.th,
          userResponse.error.bootstrapClass
        );
      } else {
        this.status = userResponse.error;
      }
    }
  }

  generatePassword() {
    this.form.controls.password.setValue(this.UtilsService.generatePassword(6));
  }

  async resetPassword() {
    const password = this.UtilsService.generatePassword(6);
    let resetPasswordRes = await this.AuthService.resetPassword({
      user_id: this.user_id,
      password,
    });
    if (resetPasswordRes.success) {
      this.status = {
        resultCode: "20000",
        bootstrapClass: "success",
        resultTitle: { th: "สำเร็จ !", en: "Success !" },
        resultDesc: {
          th: `Reset password เสร็จสิ้น รหัสผ่านใหม่คือ "${password}"`,
          en: `Reset password success. Your new password is "${password}`,
        },
      };
    } else {
      this.status = resetPasswordRes.error;
    }
  }

  cancel() {
    this.ngOnInit();
  }

  async toggleState(event) {
    let response = await this.UserService.update({
      user_id: this.user.user_id,
      user_state_id: event.target.checked ? "ACTIVE" : "INACTIVE",
    });
  }

  uploadFile(){
    document.getElementById("importFile").click();
  }
  
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.file = f;
    });
  }
  downloadFile(file){
    if(file.name){
      this.DownloadFileService.downloadFile(file);
    }else{
      this.DownloadFileService.downloadFileURL({file_path: file, file_name: 'ID Card.' + file.split('.')[1]});
    }
  }
}
