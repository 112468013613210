import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobOrderTrainingEngineerService {
  async create({
    job_order_info_id,
    user_id,
    status
  }) {
    let result = null;
    try {
      result = await Axios().post(`/job-order-training-engineer`, {
        job_order_info_id,
        user_id,
        status
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      job_order_training_engineer_id,
      job_order_info_id,
      user_id,
      status
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/job-order-training-engineer/${job_order_training_engineer_id}`, {
        job_order_info_id,
        user_id,
        status
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ job_order_training_engineer_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/job-order-training-engineer/${job_order_training_engineer_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-training-engineer`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ job_order_training_engineer_id }) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-training-engineer/${job_order_training_engineer_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
