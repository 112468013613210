import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationAcceptedFileService {
  async create({
    quotation_accepted_id,
    accepted_doc_name,
    accepted_doc_path,
    order
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-accepted-file`, {
        quotation_accepted_id,
        accepted_doc_name,
        accepted_doc_path,
        order
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_accepted_file_id,
        quotation_accepted_id,
        accepted_doc_name,
        accepted_doc_path,
        order
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/quotation-accepted-file/${quotation_accepted_file_id}`, {
        quotation_accepted_id,
        accepted_doc_name,
        accepted_doc_path,
        order
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_accepted_file_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-accepted-file/${quotation_accepted_file_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-accepted-file`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_accepted_file_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-accepted-file/${quotation_accepted_file_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
