import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccDebitNoteEditHistoryService } from 'src/app/services/debit-note';

@Component({
  selector: 'app-debit-note-item-history',
  templateUrl: './debit-note-item-history.component.html',
  styleUrls: ['./debit-note-item-history.component.css']
})
export class DebitNoteItemHistoryComponent implements OnInit {
  submitted:boolean = false;
  pageLoad:boolean = true;
 
  memoList: any = [];
 
  acc_debit_note_info_id: string;
  //any
  status: any;
  constructor(
    private route: ActivatedRoute,
    private AccDebitNoteEditHistoryService: AccDebitNoteEditHistoryService
  ) { }
 
  ngOnInit(): void {
    this.acc_debit_note_info_id = 
    this.route.snapshot.paramMap.get("acc_debit_note_info_id");
    this.getMemoList();
  }
 
  getMemoList(){
    this.pageLoad = true;
    this.memoList = [];
 
    this.AccDebitNoteEditHistoryService.load(null, { acc_debit_note_info_id : this.acc_debit_note_info_id  })
    .then(async (res)=>{
      this.memoList = res.resultData;
      this.memoList.sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      this.pageLoad = false;
    })
  }
}
