<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="work-order-import"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreate1Modal">
          บันทึกผล
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal">
            <div class="form-body">
                <div class="form-group row mb-0">
                    <div class="col-12">
                        <div class="alert alert-danger" role="alert" *ngIf="!canAccess">
                          ไฟล์ไม่ถูกต้อง โปรดตรวจสอบใหม่อีกครั้ง ({{errorMessage}})
                        </div>
                        <button 
                            class="btn btn-outline-primary" 
                            type="button"
                            (click)="uploadFile()">
                            <i class="feather icon-upload icon-left"></i>
                            Upload
                        </button>
                        <input type="file" style="display:none;" id="importMitFile"
                        (change)="onFileChange($event)">
                    </div>
                </div>
            </div>

            <div class="mt-1 pointer" (click)="isShowRemark = !isShowRemark">
              ข้อกำหนด
                <i class="fa" [ngClass]="{'fa-angle-right': !isShowRemark, 'fa-angle-down': isShowRemark }"></i>
            </div>

            <div class="" [hidden]="!isShowRemark">
              <div class="custom-bullet">
                โปรดบันทึกผลด้วยไฟล์ที่ดาวน์โหลดจากใบสั่งงานนนี้
              </div>
              <div class="custom-bullet">
                โปรดกรอกข้อมูลให้ครบถ้วน โดยเฉพาะ Column Y, Z, AE, AF, AG
              </div>
              <div class="custom-bullet">
                ไม่รองรับการเปลี่ยน Scope, Equipment, Method ด้วยวิธีการแก้ excel แล้วนำมาบันทึกผล หากต้องการแก้ไข โปรดกลับไปที่หน้าแก้ไข และดาวน์โหลดไฟล์บันทึกผลใหม่อีกครั้ง
              </div>
              <div class="custom-bullet">
                หากต้องการเพิ่ม Point สามารถเพิ่มได้ที่ Column AQ ตามข้อกำหนดการกรอกของระบบ ศึกษาได้ในคู่มือ
              </div>
              <div class="custom-bullet">
                ไม่สามารถกรอก Unit อื่น ที่นอกเหนือจากหน่วยฐานได้ (Unit base) และไม่รองรับการแปลงหน่วย
              </div>
              <div class="custom-bullet">
                หากต้องการเพิ่มเครื่องมือหน้างาน สามารถกรอกข้อมูลต่อท้าย โดยไม่ต้องระบุ Column AP และตรวจสอบให้แน่ใจว่า Cert No. Column N ได้ Running ต่อจาก Item ก่อนหน้าแล้ว
              </div>
              <div class="custom-bullet">
                การเพิ่ม Item หน้างานที่ไม่เคยเสนอราคา รองรับเฉพาะ ICS เท่านั้น
              </div>
            </div>

            <div class="form-body">
                <div class="form-group row mt-1 mb-0">
                  <div class="col-12">
                    <div class="table-responsive" style="max-height: 600px;">
                        <table class="table table-striped table-bordered">
                            <thead> 
                                <tr>
                                  <th>Cert. No.</th>
                                  <th>Description</th>
                                  <th>Maker</th>
                                  <th>Model</th>
                                  <th>Serial no.</th>
                                  <th>Tag no.</th>
                                  <th>Calibration point</th>
                                  <th>MPE</th>
                                  <th>Method</th>
                                  <th>ISO</th>
                                  <th>Pass-Failed</th>
                                  <th>Calibrated By</th>
                                  <th class="text-right">DUE</th>
                                  <th>Person in Charge</th>
                                  <th>STD Used</th>
                                  <th>Cal Date</th>
                                  <th>Note</th>
                                  <th>Action</th>
                                </tr>
                            </thead>
        
                            <tbody>
                              <tr *ngFor="let item of excelList; let i = index;" [style.background-color]="item.is_warning ? 'yellow' :null" [hidden]="loading">
                                <td class="text-nowrap">
                                  <div *ngIf="!item.is_new">
                                    <del *ngIf="item.cert_no != getDetail(item, dbList)?.cert_no">
                                      <span class="text-danger">{{getDetail(item, dbList)?.cert_no}}</span>
                                    </del>
                                    <div>
                                      {{item.cert_no}}
                                    </div>
                                  </div>
                                  <div *ngIf="item.is_new">
                                      <div>{{item?.cert_no}}</div>
                                      <span class="text-warning">รายการใหม่</span>

                                      <div class="text-danger" *ngIf="item?.is_duplicate">
                                        เลข cert ซ้ำ
                                      </div>
                                  </div>
                                </td>
                                <td>
                                  <del *ngIf="item.quotation_mit_item_info?.description != getDetail(item, dbList)?.quotation_mit_item_info?.description">
                                    <span class="text-danger">{{getDetail(item, dbList)?.quotation_mit_item_info?.description}}</span>
                                  </del>
                                  <div>
                                    {{item.quotation_mit_item_info?.description}}
                                  </div>
                                </td>
                                <td class="text-nowrap">
                                  <del *ngIf="item.quotation_mit_item_info?.marker != getDetail(item, dbList)?.quotation_mit_item_info?.marker">
                                    <span class="text-danger">{{getDetail(item, dbList)?.quotation_mit_item_info?.marker}}</span>
                                  </del>
                                  <div>
                                    {{item.quotation_mit_item_info?.marker}}
                                  </div>
                                </td>
                                <td class="text-nowrap">
                                  <del *ngIf="item.quotation_mit_item_info?.model != getDetail(item, dbList)?.quotation_mit_item_info?.model">
                                    <span class="text-danger">{{getDetail(item, dbList)?.quotation_mit_item_info?.model}}</span>
                                  </del>
                                  <div>
                                    {{item.quotation_mit_item_info?.model}}
                                  </div>
                                </td>
                                <td class="text-nowrap">
                                  <del *ngIf="item.quotation_mit_item_info?.serial_no != getDetail(item, dbList)?.quotation_mit_item_info?.serial_no">
                                    <span class="text-danger">{{getDetail(item, dbList)?.quotation_mit_item_info?.serial_no}}</span>
                                  </del>
                                  <div>
                                    {{item.quotation_mit_item_info?.serial_no}}
                                  </div>
                                </td>
                                <td class="text-nowrap">
                                  <del *ngIf="item.quotation_mit_item_info?.tag_no != getDetail(item, dbList)?.quotation_mit_item_info?.tag_no">
                                    <span class="text-danger">{{getDetail(item, dbList)?.quotation_mit_item_info?.tag_no}}</span>
                                  </del>
                                  <div>
                                    {{item.quotation_mit_item_info?.tag_no}}
                                  </div>
                                </td>
                                <td>
                                  <span *ngFor="let point of item.quotation_mit_item_info.point.data_point; let j = index; let last = last;">
                                    <span *ngFor="let p of point.data; let k = index; let lastP = last;">
                                      <span>{{p.data}}</span>
                                      <span *ngIf="!lastP">|</span>
                                      <span *ngIf="lastP" class="custom-inline" [innerHTML]="item.quotation_mit_item_info.point.unit_info[j]?.unit_short_name_en"></span>
                                    </span>

                                    <div *ngIf="checkInvalidPointByItem(item.quotation_mit_item_info.point)" class="text-danger">ข้อมูลไม่ถูกต้อง ({{getInvalidReason(item.quotation_mit_item_info.point)}})</div>
                                  </span>
                                  <div *ngIf="checkInvalidPointByItem(item.quotation_mit_item_info.point) && !item.quotation_mit_item_info.point?.data_point" class="text-danger">ข้อมูลไม่ถูกต้อง ({{getInvalidReason(item.quotation_mit_item_info.point)}})</div>
                                </td>
                                <td class="text-nowrap">
                                  <div>
                                    {{item.quotation_mit_item_info.quotation_mit_item_detail_info.mpe}}
                                  </div>
                                </td>
                                <td>
                                  <span *ngIf="item.quotation_mit_item_info.method.length > 0">
                                    {{item.quotation_mit_item_info.method.join(", ")}}
                                  </span>
                                </td>
                                <td class="text-center">
                                  <span *ngIf="item.quotation_mit_item_info.quotation_mit_item_detail_info">
                                    {{item.quotation_mit_item_info.quotation_mit_item_detail_info.iso}}
                                  </span>
                                </td>
                                <td class="text-nowrap text-center">
                                  <span *ngIf="item?.job_order_status_work_info?.reject_reason==null" class="text-danger">ข้อมูลไม่ถูกต้อง</span>
                                  <div [ngSwitch]="item?.job_order_status_work_info?.reject_reason">
                                    <span *ngSwitchCase="'p'">
                                     Pass
                                    </span>
                                    <span *ngSwitchCase="'f'">
                                      Fail
                                    </span>
                                    <span *ngSwitchDefault>
                                      {{ item?.job_order_status_work_info?.reject_reason }}
                                    </span>
                                  </div>
                                </td>
                                <td class="text-nowrap">
                                  <span *ngIf="item?.job_order_status_work_info?.created_by_info?.firstname_th==undefined" class="text-danger">ข้อมูลไม่ถูกต้อง</span>
                                  <div>
                                    {{item.job_order_status_work_info?.created_by_info?.firstname_th}} {{item.job_order_status_work_info?.created_by_info?.lastname_th}}
                                  </div>
                                </td>
                                <td class="text-right">
                                  <del *ngIf="item.quotation_mit_item_info?.due_month != getDetail(item, dbList)?.quotation_mit_item_info?.due_month">
                                    <span class="text-danger">{{getDetail(item, dbList)?.quotation_mit_item_info?.due_month}}</span>
                                  </del>
                                  <div>
                                    {{item.quotation_mit_item_info?.due_month}}
                                  </div>
                                </td>
                                <td class="text-nowrap">
                                  <span *ngIf="item?.person_in_charge_info?.firstname_th==undefined" class="text-danger">ข้อมูลไม่ถูกต้อง</span>
                                  <div>
                                    {{item?.person_in_charge_info?.firstname_th}} {{item?.person_in_charge_info?.lastname_th}}
                                  </div>
                                </td>
                                <td class="text-nowrap">
                                  <span *ngIf="item?.std==null" class="text-danger">ข้อมูลไม่ถูกต้อง</span>
                                  <div>
                                    {{item?.std}}
                                  </div>
                                </td>
                                <td class="text-nowrap">
                                  <span *ngIf="item.job_order_status_work_info?.service_date==null" class="text-danger">ข้อมูลไม่ถูกต้อง</span>
                                  <div *ngIf="item.job_order_status_work_info?.service_date">
                                    {{item.job_order_status_work_info?.service_date | thai_date: "short" | dash}}
                                  </div>
                                </td>
                                <td class="text-nowrap">
                                  {{item.job_order_status_work_info?.note}}
                                </td>
                                <td class="text-nowrap">
                                  <button
                                        type="button"
                                        class="btn mr-1 mb-1 btn-danger btn-sm"
                                        (click)="remove(i)"
                                    > <i class="fa fa-trash icon-left"></i> ลบ
                                    </button>
                                </td>
                              </tr>
                            </tbody>
                            <tbody *ngIf="loading">
                                <loading class="ml-5"></loading>
                            </tbody>
                            <tbody *ngIf="excelList.length === 0 && !loading">
                              <tr>
                                  <td [attr.colspan]="20" class="text-center">ไม่มีข้อมูล</td>
                              </tr>
                            </tbody>
                      
                        </table>
                    </div>
                    <span *ngIf="!canSubmit" class="text-danger pl-2">ข้อมูลไม่ถูกต้อง</span>
                  </div>
                </div>
            </div>
            
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ยืนยัน
        </button>
      </div>
    </div>
  </div>
</div>
