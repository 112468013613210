import Axios from "./axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class UserService {
  async create({
    email,
    firstname_th,
    lastname_th,
    firstname_en,
    lastname_en,
    date_of_birth,
    user_role_id,
    user_state_id,
    password,
    mobile_no,
    signature_file,
    secure_code,
    secure_code_last_update,
    name_code,
    nickname_th,
    nickname_en,
    idcard_file,
    iplus_signature_filename
  }) {
    let result = null;
    try {
      result = await Axios().post(`/users`, {
        email,
        firstname_th,
        lastname_th,
        firstname_en,
        lastname_en,
        date_of_birth,
        user_role_id,
        user_state_id,
        password,
        mobile_no,
        signature_file: signature_file || '',
        secure_code,
        secure_code_last_update,
        name_code,
        nickname_th,
        nickname_en,
        idcard_file,
        iplus_signature_filename
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      user_id,
      email,
      firstname_th,
      lastname_th,
      firstname_en,
      lastname_en,
      date_of_birth,
      user_role_id,
      user_state_id,
      password,
      mobile_no,
      signature_file,
      secure_code,
      secure_code_last_update,
      name_code,
      nickname_th,
      nickname_en,
      idcard_file,
      iplus_signature_filename
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/users/${user_id}`, {
        email,
        firstname_th,
        lastname_th,
        firstname_en,
        lastname_en,
        date_of_birth,
        user_role_id,
        user_state_id,
        password,
        mobile_no,
        signature_file: signature_file || '',
        secure_code,
        secure_code_last_update,
        name_code,
        nickname_th,
        nickname_en,
        idcard_file,
        iplus_signature_filename
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ user_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/users/${user_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/users`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ user_id }) {
    let result = null;
    try {
      result = await Axios().get(`/users/${user_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  async me() {
    let result = null;
    try {
      result = await Axios().get("/users/me");
      result = result.data;
    } catch (error) {
      result = error.response?.data || {};
    }
    return result;
  }
  async changePassword({ user_id, oldPassword, newPassword }) {
    let result = null;
    try {
      result = await Axios().put(`/users/${user_id}/change-password`, {
        oldPassword,
        newPassword,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async checkEmail({ email }) {
    let result = null;
    try {
      result = await Axios().get(`/users/check-email`, {
        params: { email },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
