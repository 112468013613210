import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobOrderCertService {
  async create({
    job_order_info_id,
    item_id,
    file_name,
    cert_file_path
  }) {
    let result = null;
    try {
      result = await Axios().post(`/job-order-cert`, {
        job_order_info_id,
        item_id,
        file_name,
        cert_file_path
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      job_order_item_cert_id,
      item_id,
      job_order_info_id,
      file_name,
      cert_file_path
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/job-order-cert/${job_order_item_cert_id}`, {
        job_order_info_id,
        item_id,
        file_name,
        cert_file_path
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ job_order_item_cert_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/job-order-cert/${job_order_item_cert_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-cert`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ job_order_item_cert_id }) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-cert/${job_order_item_cert_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
