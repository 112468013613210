import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class ProductCalibrationFeeService {
  async create({
    mit_equipment_id,
    service_location,
    mit_cp_list,
    mit_method_id,
    min_range,
    max_range,
    unit_id,
    start_fee,
    per_point_fee,
    min_point,
    isISO,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/product-calibration-fee`, {
        mit_equipment_id,
        service_location,
        mit_cp_list,
        mit_method_id,
        min_range: parseFloat(min_range),
        max_range: parseFloat(max_range),
        unit_id,
        start_fee,
        per_point_fee,
        min_point,
        isISO,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    product_calibration_fee_id,
    service_location,
    mit_cp_list,
    mit_equipment_id,
    mit_method_id,
    min_range,
    max_range,
    unit_id,
    start_fee,
    per_point_fee,
    min_point,
    isISO,
  }) {
    let result = null;
    try {
      result = await Axios().put(
        `/product-calibration-fee/${product_calibration_fee_id}`,
        {
          service_location,
          mit_cp_list,
          mit_equipment_id,
          mit_method_id,
          min_range: parseFloat(min_range),
          max_range: parseFloat(max_range),
          unit_id,
          start_fee,
          per_point_fee,
          min_point,
          isISO,
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ product_calibration_fee_id }) {
    let result = null;
    try {
      result = await Axios().delete(
        `/product-calibration-fee/${product_calibration_fee_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/product-calibration-fee`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  // async getById({ product_calibration_fee_id }) {
  //   let result = null;
  //   try {
  //     result = await Axios().get(`/product-calibration-fee/${product_calibration_fee_id}`);
  //     result = result.data;
  //   } catch (error) {
  //     result = error.response.data;
  //   }
  //   return result;
  // }
}
