import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TransportreceiveInfoService {
  async create({
    customer_id,
    customer_address_id,
    doc_no,
    doc_date,
    transport_type_id,
    pickup_date,
    return_date,
    doc_status_id,
    customer_signature,
    customer_signature_date,
    returned_customer_signature,
    returned_customer_signature_date,
    receiver_id,
    note,
    accessory,
    receive_type,
    email,
    extra_message,
    contacts
  }) {
    let result = null;
    try {
      result = await Axios().post(`/transport-receive-info`, {
        customer_id,
        customer_address_id,
        doc_no,
        doc_date,
        transport_type_id,
        pickup_date,
        return_date,
        doc_status_id,
        customer_signature,
        customer_signature_date,
        returned_customer_signature,
        returned_customer_signature_date,
        receiver_id,
        note,
        accessory,
        receive_type,
        email,
        extra_message,
        contacts
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      transport_receive_info_id,
      customer_id,
      customer_address_id,
      doc_no,
      doc_date,
      transport_type_id,
      pickup_date,
      return_date,
      doc_status_id,
      customer_signature,
      customer_signature_date,
      returned_customer_signature,
      returned_customer_signature_date,
      receiver_id,
      note,
      accessory,
      receive_type,
      email,
      extra_message,
      contacts
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/transport-receive-info/${transport_receive_info_id}`, {
        customer_id,
        customer_address_id,
        doc_no,
        doc_date,
        transport_type_id,
        pickup_date,
        return_date,
        doc_status_id,
        customer_signature,
        customer_signature_date,
        returned_customer_signature,
        returned_customer_signature_date,
        receiver_id,
        note,
        accessory,
        receive_type,
        email,
        extra_message,
        contacts
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ transport_receive_info_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/transport-receive-info/${transport_receive_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ transport_receive_info_id }) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-info/${transport_receive_info_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  async getCode(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-code`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async validateSecureCode({
    user_id,
    secure_code,
    transport_receive_info_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/validate-secure-code`, {
        user_id,
        secure_code,
        transport_receive_info_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
