import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CustomerAddressService, CustomerContactService, GETService } from 'src/app/services';
import { CalculateFunctionService, DownloadFileService, NgSelect2Customer, QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccTaxInvoiceContactService, AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import { AccInvoiceInfoService } from 'src/app/services/invoice';
import moment from 'moment';
import { Location } from "@angular/common";

@Component({
  selector: 'app-tax-invoice-show',
  templateUrl: './tax-invoice-show.component.html',
  styleUrls: ['./tax-invoice-show.component.css']
})
export class TaxInvoiceShowComponent implements OnInit {
  form: FormGroup;

  submitted:boolean = false;
  pageLoad:boolean = false;
  isSalesManager:boolean = false;
  isSalesCoordinator:boolean = false;
  isEditDate:boolean = false;
  isEditDueDate:boolean = false;
  isShowCheckbox:boolean = false;
  acc_tax_invoice_info_id: string;

  creditNoteTotal: number = null;
  debitNoteTotal: number = null;

  customerList: any = [];
  customerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerAddressList: any = [];
  tmpCustomerContactList: any = [];
  productList: any = [];
  paymentItem = [];
  quotationItems = [];
  //number
  count: number = 0;
  grandTotal: number = 0;
  //any
  status: any;
  select2Options = null;
  subject: Subject<string> = new Subject();
  options = { multiple: true };
  customer_info = null;
  taxInvoiceItem = null;
  taxInvoiceInfo = null;
  invoiceInfo = null;
  resetDate = true;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private GETService: GETService,
    private DownloadFileService: DownloadFileService,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccTaxInvoiceContactService: AccTaxInvoiceContactService,

    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public _utils : QuotationUtilsService,
    public _calculate: CalculateFunctionService,
    public _ngSelect2Customer: NgSelect2Customer,
  ) {
    this.acc_tax_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_tax_invoice_info_id");
  }

  async ngOnInit() {
    // this.pageLoad = false;
    this._ngSelect2Customer.initPopOver();
    this.createForm();

    this.userInfo$.subscribe(user=>{
      if(!user) return;
      this.userInfo = user;
    })

    await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: this.acc_tax_invoice_info_id})
    .then(async res=>{

      let resultData = res.resultData || {};
      this.taxInvoiceInfo = resultData;
      this.customer_info = res.resultData.customer_info;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''} ${this.customer_info?.branch?" (" + this.customer_info?.branch + ")": ""}`,
        customer_credit_term_day: resultData.credit_term_day || 0,
        ...resultData
      }
      this.form.patchValue(data);

      this.form.controls['return_date'].setValue(moment(resultData.return_date).format('YYYY-MM-DD'));
      this.form.controls['doc_duedate'].setValue(moment(resultData.doc_duedate).format('YYYY-MM-DD'));
      this.form.controls['doc_validdate'].setValue(moment(resultData.doc_validdate).format('YYYY-MM-DD'));
      this.resetEditDate();

      if(resultData.customer_address_id){
        await this.CustomerAddressService.getById({ customer_address_id: resultData.customer_address_id }).then((res)=>{
          if(res.success){
            var resultData = res.resultData;
            var address = this._utils.genAddress(resultData);

            this.form.controls['customer_address_name'].setValue(resultData.address_name + (resultData.branch?' ('+ resultData.branch +')': '')  + ` แผนก: ${resultData.department}`);
            this.form.controls['customer_address'].setValue(address); 
          }else{
            this.form.controls['customer_address_name'].setValue(null);
            this.form.controls['customer_address'].setValue(null); 
          } 
        })
      }

      if(resultData.customer_id){
        await this.setCustomerContact(resultData.customer_id);
      }

      await this.AccTaxInvoiceContactService.load(null, { acc_tax_invoice_info_id: this.acc_tax_invoice_info_id }).then((res) => {
        let ids = res.resultData.map(v=>{
          return v.customer_contact_id;
        })
        this.form.controls['customer_contact_ids'].setValue(ids);
      });

      if(resultData.acc_invoice_info_id){
        await this.AccInvoiceInfoService.getById({acc_invoice_info_id: resultData.acc_invoice_info_id}).then(res=>{
          this.invoiceInfo = res.resultData;
        })
      }
    })

    this.pageLoad = true;
  }

  ngOnDestroy() {
    this._ngSelect2Customer.clearPopOver();
  }
  
  createForm(){
    this.form = this._fbd.group({
      acc_tax_invoice_info_id: [""],
      customer_id: [""],
      customer_name: [""],
      doc_no: [""],
      doc_date: [""],
      doc_validdate:[""],
      doc_duedate: [""],
      return_date:[""],
      doc_status_id: [""],
      customer_address_id: [""],
      customer_address_name: [""],
      customer_address: [""],
      doc_note: [""],
      discount: [this._utils.transformDecimal(0)],
      customer_credit_term_day: [""],
      tax: ["7"],
      grand_total_before_vat: [0.00],
      grand_total: [0.00],
      revise_count: [""],
      customer_contact_ids: [[]],
      is_return_doc_can_create: [""],
      acc_invoice_info_id: [""],
      doc_no_company: [""],
    });
    this.form.disable();
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }

  sumCount(){
    this.count = 0;
    this.taxInvoiceItem.taxInvoiceItems.map(product=>{

      if(product.quotation_item_id == '' && product.product_id == ''){
        this.count = this.count - product.discount;
      }else{
        this.count = this.count + this._calculate.sumProduct(product);
      }
    })

    this.form.controls['discount'].setValue(this._utils.transformDecimal(this.form.get('discount').value));
    this.form.controls['grand_total'].setValue(this._calculate.sumProductTotal(this.count, this.form.value));
    
    this.grandTotal = this._calculate.sumProductTotal(this.count, this.form.value);
  }

  getTaxInvoiceItems(event){
    this.taxInvoiceItem = event;

    let findCalibration = this.taxInvoiceItem.taxInvoiceItems.find(item => item.item_type == 'calibration_service');
    this.quotationItems = this.taxInvoiceItem.taxInvoiceItems.filter((invoice, index, self) =>
    index === self.findIndex((t) => (
      t.quotation_info_id === invoice.quotation_info_id 
    )));
    if(findCalibration){
      this.isShowCheckbox = true;
    }else{
      this.isShowCheckbox = false;
    }
    this.sumCount();
  }

  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }

  async confirm(){
    let response = await this.AccTaxInvoiceInfoService.update({
      ...this.taxInvoiceInfo,
      doc_status_id: 'WAITING_FOR_APPROVE',
      doc_duedate : moment(this.taxInvoiceInfo.doc_duedate).format('YYYY-MM-DD')
    });
    if (response.success) {
      this.router.navigateByUrl("/tax-invoice", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async cancel(){
    let response = await this.AccTaxInvoiceInfoService.update({
      ...this.taxInvoiceInfo,
      doc_status_id: 'DRAFT',
      doc_duedate : moment(this.taxInvoiceInfo.doc_duedate).format('YYYY-MM-DD')
    });
    if (response.success) {
      this.router.navigateByUrl("/tax-invoice", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async approve(){
    let response = await this.AccTaxInvoiceInfoService.update({
      ...this.taxInvoiceInfo,
      doc_status_id: 'WAITING_FOR_PAYMENT',
      doc_duedate : moment(this.taxInvoiceInfo.doc_duedate).format('YYYY-MM-DD')
    });
    if (response.success) {

      this.router.navigateByUrl("/tax-invoice", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async reject(){
    let response = await this.AccTaxInvoiceInfoService.update({
      ...this.taxInvoiceInfo,
      doc_status_id: 'REJECTED',
      doc_duedate : moment(this.taxInvoiceInfo.doc_duedate).format('YYYY-MM-DD')
    });
    if (response.success) {
      this.router.navigateByUrl("/tax-invoice", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async cancelTaxInvoice(){
    let response = await this.AccTaxInvoiceInfoService.update({
      ...this.taxInvoiceInfo,
      doc_status_id: 'CANCELED',
      doc_duedate : moment(this.taxInvoiceInfo.doc_duedate).format('YYYY-MM-DD')
    });
    if (response.success) {
      await Promise.all(
        this.taxInvoiceItem.taxInvoiceItems.map(async (value: any, index: number)=>{
          if(value.item_type == 'calibration_service'){
            let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
            await this.QuotationMitItemService.update({
              ...quotationMitResponse.resultData[0],
              acc_tax_invoice_info_id: "",
              acc_tax_invoice_info_doc_no: "",
              acc_tax_invoice_info_status: "",
            })
          }else{
            let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
            await this.QuotationProductItemService.update({
              ...quotationProductResponse.resultData,
              acc_tax_invoice_info_id: "",
              acc_tax_invoice_info_doc_no: "",
              acc_tax_invoice_info_status: "",
            })
          }
      }));

      this.router.navigateByUrl("/tax-invoice", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  editDate(){
    this.isEditDate = true;

    this.form.controls['return_date'].enable();
    this.form.controls['return_date'].updateValueAndValidity();
  }

  editDueDate(){
    this.isEditDueDate = true;

    this.form.controls['doc_duedate'].enable();
    this.form.controls['doc_duedate'].updateValueAndValidity();
  }

  resetEditDate(){
    this.isEditDate = false;
    this.isEditDueDate = false;

    let return_date = this.taxInvoiceInfo.return_date;
    this.form.controls['return_date'].disable();
    this.form.controls['return_date'].setValue(return_date);
    this.form.controls['return_date'].updateValueAndValidity();

    let doc_duedate = this.taxInvoiceInfo.doc_duedate;
    this.form.controls['doc_duedate'].disable();
    this.form.controls['doc_duedate'].setValue(doc_duedate);
    this.form.controls['doc_duedate'].updateValueAndValidity();
    this.resetDate = null;
    setTimeout(() => {
      this.resetDate = true;
    }, 100);
  }

  async force(){
    let response = await this.AccTaxInvoiceInfoService
    .update({
      ...this.form.getRawValue()
    })
    this.status = response.success || response.error;
    
    this.form.controls['return_date'].disable();
    this.form.controls['return_date'].updateValueAndValidity();
    this.form.controls['doc_duedate'].disable();
    this.form.controls['doc_duedate'].updateValueAndValidity();
    this.isEditDate = false;
    this.isEditDueDate = false;
  }

  getPayment($event){
    this.paymentItem = $event;
    this.ngOnInit();
  }

  getCreditNote($event){
    this.creditNoteTotal = $event.credit;
    this.debitNoteTotal = $event.debit;
  }

  printTaxInvoiceExcel(){
    this.GETService.taxInvoiceExcel(this.taxInvoiceInfo.acc_tax_invoice_info_id).then(res=>{
      let url = window.URL.createObjectURL(res.data);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = this.taxInvoiceInfo.doc_no + '_' + this.taxInvoiceInfo.customer_info.company_name + '.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  back(): void {
    this.location.back();
  }
}
