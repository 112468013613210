import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-transport-receive-item-create-no-item',
  templateUrl: './transport-receive-item-create-no-item.component.html',
  styleUrls: ['./transport-receive-item-create-no-item.component.css']
})
export class TransportreceiveItemCreateNoItemComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(   
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router
    ) { 
      this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.closeModalBtn.nativeElement.click();
        }
      });
      this.setForm();
  }
  async ngOnInit() {}

  setForm(){
    this.form = this.formBuilder.group({
      noqt_description: ["", [Validators.required]],
      quotation_info_id:[null],
      noqt_marker: ["", [Validators.required]],
      noqt_model: ["", [Validators.required]],
      noqt_serial_no: ["", [Validators.required]],
      noqt_tag_no: ["", [Validators.required]],
      item_status_id: ["WAITING_FOR_RECEIVE"],
      note: [""]
    });
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.submitted = false;
    this.done.emit(this.form.value);
    this.closeModalBtn.nativeElement.click();
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }
}
