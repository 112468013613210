import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { 
  QuotationEditHistoryService, 
  QuotationMemoService 
} from '../../../../services/quotations';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-quotation-memo',
  templateUrl: './quotation-memo.component.html',
  styleUrls: ['./quotation-memo.component.css']
})
export class QuotationMemoComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  pageLoad = false;

  memoList: any = [];
  quotation_info_id: string;
  status: any;
  constructor(
    private formBuilder: FormBuilder,
    private QuotationEditHistoryService: QuotationEditHistoryService,
    private QuotationMemoService: QuotationMemoService,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.quotation_info_id = this.route.snapshot.paramMap.get("quotation_info_id");
    this.createForm();
    this.getMemoList();
  }
  createForm(){
    this.form = this.formBuilder.group({
      memo: ["", [Validators.required]],
    });
  }
  getMemoList(){
    this.pageLoad = true;
    this.memoList = [];
    this.form.get('memo').setValue('');
    this.QuotationEditHistoryService.load(null, { quotation_info_id: this.quotation_info_id  }).then(async (res)=>{
      let historyList  = res.resultData;
      this.QuotationMemoService.load(null, { quotation_info_id: this.quotation_info_id  }).then(async (res)=>{
        let memoList  = res.resultData;
        this.memoList = [
          ...historyList,
          ...memoList
        ];
        this.memoList.sort(function(a,b){
          if(a.created_at < b.created_at) return 1;
          if(a.created_at > b.created_at) return -1;
          return 0;
        });
      })
      this.pageLoad = false;
    })
  }
  async save(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    this.submitted = false;
    let  quotationMemoResponse = await this.QuotationMemoService.create({
      quotation_info_id: this.quotation_info_id,
      ...this.form.value
    });
    if (quotationMemoResponse.success) {
      this.getMemoList();
    }
  }
}
