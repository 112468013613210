import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services';
import { 
  TransportreceiveInfoService 
} from 'src/app/services/transport-receives';
import { UserInfoState } from 'src/app/store/user/user.state';

@Component({
  selector: 'app-transport-receive-list',
  templateUrl: './transport-receive-list.component.html',
  styleUrls: ['./transport-receive-list.component.css']
})
export class TransportreceiveListComponent implements OnInit {
  form : FormGroup;
  isLoading: boolean = true;
  
  transportreceives = [];
  transportreceive = null;

  number_all_status: number = 0;
  number_draft_status: number  = 0;
  number_waiting_for_receive_status: number  = 0;
  number_waiting_for_review_status: number  = 0;
  number_waiting_for_confirm_status: number  = 0;
  number_return_success: number  = 0;
  number_receive_success: number  = 0;
  number_voided_status: number  = 0;

  status: string;
  tab: string;
  reset: boolean = false;
  load: Function = this.TransportreceiveInfoService.load;
  filter = {};
  params: any;
  initialPage: number = 1;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private fbd: FormBuilder,
    private TransportreceiveInfoService: TransportreceiveInfoService,

    public _UtilsService: UtilsService,
  ) { 
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
    this.route.queryParams
    .subscribe(params => {
      if(params){
      let page = parseInt(params.page);

          this.params = params;
          this.filter = {
            ...params,
            offset: page == 1 || isNaN(page)? 0: ((page-1)* 10),
            page: this._UtilsService.setUndefined()
          };

          this.initialPage = isNaN(page)? 1: page;
      }
    }
  );
  }
  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit(): void {
    this.form = this.fbd.group({
      pickup_date: [""],
    });
    this.form.get('pickup_date').setValue(moment(new Date()).format('YYYY MM DD'));
    if(JSON.parse(localStorage.getItem('mit_cache'))?.transport_receive_tab){
      this.searchStatus(JSON.parse(localStorage.getItem('mit_cache')).transport_receive_tab);
    }
    this.getCountOfStatus(null);
  }

  getCountOfStatus(filter: object){
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;
      this.number_all_status = 0;
      if(userInfo?.emp_info?.emp_position_info?.emp_position_id.startsWith("TRAN_DEP-03")){
        this.filter = { receiver_id: userInfo.user_id };
        
        filter = this.filter;
      }
      this.TransportreceiveInfoService.load({limit:0}, {...filter, doc_status_id: 'DRAFT'}).then(res=>{
        this.number_draft_status = res.rowCount;
      });
      this.TransportreceiveInfoService.load({limit:0}, {...filter, doc_status_id: 'WAITING_FOR_RECEIVE'}).then(res=>{
        this.number_waiting_for_receive_status = res.rowCount;
      });
      this.TransportreceiveInfoService.load({limit:0}, {...filter, doc_status_id: 'RECEIVED_SUCCESS'}).then(res=>{
        this.number_receive_success = res.rowCount;
      });
      this.TransportreceiveInfoService.load({limit:0}, {...filter, doc_status_id: 'RECEIVE_WAITING_FOR_REVIEW'}).then(res=>{
        this.number_waiting_for_review_status = res.rowCount;

      });
      this.TransportreceiveInfoService.load({limit:0}, {...filter, doc_status_id: 'RECEIVE_WAITING_FOR_CONFIRM'}).then(res=>{
        this.number_waiting_for_confirm_status = res.rowCount;
      });
      this.TransportreceiveInfoService.load({limit:0}, {...filter, doc_status_id: 'RETURNED_SUCCESS'}).then(res=>{
        this.number_return_success = res.rowCount;
      });
      this.TransportreceiveInfoService.load({limit:0}, {...filter, doc_status_id: 'VOIDED'}).then(res=>{
        this.number_voided_status = res.rowCount;
      });

      this.form.controls.pickup_date.valueChanges.subscribe(
        (pickup_date) => {
          if(pickup_date && !userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP")){
            this.searchDate(pickup_date);
          }
        }
      );
    });
  }

  onChangePage(transportreceives: Array<any>) {
    this.transportreceives = transportreceives;
    this.isLoading = false;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {

    this.filter = { ...this.filter, ...filter };

    this.setQueryUrl();
  }

  setQueryUrl(){
    let filter = {
      ...this.filter,
      page: this.initialPage,
      offset: this._UtilsService.setUndefined(),
    }
    this.router.navigate([], {
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
    this.params = filter;
  }

  getFilterLimit(filterLimit){

    if(this.initialPage != filterLimit.initialPage && filterLimit.initialPage != 0){
  
      let params = {
        ...this.filter,
        page: filterLimit.initialPage,
        offset: this._UtilsService.setUndefined()
      }

      this.router.navigate([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.params = params;
    }
  }
  
  searchStatus(status:string = null){
    this.tab = status || null;

    let mit_store = {
      ...JSON.parse(localStorage.getItem('mit_cache')),
      transport_receive_tab: status
    }
    localStorage.setItem('mit_cache', JSON.stringify(mit_store));

    let condition = {
      doc_status_id: status,
    };
    this.search(condition);
  }
  searchDate(date){
    let condition = {
      "pickup_date": date
    };
    this.search(condition);
  }
  clearDate(){
    this.form.get('pickup_date').setValue('');
    let condition = {
      "pickup_date": undefined
    };
    this.search(condition);
  }
  select(transportreceive: string) {
    this.transportreceive = Object.assign({}, transportreceive);
  }

  async cancel(closeModal){
    if(closeModal){
      var response = await this.TransportreceiveInfoService.update({
        ...this.transportreceive,
        doc_status_id:'VOIDED'
      });
      this.getCountOfStatus(null);
      this.doReset(response.success || response.error);
      closeModal.click();
    }
  }
}
