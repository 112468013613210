import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
declare const $;
@Directive({
  selector: "[google-map]",
})
export class GoogleMapDirective {
  @Output() change = new EventEmitter();
  @Input("default-location") defaultLocation;
  @Input("disable-click") disableClick = true;

  map = null;
  marker = null;
  clickListener = null;
  search_box = null;
  constructor(el: ElementRef) {
    setTimeout(() => {
      let id = $(el.nativeElement).attr("id");
      let selector = "#" + id;
      $(el.nativeElement).attr("style", "width:100%;height:100%;");

      navigator.geolocation.getCurrentPosition((position) => {
        let currentLocation = {
          lat: 13.765013,
          lng: 100.538242,
        };

        if (this.defaultLocation) {
          let { lat, lng } = this.defaultLocation;
          currentLocation = {
            lat: Number(lat),
            lng: Number(lng),
          };
        }
        let map = new google.maps.Map(document.getElementById(id), {
          center: currentLocation,
          zoom: 17,
        });
        this.map = map;

        let card = document.getElementById("pac-card");
        let input = $(selector)
          .parent()
          .find(".map-autocomplete-box")[0] as HTMLInputElement;
        this.search_box = input;

        map.controls[google.maps.ControlPosition.TOP_RIGHT].push(card);

        var autocomplete = new google.maps.places.Autocomplete(input);

        // Bind the map's bounds (viewport) property to the autocomplete object,
        // so that the autocomplete requests use the current map bounds for the
        // bounds option in the request.
        autocomplete.bindTo("bounds", map);

        // Set the data fields to return when the user selects a place.
        autocomplete.setFields([
          "address_components",
          "geometry",
          "icon",
          "name",
        ]);

        // var infowindow = new google.maps.InfoWindow();
        // var infowindowContent = document.getElementById("infowindow-content");
        // infowindow.setContent(infowindowContent);
        let marker = new google.maps.Marker({
          position: currentLocation,
          map: map,
          anchorPoint: new google.maps.Point(0, -29),
        });
        this.marker = marker;
        // map.setCenter(currentLocation)
        // marker.setPosition(currentLocation)
        // marker.setVisible(true);

        autocomplete.addListener("place_changed", () => {
          // infowindow.close();'
          var place = autocomplete.getPlace();
          if (!place.geometry) {
            // User entered the name of a Place that was not suggested and
            // pressed the Enter key, or the Place Details request failed.
            window.alert(
              "No details available for input: '" + place.name + "'"
            );
            return;
          }

          // If the place has a geometry, then present it on a map.
          if (place.geometry.viewport) {
            map.fitBounds(place.geometry.viewport);
          } else {
            map.setCenter(place.geometry.location);
            map.setZoom(17); // Why 17? Because it looks good.
          }

          let location = {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          };
          marker.setPosition(location);
          this.triggerSearchBox(location);
          this.change.emit(location);
        });
        if (!this.disableClick) {
          this.addClickEvent();
        }
        this.triggerSearchBox(currentLocation);
        this.change.emit(currentLocation);
        this.map = map;
      });
    }, 500);
  }

  addClickEvent() {
    let interval = setInterval(() => {
      clearInterval(interval);
      if (this.map) {
        this.clickListener = this.map.addListener("click", (mapsMouseEvent) => {
          let location = {
            lat: mapsMouseEvent.latLng.lat(),
            lng: mapsMouseEvent.latLng.lng(),
          };

          this.marker.setPosition(location);
          this.triggerSearchBox(location);

          window.setTimeout(() => {
            this.map.panTo(this.marker.getPosition());
          }, 1000);

          this.change.emit(location);
        });
      }
    }, 100);
  }

  triggerSearchBox(location) {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          this.search_box.value = results[0].formatted_address;
        } else {
          console.error("No results found");
          this.search_box.value = "";
        }
      } else {
        console.error("Geocoder failed due to: " + status);
        this.search_box.value = "";
      }
    });
  }

  removeClickEvent() {
    google.maps.event.removeListener(this.clickListener);
  }

  ngOnChanges(changes) {
    if (changes.disableClick) {
      if (changes.disableClick.currentValue) {
        this.removeClickEvent();
      } else {
        this.addClickEvent();
      }
    }
  }
}
