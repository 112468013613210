<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการรับเครื่องมือ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/transport-receive']">การรับเครื่องมือ</a>
                </li>
                <li class="breadcrumb-item active">รายการรับเครื่องมือ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button class="btn btn-outline-primary"                     
                data-target="#transport-receive-filter"
                data-toggle="modal">
              <i class="feather icon-plus-circle icon-left"></i> สร้างใบรับเครื่องมือ
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <h4 class="card-title mt-1 col-12 col-sm-4 col-md-6 col-lg-9">รายการรับเครื่องมือ</h4>
                  <span class="col-12 col-sm-8 col-md-6 col-lg-3">
                    <search-box (search)="search($event)" [placeholder]="'ค้นหาเลขที่เอกสาร/รหัส/ชื่อลูกค้า'" [search_all]="params?.search_all"></search-box>
                  </span>
                </div>
              </div>
  
              <div class="card-content collapse show">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-9 align-self-end">
                          <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "tab == null" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchStatus()">ทั้งหมด</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "tab === 'DRAFT'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchStatus('DRAFT')">ร่าง ({{number_draft_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "tab === 'WAITING_FOR_RECEIVE'" id="pill3" data-toggle="pill" href="#pill3" aria-expanded="false" (click)="searchStatus('WAITING_FOR_RECEIVE')">รอเข้ารับ ({{ number_waiting_for_receive_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "tab === 'RECEIVE_WAITING_FOR_REVIEW'" id="pill0" data-toggle="pill" href="#pill0" aria-expanded="false" (click)="searchStatus('RECEIVE_WAITING_FOR_REVIEW')">เข้ารับหน้างานแล้ว ({{number_waiting_for_review_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "tab === 'RECEIVE_WAITING_FOR_CONFIRM'" id="pill01" data-toggle="pill" href="#pill01" aria-expanded="false" (click)="searchStatus('RECEIVE_WAITING_FOR_CONFIRM')">รอยืนยันรายการ ({{number_waiting_for_confirm_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "tab === 'RECEIVED_SUCCESS'" id="pill4" data-toggle="pill" href="#pill4" aria-expanded="false" (click)="searchStatus('RECEIVED_SUCCESS')">เข้ารับเสร็จสิ้น ({{number_receive_success}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "tab === 'RETURNED_SUCCESS'" id="pill9" data-toggle="pill" href="#pill9" aria-expanded="false" (click)="searchStatus('RETURNED_SUCCESS')">ส่งคืนเสร็จสิ้น ({{number_return_success}})</a>
                            </li>
                            <li class="nav-item border-light">
                                <a class="nav-link text-light py-0" [class.active] = "tab === 'VOIDED'" id="pill7" data-toggle="pill" href="#pill7" aria-expanded="false" (click)="searchStatus('VOIDED')">ยกเลิก ({{number_voided_status}})</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                          <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
                            <div class="input-group mt-0 mb-1" *ngIf="!(userInfo$ | async)?.is_sales">
                              <input
                              date-picker
                              type="text"
                              id="pickup_date"
                              [date]="form.value.pickup_date"
                              class="form-control"
                              formControlName="pickup_date"
                              [max-date]="undefined"
                              placeholder="วันที่นัดรับเครื่องมือ"
                              />
                              <div
                              class="input-group-append"
                              for="pickup_date"
                              >
                              <span class="input-group-text rounded-0 btn-primary text-white"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                              <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                  ><i class="fa fa-times"></i>
                              </span>
                            </div>
                          </form>
                        </div>
                    </div>
                    <loading *ngIf="isLoading"></loading>
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                <th class="text-center">เลขที่เอกสาร</th>
                                <th>ชื่อลูกค้า</th>
                                <th>วันที่นัดรับเครื่องมือ</th>
                                <th>กำหนดส่งคืนเครื่องมือ</th>
                                <th class="text-center">ประเภทรถ</th>
                                <th class="text-center">สถานะ</th>
                                <th>พนักงานขาย</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let transportreceive of transportreceives; let i = index">
                                    <th>
                                      <div>{{transportreceive.doc_no}}</div>
                                      <small *ngIf="transportreceive.receive_type =='WITTHOUT_QT'">(ไม่มีใบเสนอราคา)</small>
                                    </th>
                                    <td>
                                      <div>
                                        [{{transportreceive?.customer_info?.customer_code}}] {{transportreceive?.customer_info?.company_name}}
                                        <br>
                                        <small [ngSwitch]="transportreceive?.customer_address_info?.address?.province_id">
                                          <span *ngSwitchCase="'1'">
                                            {{ transportreceive?.customer_address_info?.address }} {{(transportreceive.customer_address_info?.district_name)?'แขวง': ''}} {{ transportreceive?.customer_address_info?.district_name }}
                                            {{(transportreceive?.customer_address_info?.amphure_name)?'เขต': ''}}{{ transportreceive?.customer_address_info?.amphure_name }}
                                            {{ transportreceive?.customer_address_info?.province_name }}
                                            {{ transportreceive?.customer_address_info?.zip_code }}
                                          </span>
                                          <span *ngSwitchDefault>
                                            {{ transportreceive.customer_address_info?.address }} {{(transportreceive.customer_address_info?.district_name)?'ตำบล': ''}}{{ transportreceive.customer_address_info?.district_name }}
                                            {{(transportreceive.customer_address_info?.amphure_name)?'อำเภอ': ''}}{{ transportreceive.customer_address_info?.amphure_name }}
                                            {{ transportreceive.customer_address_info?.province_name }}
                                            {{ transportreceive.customer_address_info?.zip_code }}
                                          </span>
                                        </small>
                                        <br>
                                      </div>
                                     
                                    </td>
                                    <td>{{transportreceive.pickup_date | thai_date: "short":"date" | dash}}</td>
                                    <td>{{transportreceive.return_date | thai_date: "short":"date" | dash}}</td>
                                    <td class="text-center">
                                      {{transportreceive.transport_type_info?.transport_type_name_th}}
                                    </td>
                                    <td class="text-center">
                                      {{transportreceive.doc_status_info?.document_status_name_th || transportreceive.doc_status_id}}
                                    </td>
                                    <td>
                                      {{transportreceive.sales_assignment_info?.user_info?.firstname_th}} {{transportreceive.sales_assignment_info?.user_info?.lastname_th || '-'}}
                                    </td>
                                    <td>
                                        <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm"
                                        [routerLink]="[
                                          '/transport-receive/' + transportreceive.transport_receive_info_id + '/show'
                                        ]">
                                          <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                        </a>

                                        <a 
                                          [routerLink]="[
                                            '/transport-document/' + transportreceive.transport_receive_info_id
                                          ]"
                                          type="button" class="btn mr-1 mb-1 btn-blue btn-sm text-nowrap"
                                          target="_blank">
                                          <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                                        </a>
                                    </td>
                                </tr>
                                <tr *ngIf="transportreceives.length === 0">
                                    <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
  
                    <app-paginator
                      *ngIf="userInfo$ | async"
                      [filter]="filter"
                      [reset]="reset"
                      [fetch]="load"
                      [initialPage]="initialPage"
                      (filterLimit)="getFilterLimit($event)"
                      (changePage)="onChangePage($event)">
                    </app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>

<app-transport-receive-create-filter>
</app-transport-receive-create-filter>

<app-transport-cancel 
(save)="cancel($event)" >
</app-transport-cancel>