import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TransportreceiveItemService {
  async create({
    transport_receive_id,
    quotation_info_id,
    quotation_mit_item_id,
    item_status_id,
    note,
    order,
    returned_at,
    returned_by
  }) {
    let result = null;
    try {
      result = await Axios().post(`/transport-receive-item`, {
        transport_receive_id,
        quotation_info_id,
        quotation_mit_item_id: [quotation_mit_item_id],
        item_status_id,
        note,
        order,
        returned_at,
        returned_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      transport_receive_item_id,
      transport_receive_id,
      quotation_info_id,
      quotation_mit_item_id,
      item_status_id,
      note,
      order,
      returned_at,
      returned_by
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/transport-receive-item/${transport_receive_item_id}`, {
        transport_receive_id,
        quotation_info_id,
        quotation_mit_item_id,
        item_status_id,
        note,
        order,
        returned_at,
        returned_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async createNoQT({
    transport_receive_id,
    noqt_description,
    noqt_marker,
    noqt_model,
    noqt_serial_no,
    noqt_tag_no,
    item_status_id,
    note,
    order
  }) {
    let result = null;
    try {
      result = await Axios().post(`/transport-receive-item`, {
        transport_receive_id,
        noqt_description,
        noqt_marker,
        noqt_model,
        noqt_serial_no,
        noqt_tag_no,
        item_status_id,
        note,
        order
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async updateNoQT(data) {
    let {
      transport_receive_item_id,
      transport_receive_id,
      quotation_mit_item_id,
      quotation_info_id,
      noqt_quotation_info_id,
      noqt_description,
      noqt_marker,
      noqt_model,
      noqt_serial_no,
      noqt_tag_no,
      item_status_id,
      note,
      order
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/transport-receive-item/${transport_receive_item_id}`, {
        transport_receive_id,
        quotation_mit_item_id,
        quotation_info_id,
        noqt_quotation_info_id,
        noqt_description,
        noqt_marker,
        noqt_model,
        noqt_serial_no,
        noqt_tag_no,
        item_status_id,
        note,
        order
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ transport_receive_item_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/transport-receive-item/${transport_receive_item_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-item`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ transport_receive_item_id }) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-item/${transport_receive_item_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  async getCode(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-code`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
