import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class CustomerAdditionFieldService {
  async create({
    customer_id,
    swot_strength,
    swot_weakness,
    swot_opportunity,
    swot_threat,
    calibration_cycle_month,
    calibration_cycle_memo,
    lab_using,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/customer-addition-field`, {
        customer_id,
        swot_strength,
        swot_weakness,
        swot_opportunity,
        swot_threat,
        calibration_cycle_month,
        calibration_cycle_memo,
        lab_using,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    customer_addition_field_id,
    customer_id,
    swot_strength,
    swot_weakness,
    swot_opportunity,
    swot_threat,
    calibration_cycle_month,
    calibration_cycle_memo,
    lab_using,
  }) {
    let result = null;
    try {
      result = await Axios().put(
        `/customer-addition-field/${customer_addition_field_id}`,
        {
          customer_id,
          swot_strength,
          swot_weakness,
          swot_opportunity,
          swot_threat,
          calibration_cycle_month,
          calibration_cycle_memo,
          lab_using,
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ customer_addition_field_id }) {
    let result = null;
    try {
      result = await Axios().delete(
        `/customer-addition-field/${customer_addition_field_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/customer-addition-field`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ customer_addition_field_id }) {
    let result = null;
    try {
      result = await Axios().get(
        `/customer-addition-field/${customer_addition_field_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
