<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">สร้างใบวางบิล</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/billing-note']">เอกสารทางบัญชี</a>
                </li>
                <li class="breadcrumb-item active">สร้างใบวางบิล</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <a href="#" [routerLink]="['/billing-note']" class="btn btn-warning ml-1">
                <i class="fa fa-times icon-left"></i> ยกเลิก
            </a>

            <!-- <button class="btn btn-primary mr-1" (click)="saveAsDraft()">
                <i class="fa fa-save icon-left"></i> บันทึกแบบร่าง
            </button>

            <button class="btn btn-primary" (click)="save()">
              <i class="fa fa-save icon-left"></i> บันทึก และส่งอนุมัติ
            </button> -->

            <button class="btn btn-primary ml-1" (click)="saveAsApprove()" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึก
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="!pageLoad"></loading>

        <div class="card" *ngIf="pageLoad">
          <div class="card-header">
              <h4 class="card-title mt-1">สร้างใบวางบิล</h4>
              <div class="heading-elements">
              </div>
          </div>

          <div class="card-content collapse show">
              <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
                  <div class="card-body">
                    <div class="form-body">
                      <div class="row">
                          <div class="col-xl-3 col-lg-6 col-md-12">
                              <form-group
                                  [control]="form.controls.customer_id"
                                  [submitted]="submitted"
                                >
                                <label for="customer_id">
                                  ชื่อลูกค้า
                                </label>
                                <input
                                  type="text"
                                  id="customer_name"
                                  class="form-control"
                                  placeholder="ชื่อลูกค้า"
                                  formControlName="customer_name"
                                  readonly
                                />
                              </form-group>
                          </div>

                          <div class="col-lg-3 col-md-12">
                            <form-group
                              [control]="form.controls.customer_address_id"
                                  [submitted]="submitted"
                                >
                                  <label for="customer_address_id">
                                    ออกเอกสารในนาม <span class="danger">*</span>
                                  </label>
                                  <ng-select2
                                    [data]="customerAddressList"
                                    [options]="{
                                      language: UtilsService.select2OptionsLanguage()
                                    }"
                                    class="w-100"
                                    id="customer_address_id"
                                    placeholder="ออกเอกสารในนาม"
                                    formControlName="customer_address_id"
                                    (valueChanged)="selectAddressName($event)"
                                  >
                                  </ng-select2>
                              </form-group>
                          </div>

                          <div class="col-xl-3 col-lg-6 col-md-12">
                              <form-group
                              [control]="form.controls.doc_date"
                              [submitted]="submitted">
                              <label for="doc_date">
                                  วันที่ออก <span class="danger">*</span>
                              </label>
                              <div class="input-group mt-0">
                                  <input
                                  date-picker
                                  type="text"
                                  id="doc_date"
                                  [max-date]="undefined"
                                  [date]="form.value.doc_date"
                                  class="form-control"
                                  formControlName="doc_date"
                                  />
                                  <div
                                  class="input-group-append"
                                  for="doc_date"
                                  >
                                  <span class="input-group-text"
                                      ><i class="feather icon-calendar"></i
                                  ></span>
                                  </div>
                              </div>
                              </form-group>
                          </div>

                          <div class="col-xl-3 col-lg-6 col-md-12">
                              <!-- <form-group
                              [control]="form.controls.doc_no"
                              [submitted]="submitted">
                              <label for="doc_no">
                                  เลขที่เอกสาร
                              </label>
                              <input
                                  type="text"
                                  id="doc_no"
                                  class="form-control"
                                  placeholder="เลขที่เอกสาร"
                                  formControlName="doc_no"
                                  readonly
                              />
                              </form-group> -->
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-xl-3 col-lg-6 col-md-12">
                            <form-group
                                [submitted]="submitted"
                              >
                              <label for="customer_id">
                                Billing Day
                              </label>
                              <input
                                type="text"
                                id="customer_name"
                                class="form-control"
                                placeholder="Billing Day"
                                [value]="customer_info?.billing_day"
                                readonly
                              />
                            </form-group>
                          </div>
                        
                          <div class="col-lg-6 col-md-12">
                            <form-group
                                [control]="form.controls.customer_address"
                                [submitted]="submitted"
                                >
                                <label for="customer_address">
                                    ที่อยู่ 
                                </label>
                                <input
                                type="text"
                                id="customer_address"
                                class="form-control"
                                placeholder="ที่อยู่"
                                formControlName="customer_address"
                                readonly
                                />
                            </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12">

                            <form-group
                            [control]="form.controls.customer_contact_ids"
                                [submitted]="submitted"
                            >
                                <label for="customer_contact_ids">
                                ผู้ติดต่อ <span class="danger">*</span>
                                </label>
                                <ng-select2
                                [data]="customerContactList"
                                [options]="{
                                  templateResult: NgSelect2Customer?.templateContactResult,
                                  multiple: true,
                                  language: UtilsService.select2OptionsLanguage()
                                }"
                                class="w-100"
                                id="customer_contact_ids"
                                placeholder="ผู้ติดต่อ"
                                formControlName="customer_contact_ids"
                                >
                                </ng-select2>
                            </form-group>

                        </div>
                        </div>

                        <div class="row">
                          <div class="col-xl-3 col-lg-6 col-md-12">
                            <form-group
                            [control]="form.controls.appointment_date"
                            [submitted]="submitted">
                            <label for="appointment_date">
                                วันที่นัดวางบิล <span class="danger">*</span>
                            </label>
                            <div class="input-group mt-0">
                                <input
                                date-picker
                                type="text"
                                placeholder="วันที่นัดวางบิล"
                                id="appointment_date"
                                [max-date]="undefined"
                                [date]="form.value.appointment_date"
                                class="form-control"
                                formControlName="appointment_date"
                                />
                                <div
                                class="input-group-append"
                                for="appointment_date"
                                >
                                <span class="input-group-text"
                                    ><i class="feather icon-calendar"></i
                                ></span>
                                </div>
                            </div>
                            </form-group>
                          </div>

                          <div class="col-xl-3 col-lg-6 col-md-12">
                            <label for="appointment_time_from">
                              เวลา
      
                            </label>
                            <div class="row">
                              <div class="col-5 pr-0">
                                <form-group
                                  [control]="form.controls.appointment_time_from"
                                  [submitted]="submitted">
                                  <div class="input-group mt-0">
                                    <input
                                        time-picker
                                        type="text"
                                        placeholder="เวลา"
                                        id="appointment_time_from"
                                        #appointment_time_from
                                        class="form-control"
                                        formControlName="appointment_time_from"
                                    />
                                  </div>
                                </form-group>
                              </div>

                              <div class="col-2 text-nowrap text-center">ถึง</div>

                              <div class="col-5 pl-0">
                                <form-group
                                  [control]="form.controls.appointment_time_to"
                                  [submitted]="submitted">
                                  <div class="input-group mt-0">
                                    <input
                                        time-picker
                                        type="text"
                                        placeholder="เวลา"
                                        id="appointment_time_to"
                                        #appointment_time_to
                                        class="form-control"
                                        formControlName="appointment_time_to"
                                    />
                                  </div>
                                </form-group>
                              </div>
                            </div>
                          </div>


                          <div class="col-xl-6 col-lg-6 col-md-12">
                            <form-group
                            [control]="form.controls.appointment_location"
                            [submitted]="submitted">
                            <label for="appointment_location">
                                สถานที่
                            </label>
                            <div class="input-group mt-0">
                                <input
  
                                type="text"
                                placeholder="สถานที่"
                                id="appointment_location"
                                class="form-control"
                                formControlName="appointment_location"
                                />
                            </div>
                            </form-group>
                          </div>

                          <div class="col-xl-3 col-lg-6 col-md-12">
                            <form-group
                            [control]="form.controls.sent_at"
                            [submitted]="submitted">
                            <label for="sent_at">
                                วันที่ส่งจริง
                            </label>
                            <div class="input-group mt-0">
                                <input
                                date-picker
                                type="text"
                                placeholder="วันที่ส่งจริง"
                                id="sent_at"
                                [max-date]="undefined"
                                [date]="form.value.sent_at"
                                class="form-control"
                                formControlName="sent_at"
                                />
                                <div
                                class="input-group-append"
                                for="sent_at"
                                >
                                <span class="input-group-text"
                                    ><i class="feather icon-calendar"></i
                                ></span>
                                </div>
                            </div>
                            </form-group>
                          </div>

                        </div>
                    </div>

                    <div>
                      <app-billing-note-item 
                      *ngIf="customer_info"
                      [customer_info]="customer_info"
                      (done)="getBillingNoteItems($event)">
                      </app-billing-note-item>
                    </div>

                    <div class="pb-2">
                        <div class="row">
                            <div class="col-md-6 col-sm-6 mt-75">
                                <form-group
                                [control]="form.controls.doc_note"
                                [submitted]="submitted">
                                <label for="doc_note">
                                    หมายเหตุ
                                </label>
                                <textarea
                                  placeholder="หมายเหตุ"
                                  class="form-control"
                                  rows="3"
                                  id="doc_note"
                                  formControlName="doc_note"
                                  >
                                </textarea>
                                </form-group>
                            </div>
                        </div>
                    </div>
                  </div>
              </form>
          </div>
      </div>
      </div>
    </div>
</div>