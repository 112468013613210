import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CustomerAddressService, CustomerContactService } from 'src/app/services';
import { 
  AccInvoiceContactService,
  AccInvoiceInfoService, 
  AccInvoiceItemService
} from 'src/app/services/invoice';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import domtoimage from 'dom-to-image-more'
import jsPDF from 'jspdf'; 
import THBText from 'thai-baht-text'
import { PDF } from '../../../quotation/quotation-pdf/pdf.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  invoiceItems = [];
  pages = [];

  acc_invoice_info_id: string = "";
  token: string = "";

  customer_info = null;
  invoiceInfo = null;
  count: number = 0;
  contact = null;

  canAccess: boolean = false;
  pageLoad: boolean = false;

  constructor(
    private getDataPdf: PDF,
    private titleService: Title,
    private route: ActivatedRoute,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccInvoiceItemService: AccInvoiceItemService,
    private CustomerAddressService: CustomerAddressService,
    private AccInvoiceContactService: AccInvoiceContactService,
    private CustomerContactService: CustomerContactService,

    public _utils: QuotationUtilsService,
    public _calculate: CalculateFunctionService,
  ) { 
    this.acc_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_invoice_info_id");

    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
  }
  

  async ngOnInit() {
    if(!this.token){
      await this.getPrivateData();
    }else{
      await this.getPublicData();
    }
  }

  async getPrivateData(){
    let customerContacts = [];
    await this.AccInvoiceInfoService.getById({acc_invoice_info_id: this.acc_invoice_info_id})
    .then(async res=>{

      if(res.success){
        this.canAccess = true;
        this.pageLoad = true;
  
        this.invoiceInfo = res.resultData || {};
        this.titleService.setTitle(this.invoiceInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
        if(this.invoiceInfo.customer_address_id){
          await this.CustomerAddressService.getById({customer_address_id: this.invoiceInfo.customer_address_id})
          .then(res=>{
            this.invoiceInfo['customer_address_name'] = res.resultData.address_name;
            this.invoiceInfo['customer_address'] = this._utils.genAddress(res.resultData);
            this.invoiceInfo['customer_address_info'] = res.resultData;
          })
        }
  
        await this.AccInvoiceItemService.load(null, {acc_invoice_info_id: this.acc_invoice_info_id})
        .then(async res=>{
          this.invoiceItems = res.resultData || [];
  
          this.invoiceItems = this.invoiceItems.map(value=>{
            return {
              ...value,
              data: {
                ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
                quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
              }
            }
          })
        });
        await this.CustomerContactService.load(null, {order_by: "isMaster:desc",customer_id: this.invoiceInfo.customer_id  }).then((res) => {
          customerContacts = res.resultData || [];
        });
        await this.AccInvoiceContactService.load(null, { acc_invoice_info_id: this.acc_invoice_info_id }).then((res) => {
          this.contact = res.resultData[0];
          if(this.contact){
            this.contact['customer_contact_info'] = customerContacts.find(cus=> cus.customer_contact_id == this.contact.customer_contact_id);
          }
        });
  
        for (var i=0; i<this.invoiceItems.length; i++) {
          this.invoiceItems[i].index = i + 1;
          this.invoiceItems[i].type = 'invoice';
        }
  
        let numberOfempty = 13-this.invoiceItems.length;
        for(var i=0; i<numberOfempty; i++){
          this.invoiceItems.push(
            { 
              type: 'empty'
            }
          );
        }
  
        this.sumCount();
        for (var i=0; i<this.invoiceItems.length; i+=13) {
          this.pages.push(this.invoiceItems.slice(i, i+13));
        }
      }else{
        this.canAccess = false;
        this.pageLoad = true;
        this.titleService.setTitle(' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
      }
    })
  }

  async getPublicData(){
    let customerContacts = [];

    try {
      await this.getDataPdf
      .get(this.token, "/acc-invoice-info/" + this.acc_invoice_info_id)
      .then(async (res) => {
        this.canAccess = true;
        this.pageLoad = true;
        this.invoiceInfo = res.resultData || {};
        this.titleService.setTitle(this.invoiceInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');

        if(this.invoiceInfo.customer_address_id){
          await this.getDataPdf.get(this.token, "/customer-address/" + this.invoiceInfo.customer_address_id)
          .then(async (res) => {
            this.invoiceInfo['customer_address_name'] = res.resultData.address_name;
            this.invoiceInfo['customer_address'] = this._utils.genAddress(res.resultData);
            this.invoiceInfo['customer_address_info'] = res.resultData;
          })
        }

        await this.getDataPdf
        .get(this.token, "/acc-invoice-items?acc_invoice_info_id=" + this.acc_invoice_info_id)
        .then(async res=>{
          this.invoiceItems = res.resultData || [];
  
          this.invoiceItems = this.invoiceItems.map(value=>{
            return {
              ...value,
              data: {
                ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
                quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
              }
            }
          })
        });
        await this.getDataPdf
        .get(this.token, "/customer-contact?order_by=isMaster:desc&customer_id=" + this.invoiceInfo.customer_id)
        .then((res) => {
          customerContacts = res.resultData || [];
        });
        await this.getDataPdf
        .get(this.token, "/acc-invoice-contact?acc_invoice_info_id=" + this.acc_invoice_info_id)
        .then((res) => {
          this.contact = res.resultData[0];
          if(this.contact){
            this.contact['customer_contact_info'] = customerContacts.find(cus=> cus.customer_contact_id == this.contact.customer_contact_id);
          }
        });

        for (var i=0; i<this.invoiceItems.length; i++) {
          this.invoiceItems[i].index = i + 1;
          this.invoiceItems[i].type = 'invoice';
        }
  
        let numberOfempty = 13-this.invoiceItems.length;
        for(var i=0; i<numberOfempty; i++){
          this.invoiceItems.push(
            { 
              type: 'empty'
            }
          );
        }
  
        this.sumCount();
        for (var i=0; i<this.invoiceItems.length; i+=13) {
          this.pages.push(this.invoiceItems.slice(i, i+13));
        }
      });
    } catch (error) {
      this.canAccess = false;
      this.pageLoad = true;
      this.titleService.setTitle(' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
    }
  }

  sumCount(){
    this.count = 0;
    this.invoiceItems.filter(item=> item.type == 'invoice').map(v=>{
      this.count = this.count + this._calculate.sumProduct(v);
    });
    let grand_total = this._calculate.sumProductTotal(this.count, this.invoiceInfo);
    this.invoiceInfo.grand_total = grand_total;
    if (grand_total > 0) {
      this.invoiceInfo['THBText'] = THBText(Math.abs(grand_total));
    }else if(grand_total < 0){
      this.invoiceInfo['THBText'] = 'ลบ' + THBText(Math.abs(grand_total));
    }else{
      this.invoiceInfo['THBText'] = 'ศูนย์บาทถ้วน';
    }
  }

  async print(invoiceInfo){
    const invoice_sections = document.querySelectorAll('.pdf');
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let page = 1;
    let perPage = Array.prototype.slice.call(invoice_sections,0).length;
    
    let invoiceList = [];
    await Promise.all(Array.prototype.slice.call(invoice_sections,0).map(async (value, index) =>{
      let invoice_section = document.getElementById('invoice_' + (index));
      await domtoimage.toJpeg(invoice_section, {
        background: 'white', 
        allowTaint: true,
        width: invoice_section.clientWidth * 2,
        height: invoice_section.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        invoiceList.push({index: index, canvas: canvas, invoice_section: invoice_section});
      })
    }));
    invoiceList.sort(function(a, b){return a.index - b.index});
    await Promise.all(invoiceList.map(async (value, index) =>{

      var imgWidth = 208;
      var imgHeight = value.invoice_section.clientHeight * imgWidth / value.invoice_section.clientWidth;
      if((index) != 0) doc.addPage('a4');
      doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);
      doc.setFontSize(8);
      doc.text(page + ' /  ' + perPage, 200, 290);
      page = page+1;
    }));

    setTimeout(() => {
      doc.save(invoiceInfo.doc_no);
    }, 1000);
  }

  getPoNo(){
    let item = this.invoiceItems.filter(item=> item.type == 'invoice').filter((invoice, index, self) =>
    index === self.findIndex((t) => (
      t?.quotation_info_id === invoice?.quotation_info_id 
    )))
    var out = [];

    item?.map((item, index)=>{
      item?.quotation_info[0]?.quotation_accepted_info.map(a=>{
        out.push(a.accepted_po_no);
      })
    })

    return out.join(", ");
  }

  getQuotationNo(){
    let item = this.invoiceItems.filter(item=> item.type == 'invoice').filter((invoice, index, self) =>
    index === self.findIndex((t) => (
      t?.quotation_info_id === invoice?.quotation_info_id 
    )))
    var out = [];

    item?.map((item, index)=>{
      if(item.quotation_info){
        out.push(item.quotation_info[0].doc_no);
      }
    })

    return out.join(", ");
  }
}
