import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobOrderStatusWorkService {
  async create({
    job_order_info_id,
    item_type,
    item_id,
    item_status_id,
    status,
    service_date,
    reject_reason,
    created_by,
    note
  }) {
    let result = null;
    try {
      result = await Axios().post(`/job-order-status-work`, {
        job_order_info_id,
        item_type,
        item_id,
        item_status_id,
        status,
        service_date,
        reject_reason,
        created_by,
        note
      });
      result = result?.data || {};
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async update(data) {
    let {
      job_order_status_work_id,
      job_order_info_id,
      item_type,
      item_id,
      item_status_id,
      status,
      service_date,
      reject_reason,
      created_by,
      note
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/job-order-status-work/${job_order_status_work_id}`, {
        job_order_info_id,
        item_type,
        item_id,
        item_status_id,
        status,
        service_date,
        reject_reason,
        created_by,
        note
      });
      result = result?.data || {};
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async delete({ job_order_status_work_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/job-order-status-work/${job_order_status_work_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-status-work`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result?.data || {};
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async getById({ job_order_status_work_id }) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-status-work/${job_order_status_work_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
