import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  EMAIL_TEMPLATE,
  EmailTemplateAttachmentCreate,
  EmailTemplateAttachmentService,
  UploadService,
} from "src/app/services";

@Component({
  selector: "app-email-template-attachment-create",
  templateUrl: "./email-template-attachment-create.component.html",
  styleUrls: ["./email-template-attachment-create.component.css"],
})
export class EmailTemplateAttachmentCreateComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") closeModal: ElementRef;
  @Input() id = "modalEmailTemplateAttachmentCreate";

  attachment: File;

  emailTemplateDir: string = "email-template-attachments";

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public EmailTemplateAttachmentService: EmailTemplateAttachmentService,
    public UploadService: UploadService
  ) {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      order: [1, [Validators.required]],
      description: ["", [Validators.required]],
      file_name: ["", [Validators.required]],
      file_path: ["", [Validators.required]],
      template: [EMAIL_TEMPLATE.QUOTATION, [Validators.required]],
    });
    this.attachment = null;
  }

  ngOnInit(): void {}

  async onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    if (this.attachment) {
      let result = await this.UploadService.post(
        this.attachment,
        this.emailTemplateDir,
        false
      );
      if (result.success) {
        let data = this.form.value as EmailTemplateAttachmentCreate;
        data.file_path = result.resultData.path;
        data.file_name = result.resultData.originalname;

        let emailTemplateAttachmentResponse =
          await this.EmailTemplateAttachmentService.create(data);
        if (emailTemplateAttachmentResponse.success) {
          this.save.emit({
            status: emailTemplateAttachmentResponse,
            closeModal: this.closeModal,
          });
        } else {
          this.save.emit({
            status: emailTemplateAttachmentResponse,
            closeModal: this.closeModal,
          });
        }
      }
    }
    this.submitted = false;
    this.setForm();
  }

  onClose() {
    this.setForm();
    this.save.emit(false);
  }

  selectFile(e: Event) {
    let input = e.target as HTMLInputElement;
    let files = Array.from(input.files);
    if (files.length > 0) {
      this.attachment = files[0];
      this.form.patchValue({ file_name: this.attachment.name });
    }
    input.files = null;
  }
}
