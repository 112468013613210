import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { EmployeeService, SalesTeamService } from "src/app/services";
@Component({
  selector: "app-sales-team-list",
  templateUrl: "./sales-team-list.component.html",
  styleUrls: ["./sales-team-list.component.css"],
})
export class SalesTeamListComponent implements OnInit {
  isLoading = true;
  salesTeams: Array<any> = [];
  employees: [];
  coSales: [];
  status: string;
  sales_team_id = null;
  reset: boolean = false;
  filter = {};
  load: Function = this.SalesTeamService.load;
  constructor(
    private router: Router,
    private SalesTeamService: SalesTeamService,
    private EmployeeService: EmployeeService
  ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit(): void {
    this.EmployeeService.load({},{ emp_position_id: 'ALL' }).then((result) => {
      let employees = result.resultData || [];
      this.employees = employees.map((emp) => {
        return {
          ...emp,
          fullname: `${emp.user_info?.firstname_th || ''} ${emp.user_info?.lastname_th || ''}`,
        };
      });
      this.coSales = employees.filter(emp =>
        emp?.emp_position_id?.includes("SALES_DEP-03")
      ).map(emp=>{
        return {
          ...emp,
          fullname: `${emp.user_info?.firstname_th || ''} ${emp.user_info?.lastname_th || ''}`,
        };
      })
    });
  }
  onChangePage(salesTeams: Array<any>) {
    this.salesTeams = salesTeams.map((salesTeam) => {
      return {
        ...salesTeam,
        fullname: `${salesTeam.team_leader_info?.firstname_th || ''} ${salesTeam.team_leader_info?.lastname_th || ''}`,
      };
    });
    this.isLoading = false;
  }
  select(sales_team_id: string) {
    this.sales_team_id = sales_team_id;
  }
  doReset(status?) {
    this.sales_team_id = null;
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter) {
    this.filter = filter;
  }
  async delete(closeModalEl) {
    let salesTeam = this.salesTeams.filter(
      (v) => v.sales_team_id === this.sales_team_id
    )[0];
    if (salesTeam && salesTeam.sales_team_members_count) {
      closeModalEl.click();
      return;
    }

    let response = await this.SalesTeamService.delete({
      sales_team_id: this.sales_team_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }
}
