<div id="customer-activity-edit" sidebar (close)="close($event)">
  <div
    trigger-sidebar
    data-target="#customer-activity-edit"
    class="w-100 h-100 position-fixed"
    style="
      left: 0;
      top: 0;
      background-color: black;
      opacity: 0.3;
      display: none;
      z-index: 1;
    "
  ></div>
  <div class="custom-sidebar">
    <div class="card shadow-none p-0 m-0">
      <div class="card-header border-bottom py-1">
        <div
          class="task-header d-flex justify-content-between align-items-center"
        >
          <h5 class="new-task-title mb-0">
            {{ form.enabled ? "แก้ไข" : "" }}กิจกรรม
          </h5>
          <span>
            <span class="dropdown" *ngIf="form.disabled && canEditCustomer">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i class="feather icon-more-vertical text-muted"></i>
              </a>
              <span
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="sidebar-dropdown"
              >
                <a (click)="enableForm()" class="dropdown-item"
                  ><i class="fa fa-pencil"></i> แก้ไข</a
                >

                <a
                  class="dropdown-item"
                  data-target="#modalConfirmDelMsgActivity"
                  data-toggle="modal"
                  ><i class="fa fa-trash"></i> ลบ</a
                >
              </span>
            </span>
            <button
              type="button"
              class="close close-icon"
              trigger-sidebar
              data-target="#customer-activity-edit"
              (close)="close($event)"
              #closeSidebar
            >
              <i class="feather icon-x align-middle"></i>
            </button>
          </span>
        </div>
      </div>
      <!-- form start -->
      <form
        id="compose-form_edit"
        class="mt-1"
        [formGroup]="form"
        autocomplete="off"
      >
        <div class="card-content">
          <div class="card-body py-0">
            <form-group [control]="form.controls.company_name" [submitted]="submitted" *ngIf="!form.enabled && seeCompanyName">
              <label for="company_name"> ชื่อบริษัท </label>
              <input
                type="company_name"
                id="title"
                class="form-control"
                placeholder="ชื่อบริษัท"
                formControlName="company_name"
              />
            </form-group>

            <form-group
              [control]="form.controls.activity_datetime"
              [submitted]="submitted"
            >
              <label for="activity_datetime_edit">
                วันที่ - เวลา <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <div class="input-group mt-0">
                <input
                  *ngIf="customer_activity"
                  date-time-picker
                  [date]="customer_activity?.activity_datetime"
                  type="text"
                  id="activity_datetime_edit"
                  class="form-control"
                  formControlName="activity_datetime"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i class="feather icon-calendar"></i
                  ></span>
                </div>
              </div>
            </form-group>

            <form-group
              [control]="form.controls.customer_activity_type_id"
              [submitted]="submitted"
            >
              <label for="customer_activity_type_id">
                ประเภท <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <select
                class="form-control"
                id="customer_activity_type_id_edit"
                formControlName="customer_activity_type_id"
              >
                <option value="">กรุณาเลือกข้อมูล</option>
                <option
                  [value]="activityType.customer_activity_type_id"
                  *ngFor="let activityType of activityTypes"
                >
                  {{ activityType.activity_type_name_th }}</option
                >
              </select>
            </form-group>

            <form-group [control]="form.controls.title" [submitted]="submitted">
              <label for="title">
                หัวข้อ <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <input
                type="text"
                id="title_edit"
                class="form-control"
                placeholder="หัวข้อ"
                formControlName="title"
              />
            </form-group>
          </div>

          <div class="card-body pb-1">
            <form-group
              [control]="form.controls.description"
              [submitted]="submitted"
            >
              <label for="description">
                รายละเอียด
              </label>
              <div
                class="ql-container ql-snow quill-custom-height pl-1 pt-1 overflow-auto"
                [innerHTML]="form.controls.description.value"
                *ngIf="form.disabled"
                disabled
              ></div>
              <div *ngIf="form.enabled">
                <div
                  quill
                  [text]="form.controls.description.value"
                  [html]="true"
                  class="quill-custom-height"
                  (change)="form.controls.description.setValue($event)"
                  (instance)="quill = $event"
                ></div>
              </div>
            </form-group>
          </div>

          <div class="card-body pb-1">
            <div class="mb-2">
              <div class="card-title d-inline mr-2">แนบไฟล์</div>

              <label
                *ngIf="!form.disabled"
                for="customer_activities_file_edit"
                class="btn btn-outline-primary btn-min-width mr-1 mb-1 d-inline"
              >
                <i class="feather icon-upload icon-left"></i> Upload
              </label>
              <input
                *ngIf="!form.disabled"
                type="file"
                class="form-control-file d-none"
                id="customer_activities_file_edit"
                (change)="uploadFile($event)"
                multiple
              />
            </div>

            <app-file-list
              [files]="files"
              [canRemove]="canEditCustomer && form.enabled"
              (remove)="fileToBeRemove = $event"
              [confirm-modal-target]="'#confirmDelModalRemoveFile'"
            ></app-file-list>

            <div *ngIf="!files.length && form.disabled">
              <div class="text-dark">
                ไม่มีไฟล์
              </div>
            </div>
          </div>

          <div
            class="card-body pb-1"
            *ngIf="form.value.customer_activity_type_id === 'VISIT'"
          >
            <div class="card-title">ตำแหน่ง</div>
            <!-- Map -->
            <div class="google-map mb-2">
              <input
                type="text"
                class="map-autocomplete-box form-control mb-1"
                [class]="form.disabled ? 'd-none' : ''"
              />
              <div
                google-map
                [disable-click]="form.disabled"
                [default-location]="{
                  lat: form.value.location_lat,
                  lng: form.value.location_lng
                }"
                id="activity-map-edit"
                (change)="setLocation($event)"
              ></div>
            </div>
          </div>

          <div class="card-body pb-2" *ngIf="form.enabled">
            <div class="mt-1 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-warning mr-1"
                trigger-sidebar
                data-target="#customer-activity-edit"
              >
                <i class="fa fa-times"></i> ยกเลิก
              </button>
              <button type="button" class="btn btn-primary" (click)="submit()">
                <i class="fa fa-save"></i> บันทึก
              </button>
            </div>
          </div>
        </div>
      </form>
      <!-- form start end-->
    </div>
  </div>
</div>

<app-confirm-delete
  [id]="'modalConfirmDelMsgActivity'"
  (save)="delete($event)"
></app-confirm-delete>

<app-confirm-delete
  [id]="'confirmDelModalRemoveFile'"
  (save)="removeFile($event)"
  (close)="fileToBeRemove = null"
></app-confirm-delete>
