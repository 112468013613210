import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import dayGridPlugin from "@fullcalendar/daygrid";
import { CustomerActivityService, GETService } from "src/app/services";
import moment from "moment";
import { Select, NgxsOnInit } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { Observable, Subscription } from "rxjs";

@Component({
  selector: "app-my-calendar",
  templateUrl: "./my-calendar.component.html",
  styleUrls: ["./my-calendar.component.css"],
})
export class MyCalendarComponent implements OnInit {
  isLoading = true;
  calendarPlugins = [dayGridPlugin]; // important!
  events = [];
  activityTypes = [];
  customer_activity = null;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  @ViewChild("toggleSidebar") toggleSidebar: ElementRef;
  userSub: Subscription;
  constructor(
    private CustomerActivityService: CustomerActivityService,
    private GETService: GETService
  ) {}

  ngOnInit(): void {
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      if (userInfo) {
        this.CustomerActivityService.load(null, {
          created_by: userInfo.user_id,
          customer_activity_type_id: "APPOINTMENT,MEETING,VISIT",
        }).then((res) => {
          this.events = res.resultData.map((activity) => {
            return {
              title: activity.title,
              date: activity.activity_datetime,
              data: activity,
            };
          });
          this.isLoading = false;
        });

        this.GETService.activityTypes().then((res) => {
          this.activityTypes = res.resultData;
        });
      }
    });
  }

  seeActivity(info) {
    this.customer_activity = info.event.extendedProps.data;
    this.toggleSidebar.nativeElement.click();
  }

  eventRender(info): void {
    let data = info.event.extendedProps.data;
    info.el.innerHTML = `
    ${moment(data.activity_datetime).format("HH:mm")} ${data.customer_info?.company_name} ${data?.title}`;
  }

  getActivity(ev){
    this.customer_activity = null;
    this.ngOnInit();
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}
