<div class="container app-content overflow-container">
  <div class="btn-section text-right border-bottom">
    <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(billingInfo)">
      <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
    </button>
  </div>
  <div *ngFor="let page of pages; let i = index; let lastIndex = last">
    <div class="pdf" id="billing_{{i}}">
      <div class="container mt-5" *ngIf="i==0">
        <div class="row">
          <div class="col-2"></div>
          <img class="col-1 text-right p-0" src="/assets/app-assets/images/pdf/logo1.png" width="90px"
              height="110px">
          <div class="col-6 text-center pt-3">
              <h2>บริษัท มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี จำกัด</h2>
              <p>214 ถนนบางแวก แขวงบางไผ่ เขตบางแค กรุงเทพฯ 10160</p>
          </div>
          <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon2.jpg" width="90px"
              height="110px">
          <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon3.png" width="90px"
              height="110px">
          <div class="col-1"></div>
        </div>
        <p class="text-center">โทร. 0-2865-4647-8 ต่อ 201, 202 แฟ็กซ์. 02-865-4649 E-mail : account@mit.in.th</p>
      </div>
      <div class="container" *ngIf="i==0">
        <h2 class="text-center text-header pt-1">ใบวางบิล</h2>
      </div>
      <div class="container mt-2" *ngIf="i==0">
        <div class="row">
          <div class="col-6 row">
            <div class="col-3">
              <p><b class="text-left">รหัสลูกค้า</b></p>
              <p><b class="text-left">ชื่อลูกค้า</b></p>
              <p><b class="text-left">ที่อยู่</b></p>
            </div>
            <div class="col-9">
              <p class="text-left">{{billingInfo?.customer_info?.customer_code}}</p>
              <p class="text-left">{{billingInfo?.customer_address_info?.address_name}}</p>
              <p class="text-left">{{billingInfo?.customer_address}}</p>
            </div>  
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-6 text-right">
                <p><b class="text-right">วันที่</b></p>
              </div>
              <div class="col-3" *ngIf="billingInfo?.sent_at">
                <p>{{billingInfo?.sent_at | thai_date: "short":"date" | dash}}</p>
              </div>
              <div class="col-3" *ngIf="!billingInfo?.sent_at">
                <p>{{billingInfo?.doc_date | thai_date: "short":"date" | dash}}</p>
              </div>
            </div> 
          </div>
        </div>
        <div class="row">
          <div class="col-6 row">
            <div class="col-3">
            </div>
            <div class="col-9">
              <p class="text-left">เลขประจำตัวผู้เสียภาษี : {{billingInfo?.customer_address_info.tax_id}}</p>
            </div>  
          </div>
          <div class="col-6">
            <p class="text-left">สถานประกอบการ : {{billingInfo?.customer_address_info.branch}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 row">
            <div class="col-3">
            </div>
            <div class="col-9">
              <p class="text-left">Tel. {{billingInfo?.customer_address_info?.tel_1}}<span *ngIf="billingInfo?.customer_address_info?.tel_2">,</span> {{billingInfo?.customer_address_info?.tel_2}}</p>
            </div>  
          </div>
          <div class="col-6">
            <p class="text-left">Fax : {{billingInfo?.customer_address_info?.fax_1}}<span *ngIf="billingInfo?.customer_address_info?.fax_2">,</span> {{billingInfo?.customer_address_info?.fax_2}}</p>
          </div>
        </div>
      </div>  
      <div class="container mt-2">
        <div class="row">
          <table class="mx-auto table table-bordered">
            <thead>
              <tr>
                <th class="text-center" scope="col">ลำดับ</th>
                <th scope="col">เลขที่เอกสาร</th>
                <th scope="col">วันที่</th>
                <th scope="col">วันครบกำหนด</th>
                <th class="text-right" scope="col">จำนวนเงิน</th>
                <th scope="col">หมายเหตุ</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of page; let j = index;">
                <td class="text-center">{{item.index + 1}}</td>
                <td>
                  <div *ngIf="item.doc_type == 'TAX_INVOICE'">
                    {{item?.data?.doc_no_company}}
                  </div>
                  <div *ngIf="item.doc_type != 'TAX_INVOICE'">
                    {{item?.data?.doc_no}}
                  </div>
                </td>
                <td>{{item?.data?.doc_date | thai_date: "short":"date" | dash }}</td>
                <td>{{item?.data?.doc_validdate | thai_date: "short":"date" | dash }}</td>
                <td class="text-right">{{item?.data?.grand_total | number : '1.2-2'}}</td>
                <td>{{item?.note}}</td>
              </tr>
              <tr *ngIf="lastIndex">
                <td><br></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr *ngIf="lastIndex">
                <td class="text-center"></td>
                <td class="text-center">{{billingItems.length}}</td>
                <td class="text-left">ฉบับ</td>
                <td class="text-center"></td>
                <td class="text-center"></td>
                <td class="text-center"></td>
              </tr>
              <tr *ngIf="lastIndex">
                <td colspan="6" class="text-center">
                  <div class="row">
                    <div class="col-6">
                      ({{billingInfo?.THBText}})
                    </div>
                    <div class="col-6">
                      {{billingInfo?.grand_total | number : '1.2-2'}}
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>   
      <div class="container" *ngIf="lastIndex">
        <div class="row">
          <div class="col-6">
            <div class="row form-group mx-sm-3 mb-2">
              <label for="inputPassword2" class="col-3 mt-2" style="font-weight: normal;">ผู้รับวางบิล</label>
                  <div class="col-sm-8 d-flex justify-content-end">
                    <div class="line-input"></div>
                  </div>
            </div>  
            <div class="row form-group mx-sm-3 mb-2">
              <label for="inputPassword2" class="col-3 mt-2" style="font-weight: normal;">วันที่</label>
                  <div class="col-sm-8 d-flex justify-content-end">
                    <div class="line-input"></div>
                  </div>
            </div>  
          </div>
          <div class="col-6">
            <div class="row form-group mx-sm-3 mb-2">
              <label for="inputPassword2" class="col-auto mt-2" style="font-weight: normal;">วันนัดชำระเงิน</label>
                  <div class="col-sm-6 d-flex justify-content-end">
                    <div class="line-input"></div>
                  </div>
            </div>
          </div>
        </div>
      </div>     
    </div>

    <div class="btn-section text-right border-bottom"></div>
    <br />
  </div>
</div>
