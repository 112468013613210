<div class="card">
    <div class="card-content collapse show">
        <div class="card-body">
            
            <div class="row">   
                <div class="col-6">
                    <h4 class="card-title mt-1">การลด/เพิ่มหนี้</h4>
                </div>
            </div>
            <br>
            <loading *ngIf="!pageLoad"></loading>
            
            <div class="row" *ngIf="pageLoad">
                <div class="table-responsive" style="max-height: 600px;">
                    <table class="table table-striped table-bordered">
                        <thead> 
                            <tr>
                                <th>เลขที่เอกสาร</th>
                                <th>วันที่ออก</th>
                                <th class="text-right">มูลค่า</th>
                                <th class="text-center">สถานะ</th>
                                <th>
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let creditNote of creditNotes; let i = index">
                                <td class="text-nowrap">
                                    {{creditNote.doc_no}}
                                </td>
                                <td>
                                    {{creditNote.doc_date | thai_date: "short":"date" | dash}}
                                </td>
                                <td class="text-right">
                                    <span *ngIf="creditNote?.acc_debit_note_info_id">
                                        {{creditNote.grand_total_before_vat | number : '1.2-2'}}
                                    </span>
                                    <span *ngIf="creditNote?.acc_credit_note_info_id">
                                        {{creditNote.difference | number : '1.2-2'}}
                                    </span>
                                </td>
                                <td class="text-center">
                                    {{creditNote.doc_status_info?.document_status_name_th || creditNote.doc_status_id}}
                                </td>
                                <td>
                                    <div *ngIf="creditNote?.acc_debit_note_info_id">
                                        <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm" 
                                        [routerLink]="[
                                            '/debit-note/' + creditNote.acc_debit_note_info_id + '/show'
                                        ]">
                                          <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                        </a>
                                    </div>
                                    <div *ngIf="creditNote?.acc_credit_note_info_id">
                                        <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm" 
                                        [routerLink]="[
                                            '/credit-note/' + creditNote.acc_credit_note_info_id + '/show'
                                        ]">
                                          <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                        </a>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
    
                        <tbody *ngIf="creditNotes.length == 0">
                            <tr>
                                <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>