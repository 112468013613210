<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการรายงาน</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/report']">รายงาน</a>
                </li>
                <li class="breadcrumb-item active">รายการรายงาน</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button class="btn btn-outline-primary"        
            data-toggle="modal"
            data-target="#modalReportCreate">
              <i class="feather icon-plus-circle icon-left"></i> ออกรายงาน
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
  
              <div class="card-content collapse show">
                <div class="card-body">
                    <loading *ngIf="isLoading"></loading>
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                <th>วันที่ขอรายงาน</th>
                                <th>ประเภทรายงาน</th>
                                <th>รายละเอียด</th>
                                <th>ผู้ขอรายงาน</th>
                                <th class="text-center">สถานะ</th>
                                <th class="text-center">Download</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let report of reports;">
                                    <td>{{report?.created_at | thai_date: "short":"date" | dash}}</td>
                                    <td>{{report?.report_name | dash}}</td>
                                    <td>
                                        {{report?.report_desc | dash}}
                                    </td>
                                    <td>
                                      {{report?.created_by_info?.firstname_th}} 
                                      {{report?.created_by_info?.lastname_th}} 
                                    </td>
                                    <td class="text-center">
                                      <div [ngSwitch]="report?.status">
                                        <span *ngSwitchCase="'QUEUE'" class="text-warning">รอดำเนินการ</span>
                                        <span *ngSwitchCase="'PROCESSING'" class="text-blue">กำลังประมวลผล</span>
                                        <span *ngSwitchCase="'AVAILABLE'" class="text-primary">เสร็จสิ้น</span>
                                      </div>
                                    </td>
                                    <td class="text-center">
                                      <button 
                                        (click)="downloadFile(report)"
                                          type="button" class="btn mr-1 mb-1 btn-blue btn-sm text-white"
                                          [disabled]="!['AVAILABLE'].includes(report?.status) || report?.doc_url==''"
                                         >
                                         Download
                                        </button>
                                    </td>
                                </tr>
                                <tr *ngIf="reports.length === 0">
                                    <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
  
                    <app-paginator
                      [filter]="filter"
                      [reset]="reset"
                      [fetch]="load"
                      (changePage)="onChangePage($event)">
                    </app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>

<app-report-create (save)="saveReport($event)" [reportTypes]="reportTypes" *ngIf="reportTypes.length > 0">
</app-report-create>

