import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import {
  EMAIL_TEMPLATE,
  EmailTemplateAttachmentData,
  EmailTemplateAttachmentEdit,
  EmailTemplateAttachmentService,
  UploadService,
} from "src/app/services";

@Component({
  selector: "app-email-template-attachment-edit",
  templateUrl: "./email-template-attachment-edit.component.html",
  styleUrls: ["./email-template-attachment-edit.component.css"],
})
export class EmailTemplateAttachmentEditComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @Input() emailTemplateAttachmentData: EmailTemplateAttachmentData;
  @ViewChild("closeModal") closeModal: ElementRef;
  @Input() id = "modalEmailTemplateAttachmentEdit";

  emailTemplateDir: string = "email-template-attachments";

  attachment: File = null;
  constructor(
    private formBuilder: FormBuilder,
    public EmailTemplateAttachmentService: EmailTemplateAttachmentService,
    public UploadService: UploadService
  ) {
    this.setForm();
  }

  ngOnInit(): void {}

  setForm() {
    this.form = this.formBuilder.group({
      email_template_attachment_id: ["", [Validators.required]],
      description: ["", [Validators.required]],
      file_path: ["", [Validators.required]],
      file_name: ["", [Validators.required]],
      template: [EMAIL_TEMPLATE.QUOTATION, [Validators.required]],
      order: [1, [Validators.required]],
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.form.patchValue({
      ...this.emailTemplateAttachmentData,
    });
  }

  async onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let data = this.form.value as EmailTemplateAttachmentEdit;
    if (this.attachment) {
      const renamedFile = new File(
        [this.attachment],
        this.emailTemplateAttachmentData.file_name,
        {
          type: this.attachment.type,
        }
      );
      let result = await this.UploadService.post(
        renamedFile,
        this.emailTemplateDir,
        false
      );
      if (result.success) {
        data.file_path = result.resultData.path;
        data.file_name = result.resultData.originalname;
      }
    }

    let emailTemplateAttachmentResponse =
      await this.EmailTemplateAttachmentService.update(data);
    if (emailTemplateAttachmentResponse.success) {
      this.save.emit({
        status: emailTemplateAttachmentResponse,
        closeModal: this.closeModal,
      });
    } else {
      this.save.emit({
        status: emailTemplateAttachmentResponse,
        closeModal: this.closeModal,
      });
    }
  }
  onClose() {
    this.setForm();
    this.close.emit(null);
  }

  selectFile(e: Event) {
    let input = e.target as HTMLInputElement;
    let files = Array.from(input.files);
    if (files.length > 0) {
      this.attachment = files[0];
      this.form.patchValue({ file_name: this.attachment.name });
    }
    input.files = null;
  }
}
