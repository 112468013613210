import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { generateMitItemLogService, GETService, MITScopeService } from 'src/app/services';
import { JobOrderCalibrationItemService } from 'src/app/services/job-orders';
import { 
  CalculateFunctionService, 
  DownloadFileService, 
  QuotationMitItemAttributeService, 
  QuotationMitItemDetailPointService, 
  QuotationMitItemDetailRangeService, 
  QuotationMitItemDetailService, 
  QuotationUtilsService 
} from 'src/app/services/quotations';
import {
  UnitService,
  MITCPService,
} from "src/app/services";
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Observable, Subscription } from 'rxjs';
import moment from 'moment';
import { 
  MapMitItemService 
} from '../work-order-lab-import/map-mit-item.service';

declare const $:any;

@Component({
  selector: 'app-work-order-lab-item',
  templateUrl: './work-order-lab-item.component.html',
  styleUrls: ['./work-order-lab-item.component.css']
})
export class WorkOrderLabItemComponent implements OnInit {
  pageLoad:boolean = true;
  showColumn:boolean = true;

  load: boolean = false;

  isSale:boolean = false;
  isTranDep:boolean = false;
  isPuchase:boolean = false;
  isCalibration:boolean = false;
  isShowEditFileWork:boolean = false;
  isConfirmAll:boolean = false;
  isRejectAll:boolean = false;

  jobOrderItems = [];
  groupJobOrderItems = [];
  groupReceiveItems = [];
  deletejobOrderItems: any = [];
  
  cps = [];
  scopeList = [];
  equipmentList = [];
  units = [];

  dropDownData = null;

  pageType: string = 'create';
  job_order_info_id: string;
  template: string;
  next_status: string;
  //
  itemStatus = null;
  @Output() done = new EventEmitter<any>();
  @Output() disableBtn = new EventEmitter<any>();
  @Input() customer_info: any;
  @Input() canConfirm:boolean;
  @Input() canCompleted:boolean;
  @Input() submitted:boolean;
  @Input() jobOrderInfo: any;

  jobOrder = null;
  isUploadCert = null;
  popupType = null;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private GETService: GETService,
    private UnitService: UnitService,
    private MITCPService: MITCPService,
    private MITScopeService: MITScopeService,
    private MapMitItemService: MapMitItemService,
    private DownloadFileService: DownloadFileService,
    private generateMitItemLogService: generateMitItemLogService,
    private QuotationMitItemDetailService: QuotationMitItemDetailService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService,
    private QuotationMitItemAttributeService: QuotationMitItemAttributeService,
    private QuotationMitItemDetailPointService: QuotationMitItemDetailPointService,
    private QuotationMitItemDetailRangeService: QuotationMitItemDetailRangeService,

    public _utils : QuotationUtilsService,
    public _calculateFunctionService: CalculateFunctionService,
  ) {

    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
      this.next_status = params['status'];
    });
    this.job_order_info_id = this.route.snapshot.paramMap.get("job_order_info_id");

    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  async ngOnInit() {
    this.disableBtn.emit(true);
    this.initPopover();

    await this.GETService.itemStatus({order_by: "order:asc"})
    .then(res=>{
      this.itemStatus = res.resultData || [];
    })
    if(this.pageType !='show'){
      await this.MITCPService.load().then((response) => {
        if (response.success) {
          this.cps = response.resultData || [];
        }
      });
      await this.MITScopeService.load(null).then((res) => {
        this.scopeList = res.resultData;
      });
      await this.UnitService.load().then((response) => {
        if (response.success) {
          this.units = this._utils.groupUnit(response.resultData) || [];
        }
      });
      this.dropDownData = {
        scopeList: this.scopeList,
        cps: this.cps,
        units: this.units
      };
    }
    this.getUserInfo();
  }

  getUserInfo(){
    this.userSub = this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      this.isPuchase =
      userInfo?.emp_info?.emp_department_id.startsWith("PUCHASE_DEP");
      this.isCalibration =
      userInfo?.emp_info?.emp_department_id.startsWith("CALIB_DEP");
      this.isTranDep =
      userInfo?.emp_info?.emp_department_id.startsWith("TRAN_DEP");

      if(this.isCalibration && 
        (userInfo?.emp_info?.emp_position_id=='CALIB_DEP-02'|| 
        userInfo?.emp_info?.emp_position_id=='CALIB_DEP-03' ||
        userInfo?.emp_info?.emp_position_id=='CALIB_DEP-04' ||
        userInfo?.emp_info?.emp_position_id=='CALIB_DEP-05' ||
        userInfo?.emp_info?.emp_position_id=='CALIB_DEP-06')){
        this.showColumn = false;
      }

      this.isSale = userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP");

      if(userInfo?.emp_info?.emp_department_id.startsWith("CALIB_DEP") && (userInfo?.emp_info?.emp_level_id.startsWith("MANAGER") || userInfo?.emp_info?.emp_level_id.startsWith("SUPERVISOR") || 
      (userInfo?.emp_info?.emp_level_id.startsWith("OPERATION") && (userInfo?.emp_info?.emp_position_id.startsWith("CALIB_DEP-05") || userInfo?.emp_info?.emp_position_id.startsWith("CALIB_DEP-08"))))
      ){
        this.isShowEditFileWork = true;
      }
      await this.getCalibrationItem();
      
      this.pageLoad = false;
      this.disableBtn.emit(false);
    });
  }

  async getCalibrationItem(){

    if(this.job_order_info_id){
      await this.JobOrderCalibrationItemService.load(null, {job_order_info_id: this.job_order_info_id, order_by: "order:asc"})
      .then(async res=>{
        this.jobOrderItems = res.resultData || [];

        await Promise.all(this.jobOrderItems.map(async (value) =>{
          if(this.jobOrderInfo.doc_status_id == 'WAITING_FOR_CONFIRM' && this.pageType != 'show' && ['ICL','ICS','TRN'].includes(this.template)){
            value.item_status_id = 'CRF-CONFIRMED';
          };

          value['download'] = [];
          value['job_order_cert'] = [];
          value['quotation_mit_item_details'] = [];
          
          value.job_order_file_work_info.map(file =>{
            value.download.push(file.file_path);
          });

          value.job_order_cert = value.job_order_cert_info || [];

          if(value.quotation_mit_item_id){
            await this.QuotationMitItemAttributeService
            .load(null ,{ quotation_mit_item_id: value.quotation_mit_item_id, 'order_by': 'order_by:asc' })
            .then((res) => {
              value.quotation_mit_item_info['quotation_mit_item_attribute_info'] = [...res?.resultData];
            });
    
            await this.QuotationMitItemDetailService
            .load(null ,{ quotation_mit_item_id: value.quotation_mit_item_id, is_non_calibration: 'false', 'order_by': 'order_by:asc' })
            .then(async (res) => {
              let resultData = res.resultData;

              await Promise.all(resultData.map(async (data: any)=>{
                let mapData = await this.mapMitItemData(data);

                value.quotation_mit_item_details.push(mapData);
              }));
              value.quotation_mit_item_details.sort((a, b) => (a.order_by > b.order_by? 1 : -1));
          
              value.quotation_mit_item_info.quotation_mit_item_detail_info = value?.quotation_mit_item_details;

            });
          }
        
        }));
        await this.groupJoborder();
        await this.sentData();
      })
    }
  }

  initPopover(){
    $('body').popover({
      selector: '[data-popover]',
      trigger: 'hover',
      placement: 'right',
      html: true
    }).on("show.bs.popover", function(e){
      $("[rel=popover]").not(e.target).popover("destroy");
      $(".popover").remove();                    
    }).on('mouseup', function(e) {
      var l = $(e.target);
      if (l[0].className.indexOf("popover") == -1) {
        $(".popover").each(function () {
          $(this).popover("hide");
        });
      }
    });
  }
  
  async mapMitItemData(mit: any): Promise<any>{
    let points = [];
    let range = [];
    if(mit.total_amount > 0){
      if(mit.detail_input_type == 'point'){
        points = await this.QuotationMitItemDetailPointService
        .load(null ,{ quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id, 'order_by': 'order_by:asc'})
        .then((res) => {
          return res.resultData;
        });
      }else{
        range = await this.QuotationMitItemDetailRangeService
        .load(null ,{ quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id })
        .then((res) => {
          return res.resultData;
        });  
      }
    }
    await points.map(point=>{
      if(point.cus_unit_info) point['cus_unit_name'] = point.cus_unit_info.unit_short_name_en 
      || point.cus_unit_info.unit_short_name_th;
      if(point.mit_unit_info) point['mit_unit_name'] = point.mit_unit_info.unit_short_name_en 
      || point.mit_unit_info.unit_short_name_th;
    })

    return {
      ...mit,
      detail_type: mit.detail_input_type,
      quotation_mit_item_detail_range: {
        cus_min: range[0]?.cus_min || 0,
        cus_max: range[0]?.cus_max || 0, 
        mit_min: range[0]?.mit_min || 0,
        mit_max: range[0]?.mit_max || 0,
        cus_unit_id: range[0]?.cus_unit_id || '',
        mit_unit_id: range[0]?.mit_unit_id || '',
        cus_unit_name: range[0]?.cus_unit_info.unit_short_name_en || '',
        mit_unit_name: range[0]?.mit_unit_info.unit_short_name_en || '', 
        quotation_mit_item_detail_range_id: range[0]?.quotation_mit_item_detail_range_id || '', 
      },
      cus_unit_id: mit.unit_id,
      unit_name: mit.unit_short_name_en,

      min_range: mit.product_calibration_fee_info.min_range,
      max_range:  mit.product_calibration_fee_info.max_range,
      quotation_mit_item_detail_points: points
    }
  }

  async sentData(){
    this.done.emit({
      jobOrderItems: this.jobOrderItems,
      deletejobOrderItems: this.deletejobOrderItems
    });
  }

  async submitJobOrderItem(event){
    this.load = true;
    if(event && event?.length > 0){
      await Promise.all(event.map(async (item, index)=>{
        let checkDuplicate = this.jobOrderItems.find(value=> value.quotation_mit_item_id == item.quotation_mit_item_id);
        if(!checkDuplicate){
          this.jobOrderItems.push(item);
        }

        item['quotation_mit_item_details'] = [];
        item['job_order_status_work_info'] = [];

        await Promise.all(item.quotation_mit_item_info?.quotation_mit_item_detail_info.map(async (data: any)=>{
          let mapData = await this.mapMitItemData(data);

          item.quotation_mit_item_details.push(mapData);
        }));

        item.quotation_mit_item_details.sort((a, b) => (a.order_by > b.order_by? 1 : -1));

        item.quotation_mit_item_info.quotation_mit_item_detail_info = item.quotation_mit_item_details;

        if(event.length-1 == index){
          this.load = false;
        }
        await this.groupJoborder();
      }));
    }else{
      this.popupType = null;
    }

    await this.sentData();
  }

  onEditMitItem(event){
    if(event){
      this.load = false;
      let attributes = event.mitItem.quotation_mit_item_attribute_info;
      event.mitItem.quotation_mit_item_attribute_info = attributes.filter(
        v=> v.attribute_value != null && v.attribute_value != ''
      );
      if (event.delMitItemAttributeList){
        event.delMitItemAttributeList = [
          ...event.delMitItemAttributeList,
          ...attributes.filter(
              v=> (v.attribute_value == null || v.attribute_value == '') && v.quotation_mit_item_attribute_id != null 
          )
        ]
      }
      this.jobOrderItems.find(item=>this.jobOrder.index == item.index)['quotation_mit_item_info'] = {
        ...event.mitItem
      };
      this.jobOrderItems.find(item=>this.jobOrder.index == item.index)['quotation_mit_item_details'] = event.mitItem['quotation_mit_item_detail_info']
      this.jobOrderItems.find(item=>this.jobOrder.index == item.index)['quotation_mit_item_info']['delMitItemDetails'] 
      = event.delMitItemDetailList;
      this.jobOrderItems.find(item=>this.jobOrder.index == item.index)['quotation_mit_item_info']['delMitItemDetailPoints'] 
      = event.delMitItemDetailPointList;
      this.jobOrderItems.find(item=>this.jobOrder.index == item.index)['quotation_mit_item_info']['delMitItemDetailRanges'] 
      = event.delMitItemDetailRangeList;
      this.jobOrderItems.find(item=>this.jobOrder.index == item.index)['quotation_mit_item_info']['delMitItemAttributes'] 
      = event.delMitItemAttributeList;
      this.jobOrderItems.find(item=>this.jobOrder.index == item.index).lock_invoice_at = event.lock_invoice_at;
    }

    this.groupJoborder();
    this.sentData();
    this.jobOrder = null;
  }

  select(jobOrder){
    this.isConfirmAll = false;
    this.isRejectAll = false;
    this.jobOrder = jobOrder;
  }

  uploadCertEvent(jobOrder){
    this.jobOrder = jobOrder;
    this.isUploadCert = true;
  }

  resetJobOrder(event){
    this.jobOrder = null;
  }

  confirmAllItem(){
    this.jobOrderItems.map((jobOrder, index)=>{
      this.confirmItem(jobOrder, index);
    })
    this.groupJoborder();
  }

  confirmItem(jobOrder, i){
    let findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-CONFIRMED');
    this.jobOrderItems[i].item_status_id = 'CRF-CONFIRMED';
    this.jobOrderItems[i].item_status_info = findStatus;
    this.groupJoborder();
    this.sentData();
  }

  confirmItemCostOfSales(event){
    let findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-CONFIRMED');
    if(event.isAll){
      this.jobOrderItems.map((jobOrder)=>{
        jobOrder.item_status_id = 'CRF-CONFIRMED';
        jobOrder.item_status_info = findStatus;
  
        jobOrder.quotation_mit_item_info.cost_of_sales = event.data.cost_of_sales;
      })
    }else{;
      this.jobOrderItems.map((jobOrder)=>{
        if(jobOrder.index == this.jobOrder.index){
          jobOrder.item_status_id = 'CRF-CONFIRMED';
          jobOrder.item_status_info = findStatus;
          jobOrder.quotation_mit_item_info.cost_of_sales = event.data.cost_of_sales;
        }
      })
    }
    this.groupJoborder();
    this.sentData();
  }

  rejectItem(event){
    let findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-REJECTED');
    if(event.isAll){
      this.jobOrderItems.map((jobOrder)=>{
        jobOrder.item_status_id = 'CRF-REJECTED';
        jobOrder.item_status_info = findStatus;
        jobOrder['reject_reason'] = event.data.reject_reason;
        jobOrder['reject_service_date'] = event.data.reject_service_date;
        jobOrder['reject_solution'] = event.data.reject_solution;
      })
    }else{

      this.jobOrderItems.map((jobOrder)=>{
        if(jobOrder.index == this.jobOrder.index){
          jobOrder.item_status_id = 'CRF-REJECTED';
          jobOrder.item_status_info = findStatus;
          jobOrder['reject_reason'] = event.data.reject_reason;
          jobOrder['reject_service_date'] = event.data.reject_service_date;
          jobOrder['reject_solution'] = event.data.reject_solution;
        }
      })
    }
    this.groupJoborder();
    this.sentData();
  }

  editMitItem(event){
    if(event){
      
      this.jobOrderItems.map((jobOrder)=>{
        if(jobOrder.index == this.jobOrder.index){
          jobOrder.quotation_mit_item_info = {
            ...jobOrder.quotation_mit_item_info,
            ...event
          }
        }
      })
    }
    this.groupJoborder();
    this.sentData();
  }

  async mapImportExcel(excelList = []){
    this.load = true;
    await Promise.all(excelList.map(async (ex, indexExcel)=>{

      if(ex.is_new){
        if(['ICS'].includes(this.template)){
          if(ex.quotation_mit_item_info.point.type != 'range'){
            ex.quotation_mit_item_info.point.type = 'point';
          };

          let findStatus = null;
          let status_work = ex.job_order_status_work_info;
    
          if(status_work.reject_reason == 'p' || status_work.reject_reason == 'f'){
            findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-COMPLETED');
          }else{
            findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-CANCELED');
          }

          let quotation_mit_item_info = {
            customer_id: this.jobOrderInfo.customer_id,
            description: ex.quotation_mit_item_info.description || '',
            marker: ex.quotation_mit_item_info.marker || '',
            model: ex.quotation_mit_item_info.model || '',
            serial_no: ex.quotation_mit_item_info.serial_no || '',
            tag_no: ex.quotation_mit_item_info.tag_no || '',
            due_month: ex.quotation_mit_item_info.due_month || '',
            add_detail: ex.detail_add,
            add_marker: ex.maker_add,
            add_model: ex.model_add,
            add_serial_no: ex.serialno_add,
            add_tag_no: ex.tagno_add,
            cp_list_cali: ex.quotation_mit_item_info?.method.join(", "),
            discount:0,
            equipment_name: ex.quotation_mit_item_info.point?.data_point[0].data[0].product_calibration_fee_info.mit_equipment_info?.equipment_name,
            method_name: ex.quotation_mit_item_info.point?.data_point[0].data[0].product_calibration_fee_info.mit_method_info.method_name,
            mit_equipment_id: ex.quotation_mit_item_info.point?.data_point[0].data[0].product_calibration_fee_info.mit_equipment_info?.mit_equipment_id,
            mit_method_id: ex.quotation_mit_item_info.point?.data_point[0].data[0].product_calibration_fee_info.mit_method_info.mit_method_id,
            mit_scope_id: ex.quotation_mit_item_info.point?.data_point[0].data[0].product_calibration_fee_info.mit_equipment_info?.mit_scope_id,
            original_price: 0,
            price: 0,
            quotation_info_id: 'new',
            quotation_mit_item_id: null,
            scope_name:ex.quotation_mit_item_info.point?.data_point[0].data[0].product_calibration_fee_info.mit_equipment_info?.mit_scope_info.scope_name,
            service_location: ex.quotation_mit_item_info.point?.data_point[0].data[0].product_calibration_fee_info.service_location,
            return_date: this.jobOrderInfo.return_date,
            order_by:0
          }
  
          let quotation_mit_item_details = await this.MapMitItemService.addNewDetail(
            ex.quotation_mit_item_info.point,
            ex
          );
  
          let data = {
            cert_no: ex.cert_no,
            cert_verify_at: null,
            cert_verify_by: null,
            cert_verify_note: null,
            lock_invoice_at: new Date(),
            std: ex.std,
            person_in_charge_id: ex.person_in_charge_info.user_id,
            person_in_charge_info: ex.person_in_charge_info,
            item_status_info: findStatus,
            note: status_work.note,
            service_date: status_work.service_date,
            item_status_id: status_work.reject_reason == 'p' || status_work.reject_reason == 'f'? 'CRF-COMPLETED':'CRF-CANCELED',
            status: status_work.reject_reason == 'p'? 'PASS':status_work.reject_reason == 'f'? 'FAIL': 'CANCEL',
            reject_reason: status_work.reject_reason != 'p' && status_work.reject_reason != 'f'? status_work.reject_reason: '',
            created_by: status_work.created_by_info.user_id,
            created_by_info: status_work.created_by_info,
            quotation_mit_item_details: quotation_mit_item_details,
            quotation_mit_item_info: quotation_mit_item_info,
            job_order_info_id: this.job_order_info_id,
            job_order_calibration_item_id: null,
            quotation_info_id: 'new',
            quotation_mit_item_id: null,
          }
          setTimeout(async () => {
            data = await this.MapMitItemService.calculateTotalAmountItemDetailExcel(data);
    
            this.jobOrderItems.push(data);

            if(['ICS'].includes(this.template)){
              if(excelList.length-1 == indexExcel){
                this.load = false;
              }
              await this.groupJoborder();
            }else{
              if(excelList.filter(item=> item.is_new == false).length-1 == indexExcel){
                this.load = false;
              }
              await this.groupJoborder();
            }
            await this.sentData();
          }, 500);
        }
      }else{
        if(ex.quotation_mit_item_info.point.type != 'range'){
          ex = await this.MapMitItemService.mapExcel(ex);
        }
  
        let findStatus = null;
        let status_work = ex.job_order_status_work_info;
  
        if(status_work.reject_reason == 'p' || status_work.reject_reason == 'f'){
          findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-COMPLETED');
        }else{
          findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-CANCELED');
        }
  
        let index = this.jobOrderItems.findIndex(x => x.cert_no === ex.cert_no);
  
        this.jobOrderItems[index]['std'] = ex.std;
    
        if(ex.person_in_charge_info){
          this.jobOrderItems[index]['person_in_charge_id'] = ex.person_in_charge_info.user_id;
          this.jobOrderItems[index]['person_in_charge_info'] = ex.person_in_charge_info;
        }
  
        if(status_work){
          this.jobOrderItems[index].item_status_info = findStatus;
    
          this.jobOrderItems[index]['note'] = status_work.note;
          if(status_work.service_date){
            this.jobOrderItems[index]['service_date'] = status_work.service_date;
          }
          this.jobOrderItems[index].item_status_id = status_work.reject_reason.toLowerCase() == 'p' || status_work.reject_reason.toLowerCase() == 'f'? 'CRF-COMPLETED':'CRF-CANCELED';
          this.jobOrderItems[index]['status'] = status_work.reject_reason.toLowerCase() == 'p'? 'PASS':status_work.reject_reason.toLowerCase() == 'f'? 'FAIL': 'CANCEL';
          this.jobOrderItems[index]['reject_reason'] = status_work.reject_reason.toLowerCase() != 'p' && status_work.reject_reason.toLowerCase() != 'f'? status_work.reject_reason.toLowerCase(): '';
  
          if(status_work.created_by_info){
            
            this.jobOrderItems[index]['created_by'] = status_work.created_by_info.user_id;
            this.jobOrderItems[index]['created_by_info'] = status_work.created_by_info;
          }
        }
  
        this.jobOrderItems[index].quotation_mit_item_info = {
          ...this.jobOrderItems[index].quotation_mit_item_info,
          description: ex.quotation_mit_item_info.description || '',
          marker: ex.quotation_mit_item_info.marker || '',
          model: ex.quotation_mit_item_info.model || '',
          serial_no: ex.quotation_mit_item_info.serial_no || '',
          tag_no: ex.quotation_mit_item_info.tag_no || '',
          due_month: ex.quotation_mit_item_info.due_month || '',
          add_detail: ex.detail_add,
          add_marker: ex.maker_add,
          add_model: ex.model_add,
          add_serial_no: ex.serialno_add,
          add_tag_no: ex.tagno_add,
          cp_list_cali: ex.quotation_mit_item_info?.method.join(", ")
        }
  
        this.jobOrderItems[index].quotation_mit_item_details.map((detail) =>{
          detail.mpe = ex.quotation_mit_item_info.quotation_mit_item_detail_info.mpe || detail.mpe;
        })
  
        this.jobOrderItems[index].quotation_mit_item_info.quotation_mit_item_detail_info.map(item=>{
          item.number_of_point = item.quotation_mit_item_detail_points.filter(item=>item.is_non_calculate == 'false').length;
        });

        if(ex.quotation_mit_item_info.point.type == 'range'){
          this.jobOrderItems[index].quotation_mit_item_info = await this.MapMitItemService.mapRange(
            ex.quotation_mit_item_info.point, 
            this.jobOrderItems[index].quotation_mit_item_info
          );
        }else{
  
          if(ex.quotation_mit_item_info.point.type == 'unit_different' || ex.quotation_mit_item_info.point.type == 'method_different'){
            this.jobOrderItems[index].quotation_mit_item_info = await this.MapMitItemService.addPointUnitDifferent(
              ex.quotation_mit_item_info.point, 
              this.jobOrderItems[index].quotation_mit_item_info
            );
           }else{
            this.jobOrderItems[index].quotation_mit_item_info = await this.MapMitItemService.addPoint(
              ex.quotation_mit_item_info.point, 
              this.jobOrderItems[index].quotation_mit_item_info
            );
          }              
        }
  
        setTimeout(async () => {
          let mitItem = this.jobOrderItems[index].quotation_mit_item_info;

          this.jobOrderItems[index].quotation_mit_item_info['mit_item_logs'] = [];
  
          this.jobOrderItems[index].quotation_mit_item_info = await this.generateMitItemLogService.createMitItemLog(this.jobOrderItems[index].quotation_mit_item_info, this.jobOrderItems[index].quotation_mit_item_info.quotation_mit_item_log_info, 'job-order');
          
          this.jobOrderItems[index].quotation_mit_item_info = await this.MapMitItemService.mapPoint(this.jobOrderItems[index].quotation_mit_item_info);
    
          let oldPrice = mitItem.price;
          let oldOriginalPrice = mitItem.original_price;

          let calculate = await this.MapMitItemService.calculateTotalAmountItemDetail(mitItem);

          if(
            (calculate.original_price != oldOriginalPrice && calculate.original_price > oldPrice) || 
            this.jobOrderItems[index].item_status_id == "CRF-CANCELED" ||
            ex.quotation_mit_item_info?.is_lock
            ){
            this.jobOrderItems[index].quotation_mit_item_info.price = oldPrice;
            this.jobOrderItems[index].quotation_mit_item_info.original_price =  oldOriginalPrice;

            this.jobOrderItems[index].lock_invoice_at =  new Date();
          }
  
          if(['ICS'].includes(this.template)){
            if(excelList.length-1 == indexExcel){
              this.load = false;
            }
            await this.groupJoborder();
          }else{
            if(excelList.filter(item=> item.is_new == false).length-1 == indexExcel){
              this.load = false;
            }
            await this.groupJoborder();
          }
          await this.sentData();
        }, 500);
      }
    }));
  }

  cancel(job){
    if(job){
      let findStatus = 
      this.itemStatus.find(item=> item.item_status_id == 'CRF-CANCELED');

      this.jobOrderItems.map((jobOrder)=>{
        if(jobOrder.index == job.index){
          jobOrder.item_status_id = 'CRF-CANCELED';
          jobOrder.item_status_info = findStatus;
        }
      })
    }
    this.groupJoborder();
    this.sentData();
  }

  uploadCert(event){
    let findStatus = null;
    this.jobOrderItems.find(item=> item.index == this.jobOrder.index)['job_order_cert'] = event.files;
    this.jobOrderItems.find(item=> item.index == this.jobOrder.index).quotation_mit_item_info.cost_of_sales = event.cost_of_sales;

    if(['ECL','ECS'].includes(this.template)){
      if(event.files.length > 0){
        findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-COMPLETED');
        this.jobOrderItems.
        find(item=> item.index == this.jobOrder.index).item_status_id = 'CRF-COMPLETED';
      }else{
        findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-CONFIRMED');
        this.jobOrderItems.
        find(item=> item.index == this.jobOrder.index).item_status_id = 'CRF-CONFIRMED';
      }

      this.jobOrderItems.find(item=> item.index == this.jobOrder.index).item_status_info = findStatus;
    }

    this.groupJoborder();
    this.sentData();
    this.isUploadCert = null;
  }

  downloadFile(file){
    if(file.job_order_cert_id){
      file['file_path'] = file.cert_file_path;
      this.DownloadFileService.downloadFileURL(file);
    }else{
      this.DownloadFileService.downloadFile(file);
    }
  }

  async delete(closeModalEl){
    this.load = false;
    let jobOrder = this.jobOrderItems.find(item=>this.jobOrder.index == item.index);
    const index = this.jobOrderItems.indexOf(jobOrder);
    this.deletejobOrderItems.push(jobOrder);
    if (index > -1) {
      this.jobOrderItems.splice(index, 1);
    }
    this.groupJoborder();
    this.sentData();
    closeModalEl.click();
  }

  async groupJoborder(){
    if(this.load){
        this.pageLoad = true;
        this.disableBtn.emit(true);
    }
    this.jobOrderItems = await Promise.all(this.jobOrderItems.map(async  (job, index)=>{
      return {
        ...job,
        index: index
      };
    }));

    this.groupJobOrderItems = await Promise.all(this.jobOrderItems.map(async  (job)=>{
      return {
        ...job,
        quotation_mit_item_details: job.quotation_mit_item_details.filter(item=>item?.is_non_calibration == 'false' || item?.is_non_calibration == undefined)
      };
    }));

    if(this.load == false){
      this.pageLoad = false;
      this.disableBtn.emit(false);
    }
  }

  public getStatus(item_status_id: string){
    let findStatus = this.itemStatus.
    find(item=> item.item_status_id == item_status_id);

    return findStatus.item_status_name_th || item_status_id;
  }

  public setContent(item): string{
    let status = item.job_order_status_confirm_info.find(item=>item.item_status_id == 'CRF-REJECTED');

    if(item.reject_service_date){
      return "วันที่ให้บริการ: " + moment(item.reject_service_date).format("DD-MM-YYYY") +"<br>" +
      "สาเหตุ: " + item.reject_reason + "<br>" +
      "แนวทางแก้ไข: " + item.reject_solution + "<br>";
    }else if(status){
      return "วันที่ให้บริการ: " + moment(status.reject_service_date).format("DD-MM-YYYY") +"<br>" +
      "สาเหตุ: " + status.reject_reason + "<br>" +
      "แนวทางแก้ไข: " + status.reject_solution + "<br>";
    }else{ return "";}
  }
}
