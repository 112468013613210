import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  NgZone
} from "@angular/core";
import { SignaturePad } from 'angular2-signaturepad';
@Component({
  selector: 'app-work-order-return',
  templateUrl: './work-order-return.component.html',
  styleUrls: ['./work-order-return.component.css']
})
export class WorkOrderReturnComponent implements OnInit {
  submitted:boolean = false;
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  signaturePadOptions: Object = { 
    'minWidth': 5,
    'canvasWidth': 750,
    'canvasHeight': 150
  };

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor() { }

  ngOnInit(): void {
  }

  submit(){
    this.submitted = true;
    if(this.signaturePad.isEmpty()){
      return;
    }
    this.submitted = false;
    this.done.emit(this.signaturePad.toDataURL());
    this.closeModalBtn.nativeElement.click();
  }

  close(isClose: boolean) {
    this.submitted = false;
    this.done.emit(false);
    this.closeModalBtn.nativeElement.click();
  }

  drawComplete() {}

  drawStart() {}

  reset(){
    this.signaturePad.set('minWidth', 2);
    this.signaturePad.clear();
    this.done.emit(null);
  }
}
