<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-0">เปลี่ยนรหัสผ่าน</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ภาพรวมระบบ</a>
              </li>
              <li class="breadcrumb-item active">
                <a href="#">เปลี่ยนรหัสผ่าน</a>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <!-- response status  -->
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>
      <div class="card col-6">
        <div class="card-body">
          <h6>เปลี่ยนรหัสผ่าน</h6>
          <form [formGroup]="form" autocomplete="off">
            <div class="mt-1">
              <form-group
                [submitted]="submitted"
                [control]="form.controls.password"
              >
                <label for="password"
                  >รหัสผ่านปัจจุบัน <span class="danger">*</span></label
                >
                <input
                  type="password"
                  placeholder="รหัสผ่านปัจจุบัน"
                  id="password"
                  formControlName="password"
                  class="form-control"
                />
              </form-group>

              <form-group
                [submitted]="submitted"
                [control]="form.controls.new_password"
              >
                <label for="password"
                  >รหัสผ่านใหม่ <span class="danger">*</span></label
                >
                <input
                  type="password"
                  placeholder="รหัสผ่านใหม่"
                  id="password"
                  formControlName="new_password"
                  class="form-control"
                />
              </form-group>

              <div
                class="form-group"
                [ngClass]="{
                  error: submitted && form.controls.cf_new_password.errors
                }"
              >
                <label for="cf_new_password"
                  >ยืนยันรหัสผ่านใหม่ <span class="danger">*</span></label
                >
                <input
                  type="password"
                  placeholder="ยืนยันรหัสผ่านใหม่"
                  id="cf_new_password"
                  formControlName="cf_new_password"
                  class="form-control"
                />
                <div
                  *ngIf="
                    submitted && form.controls.cf_new_password?.errors?.required
                  "
                >
                  <ul role="alert" class="pl-2">
                    <li class="text-danger">โปรดระบุ</li>
                  </ul>
                </div>
                <div *ngIf="submitted && form.errors?.notSame">
                  <ul role="alert" class="pl-2">
                    <li class="text-danger">รหัสผ่านไม่ตรงกัน</li>
                  </ul>
                </div>
              </div>
            </div>

            <button
              (click)="submit()"
              type="button"
              class="btn btn-primary btn-min-width"
            >
              <i class="fa fa-save"></i> บันทึก
            </button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
