import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services/users.service";
import { Router } from "@angular/router";
import moment from "moment";
@Component({
  selector: "app-user-list",
  templateUrl: "./user-list.component.html",
  styleUrls: ["./user-list.component.css"],
})
export class UserListComponent implements OnInit {
  isLoading = true;
  users: Array<any>;
  status: string;
  user_id: string;
  reset: boolean = false;
  load: Function = this.UserService.load;
  filter = {};
  constructor(private router: Router, public UserService: UserService) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit(): void {
    this.users = [];
  }
  onChangePage(users: Array<any>) {
    this.users = users.map((user) => {
      return {
        ...user,
        lastLoginAt: moment(user.updated_at).format("DD/MM/YYYY HH:mm"),
        toggle_user_state:
          user.user_state_id === "ACTIVE" ? "INACTIVE" : "ACTIVE",
        position:
          user.emp_info?.emp_level_info?.emp_level_id === "SUPERVISOR"
            ? `${user.emp_info?.emp_department_info?.emp_department_name_th} > (${user.emp_info?.emp_level_info?.emp_level_name_th}) ${user.emp_info.emp_position_info.emp_position_name_th}`
            : `${user.emp_info?.emp_department_info?.emp_department_name_th} > ${user.emp_info?.emp_position_info?.emp_position_name_th}`,
      };
    });
    this.isLoading = false;
  }
  selectUser(user_id) {
    this.user_id = user_id;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = filter;
  }
  async deleteUser(closeModalEl) {
    let response = await this.UserService.delete({ user_id: this.user_id });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }

}
