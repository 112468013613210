import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { CustomerInfoService } from "src/app/services/customer-info.service";
import { GETService } from "src/app/services";
import { Select, Store } from "@ngxs/store";
import {
  SetCustomer,
} from "src/app/store/customer/customer.action";
import { Observable, Subscription } from "rxjs";
import { UserInfoState } from "src/app/store/user/user.state";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-customer-list",
  templateUrl: "./customer-list.component.html",
  styleUrls: ["./customer-list.component.css"],
})
export class CustomerListComponent implements OnInit {
  is_search_all_customer = false;
  isLoading = true;
  customers = [];
  status: string;
  customer = null;
  reset: boolean = false;
  load: Function = this.CustomerInfoService.load;
  filter: any;
  isAllowCreate = false;

  // number_of_user = 0;
  number_of_lead = 0;
  number_of_opportunity = 0;
  number_of_customer = 0;
  number_of_state_change = 0;
  activityTypes = [];
  customer_states = [];
  select2Instance = null;

  select2Options = null;
  settingLanguage = {
    errorLoading: function () {
      return "ไม่สามารถค้นข้อมูลได้";
    },
    loadingMore: function () {
      return "กำลังค้นข้อมูลเพิ่ม…";
    },
    noResults: function () {
      return "ไม่พบข้อมูล";
    },
    searching: function () {
      return "กำลังค้นข้อมูล…";
    }
  }

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;

  constructor(
    private router: Router,
    private CustomerInfoService: CustomerInfoService,
    private GETService: GETService,
    private store: Store
  ) {
    this.filter = { limit:0, order_by: "customer_code:desc" };
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit(): void {
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;
      this.isAllowCreate = 
      userInfo.is_sales_manager || 
      userInfo.is_co_sales || 
      userInfo?.emp_info?.emp_department_id.startsWith("ACC_DEP") 
      || userInfo.is_sales_supervisor
      || userInfo.is_co_sales_supervisor 

      if (userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP") && !userInfo.is_sales_supervisor && !userInfo.is_co_sales_supervisor) {
        if (!userInfo.is_co_sales) {
          this.filter["sales_assignment_info.user_id"] = userInfo.user_id;
        }
      }

      this.settingDropdown(userInfo);

      this.GETService.customerStates({
        order_by: "order_by:asc",
      }).then((res) => {
        if (res.success) {
          this.customer_states = res.resultData || [];
        }
      });
      this.GETService.activityTypes({ isShow: 1 }).then((res) => {
        if (res.success) {
          this.activityTypes = res.resultData || [];
        }
      });

      this.CustomerInfoService.load(
        { limit: 0 },
        {
          customer_state_id: "LEAD",
          is_exclude_address: true,
          "sales_assignment_info.user_id": this.isAllowCreate? undefined: userInfo.user_id
        }
      ).then((res) => {
        this.number_of_lead = res.rowCount || 0;
      });

      this.CustomerInfoService.load(
        { limit: 0 },
        {
          is_exclude_address: true,
          is_decide_for_state_change: true
        }
      ).then((res) => {
        this.number_of_state_change = res.rowCount || 0;
      });

      this.CustomerInfoService.load(
        { limit: 0 },
        {
          customer_state_id: "CUSTOMER",
          is_exclude_address: true,
          "sales_assignment_info.user_id": this.isAllowCreate? undefined: userInfo.user_id
        }
      ).then((res) => {
        this.number_of_customer = res.rowCount || 0;
      });

      this.CustomerInfoService.load(
        { limit: 0 },
        {
          customer_state_id: "OPPORTUNITY",
          is_exclude_address: true,
          "sales_assignment_info.user_id": this.isAllowCreate? undefined: userInfo.user_id
        }
      ).then((res) => {
        this.number_of_opportunity = res.rowCount || 0;
      });
    });
  }
  onChangePage(customers: Array<any>) {
    this.customers = customers;
    this.isLoading = false;
  }
  select(customer) {
    this.customer = Object.assign({}, customer);
    this.store.dispatch(new SetCustomer(customer));
  }
  doReset(status?) {
    this.customer = null;
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter) {
    let filter_condition = Object.assign({}, { ...this.filter, ...filter, limit:10 });

    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
    if (userInfo.is_co_sales) {
      filter_condition = {...filter_condition, isSearchAllCustomer: this.filter["search_all"]? true: false}
    } 

    this.filter = filter_condition;
  }

  searchAll(filter) {
    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
    if (filter) {
      // if(!userInfo.is_co_sales){
        this.filter["sales_assignment_info.user_id"] = undefined;
      // }
      this.filter["search_all"] = filter;
    } else {
      this.filter["search_all"] = undefined;
      if (userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP") && !userInfo.is_sales_supervisor && !userInfo.is_co_sales_supervisor) {
        if (!userInfo.is_co_sales) {
          this.filter["sales_assignment_info.user_id"] = userInfo.user_id;
        }
      }
    }
  }

  async deleteUser(closeModalEl) {
    let response = await this.CustomerInfoService.delete({
      customer_id: this.customer.customer_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }

  filterByStatus(event) {
    let condition = {
      customer_state_id: event.target.value,
    };
    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);

    if (event.target.value === "FREE"  || userInfo.is_co_sales) {
      condition["sales_assignment_info.user_id"] = undefined;
      if (this.select2Instance) {
        this.select2Instance.val(null).trigger("change");
      }
    } else {
      if(this.filter["search_all"]){
        condition["sales_assignment_info.user_id"] = undefined;
      }else{
        condition["sales_assignment_info.user_id"] = userInfo.user_id;
      }
      if (this.select2Instance) {
        this.select2Instance.val(userInfo.user_id).trigger("change.select2");
      }
    }
    this.search(condition);
  }

  filterByUserId(user_id) {
    let condition = {
      "sales_assignment_info.user_id": user_id,
      exclude_customer_state_id:
        user_id && !this.filter?.customer_state_id ? "FREE" : undefined,
    };
    this.search(condition);
  }

  filterByStateChange(){
    let condition = {
      "sales_assignment_info.user_id": undefined,
      is_decide_for_state_change: true,
      order_by: "lastest_activity.activity_datetime:desc"
    };
    this.select2Instance.val(null).trigger("change.select2");

    this.search(condition);
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  settingDropdown(userInfo){
    this.select2Options = {
      language: this.settingLanguage,
      ajax: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        delay: 500,
        url: environment.apiUrl + "/customer-info?limit=10&&order_by=customer_code:desc",
        dataType: "json",
        data: (params) => {
          let query = null
          query= {
            search_all: params.term,
            isSearchAllCustomer: true
          };

          // if(userInfo?.is_sales_manager || userInfo?.emp_info?.emp_department_id.startsWith("ACC_DEP")){
          //   query= {
          //     search_all: params.term,
          //     isSearchAllCustomer: true
          //   };
          // }else if(params.term && userInfo?.is_co_sales){
          //   query= {
          //     search_all: params.term, 
          //     "sales_assignment_info.user_id": params.term.length > 0 ? undefined: userInfo.user_id,
          //     isSearchAllCustomer: true
          //   };
          // }else{
          //   if(userInfo?.is_co_sales){
          //     query = {
          //       search_all: params.term
          //     };
          //   }else{
          //     query = {
          //       search_all: params.term,
          //       "sales_assignment_info.user_id": userInfo.user_id 
          //     };
          //   }
          // }
          return query;
        },
        processResults: function (data) {
          let items = data.resultData.map((elem) => {
          return {
                id: elem.company_name,
                text: `${elem.company_name} ${elem.branch?" (" + elem.branch + ")": ""}`
              }
          });
          return {
            results: items,
          };
        },
      },
    };
  }
}
