<div class="card">
    <div class="card-content collapse show">
        <div class="card-body col-12">
            <div class="row">   
                <div class="col-6">
                    <p class="mt-1">การตอบรับใบเสนอราคา</p>
                </div>
                <div class="col-6 text-right" *ngIf="quotationInfo.doc_status_id != 'REVISE'">
                    <button class="btn btn-outline-primary" data-toggle="modal"data-target="#quotation-accept" (click)="openModal()">
                        <i class="feather icon-plus-circle icon-left"></i> 
                        บันทึกการตอบรับ
                    </button>
                </div>
            </div>
            <div class="table-responsive">
                <table class="table table-striped table-bordered" >
                  <thead>
                    <tr>
                        <th class="w-25">วันที่ตอบรับ</th>
                        <th class="w-25">เลขที่ PO</th>
                        <th>หมายเหตุ</th>
                        <th class="w-25">ไฟล์แนบ</th>
                        <th *ngIf="quotationInfo.doc_status_id != 'REVISE'">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let item of acceptedList; let i = index;">
                        <td>{{item.accepted_date | thai_date: "short":"date" | dash }}</td>
                        <td>{{item.accepted_po_no}}</td>
                        <td>{{item.note || '-'}}</td>
                        <td>
                            <div class="custom-bullet" *ngFor="let file of item.quotation_accepted_file_info; let j = index;">
                                <span class="text-primary" style="cursor: pointer;" 
                                (click)="downloadFile({file_path: file.accepted_doc_path,file_name: file.accepted_doc_name})">
                                    {{file.accepted_doc_name}}
                                </span>
                            </div>
                            <!-- <div *ngIf="item.quotation_accepted_file_info.length == 0">
                                ไม่มีไฟล์
                            </div> -->
                        </td>
                        <td class="text-nowrap" *ngIf="quotationInfo.doc_status_id != 'REVISE'">
                            <button
                            type="button"
                            (click)="select(item)"
                            data-toggle="modal"
                            data-target="#quotation-accept-edit"
                            class="btn mr-1 mb-1 btn-warning text-nowrap btn-sm btn-edit"
                            > <i class="fa fa-pencil icon-left"></i> แก้ไข
                            </button>
                            <!-- <button
                            type="button"
                            (click)="select(item)"
                            data-target="#modalConfirmDelMsgAccepted"
                            data-toggle="modal"
                            class="btn mr-1 mb-1 btn-danger btn-sm"
                            > <i class="fa fa-trash icon-left"></i> ลบ
                            </button> -->
                        </td>
                    </tr>
                    <tr *ngIf="acceptedList.length == 0">
                        <td colspan="5" class="text-center">ไม่มีข้อมูล</td>
                    </tr>
                  </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-quotation-accept-create (onSave)="saveAccepted($event)" (onClose)="closeModal($event)" *ngIf="showModal">
</app-quotation-accept-create>

<app-quotation-accept-edit [accepted]="accepted" (onSave)="editAccepted($event)" (onClose)="closeModal($event)" *ngIf="accepted">
</app-quotation-accept-edit>

<app-confirm-delete
  [id]="'modalConfirmDelMsgAccepted'"
  (save)="delete($event)"
></app-confirm-delete>
