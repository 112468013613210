import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class ProductPriceUnitService {
  async create({ product_id, unit_id, price }) {
    let result = null;
    try {
      result = await Axios().post(`/product-price-unit`, {
        product_id,
        unit_id,
        price,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({ product_price_unit_id, product_id, unit_id, price }) {
    let result = null;
    try {
      result = await Axios().put(
        `/product-price-unit/${product_price_unit_id}`,
        {
          product_id,
          unit_id,
          price,
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ product_price_unit_id }) {
    let result = null;
    try {
      result = await Axios().delete(
        `/product-price-unit/${product_price_unit_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  // async load(paging?, filter?) {
  //   let result = null;
  //   try {
  //     result = await Axios().get(`/product-price-unit`, {
  //       params: { offset: paging?.offset, limit: paging?.limit, ...filter },
  //     });
  //     result = result.data;
  //   } catch (error) {
  //     result = error.response.data;
  //   }
  //   return result;
  // }

  // async getById({ product_type_id }) {
  //   let result = null;
  //   try {
  //     result = await Axios().get(`/product-price-unit/${product_type_id}`);
  //     result = result.data;
  //   } catch (error) {
  //     result = error.response.data;
  //   }
  //   return result;
  // }
}
