import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtilsService } from 'src/app/services';
import { JobOrderCertService } from 'src/app/services/job-orders';
import { DownloadFileService, QuotationUtilsService } from 'src/app/services/quotations';

@Component({
  selector: 'app-upload-cert',
  templateUrl: './upload-cert.component.html',
  styleUrls: ['./upload-cert.component.css']
})
export class UploadCertComponent implements OnInit {
  form: FormGroup
  //boolean
  submitted:boolean = false;
  reset:boolean = true;
  //array
  files: any = [];

  @Input('jobOrder') jobOrder: any;
  @ViewChild("closeRejectBtn") closeRejectBtn: ElementRef;
  @Output() done = new EventEmitter<any>();
  subject: Subject<string> = new Subject();

  fileItem = null;
  template: string;
  pageType: string = 'create';
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private fbd: FormBuilder,
    private DownloadFileService: DownloadFileService,
    private JobOrderCertService: JobOrderCertService,
    private utils: QuotationUtilsService,

    public UtilsService: UtilsService,
  ) { 
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
    });
    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  ngOnInit() {}

  createForm(data?){
    this.form = this.fbd.group({
      cost_of_sales: [data.quotation_mit_item_info.cost_of_sales !=null ?data.quotation_mit_item_info.cost_of_sales: null, ['ECL','ECS'].includes(this.template)? [Validators.required]: null]
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes?.jobOrder?.currentValue)
    if(changes?.jobOrder?.currentValue?.job_order_cert){
      this.createForm(changes.jobOrder.currentValue);
      if(this.form.value.cost_of_sales != null){
        this.convert();
      }
      this.files = [
        ...changes.jobOrder.currentValue?.job_order_cert
      ];
      this.subject.pipe(
        debounceTime(1000)
      ).subscribe(x => 
        this.convert()  
      );
    }
  }

  convert(){
    if(this.form.get('cost_of_sales').value != 0){
      this.form.get('cost_of_sales').setValue(this.utils.transformDecimal(this.form.get('cost_of_sales').value));
    }else{
      this.form.get('cost_of_sales').setValue("");
    }
  }

  onKeyup(){
    this.subject.next();
  }

  save(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.done.emit({
      cost_of_sales: this.utils.convertStingToNumber(this.form.value.cost_of_sales),
      files: this.files
    });
    this.closeRejectBtn.nativeElement.click();
  }

  close(){
    this.submitted = false;
  }

  uploadFile(){
    document.getElementById("importFileCert").click();
  }
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  downloadFile(file){
    if(file.job_order_cert_id){
      file['file_path'] = file.cert_file_path;
      this.DownloadFileService.downloadFileURL(file);
    }else{
      this.DownloadFileService.downloadFile(file);
    }
  }
  removeFile(file, index){
    this.fileItem = file;
  }
  async deleteFile(closeModalEl){
    let fileItem = this.fileItem;
    if(fileItem.job_order_cert_id){
      let response = await this.JobOrderCertService.delete({
        job_order_item_cert_id: fileItem.job_order_cert_id,
      });
      this.files.map((item, index)=>{
        if(fileItem == item){
          this.files.splice(index, 1);
        }
      })
    }else{
      this.files.map((item, index)=>{
        if(fileItem == item){
          this.files.splice(index, 1);
        }
      })
    }
    closeModalEl.click();
  }
}
