// show-errors.component.ts
import { Component, Input } from "@angular/core";
import { AbstractControlDirective, AbstractControl } from "@angular/forms";

@Component({
  selector: "show-errors",
  template: `
    <div class="help-block" *ngIf="shouldShowErrors()">
      <ul role="alert" *ngFor="let error of listOfErrors()">
        <li class="text-danger">{{ error }}</li>
      </ul>
    </div>
  `,
})
export class ShowErrorsComponent {
  private static readonly errorMessages = {
    required: () => "โปรดระบุ",
    min: (params) => "โปรดระบุตัวเลขมากกว่าหรือเท่ากับ " + params.min,
    max: (params) => "โปรดระบุตัวเลขน้อยกว่าหรือเท่ากับ " + params.max,
    phone_number: () => "โปรดระบุเบอร์โทรศัพท์ 0812345678",
    minlength: (params) =>
      "โปรดระบุ อย่างน้อย " + params.requiredLength + " ตัวอักษร",
    maxlength: (params) =>
      "สามารถระบุได้สูงสุด " + params.requiredLength + " ตัวอักษร",
    pattern: (params) =>
      "โปรดระบุข้อมูลให้ถูกต้อง Format: " + params.requiredPattern,
    email: () => "โปรดระบุอีเมลให้ถูกต้อง เช่น somchai@example.com",
    th_lang: () => "โปรดระบุข้อมูลเป็นภาษาไทยเท่านั้น",
    en_lang: () => "โปรดระบุข้อมูลเป็นภาษาอังกฤษเท่านั้น",
    en_lang_sign: () =>
      "ข้อมูลประกอบด้วยตัวอักษร(a-z, A - Z) หรือ ตัวเลข(0 - 9) หรือ เครื่องหมายหรืออักขระพิเศษ($@$!%*?&#^ -/_.) ",
    abs_number: () => "โปรดระบุตัวเลขจำนวนเต็ม",
  };

  @Input()
  private control: AbstractControlDirective | AbstractControl | Array<any>;
  @Input()
  private submitted: boolean;
  shouldShowErrors(): boolean {
    let controls = Array.isArray(this.control) ? this.control : [this.control];
    let errors = false;
    for (let control of controls) {
      if (!control && !control.errors) continue;
      if (control.errors) {
        errors = true;
        break;
      }
    }

    return (
      this.control &&
      errors &&
      // (this.control.dirty || this.control.touched) &&
      this.submitted
    );
  }

  listOfErrors(): string[] {
    let controls = Array.isArray(this.control) ? this.control : [this.control];

    let errorList = [];

    for (let control of controls) {
      if (errorList.length) break;
      if (!control && !control.errors) continue;
      if (control.errors) {
        let errors = Object.keys(control.errors).map((field) => {
          return this.getMessage(field, control.errors[field]);
        });

        if (errors.length) {
          errorList = errors.slice(0, 1);
        }
      }
    }

    return errorList;
  }

  private getMessage(type: string, params: any) {
    return ShowErrorsComponent.errorMessages[type](params);
  }
}
