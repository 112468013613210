import { Injectable } from "@angular/core";
import { Select2OptionData } from "ng-select2";
import { environment } from "src/environments/environment";
declare var $: any;

@Injectable({
    providedIn: "root",
})
export class NgSelect2Customer {
    settingLanguage = {
        errorLoading: function () {
            return "ไม่สามารถค้นข้อมูลได้";
        },
        loadingMore: function () {
            return "กำลังค้นข้อมูลเพิ่ม…";
        },
        maximumSelected: function (n) {
            return "คุณสามารถเลือกได้ไม่เกิน " + n.maximum + " รายการ";
        },
        noResults: function () {
            return "ไม่พบข้อมูล";
        },
        searching: function () {
            return "กำลังค้นข้อมูล…";
        },
    };

    select2Options(filter: any, page?) {
        return {
            language: this.settingLanguage,
            ajax: {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                delay: 500,
                url: environment.apiUrl + "/customer-info" + filter,
                dataType: "json",
                data: (params) => {
                    var query = {
                        search_all: params.term
                    };
                    return query;
                },
                processResults: function (data) {
                    let items = data.resultData.map((elem) => {
                        return {
                            id: elem.customer_id,
                            text:  `[${elem?.customer_code || ''}] ${elem?.company_name || ''} ${elem.branch?" (" + elem?.branch + ")": ""}`,
                            data: elem
                        }
                    });
                    return {
                        results: items,
                    };
                },
            },
        };
    }

    selectQuotations(filter: any, page?) {
        return {
            language: this.settingLanguage,
            ajax: {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                delay: 500,
                url: environment.apiUrl + "/quotation-info" + filter,
                dataType: "json",
                data: (params) => {
                    var query = {
                        search_all: params.term
                    };
                    return query;
                },
                processResults: function (data) {
                    let items = data.resultData.map((elem) => {
                        return {
                            id: elem.quotation_info_id,
                            text:  `[${elem?.doc_no || ''}] (${elem?.customer_info?.customer_code || ''}) ${elem?.customer_info?.company_name || ''}`,
                            data: elem
                        }
                    });
                    return {
                        results: items,
                    };
                },
            },
        };
    }

    selectTransports(filter: any, page?) {
        return {
            language: this.settingLanguage,
            ajax: {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                delay: 500,
                url: environment.apiUrl + "/transport-receive-info" + filter,
                dataType: "json",
                data: (params) => {
                    var query = {
                        search_all: params.term
                    };
                    return query;
                },
                processResults: function (data) {
                    let items = data.resultData.map((elem) => {
                        return {
                            id: elem.transport_receive_info_id,
                            text:  `[${elem?.doc_no || ''}] (${elem?.customer_info?.customer_code || ''}) ${elem?.customer_info?.company_name || ''}`,
                            data: elem
                        }
                    });
                    return {
                        results: items,
                    };
                },
            },
        };
    }

    selectMitItems(filter: any, list) {
        return {
            language: this.settingLanguage,
            ajax: {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                delay: 500,
                url: environment.apiUrl + "/mit-items" + filter,
                dataType: "json",
                data: (params) => {
                    var query = {
                        search_all: params.term
                    };
                    return query;
                },
                processResults: function (data) {
                    let includes = list.map(item=>{
                        return item.quotation_mit_item_id;
                    })
                    let items = data.resultData.filter(item=> !includes.includes(item.quotation_mit_item_id)).map((elem) => {
                        console.log(list)
                        return {
                            id: elem.quotation_mit_item_id,
                            text:  `${elem.serial_no || '-'}/${elem.tag_no || '-'}`,
                            data: elem
                        }
                    });
                    return {
                        results: items,
                    };
                },
            },
        };
    }

    public templateQuotationResult = (state: Select2OptionData): JQuery | string => {
        if (!state.id && !state['data']) {
          return state.text;
        }
        let mit_iplus_code = state['data'].mit_iplus_code?state['data'].mit_iplus_code:'-';
        let template = '<span>'+ state.text +'<br>MIT iPlus code: '+  mit_iplus_code +'</span>';
        return $(template);
    }

    public templateContactResult = (state: Select2OptionData): JQuery | string => {
        if (!state.id && !state['data']) {
          return state.text;
        }
        let mit_iplus_code = state['data'].related_customer_address_info.mit_iplus_code;
        mit_iplus_code = mit_iplus_code ?mit_iplus_code:'-';
        let template = '<span>'+ state.text +'<br>MIT iPlus code: '+  mit_iplus_code +'</span>';
        return $(template);
    }

    public templateAddressResult = (state: Select2OptionData): JQuery | string => {
        if (!state.id && !state['data']) {
          return state.text;
        }
        let mit_iplus_code = state['data']?.mit_iplus_code;

        mit_iplus_code = mit_iplus_code ?mit_iplus_code:'-';
        let template = '<span>'+ state.text +'<br>MIT iPlus code: '+  mit_iplus_code +'</span>';
        return $(template);
    }

    public templateSelection = (state: Select2OptionData): JQuery | string => {
        if (!state.id && !state['data']) {
          return state.text;
        }
        let mit_iplus_code = state['data'].related_customer_address_info.mit_iplus_code?state['data'].related_customer_address_info.mit_iplus_code:'-';

        let content = "iPlus code :  "+ mit_iplus_code +"<br>" + 
        "ชื่อ-นามสกุล :  "+ state.text +"<br>" + 
        "เบอร์โทรศัพท์ :  " + state['data'].phonenumber +"<br>" +
        "อีเมล :  " + state['data'].email + "<br>";

        let template = `<span data-toggle="popover" data-popover="true" data-content="${content}">${state.text} <i class="fa fa-info-circle"></i></span>`;
        return $(template);
    }

    initPopOver(){
        $('body').popover({
          selector: '[data-popover]',
          trigger: 'click',
          placement: 'bottom',
          html: true
        }).on("show.bs.popover", function(e){
          $("[rel=popover]").not(e.target).popover("destroy");
          $(".popover").remove();                    
        }).on('mouseup', function(e) {
          var l = $(e.target);
          if (l[0].className.indexOf("popover") == -1) {
              $(".popover").each(function () {
                  $(this).popover("hide");
              });
          }
        });
    }

    clearPopOver(){
        $('[data-toggle=popover]').not(this).popover('hide');
    }
}
