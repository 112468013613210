import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { UtilsService } from 'src/app/services';
import { JobOrderInfoService } from 'src/app/services/job-orders';

@Component({
  selector: 'app-quotation-price-create',
  templateUrl: './quotation-price-create.component.html',
  styleUrls: ['./quotation-price-create.component.css'],
})
export class QuotationPriceCreateComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  
  @Input() productList: any;
  @Input() customer_id: string;
  
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  productCodeList = [];
  productNameList = [];
  jobOrderList = [];

  product = null;

  constructor(   
    private zone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,
    private JobOrderInfoService: JobOrderInfoService,
    
    public UtilsService: UtilsService,
    ){ 
      this.router.events.subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.closeModalBtn.nativeElement.click();
        }
      });
      this.setForm();
  }
  setForm(){
    this.form = this.formBuilder.group({
      product_code: ["", [Validators.required]],
      product_title: ["", [Validators.required]],
      price: ["", [Validators.required]],
      related_job_order_info_id: [""],
      related_job_order_info: [""],
    });
  }
  ngOnInit() {
  }

  async ngOnChanges(changes: SimpleChanges) {
    if(this.productList.length > 0){
      this.productNameList = this.productList.map(function(elem) {
        return {
          id: elem.product_id,
          text:`[${elem.product_code}] ${elem.product_title}`
        }
      });
    }
  }

  async getJobInfo(){
    await this.JobOrderInfoService.load(null, {customer_id: this.customer_id, 'exclude_doc_status_id': 'DRAFT,WAITING_FOR_CONFIRM,REJECTED,CANCELED'}).then(res=>{
      this.jobOrderList = res.resultData.map(function(elem) {
        return {
          id: elem.job_order_info_id,
          text:`[${elem.job_order_type}] ${elem.doc_no}`,
          data: elem
        }
      });
    })
  }
  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let product = this.productList.find(item =>item.product_id === this.form.value.product_code);
    let data = {
      ...this.form.value,
      product_code: product.product_code,
      product_title: product.product_title,
      product_item: product
    }
    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.product = null;
        this.setForm();
      }
    });
  }
  selectProductCode(event){
    if(event){

      let product = this.productList.find(item =>item.product_id === event);
      this.form.get('product_title').setValue(event);
      this.form.get('price').setValue(product.product_price_unit_info?.price);

    }
  }
  selectProductName(event){
    if(event){

      this.product = this.productList.find(item =>item.product_id === event);
      this.form.get('product_code').setValue(event);
      this.form.get('price').setValue(this.product.product_price_unit_info?.price);

      if(this.product.product_code == '000000'){
        this.form.get('related_job_order_info_id').setValidators([Validators.required]);

        this.getJobInfo();
      }else{
        this.form.get('related_job_order_info_id').setValidators(null);

        this.form.get('related_job_order_info_id').updateValueAndValidity();
      }
    }
  }

  selectJobOrder(event){
    if(event){

      let jobOrder = this.jobOrderList.find(item =>item.id === event);
      this.form.get('related_job_order_info').setValue(jobOrder.data);
    }
  }
}
