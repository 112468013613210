import { Injectable } from "@angular/core";
import { 
    AccInvoiceInfoService,
    AccInvoiceContactService,
    AccInvoiceItemService
} from '.';
import { QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from "../quotations";

@Injectable({
   providedIn: "root"
})
export class CoreService {
  constructor(
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccInvoiceItemService: AccInvoiceItemService,
    private AccInvoiceContactService: AccInvoiceContactService,
    
    private QuotationMitItemService:QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public utils : QuotationUtilsService,
  ) {}

  async createInvoice(
    data: any,
    invoiceItem?: any
    ){
    if(data){
        let invoice_info = data;
        try {
          let invoiceResponse = await this.AccInvoiceInfoService.create({
            ...invoice_info,
          });
          if (invoiceResponse.success) {
            if(data.customer_contact_ids){
              await this.saveCustomerContactList(data.customer_contact_ids, invoiceResponse);
            } 

            if(invoiceItem?.invoiceItems){
              await this.saveInvoiceList(invoiceItem?.invoiceItems, invoiceResponse);
            }   

            return {
              status: true,
              message: invoiceResponse.success
            }
          } else {
            if (invoiceResponse.resultCode === "40900") {
              return {
                status: false,
                message: 'codeDuplicate',
                error: invoiceResponse.error
              }
            } else {
              throw invoiceResponse.error;
            }
          }
        } catch (error) {
          return {
            status: false,
            message: error
          }
        }
      }
  }

  async editInvoice(
    data: any, 
    deleteContactList?,
    invoiceItem?: any
    ){
    if(data){

      let invoice_info = data;
      try {
        let invoiceResponse = await this.AccInvoiceInfoService.update({
          ...invoice_info,
        });
        if (invoiceResponse.success) {
          if(data.customer_contact_ids){
            await this.saveCustomerContactList(data.customer_contact_ids, invoiceResponse);
          } 
          if(deleteContactList){
            await Promise.all(deleteContactList.map(async (v)=>{
              let accInvoiceContactResponse = await this.AccInvoiceContactService
              .delete({
                 ...v
              });
              if (!accInvoiceContactResponse.success) {
                throw accInvoiceContactResponse.error;
              }
            }));
          }  

          if(invoiceItem?.invoiceItems){
            await this.saveInvoiceList(invoiceItem?.invoiceItems, invoiceResponse);
          }   

          if(invoiceItem?.delelteInvoiceItems){
            await Promise.all(invoiceItem.delelteInvoiceItems.map(async (v)=>{

              if(v.acc_invoice_items_id){
                let invoiceItemResponse = await this.AccInvoiceItemService.delete({
                  ...v
                });

                if(v.item_type == 'calibration_service'){
                  let getMitItemResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: v.quotation_item_id});

                  if(getMitItemResponse.success){
                    await this.QuotationMitItemService.update({
                      ...getMitItemResponse.resultData[0],
                      acc_invoice_info_id: "",
                      acc_invoice_info_doc_no: "",
                      acc_invoice_info_status: "",
                    })
                  }
                }else{
                  let getProductItemResponse = await this.QuotationProductItemService.getById({quotation_item_id: v.quotation_item_id});
                  if(getProductItemResponse.success){
                    await this.QuotationProductItemService.update({
                      ...getProductItemResponse.resultData,
                      acc_invoice_info_id: "",
                      acc_invoice_info_doc_no: "",
                      acc_invoice_info_status: "",
                    })
                  }
                }
               if (!invoiceItemResponse.success) {
                 throw invoiceItemResponse.error;
               }
              }
            }));
          }     
          return {
            status: true,
            message: invoiceResponse.success
          }
        } else {
          if (invoiceResponse.resultCode === "40900") {
            return {
              status: false,
              message: 'codeDuplicate',
              error: invoiceResponse.error
            }
          } else {
            throw invoiceResponse.error;
          }
        }
      } catch (error) {
        return {
          status: false,
          message: error
        }
      }
    }
  }

  async saveCustomerContactList(contactList, invoiceResponse){
    await Promise.all(contactList.map(async (v)=>{
      if(v.acc_invoice_contact_id){

      }else{
        let  accInvoiceContactResponse = await this.AccInvoiceContactService
        .create(
          {
            acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
            ...v
          });
        if (!accInvoiceContactResponse.success) {
          throw accInvoiceContactResponse.error;
        }
      }
    }));
  }

  async saveInvoiceList(invoiceList, invoiceResponse){

    await Promise.all(
      invoiceList.map(async (value: any, index: number)=>{

        if(value.acc_invoice_items_id){
          let invoiceItemResponse = await this.AccInvoiceItemService
          .update({
            ...value,
            price: parseFloat(this.utils.convertStingToNumber(value.price)),
            acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
            order_by: index
          });

          if(value.data){
            if(value.item_type == 'calibration_service'){
              let getMitItemResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});

              if(getMitItemResponse.success){
                await this.QuotationMitItemService.update({
                  ...getMitItemResponse.resultData[0],
                  acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
                  acc_invoice_info_doc_no: invoiceResponse.resultData.doc_no,
                  acc_invoice_info_status: invoiceResponse.resultData.doc_status_id,
                })
              }
            }else{
              let getProductItemResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
              if(getProductItemResponse.success){
                await this.QuotationProductItemService.update({
                  ...getProductItemResponse.resultData,
                  acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
                  acc_invoice_info_doc_no: invoiceResponse.resultData.doc_no,
                  acc_invoice_info_status: invoiceResponse.resultData.doc_status_id,
                })
              }
            }
          }
          if(!invoiceItemResponse.success){
            throw invoiceItemResponse.error
          }

        }else{
          let  invoiceItemResponse = await this.AccInvoiceItemService
          .create({
            ...value,
            price: parseFloat(this.utils.convertStingToNumber(value.price)),
            acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
            order_by: index
          });
          if(value.data){
            if(value.item_type == 'calibration_service'){
              await this.QuotationMitItemService.update({
                ...value.data,
                acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
                acc_invoice_info_doc_no: invoiceResponse.resultData.doc_no,
                acc_invoice_info_status: invoiceResponse.resultData.doc_status_id,
              })
            }else{
              await this.QuotationProductItemService.update({
                ...value.data,
                acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
                acc_invoice_info_doc_no: invoiceResponse.resultData.doc_no,
                acc_invoice_info_status: invoiceResponse.resultData.doc_status_id,
              })
            }
          }
          if(!invoiceItemResponse.success){
            throw invoiceItemResponse.error
          }
        }
    }));
  }
}

