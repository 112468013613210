<div class="form-body">
  <app-response-status *ngIf="status" [status]="status"></app-response-status>

  <form autocomplete="off" class="form" [formGroup]="form">
    <div class="border-primary p-2 mb-1 rounded">
      <div class="row">
        <div class="col-3"><h4 class="my-2">SWOT</h4></div>
        <div class="col-9 text-right" *ngIf="canEditCustomer">
          <a
            class="btn btn-warning text-white"
            (click)="enableForm('form')"
            *ngIf="form.disabled && ((userInfo | async)?.user_role_id === 'ADMIN' ||
            (userInfo | async)?.emp_info?.emp_department_id.startsWith('SALES_DEP') ||
            (userInfo | async)?.emp_info?.emp_department_id.startsWith('ACC_DEP'))"
            ><i class="fa fa-pencil icon-left"></i> แก้ไข</a
          >

          <div *ngIf="form.enabled">
            <button
              (click)="cancelSWOT()"
              type="button"
              class="btn btn-warning btn-min-width mr-1"
            >
              <i class="fa fa-times"></i> ยกเลิก
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="submitSWOT()"
            >
              <i class="fa fa-save"></i> บันทึก
            </button>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12 mb-1">
          <form-group
            [control]="form.controls.swot_strength"
            [submitted]="submitted"
          >
            <label for="swot_strength">จุดแข็ง</label>
            <textarea
              id="swot_strength"
              class="form-control"
              rows="5"
              formControlName="swot_strength"
            >
            </textarea>
            <p>
              <small class="text-muted blue-grey lighten-2">
                S เช่น มีจำนวนตัวที่เราสามารถให้บริการได้มาก, ต้องการคุณภาพสูง,
                ลูกค้าชอบเทคโนโลยี, ฐานะการเงินมั่นคง
              </small>
            </p>
          </form-group>
        </div>

        <div class="col-lg-6 col-md-12 mb-1">
          <form-group
            [control]="form.controls.swot_weakness"
            [submitted]="submitted"
          >
            <label for="swot_weakness">จุดด้อย / จุดอ่อน</label>
            <textarea
              id="swot_weakness"
              class="form-control"
              rows="5"
              formControlName="swot_weakness"
            >
            </textarea>
            <p>
              <small class="text-muted blue-grey lighten-2">
                W เช่น ต้องการสินค้าราคาถูก, ใช้ราคาในการตัดสินใจ,
                จำนวนตัวที่เราสามารถให้บริการได้น้อย
              </small>
            </p>
          </form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-6 col-md-12 mb-1">
          <form-group
            [control]="form.controls.swot_opportunity"
            [submitted]="submitted"
          >
            <label for="swot_opportunity">โอกาส</label>
            <textarea
              id="swot_opportunity"
              class="form-control"
              rows="5"
              formControlName="swot_opportunity"
            >
            </textarea>
            <p>
              <small class="text-muted blue-grey lighten-2">
                O เช่น สถานที่ตั้งใกล้บริษัท, ยังไม่มีแลบที่สามารถให้บริการ,
                ต้องการบริการแบบ onsite
              </small>
            </p>
          </form-group>
        </div>

        <div class="col-lg-6 col-md-12 mb-1">
          <form-group
            [control]="form.controls.swot_threat"
            [submitted]="submitted"
          >
            <label for="swot_threat">อุปสรรค</label>
            <textarea
              id="swot_threat"
              class="form-control"
              rows="5"
              formControlName="swot_threat"
            >
            </textarea>
            <p>
              <small class="text-muted blue-grey lighten-2">
                T เช่น มีแลบอื่นให้บริการอยู่ และราคาถูก, ยึดติดกับผู้ผลิต,
                ต้องอบรม safety ตลอดการเข้างาน, หน้างานอยู่ที่สูง
              </small>
            </p>
          </form-group>
        </div>
      </div>
    </div>
  </form>

  <form autocomplete="off" class="form" [formGroup]="additionForm">
    <div class="border-primary p-2 rounded">
      <div class="row">
        <div class="col-12 text-right" *ngIf="canEditCustomer">
          <a
            class="btn btn-warning text-white"
            (click)="enableForm('additionForm')"
            *ngIf="additionForm.disabled && ((userInfo | async)?.user_role_id === 'ADMIN' ||
            (userInfo | async)?.emp_info?.emp_department_id === 'ACC_DEP')"
            ><i class="fa fa-pencil icon-left"></i> แก้ไข</a
          >

          <div *ngIf="additionForm.enabled">
            <button
              (click)="cancelAddition()"
              type="button"
              class="btn btn-warning btn-min-width mr-1"
            >
              <i class="fa fa-times"></i> ยกเลิก
            </button>
            <button
              type="button"
              class="btn btn-primary"
              (click)="submitAddition()"
            >
              <i class="fa fa-save"></i> บันทึก
            </button>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6 col-md-12 mb-1">
          <h4 class="my-2">รอบการสอบเทียบ</h4>
          <form-group
            [control]="additionForm.controls.calibration_cycle_month"
            [submitted]="submitted"
          >
            <label for="calibration_cycle_month" class="sr-only"
              >รอบการสอบเทียบ</label
            >
            <select
              class="form-control"
              id="calibration_cycle_month"
              formControlName="calibration_cycle_month"
            >
              <option value="">กรุณาเลือกข้อมูล</option>
              <option value="3">3 เดือน</option>
              <option value="6">6 เดือน</option>
              <option value="12">12 เดือน</option>
              <option value="24">24 เดือน</option>
            </select>
          </form-group>

          <form-group
            [control]="additionForm.controls.calibration_cycle_memo"
            [submitted]="submitted"
          >
            <label for="calibration_cycle_memo">บันทึก</label>
            <textarea
              id="calibration_cycle_memo"
              class="form-control"
              rows="3"
              formControlName="calibration_cycle_memo"
            >
            </textarea>
          </form-group>
        </div>
        <div class="col-lg-6 col-md-12 mb-1">
          <h4 class="my-2">Lab ที่ใช้บริการอยู่ขณะนี้ หรือก่อนหน้า</h4>
          <form-group
            [control]="additionForm.controls.lab_using"
            [submitted]="submitted"
          >
            <label for="lab_using" class="sr-only"
              >Lab ที่ใช้บริการอยู่ขณะนี้ หรือก่อนหน้า</label
            >
            <textarea
              id="lab_using"
              class="form-control"
              rows="8"
              formControlName="lab_using"
            >
            </textarea>
          </form-group>
        </div>
      </div>
    </div>
  </form>

  <div class="border-primary p-2 mt-1 mb-1 rounded">
    <h4 class="my-2">รายการประมาณการวันสอบเทียบ</h4>
    <button
      type="button"
      class="btn btn-outline-primary btn-min-width mb-1"
      data-toggle="modal"
      data-target="#add-est-date"

      *ngIf="canEditCustomer"
    >
      <i class="fa fa-plus-circle icon-left"></i> เพิ่มประมาณการวันสอบเทียบ
    </button>

    <div class="table-responsive">
      <table class="table table-striped table-bordered bg-white">
        <thead>
          <tr>
            <th>#</th>
            <th>วันที่สอบเทียบ (ประมาณการ)</th>
            <th>ผู้บันทึก</th>
            <th *ngIf="canEditCustomer">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let est_cali of est_calis; let i = index">
            <th>{{ est_cali.i }}</th>
            <td>{{ est_cali.est_date | thai_date: "short":"date" }}</td>
            <td>
              {{ est_cali.created_by_info.firstname_th }}
              {{ est_cali.created_by_info.lastname_th }}
            </td>
            <td *ngIf="canEditCustomer">
              <button
                type="button"
                class="btn mr-1 mb-1 btn-warning btn-sm"
                (click)="selectEst(est_cali)"
                data-toggle="modal"
                data-target="#add-est-date"
              >
                <i class="fa fa-pencil icon-left"></i> แก้ไข
              </button>

              <button
                type="button"
                class="btn mr-1 mb-1 btn-danger btn-sm"
                data-toggle="modal"
                data-target="#modalConfirmDelMsgEstDate"
                (click)="selectEst(est_cali)"
              >
                <i class="fa fa-trash icon-left"></i> ลบ
              </button>
            </td>
          </tr>

          <tr *ngIf="!est_calis.length">
            <td
              [attr.colspan]="canEditCustomer ? '4' : '3'"
              class="text-center"
            >
              ไม่มีข้อมูล
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <app-paginator
      [reset]="resetEstDate"
      [fetch]="loadEstDate"
      (changePage)="onChangeEstDatePage($event)"
      [filter]="estFilter"
    ></app-paginator>
  </div>

  <!-- <div class="border-primary p-2 mb-1 rounded">
    <h4 class="my-2">สินค้า / บริการที่สนใจ</h4>
    <button
      *ngIf="canEditCustomer"
      type="button"
      class="btn btn-outline-primary btn-min-width mb-1"
      data-toggle="modal"
      data-target="#add-product"
    >
      <i class="fa fa-plus-circle icon-left"></i> เพิ่มสินค้า / บริการ
    </button>

    <div class="table-responsive">
      <table class="table table-striped table-bordered bg-white">
        <thead>
          <tr>
            <th>#</th>
            <th>ชื่อสินค้า / บริการ</th>
            <th>จำนวน</th>
            <th *ngIf="canEditCustomer">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let product of products">
            <th>1</th>
            <td>
              [LAB] Temperature > Temperature Sensor (RTD) > Compare With PTR >
              CP-45
            </td>
            <td>2</td>
            <td *ngIf="canEditCustomer">
              <button
                type="button"
                class="btn mr-1 mb-1 btn-danger btn-sm"
                [routerLink]=""
              >
                <i class="fa fa-trash icon-left"></i> ลบ
              </button>
            </td>
          </tr>
          <tr *ngIf="!products.length">
            <td
              [attr.colspan]="canEditCustomer ? '4' : '3'"
              class="text-center"
            >
              ไม่มีข้อมูล
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div> -->
</div>

<!-- TODO :: after quotation feature -->
<!-- Add Product Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="add-product"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addProductModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary white">
        <h4 class="modal-title" id="addProductModal">
          เลือกสินค้า/บริการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <h4>ค้นหา</h4>
        <p>รหัสสินค้า | ชื่อสินค้า</p>
        <div class="row mb-2">
          <div class="col-md-6">
            <div class="position-relative has-icon-right">
              <input
                type="text"
                id="search"
                class="form-control"
                placeholder="ค้นหา..."
                name="search"
              />
              <div class="form-control-position">
                <i class="feather icon-search"></i>
              </div>
            </div>
          </div>
        </div>

        <h4>ผลการค้นหา</h4>
        <div class="table-responsive">
          <table class="table table-striped table-bordered bg-white">
            <thead>
              <tr>
                <th>#</th>
                <th>รหัสสินค้า</th>
                <th>ชื่อสินค้า/บริการ</th>
                <th>จำนวน</th>
              </tr>
            </thead>
            <tbody>
              <!-- <tr *ngFor="let user of users"> -->
              <tr>
                <td>
                  <fieldset class="radio">
                    <label>
                      <input type="radio" name="radio" value="" />
                    </label>
                  </fieldset>
                </td>
                <th>AB-001</th>
                <td>
                  [LAB] Temperature > Temperature Sensor (RTD) > Compare With
                  PTR > CP-45
                </td>
                <td>
                  <input type="text" class="form-control" id="basicInput" />
                </td>
              </tr>
              <!-- <tr *ngIf="!customers.length">
                        <td colspan="6" class="text-center">ไม่มีข้อมูล</td>
                    </tr> -->
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="submitProduct()">
          <i class="fa fa-plus-circle icon-left"></i> เพิ่ม
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Add Est Date Modal -->
<div
  modal
  (close)="closeEstDate($event)"
  class="modal fade text-left"
  id="add-est-date"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addEstDateModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary white">
        <h4 class="modal-title" id="addEstDateModal">
          ประมาณการวันสอบเทียบ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-response-status *ngIf="estStatus" [status]="estStatus"></app-response-status>

        <form class="form" [formGroup]="formEst" *ngIf="openEstModal">
          <div class="row justify-content-md-center">
            <div class="col-md-8">
              <div class="form-body">
                <form-group
                  [control]="formEst.controls.est_date"
                  [submitted]="submittedEst"
                >
                  <label for="est_date">
                    วันที่ <span class="danger">*</span>
                  </label>
                  <div class="input-group mt-0">
                    <input
                      date-picker
                      [date]="date"
                      [max-date]="undefined"
                      type="text"
                      id="est_date"
                      class="form-control"
                      formControlName="est_date"
                    />
                    <div class="input-group-append">
                      <span class="input-group-text"
                        ><i class="feather icon-calendar"></i
                      ></span>
                    </div>
                  </div>
                </form-group>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submitEst()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>

<app-confirm-delete
  [id]="'modalConfirmDelMsgEstDate'"
  (save)="deleteEstDate($event)"
></app-confirm-delete>
