import { Component, ElementRef, OnInit, ViewChild, EventEmitter,Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-delivery-passenger-create',
  templateUrl: './delivery-passenger-create.component.html',
  styleUrls: ['./delivery-passenger-create.component.css']
})
export class DeliveryPassengerCreateComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;

  @Input('tab') tab: string;
  @Input('delivery') delivery: any;
  @Input('transportContactList') transportContactList: any;
  @Input('transportTypes') transportTypes: any;

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private _fbd: FormBuilder
  ) { }

  async ngOnInit() {
    this.form = this._fbd.group({
      return_messenger_id:  ["", Validators.required],
      return_transport_type_id:  ["", Validators.required],
    });

    this.form.patchValue(this.delivery);
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submitted = false;
    let data = {
      ...this.form.value,
    }
    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }

  close(){
    this.done.emit(false);
    this.closeModalBtn.nativeElement.click();
  }

}
