<div
  class="modal fade text-left"
  id="modalMapping"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">Mapping จากรายการเพิ่ม WR</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" [formGroup]="form">
        <div class="modal-body-content">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <label class="col-12 label-control" for="description">
                    เครื่องมือ <span class="danger">*</span> 
                  </label>
                  <div class="col-12">
                      <form-group
                      [control]="form.controls.quotation_mit_item_id"
                      [submitted]="submitted">
                      <ng-select2
                        [options]="select2OptionQuotation"
                          class="w-100"
                          placeholder="โปรดเลือกเครื่องมือ"
                          formControlName="quotation_mit_item_id"
                          [allowClear]="true">
                      </ng-select2>
                      </form-group>
                  </div>
                </div>
            </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn grey btn-outline-secondary"
          data-dismiss="modal"
          (click)="onClose()"
        >
          ปิด
        </button>
        <button type="button" class="btn btn-outline-primary" (click)="onSave()">
          ยืนยัน
        </button>
      </div>
    </div>
  </div>
</div>
