import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CustomerAddressService, CustomerContactService } from 'src/app/services';
import { CalculateFunctionService, DownloadFileService, NgSelect2Customer, QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccInvoiceContactService, AccInvoiceInfoService } from 'src/app/services/invoice';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import moment from 'moment';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';
import { Location } from "@angular/common";

@Component({
  selector: 'app-invoice-show',
  templateUrl: './invoice-show.component.html',
  styleUrls: ['./invoice-show.component.css']
})
export class InvoiceShowComponent implements OnInit {
  form: FormGroup;
  //booleam
  submitted:boolean = false;
  pageLoad:boolean = false;
  isSalesManager:boolean = false;
  isSalesCoordinator:boolean = false;
  isEditDate:boolean = false;
  isEditDueDate:boolean = false;
  isShowCheckbox:boolean = false;
  hidetaxInvoice:boolean = false;

  acc_invoice_info_id: string;

  customerList: any = [];
  customerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerAddressList: any = [];
  tmpCustomerContactList: any = [];
  productList: any = [];
  paymentItem = [];
  quotationItems = [];
  //number
  count: number = 0;
  //any
  status: any;
  select2Options = null;
  subject: Subject<string> = new Subject();
  options = { multiple: true };
  customer_info = null;
  invoiceItem = null;
  invoiceInfo = null;
  taxinvoiceInfo = null;
  resetDate = true;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private DownloadFileService: DownloadFileService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private QuotationMitItemService: QuotationMitItemService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccInvoiceContactService: AccInvoiceContactService,
    private QuotationProductItemService: QuotationProductItemService,

    public _utils : QuotationUtilsService,
    public _calculate: CalculateFunctionService,
    public _ngSelect2Customer: NgSelect2Customer,
  ) {
    this.acc_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_invoice_info_id");
  }

  async ngOnInit() {
    this._ngSelect2Customer.initPopOver();
    this.createForm();

    this.userInfo$.subscribe(user=>{
      if(!user) return;
      this.userInfo = user;
    })
    await this.init();

    this.pageLoad = true;
  }

  ngOnDestroy() {
    this._ngSelect2Customer.clearPopOver();
  }

  async init(){
    await this.AccInvoiceInfoService.getById({acc_invoice_info_id: this.acc_invoice_info_id})
    .then(async res=>{

      let resultData = res.resultData || {};
      this.invoiceInfo = resultData;
      this.customer_info = res.resultData.customer_info;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''} ${this.customer_info?.branch?" (" + this.customer_info?.branch + ")": ""}`,
        customer_credit_term_day: resultData.credit_term_day || 0,
        ...resultData
      }
      this.form.patchValue(data);

      if(resultData.customer_address_id){
        await this.CustomerAddressService.getById({ customer_address_id: resultData.customer_address_id }).then((res)=>{
          if(res.success){
            var resultData = res.resultData;
            var address = this._utils.genAddress(resultData);

            this.form.controls['customer_address_name'].setValue(resultData.address_name + (resultData.branch?' ('+ resultData.branch +')': '') + ` แผนก: ${resultData.department}`);
            this.form.controls['customer_address'].setValue(address); 
          }else{
            this.form.controls['customer_address_name'].setValue(null);
            this.form.controls['customer_address'].setValue(null); 
          } 
        })
      }

      if(resultData.customer_id){
        await this.setCustomerContact(resultData.customer_id);
      }

      await this.AccTaxInvoiceInfoService.load(null, {acc_invoice_info_id: data.acc_invoice_info_id}).then(res=>{
        let resultData = res.resultData.filter(item=>item.doc_status_id != 'CANCELED')|| [];
        if(resultData.length > 0) {

          this.hidetaxInvoice = true;
          this.taxinvoiceInfo = resultData[0];
        }
      })

      await this.AccInvoiceContactService.load(null, { acc_invoice_info_id: this.acc_invoice_info_id }).then((res) => {
        let ids = res.resultData.map(v=>{
          return v.customer_contact_id;
        })
        this.form.controls['customer_contact_ids'].setValue(ids);
      });
    })
  }

  createForm(){
    this.form = this._fbd.group({
      acc_invoice_info_id: [""],
      customer_id: [""],
      customer_name: [""],
      doc_no: [""],
      doc_date: [""],
      doc_validdate:[""],
      doc_duedate: [""],
      return_date:[""],
      doc_status_id: [""],
      customer_address_id: [""],
      customer_address_name: [""],
      customer_address: [""],
      doc_note: [""],
      discount: [this._utils.transformDecimal(0)],
      customer_credit_term_day: [""],
      tax: ["7"],
      grand_total_before_vat: [0.00],
      grand_total: [0.00],
      revise_count: [""],
      customer_contact_ids: [[]],
      is_return_doc_can_create: [""],
    });
    this.form.disable();
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }

  sumCount(){
    this.count = 0;
    this.invoiceItem.invoiceItems.map(product=>{
      this.count = this.count + this._calculate.sumProduct(product);
    })
    this.form.get('discount').setValue(this._utils.transformDecimal(this.form.get('discount').value));
    this.form.get('grand_total').setValue(this._calculate.sumProductTotal(this.count, this.form.value));
  }

  getInvoiceItems(event){
    this.invoiceItem = event;

    let findCalibration = this.invoiceItem.invoiceItems.find(item => item.item_type == 'calibration_service');
    this.quotationItems = this.invoiceItem.invoiceItems.filter((invoice, index, self) =>
    index === self.findIndex((t) => (
      t.quotation_info_id === invoice.quotation_info_id 
    )));
    if(findCalibration){
      this.isShowCheckbox = true;
    }else{
      this.isShowCheckbox = false;
    }
    this.sumCount();
  }
  
  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }

  async confirm(){
    let response = await this.AccInvoiceInfoService.update({
      ...this.invoiceInfo,
      doc_status_id: 'WAITING_FOR_APPROVE',
      doc_duedate : moment(this.invoiceInfo.doc_duedate).format('YYYY-MM-DD')
    });
    if (response.success) {
      this.updatequotationItem(response);
      this.router.navigateByUrl("/invoice", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async cancel(){
    let response = await this.AccInvoiceInfoService.update({
      ...this.invoiceInfo,
      doc_status_id: 'DRAFT',
      doc_duedate : moment(this.invoiceInfo.doc_duedate).format('YYYY-MM-DD')
    });
    if (response.success) {
      this.updatequotationItem(response);
      this.router.navigateByUrl("/invoice", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async approve(sendEmail){
    let accResponse = await this.AccInvoiceInfoService.update({
      ...this.invoiceInfo,
      doc_status_id: 'WAITING_FOR_PAYMENT',
      doc_duedate : moment(this.invoiceInfo.doc_duedate).format('YYYY-MM-DD'),
      email: sendEmail
    });
    if (accResponse.success) {
      this.updatequotationItem(accResponse);
      this.router.navigateByUrl("/invoice", {
        state: {
          status: accResponse.success,
        },
      });
    } else {
      this.status = accResponse.error;
    }
  }

  async reject(){
    let response = await this.AccInvoiceInfoService.update({
      ...this.invoiceInfo,
      doc_status_id: 'REJECTED',
      doc_duedate : moment(this.invoiceInfo.doc_duedate).format('YYYY-MM-DD')
    });
    if (response.success) {
      this.updatequotationItem(response);
      this.router.navigateByUrl("/invoice", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async cancelInvoice(){
    let response = await this.AccInvoiceInfoService.update({
      ...this.invoiceInfo,
      doc_status_id: 'CANCELED',
      doc_duedate : moment(this.invoiceInfo.doc_duedate).format('YYYY-MM-DD')
    });
    if (response.success) {
      await Promise.all(
        this.invoiceItem.invoiceItems.map(async (value: any, index: number)=>{
          if(value.item_type == 'calibration_service'){
            let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
            await this.QuotationMitItemService.update({
              ...quotationMitResponse.resultData[0],
              acc_invoice_info_id: "",
              acc_invoice_info_doc_no: "",
              acc_invoice_info_status: "",
              acc_tax_invoice_info_id: "",
              acc_tax_invoice_info_doc_no: "",
              acc_tax_invoice_info_status: "",
            })
          }else{
            let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
            await this.QuotationProductItemService.update({
              ...quotationProductResponse.resultData,
              acc_invoice_info_id: "",
              acc_invoice_info_doc_no: "",
              acc_invoice_info_status: "",
              acc_tax_invoice_info_id: "",
              acc_tax_invoice_info_doc_no: "",
              acc_tax_invoice_info_status: "",
            })
          }
      }));

      await this.AccTaxInvoiceInfoService.load(null, {acc_invoice_info_id: response.resultData.acc_invoice_info_id}).then(async res=>{
        if(res.resultData.length > 0){
          res.resultData.map(async item=>{
            let response = await this.AccTaxInvoiceInfoService.update({
              ...item,
              doc_status_id: 'CANCELED',
              doc_duedate : moment(item.doc_duedate).format('YYYY-MM-DD')
            });
          })
        }
        this.router.navigateByUrl("/invoice", {
          state: {
            status: response.success,
          },
        });
      })

    } else {
      this.status = response.error;
    }
  }

  async updatequotationItem(invoiceResponse){
    await Promise.all(
      this.invoiceItem.invoiceItems.map(async (value: any, index: number)=>{
        if(value.item_type == 'calibration_service'){
          let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
          await this.QuotationMitItemService.update({
            ...quotationMitResponse.resultData[0],
            acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
            acc_invoice_info_doc_no: invoiceResponse.resultData.doc_no,
            acc_invoice_info_status: invoiceResponse.resultData.doc_status_id,
          })
        }else{
          let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
          await this.QuotationProductItemService.update({
            ...quotationProductResponse.resultData,
            acc_invoice_info_id: invoiceResponse.resultData.acc_invoice_info_id,
            acc_invoice_info_doc_no: invoiceResponse.resultData.doc_no,
            acc_invoice_info_status: invoiceResponse.resultData.doc_status_id,
          })
        }
    }));
  }

  editDate(){
    this.isEditDate = true;

    this.form.controls['return_date'].enable();
    this.form.controls['return_date'].updateValueAndValidity();
  }

  editDueDate(){
    this.isEditDueDate = true;

    this.form.controls['doc_duedate'].enable();
    this.form.controls['doc_duedate'].updateValueAndValidity();
  }

  resetEditDate(){
    this.isEditDate = false;
    this.isEditDueDate = false;

    let return_date = this.invoiceInfo.return_date;
    this.form.controls['return_date'].disable();
    this.form.controls['return_date'].setValue(return_date);
    this.form.controls['return_date'].updateValueAndValidity();

    let doc_duedate = this.invoiceInfo.doc_duedate;
    this.form.controls['doc_duedate'].disable();
    this.form.controls['doc_duedate'].setValue(doc_duedate);
    this.form.controls['doc_duedate'].updateValueAndValidity();
    this.resetDate = null;
    setTimeout(() => {
      this.resetDate = true;
    }, 100);
  }

  async force(){
    let response = await this.AccInvoiceInfoService
    .update({
      ...this.form.getRawValue()
    })
    this.status = response.success || response.error;
    
    this.form.controls['return_date'].disable();
    this.form.controls['return_date'].updateValueAndValidity();
    this.form.controls['doc_duedate'].disable();
    this.form.controls['doc_duedate'].updateValueAndValidity();
    this.isEditDate = false;
    this.isEditDueDate = false;
  }

  getPayment($event){
    this.paymentItem = $event;
  }

  createTaxInvoice(){
    if(this.invoiceItem.invoiceItems.length > 0){
      this.router.navigateByUrl("/tax-invoice/"+ this.customer_info.customer_id + "/create", {
        state: {
          data: this.invoiceItem.invoiceItems
        },
      })
    }
  }

  copy(){
    this.router.navigate([`/invoice/` + this.invoiceInfo.customer_id + '/create'], { queryParams: {invoice_id: this.invoiceInfo.acc_invoice_info_id }});
  }

  back(): void {
    this.location.back();
  }
}
