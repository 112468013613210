import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class TransportPreBookingService {
    async create({
        booking_type,
        booking_date,
        booking_time,
        customer_id,
        location,
        extra_message,
        quotation_info_doc_no,
        job_order_info_doc_no,
        transport_receive_info_doc_no,
        transport_type_id,
        contact_name,
        contact_phonenumber,
        doc_status_id
      }) {
        let result = null;
        try {
          result = await Axios().post(`/transport-prebooking`, {
            booking_type,
            booking_date,
            booking_time,
            customer_id,
            location,
            extra_message,
            quotation_info_doc_no,
            job_order_info_doc_no,
            transport_receive_info_doc_no,
            transport_type_id,
            contact_name,
            contact_phonenumber,
            doc_status_id
          });
          result = result.data;
        } catch (error) {
          result = error.response.data;
        }
        return result;
    }
    async update(data) {
        let {
          transport_prebooking_id,
          booking_type,
          booking_date,
          booking_time,
          customer_id,
          location,
          extra_message,
          quotation_info_doc_no,
          job_order_info_doc_no,
          transport_receive_info_doc_no,
          transport_type_id,
          contact_name,
          contact_phonenumber,
          doc_status_id
        } = data;
        let result = null;
        try {
          result = await Axios().put(`/transport-prebooking/${transport_prebooking_id}`, {
            booking_type,
            booking_date,
            booking_time,
            customer_id,
            location,
            extra_message,
            quotation_info_doc_no,
            job_order_info_doc_no,
            transport_receive_info_doc_no,
            transport_type_id,
            contact_name,
            contact_phonenumber,
            doc_status_id
          });
          result = result.data;
        } catch (error) {
          result = error.response.data;
        }
        return result;
    }
    async load(paging?, filter?) {
        let result = null;
        try {
        result = await Axios().get(`/transport-prebooking`, {
            params: { offset: paging?.offset, limit: paging?.limit, ...filter},
        });
        result = result.data;
        } catch (error) {
        result = error.response.data;
        }
        return result;
    }
}
