import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class CustomerContactService {
  async create({
    customer_id,
    firstname,
    lastname,
    nickname,
    position = "",
    gender,
    date_of_birth,
    phonenumber,
    email,
    line_id,
    memo,
    isMaster,
    related_customer_address_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/customer-contact`, {
        customer_id,
        firstname,
        lastname,
        nickname,
        position,
        gender,
        date_of_birth,
        phonenumber,
        email,
        line_id,
        memo,
        isMaster,
        related_customer_address_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    customer_contact_id,
    customer_id,
    firstname,
    lastname,
    nickname,
    position,
    gender,
    date_of_birth,
    phonenumber,
    email,
    line_id,
    memo,
    isMaster,
    related_customer_address_id
  }) {
    let result = null;
    try {
      result = await Axios().put(`/customer-contact/${customer_contact_id}`, {
        customer_id,
        firstname,
        lastname,
        nickname,
        position,
        gender,
        date_of_birth,
        phonenumber,
        email,
        line_id,
        memo,
        isMaster,
        related_customer_address_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ customer_contact_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/customer-contact/${customer_contact_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/customer-contact`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ customer_contact_id }) {
    let result = null;
    try {
      result = await Axios().get(`/customer-contact/${customer_contact_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
