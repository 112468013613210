<div class="card" [formGroup]="form">
    <div class="card-content collapse show">
        <div class="card-body col-11">
            <p>Remark {{note_type=='standard_in'? 1: 2}}</p>

            <div class="table-responsive">
              <table class="table table-striped table-bordered" >
                <thead>
                  <tr>
                      <th style="width: 2%;" *ngIf="note_type != 'standard_in'"></th>
                      <th class="text-center" style="width: 7%;">ลำดับ</th>
                      <th style="width: 70%;">หมายเหตุ <span class="danger" *ngIf="note_type != 'standard_in'">*</span></th>
                      <th class="text-center py-0 align-middle" *ngIf="note_type != 'standard_in'">
                        <span class="mr-1">Action</span>
                        <button
                          *ngIf="noteList.length === 0"
                          type="button"
                          class="btn btn-sm btn-icon btn-success"
                          (click)="addNote();">
                          <i class="fa fa-plus fa-2x"></i>
                        </button>
                    </th>
                  </tr>
                </thead>
                <tbody  
                  formArrayName="notes"  
                  cdkDropList 
                  (cdkDropListDropped)="drop($event)">
                    <tr *ngFor="let item of noteItemFormGroup.controls; let i = index;" [formGroupName]="i" cdkDrag [cdkDragDisabled]="note_type == 'standard_in'">
                        <td *ngIf="note_type != 'standard_in'">
                            <a style="color: #ccc;" cdkDragHandle><i class="fa fa-ellipsis-v"></i></a>
                        </td>
                        <div *cdkDragPreview>
                            <div class="card px-5 py-2">
                              <div>
                                <b>หมายเหตุ:</b> {{item.value.quotation_note || '-'}}
                              </div>
                            </div>
                        </div>
                        <td class="text-center">
                            {{i+1}}
                        </td>
                        <td>
                          <div *ngIf="item.value.quotation_note !=''">
                            {{item.value.quotation_note}}
                          </div>
                          <div *ngIf="item.value.quotation_note ==''">
                            <form-group [control]="item.controls.quotation_note" [submitted]="submitted"> 
                              <ng-select2 [data]="notes"
                                  [placeholder]="'โปรดระบุหมายเหตุ'"
                                  formControlName="quotation_note"
                                  [width]="300">
                                </ng-select2>
                            </form-group>
                          </div>
                          
                        </td>
                        <td *ngIf="note_type != 'standard_in'">
                            <button
                            type="button"
                            class="btn round btn-icon btn-danger mr-1 mb-1"
                            (click)="removeNote(i, item);"
                          >
                            <i class="fa fa-minus fa-2x"></i>
                          </button>
                          <button
                            *ngIf="noteList.length === i + 1"
                            type="button"
                            class="btn round btn-icon btn-success mb-1"
                            (click)="addNote();"
                          >
                            <i class="fa fa-plus fa-2x"></i>
                          </button>
                        </td>
                    </tr>
                    <tr *ngIf="noteList.length === 0">
                        <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                    </tr>
                </tbody>
              </table>
            </div>
        </div>
    </div>
</div>
