import { Component, OnInit } from '@angular/core';
import jsPDF from 'jspdf';  
import domtoimage from 'dom-to-image-more'
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { JobOrderContactService, JobOrderInfoService } from 'src/app/services/job-orders';
import { CustomerAddressService } from 'src/app/services';
import { QuotationUtilsService } from 'src/app/services/quotations';


@Component({
  selector: 'app-address-cert',
  templateUrl: './address-cert.component.html',
  styleUrls: ['./address-cert.component.css']
})
export class AddressCertComponent implements OnInit {
  job_order_info_id: string = "";
  jobOrderInfo = null;
  contactList = [];
  
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private JobOrderInfoService: JobOrderInfoService,
    private JobOrderContactService: JobOrderContactService,
    private CustomerAddressService: CustomerAddressService,

    public utils : QuotationUtilsService
  ) {
    this.job_order_info_id = this.route.snapshot.paramMap.get("job_order_info_id");
  }

  async ngOnInit() {
    await this.JobOrderInfoService.getById({job_order_info_id: this.job_order_info_id})
    .then(async res=>{
      this.jobOrderInfo = res.resultData || {};
      this.titleService.setTitle(this.jobOrderInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');

      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: this.jobOrderInfo.customer_id})
      .then(async (res) => {
        let resultData = res.resultData.find(item=> item.customer_address_id === this.jobOrderInfo.send_report_address_id);
        this.jobOrderInfo['report_address_name'] = resultData.address_name;
        this.jobOrderInfo['report_address_text'] = this.utils.genAddress(resultData);
      });

      await this.JobOrderContactService.load(null, { job_order_info_id: this.job_order_info_id }).then((res) => {
        this.contactList = res.resultData || [];
      });
    });
  }

  public async print(jobOrderInfo){
    const pdfs = document.querySelectorAll('.pdf');
    var doc = new jsPDF('l', 'mm', [297, 210]);
    
    let pdfList = [];
    await Promise.all(Array.prototype.slice.call(pdfs,0).map(async (value, index) =>{
      let pdf = document.getElementById('pdf');
      await domtoimage.toJpeg(pdf, {
        background: 'white', 
        allowTaint: true,
        width: pdf.clientWidth * 2,
        height: pdf.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        pdfList.push({index: index, canvas: canvas, pdf: pdf});
      })
    }));
    pdfList.sort(function(a, b){return a.index - b.index});
    await Promise.all(pdfList.map(async (value, index) =>{

      var imgWidth = 297;
      var imgHeight = value.pdf.clientHeight * imgWidth / value.pdf.clientWidth;

      doc.addImage(value.canvas, 'JPEG', 0, 10, imgWidth, imgHeight, null);
    }));

    setTimeout(() => {
      doc.save(jobOrderInfo.doc_no);
    }, 1000);
  }
}
