<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="transport-receive-filter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="TransportreceiveFilterModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="TransportreceiveFilterModal">
          สร้างใบรับเครื่องมือ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div class="form-body">
              <div class="mt-1">
                <ul class="nav nav-tabs" role="tablist">
                  <li class="nav-item">
                    <a
                      (click)="changeTab('customer')"
                      class="nav-link active"
                      id="customer-tab"
                      data-toggle="tab"
                      href="#customer"
                      aria-controls="customer"
                      role="tab"
                      aria-selected="true"
                      >ค้นหาจาก Customer name</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      (click)="changeTab('quotation')"
                      class="nav-link"
                      id="quotation-tab"
                      data-toggle="tab"
                      href="#quotation"
                      aria-controls="quotation"
                      role="tab"
                      aria-selected="false"
                      >ค้นหาจากใบเสนอราคา</a
                    >
                  </li>
                </ul>
                <div class="tab-content pt-1">
                  <div
                    class="tab-pane active in"
                    id="customer"
                    aria-labelledby="customer-tab"
                    role="tabpanel">
                    <div class="p-2">
                      <form-group
                      [control]="form.controls.customer_id"
                      [submitted]="submitted"
                      >
                        <label for="">
                          ค้นหาจาก Customer name
                        </label>
                        <ng-select2
                          *ngIf="reset"
                          [options]="select2OptionCustomer"
                          class="w-100"
                          placeholder="Customer name"
                          formControlName="customer_id"
                          (valueChanged)="select($event, 'customer')"
                          [allowClear]="true"
                          >
                        </ng-select2>
                      </form-group>

                      <form-group
                      [control]="form.controls.receive_type"
                      [submitted]="submitted"
                      >
                        <label for="">
                          รูปแบบการรับเครื่องมือ
                        </label>
                        <ng-select2
                          class="w-100"
                          placeholder="รูปแบบการรับเครื่องมือ"
                          formControlName="receive_type"
                          [data]="receiveTypes"
                          >
                        </ng-select2>
                      </form-group>
                    </div>
                  </div>
                  <div
                    class="tab-pane"
                    id="quotation"
                    aria-labelledby="quotation-tab"
                    role="tabpanel">
                    <div class="p-2">
                      <form-group
                      [control]="form.controls.quotation_info_id"
                      [submitted]="submitted"
                      >
                        <label for="">
                          ค้นหาจากใบเสนอราคา
                        </label>
                        <ng-select2
                          *ngIf="reset"
                          [options]="select2OptionQuotation"
                          class="w-100"
                          placeholder="ใบเสนอราคา"
                          formControlName="quotation_info_id"
                          (valueChanged)="select($event, 'quotation')"
                          [allowClear]="true"
                          >
                        </ng-select2>
                      </form-group>

                      <form-group
                      [control]="form.controls.receive_type"
                      [submitted]="submitted"
                      >
                        <label for="">
                          รูปแบบการรับเครื่องมือ
                        </label>
                        <ng-select2
                          class="w-100"
                          placeholder="รูปแบบการรับเครื่องมือ"
                          formControlName="receive_type"
                          [data]="receiveTypes"
                          >
                        </ng-select2>
                      </form-group>
                    </div>
                  </div>
                </div>
            </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ตกลง
        </button>
      </div>
    </div>
  </div>
</div>
