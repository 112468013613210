import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { UserService } from "src/app/services";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.css"],
})
export class ChangePasswordComponent implements OnInit {
  form: FormGroup;
  status = null;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private UserService: UserService,
    private AuthService: AuthService,
    private store: Store,
    private router: Router
  ) {
    this.form = this.formBuilder.group(
      {
        password: ["", [Validators.required]],
        new_password: ["", [Validators.required]],
        cf_new_password: ["", [Validators.required]],
      },
      { validator: this.checkPasswords }
    );
  }

  ngOnInit(): void {}

  async submit() {
    this.submitted = true;
    this.status = null;
    if (this.form.invalid) {
      return;
    }
    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);

    let changePasswordResponse = await this.UserService.changePassword({
      user_id: userInfo.user_id,
      oldPassword: this.form.value.password,
      newPassword: this.form.value.new_password,
    });
    if (changePasswordResponse.success) {
      let response = await this.AuthService.auth({
        email: userInfo.email,
        password: this.form.value.password,
      });
      if (response.success) {
        localStorage.setItem("token", response.resultData.access_token);
      } else {
        this.status = response.error;
      }
    } else if (changePasswordResponse.error.resultCode === "40900") {
      changePasswordResponse.error = {
        bootstrapClass: "warning",
        resultTitle: { th: "พบข้อผิดพลาด !" },
        resultDesc: { th: "รหัสผ่านปัจจุบันไม่ถูกต้อง" },
      };
    }

    this.status =
      changePasswordResponse.success || changePasswordResponse.error;

    this.submitted = false;
    this.form.reset();
  }

  checkPasswords(group: FormGroup) {
    // here we have the 'passwords' group
    let pass = group.get("new_password").value;
    let confirmPass = group.get("cf_new_password").value;

    if (!pass || !confirmPass) {
      return null;
    }

    return pass === confirmPass ? null : { notSame: true };
  }
}
