import { Directive, ElementRef, Output, EventEmitter } from "@angular/core";
declare const $;
@Directive({
  selector: "[modal]",
})
export class ModalDirective {
  @Output() close = new EventEmitter();
  constructor(el: ElementRef) {
    $(el.nativeElement).on("show.bs.modal", () => {
      this.close.emit(false);
    });
    $(el.nativeElement).on("hidden.bs.modal", () => {
      this.close.emit(true);
    });
  }
}
