import { Injectable } from "@angular/core";
import moment from "moment";
import { 
    AccBillingNoteInfoService,
    AccBillingNoteContactService,
    AccBillingNoteItemService,
    AccBillingNoteItemHistoryService
} from '.';
import { AccCreditNoteInfoService } from "../credit-note";
import { AccDebitNoteInfoService } from "../debit-note";
import { AccInvoiceInfoService } from "../invoice";
import { AccTaxInvoiceInfoService } from "../tax-invoice";

@Injectable({
   providedIn: "root"
})
export class CoreService {
  constructor(
    private AccBillingNoteInfoService: AccBillingNoteInfoService,
    private AccBillingNoteContactService: AccBillingNoteContactService,
    private AccBillingNoteItemService: AccBillingNoteItemService,
    private AccBillingNoteItemHistoryService: AccBillingNoteItemHistoryService,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccCreditNoteInfoService: AccCreditNoteInfoService,
    private AccDebitNoteInfoService: AccDebitNoteInfoService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService
  ) {}

  async createBillingNote(
    data: any,
    billingNoteItem?: any
    ){
    if(data){
        let billing_info = data;
        try {
          let billingNoteResponse = await this.AccBillingNoteInfoService.create({
            ...billing_info,
            appointment_time_from: billing_info.appointment_time_from.length > 10? moment(billing_info.appointment_time_from).second(0).format("HH:mm:ss"): billing_info.appointment_time_from + ":00",
            appointment_time_to: billing_info.appointment_time_to.length > 10? moment(billing_info.appointment_time_to).second(0).format("HH:mm:ss"): billing_info.appointment_time_to + ":00",
          });
          if (billingNoteResponse.success) {
            if(data.customer_contact_ids){
              await this.saveCustomerContactList(data.customer_contact_ids, billingNoteResponse);
            } 

            if(billingNoteItem?.billingNoteItems){
              await this.saveBillList(billingNoteItem?.billingNoteItems, billingNoteResponse, 'create');
            }   

            return {
              status: true,
              message: billingNoteResponse.success
            }
          } else {
            if (billingNoteResponse.resultCode === "40900") {
              return {
                status: false,
                message: 'codeDuplicate',
                error: billingNoteResponse.error
              }
            } else {
              throw billingNoteResponse.error;
            }
          }
        } catch (error) {
          return {
            status: false,
            message: error
          }
        }
      }
  }

  async editBillingNote(
    data: any, 
    deleteContactList?,
    billingNoteItem?: any
    ){
    if(data){

      let billing_info = data;
      try {
        let billingNoteResponse = await this.AccBillingNoteInfoService.update({
          ...billing_info,
          appointment_time_from: billing_info.appointment_time_from.length > 10? moment(billing_info.appointment_time_from).second(0).format("HH:mm:ss"): billing_info.appointment_time_from.length ==8?billing_info.appointment_time_from:billing_info.appointment_time_from + ":00",
          appointment_time_to: billing_info.appointment_time_to.length > 10? moment(billing_info.appointment_time_to).second(0).format("HH:mm:ss"): billing_info.appointment_time_to.length ==8?billing_info.appointment_time_to:billing_info.appointment_time_to + ":00",
        });
        if (billingNoteResponse.success) {
          if(data.customer_contact_ids){
            await this.saveCustomerContactList(data.customer_contact_ids, billingNoteResponse);
          } 
          if(deleteContactList){
            await Promise.all(deleteContactList.map(async (v)=>{
              let accBillingNoteContactResponse = await this.AccBillingNoteContactService
              .delete({
                 ...v
              });
              if (!accBillingNoteContactResponse.success) {
                throw accBillingNoteContactResponse.error;
              }
            }));
          }  

          if(billingNoteItem?.billingNoteItems){
            await this.saveBillList(billingNoteItem?.billingNoteItems, billingNoteResponse, 'edit');
          }   

          if(billingNoteItem?.delelteBillingNoteItems){
            await Promise.all(billingNoteItem.delelteBillingNoteItems.map(async (value)=>{

              if(value.acc_billing_note_item_id){
                let billingNoteItemResponse = await this.AccBillingNoteItemService.delete({
                  ...value
               });
               if (billingNoteItemResponse.success) {
                if (value.doc_type == 'INVOICE') {
                  let  invoiceInfoResponse = await this.AccInvoiceInfoService
                  .update({
                    ...value.data,
                    doc_duedate: moment(value.data.doc_duedate).format('YYYY-MM-DD'),
                    acc_billing_note_info_id: "",
                    email: false
                  });
                  if(!invoiceInfoResponse.success){
                    throw invoiceInfoResponse.error
                  }
                }else{
                  let  invoiceTaxInfoResponse = await this.AccTaxInvoiceInfoService
                  .update({
                    ...value.data,
                    doc_duedate: moment(value.data.doc_duedate).format('YYYY-MM-DD'),
                    acc_billing_note_info_id: "",
                  });
                  if(!invoiceTaxInfoResponse.success){
                    throw invoiceTaxInfoResponse.error
                  }
                }
               }else{
                throw billingNoteItemResponse.error;
               }
              }
            }));
          }     
          return {
            status: true,
            message: billingNoteResponse.success
          }
        } else {
          if (billingNoteResponse.resultCode === "40900") {
            return {
              status: false,
              message: 'codeDuplicate',
              error: billingNoteResponse.error
            }
          } else {
            throw billingNoteResponse.error;
          }
        }
      } catch (error) {
        return {
          status: false,
          message: error
        }
      }
    }
  }

  async saveCustomerContactList(contactList, billingNoteResponse){
    await Promise.all(contactList.map(async (v)=>{
      if(v.acc_billing_note_contact_id){

      }else{
        let  accBillingNoteContactResponse = await this.AccBillingNoteContactService
        .create(
          {
            acc_billing_note_info_id: billingNoteResponse.resultData.acc_billing_note_info_id,
            ...v
          });
        if (!accBillingNoteContactResponse.success) {
          throw accBillingNoteContactResponse.error;
        }
      }
    }));
  }

  async saveBillList(billingNoteList, billingNoteResponse, type){

    await Promise.all(
      billingNoteList.map(async (value: any, index: number)=>{

        if(value.acc_billing_note_item_id){
          let billingNoteItemResponse = await this.AccBillingNoteItemService
          .update({
            ...value,
            appointment_date: moment(value.appointment_date).format('YYYY-MM-DD'), 
            sent_at: value.sent_at? moment(value.sent_at).format('YYYY-MM-DD'): null, 
            acc_billing_note_info_id: billingNoteResponse.resultData.acc_billing_note_info_id,
          });
          if(!billingNoteItemResponse.success){
            throw billingNoteItemResponse.error
          }

        }else{
          let  billingNoteItemResponse = await this.AccBillingNoteItemService
          .create({
            ...value,
            appointment_date: moment(value.appointment_date).format('YYYY-MM-DD'), 
            acc_billing_note_info_id: billingNoteResponse.resultData.acc_billing_note_info_id,
          });
          if(billingNoteItemResponse.success){

            if(type == 'create'){
              if(value.history){
                let  billingNoteItemHistoryResponse = await this.AccBillingNoteItemHistoryService
                .create({
                  acc_billing_note_item_id: billingNoteItemResponse.resultData.acc_billing_note_item_id,
                  ...value.history
                });
                if(!billingNoteItemHistoryResponse.success){
                  throw billingNoteItemHistoryResponse.error
                }
              }
            }

           if(value.data.acc_invoice_info_id){
            let  invoiceInfoResponse = await this.AccInvoiceInfoService
            .update({
              ...value.data,
              doc_duedate: moment(value.data.doc_duedate).format('YYYY-MM-DD'),
              acc_billing_note_info_id: value.acc_document_info_id,
              email: false
            });
            if(!invoiceInfoResponse.success){
              throw invoiceInfoResponse.error
            }
           }else if(value.data.acc_tax_invoice_info_id){
            let  invoiceInfoResponse = await this.AccTaxInvoiceInfoService
            .update({
              ...value.data,
              doc_duedate: moment(value.data.doc_duedate).format('YYYY-MM-DD'),
              acc_billing_note_info_id: value.acc_document_info_id,
            });
            if(!invoiceInfoResponse.success){
              throw invoiceInfoResponse.error
            }
           }else if(value.data.acc_credit_note_info_id){
            let  creditInfoResponse = await this.AccCreditNoteInfoService
            .update({
              ...value.data,
              doc_duedate: moment(value.data.doc_duedate).format('YYYY-MM-DD'),
              acc_billing_note_info_id: value.acc_document_info_id,
            });
            if(!creditInfoResponse.success){
              throw creditInfoResponse.error
            }
           }else{
            let  debitInfoResponse = await this.AccDebitNoteInfoService
            .update({
              ...value.data,
              doc_duedate: moment(value.data.doc_duedate).format('YYYY-MM-DD'),
              acc_billing_note_info_id: value.acc_document_info_id,
            });
            if(!debitInfoResponse.success){
              throw debitInfoResponse.error
            }
           }
          }else{
            throw billingNoteItemResponse.error
          }
        }
    }));
  }
}

