import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobOrderInfoService {
  async create({
    doc_no,
    doc_date, 
    job_order_type,
    customer_id,
    customer_address_id,
    pickup_date,
    return_date,
    doc_status_id,
    contact_address_id,
    calibration_address_id,
    report_address_id,
    send_report_address_id,
    charge_address_id,
    no_of_people,
    no_of_day,
    no_of_envelope,
    no_of_cert,
    location,
    note,
    accessory,
    special_condition,
    real_return_date,
    sales_user_id,
    service_time,
    returned_customer_signature,
    returned_customer_signature_date,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/job-order-info`, {
        doc_no,
        doc_date, 
        job_order_type,
        customer_id,
        customer_address_id,
        pickup_date,
        return_date,
        doc_status_id,
        contact_address_id,
        calibration_address_id,
        report_address_id,
        send_report_address_id,
        charge_address_id,
        no_of_people: no_of_people? parseInt(no_of_people): undefined,
        no_of_day: no_of_day? parseInt(no_of_day): undefined,
        no_of_envelope: no_of_envelope? parseInt(no_of_envelope): undefined,
        no_of_cert: no_of_cert? parseInt(no_of_cert): undefined,
        location,
        note,
        accessory,
        special_condition,
        real_return_date,
        sales_user_id,
        service_time,
        returned_customer_signature,
        returned_customer_signature_date,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      job_order_info_id,
      doc_no,
      doc_date, 
      job_order_type,
      customer_id,
      customer_address_id,
      calibration_address_id,
      pickup_date,
      return_date,
      doc_status_id,
      contact_address_id,
      report_address_id,
      send_report_address_id,
      charge_address_id,
      no_of_people,
      no_of_day,
      no_of_envelope,
      no_of_cert,
      location,
      note,
      accessory,
      special_condition,
      reject_service_date, reject_reason, reject_solution,
      real_return_date,
      sales_user_id,
      service_time,
      return_messenger_id,
      return_transport_type_id,
      returned_customer_signature,
      returned_customer_signature_date,
      is_send_mail,
      confirmed_by
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/job-order-info/${job_order_info_id}`, {
        doc_no,
        doc_date, 
        job_order_type,
        customer_id,
        customer_address_id,
        calibration_address_id,
        pickup_date,
        return_date,
        doc_status_id,
        contact_address_id,
        report_address_id,
        send_report_address_id,
        charge_address_id,
        no_of_people: no_of_people? parseInt(no_of_people): undefined,
        no_of_day: no_of_day? parseInt(no_of_day): undefined,
        no_of_envelope: no_of_envelope? parseInt(no_of_envelope): undefined,
        no_of_cert: no_of_cert? parseInt(no_of_cert): undefined,
        location,
        note,
        accessory,
        special_condition,
        reject_service_date, reject_reason, reject_solution,
        real_return_date,
        sales_user_id,
        service_time,
        return_messenger_id,
        return_transport_type_id,
        returned_customer_signature,
        returned_customer_signature_date,
        is_send_mail,
        confirmed_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ job_order_info_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/job-order-info/${job_order_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ job_order_info_id }) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-info/${job_order_info_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  async getCode(filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-code`, {
        params: { ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
