<!-- Edit Sales Team Member -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="edit-sales-team-member"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">รายการสมาชิก</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-response-status
          *ngIf="status"
          [status]="status"
        ></app-response-status>
        <div class="mt-2 mb-2">
          <form class="form" [formGroup]="form">
            <div class="form-body">
              <div class="row">
                <div class="col-sm-10">
                  <form-group
                    [control]="form.controls.user_id"
                    [submitted]="submitted"
                  >
                    <ng-select2
                      [options]="select2Options"
                      class="w-100"
                      placeholder="กรุณาเลือกข้อมูล"
                      formControlName="user_id"
                    >
                    </ng-select2>
                  </form-group>
                </div>

                <div class="col-sm-2">
                  <button
                    type="button"
                    class="btn btn-outline-primary"
                    (click)="submit()"
                  >
                    <i class="feather icon-plus-circle icon-left"></i>
                    เพิ่ม
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <loading *ngIf="isLoading"></loading>
        <div class="table-responsive" *ngIf="!isLoading">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th class="text-center">ลำดับ</th>
                <th>ชื่อ-นามสกุล</th>
                <th>วันที่เพิ่ม</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let member of salesTeamMembers; let i = index">
                <th class="text-center">{{ member.i }}</th>
                <td>{{ member.fullname }}</td>
                <td>{{ member.created_at_text }} น.</td>
                <td>
                  <button
                    type="button"
                    class="btn mr-1 mb-1 btn-danger text-white btn-sm"
                    data-target="#modalConfirmDelMsgMember"
                    data-toggle="modal"
                    (click)="select(member.member_id)"
                    [disabled]="member.is_sales_assignment"
                  >
                    <i class="fa fa-trash icon-left"></i> ลบออก
                  </button>
                </td>
              </tr>
              <tr *ngIf="!salesTeamMembers.length">
                <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
              </tr>
            </tbody>
          </table>
        </div>
        <app-paginator
          [filter]="filter"
          [reset]="reset"
          [fetch]="load"
          (changePage)="onChangePage($event)"
        ></app-paginator>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-warning" data-dismiss="modal">
          <i class="fa fa-times icon-left"></i> ปิด
        </button>
      </div>
    </div>
  </div>
</div>

<app-confirm-delete
  [id]="'modalConfirmDelMsgMember'"
  (save)="delete($event)"
></app-confirm-delete>
