import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccPaymentService {
  async create({
    doc_type,
    acc_document_info_id,
    payment_method_id,
    payment_date,
    bank_name,
    bank_branch,
    evidence_date,
    evidence_ref_no,
    value_before_vat,
    wht_percent,
    value_before_wht,
    fee,
    grand_total,
    note,
    tax,
    order,
    check_deposit_date,
    check_deposit_status,
    check_money_arrived_date,
    wht_received_date,
    created_acc_received_voucher_info_id,
    created_acc_tax_invoice_info_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-payment`, {
        doc_type,
        acc_document_info_id,
        payment_method_id,
        payment_date,
        bank_name,
        bank_branch,
        evidence_date,
        evidence_ref_no,
        value_before_vat,
        wht_percent,
        value_before_wht,
        fee,
        grand_total,
        note,
        tax,
        order,
        check_deposit_date,
        check_deposit_status,
        check_money_arrived_date,
        wht_received_date,
        created_acc_received_voucher_info_id,
        created_acc_tax_invoice_info_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        acc_payment_id,
        doc_type,
        acc_document_info_id,
        payment_method_id,
        payment_date,
        bank_name,
        bank_branch,
        evidence_date,
        evidence_ref_no,
        value_before_vat,
        wht_percent,
        value_before_wht,
        fee,
        grand_total,
        note,
        tax,
        order,
        check_deposit_bank_name,
        check_deposit_date,
        check_deposit_status,
        check_money_arrived_date,
        wht_received_date,
        acc_received_voucher_info_id,
        created_acc_received_voucher_info_id,
        created_acc_tax_invoice_info_id
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/acc-payment/${acc_payment_id}`, {
        doc_type,
        acc_document_info_id,
        payment_method_id,
        payment_date,
        bank_name,
        bank_branch,
        evidence_date,
        evidence_ref_no,
        value_before_vat,
        wht_percent,
        value_before_wht,
        fee,
        grand_total,
        note,
        tax,
        order,
        check_deposit_bank_name,
        check_deposit_date,
        check_deposit_status,
        check_money_arrived_date,
        wht_received_date,
        acc_received_voucher_info_id,
        created_acc_received_voucher_info_id,
        created_acc_tax_invoice_info_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_payment_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-payment/${acc_payment_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-payment`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ acc_payment_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-payment/${acc_payment_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
