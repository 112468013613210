import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationMitItemAttributeService {
  async create({
    quotation_mit_item_id,
    attribute_name,
    attribute_value,
    is_non_calculate,
    is_non_calibration,
    order_by
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-item-attribute`, {
        quotation_mit_item_id,
        attribute_name,
        attribute_value: parseFloat(attribute_value),
        is_non_calculate,
        is_non_calibration,
        order_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_mit_item_attribute_id,
        quotation_mit_item_id,
        attribute_name,
        attribute_value,
        is_non_calculate,
        is_non_calibration,
        order_by
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-mit-item-attribute/${quotation_mit_item_attribute_id}`, {
        quotation_mit_item_id,
        attribute_name,
        attribute_value: parseFloat(attribute_value),
        is_non_calculate,
        is_non_calibration,
        order_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_mit_item_attribute_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-mit-item-attribute/${quotation_mit_item_attribute_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-attribute`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_mit_item_attribute_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-attribute/${quotation_mit_item_attribute_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
