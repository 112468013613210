import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { QuotationUtilsService } from 'src/app/services/quotations';

@Component({
  selector: 'app-confirm-calibration-item',
  templateUrl: './confirm-calibration-item.component.html',
  styleUrls: ['./confirm-calibration-item.component.css']
})
export class ConfirmCalibrationItemComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  subject: Subject<string> = new Subject();

  template: string;
  status: string;

  @Output() done = new EventEmitter<any>();
  @Input('jobOrder') jobOrder: any;
  @Input('isConfirmAll') isConfirmAll: boolean;
  @ViewChild("closeConfirmBtn") closeConfirmBtn: ElementRef;
  
  constructor(
    private route: ActivatedRoute,
    private fbd: FormBuilder,
    private utils: QuotationUtilsService
  ) { 
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
      this.status = params['status'];
    });
  }

  ngOnInit() {}

  async ngOnChanges(changes: SimpleChanges) {

    this.createForm();
    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.convert()  
    );
  }

  createForm(){
    this.form = this.fbd.group({
      cost_of_sales: [this.isConfirmAll? "0.00":this.jobOrder? this.utils.transformDecimal(this.jobOrder?.quotation_item_info?.cost_of_sales): "", [Validators.required]]
    });
  }

  convert(){
    this.form.get('cost_of_sales').setValue(this.utils.transformDecimal(this.form.get('cost_of_sales').value));
  }

  onKeyup(){
    this.subject.next();
  }

  save(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.done.emit({data: this.form.value, isAll: this.isConfirmAll});
    this.closeConfirmBtn.nativeElement.click();
  }

  close(){
    this.submitted = false;
    this.createForm();
  }
}
