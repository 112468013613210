<div id="customer-contact-edit" sidebar (close)="close($event)">
  <div
    trigger-sidebar
    data-target="#customer-contact-edit"
    class="w-100 h-100 position-fixed"
    style="
      left: 0;
      top: 0;
      background-color: black;
      opacity: 0.3;
      display: none;
      z-index: 1;
    "
  ></div>
  <div class="custom-sidebar">
    <div class="card shadow-none p-0 m-0">
      <div class="card-header border-bottom py-1">
        <div class="d-flex justify-content-between align-items-center">
          <h5 class="mb-0">{{ form.enabled ? "แก้ไข" : "" }}ผู้ติดต่อ</h5>
          <span>
            <span class="dropdown" *ngIf="form.disabled && canEditCustomer">
              <a
                href="#"
                class="dropdown-toggle"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="true"
              >
                <i class="feather icon-more-vertical text-muted"></i>
              </a>
              <span
                class="dropdown-menu dropdown-menu-right"
                aria-labelledby="todo-sidebar-dropdown"
              >
                <a (click)="enableForm()" class="dropdown-item" 
                *ngIf="(userInfo | async)?.user_role_id === 'ADMIN' ||
                (userInfo | async)?.emp_info?.emp_department_id === 'ACC_DEP'"
                  ><i class="fa fa-pencil"></i> แก้ไข</a
                >

                <a
                  *ngIf="(userInfo | async)?.user_role_id === 'ADMIN' && customer_contact && customer_contact.isMaster === '0'"
                  class="dropdown-item"
                  data-target="#modalConfirmDelMsgContactEdit"
                  data-toggle="modal"
                  ><i class="fa fa-trash"></i> ลบ</a
                >
              </span>
            </span>
            <button
              type="button"
              class="close close-icon"
              trigger-sidebar
              data-target="#customer-contact-edit"
              #closeSidebar
            >
              <i class="feather icon-x align-middle"></i>
            </button>
          </span>
        </div>
      </div>
      <!-- form start -->
      <div class="alert alert-warning m-1" role="alert" *ngIf="form.enabled">
        <strong>คำเตือน: </strong>
        <span>การแก้ไขข้อมูลจะส่งผลกระทบกับเอกสารต่างๆ ตั้งแต่อดีตจนถึงปัจจุบัน</span>
      </div>
      <form
        id="compose-form"
        class="mt-1"
        [formGroup]="form"
        autocomplete="off"
      >
        <div class="card-content">
          <div class="card-body py-0">
            <form-group
              [control]="form.controls.firstname"
              [submitted]="submitted"
            >
              <label for="firstname">
                ชื่อ <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <input
                type="text"
                id="firstname"
                class="form-control"
                placeholder="ชื่อ"
                formControlName="firstname"
                oninput="this.value = 
                this.value.replace(/[-]/g, '');"
              />
            </form-group>

            <form-group
              [control]="form.controls.lastname"
              [submitted]="submitted"
            >
              <label for="lastname"> นามสกุล <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <input
                type="text"
                id="lastname"
                class="form-control"
                placeholder="นามสกุล"
                formControlName="lastname"
                oninput="this.value = 
                this.value.replace(/[-]/g, '');"
              />
            </form-group>

            <form-group
              [control]="form.controls.nickname"
              [submitted]="submitted"
            >
              <label for="nickname">ชื่อเล่น</label>
              <input
                type="text"
                id="nickname"
                class="form-control"
                placeholder="ชื่อเล่น"
                formControlName="nickname"
              />
            </form-group>

            <form-group
              [control]="form.controls.related_customer_address_id"
              [submitted]="submitted"
            >
              <label for="related_customer_address_id">สัมพันธ์กับที่อยู่ 
                <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <ng-select2
                [options]="{
                  templateResult: templateContactResult
                }"
                [data]="customerAddress"
                class="w-100"
                id="related_customer_address_id"
                placeholder="สัมพันธ์กับที่อยู่"
                formControlName="related_customer_address_id"
              >
              </ng-select2>
            </form-group>

            <form-group
              [control]="form.controls.position"
              [submitted]="submitted"
            >
              <label for="position">
                ตำแหน่ง <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <input
                type="text"
                id="position"
                class="form-control"
                placeholder="ตำแหน่ง"
                formControlName="position"
              />
            </form-group>

            <form-group
              [control]="form.controls.gender"
              [submitted]="submitted"
            >
              <label for="gender">เพศ</label>
              <select class="form-control" id="gender" formControlName="gender">
                <option value="">กรุณาเลือกข้อมูล</option>
                <option>ชาย</option>
                <option>หญิง</option>
              </select>
            </form-group>

            <form-group
              [control]="form.controls.date_of_birth"
              [submitted]="submitted"
            >
              <label for="date_of_birth">วันเกิด</label>
              <div class="input-group mt-0">
                <input
                  type="text"
                  date-picker
                  [view-mode]="'years'"
                  id="date_of_birth"
                  class="form-control"
                  formControlName="date_of_birth"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i class="feather icon-calendar"></i
                  ></span>
                </div>
              </div>
            </form-group>

            <form-group
              [control]="form.controls.phonenumber"
              [submitted]="submitted"
            >
              <label for="phonenumber">
                เบอร์โทรศัพท์ <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <input
                type="text"
                id="phonenumber"
                class="form-control"
                placeholder="เบอร์โทรศัพท์"
                formControlName="phonenumber"
              />
            </form-group>

            <form-group [control]="form.controls.email" [submitted]="submitted">
              <label for="email">
                E-mail <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <input
                type="text"
                id="email"
                class="form-control"
                placeholder="E-mail"
                formControlName="email"
              />
            </form-group>

            <form-group
              [control]="form.controls.line_id"
              [submitted]="submitted"
            >
              <label for="line_id">Line Id</label>
              <input
                type="text"
                id="line_id"
                class="form-control"
                placeholder="Line Id"
                formControlName="line_id"
              />
            </form-group>

            <form-group [control]="form.controls.memo" [submitted]="submitted">
              <label for="memo">Tag</label>
              <div class="w-100">
                <input
                  class="form-control"
                  type="text"
                  tag-input
                  [data]="form.value.memo"
                  class="form-control w-100"
                  formControlName="memo"
                  (instance)="tagInstance = $event"
                />
              </div>
            </form-group>
          </div>

          <div class="card-body pb-2">
            <div class="mt-1 d-flex justify-content-end" *ngIf="form.enabled">
              <button
                type="button"
                class="btn btn-warning mr-1"
                trigger-sidebar
                data-target="#customer-contact-edit"
              >
                <i class="fa fa-times"></i> ยกเลิก
              </button>
              <button type="button" class="btn btn-primary" (click)="submit()">
                <i class="fa fa-save"></i> บันทึก
              </button>
            </div>
          </div>
        </div>
      </form>
      <!-- form start end-->
    </div>
  </div>
</div>

<app-confirm-delete
  [id]="'modalConfirmDelMsgContactEdit'"
  (save)="delete($event)"
></app-confirm-delete>
