<div class="app-content content" *ngIf="!isLoading">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row"></div>
    <div class="content-body">
      <section class="row flexbox-container">
        <div class="col-12 d-flex align-items-center justify-content-center">
          <div class="col-lg-4 col-md-8 col-10 box-shadow-2 p-0">
            <div class="card border-grey border-lighten-3 m-0">
              <div class="card-header border-0">
                <div class="card-title text-center">
                  <div class="p-1">
                    <h3>{{ title }}</h3>
                    <!-- <img
                      src="assets/app-assets/images/logo/stack-logo-dark.png"
                      alt="branding logo"
                    /> -->
                  </div>
                </div>
                <h6
                  class="card-subtitle line-on-side text-muted text-center font-small-3 pt-2"
                >
                  <span>Login</span>
                </h6>
              </div>
              <div class="card-content">
                <div class="card-body">
                  <app-response-status
                    *ngIf="status"
                    [status]="status"
                  ></app-response-status>
                  <form
                    [formGroup]="form"
                    (ngSubmit)="auth()"
                    class="form-horizontal form-simple"
                  >
                    <form-group
                      [control]="form.controls.email"
                      [submitted]="submitted"
                      class="form-group position-relative has-icon-left mb-0"
                    >
                      <input
                        type="text"
                        class="form-control form-control-lg"
                        placeholder="Your email"
                        formControlName="email"
                      />
                      <div class="form-control-position">
                        <i class="feather icon-user"></i>
                      </div>
                    </form-group>
                    <form-group
                      [control]="form.controls.password"
                      [submitted]="submitted"
                      class="form-group position-relative has-icon-left"
                    >
                      <input
                        type="password"
                        class="form-control form-control-lg"
                        placeholder="Enter Password"
                        formControlName="password"
                      />
                      <div class="form-control-position">
                        <i class="fa fa-key"></i>
                      </div>
                    </form-group>

                    <button
                      type="submit"
                      class="btn btn-primary btn-lg btn-block"
                    >
                      <i class="feather icon-unlock"></i> Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<!-- END: Content-->
