import {
  Component,
  OnInit,
  Input,
  NgZone,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SalesTeamMembersService, EmployeeService } from "src/app/services";
import moment from "moment";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-sales-team-member-create",
  templateUrl: "./sales-team-member-create.component.html",
  styleUrls: ["./sales-team-member-create.component.css"],
})
export class SalesTeamMemberCreateComponent {
  isLoading = true;
  @Input("sales-team-id") sales_team_id: string;
  @Input() employees = [];
  reset: boolean = false;
  status = null;
  salesTeamMembers = [];
  form: FormGroup;
  submitted = false;
  member_id: string;

  typing = new Observable<string>();

  select2Data = [];
  select2Options = {
    // minimumInputLength: 1,
    language: {
      errorLoading: function () {
        return "ไม่สามารถค้นข้อมูลได้";
      },
      inputTooLong: function (n) {
        return "โปรดลบออก " + (n.input.length - n.maximum) + " ตัวอักษร";
      },
      inputTooShort: function (n) {
        return (
          "โปรดพิมพ์เพิ่มอีก " + (n.minimum - n.input.length) + " ตัวอักษร"
        );
      },
      loadingMore: function () {
        return "กำลังค้นข้อมูลเพิ่ม…";
      },
      maximumSelected: function (n) {
        return "คุณสามารถเลือกได้ไม่เกิน " + n.maximum + " รายการ";
      },
      noResults: function () {
        return "ไม่พบข้อมูล";
      },
      searching: function () {
        return "กำลังค้นข้อมูล…";
      },
      removeAllItems: function () {
        return "ลบรายการทั้งหมด";
      },
    },

    ajax: {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token"),
        "Content-Type": "application/json",
      },
      delay: 250,
      url: environment.apiUrl + "/employees",
      dataType: "json",
      data: (params) => {
        var query = {
          search_all: params.term,
          exclude: this.salesTeamMembers.map((v) => v.user_id).join(","),
        };
        // Query parameters will be ?search=[term]&type=public
        return query;
      },
      processResults: function (data) {
        let items = data.resultData.map((emp) => {
          return {
            id: emp.user_info.user_id,
            text: `[${emp.emp_code}] ${emp.user_info?.firstname_th || ''} ${emp.user_info?.lastname_th || ''}`,
          };
        });
        // Transforms the top-level key of the response object from 'items' to 'results'
        return {
          results: items,
        };
      },
    },
  };

  load = this.SalesTeamMembersService.load;

  filter = {};
  needReload = false;
  @Output() done = new EventEmitter();

  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private SalesTeamMembersService: SalesTeamMembersService
  ) {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      user_id: ["", Validators.required],
    });
  }

  onChangePage(members: Array<any>) {
    this.salesTeamMembers = members.map((member) => {
      return {
        ...member,
        fullname: `${member.user_info?.firstname_th || ''} ${member.user_info?.lastname_th || ''}`,
        created_at_text: moment(member.created_at).format("DD/MM/YYYY HH:mm"),
      };
    });
    this.isLoading = false;
  }

  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }

  async submit() {
    this.status = null;
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let response = await this.SalesTeamMembersService.create({
      ...this.form.value,
      sales_team_id: this.sales_team_id,
    });

    this.form.reset();
    this.submitted = false;
    if (response.success) {
      this.doReset();
      this.needReload = true;
    } else {
      if (response.resultCode === "40900") {
        response.error.resultDesc.th =
          "มี user นี้อยู่ในรายการ หรือ เป็นหัวหน้าทีมขายแล้ว";
      }

      this.status = response.error;
    }
  }
  async delete(closeModalEl) {
    let response = await this.SalesTeamMembersService.delete({
      member_id: this.member_id,
    });
    if (response.success) {
      this.doReset();
      this.needReload = true;
    }
    closeModalEl.click();
  }

  select(member_id: string) {
    this.member_id = member_id;
  }

  setUserId(user_id: string) {
    this.form.controls.user_id.setValue(user_id);
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.isLoading = true;
        this.status = null;
        this.submitted = false;
        this.setForm();
        if (this.needReload) {
          this.done.emit();
          this.needReload = false;
        }
      } else {
        this.filter = {
          sales_team_id: this.sales_team_id,
        };
      }
    });
  }
}
