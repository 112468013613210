<div class="app-content content" *ngIf="isReady">
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-8 col-12 mb-2">
        <h3 class="content-header-title mb-1">เพิ่ม Equipment</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/scope']">ข้อมูลบริการสอบเทียบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/scope']">รายการ Scope</a>
              </li>
              <li class="breadcrumb-item text-primary">
                <a  (click)="back()">{{ mit_scope_info?.scope_name }}</a>
              </li>
              <li class="breadcrumb-item text-primary">
                <a (click)="back()">รายการ Equipment</a>
              </li>
              <li class="breadcrumb-item active">เพิ่ม Equipment</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-4 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            [routerLink]="[url]"
            type="button"
            class="btn btn-warning btn-min-width mr-1"
          >
            <i class="fa fa-times"></i> ยกเลิก
          </button>
          <button
            (click)="submit()"
            type="button"
            class="btn btn-primary btn-min-width"
          >
            <i class="fa fa-save"></i> บันทึก
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>
      <section class="basic-elements">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-content">
                <div class="card-body">
                  <form
                    autocomplete="off"
                    class="form form-horizontal"
                    role="form"
                    action=""
                    method="post"
                    [formGroup]="equipmentForm"
                  >
                    <div class="form-body">
                      <h5 class="text-bold-600 mb-1">ข้อมูลบริการสอบเทียบ</h5>
                      <div class="row">
                        <div class="col-lg-6 col-md-12 mb-1">
                          <div class="form-group row">
                            <label class="col-md-4 label-control">
                              Service <span class="danger">*</span> :
                            </label>
                            <div class="col-md-8">
                              <form-group
                                [submitted]="submitted"
                                [control]="
                                  equipmentForm.controls.service_location
                                "
                              >
                                <div class="input-group">
                                  <div
                                    class="d-inline-block custom-control custom-checkbox mr-3"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      formControlName="service_lab"
                                      id="service_lab"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="service_lab"
                                      >Lab</label
                                    >
                                  </div>
                                  <div
                                    class="d-inline-block custom-control custom-checkbox"
                                  >
                                    <input
                                      type="checkbox"
                                      class="custom-control-input"
                                      formControlName="service_onsite"
                                      id="service_onsite"
                                    />
                                    <label
                                      class="custom-control-label"
                                      for="service_onsite"
                                      >Onsite</label
                                    >
                                  </div>
                                </div>
                              </form-group>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label
                              class="col-md-4 label-control"
                              for="mit_scope_id"
                            >
                              Scope <span class="danger">*</span> :
                            </label>
                            <div class="col-md-8">
                              <form-group
                                [submitted]="submitted"
                                [control]="equipmentForm.controls.mit_scope_id"
                              >
                                <select
                                  class="form-control"
                                  id="mit_scope_id"
                                  formControlName="mit_scope_id"
                                  readonly
                                >
                                  <option value="">โปรดเลือก...</option>
                                  <option
                                    *ngFor="let scope of scopes"
                                    [value]="scope.mit_scope_id"
                                  >
                                    {{ scope.scope_name }}
                                  </option>
                                </select>
                              </form-group>
                            </div>
                          </div>

                          <div class="form-group row">
                            <label
                              class="col-md-4 label-control"
                              for="equipment_name"
                            >
                              Equipment <span class="danger">*</span> :
                            </label>
                            <div class="col-md-8">
                              <form-group
                                [submitted]="submitted"
                                [control]="
                                  equipmentForm.controls.equipment_name
                                "
                              >
                                <input
                                  type="text"
                                  id="equipment_name"
                                  formControlName="equipment_name"
                                  class="form-control"
                                />
                              </form-group>
                            </div>
                          </div>

                          <!-- <div class="form-group row">
                            <label
                              class="col-md-4 label-control"
                              for="mit_method_id"
                            >
                              Method <span class="danger">*</span> :
                            </label>
                            <div class="col-md-8">
                              <form-group
                                [submitted]="submitted"
                                [control]="equipmentForm.controls.mit_method_id"
                              >
                                <select
                                  class="form-control"
                                  id="mit_method_id"
                                  formControlName="mit_method_id"
                                >
                                  <option value="">โปรดเลือก...</option>
                                  <option
                                    *ngFor="let method of methods"
                                    [value]="method.mit_method_id"
                                  >
                                    {{ method.method_name }}
                                  </option>
                                </select>
                              </form-group>
                            </div>
                          </div> -->

                          <!-- <div class="form-group row">
                            <label
                              class="col-md-4 label-control"
                              for="mit_cp_id"
                            >
                              CP <span class="danger">*</span> :
                            </label>
                            <div class="col-md-8">
                              <form-group
                                [submitted]="submitted"
                                [control]="equipmentForm.controls.mit_cp_id"
                              >
                                <select
                                  class="form-control"
                                  id="mit_cp_id"
                                  formControlName="mit_cp_id"
                                >
                                  <option value="">โปรดเลือก...</option>
                                  <option
                                    *ngFor="let cp of cps"
                                    [value]="cp.mit_cp_id"
                                    >{{ cp.cp_name }}
                                  </option>
                                </select>
                              </form-group>
                            </div>
                          </div> -->
                        </div>
                      </div>

                      <h5 class="text-bold-600 mb-1">Note</h5>
                      <div class="row">
                        <div class="col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="equipmentForm.controls.note_1"
                            [submitted]="submitted"
                          >
                            <label for="note_1">Note 1</label>
                            <textarea
                              id="note_1"
                              class="form-control"
                              rows="3"
                              formControlName="note_1"
                            >
                            </textarea>
                          </form-group>
                        </div>
                        <div class="col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="equipmentForm.controls.note_2"
                            [submitted]="submitted"
                          >
                            <label for="note_2">Note 2</label>
                            <textarea
                              id="note_2"
                              class="form-control"
                              rows="3"
                              formControlName="note_2"
                            >
                            </textarea>
                          </form-group>
                        </div>
                      </div>

                      <div
                        class="d-flex justify-content-between align-items-center mb-1"
                      >
                        <h5 class="text-bold-600">Calibration detail</h5>
                        <button
                          type="button"
                          class="btn btn-outline-primary"
                          data-toggle="modal"
                          data-target="#create-product-range-price"
                        >
                          <i class="fa fa-plus-circle icon-left"></i> เพิ่ม
                        </button>
                      </div>
                      <div class="table-responsive text-center">
                        <table class="table table-striped table-bordered">
                          <thead>
                            <tr>
                              <th rowspan="2" class="align-middle">#</th>
                              <th rowspan="2" class="align-middle text-left">
                                Method
                              </th>
                              <th rowspan="2" class="align-middle text-left">
                                Service Location
                              </th>
                              <th colspan="2">Range</th>
                              <th colspan="2">Calibration fee (Baht)</th>
                              <th rowspan="2" class="align-middle">
                                Min Point
                              </th>
                              <th rowspan="2" class="align-middle">ISO</th>
                              <!-- <th rowspan="2" class="align-middle">CP</th> -->
                              <th rowspan="2" class="align-middle">Action</th>
                            </tr>
                            <tr>
                              <th>Min</th>
                              <th>Max</th>
                              <th>Start</th>
                              <th>Point</th>
                            </tr>
                          </thead>
                          <tbody class="calibration-detail">
                            <tr *ngFor="let fee of fees; let i = index">
                              <th>{{ i + 1 }}</th>
                              <td class="text-left">{{ fee.method_name }}</td>
                              <td class="text-left">
                                {{fee.service_location}}
                              </td>
                              <td>
                                {{ fee.min_range }}
                                <span
                                  [innerHTML]="fee.unit_short_name_en"
                                ></span>
                              </td>
                              <td>
                                {{ fee.max_range }}
                                <span
                                  [innerHTML]="fee.unit_short_name_en"
                                ></span>
                              </td>
                              <td>{{ fee.start_fee | number: "1.2-2" }}</td>
                              <td>
                                {{ fee.per_point_fee | number: "1.2-2" }}
                              </td>
                              <td>{{ fee.min_point }}</td>
                              <td>
                                {{ fee.isISO === "Y" ? "Yes" : "No" }}
                              </td>
                              <!-- <td>
                                {{ fee.mit_cps }}
                              </td> -->
                              <td>
                                <button
                                  class="btn mr-1 mb-1 bg-warning bg-darken-1 text-white btn-sm btn-edit"
                                  data-target="#edit-product-range-price"
                                  data-toggle="modal"
                                  (click)="selectFee(i)"
                                >
                                  <i class="fa fa-pencil icon-left"></i> แก้ไข
                                </button>
                                <button
                                  type="button"
                                  class="btn mr-1 mb-1 bg-danger bg-darken-1 text-white btn-sm"
                                  data-target="#modalConfirmDelMsg"
                                  data-toggle="modal"
                                  (click)="selectFee(i)"
                                >
                                  <i class="fa fa-trash icon-left"></i> ลบ
                                </button>
                              </td>
                            </tr>
                            <tr *ngIf="!fees.length">
                              <td colspan="10" class="text-center">
                                ไม่มีข้อมูล
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <form-group
                        [control]="equipmentForm.controls.calibration_list"
                        [submitted]="submitted"
                      >
                      </form-group>

                      <div class="row">
                        <div class="col-6">
                          <div class="form-group row" *ngIf="equipmentForm.value.service_lab">
  
                            <label for="cp" class="col-md-4 label-control">CP-Lab <span class="danger">*</span> :</label>
                            <div class="col-md-8">
                              <form-group
                                [control]="equipmentForm.controls.lab_mit_cp_list"
                                [submitted]="submitted"
  
                              >
                                <select
                                  dropdown-select
                                  data-style="dropdown-select"
                                  data-live-search="true"
                                  class="form-control"
                                  formControlName="lab_mit_cp_list"
                                  multiple
                                >
                                  <option *ngFor="let cp of cps" [value]="cp.mit_cp_id"
                                    >{{ cp.cp_name }}
                                  </option>
                                </select>
                              </form-group>
                            </div>
                          </div>
  
                        </div>
                      </div>
  
                      <div class="row">
                        <div class="col-6">
                          <div class="form-group row" *ngIf="equipmentForm.value.service_onsite">
  
                            <label for="cp" class="col-md-4 label-control">CP-Onsite <span class="danger">*</span> :</label>
                            <div class="col-md-8">
                              <form-group
                                [control]="equipmentForm.controls.onsite_mit_cp_list"
                                [submitted]="submitted"
  
                              >
                                <select
                                  dropdown-select
                                  data-style="dropdown-select"
                                  data-live-search="true"
                                  class="form-control"
                                  formControlName="onsite_mit_cp_list"
                                  multiple
                                >
                                  <option *ngFor="let cp of cps" [value]="cp.mit_cp_id"
                                    >{{ cp.cp_name }}
                                  </option>
                                </select>
                              </form-group>
                            </div>
                          </div>
  
                        </div>
                      </div>

                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<app-product-range-price-create
  [methods]="methods"
  [cps]="cps"
  [units]="units"
  (done)="onAddFee($event)"
></app-product-range-price-create>

<app-product-range-price-edit
  [methods]="methods"
  [cps]="cps"
  [units]="units"
  (done)="onEditFee($event)"
  [fee]="fee"
  *ngIf="fee"
  (onClose)="fee = null"
></app-product-range-price-edit>

<app-confirm-delete (save)="onRemoveFee($event)"></app-confirm-delete>
