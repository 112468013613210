import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CustomerAddressService, CustomerContactService, GETService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import { Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { Observable } from "rxjs";

@Component({
  selector: "app-customer-contact",
  templateUrl: "./customer-contact.component.html",
  styleUrls: ["./customer-contact.component.css"],
})
export class CustomerContactComponent implements OnInit {
  isLoading = true;
  customer_contacts: Array<any>;
  status: string;
  customer_contact = null;
  customerAddress = [];
  customerAddressTypes = [];
  reset: boolean = false;
  load: Function = this.CustomerContactService.load;
  @Input("can-edit-customer") canEditCustomer = false;
  @Output("mark-master-contact") markMasterContact = new EventEmitter();
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  filter = {};
  constructor(
    private route: ActivatedRoute,
    private GETService: GETService,
    private CustomerContactService: CustomerContactService,
    private CustomerAddressService: CustomerAddressService,
  ) {}

  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.customer_contacts = [];
    await this.GETService.customerAddressType(null).then(
      (result) => {
        this.customerAddressTypes = result.resultData;
      }
    )
    await this.CustomerAddressService.load(null, {   
      customer_id: this.route.snapshot.paramMap.get("customer_id")}
    ).then((res) => {
      this.customerAddress = res.resultData || [];

      this.customerAddress.sort(function(a,b){
        if(a.customer_address_type_id < b.customer_address_type_id) return -1;
        if(a.customer_address_type_id > b.customer_address_type_id) return 1;
        return 0;
      });
      this.customerAddress = this.customerAddress.map(function(elem) {
        return {
          id: elem.customer_address_id,
          text: `${elem.customer_address_type_info?.customer_address_name || '-'} (แผนก: ${elem?.department || '-'})`,
          data: elem
        }
      });
    });
    this.search({
      customer_id: this.route.snapshot.paramMap.get("customer_id"),
      order_by: "isMaster:desc",
    });
    this.isLoading = false;
  }
  onChangePage(customer_contacts: Array<any>) {
    this.customer_contacts = customer_contacts.map(item=>{
      return {
        ...item,
        related_customer_address_info: {
          ...item.related_customer_address_info,
          customer_address_type_info: this.customerAddressTypes.find(type=> type.customer_address_type_id == item.related_customer_address_info?.customer_address_type_id)
        }
      }
    });
    this.customer_contacts.sort(function(a,b){
      if(a.related_customer_address_info.customer_address_type_id < b.related_customer_address_info.customer_address_type_id) return -1;
      if(a.related_customer_address_info.customer_address_type_id > b.related_customer_address_info.customer_address_type_id) return 1;
      return 0;
    });
  }
  select(customer_contact) {
    this.customer_contact = Object.assign({}, customer_contact);
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);

    if (this.customer_contact?.isMaster === "1") {
      this.markMasterContact.emit();
      this.customer_contact = null;
    }
  }
  search(filter: object) {
    this.filter = filter;
  }
  async delete(closeModalEl) {
    let response = await this.CustomerContactService.delete({
      customer_contact_id: this.customer_contact.customer_contact_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }

  async markMaster(customer_contact) {
    let response = await this.CustomerContactService.update({
      ...customer_contact,
      isMaster: "1",
    });

    this.doReset(response.success || response.error);
    if (response.success) {
      this.markMasterContact.emit();
    }
  }
}
