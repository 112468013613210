import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class TransportMasterBookingService {
    async load(paging?, filter?) {
        let result = null;
        try {
        result = await Axios().get(`/transport-masterbooking`, {
            params: { offset: paging?.offset, limit: paging?.limit, ...filter},
        });
        result = result.data;
        } catch (error) {
        result = error.response.data;
        }
        return result;
    }

    async create(data) {
      let {
        booking_type,
        booking_sub_type,
        messenger_id,
        booking_date,
        booking_time,
        customer_id,
        location,
        extra_message,
        quotation_info_doc_no,
        job_order_info_doc_no,
        transport_receive_info_doc_no,
        transport_type_id,
        contact_name,
        contact_phonenumber,
        doc_status_id,
        ref_transport_masterbooking_id,

        transport_receive_info_id,
        acc_billing_note_info_id,
        acc_billing_note_info_doc_no,
        acc_invoice_info_doc_no,
        acc_invoice_info_grand_total,
        ref_doc_type,
        ref_doc_id,
        created_by
      } = data;
      let result = null;
      try {
        result = await Axios().post(`/transport-masterbooking`, {
          booking_type,
          booking_sub_type,
          messenger_id,
          booking_date,
          booking_time,
          customer_id,
          location,
          extra_message,
          quotation_info_doc_no,
          job_order_info_doc_no,
          transport_receive_info_doc_no,
          transport_type_id,
          contact_name,
          contact_phonenumber,
          doc_status_id,
          ref_transport_masterbooking_id,

          transport_receive_info_id,
          acc_billing_note_info_id,
          acc_billing_note_info_doc_no,
          acc_invoice_info_doc_no,
          acc_invoice_info_grand_total,
          ref_doc_type,
          ref_doc_id,
          created_by
        });
        result = result.data;
      } catch (error) {
        result = error.response.data;
      }
      return result;
  }

    async update(data) {
        let {
          transport_masterbooking_id,
          booking_type,
          booking_sub_type,
          messenger_id,
          booking_date,
          booking_time,
          customer_id,
          location,
          extra_message,
          quotation_info_doc_no,
          job_order_info_doc_no,
          transport_receive_info_doc_no,
          acc_invoice_info_doc_no,
          transport_type_id,
          contact_name,
          contact_phonenumber,
          doc_status_id,
          closed_by,
          closed_at
        } = data;
        let result = null;
        try {
          result = await Axios().put(`/transport-masterbooking/${transport_masterbooking_id}`, {
            booking_type,
            booking_sub_type,
            messenger_id,
            booking_date,
            booking_time,
            customer_id,
            location,
            extra_message,
            quotation_info_doc_no,
            job_order_info_doc_no,
            transport_receive_info_doc_no,
            acc_invoice_info_doc_no,
            transport_type_id,
            contact_name,
            contact_phonenumber,
            doc_status_id,
            closed_by,
            closed_at
          });
          result = result.data;
        } catch (error) {
          result = error.response.data;
        }
        return result;
    }
}
