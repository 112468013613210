import { Injectable } from "@angular/core";
import { 
    AccTaxInvoiceInfoService,
    AccTaxInvoiceItemService,
    AccTaxInvoiceContactService
} from '.';
import { AccPaymentService } from "../invoice";
import { QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from "../quotations";

@Injectable({
   providedIn: "root"
})
export class CoreService {
  constructor(
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccTaxInvoiceContactService: AccTaxInvoiceContactService,
    private AccTaxInvoiceItemService: AccTaxInvoiceItemService,
    public AccPaymentService: AccPaymentService,

    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public utils : QuotationUtilsService,
  ) {}

  async createInvoice(
    data: any,
    taxInvoiceItem?: any
    ){
    if(data){
        let invoice_info = data;
        try {
          let invoiceResponse = await this.AccTaxInvoiceInfoService.create({
            ...invoice_info,
          });
          if (invoiceResponse.success) {
            
            if(invoice_info.acc_payment_id){
              await this.AccPaymentService.getById({acc_payment_id: invoice_info.acc_payment_id})
              .then(async res=>{
                if(res.success){
                  let  paymentResponse = await this.AccPaymentService
                  .update({
                    ...res.resultData,
                    created_acc_tax_invoice_info_id: invoiceResponse.resultData.acc_tax_invoice_info_id,
                  });
                  if(!paymentResponse.success){
                    throw paymentResponse.error
                  }
                }
              })
            }

            if(data.customer_contact_ids){
              await this.saveCustomerContactList(data.customer_contact_ids, invoiceResponse);
            } 

            if(taxInvoiceItem?.taxInvoiceItems){
              await this.saveInvoiceList(taxInvoiceItem?.taxInvoiceItems, invoiceResponse);
            }   

            return {
              status: true,
              message: invoiceResponse.success
            }
          } else {
            if (invoiceResponse.resultCode === "40900") {
              return {
                status: false,
                message: 'codeDuplicate',
                field: invoiceResponse.field,
                error: invoiceResponse.error
              }
            } else {
              throw invoiceResponse.error;
            }
          }
        } catch (error) {
          return {
            status: false,
            message: error
          }
        }
      }
  }

  async editInvoice(
    data: any, 
    deleteContactList?,
    taxInvoiceItem?: any
    ){
    if(data){

      let invoice_info = data;
      try {
        let invoiceResponse = await this.AccTaxInvoiceInfoService.update({
          ...invoice_info,
        });
        if (invoiceResponse.success) {
          if(data.customer_contact_ids){
            await this.saveCustomerContactList(data.customer_contact_ids, invoiceResponse);
          } 
          if(deleteContactList){
            await Promise.all(deleteContactList.map(async (v)=>{
              let accInvoiceContactResponse = await this.AccTaxInvoiceContactService
              .delete({
                 ...v
              });
              if (!accInvoiceContactResponse.success) {
                throw accInvoiceContactResponse.error;
              }
            }));
          }  

          if(taxInvoiceItem?.taxInvoiceItems){
            await this.saveInvoiceList(taxInvoiceItem?.taxInvoiceItems, invoiceResponse);
          }   

          if(taxInvoiceItem?.delelteTaxInvoiceItems){
            await Promise.all(taxInvoiceItem?.delelteTaxInvoiceItems.map(async (v)=>{

              if(v.acc_tax_invoice_items_id){
                let invoiceItemResponse = await this.AccTaxInvoiceItemService.delete({
                  ...v
               });
               if (!invoiceItemResponse.success) {
                 throw invoiceItemResponse.error;
               }
              }
            }));
          }     
          return {
            status: true,
            message: invoiceResponse.success
          }
        } else {
          if (invoiceResponse.resultCode === "40900") {
            return {
              status: false,
              message: 'codeDuplicate',
              error: invoiceResponse.error
            }
          } else {
            throw invoiceResponse.error;
          }
        }
      } catch (error) {
        return {
          status: false,
          message: error
        }
      }
    }
  }

  async saveCustomerContactList(contactList, invoiceResponse){
    await Promise.all(contactList.map(async (v)=>{
      if(v.acc_tax_invoice_contact_id){

      }else{
        let  accInvoiceContactResponse = await this.AccTaxInvoiceContactService
        .create(
          {
            acc_tax_invoice_info_id: invoiceResponse.resultData.acc_tax_invoice_info_id,
            ...v
          });
        if (!accInvoiceContactResponse.success) {
          throw accInvoiceContactResponse.error;
        }
      }
    }));
  }

  async saveInvoiceList(invoiceList, invoiceResponse){

    await Promise.all(
      invoiceList.map(async (value: any, index: number)=>{

        if(value.acc_tax_invoice_items_id){
          let invoiceItemResponse = await this.AccTaxInvoiceItemService
          .update({
            ...value,
            price: parseFloat(this.utils.convertStingToNumber(value.price)),
            acc_tax_invoice_info_id: invoiceResponse.resultData.acc_tax_invoice_info_id,
            order_by: index
          });
          this.updateQuotationItem(value, invoiceResponse);
          if(!invoiceItemResponse.success){
            throw invoiceItemResponse.error;
          }

        }else{
          let  invoiceItemResponse = await this.AccTaxInvoiceItemService
          .create({
            ...value,
            price: parseFloat(this.utils.convertStingToNumber(value.price)),
            acc_tax_invoice_info_id: invoiceResponse.resultData.acc_tax_invoice_info_id,
            order_by: index
          });
          this.updateQuotationItem(value, invoiceResponse);
          if(!invoiceItemResponse.success){
            throw invoiceItemResponse.error;
          }
        }
    }));
  }

  async updateQuotationItem(value, invoiceResponse){
    if(value.item_type == 'calibration_service'){
      let getMitItemResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});

      if(getMitItemResponse.success){
        await this.QuotationMitItemService.update({
          ...getMitItemResponse.resultData[0],
          acc_tax_invoice_info_id: invoiceResponse.resultData.acc_tax_invoice_info_id,
          acc_tax_invoice_info_doc_no: invoiceResponse.resultData.doc_no,
          acc_tax_invoice_info_status: invoiceResponse.resultData.doc_status_id,
        })
      }
    }else{
      let getProductItemResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
      if(getProductItemResponse.success){
        await this.QuotationProductItemService.update({
          ...getProductItemResponse.resultData,
          acc_tax_invoice_info_id: invoiceResponse.resultData.acc_tax_invoice_info_id,
          acc_tax_invoice_info_doc_no: invoiceResponse.resultData.doc_no,
          acc_tax_invoice_info_status: invoiceResponse.resultData.doc_status_id,
        })
      }
    }
  }
}

