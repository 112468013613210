import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class EmployeePositionService {
  async load({ emp_department_id }) {
    let result = null;
    try {
      result = await Axios().get(`/employee-position`, {
        params: {
          emp_department_id,
        },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
