import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class ProductCalibrationFeeCPService {
  async create({ product_calibration_fee_id, mit_cp_id }) {
    let result = null;
    try {
      result = await Axios().post(`/product-calibration-fee-cp`, {
        product_calibration_fee_id,
        mit_cp_id,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    product_calibration_fee_cp_id,
    product_calibration_fee_id,
    mit_cp_id,
  }) {
    let result = null;
    try {
      result = await Axios().put(
        `/product-calibration-fee-cp/${product_calibration_fee_cp_id}`,
        {
          product_calibration_fee_id,
          mit_cp_id,
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ product_calibration_fee_cp_id }) {
    let result = null;
    try {
      result = await Axios().delete(
        `/product-calibration-fee-cp/${product_calibration_fee_cp_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async deleteByQueryString({ product_calibration_fee_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/product-calibration-fee-cp`, {
        params: {
          product_calibration_fee_id,
        },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  // async load(paging?, filter?) {
  //   let result = null;
  //   try {
  //     result = await Axios().get(`/product-calibration-fee-cp`, {
  //       params: { offset: paging?.offset, limit: paging?.limit, ...filter },
  //     });
  //     result = result.data;
  //   } catch (error) {
  //     result = error.response.data;
  //   }
  //   return result;
  // }

  // async getById({ product_calibration_fee_id }) {
  //   let result = null;
  //   try {
  //     result = await Axios().get(`/product-calibration-fee-cp/${product_calibration_fee_id}`);
  //     result = result.data;
  //   } catch (error) {
  //     result = error.response.data;
  //   }
  //   return result;
  // }
}
