import {
  Component,
  OnInit,
  NgZone,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { MITMethodService } from "src/app/services";

@Component({
  selector: "app-method-crud",
  templateUrl: "./method-crud.component.html",
  styleUrls: ["./method-crud.component.css"],
})
export class MethodCrudComponent implements OnInit {
  methods = [];
  status: string;
  mit_method_id: string;
  reset: boolean = false;
  filter = {};
  unitTypes = [];

  method_name: string;
  removeList = [];
  removeIndex: number;
  @Output() done = new EventEmitter();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,
    private MITMethodService: MITMethodService
  ) {}

  async ngOnInit() {
    this.method_name = "";
    this.removeList = [];
    let response = await this.MITMethodService.load(null, {
      order_by: "created_at:asc",
    });
    if (response.success) {
      this.methods = response.resultData || [];
      if (!this.methods.length) {
        this.methods = [
          {
            method_name: "",
          },
        ];
      }
    }
  }
  select(index: number) {
    this.removeIndex = index;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
      this.mit_method_id = null;
    }, 50);
  }
  search(filter) {
    this.filter = filter;
  }
  async delete(closeModalEl) {
    if (this.removeIndex >= 0) {
      let removeEl = this.methods[this.removeIndex];
      if (removeEl && !removeEl.isUsed) {
        this.removeList.push(Object.assign({}, this.methods[this.removeIndex]));

        this.methods = this.methods.filter(
          (v, index) => index !== this.removeIndex
        );
        this.removeIndex = null;
      }
    }

    if (!this.methods.length) {
      this.methods.push({
        method_name: "",
      });
    }

    closeModalEl.click();
  }
  addMore() {
    this.methods.push({
      method_name: null,
    });
  }
  async submit() {
    let status = null;
    let methods = this.methods.filter((m) => m.method_name);
    for (let method of methods) {
      if (method.mit_method_id) {
        let response = await this.MITMethodService.update(method);
        status = response.error || response.success;
      } else {
        let response = await this.MITMethodService.create(method);
        status = response.error || response.success;
      }
    }

    for (let method of this.removeList) {
      if (method.mit_method_id) {
        let response = await this.MITMethodService.delete({
          mit_method_id: method.mit_method_id,
        });
        status = response.error || response.success;
      }
    }

    // if (this.method_name) {
    //   let response = await this.MITMethodService.create({
    //     method_name: this.method_name,
    //   });
    //   status = response.error || response.success;
    // }
    this.done.emit(status);
    this.closeModalBtn.nativeElement.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (!isClose) {
        this.ngOnInit();
      }
    });
  }
}
