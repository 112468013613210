import {
    Directive,
    ElementRef,
    Output,
    EventEmitter,
    Input,
  } from "@angular/core";
  import moment from "moment";
  declare const $;
  @Directive({
    selector: "[time-picker]",
  })
  export class TimePickerDirective {
    @Output() instance = new EventEmitter();
    @Output() input = new EventEmitter();
    datetimepicker = null;
    @Input("date") date = null;
    @Input("min-date") minDate = undefined;
    @Input("max-date") maxDate = undefined;
  
    constructor(el: ElementRef) {
      this.datetimepicker = $(el.nativeElement);
      setTimeout(() => {
        this.datetimepicker.on("keydown", (e) => {
          e.preventDefault();
        });
  
        let picker = this.datetimepicker.datetimepicker({
          format: "HH:mm",
        });
  
        picker.on("dp.change", (e) => {
          if (e.date) {
            this.input.emit({
              target: {
                value: e.date.format(),
              },
            });
          }
        });
  
        this.datetimepicker.next().click(() => {
          this.datetimepicker.focus();
        });
  
        if (this.date) {
          this.datetimepicker
            .data("DateTimePicker")
            .date(this.date);
          this.input.emit({
            target: {
              value: this.date,
            },
          });
        }
  
        this.instance.emit(picker);
      }, 50);
    }
  }
  