import { Injectable } from "@angular/core";
import { 
    QuotationService,
    QuotationMitItemLog,
    QuotationFileService,
    QuotationNoteService,
    QuotationUtilsService,
    QuotationMitItemService,
    QuotationContactService,
    QuotationProductItemService,
    QuotationMitItemDetailService,
    QuotationMitItemAttributeService,
    QuotationMitItemDetailPointService,
    QuotationMitItemDetailRangeService,
} from '.';
import { UploadService } from 'src/app/services';
import { TransportreceiveItemService } from "../transport-receives";
import { JobOrderCalibrationItemService } from "../job-orders";

@Injectable({
   providedIn: "root"
})
export class CoreService {
  directoryName = 'quotations';
  constructor(
    private UploadService: UploadService,
    private QuotationService: QuotationService,
    private QuotationMitItemLog: QuotationMitItemLog,
    private QuotationNoteService: QuotationNoteService,
    private QuotationFileService: QuotationFileService,

    private QuotationMitItemService: QuotationMitItemService,
    private QuotationContactService: QuotationContactService,
    private QuotationProductItemService:QuotationProductItemService,

    private TransportreceiveItemService: TransportreceiveItemService,
    private QuotationMitItemDetailService: QuotationMitItemDetailService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService,
    private QuotationMitItemAttributeService: QuotationMitItemAttributeService,
    private QuotationMitItemDetailPointService: QuotationMitItemDetailPointService,
    private QuotationMitItemDetailRangeService: QuotationMitItemDetailRangeService,

    public utils : QuotationUtilsService
  ) {}

  async createQuotation(
    data: any, 
    files: any, 
    mitItems: any,
    noteItemIn: any,
    noteItemOut: any
    ){
    if(data){
        let quotation_product_items = data.quotation_product_items;
        let quotation_info = data;
        delete quotation_info.quotation_product_items;
        try {
          let quotationResponse = await this.QuotationService.create({
            ...quotation_info,
            discount:parseFloat(this.utils.convertStingToNumber(quotation_info.discount))
          });
          if (quotationResponse.success) {
            if(data.customer_contact_ids){
              await this.saveCustomerContactList(data.customer_contact_ids, quotationResponse);
            } 
            if(quotation_product_items){
              await this.saveProductList(quotation_product_items, quotationResponse);
            }
            if(files.length > 0){
              await this.saveFile(files, quotationResponse);
            }
            if (mitItems.quotationMitItems){
              await Promise.all(mitItems.quotationMitItems.map(async (v: any, index: number) =>{
                if(v.quotation_mit_item_id){
                  let quotationMitItemResponse = await this.QuotationMitItemService.update({
                    ...v, 
                    customer_id: quotation_info.customer_id,
                    price: parseFloat(this.utils.convertStingToNumber(v.price)),
                    discount: parseFloat(this.utils.convertStingToNumber(v.discount)),
                    quotation_info_id: quotationResponse.resultData.quotation_info_id,
                    quotation_info_doc_no: quotationResponse.resultData.doc_no,
                    quotation_info_status: quotationResponse.resultData.doc_status_id,
                    order_by: index+1
                  });
                  if (quotationMitItemResponse.success) {
                    if(v?.job_order_calibration_item_info?.job_order_calibration_item_id){
                      let jobOrderItemResponse = await this.JobOrderCalibrationItemService.update(
                        {
                          ...v.job_order_calibration_item_info,
                          quotation_info_id: quotationResponse.resultData.quotation_info_id,
                        }
                      );
                      if(jobOrderItemResponse.error){
                        throw jobOrderItemResponse.error;
                      }
                    }
                    if(v.quotation_mit_item_details){
                      await this.saveDetailArray(v.quotation_mit_item_details, quotationMitItemResponse);
                    }
                    if(v.quotation_mit_item_attributes){
                      await this.saveAttribute(v.quotation_mit_item_attributes, quotationMitItemResponse);
                    }
                    if(v.mit_item_logs?.length > 0){
                      await this.saveLogArray(v.mit_item_logs, quotationMitItemResponse, '');
                    }
      
                  }else{
                    throw quotationMitItemResponse.error;
                  }
                }else{
                  let quotationMitItemResponse = await this.QuotationMitItemService.create({
                    ...v, 
                    customer_id: quotation_info.customer_id,
                    price: parseFloat(this.utils.convertStingToNumber(v.price)),
                    discount: parseFloat(this.utils.convertStingToNumber(v.discount)),
                    quotation_info_id: quotationResponse.resultData.quotation_info_id,
                    quotation_info_doc_no: quotationResponse.resultData.doc_no,
                    quotation_info_status: quotationResponse.resultData.doc_status_id,
                    order_by: index+1
                  });
                  if (quotationMitItemResponse.success) {
                    if(v.quotation_mit_item_details){
                      await this.saveDetailArray(v.quotation_mit_item_details, quotationMitItemResponse);
                    }
                    if(v.quotation_mit_item_attributes){
                      await this.saveAttribute(v.quotation_mit_item_attributes, quotationMitItemResponse);
                    }
                    if(v.mit_item_logs?.length >0){
                      await this.saveLogArray(v.mit_item_logs, quotationMitItemResponse, '');
                    }
                    if(v.transport_receive_item_id){
                      let transportResponse = await this.TransportreceiveItemService.getById({transport_receive_item_id: v.transport_receive_item_id});
                      if(transportResponse.success){
                        await this.TransportreceiveItemService.updateNoQT(
                          {
                          ...transportResponse.resultData,
                          noqt_quotation_info_id: quotationResponse.resultData.quotation_info_id
                          }
                        );
                      }
                    }
                  }else{
                    throw quotationMitItemResponse.error;
                  }
                }
              }));
            }

            if (noteItemIn.length > 0){
              this.saveNoteArray(noteItemIn, quotationResponse);
            }

            if (noteItemOut.length > 0){
              this.saveNoteArray(noteItemOut, quotationResponse);
            }
            return {
              status: true,
              message: quotationResponse.success
            }
          } else {
            if (quotationResponse.resultCode === "40900") {
              return {
                status: false,
                message: 'codeDuplicate',
                error: quotationResponse.error
              }
            } else {
              throw quotationResponse.error;
            }
          }
        } catch (error) {
          return {
            status: false,
            message: error
          }
        }
      }
  }
  async editQuotation(
    data: any, 
    deleteContactList: any, 
    files: any, 
    mitItems: any,
    noteItemIn: any,
    noteItemOut: any,
    deleteProductItemList = [], 
    deleteNoteList = [],
    quotation_info_id: string
    ){
    if(data){
      data.quotation_info_id = quotation_info_id;

      let quotation_product_items = data.quotation_product_items;
      let quotation_info = data;
      delete quotation_info.quotation_product_items;
      try {
        let quotationResponse = await this.QuotationService.update({
          ...quotation_info,
          discount:parseFloat(this.utils.convertStingToNumber(quotation_info.discount))
        });
        if (quotationResponse.success) {
          if(deleteContactList){
            await Promise.all(deleteContactList.map(async (v)=>{
              let quotationContactResponse = await this.QuotationContactService.delete({
                 ...v
              });
              if (!quotationContactResponse.success) {
                throw quotationContactResponse.error;
              }
            }));
          }
          if(deleteProductItemList){
            await Promise.all(deleteProductItemList.map(async (v)=>{
              if(v.quotation_item_id){
                let quotationProductItemResponse = await this.QuotationProductItemService.delete({
                  quotation_item_id: v.quotation_item_id,
                });
                if (!quotationProductItemResponse.success) {
                  throw quotationProductItemResponse.error;
                }
              }
            }));
          }
          if(data.customer_contact_ids){
            await this.saveCustomerContactList(data.customer_contact_ids, quotationResponse);
          } 
          if(quotation_product_items){
            await this.saveProductList(quotation_product_items, quotationResponse);
          }
          if(files.length > 0){
            await this.saveFile(files, quotationResponse);
          }

          if(mitItems){
            if (mitItems.quotationMitItems){
              await Promise.all(mitItems.quotationMitItems.map(async (v:any, index: number) =>{
                if(v.quotation_mit_item_id){
                  let quotationMitItemResponse = await this.QuotationMitItemService.update({
                    ...v, 
                    customer_id: quotation_info.customer_id,
                    price: parseFloat(this.utils.convertStingToNumber(v.price)),
                    discount: parseFloat(this.utils.convertStingToNumber(v.discount)),
                    quotation_info_id: quotationResponse.resultData.quotation_info_id,
                    quotation_info_doc_no: quotationResponse.resultData.doc_no,
                    quotation_info_status: quotationResponse.resultData.doc_status_id,
                    order_by: index+1
                  });
                  if (quotationMitItemResponse.success) {
                    if(v?.job_order_calibration_item_info?.job_order_calibration_item_id){
                      let jobOrderItemResponse = await this.JobOrderCalibrationItemService.update(
                        {
                          ...v.job_order_calibration_item_info,
                          quotation_info_id: quotationResponse.resultData.quotation_info_id,
                        }
                      );
                      if(jobOrderItemResponse.error){
                        throw jobOrderItemResponse.error;
                      }
                    }
                    if(v.quotation_mit_item_details){
                      await this.saveDetailArray(v.quotation_mit_item_details, quotationMitItemResponse, data);
                    }
                    if(data.doc_status_id == 'REVISE_WAITING_FOR_APPROVE'){

                      if(Array.isArray(v.quotation_mit_item_detail_info)){
                        let quotation_mit_item_details = v.quotation_mit_item_detail_info.filter(item=> item.is_non_calibration == 'true').map(res=>{
                          return {
                            ...res,
                            is_non_calculate: 'true'
                          }
                        })
                        if(quotation_mit_item_details.length > 0){
                          await this.saveDetailArray(quotation_mit_item_details, quotationMitItemResponse);
                        }
                      }
                    }
                    if(v.quotation_mit_item_attributes){
                      await this.saveAttribute(v.quotation_mit_item_attributes, quotationMitItemResponse);
                    }
                    if(v.mit_item_logs?.length > 0){
                      await this.saveLogArray(v.mit_item_logs, quotationMitItemResponse, '');
                    }
                    if(v.delMitItemDetails){
                      await Promise.all(v.delMitItemDetails.map(async (d)=>{
                        if(d.detail_type == 'point'){
                          await Promise.all(d.quotation_mit_item_detail_points.map(async (p)=>{
                            let quotationMitItemDetailPoint = await this.QuotationMitItemDetailPointService.delete({
                              quotation_mit_item_detail_point_id: p.quotation_mit_item_detail_point_id,
                            });
                            if(!quotationMitItemDetailPoint.success){
                              throw quotationMitItemDetailPoint.error;
                            }
                          }));
                        }else{
                          let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.delete({
                            quotation_mit_item_detail_range_id: d.quotation_mit_item_detail_range.quotation_mit_item_detail_range_id,
                          });
                          if(!quotationMitItemDetailRange.success){
                            throw quotationMitItemDetailRange.error;
                          }
                        }
                        let quotationMitItemDetailResponse = await this.QuotationMitItemDetailService.delete({
                          quotation_mit_item_detail_id: d.quotation_mit_item_detail_id,
                        });
                        if(!quotationMitItemDetailResponse.success){
                          throw quotationMitItemDetailResponse.error;
                        }
                      }));
                    }
                    if(v.delMitItemDetailPoints){
                      await Promise.all(v.delMitItemDetailPoints.map(async (p)=>{
                        if(p.quotation_mit_item_detail_point_id){
                          let quotationMitItemDetailPoint = await this.QuotationMitItemDetailPointService.delete({
                            quotation_mit_item_detail_point_id: p.quotation_mit_item_detail_point_id,
                          });
                          if(!quotationMitItemDetailPoint.success){
                            throw quotationMitItemDetailPoint.error;
                          }
                        }
                      }));
                    }
                    if(v.delMitItemDetailRanges){
                      await Promise.all(v.delMitItemDetailRanges.map(async (r)=>{
                        if(r.quotation_mit_item_detail_range_id){
                          let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.delete({
                            quotation_mit_item_detail_range_id: r.quotation_mit_item_detail_range_id,
                          });
                          if(!quotationMitItemDetailRange.success){
                            throw quotationMitItemDetailRange.error;
                          }
                        }
                      }));
                    }
                    if(v.delMitItemAttributes){
                      await Promise.all(v.delMitItemAttributes.map(async (a)=>{
                        if(a.quotation_mit_item_attribute_id){
                          let quotationMitItemAttribute = await this.QuotationMitItemAttributeService.delete({
                            quotation_mit_item_attribute_id: a.quotation_mit_item_attribute_id,
                          });
                          if(!quotationMitItemAttribute.success){
                            throw quotationMitItemAttribute.error;
                          }
                        }
                      }));
                    }
                  }else{
                    throw quotationMitItemResponse.error;
                  }
                }else{
                  let quotationMitItemResponse = await this.QuotationMitItemService.create({
                    ...v,
                    customer_id: quotation_info.customer_id,
                    price: parseFloat(this.utils.convertStingToNumber(v.price)),
                    discount: parseFloat(this.utils.convertStingToNumber(v.discount)),
                    quotation_info_id: quotationResponse.resultData.quotation_info_id,
                    quotation_info_doc_no: quotationResponse.resultData.doc_no,
                    quotation_info_status: quotationResponse.resultData.doc_status_id,
                    order_by: index+1
                  });
                  if (quotationMitItemResponse.success) {
                    if(v.quotation_mit_item_details){
                      await this.saveDetailArray(v.quotation_mit_item_details, quotationMitItemResponse);
                    }
  
                    if(v.quotation_mit_item_attributes){
                     await this.saveAttribute(v.quotation_mit_item_attributes, quotationMitItemResponse);
                    }
                    
                    if(v.mit_item_logs?.length > 0){
                      await this.saveLogArray(v.mit_item_logs, quotationMitItemResponse, '');
                    }

                    if(v.transport_receive_item_id){
                      let transportResponse = await this.TransportreceiveItemService.getById({transport_receive_item_id: v.transport_receive_item_id});
                      if(transportResponse.success){
                        await this.TransportreceiveItemService.updateNoQT(
                          {
                          ...transportResponse.resultData,
                          noqt_quotation_info_id: quotationResponse.resultData.quotation_info_id
                          }
                        );
                      }
                    }
                  }else{
                    throw quotationMitItemResponse.error;
                  }
                }
              }));
            }
            if(mitItems.deleteMitItemList){
              await this.deleteAllMitItemList(mitItems.deleteMitItemList);
            }
          }

          if (noteItemIn.length > 0){
           this.saveNoteArray(noteItemIn, quotationResponse);
          }

          if (noteItemOut.length > 0){
            this.saveNoteArray(noteItemOut, quotationResponse);
          }

          if(deleteNoteList.length > 0){
            let result = [];
            deleteNoteList.filter(function(item){
              var i = result.findIndex(x => x.quotation_note_id == item.quotation_note_id);
              if(i <= -1){
                result.push(item);
              }
              return null;
            });
            await Promise.all(result.map(async (v)=>{
              if(v.quotation_note_id){
                let QuotationNoteService = await this.QuotationNoteService.delete({
                  quotation_note_id: v.quotation_note_id,
                });
                if (!QuotationNoteService.success) {
                  throw QuotationNoteService.error;
                }
              }
            }));
          }

          return {
            status: true,
            message: quotationResponse.success
          }
        } else {
          throw quotationResponse.error;
        }
      } catch (error) {
        return {
          status: false,
          message: error
        }
      }
    }
  }
  async copyDocumentToNewQuotation(
    data: any, 
    files: any,
    mitItems: any, 
    noteItemIn: any,
    noteItemOut: any
    ){
    if(data){
      try {
        let quotation_product_items = data.quotation_product_items;
        let quotation_info = data;
        delete quotation_info.quotation_product_items;
        let quotationResponse = await this.QuotationService.create({
          ...quotation_info,
          discount:parseFloat(this.utils.convertStingToNumber(quotation_info.discount))
        });
        if (quotationResponse.success) {
          if(data.customer_contact_ids){
            await this.saveCustomerContactList(data.customer_contact_ids, quotationResponse);
          } 
          if(quotation_product_items){
            await Promise.all(quotation_product_items.map(async (v, index)=>{
              let quotationProductItemResponse = await this.QuotationProductItemService.create(
                {
                  ...v,
                  price: parseFloat(this.utils.convertStingToNumber(v.price)),
                  discount: parseFloat(this.utils.convertStingToNumber(v.discount)),
                  quotation_info_id: quotationResponse.resultData.quotation_info_id,
                  quotation_info_doc_no: quotationResponse.resultData.doc_no,
                  quotation_info_status: quotationResponse.resultData.doc_status_id,

                  transport_receive_item_id: "null",
                  transport_receive_info_id: "null",
                  transport_receive_info_doc_no: "null",
                  transport_receive_item_status: "null",
                  transport_receive_info_status: "null",
                  job_order_info_id: "null",
                  job_order_info_status: "null",
                  job_order_info_doc_no: "null",
                  job_order_info_type: "null",
                  job_order_calibration_item_status: "null",
                  job_order_calibration_item_id: "null",
                  acc_invoice_info_doc_no: "",
                  acc_invoice_info_status: "",
                  acc_tax_invoice_info_id: "",
                  acc_tax_invoice_info_doc_no: "",
                  acc_tax_invoice_info_status: "",
                  acc_debit_note_info_doc_no: "",
                  acc_debit_note_info_status: "",
                  acc_credit_note_info_id: "",
                  acc_credit_note_info_doc_no: "",
                  acc_credit_note_info_status: "",
                  related_job_order_info_id: "",
                  cost_of_sales: "",
  
                  revised_at: undefined,
                  return_date: undefined,
                  ready_to_billing: undefined,
                  lock_invoice_at: undefined,
                  unlock_invoice_at: undefined,

                  order_by: index
                });
              if (!quotationProductItemResponse.success) {
                throw quotationProductItemResponse.error;
              }
            }));    
          }
          if(files.length >0){
            await Promise.all(files.map(async v=>{
              if(v.quotation_file_id){
                let quotationFileResponse = await this.QuotationFileService.create(
                  {
                    quotation_info_id: quotationResponse.resultData.quotation_info_id,
                    file_name: v.file_name,
                    file_path: v.file_path
                  });
                if (!quotationFileResponse.success) {
                  throw quotationResponse.error;
                }
              }else{
                let uploadResponse = await this.UploadService.post(v, this.directoryName);
                let quotationFileResponse = await this.QuotationFileService.create(
                  {
                    quotation_info_id: quotationResponse.resultData.quotation_info_id,
                    file_name: uploadResponse.resultData.originalname,
                    file_path: uploadResponse.resultData.path
                  });
                if (!quotationFileResponse.success) {
                  throw quotationResponse.error;
                }
              }
            }));       
          }
          
          if(mitItems){
            await Promise.all(mitItems.quotationMitItems.map(async (v:any, index: number) =>{
              let quotationMitItemResponse = await this.QuotationMitItemService.create({
                ...v, 
                customer_id: quotation_info.customer_id,
                price: parseFloat(this.utils.convertStingToNumber(v.price)),
                discount: parseFloat(this.utils.convertStingToNumber(v.discount)),
                quotation_info_id: quotationResponse.resultData.quotation_info_id,
                quotation_info_doc_no: quotationResponse.resultData.doc_no,
                quotation_info_status: quotationResponse.resultData.doc_status_id,
                
                transport_receive_item_id: "null",
                transport_receive_info_id: "null",
                transport_receive_info_doc_no: "null",
                transport_receive_item_status: "null",
                transport_receive_info_status: "null",
                job_order_info_id: "null",
                job_order_info_status: "null",
                job_order_info_doc_no: "null",
                job_order_info_type: "null",
                job_order_calibration_item_status: "null",
                job_order_calibration_item_id: "null",
                acc_invoice_info_doc_no: "",
                acc_invoice_info_status: "",
                acc_tax_invoice_info_id: "",
                acc_tax_invoice_info_doc_no: "",
                acc_tax_invoice_info_status: "",
                acc_debit_note_info_doc_no: "",
                acc_debit_note_info_status: "",
                acc_credit_note_info_id: "",
                acc_credit_note_info_doc_no: "",
                acc_credit_note_info_status: "",
                return_messenger_id: "",
                return_transport_type_id: "",

                acc_invoice_info_id: "",
                acc_debit_note_info_id: "",

                return_date: undefined,
                ready_to_billing: undefined,
                lock_invoice_at: undefined,
                unlock_invoice_at: undefined,

                order_by: index+1
              });
              if (quotationMitItemResponse.success) {
                if(v.quotation_mit_item_details){
                  await Promise.all(v.quotation_mit_item_details.map(async (d: any, indexD: number) =>{
                      let quotationMitItemDetailResponse = await this.QuotationMitItemDetailService.create({
                        ...d,
                        quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
                        is_non_calculate: "false",
                        is_non_calibration: "false",
                        order_by: indexD
                      });
                      if (quotationMitItemDetailResponse.success) {
                        if(d.detail_type == 'point'){
                          d.quotation_mit_item_detail_points.sort((a, b) => {
                            return parseFloat(a.cus_point) - parseFloat(b.cus_point);
                          });

                          await Promise.all(d.quotation_mit_item_detail_points.map(async (p: any, indexP: number)=>{
                            delete p.quotation_mit_item_detail_id;
                            let quotationMitItemDetailPoint = await this.QuotationMitItemDetailPointService.create(
                            { quotation_mit_item_detail_id: quotationMitItemDetailResponse.resultData.quotation_mit_item_detail_id,
                              ...p,
                              order_by: p.order_by || indexP
                            });
                            if (quotationMitItemDetailPoint.success) {
                              if(indexP == 0){
                                if(v.quotation_mit_item_log_info?.length > 0){
                                  await this.saveLogArray(v.quotation_mit_item_log_info, quotationMitItemDetailResponse, quotationMitItemDetailResponse.resultData.quotation_mit_item_detail_id);
                                }
                              }
                            }else{
                              throw quotationMitItemDetailPoint.error;
                            }
                          }));
                        }else{
                          let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.create(
                            { quotation_mit_item_detail_id: quotationMitItemDetailResponse.resultData.quotation_mit_item_detail_id,
                              ...d.quotation_mit_item_detail_range,
                              order_by: 0
                            });
                            if (quotationMitItemDetailRange.success) {
                              if(v.quotation_mit_item_log_info?.length > 0){
                                await this.saveLogArray(v.quotation_mit_item_log_info, quotationMitItemDetailResponse, quotationMitItemDetailResponse.resultData.quotation_mit_item_detail_id);
                              }
                            }else{
                              throw quotationMitItemDetailRange.error;
                            }
                        }

                      }else{
                        throw quotationMitItemDetailResponse.error;
                      }
                  }));
                }
                if(v.quotation_mit_item_attributes){
                  await Promise.all(v.quotation_mit_item_attributes.map(async (a, indexA: number) =>{
                    delete a.quotation_mit_item_id;
                    let quotationMitItemAttributeResponse = await this.QuotationMitItemAttributeService.create({
                      quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
                      ...a,
                      order_by: indexA
                    });
                    if (!quotationMitItemAttributeResponse.success) {
                      throw quotationMitItemAttributeResponse.error;
                    }
                  }));
                }
                if(v.transport_receive_item_id){
                  let transportResponse = await this.TransportreceiveItemService.getById({transport_receive_item_id: v.transport_receive_item_id});
                  if(transportResponse.success){
                    await this.TransportreceiveItemService.updateNoQT(
                      {
                      ...transportResponse.resultData,
                      noqt_quotation_info_id: quotationResponse.resultData.quotation_info_id
                      }
                    );
                  }
                }
              }else{
                throw quotationMitItemResponse.error;
              }
            }));
          }

          if (noteItemIn.length > 0){
            this.saveNoteArrayCopy(noteItemIn, quotationResponse);
          }

          if (noteItemOut.length > 0){
            this.saveNoteArrayCopy(noteItemOut, quotationResponse);
          }
          return {
            status: true,
            message: quotationResponse.success
          }
      } else {
        if (quotationResponse.resultCode === "40900") {
          return {
            status: false,
            message: 'codeDuplicate',
            error: quotationResponse.error
          }
        } else {
          throw quotationResponse.error;
        }
      }
      } catch (error) {
        return {
          status: false,
          message: error
        }
      }
    }
  }
  async saveFile(files, quotationResponse){
    await Promise.all(files.map(async v=>{
      if(!v.quotation_file_id){
        let uploadResponse = await this.UploadService.post(v, this.directoryName);
        let quotationFileResponse = await this.QuotationFileService.create(
          {
            quotation_info_id: quotationResponse.resultData.quotation_info_id,
            file_name: uploadResponse.resultData.originalname,
            file_path: uploadResponse.resultData.path
          });
        if (!quotationFileResponse.success) {
          throw quotationResponse.error;
        }
      }
    }));
  }
  async saveCustomerContactList(contactList, quotationResponse){
    await Promise.all(contactList.map(async (v)=>{
      if(v.quotation_contact_id){

      }else{
        let quotationContactResponse = await this.QuotationContactService.create(
          {
            quotation_info_id: quotationResponse.resultData.quotation_info_id,
            ...v
          });
        if (!quotationContactResponse.success) {
          throw quotationContactResponse.error;
        }
      }
    }));
  }
  async saveProductList(productList, quotationResponse){
    await Promise.all(productList.map(async (v, index)=>{
      if(v.quotation_item_id){
        let quotationProductItemResponse = await this.QuotationProductItemService.update(
          {
            ...v,
            price: parseFloat(this.utils.convertStingToNumber(v.price)),
            discount: parseFloat(this.utils.convertStingToNumber(v.discount)),
            quotation_info_id: quotationResponse.resultData.quotation_info_id,
            quotation_info_doc_no: quotationResponse.resultData.doc_no,
            quotation_info_status: quotationResponse.resultData.doc_status_id,
            order_by:  index
          });
        if (!quotationProductItemResponse.success) {
          throw quotationProductItemResponse.error;
        }
      }else{
        let quotationProductItemResponse = await this.QuotationProductItemService.create(
          {
            ...v,
            price: parseFloat(this.utils.convertStingToNumber(v.price)),
            discount: parseFloat(this.utils.convertStingToNumber(v.discount)),
            quotation_info_id: quotationResponse.resultData.quotation_info_id,
            quotation_info_doc_no: quotationResponse.resultData.doc_no,
            quotation_info_status: quotationResponse.resultData.doc_status_id,
            order_by: index
          });
        if (!quotationProductItemResponse.success) {
          throw quotationProductItemResponse.error;
        }
      }
    }));
  }

  async saveDetailPointArray(detail, quotationMitItemDetailResponse){
    detail.quotation_mit_item_detail_points.sort((a, b) => {
      return parseFloat(a.cus_point) - parseFloat(b.cus_point);
    });
    let detailPoints = detail.quotation_mit_item_detail_points;

    detailPoints = await Promise.all(detailPoints.map(async (p: any, indexP: number)=>{
      return {
        quotation_mit_item_detail_id: quotationMitItemDetailResponse.quotation_mit_item_detail_id,
          ...p,
          order_by: indexP
      }
    }));

    if(detailPoints.filter(item=>item.quotation_mit_item_detail_point_id == null || item.quotation_mit_item_detail_point_id == "").length > 0){
      await this.QuotationMitItemDetailPointService.createArray(detailPoints.filter(item=>item.quotation_mit_item_detail_point_id == null || item.quotation_mit_item_detail_point_id == ""));
    }
    
    if(detailPoints.filter(item=>item.quotation_mit_item_detail_point_id != "" && item.quotation_mit_item_detail_point_id != null).length > 0){
      await this.QuotationMitItemDetailPointService.updateArray(detailPoints.filter(item=>item.quotation_mit_item_detail_point_id != "" && item.quotation_mit_item_detail_point_id != null));
    }

    await Promise.all(detail.quotation_mit_item_detail_points.map(async (p: any, indexP: number)=>{
      if(indexP == 0){
        if(detail?.mit_item_logs?.length > 0){
          await this.saveLogArray(detail.mit_item_logs, quotationMitItemDetailResponse, quotationMitItemDetailResponse.quotation_mit_item_detail_id);
        }
      }
    }));
  }
  
  async saveDetailRange(detail, quotationMitItemDetailResponse){

    if(detail.quotation_mit_item_detail_range?.quotation_mit_item_detail_range_id){
      let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.update(
        { quotation_mit_item_detail_id: quotationMitItemDetailResponse.quotation_mit_item_detail_id,
          ...detail.quotation_mit_item_detail_range,
          order_by: 0
        });
        if (quotationMitItemDetailRange.success) {
          if(detail?.mit_item_logs?.length >0){
            await this.saveLogArray(detail.mit_item_logs, quotationMitItemDetailResponse, quotationMitItemDetailResponse.quotation_mit_item_detail_id)
          }
        }else{
          throw quotationMitItemDetailRange.error;
        }
    }else{
      let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.create(
        { quotation_mit_item_detail_id: quotationMitItemDetailResponse.quotation_mit_item_detail_id,
          ...detail.quotation_mit_item_detail_range,
          order_by: 0
        });
        if (quotationMitItemDetailRange.success) {
          if(detail?.mit_item_logs?.length >0){
            await this.saveLogArray(detail.mit_item_logs, quotationMitItemDetailResponse, quotationMitItemDetailResponse.quotation_mit_item_detail_id)
          }
        }else{
          throw quotationMitItemDetailRange.error;
        }
    }
  }

  async saveAttribute(attributeList, quotationMitItemResponse){
    await Promise.all(attributeList.map(async (a, indexA: number) =>{
      if(a.quotation_mit_item_attribute_id){
        let quotationMitItemAttributeResponse = await this.QuotationMitItemAttributeService.update({
          quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
          ...a,
          order_by: indexA
        });
        if (!quotationMitItemAttributeResponse.success) {
          throw quotationMitItemAttributeResponse.error;
        }
      }else{
        let quotationMitItemAttributeResponse = await this.QuotationMitItemAttributeService.create({
          quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
          ...a,
          order_by: indexA
        });
        if (!quotationMitItemAttributeResponse.success) {
          throw quotationMitItemAttributeResponse.error;
        }
      }
    }));
  }

  async saveDetailArray(detailList, quotationMitItemResponse, data?){
    let details = detailList;

    details = await Promise.all(details.map(async (d: any, indexD: number) =>{
      return {
        ...d,
        is_non_calculate: data?.doc_status_id =='REVISE_WAITING_FOR_APPROVE'? 'false': d.is_non_calculate,
        is_non_calibration: data?.doc_status_id =='REVISE_WAITING_FOR_APPROVE'? 'false': d.is_non_calculate,
        quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
        order_by: d.order_by || indexD
      }
    }));

    if(details.filter(item=>item.quotation_mit_item_detail_id == null || item.quotation_mit_item_detail_id == "").length > 0){
      var createDetailArrayResponse = await this.QuotationMitItemDetailService.createArray(details.filter(item=>item.quotation_mit_item_detail_id == null || item.quotation_mit_item_detail_id == ""));
    }
    
    if(details.filter(item=>item.quotation_mit_item_detail_id != "" && item.quotation_mit_item_detail_id != null).length > 0){
      var updateDetailArrayResponse = await this.QuotationMitItemDetailService.updateArray(details.filter(item=>item.quotation_mit_item_detail_id != "" && item.quotation_mit_item_detail_id != null));
    }

    let mergeArray = [
      ...createDetailArrayResponse?.resultData || [],
      ...updateDetailArrayResponse?.resultData || []
    ]

    mergeArray.sort((a, b) => (a.order_by > b.order_by? 1 : -1));

    if (mergeArray.length > 0) {
      await Promise.all(detailList.map(async (d: any, indexD) =>{
        if(d.detail_type == 'point'){
          this.saveDetailPointArray(d, mergeArray[indexD]);
        }else{
          this.saveDetailRange(d, mergeArray[indexD]);
        }
      }));
    }
  }

  async saveNoteArrayCopy(noteItem, quotationResponse){
    let items = noteItem;

    items = await Promise.all(items.map(async (item: any, index: number) =>{
      return {
        ...item,
        quotation_info_id: quotationResponse.resultData.quotation_info_id,
        order: index.toString()
      }
    }));

    var createNoteArrayResponse = await this.QuotationNoteService.createArray(items);

    if (!createNoteArrayResponse.success) {
      throw createNoteArrayResponse.error;
    }
  }

  async saveNoteArray(noteItem, quotationResponse){

    let items = noteItem;

    items = await Promise.all(items.map(async (item: any, index: number) =>{
      return {
        ...item,
        quotation_info_id: quotationResponse.resultData.quotation_info_id,
        order: index.toString()
      }
    }));

    if(items.length > 0){
      if(items.filter(item=>item.quotation_note_id == null || item.quotation_note_id == "").length > 0){
        var createNoteArrayResponse = await this.QuotationNoteService.createArray(items.filter(item=>item.quotation_note_id == null || item.quotation_note_id == ""));
      }
  
      if (createNoteArrayResponse?.error) {
        throw createNoteArrayResponse?.error;
      }
      
      if(items.filter(item=>item.quotation_note_id != "" && item.quotation_note_id != null).length > 0){
        var updateNoteArrayResponse = await this.QuotationNoteService.updateArray(items.filter(item=>item.quotation_note_id != "" && item.quotation_note_id != null));
      }
  
      if (updateNoteArrayResponse?.error) {
        throw updateNoteArrayResponse?.error;
      }
    }
  }

  async saveLogArray(logList?, quotationMitItemResponse?, quotation_mit_item_detail_id?){
    logList =  await Promise.all(logList.map(async (log) =>{
      return {
        ...log,        
        deleted_at: undefined,
        updated_at: undefined,
        quotation_mit_item_log_id: undefined,
        
        quotation_mit_item_detail_id: quotation_mit_item_detail_id,

        quotation_mit_item_id: quotationMitItemResponse?.resultData?.quotation_mit_item_id? 
        quotationMitItemResponse?.resultData?.quotation_mit_item_id:
        quotationMitItemResponse?.quotation_mit_item_id,
      }
    }));
    let quotationMitItemLog = await this.QuotationMitItemLog.createArray(logList);
      if (!quotationMitItemLog.success) {
        throw quotationMitItemLog.error;
      }
  }

  async deleteAllMitItemList(deleteMitItemList){
    await Promise.all(deleteMitItemList.map(async (v) =>{
      if(v.quotation_mit_item_attributes){
        await Promise.all(v.quotation_mit_item_attributes.map(async (itemAttribute)=>{
          let quotationMitItemAttributeResponse = await this.QuotationMitItemAttributeService.delete({
            quotation_mit_item_attribute_id: itemAttribute.quotation_mit_item_attribute_id,
          });
          if(!quotationMitItemAttributeResponse.success){
            throw quotationMitItemAttributeResponse.error;
          }
        }));
      }
      if(v.quotation_mit_item_details){
        await Promise.all(v.quotation_mit_item_details.map(async (d)=>{
          if(d.detail_type == 'point'){
            await Promise.all(d.quotation_mit_item_detail_points.map(async (p)=>{
              let quotationMitItemDetailPoint = await this.QuotationMitItemDetailPointService.delete({
                quotation_mit_item_detail_point_id: p.quotation_mit_item_detail_point_id,
              });
              if(!quotationMitItemDetailPoint.success){
                throw quotationMitItemDetailPoint.error;
              }
            }));
          }else{
            let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.delete({
              quotation_mit_item_detail_range_id: d.quotation_mit_item_detail_range.quotation_mit_item_detail_range_id,
            });
            if(!quotationMitItemDetailRange.success){
              throw quotationMitItemDetailRange.error;
            }
          }
          let quotationMitItemDetailResponse = await this.QuotationMitItemDetailService.delete({
            quotation_mit_item_detail_id: d.quotation_mit_item_detail_id,
          });
          if(!quotationMitItemDetailResponse.success){
            throw quotationMitItemDetailResponse.error;
          }
        }));
      }

      if(v.transport_receive_item_id){
        let transportResponse = await this.TransportreceiveItemService.getById({transport_receive_item_id: v.transport_receive_item_id});
        if(transportResponse.success){
          await this.TransportreceiveItemService.updateNoQT(
            {
              ...transportResponse.resultData,
              item_status_id : 'WAITING_FOR_CONFIRM',
              quotation_mit_item_id : "",
              noqt_quotation_info_id : ""
            }
          );
        }
      }

      let quotationMitItemResponse = await this.QuotationMitItemService.delete({
        quotation_mit_item_id: v.quotation_mit_item_id,
      });
      if(!quotationMitItemResponse.success){
        throw quotationMitItemResponse.error;
      }
    }));
  }
}
