<div
  modal
  class="modal fade text-left"
  id="mit-item-log"
  tabindex="-1"
  role="dialog"
  data-backdrop="static" 
  aria-labelledby="mitItemLogModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="mitItemLogModal">
         ดูประวัติ
        </h4>
        <button
          type="button"
          class="close"
          (click)="close()"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive" *ngIf="!isLoading">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th class="text-nowrap">วัน - เวลา</th>
                        <th>รายละเอียด</th>
                        <th>ผู้ดำเนินการ</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let log of logs; let i = index">
                        <td class="text-nowrap">{{log.created_at | thai_date: "short" | dash }}</td>
                        <td>{{log.description | dash}}</td>
                        <td>{{log.created_by_info.firstname_th}} {{log.created_by_info.lastname_th}}</td>

                    </tr>
                    <tr *ngIf="logs.length === 0">
                        <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <app-paginator
          [filter]="filter"
          [reset]="reset"
          [fetch]="load"
          (changePage)="onChangePage($event)">
        </app-paginator>

      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="close()"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ปิด
        </button>
      </div>
    </div>
  </div>
</div>
