import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  NgZone,
  Input,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AbsNumberValidator } from "src/app/validators";
import { GETService, CustomerAddressService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";

@Component({
  selector: "app-customer-address-create",
  templateUrl: "./customer-address-create.component.html",
  styleUrls: ["./customer-address-create.component.css"],
})
export class CustomerAddressCreateComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  status: string;
  customerAddressTypes = [];
  districts = [];
  amphures = [];
  province$: Subscription;
  amphure$: Subscription;
  district$: Subscription;
  customerAddress = null;
  is_acc_dep: boolean = false;

  @Input("provinces") provinces = [];
  @Output() done = new EventEmitter();
  @ViewChild("closeSidebar") closeSidebar: ElementRef;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(
    private zone: NgZone,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private GETService: GETService,
    private CustomerAddressService: CustomerAddressService
  ) {
    this.setForm();
  }
  async setForm() {
    this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;

      if(userInfo.emp_info.emp_department_id == 'ACC_DEP'){
        this.is_acc_dep = true;
      }
      this.provinces = this.provinces.map(item=>{
        return {
          ...item,
          id: item.province_id,
          text: item.province_name_th,
        }
      })
  
      this.form = this.formBuilder.group({
        address: ["", [Validators.required]],
        province_id: [""],
        district_id: [""],
        amphure_id: [""],
        geography_id: [""],
        tel_1: ["", [Validators.required]],
        tel_2: [""],
        fax_1: [""],
        fax_2: [""],
        memo: [""],
  
        customer_address_type_id: ["", [Validators.required]],
        address_name: ["", [Validators.required]],
        branch: [""],
        department: ["", [Validators.required]],
        department_code: ["", !this.is_acc_dep? [Validators.required, AbsNumberValidator(), Validators.minLength(2), Validators.maxLength(2)]: null],
        tax_id: [
          "",
          [
            AbsNumberValidator(),
            Validators.minLength(13),
            Validators.maxLength(13),
          ],
        ],
      });
  
      this.CustomerAddressService.load(null, {is_registed_address: "true",customer_id: this.route.snapshot.paramMap.get("customer_id")})
      .then(async (res) => {
        this.customerAddress = res.resultData[0] || {};
  
        if(this.customerAddress.province_id){
          let result = await this.GETService.amphures({
            province_id: this.customerAddress.province_id,
          });
          this.amphures = result.resultData.map(item=>{
            return {
              ...item,
              id: item.amphure_id,
              text: item.amphure_name_th,
            }
          })
          if(this.customerAddress.amphure_id){
            result = await this.GETService.districts({
              amphure_id: this.customerAddress.amphure_id,
            });
    
            this.districts = result.resultData.map(item=>{
              return {
                ...item,
                id: item.district_id,
                text: item.district_name_th,
              }
            })
            if(this.customerAddress.district_id){
              var district = this.districts.filter(
                (v) => String(v.district_id) === String(this.customerAddress.district_id)
              )[0];
            }
          }
        }
  
        this.form.controls.geography_id.setValue(district? district.zip_code: "");
        this.form.patchValue({...this.customerAddress, customer_address_type_id: ""}, {
          onlySelf: true,
          emitEvent: false,
        });
      });
  
      this.province$ = this.form.controls.province_id.valueChanges.subscribe(
        (province_id) => {
          this.districts = [];
          this.amphures = [];
          if(province_id){
            this.form.patchValue(
              {
                amphure_id: "",
                district_id: "",
                geography_id: "",
              },
              {
                onlySelf: true,
                emitEvent: false,
              }
            );
            this.GETService.amphures({
              province_id: province_id,
            }).then((res) => {
              let result = res.resultData || [];
              result = result.map(item=>{
                return {
                  ...item,
                  id: item.amphure_id,
                  text: item.amphure_name_th,
                }
              })
              this.amphures = result;
            });
          }else{
            this.form.patchValue(
              {
                province_id: "",
                district_id: "",
                amphure_id: ""
              },
              {
                onlySelf: true,
                emitEvent: false,
              }
            );
          }
        }
      );
  
      this.amphure$ = this.form.controls.amphure_id.valueChanges.subscribe(
        (amphure_id) => {
          this.districts = [];
          if (amphure_id) {
            this.form.patchValue(
              {
                district_id: "",
                geography_id: "",
              },
              {
                onlySelf: true,
                emitEvent: false,
              }
            );
            this.GETService.districts({ amphure_id: amphure_id }).then(
              (res) => {
                let result = res.resultData || [];
                result = result.map(item=>{
                  return {
                    ...item,
                    id: item.district_id,
                    text: item.district_name_th,
                  }
                })
                this.districts = result;
              }
            );
          }else{
            this.form.controls.district_id.setValue("");
          }
        }
      );
  
      this.district$ = this.form.controls.district_id.valueChanges.subscribe(
        (district_id) => {
          let district = this.districts.filter(
            (v) => v.district_id == district_id
          )[0];
          if (district) {
            this.form.controls.geography_id.setValue(district.zip_code);
          } else {
            this.form.controls.geography_id.setValue("");
          }
        }
      );
    });
  }

  async ngOnInit() {
    let initApis = [];
    initApis.push(
      this.GETService.customerAddressType({ show_in_list: 1 }).then(
        (result) => {
          this.customerAddressTypes = result.resultData;
        }
      )
    );
    await Promise.all(initApis);
  }

  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let address_name = {
      district_name: "",
      amphure_name: "",
      province_name: "",
    }
    if (this.form.value.amphure_id){
      address_name.amphure_name = this.amphures.find(am=> am.id == this.form.value.amphure_id).text;
    }
    if (this.form.value.district_id){
      address_name.district_name = this.districts.find(ds=> ds.id == this.form.value.district_id).text;
    }
    if (this.form.value.province_id){
      address_name.province_name = this.provinces.find(pr=> pr.id == this.form.value.province_id).text;
    }
    let response = await this.CustomerAddressService.create({
      customer_id: this.route.snapshot.paramMap.get("customer_id"),
      ...this.form.value,
      is_registed_address: "false",
      ...address_name
    });

    if(response.error && response.error.resultCode == "40900"){
      response.error.resultDesc.th = "MIT iPLUS Code นี้มีอยู่ในระบบ โปรดระบุ MIT iPLUS Code ใหม่";
      this.status = response.error;
    }else{
      this.done.emit(response.success || response.error);
      this.closeSidebar.nativeElement.click();
    }
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
        this.province$?.unsubscribe();
        this.amphure$?.unsubscribe();
        this.district$?.unsubscribe();
      } else {
        this.setForm();
      }
    });
  }
}
