import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { DownloadFileService } from 'src/app/services/quotations';
import { TransportReceiveItemFileService } from 'src/app/services/transport-receives';

@Component({
  selector: 'app-return-item-edit',
  templateUrl: './return-item-edit.component.html',
  styleUrls: ['./return-item-edit.component.css']
})
export class ReturnItemEditComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  isReview: boolean = false;

  @Output() done = new EventEmitter<any>();
  @Input('transportreceive') transportreceive: any;
  @Input('itemStatus') itemStatus: any;
  @Input('receive') receive: any;

  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  fileItem = null;
  //array
  statusList = [];
  files: any = [];

  constructor(
    private zone: NgZone,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private DownloadFileService: DownloadFileService,
    private TransportReceiveItemFileService: TransportReceiveItemFileService,
    private router: Router
  ) { }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    this.setForm();
    this.statusList = changes.itemStatus?.currentValue;
    this.files = changes.receive?.currentValue.files || [];

    if(changes.receive?.currentValue.quotation_mit_item_info){
      let data = {
        ...changes.receive?.currentValue,
        item_status_id: 'RETURNED'
      }
      this.form.patchValue(data);

    }else{
      let data = {
        ...changes.receive?.currentValue,
        item_status_id: 'RETURNED'
      }

      this.form.patchValue(data);
    }
  }

  setForm(){
    this.form = this.formBuilder.group({
      item_status_id: ["", [Validators.required]],
      note: ["", [Validators.required]]
    });
  }
  
  checkEquipment(): boolean{
    let result = false;
    Object.keys(this.form.value).map(key=>{
      if(key != 'note' && key != 'noqt_description'){
        if(this.form.value[key] == '' || this.form.value[key] == null) result = true;
      }
    })
    return result;
  }

  checkMain(): boolean{
    let result = false;
    Object.keys(this.form.value).map(key=>{
      if(key == 'item_status_id ' || key == 'note'){
        if(this.form.value[key] == '' || this.form.value[key] == null) result = true;
      }
    })
    return result || this.files.length ==0;
  }

  uploadFile(){
    document.getElementById("importFile").click();
  }
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  downloadFile(file){
    if(file.transport_receive_item_id){
      this.DownloadFileService.downloadFileURL(file);
    }else{
      this.DownloadFileService.downloadFile(file);
    }
  }


  removeFile(file){
    this.fileItem = file;
  }

  async deleteFile(closeModalEl){
    let fileItem = this.fileItem;
    if(fileItem.transport_receive_item_file_id){
      await this.TransportReceiveItemFileService.delete(fileItem);

      this.files.map((item, index)=>{
        if(fileItem == item){
          this.files.splice(index, 1);
        }
      })
    }else{
      this.files.map((item, index)=>{
        if(fileItem == item){
          this.files.splice(index, 1);
        }
      })
    }
    closeModalEl.click();
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.submitted = false;
    let data = {
      ...this.form.getRawValue(),
      files: this.files
    }
    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.done.emit(null);
      }
    });
  }
}
