import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { UploadService, UserService } from 'src/app/services';
import { AuthService } from 'src/app/services/auth.service';
import { GetUserInfo } from 'src/app/store/user/user.action';
import { UserInfoState } from 'src/app/store/user/user.state';
import { 
  ENValidator,
  THValidator 
} from 'src/app/validators';
import { 
  environment 
} from '../../../../../environments/environment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  form: FormGroup;
  status = null;
  submitted = false;
  imageShow = null;
  selectFile = null;

  @Select(UserInfoState.getUser) 
  userInfo$: Observable<any>;
  userSub: Subscription;
  constructor(
    private formBuilder: FormBuilder,
    private UploadService: UploadService,
    private UserService: UserService,
    private AuthService: AuthService,
    private store: Store,
  ) {
  }
  ngOnInit() {
    this.createForm();
    this.userSub = this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      this.form.patchValue(
        {...userInfo}
      );
      if(this.form.value.signature_file){
        this.imageShow = environment.uploadPath + this.form.value.signature_file;
      }
    });
  }
  createForm(){
    this.form = this.formBuilder.group(
      {
        user_id: [""],
        email: [""],
        firstname_th: ["", [Validators.required, THValidator()]],
        lastname_th: ["", [Validators.required, THValidator()]],
        firstname_en: ["", [Validators.required, ENValidator()]],
        lastname_en: ["", [Validators.required, ENValidator()]],

        mobile_no: ["", [Validators.required]],
        signature_file: [""],
      }
    );  
  }
  async submit() {
    this.submitted = true;
    this.status = null;
    if (this.form.invalid || this.imageShow == null) {
      return;
    }
    if(this.selectFile){
      var uploadResponse = await this.UploadService.post(this.selectFile, 'users');
      if(uploadResponse.resultData){
        this.form.get('signature_file').setValue(uploadResponse.resultData.path);
      }
    }
    const userResponse = await this.UserService.update({
        ...this.form.value
    })
    if(userResponse.success){
      this.status = userResponse.success;
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
      if (userInfo.user_id === userResponse.resultData.user_id) {
        this.store.dispatch(new GetUserInfo());
      }
    }else{
      this.status = userResponse.error;
    }
    this.submitted = false;
  }
  uploadImage(){
    document.getElementById("signature_file").click();
  }
  processImage(file){
    this.selectFile = <File>file.files[0]
    var mimeType = this.selectFile.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();      
    reader.readAsDataURL(this.selectFile); 
    reader.onload = (_event) => { 
      this.imageShow = reader.result; 
    }
  }
}
