<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการใบแจ้งหนี้/ใบส่งของ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active">
                  <a [routerLink]="['/invoice']">เอกสารทางบัญชี</a>
                </li>
                <li class="breadcrumb-item">
                  ใบแจ้งหนี้/ใบส่งของ
                </li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button class="btn btn-outline-primary"                     
                data-target="#invoice-create-filter"
                data-toggle="modal">
              <i class="feather icon-plus-circle icon-left"></i> สร้างใบแจ้งหนี้/ใบส่งของ
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mt-1">รายการใบแจ้งหนี้/ใบส่งของ</h4>
                <div class="heading-elements">
                  <search-box (search)="search($event)" [search_all]="params?.search_all"></search-box>
                </div>
              </div>
  
              <div class="card-content collapse show">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-12 align-self-end">
                          <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0"  [class.active] = "!params?.doc_status_id && !params?.due_date" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchStatus()">ทั้งหมด</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='DRAFT'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchStatus('DRAFT')">ร่าง ({{number_draft_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WAITING_FOR_APPROVE'" id="pill3" data-toggle="pill" href="#pill3" aria-expanded="false" (click)="searchStatus('WAITING_FOR_APPROVE')">รออนุมัติ ({{number_waiting_for_approve_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='REJECTED'" id="pill6" data-toggle="pill" href="#pillุ6" aria-expanded="false" (click)="searchStatus('REJECTED')">ไม่อนุมัติ ({{number_rejected_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WAITING_FOR_PAYMENT'" id="pill4" data-toggle="pill" href="#pill4" aria-expanded="false" (click)="searchStatus('WAITING_FOR_PAYMENT')">รอชำระ ({{number_waiting_for_payment}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.due_date=='less_than'" id="pill4" data-toggle="pill" href="#pill9" aria-expanded="false" (click)="searchStatus('less_than')">เกินกำหนดชำระน้อยกว่า 30 วัน ({{number_lessthan_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                                <a class="nav-link py-0" [class.active] = "params?.due_date=='greater_than'" id="pill4" data-toggle="pill" href="#pill10" aria-expanded="false" (click)="searchStatus('greater_than')">เกินกำหนดชำระมากกว่า 30 วัน ({{number_greaterthan_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='PARTIAL_PAYMENT'" id="pill5" data-toggle="pill" href="#pill5" aria-expanded="false" (click)="searchStatus('PARTIAL_PAYMENT')">ชำระบางส่วน ({{number_partial_payment_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='COMPLETED'" id="pill11" data-toggle="pill" href="#pill11" aria-expanded="false" (click)="searchStatus('COMPLETED')">ชำระแล้ว ({{number_complete_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='TOTAL_AMOUNT_CREDITED'" id="pill55" data-toggle="pill" href="#pill55" aria-expanded="false" (click)="searchStatus('TOTAL_AMOUNT_CREDITED')">ออกใบลดหนี้ทั้งจำนวน ({{number_total_amount_status}})</a>
                            </li>
                            <li class="nav-item border-light">
                              <a class="nav-link text-light py-0" [class.active] = "params?.doc_status_id=='CANCELED'" id="pill22" data-toggle="pill" href="#pill22" aria-expanded="false" (click)="searchStatus('CANCELED')">ยกเลิก ({{number_canceled_status}})</a>
                            </li>
                          </ul>
                        </div>
                    </div>
                    <loading *ngIf="isLoading"></loading>
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                <th class="text-center">เลขที่เอกสาร</th>
                                <th>ชื่อลูกค้า</th>
                                <th>วันที่ออก</th>
                                <th>วันนัดวางบิล</th>
                                <th>วันนัดชำระ</th>
                                <th class="text-right">มูลค่า (บาท)</th>
                                <th class="text-center">สถานะ</th>
                                <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let invoice of invoices; let i = index">
                                <td>{{invoice.doc_no}}</td>
                                <td>
                                  <div>
                                    [{{invoice?.customer_info?.customer_code}}] {{invoice?.customer_info?.company_name}}
                                  </div>
                                  <small class="text-muted">
                                    พนักงานขาย: {{invoice.sales_assignment_info?.user_info?.firstname_th}} {{invoice.sales_assignment_info?.user_info?.lastname_th || '-'}}
                                  </small>
                                </td>
                                <td>{{invoice.doc_date | thai_date: "short":"date" | dash }}</td>
                                <td>{{invoice.doc_validdate | thai_date: "short":"date" | dash }}</td>
                                <td>{{invoice.doc_duedate | thai_date: "short":"date" | dash }}</td>
                                <td class="text-right">{{invoice.grand_total | number : '1.2-2'}}</td>
                                <td class="text-center">{{invoice.doc_status_info?.document_status_name_th}}</td>
                                <td>
                                  <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm"
                                  [routerLink]="[
                                    '/invoice/' + invoice.acc_invoice_info_id + '/show'
                                  ]">
                                    <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                  </a>
                                  <a 
                                    type="button" class="btn mr-1 mb-1 btn-blue btn-sm"
                                    target="_blank"
                                    [routerLink]="[
                                    '/acc-document/invoice/' + invoice.acc_invoice_info_id
                                    ]">
                                    <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                                  </a>
                                  <!-- <button 
                                    *ngIf="(invoice.doc_status_id === 'DRAFT' || invoice.doc_status_id === 'REJECTED')"
                                    type="button" class="btn mr-1 mb-1 btn-warning btn-sm"
                                    [routerLink]="[
                                      '/invoice/' + invoice.acc_invoice_info_id + '/edit'
                                    ]">
                                    <i class="fa fa-pencil icon-left"></i> แก้ไข
                                  </button> -->
                                </td>
                              </tr>
                                <tr *ngIf="invoices.length == 0">
                                    <td colspan="12" class="text-center">
                                        ไม่มีข้อมูล
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
  
                    <app-paginator
                      *ngIf="userInfo$ | async"
                      [filter]="filter"
                      [reset]="reset"
                      [fetch]="load"
                      [initialPage]="initialPage"
                      (filterLimit)="getFilterLimit($event)"
                      (changePage)="onChangePage($event)">
                    </app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>

<app-invoice-create-filter>
</app-invoice-create-filter>