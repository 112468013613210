import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { generateMitItemLogService, GETService } from 'src/app/services';
import { QuotationMitItemService } from 'src/app/services/quotations';
import { TransportreceiveInfoService, TransportreceiveItemService } from 'src/app/services/transport-receives';
import { UserInfoState } from 'src/app/store/user/user.state';
declare const $: any;

@Component({
  selector: 'app-transport-receive-item',
  templateUrl: './transport-receive-item.component.html',
  styleUrls: ['./transport-receive-item.component.css']
})
export class TransportreceiveItemComponent implements OnInit {
  pageLoad: boolean = true;
  isReview: boolean = false;
  isReturn: boolean = false;
  isReceive: boolean = false;
  isCreateTranportItem: boolean = false;

  pageType: string;
  receive_type: string;
  transport_receive_info_id: string;

  receiveItems = [];
  groupReceiveItems = [];
  delelteReceiveItems: any = [];

  receive: any;
  itemStatus: any;
  olditemStatus: any;

  @Output() done = new EventEmitter<any>();
  @Input() customer_info: any;
  @Input() transportreceive: any;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private GETService: GETService,
    private QuotationMitItemService: QuotationMitItemService,
    private TransportreceiveInfoService: TransportreceiveInfoService,
    private TransportreceiveItemService: TransportreceiveItemService,
    private generateMitItemLogService: generateMitItemLogService
  ) { 
    this.transport_receive_info_id = 
    this.route.snapshot.paramMap.get("transport_receive_info_id");

    this.route.queryParams.subscribe(params => {
      this.isReview = params['type'] == 'review'? true: false;
      this.isReturn = params['type'] == 'return'? true: false;
      this.isReceive = params['type'] == 'receive'? true: false;

      this.receive_type = params['receive_type'];
    });

    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  async ngOnInit() {
    this.pageLoad = false;
    this.userInfo$.subscribe(user=>{
      if(user) this.userInfo = user;
    })
    if(this.transportreceive){
      this.receive_type = this.transportreceive.receive_type;
    }

    await this.GETService.itemStatus({order_by: "order:asc"})
    .then(res=>{
      if(this.isReturn){
        this.itemStatus = res.resultData.filter(status => status.item_status_id =='RETURNED' || status.item_status_id =='RECEIVED');
        this.olditemStatus = res.resultData;
      }else{
        this.itemStatus = res.resultData.filter(status => status.section =='TRANSPORT_RECEIVE' || status.section == '');
        this.olditemStatus = res.resultData;
      }
      if(this.transportreceive?.doc_status_id == 'WAITING_FOR_RECEIVE' && this.pageType != 'show'){
        this.itemStatus = this.itemStatus.filter(status=> status.item_status_id !='WAITING_FOR_CONFIRM' && status.item_status_id !='RECEIVED');
        
      }else if(this.transportreceive?.doc_status_id == 'RECEIVE_WAITING_FOR_REVIEW' && this.pageType != 'show'){
        if(this.receive_type == 'WITTHOUT_QT'){
          this.itemStatus = this.itemStatus.filter(status=> status.item_status_id =='WAITING_FOR_REVIEW' || status.item_status_id =='WAITING_FOR_CONFIRM' || status.item_status_id =='CANCELED');
        }else{
          this.itemStatus = this.itemStatus.filter(status=> status.item_status_id =='WAITING_FOR_REVIEW' || status.item_status_id =='RECEIVED' || status.item_status_id =='CANCELED');
        }
      }
    });

    this.getItem();
  }

  async getItem(){
    if(this.transport_receive_info_id){
      await this.TransportreceiveItemService.load(null, {transport_receive_id: this.transport_receive_info_id, order_by: "order:asc"})
      .then(async res=>{
        this.receiveItems = res.resultData || [];

        await this.sentData()
      })
    }
  }

  async groupReceiveItem(){
    let receiveGroups = [];
    if(this.receive_type == 'WITH_QT'){
      this.receiveItems.sort(function(a, b){
        if (a.quotation_mit_item_id === b.quotation_mit_item_id) {
          return 0;
        }
        else if (a.quotation_mit_item_id === null) {
            return -1;
        }
        else if (b.quotation_mit_item_id === null) {
            return 1;
        }
      })
  
      this.receiveItems.sort(function ( a, b ) {
        if ( a?.quotation_info?.doc_no < b?.quotation_info?.doc_no ){
          return -1;
        }
        if ( a?.quotation_info?.doc_no > b?.quotation_info?.doc_no ){
          return 1;
        }
        return 0;
      })   
    }

    this.receiveItems.map(item=>{
      if(item.quotation_info_id == "") item.quotation_info_id = null;
      if(item.noqt_quotation_info_id == "") item.noqt_quotation_info_id = null;
      
      if(this.receive_type == 'WITTHOUT_QT'){
        if(item.quotation_info_id){
          item.noqt_quotation_info_id = item.quotation_info_id;
          item.noqt_quotation_info = item.quotation_info;
        }
      }
    })
    if(this.receive_type == 'WITH_QT'){
      var grouped = this.receiveItems.reduce(function (r, a) {
        r[a.quotation_info_id] = r[a.quotation_info_id] || [];
        r[a.quotation_info_id].push(a);
        return r;
      }, Object.create(null));
    }else{
      var grouped = this.receiveItems.reduce(function (r, a) {
        r[a.noqt_quotation_info_id] = r[a.noqt_quotation_info_id] || [];
        r[a.noqt_quotation_info_id].push(a);
        return r;
      }, Object.create(null));
    }
    
    var groups = Object.keys(grouped).map((key) => grouped[key]);

    await Promise.all(groups.map(async values=>{
      await Promise.all(
        values.map(async (value: any, index: number)=>{
        if(index == 0){
          receiveGroups.push(
            { ...value, isShow: true }
          );
        }else{
          receiveGroups.push(
            { ...value, isShow: false }
          );
        }
      }));
    }));

    return receiveGroups;
  }

  select(receive){
    this.receive = receive;
  }

  selectAll(){

  }

  async delete(closeModalEl){
    const index = this.receiveItems.indexOf(this.receive);
    this.delelteReceiveItems.push(this.receive);
    if (index > -1) {
      this.receiveItems.splice(index, 1);
    }
    await this.sentData();
    closeModalEl.click();
  }

  createTransportItem(){
    this.isCreateTranportItem = true;
    setTimeout(() => {
      $('#transport-receive-item-create').modal('show');
    }, 100);
  }

  async submitReceiveItem(event){
    if(event.length > 0){
      event.map(item=>{
        let checkDuplicate = this.receiveItems.find(value=> value.quotation_mit_item_id == item.quotation_mit_item_id);
        if(!checkDuplicate){
          this.receiveItems.push(item);
        }
      })
    }
    await this.sentData();
    this.isCreateTranportItem = false;
  }

  async submitReceiveItemNoQT(event){
    this.receiveItems.push(event);
    await this.sentData();
  }

  async editReceiveItem(event){

    if(event){

      if(this.receive.quotation_info){
        this.receiveItems.find(item => item === this.receive).quotation_mit_item_info.serial_no = event.noqt_serial_no;
        this.receiveItems.find(item => item === this.receive).quotation_mit_item_info.tag_no = event.noqt_tag_no;
        this.receiveItems.find(item => item === this.receive).quotation_mit_item_info.marker = event.noqt_marker;
        this.receiveItems.find(item => item === this.receive).quotation_mit_item_info.model = event.noqt_model;
      }

      this.receiveItems.find(item => item === this.receive).item_status_id = event.item_status_id;
      this.receiveItems.find(item => item ===  this.receive).note = event.note;
      this.receiveItems.find(item => item ===  this.receive).noqt_serial_no = event.noqt_serial_no;
      this.receiveItems.find(item => item ===  this.receive).noqt_tag_no = event.noqt_tag_no;
      this.receiveItems.find(item => item ===  this.receive).noqt_marker = event.noqt_marker;
      this.receiveItems.find(item => item ===  this.receive).noqt_model = event.noqt_model;
      this.receiveItems.find(item => item ===  this.receive).files = event.files;

      this.receiveItems.find(item => item ===  this.receive).mit_item_logs = [];
      let transportItem = await this.generateMitItemLogService.createTransportMitItemLog(this.receiveItems.find(item => item ===  this.receive), this.receiveItems.find(item => item ===  this.receive)?.quotation_mit_item_info?.quotation_mit_item_log_info);
      this.receiveItems.find(item => item ===  this.receive).mit_item_logs = [
        ...transportItem.mit_item_logs
      ];
    }
    await this.sentData();
    this.receive = null;
  }

  async editReceiveItemAll(event){

    if(event){

      let promises = [];

      promises.push(
        this.receiveItems.map(async receive=>{
  
          receive.item_status_id = event.item_status_id;
          receive.note = receive.note? receive.note: "";
          receive.noqt_serial_no = receive.noqt_serial_no? receive.noqt_serial_no: "";
          receive.noqt_tag_no = receive.noqt_tag_no? receive.noqt_tag_no: "";
          receive.noqt_marker = receive.noqt_marker? receive.noqt_marker: "";
          receive.noqt_model = receive.noqt_model? receive.noqt_model: "";
    
          receive.mit_item_logs = [];
          let transportItem = await 
          this.generateMitItemLogService.createTransportMitItemLog(receive, receive?.quotation_mit_item_info?.quotation_mit_item_log_info);
          receive.mit_item_logs = [
            ...transportItem.mit_item_logs
          ];
        })
      );

      promises.push(this.sentData());
      await Promise.all(promises);
    }
  }

  async editReturnItem(event){
    if(event){
      this.receiveItems.find(item => item === this.receive).item_status_id = event.item_status_id;
      this.receiveItems.find(item => item ===  this.receive).note = event.note;
      if(event.item_status_id == 'RETURNED'){
        this.receiveItems.find(item => item ===  this.receive)['returned_at'] = new Date();
        this.receiveItems.find(item => item ===  this.receive)['returned_by'] = this.userInfo.user_id;
      }
    }
    await this.sentData();
    this.receive = null;
  }

  async addMitItem(event){
    if(event){
      let receiveItemResponse = await this.TransportreceiveItemService.updateNoQT({
        ...this.receive,
        item_status_id : 'RECEIVED',
        quotation_mit_item_id : event.quotation_mit_item_id,
        quotation_info_id : event.quotation_info_id,
        noqt_quotation_info_id: event.quotation_info_id,
      });
      if(receiveItemResponse.success){
        let mitResponse = await this.QuotationMitItemService.update({
          ...event.quotation_mit_item_info,

          transport_receive_item_id: this.receive.transport_receive_item_id,
          transport_receive_info_id: this.receive.transport_receive_info.transport_receive_info_id,
          transport_receive_info_status: this.receive.transport_receive_info.doc_status_id,
          transport_receive_info_doc_no: this.receive.transport_receive_info.doc_no,
          transport_receive_item_status:  receiveItemResponse.resultData.item_status_id,

          marker: this.receive.noqt_marker,
          description: this.receive.noqt_description,
          model: this.receive.noqt_model,
          serial_no: this.receive.noqt_serial_no,
          tag_no: this.receive.noqt_tag_no,
        });

        if(mitResponse.success){
          let transportListResponse = await this.TransportreceiveItemService.load(null, {transport_receive_id: receiveItemResponse.resultData.transport_receive_id});
          if(transportListResponse.success){
            let transportList = transportListResponse.resultData || [];
            if(!transportList.find(item=> item.item_status_id != 'RECEIVED' && item.item_status_id != 'CANCELED')){
              let transportInfoResponse = await this.TransportreceiveInfoService.getById({transport_receive_info_id: receiveItemResponse.resultData.transport_receive_id});

              if(transportInfoResponse.success){
                var response = await this.TransportreceiveInfoService.update({
                  ...transportInfoResponse.resultData,
                  receive_type: 'WITH_QT',
                  doc_status_id:'RECEIVED_SUCCESS'
                });
                if(response.success){
                  await Promise.all(transportListResponse.resultData.map(async receive=>{
                    let updateResponse = await this.QuotationMitItemService.update({
                      ...receive.quotation_mit_item_info,
          
                      transport_receive_info_status: 'RECEIVED_SUCCESS',
                      transport_receive_item_status:  receive.item_status_id,
                    });
                  }));
                  window.location.reload();
                }
              }
            }
          }
          this.getItem();
          this.receive = null;
        }
      }
    }
  }

  async sentData(){
    this.receiveItems = await Promise.all(this.receiveItems.map(async res=>{
      if(!res.files){
        return {
          ...res,
          files: res?.transport_receive_item_file_info || []
        }
      }else{
        return {
          ...res,
          files:res.files || []
        }
      }
      
    }));
  
    this.receiveItems = await this.groupReceiveItem();

    this.done.emit({
      receiveItems: this.receiveItems,
      delelteReceiveItems: this.delelteReceiveItems
    });
  }

  resetMitItem(event){
    if(event){
      this.receive = null;
    }
  }

  async downloadZip(item){
    let download = [];
    item.files.map(file =>{
      if(file.file_path) download.push(file.file_path);
    });
    await this.GETService.zip(download).then(async (res) => {
      let url = window.URL.createObjectURL(res.data);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = 'zip';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }

  getStatus(item_status_id: string){
    let status_name = this.olditemStatus.find(item=> item.item_status_id == item_status_id)?.item_status_name_th 
    return status_name? status_name: '';
  }

  public get canSubmitAll(){
    if(this.isReceive){
      let checker = this.receiveItems.every(v => v.item_status_id == "WAITING_FOR_RECEIVE");
      return checker? true: false;
    }else if(this.isReview){
      let checker = this.receiveItems.every(v => v.item_status_id == "WAITING_FOR_REVIEW");
      return checker? true: false;
    }
  }
}
