import { FormBuilder, Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  Input,
  NgZone,
} from "@angular/core";
import {
  UnitService,
  UnitConversionService,
  UnitLabelService,
  ResponseStatusService,
} from "src/app/services";
import { ENSignValidator } from "src/app/validators";
@Component({
  selector: "app-unit-create",
  templateUrl: "./unit-create.component.html",
  styleUrls: ["./unit-create.component.css"],
})
export class UnitCreateComponent implements OnInit {
  form: FormGroup;
  addMoreForm: FormGroup;
  submitted = false;
  removeList = [];
  removeIndex: number;
  unit_labels = [];
  unit_label: string;
  quill = null;
  quillAddMore = null;
  addMoreSubmitted = false;

  @Input("unit-types") unitTypes = [];
  @Output() done = new EventEmitter();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private UnitService: UnitService,
    private UnitConversionService: UnitConversionService,
    private UnitLabelService: UnitLabelService,
    private ResponseStatusService: ResponseStatusService
  ) {
    this.setForm();
  }
  setForm(): void {
    this.addMoreForm = this.formBuilder.group({
      unit_short_name_quill_add_more: ["", [ENSignValidator()]],
    });

    this.form = this.formBuilder.group({
      unit_type_id: ["", Validators.required],
      unit_name_en: ["", [Validators.required]],
      unit_name_th: [""],
      unit_short_name_quill: ["", [Validators.required, ENSignValidator()]],
      unit_short_name_en: ["", [Validators.required]],
      unit_short_name_th: [""],
      unit_description: [""],
      // unit_labels: [[], [Validators.required]],
    });
    this.unit_label = "";
    this.unit_labels = [];
    this.unit_labels.push({
      unit_label: "",
    });

    if (this.quill) {
      this.quill.setText("");
    }

    if (this.quillAddMore) {
      this.quillAddMore.setText("");
    }

    this.removeList = [];
  }

  ngOnInit(): void {}
  async submit() {
    this.submitted = true;
    this.setLabel(this.addMoreForm.value.unit_short_name_quill_add_more);

    if (
      // !this.addMoreForm.value.unit_short_name_quill_add_more &&
      !this.unit_labels.length
    ) {
      return;
    }

    if (this.form.invalid) {
      return;
    }

    let data = {
      ...this.form.value,
      unit_name_th: this.form.value.unit_name_th,
      unit_short_name_th: this.form.value.unit_short_name_th,
    };
    let unitResponse = await this.UnitService.create(data);

    // create unit-conversion
    let unitConversionData = {
      unit_type_id: this.form.value.unit_type_id,
      unit_id: unitResponse.resultData.unit_id,
      order_number: 0,
    };
    let unitConversionResponse = await this.UnitConversionService.create(
      unitConversionData
    );

    let status = unitConversionResponse.success || unitConversionResponse.error;

    let unit_labels = this.unit_labels.filter((unit) => unit.unit_label.trim());

    let request_unit_labels = [];

    for (let unit_label of unit_labels) {
      let res = await this.UnitLabelService.create({
        unit_id: unitResponse.resultData.unit_id,
        unit_label: unit_label.unit_label,
      });

      if (res.success) {
        request_unit_labels.push(res.resultData.unit_label_id);
      }

      if (res.error && res.error.resultCode === "40900") {
        status = this.ResponseStatusService.getResponseStatus("40902");

        for (let unit_label_id of request_unit_labels) {
          let res = await this.UnitLabelService.delete({
            unit_label_id,
          });
        }

        await this.UnitConversionService.delete({
          unit_conversion_id:
            unitConversionResponse.resultData.unit_conversion_id,
        });

        await this.UnitService.delete({
          unit_id: unitResponse.resultData.unit_id,
        });

        break;
      }
    }

    // if (this.unit_label) {
    //   let res = await this.UnitLabelService.create({
    //     unit_id: unitResponse.resultData.unit_id,
    //     unit_label: this.unit_label,
    //   });
    // }

    this.done.emit(status);
    this.submitted = false;
    this.closeModalBtn.nativeElement.click();
  }

  async delete(closeModalEl) {
    if (this.removeIndex >= 0) {
      let removeEl = this.unit_labels[this.removeIndex];
      if (removeEl) {
        this.removeList.push(
          Object.assign({}, this.unit_labels[this.removeIndex])
        );

        this.unit_labels = this.unit_labels.filter(
          (v, index) => index !== this.removeIndex
        );
        // this.form.controls.unit_labels.setValue(this.unit_labels);
        this.removeIndex = null;
      }
    }
    if (!this.unit_labels.length) {
      this.unit_labels.push({
        unit_label: "",
      });
    }
    closeModalEl.click();
  }

  addMore() {
    this.unit_labels.push({
      unit_label: "",
    });

    // this.addMoreSubmitted = true;
    // if (this.addMoreForm.invalid) {
    //   return;
    // }
    // if (this.unit_label) {
    //   this.unit_labels.push({
    //     unit_label: this.unit_label,
    //   });
    //   if (this.quillAddMore) {
    //     this.quillAddMore.setText("");
    //   }
    //   this.unit_label = "";
    // }

    // this.setLabel(this.addMoreForm.value.unit_short_name_quill_add_more);
    // this.addMoreSubmitted = false;
  }

  select(index: number) {
    this.removeIndex = index;
  }

  setLabel(unit_label) {
    unit_label = unit_label.trim();
    this.addMoreForm.controls.unit_short_name_quill_add_more.setValue(
      unit_label
    );
    if (this.unit_labels.length) {
      if (unit_label && unit_label.length) {
        this.addMoreForm.controls.unit_short_name_quill_add_more.setValidators([
          ENSignValidator(),
        ]);
      } else {
        this.addMoreForm.controls.unit_short_name_quill_add_more.setValidators(
          []
        );
      }

      this.addMoreForm.controls.unit_short_name_quill_add_more.updateValueAndValidity();
    } else {
      this.addMoreForm.controls.unit_short_name_quill_add_more.setValidators([
        Validators.required,
        ENSignValidator(),
      ]);
      this.addMoreForm.controls.unit_short_name_quill_add_more.updateValueAndValidity();
    }
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.addMoreSubmitted = false;
        this.setForm();
      }
    });
  }
}
