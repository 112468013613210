import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class SystemSummaryService {
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/system-summary`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async sum(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/system-summary/sum`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
