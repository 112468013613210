<div
  class="modal fade text-left"
  id="quotation-confirm-voided"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">ยืนยันการยกเลิกใบเสนอราคา</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body-content">
          <h4 class="d-inline">คุณกำลังยกเลิกใบเสนอราคา เลขที่: </h4> <b>{{data.doc_no}}</b>
          <p class="pt-1">โปรดยืนยันโดยเลือกตัวเลือกด้านล่าง</p>

          <button
            type="button"
            style="padding: 8px;"
            class="btn btn-danger mr-1 mb-1"
            (click)="onSave('voided')">
                ยกเลิก
          </button>
          <button
            type="button"
            style="padding: 8px;"
            class="btn btn-primary mr-1 mb-1"
            (click)="onSave('voided-and-copy')">
                ยกเลิกและคัดลอกสร้างใหม่
          </button>
          <button
            type="button"
            style="padding: 8px;"
            class="btn btn-warning mr-1 mb-1"
            (click)="onSave('voided-and-copy-edit')">
                ยกเลิกและคัดลอกเพื่อแก้ไข
          </button>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn grey btn-outline-secondary"
          data-dismiss="modal"
          (click)="onClose()"
        >
          ปิด
        </button>
      </div>
    </div>
  </div>
</div>
