import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { QuotationService } from '../../../../services/quotations';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-customer-quotation',
  templateUrl: './customer-quotation.component.html',
  styleUrls: ['./customer-quotation.component.css']
})
export class CustomerQuotationComponent implements OnInit {
  quotations: Array<any>;
  user_id: string;
  customer_id: string;
  reset: boolean = false;
  isLoading = true;
  load: Function = this.QuotationService.load;
  filter = {};

  @Output() done = 
  new EventEmitter<any>();

  quotation = null;
  number_all_status = 0;
  number_draft_status = 0;
  number_waiting_for_approve_status = 0;
  number_waiting_for_accept_status = 0;
  number_accepted_status = 0;
  number_expired = 0;
  number_voided_status = 0;
  
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public QuotationService: QuotationService) { 
  }

  ngOnInit(): void {
    this.quotations = [];
    this.customer_id = this.route.snapshot.paramMap.get("customer_id");
    this.filterByCustomerId(this.customer_id);
  }
  getCountOfStatus(filter: object){
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;

      this.QuotationService.load(null, {...filter}).then(res=>{
        var resultData = res.resultData;
        this.number_draft_status = resultData.filter(v =>v.doc_status_id === 'DRAFT').length;
        this.number_waiting_for_approve_status = resultData.filter(v =>v.doc_status_id === 'WAITING_FOR_APPROVE').length;
        this.number_accepted_status = resultData.filter(v =>v.doc_status_id === 'ACCEPTED').length;
        this.number_waiting_for_accept_status = resultData.filter(v =>v.doc_status_id === 'WAITTING_FOR_ACCEPT').length;
        this.number_expired = resultData.filter(v =>v.doc_status_id === 'EXPIRED').length;
        this.number_voided_status = resultData.filter(v =>v.doc_status_id === 'VOIDED').length;
        this.number_all_status = res.rowCount;
      });
    });
  }
  onChangePage(quotations: Array<any>) {
    this.quotations = quotations;
    this.isLoading = false;
  }
  doReset(status?) {
    this.reset = false;
    setTimeout(() => {
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = { ...this.filter, ...filter };
  }
  searchStatus(status:string = null){
    let condition = {
      doc_status_id: status,
    };
    this.search(condition);
  }
  filterByCustomerId(customer_id){
    let condition = {
      "customer_id": customer_id
    };
    this.search(condition);
    this.getCountOfStatus(condition);
  }
  select(quotation: string) {
    this.quotation = Object.assign({}, quotation);
  }
  async onSaveConfirmVoided(event: any) {
    if(event){
      var response = await this.QuotationService.update({
        ...this.quotation,
        doc_status_id:'VOIDED',
        doc_date: moment(this.quotation.doc_date).format('YYYY/MM/DD'),
        doc_validdate: moment(this.quotation.doc_validdate).format('YYYY/MM/DD'),
        tax: this.quotation.tax.toString()
      });
    }
    if(event.type == "voided"){
      this.getCountOfStatus(null);
      this.doReset(response.success || response.error);
    }else if(event.type == "voided-and-copy"){
      if(response.success){
        this.router.navigateByUrl("/quotation/" + this.quotation.quotation_info_id + "/edit?action=copy", {
          state: {
            status: response.success,
        }});
      }else{
        this.doReset(response.success || response.error);
      }
    }else{
      if(response.success){
        this.router.navigateByUrl("/quotation/" + this.quotation.quotation_info_id + "/edit?action=copy&&type=edit", {
          state: {
            status: response.success,
        }});
      }else{
        this.doReset(response.success || response.error);
      }
    }
    event.closeModal.click();
  }
}
