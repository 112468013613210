import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class QuotationNoteMasterService {
  async create({
    quotation_note,
    isMaster,
    note_type,
    order,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-note-master`, {
        quotation_note,
        note_type: note_type.toString(),
        order: order.toString(),
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    quotation_note_master_id,
    quotation_note,
    isMaster,
    note_type,
    order,
  }) {
    let result = null;
    try {
      result = await Axios().put(`/quotation-note-master/${quotation_note_master_id}`, {
        quotation_note,
        note_type: note_type.toString(),
        order: order.toString(),
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_note_master_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-note-master/${quotation_note_master_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-note-master`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ quotation_note_master_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-note-master/${quotation_note_master_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
