import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobOrderMemoService {
  async create({
    job_order_info_id,
    memo
  }) {
    let result = null;
    try {
      result = await Axios().post(`/job-order-memo`, {
        job_order_info_id,
        memo
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      transport_receive_memo_id,
      job_order_info_id,
      memo
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/job-order-memo/${transport_receive_memo_id}`, {
        transport_receive_memo_id,
        job_order_info_id,
        memo
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ transport_receive_memo_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/job-order-memo/${transport_receive_memo_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-memo`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ transport_receive_memo_id }) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-memo/${transport_receive_memo_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
