import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { 
  AccBillingNoteItemService, 
  AccBillingNoteItemHistoryService 
} from 'src/app/services/billing-note';
import { AccCreditNoteInfoService } from 'src/app/services/credit-note';
import { AccDebitNoteInfoService } from 'src/app/services/debit-note';
import { AccInvoiceInfoService } from 'src/app/services/invoice';
import { CalculateFunctionService } from 'src/app/services/quotations';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';

@Component({
  selector: 'app-billing-note-item',
  templateUrl: './billing-note-item.component.html',
  styleUrls: ['./billing-note-item.component.css']
})
export class BillingNoteItemComponent implements OnInit {
  pageLoad = false;
  pageType: string = 'create';
  acc_billing_note_info_id: string;

  billingNoteItems = [];
  delelteBillingNoteItems: any = [];

  billingNote = null;
  billType = null;
  @Output() done = new EventEmitter<any>();
  @Input('customer_info') customer_info: any;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private AccBillingNoteItemService: AccBillingNoteItemService,
    private AccInvoiceInfoService:AccInvoiceInfoService,
    private AccTaxInvoiceInfoService:AccTaxInvoiceInfoService,
    private AccBillingNoteItemHistoryService: AccBillingNoteItemHistoryService,
    private AccDebitNoteInfoService: AccDebitNoteInfoService,
    private AccCreditNoteInfoService: AccCreditNoteInfoService,

    public calculate: CalculateFunctionService,
  ) { 
    this.acc_billing_note_info_id = 
    this.route.snapshot.paramMap.get("acc_billing_note_info_id");

    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  async ngOnInit() {
    this.pageLoad = false;

    if(this.acc_billing_note_info_id){
      await this.AccBillingNoteItemService.load(null, {acc_billing_note_info_id: this.acc_billing_note_info_id})
      .then(async res=>{
        this.billingNoteItems = res.resultData || [];

        this.billingNoteItems.sort(function(a,b){
          if(a.doc_no_company > b.doc_no_company) return 1;
          if(a.doc_no_company < b.doc_no_company) return -1;
          return 0;
        });

        this.billingNoteItems.sort(function(a,b){
          if(a.doc_no > b.doc_no) return 1;
          if(a.doc_no < b.doc_no) return -1;
          return 0;
        });
        
        await Promise.all(this.billingNoteItems.map(async (bill)=>{
          if(bill.doc_type == 'INVOICE'){
            await this.AccInvoiceInfoService.getById({acc_invoice_info_id: bill.acc_document_info_id})
            .then(res=>{
              bill['data'] = res.resultData;
            })
          }else if(bill.doc_type == 'TAX_INVOICE'){
            await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: bill.acc_document_info_id})
            .then(res=>{
              bill['data'] = res.resultData;
            })
          }else if(bill.doc_type == 'CREDIT_NOTE'){
            await this.AccCreditNoteInfoService.getById({acc_credit_note_info_id: bill.acc_document_info_id})
            .then(res=>{
              bill['data'] = res.resultData;
            })
          }else{
            await this.AccDebitNoteInfoService.getById({acc_debit_note_info_id: bill.acc_document_info_id})
            .then(res=>{
              bill['data'] = res.resultData;
            })
          }
        }));
        await this.sentData()
      })
    }
  }

  async submitBillingNoteItem(event){
    if(event.length > 0){
      event.map(item=>{
        let checkDuplicate = this.billingNoteItems.find(value=> value.acc_document_info_id == item.acc_document_info_id);
        if(!checkDuplicate){
          this.billingNoteItems.push(item);

          this.billingNoteItems.sort(function(a,b){
            if(a.data.doc_no_company > b.data.doc_no_company) return 1;
            if(a.data.doc_no_company < b.data.doc_no_company) return -1;
            return 0;
          });

          this.billingNoteItems.sort(function(a,b){
            if(a.data.doc_no > b.data.doc_no) return 1;
            if(a.data.doc_no < b.data.doc_no) return -1;
            return 0;
          });
        }
      })
    }
    this.billingNote = null;
    await this.sentData();
  }

  async submitHistory(event){
    if(event.billing_note_item){
      const index = this.billingNoteItems.indexOf(this.billingNote);
      this.billingNoteItems[index] = {
        ...this.billingNoteItems[index],
        ...event.billing_note_item
      };
      if(this.billingNoteItems[index].acc_billing_note_item_id){
        let billingNoteItemResponse = await this.AccBillingNoteItemService
        .update({
          ...this.billingNoteItems[index]
        });
        if(billingNoteItemResponse.success){
          let  billingNoteItemHistoryResponse = await this.AccBillingNoteItemHistoryService
          .create({
            acc_billing_note_item_id: billingNoteItemResponse.resultData.acc_billing_note_item_id,
            ...this.billingNoteItems[index].history
          });
          if(!billingNoteItemHistoryResponse.success){
            throw billingNoteItemHistoryResponse.error
          }
        }
      }
    }
    event.closeModalBtn.nativeElement.click();
    this.billingNote = null;
    await this.sentData();
  }

  select(billingNote){
    this.billingNote = billingNote;
  }

  setBillType(billType){
    this.billType = billType;
  }

  async delete(closeModalEl){
    const index = this.billingNoteItems.indexOf(this.billingNote);
    this.delelteBillingNoteItems.push(this.billingNote);
    if (index > -1) {
      this.billingNoteItems.splice(index, 1);
    }
    await this.sentData();
    closeModalEl.click();
  }

  async sentData(){
    this.done.emit({
      billingNoteItems: this.billingNoteItems,
      delelteBillingNoteItems: this.delelteBillingNoteItems
    });
  }
}
