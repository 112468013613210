import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class CustomerAddressService {
  async create({
    customer_id,
    address,
    district_id,
    district_name,
    amphure_id,
    amphure_name,
    province_id,
    province_name,
    tel_1,
    tel_2,
    email_1,
    email_2,
    fax_1,
    fax_2,
    memo,
    is_registed_address,
    geography_id,
    customer_address_type_id,
    address_name,
    branch,
    department,
    department_code,
    tax_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/customer-address`, {
        customer_id,
        address,
        district_id: parseInt(district_id),
        district_name,
        amphure_id: parseInt(amphure_id),
        amphure_name,
        province_id: parseInt(province_id),
        province_name,
        tel_1,
        tel_2,
        email_1,
        email_2,
        fax_1,
        fax_2,
        memo,
        is_registed_address,
        zip_code: parseInt(geography_id),
        customer_address_type_id: Number(customer_address_type_id) || 1,
        address_name,
        branch,
        department,
        department_code,
        tax_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    customer_address_id,
    customer_id,
    address,
    district_id,
    district_name,
    amphure_id,
    amphure_name,
    province_id,
    province_name,
    tel_1,
    tel_2,
    email_1,
    email_2,
    fax_1,
    fax_2,
    memo,
    is_registed_address,
    geography_id,
    customer_address_type_id,
    address_name,
    branch,
    department,
    department_code,
    tax_id
  }) {
    let result = null;
    try {
      result = await Axios().put(`/customer-address/${customer_address_id}`, {
        customer_id,
        address,
        district_id: parseInt(district_id),
        district_name,
        amphure_id: parseInt(amphure_id),
        amphure_name,
        province_id: parseInt(province_id),
        province_name,
        tel_1,
        tel_2,
        email_1,
        email_2,
        fax_1,
        fax_2,
        memo,
        is_registed_address,
        zip_code: parseInt(geography_id),
        customer_address_type_id: Number(customer_address_type_id) || 1,
        address_name,
        branch,
        department,
        department_code,
        tax_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ customer_address_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/customer-address/${customer_address_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/customer-address`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ customer_address_id }) {
    let result = null;
    try {
      result = await Axios().get(`/customer-address/${customer_address_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
