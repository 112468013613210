import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { 
  QuotationMitItemService, 
  QuotationProductItemService 
} from 'src/app/services/quotations';
import { 
  UserInfoState 
} from 'src/app/store/user/user.state';
import { 
  TransportreceiveItemService 
} from 'src/app/services/transport-receives';
import { GETService } from 'src/app/services';
import { FormBuilder, FormGroup } from '@angular/forms';
declare const $;

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {
  form : FormGroup;

  status: string;
  reset: boolean = false;
  load: Function = null;
  isLoading: boolean = true;
  canAction: boolean = true;

  filter = {};

  itemJobStatus = [];
  itemTranStatus = [];
  itemTranQtStatus = [];
  itemJobTrainingStatus = [];

  itemBilling = [
    { id:'-', text: '-' },
    { id:'true', text: 'สามารถเก็บค่าบริการได้' },
  ];

  itemAccStatus = [
    { id:'-', text: '-' },
    { id: 'WAITING_FOR_APPROVE', text: 'รออนุมัติ'},
    { id: 'WAITING_FOR_PAYMENT', text: 'รอชำระ'},
    { id: 'COMPLETED', text: 'ชำระแล้ว'},
  ];

  calibration_count: number = 0;
  no_quotation_count: number = 0;
  transport_count: number = 0;
  training_count: number = 0;
  product_count: number = 0;

  items = null;
  item = null;
  itemIndex = null;
  tab: string = 'calibration';
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;

  constructor(
    private fbd: FormBuilder,
    private router: Router,
    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,
    private TransportreceiveItemService: TransportreceiveItemService,
    
    private GETService: GETService
  ) { 
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }
  public routeEvent(state): void {
    this.status = state?.status;
  }
  async ngOnInit() {
    this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;
  
      if(userInfo?.emp_info?.emp_position_id.startsWith("TRAN_DEP-01") ||
      userInfo?.emp_info?.emp_position_id.startsWith("ACC_DEP-01") ||
      userInfo?.emp_info?.emp_position_id.startsWith("TRAN_DEP-02")){
        this.canAction = false;
      };
    });

    this.form = this.fbd.group({
      tran_item_status_id: [""],
      job_item_status_id: [""],
      invoice_status_id: [""],
      tax_invoice_status_id: [""],
      transport_receive_info_doc_no: [""],
      company_name: [""],
      doc_no: [""],
      job_order_doc_no: [""],
      ready_to_billing: [""],
    });

    this.load = this.QuotationMitItemService.equipment;
    this.filter = {
      "quotation_info_status": "ACCEPTED",
      is_child_quotation_mit_item_id_not_null: false,
      'order_by': 'quotation_info.doc_no:desc,order_by:asc'
    };

    await this.GETService.itemStatus({order_by: "order:asc"})
    .then(res=>{
      let resultData = res.resultData.filter(status => status.section =='TRANSPORT_RECEIVE') || [];
      this.itemTranQtStatus = resultData.map(item=>{
        let text = "";
        if (item.item_status_id == 'WAITING_FOR_RECEIVE'){
          text = "รอเข้ารับ";
        }else{
          text = item.item_status_name_th;
        }
        return {
          ...item,
          id: item.item_status_id,
          text: text,
        }
      });
      this.itemTranStatus = resultData.map(item=>{
        let text = "";
        if (item.item_status_id == 'WAITING_FOR_RECEIVE'){
          text = "รอเข้ารับ";
        }else{
          text = item.item_status_name_th;
        }
        return {
          ...item,
          id: item.item_status_id,
          text: text
        }
      });
      this.itemTranQtStatus.unshift(
        {
          id:'-',
          text: '-'
        }
      )

      this.itemJobStatus = res.resultData.filter(status => (status.section =='WORKORDER' && status.item_status_id != 'CRF-CALI_COMPLETED' || status.item_status_id == 'RETURNED'));
      this.itemJobTrainingStatus = res.resultData.filter(status => (status.section =='WORKORDER' && status.item_status_id != 'CRF-CANCELED' && status.item_status_id != 'CRF-CALI_COMPLETED' || status.item_status_id == 'RETURNED'));

      this.itemJobStatus = this.itemJobStatus.map(item=>{

        let text = "";
        if (item.item_status_id == 'CRF-WAITING_FOR_CONFIRM'){
          text = "รอการยืนยัน";
        }else if(item.item_status_id == 'CRF-CONFIRMED'){
          text = "อยู่ระหว่างให้บริการ";
        }else if(item.item_status_id == 'CRF-COMPLETED'){
          text = "บันทึกผลแล้ว";
        }else if(item.item_status_id == 'RETURNED'){
          text = "เสร็จสิ้น";
        }else{
          text = item.item_status_name_th;
        }
        return {
          ...item,
          id: item.item_status_id,
          text: text
        }
      });
      this.itemJobStatus.unshift(
        {
          id:'-',
          text: '-'
        }
      )

      this.itemJobTrainingStatus = this.itemJobTrainingStatus.map(item=>{

        let text = "";
        if (item.item_status_id == 'CRF-WAITING_FOR_CONFIRM'){
          text = "รอการยืนยัน";
        }else if(item.item_status_id == 'CRF-CONFIRMED'){
          text = "อยู่ระหว่างให้บริการ";
        }else if(item.item_status_id == 'CRF-COMPLETED'){
          text = "เสร็จสิ้น";
        }else{
          text = item.item_status_name_th;
        }
        return {
          ...item,
          id: item.item_status_id,
          text: text
        }
      });
      this.itemJobTrainingStatus.unshift(
        {
          id:'-',
          text: '-'
        }
      )
    });

    this.getNumberOfStatus();
  }

  getNumberOfStatus(){
    this.QuotationMitItemService.equipment({limit:0}, {"quotation_info_status": "ACCEPTED", is_child_quotation_mit_item_id_not_null: false}).then(res=>{
      this.calibration_count = res.rowCount;
    });
    this.QuotationMitItemService.equipment({limit:0}, {'quotation_info_id': 'new'}).then(res=>{
      this.no_quotation_count = res.rowCount;
    });
    this.TransportreceiveItemService.load({limit:0}, {"quotation_mit_item_id": "null"}).then(res=>{
      this.transport_count = res.rowCount;
    });
    this.QuotationProductItemService.load({limit:0}, { "startwith|$product_info.product_title$": "training".charAt(0).toUpperCase() + "training".slice(1),"quotation_info_status": "ACCEPTED"}).then(res=>{
      this.training_count = res.rowCount;
    });
    this.QuotationProductItemService.load({limit:0}, {'ne|$product_info.product_title$': 'Training',"quotation_info_status": "ACCEPTED"}).then(res=>{
      this.product_count = res.rowCount;
    });
  }
  public onChangePage(items: Array<any>) {
    this.items = items;
    
    this.isLoading = false;
  }
  async doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  public search() {
    let filter = null;
    if(this.tab == "transport"){
      filter = {
        "job_order_info_doc_no": undefined,
        "$transport_receive_info.doc_no$": this.form.value.transport_receive_info_doc_no? this.form.value.transport_receive_info_doc_no: undefined,
        "$quotation_info.customer_info.company_name$": undefined,
        "quotation_info_doc_no": undefined,
        "$transport_receive_info.customer_info.company_name$": this.form.value.company_name? this.form.value.company_name: undefined,
        "$job_order_product_item_info.job_order_info.doc_no$": undefined,
      }
    }else{
      filter = {
        "$transport_receive_info.customer_info.company_name$": undefined,
        "quotation_info_doc_no": this.form.value.doc_no? this.form.value.doc_no: undefined,
        "job_order_info_doc_no": this.form.value.job_order_doc_no? this.form.value.job_order_doc_no: undefined,
        "$quotation_info.customer_info.company_name$": this.form.value.company_name? this.form.value.company_name: undefined
      }
      if(this.tab == 'calibration'){
        filter = {
          ...filter,
          "transport_receive_info_doc_no": this.form.value.transport_receive_info_doc_no? this.form.value.transport_receive_info_doc_no: undefined,
        }
      }else{
        filter = {
          ...filter,
          "transport_receive_info_doc_no": undefined
        }
      }
    }

    this.filter = { ...this.filter, ...filter };
    
    this.router.navigate([], {
      queryParams: this.filter,
      queryParamsHandling: 'merge',
    });
    // console.log(this.filter);
  }

  async searchFilter(filter?){
    this.isLoading = true;
    this.items = null;

    this.tab = filter;
    this.form.get("tran_item_status_id").setValue("");
    this.form.get("job_item_status_id").setValue("");
    this.form.get("invoice_status_id").setValue("");
    this.form.get("tax_invoice_status_id").setValue("");
    this.form.get("ready_to_billing").setValue("");
    
    if(filter == 'calibration'){
      this.load = this.QuotationMitItemService.equipment;
      this.filter = {
        "quotation_info_status": "ACCEPTED", 
        is_child_quotation_mit_item_id_not_null: false,
        'order_by': 'quotation_info.doc_no:desc,order_by:asc'
      };
    }else if(filter == 'no_quotation'){
      this.load = this.QuotationMitItemService.equipment;
      this.filter = {
        'quotation_info_id': 'new'
      };
    }else if(filter == 'transport'){
      this.load = this.TransportreceiveItemService.load;
      this.filter = {
        "quotation_mit_item_id": "null"
      };
    }else{
      this.load = this.QuotationProductItemService.load;
      if(filter == 'training'){
        this.filter = {
          "startwith|$product_info.product_title$": filter.charAt(0).toUpperCase() + filter.slice(1),
          "quotation_info_status": "ACCEPTED"
        }
      }else{
        this.filter = {
          'ne|$product_info.product_title$': 'Training',
          "quotation_info_status": "ACCEPTED"
        }
      }
    }
  }

  public filterTransportStatus(filter?){
    if(this.tab == 'calibration'){
      let newFilter = filter.filter(item=> item!='-').join();
      let isNull = filter.find(item=> item == '-')? true: false;

      if(newFilter || isNull){
        this.filter = {
          ...this.filter,
          "transport_receive_item_status": newFilter || undefined,
          transport_receive_item_is_null: isNull? isNull: undefined
        };
      }else{
        this.filter = {
          ...this.filter,
          "transport_receive_item_status": undefined,
          transport_receive_item_is_null: undefined
        };
      }
    }else if(this.tab == 'transport'){
      let newFilter = filter.join();
      if(newFilter){
        this.filter = {
          ...this.filter,
          "item_status_id": newFilter,
        };
      }else{
        this.filter = {
          ...this.filter,
          "item_status_id": undefined,
        };
      }
    }
  }

  public filterJobOrderStatus(filter?){
    if(this.tab == 'calibration'){
      let newFilter = filter.filter(item=> item!='-').join();
      let isNull = filter.find(item=> item == '-')? true: false;

      if(newFilter || isNull){
        this.filter = {
          ...this.filter,
          "job_order_calibration_item_status": newFilter,
          job_order_calibration_item_is_null: isNull? isNull: undefined,
          "job_order_product_item_status": undefined,
          job_order_product_item_is_null: undefined
        };
      }else{
        this.filter = {
          ...this.filter,
          "job_order_calibration_item_status": undefined,
          "job_order_product_item_status": undefined,
          job_order_calibration_item_is_null: undefined,
          job_order_product_item_is_null: undefined
        };
      }
    }else if(this.tab == 'training' || this.tab == 'product'){
      let newFilter = filter.filter(item=> item!='-').join();
      let isNull = filter.find(item=> item == '-')? true: false;

      if(newFilter || isNull){
        this.filter = {
          ...this.filter,
          "job_order_product_item_status": newFilter,
          job_order_product_item_is_null: isNull? isNull: undefined,
          "job_order_calibration_item_status": undefined,
          job_order_calibration_item_is_null: undefined
        };
      }else{
        this.filter = {
          ...this.filter,
          "job_order_product_item_status": undefined,
          "job_order_calibration_item_status": undefined,
          job_order_calibration_item_is_null: undefined,
          job_order_product_item_is_null: undefined
        };
      }
    }
  }

  public filterInvoiceStatus(filter?){
    let newFilter = filter.filter(item=> item!='-').join();
    let isNull = filter.find(item=> item == '-')? true: false;

    if(newFilter || isNull){
      this.filter = {
        ...this.filter,
        "acc_tax_invoice_info_status": undefined,
        "acc_invoice_info_status": newFilter,
        acc_invoice_items_is_null: isNull? isNull: undefined,
      };
    }else{
      this.filter = {
        ...this.filter,
        "acc_tax_invoice_info_status": undefined,
        "acc_invoice_info_status": undefined,
        acc_invoice_items_is_null:undefined
      };
    }
  }

  public filterTaxInvoiceStatus(filter?){
    let newFilter = filter.filter(item=> item!='-').join();
    let isNull = filter.find(item=> item == '-')? true: false;

    if(newFilter || isNull){
      this.filter = {
        ...this.filter,
        "acc_tax_invoice_info_status": newFilter,
        "acc_invoice_info_status": undefined,
        acc_tax_invoice_items_is_null: isNull? isNull: undefined,
      };
    }else{
      this.filter = {
        ...this.filter,
        "acc_tax_invoice_info_status": undefined,
        "acc_invoice_info_status": undefined,
        acc_tax_invoice_items_is_null: undefined
      };
    }
  }

  public filterbilling(filter?){
    
    if(filter){
      this.filter = {
        ...this.filter,
        "ready_to_billing": filter == '-'? 'false': filter
      };
    }else{
      this.filter = {
        ...this.filter,
        "ready_to_billing": undefined
      };
    }
  }

  public checkStatusInvoiceItem(status){
    if(status == 'DRAFT' || status == 'REJEDTED'){
      return "-";
    }else if(status == 'WAITING_VERIFY' || status == 'WAITING_FOR_APPROVE'){
      return "รออนุมัติ";
    }else if(status == 'WAITING_FOR_PAYMENT'){
      return "รอชำระ";
    }else if(status == 'COMPLETED'){
      return "ชำระแล้ว";
    }else if(status == 'TOTAL_AMOUNT_CREDITED'){
      return "ลดหนี้ทั้งจำนวน";
    }else{
      return "-";
    }
  }

  public checkStatusTaxInvoiceItem(status){
    if(status == 'DRAFT' || status == 'REJEDTED'){
      return "-";
    }else if(status == 'WAITING_VERIFY' || status == 'WAITING_FOR_APPROVE'){
      return "รออนุมัติ";
    }else if(status == 'WAITING_FOR_PAYMENT'){
      return "รอชำระ";
    }else if(status == 'COMPLETED'){
      return "ชำระแล้ว";
    }else if(status == 'TOTAL_AMOUNT_CREDITED'){
      return "ลดหนี้ทั้งจำนวน";
    }else{
      return "-";
    }
  }

  public checkCanServiceCharge(item, check = 'default'): boolean {
    let files = item.quotation_info?.quotation_accepted_info.find(file => file?.quotation_accepted_file_info?.length > 0);
    
    if(this.tab == 'calibration' || this.tab == 'no_quotation'){
      var lock = !item?.lock_invoice_at || item?.unlock_invoice_at;
    }else{
      var lock = !item?.lock_invoice_at || item?.unlock_invoice_at;
    }
    if(check == 'file'){
      return files? true: false;
    }else{
      return files && lock? true: false;
    }
  }
  
  public checkCanServiceChargeArray(): boolean {
    let result = [];
    if(this.tab == 'calibration' || this.tab == 'no_quotation'){
      result = this.items.filter(item => 
        ['CRF-CANCELED'].includes(item?.job_order_calibration_item_status) && item.lock_invoice_at && !item.ready_to_billing ||
        this.checkCanServiceCharge(item) && ['CRF-COMPLETED','PREPARE_FOR_SHIP', 'RETURNED'].includes(item?.job_order_calibration_item_status) && !item.ready_to_billing
        );
    }else if(this.tab == 'product'){
      result = this.items.filter(item => 
        this.checkCanServiceCharge(item, 'file') && ['CRF-COMPLETED','PREPARE_FOR_SHIP', 'RETURNED'].includes(item?.job_order_product_item_status) && item.product_info?.product_type_id !=2 && !item.ready_to_billing
        || this.checkCanServiceCharge(item, 'file') && item.product_info?.product_type_id ==2 && item.quotation_info?.doc_status_id=='ACCEPTED' && !item.ready_to_billing
        || this.checkCanServiceCharge(item, 'file') && item.product_info?.product_type_id ==3 && item.quotation_info?.doc_status_id=='ACCEPTED' && !item.ready_to_billing
        );
    }else{
      result = [];
    }

    return result.length > 0? true: false;
  }

  async setBilling(item, type:string, index: number){
    if(type == 'product'){
      let response = await this.QuotationProductItemService.update(
        {
          ...item,
          ready_to_billing: new Date()
        }
      )
      this.items[index].ready_to_billing = new Date();
      this.status = response.success || response.error;
    }else{
      let response = await this.QuotationMitItemService.update(
        {
          ...item,
          ready_to_billing: new Date()
        }
      )
      this.items[index].ready_to_billing = new Date();
      this.status = response.success || response.error;
    }
  }

  async setBillingArray(){
    if(this.tab == 'calibration' || this.tab == 'no_quotation'){
      await Promise.all(this.items.map(async (item, index)=>{
        if(
          item.job_order_calibration_item_status == "" && !item.ready_to_billing ||
          this.checkCanServiceCharge(item) && ['CRF-COMPLETED','PREPARE_FOR_SHIP', 'RETURNED'].includes(item?.job_order_calibration_item_status) && !item.ready_to_billing){
          this.setBilling(item, 'calibration', index);
        }
      }));
    }else if(this.tab == 'product'){
      await Promise.all(this.items.map(async (item, index)=>{
        if(this.checkCanServiceCharge(item, 'file') && ['CRF-COMPLETED','PREPARE_FOR_SHIP', 'RETURNED'].includes(item?.job_order_product_item_status) && item.product_info?.product_type_id !=2 && !item.ready_to_billing
        || this.checkCanServiceCharge(item, 'file') && item.product_info?.product_type_id ==2 && item.quotation_info?.doc_status_id=='ACCEPTED' && !item.ready_to_billing
        || this.checkCanServiceCharge(item, 'file') && item.product_info?.product_type_id ==3 && item.quotation_info?.doc_status_id=='ACCEPTED' && !item.ready_to_billing){
          
          this.setBilling(item, 'product', index);
        }
      }));
    }
  }

  public btnToggleTransport() {
    if(this.tab == 'calibration'){
      if(document.getElementById("job_order_status").classList.toggle("show")){
        document.getElementById("job_order_status").classList.toggle("show")
      }
      if(document.getElementById("invoice_status").classList.toggle("show")){
        document.getElementById("invoice_status").classList.toggle("show")
      }
      if(document.getElementById("tax_invoice_status").classList.toggle("show")){
        document.getElementById("tax_invoice_status").classList.toggle("show")
      }
      if(document.getElementById("ready_to_billing").classList.toggle("show")){
        document.getElementById("ready_to_billing").classList.toggle("show")
      }
    }
    document.getElementById("transport_receive_status").classList.toggle("show");
  }

  public btnToggleJobOrder() {
    if(this.tab == 'calibration'){
      if(document.getElementById("transport_receive_status").classList.toggle("show")){
        document.getElementById("transport_receive_status").classList.toggle("show")
      }
      if(document.getElementById("invoice_status").classList.toggle("show")){
        document.getElementById("invoice_status").classList.toggle("show")
      }
      if(document.getElementById("tax_invoice_status").classList.toggle("show")){
        document.getElementById("tax_invoice_status").classList.toggle("show")
      }
      if(document.getElementById("ready_to_billing").classList.toggle("show")){
        document.getElementById("ready_to_billing").classList.toggle("show")
      }
    }
    if(this.tab == 'training' || this.tab == 'product'){
      if(document.getElementById("invoice_status").classList.toggle("show")){
        document.getElementById("invoice_status").classList.toggle("show")
      }
      if(document.getElementById("tax_invoice_status").classList.toggle("show")){
        document.getElementById("tax_invoice_status").classList.toggle("show")
      }
      if(document.getElementById("ready_to_billing").classList.toggle("show")){
        document.getElementById("ready_to_billing").classList.toggle("show")
      }
    }
    document.getElementById("job_order_status").classList.toggle("show");
  }

  public btnToggleInvoice() {
    if(this.tab == 'calibration' || this.tab == 'training' || this.tab == 'product'){
      if(document.getElementById("job_order_status").classList.toggle("show")){
        document.getElementById("job_order_status").classList.toggle("show")
      }
      if(document.getElementById("tax_invoice_status").classList.toggle("show")){
        document.getElementById("tax_invoice_status").classList.toggle("show")
      }
      if(document.getElementById("ready_to_billing").classList.toggle("show")){
        document.getElementById("ready_to_billing").classList.toggle("show")
      }
    }
    document.getElementById("invoice_status").classList.toggle("show");
  }

  public btnTogglebilling() {
    if(this.tab == 'calibration' || this.tab == 'training' || this.tab == 'product'){
      if(document.getElementById("job_order_status").classList.toggle("show")){
        document.getElementById("job_order_status").classList.toggle("show")
      }
      if(document.getElementById("tax_invoice_status").classList.toggle("show")){
        document.getElementById("tax_invoice_status").classList.toggle("show")
      }
      if(document.getElementById("invoice_status").classList.toggle("show")){
        document.getElementById("invoice_status").classList.toggle("show")
      }
    }
    document.getElementById("ready_to_billing").classList.toggle("show");
  }

  public btnToggleTaxInvoice(){
    if(this.tab == 'calibration' || this.tab == 'training' || this.tab == 'product'){
      if(document.getElementById("job_order_status").classList.toggle("show")){
        document.getElementById("job_order_status").classList.toggle("show")
      }
      if(document.getElementById("invoice_status").classList.toggle("show")){
        document.getElementById("invoice_status").classList.toggle("show")
      }
      if(document.getElementById("ready_to_billing").classList.toggle("show")){
        document.getElementById("ready_to_billing").classList.toggle("show")
      }
    }
    document.getElementById("tax_invoice_status").classList.toggle("show");
  }

  public checkStatusJobOrder(job_order_item_info, includeStatus = []): boolean{
    let job_item_status_id = null;
    if(job_order_item_info.length >1){
      job_item_status_id = job_order_item_info.find(item=> item.item_status_id != 'CRF-CANCELED');
    }else{
      job_item_status_id = job_order_item_info[0];
    }
    return includeStatus.includes(job_item_status_id?.item_status_id)? true: false;
  }

  mapping(item){
    this.item = Object.assign({}, item);

    setTimeout(() => {
      $(`#modalMappingFilter`).modal("show");
    }, 100);
  }
}
