import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { 
  AccCreditNoteInfoService
} from 'src/app/services/credit-note';
import { AccDebitNoteInfoService } from 'src/app/services/debit-note';

@Component({
  selector: 'app-billing-note-item-credit-create',
  templateUrl: './billing-note-item-credit-create.component.html',
  styleUrls: ['./billing-note-item-credit-create.component.css']
})
export class BillingNoteItemCreditCreateComponent implements OnInit {
  creditNotes = [];
  creditNote = null;
  isShowCheckAll: boolean = false;

  status: string;
  reset: boolean = false;
  load: Function = null;
  filter = {};

  number_of_select:number = 0;
  //any
  @Input('billType') billType: string;
  @Input('customer_info') customer_info: any;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private AccDebitNoteInfoService: AccDebitNoteInfoService,
    private AccCreditNoteInfoService: AccCreditNoteInfoService,
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
  }

  async ngOnInit() {}

  async ngOnChanges(changes: SimpleChanges) {
    if(this.billType == 'credit'){
      this.load = this.AccCreditNoteInfoService.load;
    }else{
      this.load = this.AccDebitNoteInfoService.load;
    }
    this.filter = { doc_status_id: "APPROVED", customer_id: this.customer_info.customer_id };
  }

  onChangePage(creditNotes: Array<any>) {
    this.creditNotes = creditNotes;
    let result = this.creditNotes.filter(item=>!item.acc_billing_note_info_id);
    this.isShowCheckAll = false;
    if(result.length > 0){
      this.isShowCheckAll = true;
    }
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = { ...this.filter, ...filter };
  }

  onCheckAll(e){
    this.creditNotes.map(item=>{
      item['select'] = e.target.checked;
    })
    this.number_of_select = this.creditNotes.filter(item=>item.select == true && !item.acc_billing_note_info_id).length;
  }

  onCheckChange(e, index: number){
    this.creditNotes[index]['select'] = e.target.checked;
    this.number_of_select = this.creditNotes.filter(item=>item.select == true).length;
  }

  submit(){
    let items = this.creditNotes.filter(item=> item.select == true && !item.acc_billing_note_info_id);
    var newResult = items.map(item => ({ 
      acc_billing_note_info_id: "",
      acc_document_info_id: this.billType =='credit'? item.acc_credit_note_info_id: item.acc_debit_note_info_id,
      doc_type: this.billType =='credit'? "CREDIT_NOTE": "DEBIT_NOTE",
      appointment_location: this.customer_info.check_location,
      doc_no: item.doc_no,
      appointment_date: undefined,
      note: item.doc_reason,
      data:item
     }));
    this.done.emit(newResult);
    this.closeModalBtn.nativeElement.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.number_of_select = 0;
      }
    });
  }
}
