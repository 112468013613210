import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { 
  UtilsService,
  EmployeeService,
  CustomerInfoService,
  TransportMasterBookingService
} from '../../../../services';

import { NavigationStart, Router } from "@angular/router";
import moment from "moment";
import { Select, Store } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { Observable } from "rxjs";
@Component({
  selector: 'app-master-booking-status',
  templateUrl: './master-booking-status.component.html'
})
export class MasterBookingStatusComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  pageLoad: boolean = false;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") closeModal: ElementRef;
  @Input() booking: any;
  @Input() id = "modalMasterBookingStatus";

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
    private TransportMasterBookingService: TransportMasterBookingService,
    
    public UtilsService: UtilsService,
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModal.nativeElement.click();
      }
    });
    this.setForm();
  }

  setForm(){
    this.form = this.formBuilder.group({
      transport_masterbooking_id: [""],
      booking_type: [""],
      booking_sub_type: [""],
      booking_date: [""],
      booking_time: [""],
      customer_id: [null],
      location: [""],
      extra_message: [""],
      quotation_info_doc_no: [""],
      job_order_info_doc_no: [""],
      transport_receive_info_doc_no: [""],
      transport_type_id: [""],
      contact_name: ["",],
      contact_phonenumber: [""],
      messenger_id: [""],
      doc_status_id: [""],

      acc_invoice_info_doc_no: [""],
      status: ["COMPLETED"],
    });

    this.form.controls.status.valueChanges.subscribe(
      (value) => {
        if(value == 'COMPLETED' || value == 'CANCELED'){
          this.form.controls.booking_date.setValidators(null);
          this.form.controls.booking_time.setValidators(null);

        }else{
          this.form.controls.booking_date.setValidators([Validators.required]);
          this.form.controls.booking_time.setValidators([Validators.required]);
        }

        this.form.controls.booking_date.updateValueAndValidity();
        this.form.controls.booking_time.updateValueAndValidity();
      }
    );
  }

  async ngOnInit() {
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.pageLoad = false;
    console.log(this.booking)

    this.form.patchValue(
    {...this.booking,
        booking_date: this.booking.booking_date? moment(new Date(this.booking.booking_date)).format('YYYY-MM-DD'): moment(new Date()).format('YYYY-MM-DD'),
        booking_time: this.booking.booking_time
    }
    );

    this.pageLoad = true;
  }

  async onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let formValue = this.form.getRawValue();
    let data = {
      ...formValue,
      doc_status_id: formValue.status
    }

    if(formValue.status == 'COMPLETED'){
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
      data.closed_at = moment(new Date()).format();
      data.closed_by = userInfo.user_id;
      this.saveData(data);
    }else if (formValue.status == 'RESCHEDULED'){
      let oldData = {
        ...formValue,
        doc_status_id: formValue.status,
        booking_date: this.booking.booking_date,
        booking_time: this.booking.booking_time
      }

      let newData = {
        ...formValue,

        transport_receive_info_id: this.booking.transport_receive_info_id,
        acc_billing_note_info_id: this.booking.acc_billing_note_info_id,
        acc_billing_note_info_doc_no: this.booking.acc_billing_note_info_doc_no,
        acc_invoice_info_doc_no: this.booking.acc_invoice_info_doc_no,
        acc_invoice_info_grand_total: this.booking.acc_invoice_info_grand_total,
        ref_doc_type: this.booking.ref_doc_type,
        ref_doc_id: this.booking.ref_doc_id,
        created_by: this.booking.created_by,

        transport_masterbooking_id: undefined,
        doc_status_id: "INPROGRESS",
      }
      // console.log(oldData);
      // console.log(newData);

      this.saveDataAndCreateBooking(oldData, newData);
    }else{
      let oldData = {
        ...formValue,
        doc_status_id: formValue.status,
        booking_date: this.booking.booking_date,
        booking_time: this.booking.booking_time
      }

      this.saveData(oldData);
    }
  }

  async saveData(data){
    let bookingResponse = await this.TransportMasterBookingService.update(data);
    if (bookingResponse.success) {
      this.save.emit({
        status: bookingResponse,
        closeModal: this.closeModal
      })
    } else {
      this.save.emit({
        status: bookingResponse,
        closeModal: this.closeModal
      })
    }
  }

  async saveDataAndCreateBooking(data, newData){
    let bookingResponse = await this.TransportMasterBookingService.update(data);
    if (bookingResponse.success) {
      newData.ref_transport_masterbooking_id = bookingResponse.resultData.transport_masterbooking_id;

      let newBookingResponse = await this.TransportMasterBookingService.create(newData);
      if(newBookingResponse.success){
        this.save.emit({
          status: newBookingResponse,
          closeModal: this.closeModal
        })
      }else{
        this.save.emit({
          status: newBookingResponse,
          closeModal: this.closeModal
        })
      }
    } else {
      this.save.emit({
        status: bookingResponse,
        closeModal: this.closeModal
      })
    }
  }

  onClose() {
    this.setForm();
  }
}
