import { ActivatedRoute } from "@angular/router";
import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import {
  CustomerInfoService,
  GETService,
  UserService,
  SalesAssignmentService,
  UtilsService,
  CustomerActivityService,
} from "src/app/services";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Select, Store } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";

import { Observable, Subscription } from "rxjs";
import { CustomerState } from "src/app/store/customer/customer.state";
import { GetCustomer } from "src/app/store/customer/customer.action";

@Component({
  selector: "app-customer-show",
  templateUrl: "./customer-show.component.html",
  styleUrls: ["./customer-show.component.css"],
})
export class CustomerShowComponent implements OnInit {
  isEditSales = false;
  status = null;
  customer = null;
  customer_level_id: string = "NEW";
  unqualifiedForm: FormGroup;
  qualifiedForm: FormGroup;
  submitted = false;
  customerLevels = [];
  @Select(UserInfoState.getUser) userInfo: Observable<any>;
  @Select(CustomerState.getCustomer) customer$: Observable<any>;
  provinces = [];
  sales_assignment_info = null;
  canEditCustomer = false;
  canUnqualified = false;
  tab = "activity-tab";
  salesUserId = null;

  @ViewChild("closeModalBtnQualified") closeModalBtnQualified: ElementRef;
  @ViewChild("closeModalBtnUnqualified") closeModalBtnUnqualified: ElementRef;

  customerSub: Subscription = null;
  userSub: Subscription = null;
  isReady = false;

  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private CustomerInfoService: CustomerInfoService,
    private GETService: GETService,
    private store: Store,
    private UserService: UserService,
    private SalesAssignmentService: SalesAssignmentService,
    private UtilsService: UtilsService,
    private CustomerActivityService: CustomerActivityService
  ) {
    this.setForm();
  }
  setForm() {
    this.unqualifiedForm = this.formBuilder.group({
      revoked_reason: ["", Validators.required],
      revoked_other_reason: [""],
    });
    this.qualifiedForm = this.formBuilder.group({
      interest_level: ["", Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.customerSub) {
      this.customerSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }

    this.customerSub = this.customer$.subscribe(async (customer) => {
      if (customer) {
        this.customer = customer;
        this.customer_level_id = this.customer.customer_level_id;

        let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
        // manager or sales_assignment canEdit
        this.canEditCustomer =
          customer.customer_state_id !== "FREE" &&
          (customer.sales_assignment_info?.user_info?.user_id ===
            userInfo.user_id ||
            userInfo.emp_info?.emp_level_id === "MANAGER" ||
            userInfo.is_co_sales ||
            userInfo.emp_info.emp_department_id.startsWith(
              'ACC_DEP'
            )) ||
            userInfo.is_sales_supervisor ||
            userInfo.is_co_sales_supervisor;
            
        this.canUnqualified = 
          customer.customer_state_id !== "FREE" &&
          (customer.sales_assignment_info?.user_info?.user_id ===
            userInfo.user_id ||
            userInfo.emp_info?.emp_level_id === "MANAGER" ||
            userInfo.is_co_sales_supervisor ||
            (customer.inCharge && userInfo.is_co_sales));

        this.customer.is_see_all =
          customer.customer_state_id === "FREE" ||
          customer.sales_assignment_info?.user_id === userInfo?.user_id ||
          (customer.inCharge && userInfo.is_co_sales) ||
          (userInfo.is_sales_manager) ||
          (customer.inCharge && userInfo.is_sales_supervisor) ||
          (userInfo.is_co_sales_supervisor);

        customer.phonenumbers = [customer.customer_address_info.tel_1, customer.customer_address_info.tel_2]
          .filter((v) => (v ? true : false))
          .join(",");

        if (this.customer.sales_assignment_info?.user_id) {
          let sales_assignment_info_user = await this.UserService.getById({
            user_id: this.customer.sales_assignment_info.user_id,
          });
          this.sales_assignment_info = sales_assignment_info_user.resultData;
        } else {
          this.sales_assignment_info = null;
        }
        if(!this.customer?.is_see_all){
          this.tab = 'address-tab';
        }
        this.isReady = true;
      }
    });

    this.userSub = this.userInfo.subscribe((userInfo) => {
      if (userInfo) {
        let customer_id = this.route.snapshot.paramMap.get("customer_id");
        this.store.dispatch(new GetCustomer({ customer_id }));

        if(userInfo.emp_info?.emp_department_id.startsWith(
          'ACC_DEP'
        )){
          this.tab = 'address-tab';
        }
      }
    });

    this.GETService.customerLevels().then((res) => {
      if (res.success) {
        this.customerLevels = res.resultData || [];
      }
    });

    this.GETService.provinces().then((res) => {
      if (res.success) {
        this.provinces = res.resultData || [];
      }
    });
  }
  submit() {}

  selectRevokedReason(event: string) {
    if (event === "Other") {
      this.unqualifiedForm
        .get("revoked_other_reason")
        .setValidators([Validators.required]);
    } else {
      this.unqualifiedForm.get("revoked_other_reason").setValidators(null);
    }
    this.unqualifiedForm.get("revoked_other_reason").updateValueAndValidity();
  }

  async convert(event: string) {
    this.submitted = true;
    let revoked_reason = undefined;
    let revoked_other_reason = undefined;
    let interesting_level = undefined;
    if (event === "unqualified") {
      if (this.unqualifiedForm.invalid) return;
      revoked_reason = this.unqualifiedForm.value.revoked_reason;
      revoked_other_reason = this.unqualifiedForm.value.revoked_other_reason;
    } else {
      if (this.qualifiedForm.invalid) return;

      await this.CustomerInfoService.update({
        ...this.customer,
        ...this.qualifiedForm.value,
        // customerActivities: {
        //   title: "Test",
        //   description: "",
        //   customer_activity_type_id: "UPDATED_CUSTOMER_INFO",
        // },
      });
    }

    let result = await this.CustomerInfoService.convert({
      customer_id: this.customer.customer_id,
      revoked_reason:
        revoked_reason === "Other" ? revoked_other_reason : revoked_reason,
      event,
      // customerActivities: {
      //   title: "Test",
      //   description: "",
      //   customer_activity_type_id:
      // event === "unqualified"
      //   ? "LEAD_QUALIFICATION"
      //   : "OPPORTUNITY_QUALIFICATION",
      // },
    });
    if (result.success) {
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);

      if (event === "unqualified") {
        let activity_text = await this.UtilsService.customerActivityTitle(
          "LEAD_QUALIFICATION_UNQUALIFIED",
          {
            $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
            $reason:
              revoked_reason === "Other"
                ? revoked_other_reason
                : revoked_reason,
          }
        );
        await this.CustomerActivityService.create({
          customer_id: this.customer.customer_id,
          customer_activity_type_id: "LEAD_QUALIFICATION_UNQUALIFIED",
          title: activity_text,
          activity_datetime: new Date(),
        });

        await new Promise((resolve) => {
          setTimeout(() => {
            resolve(true);
          }, 1000);
        });

        activity_text = await this.UtilsService.customerActivityTitle(
          "REVOKED_ASSIGNMENT",
          {
            $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
          }
        );
        await this.CustomerActivityService.create({
          customer_id: this.customer.customer_id,
          customer_activity_type_id: "REVOKED_ASSIGNMENT",
          title: activity_text,
          activity_datetime: new Date(),
        });
      } else {
        interesting_level = this.qualifiedForm.value.interest_level;

        let activity_text = await this.UtilsService.customerActivityTitle(
          "LEAD_QUALIFICATION_QUALIFIED",
          {
            $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
            $interesting_level: interesting_level,
          }
        );
        await this.CustomerActivityService.create({
          customer_id: this.customer.customer_id,
          customer_activity_type_id: "LEAD_QUALIFICATION_QUALIFIED",
          title: activity_text,
          activity_datetime: new Date(),
        });
      }
      this.tab = "";
      const customer$ = this.store
        .dispatch(new GetCustomer({ customer_id: this.customer.customer_id }))
        .subscribe(() => {
          this.ngOnInit();
          this.tab = "activity-tab";
          customer$.unsubscribe();
        });
    }

    if (event === "unqualified") {
      this.closeModalBtnUnqualified.nativeElement.click();

      setTimeout(() => {
        this.ngOnDestroy();
        this.ngOnInit();
      }, 1000);
    } else {
      this.closeModalBtnQualified.nativeElement.click();
    }
    this.status = result.error || result.success;
    this.submitted = false;
  }

  async setCustomerLevel(event) {
    this.isReady = false;
    let res = await this.CustomerInfoService.update(this.customer);
    this.status = res.error || res.success;

    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);

    await this.CustomerActivityService.create({
      customer_id: this.customer.customer_id,
      customer_activity_type_id: "CHANGE_CUSTOMER_LEVEL",
      title: `${userInfo.firstname_th} ${userInfo.lastname_th} ปรับระดับลูกค้าจาก ${this.customer_level_id} เป็น ${event.target.value}`,
      activity_datetime: new Date(),
    });

    this.customer_level_id = event.target.value;

    setTimeout(() => {
      this.isReady = true;
    }, 100);
  }

  revoke() {}
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }

  ngOnDestroy() {
    if (this.customerSub) {
      this.customerSub.unsubscribe();
    }
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
  }

  setSalesUserId(user_id) {
    if (this.isEditSales) {
      this.salesUserId = user_id;
    }
  }

  resetEditSales() {
    this.isEditSales = false;
    let sales_assignment_info = this.sales_assignment_info;
    this.sales_assignment_info = "";
    setTimeout(() => {
      this.sales_assignment_info = sales_assignment_info;
      this.salesUserId = sales_assignment_info?.user_id;
    });
  }

  async force() {
    if (this.salesUserId === null) return;

    if (this.salesUserId === "") {
      await this.forceRevoke();
      this.salesUserId = null;
    } else {
      if (this.customer.customer_state_id === "FREE") {
        await this.forceAssign(this.salesUserId);
      } else {
        if (
          this.customer.sales_assignment_info &&
          !this.customer.sales_assignment_info.revoked_by
        ) {
          await this.forceRevokeAndAssign(this.salesUserId);
          this.salesUserId = null;
        } else {
          await this.forceAssign(this.salesUserId);
        }
      }
    }
    this.resetEditSales();
  }

  async forceRevoke() {
    // console.log("force revoked");
    await this.CustomerInfoService.update({
      ...this.customer,
      customer_state_id: "FREE",
      // customerActivities: {
      //   title: "ถอนการถือครอง",
      //   customer_activity_type_id: "REVOKED_ASSIGNMENT",
      // },
    });

    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
    if(this.customer?.sales_assignment_info){
      let resSalesAssignment = await this.SalesAssignmentService.update({
        sales_assignment_id: this.customer.sales_assignment_info.sales_assignment_id,
        customer_id: this.customer.customer_id,
        revoked_by: userInfo.user_id,
        user_id: "",
      });
  
      let salesUserInfo = await this.UserService.getById({
        user_id: this.customer.sales_assignment_info.user_id,
      });
      salesUserInfo = salesUserInfo.resultData;
  
      let activity_text = await this.UtilsService.customerActivityTitle(
        "FORCE_REVOKED_ASSIGNMENT",
        {
          $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
          $sales_name: `${salesUserInfo.firstname_th} ${salesUserInfo.lastname_th}`,
        }
      );
      await this.CustomerActivityService.create({
        customer_id: this.customer.customer_id,
        customer_activity_type_id: "FORCE_REVOKED_ASSIGNMENT",
        title: activity_text,
        activity_datetime: new Date(),
      });
  
      this.store.dispatch(
        new GetCustomer({ customer_id: this.customer.customer_id })
      );
      this.isReady = false;
    }else{
      this.isReady = true;
    }
  }

  async forceAssign(user_id) {
    // console.log("force assign");
    let customerInfo = await this.CustomerInfoService.update({
      ...this.customer,
      customer_state_id: "LEAD",
      // customerActivities: {
      //   title: "กำหนดการถือครอง",
      //   customer_activity_type_id: "FORCE_SALES_ASSIGNMENT",
      // },
    });

    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
    let salesUserInfo = await this.UserService.getById({
      user_id,
    });
    salesUserInfo = salesUserInfo.resultData;

    let activity_text = await this.UtilsService.customerActivityTitle(
      "FORCE_SALES_ASSIGNMENT",
      {
        $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
        $sales_name: `${salesUserInfo.firstname_th} ${salesUserInfo.lastname_th}`,
      }
    );
    await this.CustomerActivityService.create({
      customer_id: this.customer.customer_id,
      customer_activity_type_id: "FORCE_SALES_ASSIGNMENT",
      title: activity_text,
      activity_datetime: new Date(),
    });

    let resSalesAssignment = await this.SalesAssignmentService.create({
      customer_id: this.customer.customer_id,
      revoked_by: null,
      user_id
    });

    this.store.dispatch(
      new GetCustomer({ customer_id: this.customer.customer_id })
    );
    this.isReady = false;
  }

  async forceRevokeAndAssign(user_id) {
    // console.log("force revoked and assign");
    if(user_id){
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
      if (this.customer.sales_assignment_info?.sales_assignment_id) {
        let resSalesAssignmentOld = await this.SalesAssignmentService.update({
          sales_assignment_id: this.customer.sales_assignment_info
            .sales_assignment_id,
          customer_id: this.customer.customer_id,
          revoked_by: userInfo.user_id,
        });
  
        // await this.CustomerInfoService.update({
        //   ...this.customer,
        //   customer_state_id: "FREE",
        //   // customerActivities: {
        //   //   title: "ถอนการถือครอง",
        //   //   customer_activity_type_id: "REVOKED_ASSIGNMENT",
        //   // },
        // });
        let salesUserInfo = await this.UserService.getById({
          user_id: this.customer.sales_assignment_info.user_id,
        });
        salesUserInfo = salesUserInfo.resultData;
  
        let activity_text = await this.UtilsService.customerActivityTitle(
          "FORCE_REVOKED_ASSIGNMENT",
          {
            $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
            $sales_name: `${salesUserInfo.firstname_th} ${salesUserInfo.lastname_th}`,
          }
        );
        await this.CustomerActivityService.create({
          customer_id: this.customer.customer_id,
          customer_activity_type_id: "FORCE_REVOKED_ASSIGNMENT",
          title: activity_text,
          activity_datetime: new Date(),
        });
      }
  
      // await this.CustomerInfoService.update({
      //   ...this.customer,
      //   customer_state_id: "LEAD",
      //   // customerActivities: {
      //   //   title: "กำหนดการถือครอง",
      //   //   customer_activity_type_id: "FORCE_SALES_ASSIGNMENT",
      //   // },
      // });
      
      // console.log(user_id)
      let salesUserInfo = await this.UserService.getById({
        user_id,
      });
      salesUserInfo = salesUserInfo.resultData;
  
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(true);
        }, 1000);
      });
  
      let activity_text = await this.UtilsService.customerActivityTitle(
        "FORCE_SALES_ASSIGNMENT",
        {
          $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
          $sales_name: `${salesUserInfo.firstname_th} ${salesUserInfo.lastname_th}`,
        }
      );
      await this.CustomerActivityService.create({
        customer_id: this.customer.customer_id,
        customer_activity_type_id: "FORCE_SALES_ASSIGNMENT",
        title: activity_text,
        activity_datetime: new Date(),
      });
  
      let resSalesAssignment = await this.SalesAssignmentService.create({
        customer_id: this.customer.customer_id,
        revoked_by: null,
        user_id
      });
  
      this.store.dispatch(
        new GetCustomer({ customer_id: this.customer.customer_id })
      );
      this.isReady = false;
    }else{
      this.isReady = true;
    }
  }

  public getCustomerlevel(){
    return this.customerLevels.find(cus=> cus.customer_level_id == this.customer.customer_level_id);
  }
}
