import { Injectable } from "@angular/core";
import { QuotationUtilsService } from '../../../../services/quotations';
import { FormBuilder, FormGroup } from '@angular/forms';

@Injectable({
   providedIn: "root"
})
export class FormMitService {

  constructor(
    public utils: QuotationUtilsService,
    private fbd: FormBuilder
  ) {}

  createDetailItem(data:any, equipment:any): FormGroup {
    data.product_calibration_fee_cp_info.sort(
      (a: any, b: any) => (a.mit_cp_id > b.mit_cp_id ? 1 : -1)
    );
    return this.fbd.group({
      quotation_mit_item_id: this.fbd.control(null),
      service_location: this.fbd.control(this.getServiceLocation(data) || ''),
      mit_cp_id: this.fbd.control(data.product_calibration_fee_cp_info[0]?.mit_cp_id || null),
      mpe: this.fbd.control(''),
      note: this.fbd.control(data.note || null),
      mit_scope_id: this.fbd.control(equipment.mit_scope_id || null),
      mit_equipment_id: this.fbd.control(equipment.mit_equipment_id || null),
      mit_method_id: this.fbd.control(data.mit_method_id || null),
      product_calibration_fee_id:this.fbd.control(data.product_calibration_fee_id || null),
      price_start: this.fbd.control(data.start_fee || 0),
      price_point: this.fbd.control(data.per_point_fee || 0),
      min_point: this.fbd.control(data.min_point || 0),
      total_amount: this.fbd.control(0),
      detail_type: this.fbd.control('point'),
      detail_input_type: this.fbd.control('point'),
      cus_unit_id: this.fbd.control(data.unit_id || null),
      cus_unit_name: this.fbd.control(data.unit_info.unit_short_name_en || data.unit_info.unit_short_name_th || null),
      unit_id: this.fbd.control(data.unit_id || null),
      unit_name: this.fbd.control(data.unit_info.unit_short_name_en || data.unit_info.unit_short_name_th || null),
      isISO: this.fbd.control(data.isISO || null),
      min_range: this.fbd.control(data.min_range || 0),
      max_range: this.fbd.control(data.max_range || 0),
      method_name: this.fbd.control(data.mit_method_info?.method_name || null),
      cp_name: this.fbd.control(data.product_calibration_fee_cp_info[0]?.mit_cp_info?.cp_name || null),
      cpInfo: this.fbd.control(data.product_calibration_fee_cp_info || []),
      quotation_mit_item_detail_points: this.fbd.array([]),
      quotation_mit_item_detail_range: this.fbd.group({
        quotation_mit_item_detail_range_id: this.fbd.control(''),cus_min: this.fbd.control(0),cus_max: this.fbd.control(0),
        cus_unit_id: this.fbd.control(''),cus_unit_name: this.fbd.control(''),mit_min: this.fbd.control(0),
        mit_max: this.fbd.control(0), mit_unit_id: this.fbd.control(''),mit_unit_name: this.fbd.control('')
      }),
      scope_name: this.fbd.control(equipment.mit_scope_info?.scope_name || null),
      equipment_name: this.fbd.control(equipment.equipment_name || null),
      unit_short_name_en: this.fbd.control(data.unit_info.unit_short_name_en || data.unit_info.unit_short_name_th || null),
    }, { 
      validator: [
        this.utils.customValidatePoints(), 
        this.utils.customValidateRangePoints()
      ]
    });
  }

  createDetailItemEdit(data:any, dataInit: any): FormGroup {
    const product_calibration_fee_info = dataInit.product_calibration_fee_info.find((item: any)=>
      item.product_calibration_fee_id === data.product_calibration_fee_id
    );
    product_calibration_fee_info.product_calibration_fee_cp_info.sort(
      (a: any, b: any) => (a.mit_cp_id > b.mit_cp_id ? 1 : -1)
    );
    return this.fbd.group({
      quotation_mit_item_detail_id:  this.fbd.control(data.quotation_mit_item_detail_id || null),
      quotation_mit_item_id: this.fbd.control(null),
      service_location: this.fbd.control(data.service_location.toUpperCase() || ''),
      mit_cp_id: this.fbd.control(data.mit_cp_id || null),
      mpe: this.fbd.control(data.mpe),
      note: this.fbd.control(data.note || null),
      mit_scope_id: this.fbd.control(data.mit_scope_id || null),
      mit_equipment_id: this.fbd.control(data.mit_equipment_id || null),
      mit_method_id: this.fbd.control(data.mit_method_id || null),
      product_calibration_fee_id:this.fbd.control(data.product_calibration_fee_id || null),
      price_start: this.fbd.control(data.price_start || null),
      price_point: this.fbd.control(data.price_point || 0),
      min_point: this.fbd.control(data.min_point || 0),
      total_amount: this.fbd.control(data.total_amount),
      quotation_mit_item_detail_points: this.fbd.array([]),
      detail_type: this.fbd.control(data.detail_type || 'point'),
      detail_input_type: this.fbd.control(data.detail_input_type || 'point'),
      cus_unit_id: this.fbd.control(data.cus_unit_id || null),
      cus_unit_name: this.fbd.control(data.unit_name || null),
      unit_id: this.fbd.control(data.unit_id || null),
      unit_name: this.fbd.control(data.unit_name || null),
      isISO: this.fbd.control(data.isISO || null),
      min_range: this.fbd.control(data.min_range || 0),
      max_range: this.fbd.control(data.max_range || 0),
      method_name: this.fbd.control(data.method_name || null),
      cp_name: this.fbd.control(data.cp_name || null),
      cpInfo: this.fbd.control(product_calibration_fee_info?.product_calibration_fee_cp_info || []),
      quotation_mit_item_detail_range: this.fbd.group({
        quotation_mit_item_detail_range_id: this.fbd.control(
          data.quotation_mit_item_detail_range?.quotation_mit_item_detail_range_id || ''
        ),
        cus_min: this.fbd.control(this.utils.transformDecimal(data.quotation_mit_item_detail_range?.cus_min || 0, '1.1-5')),
        cus_max: this.fbd.control(this.utils.transformDecimal(data.quotation_mit_item_detail_range?.cus_max || 0, '1.1-5')),
        cus_unit_id: this.fbd.control(data.quotation_mit_item_detail_range?.cus_unit_id || ''),
        cus_unit_name: this.fbd.control(data.quotation_mit_item_detail_range?.cus_unit_name || ''),
        mit_min: this.fbd.control(this.utils.transformDecimal(data.quotation_mit_item_detail_range?.mit_min || 0, '1.1-5')),
        mit_max: this.fbd.control(this.utils.transformDecimal(data.quotation_mit_item_detail_range?.mit_max || 0, '1.1-5')),
        mit_unit_id: this.fbd.control(data.quotation_mit_item_detail_range?.mit_unit_id || ''),
        mit_unit_name: this.fbd.control(data.quotation_mit_item_detail_range?.mit_unit_name || '')
      }),
      scope_name: this.fbd.control(data?.scope_name || null),
      equipment_name: this.fbd.control(data?.equipment_name || null),
      unit_short_name_en: this.fbd.control(data.unit_short_name_en || null),
    }, {
      validator: [
        this.utils.customValidatePoints(), 
        this.utils.customValidateRangePoints()
      ]
    });
  }

  createDetailItemForExcel(data: any, equipment: any, form: any) {
    data.product_calibration_fee_cp_info.sort(
      (a: any, b: any) => (a.mit_cp_id > b.mit_cp_id ? 1 : -1)
    );

    return {
      quotation_mit_item_id: null,
      service_location: this.getServiceLocation(data) || '',
      mit_cp_id: data.product_calibration_fee_cp_info[0]?.mit_cp_id || null,
      mpe: form.mpe || '',
      note: form.note || '',
      mit_scope_id: equipment.mit_scope_id || null,
      mit_equipment_id: equipment.mit_equipment_id || null,
      mit_method_id: data.mit_method_id || null,
      product_calibration_fee_id:data.product_calibration_fee_id || null,
      price_start: data.start_fee || 0,
      price_point: data.per_point_fee || 0,
      min_point: data.min_point || 0,
      total_amount: 0,
      detail_type: 'point',
      detail_input_type: 'point',
      cus_unit_id: data.unit_id || null,
      cus_unit_name: data.unit_info.unit_short_name_en || data.unit_info.unit_short_name_th || null,
      unit_id: data.unit_id || null,
      unit_name: data.unit_info.unit_short_name_en || data.unit_info.unit_short_name_th || null,
      isISO: data.isISO || null,
      min_range: data.min_range || 0,
      max_range: data.max_range || 0,
      method_name:data.mit_method_info?.method_name || null,
      cp_name: data.product_calibration_fee_cp_info[0]?.mit_cp_info?.cp_name || null,
      cpInfo: data.product_calibration_fee_cp_info || [],
      quotation_mit_item_detail_points: [],
      quotation_mit_item_detail_range: {
        quotation_mit_item_detail_range_id: '',cus_min: 0,cus_max: 0,cus_unit_id: '',cus_unit_name: '',mit_min: 0,mit_max: 0,
        mit_unit_id: '', mit_unit_name: ''
      },
      scope_name: equipment.mit_scope_info?.scope_name || null,
      equipment_name: equipment.equipment_name || null,
      unit_short_name_en: data.unit_info.unit_short_name_en || null,
    };
  }

  createPointItem(data:any): FormGroup {
    return this.fbd.group({
      quotation_mit_item_detail_point_id: this.fbd.control(data.quotation_mit_item_detail_point_id || null),
      cus_point: this.fbd.control(data.cus_point || 0),
      cus_unit_id: this.fbd.control(data.cus_unit_id || null),
      cus_unit_name: this.fbd.control(data.cus_unit_name || null),
      mit_point: this.fbd.control(data.mit_point || 0),
      mit_unit_id: this.fbd.control(data.mit_unit_id || null),
      mit_unit_name: this.fbd.control(data.mit_unit_name || null)
    });
  }

  createAttributeItem(data:any): FormGroup {
    return this.fbd.group({
      quotation_mit_item_attribute_id: this.fbd.control(data.quotation_mit_item_attribute_id || null),
      attribute_name: this.fbd.control(data.attribute_name || null),
      attribute_value: this.fbd.control(data.attribute_value || null)
    });
  }
  
  public getServiceLocation(product: any): string{
    if (product.service_location.includes('ONSITE') && product.service_location.includes('LAB')){
      return 'LAB';
    }else if(product.service_location.includes('ONSITE')){
      return 'ONSITE';
    }else{
      return 'LAB';
    }
  }
}