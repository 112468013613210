import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class SalesTeamMembersService {
  async create({ sales_team_id, user_id }) {
    let result = null;
    try {
      result = await Axios().post(`/sales-team-members`, {
        sales_team_id,
        user_id,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  // async update({ sales_team_id, team_leader_id }) {
  //   let result = null;
  //   try {
  //     result = await Axios().put(`/sales-team-members/${sales_team_id}`, {
  //       sales_team_id,
  //       team_leader_id,
  //     });
  //     result = result.data;
  //   } catch (error) {
  //     result = error.response.data;
  //   }
  //   return result;
  // }
  async delete({ member_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/sales-team-members/${member_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/sales-team-members`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ sales_team_member_id }) {
    let result = null;
    try {
      result = await Axios().get(`/sales-team-members/${sales_team_member_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
