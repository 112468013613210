<loading *ngIf="!pageLoad"></loading>
<div class="card" *ngIf="pageLoad">
    <div class="card-content collapse show">
        <div class="card-body">

            <div class="d-flex justify-content-between mb-1">
                <div>
                    <button
                    *ngIf="pageType != 'show' && docStatusId !='REVISE'"
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#add-mit-item"
                    (click)="editType ='qt'"
                    >
                    <i class="fa fa-plus icon-left"></i> เพิ่มรายการ
                </button>

                <button
                    *ngIf="pageType != 'show' && docStatusId !='REVISE'"
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#add-mit-import-item"
                    >
                    <i class="fa fa-arrow-up icon-left"></i> นำเข้าไฟล์
                </button>

                <button
                    *ngIf="pageType != 'show'&& docStatusId !='REVISE' && !isCopy"
                    type="button"
                    data-toggle="modal"
                    data-target="#add-mit-item"
                    class="btn btn-primary mr-1"
                    (click)="editType ='noqt'"
                    >
                    <i class="fa fa-plus icon-left"></i> เพื่มจากรายการรับเครื่องมือ
                </button>

                <button
                    *ngIf="pageType != 'show' && customer_id"
                    type="button"
                    data-toggle="modal"
                    data-target="#add-mit-item-job-order"
                    class="btn btn-primary"
                    >
                    <i class="fa fa-plus icon-left"></i> เพื่มจากใบสั่งงาน
                </button>
                </div>
                <h3>ทั้งหมด {{ quotationMitItemGroups.length }} รายการ</h3>
            </div>

            <div class="table-responsive">
                <table class="table table-bordered" cdkDropList (cdkDropListDropped)="drop($event)">
                    <thead> 
                        <tr>
                            <th class="fixed-column">
                            </th>
                            <th class="fixed-column">ลำดับ</th>
                            <th class="fixed-column">
                                <span>Description </span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th>
                                <span>Scope </span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th>
                                <span>Equipment </span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th class="text-left">Method</th>
                            <th>Service Location</th>
                          
                            <th>Calibration Point by customer</th>
                            <th>
                                <span>Calibration Point</span>
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-2">&nbsp;</span>
                            </th>
                            <th>MPE (&plusmn;)</th>
                            <th>
                                <span>Note</span> 
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th class="text-center">
                                <span class="pr-2">&nbsp;</span>
                                <span>CP </span>
                                <span class="pr-2">&nbsp;</span>
                            </th>
                            <th>
                                <span>Attributes</span> 
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th class="text-right">Price</th>
                            <th class="text-right">
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-5">&nbsp;</span>
                                ราคาขาย
                            </th>
                            <th class="text-right">
                                <span class="pr-5">&nbsp;</span>
                                ส่วนลด(%)
                            </th>
                            <th class="text-right">รวมเงิน</th>
                            <th *ngIf="pageType != 'show'">
                                action
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-5">&nbsp;</span>
                                <span class="pr-5">&nbsp;</span>
                            </th>
                            <th  *ngIf="pageType != 'create'">
                                ดูประวัติ
                            </th>
                        </tr>
                    </thead>
                    <tbody *ngFor="let item of quotationMitItemGroups; let i = index;" cdkDrag>
                        <tr>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="pt-1 text-center fixed-column">
                                <a style="color: #ccc;" [hidden]="pageType == 'show'" cdkDragHandle><i class="fa fa-ellipsis-v"></i></a>
                              </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="pt-1 text-center fixed-column">
                                <span class="text-bold-600">
                                    {{i+ 1}}
                                </span>
                                <div *ngIf="isLock(item) && docStatusId == 'REVISE'">
                                    <i class="fa fa-lock fa-lg primary" aria-hidden="true"></i>
                                </div>
                            </td>
                            <div *cdkDragPreview>
                                <div class="card px-5 py-2">
                                    <div>
                                      <b>Scope:</b> {{item.scope_name}}
                                    </div>
                                    <div>
                                      <b>Equipment:</b> {{item.equipment_name}}
                                    </div>
                                    <div>
                                      <b>ราคา:</b> {{item.original_price | number : '1.2-2'}}
                                    </div>
                                </div>
                            </div>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="fixed-column">
                                <h5 class="text-bold-600">{{item.description || item.equipment_name}}</h5>
                                <p>Maker : {{item.marker || '-'}}</p>
                                <p>Model : {{item.model || '-'}}</p>
                                <p>Serial No : {{item.serial_no || '-'}}</p>
                                <p>Tag No : {{item.tag_no || '-'}}</p>
                                <p>Due (Month): {{item.due_month || '-'}}</p>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1">
                                {{item.scope_name}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1">
                                {{item.equipment_name}}
                            </td>
                            <td>
                                {{item.quotation_mit_item_details[0]?.method_name || ''}}
                            </td>
                            <td class="text-center">
                                {{item.quotation_mit_item_details[0]?.service_location.toUpperCase() || ''}}
                            </td>
                 
                            <td>
                                <ng-container [ngSwitch]="true">
                                    <ng-container *ngSwitchCase="item?.cus_calibration_point != null && item?.cus_calibration_point != ''">
                                        {{item?.cus_calibration_point}}
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <div *ngIf="item.quotation_mit_item_details[0]?.detail_type == 'point'">
                                            <span  class="pb-2" *ngFor="let point of item.quotation_mit_item_details[0]?.quotation_mit_item_detail_points; let pointIndex = index;">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline" *ngIf="point.is_non_calculate =='false' || !point?.is_non_calculate">
                                                    {{point.cus_point || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="point.cus_unit_name"></span>
                                                </span>
                                            </span>
                                        </div>
                                        <div *ngIf="item.quotation_mit_item_details[0]?.detail_type == 'range'">
                                            <span  class="pb-2">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                                    {{utils.convertStingToNumber(item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.cus_min) || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                                </span>
                                            </span>
                                            - &nbsp;
                                            <span  class="pb-2">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                                    {{utils.convertStingToNumber(item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.cus_max) || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                                </span>
                                            </span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td>
                                <div *ngIf="item.quotation_mit_item_details[0]?.detail_type == 'point'">
                                    <span  class="pb-2" *ngFor="let point of item.quotation_mit_item_details[0]?.quotation_mit_item_detail_points; let pointIndex = index;">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline" *ngIf="point.is_non_calculate =='false' || !point?.is_non_calculate">
                                            {{point.mit_point || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="point.mit_unit_name"></span>
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="item.quotation_mit_item_details[0]?.detail_type == 'range'">
                                    <span  class="pb-2">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                            {{utils.convertStingToNumber(item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.mit_min) || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.mit_unit_name"></span>
                                        </span>
                                    </span>
                                     - &nbsp;
                                    <span  class="pb-2">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                            {{utils.convertStingToNumber(item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.mit_max) || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="item.quotation_mit_item_details[0]?.quotation_mit_item_detail_range.mit_unit_name"></span>
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <span *ngIf="item.quotation_mit_item_details.length != 0">
                                    {{item.quotation_mit_item_details[0]?.mpe}}
                                </span>
                            </td>
                            <td>{{item.quotation_mit_item_details[0]?.note || ''}}</td>
                            <td class="text-center" [attr.rowspan]="item.quotation_mit_item_details.length + 1">
                                {{item?.cp_list_init || ''}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1">
                                <div *ngFor="let attribute of item.quotation_mit_item_attributes; let j = index;">
                                    <p *ngIf="attribute.attribute_name && (attribute.is_non_calculate =='false' || !attribute?.is_non_calculate)">&#9679; {{attribute.attribute_name}} = {{attribute.attribute_value | number : '1.0-2'}}</p>
                                </div>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="text-right">
                                {{item.original_price | number : '1.2-2'}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="text-right">
                                <div>
                                    <input type="text"
                                    placeholder="0.00"
                                    class="form-control text-right"
                                    (click)="$event.target.select()"
                                    (change)="onKeyup('price', $event.target.value, item.index)"
                                    (keyup)="onKeyup('price', $event.target.value, item.index)"
                                    oninput="validity.valid||(value='');"
                                    [readonly]="pageType == 'show' || !isLock(item)"
                                    [value]="item.price">
                                </div>
                                <div class="text-danger text-right" 
                                  *ngIf="utils.convertStingToNumber(item.original_price) != utils.convertStingToNumber(item.price)">
                                    ราคาตั้งต้น: {{item.original_price | number : '1.2-2'}}
                                </div>
                                <div class="text-danger text-right"  *ngIf="item.method_name == 'Supplier' && item.lock_invoice_at"> 
                                    ต้นทุนการให้บริการ {{item.cost_of_sales | number : '1.2-2'}} บาท
                                </div>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="text-right">
                                <input type="text"
                                placeholder="0.00"
                                class="form-control text-right"
                                (click)="$event.target.select()"
                                (change)="onKeyup('discount', $event.target.value, item.index)"
                                (keyup)="onKeyup('discount', $event.target.value, item.index)"
                                oninput="validity.valid||(value='');"
                                [readonly]="pageType == 'show' || !isLock(item)"
                                [value]="item.discount">
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="text-right">
                                {{item.grand_total | number : '1.2-2'}}
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" *ngIf="pageType != 'show'">
                                <button
                                *ngIf="docStatusId !='REVISE'"
                                [hidden]="item?.can_editable == 'false'"
                                type="button"
                                class="btn mr-1 mb-1 btn-warning btn-sm btn-edit"
                                data-toggle="modal"
                                data-target="#edit-mit-item"
                                (click)="select(item.index)"
                                > <i class="fa fa-pencil icon-left"></i> แก้ไข
                                </button>
                                <button 
                                *ngIf="docStatusId !='REVISE'"
                                [hidden]="item?.can_editable == 'false'"
                                type="button"
                                (click)="copy(item.index)"
                                class="btn mr-1 mb-1 btn-light btn-sm">
                                <i class="fa fa-copy icon-left"></i> คัดลอก
                                </button>
                                <button
                                    (click)="mapping(item)"
                                    *ngIf="canMapping(item)"
                                    type="button"
                                    class="btn mr-1 mb-1 btn-primary btn-sm btn-edit text-nowrap"
                                ><i class="fa fa-plus icon-left"></i> Mapping จากรายการเพิ่ม WR
                                </button>
                                <button
                                type="button"
                                *ngIf="docStatusId !='REVISE' || (docStatusId =='REVISE' && canDelete(item))"
                                class="btn mr-1 mb-1 btn-danger btn-sm"
                                data-target="#modalConfirmDelMitItem"
                                data-toggle="modal"
                                (click)="select(item.index)"
                                > <i class="fa fa-trash icon-left"></i> ลบ
                                </button>
                                <div *ngIf="item?.isMapping" class="text-success">
                                    Mapping แล้ว
                                </div>
                            </td>
                            <td [attr.rowspan]="item.quotation_mit_item_details.length + 1" class="text-nowrap" *ngIf="pageType != 'create'">
                                <button
                                type="button"
                                class="btn mr-1 mb-1 btn-primary btn-sm btn-edit"
                                data-toggle="modal"
                                data-target="#mit-item-log"
                                (click)="select(item.index)"
                                > <i class="fa fa-history icon-left"></i> ดูประวัติ
                                </button>
                            </td>
                        </tr>
                        <tr *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                            <td *ngIf="detailIndex != 0">{{detail.method_name}}</td>
                            <td *ngIf="detailIndex != 0" class="text-center">
                                {{detail.service_location.toUpperCase()}}
                            </td>
                            <td *ngIf="detailIndex != 0">
                                <ng-container [ngSwitch]="true">
                                    <ng-container *ngSwitchCase="item?.cus_calibration_point != null && item?.cus_calibration_point != ''">
                                        {{item?.cus_calibration_point}}
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <div *ngIf="detail.detail_type == 'point'">
                                            <span class="pb-2" *ngFor="let point of detail.quotation_mit_item_detail_points; let pointIndex = index;">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline" *ngIf="point.is_non_calculate =='false' || !point?.is_non_calculate">
                                                    {{point.cus_point || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="point.cus_unit_name"></span>
                                                </span>
                                            </span>
                                        </div>
                                        <div *ngIf="detail.detail_type == 'range'">
                                            <span  class="pb-2">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                                    {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_min) || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="detail?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                                </span>
                                            </span>
                                            - &nbsp;
                                            <span  class="pb-2">
                                                <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                                    {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_max) || 0 | number : '1.1-5'}} 
                                                    <span [innerHTML]="detail?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                                </span>
                                            </span>
                                        </div>
                                    </ng-container>
                                </ng-container>

                            </td>
                            <td *ngIf="detailIndex != 0">
                                <div *ngIf="detail.detail_type == 'point'">
                                    <span class="pb-2" *ngFor="let point of detail.quotation_mit_item_detail_points; let pointIndex = index;">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline" *ngIf="point.is_non_calculate =='false' || !point?.is_non_calculate">
                                            {{point.mit_point || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="point.mit_unit_name"></span>
                                        </span>
                                    </span>
                                </div>
                                <div *ngIf="detail.detail_type == 'range'">
                                    <span  class="pb-2">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                            {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.mit_min) || 0 | number : '1.1-5'}} 
                                            <span [innerHTML]="detail?.quotation_mit_item_detail_range.mit_unit_name"></span>
                                        </span>
                                    </span>
                                    - &nbsp;
                                    <span  class="pb-2">
                                        <span class="badge text-primary border-primary bg-transparent mr-50 mb-50 custom-inline">
                                            {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.mit_max) || 0 | number : '1.1-5'}}
                                            <span [innerHTML]="detail?.quotation_mit_item_detail_range.mit_unit_name"></span>
                                        </span>
                                    </span>
                                </div>
                            </td>
                            <td *ngIf="detailIndex != 0">{{detail.mpe}}</td>
                            <td *ngIf="detailIndex != 0">{{detail.note || ''}}</td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="quotationMitItemGroups.length === 0">
                        <tr>
                            <td [attr.colspan]="20" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-2 text-right">
                <h3>รวมทั้งสิ้น {{ totalPrice | number : '1.2-2' }} บาท</h3>
            </div>
        </div>
    </div>
</div>

<app-quotation-item-import-create
*ngIf="dropDownData" 
(done)="onImportMitItem($event)"
[dropDownData]="dropDownData">
</app-quotation-item-import-create>

<app-quotation-item-create 
*ngIf="dropDownData && editType" 
[type]="editType"
[quotationMitItemGroups]="quotationMitItemGroups"
[customer_id]="customer_id"
(done)="onCreateMitItem($event)"
[dropDownData]="dropDownData"
>
</app-quotation-item-create>

<app-quotation-item-edit 
[mitItem]="mitItem" 
*ngIf="mitItem && dropDownData" 
[customer_id]="customer_id"
(done)="onEditMitItem($event)"
[dropDownData]="dropDownData"
[index]="mitItemIndex"
>
</app-quotation-item-edit>

<app-quotation-item-job-order-create
*ngIf="dropDownData" 
[customer_id]="customer_id"
[quotationMitItems]="quotationMitItems"
(done)="onCreateJobOrder($event)"
[dropDownData]="dropDownData"
>
</app-quotation-item-job-order-create>

<app-confirm-delete 
[id]="'modalConfirmDelMitItem'" 
(save)="deleteMitItem($event)">
</app-confirm-delete>

<app-mit-item-log 
*ngIf="mitItem"
(done)="resetMitItem($event)"
[mitItem]="mitItem">
</app-mit-item-log>

<app-quotation-item-mapping 
*ngIf="mappingId"
[quotationMitItems]="quotationMitItems"
 (save)="replaceMapping($event)">
</app-quotation-item-mapping>