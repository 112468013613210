<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการใบสั่งงาน</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/work-order']">ใบสั่งงาน </a>
                </li>
                <li class="breadcrumb-item active">รายการใบสั่งงาน</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right"  *ngIf="(
            (userInfo$ | async)?.is_sales_manager || 
                              (userInfo$ | async)?.is_co_sales || 
                              (userInfo$ | async)?.is_sales
          )">
            <button class="btn btn-outline-primary"                     
                data-target="#work-order-filter"
                data-toggle="modal">
              <i class="feather icon-plus-circle icon-left"></i> สร้างใบสั่งงาน
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <h4 class="card-title mt-1 col-12 col-sm-4 col-md-6 col-lg-9">รายการใบสั่งงาน</h4>
                  <span class="col-12 col-sm-8 col-md-6 col-lg-3">
                    <search-box (search)="search($event)" [placeholder]="'ค้นหาเลขที่เอกสาร/รหัส/ชื่อลูกค้า'" [search_all]="params?.search_all"></search-box>
                  </span>
                </div>
              </div>
  
              <div class="card-content collapse show">
                <div class="card-body">
                    <div class="row justify-content-between">
                        <div class="col-12 col-sm-12 col-md-12 col-lg-9 align-self-end">
                          <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                            <li class="nav-item border-primary mr-50 mt-1">
                                <a class="nav-link py-0" [class.active] = "!params?.doc_status_id" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchStatus()">ทั้งหมด</a>
                            </li>
                            <li class="nav-item border-primary mr-50 mt-1" [class.active] = "params?.doc_status_id=='DRAFT'" *ngIf="((userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales || (userInfo$ | async)?.is_sales || (userInfo$ | async)?.is_acc_dep)">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='DRAFT'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchStatus('DRAFT')">ร่าง ({{number_draft_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50 mt-1" *ngIf="!(userInfo$ | async)?.is_tran_dep">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WAITING_FOR_CONFIRM'" id="pill3" data-toggle="pill" href="#pill3" aria-expanded="false" (click)="searchStatus('WAITING_FOR_CONFIRM')">รอการยืนยัน ({{number_waiting_for_confirm_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50 mt-1"  *ngIf="!(userInfo$ | async)?.is_tran_dep">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='REJECTED'" id="pill4" data-toggle="pill" href="#pill4" aria-expanded="false" (click)="searchStatus('REJECTED')">ไม่สามารถให้บริการ ({{number_rejected_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50 mt-1">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='CONFIRMED'" id="pill7" data-toggle="pill" href="#pill7" aria-expanded="false" (click)="searchStatus('CONFIRMED')">สามารถให้บริการ ({{number_confirmed_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50 mt-1" 
                            *ngIf="((userInfo$ | async)?.is_sales_manager || 
                              (userInfo$ | async)?.is_co_sales || 
                              (userInfo$ | async)?.is_sales || 
                              (userInfo$ | async)?.is_calib_dep || 
                              (userInfo$ | async)?.is_tran_dep || 
                              (userInfo$ | async)?.is_acc_dep)">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WORK_DONE'" id="pill19" data-toggle="pill" href="#pill19" aria-expanded="false" (click)="searchStatus('WORK_DONE')">บันทึกผลแล้ว ({{number_work_done_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50 mt-1"  *ngIf="((userInfo$ | async)?.is_sales_manager || 
                              (userInfo$ | async)?.is_co_sales || 
                              (userInfo$ | async)?.is_sales || 
                              (userInfo$ | async)?.is_calib_dep || 
                              (userInfo$ | async)?.is_tran_dep || 
                              (userInfo$ | async)?.is_acc_dep)">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WORK_INCOMPLETE'" id="pill20" data-toggle="pill" href="#pill20" aria-expanded="false" (click)="searchStatus('WORK_INCOMPLETE')">การบันทึกผลไม่สมบูรณ์	({{number_work_incomplate_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50 mt-1"  *ngIf="((userInfo$ | async)?.is_sales_manager || 
                              (userInfo$ | async)?.is_co_sales || 
                              (userInfo$ | async)?.is_sales || 
                              (userInfo$ | async)?.is_calib_dep || 
                              (userInfo$ | async)?.is_tran_dep || 
                              (userInfo$ | async)?.is_acc_dep)">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='REJECTED_VERIFY_CRF'" id="pill20" data-toggle="pill" href="#pill20" aria-expanded="false" (click)="searchStatus('REJECTED_VERIFY_CRF')">ไม่อนุมัติใบรายงานผล ({{number_rejected_verify_crf_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50 mt-1"  *ngIf="((userInfo$ | async)?.is_sales_manager || 
                              (userInfo$ | async)?.is_co_sales || 
                              (userInfo$ | async)?.is_sales || 
                              (userInfo$ | async)?.is_calib_dep || 
                              (userInfo$ | async)?.is_tran_dep || 
                              (userInfo$ | async)?.is_acc_dep)">
                                <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WAITING_VERIFY_CRF'" id="pill5" data-toggle="pill" href="#pill5" aria-expanded="false" (click)="searchStatus('WAITING_VERIFY_CRF')">รออนุมัติใบรายงานผล ({{number_waiting_verify_status}})</a>
                            </li>
                            <li class="nav-item border-primary mr-50 mt-1"  *ngIf="((userInfo$ | async)?.is_sales_manager || 
                              (userInfo$ | async)?.is_co_sales || 
                              (userInfo$ | async)?.is_sales || 
                              (userInfo$ | async)?.is_calib_dep || 
                              (userInfo$ | async)?.is_tran_dep || 
                              (userInfo$ | async)?.is_acc_dep)">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='CRF_COMPLETED'" id="pill5" data-toggle="pill" href="#pill5" aria-expanded="false" (click)="searchStatus('CRF_COMPLETED')">ใบรายงานผลผ่านเรียบร้อย ({{number_crf_completed_status}})</a>
                          </li>
                            <li class="nav-item border-primary mr-50 mt-1">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='PREPARE_FOR_SHIP'" id="pill66" data-toggle="pill" href="#pill66" aria-expanded="false" (click)="searchStatus('PREPARE_FOR_SHIP')">ดำเนินการจัดส่ง ({{number_prepare_for_ship_status}})</a>
                             </li>
                            <li class="nav-item border-primary mr-50 mt-1">
                              <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='RETURNED_SUCCESS'" id="pill10" data-toggle="pill" href="#pill10" aria-expanded="false" (click)="searchStatus('RETURNED_SUCCESS')">เสร็จสิ้น ({{number_return_status}})</a>
                           </li>
                            <li class="nav-item border-light mt-1"  *ngIf="!(userInfo$ | async)?.is_tran_dep">
                                <a class="nav-link text-light py-0" [class.active] = "params?.doc_status_id=='CANCELED'" id="pill8" data-toggle="pill" href="#pill8" aria-expanded="false" (click)="searchStatus('CANCELED')" >ยกเลิก ({{number_canceled_status}})</a>
                            </li>
                          </ul>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                          <fieldset class="form-group">
                            <ng-select2
                            [data]="templates"
                            placeholder="TEMPLATE : ทั้งหมด"
                            [(ngModel)]="template"
                            [ngModelOptions]="{ standalone: true }"
                            [value]="params?.job_order_type"
                            (valueChanged)="filterByJobOrderType($event)"
                            [allowClear]="true"
                            >
                          </ng-select2>
                          </fieldset>
                        </div>
                    </div>
                    <div class="table-responsive"  style="min-height: 150px">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                  <th class="text-center">เลขที่ใบสั่งงาน</th>
                                  <th>ชื่อลูกค้า 
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                  </th>
                                  <th>วันที่สร้าง</th>
                                  <th>
                                    <span class="pr-1">วันที่รับงาน</span>
                                    <br>
                                    <div class="dropdownmenu">
            
                                      <button (click)="btnTogglePickupDate()" class="btn btn-primary btn-sm">
                                        วันที่รับงาน <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                      </button>
                                        
                                      <div id="pickup_date_filter" class="dropdownmenu-content rounded-right rounded-bottom">
                                        <form class="px-2 pt-1" [formGroup]="form">
                                          <div class="row">
                                            <div class="col-8">
                                              <label for="pickup_date">วันที่รับงาน</label>
                                            </div>
                                            <div class="col-4 text-right">
                                              <i class="fa fa-times pointer text-secondary" (click)="btnTogglePickupDate()"></i>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <div class="input-group ">
                                              <input
                                                  date-range-picker
                                                  type="text"
                                                  [singleDatePicker]="true"
                                                  [timePicker]="false"
                                                  [date]="form.value?.pickup_date"
                                                  [format]="'DD-MM-YYYY'"
                                                  placeholder="วันที่รับงาน"
                                                  id="pickup_date"
                                                  #pickup_date
                                                  class="form-control"
                                                  formControlName="pickup_date"
                                              />
                                              <div class="input-group-append pointer" (click)="pickup_date.click()">
                                                <span class="input-group-text rounded-0 btn-primary text-white"
                                                ><i class="feather icon-calendar"></i
                                                ></span>
                                              </div>
                                              <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                              ><i class="fa fa-times"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </form>
                                        
                                      </div>
                                    </div>
                                  </th>
                                  <th> 
                                    <span class="pr-1">วันกำหนดส่งคืน</span>
                                    <br>
                                    <div class="dropdownmenu">
            
                                      <button (click)="btnToggleReturnDate()" class="btn btn-primary btn-sm">
                                          กำหนดส่งคืน <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                      </button>
                                        
                                      <div id="return_date_filter" class="dropdownmenu-content rounded-right rounded-bottom">
                                        <form class="px-2 pt-1" [formGroup]="form">
                                          <div class="row">
                                            <div class="col-8">
                                              <label for="return_date">วันกำหนดส่งคืน</label>
                                            </div>
                                            <div class="col-4 text-right">
                                              <i class="fa fa-times pointer text-secondary" (click)="btnToggleReturnDate()"></i>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <div class="input-group ">
                                              <input
                                                  date-range-picker
                                                  type="text"
                                                  [singleDatePicker]="true"
                                                  [timePicker]="false"
                                                  [date]="form.value?.return_date"
                                                  [format]="'DD-MM-YYYY'"
                                                  placeholder="วันกำหนดส่งคืน"
                                                  id="return_date"
                                                  #return_date
                                                  class="form-control"
                                                  formControlName="return_date"
                                              />
                                              <div class="input-group-append pointer" (click)="return_date.click()">
                                                <span class="input-group-text rounded-0 btn-primary text-white"
                                                ><i class="feather icon-calendar"></i
                                                ></span>
                                              </div>
                                              <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                              ><i class="fa fa-times"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </form>
                                        
                                      </div>
                                    </div>
                                  </th>
                                  <th>
                                    <span class="pr-1">วันที่คืนเครื่อง</span>
                                    <br>
                                    <div class="dropdownmenu">
            
                                      <button (click)="btnToggleRealReturnDate()" class="btn btn-primary btn-sm">
                                        วันที่คืนเครื่อง <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                      </button>
                                        
                                      <div id="real_return_date_filter" class="dropdownmenu-content rounded-right rounded-bottom">
                                        <form class="px-2 pt-1" [formGroup]="form">
                                          <div class="row">
                                            <div class="col-8">
                                              <label for="real_return_date">วันที่คืนเครื่อง</label>
                                            </div>
                                            <div class="col-4 text-right">
                                              <i class="fa fa-times pointer text-secondary" (click)="btnToggleRealReturnDate()"></i>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <div class="input-group ">
                                              <input
                                                  date-range-picker
                                                  type="text"
                                                  [singleDatePicker]="true"
                                                  [timePicker]="false"
                                                  [date]="form.value?.real_return_date"
                                                  [format]="'DD-MM-YYYY'"
                                                  placeholder="วันที่คืนเครื่อง"
                                                  id="real_return_date"
                                                  #real_return_date
                                                  class="form-control"
                                                  formControlName="real_return_date"
                                              />
                                              <div class="input-group-append pointer" (click)="real_return_date.click()">
                                                <span class="input-group-text rounded-0 btn-primary text-white"
                                                ><i class="feather icon-calendar"></i
                                                ></span>
                                              </div>
                                              <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                              ><i class="fa fa-times"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </form>
                                        
                                      </div>
                                    </div>
                                  </th>
                                  <th>
                                  
                                    <span class="pr-1">วันที่ส่ง Cert</span>
                                    <br>
                                    <div class="dropdownmenu">
            
                                      <button (click)="btnToggleRealCertDate()" class="btn btn-primary btn-sm">
                                        วันที่ส่ง Cert <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                      </button>
                                        
                                      <div id="real_cert_return_date_filter" class="dropdownmenu-content rounded-right rounded-bottom">
                                        <form class="px-2 pt-1" [formGroup]="form">
                                          <div class="row">
                                            <div class="col-8">
                                              <label for="real_cert_return_date">วันที่ส่ง Cert</label>
                                            </div>
                                            <div class="col-4 text-right">
                                              <i class="fa fa-times pointer text-secondary" (click)="btnToggleRealCertDate()"></i>
                                            </div>
                                          </div>
                                          <div class="form-group">
                                            <div class="input-group ">
                                              <input
                                                  date-range-picker
                                                  type="text"
                                                  [singleDatePicker]="true"
                                                  [timePicker]="false"
                                                  [date]="form.value?.real_cert_return_date"
                                                  [format]="'DD-MM-YYYY'"
                                                  placeholder="วันที่ส่ง Cert"
                                                  id="real_cert_return_date"
                                                  #real_cert_return_date
                                                  class="form-control"
                                                  formControlName="real_cert_return_date"
                                              />
                                              <div class="input-group-append pointer" (click)="real_cert_return_date.click()">
                                                <span class="input-group-text rounded-0 btn-primary text-white"
                                                ><i class="feather icon-calendar"></i
                                                ></span>
                                              </div>
                                              <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                              ><i class="fa fa-times"></i>
                                              </span>
                                            </div>
                                          </div>
                                        </form>
                                        
                                      </div>
                                    </div>
                                  </th>
                                  <th>ผู้ขาย</th>
                                  <th class="text-center">สถานะ</th>
                                  <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let jobOrder of jobOrders; let i = index">
                                  <th>{{jobOrder.doc_no}}</th>
                                  <td>
                                    [{{jobOrder?.customer_info?.customer_code}}] {{jobOrder?.customer_info?.company_name}}
                                  </td>
                                  <td>{{jobOrder.created_at | thai_date: "short":"date" | dash}}</td>
                                  <td>
                                    {{jobOrder.pickup_date | thai_date: "short":"date" | dash}}
                                  </td>
                                  <td>
                                    {{jobOrder.return_date | thai_date: "short":"date" | dash}}
                                  </td>
                                  <td>
                                    {{jobOrder.real_return_date | thai_date: "short":"date" | dash}}
                                  </td>
                                  <td>
                                    {{jobOrder.real_cert_return_date | thai_date: "short":"date" | dash}}
                                  </td>
                                  <td class="text-nowrap">
                                    {{jobOrder.sales_assignment_info?.user_info?.firstname_th}} {{jobOrder.sales_assignment_info?.user_info?.lastname_th || '-'}}
                                  </td>
                                  <td class="text-center text-nowrap">
                                    {{jobOrder.doc_status_info?.document_status_name_th || jobOrder.doc_status_id}}
                                  </td>
                                  <td>
                                    <a 
                                    *ngIf="['PRD','TRN'].includes(jobOrder.job_order_type)"
                                    type="button" class="btn mr-1 mb-1 btn-primary btn-sm text-nowrap" 
                                    [routerLink]="[
                                    '/work-order/non-calibration/'+ jobOrder.job_order_info_id +'/show'
                                    ]"
                                    [queryParams]="{template: jobOrder.job_order_type}">
                                      <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                    </a>

                                    <a 
                                    *ngIf="['ICL','ICS','ECL','ECS'].includes(jobOrder.job_order_type)"
                                    type="button" class="btn mr-1 mb-1 btn-primary btn-sm text-nowrap"
                                    [routerLink]="[
                                    '/work-order/calibration/'+ jobOrder.job_order_info_id +'/show'
                                    ]"
                                    [queryParams]="{template: jobOrder.job_order_type}">
                                      <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                                    </a>

                                    <button 
                                      *ngIf="(jobOrder.doc_status_id == 'CONFIRMED' || jobOrder.doc_status_id == 'REJECTED_VERIFY_CRF' || jobOrder.doc_status_id == 'WORK_INCOMPLETE') && ((userInfo$ | async)?.is_calib_dep && ['ICL','ICS','TRN'].includes(jobOrder.job_order_type))"
                                      type="button" class="btn mr-1 mb-1 btn-outline-primary btn-sm text-nowrap" (click)="edit(jobOrder, 'complete')">
                                      <i class="fa fa-save icon-left"></i> บันทึกผล
                                    </button>
                                  </td>
                                </tr>
                                <tr *ngIf="jobOrders.length === 0">
                                    <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
  
                    <app-paginator
                      [filter]="filter"
                      [reset]="reset"
                      [fetch]="load"
                      pageSize="50"
                      [initialPage]="initialPage"
                      (filterLimit)="getFilterLimit($event)"
                      (changePage)="onChangePage($event)">
                    </app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>

<app-work-order-create-filter>
</app-work-order-create-filter>