import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AccDebitNoteItemService } from 'src/app/services/debit-note';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
@Component({
  selector: 'app-debit-note-item',
  templateUrl: './debit-note-item.component.html',
  styleUrls: ['./debit-note-item.component.css']
})
export class DebitNoteItemComponent implements OnInit {
  pageType: string = 'create';
  reset: boolean = true;
  pageLoad: boolean = true;
  createItem: boolean = false;

  invoiceItems = [];
  delelteInvoiceItems = [];
  invoice = null;

  @Input('debitNoteInfo') debitNoteInfo: any;
  @Output() done = new EventEmitter<any>();

  subject: Subject<string> = new Subject();
  constructor(
    private router: Router,
    private AccDebitNoteItemService: AccDebitNoteItemService,
    
    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) { 
    if (this.router.url.includes('/show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('/edit')) {
      this.pageType = 'edit';
    }
  }

  async ngOnInit() {
    if(this.pageType != 'create'){
      await this.AccDebitNoteItemService.load(null, {acc_debit_note_info_id: this.debitNoteInfo.acc_debit_note_info_id}).then(res=>{
        this.invoiceItems = res.resultData;
        this.invoiceItems = this.invoiceItems.map(value=>{
          return {
            ...value,
            data: {
              ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
              quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
            }
          }
        })
        this.invoiceItems.sort(function(a, b) {
          return a.order_by - b.order_by;
        });
      })
    }

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );
    this.sumCount();
    this.pageLoad = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.invoiceItems, event.previousIndex, event.currentIndex);
  }

  async sumCount(){
    this.addNumberRunning();
    this.sentData();
  }
  
  async sentData(){
    let totalPrice = 0;
    this.invoiceItems.map(item=>{

      if(item.quantity == '' || item.quantity == null) item.quantity = 0;
      if(item.item_type == 'calibration_service'){
        totalPrice = totalPrice + this.calculate.sumMitPrevious(item);
      }else if(item.item_type == 'product'){
        totalPrice = totalPrice + this.calculate.sumProductPrevious(item);
      }
      item.price = this.utils.transformDecimal(item.price)
    })

    this.done.emit({
      items: this.invoiceItems,
      delelteItems: this.delelteInvoiceItems,
      totalPrice: totalPrice
    });
  }

  addDescription(){
    let data = {
      price: 0,
      product_description: "",
      item_type: 'description',
      discount: 0,
      original_price: 0
    }
    this.invoiceItems.push(data);
  }

  addNumberRunning(){
    let i = 1;
    this.invoiceItems.map(item=>{
      let item_type = item.item_type;
      if (item_type != 'description'){
        item['index'] = i;
        i++;
      }
    })
  }

  openCreateItem(){
    this.createItem = true;
  }

  async submitDebit(event){
    if(event){
      event.map(item=>{
        let checkDuplicate = this.invoiceItems.find(value=> value.quotation_item_id == item.quotation_item_id);
        if(!checkDuplicate){
          this.invoiceItems.push(item);
        }
      })
      await this.sumCount();
    }
    this.createItem = false;
  }

  onKeyup(){
    this.subject.next();
  }

  select(invoice){
    this.invoice = invoice;
  }

  async delete(closeModalEl){
    const index = this.invoiceItems.indexOf(this.invoice);
    this.delelteInvoiceItems.push(this.invoice);
    if (index > -1) {
      this.invoiceItems.splice(index, 1);
    }
    await this.sumCount();
    this.invoice = null;
    closeModalEl.click();
  }
}
