<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">รายการผู้ใช้งาน</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/users']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/users']">จัดการผู้ใช้งาน</a>
              </li>
              <li class="breadcrumb-item active">รายการผู้ใช้งานระบบ</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <a [routerLink]="['/users/create']" class="btn btn-outline-primary">
            <i class="feather icon-plus-circle icon-left"></i> เพิ่มผู้ใช้งาน
          </a>
        </div>
      </div>
    </div>
    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">รายการผู้ใช้งาน</h4>
              <div class="heading-elements">
                <search-box (search)="search($event)"></search-box>
              </div>
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th>รหัสพนักงาน</th>
                        <th>ชื่อ-นามสกุล</th>
                        <th>เบอร์โทรศัพท์</th>
                        <th>อีเมล</th>
                        <th>สถานะ</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let user of users">
                        <th>{{ user.emp_info?.emp_code }}</th>
                        <td>
                          {{ user.firstname_th }} {{ user.lastname_th }}
                          <p
                            class="font-small-2 text-muted blue-grey lighten-2 mt-1"
                          >
                            <!-- เข้าระบบล่าสุด {{ user.lastLoginAt }} น. -->
                            {{ user.position }}
                          </p>
                        </td>
                        <td>{{ user.mobile_no }}</td>
                        <td>{{ user.email }}</td>
                        <td>
                          <p>{{ user.user_state_info?.user_state_id | titlecase }}</p>
                          <p class="font-small-2 text-muted blue-grey lighten-2">Login ล่าสุด: {{user.last_login | thai_date:"short" | dash}}</p>

                        </td>
                        <td>
                          <!-- <a
                            [routerLink]="['/users/' + user.user_id + '/edit']"
                            class="btn mr-1 mb-1 btn-warning btn-sm btn-edit"
                            ><i class="fa fa-pencil icon-left"></i> แก้ไข</a
                          > -->
                          <a
                            type="button"
                            class="btn mr-1 mb-1 btn-primary btn-sm"
                            [routerLink]="['/users/' + user.user_id + '/show']"
                          >
                            <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                          </a>

                        </td>
                      </tr>
                      <tr *ngIf="!users.length">
                        <td colspan="6" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
<!--
<app-confirm-delete
  [id]="user_id"
  (save)="deleteUser($event)"
></app-confirm-delete> -->
