<loading *ngIf="isLoading"></loading>

<div *ngIf="!isLoading">
  <button
    *ngIf="canEditCustomer"
    type="button"
    class="btn btn-outline-primary mb-2"
    data-target="#create-customer-address"
    trigger-sidebar
  >
    <i class="fa fa-plus-circle icon-left"></i> เพิ่มที่อยู่
  </button>
  <div class="table-responsive">
    <table class="table table-striped table-bordered">
      <thead>
        <tr>
          <th>#</th>
          <th>ประเภทที่อยู่</th>
          <th>บริษัท</th>
          <th> MIT iPLUS Code</th>
          <th>ที่อยู่</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let address of addresses; let i = index">
          <th>{{ address.i }}</th>
          <td>{{ address.customer_address_type_info?.customer_address_name || '-'}}</td>
          <td>
            <div>
              {{ address.address_name || '-'}} 
              {{ address.branch?  '('+address.branch+')':''}}
            </div>
            <small>แผนก: {{address.department || '-'}}</small>
            <span class="badge bg-blue float-right">{{ address.memo }}</span>
          </td>
          <td>
            {{ address.mit_iplus_code | dash}}
          </td>
          <td [ngSwitch]="address?.province_id">
            <span *ngSwitchCase="'1'">
              {{ address.address }} {{(address?.district_name)?'แขวง': ''}}{{ address.district_name }}
              {{(address?.amphure_name)?'เขต': ''}}{{ address.amphure_name }}
              {{(address?.province_name)?'จังหวัด': ''}}{{ address.province_name }}
              {{ address?.zip_code }}
            </span>
            <span *ngSwitchDefault>
              {{ address.address }} {{(address?.district_name)?'ตำบล': ''}}{{ address.district_name }}
              {{(address?.amphure_name)?'อำเภอ': ''}}{{ address.amphure_name}}
              {{(address?.province_name)?'จังหวัด': ''}}{{ address.province_name }}
              {{ address?.zip_code }}
            </span>
          </td>
          <td>
            <button
              type="button"
              class="btn mr-1 mb-1 btn-primary btn-sm"
              (click)="select(address)"
              trigger-sidebar
              data-target="#edit-customer-address"
            >
              <i class="fa fa-eye icon-left"></i> ดูข้อมูล
            </button>
          </td>
        </tr>
        <tr *ngIf="!addresses.length">
          <td colspan="6" class="text-center">
            ไม่มีข้อมูล
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<app-paginator
  *ngIf="!isLoading"
  [filter]="filter"
  [reset]="reset"
  [fetch]="load"
  (changePage)="onChangePage($event)"
></app-paginator>

<app-customer-address-create
  [provinces]="provinces"
  (done)="doReset($event)"
></app-customer-address-create>

<app-customer-address-edit
  [provinces]="provinces"
  [customer-address]="customer_address"
  [can-edit-customer]="canEditCustomer"
  (done)="doReset($event)"
></app-customer-address-edit>
