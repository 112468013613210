<div id="create-customer-address" sidebar (close)="close($event)">
  <div
    trigger-sidebar
    data-target="#create-customer-address"
    class="w-100 h-100 position-fixed"
    style="
      left: 0;
      top: 0;
      background-color: black;
      opacity: 0.3;
      display: none;
      z-index: 1;
    "
  ></div>
  <div class="custom-sidebar">
    <div class="card shadow-none p-0 m-0">
      <div class="card-header border-bottom py-1">
        <div
          class="task-header d-flex justify-content-between align-items-center"
        >
          <h5 class="new-task-title mb-0">เพิ่มที่อยู่</h5>
          <span>
            <button
              type="button"
              class="close close-icon"
              trigger-sidebar
              data-target="#create-customer-address"
              (close)="close($event)"
              #closeSidebar
            >
              <i class="feather icon-x align-middle"></i>
            </button>
          </span>
        </div>
      </div>

      <app-response-status class="mx-1 mt-1"
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <form class="form mt-1" [formGroup]="form">
        <div class="card-content">
          <div class="card-body">
            <form-group
              [control]="form.controls.customer_address_type_id"
              [submitted]="submitted"
            >
              <label for="customer_address_type_id">ประเภท <span class="danger">*</span></label>
              <select
                class="form-control"
                id="customer_address_type_id"
                formControlName="customer_address_type_id"
              >
                <option value="">กรุณาเลือกข้อมูล</option>
                <option
                  *ngFor="let customerAddressType of customerAddressTypes"
                  [value]="customerAddressType.customer_address_type_id"
                >
                  {{ customerAddressType.customer_address_name }}</option
                >
              </select>
            </form-group>

            <form-group
            [control]="form.controls.address_name"
            [submitted]="submitted">
              <label for="address_name">บริษัท
                <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <input
                type="text"
                id="address_name"
                class="form-control"
                placeholder="บริษัท"
                formControlName="address_name"
              />
            </form-group>

            <form-group
              *ngIf="!is_acc_dep"
              [control]="form.controls.department_code"
              [submitted]="submitted"
            >
              <label for="department_code"> MIT iPLUS Code
                <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <input
                type="text"
                id="department_code"
                class="form-control"
                placeholder="MIT iPLUS Code"
                formControlName="department_code"
              />
            </form-group>

            <form-group
            [control]="form.controls.branch"
            [submitted]="submitted">
              <label for="branch">สาขา / สำนักงาน
              </label>
              <input
                type="text"
                id="branch"
                class="form-control"
                placeholder="สาขา / สำนักงาน"
                formControlName="branch"
              />
            </form-group>

            <form-group
              [control]="form.controls.department"
              [submitted]="submitted"
            >
              <label for="department">แผนก
                <span class="danger" *ngIf="form.enabled">*</span>
              </label>
              <input
                type="text"
                id="department"
                class="form-control"
                placeholder="แผนก"
                formControlName="department"
              />
            </form-group>

            <form-group
            [control]="form.controls.tax_id"
            [submitted]="submitted">
              <label for="tax_id">
                เลขประจำตัวผู้เสียภาษี
              </label>
              <input
                type="text"
                id="tax_id"
                class="form-control"
                placeholder="เลขประจำตัวผู้เสียภาษี"
                formControlName="tax_id"
                maxlength="13"
              />
            </form-group>

            <form-group
              [control]="form.controls.address"
              [submitted]="submitted"
            >
              <label for="address">
                ที่อยู่ <span class="danger">*</span>
              </label>
              <input
                type="text"
                id="address"
                class="form-control"
                placeholder="ที่อยู่"
                formControlName="address"
              />
            </form-group>

            <form-group
              [control]="form.controls.province_id"
              [submitted]="submitted"
            >
              <label for="province_id">
                จังหวัด
              </label>
              <ng-select2 
                [data]="provinces"
                [placeholder]="'จังหวัด'"
                formControlName="province_id"
                [allowClear]="true"
                [width]="300">
              </ng-select2>
            </form-group>
            <form-group
              [control]="form.controls.amphure_id"
              [submitted]="submitted"
            >
              <label for="amphure_id">
                เขต/อำเภอ
              </label>
              <ng-select2 [data]="amphures"
                [placeholder]="'เขต/อำเภอ'"
                [allowClear]="true"
                formControlName="amphure_id"
                [width]="300">
              </ng-select2>
            </form-group>
            <form-group
              [control]="form.controls.district_id"
              [submitted]="submitted"
            >
              <label for="district_id">
                แขวง/ตำบล
              </label>
              <ng-select2 [data]="districts"
                [placeholder]="'แขวง/ตำบล'"
                [allowClear]="true"
                formControlName="district_id"
                [width]="300">
              </ng-select2>
            </form-group>

            <form-group
              [control]="form.controls.geography_id"
              [submitted]="submitted"
            >
              <label for="geography_id">
                รหัสไปรษณีย์
              </label>
              <input
                type="text"
                id="geography_id"
                class="form-control"
                placeholder="รหัสไปรษณีย์"
                formControlName="geography_id"
              />
            </form-group>

            <form-group
              [control]="form.controls.tel_1"
              [submitted]="submitted"
            >
              <label for="tel_1"
                >โทรศัพท์ 1
                <span class="danger"
                  >*</span
                ></label
              >
              <input
                type="text"
                id="tel_1"
                class="form-control"
                placeholder="โทรศัพท์ 1"
                formControlName="tel_1"
              />
            </form-group>

            
            <form-group
              [control]="form.controls.tel_2"
              [submitted]="submitted"
            >
              <label for="tel_2"
                >โทรศัพท์ 2
                </label
              >
              <input
                type="text"
                id="tel_2"
                class="form-control"
                placeholder="โทรศัพท์ 2"
                formControlName="tel_2"
              />
            </form-group>

            <form-group
              [control]="form.controls.fax_1"
              [submitted]="submitted"
            >
              <label for="fax_1"> Fax 1</label>
              <input
                type="text"
                id="fax_1"
                class="form-control"
                placeholder="Fax 1"
                formControlName="fax_1"
              />
            </form-group>

            <form-group
              [control]="form.controls.fax_2"
              [submitted]="submitted"
            >
              <label for="fax_2"> Fax 2</label>
              <input
                type="text"
                id="fax_2"
                class="form-control"
                placeholder="Fax 2"
                formControlName="fax_2"
              />
            </form-group>

            <form-group [control]="form.controls.memo" [submitted]="submitted">
              <label for="memo"> Memo </label>
              <input
                type="text"
                id="memo"
                class="form-control"
                placeholder="Memo"
                formControlName="memo"
              />
            </form-group>
          </div>
          <div class="card-body pb-2" *ngIf="form.enabled">
            <div class="mt-1 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-warning mr-1"
                trigger-sidebar
                data-target="#create-customer-address"
              >
                <i class="fa fa-times"></i> ยกเลิก
              </button>
              <button type="button" class="btn btn-primary" (click)="submit()">
                <i class="fa fa-save"></i> บันทึก
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
