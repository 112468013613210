import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { UtilsService } from 'src/app/services';
import {
   QuotationMitItemService,
   QuotationProductItemService,
   NgSelect2Customer
} from 'src/app/services/quotations';

@Component({
  selector: 'app-transport-receive-item-create',
  templateUrl: './transport-receive-item-create.component.html',
  styleUrls: ['./transport-receive-item-create.component.css']
})
export class TransportreceiveItemCreateComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  showCbxAll:boolean = false;
  quotationList = [];
  productList = [];
  receiveItems: any = [];
  number_of_select:number = 0;

  @Input() itemStatus: any;
  @Input() userInfo: any;
  @Input() customer_info: any;
  @Input('receiveItems') selectedReceiveItems;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  select2OptionQuotation = null;

  constructor(   
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private NgSelect2Customer: NgSelect2Customer,
    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public UtilsService: UtilsService,
    ) { 
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }
  ngOnInit() {}

  async ngOnChanges(changes: SimpleChanges) {
    this.quotationList = [];
    this.select2OptionQuotation = this.NgSelect2Customer.selectQuotations(`?limit=10&&customer_id=${this.customer_info.customer_id}&&doc_status_id=ACCEPTED&&order_by=doc_no:desc`);
  }
  
  setForm(){
    this.form = this.formBuilder.group({
      quotation_info_id: ["", Validators.required],
      transport_receive_item: this.formBuilder.array([])
    });

    this.receiveItems = this.form.get('transport_receive_item') as FormArray;
  }

  createReceiveItem(data:any = {}): FormGroup {

    return this.formBuilder.group({
      select:[false],
      quotation_info_id: [data.quotation_info_id || ""],
      transport_receive_id: [""],
      quotation_mit_item_id: [data.quotation_mit_item_id || ""],
      item_status_id: ["WAITING_FOR_RECEIVE"],
      note: [""],
      quotation_mit_item_info: [data],
      quotation_info: [data.quotation_info]
    });
  }

  get receiveFormGroup() {
    return this.form.get('transport_receive_item') as FormArray;
  }

  async selectQuotation(event){
    this.productList = [];
    this.number_of_select = 0;

    if(event){
      while (this.receiveItems.length !== 0) {
        this.receiveItems.removeAt(0);
      }
      await this.QuotationMitItemService.load(null, {quotation_info_id: event,is_parent_quotation_mit_item_id_not_null: false, transport_data: true, service_location:'LAB', 'order_by': 'order_by:asc'})
      .then(res=>{
        let result = res.resultData.filter(item=>item.transport_receive_item_info.length == 0 && this.checkSelected(item));

        this.showCbxAll = false;

        if(result.length > 0){
          this.showCbxAll = true;
        }

        res.resultData.map(item=>{
          this.receiveItems.push(this.createReceiveItem(item));
        })
      })

      await this.QuotationProductItemService.load(null,  {quotation_info_id: event, '$product_info.product_title$': 'transport' })
      .then(res=>{
        this.productList = res.resultData || [];
      })
    }
  }

  onCheckAll(e){
    this.receiveItems.controls.map(item=>{
      if(item.value.quotation_mit_item_info.transport_receive_item_info.length == 0 && item.value?.quotation_mit_item_info.service_location.toUpperCase() =='LAB'){
        item.get('select').setValue(e.target.checked);
      }
    })
    this.number_of_select = this.receiveItems.value.filter(item=>item.select === true).length;
  }

  onCheckChange(e, index: number){
    this.receiveItems.controls[index].patchValue({
      select: e.target.checked
    });
    this.number_of_select = this.receiveItems.value.filter(item=>item.select === true).length;
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let items = this.receiveItems.value.filter(item=> item.select == true);
    this.done.emit(items);
    this.closeModalBtn.nativeElement.click();
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.showCbxAll = false;
        this.number_of_select = 0;
        this.productList = [];
        this.setForm();
      }
    });
  }
  
  public getStatus(item_status_id: string){
    let findStatus = this.itemStatus.find(item=> item.item_status_id == item_status_id);
    return findStatus.item_status_name_th || item_status_id;
  }

  public getMethodName(mit){
    let item = mit.filter(item=> item.total_amount != 0);  

    item = mit.filter((mit, index, self) =>
      index === self.findIndex((t) => (
        t.method_name === mit.method_name 
      ))
    )
    let methodName = "";
    item.map((item, index)=>{
      if(index == 0){
        methodName = item.method_name;
      }else{
        methodName = methodName + ", "+ item.method_name;
      }
    })
    return methodName;
  }

  public checkSelected (mit){
    if(!this.selectedReceiveItems.find(selected=> selected.quotation_mit_item_id == mit.quotation_mit_item_id)){
      return true;
    }else{
      return false;
    }
  }
}
