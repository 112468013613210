import { Component, NgZone, OnInit, Output, ViewChild, EventEmitter, ElementRef, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccInvoiceInfoService, AccInvoiceItemService, AccPaymentService } from 'src/app/services/invoice';
import moment from 'moment';
import { AccTaxInvoiceInfoService, AccTaxInvoiceItemService } from 'src/app/services/tax-invoice';

@Component({
  selector: 'app-cheque-edit',
  templateUrl: './cheque-edit.component.html',
  styleUrls: ['./cheque-edit.component.css']
})
export class ChequeEditComponent implements OnInit {
  form: FormGroup;
  //boolean
  submitted:boolean = false;
  //number
  sum_price: number = 0;
  sum_value_before_wht: number = 0;

  @Input('payment') payment: any;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  subject: Subject<string> = new Subject();

  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private AccPaymentService: AccPaymentService,
    private AccInvoiceItemService: AccInvoiceItemService,
    private AccTaxInvoiceItemService: AccTaxInvoiceItemService,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private calculate: CalculateFunctionService,
    public util : QuotationUtilsService,
  ) {
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }

  async ngOnInit() {

    this.form.patchValue(this.payment);

    if(this.payment?.acc_invoice_info?.acc_invoice_info_id){
      await this.AccInvoiceItemService.load(null, {acc_invoice_info_id: this.payment.acc_document_info_id})
      .then(async res=>{
        let invoiceItems = res.resultData || [];
        invoiceItems.map(value=>{
          if(value.item_type=='calibration_service'){
            this.sum_price = this.sum_price + this.calculate.sumMit(value);
          }else{
            this.sum_price = this.sum_price + this.calculate.sumProduct(value);
          }
        })
      })
      this.sum_price = this.sum_price -  this.util.convertStingToNumber(this.payment.acc_invoice_info.discount);
      await this.AccPaymentService.load(null, {acc_document_info_id: this.payment.acc_document_info_id})
      .then(res=>{
        let payments = res.resultData;
        payments.filter(payment=> payment.acc_payment_id != this.payment.acc_payment_id).map(item=>{
          this.sum_value_before_wht = this.sum_value_before_wht + item.value_before_wht;
        });
      });
    }else if(this.payment?.acc_tax_invoice_info?.acc_tax_invoice_info_id){
      await this.AccTaxInvoiceItemService.load(null, {acc_tax_invoice_info_id: this.payment.acc_document_info_id})
      .then(async res=>{
        let invoiceItems = res.resultData || [];
        invoiceItems.map(value=>{
          if(value.item_type=='calibration_service'){
            this.sum_price = this.sum_price + this.calculate.sumMit(value);
          }else{
            this.sum_price = this.sum_price + this.calculate.sumProduct(value);
          }
        })
      })
      this.sum_price = this.sum_price -  this.util.convertStingToNumber(this.payment.acc_tax_invoice_info.discount);
      await this.AccPaymentService.load(null, {acc_document_info_id: this.payment.acc_document_info_id})
      .then(res=>{
        let payments = res.resultData;
        payments.filter(payment=> payment.acc_payment_id != this.payment.acc_payment_id).map(item=>{
          this.sum_value_before_wht = this.sum_value_before_wht + item.value_before_wht;
        });
      });
    }
    this.sumCount();

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );
  }

  setForm(){
    this.form = this.formBuilder.group({
      acc_document_info_id: [""],
      acc_payment_id: [""],
      doc_type: [""],
      payment_method_id: [""],
      payment_date: ["", [Validators.required]],
      bank_name: ["", [Validators.required]],
      bank_branch: ["", [Validators.required]],
      evidence_date: ["", [Validators.required]],
      evidence_ref_no: ["", [Validators.required]],
      value_before_vat: [""],
      wht_percent: ["0"],
      value_before_wht: ["", [Validators.required]],
      fee: ["0.00"],
      grand_total: [""],
      note: [""],
      tax: [""],
      order: [1],
      deduction: ["0.00"],

      check_deposit_bank_name: ["", [Validators.required]],
      check_deposit_date: ["", [Validators.required]],
      check_deposit_status: ["", [Validators.required]],
      check_money_arrived_date: [""],
    });
  }

  onKeyup(){
    this.subject.next();
  }
  
  sumCount(){
    let data = {
      ...this.form.getRawValue()
    }

    this.form.get('value_before_wht').setValue(this.util.transformDecimal(data.value_before_wht));
    this.form.get('fee').setValue(this.util.transformDecimal(data.fee));
    if(data.wht_percent){
      let sum = (this.util.convertStingToNumber(data.value_before_wht) * this.util.convertStingToNumber(data.wht_percent)) /100;
      
      this.form.get('deduction').setValue(this.util.transformDecimal(sum));

      let value_before_vat = this.util.convertStingToNumber(this.form.value.value_before_wht) - sum;
      this.form.get('value_before_vat').setValue(value_before_vat);

      let grand_total = (this.util.convertStingToNumber(value_before_vat)+(this.util.convertStingToNumber(this.form.value.value_before_wht) * this.util.convertStingToNumber(data.tax)) /100)-this.util.convertStingToNumber(this.form.value.fee);
      this.form.get('grand_total').setValue(this.util.transformDecimal(grand_total));
    }
  }

  async save(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let value = {
      ...this.form.getRawValue()
    }

    let data = {
      ...value,
      grand_total: parseFloat(this.util.convertStingToNumber(value.grand_total)),
      value_before_vat: parseFloat(this.util.convertStingToNumber(value.value_before_vat)),
      value_before_wht: parseFloat(this.util.convertStingToNumber(value.value_before_wht)),
      wht_percent: parseFloat(value.wht_percent),
      evidence_date: value.evidence_date == ""? undefined: value.evidence_date,
      fee: parseFloat(this.util.convertStingToNumber(value.fee)),
      check_deposit_date: value.check_deposit_date? value.check_deposit_date: undefined,
      check_money_arrived_date: value.check_money_arrived_date? value.check_money_arrived_date: undefined,
    }
    this.sum_value_before_wht = this.sum_value_before_wht + data.value_before_wht;
    let response = await this.AccPaymentService.update(data);
    if(response.success){
      if(this.sum_value_before_wht >= this.sum_price){
        if(this.payment.acc_invoice_info?.acc_invoice_info_id == 'INVOICE'){
          let response = await this.AccInvoiceInfoService.update({
            ...this.payment.acc_invoice_info,
            doc_status_id: 'COMPLETED',
            doc_duedate : moment(this.payment.acc_invoice_info.doc_duedate).format('YYYY-MM-DD')
          });
          if (response.success) {
            this.done.emit(response);
            this.closeModalBtn.nativeElement.click();
          } 
        }else{
          let response = await this.AccTaxInvoiceInfoService.update({
            ...this.payment.acc_tax_invoice_info,
            doc_status_id: 'COMPLETED',
            doc_duedate : moment(this.payment.acc_tax_invoice_info.doc_duedate).format('YYYY-MM-DD')
          });
          if (response.success) {
            this.done.emit(response);
            this.closeModalBtn.nativeElement.click();
          } 
        }
      }else{
        this.done.emit(response);
        this.closeModalBtn.nativeElement.click();
      }
    }
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
        this.done.emit(false);
      }
    });
  }
}
