import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import {
  GETService,
  CustomerContactService,
  CustomerInfoService,
  UserService,
  SalesAssignmentService,
  CustomerAddressService,
  UtilsService,
  CustomerActivityService,
} from "src/app/services";
import {
  EmailValidator,
  AbsNumberValidator,
} from "src/app/validators";
import { Subscription, Observable } from "rxjs";
import { Select, Store } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import Swal from "sweetalert2";
import moment from "moment";

@Component({
  selector: "app-customer-create",
  templateUrl: "./customer-create.component.html",
  styleUrls: ["./customer-create.component.css"],
})
export class CustomerCreateComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  disableSaveBtn:boolean = false;
  status: any;

  customerLevels = [];
  customerStates = [];
  industryGroups = [];
  industrys = [];
  sources = [];
  districts = [];
  amphures = [];
  provinces = [];
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  province$: Subscription;
  amphure$: Subscription;
  district$: Subscription;
  userSub: Subscription;
  exclude_user_ids = [];

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private GETService: GETService,
    private CustomerContactService: CustomerContactService,
    private CustomerInfoService: CustomerInfoService,
    private UserService: UserService,
    private SalesAssignmentService: SalesAssignmentService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerActivityService: CustomerActivityService,
    private UtilsService: UtilsService,
    private store: Store
  ) {}

  async ngOnInit() {
    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
    if(userInfo) {
      this.exclude_user_ids = [userInfo.user_id];
    }
    this.form = this.formBuilder.group({
      isMaster: ["1"],
      customer_level_id: ["NEW", [Validators.required]],
      customer_state_id: ["LEAD", [Validators.required]],
      tax_id: [
        "",
        [
          AbsNumberValidator(),
          Validators.minLength(13),
          Validators.maxLength(13),
        ],
      ],
      company_name: ["", [Validators.required]],
      branch: ["", [Validators.required]],
      // department: ["", [Validators.required]],
      industry_id: ["", [Validators.required]],
      customer_source: ["", [Validators.required]],
      customer_source_text: [""],
      address: ["", [Validators.required]],
      district_id: [""],
      amphure_id: [""],
      province_id: [""],
      geography_id: [""],

      tel_1: ["", [Validators.required]],
      tel_2: [""],
      email_1: ["", [EmailValidator()]],
      email_2: ["", [EmailValidator()]],
      fax_1: [""],
      fax_2: [""],

      firstname: ["", [Validators.required]],
      lastname: ["", [Validators.required]],
      phonenumber: ["", [Validators.required]],
      line_id: [""],
      nickname: [""],
      email: ["", [Validators.required, EmailValidator()]],

      emp_code: ["", [Validators.required]],
      sales_assignment_id: ["", [Validators.required]],
      sale_name: [""],
      selling_type: ["normal"],
    });

    this.sources = this.getSource();
    await this.GETService.industryGroups().then((result) => {
      this.industryGroups = result.resultData;
    });

    await this.GETService.industrys().then((res) => {
      let result = res.resultData || [];
      result = result.map(item=>{
        return {
          ...item,
          id: item.industry_id,
          text: item.industry_name,
        }
      })
      this.industrys = this.groupIndustrys(result);
    });

    this.GETService.provinces().then((res) => {
      let result = res.resultData || [];
      result = result.map(item=>{
        return {
          ...item,
          id: item.province_id,
          text: item.province_name_th,
        }
      })
      this.provinces = result;
    });

    this.form.controls.customer_source.valueChanges.subscribe(
      (customer_source) => {
        if(!customer_source.includes('...')){
          this.form.get('customer_source_text').setValue("");
          this.form.get('customer_source_text').setValidators(null);
        }else{
          this.form.get('customer_source_text').setValidators([Validators.required]);
        }
        this.form.get('customer_source_text').updateValueAndValidity();
      }
    );

    this.province$ = this.form.controls.province_id.valueChanges.subscribe(
      (province_id) => {
        this.districts = [];
        this.amphures = [];
        this.form.patchValue(
          {
            amphure_id: "",
            district_id: "",
            geography_id: "",
          },
          {
            onlySelf: true,
            emitEvent: false,
          }
        );
        if(province_id){
          this.GETService.amphures({
            province_id: province_id,
          }).then((res) => {
            let result = res.resultData || [];
            result = result.map(item=>{
              return {
                ...item,
                id: item.amphure_id,
                text: item.amphure_name_th,
              }
            })
            this.amphures = result;
          });
        }
      }
    );

    this.amphure$ = this.form.controls.amphure_id.valueChanges.subscribe(
      (amphure_id) => {
        this.districts = [];
        this.form.patchValue(
          {
            district_id: "",
            geography_id: "",
          },
          {
            onlySelf: true,
            emitEvent: false,
          }
        );
        if (amphure_id) {
          this.GETService.districts({ amphure_id: amphure_id }).then(
            (res) => {

              let result = res.resultData || [];
              result = result.map(item=>{
                return {
                  ...item,
                  id: item.district_id,
                  text: item.district_name_th,
                }
              })
              this.districts = result;
            }
          );
        }
      }
    );

    this.district$ = this.form.controls.district_id.valueChanges.subscribe(
      (district_id) => {
        let district = this.districts.filter(
          (v) => v.district_id == district_id
        )[0];
        if (district) {
          this.form.controls.geography_id.setValue(district.zip_code);
        } else {
          this.form.controls.geography_id.setValue("");
        }
      }
    );
    
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      this.exclude_user_ids = this.exclude_user_ids.length == 0 ? [(userInfo || {}).user_id] : this.exclude_user_ids
      if (userInfo) {
        this.queryEmployee(userInfo.user_id);
      }
    });
  }

  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.disableSaveBtn = true;
    let customerResponse = await this.CustomerInfoService.create({
      ...this.form.value,
      // province_id: Number(this.form.value.province_id),
      // amphure_id: Number(this.form.value.amphure_id),
      // district_id: Number(this.form.value.district_id),
      customer_level_change_at: moment(new Date()).format()
      // customerActivities: {
      //   title: "Test",
      //   description: "",
      //   customer_activity_type_id: "CREATED_CUSTOMER_INFO",
      // },
    });

    let userInfo = this.store.selectSnapshot(UserInfoState.getUser);

    if (customerResponse.success) {
      let customer_id = customerResponse.resultData.customer_id;

      let address_name = {
        district_name: "",
        amphure_name: "",
        province_name: "",
      }
      if (this.form.value.amphure_id == ""){
        this.form.value.amphure_id = null;
      }else{
        address_name.amphure_name = this.amphures.find(am=> am.id == this.form.value.amphure_id).text;
      }
      if (this.form.value.district_id == ""){
        this.form.value.district_id = null;
      }else{
        address_name.district_name = this.districts.find(ds=> ds.id == this.form.value.district_id).text;
      } 
      if (this.form.value.province_id == ""){
        this.form.value.province_id = null;
      }else{
        address_name.province_name = this.provinces.find(pr=> pr.id == this.form.value.province_id).text;
      }
      if (this.form.value.zip_code == "") this.form.value.zip_code = null;
      let customerAddressResponse = await this.CustomerAddressService.create({
        customer_id,
        ...this.form.value,
        address_name: this.form.value.company_name,
        ...address_name,
        is_registed_address: "true",
      });

      if(customerAddressResponse.success){
        let customerContactResponse = await this.CustomerContactService.create({
          related_customer_address_id: customerAddressResponse.resultData.customer_address_id,
          customer_id,
          ...this.form.value,
        });
      }

      // if (customerContactResponse.error || customerAddressResponse.error) {
      //   if (customerContactResponse.resultData) {
      //     await this.CustomerContactService.delete({
      //       customer_contact_id:
      //         customerContactResponse.resultData.customer_contact_id,
      //     });
      //   }

      //   if (customerAddressResponse.resultData) {
      //     await this.CustomerAddressService.delete({
      //       customer_address_id:
      //         customerAddressResponse.resultData.customer_address_id,
      //     });
      //   }

      //   await this.deleteCustomer(customer_id);

      //   return;
      // }
      let salesAssignmentResponse = await this.SalesAssignmentService.create({
        customer_id,
        user_id: this.form.value.sales_assignment_id
      });

      if (salesAssignmentResponse.error) {
        this.deleteCustomer(customer_id);
        return;
      }

      let activity_text = await this.UtilsService.customerActivityTitle(
        "CREATED_CUSTOMER_INFO",
        {
          $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
        }
      );
      await this.CustomerActivityService.create({
        customer_id,
        customer_activity_type_id: "CREATED_CUSTOMER_INFO",
        title: activity_text,
        activity_datetime: new Date(),
      });

      if (userInfo.user_id !== this.form.value.sales_assignment_id) {
        let salesUserInfo = await this.UserService.getById({
          user_id: this.form.value.sales_assignment_id,
        });
        salesUserInfo = salesUserInfo.resultData;

        activity_text = await this.UtilsService.customerActivityTitle(
          "FORCE_SALES_ASSIGNMENT",
          {
            $actor_name: `${userInfo.firstname_th} ${userInfo.lastname_th}`,
            $sales_name: `${salesUserInfo.firstname_th} ${salesUserInfo.lastname_th}`,
          }
        );
        setTimeout(async () => {
          await this.CustomerActivityService.create({
            customer_id,
            customer_activity_type_id: "FORCE_SALES_ASSIGNMENT",
            title: activity_text,
            activity_datetime: new Date(),
          });
        }, 1000);
      }

      this.router.navigateByUrl("/customer", {
        state: {
          status: salesAssignmentResponse.success,
        },
      });
      this.disableSaveBtn = false;
    } else {
      if (customerResponse.resultCode == "40301") {
        Swal.fire({
          icon: "warning",
          title: "ข้อผิดพลาด !",
          text: "สาขานี้มีอยู่ในระบบ โปรดตรวจสอบสาขาอีกครั้ง",
        });
      }
      this.disableSaveBtn = false;
    }
  }

  async deleteCustomer(customer_id) {
    await this.CustomerInfoService.delete({ customer_id });
  }

  queryEmployee(user_id) {
    this.form.controls.sale_name.setValue("");
    this.form.controls.sales_assignment_id.setValue("");
    this.form.controls.emp_code.setValue("");
    if (user_id) {
      let userInfo = this.store.selectSnapshot(UserInfoState.getUser);
      if (
        !userInfo.is_co_sales ||
        (userInfo.is_co_sales && userInfo.user_id !== user_id)
      ) {
        this.form.controls.emp_code.setValue(user_id);
        this.UserService.getById({ user_id }).then((response) => {
          if (response.success) {
            let userInfo = response.resultData;
            if (userInfo) {
              this.form.controls.sale_name.setValue(
                `${userInfo?.firstname_th} ${userInfo?.lastname_th}`
              );
              this.form.controls.sales_assignment_id.setValue(
                // empInfo.user_info?.user_id
                user_id
              );
            }
          }
        });
      }
    }
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }

  groupIndustrys(industrys) {
    let groups = {};
    for (let industry of industrys) {
      if (!groups[industry.industry_group_id]) {
        groups[industry.industry_group_id] = industry;
      }
    }
    let groupIndustrys = {};

    for (let industry of industrys) {
      if (!groupIndustrys[industry.industry_group_id]) {
        groupIndustrys[industry.industry_group_id] = [];
      }
      groupIndustrys[industry.industry_group_id].push(industry);
    }
    let groupByIndustrys = [];
    for (let group in groups) {
      let industry_group = this.industryGroups.find((v: any)=>
        groups[group].industry_group_id == v.industry_group_id
      )
      groupByIndustrys.push({
        id: groups[group].industry_group_id,
        text: industry_group.industry_group_name,
        children: [...groupIndustrys[group]],
      });
    }
    return groupByIndustrys;
  }

  getSource(){
    return [
      {id:"Google", text: "Google"},
      {id:"Line", text: "Line"},
      {id:"Facebook", text: "Facebook"},
      {id:"CS", text: "CS"},
      {id:"การแนะนำบอกต่อ", text: "การแนะนำบอกต่อ"},
      {id:"เคยใช้บริการมาก่อน", text: "เคยใช้บริการมาก่อน"},
      {id:"#MIT SAVE U", text: "#MIT SAVE U"},
      {id:"ออกบูธที่.", text: "ออกบูธที่."},
      {id:"อบรมที่.", text: "อบรมที่."},
      {id:"SALE", text: "SALE"},      
      {id:"อื่น ๆ คือ.", text: "อื่น ๆ คือ."},
    ]
  }
}
