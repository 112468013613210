import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { 
  CustomerAddressService, 
  CustomerContactService, 
  EmployeeService,
  UnitService
} from 'src/app/services';
import { 
  CalculateFunctionService, 
  QuotationContactService, 
  QuotationMitItemService, 
  QuotationNoteService, 
  QuotationProductItemService, 
  QuotationService,
  QuotationUtilsService
} from '../../../../services/quotations';
import THBText from 'thai-baht-text'
import jsPDF from 'jspdf';  
import domtoimage from 'dom-to-image-more'
import { 
  environment 
} from '../../../../../environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-quotation-pdf',
  templateUrl: './quotation-pdf.component.html',
  styleUrls: ['./quotation-pdf.component.css']
})
export class QuotationPdfComponent implements OnInit{
  pageLoad: boolean = true;
  canAccess: boolean = true;
  employeeShow: boolean = false;

  quotation_info_id: string = "";
  uploadPath:string = "";
  textBottomPage: string = "FM-ADM-01, Revise 7, Effective Date 01/11/21";
  pageList = [
    1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
  ];
  itemPerPage: number = 14;

  count:number = 0;
  totalPrice: number = 0;

  productItemList = [];
  productList = [];
  emptyList = [0, 1, 2];
  units = [];
  noteList = [];
  quotationContactList = [];
  quotationMitItems = [];
  quotationMitItemGroups = [];
  customerContactList = [];
  scopeList = [];
  equipmentList = [];
  mitList = [];
  //any
  signature_file: any = {
    sales_assignment_info: "",
    managerInfo: ""
  }
  quotationInfo: any = null;
  managerInfo: any = null;
  constructor(
    private titleService: Title,
    private router: Router,
    private route: ActivatedRoute,
    
    private UnitService: UnitService,
    private EmployeeService: EmployeeService,
    private QuotationService: QuotationService,
    private QuotationNoteService: QuotationNoteService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private QuotationMitItemService:QuotationMitItemService,
    private QuotationContactService: QuotationContactService,
    private QuotationProductItemService: QuotationProductItemService,
    
    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ){
    this.quotation_info_id = this.route.snapshot.paramMap.get("quotation_info_id");
    if(environment.uploadPath){
      this.uploadPath = environment.uploadPath;
    }
    this.route.queryParams.subscribe(params => {
      if(params['itemPerPage']){
        this.itemPerPage = params['itemPerPage'];
      }
    });
  }

  async ngOnInit(){
    this.pageLoad = false;
    this.UnitService.load().then((response) => {
      if (response.success) {
        this.units = response.resultData || [];
      }
    });
    await this.QuotationService
    .getById({ quotation_info_id: this.quotation_info_id })
    .then(async (res) => {
      if (res.success) {
        this.quotationInfo = res.resultData;
        this.titleService.setTitle(this.quotationInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
        
        if(this.quotationInfo.customer_address_info){
          this.quotationInfo['address'] = this.utils.genAddress(this.quotationInfo.customer_address_info);
        }
        if(this.quotationInfo.customer_address_id){
          await this.CustomerAddressService
          .getById({ customer_address_id: this.quotationInfo.customer_address_id })
          .then((res)=>{
            let resultData = res.resultData;
            this.quotationInfo['address'] = this.utils.genAddress(resultData);
          })
        }
        if(this.quotationInfo.quotation_sub_header_info){
          this.quotationInfo['sub_header'] = this.quotationInfo.quotation_sub_header_info.sub_header;
        }
        await this.CustomerContactService.load(null, {
          order_by: "isMaster:desc",
          customer_id: this.quotationInfo.customer_info.customer_id
        }).then((res) => {
          this.customerContactList = res.resultData;
        });
        await this.QuotationContactService
        .load(null, { quotation_info_id: this.quotation_info_id })
        .then((res) => {
          let result = res.resultData;
          this.customerContactList.map(v=> {
            const res = result.find(item => item.customer_contact_id == v.customer_contact_id);
            if(res) this.quotationContactList.push(res);
          })
        });
        this.QuotationNoteService.load(null, { 
          quotation_info_id: this.quotation_info_id,
          order_by: 'order:asc'
        }).then(async (res)=>{
          this.noteList = res.resultData || [];
          this.noteList.sort((a, b) => (parseInt(a.order) > parseInt(b.order)? 1 : -1));
        })
        await this.QuotationProductItemService.load(null, {
          quotation_info_id: this.quotation_info_id,
          'order_by': 'order_by:asc'
        }).then((res)=>{
          this.productItemList = res.resultData || [];
    
          for (var i=0; i<this.productItemList.length; i+=20) {
            this.productList.push(this.productItemList.slice(i, i+20));
          }   

          if(this.productItemList.length == 0){
            this.productList.push([]);
          }
          for (var i = 0; i < this.productItemList.length; i++) {
            this.emptyList.splice(0, 1);
          }
          this.sumCount();
        });
        await this.EmployeeService.manager(null, {emp_level_id: 'MANAGER', emp_department_id:'SALES_DEP'}).then(res=>{
          let emps = res.resultData;
          if(this.quotationInfo?.approved_id){
            this.managerInfo = emps.find(v=> v.emp_level_id == 'MANAGER' && this.quotationInfo?.approved_id == v.user_id);
          }else{
            this.managerInfo = emps.find(v=> v.emp_level_id == 'MANAGER');
          }
        });
        this.quotationMitItems = await this.getMitItem();
        this.quotationMitItems  = await this.calculateMitPrice(this.quotationMitItems);

        this.quotationMitItemGroups = await this.groupMitItems();
        for (var i=0; i<this.quotationMitItemGroups.length; i++) {
          this.quotationMitItemGroups[i].index = i;
        }
        this.setPage();

        this.getTotalPrice();
        if(this.quotationInfo?.sales_assignment_info?.user_info?.signature_file){
          this.signature_file.sales_assignment_info = 
          this.uploadPath + this.quotationInfo?.sales_assignment_info?.user_info?.signature_file;
        }
        if(this.managerInfo && this.managerInfo?.user_info?.signature_file){
          this.signature_file.managerInfo = this.uploadPath + this.managerInfo?.user_info?.signature_file;
        }
      }
    });
    this.employeeShow = await this.checkProductSize();
    this.pageLoad = true;
  }

  async checkProductSize(): Promise<any>{
    let result = false;

    const product_sections = document.querySelectorAll('.product-pdf');
    await Promise.all(Array.prototype.slice.call(product_sections,0).map(async (value, index) =>{
      if(product_sections.length === (index + 1)){
        const product_section = document.getElementById('product_section_' + (index));
        const imgHeight = product_section.clientHeight * 208 / product_section.clientWidth; 
        if(imgHeight > 295){
          result = true;
        }else{
          result = false;
        }
      }
    }));
    return result;
  }

  async getMitItem(): Promise<any>{
    this.quotation_info_id = this.route.snapshot.paramMap.get("quotation_info_id");

    if(this.quotation_info_id){
      let quotationMitItems = [];
      
      await this.QuotationMitItemService
      .load(null, {quotation_info_id: this.quotation_info_id, is_parent_quotation_mit_item_id_not_null: false,'order_by': 'order_by:asc'}).then(async (res) => {
        quotationMitItems = [...res.resultData];

        await Promise.all(quotationMitItems.map(async value=>{
          value['quotation_mit_item_details'] = [];
          value['quotation_mit_item_attributes'] = [];

          value['quotation_mit_item_attributes'] = value?.quotation_mit_item_attribute_info || [];

          value.quotation_mit_item_details = value?.quotation_mit_item_detail_info.filter(item=> item.is_non_calculate == "false") || [];

          value.quotation_mit_item_details = await Promise.all(value.quotation_mit_item_details.map(async (data: any)=>{
            let resultData = await this.mapMitItemData(data);

            return {
              ...resultData
            }
          }));

          value.quotation_mit_item_details.sort((a, b) => (a.order_by > b.order_by? 1 : -1));
        }));

      });
      return quotationMitItems;
    }else{
      return [];
    }
  }

  async groupMitItems(): Promise<any>{
    let quotationMitItemGroups = this.quotationMitItems;

    quotationMitItemGroups.map(value=>{
      const quotation_mit_item_details = [];
      if(value.quotation_mit_item_details.length >0){
        value.quotation_mit_item_details.map(item=>{
          if(item.detail_type == 'point' && (item?.cus_calibration_point == null && item?.cus_calibration_point == '')){
            if(item.quotation_mit_item_detail_points.length > 0){
              quotation_mit_item_details.push(item);
            }
          }else{
            quotation_mit_item_details.push(item);
          }
        })
      }
      value.quotation_mit_item_details = quotation_mit_item_details;
    })
    return quotationMitItemGroups;
  }

  setPage(){
    this.mitList = [];

    this.quotationMitItemGroups.forEach((item)=>{
      if(!this.mitList.length || this.mitList[this.mitList.length-1].length == this.itemPerPage)
      this.mitList.push([]);

      this.mitList[this.mitList.length-1].push(item);
    });

    let filter = {
      itemPerPage: this.itemPerPage,
    }
    this.router.navigate([], {
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
  }

  async mapMitItemData(mit: any): Promise<any>{
    let points = [];
    let range = [];
    if(mit.total_amount > 0){
      if(mit.detail_input_type == 'point'){
        points = mit.quotation_mit_item_detail_points_info || [];

        points
        .sort((a, b) => (a.order_by > b.order_by? 1 : -1));
      }else{
        range = mit.quotation_mit_item_detail_ranges_info || [];
      }
    }
    points.map(point=>{

      if(point.cus_unit_id) point['cus_unit_info'] = this.units.find(unit=> unit.unit_id == point.cus_unit_id);
      if(point.mit_unit_id) point['mit_unit_info'] = this.units.find(unit=> unit.unit_id == point.mit_unit_id);

      if(point.cus_unit_info) point['cus_unit_name'] = point.cus_unit_info.unit_short_name_en 
      || point.cus_unit_info.unit_short_name_th;
      if(point.mit_unit_info) point['mit_unit_name'] = point.mit_unit_info.unit_short_name_en 
      || point.mit_unit_info.unit_short_name_th;
    });

    range.map(range=>{

      if(range.cus_unit_id) range['cus_unit_info'] = this.units.find(unit=> unit.unit_id == range.cus_unit_id);
      if(range.mit_unit_id) range['mit_unit_info'] = this.units.find(unit=> unit.unit_id == range.mit_unit_id);
    });

    return {
      ...mit,
      detail_type: mit.detail_input_type,
      quotation_mit_item_detail_range: {
        cus_min: range[0]?.cus_min || 0,
        cus_max: range[0]?.cus_max || 0, 
        mit_min: range[0]?.mit_min || 0,
        mit_max: range[0]?.mit_max || 0,
        cus_unit_id: range[0]?.cus_unit_id || "",
        mit_unit_id: range[0]?.mit_unit_id || "",
        cus_unit_name: range[0]?.cus_unit_info.unit_short_name_en || "",
        mit_unit_name: range[0]?.mit_unit_info.unit_short_name_en || "", 
        quotation_mit_item_detail_range_id: range[0]?.quotation_mit_item_detail_range_id || "", 
      },
      quotation_mit_item_detail_points: points
    }
  }

  async calculateMitPrice(mitList = []){
    mitList = mitList.map((res) => (
      {
        ...res,
        grand_total: this.calculate.sumMit(res)
      } 
    ));
    return mitList;
  }

  getTotalPrice(){
    this.totalPrice = 0;
    this.quotationMitItems.map(item=>{
      this.totalPrice = this.totalPrice + parseFloat(item.grand_total);
    })
  }

  sumCount(){
    this.count = 0;
    this.productItemList.map(v=>{
      this.count = this.count + this.calculate.sumProduct(v);
    });
    let grand_total = this.calculate.sumProductTotal(this.count, this.quotationInfo);
    this.quotationInfo.grand_total = grand_total;

    if (grand_total > 0) {
      this.quotationInfo['THBText'] = THBText(Math.abs(grand_total).toFixed(2));
    }else if(grand_total < 0){
      this.quotationInfo['THBText'] = 'ลบ' + THBText(Math.abs(grand_total).toFixed(2));
    }else{
      this.quotationInfo['THBText'] = 'ศูนย์บาทถ้วน';
    }
    this.addNumberRunning();
  }

  addNumberRunning(){
    let i = 1;
    this.productItemList.map((v)=>{
      if (v.item_type != 'description' && v.item_type != 'note'){
        v['index'] = i;
        i++;
      }
    })
  }

  async print(quotationInfo){
    const product_sections = document.querySelectorAll('.product-pdf');
    const employee_section = document.getElementById('employee_section');
    const mit_sections = document.querySelectorAll('.mit-pdf');

    let doc = new jsPDF('p', 'mm', 'a4', true);
    doc.setFontSize(8);

    let page = 1;
    let perPage = 0;

    if(this.employeeShow){
      perPage = perPage + 1;
    }
    if(Array.prototype.slice.call(product_sections,0).length> 0){
      perPage = perPage + Array.prototype.slice.call(product_sections,0).length;
    }
    if(Array.prototype.slice.call(mit_sections,0).length> 0){
      perPage = perPage + Array.prototype.slice.call(mit_sections,0).length;
    }
    
    let productList = [];
    await Promise.all(Array.prototype.slice.call(product_sections,0).map(async (value, index) =>{
      let product_section = document.getElementById('product_section_' + (index));
      await domtoimage.toJpeg(product_section, {
        background: 'white', 
        allowTaint: true,
        width: product_section.clientWidth * 2,
        height: product_section.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        productList.push({index: index, canvas: canvas, product_section: product_section});
      })
    }));
    productList.sort(function(a, b){return a.index - b.index});
    await Promise.all(productList.map(async (value, index) =>{

      var imgWidth = 208;
      var imgHeight = value.product_section.clientHeight * imgWidth / value.product_section.clientWidth;
      if((index) != 0) doc.addPage('a4');
      doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);
      doc.text(this.textBottomPage, 130, 290);
      doc.text(page + ' /  ' + perPage, 199, 290);
      page = page+1;
    }));
    if(this.employeeShow){
      let textBottomPage = this.textBottomPage;

      await domtoimage.toJpeg(employee_section, {
        background: 'white', 
        allowTaint: true,
        width: employee_section.clientWidth * 2,
        height: employee_section.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        var imgWidth = 208;
        var imgHeight = employee_section.clientHeight * imgWidth / employee_section.clientWidth;

        doc.addPage('a4');
        doc.addImage(canvas, 'JPEG', 0, 0, imgWidth, imgHeight, 'FAST');
        doc.text(textBottomPage, 130, 290);
        doc.text(page + ' /  ' + perPage, 199, 290);
        page = page+1;
      })
    }  
    if(this.mitList.length > 0){
      let mitList = [];
      let count = 0 ;
      let countofItem = Array.prototype.slice.call(mit_sections,0).length;
      let textBottomPage = this.textBottomPage;

      await Promise.all(Array.prototype.slice.call(mit_sections,0).map(async (v, index) =>{
        let mit_section = document.getElementById('mit_section_' + (index));

        await domtoimage.toJpeg(mit_section, {
          background: 'white', 
          allowTaint: true,
          width: mit_section.clientWidth * 2,
          height: mit_section.clientHeight * 2,
          style: {
           transform: 'scale('+2+')',
           transformOrigin: 'top left'
          },
          quality: 0.95 
        })
        .then(async function (canvas) {
          count++;
          mitList.push({index: index, canvas: canvas, mit_section: mit_section, textBottomPage: textBottomPage});

          if(count == countofItem){
            mitList.sort(function(a, b){return a.index - b.index});

            await Promise.all(mitList.map(async (value, mitIndex) =>{

              var imgWidth = 208;
              var imgHeight = value.mit_section.clientHeight * imgWidth / value.mit_section.clientWidth;
              doc.addPage('a4');
              doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);
              doc.text(value.textBottomPage, 130, 290);
              doc.text(page + ' /  ' + perPage, 199, 290);
              page = page+1;
           
              if(mitIndex == mitList.length - 1){
                doc.save(quotationInfo.doc_no);
              }
            }));
          }
        })
      }));
    }else{
      setTimeout(() => {
        doc.save(quotationInfo.doc_no);
      }, 1000);
    }
  }
  public isShowDiscount(): boolean{
    return (this.productItemList.find(v=>v.discount > 0) != undefined)? true: false;
  }
  public showMethod(detail):any{
    let mtName = "";
    let result = "";
    detail.map(v=>{
      if(mtName !== v.method_name){
        mtName = v.method_name;
        if(result == ""){
          result = mtName;
        }else{
          result = result + ', ' + mtName;
        }
      } 
    })
    return result;
  }

  public showServiceLocation(detail):any{
    let sLocation = "";
    let result = "";
    detail.map(v=>{
      if(sLocation !== v.service_location){
        sLocation = v.service_location;
        if(result == ""){
          result = sLocation;
        }else{
          result = result + ', ' + sLocation;
        }
      } 
    })
    return result;
  }

  public pointLenght(points):number{
    return points.filter(point=> point.is_non_calculate == 'false').length;
  }

  public getMitNote(detail):any{
    let sNote = "";
    let result = "";
    detail.map(v=>{
      if(sNote !== v.note){
        sNote = v.note;
        if(result == ""){
          result = sNote;
        }else{
          result = result + ', ' + sNote;
        }
      } 
    })
    return result;
  }

  public getMitAttribute(attribute):any{
    let result = "";
    attribute.map(v=>{
      if(result == ""){
        result = v.attribute_name + ': ' + v.attribute_value;
      }else{
        result = result + ', ' + v.attribute_name + ': ' + v.attribute_value;
      }
    })
    return result;
  }
}
