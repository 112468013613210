import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
  SimpleChanges,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { 
  UtilsService,
  EmployeeService,
  CustomerInfoService,
  TransportMasterBookingService
} from '../../../../services';

import { NavigationStart, Router } from "@angular/router";
import moment from "moment";

@Component({
  selector: 'app-master-booking-edit',
  templateUrl: './master-booking-edit.component.html',
  styleUrls: ['./master-booking-edit.component.css']
})
export class MasterBookingEditComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;
  pageLoad: boolean = false;
  customerList = [];
  transportContactList = [];

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") closeModal: ElementRef;
  @Input() status: any;
  @Input() verify: boolean = false;
  @Input() booking: any;
  @Input() transportTypes = [];
  @Input() id = "modalMasterBookingEdit";

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private EmployeeService: EmployeeService,
    private CustomerInfoService: CustomerInfoService,
    private TransportMasterBookingService: TransportMasterBookingService,
    
    public UtilsService: UtilsService,
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModal.nativeElement.click();
      }
    });
    this.setForm();
  }

  setForm(){
    this.form = this.formBuilder.group({
      transport_masterbooking_id: [""],
      booking_type: [""],
      booking_sub_type: [""],
      booking_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      booking_time: [""],
      customer_id: [null, [Validators.required]],
      location: ["", [Validators.required]],
      extra_message: [""],
      quotation_info_doc_no: [""],
      job_order_info_doc_no: [""],
      transport_receive_info_doc_no: [""],
      transport_type_id: [""],
      contact_name: ["", [Validators.required]],
      contact_phonenumber: ["", [Validators.required]],
      messenger_id: [""],
      doc_status_id: [""]
    });
  }

  async ngOnInit() {
  }

  async ngOnChanges(changes: SimpleChanges) {
    this.pageLoad = false;
    await this.EmployeeService.load(null, { emp_department_id: 'TRAN_DEP' })
    .then(res=>{
      this.transportContactList = res.resultData || {}
      this.transportContactList = this.transportContactList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info.firstname_th} ${elem.user_info.lastname_th}`,
        }
      });
    })
    if(this.booking.customer_id){
      await this.CustomerInfoService.getById({customer_id: this.booking.customer_id}).then(res=>{
        this.customerList.push(
          {
            id: this.booking.customer_id,
            text: `[${res.resultData?.customer_code || ''}] ${res.resultData?.company_name || ''} ${res.resultData?.branch?" (" + res.resultData?.branch + ")": ""}`,
          }
        )
      })
    }

    this.form.patchValue(
    {...this.booking,
        booking_date: moment(this.booking.booking_date).format('YYYY-MM-DD')}
    );

    this.pageLoad = true;
  }

  async onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }

    let bookingResponse = await this.TransportMasterBookingService.update(this.form.getRawValue());
    if (bookingResponse.success) {
      this.save.emit({
        status: bookingResponse,
        closeModal: this.closeModal
      })
    } else {
      this.save.emit({
        status: bookingResponse,
        closeModal: this.closeModal
      })
    }
  }

  onClose() {
    this.setForm();
  }
}
