import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccInvoiceEditHistoryService } from 'src/app/services/invoice';

@Component({
  selector: 'app-invoice-history',
  templateUrl: './invoice-history.component.html',
  styleUrls: ['./invoice-history.component.css']
})
export class InvoiceHistoryComponent implements OnInit {
  submitted:boolean = false;
  pageLoad:boolean = true;

  memoList: any = [];

  acc_invoice_info_id: string;
  //any
  status: any;
  constructor(
    private route: ActivatedRoute,
    private AccInvoiceEditHistoryService: AccInvoiceEditHistoryService
  ) { }

  ngOnInit(): void {
    this.acc_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_invoice_info_id");
    this.getMemoList();
  }

  getMemoList(){
    this.pageLoad = true;
    this.memoList = [];

    this.AccInvoiceEditHistoryService.load(null, { acc_invoice_info_id : this.acc_invoice_info_id  })
    .then(async (res)=>{
      this.memoList = res.resultData;
      this.memoList.sort(function(a,b){
        if(a.created_at < b.created_at) return 1;
        if(a.created_at > b.created_at) return -1;
        return 0;
      });
      this.pageLoad = false;
    })
  }
}
