export class GetUserInfo {
  static readonly type = "[UserInfo] Get";
}

export class UpdateUserInfo {
  static readonly type = "[UserInfo] Update";

  constructor(public payload: any) {}
}

export class SetUserInfo {
  static readonly type = "[UserInfo] Set";

  constructor(public payload: any) {}
}
