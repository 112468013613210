import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccTaxInvoiceItemService } from 'src/app/services/tax-invoice';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccInvoiceItemService } from 'src/app/services/invoice';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AccCreditNoteInfoService, AccCreditNoteItemService } from 'src/app/services/credit-note';

@Component({
  selector: 'app-tax-invoice-item',
  templateUrl: './tax-invoice-item.component.html',
  styleUrls: ['./tax-invoice-item.component.css']
})
export class TaxInvoiceItemComponent implements OnInit {
  pageLoad = false;
  pageType: string = 'create';
  acc_tax_invoice_info_id: string;
  acc_payment_id: string;

  taxInvoiceItems = [];
  delelteTaxInvoiceItems: any = [];

  taxInvoice = null;
  @Input('state') state: any;
  @Output() done = new EventEmitter<any>();
  @Input('customer_info') customer_info: any;
  subject: Subject<string> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private AccInvoiceItemService: AccInvoiceItemService,
    private AccTaxInvoiceItemService: AccTaxInvoiceItemService,
    private AccCreditNoteInfoService: AccCreditNoteInfoService,
    private AccCreditNoteItemService: AccCreditNoteItemService,

    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) { 
    this.acc_tax_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_tax_invoice_info_id");

    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }

    this.route.queryParams.subscribe(params => {
      this.acc_payment_id = params['acc_payment_id'] || "";
    });
  }

  async ngOnInit() {
    this.pageLoad = false;

    if(this.acc_tax_invoice_info_id && this.acc_payment_id == ""){
      await this.AccTaxInvoiceItemService.load(null, {acc_tax_invoice_info_id: this.acc_tax_invoice_info_id})
      .then(async res=>{
        this.taxInvoiceItems = res.resultData || [];
        this.taxInvoiceItems = this.taxInvoiceItems.map(value=>{
          return {
            ...value,
            data: {
              ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
              quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
            }
          }
        });

        this.taxInvoiceItems.sort(function(a, b) {
          return a.order_by - b.order_by;
        });
        await this.sumCount();
      })
    }else if(this.acc_payment_id){
      let acc_invoice_info_id = this.acc_tax_invoice_info_id;
      await this.AccInvoiceItemService.load(null, {acc_invoice_info_id: acc_invoice_info_id})
      .then(async res=>{
        this.taxInvoiceItems = res.resultData || [];

        this.taxInvoiceItems = this.taxInvoiceItems.map(value=>{
          return {
            ...value,
            data: {
              ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
              quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
            }
          }
        });

        this.taxInvoiceItems.sort(function(a, b) {
          return a.order_by - b.order_by;
        });
        await this.sumCount();
      })
    }else{
      if(this.pageType == 'create' && this.state){
        await this.submitTaxInvoiceItem(this.state.data);
      }
    }

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );
  }

  async submitTaxInvoiceItem(event){
    if(event.length > 0){
      var findCreditNoteDiscount = null;

      await this.AccCreditNoteInfoService.load(null, {ref_acc_document_info_id: event[0].acc_invoice_info_id}).then(async res=>{
        findCreditNoteDiscount = res.resultData.find(item=> item.credit_note_type =='DISCOUNT' && item.ref_doc_type == 'INVOICE');
      });
      
      await Promise.all(event.map(async (item)=>{
        var acc_invoice_info_id = item.acc_invoice_info_id;
        var accCreditPartialList = [];

        await this.AccCreditNoteInfoService.load(null, {ref_acc_document_info_id: acc_invoice_info_id}).then(async res=>{
  
          var findCreditNotePartial = res.resultData.find(item=> item.credit_note_type =='PARTIALLY' && item.ref_doc_type == 'INVOICE');
  
          if(findCreditNotePartial){
            await this.AccCreditNoteItemService.load(null, {acc_credit_note_info_id: findCreditNotePartial.acc_credit_note_info_id }).then(res=>{
              if(findCreditNotePartial){
                accCreditPartialList = res.resultData || [];
              }
            })
          }
        });

        if(item.item_type=='product'){
          // let checkDuplicate = this.taxInvoiceItems.find(value=> value.product_id == item.product_id);
          let checkCreditNote = accCreditPartialList.find(acc => acc.product_id == item.product_id);

          if(!checkCreditNote){
            this.taxInvoiceItems.push(item);
          }
        }else{
          // if(this.state?.data){
          //   var checkDuplicate = this.taxInvoiceItems.find(value=> (value.quotation_item_id == item.quotation_mit_item_id || value.quotation_item_id == item.quotation_item_id));
          // }else{
          //   var checkDuplicate = this.taxInvoiceItems.find(value=> value.quotation_item_id == item.quotation_mit_item_id);
          // }
          let checkCreditNote = accCreditPartialList.find(acc => acc.quotation_item_id == item.quotation_item_id);
          
          if(!checkCreditNote){
            this.taxInvoiceItems.push(item);
          }
        }
      }));

      if(findCreditNoteDiscount){
        this.taxInvoiceItems.push({
          quotation_item_id: "",
          product_id: "",
          quotation_info_id: "",
          product_title: "ส่วนลดพิเศษ อ้างอิง: " + findCreditNoteDiscount.doc_no,
          product_description: "",
          original_price: 0,
          price: 0,
          quantity: 1,
          discount: findCreditNoteDiscount.difference,
          item_type: "product",
        });
      }
    }

    await this.sumCount();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.taxInvoiceItems, event.previousIndex, event.currentIndex);
  }

  select(taxInvoice){
    this.taxInvoice = taxInvoice;
  }

  async delete(closeModalEl){
    const index = this.taxInvoiceItems.indexOf(this.taxInvoice);
    this.delelteTaxInvoiceItems.push(this.taxInvoice);
    if (index > -1) {
      this.taxInvoiceItems.splice(index, 1);
    }
    await this.sumCount();
    closeModalEl.click();
  }

  onKeyup(){
    this.subject.next();
  }

  async sumCount(){
    this.taxInvoiceItems.map(item=>{
      item.price = this.utils.transformDecimal(this.utils.convertStingToNumber(item.price));
    })
    this.sentData();
  }

  async sentData(){
    this.done.emit({
      taxInvoiceItems: this.taxInvoiceItems,
      delelteTaxInvoiceItems: this.delelteTaxInvoiceItems
    });
  }
}
