<div class="container app-content overflow-container" *ngIf="canAccess && pageLoad">

    <div class="btn-section text-right border-bottom">
      <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(jobOrderInfo)">
          <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
      </button>
    </div>
  
    <div *ngFor="let page of pages; let i = index; let lastIndex = last">
      <div class="pdf" id="job_order_{{i}}">
        <div class="container mt-5">
          <div class="row">
            <table class="mx-auto table table-bordered">
              <thead *ngIf="jobOrderInfo && i == 0">
                <tr>
                  <th colspan="4">
                    <div class="row"> 
                      <div class="col-2 text-left" style="height: 40px;">
                        <img class="" src="/assets/app-assets/images/pdf/logo1.png" width="40px" height="40px">
                      </div>
                      <div class="col-8 text-center align-self-center">
                        <h3 class="mb-0">
                          ใบขอซื้อ (Purchase Requirement)
                        </h3>
                      </div>
                      <div class="col-2">
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="jobOrderInfo && i == 0">
                  <td colspan="2" class="text-center">
                    <div class="row form-group mx-sm-3 mb-2">
                      <label class="mt-2">เรื่อง :</label>
                      <div class="col-sm-10">
                          <input type="text" class="form-control line-input" placeholder="" value="ขอสอบเทียบเครื่องมือวัด">
                        
                      </div>
                    </div>
                    <div class="row form-group mx-sm-3 mb-2">
                      <label class="mt-2">เรียน :</label>
                      <div class="col-sm-10">
                        <input type="text" class="form-control line-input" placeholder="" value="คุณสิทธิ์ชัย เนตรประดิษฐ์">

                      </div>
                    </div>
                  </td>
                  <td colspan="2" class="text-left">
                    <div class="row form-group mx-sm-3 mb-2">
                      <label class="mt-2">PR No. :</label>
                      <div class="col-sm-10">
                        <input type="text" class="form-control line-input" placeholder="">
                      </div>
                    </div>
                    <div class="row form-group mx-sm-3 mb-2">
                      <label class="mt-2">วันที่เขียน :</label>
                      <div class="col-sm-10">
                        <input type="text" class="form-control line-input" placeholder="" [value]="jobOrderInfo.doc_date" >
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th class="text-center" scope="col">ลำดับ</th>
                  <th scope="col">รายการ / รายละเอียด</th>
                  <th class="text-right" scope="col">จำนวน</th>
                  <th class="text-center" scope="col">เหตุผล / หมายเหตุ</th>
                </tr>
                <!-- <tr *ngFor="let item of page; let j = index;" [hidden]="['ECL','ECS'].includes(jobOrderInfo.job_order_type)">
                  <td class="text-center" scope="col">{{item.index+1}}</td>
                  <td>
                    {{item.quotation_item_info?.product_title || item?.quotation_mit_item_info?.equipment_name}}
                  </td>
                  <td class="text-center">
                      {{item.quotation_item_info?.quantity || 1}}
                  </td>
                  <td class="text-center" scope="col"></td>
                </tr> -->
                <tr>
                  <td class="text-center" scope="col"></td>
                  <td>
                    รายการตามเอกสารแนบ
                  </td>
                  <td class="text-center">
                    
                  </td>
                  <td class="text-center" scope="col">
                    <span [hidden]="['PRD'].includes(jobOrderInfo.job_order_type)">
                      เนื่องจาก MIT ไม่สามารถสอบเทียบได้
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="text-center" scope="col"></td>
                  <td>
                    {{jobOrderInfo?.customer_address_info.address_name}} 
                  </td>
                  <td class="text-center">
                    
                  </td>
                  <td class="text-center" scope="col">
                  </td>
                </tr>
                <tr>
                  <td class="text-center" scope="col"></td>
                  <td>
                    QN: {{docNo}}
                  </td>
                  <td class="text-center">
                    
                  </td>
                  <td class="text-center" scope="col">
                  </td>
                </tr>
                <tr>
                  <td class="text-center"></td>
                  <td>
                  </td>
                  <td class="text-center">
                    <br>
                  </td>
                  <td class="text-center">
                  </td>
                </tr>
                <tr *ngIf="lastIndex">
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col">
                    <div class="row form-group mx-sm-3 mb-0">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                        <label class="form-check-label" for="flexCheckChecked">
                        เข้าระบบ ISO
                      </label>
                      </div>
                      <div class="form-check pl-5">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                        <label class="form-check-label" for="flexCheckChecked">
                          นอกระบบ ISO
                        </label>
                      </div>    
                    </div>
                  </td>
                </tr>
                <tr *ngIf="lastIndex">
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col">
                    <div class="row form-group mb-0 mx-sm-3">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                        <label class="form-check-label" for="flexCheckChecked">
                        ด่วนภายใน
                      </label>
                      </div>
                      <div class="col-sm-3">
                          <input type="text" class="form-control-prd line-input" placeholder="">
                      </div>
                      <label class="">วัน</label>
                      <div class="form-check pl-5">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                        <label class="form-check-label" for="flexCheckChecked">
                          ปกติ
                        </label>
                      </div>
                      <div class="col-sm-3">
                        <input type="text" class="form-control-prd line-input" placeholder="">
                      </div>    
                    </div>
                  </td>
                </tr>
                <tr *ngIf="lastIndex">
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col">
                    <div class="row form-group mb-0 mx-sm-3">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                        <label class="form-check-label" for="flexCheckChecked">
                        ทรัพย์สิน
                      </label>
                      </div>
                      <div class="form-check pl-5">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                        <label class="form-check-label" for="flexCheckChecked">
                          วัสดุสิ้นเปลือง
                        </label>
                      </div>    
                    </div>
                  </td>
                </tr>
                <tr *ngIf="lastIndex">
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col">
                    <div class="row form-group mb-0 mx-sm-3">
                      <div class="form-check">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                        <label class="form-check-label" for="flexCheckChecked">
                        ค่าบริการ
                      </label>
                      </div>
                      <div class="form-check pl-5">
                        <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                        <label class="form-check-label" for="flexCheckChecked">
                          ชดใช้ค่าเสียหาย
                        </label>
                      </div>    
                    </div>
                  </td>
                </tr>
                <tr *ngIf="lastIndex">
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col"></td>
                  <td class="text-center" scope="col">
                    <div class="row form-group mb-0 mx-sm-3">
                    <div class="form-check">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                    <label class="form-check-label" for="flexCheckChecked">
                      อื่นๆ
                    </label>
                    </div>
                      <label class="">ระบุ</label>
                      <div class="col-sm-9">
                          <input type="text" class="form-control-prd line-input" placeholder="">
                      </div>
                    </div>
                  </td>
                </tr>
                <tr *ngIf="lastIndex">
                  <td colspan="4" scope="col">
                    <div class="row form-group mx-sm-3">
                      <div class="col-4">
                        <div class="row form-group mx-sm-3 mb-0">
                          <label class="mt-2">ผู้ขอซื้อ: </label>
                          <div class="col-sm-8">
                              <input type="text" class="form-control line-input" placeholder="">
                          </div>
                        </div>
                        <div class="row form-group mx-sm-3 mb-2">
                          <div class="col-sm-2">
                          </div>
                          <label class="mt-2">(</label>
                          <div class="col-sm-7 d-flex justify-content-end">
                            <div class="line-input"></div>
                          </div>
                          <label class="mt-2">)</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row form-group mx-sm-3 mb-0">
                          <label class="mt-2">ผู้ทบทวน: </label>
                          <div class="col-sm-9">
                              <input type="text" class="form-control line-input" placeholder="">
                          </div>
                        </div>
                        <div class="row form-group mx-sm-3 mb-2">
                          <div class="col-sm-3">
                          </div>
                          <label class="mt-2">(</label>
                          <div class="col-sm-8 d-flex justify-content-end">
                            <div class="line-input"></div>
                          </div>
                          <label class="mt-2">)</label>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="row form-group mx-sm-3">
                          <div class="text-center col-10 mx-auto">
                            <div class="form-check form-check-inline mt-2">
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                              <label class="form-check-label" for="flexCheckChecked">
                              ไม่อนุมัติ
                            </label>
                            </div>
                            <div class="form-check form-check-inline mt-2">
                              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked">
                              <label class="form-check-label" for="flexCheckChecked">
                                อนุมัติ
                              </label>
                            </div> 
                          </div>     
                        </div>
                        <div class="row">
                          <div class="mx-auto col-sm-10">
                            <input type="text" class="form-control line-input" placeholder="">
                          </div>
                          <br>
                          <label class="mx-auto mt-2">กรรมการผู้จัดการ</label>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>    
      </div>
      
      <div class="btn-section text-right border-bottom">
      </div>
      <br>
    </div>
    
  </div>
  
  
<div class="container app-content" *ngIf="canAccess == false && pageLoad">
  <div class="text-center">
      <h2>ไม่สามารถเข้าถึงได้</h2>
      <h5>โปรดติดต่อพนักงานขาย</h5>
  </div>
</div>