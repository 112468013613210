import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "app-confirm-delete",
  templateUrl: "./confirm-delete.component.html",
  styleUrls: ["./confirm-delete.component.css"],
})
export class ConfirmDeleteComponent implements OnInit {
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") private closeModal: ElementRef;
  @Input() status: any;
  @Input() id = "modalConfirmDelMsg";
  constructor() {}

  ngOnInit(): void {}

  async onSave() {
    this.save.emit(this.closeModal.nativeElement);
  }

  onClose() {
    this.close.emit(null);
  }
}
