import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {MatTooltipModule} from '@angular/material/tooltip';
import {
    ICheckDirective,
    ModalDirective,
    DatePickerDirective,
    DateRangePickerDirective,
    SidebarDirective,
    PerfectScrollbarDirective,
    QuillDirective,
    TriggerSidebarDirective,
    DateTimePickerDirective,
    GoogleMapDirective,
    TagInputDirective,
    CurrencyInputDirective,
    ToggleSwitchDirective,
    DropdownSelectDirective,
    TimePickerDirective,
} from "./directive";
import {
  ShowErrorsComponent,
  ConfirmDeleteComponent,
  FormGroupComponent,
  PaginatorComponent,
  ResponseStatusComponent,
  SearchBoxComponent,
  EmployeeSelectComponent,
  FileListComponent,
  LoadingComponent,
} from "./components";

import { ThaiDatehPipe, DashhPipe } from "./pipe";
import { NgSelect2Module } from 'ng-select2';
import { FullCalendarModule } from '@fullcalendar/angular';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DragDropModule } from '@angular/cdk/drag-drop';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  declarations: [
    ShowErrorsComponent,
    ConfirmDeleteComponent,
    FormGroupComponent,
    PaginatorComponent,
    ResponseStatusComponent,
    SearchBoxComponent,
    EmployeeSelectComponent,
    FileListComponent,
    LoadingComponent,

    ICheckDirective,
    ModalDirective,
    DatePickerDirective,
    DateRangePickerDirective,
    SidebarDirective,
    PerfectScrollbarDirective,
    QuillDirective,
    TriggerSidebarDirective,
    DateTimePickerDirective,
    GoogleMapDirective,
    TagInputDirective,
    CurrencyInputDirective,
    ToggleSwitchDirective,
    DropdownSelectDirective,
    TimePickerDirective,

    ThaiDatehPipe, 
    DashhPipe
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    ShowErrorsComponent,
    ConfirmDeleteComponent,
    FormGroupComponent,
    PaginatorComponent,
    ResponseStatusComponent,
    SearchBoxComponent,
    EmployeeSelectComponent,
    FileListComponent,
    LoadingComponent,

    ICheckDirective,
    ModalDirective,
    DatePickerDirective,
    DateRangePickerDirective,
    SidebarDirective,
    PerfectScrollbarDirective,
    QuillDirective,
    TriggerSidebarDirective,
    DateTimePickerDirective,
    GoogleMapDirective,
    TagInputDirective,
    CurrencyInputDirective,
    ToggleSwitchDirective,
    DropdownSelectDirective,
    TimePickerDirective,

    ThaiDatehPipe, 
    DashhPipe,

    ReactiveFormsModule,
    FormsModule,
    NgSelect2Module,
    FullCalendarModule,
    SignaturePadModule,
    DragDropModule,
    MatTooltipModule
  ]
})

export class SharedModule {}
