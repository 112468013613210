<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="return-item-edit"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreateModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreateModal">
            บันทึกเครื่องมือ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal"  autocomplete="off" class="form" [formGroup]="form">
            <form-group
                [control]="form.controls.item_status_id"
                [submitted]="submitted"
            >
            <label class="label-control" for="description">
                สถานะ <span class="danger">*</span>
            </label>
                <select
                class="form-control"
                id="item_status_id"
                formControlName="item_status_id"
                >
                <option value="">โปรดเลือก...</option>
                <option
                    *ngFor="let status of statusList"
                    [value]="status.item_status_id"
                    >{{ status.item_status_name_th }}</option
                >
                </select>
            </form-group>

            <form-group
            [control]="form.controls.note"
            [submitted]="submitted">
            <label class="label-control" for="note">
              หมายเหตุ <span class="danger">*</span> 
            </label>
            <textarea
                type="text"
                rows="3"
                placeholder="หมายเหตุ"
                id="note"
                class="form-control"
                formControlName="note"
            ></textarea>
            </form-group>

            <label for="file" class="mr-1">แนบไฟล์</label>

            <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                <span style="cursor: pointer;" (click)="downloadFile(file)">
                    {{file.transport_receive_item_file_id? file.file_name: file.name}}
                </span>
            </span>
      
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ตกลง
        </button>
      </div>
    </div>
  </div>
</div>
