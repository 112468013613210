import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EmployeeService } from 'src/app/services';
import { UserInfoState } from 'src/app/store/user/user.state';
import { JobOrderTrainingEngineerService } from '../../../../services/job-orders/index';
@Component({
  selector: 'app-training-engineer',
  templateUrl: './training-engineer.component.html',
  styleUrls: ['./training-engineer.component.css']
})
export class TrainingEngineerComponent implements OnInit {
  public form: FormGroup;
  public trainingList: FormArray;

  public employeeList = [];
  public deleteTrainingList = [];
  public pageLoad: boolean = false;

  public job_order_info_id: string;
  public pageType: string;

  @Input("submitted") submitted: boolean;
  @Input("jobOrderInfo") jobOrderInfo: any;
  @Input("canCompleted") canCompleted: boolean;
  @Output() public done = new EventEmitter<any>();
  @Input("canSaveTraining") canSaveTraining: boolean;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private EmployeeService: EmployeeService,
    private JobOrderTrainingEngineerService: JobOrderTrainingEngineerService,
    private route: ActivatedRoute
  ) {
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  async ngOnInit() {
    this.createForm();
    this.EmployeeService.load(null, { emp_department_id: 'CALIB_DEP' }).then(res=>{
      this.employeeList = res.resultData;
      this.employeeList = this.employeeList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info?.firstname_th} ${elem.user_info?.lastname_th}`,
        }
      })
    });

    if(this.jobOrderInfo?.doc_status_id == 'WAITING_FOR_CONFIRM'){
      for(var i = 0; i < this.jobOrderInfo.no_of_people; i++){
        this.trainingList.push(this.createTrainingItem());
      }
    }
    await this.getTraining();
    this.form.controls.trainings.valueChanges.subscribe(
      (value) => {
        this.sentData();
      }
    );
    this.sentData();
    this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      if(userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP")){
        this.pageType = 'show';
      }
    });
  }

  async getTraining(){
    await this.JobOrderTrainingEngineerService.load(null, 
      { 
        job_order_info_id: this.job_order_info_id
      }).then(async (res)=>{
        
        await Promise.all(res.resultData.map(async (v: any) =>{
          this.trainingList.push(this.createTrainingItem(v));
        }));
        this.pageLoad = true;
      })
  }

  public createForm(){
    this.form = this.formBuilder.group({
      trainings: this.formBuilder.array([]),
    });
    this.trainingList = 
    this.form.get('trainings') as FormArray;
  }

  get trainingItemFormGroup() {
    return this.form.get('trainings') as FormArray;
  }

  public createTrainingItem(data:any = {}): FormGroup {
    return this.formBuilder.group({
      job_order_training_engineer_id: this.formBuilder.control(data.job_order_training_engineer_id || null),
      job_order_info_id: this.formBuilder.control(this.job_order_info_id),
      user_id: this.formBuilder.control(data.user_id || "", [Validators.required]),
      status: this.formBuilder.control(data.status || "WAITING")
    });
  }

  public addTraining(){
    this.trainingList.push(this.createTrainingItem());
    this.sentData();
  }

  public removeTraining(index: number, item: any){
    this.trainingList.removeAt(index);
    this.deleteTrainingList.push(item.value);
    this.sentData();
  }

  public sentData(){
    this.done.emit({
      form: this.form,
      deleteTrainingList: this.deleteTrainingList
    });
  }

  public setStatus(status, i){
    this.trainingList.controls[i].get('status').setValue(status);
  }

  public getEmplyeeName(id: string): string{
    let user = this.employeeList.find(emp=> emp.id == id);
    if(user) return user.text || "";
  }
}
