import { environment } from "../../environments/environment";
// show-errors.component.ts
import {
  Component,
  EventEmitter,
  Output,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
} from "@angular/core";
declare const $;
@Component({
  selector: "employee-select",
  template: `
    <select
      #select2
      class="form-control"
      [id]="id"
      (change)="valueChanged.emit($event.target.value)"
      [disabled]="disabled"
    >
      <option value=""></option>
    </select>
  `,
})
export class EmployeeSelectComponent implements OnInit {
  @Output() valueChanged = new EventEmitter<any>();
  @Input() placeholder = "กรุณาเลือกข้อมูล";
  @Input() selected = "";
  @Input() exclude_user_ids = [];
  id = "select2_" + new Date().getTime();
  @Input() emp;
  @Input("disabled") disabled = false;
  @ViewChild("select2") select2: ElementRef;
  @Output() instance = new EventEmitter();
  ngOnInit() {
    setTimeout(() => {
      let select2Options = {
        allowClear: true,
        language: {
          errorLoading: function () {
            return "ไม่สามารถค้นข้อมูลได้";
          },
          inputTooLong: function (n) {
            return "โปรดลบออก " + (n.input.length - n.maximum) + " ตัวอักษร";
          },
          inputTooShort: function (n) {
            return (
              "โปรดพิมพ์เพิ่มอีก " + (n.minimum - n.input.length) + " ตัวอักษร"
            );
          },
          loadingMore: function () {
            return "กำลังค้นข้อมูลเพิ่ม…";
          },
          maximumSelected: function (n) {
            return "คุณสามารถเลือกได้ไม่เกิน " + n.maximum + " รายการ";
          },
          noResults: function () {
            return "ไม่พบข้อมูล";
          },
          searching: function () {
            return "กำลังค้นข้อมูล…";
          },
          removeAllItems: function () {
            return "ลบรายการทั้งหมด";
          },
        },

        ajax: {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
          delay: 250,
          url: environment.apiUrl + "/employees",
          dataType: "json",
          data: (params) => {
            var query = {
              search_all: params.term,
              exclude: this.exclude_user_ids.length
                ? this.exclude_user_ids.join(",")
                : undefined,
              emp_department_id: 'SALES_DEP'
            };
            // Query parameters will be ?search=[term]&type=public
            return query;
          },
          processResults: (data) => {
            if (!this.disabled) {
              let items = data.resultData.map((emp) => {
                return {
                  id: emp.user_info.user_id,
                  text: `[${emp.emp_code}] ${emp.user_info.firstname_th} ${emp.user_info.lastname_th}`,
                };
              });
              // Transforms the top-level key of the response object from 'items' to 'results'
              return {
                results: items,
              };
            }
            return { results: [] };
          },
        },
        placeholder: this.placeholder,
      };

      $("#" + this.id)
        .select2(select2Options)
        .on("change", (e) => {
          this.valueChanged.emit(e.target.value);
        })
        .on("select2:clear", () => {
          // $("#" + this.id)
          //   .val(null)
          //   .trigger("change");
          // this.reset();
        });

      $("#" + this.id).trigger({
        type: "select2:select",
        params: {
          data: this.selected,
        },
      });
      this.instance.emit($("#" + this.id));
    }, 500);
  }

  ngAfterViewInit() {
    this.reset();
  }

  reset() {
    if (this.select2) {
      if (this.emp && this.emp.emp_info) {
        let option = {
          id: this.emp.user_id,
          text: `[${this.emp.emp_info?.emp_code}] ${this.emp.firstname_th} ${this.emp.lastname_th}`,
        };
        let data = new Option(option.text, option.id, true, true);
        let select2 = $(this.select2.nativeElement);

        select2.append(data);
        select2.trigger({
          type: "select2:select",
          params: {
            data: data,
          },
        });
      }
    }
  }

  ngOnChanges(changes) {
    if (changes.disabled) {
      // this.reset();
      $("#" + this.id).prop("disabled", changes.disabled.currentValue);
    }

    if (changes.emp) {
      if (!changes.emp.currentValue) {
        $("#" + this.id)
          .val(null)
          .trigger("change");
      } else {
        this.reset();
      }
    }
  }
}
