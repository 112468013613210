<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">ปฏิทินของฉัน</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ระบบบริหารงานขาย</a>
              </li>
              <li class="breadcrumb-item active">ปฏิทินของฉัน</li>
            </ol>
          </div>
        </div>
      </div>
    </div>

    <div class="content-body">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <full-calendar
                  *ngIf="!isLoading"
                  [header]="{
                    left: 'today prev,next',
                    center: 'title',
                    right: ''
                  }"
                  defaultView="dayGridMonth"
                  [plugins]="calendarPlugins"
                  [events]="events"
                  eventTextColor="#FFF"
                  (eventRender)="eventRender($event)"
                  [eventTimeFormat]="{
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                    meridiem: false
                  }"
                  (eventClick)="seeActivity($event)"
                ></full-calendar>

                <button
                  #toggleSidebar
                  type="button"
                  trigger-sidebar
                  data-target="#customer-activity-edit"
                  class="d-none"
                ></button>
                <app-customer-activity-edit
                  [see-company-name]="true"
                  [can-edit-customer]="true"
                  [activity-types]="activityTypes"
                  [customer-activity]="customer_activity"
                  (done)="getActivity($event)"
                ></app-customer-activity-edit>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
