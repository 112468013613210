import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CustomerAddressService } from 'src/app/services';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import domtoimage from 'dom-to-image-more'
import jsPDF from 'jspdf'; 
import THBText from 'thai-baht-text'
import { AccCreditNoteInfoService, AccCreditNoteItemService } from 'src/app/services/credit-note';
import { AccDebitNoteInfoService, AccDebitNoteItemService } from 'src/app/services/debit-note';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';
import { AccInvoiceInfoService } from 'src/app/services/invoice';

@Component({
  selector: 'app-credit-note',
  templateUrl: './credit-note.component.html',
  styleUrls: ['./credit-note.component.css']
})
export class CreditNoteComponent implements OnInit {
  items = [];
  pages = [];

  id: string = "";
  type: string = "";

  customer_info = null;
  info = null;
  count = 0;
  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private CustomerAddressService: CustomerAddressService,
    private AccDebitNoteInfoService: AccDebitNoteInfoService,
    private AccDebitNoteItemService: AccDebitNoteItemService,
    private AccCreditNoteInfoService: AccCreditNoteInfoService,
    private AccCreditNoteItemService: AccCreditNoteItemService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    
    public utils: QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) { 
    this.id = 
    this.route.snapshot.paramMap.get("id");
    this.type = 
    this.route.snapshot.paramMap.get("type");
  }

  async ngOnInit() {
    if(this.type == 'credit'){
      await this.AccCreditNoteInfoService.getById({acc_credit_note_info_id: this.id})
      .then(async res=>{
        this.info = res.resultData || {};
        this.titleService.setTitle(this.info.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
        if(this.info.customer_address_id){
          await this.CustomerAddressService.getById({customer_address_id: this.info.customer_address_id})
          .then(res=>{
            this.info['customer_address'] = this.utils.genAddress(res.resultData);
            this.info['customer_address_info'] = res.resultData;
          })
        }

        if(this.info.ref_doc_type == 'TAX_INVOICE'){
          await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: this.info.ref_acc_document_info_id}).then(res=>{
            this.info['ref_acc_document_info'] = res.resultData;
          })
        }else{
          await this.AccInvoiceInfoService.getById({acc_invoice_info_id: this.info.ref_acc_document_info_id}).then(res=>{
            this.info['ref_acc_document_info'] = res.resultData;
          })
        }

        await this.AccCreditNoteItemService.load(null, {acc_credit_note_info_id: this.id})
        .then(async res=>{
          this.items = res.resultData || [];
        })
        for (var i=0; i<this.items.length; i++) {
          this.items[i].index = i + 1;
        }
  
        for (var i=0; i<this.items.length; i+=20) {
          this.pages.push(this.items.slice(i, i+20));
        }
      })
    }else{
      await this.AccDebitNoteInfoService.getById({acc_debit_note_info_id: this.id})
      .then(async res=>{
        this.info = res.resultData || {};
        this.titleService.setTitle(this.info.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
        if(this.info.customer_address_id){
          await this.CustomerAddressService.getById({customer_address_id: this.info.customer_address_id})
          .then(res=>{
            this.info['customer_address'] = this.utils.genAddress(res.resultData);
            this.info['customer_address_info'] = res.resultData;
          })
        }

        if(this.info.ref_doc_type == 'TAX_INVOICE'){
          await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: this.info.ref_acc_document_info_id}).then(res=>{
            this.info['ref_acc_document_info'] = res.resultData;
          })
        }else{
          await this.AccInvoiceInfoService.getById({acc_invoice_info_id: this.info.ref_acc_document_info_id}).then(res=>{
            this.info['ref_acc_document_info'] = res.resultData;
          })
        }

        await this.AccDebitNoteItemService.load(null, {acc_debit_note_info_id: this.id})
        .then(async res=>{
          this.items = res.resultData || [];
        })
        for (var i=0; i<this.items.length; i++) {
          this.items[i].index = i + 1;
        }
  
        for (var i=0; i<this.items.length; i+=20) {
          this.pages.push(this.items.slice(i, i+20));
        }
      })
    }
    
    // console.log(this.info)
    // console.log(this.pages)
  }

  async print(info){
    const note_sections = document.querySelectorAll('.pdf');
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let page = 1;
    let perPage = Array.prototype.slice.call(note_sections,0).length;
    
    let noteList = [];
    await Promise.all(Array.prototype.slice.call(note_sections,0).map(async (value, index) =>{
      let note_section = document.getElementById('note_' + (index));
      await domtoimage.toJpeg(note_section, {
        background: 'white', 
        allowTaint: true,
        width: note_section.clientWidth * 2,
        height: note_section.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        noteList.push({index: index, canvas: canvas, note_section: note_section});
      })
    }));
    noteList.sort(function(a, b){return a.index - b.index});
    await Promise.all(noteList.map(async (value, index) =>{

      var imgWidth = 208;
      var imgHeight = value.note_section.clientHeight * imgWidth / value.note_section.clientWidth;
      if((index) != 0) doc.addPage('a4');
      doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);
      doc.setFontSize(8);
      doc.text(page + ' /  ' + perPage, 200, 285);
      page = page+1;
    }));

    setTimeout(() => {
      doc.save(info.doc_no);
    }, 1000);
  }
}
