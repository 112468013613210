import { 
  Component, 
  OnInit, 
  Output, 
  ViewChild, 
  EventEmitter,
  Input,
  SimpleChanges
} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-transport-receive-return-signature',
  templateUrl: './transport-receive-return-signature.component.html',
  styleUrls: ['./transport-receive-return-signature.component.css']
})
export class TransportReceiveReturnSignatureComponent implements OnInit {
  isComplete: boolean = true;
  check: boolean = false;
  
  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  signaturePadOptions: Object = { 
    'minWidth': 5,
    'canvasWidth': 750,
    'canvasHeight': 150
  };
  @Input() receiveItem: any;
  @Input() submitted: any;
  @Output() done = new EventEmitter<any>();

  storeReceiveItems = [];
  constructor() {}

  ngOnInit(){}

  ngOnChanges(changes: SimpleChanges){
    this.isComplete = true;
    if(changes.receiveItem?.currentValue){
      this.receiveItem = changes.receiveItem.currentValue.receiveItems;
    }

    let result = this.receiveItem.find(item => item.item_status_id == "RECEIVED");
    if(!result){
      this.isComplete = false;
    }
  }

  ngAfterViewInit() {
    this.reset();
  }

  drawComplete() {
    this.done.emit(this.signaturePad.toDataURL());
  }

  checkValidate(): boolean{
    return (this.isComplete === false && this.signaturePad.isEmpty())
  }

  drawStart() {}
  isCheckbox(){
    if(this.check === true){
      this.signaturePad.on();
    }else{
      this.signaturePad.off();
      this.signaturePad.clear();
      this.done.emit(null);
    }
  }

  reset(){
    this.signaturePad.set('minWidth', 2);
    this.signaturePad.clear();
    this.done.emit(null);
  }
}
