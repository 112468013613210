<div class="app-content">
    <div class="container overflow-container" *ngIf="canAccess">
        <div class="btn-section text-right border-bottom">
            <!-- <button type="button" class="btn btn-blue px-2 mx-2" (click)="print(quotationInfo)">
                <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
            </button> -->

            <div class="row">
                <div class="col-7">
        
                </div>
                <div class="col-5 content-header-right">
                  <div class="row">
                    <div class="col-3 pr-0 title">
                      หน้าละ
                    </div>
                    <div class="col-4 pr-0">
                      <div class="form-group">
                        <select class="form-control text-right" [(ngModel)]="itemPerPage" (change)="setPage()">
                          <option [value]="page" *ngFor="let page of pageList;">{{page}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-5 pl-0">
                       
                      <button type="button" class="btn btn-blue mr-2" (click)="print(quotationInfo)">
                        <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
                    </button>
                    </div>
                  </div>
                </div>
              </div>
        </div>
        <div *ngFor="let product of productList; let i = index; let lastIndex = last">
            <div class="product-pdf" id="product_section_{{i}}">

                <div class="row">
                    <div class="col-2 text-center p-0">
                        <img src="/assets/app-assets/images/pdf/logo1.png" width="120px" height="120px">
                    </div>
                    <div class="col-8 text-center pt-4 px-0">
                        <h1 class="font-weight-bold">MIRACLE INTERNATIONAL TECHNOLOGY CO., LTD.</h1>
                        <p class="font-weight-bold">214 Bangwaek Rd., Bangpai, Bangkae, Bangkok 10160 Tel: 0-2865-4647-8 Fax: 0-2865-4649
                        </p>
                    </div>
                    <div class="col-2 text-right p-0">
                        <img src="/assets/app-assets/images/pdf/icon2.jpg" width="105px" height="105px">
                        <img src="/assets/app-assets/images/pdf/icon3.png" width="105px" height="105px">
                    </div>
                </div>
                <p class="text-center">Web-site : http://www.mit.in.th  E-mail : info@mit.in.th Tax ID.: 0-1055-45112-91-9 (Head Office)</p>
               
                <h2 class="text-center text-header">QUOTATION / SERVICE REQUEST FORM</h2>
                <h4 class="text-center text-header">{{quotationInfo?.sub_header}}</h4>
                <table class="table table-bordered">
                    <tr>
                        <td class="text-left" width="60%">
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Customer / ลูกค้า:</div>
                                </div>
                                <div class="col-9 col-sm-9 col-md-9 pl-0">
                                    <div class="font-weight-normal">{{quotationInfo?.customer_address_info?.address_name}}
                                        ({{quotationInfo?.customer_info?.customer_code}})
                                    </div>
                                </div>
                            </div>
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Address / ที่อยู่:</div>
                                </div>
                                <div class="col-9 col-sm-9 col-md-9 pl-0">
                                    <div class="font-weight-normal ">{{quotationInfo?.address}}
                                    </div>
                                </div>
                            </div>
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Tel No. / โทร:</div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 pl-0">
                                    <div class="font-weight-normal">
                                      {{quotationInfo?.customer_address_info.tel_1}}
                                      <span *ngIf="quotationInfo?.customer_address_info.tel_2">
                                        , {{quotationInfo?.customer_address_info.tel_2}}
                                      </span>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2">
                                    <div class="font-weight-bold">Fax:</div>
                                </div>
                                <div class="col-3 col-sm-3 col-md-3 pl-0">
                                    <div class="font-weight-normal">
                                        {{quotationInfo?.customer_address_info.fax_1}}
                                        <span *ngIf="quotationInfo?.customer_address_info.fax_2">
                                          , {{quotationInfo?.customer_address_info.fax_2}}
                                        </span>
                                      </div>
                                </div>
                            </div>
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Attn / เรียน:</div>
                                </div>
                                <div class="col-9 col-sm-9 col-md-9 pl-0">
                                    <div class="font-weight-normal">
                                        <span class="pl-0" *ngFor="let item of quotationContactList; let i = index;">
                                            {{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}}
                                            <span class="pl-0" *ngIf="quotationContactList?.length != i+1">,</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Tel No. / โทร:</div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 pl-0">
                                    <div class="font-weight-normal">
                                        <span class="pl-0" *ngFor="let item of quotationContactList; let i = index;">
                                            {{item?.customer_contact_info?.phonenumber}}
                                            <span class="pl-0" *ngIf="quotationContactList?.length != i+1">,</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2">
                                    <div class="font-weight-bold">E-mail:</div>
                                </div>
                                <div class="col-3 col-sm-3 col-md-3 pl-0">
                                    <div class="font-weight-normal">
                                        <span class="pl-0" *ngFor="let item of quotationContactList; let i = index;">
                                            {{item?.customer_contact_info?.email}}
                                            <span class="pl-0" *ngIf="quotationContactList?.length != i+1">,</span>
                                        </span>
                                    </div>
                                </div>
        
                            </div>
                        </td>
                        <td class="text-left" width="40%">
                            <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <div class="font-weight-bold">Quotation No:</div>
                                </div>
                                <div class="col-7 col-sm-7 col-md-7">
                                    <div class="font-weight-normal">{{quotationInfo?.doc_no}}</div>
                                </div>
                            </div>
                            <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <div class="font-weight-bold">Date / วันที่:</div>
                                </div>
                                <div class="col-7 col-sm-7 col-md-7">
                                    <div class="font-weight-normal">
                                        {{quotationInfo?.doc_date | date: 'dd/MM/yyyy'}}</div>
                                </div>
                            </div>
                            <div style="padding-left: 0px;padding-bottom: 5px;" class="row align-items-end">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <div class="font-weight-bold">Payment terms / เงื่อนไขการชำระเงิน:</div>
                                </div>
                                <div class="col-7 col-sm-7 col-md-7 align-items-end">
                                    <div class="font-weight-normal">{{quotationInfo?.customer_info?.credit_term_day || '0'}} วัน
                                    </div>
                                </div>
                            </div>
                            <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <div class="font-weight-bold">ยืนยันราคา:</div>
                                </div>
                                <div class="col-7 col-sm-7 col-md-7">
                                    <div class="font-weight-normal">
                                        {{quotationInfo?.doc_validdate | date: 'dd/MM/yyyy'}}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
        
                <table class="table table-bordered">
                    <tr>
                        <th class="text-center form-group text-bold" width="5%">Item <br> ลําดับ</th>
                        <th class="text-center form-group text-bold" [ngStyle]="{'width':isShowDiscount() ? '40%' : '50%' }">
                            Description <br> รายการ
                        </th>
                        <th class="text-center form-group text-bold" width="5%">Quantity <br> จํานวน</th>
                        <th class="text-center form-group text-bold" width="10%">Price/Unit <br> ราคาต่อหน่วย (บาท)</th>
                        <th class="text-center form-group text-bold" width="10%" *ngIf="isShowDiscount()">
                            Discount<br> ส่วนลด (%)
                        </th>
                        <th class="text-center form-group text-bold" width="10%">Total amount<br> จํานวนเงิน (บาท)</th>
                    </tr>
                    <tr *ngFor="let item of product; let i = index;">
                        <td class="text-center">{{item.index}}</td>
                        <td class="text-left">
                            <div *ngIf="item.item_type === 'product'">
                                {{item.product_title? item.product_title: '-'}}
                                <div *ngIf="item.product_description">
                                    <small>
                                        {{item.product_description}}
                                    </small>
                                </div>
                            </div>
                            <div *ngIf="item.item_type === 'description' || item.item_type === 'note'">
                                {{item.product_description? item.product_description: '-'}}
                            </div>
                            
                            <div *ngIf="item.item_type === 'calibration_service'">
                                ค่าบริการสอบเทียบ(รายละเอียดตามใบแจ้งรายการแนบท้าย)
                            </div>
                        </td>
                        <td class="text-center">
                            <div *ngIf="item.item_type !== 'description' && item.item_type !== 'note'">
                                {{item.quantity}}
                            </div>
                        </td>
                        <td class="text-right">
                            <div *ngIf="item.item_type !== 'description' && item.item_type !== 'note'">
                                {{item.price | number : '1.2-2'}}
                            </div>
                        </td>
                        <td class="text-right" *ngIf="isShowDiscount()">
                            <div *ngIf="item.item_type === 'product'">
                                {{item.discount | number : '1.2-2'}}
                            </div>
                        </td>
                        <td class="text-right">
                            <div *ngIf="item.item_type !== 'description' && item.item_type !== 'note'">
                                {{calculate.sumProduct(item) | number : '1.2-2'}}
                            </div>
                        </td>
                    </tr>
                    <tr *ngFor="let item of emptyList; let i = index;">
                        <td class="text-center" style="height: 38px;"></td>
                        <td class="text-left"></td>
                        <td class="text-center"></td>
                        <td class="text-right"></td>
                        <td class="text-right" *ngIf="isShowDiscount()"></td>
                        <td class="text-right"></td>
                    </tr>
                    <tr *ngIf="lastIndex">
                        <td class="text-center" [attr.colspan]="2"></td>
                        <td class="text-right text-bold" [attr.colspan]="isShowDiscount() == true? 3:2">Total / รวมเป็นเงิน</td>
                        <td class="text-right">{{count | number : '1.2-2'}}</td>
                    </tr>
                    <tr *ngIf="quotationInfo?.discount != 0 && lastIndex">
                        <td class="text-center" [attr.colspan]="2"></td>
                        <td class="text-right text-bold" [attr.colspan]="isShowDiscount() == true? 3:2">Discount / ส่วนลดเพิ่มเติม</td>
                        <td class="text-right">{{quotationInfo?.discount | number : '1.2-2'}}</td>
                    </tr>

                    <tr *ngIf="lastIndex">
                        <td class="text-center" [attr.colspan]="2"></td>
                        <td class="text-right text-bold" [attr.colspan]="isShowDiscount() == true? 3:2">Vat {{quotationInfo?.tax}}% / ภาษีมูลค่าเพิ่ม
                            {{quotationInfo?.tax}}%</td>
                        <td class="text-right" *ngIf="quotationInfo">
                            {{calculate.sumProductWithTax(count, quotationInfo) | number : '1.2-2'}}</td>
                    </tr>
                    <tr *ngIf="lastIndex">
                        <td class="text-center" [attr.colspan]="2">({{quotationInfo?.THBText}})</td>
                        <td class="text-right text-bold" [attr.colspan]="isShowDiscount() == true? 3:2">Grand total / รวมทั้งสิ้น</td>
                        <td class="text-right">{{quotationInfo?.grand_total | number : '1.2-2'}}</td>
                    </tr>
                </table>

                <div class="ml-1" *ngIf="noteList.length > 0  && lastIndex">
                    <div class="text-left text-bold">Remarks 1</div>
                    <ng-container  class="text-left" *ngFor="let note of noteList">
                        <p class="custom-bullet" *ngIf="note.note_type =='standard_in'">
                            {{note?.quotation_note}}
                        </p>
                    </ng-container>
        
                    <div class="text-left text-bold pt-1">Remarks 2</div>
                    <ng-container  class="text-left" *ngFor="let note of noteList">
                        <p class="custom-bullet" *ngIf="note.note_type =='standard_out'">
                            {{note?.quotation_note}}
                        </p>
                    </ng-container>
                </div>
                <div class="ml-1" *ngIf="noteList.length === 0 && lastIndex">
                    <div class="text-left text-bold">Remarks</div>
                    <p class="text-left">
                        -
                    </p>
                </div>
                <br>
        
                <table class="table table-bordered mb-0" *ngIf="employeeShow == false && lastIndex">
                    <tr>
                        <td class="pb-0" width="33.33%" colspan="2">
                            <div class="row pl-0">
                                <div class="col-12 col-sm-12 col-md-12 mb-5">
                                    <div class="font-weight-normal">The calibration schedule / กำหนดการสอบเทียบ</div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 mt-5 mb-2">
                                    Date / วันที่: <span>.........</span><span>/</span><span>...............</span><span>/</span><span>........................</span>
                                </div>
                            </div>
                        </td>
                        <td class="pb-0" width="33.33%" colspan="2">
                            <div class="row pl-0">
                                <div class="col-12 col-sm-12 col-md-12 mb-2">
                                    <div class="font-weight-normal">
                                        Please fax confirm to calibrate and send the instrument before the date / กรุณาแฟกซ์ยืนยันการสอบเทียบ และส่งเครื่องมือมาภายในวันที่
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 mt-5">
                                    Date / วันที่: <span>.........</span><span>/</span><span>...............</span><span>/</span><span>........................</span>
                                </div>
                            </div>
                        </td>
                        <td class="pb-0" width="33.33%" colspan="2">
                            <div class="row pl-0">
                                <div class="col-12 col-sm-12 col-md-12 mb-4">
                                    <div class="font-weight-normal">For customer confirm / สำหรับลูกค้าเพื่อยืนยันการสอบเทียบ</div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 mb-1 text-center text-dark">
                                    <hr />
                                    <div class="font-weight-normal">(ตัวบรรจง)</div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12">
                                    Date / วันที่: <span>.........</span><span>/</span><span>...............</span><span>/</span><span>........................</span>
                                </div>
                            </div>
                           
                        </td>
                    </tr>
                </table>

                <table class="table table-bordered" *ngIf="employeeShow == false && lastIndex">
                    <tr class="pb-0" style="border: 0 !important;">
                        <td class="pb-0" width="50%" colspan="3" 
                            style="border-top: 0 !important;
                            border-bottom: 0 !important;
                            padding-bottom: 0px;">
                            <div class="row pl-0">
                                <div class="col-12 col-sm-12 col-md-12">
                                    <div class="font-weight-normal">Issued by / ออกโดย:</div>
                                </div>
                            </div> 
                        </td>
                        <td class="pb-0" width="50%" colspan="3" 
                            style="border-top: 0 !important;
                            border-bottom: 0 !important;
                            padding-bottom: 0px;">
                            <div class="row pl-0">
                                <div class="col-12 col-sm-12 col-md-12">
                                    <div class="font-weight-normal">Approved by / อนุมัติโดย:</div>
                                </div>
                            </div> 
                        </td>
                    </tr>
                    <tr class="align-text-bottom pt-0" style="vertical-align: bottom;">
                        <td class="pb-0" width="50%" colspan="3" 
                            style="border-top: 0 !important;
                            vertical-align: bottom;
                            padding-top: 0px;">
                            <div class="row pl-0 pt-0">
                                <div class="col-2 col-sm-2 col-md-2">
                                </div>
                                <div class="col-8 col-sm-8 col-md-8 mb-1 text-center text-dark">
                                    <img class="image-size" [src]="signature_file.sales_assignment_info" (error)="signature_file.sales_assignment_info = ''" *ngIf="signature_file.sales_assignment_info">
                                    <hr />
                                    <div class="font-weight-normal">
                                        ({{quotationInfo?.sales_assignment_info?.user_info?.firstname_en}}
                                        {{quotationInfo?.sales_assignment_info?.user_info?.lastname_en}})
                                    </div>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 text-center">
                                    Mobile No. <span>{{quotationInfo?.sales_assignment_info?.user_info?.mobile_no}}</span> E-mail: <span>{{quotationInfo?.sales_assignment_info?.user_info?.email}}</span>
                                </div>
                            </div>
                        </td>
                        <td class="pb-0" width="50%" colspan="3" 
                            style="border-top: 0 !important;
                            vertical-align: bottom;
                            padding-top: 0px;">
                            <div class="row pl-0 pt-0">
                                <div class="col-2 col-sm-2 col-md-2">
                                </div>
                                <div class="col-8 col-sm-8 col-md-8 mb-1 text-center text-dark">
                                    <img class="image-size" [src]="signature_file.managerInfo" 
                                    (error)="signature_file.managerInfo = ''"
                                    *ngIf="signature_file.managerInfo && (quotationInfo?.doc_status_id=='WAITTING_FOR_ACCEPT' ||
                                    quotationInfo?.doc_status_id=='ACCEPTED')">
                                    <div class="pb-2" *ngIf="quotationInfo?.doc_status_id!='WAITTING_FOR_ACCEPT' &&
                                    quotationInfo?.doc_status_id!='ACCEPTED'">
                                        
                                    </div>
                                    <hr />
                                    <div class="font-weight-normal">
                                        <span *ngIf="quotationInfo?.doc_status_id=='WAITTING_FOR_ACCEPT' ||
                                        quotationInfo?.doc_status_id=='ACCEPTED'">
                                            ({{managerInfo?.user_info?.firstname_en}}
                                            {{managerInfo?.user_info?.lastname_en}})
                                        </span>

                                        <span *ngIf="quotationInfo?.doc_status_id!='WAITTING_FOR_ACCEPT' &&
                                        quotationInfo?.doc_status_id!='ACCEPTED'">
                                        (____________________________________)
                                        </span>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2">
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 text-center">
                                    Sales Manager
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
            <br>
        </div>
        <div class="pdf" id="employee_section" *ngIf="employeeShow == true">
            <div class="row">
                <div class="col-2 text-center p-0">
                    <img src="/assets/app-assets/images/pdf/logo1.png" width="120px" height="120px">
                </div>
                <div class="col-8 text-center pt-4 px-0">
                    <h1 class="font-weight-bold">MIRACLE INTERNATIONAL TECHNOLOGY CO., LTD.</h1>
                    <p class="font-weight-bold">214 Bangwaek Rd., Bangpai, Bangkae, Bangkok 10160 Tel: 0-2865-4647-8 Fax: 0-2865-4649
                    </p>
                </div>
                <div class="col-2 text-right p-0">
                    <img src="/assets/app-assets/images/pdf/icon2.jpg" width="105px" height="105px">
                    <img src="/assets/app-assets/images/pdf/icon3.png" width="105px" height="105px">
                </div>
            </div>

            <p class="text-center">Web-site : http://www.mit.in.th  E-mail : info@mit.in.th Tax ID.: 0-1055-45112-91-9 (Head Office)</p>

            <h2 class="text-center text-header">QUOTATION / SERVICE REQUEST FORM</h2>

            <h4 class="text-center text-header">{{quotationInfo?.sub_header}}</h4>
            <table class="table table-bordered">
                <tr>
                    <td class="text-left" width="60%">
                        <div class="row pl-0" style="padding-bottom: 5px;">
                            <div class="col-3 col-sm-3 col-md-3">
                                <div class="font-weight-bold">Customer / ลูกค้า:</div>
                            </div>
                            <div class="col-9 col-sm-9 col-md-9 pl-0">
                                <div class="font-weight-normal">{{quotationInfo?.customer_address_info?.address_name}}
                                    ({{quotationInfo?.customer_info?.customer_code}})
                                </div>
                            </div>
                        </div>
                        <div class="row pl-0" style="padding-bottom: 5px;">
                            <div class="col-3 col-sm-3 col-md-3">
                                <div class="font-weight-bold">Address / ที่อยู่:</div>
                            </div>
                            <div class="col-9 col-sm-9 col-md-9 pl-0">
                                <div class="font-weight-normal ">{{quotationInfo?.address}}
                                </div>
                            </div>
                        </div>
                        <div class="row pl-0" style="padding-bottom: 5px;">
                            <div class="col-3 col-sm-3 col-md-3">
                                <div class="font-weight-bold">Tel No. / โทร:</div>
                            </div>
                            <div class="col-4 col-sm-4 col-md-4 pl-0">
                                <div class="font-weight-normal">
                                  {{quotationInfo?.customer_address_info.tel_1}}
                                  <span *ngIf="quotationInfo?.customer_address_info.tel_2">
                                    , {{quotationInfo?.customer_address_info.tel_2}}
                                  </span>
                                </div>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2">
                                <div class="font-weight-bold">Fax:</div>
                            </div>
                            <div class="col-3 col-sm-3 col-md-3 pl-0">
                                <div class="font-weight-normal">
                                    {{quotationInfo?.customer_address_info.fax_1}}
                                    <span *ngIf="quotationInfo?.customer_address_info.fax_2">
                                      , {{quotationInfo?.customer_address_info.fax_2}}
                                    </span>
                                  </div>
                            </div>
                        </div>
                        <div class="row pl-0" style="padding-bottom: 5px;">
                            <div class="col-3 col-sm-3 col-md-3">
                                <div class="font-weight-bold">Attn / เรียน:</div>
                            </div>
                            <div class="col-9 col-sm-9 col-md-9 pl-0">
                                <div class="font-weight-normal">
                                    <span class="pl-0" *ngFor="let item of quotationContactList; let i = index;">
                                        {{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}}
                                        <span class="pl-0" *ngIf="quotationContactList?.length != i+1">,</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="row pl-0" style="padding-bottom: 5px;">
                            <div class="col-3 col-sm-3 col-md-3">
                                <div class="font-weight-bold">Tel No. / โทร:</div>
                            </div>
                            <div class="col-4 col-sm-4 col-md-4 pl-0">
                                <div class="font-weight-normal">
                                    <span class="pl-0" *ngFor="let item of quotationContactList; let i = index;">
                                        {{item?.customer_contact_info?.phonenumber}}
                                        <span class="pl-0" *ngIf="quotationContactList?.length != i+1">,</span>
                                    </span>
                                </div>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2">
                                <div class="font-weight-bold">E-mail:</div>
                            </div>
                            <div class="col-3 col-sm-3 col-md-3 pl-0">
                                <div class="font-weight-normal">
                                    <span class="pl-0" *ngFor="let item of quotationContactList; let i = index;">
                                        {{item?.customer_contact_info?.email}}
                                        <span class="pl-0" *ngIf="quotationContactList?.length != i+1">,</span>
                                    </span>
                                </div>
                            </div>
    
                        </div>
                    </td>
                    <td class="text-left" width="40%">
                        <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                            <div class="col-5 col-sm-5 col-md-5">
                                <div class="font-weight-bold">Quotation No:</div>
                            </div>
                            <div class="col-7 col-sm-7 col-md-7">
                                <div class="font-weight-normal">{{quotationInfo?.doc_no}}</div>
                            </div>
                        </div>
                        <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                            <div class="col-5 col-sm-5 col-md-5">
                                <div class="font-weight-bold">Date / วันที่:</div>
                            </div>
                            <div class="col-7 col-sm-7 col-md-7">
                                <div class="font-weight-normal">
                                    {{quotationInfo?.doc_date | date: 'dd/MM/yyyy'}}</div>
                            </div>
                        </div>
                        <div style="padding-left: 0px;padding-bottom: 5px;" class="row align-items-end">
                            <div class="col-5 col-sm-5 col-md-5">
                                <div class="font-weight-bold">Payment terms / เงื่อนไขการชำระเงิน:</div>
                            </div>
                            <div class="col-7 col-sm-7 col-md-7 align-items-end">
                                <div class="font-weight-normal">{{quotationInfo?.customer_info?.credit_term_day || '0'}} วัน
                                </div>
                            </div>
                        </div>
                        <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                            <div class="col-5 col-sm-5 col-md-5">
                                <div class="font-weight-bold">ยืนยันราคา:</div>
                            </div>
                            <div class="col-7 col-sm-7 col-md-7">
                                <div class="font-weight-normal">
                                    {{quotationInfo?.doc_validdate | date: 'dd/MM/yyyy'}}</div>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
    
            <table class="table table-bordered mb-0">
                <tr>
                    <td class="pb-0" width="33.33%" colspan="2">
                        <div class="row pl-0">
                            <div class="col-12 col-sm-12 col-md-12 mb-5">
                                <div class="font-weight-normal">The calibration schedule / กำหนดการสอบเทียบ</div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 mt-5 mb-2">
                                Date / วันที่: <span>.........</span><span>/</span><span>...............</span><span>/</span><span>........................</span>
                            </div>
                        </div>
                    </td>
                    <td class="pb-0" width="33.33%" colspan="2">
                        <div class="row pl-0">
                            <div class="col-12 col-sm-12 col-md-12 mb-2">
                                <div class="font-weight-normal">
                                    Please fax confirm to calibrate and send the instrument before the date / กรุณาแฟกซ์ยืนยันการสอบเทียบ และส่งเครื่องมือมาภายในวันที่
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 mt-5">
                                Date / วันที่: <span>.........</span><span>/</span><span>...............</span><span>/</span><span>........................</span>
                            </div>
                        </div>
                    </td>
                    <td class="pb-0" width="33.33%" colspan="2">
                        <div class="row pl-0">
                            <div class="col-12 col-sm-12 col-md-12 mb-4">
                                <div class="font-weight-normal">For customer confirm / สำหรับลูกค้าเพื่อยืนยันการสอบเทียบ</div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 mb-1 text-center text-dark">
                                <hr />
                                <div class="font-weight-normal">(ตัวบรรจง)</div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12">
                                Date / วันที่: <span>.........</span><span>/</span><span>...............</span><span>/</span><span>........................</span>
                            </div>
                        </div>
                       
                    </td>
                </tr>
            </table>

            <table class="table table-bordered">
                <tr class="pb-0" style="border: 0 !important;">
                    <td class="pb-0" width="50%" colspan="3" 
                        style="border-top: 0 !important;
                        border-bottom: 0 !important;
                        padding-bottom: 0px;">
                        <div class="row pl-0">
                            <div class="col-12 col-sm-12 col-md-12">
                                <div class="font-weight-normal">Issued by / ออกโดย:</div>
                            </div>
                        </div> 
                    </td>
                    <td class="pb-0" width="50%" colspan="3" 
                        style="border-top: 0 !important;
                        border-bottom: 0 !important;
                        padding-bottom: 0px;">
                        <div class="row pl-0">
                            <div class="col-12 col-sm-12 col-md-12">
                                <div class="font-weight-normal">Approved by / อนุมัติโดย:</div>
                            </div>
                        </div> 
                    </td>
                </tr>
                <tr class="align-text-bottom pt-0" style="vertical-align: bottom;">
                    <td class="pb-0" width="50%" colspan="3" 
                        style="border-top: 0 !important;
                        vertical-align: bottom;
                        padding-top: 0px;">
                        <div class="row pl-0 pt-0">
                            <div class="col-2 col-sm-2 col-md-2">
                            </div>
                            <div class="col-8 col-sm-8 col-md-8 mb-1 text-center text-dark">
                                <img class="image-size" [src]="signature_file.sales_assignment_info" *ngIf="signature_file.sales_assignment_info">
                                <hr />
                                <div class="font-weight-normal">
                                    ({{quotationInfo?.sales_assignment_info?.user_info?.firstname_en}}
                                    {{quotationInfo?.sales_assignment_info?.user_info?.lastname_en}})
                                </div>
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 text-center">
                                Mobile No. <span>{{quotationInfo?.sales_assignment_info?.user_info?.mobile_no}}</span> E-mail: <span>{{quotationInfo?.sales_assignment_info?.user_info?.email}}</span>
                            </div>
                        </div>
                    </td>
                    <td class="pb-0" width="50%" colspan="3" 
                        style="border-top: 0 !important;
                        vertical-align: bottom;
                        padding-top: 0px;">
                        <div class="row pl-0 pt-0">
                            <div class="col-2 col-sm-2 col-md-2">
                            </div>
                            <div class="col-8 col-sm-8 col-md-8 mb-1 text-center text-dark">
                                <img class="image-size" [src]="signature_file.managerInfo" 
                                *ngIf="signature_file.managerInfo && (quotationInfo?.doc_status_id=='WAITTING_FOR_ACCEPT' ||
                                quotationInfo?.doc_status_id=='ACCEPTED')">
                                <div class="pb-2" *ngIf="quotationInfo?.doc_status_id!='WAITTING_FOR_ACCEPT' &&
                                quotationInfo?.doc_status_id!='ACCEPTED'">
                                    
                                </div>
                                <hr />
                                <div class="font-weight-normal">
                                    <span *ngIf="quotationInfo?.doc_status_id=='WAITTING_FOR_ACCEPT' ||
                                    quotationInfo?.doc_status_id=='ACCEPTED'">
                                        ({{managerInfo?.user_info?.firstname_en}}
                                        {{managerInfo?.user_info?.lastname_en}})
                                    </span>

                                    <span *ngIf="quotationInfo?.doc_status_id!='WAITTING_FOR_ACCEPT' &&
                                    quotationInfo?.doc_status_id!='ACCEPTED'">
                                        (____________________________________)
                                    </span>
                                </div>
                            </div>
                            <div class="col-2 col-sm-2 col-md-2">
                            </div>
                            <div class="col-12 col-sm-12 col-md-12 text-center">
                                Sales Manager
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <br>
        <div *ngFor="let mit of mitList; let i = index; let lastIndex = last" >
            <div class="mit-pdf" id="mit_section_{{i}}" *ngIf="mit.length > 0">
                <div class="row">
                    <div class="col-2 text-center p-0">
                        <img src="/assets/app-assets/images/pdf/logo1.png" width="120px" height="120px">
                    </div>
                    <div class="col-8 text-center pt-4 px-0">
                        <h1 class="font-weight-bold">MIRACLE INTERNATIONAL TECHNOLOGY CO., LTD.</h1>
                        <p class="font-weight-bold">214 Bangwaek Rd., Bangpai, Bangkae, Bangkok 10160 Tel: 0-2865-4647-8 Fax: 0-2865-4649
                        </p>
                    </div>
                    <div class="col-2 text-right p-0">
                        <img src="/assets/app-assets/images/pdf/icon2.jpg" width="105px" height="105px">
                        <img src="/assets/app-assets/images/pdf/icon3.png" width="105px" height="105px">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 pr-0 text-right">
                        Web-site : http://www.mit.in.th
                    </div>
                    <div class="col-6">
                        E-mail : info@mit.in.th
                    </div>
                </div>
                <h2 class="text-center text-header py-1">ใบแจ้งรายการสอบเทียบ</h2>
                <table class="table table-bordered">
                    <tr>
                        <td class="text-left" width="60%">
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Customer / ลูกค้า:</div>
                                </div>
                                <div class="col-9 col-sm-9 col-md-9 pl-0">
                                    <div class="font-weight-normal">{{quotationInfo?.customer_address_info?.address_name}}
                                        ({{quotationInfo?.customer_info?.customer_code}})
                                    </div>
                                </div>
                            </div>
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Address / ที่อยู่:</div>
                                </div>
                                <div class="col-9 col-sm-9 col-md-9 pl-0">
                                    <div class="font-weight-normal ">{{quotationInfo?.address}}
                                    </div>
                                </div>
                            </div>
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Tel No. / โทร:</div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 pl-0">
                                    <div class="font-weight-normal">
                                      {{quotationInfo?.customer_address_info.tel_1}}
                                      <span *ngIf="quotationInfo?.customer_address_info.tel_2">
                                        , {{quotationInfo?.customer_address_info.tel_2}}
                                      </span>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2">
                                    <div class="font-weight-bold">Fax:</div>
                                </div>
                                <div class="col-3 col-sm-3 col-md-3 pl-0">
                                    <div class="font-weight-normal">
                                        {{quotationInfo?.customer_address_info.fax_1}}
                                        <span *ngIf="quotationInfo?.customer_address_info.fax_2">
                                          , {{quotationInfo?.customer_address_info.fax_2}}
                                        </span>
                                      </div>
                                </div>
                            </div>
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Attn / เรียน:</div>
                                </div>
                                <div class="col-9 col-sm-9 col-md-9 pl-0">
                                    <div class="font-weight-normal">
                                        <span class="pl-0" *ngFor="let item of quotationContactList; let i = index;">
                                            {{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}}
                                            <span class="pl-0" *ngIf="quotationContactList?.length != i+1">,</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row pl-0" style="padding-bottom: 5px;">
                                <div class="col-3 col-sm-3 col-md-3">
                                    <div class="font-weight-bold">Tel No. / โทร:</div>
                                </div>
                                <div class="col-4 col-sm-4 col-md-4 pl-0">
                                    <div class="font-weight-normal">
                                        <span class="pl-0" *ngFor="let item of quotationContactList; let i = index;">
                                            {{item?.customer_contact_info?.phonenumber}}
                                            <span class="pl-0" *ngIf="quotationContactList?.length != i+1">,</span>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-2 col-sm-2 col-md-2">
                                    <div class="font-weight-bold">E-mail:</div>
                                </div>
                                <div class="col-3 col-sm-3 col-md-3 pl-0">
                                    <div class="font-weight-normal">
                                        <span class="pl-0" *ngFor="let item of quotationContactList; let i = index;">
                                            {{item?.customer_contact_info?.email}}
                                            <span class="pl-0" *ngIf="quotationContactList?.length != i+1">,</span>
                                        </span>
                                    </div>
                                </div>
        
                            </div>
                        </td>
                        <td class="text-left" width="40%">
                            <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <div class="font-weight-bold">Quotation No:</div>
                                </div>
                                <div class="col-7 col-sm-7 col-md-7">
                                    <div class="font-weight-normal">{{quotationInfo?.doc_no}}</div>
                                </div>
                            </div>
                            <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <div class="font-weight-bold">Date / วันที่:</div>
                                </div>
                                <div class="col-7 col-sm-7 col-md-7">
                                    <div class="font-weight-normal">
                                        {{quotationInfo?.doc_date | date: 'dd/MM/yyyy'}}</div>
                                </div>
                            </div>
                            <div style="padding-left: 0px;padding-bottom: 5px;" class="row align-items-end">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <div class="font-weight-bold">Payment terms / เงื่อนไขการชำระเงิน:</div>
                                </div>
                                <div class="col-7 col-sm-7 col-md-7 align-items-end">
                                    <div class="font-weight-normal">{{quotationInfo?.customer_info?.credit_term_day || '0'}} วัน
                                    </div>
                                </div>
                            </div>
                            <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                                <div class="col-5 col-sm-5 col-md-5">
                                    <div class="font-weight-bold">ยืนยันราคา:</div>
                                </div>
                                <div class="col-7 col-sm-7 col-md-7">
                                    <div class="font-weight-normal">
                                        {{quotationInfo?.doc_validdate | date: 'dd/MM/yyyy'}}</div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </table>
        
                <table class="table table-bordered">
                    <tr>
                        <td class="form-group text-center custom-padding-th text-bold px-0" width="1%">Item</td>
                        <td class="form-group text-bold custom-padding-th" width="26%">Description</td>
                        <td class="form-group text-bold custom-padding-th" width="5%">Service Location</td>
                        <td class="form-group text-bold custom-padding-th" width="14%">Serial No.</td>
                        <td class="form-group text-bold custom-padding-th" width="14%">Tag No.</td>
                        <td class="form-group text-bold custom-padding-th" width="22%">Cal point</td>
                        <td class="form-group text-right custom-padding-th text-bold px-1" width="6%">ราคาขาย</td>
                        <td class="form-group text-right custom-padding-th text-bold px-1" width="2%">ส่วนลด(%)</td>
                        <td class="form-group text-right custom-padding-th text-bold" width="4%">รวมเงิน</td>
                    </tr>
                    <tbody *ngFor="let item of mit; let j = index;">
                        <tr>
                            <td class="pt-1 text-center custom-padding" rowspan="2">
                                {{item.index + 1}}
                            </td>
                            <td class="custom-padding">
                                <p>{{item.description || item?.equipment_name}}</p>
                            </td>
                            <td class="custom-padding" >
                                {{showServiceLocation(item.quotation_mit_item_details)}}
                            </td>
                            <td class="custom-padding">
                                <p>{{item.serial_no || '-'}}</p>
                            </td>
                            <td class="custom-padding">
                                <p>{{item.tag_no || '-'}}</p>
                            </td>
                            <td class="px-1 custom-padding" rowspan="2" style="word-break:break-all;">
                                <ng-container [ngSwitch]="true">
                                    <ng-container *ngSwitchCase="item?.cus_calibration_point != null && item?.cus_calibration_point != ''">
                                        {{item?.cus_calibration_point}}
                                    </ng-container>

                                    <ng-container *ngSwitchDefault>
                                        <div class="custom-inline" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                                            <span *ngIf="detail.detail_type == 'point'">
                                                <span class="pb-2 custom-inline" *ngFor="let point of detail.quotation_mit_item_detail_points; let pointIndex = index;">
                                                    <span class="danger" *ngIf="detail.isISO === 'N' && point.is_non_calculate =='false'">*</span>
                                                    <span *ngIf="point.is_non_calculate =='false'">
                                                        {{point.cus_point || 0 | number : '1.1-5'}}
                                                    </span>
                                                    <span class="p-0 m-0" *ngIf="((pointIndex + 1) != pointLenght(detail.quotation_mit_item_detail_points)) && point.is_non_calculate =='false'">,</span>
                                                    <span [innerHTML]="point.cus_unit_name" *ngIf="pointIndex + 1 == detail.quotation_mit_item_detail_points.length"></span>
                                                </span>
                                            </span>
                                            <span *ngIf="detail.detail_type == 'range'">
                                                <span  class="pb-2 custom-inline">
                                                    <span class="danger" *ngIf="detail.isISO === 'N'">*</span>
                                                    {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_min) || 0 | number : '1.1-5'}} 
                                                </span>
                                                &nbsp;-
                                                <span  class="pb-2 custom-inline">
                                                    {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_max) || 0 | number : '1.1-5'}}
                                                    <span [innerHTML]="detail?.quotation_mit_item_detail_range.cus_unit_name"></span>
                                                </span>
                                            </span>
                                            <span class="p-0 m-0" *ngIf="detailIndex + 1 != item.quotation_mit_item_details.length">,</span>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </td>
                            <td class="text-right px-1 custom-padding" rowspan="2">
                                {{item.price | number : '1.2-2'}}
                            </td>
                            <td class="text-right px-1 custom-padding" rowspan="2">
                                {{item.discount | number : '1.2-2'}}
                            </td>
                            <td class="text-right custom-padding" rowspan="2">
                                {{item.grand_total | number : '1.2-2'}}
                            </td>
                        </tr>
                        <tr colspan="4">               
                            <td colspan="4" class="custom-padding">
                                <div>
                                    <span>
                                        <span class="font-weight-bold">Method:</span> {{showMethod(item.quotation_mit_item_details)}}
                                    </span>
                                    <span *ngIf="getMitNote(item.quotation_mit_item_details)">
                                        <span class="font-weight-bold">Note:</span> {{getMitNote(item.quotation_mit_item_details)}}
                                    </span>

                                    <span *ngIf="item?.note_1">
                                        <span class="font-weight-bold">Note 1:</span> {{item?.note_1}}
                                    </span>
                                    <span *ngIf="item?.note_2">
                                        <span class="font-weight-bold">Note 2:</span> {{item?.note_2}}
                                    </span>
                                    <span *ngIf="getMitAttribute(item.quotation_mit_item_attributes)">
                                        <span class="font-weight-bold">Attributes:</span> {{getMitAttribute(item.quotation_mit_item_attributes)}}
                                    </span>
                                </div>
                               
                            </td>
                        </tr> 
                    </tbody>
                    <tbody *ngIf="quotationMitItemGroups.length == 0">
                        <tr colspan="10">               
                            <td colspan="10" style="height: 38px;"></td>
                        </tr> 
                    </tbody>
                </table>
                <div>
                    <span class="p-0 text-bold">หมายเหตุ: </span> * นอกขอบข่ายการรับรอง  ISO/IEC 17025
                </div>
                <div class="row" *ngIf="lastIndex">
                    <div class="col-6"></div>
                    <div class="col-6">
                    <table class="table table-bordered" width="50%">
                            <tr>
                                <td class="text-leftr form-group">Total / รวม</td>
                                <td class="text-right form-group">{{quotationMitItems.length}} Item(s) / รายการ</td>
                            </tr>
                            <tr>
                                <td class="text-left form-group">Total / รวม</td>
                                <td class="text-right form-group">{{ totalPrice | number : '1.2-2' }} Baht / บาท</td>
                                
                            </tr>
                    </table>
                    </div>
                </div>
            </div>
            <br>
        </div>
    </div>
</div>

<div class="container app-content" *ngIf="canAccess == false && pageLoad">
    <div class="text-center">
        <h2>ไม่สามารถเข้าถึงได้</h2>
        <h5>Link มีอายุ 90 วัน หากเข้าไม่ได้ โปรดติดต่อพนักงานขาย</h5>
    </div>
</div>