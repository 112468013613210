import { Component, OnInit, ElementRef, ViewChild } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import {
  ProductService,
  ProductTypeService,
  UnitService,
  MITScopeService,
  MITEquipmentService,
  MITMethodService,
  ProductPriceUnitService,
  ProductCalibrationDetailService,
  ProductCalibrationFeeService,
  MITCPService,
} from "src/app/services";
import _ from "lodash";
const CALIBRATION = "3";
@Component({
  selector: "app-product-create",
  templateUrl: "./product-create.component.html",
  styleUrls: ["./product-create.component.css"],
})
export class ProductCreateComponent implements OnInit {
  CALIBRATION = "3";
  isReady = false;
  form: FormGroup;
  formProductCode: FormGroup;
  // productDetailForm: FormGroup;
  submitted = false;
  status: any;

  productTypes = [];
  units = [];
  // scopes = [];
  // equipments = [];
  // methods = [];
  // fees = [];
  // cps = [];
  services = [];
  @ViewChild("productCodeResponse") productCodeResponse: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private ProductService: ProductService,
    private ProductPriceUnitService: ProductPriceUnitService,
    // private ProductCalibrationDetailService: ProductCalibrationDetailService,
    // private ProductCalibrationFeeService: ProductCalibrationFeeService,

    private ProductTypeService: ProductTypeService,
    private UnitService: UnitService // private MITScopeService: MITScopeService, // private MITEquipmentService: MITEquipmentService, // private MITMethodService: MITMethodService, // private MITCPService: MITCPService
  ) {
    this.setForm();
  }
  setForm() {
    this.form = this.formBuilder.group({
      // product info
      product_type_id: ["1", Validators.required],
      product_code: ["", Validators.required],
      product_title: ["", Validators.required],
      product_description: [""],
      unit_id: ["", [Validators.required]],
      price: ["", [Validators.min(0)]],
    });

    this.formProductCode = this.formBuilder.group({
      product_code: ["", Validators.required],
    });

    // this.productDetailForm = this.formBuilder.group({
    //   // product detail
    //   service_lab: [false],
    //   service_onsite: [false],
    //   mit_scope_id: ["", [Validators.required]],
    //   mit_equipment_id: ["", [Validators.required]],
    //   mit_method_id: ["", [Validators.required]],
    //   mit_cp_id: ["", [Validators.required]],
    //   note_1: [""],
    //   note_2: [""],
    //   service: ["", [Validators.required]],
    // });
  }
  async ngOnInit() {
    let promises = [];
    promises.push(this.genProductCode());
    // this.ProductService.genProductCode().then((res) => {
    //   if (res.success) {
    //     this.form.controls.product_code.setValue(res.resultData.product_code);
    //     this.form.controls.product_code.disable();
    //   }
    // });

    promises.push(
      this.ProductTypeService.load().then((response) => {
        if (response.success) {
          this.productTypes = response.resultData || [];
        }
      })
    );

    promises.push(
      this.UnitService.load().then((response) => {
        if (response.success) {
          this.units = this.groupUnit(response.resultData) || [];
        }
      })
    );
    // this.MITScopeService.load().then((response) => {
    //   if (response.success) {
    //     this.scopes = response.resultData || [];
    //   }
    // });

    // this.MITMethodService.load().then((response) => {
    //   if (response.success) {
    //     this.methods = response.resultData || [];
    //   }
    // });

    // this.MITCPService.load().then((response) => {
    //   if (response.success) {
    //     this.cps = response.resultData || [];
    //   }
    // });

    // this.productDetailForm.controls.service_lab.valueChanges.subscribe(
    //   (isLab) => {
    //     if (isLab) {
    //       this.services.push("LAB");
    //     } else {
    //       this.services = this.services.filter((v) => v !== "LAB");
    //     }
    //     this.productDetailForm.controls.service.setValue(
    //       this.services.join(", ")
    //     );
    //   }
    // );

    // this.productDetailForm.controls.service_onsite.valueChanges.subscribe(
    //   (isOnsite) => {
    //     if (isOnsite) {
    //       this.services.push("ONSITE");
    //     } else {
    //       this.services = this.services.filter((v) => v !== "ONSITE");
    //     }
    //     this.productDetailForm.controls.service.setValue(
    //       this.services.join(", ")
    //     );
    //   }
    // );

    // this.form.controls.product_type_id.valueChanges.subscribe(
    //   (product_type_id) => {
    //     if (product_type_id === CALIBRATION) {
    //       this.form.controls.unit_id.setValidators(null);
    //       this.form.controls.price.setValidators(null);
    //     } else {
    //       this.form.controls.unit_id.setValidators([Validators.required]);
    //       this.form.controls.price.setValidators([Validators.required]);
    //     }
    //   }
    // );
    await Promise.all(promises);
    this.isReady = true;
  }

  async submit() {
    this.submitted = true;
    this.form.controls.product_code.enable();
    if (this.form.invalid) {
      this.form.controls.product_code.disable();
      return;
    }

    let data = {
      ...this.form.value,
      price: Number(this.form.value.price),
    };

    let status = null;
    // create product info
    let productResponse = await this.ProductService.create(
      _.pick(data, [
        "product_type_id",
        "product_code",
        "product_title",
        "product_description",
      ])
    );

    let product_id = null;
    if (productResponse.success) {
      product_id = productResponse.resultData.product_id;
      status = productResponse.success;

      // if (this.form.value.product_type_id !== CALIBRATION) {
      let productPriceUnitResponse = await this.ProductPriceUnitService.create({
        product_id,
        ..._.pick(data, ["unit_id", "price"]),
      });
      if (productPriceUnitResponse.error) {
        this.status = productPriceUnitResponse.error;
        this.form.controls.product_code.disable();
        return this.deleteProduct(product_id);
      }
      // } else if (this.form.value.product_type_id === CALIBRATION) {
      //   // create product detail
      //   let productCalibrationDetailResponse = await this.ProductCalibrationDetailService.create(
      //     {
      //       product_id,
      //       ...this.productDetailForm.value,
      //       mit_cp_id: Number(this.productDetailForm.value.mit_cp_id),
      //     }
      //   );

      //   if (productCalibrationDetailResponse.success) {
      //     for (let fee of this.fees) {
      //       let feeResponse = await this.ProductCalibrationFeeService.create({
      //         product_calibration_detail_id:
      //           productCalibrationDetailResponse.resultData
      //             .product_calibration_detail_id,
      //         ...fee,
      //       });
      //       if (feeResponse.error) {
      //         this.status = feeResponse.error;
      //         return this.deleteProduct(product_id);
      //       }
      //     }
      //   } else {
      //     this.status = productCalibrationDetailResponse.error;
      //     return this.deleteProduct(product_id);
      //   }
      // }
    } else {
      this.form.controls.product_code.disable();
      if (productResponse.error.resultCode === "40900") {
        this.productCodeResponse.nativeElement.click();
        this.form.controls.product_code.enable();

        await this.genProductCode();
      } else {
        this.status = productResponse.error;
      }

      return;
    }

    this.router.navigateByUrl("/product", {
      state: {
        status: status,
      },
    });
  }

  async reSubmit() {
    this.status = null;
    this.productCodeResponse.nativeElement.click();
    this.form.controls.product_code.disable();
    this.submit();
  }

  deleteProduct(product_id) {
    this.ProductService.delete({ product_id });
  }

  // changeProductCode() {
  //   this.form.controls.product_code.setValue(
  //     this.formProductCode.value.product_code
  //   );
  // }

  async genProductCode() {
    let genCode = await this.ProductService.genProductCode();
    this.form.controls.product_code.setValue(genCode.resultData.product_code);

    this.formProductCode.controls.product_code.setValue(
      genCode.resultData.product_code
    );

    this.formProductCode.disable();
    this.form.controls.product_code.disable();
  }

  // changeScope() {
  //   if (!this.productDetailForm.value.mit_scope_id) {
  //     this.equipments = [];
  //     this.productDetailForm.controls.mit_equipment_id.setValue("");
  //     return;
  //   }
  //   this.MITEquipmentService.load(null, {
  //     mit_scope_id: this.productDetailForm.value.mit_scope_id,
  //   }).then((response) => {
  //     if (response.success) {
  //       this.equipments = response.resultData || [];
  //     }
  //   });
  // }

  // onAddFee(fee) {
  //   this.fees.push(fee);
  // }

  groupUnit(units) {
    let groups = {};
    for (let unit of units) {
      if (!groups[unit.unit_type_id]) {
        groups[unit.unit_type_id] = unit.unit_type_info;
      }
    }
    let groupUnits = {};

    for (let unit of units) {
      if (!groupUnits[unit.unit_type_id]) {
        groupUnits[unit.unit_type_id] = [];
      }
      groupUnits[unit.unit_type_id].push(unit);
    }

    let groupByUnits = [];
    for (let group in groups) {
      groupByUnits.push({
        unit_type_info: groups[group],
        units: groupUnits[group],
      });
    }

    return groupByUnits;
  }
}
