<div class="container app-content overflow-container">

    <div class="btn-section text-right border-bottom">
      <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(jobOrderInfo)">
          <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
      </button>
    </div>
  
    <div class="pdf" id=pdf>
        <div class="row mt-5 mb-1">
            <div class="col-4">

            </div>
            <div class="col-6">
                <h3><u>ผู้ติดต่อ</u></h3>
                <span class="pl-0" *ngFor="let item of contactList; let i = index;" style="font-size: 1.74rem;font-weight: 400;">
                    {{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}} {{item?.customer_contact_info?.phonenumber}}
                    <span class="pl-0" *ngIf="contactList?.length != i+1">,</span>
                </span>
            </div>
        </div>
        <div class="row">
            <div class="col-4">

            </div>
            <div class="col-5">
                <h3><u>ที่อยู่ในการจัดส่ง</u></h3>
                <h2>{{jobOrderInfo?.report_address_name}}</h2>
                <h2>{{jobOrderInfo?.report_address_text}}</h2>
            </div>
        </div>
    </div>
    
</div>