import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class TransportReceiveItemFileService {
  async create({
    transport_receive_item_id,
    file_name,
    file_path
  }) {
    let result = null;
    try {
      result = await Axios().post(`/transport-receive-item-file`, {
        transport_receive_item_id,
        file_name,
        file_path
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      transport_receive_item_file_id,
      transport_receive_item_id,
      file_name,
      file_path
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/transport-receive-item-file/${transport_receive_item_file_id}`, {
        transport_receive_item_id,
        file_name,
        file_path
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ transport_receive_item_file_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/transport-receive-item-file/${transport_receive_item_file_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-item-file`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result?.data;
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async getById({ transport_receive_item_file_id }) {
    let result = null;
    try {
      result = await Axios().get(`/transport-receive-item-file/${transport_receive_item_file_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
