import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationService {
  async create({
    customer_id,
    doc_no,
    doc_no_old,
    doc_date,
    doc_validdate,
    doc_status_id,
    customer_address_id,
    quotation_sub_header_id,
    discount,
    grand_total,
    tax,
    sale_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-info`, {
        customer_id,
        doc_no: doc_no? doc_no: undefined,
        doc_no_old,
        doc_date,
        doc_validdate,
        doc_status_id,
        customer_address_id,
        quotation_sub_header_id: parseInt(quotation_sub_header_id) || 0,
        discount,
        grand_total,
        tax,
        sale_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      quotation_info_id,
      customer_id,
      doc_no,
      doc_date,
      doc_validdate,
      doc_status_id,
      customer_address_id,
      quotation_sub_header_id,
      discount,
      grand_total,
      tax,
      accepted_date,
      accepted_doc_path,
      accepted_doc_name,
      accepted_po_no,
      sale_id,
      approved_id,
      email
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-info/${quotation_info_id}`, {
        customer_id,
        doc_no,
        doc_date,
        doc_validdate,
        doc_status_id,
        customer_address_id,
        quotation_sub_header_id: parseInt(quotation_sub_header_id) || 0,
        discount,
        grand_total,
        tax,
        accepted_date,
        accepted_doc_path,
        accepted_doc_name,
        accepted_po_no,
        sale_id,
        approved_id,
        email
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_info_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-info/${quotation_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_info_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-info/${quotation_info_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  async getCode(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-code`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getSubHeader(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-sub-header`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getSubHeaderById(quotation_sub_header_id) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-sub-header/${quotation_sub_header_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  async getSumMit(quotation_info_id) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-info/${quotation_info_id}/sum-mit-items`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  async sendEmail({
    quotation_info_id
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-info/${quotation_info_id}/mail/`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
