import { Component, OnInit, NgZone, ViewChild, ElementRef, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { 
  ConvertService,
  QuotationUtilsService
 } from '../../../../../services/quotations';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormMitService } from '../quotation-item.service';
import { generateMitItemLogService, MITEquipmentService, UtilsService } from 'src/app/services';
import { NavigationStart, Router } from '@angular/router';
import { 
  TransportreceiveInfoService,
  TransportreceiveItemService
} from 'src/app/services/transport-receives';

@Component({
  selector: 'app-quotation-item-create',
  templateUrl: './quotation-item-create.component.html',
  styleUrls: ['./quotation-item-create.component.css']
})
export class QuotationItemCreateComponent implements OnInit {
  form: FormGroup;
  attributeList: FormArray;
  detailList: FormArray;

  submitted: boolean = false;
  is_show_attributes: boolean = false;
  is_show_deatils: boolean = false;

  subject: Subject<string> = new Subject();
  subject_convert_range: Subject<string> = new Subject();

  service = "";
  cps = []; 
  units = []; 

  oldRange = []; 
  oldMitItemDetails = []; 

  scopeList = [];
  equipmentList = [];
  methodList = []; 
  oldEquipmentList = []; 
  oldServiceEquipmentList = [];
  transportReceiveList = []; 
  transportReceiveItemList = [];

  filterMethod: string = "";

  @Output() done = new EventEmitter<any>();
  @Input() quotationMitItemGroups: any;
  @Input() dropDownData: any;
  @Input() customer_id: string;
  @Input() type: string;
  
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private formBuilder: FormBuilder,
    private zone: NgZone,
    private router: Router,
    private ConvertService: ConvertService,
    private MITEquipmentService: MITEquipmentService,
    private formMitService: FormMitService,
    private generateMitItemLogService: generateMitItemLogService,
    private TransportreceiveInfoService: TransportreceiveInfoService,
    private TransportreceiveItemService: TransportreceiveItemService,

    public UtilsService: UtilsService,
    public _utils : QuotationUtilsService,
  ){
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.createForm();
  }
  ngOnInit() {}

  async load(){
    if(this.dropDownData){
      this.scopeList = this.dropDownData.scopeList;
      this.scopeList = this.scopeList.map(function(elem) {
        return {
          id: elem.mit_scope_id,
          text: elem.scope_name
        }
      });
      this.cps = this.dropDownData.cps || [];
      this.units = this.dropDownData.units || [];
    }
    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.calculateTotalAmountItemDetail()
    );
    this.subject_convert_range.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.convertSpecificRange(parseInt(x))
    );
  }

  ngOnChanges(changes: SimpleChanges) {

    if(this.type == 'qt'){
      this.form.get('transport_receive_id').setValidators(null);
      this.form.get('transport_receive_item_id').setValidators(null);
      this.form.get('mit_scope_id').enable();
      this.form.updateValueAndValidity();
      this.load();
    }else{
      this.form.get('transport_receive_id').setValidators([Validators.required]);
      this.form.get('transport_receive_item_id').setValidators([Validators.required]);
      this.form.get('mit_scope_id').disable();
      this.form.get('transport_receive_item_id').disable();
      this.form.updateValueAndValidity();
      this.load();
      if(this.customer_id){
        this.getTransport(this.customer_id);
      }
    }
  }

  async getTransport(customer_id: string){
    await this.TransportreceiveInfoService.load(null, {customer_id: customer_id, doc_status_id: 'RECEIVE_WAITING_FOR_CONFIRM'}).then(res=>{
      this.transportReceiveList = res.resultData.filter(receive => receive.receive_type == 'WITTHOUT_QT') || [];
      
      this.transportReceiveList = this.transportReceiveList.map(function(elem) {
        return {
          id: elem.transport_receive_info_id,
          text: elem.doc_no,
          data: elem
        }
      });
    })
  }

  createForm(){
    this.form = this.formBuilder.group({
      transport_receive_id: [""],
      transport_receive_item_id: [""],
      transport_receive_info_id: [""],
      transport_receive_info_doc_no: [""],
      transport_receive_item_status: [""],
      transport_receive_info_status: [""],
      mit_scope_id: ["", [Validators.required]],
      mit_equipment_id: ["", [Validators.required]],
      mit_method_id: ["", [Validators.required]],
      method_name: [""],
      service_lab: [false],
      service_onsite: [false],
      service_location: ["", [Validators.required]],
      marker: [""],
      description: [""],
      model: [""],
      serial_no: [""],
      tag_no: [""],
      due_month: [""],
      order_by: [0],
      original_price: [0],
      scope_name: [""], 
      equipment_name: [""], 
      note_1: [""], 
      note_2: [""],
      cp_list_init: [""],
      cp_list_cali: [""],
      price: [null],
      discount: [null],
      quotation_mit_item_attributes: this.formBuilder.array([]),
      quotation_mit_item_details: this.formBuilder.array([]),
      mit_item_logs: [[]],
    }, {validator: [this._utils.customValidateRequirePoints(), this._utils.customValidateOneType()]});

    this.form.get('mit_equipment_id').disable();
    this.form.get('mit_method_id').disable();
    this.form.get('service_lab').disable();
    this.form.get('service_onsite').disable();

    this.attributeList = 
    this.form.get('quotation_mit_item_attributes') as FormArray;
    this.detailList = 
    this.form.get('quotation_mit_item_details') as FormArray;

    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Channel',
      attribute_value: ''
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Sensor',
      attribute_value: ''
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Display',
      attribute_value: ''
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Function',
      attribute_value: ''
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Element',
      attribute_value: ''
    }));
  }

  get attributeItemFormGroup() {
    return this.form.get('quotation_mit_item_attributes') as FormArray;
  }

  get detailItemFormGroup() {
    return this.form.get('quotation_mit_item_details') as FormArray;
  }

  pointItemFormGroup(form) {
     return form.controls.quotation_mit_item_detail_points.controls;
  }

  async getEquipments(mit_scope_id){
    await this.MITEquipmentService.load(null, {mit_scope_id: mit_scope_id}).then((res) => {
      if(res.resultData){
        this.oldEquipmentList = res.resultData || [];
        this.oldEquipmentList = this.oldEquipmentList.map(function(elem) {
          return {
            data: elem,
            id: elem.mit_equipment_id,
            text: elem.equipment_name
          }
        });
        this.equipmentList =  this.oldEquipmentList;
      }
    });
  }

  async selectTransport(event){
    if(event){
      this.form.get('transport_receive_item_id').enable();
      await this.TransportreceiveItemService.load(null, {transport_receive_id:event, order_by: "order:asc"}).then(res=>{

        this.transportReceiveItemList = res.resultData.filter(item=> (item.noqt_quotation_info_id == null || item.noqt_quotation_info_id == "") && (item.quotation_info == null || item.quotation_info == "")  && item.item_status_id != 'CANCELED') || [];

        this.transportReceiveItemList = this.transportReceiveItemList.filter(item=> !this.quotationMitItemGroups.find(mit=> mit.transport_receive_item_id == item.transport_receive_item_id)).map(function(elem) {
          return {
            data: elem,
            id: elem.transport_receive_item_id,
            text: elem.noqt_description
          }
        });
      });
    }else{
      this.transportReceiveItemList = [];
    }
  }

  async selectTransportItem(event){
    if(event){
      let transportItem = this.transportReceiveItemList.find(t=> t.id == event);
      if(transportItem){
        this.form.get('transport_receive_info_id').setValue(transportItem.data.transport_receive_info.transport_receive_info_id);
        this.form.get('transport_receive_info_doc_no').setValue(transportItem.data.transport_receive_info.doc_no);
        this.form.get('transport_receive_item_status').setValue(transportItem.data.item_status_id);
        this.form.get('transport_receive_info_status').setValue(transportItem.data.transport_receive_info.doc_status_id);
        this.form.get('marker').setValue(transportItem.data.noqt_marker);
        this.form.get('description').setValue(transportItem.data.noqt_description);
        this.form.get('model').setValue(transportItem.data.noqt_model);
        this.form.get('serial_no').setValue(transportItem.data.noqt_serial_no);
        this.form.get('tag_no').setValue(transportItem.data.noqt_tag_no);
      }

      this.form.get('mit_scope_id').enable();
    }
  }

  async selectScope(event){
    this.getNote();

    this.oldServiceEquipmentList = [];
    
    if(event){
      await this.getEquipments(event);

      const selectEquipment = 
      this.oldEquipmentList.filter(v => (
        v.data?.mit_scope_id === event)
      );
      this.equipmentList = selectEquipment;
      
      this.detailList.clear();
      this.form.get('mit_equipment_id').enable();

      let scope = this.scopeList.find(scope => scope.id == event);
      if(scope)this.form.get('scope_name').setValue(scope.text);
    }else{
      this.form.get('mit_equipment_id').disable();

      this.form.get('scope_name').setValue("");
    }
    this.clearMethod();
    this.clearServiceLocation();
    this.getCPList(null);
    this.form.get('original_price').setValue(0);
  }

  async selectEquipment(event){
    this.oldRange = [];

    if(event){
      this.detailList.clear();
      const equipment = this.getEquipmentList(event);

      this.getNote(equipment);
      if(equipment){

        this.form.get('equipment_name').setValue(equipment.text);
      }
      this.clearMethod();
      this.clearServiceLocation();
      this.getCPList(null);

      this.form.get('service_lab').enable();
      this.form.get('service_onsite').enable();
    }else{
      this.clearMethod();
      this.clearServiceLocation();
      this.getCPList(null);

      this.form.get('equipment_name').setValue("");

      this.form.get('service_lab').disable();
      this.form.get('service_onsite').disable();
    }
    this.form.get('original_price').setValue(0);
  }

  async selectServiceLocation(event){
    let value = this.form.getRawValue();
  
    if(event == 'lab'){
      await this.setServiceLocation(true, false);
    }else{
      await this.setServiceLocation(false, true);
    }
    await this.setService();
  }

  async setServiceLocation(lab, onsite){
    this.form.get('service_lab').setValue(lab);
    this.form.get('service_onsite').setValue(onsite);
    if(lab && onsite){
      this.form.get('service_location').setValue('Lab, Onsite');
    }else if(lab){
      this.form.get('service_location').setValue('Lab');
    }else if(onsite){
      this.form.get('service_location').setValue('Onsite');
    }else{
      this.form.get('service_location').setValue("");
    }
  }

  async setService(){
    this.detailList.clear();

    const equipment = this.getEquipmentList(this.form.getRawValue().mit_equipment_id);

    this.setOptionServiceLocation(equipment.data.service_location);

    let detailList = this.getDetailList(equipment);

    this.getCPList(detailList);
    

    detailList = this.sortDetailList(detailList);

    await detailList.map((v: any)=>{

      this.detailList.push(this.formMitService.createDetailItem(v, equipment.data));

      this.oldRange.push({min: v.min_range, max: v.max_range});
    });

    this.oldMitItemDetails = this.detailList.getRawValue();

    this.setMethodList(this.oldMitItemDetails);

    this.setServiceLocationDetail();
  }

  setServiceLocationDetail(){
    if(this.form.getRawValue().service_lab && !this.form.getRawValue().service_onsite){
  
      this.detailList.controls.map((v: any)=>{
        v.get('service_location').setValue('LAB');

      })
    }else if(!this.form.getRawValue().service_lab && this.form.getRawValue().service_onsite){

      this.detailList.controls.map((v: any)=>{
        v.get('service_location').setValue('ONSITE');
      })
    }
  }

  setMethodList(items){

    if(items.length > 0){
      this.methodList = items.map(function(elem) {
        return {
          id: elem.mit_method_id,
          text: elem.method_name
        }
      });
      this.methodList = this.methodList.filter((method, index, self) =>
        index === self.findIndex((t) => (
          t.id === method.id
        ))
      )
   
      if(this.methodList.length == 1){
        this.form.get('mit_method_id').setValue(this.methodList[0].id);
        this.form.get('method_name').setValue(this.methodList[0].text);
      }
      this.form.get('mit_method_id').enable();
    }else{
      this.methodList = [];
      this.form.get('mit_method_id').disable();
    }
  }

  selectMethod(event){
    if(event){
      let method = this.methodList.find(method => method.id == event);
      this.filterMethod = event;
      this.form.get('method_name').setValue(method.text);
    }
  }

  clearMethod(){
    this.form.get('mit_method_id').disable();
    this.form.get('mit_method_id').setValue("");
    this.form.get('method_name').setValue("");
  }

  clearServiceLocation(){
    this.form.get('service_lab').disable();
    this.form.get('service_lab').setValue(false);

    this.form.get('service_onsite').disable();
    this.form.get('service_onsite').setValue(false);
  }

  getNote(equipment = null){
    if(equipment) {
      this.form.get('note_1').setValue(equipment.data.note_1);
      this.form.get('note_2').setValue(equipment.data.note_2);
    }else{
      this.form.get('note_1').setValue("");
      this.form.get('note_2').setValue("");
    }
  }

  getCPList(detailList){
    let formValue = this.form.value;

    let getCPInfo = this.equipmentList.find(eq=> eq.data.mit_equipment_id == formValue.mit_equipment_id);
    
    if(detailList?.length > 0) {
      this.form.get('cp_list_init').setValue(formValue.service_location.toUpperCase() == 'LAB'? getCPInfo.data?.lab_mit_cp_list: getCPInfo.data?.onsite_mit_cp_list || "");
      this.form.get('cp_list_cali').setValue(formValue.service_location.toUpperCase() == 'LAB'? getCPInfo.data?.lab_mit_cp_list: getCPInfo.data?.onsite_mit_cp_list || "");
    }else{
      this.form.get('cp_list_init').setValue("");
      this.form.get('cp_list_cali').setValue("");
    }
  }

  getEquipmentList(event){
    const res = this.oldEquipmentList.filter(v => (
      v.id === event)
    );
    return  res[0];
  }

  getDetailList(equipment){
    let value = this.form.getRawValue();

    if(value.service_location.toUpperCase().includes('LAB') && value.service_location.toUpperCase().includes('ONSITE')){
      return  equipment.data?.product_calibration_fee_info || [];
    }else if(value.service_location.toUpperCase().includes('LAB')){
      return  equipment.data?.product_calibration_fee_info.filter(item=> item.service_location.trim() == "LAB") || [];
    }else{
      return  equipment.data?.product_calibration_fee_info.filter(item=> item.service_location.trim() == "ONSITE") || [];
    }
  }

  sortDetailList(items){
    items.sort(function(a, b) {
      if (a.mit_method_info.method_name > b.mit_method_info.method_name) return 1;
      if (a.mit_method_info.method_name < b.mit_method_info.method_name) return -1;
      
      if (a.unit_info.unit_short_name_th > b.unit_info.unit_short_name_th) return 1;
      if (a.unit_info.unit_short_name_th < b.unit_info.unit_short_name_th) return -1;

      return parseFloat(a.min_range) - parseFloat(b.min_range);
    });
    return items;
  }

  setOptionServiceLocation(service_location = null){
    if (service_location.includes('ONSITE') && service_location.includes('LAB')){
      this.service = "";
    }else if(service_location.includes('ONSITE')){
      this.service = "ONSITE";
    }else{
      this.service = "LAB";
    }
  }

  async selectUnit(event: string, index: number){
    let units = this.getUnits(event);
    let detail = this.detailList.controls[index].value;
    const unit = units.find(v=>
       v.unit_id == event
    );
    const equipment = this.getEquipmentList(detail.mit_equipment_id);
    let detailList = this.getDetailList(equipment);
    detailList = this.sortDetailList(detailList);
    
    if(detailList[index].unit_id != unit.unit_id){

      const response = await this.getConvertRange(unit.unit_id, detailList[index]);
      this.detailList.controls[index].get('min_range').setValue(isNaN(response?.min_range)? 0: response?.min_range);
      this.detailList.controls[index].get('max_range').setValue(isNaN(response?.max_range)? 0: response?.max_range);
    }else{
      const response = await this.getConvertRange(detailList[index].unit_id, detailList[index]);
      this.detailList.controls[index].get('min_range').setValue(isNaN(response?.min_range)? 0: response?.min_range);
      this.detailList.controls[index].get('max_range').setValue(isNaN(response?.max_range)? 0: response?.max_range);
    }
    this.detailList.controls[index].get('cus_unit_name').setValue(unit.unit_short_name_en);

    if(detail.detail_type == 'range'){
      let selectRange = detail.quotation_mit_item_detail_range;
      if(selectRange.mit_unit_id !== unit.unit_id){
        let convertUnitMin = await this.ConvertService.convert({
          unit_id_2: selectRange.cus_unit_id,
          unit_id_1: unit.unit_id,
          val:parseFloat(this._utils.convertStingToNumber(selectRange.cus_min))
        });
        let convertUnitMax = await this.ConvertService.convert({
          unit_id_2: selectRange.cus_unit_id,
          unit_id_1: unit.unit_id,
          val:parseFloat(this._utils.convertStingToNumber(selectRange.cus_max))
        });
        this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
          cus_unit_id: unit.unit_id || "",
          cus_unit_name: unit.unit_short_name_en || "",
          mit_min: isNaN(convertUnitMin.resultData.val)? 0: convertUnitMin.resultData.val,
          mit_max: isNaN(convertUnitMax.resultData.val)? 0: convertUnitMax.resultData.val
        });
      }else{
        this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
          cus_unit_id: unit.unit_id || "",
          cus_unit_name: unit.unit_short_name_en || "",
          mit_min: selectRange?.cus_min,
          mit_max: selectRange?.cus_max
        });
      }
    }else{
      const control = 
      this.detailList.controls[index].get('quotation_mit_item_detail_points') as FormArray;
      control.controls.map(async (v: any)=>{
        if(v.value.mit_unit_id !== unit.unit_id){

          // console.log(
          //   {
          //     unit
          //   }
          // )
          // console.log(
          //   {
  
          //     unit_id_1: unit.unit_id,
          //     unit_id_2: v.value.cus_unit_id,
          //     val: v.value.cus_point
          //   }
          // )

          let convertUnit = await this.ConvertService.convert({
            unit_id_1: unit.unit_id,
            unit_id_2: v.value.cus_unit_id,
            val: v.value.cus_point
          });
          if(convertUnit.success){
            v.get('mit_point').setValue(
              isNaN(convertUnit.resultData.val)? 0: convertUnit.resultData.val
            );
          }
        }else{
          v.get('mit_point').setValue(v.value.cus_point);
        }
        v.get('cus_unit_id').setValue(unit.unit_id);
        v.get('cus_unit_name').setValue(unit.unit_short_name_en);
      })
    }
  }

  async getConvertRange(unitId, detailList): Promise<any>{
    if(unitId && detailList.unit_id){
      let convertMinRange = await this.ConvertService.convert({
        unit_id_2: unitId,
        unit_id_1: detailList.unit_id,
        val: detailList.min_range
      });
      let convertMaxRange = await this.ConvertService.convert({
        unit_id_2: unitId,
        unit_id_1: detailList.unit_id,
        val: detailList.max_range
      });
      return { 
        min_range: isNaN(convertMinRange.resultData.val)? 0: convertMinRange.resultData.val,
        max_range: isNaN(convertMaxRange.resultData.val)? 0: convertMaxRange.resultData.val,
      }
    }
  }

  async addPoint(detail: any, i: number, event: any){
    const point = event.target.value.trim();
    const control = 
    this.detailList.controls[i].get('quotation_mit_item_detail_points') as FormArray;
    const isDuplicateNumber = control.value.find(v=> v.cus_point == point);
    if(isDuplicateNumber){
      return;
    }
    if(point !== '' && !isNaN(point)){
      let units = this.getUnits(detail.unit_id);
      const unit = units.find(v=>
         v.unit_id == this.detailList.controls[i].get('cus_unit_id').value
      );
      let convertPoint = point;

      if(this.detailList.controls[i].get('unit_id').value != unit.unit_id){
        // console.log(
        //   {

        //     unit_id_1: unit.unit_id,
        //     unit_id_2: this.detailList.controls[i].get('unit_id').value,
        //     val: point
        //   }
        // )
        let convertResponse = await this.ConvertService.convert({
          unit_id_2: this.detailList.controls[i].get('unit_id').value,
          unit_id_1: unit.unit_id,
          val: point
        });
        if(convertResponse.success){
          convertPoint = isNaN(convertResponse.resultData.val)? 0: convertResponse.resultData.val;
        }
      }

      const data = {
        cus_point: point,
        cus_unit_id: unit.unit_id || "",
        cus_unit_name: unit.unit_short_name_en || "",
        mit_point: convertPoint,
        mit_unit_id: this.detailList.controls[i].get('unit_id').value || "",
        mit_unit_name: this.detailList.controls[i].get('unit_name').value || "",
      }
      event.preventDefault();
      event.target.value = "";
      control.push(this.formMitService.createPointItem(data));
      this.calculateTotalAmountItemDetail();
    }
  }

  removePoint(i: number, j: number){
    const control = 
    this.detailList.controls[i].get('quotation_mit_item_detail_points') as FormArray;
    control.removeAt(j);
    this.calculateTotalAmountItemDetail();
  }

  calculateTotalAmountItemDetail(){
    const getDetailList = this.detailList.value.filter(item =>(
      (item.min_point != 0 && item.min_point != null) && 
      (item.quotation_mit_item_detail_points.length > 0 ||
        (item.quotation_mit_item_detail_range.cus_unit_id != null &&
          item.quotation_mit_item_detail_range.cus_unit_id != '')
      )
    ));
    let detail_high_price_start = null;
    if(getDetailList.length > 0){
      detail_high_price_start = getDetailList.reduce((a, b) => a.price_start > b.price_start ? a : b);
    }
    this.detailList.controls.map((v, index)=>{
      const control = v.value;
      const number_of_detail_points = control.quotation_mit_item_detail_points.length;
      if(control.detail_type == 'point'){
        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
          if(control.min_point == number_of_detail_points){
            this.detailList.controls[index].get('total_amount').setValue(control.price_start);
          }else if(control.min_point > number_of_detail_points){
            this.detailList.controls[index].get('total_amount').setValue(number_of_detail_points * control.price_point);
          }else if (control.min_point < number_of_detail_points){
            const dif = number_of_detail_points - control.min_point;
            this.detailList.controls[index].get('total_amount').setValue((dif * control.price_point) + control.price_start);
          }
        }else{
          this.detailList.controls[index].get('total_amount').setValue(number_of_detail_points * control.price_point);
        }
      }else if(control.detail_type == 'range'){
        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
          this.detailList.controls[index].get('total_amount').setValue(control.price_start);
        }else{
          this.detailList.controls[index].get('total_amount').setValue(control.min_point * control.price_point);
        }
      }
    })  
    this.calculateTotalAmountItem();
  }

  calculateTotalAmountItem(){
    const form = this.form.value;
    let countOfItem = 0;
    let priceStart = 0;
    let isMinPoint = false;
    let mostTotalAmount = 0;
    let mostTotalAmountItem = null;

    if(form.quotation_mit_item_details.length > 0){
      form.quotation_mit_item_details.map((v: any)=>{
        if(v.total_amount != 0 && priceStart < v.price_start) {
          priceStart = v.price_start;
        }
        if(v.total_amount != 0 && v.min_point != 0) {
          isMinPoint = true;
        } 
        if(v.total_amount != 0 && mostTotalAmount < v.total_amount) {
          mostTotalAmount = v.total_amount;
          mostTotalAmountItem = v;
        } 
        countOfItem = countOfItem + v.total_amount;
      })
    }
    let count = countOfItem;

    if(form.quotation_mit_item_attributes.length > 0){
      this.attributeList.controls.map((v: any)=>{
        if(v.value.attribute_value){
          v.get('attribute_value').setValue(
            this._utils.transformDecimal(v.get('attribute_value').value, '1.0-0')
          );
        }else{
          v.get('attribute_value').setValue(null);
        }
      })
    }
    

    if(isMinPoint && this.attributeList.value.find(attr=> attr.attribute_value != null)){
      let sumAttr = 0;
      this.attributeList.value.map((v: any)=>{
        if(v.attribute_value) sumAttr = sumAttr + parseFloat(v.attribute_value);
      })
      sumAttr--;
      if(mostTotalAmountItem.detail_type == 'point'){
        count = count + ((mostTotalAmountItem.quotation_mit_item_detail_points.length * sumAttr) *  mostTotalAmountItem.price_point);
      }else{
        count = count + ((mostTotalAmountItem.min_point * sumAttr) *  mostTotalAmountItem.price_point);
      }
    }else{
      if(form.quotation_mit_item_attributes.length > 0){
        this.attributeList.value.map((v: any)=>{
          if(v.attribute_value) count = count * parseFloat(v.attribute_value);
        })
      }
      if(!isMinPoint){
        count = count + priceStart;
      }
    }
    this.form.get('original_price').setValue(count);
  }

  async convertSpecificRange(index: number){
    let selectRange = this.detailList.controls[index].value.quotation_mit_item_detail_range;
   if(selectRange.mit_unit_id != selectRange.cus_unit_id){
      let convertMinRange = await this.ConvertService.convert({
        unit_id_1: selectRange.cus_unit_id,
        unit_id_2: selectRange.mit_unit_id,
        val: this._utils.convertStingToNumber(selectRange.cus_min) || 0
      });
      let convertMaxRange = await this.ConvertService.convert({
        unit_id_1: selectRange.cus_unit_id,
        unit_id_2: selectRange.mit_unit_id,
        val: this._utils.convertStingToNumber(selectRange.cus_max) || 0
      });
      this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
        cus_min: this._utils.transformDecimal(selectRange.cus_min, '1.1-5'),
        cus_max: this._utils.transformDecimal(selectRange.cus_max, '1.1-5'),
        mit_min: isNaN(convertMinRange.resultData.val)? 0: convertMinRange.resultData.val,
        mit_max: isNaN(convertMaxRange.resultData.val)? 0: convertMaxRange.resultData.val,
      });
    }else{
      this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
        cus_min: this._utils.transformDecimal(selectRange.cus_min, '1.1-5'),
        cus_max: this._utils.transformDecimal(selectRange.cus_max, '1.1-5'),
        mit_min: selectRange.cus_min,
        mit_max: selectRange.cus_max,
      });
    }
  }

  async submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.detailList.controls.map((v, index)=>{
      v.get('min_range').setValue(this.oldRange[index].min);
      v.get('max_range').setValue(this.oldRange[index].max);
    });
    let data = {
      ...this.form.value
    }
    data = await this.generateMitItemLogService.createMitItemLog(data);

    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.form.get('mit_equipment_id').disable();
        this.form.get('mit_method_id').disable();
        this.form.get('service_lab').disable();
        this.form.get('service_onsite').disable();
        this.createForm();
      }
    });
  }

  onKeyup(){
    this.subject.next();
  }

  onKeyupRange(i){
    this.subject_convert_range.next(i);
  }

  getUnits(unit_id: string): any{
    let result = [];
    this.units.map(v=>{
      const unit = v.units.filter(unit => unit.unit_id == unit_id);
      if (unit.length > 0){
        result = v.units;
      }
    })
    return result;
  }

  async setDetailType(i: number, type: string){
    this.detailList.controls.map(async (v, index)=>{
      if(i == index && v.value.detail_type != type){
        const selectDetail = this.oldMitItemDetails[i];
        v.get('detail_type').setValue(type);
        v.get('detail_input_type').setValue(type);
        if(type == 'point'){
          this.detailList.controls[index].get('cus_unit_id').setValue(selectDetail.cus_unit_id);
          this.detailList.controls[index].get('cus_unit_name').setValue(selectDetail.cus_unit_name);
          this.detailList.controls[index].get('min_range').setValue(this._utils.convertStingToNumber(selectDetail.min_range));
          this.detailList.controls[index].get('max_range').setValue(this._utils.convertStingToNumber(selectDetail.max_range));

          v.get('quotation_mit_item_detail_range').reset();
        }else{
          let units = this.getUnits(selectDetail.unit_id);
          const unit = units.find(item=>
             item.unit_id == selectDetail.cus_unit_id
          );
          const control = 
          this.detailList.controls[index].get('quotation_mit_item_detail_points') as FormArray;
          control.clear();
          this.detailList.controls[index].get('cus_unit_id').setValue(selectDetail.cus_unit_id);
          this.detailList.controls[index].get('cus_unit_name').setValue(selectDetail.cus_unit_name);
          this.detailList.controls[index].get('min_range').setValue(this._utils.convertStingToNumber(selectDetail.min_range));
          this.detailList.controls[index].get('max_range').setValue(this._utils.convertStingToNumber(selectDetail.max_range));
          v.get('quotation_mit_item_detail_range').patchValue({
            cus_min: this._utils.transformDecimal(this._utils.convertStingToNumber(selectDetail.min_range), '1.1-5'),
            cus_max: this._utils.transformDecimal(this._utils.convertStingToNumber(selectDetail.max_range), '1.1-5'),
            cus_unit_id: selectDetail.cus_unit_id || "",
            cus_unit_name: unit.unit_short_name_en || "",
            mit_min: this._utils.transformDecimal(this._utils.convertStingToNumber(selectDetail.min_range), '1.1-5'),
            mit_max: this._utils.transformDecimal(this._utils.convertStingToNumber(selectDetail.max_range), '1.1-5'),
            mit_unit_id: selectDetail.unit_id || "",
            mit_unit_name:selectDetail.unit_name || ""
          });
        }
      }
    })
    this.calculateTotalAmountItemDetail();
  }
}