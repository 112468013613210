import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { CustomerAddressService } from "src/app/services";
import {
  QuotationMitItemAttributeService,
  QuotationMitItemDetailPointService,
  QuotationMitItemDetailRangeService,
  QuotationMitItemDetailService,
  QuotationUtilsService,
} from "src/app/services/quotations";
import {
  JobOrderCalibrationItemService,
  JobOrderContactService,
  JobOrderInfoService,
} from "src/app/services/job-orders";
import domtoimage from "dom-to-image-more";
import jsPDF from "jspdf";
import { Title } from "@angular/platform-browser";
import { PDF } from "../../../quotation/quotation-pdf/pdf.service";

@Component({
  selector: "app-ecl-ecs",
  templateUrl: "./ecl-ecs.component.html",
  styleUrls: ["./ecl-ecs.component.css"],
})
export class EclEcsComponent implements OnInit {
  contactList = [];

  pages = [];
  jobOrderItems = [];
  reportAddressList = [];
  sendReportAddressList = [];
  chargeReportAddressList = [];

  job_order_info_id: string = "";
  token: string = "";
  textBottomPage: string = "FM-ADM-03, Revise 4, Effective Date 01/11/21";

  customer_info = null;
  jobOrderInfo = null;

  canAccess: boolean = false;
  pageLoad: boolean = false;

  constructor(
    private getDataPdf: PDF,
    private titleService: Title,
    private route: ActivatedRoute,
    private JobOrderInfoService: JobOrderInfoService,
    private JobOrderContactService: JobOrderContactService,
    private CustomerAddressService: CustomerAddressService,
    private QuotationMitItemDetailPointService: QuotationMitItemDetailPointService,
    private QuotationMitItemDetailRangeService: QuotationMitItemDetailRangeService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService,
    private QuotationMitItemDetailService: QuotationMitItemDetailService,
    private QuotationMitItemAttributeService: QuotationMitItemAttributeService,

    public _utils: QuotationUtilsService
  ) {
    this.job_order_info_id =
      this.route.snapshot.paramMap.get("job_order_info_id");
    this.route.queryParams.subscribe((params) => {
      this.token = params["token"];
    });
  }

  async ngOnInit() {
    if (!this.token) {
      await this.getPrivateData();
    } else {
      await this.getPublicData();
    }
  }

  async getPrivateData() {
    await this.JobOrderInfoService.getById({
      job_order_info_id: this.job_order_info_id,
    }).then(async (res) => {
      if (res?.success) {
        this.canAccess = true;
        this.pageLoad = true;

        this.jobOrderInfo = res.resultData || {};
        this.titleService.setTitle(
          this.jobOrderInfo.doc_no + " - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี"
        );
        if (this.jobOrderInfo.customer_address_info) {
          this.jobOrderInfo["customer_address"] = this._utils.genAddress(
            this.jobOrderInfo.customer_address_info
          );
        }

        await this.JobOrderCalibrationItemService.load(null, {
          job_order_info_id: this.job_order_info_id,
          order_by: "order:asc",
        }).then(async (res) => {
          this.jobOrderItems = res.resultData || [];

          await Promise.all(
            this.jobOrderItems.map(async (value) => {
              value["quotation_mit_item_details"] = [];

              if (value.quotation_mit_item_id) {
                await this.QuotationMitItemDetailService.load(null, {
                  quotation_mit_item_id: value.quotation_mit_item_id,
                  order_by: "order_by:asc",
                }).then(async (res) => {
                  let resultData = res.resultData;

                  value["quotation_mit_item_details"] = [];
                  await Promise.all(
                    resultData.map(async (data: any) => {
                      let resultData = await this.mapMitItemData(data);
                      if (
                        resultData.quotation_mit_item_detail_points.length >
                          0 ||
                        resultData.quotation_mit_item_detail_range
                          .cus_unit_id != ""
                      ) {
                        value.quotation_mit_item_details.push(resultData);
                      }
                    })
                  );
                  value.quotation_mit_item_info.quotation_mit_item_detail_info =
                    value.quotation_mit_item_details;
                });

                await this.QuotationMitItemAttributeService.load(null, {
                  quotation_mit_item_id: value.quotation_mit_item_id,
                }).then((res) => {
                  value["quotation_mit_item_attributes"] = res.resultData;
                });
              }
            })
          );
        });
        for (var i = 0; i < this.jobOrderItems.length; i++) {
          this.jobOrderItems[i].index = i;
        }
        for (var i = 0; i < this.jobOrderItems.length; i += 20) {
          this.pages.push(this.jobOrderItems.slice(i, i + 20));
        }

        await this.CustomerAddressService.load(null, {
          is_registed_address: "false",
          customer_id: this.jobOrderInfo.customer_id,
        }).then(async (res) => {
          let resultData = res.resultData || [];

          this.sendReportAddressList = resultData
            .filter((item) => item.customer_address_type_id === 6)
            .map((elem) => ({
              id: elem.customer_address_id,
              text: this._utils.genAddress(elem),
              data: elem,
            }));

          this.chargeReportAddressList = resultData
            .filter((item) => item.customer_address_type_id === 3)
            .map((elem) => ({
              id: elem.customer_address_id,
              text: this._utils.genAddress(elem),
              data: elem,
            }));
        });

        if (this.jobOrderInfo.report_address_id) {
          let findReportAddress = this.sendReportAddressList.find(
            (send) => send.id == this.jobOrderInfo.report_address_id
          );
          if (findReportAddress) {
            this.jobOrderInfo["report_address"] = findReportAddress.text;
            this.jobOrderInfo["report_address_name"] =
              findReportAddress.data.address_name;
          }
        }

        if (this.jobOrderInfo.charge_address_id) {
          let findChargeAddress = this.chargeReportAddressList.find(
            (send) => send.id == this.jobOrderInfo.charge_address_id
          );
          if (findChargeAddress) {
            this.jobOrderInfo["charge_address"] = findChargeAddress.text;
            this.jobOrderInfo["charge_address_name"] =
              findChargeAddress.data.address_name;
            this.jobOrderInfo["charge_contact"] =
              "Tel: " +
              findChargeAddress.data.tel_1 +
              " Fax: " +
              findChargeAddress.data.fax_1;
          }
        }

        await this.JobOrderContactService.load(null, {
          job_order_info_id: this.job_order_info_id,
        }).then((res) => {
          this.contactList = res.resultData || [];
        });
      } else {
        this.canAccess = false;
        this.pageLoad = true;
        this.titleService.setTitle(" - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี");
      }
    });
  }

  async getPublicData() {
    try {
      await this.getDataPdf
        .get(this.token, "/job-order-info/" + this.job_order_info_id)
        .then(async (res) => {
          this.canAccess = true;
          this.pageLoad = true;
          this.jobOrderInfo = res.resultData || {};
          this.titleService.setTitle(
            this.jobOrderInfo.doc_no + " - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี"
          );

          await this.getDataPdf
            .get(
              this.token,
              "/job-order-calibration-item?job_order_info_id=" +
                this.job_order_info_id +
                "&order_by=order:asc"
            )
            .then(async (res) => {
              this.jobOrderItems = res.resultData || [];

              await Promise.all(
                this.jobOrderItems.map(async (value) => {
                  value["quotation_mit_item_details"] = [];

                  if (value.quotation_mit_item_id) {
                    await this.getDataPdf
                      .get(
                        this.token,
                        "/quotation-mit-item-detail?order_by=order_by:asc&&quotation_mit_item_id=" +
                          value.quotation_mit_item_id
                      )
                      .then(async (res) => {
                        let resultData = res.resultData;

                        value["quotation_mit_item_details"] = [];
                        await Promise.all(
                          resultData.map(async (data: any) => {
                            let resultData = await this.mapMitItemData(data);
                            if (
                              resultData.quotation_mit_item_detail_points
                                .length > 0 ||
                              resultData.quotation_mit_item_detail_range
                                .cus_unit_id != ""
                            ) {
                              value.quotation_mit_item_details.push(resultData);
                            }
                          })
                        );
                        value.quotation_mit_item_info.quotation_mit_item_detail_info =
                          value.quotation_mit_item_details;
                      });

                    await this.getDataPdf
                      .get(
                        this.token,
                        "/quotation-mit-item-attribute?quotation_mit_item_id=" +
                          value.quotation_mit_item_id
                      )
                      .then(async (res) => {
                        value["quotation_mit_item_attributes"] = res.resultData;
                      });
                  }
                })
              );
            });

          for (var i = 0; i < this.jobOrderItems.length; i++) {
            this.jobOrderItems[i].index = i;
          }
          for (var i = 0; i < this.jobOrderItems.length; i += 20) {
            this.pages.push(this.jobOrderItems.slice(i, i + 20));
          }

          await this.getDataPdf
            .get(
              this.token,
              "/customer-address?is_registed_address=false&&customer_id=" +
                this.jobOrderInfo.customer_id
            )
            .then((res) => {
              let resultData = res.resultData || [];

              this.sendReportAddressList = resultData
                .filter((item) => item.customer_address_type_id === 6)
                .map((elem) => ({
                  id: elem.customer_address_id,
                  text: this._utils.genAddress(elem),
                  data: elem,
                }));

              this.chargeReportAddressList = resultData
                .filter((item) => item.customer_address_type_id === 3)
                .map((elem) => ({
                  id: elem.customer_address_id,
                  text: this._utils.genAddress(elem),
                  data: elem,
                }));
            });

          if (this.jobOrderInfo.send_report_address_id) {
            let findSendReportAddress = this.sendReportAddressList.find(
              (send) => send.id == this.jobOrderInfo.send_report_address_id
            );
            if (findSendReportAddress) {
              this.jobOrderInfo["send_report_address"] =
                findSendReportAddress.text;
              this.jobOrderInfo["send_report_address_name"] =
                findSendReportAddress.data.address_name;
            }
          }

          if (this.jobOrderInfo.charge_address_id) {
            let findChargeAddress = this.chargeReportAddressList.find(
              (send) => send.id == this.jobOrderInfo.charge_address_id
            );
            if (findChargeAddress) {
              this.jobOrderInfo["charge_address"] = findChargeAddress.text;
              this.jobOrderInfo["charge_address_name"] =
                findChargeAddress.data.address_name;
              this.jobOrderInfo["charge_contact"] =
                "Tel: " +
                findChargeAddress.data.tel_1 +
                " Fax: " +
                findChargeAddress.data.fax_1;
            }
          }

          await this.getDataPdf
            .get(
              this.token,
              "/job-order-contact?job_order_info_id=" + this.job_order_info_id
            )
            .then((res) => {
              this.contactList = res.resultData || [];
            });
        });
    } catch (error) {
      this.canAccess = false;
      this.pageLoad = true;
      this.titleService.setTitle(" - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี");
    }
  }

  async mapMitItemData(mit: any): Promise<any> {
    let points = [];
    let range = [];
    if (!this.token) {
      if (mit.total_amount > 0) {
        if (mit.detail_input_type == "point") {
          points = await this.QuotationMitItemDetailPointService.load(null, {
            quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id,
            order_by: "order_by:asc",
          }).then((res) => {
            return res.resultData || [];
          });
        } else {
          range = await this.QuotationMitItemDetailRangeService.load(null, {
            quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id,
          }).then((res) => {
            return res.resultData || [];
          });
        }
      }
    } else {
      if (mit.total_amount > 0) {
        if (mit.detail_input_type == "point") {
          points = await this.getDataPdf
            .get(
              this.token,
              "/quotation-mit-item-detail-points?order_by=order_by:asc&&quotation_mit_item_detail_id=" +
                mit.quotation_mit_item_detail_id
            )
            .then((res) => {
              return res.resultData || [];
            });
        } else {
          range = await this.getDataPdf
            .get(
              this.token,
              "/quotation-mit-item-detail-ranges?quotation_mit_item_detail_id=" +
                mit.quotation_mit_item_detail_id
            )
            .then((res) => {
              return res.resultData || [];
            });
        }
      }
    }

    await points.map((point) => {
      if (point.cus_unit_info)
        point["cus_unit_name"] =
          point.cus_unit_info.unit_short_name_en ||
          point.cus_unit_info.unit_short_name_th;
      if (point.mit_unit_info)
        point["mit_unit_name"] =
          point.mit_unit_info.unit_short_name_en ||
          point.mit_unit_info.unit_short_name_th;
    });

    return {
      ...mit,
      detail_type: mit.detail_input_type,
      quotation_mit_item_detail_range: {
        quotation_mit_item_detail_range_id:
          range[0]?.quotation_mit_item_detail_range_id || "",
        cus_min: range[0]?.cus_min || 0,
        cus_max: range[0]?.cus_max || 0,
        cus_unit_id: range[0]?.cus_unit_id || "",
        cus_unit_name: range[0]?.cus_unit_info.unit_short_name_en || "",
        mit_min: range[0]?.mit_min || 0,
        mit_max: range[0]?.mit_max || 0,
        mit_unit_id: range[0]?.mit_unit_id || "",
        mit_unit_name: range[0]?.mit_unit_info.unit_short_name_en || "",
      },
      cus_unit_id: mit.unit_id,
      unit_name: mit.unit_short_name_en,
      min_range: mit.product_calibration_fee_info.min_range,
      max_range: mit.product_calibration_fee_info.max_range,
      quotation_mit_item_detail_points: points,
    };
  }

  async print(jobOrderInfo) {
    const job_order_sections = document.querySelectorAll(".pdf");
    let doc = new jsPDF("p", "mm", "a4", true);
    doc.setFontSize(8);
    let page = 1;
    let perPage = Array.prototype.slice.call(job_order_sections, 0).length;

    let jobOrderList = [];
    await Promise.all(
      Array.prototype.slice
        .call(job_order_sections, 0)
        .map(async (value, index) => {
          let job_order_section = document.getElementById("job_order_" + index);
          await domtoimage
            .toJpeg(job_order_section, {
              background: "white",
              allowTaint: true,
              width: job_order_section.clientWidth * 2,
              height: job_order_section.clientHeight * 2,
              style: {
                transform: "scale(" + 2 + ")",
                transformOrigin: "top left",
              },
              quality: 0.95,
            })
            .then(async function (canvas) {
              jobOrderList.push({
                index: index,
                canvas: canvas,
                job_order_section: job_order_section,
              });
            });
        })
    );
    jobOrderList.sort(function (a, b) {
      return a.index - b.index;
    });
    await Promise.all(
      jobOrderList.map(async (value, index) => {
        var imgWidth = 208;
        var imgHeight =
          (value.job_order_section.clientHeight * imgWidth) /
          value.job_order_section.clientWidth;
        if (index != 0) doc.addPage("a4");
        doc.addImage(value.canvas, "JPEG", 0, 0, imgWidth, imgHeight, null);
        doc.text(this.textBottomPage, 130, 285);
        doc.text(page + " /  " + perPage, 200, 285);
        page = page + 1;

        if (index == jobOrderList.length - 1) {
          doc.save(jobOrderInfo.doc_no);
        }
      })
    );
  }

  public getMitAttribute(attribute): any {
    let result = "";
    attribute.map((v) => {
      if (result == "") {
        result = v.attribute_name + ": " + v.attribute_value;
      } else {
        result = result + ", " + v.attribute_name + ": " + v.attribute_value;
      }
    });
    return result;
  }

  public getMitNote(details): any {
    let result = "";
    details.map((v) => {
      if (result == "") {
        result = v.note;
      } else {
        result = result + ", " + v.note;
      }
    });
    return result;
  }
}
