import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class ProductService {
  async create({
    product_type_id,
    product_code,
    product_title,
    product_description,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/products`, {
        product_type_id,
        product_code,
        product_title,
        product_description,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    product_id,
    product_type_id,
    product_code,
    product_title,
    product_description,
  }) {
    let result = null;
    try {
      result = await Axios().put(`/products/${product_id}`, {
        product_type_id,
        product_code,
        product_title,
        product_description,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ product_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/products/${product_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/products`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter, order_by: "product_code:asc" },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ product_id }) {
    let result = null;
    try {
      result = await Axios().get(`/products/${product_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async genProductCode() {
    let result = null;
    try {
      result = await Axios().get(`/product-code`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
