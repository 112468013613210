import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class CarInfoService {
  async create({ car_plate, car_type_id, brand, model }) {
    let result = null;
    try {
      result = await Axios().post(`/car-info`, {
        car_plate, car_type_id, brand, model
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({ car_info_id, car_plate, car_type_id, brand, model }) {
    let result = null;
    try {
      result = await Axios().put(
        `/car-info/${car_info_id}`,
        {
          car_plate, car_type_id, brand, model
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ car_info_id }) {
    let result = null;
    try {
      result = await Axios().delete(
        `/car-info/${car_info_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/car-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter, 'order_by': 'car_type_id:asc,brand:asc,model:asc,car_plate:asc' },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ car_info_id }) {
    let result = null;
    try {
      result = await Axios().get(
        `/car-info/${car_info_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
