import { Injectable } from "@angular/core";
import { DecimalPipe } from '@angular/common';
import { FormArray, ValidatorFn, FormGroup } from '@angular/forms';

@Injectable({
   providedIn: "root"
})
export class QuotationUtilsService {
  constructor(
    private decPipe: DecimalPipe
  ) {}

  transformDecimal(num: any, format = '1.2-2'){
    num = this.convertStingToNumber(num);
    if(!isNaN(num) && isFinite(num) && num != null){
      var number = this.decPipe.transform(num, format);
    }else{
      var number = this.decPipe.transform(0, format);
    }
    return number;
  }

  convertStingToNumber(str){
    if(str == undefined) return;
    if(isNaN(str)) str = str.split(',').join('');
    return str;
  }

  setOrderBy(itemList: FormArray, key: string){
    let defaultNumber = 12500;
    let i = 1;
    itemList.controls.map(item => {
      if (item.value.hasOwnProperty(key)) {
        item.get(key).setValue(defaultNumber * i);
      }
      i = i * 2;
    });
    return itemList;
  }

  setOrderByMitItem(itemList = [], key: string){
    let defaultNumber = 12500;
    let i = 1;
    itemList.map(v => {
      if (v.hasOwnProperty(key)) {
        v[key] = defaultNumber * i;
      }
      i = i * 2;
    });
    return itemList;
  }

  calculateOrderBy(
    currentIndex: number, 
    itemList: FormArray, 
    key: string) {
    let previous = 0;
    let next = 0;
    itemList.value.map((item, index) => {
      if (currentIndex == 0) {
        if (currentIndex + 1 == index) {
          previous = (item[key] / 2);
          next = item[key];
        }
      } else {
        if (currentIndex - 1 == index) {
          previous = item[key];
        } else if (currentIndex + 1 == index) {
          next = item[key];
        }
      }
    })
    if (next ==0) next = (previous * 2);
    let calculate = (previous + next) / 2;
    
    const item = itemList.controls.find((item: any, index)=> currentIndex == index);
    if(item) item.get(key).setValue(calculate);
    return itemList;
  }

  customValidatePoints(): ValidatorFn {
    return (FormGroup:FormGroup):{[key: string]: any} | null=>{
      const form = FormGroup.getRawValue();
      let result = true;
      if(form && form.min_point != 0 && form.quotation_mit_item_detail_points.length > 0){
        if(form.min_point > form.quotation_mit_item_detail_points.length){
          result = false;
        }else{
          result = true;
        }
      }
      return !result ? { invalidPoint: true } : null;
    }
  };

  customValidateRangePoints(): ValidatorFn {
    return (FormGroup:FormGroup):{[key: string]: any} | null=>{
      const form = FormGroup.getRawValue();
      let result = false;
      if(form.detail_type == 'range'){
        let range = form.quotation_mit_item_detail_range;
        if(parseFloat(this.convertStingToNumber(range.cus_min)) < parseFloat(form.min_range) 
        || parseFloat(this.convertStingToNumber(range.cus_max)) > parseFloat(form.max_range)
        || parseFloat(this.convertStingToNumber(range.cus_min)) > parseFloat(this.convertStingToNumber(range.cus_max))      
        ){
          result = true;
        }else{
          result = false;
        }
      }else{
        result = form.quotation_mit_item_detail_points.find(
          (item: any)=>  parseFloat(item.cus_point) < parseFloat(this.convertStingToNumber(this.transformDecimal(form.min_range, '1.1-5'))) || parseFloat(item.cus_point) > parseFloat(this.convertStingToNumber(this.transformDecimal(form.max_range, '1.1-5')))
        );
      }
      return result ? { invalidRangePoint: true } : null;
    }
  };

  customValidateRequirePoints(): ValidatorFn {
    return (FormGroup:FormGroup):{[key: string]: any} | null=>{
      const form = FormGroup.getRawValue();
      let result = false;

      if(form.quotation_mit_item_details){
        form.quotation_mit_item_details.map(v=>{
          if(v.detail_type == 'point'){
            if(v.quotation_mit_item_detail_points.length > 0){
              result = true;
            }
          }else{
            result = true;
          }
        })
        return !result ? { invalidRequirePoint: true } : null;
      }else{
        form.quotation_mit_item_detail_info.map(v=>{
          if(v.detail_type == 'point'){
            if(v.quotation_mit_item_detail_points.length > 0){
              result = true;
            }
          }else{
            result = true;
          }
        })
        return !result ? { invalidRequirePoint: true } : null;
      }
    }
  };

  customValidateOneType(): ValidatorFn {
    let isPoint: boolean = false;
    let isRange: boolean = false;
    return (FormGroup:FormGroup):{[key: string]: any} | null=>{
      if(FormGroup.getRawValue().quotation_mit_item_details){
        var form = FormGroup.getRawValue().quotation_mit_item_details.filter(item => item.quotation_mit_item_detail_points.length > 0 || (item.quotation_mit_item_detail_range.cus_unit_id != "" && item.quotation_mit_item_detail_range.cus_unit_id != null));
      }else{
        var form = FormGroup.getRawValue().quotation_mit_item_detail_info.filter(item => item.quotation_mit_item_detail_points.length > 0 || (item.quotation_mit_item_detail_range.cus_unit_id != "" && item.quotation_mit_item_detail_range.cus_unit_id != null));
      }

      isRange = form.find(v=> v.detail_type == 'range');
      isPoint = form.find(v=> v.detail_type == 'point');
      return isRange &&  isPoint ? { invalidTypePoint: true } : null;
    }
  };

  groupUnit(units) {
    let groups = {};
    for (let unit of units) {
      if (!groups[unit.unit_type_id]) {
        groups[unit.unit_type_id] = unit.unit_type_info;
      }
    }
    let groupUnits = {};
    for (let unit of units) {
      if (!groupUnits[unit.unit_type_id]) {
        groupUnits[unit.unit_type_id] = [];
      }
      groupUnits[unit.unit_type_id].push(unit);
    }
    let groupByUnits = [];
    for (let group in groups) {
      groupByUnits.push({
        unit_type_info: groups[group],
        units: groupUnits[group],
      });
    }
    return groupByUnits;
  }

  groupNotes(notes) {
    let groups = {};
    for (let note of notes) {
      if (!groups[note.note_type] && note.note_type != "standard_out") {
        groups[note.note_type] = note;
      }
    }
    let groupNotes= {};

    for (let note of notes) {
      if (!groupNotes[note.note_type]) {
        groupNotes[note.note_type] = [];
      }
      groupNotes[note.note_type].push(note);
    }
    let groupByNotes = [];
    for (let group in groups) {
      groupByNotes.push({
        id: groups[group].note_type,
        text: groups[group].note_type== 'standard_in'? 'มาตรฐาน': 'ทั่วไป',
        children: [...groupNotes[group]],
      });
    }

    groupByNotes.sort(function(x, y) {
      return (x.id === y.id)? 0 : x.id? -1 : 1;
    });
    return groupByNotes;
  }

  genCustomerAddressList(elem){
    let department = elem?.department?` แผนก: ${elem?.department}`: '';
    return {
      id: elem.customer_address_id,
      customer_id: elem.customer_id,
      text: elem.branch? `${elem.address_name} (${elem.branch})` + department: `${elem.address_name}` + department,
      address_name: this.genAddress(elem),
      data: elem
    }
  }

  genAddress(address: any): string {
    if (address.province_id === 1){
      var result = `${address.address} 
${(address.district_name)?'แขวง': ''}${address.district_name || ''} 
${(address.amphure_name)?'เขต': ''}${address.amphure_name || ''} 
${(address.province_name)?'จังหวัด': ''}${address.province_name || ''} 
${address?.zip_code  || ''}`;
    }else{
      var result = `${address.address} 
${(address.district_name)?'ตำบล': ''}${address.district_name || ''} 
${(address.amphure_name)?'อำเภอ': ''}${address.amphure_name || ''} 
${(address.province_name)?'จังหวัด': ''}${address.province_name || ''} 
${address?.zip_code  || ''}`;
    }
    return result || "";
  }
}
