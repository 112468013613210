<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="debit-note-filter"
  tabindex="-1"
  role="dialog"
  aria-labelledby="TransportreceiveFilterModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="TransportreceiveFilterModal">
          สร้างใบเพิ่มหนี้
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div class="form-body">

              <div class="mt-1">

                <form-group
                [control]="form.controls.customer_id"
                [submitted]="submitted"
                >
                  <label for="">
                    ค้นหาจาก Customer name <span class="danger">*</span>
                  </label>
                  <ng-select2
                    *ngIf="reset"
                    [options]="select2OptionCustomer"
                    class="w-100"
                    placeholder="Customer name"
                    formControlName="customer_id"
                    [allowClear]="true"
                    >
                  </ng-select2>
                </form-group>

                <form-group
                *ngIf="form.value.customer_id"
                [control]="form.controls.ref_doc_type"
                [submitted]="submitted"
              >

                <div class="input-group">
                  <div
                    class="d-inline-block custom-control custom-radio mr-1"
                  >
                    <input
                      type="radio"
                      (click)="changeTyoe()"
                      value="INVOICE"
                      formControlName="ref_doc_type"
                      class="custom-control-input"
                      id="ref_doc_type_1"
                    />
                    <label
                      class="custom-control-label"
                      for="ref_doc_type_1"
                      >ใบแจ้งหนี้</label
                    >
                  </div>
                  <div
                    class="d-inline-block custom-control custom-radio mr-1"
                  >
                    <input
                      type="radio"
                      (click)="changeTyoe()"
                      value="TAX_INVOICE"
                      formControlName="ref_doc_type"
                      class="custom-control-input"
                      id="ref_doc_type_2"
                    />
                    <label
                      class="custom-control-label"
                      for="ref_doc_type_2"
                      >ใบกำกับภาษี</label
                    >
                  </div>
                </div>
                </form-group>

                <form-group
                *ngIf="form.value.customer_id"
                [control]="form.controls.ref_acc_document_info_id"
                [submitted]="submitted"
                >
                  <label for="">
                    เลือกเอกสาร <span class="danger">*</span>
                  </label>
                  <ng-select2
                    [data]="items"
                    class="w-100"
                    placeholder="เลือกเอกสาร"
                    formControlName="ref_acc_document_info_id"
                    [allowClear]="true"
                    >
                  </ng-select2>
                </form-group>

                <form-group
                *ngIf="form.value.customer_id"
                [control]="form.controls.doc_reason"
                [submitted]="submitted">
                <label for="doc_reason">
                    เหตุผล <span class="danger">*</span>
                </label>
                <textarea
                  placeholder="เหตุผล"
                  class="form-control"
                  rows="2"
                  id="doc_reason"
                  formControlName="doc_reason"
                  >
                </textarea>
                </form-group>
              </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ตกลง
        </button>
      </div>
    </div>
  </div>
</div>
