import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class EmployeeLevelService {
  async load() {
    let result = null;
    try {
      result = await Axios().get(`/employee-level`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
