import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  NgZone,
  Input,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import {
  CustomerEstDateCaliService,
  CustomerAdditionFieldService,
  ResponseStatusService,
} from "src/app/services";
import { Router, ActivatedRoute } from "@angular/router";
import moment from "moment";
import { Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { Observable } from "rxjs";

@Component({
  selector: "app-customer-marketing",
  templateUrl: "./customer-marketing.component.html",
  styleUrls: ["./customer-marketing.component.css"],
})
export class CustomerMarketingComponent implements OnInit {
  openEstModal = false;
  date = moment().format();
  est_calis = [];
  customer_est_date = null;
  formEst: FormGroup;
  resetEstDate = false;
  submittedEst = false;

  estStatus = null;

  products = [];
  resetProduct = false;

  status: string;
  customer_id: string;
  reset: boolean = false;
  loadEstDate: Function = this.CustomerEstDateCaliService.load;
  filter = {};
  estFilter = {};

  form: FormGroup;
  additionForm: FormGroup;
  submitted = false;

  customer_addtion_fields = null;
  @ViewChild("closeModalBtn") private closeModalBtn: ElementRef;
  @Input("can-edit-customer") canEditCustomer = false;
  @Select(UserInfoState.getUser) userInfo: Observable<any>;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private CustomerEstDateCaliService: CustomerEstDateCaliService,
    private CustomerAdditionFieldService: CustomerAdditionFieldService,
    private ResponseStatusService: ResponseStatusService
  ) {
    this.setSWOTForm();
    this.setAdditionForm();
    this.setEstForm();
    this.customer_id = this.route.snapshot.paramMap.get("customer_id");
    this.estFilter = {
      customer_id: this.customer_id,
      order_by: "est_date:desc",
    };
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  setEstForm() {
    this.formEst = this.formBuilder.group({
      est_date: ["", [Validators.required]],
    });
  }

  setSWOTForm() {
    this.form = this.formBuilder.group({
      swot_strength: [""],
      swot_weakness: [""],
      swot_opportunity: [""],
      swot_threat: [""],
    });
  }

  setAdditionForm() {
    this.additionForm = this.formBuilder.group({
      calibration_cycle_month: [""],
      calibration_cycle_memo: [""],
      lab_using: [""],
    });
  }

  enableForm(form) {
    this[form].enable();
  }

  cancelSWOT() {
    this.setSWOTData();
  }

  cancelAddition() {
    this.setAdditionData();
  }

  setSWOTData() {
    for (let key in this.form.value) {
      this.form.controls[key].setValue(
        this.customer_addtion_fields ? this.customer_addtion_fields[key] : ""
      );
    }
    this.form.disable();
  }

  setAdditionData() {
    for (let key in this.additionForm.value) {
      this.additionForm.controls[key].setValue(
        this.customer_addtion_fields ? this.customer_addtion_fields[key] : ""
      );
    }
    this.additionForm.disable();
  }

  setEstData() {
    if (!this.customer_est_date) return;
    this.formEst.controls.est_date.setValue(
      this.customer_est_date.est_date
        ? moment(this.customer_est_date.est_date).format("DD-MM-YYYY")
        : ""
    );
  }

  async ngOnInit() {
    let reuslt = await this.CustomerAdditionFieldService.load(null, {
      customer_id: this.customer_id,
    });

    this.customer_addtion_fields = reuslt.resultData[0];
    this.setSWOTData();
    this.setAdditionData();
  }
  onChangeEstDatePage(est_calis: Array<any>) {
    this.est_calis = est_calis;
  }

  onChangeProductPage(products: Array<any>) {
    this.products = products;
  }

  selectEst(customer_est_date) {
    this.date = customer_est_date.est_date;
    this.customer_est_date = customer_est_date;
  }
  doResetEstDate(status?) {
    this.resetEstDate = false;
    setTimeout(() => {
      this.resetEstDate = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = filter;
  }
  async deleteEstDate(closeModalEl) {
    let response = await this.CustomerEstDateCaliService.delete({
      customer_est_date_cali_id: this.customer_est_date
        .customer_est_date_cali_id,
    });
    closeModalEl.click();
    this.doResetEstDate(response.success || response.error);
  }

  async submitEst() {
    this.submittedEst = true;
    if (this.formEst.invalid) return;
    let res = null;
    if (!this.customer_est_date) {
      res = await this.CustomerEstDateCaliService.create({
        ...this.formEst.value,
        customer_id: this.customer_id,
      });
    } else {
      res = await this.CustomerEstDateCaliService.update({
        ...this.formEst.value,
        customer_id: this.customer_id,
        customer_est_date_cali_id: this.customer_est_date
          .customer_est_date_cali_id,
      });
    }
    if (res.success) {
      this.closeModalBtn.nativeElement.click();
      this.doResetEstDate();
    } else {
      if (res.error.resultCode === "40900") {
        this.estStatus = this.ResponseStatusService.getResponseStatus("40901");
      }
    }

    this.submittedEst = false;
    this.customer_est_date = null;
  }
  async submitSWOT() {
    this.status = null;
    // console.log(this.form.value);
    this.submitted = true;
    if (this.form.invalid) return;

    if (!this.customer_addtion_fields) {
      let result = await this.CustomerAdditionFieldService.create({
        ...this.form.value,
        customer_id: this.customer_id,
      });
      this.status = result.success || result.error;
    } else {
      let result = await this.CustomerAdditionFieldService.update({
        ...this.form.value,
        customer_addition_field_id: this.customer_addtion_fields
          .customer_addition_field_id,
        customer_id: this.customer_id,
      });
      this.status = result.success || result.error;
    }

    this.submitted = false;
    this.form.disable();
    this.ngOnInit();
  }

  async submitAddition() {
    this.status = null;
    // console.log(this.additionForm.value);
    this.submitted = true;
    if (this.additionForm.invalid) return;

    if (!this.customer_addtion_fields) {
      let result = await this.CustomerAdditionFieldService.create({
        ...this.additionForm.value,
        customer_id: this.customer_id,
      });
      this.status = result.success || result.error;
    } else {
      let result = await this.CustomerAdditionFieldService.update({
        ...this.additionForm.value,
        customer_addition_field_id: this.customer_addtion_fields
          .customer_addition_field_id,
        customer_id: this.customer_id,
      });
      this.status = result.success || result.error;
    }

    this.submitted = false;
    this.additionForm.disable();
    this.ngOnInit();
  }

  submitProduct() {}

  closeEstDate(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submittedEst = false;
        this.customer_est_date = null;
        this.date = moment().format();
        this.openEstModal = false;
        this.estStatus = null;
        this.setEstForm();
      } else {
        this.openEstModal = true;
        this.setEstData();
      }
    });
  }

  close(e) {}
}
