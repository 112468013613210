import { Component, OnInit, Output, EventEmitter, ElementRef, ViewChild, NgZone, Input } from '@angular/core';
import { CalculateFunctionService, QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from 'src/app/services/quotations';

@Component({
  selector: 'app-debit-note-item-create',
  templateUrl: './debit-note-item-create.component.html',
  styleUrls: ['./debit-note-item-create.component.css']
})
export class DebitNoteItemCreateComponent implements OnInit {
  selectedCount: number = 0;
  isShowCheckAll: boolean = false;

  billing = [];

  @Input('debitNoteInfo') debitNoteInfo: any;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,
    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) { }

  async ngOnInit() {
    if(this.debitNoteInfo.customer_id){
      this.getBilling(null);
    }
  }

  async getBilling(filter: object){
    await this.QuotationMitItemService.load(null, {'$quotation_info.customer_id$':this.debitNoteInfo.customer_id, ready_to_billing:'true', ...filter},)
    .then(res=>{

      let resultData =  res.resultData.filter(item=>!item.acc_invoice_info_id && !item.acc_debit_note_info_id) || [];
      resultData.map(item=>{
        let file = item?.quotation_info.quotation_accepted_info.find(f=>f?.quotation_accepted_file_info?.length > 0);

        this.billing.push({
          ...item,
          product_title: item.scope_name + ": "+ item.equipment_name,
          product_description: item.description,
          quotation_item_id: item.quotation_mit_item_id,
          original_price: 0,
          quantity: 1,
          unit_id:"",
          product_id: "",
          acc_invoice_info_id: "",
          select: false,
          item_type: 'calibration_service',
          data: item,
          lock: file? false: true
        })
      })
    });
     await this.QuotationProductItemService.load(null,  {'$quotation_info.customer_id$': this.debitNoteInfo.customer_id, ready_to_billing:'true', ...filter})
    .then(res=>{
      let resultData = res.resultData.filter(item=> item.item_type == 'product' && !item.acc_invoice_info_id && !item.acc_debit_note_info_id);

      resultData.map(item=>{

        let file = item?.quotation_info.quotation_accepted_info.find(f=>f?.quotation_accepted_file_info?.length > 0);

        this.billing.push({
          ...item,
          product_title: item.product_title,
          acc_invoice_info_id: "",
          quotation_item_id: item.quotation_item_id,
          select: false,
          item_type: 'product',
          data: item,
          lock: file? false: true
        })
      })
    });
    this.isShowCheckAll = false;
    if(this.billing.length > 0){
      this.isShowCheckAll = true;
    }
    this.billing.sort(function(a,b){
      if(a.return_date > b.return_date) return 1;
      if(a.return_date < b.return_date) return -1;
      return 0;
    });
  }

  onCheckAll(event){
    this.billing.map(item=>{
      item.select = event.target.checked;
    });
    this.setCount();
  }


  setCount(){
    setTimeout(() => {
      this.selectedCount = 0;
      this.billing.map(item=>{
        if(item.select){
          this.selectedCount = this.selectedCount + 1;
        }
      });
    }, 100);
  }

  submit(){
    let items = [
      ...this.billing.filter(item=> item.select == true),
    ];
    
    this.done.emit(items);
    this.closeModalBtn.nativeElement.click();
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.selectedCount = 0;
        this.done.emit(false);
      }
    });
  }
}
