import {
  Component,
  OnInit,
  EventEmitter,
  ViewChild,
  ElementRef,
  Output,
  NgZone,
  Input,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { AbsNumberValidator } from "src/app/validators";
import { ActivatedRoute } from "@angular/router";
import { GETService, CustomerAddressService } from "src/app/services";
import { Observable, Subscription } from "rxjs";
import { Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";

@Component({
  selector: "app-customer-address-edit",
  templateUrl: "./customer-address-edit.component.html",
  styleUrls: ["./customer-address-edit.component.css"],
})
export class CustomerAddressEditComponent implements OnInit {
  form: FormGroup;
  status: string;
  submitted = false;
  customerAddressTypes = [];
  districts = [];
  amphures = [];

  province$: Subscription;
  amphure$: Subscription;
  district$: Subscription;
  is_acc_dep: boolean = false;

  @Input() provinces = [];
  @Input("customer-address") customer_address;
  @Output() done = new EventEmitter();
  @ViewChild("closeSidebar") closeSidebar: ElementRef;
  @Input("can-edit-customer") canEditCustomer;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(
    private zone: NgZone,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private GETService: GETService,
    private CustomerAddressService: CustomerAddressService
  ) {
    this.setForm();
  }
  setForm() {
    this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;

      if(userInfo.emp_info.emp_department_id == 'ACC_DEP'){
        this.is_acc_dep = true;
      }
      this.provinces = this.provinces.map(item=>{
        return {
          ...item,
          id: item.province_id,
          text: item.province_name_th,
        }
      })
  
      this.form = this.formBuilder.group({
        address: ["", [Validators.required]],
        province_id: [""],
        district_id: [""],
        amphure_id: [""],
        geography_id: [""],
        tel_1: ["", [Validators.required]],
        tel_2: [""],
        fax_1: [""],
        fax_2: [""],
        memo: [""],
  
        customer_address_type_id: ["", [Validators.required]],
        address_name: ["", [Validators.required]],
        branch: [""],
        department: ["", [Validators.required]],
        department_code: ["", !this.is_acc_dep? [Validators.required, AbsNumberValidator(), Validators.minLength(2), Validators.maxLength(2)]: null],
        tax_id: ["", [AbsNumberValidator(), Validators.minLength(13), Validators.maxLength(13)]],
      });
    });
  }
  async setData() {
    console.log(this.customer_address)
    if (!this.customer_address) return;
    if(this.customer_address.amphure_id == null ) this.customer_address.amphure_id = "";
    if(this.customer_address.district_id == null ) this.customer_address.district_id = "";
    if(this.customer_address.province_id == null ) this.customer_address.province_id = "";
    if(this.customer_address.zip_code == null ) this.customer_address.zip_code = "";
    if(this.customer_address.customer_address_type_id == 0) this.customer_address.customer_address_type_id = "";
    
    if(this.customer_address.province_id){
      let result = await this.GETService.amphures({
        province_id: this.customer_address.province_id,
      });
      this.amphures = result.resultData;
      if(this.customer_address.amphure_id){
        result = await this.GETService.districts({
          amphure_id: this.customer_address.amphure_id,
        });

        this.districts = result.resultData;
        if(this.customer_address.district_id){
          var district = this.districts.filter(
            (v) => String(v.district_id) === String(this.customer_address.district_id)
          )[0];
        }
      }
    }

    setTimeout(() => {

      this.form.controls.geography_id.setValue(district? this.customer_address.zip_code: "");
      this.form.patchValue(this.customer_address, {
        onlySelf: true,
        emitEvent: false,
      });

      this.GETService.provinces().then((res) => {
        let result = res.resultData || [];
        result = result.map(item=>{
          return {
            ...item,
            id: item.province_id,
            text: item.province_name_th,
          }
        })
        this.provinces = result;
      })
      if(this.form.value.province_id){
        this.GETService.amphures({
          province_id: this.form.value.province_id,
        }).then((res) => {
          let result = res.resultData || [];
          result = result.map(item=>{
            return {
              ...item,
              id: item.amphure_id,
              text: item.amphure_name_th,
            }
          })
          this.amphures = result;
        });
      }
      if (this.form.value.amphure_id) {
        this.GETService.districts({ amphure_id: this.form.value.amphure_id }).then(
          (res) => {
            let result = res.resultData || [];
            result = result.map(item=>{
              return {
                ...item,
                id: item.district_id,
                text: item.district_name_th,
              }
            })
            this.districts = result;
          }
        );
      }
    });

    this.form.disable();
  }

  async ngOnInit(){
    let initApis = [];
    initApis.push(
      this.GETService.customerAddressType({show_in_list : 1}).then((result) => {
        this.customerAddressTypes = result.resultData;
      })
    );
    await Promise.all(initApis);
  }

  ngOnChanges(changes) {
    // console.log(changes.customer_address);
    // if (changes.customer_address) {
    //   this.setData();
    // }
  }

  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let address_name = {
      district_name: "",
      amphure_name: "",
      province_name: "",
    }
    if (this.form.value.amphure_id){
      address_name.amphure_name = this.amphures.find(am=> am.id == this.form.value.amphure_id).text;
    }
    if (this.form.value.district_id){
      address_name.district_name = this.districts.find(ds=> ds.id == this.form.value.district_id).text;
    }
    if (this.form.value.province_id){
      address_name.province_name = this.provinces.find(pr=> pr.id == this.form.value.province_id).text;
    }
    let response = await this.CustomerAddressService.update({
      customer_id: this.route.snapshot.paramMap.get("customer_id"),
      ...this.form.value,
      customer_address_id: this.customer_address.customer_address_id,
      is_registed_address: "false",
      ...address_name
    });
    if(response.error && response.error.resultCode == "40900"){
      response.error.resultDesc.th = "MIT iPLUS Code นี้มีอยู่ในระบบ โปรดระบุ MIT iPLUS Code ใหม่";
      this.status = response.error;
    }else{
      this.done.emit(response.success || response.error);
      this.closeSidebar.nativeElement.click();
    }
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.province$?.unsubscribe();
        this.amphure$?.unsubscribe();
        this.district$?.unsubscribe();
        setTimeout(() => {
          this.setForm();
        }, 500);
      } else {
        this.form.disable();
        setTimeout(() => {
          this.setData();
        });
      }
    });
  }

  async delete(closeModalEl) {
    let response = await this.CustomerAddressService.delete({
      customer_address_id: this.customer_address.customer_address_id,
    });
    closeModalEl.click();
    this.closeSidebar.nativeElement.click();
    this.done.emit(response.error || response.success);
  }

  enableForm() {
    this.form.enable();
    this.province$ = this.form.controls.province_id.valueChanges.subscribe(
      (province_id) => {
        this.districts = [];
        this.amphures = [];
        this.form.patchValue(
          {
            amphure_id: "",
            district_id: "",
            geography_id: "",
          },
          {
            onlySelf: true,
            emitEvent: false,
          }
        );
        if(province_id){
          this.GETService.amphures({
            province_id: province_id,
          }).then((res) => {
            let result = res.resultData || [];
            result = result.map(item=>{
              return {
                ...item,
                id: item.amphure_id,
                text: item.amphure_name_th,
              }
            })
            this.amphures = result;
          });
        }
      }
    );

    this.amphure$ = this.form.controls.amphure_id.valueChanges.subscribe(
      (amphure_id) => {
        this.districts = [];
        this.form.patchValue(
          {
            district_id: "",
            geography_id: "",
          },
          {
            onlySelf: true,
            emitEvent: false,
          }
        );
        if (amphure_id) {
          this.GETService.districts({ amphure_id: amphure_id }).then(
            (res) => {
              let result = res.resultData || [];
              result = result.map(item=>{
                return {
                  ...item,
                  id: item.district_id,
                  text: item.district_name_th,
                }
              })
              this.districts = result;
            }
          );
        }
      }
    );

    this.district$ = this.form.controls.district_id.valueChanges.subscribe(
      (district_id) => {
        let district = this.districts.filter(
          (v) => v.district_id == district_id
        )[0];
        if (district) {
          this.form.controls.geography_id.setValue(district.zip_code);
        } else {
          this.form.controls.geography_id.setValue("");
        }
      }
    );
  }
}
