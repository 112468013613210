<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="transport-receive-unlock"
  tabindex="-1"
  role="dialog"
  aria-labelledby="UnlockModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="UnlockModal">
          Unlock Authorization
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" autocomplete="off" [formGroup]="form">
            <div class="form-body">
              <form-group
              [control]="form.controls.user_id"
              [submitted]="submitted"
              >
                <label for="user_id">
                  เลือกชื่อผู้มีอำนาจ <span class="danger">*</span>
                </label>
                <ng-select2
                  [data]="managerList"
                  class="w-100"
                  id="user_id"
                  placeholder="เลือกชื่อผู้มีอำนาจ"
                  formControlName="user_id"
                >
                </ng-select2>
              </form-group>              
            </div>
            <div class="form-body">
                <form-group
                [control]="form.controls.secure_code"
                [submitted]="submitted">
                <label for="secure_code">
                    โปรดให้ผู้มีอำนาจกรอกรหัสเพื่อปลดล็อก <span class="danger">*</span>
                </label>
                <input
                    type="text"
                    id="secure_code"
                    class="form-control"
                    placeholder="โปรดให้ผู้มีอำนาจกรอกรหัสเพื่อปลดล็อก"
                    formControlName="secure_code"
                />
                </form-group>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ตกลง
        </button>
      </div>
    </div>
  </div>
</div>
