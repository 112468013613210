
<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการรอส่ง</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/waiting-for-delivery']">การส่งเครื่องมือ</a>
                </li>
                <li class="breadcrumb-item active">รายการรอส่ง</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-12 col-12 mb-md-0 mb-2">
          <app-response-status *ngIf="status" [status]="status"></app-response-status>
            <section class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-header">
                      <div class="row">
                        <h4 class="card-title mt-1 col-12 col-sm-4 col-md-6 col-lg-9">รายการรอส่ง</h4>
                        <span class="col-12 col-sm-8 col-md-6 col-lg-3">
                          <search-box (search)="search($event)" [placeholder]="'ค้นหาเลขที่เอกสาร/รหัส/ชื่อลูกค้า'"></search-box>
                        </span>
                      </div>
                    </div>
        
                    <div class="card-content collapse show">
                      <div class="card-body">
                            <div class="row justify-content-between" *ngIf="!isLoading">
                                <div class="col-12 col-sm-12 col-md-12 col-lg-9 align-self-end">
                                    <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                                        <li class="nav-item border-primary mr-50">
                                            <a class="nav-link py-0" [class.active] = "tab === 'lab'" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchFilter('lab')">LAB</a>
                                        </li>
                                        <li class="nav-item border-primary mr-50">
                                            <a class="nav-link py-0" [class.active] = "tab === 'onsite'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchFilter('onsite')">On-Site</a>
                                        </li>
                                        <li class="nav-item border-primary mr-50">
                                            <a class="nav-link py-0" [class.active] = "tab === 'product'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchFilter('product')">Product</a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="col-12 col-sm-12 col-md-12 col-lg-3">
                                    <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
                                      <div class="input-group mt-0 mb-1">
                                        <input
                                        date-picker
                                        type="text"
                                        id="return_date"
                                        [date]="form.value.return_date"
                                        class="form-control"
                                        formControlName="return_date"
                                        [max-date]="undefined"
                                        placeholder="วันที่กำหนดส่ง"
                                        />
                                        <div
                                        class="input-group-append"
                                        for="return_date"
                                        >
                                        <span class="input-group-text rounded-0 btn-primary text-white"
                                            ><i class="feather icon-calendar"></i
                                        ></span>
                                        </div>
                                        <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                            ><i class="fa fa-times"></i>
                                        </span>
                                      </div>
                                    </form>
                                </div>
                            </div>
                            <loading *ngIf="isLoading"></loading>
                            <div class="table-responsive" [hidden]="isLoading">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>
                                                กำหนดส่ง
                                            </th>
                                            <th>
                                                ใบรับเครื่องมือ
                                            </th>
                                            <th class="text-center">
                                                ประเภท
                                            </th>
                                            <th>
                                                เลขที่ใบสั่งงาน
                                            </th>
                                            <th>
                                                Customer
                                            </th>
                                            <th>
                                                เลขที่ใบเสนอราคา
                                            </th>
                                            <th class="text-center">
                                                Credit term
                                            </th>
                                            <th>
                                                Description
                                            </th>
                                            <th>
                                                Note
                                            </th>
                                            <th class="text-center">
                                                เครื่อง
                                            </th>
                                            <th class="text-center">
                                                <span class="pr-1">Cert</span>

                                                <div class="dropdownmenu" [hidden]="tab=='product'">
          
                                                    <button (click)="btnToggleDate()" class="btn btn-primary btn-sm">
                                                        Cert <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                                    </button>
                                                      
                                                    <div id="date_filter" class="dropdownmenu-content rounded-right rounded-bottom" >
                                                      <form class="px-2 pt-1" [formGroup]="form">
                                                        <div class="row">
                                                          <div class="col-8 text-left">
                                                            <label for="tran_item_status_id">Cert</label>
                                                          </div>
                                                          <div class="col-4 text-right">
                                                            <i class="fa fa-times pointer" (click)="btnToggleDate()"></i>
                                                          </div>
                                                        </div>
                                                        <div class="form-group">
                                                          <div class="input-group ">
                                                            <input
                                                                date-range-picker
                                                                type="text"
                                                                [singleDatePicker]="true"
                                                                [timePicker]="false"
                                                                [date]="form.value?.real_cert_return_date"
                                                                [format]="'DD-MM-YYYY'"
                                                                placeholder="Cert"
                                                                id="real_cert_return_date"
                                                                #real_cert_return_date
                                                                class="form-control"
                                                                formControlName="real_cert_return_date"
                                                            />
                                                            <div class="input-group-append pointer" (click)="real_cert_return_date.click()">
                                                              <span class="input-group-text rounded-0 btn-primary text-white"
                                                              ><i class="feather icon-calendar"></i
                                                              ></span>
                                                            </div>
                                                            <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearCertDate()"
                                                            ><i class="fa fa-times"></i>
                                                            </span>
                                                          </div>
                                                        </div>
                                                      </form>
                                                      
                                                    </div>
                                                </div>
                                            </th>
                                            <th class="text-center">
                                                Messenger
                                            </th>
                                            <th class="text-center">
                                                <span class="pr-1">สถานะ</span>
                                                <div class="dropdownmenu">
                
                                                  <button (click)="btnToggleStatus()" class="btn btn-primary btn-sm">
                                                      สถานะ <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                                  </button>
                                                    
                                                  <div id="status" class="dropdownmenu-content rounded-right rounded-bottom">
                                                    <form class="px-2 pt-1" [formGroup]="form">
                                                      <div class="row">
                                                        <div class="col-4 text-left">
                                                          <label for="tran_item_status_id">สถานะ</label>
                                                        </div>
                                                        <div class="col-8 text-right">
                                                          <i class="fa fa-times pointer text-secondary" (click)="btnToggleStatus()"></i>
                                                        </div>
                                                      </div>
                                                      <div class="form-group">
                                                        <ng-select2
                                                        [data]="tab=='lab'? itemLabJobStatus: itemJobStatus"
                                                        id="status"
                                                        [options]="{ multiple: true }"
                                                        class="w-100"
                                                        formControlName="status"
                                                        (valueChanged)="filterStatus($event)"
                                                      >
                                                      </ng-select2>
                                                      </div>
                                                    </form>
                                                    
                                                  </div>
                                                </div>
                                            </th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <thead *ngFor="let delivery of deliveryList; let i = index">
                                        <tr *ngIf="tab=='lab'">
                                            <td class="text-nowrap">
                                                {{delivery.return_date | thai_date: "short":"date" | dash}}
                                            </td>
                                            <td class="text-nowrap">
                                                <a [href]="'/transport-receive/'+delivery?.transport_receive_info_id+'/show'" target="_blank">
                                                    {{delivery.transport_receive_info_doc_no}}
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                {{delivery.job_order_info_type}}
                                            </td>
                                            <td class="text-nowrap">
                                                <a [href]="'/work-order/calibration/'+delivery?.job_order_info_id+'/show?template='+delivery.job_order_info_type" target="_blank">
                                                    {{delivery?.job_order_info_doc_no}}
                                                </a>
                                            </td>
                                            <td class="text-nowrap">
                                                [{{delivery?.quotation_info?.customer_info?.customer_code}}] {{delivery.quotation_info?.customer_info?.company_name}}
                                                <br>
                                                <small>
                                                    ส่งเครื่อง: 
                                                    {{
                                                        delivery?.transport_receive_info?.address
                                                    }}
                                                </small>
                                                <br>
                                                <small>
                                                    ส่ง Cert: 
                                                    {{
                                                        delivery?.job_order_info?.address
                                                    }}
                                                </small>
                                            </td>
                                            <td>
                                                <a [href]="'/quotation/'+delivery?.quotation_info_id+'/show'" target="_blank">
                                                    {{delivery?.quotation_info_doc_no}}
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                {{delivery?.customer_info?.credit_term_day}}
                                            </td>
                                            <td>
                                                {{delivery?.description || delivery?.equipment_name}}
                                            </td>
                                            <td>
                                                <span *ngFor="let detail of delivery.quotation_mit_item_detail_info; let last = last">
                                                    <span>{{detail.note | dash}}</span>
                                                    <span *ngIf="!last">,</span>
                                                </span>
                                            </td>
                                            <td class="text-center text-nowrap ">
                                                <div *ngIf="!isMessenger">
                                                    <div *ngIf="!delivery.items_date">
                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    <div *ngIf="delivery.items_date">
                                                        {{delivery.items_date | thai_date: "short":"date" | dash}}
                                                    </div>
                                                    <!-- <i class="fa fa-lock fa-lg primary" *ngIf="!canServiceCharge(delivery)"></i> -->
                                                    <div class="primary pointer text-nowrap"
                                                        (click)="select(delivery, 'items', i)">
                                                        [กำหนดส่งจริง]
                                                    </div>
                                                </div>
                                                <div *ngIf="isMessenger">
                                                    {{delivery.items_date | thai_date: "short":"date" | dash}}
                                                </div>
                                            </td>
                                            <td class="text-center text-nowrap ">
                                                <div *ngIf="!isMessenger">
                                                    <div *ngIf="!delivery.cert_date">
                                                        <i class="fa fa-check" aria-hidden="true"></i>
                                                    </div>
                                                    <div *ngIf="delivery.cert_date">
                                                        {{delivery.cert_date | thai_date: "short":"date" | dash}}
                                                    </div>
                                                    <i class="fa fa-lock fa-lg primary" *ngIf="!canServiceCharge(delivery)"></i>
                                                    <div class="primary pointer text-nowrap"
                                                        *ngIf="canServiceCharge(delivery)"
                                                        (click)="select(delivery, 'cert', i)">
                                                        [กำหนดส่งจริง]
                                                    </div>
                                                </div>
                                                <div *ngIf="isMessenger">
                                                    {{delivery.cert_date | thai_date: "short":"date" | dash}}
                                                </div>
                                            </td>
                                            <td class="text-center text-nowrap">
                                                <div *ngIf="!isMessenger">

                                                    <div *ngIf="delivery.transport_receive_info?.receiver_info">
                                                        ผู้รับ: {{delivery.transport_receive_info?.receiver_info.firstname_th}} {{delivery.transport_receive_info?.receiver_info.lastname_th}} [{{delivery.transport_receive_info?.transport_type_info?.transport_type_name_th}}]
                                                    </div>
                                                    <div *ngIf="!delivery.return_messenger_id">
                                                        ผู้ส่ง: -
                                                    </div>
                                                    <div *ngIf="delivery.return_messenger_id">
                                                        ผู้ส่ง: {{getPassenger(delivery.return_messenger_id)}} [{{getTransport(delivery.return_transport_type_id)}}]
                                                    </div>
                                                    <i class="fa fa-lock fa-lg primary" *ngIf="!canServiceCharge(delivery)"></i>
                                                    <div class="primary pointer text-nowrap"
                                                    *ngIf="canServiceCharge(delivery)"
                                                    (click)="selectJob(delivery, i)">
    
                                                    [กำหนดผู้ส่ง]
                                                    </div>
                                                </div>

                                                <div *ngIf="isMessenger">
                                                    {{getPassenger(delivery.return_messenger_id)}} [{{getTransport(delivery.return_transport_type_id)}}]
                                                </div>
                                            </td>
                                            <td class="text-nowrap text-center">
                                                <div *ngIf="!delivery?.job_order_info_status">
                                                    -
                                                </div>
                                                <div *ngIf="delivery?.job_order_info_status">
                                                    <span [ngSwitch]="delivery?.job_order_info_status">
                                                        <span *ngSwitchCase="'CONFIRMED'">
                                                            อยู่ระหว่างให้บริการ
                                                        </span>
                                                        <span *ngSwitchCase="'WAITING_VERIFY_CRF'">
                                                            รออนุมัติใบรายงานผล
                                                        </span>
                                                        <span *ngSwitchCase="'PREPARE_FOR_SHIP'">
                                                            ดำเนินการจัดส่ง
                                                        </span>
                                                        <span *ngSwitchCase="'COMPLETED'">
                                                            เสร็จสิ้น
                                                        </span>
                                                        <span *ngSwitchCase="'CRF_COMPLETED'">
                                                            ใบรายงานผลเรียบร้อย
                                                        </span>
                                                        <span *ngSwitchDefault>
                                                            -
                                                        </span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td class="text-nowrap">
                                                <div *ngIf="delivery?.job_order_calibration_item_status">
                                                    <span [ngSwitch]="delivery?.job_order_info_status">
               
                                                        <span *ngSwitchCase="'PREPARE_FOR_SHIP'">

                                                            <button
                                                                *ngIf="delivery?.transport_receive_info_id"
                                                                type="button"
                                                                class="btn mr-1 mb-1 btn-primary btn-sm"
                                                                [routerLink]="['/transport-receive/' + delivery?.transport_receive_info_id + '/show']"
                                                            >
                                                                <i class="fa fa-eye icon-left"></i> ใบรับ-ส่งเครื่องมือ
                                                            </button>
                                                        </span>
                                                    </span>
                                                </div>
                                               
                                            </td>
                                        </tr>
                                        <tr *ngIf="tab=='onsite' || tab=='product'">
                                            <td class="text-nowrap">
                                                {{delivery.return_date | thai_date: "short":"date" | dash}}
                                            </td>
                                            <td class="text-nowrap">
                                               -
                                            </td>
                                            <td class="text-center">
                                                {{delivery?.job_order_type}}
                                            </td>
                                            <td class="text-nowrap">

                                                <a [href]="'/work-order/calibration/'+delivery?.job_order_info_id+'/show?template='+delivery.job_order_type" target="_blank">
                                                    {{delivery?.doc_no}}
                                                </a>
                                            </td>
                                            <td class="text-nowrap">
                                                [{{delivery?.customer_info?.customer_code}}] {{delivery?.customer_info?.company_name}}

                                                <br>
                                                <small *ngIf="delivery?.job_order_info?.address">
                                                    ส่ง Cert: 
                                                    {{
                                                        delivery?.job_order_info?.address
                                                    }}
                                                </small>
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td class="text-center">
                                                {{delivery?.customer_info?.credit_term_day}}
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td>
                                                -
                                            </td>
                                            <td class="text-center text-nowrap ">
                                                <div *ngIf="tab == 'onsite'">
                                                    -
                                                </div>
                                                <div *ngIf="tab == 'product'">
                                                    <div *ngIf="!isMessenger">
                                                        <div *ngIf="!delivery?.return_history_date">
                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                        </div>
                                                        <div *ngIf="delivery?.return_history_date">
                                                            {{delivery.return_history_date | thai_date: "short":"date" | dash}}
                                                        </div>
                                                        <!-- <i class="fa fa-lock fa-lg primary" *ngIf="!canServiceCharge(delivery)"></i> -->
                                                        <div class="primary pointer text-nowrap"
                                                            (click)="select(delivery, 'product', i)">
                                                            [กำหนดส่งจริง]
                                                        </div>
                                                    </div>

                                                    <div *ngIf="isMessenger">
                                                        {{delivery.return_history_date | thai_date: "short":"date" | dash}}
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="text-center text-nowrap ">
                                                <div *ngIf="tab == 'onsite'">
                                                    <div *ngIf="!isMessenger">
                                                        <div *ngIf="!delivery?.cert_return_history_date">
                                                            <i class="fa fa-check" aria-hidden="true"></i>
                                                        </div>
                                                        <div *ngIf="delivery?.cert_return_history_date">
                                                            {{delivery.cert_return_history_date | thai_date: "short":"date" | dash}}
                                                        </div>
                                                        <i class="fa fa-lock fa-lg primary" *ngIf="!canServiceCharge(delivery)"></i>
                                                        <div class="primary pointer text-nowrap"
                                                            *ngIf="canServiceCharge(delivery)"

                                                            (click)="select(delivery, 'onsite', i)">
                                                            [กำหนดส่งจริง]
                                                        </div>
                                                    </div>

                                                    <div *ngIf="isMessenger">
                                                     {{delivery.cert_return_history_date | thai_date: "short":"date" | dash}}
                                                    </div>
                                                </div>
                                                <div *ngIf="tab == 'product'">
                                                    -
                                                </div>
                                            </td>
                                            <td class="text-center text-nowrap">
                                                <div *ngIf="!isMessenger">
                                                    <div *ngIf="!delivery.return_messenger_id">
                                                        ผู้ส่ง: -
                                                    </div>
                                                    <div *ngIf="delivery.return_messenger_id">
                                                        ผู้ส่ง: {{getPassenger(delivery.return_messenger_id)}} [{{getTransport(delivery.return_transport_type_id)}}]
                                                    </div>
                                                    <i class="fa fa-lock fa-lg primary" *ngIf="!canServiceCharge(delivery)"></i>
                                                  
                                                    <div class="primary pointer text-nowrap" 
                                                    *ngIf="canServiceCharge(delivery)"
                                                    (click)="selectJob(delivery, i)">
                                                    [กำหนดผู้ส่ง]
                                                    </div>
    
                                                </div>
                                                
                                                <div *ngIf="isMessenger">
                                                    {{getPassenger(delivery.return_messenger_id)}} [{{getTransport(delivery.return_transport_type_id)}}]
                                                </div>
                                            </td>
                                            <td class="text-nowrap text-center">
                                                {{delivery?.doc_status_info?.document_status_name_th}}
                                            </td>
                                            <td class="text-nowrap">
                                                <div *ngIf="delivery?.doc_status_id == 'PREPARE_FOR_SHIP'">
                                                    <button
                                                        type="button"
                                                        class="btn mr-1 mb-1 btn-primary btn-sm"
                                                        (click)="view(delivery)"
                                                    >
                                                        <i class="fa fa-eye icon-left"></i> บันทึกส่งงาน
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr *ngIf="deliveryList.length ==0">
                                            <td colspan="15" class="text-center">ไม่มีข้อมูล</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
            
                            <app-paginator
                                [filter]="filter"
                                [reset]="reset"
                                [fetch]="load"
                                (changePage)="onChangePage($event)">
                            </app-paginator>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        </div>
      </div>
    </div>
</div>

<app-delivery-cert-history *ngIf="delivery && type=='cert'" [quotation_item]="delivery" (done)="submitCert($event)">
</app-delivery-cert-history>

<app-delivery-items-history *ngIf="delivery && type=='items'" [quotation_item]="delivery" (done)="submitItems($event)">
</app-delivery-items-history>

<app-delivery-job-order-history *ngIf="delivery && (type=='product' || type=='onsite')" [tab]="type" [jobOrderInfo]="delivery" (done)="submitJobOrder($event)">
</app-delivery-job-order-history>

<app-delivery-passenger-create *ngIf="delivery && tab" [transportTypes]="transportTypes" [transportContactList]="transportContactList" [tab]="tab" [delivery]="delivery" (done)="submitPassenger($event)">
</app-delivery-passenger-create>