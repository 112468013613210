<div *ngFor="let file of files">
  <div class="badge badge-secondary nowrap mb-1">
    <a [href]="environment.uploadPath + '/' + file.file_path" target="_blank">
      <!-- <i>{{ file.mimetype }}</i> -->
      {{ file.file_name }}
    </a>
    <span
      class="font-bold pointer"
      *ngIf="canRemove"
      data-toggle="modal"
      [attr.data-target]="confirmModalId"
      (click)="removeFile(file)"
      >&times;</span
    >
  </div>
</div>
