import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CustomerAddressService, CustomerContactService } from 'src/app/services';
import { CalculateFunctionService, QuotationContactService, QuotationUtilsService } from 'src/app/services/quotations';
import domtoimage from 'dom-to-image-more'
import jsPDF from 'jspdf'; 
import THBText from 'thai-baht-text'
import { AccTaxInvoiceContactService, AccTaxInvoiceInfoService, AccTaxInvoiceItemService } from 'src/app/services/tax-invoice';

@Component({
  selector: 'app-tax-invoice',
  templateUrl: './tax-invoice.component.html',
  styleUrls: ['./tax-invoice.component.css']
})
export class TaxInvoiceComponent implements OnInit {
  taxInvoiceItems = [];
  pages = [];
  //string
  acc_tax_invoice_info_id: string = "";
  //any
  customer_info = null;
  taxInvoiceInfo = null;
  count = 0;
  customerContacts = [];
  quotationContacts = [];

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,

    private CustomerContactService: CustomerContactService,
    private CustomerAddressService: CustomerAddressService,
    private QuotationContactService: QuotationContactService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccTaxInvoiceItemService: AccTaxInvoiceItemService,
    private AccTaxInvoiceContactService: AccTaxInvoiceContactService,

    public utils: QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) { 
    this.acc_tax_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_tax_invoice_info_id");
  }
  

  async ngOnInit() {
    await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: this.acc_tax_invoice_info_id})
    .then(async res=>{
      this.taxInvoiceInfo = res.resultData || {};
      this.titleService.setTitle(this.taxInvoiceInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
      if(this.taxInvoiceInfo.customer_address_id){
        await this.CustomerAddressService.getById({customer_address_id: this.taxInvoiceInfo.customer_address_id})
        .then(res=>{
          this.taxInvoiceInfo['customer_address_name'] = res.resultData.address_name;
          this.taxInvoiceInfo['customer_address'] = this.utils.genAddress(res.resultData);
          this.taxInvoiceInfo['customer_address_info'] = res.resultData;
        })
      }

      await this.AccTaxInvoiceItemService.load(null, {acc_tax_invoice_info_id: this.acc_tax_invoice_info_id})
      .then(async res=>{
        this.taxInvoiceItems = res.resultData || [];

        this.taxInvoiceItems = this.taxInvoiceItems.map(value=>{
          return {
            ...value,
            data: {
              ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
              quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
            }
          }
        })
      })

      await this.CustomerContactService.load(null, {
        order_by: "isMaster:desc",
        customer_id: this.taxInvoiceInfo.customer_id
      }).then((res) => {
        this.customerContacts = res.resultData || [];
      });

      await this.AccTaxInvoiceContactService.load(null, { acc_tax_invoice_info_id: this.acc_tax_invoice_info_id }).then((res) => {
        this.quotationContacts = res.resultData || [];
        this.quotationContacts.map(item=>{
          item['customer_contact_info'] = this.customerContacts.find(cus=> cus.customer_contact_id == item.customer_contact_id);
        })
      });

      for (var i=0; i<this.taxInvoiceItems.length; i++) {
        this.taxInvoiceItems[i].index = i + 1;
      }
      this.sumCount();
      for (var i=0; i<this.taxInvoiceItems.length; i+=10) {
        this.pages.push(this.taxInvoiceItems.slice(i, i+10));
      }  
    })
  }

  sumCount(){
    this.count = 0;
    this.taxInvoiceItems.map(v=>{
      if(v.quotation_item_id == '' && v.product_id == ''){
        this.count = this.count - v.discount;
      }else{
        this.count = this.count + this.calculate.sumProduct(v);
      }
    });
    let grand_total = this.calculate.sumProductTotal(this.count, this.taxInvoiceInfo);
    this.taxInvoiceInfo.grand_total = grand_total;
    if (grand_total > 0) {
      this.taxInvoiceInfo['THBText'] = THBText(Math.abs(grand_total));
    }else if(grand_total < 0){
      this.taxInvoiceInfo['THBText'] = 'ลบ' + THBText(Math.abs(grand_total));
    }else{
      this.taxInvoiceInfo['THBText'] = 'ศูนย์บาทถ้วน';
    }
  }

  async print(taxInvoiceInfo){
    const invoice_sections = document.querySelectorAll('.pdf');
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let page = 1;
    let perPage = Array.prototype.slice.call(invoice_sections,0).length;
    
    let invoiceList = [];
    await Promise.all(Array.prototype.slice.call(invoice_sections,0).map(async (value, index) =>{
      let invoice_section = document.getElementById('invoice_' + (index));
      await domtoimage.toJpeg(invoice_section, {
        background: 'white', 
        allowTaint: true,
        width: invoice_section.clientWidth * 2,
        height: invoice_section.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        invoiceList.push({index: index, canvas: canvas, invoice_section: invoice_section});
      })
    }));
    invoiceList.sort(function(a, b){return a.index - b.index});
    await Promise.all(invoiceList.map(async (value, index) =>{

      var imgWidth = 208;
      var imgHeight = value.invoice_section.clientHeight * imgWidth / value.invoice_section.clientWidth;
      if((index) != 0) doc.addPage('a4');
      doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);
      doc.setFontSize(8);
      doc.text(page + ' /  ' + perPage, 200, 285);
      page = page+1;
    }));

    setTimeout(() => {
      doc.save(taxInvoiceInfo.doc_no);
    }, 1000);
  }

  getQuotationContact(){
    let item = this.quotationContacts;
    var out = [];

    item.map((item, index)=>{
      out.push(item.customer_contact_info?.firstname + ' ' + item.customer_contact_info?.lastname);
    })

    return out.join(", ");
  }

  getPoNo(){
    let item = this.taxInvoiceItems.filter((invoice, index, self) =>
    index === self.findIndex((t) => (
      t.quotation_info_id === invoice.quotation_info_id 
    )))
    var out = [];

    item.map((item, index)=>{
      item.quotation_info[0]?.quotation_accepted_info.map(a=>{
        out.push(a.accepted_po_no);
      })
    })

    return out.join(", ");
  }

  getQuotationNo(){

    let item = this.taxInvoiceItems.filter((invoice, index, self) =>
    index === self.findIndex((t) => (
      t.quotation_info_id === invoice.quotation_info_id 
    )))
    var out = [];

    item.map((item, index)=>{
      if(item.quotation_info){
        out.push(item.quotation_info[0].doc_no);
      }
    })

    return out.join(", ");
  }
}
