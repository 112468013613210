import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { UtilsService } from 'src/app/services';
import { JobOrderInfoService } from 'src/app/services/job-orders';
import { UserInfoState } from 'src/app/store/user/user.state';
declare var $: any;

@Component({
  selector: 'app-work-order-list',
  templateUrl: './work-order-list.component.html',
  styleUrls: ['./work-order-list.component.css']
})
export class WorkOrderListComponent implements OnInit {
  form : FormGroup;
  isLoading:boolean = true;

  jobOrders = [];
  templates = [];
  jobOrder = null;

  number_all_status:number = 0;
  number_draft_status:number = 0;
  number_return_status:number = 0;
  number_rejected_status:number = 0;
  number_canceled_status:number = 0;
  number_completed_status:number = 0;
  number_confirmed_status:number = 0;
  number_work_done_status:number = 0;
  number_work_incomplate_status:number = 0;
  number_inprogress_status:number = 0;
  number_crf_completed_status:number = 0;

  number_waiting_verify_status:number = 0;
  number_prepare_for_ship_status:number = 0;
  number_rejected_verify_crf_status:number = 0;
  number_waiting_for_confirm_status:number = 0;

  status: string;
  template: string = "";
  reset: boolean = false;
  load: Function = this.JobOrderInfoService.load;
  filter = {};
  params: any;
  initialPage: number = 1;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;

  @ViewChild("return_date") return_date: ElementRef;
  @ViewChild("pickup_date") pickup_date: ElementRef;
  @ViewChild("real_return_date") real_return_date: ElementRef;
  @ViewChild("real_cert_return_date") real_cert_return_date: ElementRef;

  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private route: ActivatedRoute,
    private JobOrderInfoService: JobOrderInfoService,
    public _UtilsService: UtilsService
  ) { 
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
    this.route.queryParams
    .subscribe(params => {
      if(params){

        let page = parseInt(params.page);

        this.params = params;
        this.filter = {
          ...params,
          offset: page == 1 || isNaN(page)? 0: ((page-1)* 10),
          page: this._UtilsService.setUndefined()
        };

        this.initialPage = isNaN(page)? 1: page;
      }
    }
  );
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit(): void {
    this.template = JSON.parse(localStorage.getItem('mit_cache'))?.job_order_type || "";
    
    this.form = this.fbd.group({
      return_date: [""],
      pickup_date: [""],
      real_return_date: [""],
      real_cert_return_date: [""],
    });
    if(this.template){
      this.filterByJobOrderType(this.template);
    }else{
      this.getCountOfStatus(null);
    }

    this.form.controls.return_date.valueChanges.subscribe(
      (value) => {

        this.filter = {
          ...this.filter,
          return_date: moment($(this.return_date.nativeElement).data('daterangepicker').startDate).format("YYYY-MM-DD"),
        };
      }
    );

    this.form.controls.pickup_date.valueChanges.subscribe(
      (value) => {

        this.filter = {
          ...this.filter,
          pickup_date: moment($(this.pickup_date.nativeElement).data('daterangepicker').startDate).format("YYYY-MM-DD"),
        };
      }
    );

    this.form.controls.real_return_date.valueChanges.subscribe(
      (value) => {

        this.filter = {
          ...this.filter,
          real_return_date: moment($(this.real_return_date.nativeElement).data('daterangepicker').startDate).format("YYYY-MM-DD"),
        };
      }
    );

    this.form.controls.real_cert_return_date.valueChanges.subscribe(
      (value) => {

        this.filter = {
          ...this.filter,
          real_cert_return_date: moment($(this.real_cert_return_date.nativeElement).data('daterangepicker').startDate).format("YYYY-MM-DD"),
        };
      }
    );
  }

  getCountOfStatus(filter: object){
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;

      let searchFilter = {};
      if(
        userInfo.is_sales_manager || 
        userInfo.is_co_sales  || 
        userInfo.is_sales  || 
        userInfo.is_tran_dep  || 
        userInfo.is_acc_dep || 
        (userInfo.is_calib_dep && userInfo?.emp_info?.emp_position_id == 'CALIB_DEP-08')
        ){
        this.templates = [
          { id: "", text: "TEMPLATE: ทั้งหมด"},
          { id: "ICL", text: "Code: [ICL] : Internal calibration (Lab)"},
          { id: "ECL", text: "Code: [ECL] : External calibration (Lab)" },
          { id: "ICS", text: "Code: [ICS] : Internal calibration (On-site)" },
          { id: "ECS", text: "Code: [ECS] : External calibration (On-site)" },
          { id: "TRN", text: "Code: [TRN] : Training" },
          { id: "PRD", text: "Code: [PRD] : Product" },
        ];
        if(userInfo.is_tran_dep){
          searchFilter['exclude_doc_status_id'] = 'DRAFT,WAITING_FOR_CONFIRM,REJECTED,CANCELED';
          this.filter['exclude_doc_status_id'] = 'DRAFT,WAITING_FOR_CONFIRM,REJECTED,CANCELED';
        }
      }else{
        if(userInfo.is_calib_dep){

          this.filter['exclude_job_order_type'] = 'PRD,ECL,ECS';
          searchFilter['exclude_job_order_type'] = 'PRD,ECL,ECS';
          this.templates = [
            { id: "", text: "TEMPLATE: ทั้งหมด"},
            { id: "ICL", text: "Code: [ICL] : Internal calibration (Lab)"},
            { id: "ICS", text: "Code: [ICS] : Internal calibration (On-site)" },
            { id: "TRN", text: "Code: [TRN] : Training" },
          ];
        }else{
          this.filter['exclude_job_order_type'] = 'ICL,ICS,TRN';
          searchFilter['exclude_job_order_type'] = 'ICL,ICS,TRN';
          this.templates = [
            { id: "", text: "TEMPLATE: ทั้งหมด"},
            { id: "ECL", text: "Code: [ECL] : External calibration (Lab)" },
            { id: "ECS", text: "Code: [ECS] : External calibration (On-site)" },
            { id: "PRD", text: "Code: [PRD] : Product" },
          ];
        }
        this.filter['exclude_doc_status_id'] = 'DRAFT';
        searchFilter['exclude_doc_status_id'] = 'DRAFT';
      }
      this.number_all_status = 0;

      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'DRAFT'}).then(res=>{
        this.number_draft_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'WAITING_FOR_CONFIRM'}).then(res=>{
        this.number_waiting_for_confirm_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'REJECTED'}).then(res=>{
        this.number_rejected_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'CONFIRMED'}).then(res=>{
        this.number_confirmed_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'INPROGRESS'}).then(res=>{
        this.number_inprogress_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'COMPLETED'}).then(res=>{
        this.number_completed_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'CANCELED'}).then(res=>{
        this.number_canceled_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'REJECTED_VERIFY_CRF'}).then(res=>{
        this.number_rejected_verify_crf_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'WAITING_VERIFY_CRF'}).then(res=>{
        this.number_waiting_verify_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'PREPARE_FOR_SHIP'}).then(res=>{
        this.number_prepare_for_ship_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'RETURNED_SUCCESS'}).then(res=>{
        this.number_return_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'WORK_DONE'}).then(res=>{
        this.number_work_done_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'WORK_INCOMPLETE'}).then(res=>{
        this.number_work_incomplate_status = res.rowCount || 0;
      });
      this.JobOrderInfoService.load({limit:0}, {...searchFilter, ...filter, doc_status_id: 'CRF_COMPLETED'}).then(res=>{
        this.number_crf_completed_status = res.rowCount || 0;
      });
    });
  }

  onChangePage(jobOrders: Array<any>) {
    this.jobOrders = jobOrders;
    this.isLoading = false;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = { ...this.filter, ...filter };

    this.setQueryUrl();
  }

  setQueryUrl(){
    let filter = {
      ...this.filter,
      page: this.initialPage,
      offset: this._UtilsService.setUndefined(),
    }
    this.router.navigate([], {
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
    this.params = filter;
  }

  getFilterLimit(filterLimit){
    if(this.initialPage != filterLimit.initialPage && filterLimit.initialPage != 0){
  
      let params = {
        ...this.filter,
        page: filterLimit.initialPage,
        offset: this._UtilsService.setUndefined()
      }

      this.router.navigate([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.params = params;
    }
  }
  searchStatus(status:string = null){
    let condition = {
      doc_status_id: status,
    };
    this.search(condition);
  }
  filterByJobOrderType(job_order_type){
    let mit_store = {
      ...JSON.parse(localStorage.getItem('mit_cache')),
      job_order_type: job_order_type
    }
    localStorage.setItem('mit_cache', JSON.stringify(mit_store));

    let condition = {
      "job_order_type": job_order_type
    };
    this.search(condition);
    this.getCountOfStatus(condition);
  }
  clearDate(){
    this.form.get('return_date').setValue('');
    this.form.get('pickup_date').setValue('');
    this.form.get('real_return_date').setValue('');
    this.form.get('real_cert_return_date').setValue('');
    let condition = {
      "return_date": undefined,
      "pickup_date": undefined,
      "real_return_date": undefined,
      "real_cert_return_date": undefined
    };
    this.search(condition);
  }
  select(jobOrder: string) {
    this.jobOrder = Object.assign({}, jobOrder);
  }

  edit(jobOrder, status = ''){
    if(jobOrder.job_order_type == "TRN" || jobOrder.job_order_type == "PRD"){
      this.router.navigateByUrl("/work-order/non-calibration/"+ jobOrder.job_order_info_id +"/edit?template=" +jobOrder.job_order_type +"&&status=" + status);
    }else{
      this.router.navigateByUrl("/work-order/calibration/"+ jobOrder.job_order_info_id  +"/edit?template=" +jobOrder.job_order_type +"&&status=" + status);
    }
  }

  sortReturnDate(){
    let condition = {
      'order_by': !this.filter['order_by'] || this.filter['order_by']=='return_date:desc'?'return_date:asc':'return_date:desc',
    };
    this.search(condition);
  }

  btnToggleReturnDate(){
    if(document.getElementById("pickup_date_filter").classList.toggle("show")){
      document.getElementById("pickup_date_filter").classList.toggle("show")
    }
    if(document.getElementById("real_return_date_filter").classList.toggle("show")){
      document.getElementById("real_return_date_filter").classList.toggle("show")
    }
    if(document.getElementById("real_cert_return_date_filter").classList.toggle("show")){
      document.getElementById("real_cert_return_date_filter").classList.toggle("show")
    }
    document.getElementById("return_date_filter").classList.toggle("show");
  }

  btnTogglePickupDate(){
    if(document.getElementById("return_date_filter").classList.toggle("show")){
      document.getElementById("return_date_filter").classList.toggle("show")
    }
    if(document.getElementById("real_return_date_filter").classList.toggle("show")){
      document.getElementById("real_return_date_filter").classList.toggle("show")
    }
    if(document.getElementById("real_cert_return_date_filter").classList.toggle("show")){
      document.getElementById("real_cert_return_date_filter").classList.toggle("show")
    }
    document.getElementById("pickup_date_filter").classList.toggle("show");
  }

  btnToggleRealReturnDate(){
    if(document.getElementById("return_date_filter").classList.toggle("show")){
      document.getElementById("return_date_filter").classList.toggle("show")
    }
    if(document.getElementById("pickup_date_filter").classList.toggle("show")){
      document.getElementById("pickup_date_filter").classList.toggle("show")
    }
    if(document.getElementById("real_cert_return_date_filter").classList.toggle("show")){
      document.getElementById("real_cert_return_date_filter").classList.toggle("show")
    }
    document.getElementById("real_return_date_filter").classList.toggle("show");
  }

  btnToggleRealCertDate(){
    if(document.getElementById("real_return_date_filter").classList.toggle("show")){
      document.getElementById("real_return_date_filter").classList.toggle("show")
    }
    if(document.getElementById("return_date_filter").classList.toggle("show")){
      document.getElementById("return_date_filter").classList.toggle("show")
    }
    if(document.getElementById("pickup_date_filter").classList.toggle("show")){
      document.getElementById("pickup_date_filter").classList.toggle("show")
    }
    document.getElementById("real_cert_return_date_filter").classList.toggle("show");
  }

  getReturnDate(item){
    let result = null;
    if(item.real_return_date && item.real_cert_return_date && item.real_return_date < item.real_cert_return_date){
      result = item.real_return_date;
    }else if(item.real_return_date && item.real_cert_return_date && item.real_cert_return_date < item.real_return_date){
      result = item.real_cert_return_date;
    }else if((item.real_return_date == null || item.real_return_date == '') && item.real_cert_return_date){
      result = item.real_cert_return_date;
    }else if(item.real_return_date && (item.real_cert_return_date == null || item.real_cert_return_date == '')){
      result = item.real_return_date;
    }else{
      result = item.return_date;
    }
    return result
  }
}
