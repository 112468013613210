import { Component, Input, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import { GETService } from 'src/app/services';
import { JobOrderProductItemService } from 'src/app/services/job-orders';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import { UserInfoState } from 'src/app/store/user/user.state';
declare const $:any;

@Component({
  selector: 'app-work-order-non-lab-item',
  templateUrl: './work-order-non-lab-item.component.html',
  styleUrls: ['./work-order-non-lab-item.component.css']
})
export class WorkOrderNonLabItemComponent implements OnInit {
  form: FormGroup;
  pageLoad:boolean = true;
  isSubmit:boolean = false;
  isSale:boolean = false;
  isPuchase:boolean = false;
  isCalibration:boolean = false;
  isTranDep:boolean = false;
  isConfirmAll:boolean = false;
  isRejectAll:boolean = false;

  jobOrderItems = [];
  groupReceiveItems = [];
  deletejobOrderItems: any = [];

  pageType: string;
  template: string;
  next_status: string;
  job_order_info_id: string;
  //
  itemStatus = null;
  @Output() done = new EventEmitter<any>();
  @Input('customer_info') customer_info;
  @Input('canConfirm') canConfirm;
  @Input('canCompleted') canCompleted;
  @Input('submitted') submitted;
  @Input('jobOrderInfo') jobOrderInfo;

  @ViewChild("closeRejectBtn") closeRejectBtn: ElementRef;

  jobOrder = null;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;
  constructor(
    private fbd: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private GETService: GETService,
    private JobOrderProductItemService: JobOrderProductItemService,

    public CalculateFunctionService: CalculateFunctionService,
    public utils : QuotationUtilsService,
  ) { 
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
      this.next_status = params['status'];
    });
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  async ngOnInit() {
    this.initPopover();

    this.form = this.fbd.group({
      reject_reason: ["", [Validators.required]],
    });

    await this.GETService.itemStatus(null)
    .then(res=>{
      this.itemStatus = res.resultData.filter(status => String(status.item_status_id).toLocaleLowerCase().startsWith('crf') || status.item_status_id == 'PREPARE_FOR_SHIP' || status.item_status_id == 'RETURNED') || [];
    })

    this.userSub = this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      this.isPuchase =
      userInfo?.emp_info?.emp_department_id.startsWith("PUCHASE_DEP");
      this.isCalibration =
      userInfo?.emp_info?.emp_department_id.startsWith("CALIB_DEP");
      this.isTranDep =
      userInfo?.emp_info?.emp_department_id.startsWith("TRAN_DEP");
      this.isSale =
      userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP");

      if(this.job_order_info_id){
        await this.JobOrderProductItemService.load(null, {job_order_info_id: this.job_order_info_id})
        .then(async res=>{
          this.jobOrderItems = res.resultData || [];
          await Promise.all(this.jobOrderItems.map(async value =>{
            //default item 
            if(this.jobOrderInfo.doc_status_id == 'WAITING_FOR_CONFIRM' && this.pageType != 'show'  && ['ICL','ICS','TRN'].includes(this.template)){
              value.item_status_id = 'CRF-CONFIRMED';
            }
          }));
          await this.sentData()
        })
      }
      this.pageLoad = false;
    });
  }

  initPopover(){
    $('body').popover({
      selector: '[data-popover]',
      trigger: 'hover',
      placement: 'right',
      html: true
    }).on("show.bs.popover", function(e){
      $("[rel=popover]").not(e.target).popover("destroy");
      $(".popover").remove();                    
    }).on('mouseup', function(e) {
      var l = $(e.target);
      if (l[0].className.indexOf("popover") == -1) {
        $(".popover").each(function () {
          $(this).popover("hide");
        });
      }
    });
  }

  async sentData(){
    this.done.emit({
      jobOrderItems: this.jobOrderItems,
      deletejobOrderItems: this.deletejobOrderItems
    });
  }

  async submitJobOrderItem(event){
    if(event.length > 0){
      event.map(item=>{
        let checkDuplicate = this.jobOrderItems.find(value=> value.quotation_item_id == item.quotation_item_id);
        if(!checkDuplicate){
          this.jobOrderItems.push(item);
        }
      })
    }
    await this.sentData();
  }

  confirmAndComplateItemCodePRD(event){
    if(event.isAll){
      this.jobOrderItems.map((jobOrder)=>{
        let findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-CONFIRMED');
        jobOrder.item_status_id = 'CRF-CONFIRMED';
        jobOrder.item_status_info = findStatus;

        jobOrder.quotation_item_info.cost_of_sales = event.data.cost_of_sales; 
      })
    }else{
      let index = this.jobOrderItems.findIndex(x => x === this.jobOrder);

      if(this.jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM'){
        let findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-CONFIRMED');
        this.jobOrderItems[index].item_status_id = 'CRF-CONFIRMED';
        this.jobOrderItems[index].item_status_info = findStatus;
      }else{
        let findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-COMPLETED');
        this.jobOrderItems[index].item_status_id = 'CRF-COMPLETED';
        this.jobOrderItems[index].item_status_info = findStatus;
  
        if(parseFloat(this.utils.convertStingToNumber(event.data.cost_of_sales)) > this.CalculateFunctionService.sumProduct(this.jobOrderItems[index].quotation_item_info)){
          this.jobOrderItems[index].lock_invoice_at = new Date();
        }else{
          this.jobOrderItems[index].lock_invoice_at = undefined;
        }
      }
  
      this.jobOrderItems[index].quotation_item_info.cost_of_sales = event.data.cost_of_sales; 
    }
  }

  rejectItem(){
    this.isSubmit = true;
    if(this.form.invalid){
      return;
    }
    let findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-REJECTED');
    if(this.isRejectAll){
      this.jobOrderItems.map((jobOrder)=>{

        jobOrder.item_status_id = 'CRF-REJECTED';
        jobOrder.item_status_info = findStatus;
        jobOrder['reject_reason'] = this.form.value.reject_reason;
      })
    }else{
      this.jobOrderItems[this.jobOrderItems.indexOf(this.jobOrder)].item_status_id = 'CRF-REJECTED';
      this.jobOrderItems[this.jobOrderItems.indexOf(this.jobOrder)].item_status_info = findStatus;
      this.jobOrderItems[this.jobOrderItems.indexOf(this.jobOrder)]['reject_reason'] = this.form.value.reject_reason;
    }

    this.cancelForm();
    this.closeRejectBtn.nativeElement.click();
  }

  cancelItem(){
    this.isSubmit = true;
    if(this.form.invalid){
      return;
    }
    let findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-CANCELED');
    this.jobOrderItems[this.jobOrderItems.indexOf(this.jobOrder)].item_status_id = 'CRF-CANCELED';
    this.jobOrderItems[this.jobOrderItems.indexOf(this.jobOrder)].item_status_info = findStatus;
    this.jobOrderItems[this.jobOrderItems.indexOf(this.jobOrder)]['reject_reason'] = this.form.value.reject_reason;

    this.cancelForm();
    this.closeRejectBtn.nativeElement.click();
  }

  cancel(item){
    if(item){
      let findStatus = this.itemStatus.find(item=> item.item_status_id == 'CRF-CANCELED');
      this.jobOrderItems[this.jobOrderItems.indexOf(item)].item_status_id = 'CRF-CANCELED';
      this.jobOrderItems[this.jobOrderItems.indexOf(item)].item_status_info = findStatus;
    }
  }

  saveForm(){
    if(this.jobOrderInfo.doc_status_id == 'CONFIRMED'){
      this.cancelItem();
    }else{
      this.rejectItem();
    }
  }

  cancelForm(){
    this.form.controls['reject_reason'].setValue("");
    this.isSubmit = false;
  }

  select(jobOrder){
    this.isConfirmAll = false;
    this.isRejectAll = false;
    this.jobOrder = jobOrder;
  }

  async delete(closeModalEl){
    const index = this.jobOrderItems.indexOf(this.jobOrder);
    this.deletejobOrderItems.push(this.jobOrder);
    if (index > -1) {
      this.jobOrderItems.splice(index, 1);
    }
    await this.sentData();
    closeModalEl.click();
  }

  public getStatus(item_status_id: string){
    let findStatus = this.itemStatus.find(item=> item.item_status_id == item_status_id);
    return findStatus?.item_status_name_th || item_status_id;
  }

  public setContent(item, type:string): string{
    if(type == 'confirm'){
      item.job_order_status_confirm_info.sort(function(a,b){
        if(a.updated_at < b.updated_at) return 1;
        if(a.updated_at > b.updated_at) return -1;
        return 0;
      });
      let status = item.job_order_status_confirm_info.filter(item=>item.item_status_id == 'CRF-REJECTED')[0];

      if(item.reject_reason){
        if(!item?.reject_reason) item.reject_reason = "-";
        if(!item?.reject_solution) item.reject_solution = "-";
        return "วันที่ให้บริการ: " + moment(new Date()).format("DD-MM-YYYY") +"<br>" +
        "สาเหตุ: " + item.reject_reason + "<br>" +
        "แนวทางแก้ไข: " + item.reject_solution + "<br>";
      }else  if(status){
        if(!status?.reject_reason) status.reject_reason = "-";
        if(!status?.reject_solution) status.reject_solution = "-";
        return "วันที่ให้บริการ: " + moment(status.reject_service_date).format("DD-MM-YYYY") +"<br>" +
        "สาเหตุ: " + status?.reject_reason + "<br>" +
        "แนวทางแก้ไข: " + status?.reject_solution + "<br>";
      }else{
        return "" ;
      }
    }else{
      if(this.template == 'PRD'){
        item.job_order_status_work_info.sort(function(a,b){
          if(a.updated_at < b.updated_at) return 1;
          if(a.updated_at > b.updated_at) return -1;
          return 0;
        });
        let status = item.job_order_status_work_info.filter(item=>item.item_status_id == 'CRF-CANCELED')[0];
        if(item.reject_reason){
          return "สาเหตุ: " + item.reject_reason + "<br>";
        }else if(status){
          if(!status?.reject_reason) status.reject_reason = "-"
          if(!status?.reject_solution) status.reject_solution = "-"
          return "สาเหตุ: " + status.reject_reason + "<br>";
        }else{
          return "";
        }
      }
    }
  }
}
