<div class="app-content content" *ngIf="pageLoad">
  <div class="content-overlay"></div>
  <div class="content-wrapper" *ngIf="canAccess">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title">ภาพรวมระบบ
        </h3>

        <small >ข้อมูล ณ วันที่ {{date | thai_date:"short" | dash}}</small>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ระบบบริหารงานขาย</a>
              </li>
              <li class="breadcrumb-item active">ภาพรวมระบบ</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
    <div class="content-body">
      <div class="row">
        <div class="col-12 col-md-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-3">
                  <span
                      class="card-icon primary d-flex justify-content-center"
                    >
                      <img
                        src="/assets/app-assets/images/icons/invoice.png"
                        height="60"
                      />
                    </span>
                </div>
                <div class="col-9">
                  <h3>แจ้งหนี้</h3>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                              <th class="text-center">รอแจ้งหนี้</th>
                              <td class="text-right">{{invoiceData['syssum_type_1']?.value || 0 | number : '1.2-2'}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <th class="text-center">ยอดแจ้งหนี้</th>
                              <td class="text-right">{{invoiceData['syssum_type_2']?.value || 0 | number : '1.2-2'}}</td>
                            </tr>
                            <tr>
                              <th class="text-center">ยอดคงค้าง</th>
                              <td class="text-right">{{invoiceData['syssum_type_3']?.value || 0 | number : '1.2-2'}}</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-3">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-3">
                  <span
                      class="card-icon primary d-flex justify-content-center"
                    >
                      <img
                        src="/assets/app-assets/images/icons/capital.png"
                        height="60"
                      />
                    </span>
                </div>
                <div class="col-9">
                  <h3>รายรับสะสม</h3>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                              <th class="text-center">
                                สัปดาห์
                                <br>
                                <small>(จ. - อา.)</small>
                              </th>
                              <td class="text-right">{{incomeData['week'] || 0 | number : '1.2-2'}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <th class="text-center">เดือน</th>
                              <td class="text-right">{{incomeData['month'] || 0 | number : '1.2-2'}}</td>
                            </tr>
                            <tr>
                              <th class="text-center">ปี</th>
                              <td class="text-right">{{incomeData['year'] || 0 | number : '1.2-2'}}</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-2">
                  <span
                      class="card-icon primary d-flex justify-content-center"
                    >
                      <img
                        src="/assets/app-assets/images/icons/bill.png"
                        height="60"
                      />
                    </span>
                </div>
                <div class="col-10">
                  <h3>เสนอราคาและใบสั่งซื้อ</h3>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                              <th class="text-center">
                                ยอดเสนอราคารายสัปดาห์
                                <br>
                                <small>(รอตอบรับ)</small>
                              </th>
                              <td class="text-right">{{quotationData['week'] || 0 | number : '1.2-2'}}</td>
                              <th class="text-center">ยอดใบสั่งซื้อรายสัปดาห์
                                <br>
                                <small>(ยอมรับแล้ว)</small>
                              </th>
                              <td class="text-right">{{jobOrderData['week'] || 0 | number : '1.2-2'}}</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <th class="text-center">รายเดือน</th>
                              <td class="text-right">{{quotationData['month'] || 0 | number : '1.2-2'}}</td>
                              <th class="text-center">รายเดือน</th>
                              <td class="text-right">{{jobOrderData['month']|| 0 | number : '1.2-2'}}</td>
                            </tr>
                            <tr>
                              <th class="text-center">รายปี</th>
                              <td class="text-right">{{quotationData['year'] || 0 | number : '1.2-2'}}</td>
                              <th class="text-center">รายปี</th>
                              <td class="text-right">{{jobOrderData['year'] || 0 | number : '1.2-2'}}</td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-1">
                  <span
                      class="card-icon primary d-flex justify-content-center"
                    >
                      <img
                        src="/assets/app-assets/images/icons/report.png"
                        height="60"
                      />
                    </span>
                </div>
                <div class="col-11">
                  <h3>ใบสั่งงาน</h3>
                  <div class="table-responsive">
                    <table class="table table-striped table-bordered">
                        <thead>
                            <tr>
                              <th class="text-center">Scope</th>
                              <th class="text-right" *ngFor="let scope of scopeList">
                                {{scope?.scope_name}}
                              </th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                              <th class="text-center">{{sumCount}} (ชิ้น)</th>
                              <td class="text-right"  *ngFor="let scope of scopeList">
                                {{scope['syssum_type_7']?.value || 0 | number : '1.2-2'}}
                              </td>
        
                            </tr>
                            <tr>
                              <th class="text-center">{{sumPrice || 0 | number : '1.2-2'}}</th>
                              <td class="text-right"  *ngFor="let scope of scopeList">
                                {{scope['syssum_type_8']?.value || 0 | number : '1.2-2'}}
                              </td>
                            </tr>
                        </tbody>
                    </table>
                  </div>
                  <small>* หมายเหตุ ยอดจากใบสั่งงานที่สามารถให้บริการได้ คำนวณจากใบสั่งงานทุกปีรวมกัน</small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content-wrapper" *ngIf="!canAccess">
    <div class="content-body">
      <div class="text-center mt-3">
        <h2>ไม่มีสิทธิ์เข้าใช้งาน</h2>
      </div>
    </div>
  </div>
</div>

<div class="app-content content mt-3" *ngIf="!pageLoad">
  <loading></loading>
</div>