<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-0">ผู้ใช้งาน</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/users']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/users']">จัดการผู้ใช้งาน</a>
              </li>
              <li class="breadcrumb-item active">ผู้ใช้งาน</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            [routerLink]="['/users']"
            type="button"
            class="btn btn-light btn-min-width mr-1"
          >
            <i class="fa fa-arrow-left"></i> กลับ
          </button>
        </div>
      </div>
    </div>
    <loading *ngIf="!isReady"></loading>
    <div class="content-body"  *ngIf="isReady">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>
      <section class="basic-elements">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title">
                  ผู้ใช้งาน
                </h4>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <form
                    autocomplete="off"
                    class="form"
                    role="form"
                    action="/dashboard/user-accounts"
                    method="post"
                    [formGroup]="form"
                  >
                    <div class="form-body">
                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <div class="form-group">
                            <label for="emp_code">รหัสพนักงาน</label>
                            <input
                              type="text"
                              class="form-control"
                              id="emp_code"
                              formControlName="emp_code"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.emp_level_id"
                            [submitted]="submitted"
                          >
                            <label for="emp_department_id">แผนก</label>
                            <select
                              class="form-control"
                              id="emp_department_id"
                              formControlName="emp_department_id"
                            >
                              <option value="">กรุณาเลือกข้อมูล</option>
                              <option
                                *ngFor="let item of departments"
                                [value]="item.emp_department_id"
                              >
                                {{ item.emp_department_name_th }}</option
                              >
                            </select>
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.emp_level_id"
                            [submitted]="submitted"
                          >
                            <label for="emp_level_id">ระดับงาน</label>
                            <select
                              class="form-control"
                              id="emp_level_id"
                              formControlName="emp_level_id"
                            >
                              <option value="">กรุณาเลือกข้อมูล</option>
                              <option
                                *ngFor="let item of levels"
                                [value]="item.emp_level_id"
                              >
                                {{ item.emp_level_name_th }}</option
                              >
                            </select>
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.emp_position_id"
                            [submitted]="submitted"
                          >
                            <label for="emp_position_id">ตำแหน่งงาน</label>
                            <select
                              class="form-control"
                              id="emp_position_id"
                              formControlName="emp_position_id"
                            >
                              <option value="">กรุณาเลือกข้อมูล</option>
                              <option
                                *ngFor="let item of positions"
                                [value]="item.emp_position_id"
                              >
                                {{ item.emp_position_name_th }}</option
                              >
                            </select>
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.firstname_th"
                            [submitted]="submitted"
                          >
                            <label for="firstname_th">
                              ชื่อ (ภาษาไทย)
                            </label>
                            <input
                              type="text"
                              id="firstname_th"
                              class="form-control"
                              placeholder="ชื่อ (ภาษาไทย)"
                              formControlName="firstname_th"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.lastname_th"
                            [submitted]="submitted"
                          >
                            <label for="lastname_th">
                              นามสกุล (ภาษาไทย)
                            </label>
                            <input
                              type="text"
                              id="lastname_th"
                              class="form-control"
                              placeholder="นามสกุล (ภาษาไทย) *"
                              formControlName="lastname_th"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.firstname_en"
                            [submitted]="submitted"
                          >
                            <label for="firstname_en">
                              ชื่อ (ภาษาอังกฤษ)
                            </label>
                            <input
                              type="text"
                              id="firstname_en"
                              class="form-control"
                              placeholder="ชื่อ (ภาษาอังกฤษ)"
                              formControlName="firstname_en"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.lastname_en"
                            [submitted]="submitted"
                          >
                            <label for="lastname_en">
                              นามสกุล (ภาษาอังกฤษ)
                            </label>
                            <input
                              type="text"
                              id="lastname_en"
                              class="form-control"
                              placeholder="นามสกุล (ภาษาอังกฤษ) *"
                              formControlName="lastname_en"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.date_of_birth"
                            [submitted]="submitted"
                          >
                            <label for="date_of_birth">
                              วันเกิด
                            </label>
                            <div class="input-group mt-0">
                              <input
                                type="text"
                                id="date_of_birth"
                                class="form-control"
                                formControlName="date_of_birth"
                              />
                              <div class="input-group-append">
                                <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                                ></span>
                              </div>
                            </div>
                          </form-group>
                        </div>
                      </div>

                      <h5 class="text-bold-600 mb-1">ข้อมูลติดต่อ</h5>
                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.mobile_no"
                            [submitted]="submitted"
                          >
                            <label for="mobile_no">
                              โทรศัพท์
                            </label>
                            <input
                              type="text"
                              id="mobile_no"
                              class="form-control"
                              placeholder="โทรศัพท์"
                              formControlName="mobile_no"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.email"
                            [submitted]="submitted"
                          >
                            <label for="email">
                              อีเมล
                            </label>
                            <input
                              type="email"
                              id="email"
                              class="form-control"
                              placeholder="อีเมล์"
                              formControlName="email"
                            />
                          </form-group>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
