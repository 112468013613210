import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { NavbarComponent } from "./layout/navbar/navbar.component";
import { SidebarComponent } from "./layout/sidebar/sidebar.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LoginComponent } from "./pages/login/login.component";
import { DashboardComponent as DashboardLayout } from "./web-layout/dashboard/dashboard.component";
import { AuthGuardService } from "./authGuard.service";

import { NgxsModule } from "@ngxs/store";

import "../assets/app-assets/vendors/css/forms/selects/select2.css";

import { NgxsReduxDevtoolsPluginModule } from "@ngxs/devtools-plugin";
import { NgxsLoggerPluginModule } from "@ngxs/logger-plugin";

import {
  //user
  UserListComponent,
  UserCreateComponent,
  UserEditComponent,
  UserShowComponent,
  SecureCodeComponent,
  UserProfileComponent,
  //sale
  SalesTeamListComponent,
  SalesTeamCreateComponent,
  SalesTeamEditComponent,
  SalesTeamMemberCreateComponent,
  //unit
  UnitListComponent,
  UnitCreateComponent,
  UnitTypeListComponent,
  UnitTypeCreateComponent,
  UnitConversionComponent,
  UnitTypeEditComponent,
  UnitEditComponent,
  //poduct
  ProductListComponent,
  ProductCreateComponent,
  ProductEditComponent,
  ProductRangePriceCreateComponent,
  ProductRangePriceEditComponent,
  //scope
  ScopeListComponent,
  ScopeCreateComponent,
  ScopeEditComponent,
  MethodCrudComponent,
  EquipmentListComponent,
  EquipmentCreateComponent,
  EquipmentEditComponent,
  //customer
  CustomerListComponent,
  CustomerCreateComponent,
  CustomerEditComponent,
  CustomerShowComponent,
  CustomerActivityComponent,
  CustomerActivityCreateComponent,
  CustomerActivityShowComponent,
  CustomerAddressComponent,
  CustomerAddressListComponent,
  CustomerContactComponent,
  CustomerContactCreateComponent,
  CustomerMarketingComponent,
  CustomerRevokeComponent,
  CustomerAddressCreateComponent,
  CustomerActivityEditComponent,
  CustomerAddressEditComponent,
  CustomerContactEditComponent,
  CustomerQuotationComponent,
  MethodListComponent,
  MethodCreateComponent,
  MethodEditComponent,
  CpListComponent,
  CpCreateComponent,
  CpEditComponent,
  //quotation
  QuotationListComponent,
  QuotationCreateComponent,
  QuotationPrepareComponent,
  QuotationItemCreateComponent,
  QuotationItemEditComponent,
  QuotationPriceCreateComponent,
  QuotationShowComponent,
  QuotationEditComponent,
  QuotationAcceptListComponent,
  QuotationAcceptCreateComponent,
  QuotationAcceptEditComponent,
  QuotationMemoComponent,
  QuotationItemImportCreateComponent,
  QuotationPdfComponent,
  QuotationPdfPublicComponent,
  QuotationNoteCreateComponent,
  QuotationNoteEditComponent,
  QuotationNoteShowComponent,
  QuotationConfirmVoidedComponent,
  MitItemLogComponent,
  QuotationItemJobOrderCreateComponent,
  QuotationConfirmComponent,
  QuotationMitItemComponent,
  QuotationItemMappingComponent,
  NoteListComponent,
  //tran
  TransportreceiveListComponent,
  TransportreceiveCreateComponent,
  TransportreceiveCreateFilterComponent,
  TransportreceiveItemComponent,
  TransportreceiveMemoComponent,
  TransportreceiveItemCreateComponent,
  TransportreceiveItemCreateNoItemComponent,
  TransportreceiveUnlockComponent,
  TransportreceiveSignatureComponent,
  TransportreceiveShowComponent,
  TransportreceiveEditComponent,
  ReceiveItemEditAllComponent,
  TransportCancelComponent,
  TransportPdfComponent,
  ReceiveItemEditComponent,
  AddQuotationItemComponent,
  //work
  WorkOrderListComponent,
  WorkOrderCreateFilterComponent,
  WorkOrderLabCreateComponent,
  WorkOrderLabShowComponent,
  WorkOrderLabEditComponent,
  WorkOrderLabVerifyComponent,
  WorkOrderMemoComponent,
  WorkOrderLabItemComponent,
  WorkOrderLabImportComponent,
  WorkOrderLabItemCreateComponent,
  WorkOrderNonLabCreateComponent,
  WorkOrderNonLabEditComponent,
  WorkOrderNonLabItemComponent,
  WorkOrderNonLabItemCreateComponent,
  WorkOrderNonLabShowComponent,
  TrainingEngineerComponent,
  WorkerEngineerComponent,
  CarInfoComponent,
  ConfirmCalibrationItemComponent,
  RejectCalibrationItemComponent,
  SaveCalibrationItemComponent,
  EditQuotationMitItemComponent,
  UploadCertComponent,
  ConfirmationLetterComponent,
  IclIcsComponent,
  EclEcsComponent,
  PrdComponent,
  ServiceReportComponent,
  InvoiceComponent,
  TaxInvoiceComponent,
  BillingNoteComponent,
  ReceivedVoucherComponent,
  CreditNoteComponent,
  InvoiceListComponent,
  InvoiceCreateComponent,
  InvoiceEditComponent,
  InvoiceCreateFilterComponent,
  InvoiceShowComponent,
  InvoiceItemComponent,
  InvoiceItemCreateComponent,
  InvoiceHistoryComponent,
  InvoicePaymentComponent,
  InvoicePaymentCreateComponent,
  InvoicePaymentShowComponent,
  InvoicePoItemComponent,
  BillingNoteListComponent,
  BillingNoteCreateComponent,
  BillingNoteShowComponent,
  BillingNoteEditComponent,
  BillingNoteItemComponent,
  BillingNoteItemCreateComponent,
  BillingNoteHistoryComponent,
  BillingNoteItemHistoryComponent,
  ReceivedVoucherShowComponent,
  ReceivedVoucherItemComponent,
  ReceivedVoucherCreateComponent,
  ReceivedVoucherEditComponent,
  ReceivedVoucherHistoryComponent,
  ReceivedVoucherItemCreateComponent,
  ReceivedVoucherListComponent,
  PaymentListComponent,
  ChequeListComponent,
  ChequeEditComponent,
  TaxInvoiceCreateComponent,
  TaxInvoiceListComponent,
  TaxInvoiceShowComponent,
  TaxInvoiceEditComponent,
  TaxInvoiceItemComponent,
  TaxInvoiceItemCreateComponent,
  TaxInvoiceHistoryComponent,
  TaxInvoicePaymentComponent,
  TaxInvoicePaymentCreateComponent,
  TaxInvoicePaymentShowComponent,
  BillingNoteItemTaxCreateComponent,
  ReceivedVoucherItemTaxCreateComponent,
  EquipmentComponent,
  EquipmentUnlockComponent,
  EquipmentMappingFilterComponent,
  CarListComponent,
  CarCreateComponent,
  CarEditComponent,
  BillingListComponent,
  BillingCreateComponent,
  DeliveryListComponent,
  DeliveryCertHistoryComponent,
  DeliveryItemsHistoryComponent,
  CreditNoteCreateComponent,
  CreditNoteEditComponent,
  CreditNoteListComponent,
  CreditNoteItemComponent,
  CreditNoteShowComponent,
  CreditNoteItemCreateComponent,
  CreditNoteCreateFilterComponent,
  BillingNoteItemCreditCreateComponent,
  CreditNoteItemHistoryComponent,
  DebitNoteCreateComponent,
  DebitNoteCreateFilterComponent,
  DebitNoteEditComponent,
  DebitNoteItemComponent,
  DebitNoteItemCreateComponent,
  DebitNoteItemHistoryComponent,
  DebitNoteListComponent,
  DebitNoteShowComponent,
  DeliveryJobOrderHistoryComponent,
  DeliveryPassengerCreateComponent,
  ReturnItemEditComponent,
  TransportReceiveReturnSignatureComponent,
  WorkOrderReturnComponent,
  OnsiteComponent,
  ProductComponent,
  BillChequeListComponent,
  BillUpdateSentInfoComponent,
  AddressCertComponent,
  TaxInvoiceCreditItemComponent,
  ReportListComponent,
  ReportCreateComponent,
  PreBookingCreateComponent,
  PreBookingEditComponent,
  PreBookingListComponent,
  MasterBookingListComponent,
  MasterBookingEditComponent,
  MasterBookingStatusComponent,
  StandardCreateComponent,
  StandardEditComponent,
  StandardListComponent,
  EmailTemplateAttachmentListComponent,
} from "./pages";

import { GoogleMapsModule } from "@angular/google-maps"; // this is needed

import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // this is needed!
import { MyCalendarComponent } from "./pages/dashboard/my-calendar/my-calendar.component";

import { CustomerState } from "./store/customer/customer.state";
import { UserInfoState } from "./store/user/user.state";
import { ChangePasswordComponent } from "./pages/dashboard/users/change-password/change-password.component";
import { DecimalPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { SharedModule } from "./shared.module";
import { LandingComponent } from "./pages/landing/landing.component";
import { PermissionGuard } from "./permission.guard";
import { Page404Component } from "./pages/404/404.component";
import { EmailTemplateAttachmentCreateComponent } from "./pages/dashboard/email-template-attachment/email-template-attachment-create/email-template-attachment-create.component";
import { EmailTemplateAttachmentEditComponent } from "./pages/dashboard/email-template-attachment/email-template-attachment-edit/email-template-attachment-edit.component";

@NgModule({
  declarations: [
    MyCalendarComponent,
    ChangePasswordComponent,
    //layout
    AppComponent,
    NavbarComponent,
    SidebarComponent,
    DashboardComponent,
    LandingComponent,
    LoginComponent,
    DashboardLayout,
    //user
    UserListComponent,
    UserCreateComponent,
    UserEditComponent,
    UserShowComponent,
    SecureCodeComponent,
    UserProfileComponent,
    //sale
    SalesTeamListComponent,
    SalesTeamCreateComponent,
    SalesTeamEditComponent,
    SalesTeamMemberCreateComponent,
    //unit
    UnitListComponent,
    UnitCreateComponent,
    UnitTypeListComponent,
    UnitTypeCreateComponent,
    UnitConversionComponent,
    UnitTypeEditComponent,
    UnitEditComponent,
    //poduct
    ProductListComponent,
    ProductCreateComponent,
    ProductEditComponent,
    ProductRangePriceCreateComponent,
    ProductRangePriceEditComponent,
    //scope
    ScopeListComponent,
    ScopeCreateComponent,
    ScopeEditComponent,
    MethodCrudComponent,
    EquipmentListComponent,
    EquipmentCreateComponent,
    EquipmentEditComponent,
    //customer
    CustomerListComponent,
    CustomerCreateComponent,
    CustomerEditComponent,
    CustomerShowComponent,
    CustomerActivityComponent,
    CustomerActivityCreateComponent,
    CustomerActivityShowComponent,
    CustomerAddressComponent,
    CustomerAddressListComponent,
    CustomerContactComponent,
    CustomerContactCreateComponent,
    CustomerMarketingComponent,
    CustomerRevokeComponent,
    CustomerAddressCreateComponent,
    CustomerActivityEditComponent,
    CustomerAddressEditComponent,
    CustomerContactEditComponent,
    CustomerQuotationComponent,

    MethodListComponent,
    MethodCreateComponent,
    MethodEditComponent,
    CpListComponent,
    CpCreateComponent,
    CpEditComponent,
    //quotation
    QuotationListComponent,
    QuotationCreateComponent,
    QuotationPrepareComponent,
    QuotationItemCreateComponent,
    QuotationItemEditComponent,
    QuotationPriceCreateComponent,
    QuotationShowComponent,
    QuotationEditComponent,
    QuotationAcceptListComponent,
    QuotationAcceptCreateComponent,
    QuotationAcceptEditComponent,
    QuotationMemoComponent,
    QuotationItemImportCreateComponent,
    QuotationConfirmComponent,
    QuotationMitItemComponent,

    QuotationPdfComponent,
    QuotationPdfPublicComponent,
    QuotationNoteCreateComponent,
    QuotationNoteEditComponent,
    QuotationNoteShowComponent,
    QuotationConfirmVoidedComponent,
    MitItemLogComponent,
    QuotationItemJobOrderCreateComponent,
    QuotationItemMappingComponent,

    NoteListComponent,
    //tran
    TransportreceiveListComponent,
    TransportreceiveCreateComponent,
    TransportreceiveCreateFilterComponent,
    TransportreceiveItemComponent,
    TransportreceiveMemoComponent,
    TransportreceiveItemCreateComponent,
    TransportreceiveItemCreateNoItemComponent,
    TransportreceiveUnlockComponent,
    TransportreceiveSignatureComponent,
    TransportreceiveShowComponent,
    TransportreceiveEditComponent,
    TransportCancelComponent,
    TransportPdfComponent,
    ReceiveItemEditComponent,
    ReceiveItemEditAllComponent,
    AddQuotationItemComponent,
    //work
    WorkOrderListComponent,
    WorkOrderCreateFilterComponent,
    WorkOrderLabCreateComponent,
    WorkOrderLabShowComponent,
    WorkOrderLabEditComponent,
    WorkOrderLabVerifyComponent,
    WorkOrderMemoComponent,
    WorkOrderLabItemComponent,
    WorkOrderLabImportComponent,
    WorkOrderLabItemCreateComponent,
    WorkOrderNonLabCreateComponent,
    WorkOrderNonLabEditComponent,
    WorkOrderNonLabItemComponent,
    WorkOrderNonLabItemCreateComponent,
    WorkOrderNonLabShowComponent,
    TrainingEngineerComponent,
    WorkerEngineerComponent,
    CarInfoComponent,
    ConfirmCalibrationItemComponent,
    RejectCalibrationItemComponent,
    SaveCalibrationItemComponent,
    EditQuotationMitItemComponent,
    UploadCertComponent,
    ConfirmationLetterComponent,

    IclIcsComponent,
    EclEcsComponent,
    PrdComponent,
    ServiceReportComponent,

    InvoiceComponent,
    TaxInvoiceComponent,
    BillingNoteComponent,
    ReceivedVoucherComponent,
    CreditNoteComponent,

    InvoiceListComponent,
    InvoiceCreateComponent,
    InvoiceEditComponent,
    InvoiceShowComponent,
    InvoiceCreateFilterComponent,
    InvoiceItemComponent,
    InvoiceItemCreateComponent,
    InvoiceHistoryComponent,
    InvoicePaymentComponent,
    InvoicePaymentCreateComponent,
    InvoicePaymentShowComponent,
    InvoicePoItemComponent,

    BillingNoteListComponent,
    BillingNoteCreateComponent,
    BillingNoteShowComponent,
    BillingNoteEditComponent,
    BillingNoteItemComponent,
    BillingNoteItemCreateComponent,
    BillingNoteItemHistoryComponent,
    BillingNoteHistoryComponent,

    ReceivedVoucherListComponent,
    ReceivedVoucherShowComponent,
    ReceivedVoucherItemComponent,
    ReceivedVoucherCreateComponent,
    ReceivedVoucherEditComponent,
    ReceivedVoucherHistoryComponent,
    ReceivedVoucherItemCreateComponent,

    PaymentListComponent,
    ChequeListComponent,
    ChequeEditComponent,

    TaxInvoiceCreateComponent,
    TaxInvoiceListComponent,
    TaxInvoiceShowComponent,
    TaxInvoiceEditComponent,
    TaxInvoiceItemComponent,
    TaxInvoiceItemCreateComponent,
    TaxInvoiceHistoryComponent,
    TaxInvoicePaymentComponent,
    TaxInvoicePaymentCreateComponent,
    TaxInvoicePaymentShowComponent,

    BillingNoteItemTaxCreateComponent,
    ReceivedVoucherItemTaxCreateComponent,

    EquipmentComponent,
    EquipmentUnlockComponent,
    EquipmentMappingFilterComponent,

    CarListComponent,
    CarCreateComponent,
    CarEditComponent,

    BillingListComponent,
    BillingCreateComponent,
    DeliveryListComponent,
    DeliveryCertHistoryComponent,
    DeliveryItemsHistoryComponent,

    CreditNoteCreateComponent,
    CreditNoteEditComponent,
    CreditNoteListComponent,
    CreditNoteItemComponent,
    CreditNoteShowComponent,
    CreditNoteItemCreateComponent,
    CreditNoteCreateFilterComponent,
    CreditNoteItemHistoryComponent,

    BillingNoteItemCreditCreateComponent,
    TaxInvoiceCreditItemComponent,

    DebitNoteCreateComponent,
    DebitNoteCreateFilterComponent,
    DebitNoteEditComponent,
    DebitNoteItemComponent,
    DebitNoteItemCreateComponent,
    DebitNoteItemHistoryComponent,
    DebitNoteListComponent,
    DebitNoteShowComponent,

    DeliveryJobOrderHistoryComponent,
    DeliveryPassengerCreateComponent,

    ReturnItemEditComponent,
    TransportReceiveReturnSignatureComponent,
    WorkOrderReturnComponent,

    OnsiteComponent,
    ProductComponent,
    BillChequeListComponent,
    BillUpdateSentInfoComponent,
    CreditNoteComponent,
    AddressCertComponent,

    Page404Component,
    ReportListComponent,
    ReportCreateComponent,

    PreBookingCreateComponent,
    PreBookingEditComponent,
    PreBookingListComponent,
    MasterBookingListComponent,
    MasterBookingEditComponent,
    MasterBookingStatusComponent,

    StandardCreateComponent,
    StandardEditComponent,
    StandardListComponent,
    EmailTemplateAttachmentListComponent,
    EmailTemplateAttachmentCreateComponent,
    EmailTemplateAttachmentEditComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    NgxsModule.forRoot([CustomerState, UserInfoState]),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsLoggerPluginModule.forRoot(),

    AppRoutingModule,
  ],
  providers: [AuthGuardService, PermissionGuard, DecimalPipe],
  bootstrap: [AppComponent],
})
export class AppModule {}
