import { Component, OnInit, ElementRef, Input } from "@angular/core";
import { CustomerActivityService, GETService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";

@Component({
  selector: "app-customer-activity",
  templateUrl: "./customer-activity.component.html",
  styleUrls: ["./customer-activity.component.css"],
})
export class CustomerActivityComponent implements OnInit {
  isLoading = true;
  activities = [];
  activityTypes = [];
  activityType = "";
  customer_activity = null;
  @Input("can-edit-customer") canEditCustomer = false;
  constructor(
    private route: ActivatedRoute,
    private CustomerActivityService: CustomerActivityService,
    private GETService: GETService
  ) {}

  ngOnInit(): void {
    this.GETService.activityTypes({ isShow: "1" }).then((result) => {
      this.activityTypes = result.resultData || [];
    });

    this.load();
  }

  load() {
    const customer_id = this.route.snapshot.paramMap.get("customer_id");
    this.CustomerActivityService.load(null, {
      customer_id,
      customer_activity_type_id: this.activityType,
      order_by: "created_at:desc",
    }).then((result) => {
      this.activities = result.resultData || [];
      this.isLoading = false;
    });
  }

  select(customer_activity) {
    this.customer_activity = Object.assign({}, customer_activity);
  }

  closeSidebar() {
    this.customer_activity = null;
  }
}
