import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerInfoService } from 'src/app/services';
import { 
  CalculateFunctionService,
  DownloadFileService,
  QuotationMitItemService, 
  QuotationProductItemService, 
  QuotationService,
  QuotationUtilsService
} from 'src/app/services/quotations';

@Component({
  selector: 'app-billing-create',
  templateUrl: './billing-create.component.html',
  styleUrls: ['./billing-create.component.css']
})
export class BillingCreateComponent implements OnInit {
  customer_id: string;
  isShowCheckAll:boolean = false;
  selectedCount: number = 0;

  billing = [];
  customerInfo = null;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private DownloadFileService: DownloadFileService,
    private CustomerInfoService: CustomerInfoService,
    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public calculate: CalculateFunctionService,
    public utils : QuotationUtilsService,
  ) { 
    this.customer_id = this.route.snapshot.paramMap.get("customer_id");
  }

  async ngOnInit() {
    await this.CustomerInfoService.getById({customer_id: this.customer_id}).then(res=>{
      this.customerInfo =  res.resultData;
    })
    this.getBilling(null);
  }

  async getBilling(filter: object){
    await this.QuotationMitItemService.load(null, {'$quotation_info.customer_id$': this.customer_id, quotation_info_status:'ACCEPTED', is_child_quotation_mit_item_id_not_null: 'false', ready_to_billing:'true', ...filter, order_by: 'quotation_info.doc_no:desc,order_by:asc'},)
    .then(res=>{

      let resultData =  res.resultData.filter(item=>!item.acc_invoice_info_id && !item.acc_debit_note_info_id);
      resultData.map(item=>{
        let file = item?.quotation_info.quotation_accepted_info.find(f=>f?.quotation_accepted_file_info?.length > 0);

        this.billing.push({
          ...item,
          product_title: item.scope_name + ": "+ item.equipment_name,
          product_description: item.description,
          quotation_item_id: item.quotation_mit_item_id,
          original_price: 0,
          quantity: 1,
          unit_id:"",
          product_id: "",
          acc_invoice_info_id: "",
          select: false,
          item_type: 'calibration_service',
          data: item,
          lock: file? false: true
        })
      })
    });
     await this.QuotationProductItemService.load(null,  {'$quotation_info.customer_id$': this.customer_id, ready_to_billing:'true', ...filter})
    .then(res=>{
      let resultData = res.resultData.filter(item=> item.item_type == 'product' && !item.acc_invoice_info_id && !item.acc_debit_note_info_id);

      resultData.map(item=>{

        let file = item?.quotation_info.quotation_accepted_info.find(f=>f?.quotation_accepted_file_info?.length > 0);

        this.billing.push({
          ...item,
          product_title: item.product_title,
          acc_invoice_info_id: "",
          quotation_item_id: item.quotation_item_id,
          select: false,
          item_type: 'product',
          data: item,
          lock: file? false: true
        })
      })
    });
    this.isShowCheckAll = false;
    if(this.billing.length > 0){
      this.isShowCheckAll = true;
    }
    this.billing.sort(function(a,b){
      if(a.return_date > b.return_date) return 1;
      if(a.return_date < b.return_date) return -1;
      return 0;
    });
  }

  createInvoice(){
    if(this.billing.length > 0){
      this.router.navigateByUrl("/invoice/"+ this.billing[0].quotation_info.customer_id + "/create", {
        state: {
          billing: this.billing.filter(item=> item.select == true)
        },
      })
    }
  }

  onCheckAll(e){
    this.billing.map(item=>{
      if(!item.lock){
        item.select = e.target.checked;
      }
    });
    this.setCount();
  }

  setCount(){
    setTimeout(() => {
      this.selectedCount = 0;
      this.billing.map(item=>{
        if(item.select){
          this.selectedCount = this.selectedCount + 1;
        }
      });
    }, 100);
  }

  search(filter: object) {
    this.billing = [];
    this.selectedCount = 0;
    
    if(filter['search_all']){
      this.getBilling(filter);
    }else{
      this.getBilling(filter);
    }
  }

  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }
}
