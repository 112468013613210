import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
import moment from "moment";
declare const $;
@Directive({
  selector: "[date-time-picker]",
})
export class DateTimePickerDirective {
  @Output() instance = new EventEmitter();
  @Output() input = new EventEmitter();
  datetimepicker = null;
  @Input("date") date = null;
  @Input("min-date") minDate = undefined;
  @Input("max-date") maxDate = undefined;

  constructor(el: ElementRef) {
    this.datetimepicker = $(el.nativeElement);
    setTimeout(() => {
      this.datetimepicker.on("keydown", (e) => {
        e.preventDefault();
      });

      let picker = this.datetimepicker.datetimepicker({
        format: "DD-MM-YYYY HH:mm",
        minDate: this.minDate,
        maxDate: this.maxDate,
        useCurrent: false,
      });

      picker.on("dp.change", (e) => {
        if (e.date) {
          this.input.emit({
            target: {
              value: e.date.format(),
            },
          });
        }
      });

      this.datetimepicker.next().click(() => {
        this.datetimepicker.focus();
      });

      if (this.date) {
        this.input.emit({
          target: {
            value: this.date,
          },
        });
        this.datetimepicker
          .data("DateTimePicker")
          .date(moment(this.date).format("DD-MM-YYYY HH:mm"));
        this.datetimepicker.change();
      }

      this.instance.emit(picker);
    }, 500);
  }
}
