import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class ConvertService {
  async convert({
    unit_id_1,
    unit_id_2,
    val
  }) {
    let result = null;
    try {
      result = await Axios().post(`/convert/`+ unit_id_1+ '/t/'+ unit_id_2, {
        val: parseFloat(val)
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}