import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { CustomerAddressService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-customer-address-list",
  templateUrl: "./customer-address-list.component.html",
  styleUrls: ["./customer-address-list.component.css"],
})
export class CustomerAddressListComponent implements OnInit {
  isLoading = true;
  addresses = [];
  status: string;
  customer_address = null;
  reset: boolean = false;
  load: Function = this.CustomerAddressService.load;
  filter = {};
  @Input("can-edit-customer") canEditCustomer = false;
  @Input() provinces = [];
  @Output() update = new EventEmitter();
  constructor(
    private route: ActivatedRoute,
    private CustomerAddressService: CustomerAddressService
  ) {}

  ngOnInit(): void {
    this.search({
      customer_id: this.route.snapshot.paramMap.get("customer_id"),
      is_registed_address: "false",
    });
    this.isLoading = false;
  }
  onChangePage(addresses) {
    this.addresses = addresses;
  }
  select(customer_address) {
    this.customer_address = Object.assign({}, customer_address);
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter) {
    this.filter = filter;
  }
  async delete(closeModalEl) {
    let response = await this.CustomerAddressService.delete({
      customer_address_id: this.customer_address.customer_address_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }

  updateAddress(status) {
    // console.log("update address");
    this.doReset(status);
    this.update.emit();
  }
}
