
<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการเครื่องมือ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/quotation']">เครื่องมือ</a>
                </li>
                <li class="breadcrumb-item active">รายการเครื่องมือ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-12 col-12 mb-md-0 mb-2">
          <app-response-status *ngIf="status" [status]="status"></app-response-status>
            <section class="row">
                <div class="col-sm-12">
                  <div class="card">
                    <div class="card-header">

                      <form autocomplete="off" [formGroup]="form">
                        <div class="row">
                          <h4 class="card-title mb-2 col-12">รายการเครื่องมือ</h4>

                            <div class="col">
                              <div class="form-group">
                                <label class="sub-menu-title">เลขที่ใบเสนอราคา</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="เลขที่ใบเสนอราคา" formControlName="doc_no"/>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label class="sub-menu-title">เลขที่ใบรับเครื่องมือ</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="เลขที่ใบสั่งงาน" formControlName="transport_receive_info_doc_no"/>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label class="sub-menu-title">เลขที่ใบสั่งงาน</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="เลขที่ใบสั่งงาน" formControlName="job_order_doc_no"/>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">
                                <label class="sub-menu-title">ชื่อบริษัท</label>
                                <div class="form-group">
                                    <input type="text" class="form-control" placeholder="ชื่อบริษัท" formControlName="company_name"/>
                                </div>
                              </div>
                            </div>
                            <div class="col">
                                <div class="form-group">
                                    <label class="sub-menu-title text-white">.</label>
                                    <button type="submit" class="btn btn-primary w-100 btn-block" (click)="search()">
                                      <div>
                                        <i class="fa fa-search fa-lg" aria-hidden="true"></i><span class="ml-2">ค้นหา</span>
                                      </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                      </form>

                      <!-- <div class="row">
                        <h4 class="card-title mt-1 col-12 col-sm-4 col-md-6 col-lg-9">รายการเครื่องมือ</h4>
                        <span class="col-12 col-sm-8 col-md-6 col-lg-3">
                          <search-box (search)="search($event)" [placeholder]="'ค้นหาเลขที่เอกสาร/รหัส/ชื่อลูกค้า'"></search-box>
                        </span>
                      </div> -->
                    </div>
        
                    <div class="card-content collapse show">
                      <div class="card-body pt-0">
                          <div class="row justify-content-between" *ngIf="!isLoading && items">
                              <div class="col-12 col-sm-12 col-md-12 col-lg-8 align-self-end">
                                <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                                    <li class="nav-item border-primary mr-50">
                                        <a class="nav-link py-0" [class.active] = "tab === 'calibration'" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchFilter('calibration')">Calibration ({{calibration_count}})</a>
                                    </li>
                                    <li class="nav-item mr-50 border-primary">
                                      <a class="nav-link py-0" [class.active] = "tab === 'no_quotation'" id="pill10" data-toggle="pill" href="#pill10" aria-expanded="false" (click)="searchFilter('no_quotation')">รายการเพิ่มจาก WR ({{no_quotation_count}})</a>
                                    </li>
                                    <li class="nav-item border-primary mr-50">
                                      <a class="nav-link py-0" [class.active] = "tab === 'transport'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchFilter('transport')">เครื่องมือที่ไม่มีใบเสนอราคา ({{transport_count}})</a>
                                    </li>
                                    <li class="nav-item border-primary mr-50">
                                        <a class="nav-link py-0" [class.active] = "tab === 'training'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchFilter('training')">Training ({{training_count}})</a>
                                    </li>
                                    <li class="nav-item border-primary mr-50">
                                        <a class="nav-link py-0" [class.active] = "tab === 'product'" id="pill3" data-toggle="pill" href="#pill3" aria-expanded="false" (click)="searchFilter('product')">Product ({{product_count}})</a>
                                    </li>
                                  </ul>
                              </div>
                          </div>
                          <loading *ngIf="isLoading"></loading>
                          <div class="table-responsive" *ngIf="!isLoading && items" style="min-height: 150px">
                              <table class="table table-striped table-bordered">
                                  <thead>
                                      <tr>
                                        <th class="text-center">
                                          <div [ngSwitch]="tab">
                                            <span *ngSwitchCase="'transport'">ใบรับเครื่องมือ</span>
                                            <span *ngSwitchDefault>ใบเสนอราคา</span>
                                          </div>
                                        </th>
                                        <th>ชื่อลูกค้า</th>
                                        <th>ชื่อเครื่องมือ</th>
                                        <th *ngIf="tab=='calibration' || tab=='transport' || tab=='no_quotation'">Scope</th>
                                        <th *ngIf="tab=='calibration' || tab=='transport' || tab=='no_quotation'">Method</th>
                                        <th *ngIf="tab=='calibration' || tab=='transport' || tab=='no_quotation'">Location</th>
                                        <th *ngIf="tab=='calibration' || tab=='transport' || tab=='no_quotation'">Serial No. / Tag No.</th>
                                        <th class="text-nowrap" *ngIf="tab=='calibration' || tab=='transport'">
                                          <span class="pr-1">เข้ารับเครื่องมือ</span>
                                          <div class="dropdownmenu">
          
                                            <button (click)="btnToggleTransport()" class="btn btn-primary btn-sm">
                                                สถานะ <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                            </button>
                                              
                                            <div id="transport_receive_status" class="dropdownmenu-content rounded-right rounded-bottom">
                                              <form class="px-2 pt-1" [formGroup]="form">
                                                <div class="row">
                                                  <div class="col-8">
                                                    <label for="tran_item_status_id">สถานะเข้ารับเครื่องมือ</label>
                                                  </div>
                                                  <div class="col-4 text-right">
                                                    <i class="fa fa-times pointer text-secondary" (click)="btnToggleTransport()"></i>
                                                  </div>
                                                </div>
                                                <div class="form-group">
                                                  <ng-select2
                                                  [data]="tab=='calibration'? itemTranQtStatus: itemTranStatus"
                                                  id="tran_item_status_id"
                                                  [options]="{ multiple: true }"
                                                  class="w-100"
                                                  formControlName="tran_item_status_id"
                                                  (valueChanged)="filterTransportStatus($event)"
                                                >
                                                </ng-select2>
                                                </div>
                                              </form>
                                              
                                            </div>
                                          </div>
                                        
                                        </th>
                                        <th>
                                          <div>
                                            <span  class="pr-1">การให้บริการ</span>
                                            <div class="dropdownmenu" *ngIf="tab=='calibration' || tab=='training' || tab=='product'">
            
                                              <button (click)="btnToggleJobOrder()" class="btn btn-primary btn-sm">
                                                  สถานะ <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                              </button>
                                                
                                              <div id="job_order_status" class="dropdownmenu-content rounded-right rounded-bottom">
                                                <form class="px-2 pt-1" [formGroup]="form">
                                                  <div class="row">
                                                    <div class="col-8">
                                                      <label for="job_item_status_id">สถานะการสอบเทียบ</label>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                      <i class="fa fa-times pointer text-secondary" (click)="btnToggleJobOrder()"></i>
                                                    </div>
                                                  </div>
                                                  <div class="form-group">
                                                    <ng-select2
                                                    [data]="tab=='training'? itemJobTrainingStatus: itemJobStatus"
                                                    id="job_item_status_id"
                                                    [options]="{ multiple: true }"
                                                    class="w-100"
                                                    formControlName="job_item_status_id"
                                                    (valueChanged)="filterJobOrderStatus($event)"
                                                  >
                                                  </ng-select2>
                                                  </div>
                                                </form>
                                                
                                              </div>
                                    
                                            </div>
                                          </div>

                                          <div *ngIf="tab=='calibration' || tab=='training' || tab=='product'">
                                            <button class="btn btn-sm" [ngClass]="{'btn-secondary': !checkCanServiceChargeArray(), 'btn-info' : checkCanServiceChargeArray()}" [disabled]="!checkCanServiceChargeArray()" *ngIf="canAction" (click)="setBillingArray()">
                                              <i class="fa fa-money icon-left"></i>
                                               เก็บค่าบริการได้ทั้งหมด
                                            </button>
                                          </div>
                                        </th>
                                        <th *ngIf="tab!='transport' && tab!='no_quotation'">
                                          <span class="pr-1">
                                            สามารถเก็บค่าบริการได้
                                          </span>

                                          <div class="dropdownmenu">
          
                                            <button (click)="btnTogglebilling()" class="btn btn-primary btn-sm">
                                              สถานะ <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                            </button>
  
                                            <div id="ready_to_billing" class="dropdownmenu-content rounded-right rounded-bottom">
                                              <form class="px-2 pt-1" [formGroup]="form">
                                                <div class="row">
                                                  <div class="col-8">
                                                    <label for="ready_to_billing">สถานะการเก็บค่าบริการได้</label>
                                                  </div>
                                                  <div class="col-4 text-right">
                                                    <i class="fa fa-times pointer text-secondary" (click)="btnTogglebilling()"></i>
                                                  </div>
                                                </div>
                                                <div class="form-group">
                                                  <ng-select2
                                                  [data]="itemBilling"
                                                  id="ready_to_billing"
                                                  class="w-100"
                                                  formControlName="ready_to_billing"
                                                  (valueChanged)="filterbilling($event)"
                                                >
                                                </ng-select2>
                                                </div>
                                              </form>
                                              
                                            </div>
                                          </div>
                                        </th>
                                        <th *ngIf="tab!='no_quotation'">
                                          <span class="pr-1">
                                            ใบแจ้งหนี้
                                          </span>
                                          <div class="dropdownmenu" *ngIf="tab=='calibration' || tab=='training' || tab=='product'">
          
                                            <button (click)="btnToggleInvoice()" class="btn btn-primary btn-sm">
                                              สถานะ <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                            </button>
  
                                            <div id="invoice_status" class="dropdownmenu-content rounded-right rounded-bottom">
                                              <form class="px-2 pt-1" [formGroup]="form">
                                                <div class="row">
                                                  <div class="col-8">
                                                    <label for="invoice_status_id">สถานะใบแจ้งหนี้</label>
                                                  </div>
                                                  <div class="col-4 text-right">
                                                    <i class="fa fa-times pointer text-secondary" (click)="btnToggleInvoice()"></i>
                                                  </div>
                                                </div>
                                                <div class="form-group">
                                                  <ng-select2
                                                  [data]="itemAccStatus"
                                                  id="invoice_status_id"
                                                  [options]="{ multiple: true }"
                                                  class="w-100"
                                                  formControlName="invoice_status_id"
                                                  (valueChanged)="filterInvoiceStatus($event)"
                                                >
                                                </ng-select2>
                                                </div>
                                              </form>
                                              
                                            </div>
                                          </div>
                                         
                                        </th>
                                        <th *ngIf="tab!='no_quotation'">
                                          <span class="pr-1">ใบกำกับภาษี</span>
                                          <div class="dropdownmenu" *ngIf="tab=='calibration' || tab=='training' || tab=='product'">
          
                                            <button (click)="btnToggleTaxInvoice()" class="btn btn-primary btn-sm">
                                              สถานะ <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                            </button>
  
                                            <div id="tax_invoice_status" class="dropdownmenu-content rounded-right rounded-bottom">
                                              <form class="px-2 pt-1" [formGroup]="form">
                                                <div class="row">
                                                  <div class="col-8">
                                                    <label for="tax_invoice_status_id">สถานะใบแจ้งหนี้</label>
                                                  </div>
                                                  <div class="col-4 text-right">
                                                    <i class="fa fa-times pointer text-secondary" (click)="btnToggleTaxInvoice()"></i>
                                                  </div>
                                                </div>
                                                <div class="form-group">
                                                  <ng-select2
                                                  [data]="itemAccStatus"
                                                  id="tax_invoice_status_id"
                                                  [options]="{ multiple: true }"
                                                  class="w-100"
                                                  formControlName="tax_invoice_status_id"
                                                  (valueChanged)="filterTaxInvoiceStatus($event)"
                                                >
                                                </ng-select2>
                                                </div>
                                              </form>
                                              
                                            </div>
                                          </div>
                                        </th>
                                        <th *ngIf="tab=='calibration' || tab=='transport'">การส่งคืน</th>
                                      </tr>
                                  </thead>
                                  
                                  <tbody *ngIf="tab=='calibration'">
                                      <tr *ngFor="let item of items; let i = index">
                                        <th class="text-center">
                                          <a [href]="'/quotation/'+item?.quotation_info.quotation_info_id+'/show'" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                            {{item?.quotation_info?.doc_no}}
                                          </a>
                                          <div *ngIf="(userInfo$ | async)?.is_calib_dep">
                                            {{item?.quotation_info?.doc_no}}
                                          </div>
                                        </th>
                                        <td class="text-nowrap">
                                          <div>
                                            [{{item?.quotation_info?.customer_info?.customer_code}}] {{item?.quotation_info?.customer_info?.company_name}}
                                          </div>
                                         
                                        </td>
                                        <td>{{item?.equipment_name}}</td>
                                        <td>{{item?.scope_name || '-'}}</td>
                                        <td>{{item?.method_name || '-'}}</td>
                                        <td>{{item?.service_location || '-'}}</td>
                                        <td>{{item.serial_no || '-'}} / {{item.tag_no || '-'}}</td>
                                        <td>
                                          <div *ngIf="!item?.transport_receive_item_status">
                                            -
                                          </div>
                                          <div *ngIf="item?.transport_receive_item_status">
                                            <span [ngSwitch]="item?.transport_receive_item_status">
                                              <span *ngSwitchCase="'WAITING_FOR_CONFIRM'">
                                                รอยืนยันรายการ
                                              </span>
                                              <span *ngSwitchCase="'WAITING_FOR_REVIEW'">
                                                เข้ารับหน้างาน
                                              </span>
                                              <span *ngSwitchCase="'RECEIVED'">
                                                เข้ารับเสร็จสิ้น
                                              </span>
                                              <span *ngSwitchCase="'WAITING_FOR_RECEIVE'">
                                                รอเข้ารับ
                                              </span>
                                              <span *ngSwitchCase="'RETURNED'">
                                                เข้ารับเสร็จสิ้น
                                              </span>
                                              <span *ngSwitchDefault>
                                                -
                                              </span>
                                              <br><small *ngIf="item?.transport_receive_item_status !='CANCELED'">Doc No.: 
                                                <a [href]="'/transport-receive/'+item?.transport_receive_info_id+'/show'" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                                  {{item?.transport_receive_info_doc_no}}
                                                </a>
                                                <span *ngIf="(userInfo$ | async)?.is_calib_dep">
                                                  {{item?.transport_receive_info_doc_no}}
                                                </span>
                                              </small>
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          <ng-container [ngSwitch]="true">
                                            <ng-container *ngSwitchCase="item.job_order_info_status === 'DRAFT'">
                                              แบบร่าง
                                              <br>
                                              <small *ngIf="item?.job_order_info_doc_no">Doc No.: 
                                                <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                                  {{item?.job_order_info_doc_no}}
                                                </a>
                                                <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && ['ICL','ICS'].includes(item.job_order_info_type)">
                                                  {{item?.job_order_info_doc_no}}
                                                </a>
                                                <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && ['ECL','ECS'].includes(item.job_order_info_type)">
                                                  {{item?.job_order_info_doc_no}}
                                                </a>
                                              </small>
                                              <br>
                                              <small *ngIf="item?.cert_no">
                                                cert. no {{item?.cert_no}}
                                              </small>
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>
                                              <div *ngIf="item?.job_order_calibration_item_status==null">
                                                -
                                             </div>
                                             <!-- <div *ngIf="item?.job_order_calibration_item_status==''">
                                               <div *ngIf="!item?.ready_to_billing">
                                                 <div>
                                                   ยกเลิก
                                                 </div>
                                                 <button type="button" class="btn mr-1 btn-sm btn-info" (click)="setBilling(item, 'calibration', i)" *ngIf="!item.ready_to_billing && canAction">
                                                   <i class="fa fa-money icon-left"></i>
                                                    เก็บค่าบริการได้
                                                 </button>
                                               </div>
                                               <div *ngIf="item?.ready_to_billing">
                                                 ยกเลิก
                                               </div>
                                             </div> -->
                                             <div *ngIf="item?.job_order_calibration_item_status">
                                               <span *ngIf="['CRF-WAITING_FOR_CONFIRM'].includes(item?.job_order_calibration_item_status)">
                                                 รอการยืนยัน
                                               </span>
                                               <span *ngIf="['CRF-REJECTED'].includes(item?.job_order_calibration_item_status)">
                                                 ไม่สามารถให้บริการได้
                                               </span>
                                               <span *ngIf="['CRF-CONFIRMED'].includes(item?.job_order_calibration_item_status)">
                                                 อยู่ระหว่างให้บริการ
                                               </span>
                                               <span *ngIf="['CRF-COMPLETED','PREPARE_FOR_SHIP', 'RETURNED'].includes(item?.job_order_calibration_item_status)">
                                                 <span *ngIf="item?.job_order_calibration_item_status == 'CRF-COMPLETED'">บันทึกผลแล้ว</span>
                                                 <span *ngIf="item?.job_order_calibration_item_status == 'PREPARE_FOR_SHIP'">ดำเนินการจัดส่ง</span>
                                                 <span *ngIf="item?.job_order_calibration_item_status == 'RETURNED'">เสร็จสิ้น</span>
                                                 <br *ngIf="!item.ready_to_billing && canAction">
                                                 <button type="button" class="btn mr-1 btn-sm" [ngClass]="{'btn-secondary': !checkCanServiceCharge(item), 'btn-info' : checkCanServiceCharge(item)}" [disabled]="!checkCanServiceCharge(item)" (click)="setBilling(item, 'calibration', i)" *ngIf="!item.ready_to_billing && canAction">
                                                   <i class="fa fa-money icon-left" *ngIf="checkCanServiceCharge(item)"></i>
                                                   <i class="fa fa-lock fa-lg" *ngIf="!checkCanServiceCharge(item)"></i>
                                                    เก็บค่าบริการได้
                                                 </button>
                                               </span>
                                               <span *ngIf="['CRF-CANCELED'].includes(item?.job_order_calibration_item_status)">

                                                 <div *ngIf="!item?.ready_to_billing">
                                                  <div>
                                                    ยกเลิก
                                                  </div>
                                                  <button type="button" class="btn mr-1 btn-sm btn-info" (click)="setBilling(item, 'calibration', i)" *ngIf="!item.ready_to_billing && canAction">
                                                    <i class="fa fa-money icon-left"></i>
                                                     เก็บค่าบริการได้
                                                  </button>
                                                </div>
                                                <div *ngIf="item?.ready_to_billing">
                                                  ยกเลิก
                                                </div>
                                               </span>
                             
                                              <div>
                                                <small *ngIf="item?.job_order_info_doc_no">Doc No.: 
                                                  <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                                    {{item?.job_order_info_doc_no}}
                                                  </a>
                                                  <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && ['ICL','ICS'].includes(item.job_order_info_type)">
                                                    {{item?.job_order_info_doc_no}}
                                                  </a>
                                                  <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && ['ECL','ECS'].includes(item.job_order_info_type)">
                                                    {{item?.job_order_info_doc_no}}
                                                  </a>
                                                </small>
                                              </div>
                                              <div>
                                                <small *ngIf="item?.cert_no">
                                                  cert. no {{item?.cert_no}}
                                                </small>
                                              </div>
                                             </div>
                                            </ng-container>
                                          </ng-container>
                                       
                                        </td>
                                        <td colspan="3" class="text-left" *ngIf="item?.lock_invoice_at && !item?.unlock_invoice_at && item?.job_order_calibration_item_status">
                                          <i class="fa fa-lock fa-lg primary" aria-hidden="true"></i>
                                          <div class="primary">
                                            (ราคาทุนสูงกว่าราคาขาย หรือ มีการใช้ CP นอก Scope) 
                                          </div>
                                        </td>
                                        <td *ngIf="!item?.lock_invoice_at || item?.unlock_invoice_at || !item?.job_order_calibration_item_status">
                                          {{item.ready_to_billing | thai_date: "short":"date" | dash}}
                                        </td>
                                        <td *ngIf="!item?.lock_invoice_at || item?.unlock_invoice_at || !item?.job_order_calibration_item_status">
                                          <div *ngIf="!item?.acc_invoice_info_status">
                                            -
                                          </div>
                                          <div *ngIf="item?.acc_invoice_info_status">
                                            {{checkStatusInvoiceItem(item?.acc_invoice_info_status)}}
                                            <br><small class="text-nowrap" *ngIf="item?.acc_invoice_info_doc_no">Doc No.: {{item?.acc_invoice_info_doc_no}}</small>
                                          </div>
                                        </td>
                                        <td *ngIf="!item?.lock_invoice_at || item?.unlock_invoice_at || !item?.job_order_calibration_item_status">
                                          <div *ngIf="!item?.acc_tax_invoice_info_status">
                                            -
                                          </div>
                                          <div *ngIf="item?.acc_tax_invoice_info_status">
                                            {{checkStatusTaxInvoiceItem(item?.acc_tax_invoice_info_status)}}
                                            <br><small class="text-nowrap" *ngIf="item?.acc_tax_invoice_info_doc_no">Doc No.: {{item?.acc_tax_invoice_info_doc_no}}</small>
                                          </div>
                                        </td>
                                        <td class="text-nowrap">
                                          <div *ngIf="!item?.transport_receive_item_status">
                                            -
                                          </div>
                                          
                                          <div *ngIf="item?.transport_receive_item_status">
                                            <span [ngSwitch]="item?.transport_receive_item_status">

                                              <span *ngSwitchCase="'RETURNED'">
                                                ส่งคืนเครื่องแล้ว
                                              </span>
                                              <span *ngSwitchDefault>
                                                -
                                              </span>
                                            </span>
                                          </div>
                                        </td>
                                      </tr>
                                      <tr *ngIf="items.length === 0">
                                          <td colspan="15" class="text-center">ไม่มีข้อมูล</td>
                                      </tr>
                                  </tbody>

                                  <tbody *ngIf="tab=='no_quotation'">
                                    <tr *ngFor="let item of items; let i = index">
                                      <td>
                                        <button
  
                                        type="button"
                                        (click)="mapping(item)"
                                        class="btn mr-1 mb-1 btn-primary btn-sm btn-edit text-nowrap"
                                    ><i class="fa fa-plus icon-left"></i> เลือกใบเสนอราคา
                                    </button>
                                      </td>
                                      <td class="text-nowrap">
                                        <div *ngIf="item?.customer_info">
                                          [{{item?.customer_info?.customer_code}}] {{item?.customer_info?.company_name}}
                                        </div>
                                       
                                      </td>
                                      <td>{{item?.equipment_name}}</td>
                                      <td>{{item?.scope_name || '-'}}</td>
                                      <td>{{item?.method_name || '-'}}</td>
                                      <td>{{item?.service_location || '-'}}</td>
                                      <td>{{item.serial_no || '-'}} / {{item.tag_no || '-'}}</td>

                                      <td>
                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="item.job_order_info_status === 'DRAFT'">
                                            แบบร่าง
                                            <br>
                                            <small *ngIf="item?.job_order_info_doc_no">Doc No.: 
                                              <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                                {{item?.job_order_info_doc_no}}
                                              </a>
                                              <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && ['ICL','ICS'].includes(item.job_order_info_type)">
                                                {{item?.job_order_info_doc_no}}
                                              </a>
                                              <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && ['ECL','ECS'].includes(item.job_order_info_type)">
                                                {{item?.job_order_info_doc_no}}
                                              </a>
                                            </small>
                                            <br>
                                            <small *ngIf="item?.cert_no">
                                              cert. no {{item?.cert_no}}
                                            </small>
                                          </ng-container>
                                          <ng-container *ngSwitchDefault>
                                            <div *ngIf="!item?.job_order_calibration_item_status">
                                              -
                                            </div>
                                            <div *ngIf="item?.job_order_calibration_item_status">
                                              <span *ngIf="['CRF-WAITING_FOR_CONFIRM'].includes(item?.job_order_calibration_item_status)">
                                                รอการยืนยัน
                                              </span>
                                              <span *ngIf="['CRF-REJECTED'].includes(item?.job_order_calibration_item_status)">
                                                ไม่สามารถให้บริการได้
                                              </span>
                                              <span *ngIf="['CRF-CONFIRMED'].includes(item?.job_order_calibration_item_status)">
                                                อยู่ระหว่างให้บริการ
                                              </span>
                                              <span *ngIf="['CRF-COMPLETED','PREPARE_FOR_SHIP', 'RETURNED'].includes(item?.job_order_calibration_item_status)">
            
                                                <span *ngIf="item?.job_order_calibration_item_status == 'CRF-COMPLETED'">บันทึกผลแล้ว</span>
                                                <span *ngIf="item?.job_order_calibration_item_status == 'PREPARE_FOR_SHIP'">ดำเนินการจัดส่ง</span>
                                                <span *ngIf="item?.job_order_calibration_item_status == 'RETURNED'">เสร็จสิ้น</span>
                                                <br *ngIf="!item.ready_to_billing && canAction">
                                                <button type="button" class="btn mr-1 btn-sm text-nowrap" [ngClass]="{'btn-secondary': !checkCanServiceCharge(item), 'btn-info' : checkCanServiceCharge(item)}" [disabled]="!checkCanServiceCharge(item)" (click)="setBilling(item, 'calibration', i)" *ngIf="!item.ready_to_billing && canAction">
                                                  <i class="fa fa-money icon-left" *ngIf="checkCanServiceCharge(item)"></i>
                                                  <i class="fa fa-lock fa-lg" *ngIf="!checkCanServiceCharge(item)"></i>
                                                   เก็บค่าบริการได้
                                                </button>
                                              </span>
                                              <span  *ngIf="['CRF-CANCELED'].includes(item?.job_order_calibration_item_status)">
                                                ยกเลิก
                                              </span>
                                              <br>
                                              <small *ngIf="item?.job_order_info_doc_no">Doc No.: 
                                                <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                                  {{item?.job_order_info_doc_no}}
                                                </a>
                                                <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && ['ICL','ICS'].includes(item.job_order_info_type)">
                                                  {{item?.job_order_info_doc_no}}
                                                </a>
                                                <a [href]="'/work-order/calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && ['ECL','ECS'].includes(item.job_order_info_type)">
                                                  {{item?.job_order_info_doc_no}}
                                                </a>
                                              </small>
    
                                              <br>
                                              <small *ngIf="item?.cert_no">
                                                cert. no {{item?.cert_no}}
                                              </small>
                                            </div>
                                          </ng-container>
                                        </ng-container>
                                     
                                      </td>
                                    </tr>
                                    <tr *ngIf="items.length === 0">
                                        <td colspan="15" class="text-center">ไม่มีข้อมูล</td>
                                    </tr>
                                  </tbody>

                                  <tbody *ngIf="tab=='transport'">
                                    <tr *ngFor="let item of items; let i = index">
                                      <th class="text-center">
                                        <a [href]="'/transport-receive/'+item.transport_receive_info?.transport_receive_info_id+'/show'" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                          {{item.transport_receive_info?.doc_no}}
                                        </a>
                                        <span *ngIf="(userInfo$ | async)?.is_calib_dep">
                                          {{item.transport_receive_info?.doc_no}}
                                        </span>
                                      </th>
                                      <td class="text-nowrap">
                                        <div>
                                          [{{item?.transport_receive_info?.customer_info?.customer_code}}] {{item?.transport_receive_info?.customer_info?.company_name}}
                                        </div>
                                       
                                      </td>
                                      <td>{{item?.noqt_description || '-'}}</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>-</td>
                                      <td>{{item.noqt_serial_no || '-'}} / {{item.noqt_tag_no || '-'}}</td>
                                      <td  [ngSwitch]="item?.item_status_id">
                                        <div *ngSwitchCase="'WAITING_FOR_CONFIRM'">
                                          รอยืนยันรายการ
                                        </div>
                                        <div *ngSwitchCase="'WAITING_FOR_REVIEW'">
                                          เข้ารับหน้างาน
                                        </div>
                                        <div *ngSwitchCase="'RECEIVED'">
                                          เข้ารับเสร็จสิ้น
                                        </div>
                                        <div *ngSwitchCase="'WAITING_FOR_RECEIVE'">
                                          รอเข้ารับ
                                        </div>
                                        <div *ngSwitchDefault>
                                          -
                                        </div>
                                      </td>
                                      <td>
                                        -
                                      </td>
                                      <td class="text-center">
                                        -
                                      </td> 
                                      <td>
                                        -
                                      </td>
                                      <td>
                                        -
                                      </td>
                                    </tr>
                                    <tr *ngIf="items.length === 0">
                                        <td colspan="15" class="text-center">ไม่มีข้อมูล</td>
                                    </tr>
                                  </tbody>

                                  <tbody *ngIf="tab!='calibration' && tab!='transport' && tab!='no_quotation'">
                                    <tr *ngFor="let item of items; let i = index">
                                      <th class="text-center">
                                        <a [href]="'/quotation/'+item?.quotation_info.quotation_info_id+'/show'" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                          {{item?.quotation_info?.doc_no}}
                                        </a>
                                        <div *ngIf="(userInfo$ | async)?.is_calib_dep">
                                          {{item?.quotation_info?.doc_no}}
                                        </div>
                                      </th>
                                      <td class="text-nowrap">
                                        <div>
                                          [{{item?.quotation_info?.customer_info?.customer_code}}] {{item?.quotation_info?.customer_info?.company_name}}
                                        </div>
                                       
                                      </td>
                                      <td>
                                        {{item?.product_title}}
                                        <br>
                                        <small *ngIf="item?.related_job_order_info?.doc_no">
                                          Job Order: {{item?.related_job_order_info?.doc_no}}
                                        </small>
                                      </td>
                                      <td>

                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="item.job_order_info_status === 'DRAFT'">
                                            แบบร่าง
                                            <br>
                                            <small *ngIf="item?.job_order_info_doc_no">Doc No.: 
                                              <a [href]="'/work-order/non-calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                                {{item?.job_order_info_doc_no}}
                                              </a>
                                              <a [href]="'/work-order/non-calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && item.job_order_info_type == 'TRN'">
                                                {{item?.job_order_info_doc_no}}
                                              </a>
    
                                              <span *ngIf="(userInfo$ | async)?.is_calib_dep && item.job_order_info_type == 'PRD'">
                                                {{item?.job_order_info_doc_no}}
                                              </span>
                                            </small>
                                          </ng-container>
                                          <ng-container *ngSwitchDefault>
                                            <div *ngIf="!item?.job_order_product_item_status">
                                              -
                                            </div>
                                            <div *ngIf="item?.job_order_product_item_status">
                                              <span *ngIf="['CRF-WAITING_FOR_CONFIRM'].includes(item?.job_order_product_item_status)">
                                                รอการยืนยัน
                                              </span>
                                              <span *ngIf="['CRF-REJECTED'].includes(item?.job_order_product_item_status)">
                                                ไม่สามารถให้บริการได้
                                              </span>
                                              <span   *ngIf="['CRF-CONFIRMED'].includes(item?.job_order_product_item_status)">
                                                อยู่ระหว่างให้บริการ
                                              </span>
                                              <span *ngIf="['CRF-COMPLETED','PREPARE_FOR_SHIP', 'RETURNED'].includes(item?.job_order_product_item_status)">
                                                <span *ngIf="item?.job_order_product_item_status== 'CRF-COMPLETED'">{{tab=='training'?'เสร็จสิ้น': 'รออนุมัติใบรายงานผล'}}</span>
                                                <span *ngIf="item?.job_order_product_item_status == 'PREPARE_FOR_SHIP'">ดำเนินการจัดส่ง</span>
                                                <span *ngIf="item?.job_order_product_item_status == 'RETURNED'">เสร็จสิ้น</span>
    
                                                <div *ngIf="!item.ready_to_billing && item.product_info?.product_type_id !=2 && canAction">
                                                  <button type="button" class="btn mr-1 btn-sm" [ngClass]="{'btn-secondary': !checkCanServiceCharge(item), 'btn-info' : checkCanServiceCharge(item)}" [disabled]="!checkCanServiceCharge(item)" (click)="setBilling(item, 'product', i)">
                                                    <i class="fa fa-money icon-left" *ngIf="checkCanServiceCharge(item)"></i>
                                                    <i class="fa fa-lock fa-lg" *ngIf="!checkCanServiceCharge(item)"></i>
                                                    เก็บค่าบริการได้
                                                  </button>
                                                </div>
    
                                              </span>
                                              <span *ngIf="['CRF-CANCELED'].includes(item?.job_order_product_item_status)">
                                                ยกเลิก
                                              </span>
                                            </div>
                                            <small *ngIf="item?.job_order_info_doc_no">Doc No.: 
                                              <a [href]="'/work-order/non-calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="!(userInfo$ | async)?.is_calib_dep">
                                                {{item?.job_order_info_doc_no}}
                                              </a>
                                              <a [href]="'/work-order/non-calibration/'+item?.job_order_info_id+'/show?template='+item.job_order_info_type" target="_blank" *ngIf="(userInfo$ | async)?.is_calib_dep && item.job_order_info_type == 'TRN'">
                                                {{item?.job_order_info_doc_no}}
                                              </a>
    
                                              <span *ngIf="(userInfo$ | async)?.is_calib_dep && item.job_order_info_type == 'PRD'">
                                                {{item?.job_order_info_doc_no}}
                                              </span>
                                            </small>
    
                                            <div *ngIf="!item.ready_to_billing && item.product_info?.product_type_id ==2 && item.quotation_info?.doc_status_id=='ACCEPTED' && canAction">
                                              <button type="button" class="btn mr-1 btn-sm" [ngClass]="{'btn-secondary': !checkCanServiceCharge(item, 'file'), 'btn-info' : checkCanServiceCharge(item, 'file')}" [disabled]="!checkCanServiceCharge(item, 'file')" (click)="setBilling(item, 'product', i)">
                                                <i class="fa fa-money icon-left" *ngIf="checkCanServiceCharge(item, 'file')"></i>
                                                <i class="fa fa-lock fa-lg" *ngIf="!checkCanServiceCharge(item, 'file')"></i>
                                                เก็บค่าบริการได้
                                              </button>
                                            </div>
    
                                            <div *ngIf="!item.ready_to_billing && item.product_info?.product_type_id ==3 && item.quotation_info?.doc_status_id=='ACCEPTED' && canAction">
                                              <button type="button" class="btn mr-1 btn-sm" [ngClass]="{'btn-secondary': !checkCanServiceCharge(item, 'file'), 'btn-info' : checkCanServiceCharge(item, 'file')}" [disabled]="!checkCanServiceCharge(item, 'file')" (click)="setBilling(item, 'product', i)">
                                                <i class="fa fa-money icon-left" *ngIf="checkCanServiceCharge(item, 'file')"></i>
                                                เก็บค่าบริการได้
                                              </button>
                                            </div>
                                          </ng-container>
                                        </ng-container>

                                        
                                        
                                      </td>
                                      <td>
                                        {{item.ready_to_billing | thai_date: "short":"date" | dash}}
                                      </td>
                                      <td colspan="2" class="text-center" *ngIf="item?.lock_invoice_at && !item?.unlock_invoice_at">
                                        <i class="fa fa-lock fa-lg primary" aria-hidden="true"></i>
                                        <div class="primary">
                                          (ราคาทุนสูงกว่าราคาขาย) 
                                        </div>
                                      </td>
                                      <td *ngIf="!item?.lock_invoice_at || item?.unlock_invoice_at">
                                        <div *ngIf="!item?.acc_invoice_info_status">
                                          -
                                        </div>
                                        <div *ngIf="item?.acc_invoice_info_status">
                                          {{checkStatusInvoiceItem(item?.acc_invoice_info_status)}}
                                          <br><small class="text-nowrap" *ngIf="item?.acc_invoice_info_doc_no">Doc No.: {{item?.acc_invoice_info_doc_no}}</small>
                                        </div>
                                      </td>
                                      <td *ngIf="!item?.lock_invoice_at || item?.unlock_invoice_at">
                                        <div *ngIf="!item?.acc_tax_invoice_info_status">
                                          -
                                        </div>
                                        <div *ngIf="item?.acc_tax_invoice_info_status">
                                          {{checkStatusTaxInvoiceItem(item?.acc_tax_invoice_info_status)}}
                                          <br><small class="text-nowrap" *ngIf="item?.acc_tax_invoice_info_doc_no">Doc No.: {{item?.acc_tax_invoice_info_doc_no}}</small>
                                        </div>
                                    </tr>
                                    <tr *ngIf="items.length === 0">
                                        <td colspan="20" class="text-center">ไม่มีข้อมูล</td>
                                    </tr>
                                  </tbody>
                              </table>
                          </div>
        
                          <app-paginator
                            [filter]="filter"
                            [reset]="reset"
                            [fetch]="load"
                            [pageSize]="15"
                            (changePage)="onChangePage($event)">
                          </app-paginator>

                          <small class="text-danger">
                            * ราคา Lock เมื่อ มีการบันทึก CP นอก Scope หรือ มีการเปลี่ยนแปลง Point แล้วราคาใหม่สูงกว่าราคาเดิม
                          </small>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
        </div>
      </div>
    </div>
</div>

<app-equipment-mapping-filter [item]="item" *ngIf="item">
</app-equipment-mapping-filter>

<!-- <app-equipment-unlock (save)="unlock($event)">
</app-equipment-unlock> -->