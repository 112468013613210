import Axios from "./axios";
import { Injectable } from "@angular/core";

export interface Standard {
  standard_id: string;

  tag_no: string;
  description: string;
  manufacturer: string;
  model: string;
  serial_no: string;
  range: string;
  accuracy: string;
  calibration_period: number;
  calibrate_date: string | null;
  due_date: string | null;
  certificate_no: string;
  calibrated_by: string;
  traceability: string;
  accessory: string;
  cost: number;
  status: string;
  location: string;
  owner: string;

  created_at: string;
  updated_at: string;
  deleted_at: string;
}

export interface StandardCreate extends Omit<Standard, "standard_id"> {}
export interface StandardEdit
  extends Omit<Standard, "created_at" | "updated_at" | "deleted_at"> {}

export interface StandardFilter {
  search: string;
}

@Injectable({ providedIn: "root" })
export class StandardService {
  async create(data: StandardCreate) {
    let result = null;
    try {
      result = await Axios().post(`/standard`, data);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data: StandardEdit) {
    let result = null;
    try {
      let standardId = data.standard_id;
      delete data.standard_id;
      result = await Axios().put(`/standard/${standardId}`, data);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ standard_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/standard/${standard_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/standard`, {
        params: {
          offset: paging?.offset,
          limit: paging?.limit,
          ...filter,
          order_by: "due_date:asc",
        },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ standard_id }) {
    let result = null;
    try {
      result = await Axios().get(`/standard/${standard_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async download() {
    let result = null;
    try {
      result = await Axios().get(`/standard/download`, {
        responseType: "blob",
      });

      const url = window.URL.createObjectURL(new Blob([result.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", result.headers["x-filename"]);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
