<div
  modal
  class="modal fade text-left"
  id="delivery-passenger-create"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreateModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="ItemCreateModal">
          กำหนด Messenger
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="close()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" autocomplete="off" class="form" [formGroup]="form">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12">
                    <form-group
                      [control]="form.controls.return_messenger_id"
                      [submitted]="submitted"
                      >
                        <label for="return_messenger_id">
                          Messenger <span class="danger">*</span>
                        </label>
                        <ng-select2
                          [data]="transportContactList"
                          class="w-100"
                          id="return_messenger_id"
                          formControlName="return_messenger_id"
                          placeholder="ผู้ส่งเครื่องมือ"
                        >
                        </ng-select2>
                    </form-group>

                    <form-group
                      [control]="form.controls.return_transport_type_id"
                      [submitted]="submitted"
                      >
                        <label for="return_transport_type_id">
                          ด้วยวิธี <span class="danger">*</span>
                        </label>
                        <ng-select2
                          [data]="transportTypes"
                          class="w-100"
                          id="return_transport_type_id"
                          formControlName="return_transport_type_id"
                          placeholder="วิธีส่งเครื่องมือ"
                        >
                        </ng-select2>
                    </form-group>
                  </div>

                </div>
              </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
          (click)="close()"
        >
          <i class="fa fa-times icon-left"></i> ปิด
        </button>
        <button
          type="button"
          class="btn btn-primary"
          (click)="submit()"
        >
          <i class="fa fa-save icon-left"></i> บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
