import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
} from "@angular/core";
import { 
  NgSelect2Customer
} from '../../../../../services/quotations';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilsService } from 'src/app/services';

@Component({
  selector: 'app-quotation-item-mapping',
  templateUrl: './quotation-item-mapping.component.html',
  styleUrls: ['./quotation-item-mapping.component.css']
})
export class QuotationItemMappingComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") private closeModal: ElementRef;
  @Input() quotationMitItems: any;
  select2OptionQuotation = null;

  constructor(
    private formBuilder: FormBuilder,
    private NgSelect2Customer: NgSelect2Customer,

    public UtilsService: UtilsService,
  ) {
    this.setForm();
  }

  ngOnInit(): void {
    this.getData();
  }

  async getData(){
    let customer_id = this.quotationMitItems[0].customer_id;
    if(customer_id){
      this.select2OptionQuotation = this.NgSelect2Customer.selectMitItems(`?limit=20&&quotation_info_id=new&&customer_id=`+customer_id, this.quotationMitItems);
    }else{
      this.select2OptionQuotation = this.NgSelect2Customer.selectMitItems(`?limit=20&&quotation_info_id=new`, this.quotationMitItems);
    }
  }

  setForm(){
    this.form = this.formBuilder.group({
      quotation_mit_item_id: ["", Validators.required]
    });
  }

  async onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.closeModal.nativeElement.click();
    this.save.emit(this.form.value.quotation_mit_item_id);
  }

  onClose() {
    this.save.emit(null);
  }
}
