<div
  class="modal fade text-left"
  [id]="id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="myModalLabel1">{{verify? 'ตรวจสอบ':'แก้ไข'}} Pre Booking</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <loading *ngIf="!pageLoad"></loading>
      </div>
      <div class="modal-body" *ngIf="pageLoad">

        <form class="form" [formGroup]="form">
            <div class="row justify-content-md-center">
              <div class="col-md-12">
                <div class="form-body">
                    <div class="row">
                        <div class="col-3">
                            <label for="booking_date">
                                ประเภท <span class="danger" *ngIf="!verify">*</span>
                            </label>
                        </div>
                        <div class="col-9">
                            <form-group [control]="form.controls.booking_type" [submitted]="submitted">
                
                                <div class="input-group">
                                    <div class="d-inline-block custom-control custom-radio mr-1">
                                        <input type="radio" value="RECEIVE" formControlName="booking_type" class="custom-control-input"
                                            id="booking_type_1" />
                                        <label class="custom-control-label" for="booking_type_1">รับ</label>
                                    </div>
                                    <div class="d-inline-block custom-control custom-radio mr-1">
                                        <input type="radio" value="SEND" formControlName="booking_type" class="custom-control-input"
                                            id="booking_type_2" />
                                        <label class="custom-control-label" for="booking_type_2">ส่ง</label>
                                    </div>
                                </div>
                            </form-group>
                        </div>
                    </div>
                
                    <form-group [control]="form.controls.booking_date" [submitted]="submitted">
                        <label for="booking_date">
                            วันที่จองงาน <span class="danger" *ngIf="!verify">*</span>
                        </label>
                        <div class="input-group mt-0">
                            <input date-picker type="text" id="booking_date" 
                            [date]="form.value.booking_date"
                            [max-date]="undefined"
                            *ngIf="form.value.booking_date"
                             class="form-control"
                                formControlName="booking_date" />
                            <div class="input-group-append" for="doc_date">
                                <span class="input-group-text"><i class="feather icon-calendar"></i></span>
                            </div>
                        </div>
                    </form-group>
                
                    <form-group [control]="form.controls.customer_id" [submitted]="submitted">
                        <label for="customer_id">ชื่อหน่วยงาน/บริษัท <span class="danger" *ngIf="!verify">*</span></label>
                        <div class="input-group">
                            <ng-select2 formControlName="customer_id" class="w-100" [options]="select2OptionCustomer" [data]="customerList"
                                placeholder="ชื่อหน่วยงาน/บริษัท" [allowClear]="true">
                            </ng-select2>
                        </div>
                    </form-group>
                    <form-group [control]="form.controls.location" [submitted]="submitted">
                        <label for="location">
                            สถานที่ <span class="danger" *ngIf="!verify">*</span>
                        </label>
                        <input type="text" id="location" class="form-control" placeholder="สถานที่"
                            formControlName="location" />
                    </form-group>
                
                    <form-group [control]="form.controls.booking_time" [submitted]="submitted">
                        <label for="booking_time">
                            เวลา
                        </label>
                        <input type="text" id="booking_time" class="form-control" placeholder="เวลา" formControlName="booking_time" />
                    </form-group>
                
                    <form-group [control]="form.controls.extra_message" [submitted]="submitted">
                        <label for="extra_message">
                            ข้อความพิเศษ
                        </label>
                        <input type="text" id="extra_message" class="form-control" placeholder="ข้อความพิเศษ"
                            formControlName="extra_message" />
                    </form-group>
                
                    <form-group [control]="form.controls.transport_receive_info_doc_no" [submitted]="submitted">
                        <label for="transport_receive_info_doc_no">
                            TR
                        </label>
                        <input type="text" id="transport_receive_info_doc_no" class="form-control" placeholder="TR"
                            formControlName="transport_receive_info_doc_no" />
                    </form-group>
                
                    <form-group [control]="form.controls.job_order_info_doc_no" [submitted]="submitted">
                        <label for="job_order_info_doc_no">
                            WR
                        </label>
                        <input type="text" id="job_order_info_doc_no" class="form-control" placeholder="WR"
                            formControlName="job_order_info_doc_no" />
                    </form-group>
                
                    <form-group [control]="form.controls.quotation_info_doc_no" [submitted]="submitted">
                        <label for="quotation_info_doc_no">
                            QT
                        </label>
                        <input type="text" id="quotation_info_doc_no" class="form-control" placeholder="QT"
                            formControlName="quotation_info_doc_no" />
                    </form-group>

                    <form-group
                    [control]="form.controls.transport_type_id"
                    [submitted]="submitted"
                    >
                      <label for="transport_type_id">
                        ประเภทรถ
                      </label>
                      <ng-select2
                        [options]="
                        {
                          language: UtilsService.select2OptionsLanguage()
                        }"
                        [data]="transportTypes"
                        class="w-100"
                        id="transport_type_id"
                        formControlName="transport_type_id"
                        placeholder="ประเภทรถ"
                      >
                      </ng-select2>
                  </form-group>
                
                    <form-group [control]="form.controls.contact_name" [submitted]="submitted">
                        <label for="contact_name">
                            ชื่อผู้ติดต่อ <span class="danger" *ngIf="!verify">*</span>
                        </label>
                        <input type="text" id="contact_name" class="form-control" placeholder="ชื่อผู้ติดต่อ"
                            formControlName="contact_name" />
                    </form-group>
                
                    <form-group [control]="form.controls.contact_phonenumber" [submitted]="submitted">
                        <label for="contact_phonenumber">
                            เบอร์ผู้ติดต่อ <span class="danger" *ngIf="!verify">*</span>
                        </label>
                        <input type="text" id="contact_phonenumber" class="form-control" placeholder="เบอร์ผู้ติดต่อ"
                            formControlName="contact_phonenumber" />
                    </form-group>
                
                
                </div>
              </div>
            </div>
          </form>
      </div>
      <div class="modal-footer" *ngIf="verify && pageLoad">

        <button
        type="button"
        class="btn btn-danger"
        (click)="reject()"
      >
        <i class="fa fa-times icon-left"></i> ไม่สามารถให้บริการ
      </button>

      <button type="button" class="btn btn-success" (click)="confirm()">
        <i class="fa fa-check-circle-o"></i> สามารถให้บริการได้
      </button>
      </div>
      <div class="modal-footer" *ngIf="!verify && pageLoad">

        <button
        type="button"
        class="btn btn-warning"
        data-dismiss="modal"
        (click)="onClose()"
      >
        <i class="fa fa-times icon-left"></i> ยกเลิก
      </button>

      <button type="button" class="btn btn-primary" (click)="onSave()">
        <i class="fa fa-plus icon-left"></i>ยืนยัน
      </button>
      </div>
    </div>
  </div>
</div>
