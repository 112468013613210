import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class UnitTypeService {
  async create({ unit_type_name_th, unit_type_name_en }) {
    let result = null;
    try {
      result = await Axios().post(`/unit-type`, {
        unit_type_name_th,
        unit_type_name_en,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({ unit_type_id, unit_type_name_th, unit_type_name_en }) {
    let result = null;
    try {
      result = await Axios().put(`/unit-type/${unit_type_id}`, {
        unit_type_name_th,
        unit_type_name_en,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ unit_type_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/unit-type/${unit_type_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/unit-type`, {
        params: {
          offset: paging?.offset,
          limit: paging?.limit,
          ...filter,
          order_by: "unit_type_name_en:asc",
        },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ unit_type_id }) {
    let result = null;
    try {
      result = await Axios().get(`/unit-type/${unit_type_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
