
<div
modal
class="modal fade text-left"
id="reject-calibration-item"
tabindex="-1"
(close)="close()"
role="dialog"
aria-labelledby="TransportreceiveFilterModal"
aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header bg-primary text-white">
      <h4 class="modal-title" id="TransportreceiveFilterModal">
        ไม่สามารถให้บริการได้
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form form-horizontal" [formGroup]="form">
          <div class="form-body">
            <div class="mt-1">
                <form-group
                [control]="form.controls.reject_service_date"
                [submitted]="submitted">
                <label for="reject_service_date">
                    วันที่สามารถให้บริการได้ <span class="danger">*</span>
                </label>
                <div class="input-group mt-0">
                    <input
                      date-picker
                      type="text"
                      *ngIf="form.value.reject_service_date && resetdate"
                      id="reject_service_date"
                      placeholder="วันที่สามารถให้บริการได้"
                      [max-date]="undefined"
                      class="form-control"
                      [date]="form.value.reject_service_date"
                      formControlName="reject_service_date"
                    />
                    <div
                    class="input-group-append"
                    for="reject_service_date"
                    >
                    <span class="input-group-text"
                        ><i class="feather icon-calendar"></i
                    ></span>
                    </div>
                </div>
                </form-group>
            </div>
            <div class="mt-1">
              <form-group
              [control]="form.controls.reject_reason"
              [submitted]="submitted"
              >
                <label for="template">
                  สาเหตุ <span class="danger">*</span>
                </label>
                <textarea class="form-control" name="reject_reason" placeholder="สาเหตุ" id="reject_reason" rows="4" formControlName="reject_reason"></textarea>
              </form-group>

            </div>
            <div class="mt-1">
                <form-group
                [control]="form.controls.reject_solution"
                [submitted]="submitted"
                >
                  <label for="template">
                    แนวทางแก้ไข <span class="danger">*</span>
                  </label>
                  <textarea class="form-control" name="reject_solution" placeholder="แนวทางแก้ไข" id="reject_solution" rows="4" formControlName="reject_solution"></textarea>
                </form-group>
  
              </div>
          </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-warning"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
        #closeRejectBtn
      >
        <i class="fa fa-times icon-left"></i> ยกเลิก
      </button>

      <button type="button" class="btn btn-primary" (click)="save()">
        <i class="fa fa-check icon-left"></i>ยืนยัน
      </button>
    </div>
  </div>
</div>
</div>
