import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import moment from "moment";
import { EventEmitter } from "@angular/core";
import { StandardCreate, StandardService } from "src/app/services";

@Component({
  selector: "app-standard-create",
  templateUrl: "./standard-create.component.html",
  styleUrls: ["./standard-create.component.css"],
})
export class StandardCreateComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;

  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") closeModal: ElementRef;
  @Input() id = "modalStandardCreate";

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    public StandardService: StandardService
  ) {
    this.setForm();
  }

  setForm() {
    this.form = this.formBuilder.group({
      tag_no: ["", [Validators.required]],
      description: ["", [Validators.required]],
      manufacturer: ["", [Validators.required]],
      model: ["", [Validators.required]],
      serial_no: ["", [Validators.required]],
      range: ["", [Validators.required]],
      accuracy: ["", [Validators.required]],
      calibration_period: ["", [Validators.required]],
      calibrate_date: [null],
      due_date: [null],
      certificate_no: ["", [Validators.required]],
      calibrated_by: ["", [Validators.required]],
      traceability: ["", [Validators.required]],
      accessory: ["", [Validators.required]],
      cost: [0, [Validators.required]],
      status: ["", [Validators.required]],
      location: ["", [Validators.required]],
      owner: ["", [Validators.required]],
    });
  }

  ngOnInit(): void {}

  async onSave() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let data = this.form.value as StandardCreate;
    data.calibrate_date = data.calibrate_date
      ? moment(data.calibrate_date).startOf("day").toISOString()
      : data.calibrate_date;
    data.due_date = data.due_date
      ? moment(data.due_date).startOf("day").toISOString()
      : data.due_date;
    data.cost = parseFloat(`${data.cost}`);

    let standardResponse = await this.StandardService.create(data);
    if (standardResponse.success) {
      this.save.emit({
        status: standardResponse,
        closeModal: this.closeModal,
      });
    } else {
      this.save.emit({
        status: standardResponse,
        closeModal: this.closeModal,
      });
    }
    this.submitted = false;
    this.setForm();
  }

  onClose() {
    this.setForm();
    this.save.emit(false);
  }
}
