import { Component, OnInit } from '@angular/core';
import { 
  ReportService,
} from '../../../../services';
import { 
  DownloadFileService,
} from '../../../../services/quotations';
@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.css']
})
export class ReportListComponent implements OnInit {
  reports: Array<any>;
  status: string;
  reset: boolean = false;
  isLoading: boolean = true;
  load: Function = this.ReportService.load;
  filter = {};
  reportTypes = [];

  constructor(
    private ReportService: ReportService,
    private DownloadFileService: DownloadFileService,
  ) { }

  ngOnInit(): void {
    this.ReportService.getReportType().then(res=>{
      this.reportTypes = res.resultData.map(item=>{
        return {
          id: item.report_type_id,
          text: item.report_name,
        }
      })
    })
  }

  onChangePage(reports: Array<any>) {
    this.reports = reports;
    this.isLoading = false;
  }

  saveReport(e){
    if(e){
      this.doReset(e.status.success || e.status.error);
      e.closeModal.nativeElement.click();
    }
  }

  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }

  downloadFile(report){
    this.DownloadFileService.downloadReportURL(report);
  }
}
