<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-4 col-12 mb-2">
          <h3 class="content-header-title mb-1">{{next_status=='complete'?'บันทึกผล':next_status=='confirm'?'ยืนยันการให้บริการ': 'แก้ใขใบสั่งงาน'}}</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/work-order']">ใบสั่งงาน </a>
                </li>
                <li class="breadcrumb-item active">{{next_status=='complete'?'บันทึกผล':next_status=='confirm'?'ยืนยันการให้บริการ': 'แก้ใขใบสั่งงาน'}}</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-8 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <a href="#" [routerLink]="['/work-order']" class="btn btn-warning ml-1">
                <i class="fa fa-times icon-left"></i> ยกเลิก
            </a>

            <button *ngIf="(jobOrderInfo?.doc_status_id === 'DRAFT' || jobOrderInfo?.doc_status_id == 'REJECTED')" class="btn btn-primary ml-1" (click)="saveAsDraft()" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึกแบบร่าง
            </button>

            <button class="btn btn-primary ml-1" *ngIf="(jobOrderInfo?.doc_status_id === 'DRAFT' || jobOrderInfo?.doc_status_id == 'REJECTED')" (click)="save()" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึกและส่งตรวจสอบ
            </button>

            <button class="btn btn-danger ml-1" 
            data-target="#reject-calibration-item"
            data-toggle="modal"
            *ngIf="(jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM') && template == 'TRN' && !(userInfo$ | async)?.is_tran_dep" [disabled]="disableSaveBtn">
              <i class="fa fa-times icon-left"></i> ไม่สามารถให้บริการได้
            </button>

            <button class="btn btn-primary ml-1" *ngIf="(jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM' && !(userInfo$ | async)?.is_tran_dep && !(userInfo$ | async)?.is_sales)" (click)="saveAsConfirm(false)" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> ยืนยันการให้บริการโดยไม่ส่งอีเมล
            </button>

            <button class="btn btn-primary ml-1" *ngIf="(jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM' && !(userInfo$ | async)?.is_tran_dep && !(userInfo$ | async)?.is_sales)" (click)="saveAsConfirm(true)" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> ยืนยันการให้บริการ
            </button>

            <button class="btn btn-primary ml-1" *ngIf="(jobOrderInfo?.doc_status_id === 'CONFIRMED') && !(userInfo$ | async)?.is_tran_dep && !(userInfo$ | async)?.is_sales" (click)="saveAsCompleted(false)" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึกโดยไม่ส่งอีเมล
            </button>

            <button class="btn btn-primary ml-1" *ngIf="(jobOrderInfo?.doc_status_id === 'CONFIRMED') && !(userInfo$ | async)?.is_tran_dep && !(userInfo$ | async)?.is_sales" (click)="saveAsCompleted(true)" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึก
            </button>

            <button class="btn btn-primary ml-1" *ngIf="(userInfo$ | async)?.is_tran_dep || (jobOrderInfo?.doc_status_id === 'CONFIRMED' && (userInfo$ | async)?.is_sales)" (click)="editDate()" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึก
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <div class="alert alert-danger" role="alert" *ngIf="submitted && invalid">
          เกิดข้อผิดพลาด! กรุณากรอกข้อมูลให้ครบถ้วน
        </div>
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="pageLoad"></loading>

        <div class="card" [hidden]="pageLoad">
          <div class="card-header">
              <h4 class="card-title d-inline mt-1">{{next_status=='complete'?'บันทึกผล':next_status=='confirm'?'ยืนยันการให้บริการ': 'แก้ใขใบสั่งงาน'}} ({{template}})</h4>
              &nbsp;
              <span [ngClass]="{'badge-info': jobOrderInfo?.doc_status_id === 'DRAFT',
              'badge-warning':jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM',
              'badge-danger':jobOrderInfo?.doc_status_id === 'REJECTED',
              'badge-blue': ['CONFIRMED','WAITING_VERIFY_CRF'].includes(jobOrderInfo?.doc_status_id),
              'badge-success': ['COMPLETED'].includes(jobOrderInfo?.doc_status_id)
              }"
              class="badge">{{
                jobOrderInfo?.doc_status_info?.document_status_name_th
              }}</span>
              <div class="heading-elements">
              </div>
              <div class="float-md-right">
                <button 
                  *ngIf="jobOrderInfo?.job_order_type != 'TRN'"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1"
                  (click)="openPdf(jobOrderInfo)"
                  >
                      <i class="fa fa-print icon-left"></i> {{jobOrderInfo?.job_order_type=='PRD'?'พิมพ์ใบขอซื้อ': 'พิมพ์เอกสาร'}}
                </button>
                <button 
                  *ngIf="jobOrderInfo?.job_order_type != 'TRN' && (jobOrderInfo?.doc_status_id === 'WAITING_VERIFY_CRF' || jobOrderInfo?.doc_status_id === 'PREPARE_FOR_SHIP' || jobOrderInfo?.doc_status_id === 'COMPLETED' || jobOrderInfo?.doc_status_id === 'RETURNED_SUCCESS')"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1"
                  >
                      <i class="fa fa-print icon-left"></i> พิมพ์เอกสารรายงาน
                </button>
                <!-- <button 
                  *ngIf="jobOrderInfo?.job_order_type != 'PRD' && jobOrderInfo?.job_order_type != 'TRN'"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1"
                  (click)="openServiceReportPdf(jobOrderInfo)"
                  >
                    <i class="fa fa-print icon-left"></i> พิมพ์ Service report
                </button> -->
              </div>
          </div>

          <div class="card-content collapse show">
              <form autocomplete="off" class="form" [formGroup]="form">
                  <div class="card-body">
                    <div class="form-body">
                      <h4 class="badge btn-blue badge-customer-lvl" *ngIf="customer_info">
                        {{ customer_info.customer_level_id | dash }}
                      </h4>
                      <div class="row">
                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.customer_id"
                            [submitted]="submitted"
                            >
                              <label for="customer_id">
                                ชื่อลูกค้า
                              </label>
                              <input
                                type="text"
                                id="customer_name"
                                class="form-control"
                                placeholder="ชื่อลูกค้า"
                                formControlName="customer_name"
                                readonly
                              />
                          </form-group>
                      </div>


                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.customer_address_id"
                          [submitted]="submitted"
                          >
                            <label for="customer_address_id">
                              ออกเอกสารในนาม <span class="danger"  *ngIf="form.controls['customer_address_id'].enabled">*</span>
                            </label>
                            <ng-select2
                            [options]="
                            {
                              templateResult: NgSelect2Customer?.templateAddressResult,
                              language: UtilsService.select2OptionsLanguage()
                            }"
                              [data]="customerAddressList"
                              class="w-100"
                              id="customer_address_id"
                              placeholder="ออกเอกสารในนาม"
                              formControlName="customer_address_id"
                              (valueChanged)="selectAddressName($event)"
                            >
                            </ng-select2>
                        </form-group>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_date"
                          [submitted]="submitted">
                          <label for="doc_date">
                              วันที่ออก <span class="danger" *ngIf="form.controls['doc_date'].enabled">*</span>
                          </label>
                          <div class="input-group mt-0">
                              <input
                                date-picker
                                type="text"
                                id="doc_date"
                                placeholder="วันที่ออก"
                                [max-date]="undefined"
                                class="form-control"
                                *ngIf="form.getRawValue().doc_date"
                                [date]="form.value.doc_date"
                                formControlName="doc_date"
                                />
                              <div
                              class="input-group-append"
                              for="doc_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                      </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_no"
                          [submitted]="submitted">
                          <label for="doc_no">
                              เลขที่เอกสาร
                          </label>
                          <input
                              type="text"
                              id="doc_no"
                              class="form-control"
                              placeholder="เลขที่เอกสาร"
                              formControlName="doc_no"
                              readonly
                          />
                          </form-group>
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <p class="font-weight-bold"> ที่อยู่ในการติดต่อลูกค้า</p>
                          <form-group
                              [control]="form.controls.customer_address"
                              [submitted]="submitted"
                              >
                              <label for="customer_address">
                                  ที่อยู่
                              </label>
                              <input
                                  type="text"
                                  id="customer_address"
                                  class="form-control"
                                  placeholder="ที่อยู่"
                                  formControlName="customer_address"
                                  readonly
                              />
                            </form-group>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <p>
                            <br>
                          </p>
                          <form-group
                            [control]="form.controls.customer_contact_ids"
                            [submitted]="submitted">
                              <label for="customer_contact_ids">
                                ผู้ติดต่อ <span class="danger" *ngIf="form.controls['customer_contact_ids'].enabled">*</span>
                              </label>
                              <ng-select2
                                  [data]="customerContactList"
                                  [options]="{
                                    templateResult: NgSelect2Customer?.templateContactResult,
                                    multiple: true,
                                    maximumSelectionLength: 1,
                                    language: UtilsService.select2OptionsLanguage()
                                  }"
                                  class="w-100"
                                  id="customer_contact_ids"
                                  placeholder="ผู้ติดต่อ"
                                  formControlName="customer_contact_ids"
                                  >
                              </ng-select2>
                          </form-group>

                        </div>

                      </div>
                      
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <p class="font-weight-bold"> ที่อยู่ในการ เก็บค่าบริการ</p>
                          <form-group
                            [control]="form.controls.charge_address_id"
                            [submitted]="submitted"
                              >
                                <label for="charge_address_id">
                                  ที่อยู่ <span class="danger" *ngIf="form.controls['charge_address_id'].enabled">*</span>
                                </label>
                                <ng-select2
                                [options]="
                                {
                                  templateResult: NgSelect2Customer?.templateAddressResult,
                                  language: UtilsService.select2OptionsLanguage()
                                }"
                                  [data]="chargeReportAddressList"
                                  class="w-100"
                                  id="charge_address_id"
                                  placeholder="ที่อยู่"
                                  formControlName="charge_address_id"
                                >
                                </ng-select2>
                            </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold">
                            <br>  
                          </p>
                          <form-group
                          [control]="form.controls.credit_term_day"
                          [submitted]="submitted"
                            >
                              <label for="credit_term_day">
                                เครดิต (วัน)
                              </label> 
                              <input type="text"    
                              id="credit_term_day"
                              class="form-control"
                              placeholder="เครดิต (วัน)" 
                              formControlName="credit_term_day"
                              readonly>
                          </form-group>

                        </div>

                      </div>
                      
                      <div  class="dropdown-divider mb-2">
                      </div>
                      
                      <div class="row">
                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"> {{template=='TRN'?'ข้อมูลผู้เข้าอบรม':'ข้อมูลรายการสินค้า'}}</p>
                          <form-group
                          [control]="form.controls.pickup_date"
                          [submitted]="submitted">
                          <label for="pickup_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'TRN'">วันที่ให้บริการ</ng-container>
                              <ng-container *ngSwitchCase="'PRD'">วันสั่งซื้อ</ng-container>
                            </ng-container>
                            <span class="danger" *ngIf="form.controls['pickup_date'].enabled"> *</span>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              id="pickup_date"
                              placeholder="วันสั่งซื้อ"
                              [max-date]="undefined"
                              class="form-control"
                              *ngIf="form.getRawValue().pickup_date"
                              [date]="form.value.pickup_date"
                              formControlName="pickup_date"
                              />
                              <div
                              class="input-group-append"
                              for="pickup_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                        </form-group>

                        </div>

                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                          [control]="form.controls.return_date"
                          [submitted]="submitted">
                          <label for="return_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'TRN'">วันที่สิ้นสุดให้บริการ</ng-container>
                              <ng-container *ngSwitchCase="'PRD'">วันที่สิ้นสุด</ng-container>
                            </ng-container>
                            <span class="danger" *ngIf="form.controls['return_date'].enabled"> *</span>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              *ngIf="form.getRawValue().return_date && resetdate"
                              date-picker
                              type="text"
                              id="return_date"
                              placeholder="วันที่สิ้นสุด"
                              [max-date]="maxReturnDate"
                              class="form-control"
                              [date]="form.getRawValue().return_date"
                              formControlName="return_date"
                              />
                              <div
                              class="input-group-append"
                              for="pickup_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                        </div>

                        <!-- <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                          [control]="form.controls.real_return_date"
                          [submitted]="submitted">
                          <label for="real_return_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'TRN'">วันที่เสร็จสิ้นจริง</ng-container>
                              <ng-container *ngSwitchCase="'PRD'">วันที่เสร็จสิ้นจริง</ng-container>
                            </ng-container>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              *ngIf="resetdate"
                              date-picker
                              type="text"
                              id="real_return_date"
                              placeholder="วันที่เสร็จสิ้นจริง"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.real_return_date"
                              formControlName="real_return_date"
                              />
                              <div
                              class="input-group-append"
                              for="real_return_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                        </div> -->
                      </div>

                      <div class="row" *ngIf="template=='TRN'">
                        <div class="col-lg-3 col-md-12">
                          <form-group
                          [control]="form.controls.no_of_people"
                          [submitted]="submitted">
                          <label for="doc_date">
                              จำนวนคน <span class="danger" *ngIf="form.controls['no_of_people'].enabled">*</span>
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="จำนวนคน"
                            formControlName="no_of_people"
                            id="no_of_people"
                            />
                          </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12">
                          <form-group
                          [control]="form.controls.no_of_day"
                          [submitted]="submitted">
                          <label for="doc_date">
                              จำนวนวัน <span class="danger" *ngIf="form.controls['no_of_day'].enabled">*</span>
                          </label>
                          <input
                          type="text"
                          class="form-control"
                          placeholder="จำนวนวัน"
                          formControlName="no_of_day"
                          id="no_of_day"
                            />
                          </form-group>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <form-group
                            [control]="form.controls.location"
                            [submitted]="submitted">
                            <label for="doc_date">
                                สถานที่ <span class="danger" *ngIf="form.controls['location'].enabled">*</span>
                            </label>
                            <ng-select2
                            [options]="
                            {
                              templateResult: NgSelect2Customer?.templateAddressResult,
                              language: UtilsService.select2OptionsLanguage()
                            }"
                              [data]="addressList"
                              class="w-100"
                              id="location"
                              placeholder="สถานที่"
                              formControlName="location"
                            >
                            </ng-select2>
                            </form-group>
                        </div>
                      </div>
                    </div>

                    <div class="my-1">
                      <app-work-order-non-lab-item 
                        [jobOrderInfo]="jobOrderInfo"
                        [canConfirm]="canSaveConfirm"
                        [canCompleted]="canSaveCompleted"
                        [submitted]="submitted"
                        *ngIf="customer_info && reset"
                        [customer_info]="customer_info"
                        (done)="getJobOrderItems($event)">
                      </app-work-order-non-lab-item>

                      <div class="text-danger" *ngIf="(jobOrderProductItem?.jobOrderItems?.length == 0) && submitted">
                        <span class="pl-1">
                          โปรดระบุอย่างน้อย 1 รายการ
                        </span>
                      </div>
                    </div>

                    <div class="col-8 p-0 my-1" 
                      *ngIf="!(userInfo$ | async)?.is_tran_dep && template=='TRN' && (jobOrderInfo?.doc_status_id !== 'DRAFT')">
                      <app-training-engineer 
                      *ngIf="jobOrderInfo"
                      [submitted]="submitted"
                      [canSaveTraining]="canSaveTraining"
                      [canCompleted]="canSaveCompleted"
                      [jobOrderInfo]="jobOrderInfo"
                      (done)="getTrainings($event)">
                      </app-training-engineer>
                    </div>

                    <div class="col-8 p-0 my-1" *ngIf="!(userInfo$ | async)?.is_tran_dep && template=='TRN' && (jobOrderInfo?.doc_status_id != 'DRAFT')">
                      <app-car-info                
                      [submitted]="submitted"
                      [jobOrderInfo]="jobOrderInfo"
                      (done)="getCarInfos($event)">
                      </app-car-info>
                    </div>
                    
                  </div>
              </form>

              <form autocomplete="off" class="form" [formGroup]="extraForm">
                <div class="card-body pt-0">
                  <div class="form-body">
                    <div class="row">
                      <div class="col-lg-6 col-md-12">
                        <form-group
                        [control]="extraForm.controls.note"
                        [submitted]="submitted">
                        <label for="doc_date">
                            หมายเหตุ
                        </label>
                        <textarea
                          placeholder="หมายเหตุ"
                          class="form-control"
                          rows="3"
                          id="note"
                          formControlName="note"
                          >
                        </textarea>
                        </form-group>
                      </div>
  
                      <div class="col-lg-6 col-md-12">

                        <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                          <div class="btn-group mr-1" role="group" aria-label="First group">
                            <label for="file">แนบไฟล์ <span class="danger" *ngIf="(jobOrderInfo?.doc_status_id === 'CONFIRMED' && template =='PRD')">*</span></label>
                          </div>
                          <button 
                  
                            class="btn btn-outline-primary mb-1" 
                            type="button"
                            (click)="uploadFile()">
                              <i class="feather icon-upload icon-left"></i>
                              Upload
                          </button>
                          <input type="file" style="display:none;" multiple id="importFile"
                          (change)="importFile($event.target.files)">
                        </div>
                        <small>* จำกัดขนาดไฟล์ {{UtilsService.config.limitFile}} mb</small> 
                        <div class="mt-1">
                          <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                            <span style="cursor: pointer;" (click)="downloadFile(file)">
                              {{file.job_order_file_id? file.file_name: file.name}}
                            </span>
                            <span aria-hidden="true" style="cursor: pointer;" 
                            data-target="#modalConfirmDelFile"
                            data-toggle="modal"
                            (click)="removeFile(file, i)">&times;</span>
                          </span>
                        </div>
                        <span class="pl-1 text-danger w-100" *ngIf="(submitted && files.length == 0 && template =='PRD' && jobOrderInfo?.doc_status_id === 'CONFIRMED')">
                          เลือกไฟล์อย่างน้อย 1 รายการ
                        </span>
                        <span class="pl-1 text-danger w-100" *ngIf="submitted && checkFileInvalid">
                          ขนาดไฟล์เกินกว่าที่กำหนด
                        </span>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-6 col-md-12">
                        <form-group
                        [control]="form.controls.special_condition"
                        [submitted]="submitted">
                        <label for="special_condition">
                            Special Condition
                        </label>
                        <textarea
                          placeholder="Special Condition"
                          class="form-control"
                          rows="3"
                          id="special_condition"
                          formControlName="special_condition"
                          >
                        </textarea>
                        </form-group>
                      </div>
                    </div>
  
                    <div class="row">
                      <div class="col-lg-6 col-md-12">
  
                        <form-group
                        [control]="extraForm.controls.accessory"
                        [submitted]="submitted">
                        <label for="doc_date">
                          Accessory
                        </label>
                        <textarea
                          placeholder="Accessory"
                          class="form-control"
                          id="accessory"
                          rows="3"
                          formControlName="accessory"
                          >
                        </textarea>
                        </form-group>
                      </div>
  
                    </div>
                  </div>
                </div>
              </form>
          </div>
      </div>
      </div>

      <app-work-order-memo *ngIf="!pageLoad">
      </app-work-order-memo>
    </div>
</div>

<app-confirm-delete [id]="'modalConfirmDelFile'" (save)="deleteFile($event)"></app-confirm-delete>

<app-reject-calibration-item
*ngIf="jobOrderInfo"
[jobOrderInfo]="jobOrderInfo"
(done)="saveReject($event);">
</app-reject-calibration-item>