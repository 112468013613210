import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";

declare const $;
@Directive({
  selector: "[tag-input]",
})
export class TagInputDirective {
  @Output() input = new EventEmitter();
  @Input() data = [];
  @Output() instance = new EventEmitter();
  constructor(el: ElementRef) {
    setTimeout(() => {
      $(el.nativeElement).tagsinput();
      $(el.nativeElement)
        .on("itemAdded", (e) => {
          this.input.emit({
            target: {
              value: $(el.nativeElement).val(),
            },
          });
        })
        .on("itemRemoved", () => {
          this.input.emit({
            target: {
              value: $(el.nativeElement).val(),
            },
          });
        });

      for (let tag of this.data) {
        $(el.nativeElement).tagsinput("add", tag);
      }
      this.instance.emit($(el.nativeElement));
    });
  }
}
