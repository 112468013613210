<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">ดูใบสั่งงาน</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/work-order']">ใบสั่งงาน </a>
                </li>
                <li class="breadcrumb-item active">ดูใบสั่งงาน</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button
            (click)="back()"
            type="button"
            class="btn btn-light btn-min-width ml-1"
            >
            <i class="fa fa-arrow-left"></i>  กลับ
            </button>
            <button 
              *ngIf="(isSalesManager || isSalesCoordinator || isSales) && (jobOrderInfo?.doc_status_id === 'DRAFT' || jobOrderInfo?.doc_status_id === 'REJECTED') && !isAccDep"
              (click)="cancel(jobOrderInfo)"
              type="button"
              class="btn btn-danger btn-min-width ml-1"
              >
              <i class="fa fa-times icon-left"></i>  ยกเลิก
            </button>
            <button 
              *ngIf="((isSalesManager || isSalesCoordinator || isSales) && (jobOrderInfo?.doc_status_id === 'DRAFT' || jobOrderInfo?.doc_status_id === 'REJECTED' || (jobOrderInfo?.doc_status_id === 'CONFIRMED')) || isTranDep) && !isAccDep"
              (click)="edit(jobOrderInfo)"
              type="button"
              class="btn btn-warning btn-min-width ml-1"
              >
              <i class="fa fa-pencil"></i>  แก้ไข
            </button>
            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'WAITING_FOR_CONFIRM' && ((isCalibration && ['ICL','ICS','TRN'].includes(jobOrderInfo?.job_order_type)) || (isPuchase && ['PRD','ECL','ECS'].includes(jobOrderInfo?.job_order_type))) && !isAccDep"
              type="button" class="btn btn-info btn-min-width ml-1" 
              (click)="confirmJoborder(jobOrderInfo, 'confirm')">
              <i class="fa fa-pencil"></i> ตรวจสอบรายการ
            </button>

            <button 
              *ngIf="isCalibration && (jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM')"
              type="button"
              (click)="cancel(jobOrderInfo)"
              class="btn btn-danger btn-min-width ml-1"
              >
              <i class="fa fa-times icon-left"></i>  ยกเลิก
            </button>

            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'CONFIRMED' && isPuchase && ['PRD'].includes(jobOrderInfo?.job_order_type) && !isAccDep"
              type="button" class="btn btn-info btn-min-width ml-1" (click)="editCert(jobOrderInfo, 'complete')">
            <i class="fa fa-file-o icon-left"></i> บันทึกการดำเนินการ
            </button>

            <button 
              *ngIf="jobOrderInfo?.doc_status_id == 'PREPARE_FOR_SHIP' && ['PRD'].includes(jobOrderInfo?.job_order_type) && isTranDep"
              data-toggle="modal"
              data-target="#work-order-return"
              type="button" class="btn btn-info btn-min-width ml-1">
            <i class="fa fa-file-o icon-left"></i> บันทึกส่งงาน
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="pageLoad"></loading>

        <div class="card" [hidden]="pageLoad">
          <div class="card-header">
              <h4 class="card-title d-inline mr-50">ดูใบสั่งงาน ({{template}})</h4>
              &nbsp;
              <span [ngClass]="{'badge-info': jobOrderInfo?.doc_status_id === 'DRAFT',
              'badge-warning':jobOrderInfo?.doc_status_id === 'WAITING_FOR_CONFIRM',
              'badge-danger':jobOrderInfo?.doc_status_id === 'REJECTED',
              'badge-blue': ['CONFIRMED','WAITING_VERIFY_CRF'].includes(jobOrderInfo?.doc_status_id),
              'badge-success': ['COMPLETED'].includes(jobOrderInfo?.doc_status_id)
              }"
              class="badge">{{
                jobOrderInfo?.doc_status_info?.document_status_name_th
              }}</span>
              <div class="heading-elements">
              </div>
              <div class="float-md-right" *ngIf="!isAccDep">
                <button 
                  *ngIf="jobOrderInfo?.job_order_type != 'TRN'"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1"
                  (click)="openPdf(jobOrderInfo)"
                  >
                  <i class="fa fa-print icon-left"></i> {{jobOrderInfo?.job_order_type=='PRD'?'พิมพ์ใบขอซื้อ': 'พิมพ์เอกสาร'}}
                </button>
                <button 
                  *ngIf="jobOrderInfo?.job_order_type != 'TRN' && (jobOrderInfo?.doc_status_id === 'WAITING_VERIFY_CRF' || jobOrderInfo?.doc_status_id === 'PREPARE_FOR_SHIP' || jobOrderInfo?.doc_status_id === 'COMPLETED' || jobOrderInfo?.doc_status_id === 'RETURNED_SUCCESS')"
                  type="button"
                  (click)="openProductPdf(jobOrderInfo)"
                  class="btn btn-blue btn-min-width ml-1 mb-1"
                  >
                      <i class="fa fa-print icon-left"></i> พิมพ์เอกสารรายงาน
                </button>

              </div>
          </div>

          <div class="card-content collapse show">
              <form autocomplete="off" class="form" [formGroup]="form">
                  <div class="card-body">
                    <div class="form-body">
                      <h4 class="badge btn-blue badge-customer-lvl" *ngIf="customer_level_id">
                        {{ customer_level_id | dash }}
                      </h4>
                      <div class="row">
                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.customer_id"
                            [submitted]="submitted"
                            >
                              <label for="customer_id">
                                ชื่อลูกค้า
                              </label>
                              <input
                                type="text"
                                id="customer_name"
                                class="form-control"
                                placeholder="ชื่อลูกค้า"
                                formControlName="customer_name"
                                readonly
                              />
                          </form-group>
                      </div>


                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.customer_address_id"
                          [submitted]="submitted"
                          >
                            <label for="customer_address_id">
                              ออกเอกสารในนาม 
                            </label>
                            <ng-select2
                              [data]="customerAddressList"
                              class="w-100"
                              id="customer_address_id"
                              placeholder="ออกเอกสารในนาม"
                              formControlName="customer_address_id"
                            >
                            </ng-select2>
                            <small>mit_iplus_code: {{form.value.mit_iplus_code | dash}}</small>
                        </form-group>
                        </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_date"
                          [submitted]="submitted">
                          <label for="doc_date">
                              วันที่ออก 
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              id="doc_date"
                              *ngIf="form.value.doc_date"
                              placeholder="วันที่ออก"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.doc_date"
                              formControlName="doc_date"
                              />
                              <div
                              class="input-group-append"
                              for="doc_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                      </div>

                        <div class="col-xl-3 col-lg-6 col-md-12">
                          <form-group
                          [control]="form.controls.doc_no"
                          [submitted]="submitted">
                          <label for="doc_no">
                              เลขที่เอกสาร
                          </label>
                          <input
                              type="text"
                              id="doc_no"
                              class="form-control"
                              placeholder="เลขที่เอกสาร"
                              formControlName="doc_no"
                              readonly
                          />
                          </form-group>
                        </div>
                      </div>
                      
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <p class="font-weight-bold"> ที่อยู่ในการติดต่อลูกค้า</p>
                          <form-group
                              [control]="form.controls.customer_address"
                              [submitted]="submitted"
                              >
                              <label for="customer_address">
                                  ที่อยู่
                              </label>
                              <input
                                  type="text"
                                  id="customer_address"
                                  class="form-control"
                                  placeholder="ที่อยู่"
                                  formControlName="customer_address"
                                  readonly
                              />
                            </form-group>
                        </div>

                        <div class="col-lg-6 col-md-12">
                          <p>
                            <br>
                          </p>
                          <form-group
                            [control]="form.controls.customer_contact_ids"
                            [submitted]="submitted">
                              <label for="customer_contact_ids">
                                ผู้ติดต่อ 
                              </label>
                              <ng-select2
                                  [data]="customerContactList"
                                  [options]="{
                                    templateSelection: _ngSelect2Customer?.templateSelection,
                                    multiple: true,
                                    maximumSelectionLength: 1
                                  }"
                                  class="w-100"
                                  id="customer_contact_ids"
                                  placeholder="ผู้ติดต่อ"
                                  formControlName="customer_contact_ids"
                                  >
                              </ng-select2>
                          </form-group>

                        </div>

                      </div>
                      
                      <div class="row">
                        <div class="col-lg-6 col-md-12">
                          <p class="font-weight-bold"> ที่อยู่ในการ เก็บค่าบริการ</p>
                          <form-group
                            [control]="form.controls.charge_address_id"
                            [submitted]="submitted"
                              >
                                <label for="charge_address_id">
                                  ที่อยู่ 
                                </label>
                                <ng-select2
                                  [data]="chargeReportAddressList"
                                  class="w-100"
                                  id="charge_address_id"
                                  placeholder="ที่อยู่"
                                  formControlName="charge_address_id"
                                >
                                </ng-select2>
                            </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold">
                            <br>  
                          </p>
                          <form-group
                          [control]="form.controls.credit_term_day"
                          [submitted]="submitted"
                            >
                              <label for="credit_term_day">
                                เครดิต (วัน)
                              </label> 
                              <input type="text"    
                              id="credit_term_day"
                              class="form-control"
                              placeholder="เครดิต (วัน)" 
                              formControlName="credit_term_day"
                              readonly>
                          </form-group>

                        </div>

                      </div>
                      
                      <div  class="dropdown-divider mb-2">
                      </div>
                      
                      <div class="row">
                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"> {{template=='TRN'?'ข้อมูลผู้เข้าอบรม':'ข้อมูลรายการสินค้า'}}</p>
                          <form-group
                          [control]="form.controls.pickup_date"
                          [submitted]="submitted">
                          <label for="pickup_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'TRN'">วันที่ให้บริการ</ng-container>
                              <ng-container *ngSwitchCase="'PRD'">วันสั่งซื้อ</ng-container>
                            </ng-container>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              id="pickup_date"
                              placeholder="วันสั่งซื้อ"
                              [max-date]="undefined"
                              class="form-control"
                              *ngIf="form.value.pickup_date"
                              [date]="form.value.pickup_date"
                              formControlName="pickup_date"
                              />
                              <div
                              class="input-group-append"
                              for="pickup_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                        </form-group>

                        </div>

                        <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                          [control]="form.controls.return_date"
                          [submitted]="submitted">
                          <label for="return_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'TRN'">วันที่สิ้นสุดให้บริการ</ng-container>
                              <ng-container *ngSwitchCase="'PRD'">วันที่สิ้นสุด</ng-container>
                            </ng-container>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              date-picker
                              type="text"
                              id="return_date"
                              placeholder="วันที่สิ้นสุด"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.return_date"
                              *ngIf="form.value.return_date"
                              formControlName="return_date"
                              />
                              <div
                              class="input-group-append"
                              for="pickup_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                        </div>

                        <!-- <div class="col-lg-3 col-md-12">
                          <p class="font-weight-bold"><br></p>
                          <form-group
                          [control]="form.controls.real_return_date"
                          [submitted]="submitted">
                          <label for="real_return_date">
                            <ng-container [ngSwitch]="template">
                              <ng-container *ngSwitchCase="'TRN'">วันที่เสร็จสิ้นจริง</ng-container>
                              <ng-container *ngSwitchCase="'PRD'">วันที่เสร็จสิ้นจริง</ng-container>
                            </ng-container>
                          </label>
                          <div class="input-group mt-0">
                              <input
                              *ngIf="resetdate"
                              date-picker
                              type="text"
                              id="real_return_date"
                              placeholder="วันที่เสร็จสิ้นจริง"
                              [max-date]="undefined"
                              class="form-control"
                              [date]="form.value.real_return_date"
                              formControlName="real_return_date"
                              />
                              <div
                              class="input-group-append"
                              for="real_return_date"
                              >
                              <span class="input-group-text"
                                  ><i class="feather icon-calendar"></i
                              ></span>
                              </div>
                          </div>
                          </form-group>
                        </div> -->
                      </div>

                      <div class="row" *ngIf="template=='TRN'">
                        <div class="col-lg-3 col-md-12">
                          <form-group
                          [control]="form.controls.no_of_people"
                          [submitted]="submitted">
                          <label for="doc_date">
                              จำนวนคน 
                          </label>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="จำนวนคน"
                            formControlName="no_of_people"
                            id="no_of_people"
                            />
                          </form-group>
                        </div>

                        <div class="col-lg-3 col-md-12">
                          <form-group
                          [control]="form.controls.no_of_day"
                          [submitted]="submitted">
                          <label for="doc_date">
                              จำนวนวัน 
                          </label>
                          <input
                          type="text"
                          class="form-control"
                          placeholder="จำนวนคน"
                          formControlName="no_of_day"
                          id="no_of_day"
                            />
                          </form-group>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <form-group
                            [control]="form.controls.location"
                            [submitted]="submitted">
                            <label for="doc_date">
                                สถานที่ 
                            </label>
                              <ng-select2
                                [data]="addressList"
                                class="w-100"
                                id="location"
                                placeholder="สถานที่"
                                formControlName="location"
                              >
                              </ng-select2>
                            </form-group>
                        </div>
                      </div>
                    </div>

                    <div class="my-1">
                      <app-work-order-non-lab-item [jobOrderInfo]="jobOrderInfo" *ngIf="jobOrderInfo" (done)="getJobOrderItems($event)">
                      </app-work-order-non-lab-item>
                    </div>

                    <div class="col-8 p-0 my-1" *ngIf="template=='TRN' && (jobOrderInfo?.doc_status_id !== 'DRAFT')">
                      <app-training-engineer >
                      </app-training-engineer>
                    </div>

                    <div class="col-8 p-0 my-1" *ngIf="template=='TRN' && (jobOrderInfo?.doc_status_id != 'DRAFT')">
                      <app-car-info>
                      </app-car-info>
                    </div>
                    
                    <div class="form-body">
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <form-group
                            [control]="form.controls.note"
                            [submitted]="submitted">
                            <label for="doc_date">
                                หมายเหตุ
                            </label>
                            <textarea
                              placeholder="หมายเหตุ"
                              class="form-control"
                              rows="3"
                              id="note"
                              formControlName="note"
                              >
                            </textarea>
                            </form-group>
                          </div>
  
                          <div class="col-lg-6 col-md-12">
                            <label for="file">แนบไฟล์</label>
                            <div class="mt-1 mb-1">
                              <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                                  <span style="cursor: pointer;" (click)="downloadFile(file)">
                                      {{file.file_name}}
                                  </span>
                              </span> 
                              <span *ngIf="files.length == 0" class="text-dark">
                                  ไม่มีไฟล์
                              </span> 
                            </div>
                          </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <form-group
                            [control]="form.controls.special_condition"
                            [submitted]="submitted">
                            <label for="special_condition">
                                Special Condition
                            </label>
                            <textarea
                              placeholder="Special Condition"
                              class="form-control"
                              rows="3"
                              id="special_condition"
                              formControlName="special_condition"
                              >
                            </textarea>
                            </form-group>
                          </div>
                        </div>
  
                        <div class="row">
                          <div class="col-lg-6 col-md-12">
  
                            <form-group
                            [control]="form.controls.accessory"
                            [submitted]="submitted">
                            <label for="doc_date">
                              Accessory
                            </label>
                            <textarea
                              placeholder="Accessory"
                              class="form-control"
                              id="accessory"
                              rows="3"
                              formControlName="accessory"
                              >
                            </textarea>
                            </form-group>
                          </div>
  
                        </div>
                      </div>
                  </div>
              </form>
          </div>
      </div>
      </div>

      <app-work-order-memo *ngIf="!pageLoad">
      </app-work-order-memo>
    </div>
</div>

<app-work-order-return (done)="saveAsReturn($event)">
</app-work-order-return>