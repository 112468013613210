export function docValiddateAfterOrEqualValidator(formGroup): any {
    var docDate, docValidDate;
    for (var controlName in formGroup.controls) {
        if (controlName.indexOf("doc_date") !== -1) {
            docDate = Date.parse(formGroup.controls[controlName].value);
        }
        if (controlName.indexOf("doc_validdate") !== -1) {
            docValidDate = Date.parse(formGroup.controls[controlName].value);
        }
    }
    return (docValidDate < docDate) ? { docValiddateLessThanDocDate: true } : null;
}