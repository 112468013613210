<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">รายการไฟล์แนบ</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/email-template-attachment']"
                  >ตั้งค่าระบบ</a
                >
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/email-template-attachment']"
                  >จัดการไฟล์แนบ</a
                >
              </li>
              <li class="breadcrumb-item active">รายการไฟล์แนบ</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button class="btn btn-outline-primary" (click)="create()">
            <i class="feather icon-plus-circle icon-left"></i>
            เพิ่มไฟล์แนบ
          </button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">รายการไฟล์แนบ</h4>
              <!-- <div class="heading-elements">
                <search-box (search)="search($event)"></search-box>
              </div> -->
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="5%">#</th>
                        <th>รายละเอียด</th>
                        <th width="10%">ไฟล์แนบ</th>
                        <th width="20%">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let attachment of attachments">
                        <td class="text-nowrap">
                          {{ attachment.order }}
                        </td>
                        <td>
                          {{ attachment.description }}
                        </td>
                        <td>
                          <a target="_blank" href="{{ attachment.asset_path }}"
                            ><span class="badge badge-secondary">{{
                              attachment.file_name
                            }}</span></a
                          >
                        </td>
                        <td>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 btn-primary btn-sm"
                            (click)="edit(attachment)"
                          >
                            <i class="fa fa-edit icon-left"></i> แก้ไขข้อมูล
                          </button>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 bg-danger bg-darken-1 text-white btn-sm"
                            data-target="#modalConfirmDelMsg"
                            data-toggle="modal"
                            (click)="
                              select(attachment.email_template_attachment_id)
                            "
                            [disabled]=""
                          >
                            <i class="fa fa-trash icon-left"></i> ลบ
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="!attachments.length">
                        <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<app-email-template-attachment-create
  (save)="saveEmailTemplateAttachment($event)"
  (close)="close()"
  *ngIf="createEmailTemplateAttachment"
></app-email-template-attachment-create>

<app-email-template-attachment-edit
  [emailTemplateAttachmentData]="emailTemplateAttachmentData"
  (save)="saveEmailTemplateAttachment($event)"
  (close)="close()"
  *ngIf="emailTemplateAttachmentData"
>
</app-email-template-attachment-edit>

<app-confirm-delete (save)="delete($event)"></app-confirm-delete>
