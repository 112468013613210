import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: "root",
})
export class NgSelect2Quotation {
    settingLanguage = {
        errorLoading: function () {
            return "ไม่สามารถค้นข้อมูลได้";
        },
        loadingMore: function () {
            return "กำลังค้นข้อมูลเพิ่ม…";
        },
        maximumSelected: function (n) {
            return "คุณสามารถเลือกได้ไม่เกิน " + n.maximum + " รายการ";
        },
        noResults: function () {
            return "ไม่พบข้อมูล";
        },
        searching: function () {
            return "กำลังค้นข้อมูล…";
        },
    };

    select2Options(filter: any) {
        return {
            language: this.settingLanguage,
            ajax: {
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("token"),
                    "Content-Type": "application/json",
                },
                delay: 500,
                url: environment.apiUrl + "/quotation-info" + filter,
                dataType: "json",
                data: (params) => {
                    var query = {
                        search_all: params.term
                    };
                    return query;
                },
                processResults: function (data) {
                    let items = data.resultData.map((elem) => {
                        return {
                            id: elem.quotation_info_id,
                            text:  `[${elem?.doc_no || ''}] (${elem?.customer_info.customer_code || ''}) ${elem?.customer_info.company_name || ''}`
                        }
                    });
                    return {
                        results: items,
                    };
                },
            },
        };
    }
}
