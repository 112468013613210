import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { UtilsService } from 'src/app/services';
import { 
  NgSelect2Customer,
  QuotationMitItemService
} from 'src/app/services/quotations';

@Component({
  selector: 'app-work-order-lab-item-create',
  templateUrl: './work-order-lab-item-create.component.html',
  styleUrls: ['./work-order-lab-item-create.component.css']
})
export class WorkOrderLabItemCreateComponent implements OnInit {
  form: FormGroup;
  pageLoad: boolean = false;
  submitted: boolean = false;
  isShowCheckAll: boolean = false;
  template: string = "";

  allItems = [];
  quotationList = [];
  transportList = [];
  
  jobOrderItems: any = [];

  number_of_select: number = 0;
  
  @Output() done = new EventEmitter<any>();
  @Input('jobOrderItems') selectedJobOrderItems;
  @Input('customer_info') customer_info;
  @Input('popupType') popupType: string;
  @Input('itemStatus') itemStatus;

  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  status: string;
  reset: boolean = false;
  load: Function = null;
  filter = {};

  select2OptionQuotation = null;
  select2OptionTransport = null;

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private NgSelect2Customer: NgSelect2Customer,
    private QuotationMitItemService: QuotationMitItemService,

    public UtilsService: UtilsService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
    });
    this.router.events.subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }
  
  setForm(){
    this.form = this.formBuilder.group({
      quotation_info_id: ["", Validators.required],
      transport_receive_info_id: ["", Validators.required],
      job_order_item: this.formBuilder.array([])
    });

    this.jobOrderItems = this.form.get('job_order_item') as FormArray;
  }

  createJobOrderItem(data:any = {}): FormGroup {

    return this.formBuilder.group({
      select:[false],
      quotation_info_id: [data.quotation_info_id || ""],
      quotation_mit_item_id: [data.quotation_mit_item_id || ""],
      item_status_id: ["CRF-WAITING_FOR_CONFIRM"],
      note: [""],
      quotation_mit_item_info: [data],
      quotation_info: [data.quotation_info]
    });
  }

  createTranJobOrderItem(data:any = {}): FormGroup {
    return this.formBuilder.group({
      select:[false],
      quotation_info_id: [data.quotation_info_id || data.noqt_quotation_info_id],
      quotation_mit_item_id: [data.quotation_mit_item_id || ""],
      item_status_id: ["CRF-WAITING_FOR_CONFIRM"],
      note: [""],
      quotation_mit_item_info: [data.quotation_mit_item_info],
      quotation_info: [data.quotation_info || data.noqt_quotation_info],
      transport_receive_info: [data.transport_receive_info],
    });
  }

  get mitFormGroup() {
    return this.form.get('job_order_item') as FormArray;
  }

  ngOnInit() {}
  
  async ngOnChanges(changes: SimpleChanges) {
    if(this.popupType == 'transport'){
      this.select2OptionTransport = this.NgSelect2Customer.selectTransports(`?limit=10&&customer_id=${this.customer_info.customer_id}&&doc_status_id=RECEIVED_SUCCESS&&order_by=doc_no:desc`);

      this.form.controls['quotation_info_id'].setValidators(null);
      this.form.controls['transport_receive_info_id'].setValidators([Validators.required]);
    } else {
      this.select2OptionQuotation = this.NgSelect2Customer.selectQuotations(`?limit=10&&customer_id=${this.customer_info.customer_id}&&doc_status_id=ACCEPTED&&order_by=doc_no:desc`);

      this.form.controls['transport_receive_info_id'].setValidators(null);
      this.form.controls['quotation_info_id'].setValidators([Validators.required]);
    }
    this.form.controls['quotation_info_id'].updateValueAndValidity();
    this.form.controls['transport_receive_info_id'].updateValueAndValidity();
  }

  async selectQuotation(event){
    this.allItems = [];
    this.number_of_select = 0;
    
    if(event){
      this.pageLoad = true;
      while (this.jobOrderItems.length !== 0) {
        this.jobOrderItems.removeAt(0);
      }
      
      this.load = this.QuotationMitItemService.load;
      this.filter = {
        quotation_info_id: event, 
        is_parent_quotation_mit_item_id_not_null: false,
        service_location: 'Onsite',
        method_name: ['ECS'].includes(this.template)? 'Supplier': undefined,
        method_name_ne: ['ICS'].includes(this.template)? 'Supplier': undefined,
        order_by: 'order_by:asc'
      };
    }
  }

  async selectTransport(event){
    this.allItems = [];
    this.number_of_select = 0;

    if(event){
      this.pageLoad = true;
      while (this.jobOrderItems.length !== 0) {
        this.jobOrderItems.removeAt(0);
      }

      this.load = this.QuotationMitItemService.load;
      this.filter = {
        transport_receive_info_id: event, 
        is_parent_quotation_mit_item_id_not_null: false,
        service_location: 'Lab',
        transport_receive_item_status: 'RECEIVED',
        method_name: ['ECL'].includes(this.template)? 'Supplier': undefined,
        method_name_ne: ['ICL'].includes(this.template)? 'Supplier': undefined,
        order_by: 'order_by:asc'
      };
    }
  }

  onCheckAll(e){
    this.jobOrderItems.controls.map(item=>{
      if(item.value.quotation_mit_item_info.job_order_calibration_item_info.length == 0 &&
        !item.value.quotation_mit_item_info.ready_to_billing){
        item.get('select').setValue(e.target.checked);
      }
    })
    this.number_of_select = this.jobOrderItems.value.filter(item=>item.select === true).length;
  }

  onCheckChange(e, index: number){
    this.jobOrderItems.controls[index].patchValue({
      select: e.target.checked
    });
    this.number_of_select = this.jobOrderItems.value.filter(item=>item.select === true).length;
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let items = this.jobOrderItems.value.filter(item=> item.select == true);
    this.done.emit(items);
    this.closeModalBtn.nativeElement.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.isShowCheckAll = false;
        this.number_of_select = 0;
        this.setForm();
        this.done.emit(null);
      }
    });
  }

  getStatus(item_status_id: string){
    let findStatus = this.itemStatus.find(item=> item.item_status_id == item_status_id);
    return findStatus.item_status_name_th || item_status_id;
  }

  checkSelected (mit){
    if(!this.selectedJobOrderItems.find(selected=> selected.quotation_mit_item_id == mit.quotation_mit_item_id)){
      return true;
    }else{
      return false;
    }
  }

  getMethodName(mit){
    let item = mit.filter(item=> item.total_amount != 0);  

    item = mit.filter((mit, index, self) =>
      index === self.findIndex((t) => (
        t?.method_name === mit?.method_name 
      ))
    )
    let methodName = "";
    item.map((item, index)=>{
      if(index == 0){
        methodName = item?.method_name;
      }else{
        methodName = methodName + ", "+ item?.method_name;
      }
    })

    return methodName;
  }
  async onChangePage(allItems: Array<any>) {
    this.allItems = allItems;

    this.pageLoad = false;
    while (this.jobOrderItems.length !== 0) {
      this.jobOrderItems.removeAt(0);
    }
    if(this.popupType == 'transport'){
      let result = allItems.filter(item=> 
        item.job_order_calibration_item_info.length == 0 && 
        !item.ready_to_billing &&
        this.checkSelected(item)
      );

      this.isShowCheckAll = false;
      if(result.length > 0){
        this.isShowCheckAll = true;
      }

      allItems.map(item=>{
        this.jobOrderItems.push(this.createJobOrderItem(item));
      })
    }else{
      let result = allItems.filter(item=>
        item.job_order_calibration_item_info.length == 0 && 
        !item.ready_to_billing &&
        this.checkSelected(item)
      );
      this.isShowCheckAll = false;
      if(result.length > 0){
        this.isShowCheckAll = true;
      }

      allItems.map(item=>{
        this.jobOrderItems.push(this.createJobOrderItem(item));
      })
    }
  }
}
