<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">Master Booking</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/transport/pre-booking']">Transport System</a>
                </li>
                <li class="breadcrumb-item active">Master Booking</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div  class="float-md-right">
            <button 
            type="button"
            class="btn btn-blue btn-min-width ml-1 mb-1"
            (click)="exportExcel()"
            >
            <i class="fa fa-download icon-left"></i> Download
          </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
                <div class="card-header" *ngIf="!isLoadFilter">
                    <form autocomplete="off" [formGroup]="form">
                      <div class="row">
                        <h4 class="card-title mb-2 col-12">Master Booking</h4>

                          <div class="col">
                            <div class="form-group">
                              <label class="sub-menu-title">ลูกค้า</label>
                              <div class="form-group">
                                <ng-select2
                                  *ngIf="select2Options"
                                  [options]="select2Options"
                                  formControlName="customer_id"
                                  [data]="customers"
                                  allowClear="true"
                                  class="w-200"
                                  placeholder="ลูกค้า"
                                >
                                </ng-select2>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label class="sub-menu-title">Messenger</label>
                              <div class="form-group">
                                <ng-select2
                                  allowClear="true"
                                  class="w-200"
                                  placeholder="Messenger"
                                  formControlName="messenger_id"
                                  [options]="
                                  {
                                    language: _UtilsService.select2OptionsLanguage()
                                  }"
                                  [data]="transportContactList"
                                >
                                </ng-select2>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label class="sub-menu-title">วันที่รับ-ส่งงาน</label>
                              <div class="form-group">
                                <div class="input-group mt-0 mb-1">
                                  <input
                                  date-picker
                                  type="text"
                                  id="booking_date"
                                  [date]="form.value.booking_date"
                                  class="form-control"
                                  formControlName="booking_date"
                                  [max-date]="undefined"
                                  placeholder="วันที่ส่งคืน"
                                  />
                                  <div
                                  class="input-group-append"
                                  for="booking_date"
                                  >
                                  <span class="input-group-text rounded-0 btn-primary text-white"
                                      ><i class="feather icon-calendar"></i
                                  ></span>
                                  </div>
                                  <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                      ><i class="fa fa-times"></i>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col">
                            <div class="form-group">
                              <label class="sub-menu-title">สถานะ</label>
                              <div class="form-group">
                                <ng-select2
                                  formControlName="doc_status_id"
                                  [data]="transportStatus"
                                  class="w-200"
                                  placeholder="สถานะ"
                                >
                                </ng-select2>
                              </div>
                            </div>
                          </div>
                          <div class="col">
  
                            <div class="form-group">
                              <label class="sub-menu-title text-white">.</label>
                              <button type="submit" class="btn btn-primary w-100 btn-block" (click)="search()">
                                <div>
                                  <i class="fa fa-search fa-lg" aria-hidden="true"></i><span class="ml-2">ค้นหา</span>
                                </div>
                              </button>
                            </div>
                          </div>
                      </div>

                      <div class="row">

                        <div class="col">
                          <div class="form-group">
                            <label class="sub-menu-title">สถานที่</label>
                            <div class="form-group">
                              <input type="text" id="location" class="form-control" placeholder="สถานที่"
                              formControlName="location" />
                            </div>
                          </div>
                        </div>
                        <div class="col">
                          
                        </div>
                        <div class="col">
                        
                        </div>
                        <div class="col">
                        
                        </div>
                        <div class="col">
                         
                        </div>
                      </div>
                    </form>

                </div>

                <div class="card-content collapse show">
                    <div class="card-body">
                        <loading *ngIf="isLoading"></loading>
                        <div class="table-responsive" *ngIf="!isLoading">
                            <table class="table table-striped table-bordered mb-5">
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th>ชื่อหน่วยงาน / บริษัท</th>
                                        <th>สถานที่</th>
                                        <th>ประเภทรถ</th>
                                        <th>Messenger</th>
                                        <th>ชื่อผู้จองงาน</th>
                                        <th>
                                          วันที่รับส่งงาน
                                        </th>
                                        <th>เวลา</th>
                                        <th class="text-center">ประเภทงาน</th>
                                        <th>
                                          เลขที่ INV
                                        </th>
                                        <th class="text-right">
                                          ยอดเงิน
                                        </th>
                                        <th>
                                          เลขที่ Quotation
                                        </th>
                                        <th>
                                          เลขที่ TR
                                        </th>
                                        <th>
                                          เลขที่ WR
                                        </th>
                      
                                        <th>ข้อความพิเศษถึงจัดส่ง</th>
                                        <th>ชื่อ และเบอร์ผู้ติดต่อ</th>

                                        <th class="text-center px-5">สถานะงาน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let booking of bookings;">
                                      <td class="text-nowrap">
                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="(userInfo$ | async)?.is_tran_dep && 
                                          ((userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                            'TRAN_DEP-01'
                                          ) || 
                                          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                            'TRAN_DEP-02'
                                          ) ||
                                          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                            'TRAN_DEP-03'
                                          )) && booking?.doc_status_id !='COMPLETED' && booking?.doc_status_id !='CANCELED' && booking?.doc_status_id !='RESCHEDULED'">
                                            <div class="text-warning cursor-pointer" data-toggle="modal"
                                            data-target="#modalMasterBookingEdit" (click)="select(booking)" 
                                            *ngIf="(userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                              'TRAN_DEP-01'
                                            ) || 
                                            (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                              'TRAN_DEP-02'
                                            )">
                                            <i class="fa fa-pencil icon-left"></i> แก้ไข
                                            </div>
                                            <div class="text-primary cursor-pointer" data-toggle="modal"
                                            data-target="#modalMasterBookingStatus" (click)="select(booking)">
                                              <i class="fa fa-check icon-left"></i>ปรับสถานะ
                                            </div>   
                                          </ng-container>
                                          <ng-container *ngSwitchDefault class="text-warning">
                                            -
                                          </ng-container>
                                        </ng-container>
                                        

                                      </td>
                                      <td>
                                        {{booking?.customer_info?.company_name | dash
                                        }}
                                      </td>
                                      <td>
                                        {{booking?.location | dash
                                        }}
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.transport_type_info?.transport_type_name_th | dash
                                        }}
                                       
                                      </td>
                                      <td class="text-nowrap">
                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="booking?.messenger_id !=''">
                                            {{booking?.messenger_info?.firstname_th 
                                            }}
                                            
                                            {{booking?.messenger_info?.lastname_th 
                                            }}  
                                          </ng-container>
                                          <ng-container *ngSwitchDefault >
                                            -
                                          </ng-container>
                                        </ng-container>
                                        
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.created_by_info?.firstname_th 
                                        }}
                                        
                                        {{booking?.created_by_info?.lastname_th 
                                        }}
                                        
                                      </td>
                                      <td>
                                        {{booking?.booking_date  | thai_date: "short":"date" | dash
                                        }}
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.booking_time | dash
                                        }}
                                      </td>
                                      <td class="text-center pointer">
                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="booking.booking_type === 'SEND'">ส่ง - {{booking?.booking_sub_type}}</ng-container>
                                          <ng-container *ngSwitchDefault>รับ - {{booking?.booking_sub_type}}</ng-container>
                                        </ng-container>
                                      </td>
                                      <td>
                                        {{booking?.acc_invoice_info_doc_no | dash
                                        }}
                                        
                                      </td>
                                      <td class="text-right">
                                        {{booking?.acc_invoice_info_grand_total || 0 | number : '1.2-2' 
                                        }}
                                        
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.quotation_info_doc_no | dash}}
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.transport_receive_info_doc_no | dash}}
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.job_order_info_doc_no | dash}}
                                        <!-- <div>
                                          <ng-container [ngSwitch]="true">
                                            <ng-container *ngSwitchCase="booking.ref_doc_type === 'MITITEM' || booking.ref_doc_type === 'MITCERT' || booking.ref_doc_type === 'TRANSPORT_RECEIVE'">

                                              <div *ngIf="booking?.transport_receive_info_id">
                                                <a [href]="'/transport-receive/'+booking?.transport_receive_info_id+'/show'" target="_blank">{{booking?.transport_receive_info_doc_no | dash}}</a>
                                              </div>
                                              <div *ngIf="!booking?.transport_receive_info_id">
                                                {{booking?.transport_receive_info_doc_no | dash}}
                                              </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="booking.ref_doc_type === 'JOBORDER'">
                                              <ng-container [ngSwitch]="true">
                                                <ng-container *ngSwitchCase="['PRD','TRN'].includes(getTemplate(booking?.job_order_info_doc_no))">
                                                  <a [href]="'/work-order/non-calibration/'+booking?.ref_doc_id+'/show?template='+getTemplate(booking?.job_order_info_doc_no)" target="_blank">{{booking?.job_order_info_doc_no}}</a>
                                                </ng-container>
                                  
                                                <ng-container *ngSwitchDefault>
                                                  <a [href]="'/work-order/calibration/'+booking?.ref_doc_id+'/show?template='+getTemplate(booking?.job_order_info_doc_no)" target="_blank">{{booking?.job_order_info_doc_no}}</a>
                                                </ng-container>
                                              </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="booking.ref_doc_type === 'BILLINGNOTEINFO' || booking.ref_doc_type === 'BILLINGNOTEITEM'">
                                              <div *ngIf="booking?.acc_billing_note_info_id">
                                                <a [href]="'/billing-note/'+booking?.acc_billing_note_info_id+'/show'" target="_blank">{{booking?.acc_billing_note_info_doc_no | dash}}</a>
                                              </div>
                                              <div *ngIf="!booking?.acc_billing_note_info_id">
                                                {{booking?.acc_billing_note_info_doc_no | dash}}
                                              </div>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="booking.ref_doc_type === 'PREBOOKING'">
                                              {{getMergeDocNo(booking)}}
                                            </ng-container>
                                            <ng-container *ngSwitchDefault>-</ng-container>
                                          </ng-container>
                                        </div> -->
                                      </td>

                                      <td>
                                        <div *ngIf=" booking.ref_doc_type === 'MITITEM'">
                                          {{
                                            booking.quotation_mit_item_info?.description || booking.quotation_mit_item_info?.equipment_name
                                          }}
/ SN:
                                          {{
                                            booking.quotation_mit_item_info?.serial_no | dash
                                          }}
                                          / TAG:
                                          {{
                                            booking.quotation_mit_item_info?.tag_no | dash
                                          }}
                                          <br>
                                          --------
                                        </div>

                                        <div *ngIf=" booking.ref_doc_type === 'MITCERT'">
                                          จำนวน Cert = 1
                                          <br>
                                          --------
                                        </div>
                                        <div *ngIf=" booking.ref_doc_type === 'JOBORDER'">
                                          จำนวน Cert = 
                                          {{
                                            booking.total_cert || 0
                                          }}
                                          <br>
                                          --------
                                        </div>
                                        
                                        <div>
                                          {{booking?.extra_message | dash
                                          }}
                                        </div>
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.contact_name 
                                        }} -   {{booking?.contact_phonenumber 
                                        }}
                                      </td>

                                      <td class="text-center">

                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="booking?.doc_status_id == 'COMPLETED'">
                                            <span class="text-success">สำเร็จ
                                            
                                            </span>
                                            <br>
                                            <small>(โดย {{booking.closed_by_info?.firstname_th}} {{booking.closed_by_info?.lastname_th}}  เมื่อ {{
                                              booking.closed_at | thai_date:"short" | dash
                                            }})</small>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="booking?.doc_status_id == 'CANCELED'">
                                              <span class="text-danger">ยกเลิก</span>
                                              </ng-container>
                                              <ng-container *ngSwitchCase="booking?.doc_status_id == 'RESCHEDULED'">
                                                <span class="text-success">ทำนัดหมายใหม่</span>
                                                </ng-container>
                                          <ng-container *ngSwitchDefault class="text-warning">
                                            <span class="text-warning">ระหว่างดำเนินการ</span>
                                            </ng-container>
                                        </ng-container>
                                      </td>
                  
                                    </tr>
                                    <tr *ngIf="bookings.length === 0">
                                        <td colspan="20" class="text-center">ไม่มีข้อมูล</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
    
                        <app-paginator
                        *ngIf="initialPage && !isLoadFilter"
                        [filter]="filter"
                        [reset]="reset"
                        [fetch]="load"
                        [initialPage]="initialPage"
                        (filterLimit)="getFilterLimit($event)"
                        (changePage)="onChangePage($event)">
                      </app-paginator>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>


<app-master-booking-edit (save)="saveBooking($event)" [transportTypes]="transportTypes" *ngIf="booking" [booking]="booking">
</app-master-booking-edit>


<app-master-booking-status (save)="saveBooking($event)" *ngIf="booking" [booking]="booking">
</app-master-booking-status>
