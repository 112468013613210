import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccInvoiceEditHistoryService {
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-invoice-edit-history`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      acc_invoice_edit_history_id,
      acc_invoice_info_id,
      document_event_id,
      event_text,
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/acc-invoice-edit-history/${acc_invoice_edit_history_id}`, {
        acc_invoice_info_id,
        document_event_id,
        event_text
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
