<!-- Create Method Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="create-method"
  tabindex="-1"
  role="dialog"
  aria-labelledby="createMethodModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="createMethodModal">
          เพิ่ม Method
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="form">
          <div class="row justify-content-md-center">
            <div class="col-md-12">
              <div class="form-body">
                <form-group
                  [control]="form.controls.method_name"
                  [submitted]="submitted"
                >
                  <label for="method_name">
                    Method name (ภาษาอังกฤษ) <span class="danger">*</span>
                  </label>
                  <input
                    type="text"
                    id="method_name"
                    class="form-control"
                    placeholder=""
                    formControlName="method_name"
                  />
                </form-group>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
