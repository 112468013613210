import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  EventEmitter,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";
import moment from "moment";
@Component({
  selector: "app-customer-activity-show",
  templateUrl: "./customer-activity-show.component.html",
  styleUrls: ["./customer-activity-show.component.css"],
})
export class CustomerActivityShowComponent implements OnInit {
  environment = environment;
  form: FormGroup;
  submitted = false;
  status = null;

  customer_id = null;
  centerMap: google.maps.LatLngLiteral;
  @Output() done = new EventEmitter();
  @Output("close-sidebar") closeSidebarEvent = new EventEmitter();

  @Input("activity-types") activityTypes: Array<any> = [];
  @Input("customer-activity") customer_activity;
  @ViewChild("closeSidebar") closeSidebar: ElementRef;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute
  ) {
    this.setForm();
    this.customer_id = this.route.snapshot.paramMap.get("customer_id");
  }
  setForm() {
    this.form = this.formBuilder.group({
      company_name: ["", [Validators.required]],
      activity_datetime: ["", [Validators.required]],
      customer_activity_type_id: ["", [Validators.required]],
      title: ["", [Validators.required]],
      description: [""],
      location_lat: [""],
      location_lng: [""],
    });
  }

  setData() {
    if (!this.customer_activity) return;

    this.form.controls.activity_datetime.setValue(
      moment(this.customer_activity.activity_datetime).format(
        "DD-MM-YYYY HH:mm"
      )
    );
    this.form.controls.company_name.setValue(
      this.customer_activity.customer_info.company_name
    );
    this.form.controls.customer_activity_type_id.setValue(
      this.customer_activity.customer_activity_type_id
    );
    this.form.controls.title.setValue(this.customer_activity.title);
    this.form.controls.description.setValue(this.customer_activity.description);

    this.form.controls.location_lat.setValue(
      this.customer_activity.location_lat
    );
    this.form.controls.location_lng.setValue(
      this.customer_activity.location_lng
    );
  }

  ngOnInit(): void {
    this.setData();
    this.form.disable();
  }

  ngOnChanges(changes) {
    if (changes.customer_activity) {
      this.ngOnInit();
    }
  }

  setLocation(location) {
    this.form.controls.location_lat.setValue(String(location.lat));
    this.form.controls.location_lng.setValue(String(location.lng));
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
        this.closeSidebarEvent.emit();
      } else {
        this.ngOnInit();
      }
    });
  }
}
