import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class MITScopeService {
  async create({ scope_name, scope_name_short }) {
    let result = null;
    try {
      result = await Axios().post(`/mit-scope`, {
        scope_name,
        scope_name_short
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({ mit_scope_id, scope_name, scope_name_short }) {
    let result = null;
    try {
      result = await Axios().put(`/mit-scope/${mit_scope_id}`, {
        scope_name,
        scope_name_short
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ mit_scope_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/mit-scope/${mit_scope_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/mit-scope`, {
        params: {
          offset: paging?.offset,
          limit: paging?.limit,
          ...filter,
          order_by: "scope_name:asc",
        },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ mit_scope_id }) {
    let result = null;
    try {
      result = await Axios().get(`/mit-scope/${mit_scope_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
