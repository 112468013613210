import { Component, ElementRef, Input, NgZone, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import moment from 'moment';
import { GETService } from 'src/app/services';
declare var $: any;

@Component({
  selector: 'app-work-order-lab-import',
  templateUrl: './work-order-lab-import.component.html',
  styleUrls: ['./work-order-lab-import.component.css']
})
export class WorkOrderLabImportComponent implements OnInit {
  loading: boolean = false;
  canAccess: boolean = true;
  canSubmit: boolean = true;
  isShowRemark: boolean = false;

  errorMessage: string = "";
  job_order_info_id: string;

  dbList = [];
  excelList = [];
  exportList = [];

  @Input() jobOrderInfo;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private zone: NgZone,
    private route: ActivatedRoute,
    private GETService: GETService
  ) {
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
   }

  ngOnInit(): void {
    this.canSubmit = true;
  }

  submit(){
    this.canSubmit = true;

    let excel = this.excelList.find(item=> item.person_in_charge_info?.firstname_th == undefined || item.std == null || item.job_order_status_work_info.service_date == null || item.job_order_status_work_info.created_by_info?.firstname_th == undefined || item.job_order_status_work_info.reject_reason == null || item?.is_duplicate);
    if(this.excelList.length == 0 || excel || this.checkInvalidPoint()){
      if(excel || this.checkInvalidPoint()){
        this.setWarningBackground();
        this.canSubmit = false;
      }
      return;
    }

    this.done.emit(this.excelList);  
    this.closeModalBtn.nativeElement.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.canSubmit = true;
        this.clearItem();
      }
    });
  }

  setWarningBackground(){
    this.excelList.map(ex=>{
      if(ex.person_in_charge_info?.firstname_th == undefined || ex.std == null || ex.job_order_status_work_info.service_date == null || ex.job_order_status_work_info.created_by_info?.firstname_th == undefined || ex.job_order_status_work_info.reject_reason == null || ex?.is_duplicate || ex.quotation_mit_item_info.point.is_valid == false){
        ex['is_warning'] = true;
      }
    })
  }

  uploadFile(){
    document.getElementById("importMitFile").click();
  }

  async onFileChange(ev) {
    this.loading = true;
    this.canAccess = true;
    this.canSubmit = true;
    
    if(ev.target.files[0]){
      const response = await this.GETService.uploadMasterReport(ev.target.files[0], this.job_order_info_id);
      if(response.success){
        this.exportList = response.resultData || [];
        if(this.exportList.length > 0){
          this.excelList = this.exportList[0]?.excel?.job_order_calibration_item_info || [];
          this.dbList = this.exportList[0]?.db?.job_order_calibration_item_info || [];
        }
        this.excelList.map((ex, index)=>{
          ex['job_order_calibration_item_id'] = this.dbList[index]?.job_order_calibration_item_id;
          ex['job_order_status_work_info'].service_date = ex.job_order_status_work_info.service_date? 
          moment(ex.job_order_status_work_info.service_date).format(): null;
        });
        

        if(this.excelList.find(item=> item?.is_duplicate)){
          this.canAccess = false;
        }else{
          this.canAccess = true;
        }
        this.loading = false;
      }else{
        this.errorMessage = response.errorMessage;
        this.canAccess = false;
        this.loading = false;
        this.clearItem();
      }
    }
    $("#importMitFile").val('');
  }

  clearItem(){
    this.exportList = [];
    this.excelList = [];
    this.dbList = [];
  }

  remove(index){
    this.excelList.splice(index, 1);
  }

  checkInvalidPoint(): boolean {
    return this.excelList.find(item=> item.quotation_mit_item_info.point.is_valid == false);
  }

  checkInvalidPointByItem(point): boolean {
    return point.is_valid? false: true;
  }

  getInvalidReason(point): string{
    return point.reason;
  }

  getDetail(exDetail, details){
    let specificDetail = 
    details.find(d=> d.cert_no == exDetail.cert_no);
    
    return specificDetail;
  }
}
