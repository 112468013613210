import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CustomerAddressService } from 'src/app/services';

import { 
  CalculateFunctionService, 
  QuotationUtilsService 
} from 'src/app/services/quotations';
import domtoimage from 'dom-to-image-more'
import jsPDF from 'jspdf'; 
import THBText from 'thai-baht-text'
import { AccPaymentService } from 'src/app/services/invoice';
import { AccRecivedVoucherInfoService, AccRecivedVoucherItemService } from 'src/app/services/received-voucher';

@Component({
  selector: 'app-received-voucher',
  templateUrl: './received-voucher.component.html',
  styleUrls: ['./received-voucher.component.css']
})
export class ReceivedVoucherComponent implements OnInit {
  pages = [];
  receiveItems = [];

  acc_received_voucher_info_id: string = "";
  //any
  customer_info = null;
  receiveInfo = null;
  count: number = 0;

  constructor(
    private titleService: Title,
    private route: ActivatedRoute,
    private AccPaymentService: AccPaymentService,
    private CustomerAddressService: CustomerAddressService,
    private AccRecivedVoucherInfoService: AccRecivedVoucherInfoService,
    private AccRecivedVoucherItemService: AccRecivedVoucherItemService,

    public utils: QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) {
    this.acc_received_voucher_info_id = 
    this.route.snapshot.paramMap.get("acc_received_voucher_info_id");
   }

   async ngOnInit() {
    await this.AccRecivedVoucherInfoService.getById({acc_received_voucher_info_id: this.acc_received_voucher_info_id})
    .then(async res=>{
      this.receiveInfo = res.resultData || {};
      this.titleService.setTitle(this.receiveInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
      if(this.receiveInfo.customer_address_id){
        await this.CustomerAddressService.getById({customer_address_id: this.receiveInfo.customer_address_id})
        .then(res=>{
          this.receiveInfo['customer_address'] = this.utils.genAddress(res.resultData);
          this.receiveInfo['customer_address_info'] = res.resultData;
        })
      }

      await this.AccRecivedVoucherItemService.load(null, {acc_received_voucher_info_id: this.acc_received_voucher_info_id})
      .then(async res=>{
        this.receiveItems = res.resultData || [];
        await Promise.all(this.receiveItems.map(async (voucher)=>{
          await this.AccPaymentService.getById({acc_payment_id: voucher.acc_document_payment_id})
          .then(res=>{
            voucher['data'] = res.resultData;
          })
        }));
      });
      for (var i=0; i<this.receiveItems.length; i++) {
        this.receiveItems[i].index = i;
      }
      this.sumCount();
      for (var i=0; i<this.receiveItems.length; i+=9) {
        this.pages.push(this.receiveItems.slice(i, i+9));
      }  
    })
  }

  sumCount(){
    let all_fee = 0;
    let all_value_before_wht = 0;
    let all_wht_percent = 0;
    let all_income_tax = 0;
    let all_doc_no = "";

    this.receiveItems.map((v, index)=>{

      this.count = this.count + v.data.grand_total;
      all_value_before_wht = all_value_before_wht +  v.data.value_before_wht;
      let cal_wht_percent = (v.data.wht_percent * v.data.value_before_wht)/100;

      all_wht_percent = all_wht_percent + cal_wht_percent;
      let cal_income_tax = (v.data.tax * v.data.value_before_wht)/100;

      all_income_tax = all_income_tax + cal_income_tax;

      all_fee = all_fee +  v.data.fee;
      if(index == 0){
        all_doc_no = v.data?.acc_tax_invoice_info?.doc_no_company;
      }else{
        if(!all_doc_no.includes( v.doc_no)){
          all_doc_no = all_doc_no + ", " +  v.data?.acc_tax_invoice_info?.doc_no_company;
        }
      }
    });
    this.receiveInfo.grand_total = this.count;
    this.receiveInfo.all_doc_no = all_doc_no;

    this.receiveInfo.all_wht_percent = all_wht_percent;
    this.receiveInfo.all_income_tax = all_income_tax;
    this.receiveInfo.all_value_before_wht = all_value_before_wht;
    this.receiveInfo.all_fee = all_fee;
    if (this.receiveInfo.grand_total > 0) {
      this.receiveInfo['THBText'] = THBText(Math.abs(this.receiveInfo.grand_total));
    }else if(this.receiveInfo.grand_total < 0){
      this.receiveInfo['THBText'] = 'ลบ' + THBText(Math.abs(this.receiveInfo.grand_total));
    }else{
      this.receiveInfo['THBText'] = 'ศูนย์บาทถ้วน';
    }
  }

  async print(receiveInfo){
    const receive_sections = document.querySelectorAll('.pdf');
    let doc = new jsPDF('p', 'mm', 'a4', true);
    let page = 1;
    let perPage = Array.prototype.slice.call(receive_sections,0).length;
    
    let receiveList = [];
    await Promise.all(Array.prototype.slice.call(receive_sections,0).map(async (value, index) =>{
      let receive_section = document.getElementById('receive_' + (index));
      await domtoimage.toJpeg(receive_section, {
        background: 'white', 
        allowTaint: true,
        width: receive_section.clientWidth * 2,
        height: receive_section.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        receiveList.push({index: index, canvas: canvas, receive_section: receive_section});
      })
    }));
    receiveList.sort(function(a, b){return a.index - b.index});
    await Promise.all(receiveList.map(async (value, index) =>{

      var imgWidth = 208;
      var imgHeight = value.receive_section.clientHeight * imgWidth / value.receive_section.clientWidth;
      if((index) != 0) doc.addPage('a4');
      doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);
      doc.setFontSize(8);
      doc.text(page + ' /  ' + perPage, 200, 285);
      page = page+1;
    }));

    setTimeout(() => {
      doc.save(receiveInfo.doc_no);
    }, 1000);
  }

  getPercent(){
    let item = this.receiveItems.filter((payment, index, self) =>
    index === self.findIndex((t) => (
      t.data.wht_percent === payment.data.wht_percent 
    )))
    var out = [];

    item.map((item, index)=>{
      out.push(item.data.wht_percent);
    })

    return out.join(", ");
  }
}
