<div class="container app-content overflow-container" *ngIf="canAccess">

    <div class="btn-section text-right border-bottom">
      <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(jobOrderInfo)">
          <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
      </button>
    </div>
  
    <div class="pdf" id=pdf>
        <div class="row">
            <div class="col-2"></div>
            <img class="col-1 text-right p-0" src="/assets/app-assets/images/pdf/logo1.png" width="90px"
                height="110px">
            <div class="col-6 text-center pt-3">
                <h2>MIRACLE INTERNATIONAL TECHNOLOGY CO., LTD.</h2>
                <p>214 Bangwaek Rd., Bangpai, Bangkae, Bangkok 10160 Tel : 0-2865-4647-8 Fax : 0-2865-4649
                </p>
            </div>
            <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon2.jpg" width="90px"
                height="110px">
            <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon3.png" width="90px"
                height="110px">
            <div class="col-1"></div>
        </div>
        <p class="text-center">Taxpayer Identification No.: 0-1055-45112-91-9 (Head Office)</p>
        <div class="row">
            <div class="col-6 pr-0 text-right">
                Web-site : http://www.mit.in.th
            </div>
            <div class="col-6">
                E-mail : info@mit.in.th
            </div>
        </div>
        

        <ng-container class="mt-3" *ngIf="jobOrderInfo?.job_order_type == 'ICS' || jobOrderInfo?.job_order_type == 'TRN'">
            <div class="row">
                <div class="col-2"></div>
                <div class="col-9">
                    <p class="text-right">{{jobOrderInfo?.created_at | thai_date: "":"date" | dash}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-10">
                    <p>เรื่อง &nbsp;&nbsp; แจ้งรายชื่อพนักงานที่{{jobOrderInfo?.job_order_type == 'ICS'? 'เข้าทำงาน': 'อบรม'}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-10">
                    <p>เรียน &nbsp;&nbsp;   
                        <span class="pl-0" *ngFor="let item of jobOrderContactList; let i = index;">
                            <span class="pl-0" *ngIf="i == 0">
                                {{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}}
                            </span>
                        </span>
                    </p>
                </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-9">
                    <p style="text-indent: 3.0em;">บริษัท มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี จำกัด ใคร่ขอขอบคุณเป็นอย่างยิ่งที่ได้พิจารณาใช้บริการสอบเทียบเครื่องมือวัดของทางบริษัทมิราเคิลฯ และขอยืนยันวันและเวลาเพื่อ{{jobOrderInfo?.job_order_type == 'ICS'? 'เข้าดำเนินการสอบเทียบที่': 'เข้าอบรมที่'}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-1">
                    <p>บริษัท</p>
                </div>
                <div class="col-auto">
                    <p>{{jobOrderInfo?.customer_info?.company_name}}</p>
                </div>
            </div>
            <div class="row">
                <div class="col-2"></div>
                <div class="col-10">
                     <p>นัดหมายเข้าบริการวันที่ {{jobOrderInfo?.pickup_date | thai_date: "":"date" | dash}} | {{jobOrderInfo?.doc_no | dash}}</p>
                </div>
            </div>
            <div class="row" *ngIf="jobOrderInfo?.job_order_type == 'ICS'">
                <div class="col-2"></div>
                <div class="col-1">
                    <p>เวลา</p>
                </div>
                <div class="col-auto">
                    <p>{{jobOrderInfo?.service_time}} น.</p>
                </div>
            </div>
            <ng-container *ngIf="jobOrderInfo?.job_order_type == 'ICS'">
                <div class="row mt-2">
                    <div class="col-2"></div>
                    <div class="col-2">
                        <p>มีพนักงานจำนวน</p>
                    </div>
                    <div class="col-1">{{engineerList.length}}</div>
                    <div class="col-2">
                        <p>คน รายชื่อดังต่อไปนี้</p>
                    </div>
                </div>
                <div class="row" *ngFor="let engineer of engineerList; let i = index">
                    <div class="col-2"></div>
                    <div class="col-3">
                        <p>{{i+1}}. {{engineer.user_info?.firstname_th}} {{engineer.user_info?.lastname_th}}  <span *ngIf="i==0">(หัวหน้างาน)</span></p>
                    </div>
                    <!-- <div class="col-1"></div> -->
                    <div class="col-4">
                        <p>เบอร์โทรติดต่อ {{engineer.user_info?.mobile_no}} <span *ngIf="engineer.user_info.nickname_th">(คุณ{{engineer.user_info?.nickname_th}})</span></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-2">
                        <p>เดินทางโดย</p>
                    </div>
                </div>
            </ng-container>
            <ng-container *ngIf="jobOrderInfo?.job_order_type == 'TRN'">
                <div class="row mt-2">
                    <div class="col-2"></div>
                    <div class="col-2">
                        <p>มีพนักงานจำนวน</p>
                    </div>
                    <div class="col-1">{{trainingList.length}}</div>
                    <div class="col-2">
                        <p>คน รายชื่อดังต่อไปนี้</p>
                    </div>
                </div>
                <div class="row" *ngFor="let training of trainingList; let i = index">
                    <div class="col-2"></div>
                    <div class="col-2">
                        <p>{{i+1}}. {{training.user_info?.firstname_th}} {{training.user_info?.lastname_th}} </p>
                    </div>
                    <div class="col-1"></div>
                    <div class="col-4">
                        <p>เบอร์โทรติดต่อ {{training.user_info?.mobile_no}} <span *ngIf="training.user_info.nickname_th">(คุณ{{training.user_info?.nickname_th}})</span></p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-2"></div>
                    <div class="col-2">
                        <p>เดินทางโดย</p>
                    </div>
                </div>
            </ng-container>
            <div class="row" *ngFor="let car of carList; let i = index">
                <div class="col-2"></div>
                <div class="col-2">
                    <p>{{getCarType(car.car_info?.car_type_id)}} ทะเบียน {{car.car_info?.car_plate}}</p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-2"></div>
                <div class="col-9">
                    <p style="text-indent: 3.0em;">ทั้งนี้บริษัท มิราเคิลฯ มีการจัดเตรียมอุปกรณ์ป้องกันภัยส่วนบุคคล ได้เเก่ รองเท้าและหมวกเซฟตี้เเว่นตานิรภัย และปลั๊กอุดหู หากท่านต้องการให้ เตรียมอุปกรณ์ใดเพิ่มเติมกรุณากรอกรายละเอียดด้านล่าง</p>
                    <p>........................................................................................................................................................................................................................................................... 
                    </p>
                    <p>...........................................................................................................................................................................................................................................................
                    </p>
                    <p>และบริษัท มิราเคิลฯ ขอสงวนสิทธิ์ในการปรับแต่งเครื่องมือวัด กรณีที่ไม่มีวิธีการหรือคู่มือสำหรับปรับแต่ง</p>
                    <p style="text-indent: 3.0em;">จึงเรียนมาเพื่อทราบและกรุณาเซ็นชื่อเพื่อยืนยันให้เข้าทำงานสอบเทียบในวันและเวลาดังกล่าว ส่งกลับเมล์ coen@mit.in.th</p>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-2"></div>
                <div class="col-5 text-left">
                    <!-- <p>ส่งกลับเมล์ coen@mit.in.th</p> -->
                </div>
                <div class="col-4 text-right">
                    <p>_______________________________(ลูกค้าเซ็นผู้ทราบ)</p>
                </div>
                <div class="col-2"></div>
            </div>
            <div class="row mt-5"></div>
            <div class="row mt-5"></div>
            <div class="row mt-5">
                <div class="col-7"></div>
                <div class="col-4 text-center">
                    <p>ขอแสดงความนับถือ</p>
                    <img class="image-size" [src]="signature_file" *ngIf="signature_file" (error)="signature_file='';">
                    <div class="mb-3" *ngIf="!signature_file">
                    </div>
                    <p>_____________________________________________</p>
                    <p>{{jobOrderInfo?.confirmed_by_info?.firstname_th}} {{jobOrderInfo?.confirmed_by_info?.lastname_th}} เบอร์โทร {{jobOrderInfo?.confirmed_by_info?.mobile_no}}</p>
                    <p>{{jobOrderInfo?.confirmed_by_info?.emp_info?.emp_position_info?.emp_position_name_th}}</p>
                    <p>บริษัท มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี จำกัด</p>
                </div>
                <div class="col-1"></div>
            </div>
        </ng-container>
    </div>

    <br>
    
    <ng-container *ngIf="jobOrderInfo?.job_order_type == 'ICS'">
        <ng-container *ngFor="let engineer of engineerList; let i = index" class="my-2">
            <div class="id-card" id="id_card_{{i}}">
              <div class="row">
                <div class="col-3">    
                </div>
                <div class="col-6 text-center">
                    <img *ngIf="engineer.user_info.idcard_file" [src]="uploadPath + engineer.user_info.idcard_file" alt="ID Card" width="70%" height="300">
                </div>
                <div class="col-3">
                </div>
              </div>
            </div>
            <br>
        </ng-container>
    </ng-container>

    <ng-container *ngIf="jobOrderInfo?.job_order_type == 'TRN'">
        <ng-container *ngFor="let training of trainingList; let i = index" class="my-2">
            <div class="id-card" id="id_card_{{i}}">
              <div class="row">
                <div class="col-3">    
                </div>
                <div class="col-6 text-center">
                    <img *ngIf="training.user_info.idcard_file" [src]="uploadPath + training.user_info.idcard_file" alt="ID Card" width="70%" height="300">
                </div>
                <div class="col-3">
                </div>
              </div>
            </div>
            <br>
        </ng-container>
    </ng-container>
    
</div>
  
<div class="container app-content" *ngIf="canAccess == false && pageLoad">
    <div class="text-center">
        <h2>ไม่สามารถเข้าถึงได้</h2>
        <h5>โปรดติดต่อพนักงานขาย</h5>
    </div>
</div>