<!-- Method List Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="method-list"
  tabindex="-1"
  role="dialog"
  aria-labelledby="methodListModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="methodListModal">
          รายการ Method
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th class="text-center">ลำดับ</th>
                <th>Method</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let method of methods; let i = index">
                <th class="text-center">{{ i + 1 }}</th>

                <td>
                  <input
                    type="text"
                    id="method_name"
                    class="form-control form-control-sm"
                    [(ngModel)]="method.method_name"
                  />
                </td>
                <td>
                  <button
                    type="button"
                    class="btn round btn-icon btn-danger mr-1 mb-1"
                    data-target="#modalConfirmDelMsg"
                    data-toggle="modal"
                    (click)="select(i)"
                    [disabled]="method.isUsed"
                  >
                    <i class="fa fa-minus fa-2x"></i>
                  </button>
                  <button
                    *ngIf="methods.length === i + 1"
                    type="button"
                    class="btn round btn-icon btn-success mb-1"
                    (click)="addMore()"
                  >
                    <i class="fa fa-plus fa-2x"></i>
                  </button>
                </td>
              </tr>

              <!-- <tr *ngIf="!methods.length">
                <td colspan="3" class="text-center">ไม่มีข้อมูล</td>
              </tr> -->

              <tr *ngIf="!methods.length">
                <th>1</th>
                <td>
                  <input
                    type="text"
                    id="method_name"
                    class="form-control form-control-sm"
                    placeholder="กรอกข้อมูล"
                    [(ngModel)]="method_name"
                  />
                </td>
                <td>
                  <button
                    type="button"
                    class="btn round btn-icon btn-danger mr-1 mb-1"
                    (click)="method_name = ''"
                  >
                    <i class="fa fa-minus fa-2x"></i>
                  </button>

                  <button
                    type="button"
                    class="btn round btn-icon btn-success mb-1"
                    (click)="addMore()"
                  >
                    <i class="fa fa-plus fa-2x"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>

<app-confirm-delete (save)="delete($event)"></app-confirm-delete>
