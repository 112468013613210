<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="receive-item-edit"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreateModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreateModal">
          {{isReview? 'ตรวจสอบการรับเครื่องมือ': 'บันทึกรับเครื่องมือ'}}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal"  autocomplete="off" class="form" [formGroup]="form">
          <ul class="nav nav-tabs" role="tablist">
            <li class="nav-item">
              <a
              class="nav-link" 
              (click)="tab='status'"
              [ngClass]="{'active': tab === 'status', 'text-danger': checkMain() && submitted && tab !='status' }"
              id="status-tab"
              data-toggle="tab"
              href="#status"
              aria-controls="status"
              role="tab"
              aria-selected="false"
              > {{isReview? 'ตรวจสอบการรับเครื่องมือ': 'บันทึกรับเครื่องมือ'}}</a
            >
            </li>
            <li class="nav-item">
              <a
              class="nav-link"
              (click)="tab='equipment'"
              [ngClass]="{'active': tab === 'equipment', 'text-danger': checkEquipment() && submitted && tab !='equipment' }"
              id="equipment-tab"
              data-toggle="tab"
              href="#equipment"
              aria-controls="equipment"
              role="tab"
              aria-selected="true"
              >รายละเอียดเครื่องมือ
              </a
            >
            </li>
          </ul>
          <div class="tab-content pt-1">
            <div
              class="tab-pane in"
              [ngClass]="{'active': tab === 'status' }"
              id="status"
              aria-labelledby="status-tab"
              role="tabpanel">
              <div class="p-2">
                <div class="form-body">
                  <div class="form-body">
                        <div class="form-group row mb-0">
                          <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="description">
                            สถานะ <span class="danger" *ngIf="form.controls['item_status_id'].enabled">*</span> : 
                          </label>
                          <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                              <form-group
                              [control]="form.controls.item_status_id"
                              [submitted]="submitted"
                            >
                              <select
                                class="form-control"
                                id="item_status_id"
                                formControlName="item_status_id"
                              >
                                <option value="">โปรดเลือก...</option>
                                <option
                                  *ngFor="let status of statusList"
                                  [value]="status.item_status_id"
                                  >{{ status.item_status_name_th }}</option
                                >
                              </select>
                            </form-group>
                          </div>
                        </div>
                  </div>
                  <div class="form-body">
                    <div class="form-group row mb-0">
                          <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="note">
                            หมายเหตุ <span class="danger">*</span> : 
                          </label>
                          <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                            <form-group
                            [control]="form.controls.note"
                            [submitted]="submitted">
                            <textarea
                                type="text"
                                rows="3"
                                placeholder="หมายเหตุ"
                                id="note"
                                class="form-control"
                                formControlName="note"
                            ></textarea>
                            </form-group>
                          </div>
                    </div>
                  </div>
  
                  <div class="form-body">
                    <div class="form-group row mb-0">
                      <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="note">
                        <label for="file">แนบไฟล์ </label>
                      </label>
                      <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                        <button
                          class="btn btn-outline-primary"
                          type="button"
                          (click)="uploadFile()">
                            <i class="feather icon-upload icon-left"></i>
                            Upload
                        </button>
                        <input type="file" style="display:none;" multiple id="importFile"
                        (change)="importFile($event.target.files)">
  
                        <div class="mt-1">
                          <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                            <span style="cursor: pointer;" (click)="downloadFile(file)">
                              {{file.transport_receive_item_file_id? file.file_name: file.name}}
                            </span>
                            <span aria-hidden="true" style="cursor: pointer;" 
                            data-target="#modalConfirmDelFile"
                            data-toggle="modal"
                            (click)="removeFile(file)">&times;</span>
                          </span>
                        </div>
                        <!-- <span class="pl-1 text-danger w-100" *ngIf="submitted && files.length == 0">
                          โปรดระบุ
                        </span> -->
                        <span class="pl-1 text-danger w-100" *ngIf="submitted && checkFileInvalid">
                          ขนาดไฟล์เกินกว่าที่กำหนด
                        </span>
                      </div>
                    </div>
    
                  </div>
                </div>
              </div>
            </div>
            <div
            class="tab-pane in"
            [ngClass]="{'active': tab === 'equipment' }"
            id="equipment"
            aria-labelledby="equipment-tab"
            role="tabpanel">
            <div class="p-2">
              <div class="form-body">

                <div class="form-body">
                  <div class="form-group row mb-0">
                    <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="note">
                      Maker <span class="danger">*</span> : 
                    </label>
                    <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                      <form-group
                      [control]="form.controls.noqt_marker"
                      [submitted]="submitted">
                      <input
                          type="text"
                          placeholder="Maker"
                          id="noqt_marker"
                          class="form-control"
                          formControlName="noqt_marker"
                      />
                      </form-group>
                    </div>
                  </div>
                </div>

                <div class="form-body">
                  <div class="form-group row mb-0">
                    <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="note">
                      Model <span class="danger">*</span> : 
                    </label>
                    <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                      <form-group
                      [control]="form.controls.noqt_model"
                      [submitted]="submitted">
                      <input
                          type="text"
                          placeholder="Model"
                          id="noqt_model"
                          class="form-control"
                          formControlName="noqt_model"
                      />
                      </form-group>
                    </div>
                  </div>
                </div>
                
                <div class="form-body">
                  <div class="form-group row mb-0">
                    <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="note">
                      Serial No. <span class="danger">*</span> : 
                    </label>
                    <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                      <form-group
                      [control]="form.controls.noqt_serial_no"
                      [submitted]="submitted">
                      <input
                          type="text"
                          placeholder="Serial No."
                          id="noqt_serial_no"
                          class="form-control"
                          formControlName="noqt_serial_no"
                      />
                      </form-group>
                    </div>
                  </div>
                </div>

                <div class="form-body">
                  <div class="form-group row mb-0">
                    <label class="col-12 col-sm-12 col-md-3 col-lg-3 label-control" for="note">
                      Tag No. <span class="danger">*</span> : 
                    </label>
                    <div class="col-12 col-sm-12 col-md-9 col-lg-9">
                      <form-group
                      [control]="form.controls.noqt_tag_no"
                      [submitted]="submitted">
                      <input
                          type="text"
                          placeholder="Tag No."
                          id="noqt_tag_no"
                          class="form-control"
                          formControlName="noqt_tag_no"
                      />
                      </form-group>
                    </div>
                  </div>
                </div>

              </div>
            </div>
            </div>
          </div>
      
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ตกลง
        </button>
      </div>
    </div>
  </div>
</div>

<app-confirm-delete [id]="'modalConfirmDelFile'" (save)="deleteFile($event)"></app-confirm-delete>
