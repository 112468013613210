<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-8 col-12 mb-2">
        <h3 class="content-header-title mb-1">
          รายการ method
        </h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/scope']">ข้อมูลบริการสอบเทียบ</a>
              </li>
              <li class="breadcrumb-item active">
                รายการ method
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-4 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            [routerLink]="['/scope']"
            type="button"
            class="btn btn-light btn-min-width mr-1"
          >
            <i class="fa fa-arrow-left"></i> กลับ
          </button>
          <button
            type="button"
            class="btn btn-outline-primary"
            data-toggle="modal"
            data-target="#create-method"
          >
            <i class="fa fa-plus-circle icon-left"></i> เพิ่ม Method
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">
                รายการ Method
              </h4>
              <div class="heading-elements">
                <search-box (search)="search($event)"></search-box>
              </div>
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th width="5%" class="text-center">ลำดับ</th>
                        <th>Method name</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let method of methods; let i = index">
                        <th class="text-center">{{ method.i }}</th>
                        <td>{{ method.method_name }}</td>

                        <td>
                          <button
                            class="btn mr-1 mb-1 btn-warning btn-sm btn-edit"
                            data-target="#edit-method"
                            data-toggle="modal"
                            (click)="select(method.mit_method_id)"
                          >
                            <i class="fa fa-pencil icon-left"></i> แก้ไข
                          </button>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 btn-danger btn-sm"
                            data-target="#modalConfirmDelMsg"
                            data-toggle="modal"
                            (click)="select(method.mit_method_id)"
                            [disabled] = "method.isUsed"
                          >
                            <i class="fa fa-trash icon-left"></i> ลบ
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="!methods.length">
                        <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
      <app-method-create (done)="doReset($event)"></app-method-create>
      <app-method-edit
        [mit-method-id]="mit_method_id"
        *ngIf="mit_method_id"
        (done)="doReset($event)"
      ></app-method-edit>
    </div>
  </div>
</div>

<app-confirm-delete (save)="delete($event)"></app-confirm-delete>
