<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">การแปลงหน่วย</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/unit/unit-type']">ตั้งค่าประเภทหน่วยนับ</a>
              </li>
              <li class="breadcrumb-item active">การแปลงหน่วย</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            [routerLink]="['/unit/unit-type']"
            type="button"
            class="btn btn-warning btn-min-width mr-1"
          >
            <i class="fa fa-times icon-left"></i> ยกเลิก
          </button>
          <button
            (click)="submit()"
            type="button"
            class="btn btn-primary btn-min-width"
          >
            <i class="fa fa-save icon-left"></i> บันทึก
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">รายการหน่วยนับ</h4>
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered table-sm" cdkDropList (cdkDropListDropped)="drop($event)">
                    <thead>
                      <tr>
                        <th>ลำดับ</th>
                        <th>ชื่อหน่วยนับ</th>
                        <th>หน่วยย่อ</th>
                        <th>value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let unit of units; let i = index" cdkDrag>
                        <td cdkDragHandle>
                          <div class="d-flex flex-row align-items-center cursor-pointer">
                            <i class="fa fa-ellipsis-v mr-2" style="color: #ccc;"></i>
                            <div class="flex-1">
                              {{ i + 1 }}
                            </div>
                          </div>
                        </td>
                        <div *cdkDragPreview>
                          <div class="card px-5 py-2">
                            <div>
                              <b>ชื่อหน่วยนับ:</b> {{unit.unit_info?.unit_name_en}}
                            </div>
                            <div>
                              <b>หน่วยย่อ:</b> {{unit.unit_info?.unit_short_name_en}}
                            </div>
                            <div>
                              <b>value:</b> {{unit.base_value}}
                            </div>
                          </div>
                        </div>
                        <td>{{ unit.unit_info?.unit_name_en }}</td>
                        <td
                          [innerHTML]="unit.unit_info?.unit_short_name_en"
                        ></td>
                        <td>
                          <input
                            type="text"
                            id="base_value"
                            class="form-control form-control-sm placeholder-right"
                            [(ngModel)]="unit.base_value"
                          />
                        </td>
                      </tr>
                      <tr *ngIf="!units.length">
                        <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
