import { Component, OnInit, NgZone, ViewChild, ElementRef, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { 
  ConvertService,
  QuotationUtilsService,
  QuotationMitItemDetailService,
  QuotationMitItemDetailPointService,
  QuotationMitItemDetailRangeService,
 } from '../../../../../services/quotations';

import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FormMitService } from '../quotation-item.service';
import { generateMitItemLogService, MITEquipmentService, UtilsService } from 'src/app/services';
import { NavigationStart, Router } from '@angular/router';

import { 
  JobOrderCalibrationItemService,
  JobOrderInfoService
} from 'src/app/services/job-orders';

@Component({
  selector: 'app-quotation-item-job-order-create',
  templateUrl: './quotation-item-job-order-create.component.html',
  styleUrls: ['./quotation-item-job-order-create.component.css']
})
export class QuotationItemJobOrderCreateComponent implements OnInit {

  form: FormGroup;
  attributeList: FormArray;
  detailList: FormArray;
  submitted = false;
  subject: Subject<string> = new Subject();
  subject_convert_range: Subject<string> = new Subject();

  service = "";
  cps = []; units = []; tmpMitItemDetails = []; scopeList = [];
  equipmentList = []; 
  methodList = []; 

  oldRange = [];
  oldEquipmentList = []; 
  oldServiceEquipmentList = [];

  jobOrderList = []; 
  jobOrderItemList = [];

  is_show_attributes: boolean = false;
  is_show_deatils: boolean = false;
  filterMethod:string = "";

  @Output() done = new EventEmitter<any>();
  @Input() dropDownData: any;
  @Input() customer_id: string;
  @Input() quotationMitItems: any;
  
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  mitItem = null;
  constructor(
    private zone: NgZone,
    private router: Router,
    private formBuilder: FormBuilder,
    private ConvertService: ConvertService,
    private MITEquipmentService: MITEquipmentService,
    private formMitService: FormMitService,
    private generateMitItemLogService: generateMitItemLogService,
    private QuotationMitItemDetailService: QuotationMitItemDetailService,
    private QuotationMitItemDetailPointService: QuotationMitItemDetailPointService,
    private QuotationMitItemDetailRangeService: QuotationMitItemDetailRangeService,
    private JobOrderInfoService: JobOrderInfoService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService,

    public UtilsService: UtilsService,
    public _utils : QuotationUtilsService,
  ){
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.createForm();
  }
  ngOnInit() {}

  async load(){

    if(this.dropDownData){
      this.scopeList = this.dropDownData.scopeList;
      this.scopeList = this.scopeList.map(function(elem) {
        return {
          id: elem.mit_scope_id,
          text: elem.scope_name
        }
      });
      this.cps = this.dropDownData.cps || [];
      this.units = this.dropDownData.units || [];
    }
    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.calculateTotalAmountItemDetail()
    );
    this.subject_convert_range.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.convertSpecificRange(parseInt(x))
    );
  }

  ngOnChanges(changes: SimpleChanges) {

    this.form.get('job_order_info_id').setValidators([Validators.required]);
    this.form.get('job_order_calibration_item_id').setValidators([Validators.required]);
    this.form.get('job_order_calibration_item_id').disable();
    this.form.updateValueAndValidity();
    this.load();
    if(this.customer_id){
      this.getJobOrder(this.customer_id);
    }
  }

  async getJobOrder(customer_id: string){
    await this.JobOrderInfoService.load(null, {customer_id: customer_id, '$job_order_calibration_item_info.quotation_info_id$': 'new'}).then(res=>{

      this.jobOrderList = res.resultData || [];
      
      this.jobOrderList = this.jobOrderList.map(function(elem) {
        return {
          id: elem.job_order_info_id,
          text: elem.doc_no,
          data: elem
        }
      });
    })
  }

  createForm(data?){
    this.form = this.formBuilder.group({
      job_order_info_id: [""],
      job_order_calibration_item_id: [""],
      quotation_mit_item_id: [""],
      mit_scope_id: ["", [Validators.required]],
      mit_equipment_id: ["", [Validators.required]],
      mit_method_id: [""],
      method_name: [""],
      service_lab: [false],
      service_onsite: [false],
      service_location: ["", [Validators.required]],
      marker: [""],
      description: [""],
      model: [""],
      serial_no: [""],
      tag_no: [""],
      due_month: [""],
      order_by: [0],
      original_price: [0],
      scope_name: [""], 
      equipment_name: [""], 
      note_1: [""], 
      note_2: [""],
      cp_list_init: [""],
      cp_list_cali: [""],
      price: [null],
      discount: [null],
      quotation_mit_item_attributes: this.formBuilder.array([]),
      quotation_mit_item_details: this.formBuilder.array([]),
      mit_item_logs: [[]],
    }, {validator: [this._utils.customValidateRequirePoints(), this._utils.customValidateOneType()]});

    this.disableForm();

    this.attributeList = 
    this.form.get('quotation_mit_item_attributes') as FormArray;
    this.detailList = 
    this.form.get('quotation_mit_item_details') as FormArray;

    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Channel',
      attribute_value: ''
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Sensor',
      attribute_value: ''
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Display',
      attribute_value: ''
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Function',
      attribute_value: ''
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Element',
      attribute_value: ''
    }));
  }

  disableForm(){
    Object.keys(this.form.value).map(item=>{
      if(item == 'job_order_info_id' || item == 'job_order_calibration_item_id'){
        this.form.controls[item].enable();
      }else{
        this.form.controls[item].disable();
      }
    });
  }

  disableFormArray(){
    this.detailList.controls.map((detail, index)=>{
      let control = (<FormArray>this.form.get('quotation_mit_item_details')).controls[index];
        Object.keys(control.value).map(item=>{
          control['controls'][item].disable();
      })
    })
    this.attributeList.controls.map((att, index)=>{
      let control = (<FormArray>this.form.get('quotation_mit_item_attributes')).controls[index];
        Object.keys(control.value).map(item=>{
          control['controls'][item].disable();
      })
    })
  }

  get attributeItemFormGroup() {
    return this.form.get('quotation_mit_item_attributes') as FormArray;
  }

  get detailItemFormGroup() {
    return this.form.get('quotation_mit_item_details') as FormArray;
  }

  pointItemFormGroup(form) {
     return form.controls.quotation_mit_item_detail_points.controls;
  }

  async getEquipments(mit_scope_id){
    await this.MITEquipmentService.load(null, {mit_scope_id: mit_scope_id}).then((res) => {
      if(res.resultData){
        this.oldEquipmentList = res.resultData || [];
        this.oldEquipmentList = this.oldEquipmentList.map(function(elem) {
          return {
            data: elem,
            id: elem.mit_equipment_id,
            text: elem.equipment_name
          }
        });
        this.equipmentList =  this.oldEquipmentList;
      }
    });
  }

  async selectJobOrder(event){
    if(event){
      this.selectScope('');

      this.form.get('job_order_calibration_item_id').enable();
      
      await this.JobOrderCalibrationItemService.load(null, {job_order_info_id: event, quotation_info_id: 'new'}).then(res=>{

        this.jobOrderItemList = res.resultData.filter(filter=> !this.quotationMitItems.find(item=> item.job_order_calibration_item_id == filter.job_order_calibration_item_id)).map(function(elem) {
          return {
            data: elem,
            id: elem.job_order_calibration_item_id,
            text: elem?.quotation_mit_item_info?.description || 
            elem?.quotation_mit_item_info?.equipment_name
          }
        });
      });
    }else{
      this.jobOrderItemList = [];
    }
  }

  async selectJobOrderItem(event){
    if(event){
      this.selectScope('');

      this.mitItem = this.jobOrderItemList.find(t=> t.id == event).data.quotation_mit_item_info;

      if(this.mitItem){
        await this.QuotationMitItemDetailService
        .load(null ,{ quotation_mit_item_id: this.mitItem.quotation_mit_item_id, 'order_by': 'order_by:asc' })
        .then(async (res) => {
          this.mitItem['quotation_mit_item_details'] = [];
          
          await Promise.all(res.resultData.map(async (data: any)=>{
            let resultData = await this.mapMitItemData(data);

            this.mitItem.quotation_mit_item_details.push(resultData);
          }));

          this.mitItem['quotation_mit_item_details'].sort((a, b) => (a.order_by > b.order_by? 1 : -1));
        });
      }

      let data = {
        quotation_mit_item_id: this.mitItem.quotation_mit_item_id || undefined,
        mit_scope_id: this.mitItem.mit_scope_id,
        mit_equipment_id: this.mitItem.mit_equipment_id,
        mit_method_id: this.mitItem?.mit_method_id,
        method_name: this.mitItem?.method_name,
        service_lab: this.mitItem.service_location.toUpperCase().includes('LAB'),
        service_onsite: this.mitItem.service_location.toUpperCase().includes('ONSITE'),
        service_location: this.mitItem.service_location.toUpperCase().includes('LAB')? 'Lab': 'Onsite',
        marker: this.mitItem.marker || '',
        description: this.mitItem.description || '',
        model: this.mitItem.model || '',
        serial_no: this.mitItem.serial_no || '',
        tag_no: this.mitItem.tag_no || '',
        due_month: this.mitItem.due_month || '',
        order_by: 0,
        original_price: this.mitItem.original_price,
        scope_name: this.mitItem.scope_name, 
        equipment_name: this.mitItem.equipment_name, 
        note_1: this.mitItem.note_1, 
        note_2: this.mitItem.note_2,
        cp_list_init: this.mitItem.cp_list_cali,
        cp_list_cali: this.mitItem.cp_list_cali,
        price: this.mitItem.price || null,
        discount: this.mitItem.discount || null,
        quotation_mit_item_attributes: [],
        quotation_mit_item_details: [],
        mit_item_logs: [],
        cus_calibration_point: ""
      }
      this.form.patchValue(data);

      this.selectScope(this.mitItem.mit_scope_id)
    }
  }

  async mapMitItemData(mit: any): Promise<any>{
    let points = [];
    let range = [];
    if(mit.total_amount > 0){
      if(mit.detail_input_type == 'point'){
        points = await this.QuotationMitItemDetailPointService
        .load(null ,{ quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id, 'order_by': 'order_by:asc'})
        .then((res) => {
          return res.resultData;
        });
      }else{
        range = await this.QuotationMitItemDetailRangeService
        .load(null ,{ quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id })
        .then((res) => {
          return res.resultData;
        });  
      }
    }
    await points.map(point=>{
      if(point.cus_unit_info) point['cus_unit_name'] = point.cus_unit_info.unit_short_name_en 
      || point.cus_unit_info.unit_short_name_th;
      if(point.mit_unit_info) point['mit_unit_name'] = point.mit_unit_info.unit_short_name_en 
      || point.mit_unit_info.unit_short_name_th;
    });
    return {
      ...mit,
      detail_type: mit.detail_input_type,
      quotation_mit_item_detail_range: {
        quotation_mit_item_detail_range_id: range[0]?.quotation_mit_item_detail_range_id || "", 
        cus_min: range[0]?.cus_min || 0,
        cus_max: range[0]?.cus_max || 0, 
        cus_unit_id: range[0]?.cus_unit_id || "", 
        cus_unit_name: range[0]?.cus_unit_info.unit_short_name_en || "", 
        mit_min: range[0]?.mit_min || 0,
        mit_max: range[0]?.mit_max || 0,
        mit_unit_id: range[0]?.mit_unit_id || "", 
        mit_unit_name: range[0]?.mit_unit_info.unit_short_name_en || "",  
        
      },
      unit_name: mit.unit_short_name_en,
      cus_unit_id: mit.unit_id,
      min_range: mit.product_calibration_fee_info?.min_range,
      max_range:  mit.product_calibration_fee_info?.max_range,
      quotation_mit_item_detail_points: points
    }
  }

  async selectScope(event){
    this.getNote();
    this.oldServiceEquipmentList = [];
    
    if(event){
      const equipment = 
      this.oldEquipmentList.filter(item => (
        item.data?.mit_scope_id === event)
      );
      this.equipmentList = equipment;
      await this.getEquipments(event);
      this.detailList.clear();
      
      if(this.mitItem.mit_scope_id == event){
        this.selectEquipment(this.mitItem.mit_equipment_id);
      }else{
        
        this.form.get('mit_equipment_id').setValue(null);

        this.clearMethod();
        this.clearServiceLocation();
        this.getCPList(null);
        this.form.get('original_price').setValue(0);
      }

      let scope = this.scopeList.find(scope => scope.id == event);
      if(scope)this.form.get('scope_name').setValue(scope.text);
    }else{
      this.clearMethod();
      this.clearServiceLocation();
      this.getCPList(null);
      this.form.get('original_price').setValue(0);

      this.detailList.clear();

      this.form.get('mit_scope_id').setValue(null);
      this.form.get('mit_equipment_id').setValue(null);
      this.form.get('scope_name').setValue("");
    }
  }

  async selectEquipment(event){
    this.oldRange = [];

    if(event){
      if(this.mitItem.mit_equipment_id == event){
        this.detailList.clear();
        
        const equipment = this.getEquipmentList(event);
        this.getNote(equipment);

        if(this.mitItem.quotation_mit_item_details){

          /////
          let detailList = this.getDetailList(equipment);

          /////

          detailList.map(async (v: any, index: number)=>{
            let detail = this.mitItem.quotation_mit_item_details.find(item=> item.product_calibration_fee_id == v.product_calibration_fee_id);
            if(detail){
              if(equipment){
                this.detailList.push(this.formMitService.createDetailItemEdit(detail, equipment.data));

                this.form.get('equipment_name').setValue(equipment.text);
              }
    
              this.oldRange.push({min: detail.min_range, max: detail.max_range});

              if(v.detail_type == 'range'){
                let selectRange = detail.quotation_mit_item_detail_range;
                this.detailList.controls[index].get('cus_unit_id').setValue(selectRange.cus_unit_id);
                this.detailList.controls[index].get('cus_unit_name').setValue(selectRange.cus_unit_name);

                if(selectRange.cus_unit_id != selectRange.mit_unit_id){
                  const response = await this.getConvertRange(selectRange.cus_unit_id, detail);
                  this.detailList.controls[index].get('min_range').setValue(isNaN(response?.min_range)? 0: response?.min_range);
                  this.detailList.controls[index].get('max_range').setValue(isNaN(response?.max_range)? 0: response?.max_range);
                }
              }else{
                const control = 
                this.detailList.controls[index].get('quotation_mit_item_detail_points') as FormArray;
                let selectPoint = null;
                if(detail.quotation_mit_item_detail_points.length > 0){
                  detail.quotation_mit_item_detail_points.map(p=>{
                    if(selectPoint == null) selectPoint = p;
                    control.push(this.formMitService.createPointItem(p));
                  })

                  this.detailList.controls[index].get('cus_unit_id').setValue(selectPoint.cus_unit_id);
                  this.detailList.controls[index].get('cus_unit_name').setValue(selectPoint.cus_unit_name);
                  if(selectPoint.cus_unit_id != selectPoint.mit_unit_id){
                    const response = await this.getConvertRange(selectPoint.cus_unit_id, detail);
                    this.detailList.controls[index].get('min_range').setValue(response?.min_range);
                    this.detailList.controls[index].get('max_range').setValue(response?.max_range);
                  }
                }
              }
            }else{
              this.detailList.push(this.formMitService.createDetailItem(v, equipment.data));

              this.oldRange.push({min: v.min_range, max: v.max_range});
            }
          });
        }

        this.form.get('service_lab').setValue(this.mitItem.service_location.toUpperCase().includes('LAB'));
        this.form.get('service_onsite').setValue(this.mitItem.service_location.toUpperCase().includes('ONSITE'));

        this.disableFormArray();
        this.calculateTotalAmountItemDetail();
      }else{
        this.detailList.clear();

        const equipment = this.getEquipmentList(event);;
        this.getNote(equipment);
        this.getCPList(null);

        if(equipment){

          this.form.get('equipment_name').setValue(equipment.text);
        }

        this.clearMethod();
        this.form.get('service_lab').setValue(false);
        this.form.get('service_onsite').setValue(false);
        this.form.get('original_price').setValue(0);
      }

      this.tmpMitItemDetails = this.detailList.getRawValue();

      if(this.mitItem.mit_equipment_id == event){
        this.setMethodList(this.tmpMitItemDetails, 'init');
      }

      if(this.form.getRawValue().service_lab && !this.form.getRawValue().service_onsite){
        this.detailList.controls.map(v=>{
          v.get('service_location').setValue('LAB');
        })
      }else if(!this.form.getRawValue().service_lab && this.form.getRawValue().service_onsite){
        this.detailList.controls.map(v=>{
          v.get('service_location').setValue('ONSITE');
        })
      }
    }else{
      this.clearMethod();
      this.clearServiceLocation();
      this.getCPList(null);
      this.form.get('equipment_name').setValue("");
    }
  }

  setMethodList(items, type?){
    if(items.length > 0){
      this.methodList = items.map(function(elem) {
        return {
          id: elem.mit_method_id,
          text: elem.method_name
        }
      });
      this.methodList = this.methodList.filter((method, index, self) =>
        index === self.findIndex((t) => (
          t.id === method.id
        ))
      );

      if(type != 'init'){
        this.form.get('mit_method_id').setValue(this.methodList[0].id);
        this.form.get('method_name').setValue(this.methodList[0].text);
      }
    }else{
      this.methodList = [];
      this.form.get('mit_method_id').disable();
    }
  }

  async selectServiceLocation(event){
  
    if(event == 'lab'){
      await this.setServiceLocation(true, false);
    }else{
      await this.setServiceLocation(false, true);
    }
    await this.setService();
  }

  async setServiceLocation(lab, onsite){
    this.form.get('service_lab').setValue(lab);
    this.form.get('service_onsite').setValue(onsite);
    if(lab && onsite){
      this.form.get('service_location').setValue('Lab, Onsite');
    }else if(lab){
      this.form.get('service_location').setValue('Lab');
    }else if(onsite){
      this.form.get('service_location').setValue('Onsite');
    }else{
      this.form.get('service_location').setValue("");
    }
  }

  async setService(){
    this.detailList.clear();

    const equipment = this.getEquipmentList(this.form.getRawValue().mit_equipment_id);

    this.setOptionServiceLocation(equipment.data.service_location);

    let detailList = this.getDetailList(equipment);

    this.getCPList(detailList);
    

    detailList = this.sortDetailList(detailList);

    await detailList.map((v: any)=>{

      this.detailList.push(this.formMitService.createDetailItem(v, equipment.data));

      this.oldRange.push({min: v.min_range, max: v.max_range});
    });

    this.tmpMitItemDetails = this.detailList.getRawValue();

    this.setMethodList(this.tmpMitItemDetails);

    this.setServiceLocationDetail();
  }

  setServiceLocationDetail(){
    if(this.form.getRawValue().service_lab && !this.form.getRawValue().service_onsite){
  
      this.detailList.controls.map((v: any)=>{
        v.get('service_location').setValue('LAB');

      })
    }else if(!this.form.getRawValue().service_lab && this.form.getRawValue().service_onsite){

      this.detailList.controls.map((v: any)=>{
        v.get('service_location').setValue('ONSITE');
      })
    }
  }

  clearMethod(){
    this.form.get('mit_method_id').disable();
    this.form.get('mit_method_id').setValue("");
    this.form.get('method_name').setValue("");
  }

  clearServiceLocation(){
    this.form.get('service_lab').disable();
    this.form.get('service_lab').setValue(false);

    this.form.get('service_onsite').disable();
    this.form.get('service_onsite').setValue(false);
  }

  getNote(equipment = null){
    if(equipment) {
      this.form.get('note_1').setValue(equipment.data.note_1);
      this.form.get('note_2').setValue(equipment.data.note_2);
    }else{
      this.form.get('note_1').setValue("");
      this.form.get('note_2').setValue("");
    }
  }

  getCPList(detailList){
    let formValue = this.form.value;

    let getCPInfo = this.equipmentList.find(eq=> eq.data.mit_equipment_id == formValue.mit_equipment_id);
    
    if(detailList?.length > 0) {
      this.form.get('cp_list_init').setValue(formValue.service_location.toUpperCase() == 'LAB'? getCPInfo.data?.lab_mit_cp_list: getCPInfo.data?.onsite_mit_cp_list || "");
      this.form.get('cp_list_cali').setValue(formValue.service_location.toUpperCase() == 'LAB'? getCPInfo.data?.lab_mit_cp_list: getCPInfo.data?.onsite_mit_cp_list || "");
    }else{
      this.form.get('cp_list_init').setValue("");
      this.form.get('cp_list_cali').setValue("");
    }
  }

  getEquipmentList(event){
    const res = this.oldEquipmentList.filter(v => (
      v.id === event)
    );
    return  res[0];
  }

  getDetailList(equipment){
    let value = this.form.getRawValue();
    if(value.service_location.toUpperCase().includes('LAB') && value.service_location.toUpperCase().includes('ONSITE')){
      return  equipment.data?.product_calibration_fee_info || [];
    }else if(value.service_location.toUpperCase().includes('LAB')){
      return  equipment.data?.product_calibration_fee_info.filter(item=> item.service_location.trim() == "LAB") || [];
    }else{
      return  equipment.data?.product_calibration_fee_info.filter(item=> item.service_location.trim() == "ONSITE") || [];
    }
  }

  sortDetailList(items){
    items.sort(function(a, b) {
      if (a.mit_method_info.method_name > b.mit_method_info.method_name) return 1;
      if (a.mit_method_info.method_name < b.mit_method_info.method_name) return -1;
      
      if (a.unit_info.unit_short_name_th > b.unit_info.unit_short_name_th) return 1;
      if (a.unit_info.unit_short_name_th < b.unit_info.unit_short_name_th) return -1;

      return parseFloat(a.min_range) - parseFloat(b.min_range);
    });
    return items;
  }

  setOptionServiceLocation(service_location = null){
    if (service_location.includes('ONSITE') && service_location.includes('LAB')){
      this.service = "";
    }else if(service_location.includes('ONSITE')){
      this.service = "ONSITE";
    }else{
      this.service = "LAB";
    }
  }

  async getConvertRange(unitId, detailList): Promise<any>{
    if(unitId && detailList.unit_id){
      let convertMinRange = await this.ConvertService.convert({
        unit_id_2: unitId,
        unit_id_1: detailList.unit_id,
        val: detailList.min_range
      });
      let convertMaxRange = await this.ConvertService.convert({
        unit_id_2: unitId,
        unit_id_1: detailList.unit_id,
        val: detailList.max_range
      });
      return { 
        min_range: isNaN(convertMinRange.resultData.val)? 0: convertMinRange.resultData.val,
        max_range: isNaN(convertMaxRange.resultData.val)? 0: convertMaxRange.resultData.val,
      }
    }
  }

  calculateTotalAmountItemDetail(){
    const getDetailList = this.detailList.value.filter(item =>(
      (item.min_point != 0 && item.min_point != null) && 
      (item.quotation_mit_item_detail_points.length > 0 ||
        (item.quotation_mit_item_detail_range.cus_unit_id != null &&
          item.quotation_mit_item_detail_range.cus_unit_id != '')
      )
    ));

    let detail_high_price_start = null;
    if(getDetailList.length > 0){
      detail_high_price_start = getDetailList.reduce((a, b) => a.price_start > b.price_start ? a : b);
    }

    this.detailList.controls.map((v, index)=>{
      const control = v.value;
      const number_of_detail_points = control.quotation_mit_item_detail_points.length;
      if(control.detail_type == 'point'){
        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
          if(control.min_point == number_of_detail_points){
            this.detailList.controls[index].get('total_amount').setValue(control.price_start);
          }else if(control.min_point > number_of_detail_points){
            this.detailList.controls[index].get('total_amount').setValue(number_of_detail_points * control.price_point);
          }else if (control.min_point < number_of_detail_points){
            const dif = number_of_detail_points - control.min_point;
            this.detailList.controls[index].get('total_amount').setValue((dif * control.price_point) + control.price_start);
          }
        }else{
          this.detailList.controls[index].get('total_amount').setValue(number_of_detail_points * control.price_point);
        }
      }else if(control.detail_type == 'range'){

        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
    
          this.detailList.controls[index].get('total_amount').setValue(control.price_start);
        }else{
          this.detailList.controls[index].get('total_amount').setValue(control.min_point * control.price_point);
        }
      }
    })  
    this.calculateTotalAmountItem();
  }

  calculateTotalAmountItem(){
    const form = this.form.getRawValue();
    let countOfItem = 0;
    let priceStart = 0;
    let isMinPoint = false;
    if(form.quotation_mit_item_details.length > 0){
      form.quotation_mit_item_details.map((v: any)=>{
        if(v.total_amount != 0 && priceStart < v.price_start) {
          priceStart = v.price_start;
        }
        if(v.total_amount != 0 && v.min_point != 0) {
          isMinPoint = true;
        } 
        countOfItem = countOfItem + v.total_amount;
      })
    }
    let count = countOfItem;

    if(isMinPoint){
    }else{
      count = count + priceStart;
    }
    this.form.get('original_price').setValue(count);
  }

  async convertSpecificRange(index: number){
    let selectRange = this.detailList.controls[index].value.quotation_mit_item_detail_range;
   if(selectRange.mit_unit_id != selectRange.cus_unit_id){
      let convertMinRange = await this.ConvertService.convert({
        unit_id_1: selectRange.cus_unit_id,
        unit_id_2: selectRange.mit_unit_id,
        val: this._utils.convertStingToNumber(selectRange.cus_min) || 0
      });
      let convertMaxRange = await this.ConvertService.convert({
        unit_id_1: selectRange.cus_unit_id,
        unit_id_2: selectRange.mit_unit_id,
        val: this._utils.convertStingToNumber(selectRange.cus_max) || 0
      });
      this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
        cus_min: this._utils.transformDecimal(selectRange.cus_min, '1.1-5'),
        cus_max: this._utils.transformDecimal(selectRange.cus_max, '1.1-5'),
        mit_min: isNaN(convertMinRange.resultData.val)? 0: convertMinRange.resultData.val,
        mit_max: isNaN(convertMaxRange.resultData.val)? 0: convertMaxRange.resultData.val,
      });
    }else{
      this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
        cus_min: this._utils.transformDecimal(selectRange.cus_min, '1.1-5'),
        cus_max: this._utils.transformDecimal(selectRange.cus_max, '1.1-5'),
        mit_min: selectRange.cus_min,
        mit_max: selectRange.cus_max,
      });
    }
  }

  async submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    let info = this.jobOrderItemList.find(item=> item.id == this.form.getRawValue().job_order_calibration_item_id);

    let data = {
      ...this.form.getRawValue(),
      job_order_calibration_item_info: info.data
    }
    data = await this.generateMitItemLogService.createMitItemLog(data);
    this.detailList.controls.map((v, index)=>{
      v.get('min_range').setValue(this.oldRange[index].min);
      v.get('max_range').setValue(this.oldRange[index].max);
    });

    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.form.get('mit_equipment_id').disable();
        this.form.get('mit_method_id').disable();
        this.form.get('service_lab').disable();
        this.form.get('service_onsite').disable();
        this.createForm();
      }
    });
  }

  onKeyup(){
    this.subject.next();
  }

  onKeyupRange(i){
    this.subject_convert_range.next(i);
  }

  getUnits(unit_id: string): any{
    let result = [];
    this.units.map(v=>{
      const unit = v.units.filter(unit => unit.unit_id == unit_id);
      if (unit.length > 0){
        result = v.units;
      }
    })
    return result;
  }
}
