import { Component, Input, OnInit, Output, ViewChild, EventEmitter, ElementRef, NgZone, ChangeDetectorRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import {
  generateMitItemLogService, 
  MITEquipmentService, 
  UnitConversionService 
} from 'src/app/services';
import { ConvertService, QuotationUtilsService } from 'src/app/services/quotations';
import { FormQuotationMitService } from './edit-quotation-mit-item.service';

@Component({
  selector: 'app-edit-quotation-mit-item',
  templateUrl: './edit-quotation-mit-item.component.html',
  styleUrls: ['./edit-quotation-mit-item.component.css']
})
export class EditQuotationMitItemComponent implements OnInit {
  form: FormGroup;
  attributeList: FormArray;
  detailList: FormArray;
  submitted:boolean = false;
  isLoading:boolean = false;

  @Input('dropDownData') dropDownData: any;
  @Input('isCalibration') isCalibration: any;
  @Input('jobOrder') jobOrder: any;

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  scopeList = []; 
  attributes = []; 
  equipmentList = []; 
  methodList = [];

  delMitItemDetailList = []; 
  delMitItemDetailPointList = [];
  delMitItemDetailRangeList = []; 
  delMitItemAttributeList = [];

  cps = []; 
  units = []; 
  unit_conversations = []; 

  oldRange = [];
  oldMitItemDetails = []; 
  oldEquipmentList = [];
  oldPrice: number = 0;

  oldMitItem: any;
  oldItem: boolean = true;
  is_show_attributes: boolean = false;
  is_show_deatils: boolean = false;
  is_change_point: boolean = false;
  
  filterMethod: string  = "";
  service: string = "";
  pageType: string = "create";

  subject: Subject<string> = new Subject();
  subject_convert_range: Subject<string> = new Subject();

  constructor(
    private zone: NgZone,
    private router: Router,

    private cdRef:ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private ConvertService: ConvertService,
    private formMitService: FormQuotationMitService,
    private MITEquipmentService: MITEquipmentService,
    private UnitConversionService:UnitConversionService,
    private generateMitItemLogService: generateMitItemLogService,

    public utils : QuotationUtilsService,
  ) {
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
   }

  async ngOnInit() {
    this.isLoading = true;

    if(this.jobOrder.quotation_mit_item_info){

      this.createForm(this.jobOrder.quotation_mit_item_info);

      this.oldPrice = this.jobOrder.quotation_mit_item_info.price;
      this.oldMitItem = this.jobOrder.quotation_mit_item_info;
    }

    if(this.dropDownData){
      this.scopeList = this.dropDownData.scopeList;
      this.scopeList = this.scopeList.map(function(elem) {
        return {
          id: elem.mit_scope_id,
          text: elem.scope_name,
          data: elem
        }
      });
      this.cps = this.dropDownData.cps || [];
      this.units = this.dropDownData.units || [];
    }

    await this.UnitConversionService.load(null).then((response) => {
      if (response.success) {
        this.unit_conversations = response.resultData || [];
      }
    });
    if(this.jobOrder.quotation_mit_item_info){
      this.selectScope(this.jobOrder.quotation_mit_item_info['mit_scope_id']);
    }
    
    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.calculateTotalAmountItemDetail()
    );
    this.subject_convert_range.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.convertSpecificRange(parseInt(x))
    );
  }

  createForm(mitItem){
    this.form = this.formBuilder.group({
      quotation_mit_item_id: [mitItem.quotation_mit_item_id || undefined],
      mit_scope_id: [mitItem.mit_scope_id, [Validators.required]],
      mit_scope_info: [mitItem.mit_scope_info],
      mit_equipment_id: [mitItem.mit_equipment_id, [Validators.required]],
      mit_equipment_info: [mitItem.mit_equipment_info],
      mit_method_id: [mitItem?.mit_method_id],
      method_name: [mitItem?.method_name],
      service_lab: [mitItem.service_location.toUpperCase().includes('LAB')],
      service_onsite: [mitItem.service_location.toUpperCase().includes('ONSITE')],
      service_location: [mitItem.service_location, [Validators.required]],
      marker: [mitItem.marker || ''],
      description: [mitItem.description || ''],
      model: [mitItem.model || ''],
      serial_no: [mitItem.serial_no || ''],
      tag_no: [mitItem.tag_no || ''],
      due_month: [mitItem.due_month || ''],
      order_by: [0],
      original_price: [mitItem.original_price],
      scope_name: [mitItem.scope_name], 
      equipment_name: [mitItem.equipment_name], 
      note_1: [mitItem.note_1], 
      note_2: [mitItem.note_2],
      cp_list_init: [mitItem.cp_list_init],
      cp_list_cali: [mitItem.cp_list_cali],
      price: [mitItem.price || null],
      discount: [mitItem.discount || 0],
      price_addition_attributes: [mitItem.price_addition_attributes],
      price_start: [mitItem.price_start],
      price_addition_points: [mitItem.price_addition_points],
      quotation_mit_item_attribute_info: this.formBuilder.array([]),
      quotation_mit_item_detail_info: this.formBuilder.array([]),
      mit_item_logs: [[]],
      cus_calibration_point: "",

      old_price: [mitItem?.old_price? mitItem?.old_price: mitItem?.price],
      old_price_addition_attributes: [mitItem?.old_price_addition_attributes? mitItem?.old_price_addition_attributes:  mitItem?.price_addition_attributes],
      old_price_addition_points: [mitItem?.old_price_addition_points? mitItem?.old_price_addition_points: mitItem?.price_addition_points]
      
    }, {validator: [this.utils.customValidateRequirePoints(), this.utils.customValidateOneType()]});

    if(this.pageType == 'create'){
      this.form.get('mit_scope_id').disable();
      this.form.get('mit_equipment_id').disable();
      this.form.get('mit_method_id').disable();
    }else if(this?.jobOrder?.job_order_info?.doc_status_id != 'CONFIRMED' || !this.isCalibration){
      this.form.get('mit_scope_id').disable();
      this.form.get('mit_equipment_id').disable();
      this.form.get('mit_method_id').disable();
    }

    this.attributeList = 
    this.form.get('quotation_mit_item_attribute_info') as FormArray;
    this.attributes = this.jobOrder.quotation_mit_item_info.quotation_mit_item_attribute_info;

    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Channel',
      attribute_value: this.attributes.find(v=> v.attribute_name == 'Channel' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.attribute_value || '',
      quotation_mit_item_attribute_id: this.attributes.find(v=> v.attribute_name == 'Channel' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.quotation_mit_item_attribute_id || '',
      is_non_calculate: this.attributes.find(v=> v.attribute_name == 'Channel' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calculate || 'false',
      is_non_calibration: this.attributes.find(v=> v.attribute_name == 'Channel' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calibration || 'false',
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Sensor',
      attribute_value: this.attributes.find(v=> v.attribute_name == 'Sensor' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.attribute_value || '',
      quotation_mit_item_attribute_id: this.attributes.find(v=> v.attribute_name == 'Sensor' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.quotation_mit_item_attribute_id || '',
      is_non_calculate: this.attributes.find(v=> v.attribute_name == 'Sensor' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calculate || 'false',
      is_non_calibration: this.attributes.find(v=> v.attribute_name == 'Sensor' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calibration || 'false',
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Display',
      attribute_value: this.attributes.find(v=> v.attribute_name == 'Display' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.attribute_value || '',
      quotation_mit_item_attribute_id: this.attributes.find(v=> v.attribute_name == 'Display' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.quotation_mit_item_attribute_id || '',
      is_non_calculate: this.attributes.find(v=> v.attribute_name == 'Display' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calculate || 'false',
      is_non_calibration: this.attributes.find(v=> v.attribute_name == 'Display' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calibration || 'false',
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Function',
      attribute_value: this.attributes.find(v=> v.attribute_name == 'Function' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.attribute_value || '',
      quotation_mit_item_attribute_id: this.attributes.find(v=> v.attribute_name == 'Function' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.quotation_mit_item_attribute_id || '',
      is_non_calculate: this.attributes.find(v=> v.attribute_name == 'Function' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calculate || 'false',
      is_non_calibration: this.attributes.find(v=> v.attribute_name == 'Function' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calibration || 'false',
    }));
    this.attributeList.push(this.formMitService.createAttributeItem({
      attribute_name: 'Element',
      attribute_value: this.attributes.find(v=> v.attribute_name == 'Element' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.attribute_value || '',
      quotation_mit_item_attribute_id: this.attributes.find(v=> v.attribute_name == 'Element' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.quotation_mit_item_attribute_id || '',
      is_non_calculate: this.attributes.find(v=> v.attribute_name == 'Element' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calculate || 'false',
      is_non_calibration: this.attributes.find(v=> v.attribute_name == 'Element' && (v.is_non_calibration =='false' || !v?.is_non_calibration))?.is_non_calibration || 'false',
    }));

    this.detailList = 
    this.form.get('quotation_mit_item_detail_info') as FormArray;
    if(mitItem.delMitItemDetails){
      this.delMitItemDetailList = mitItem.delMitItemDetails;
    }
    if(mitItem.delMitItemDetailPoints){
      this.delMitItemDetailPointList = mitItem.delMitItemDetailPoints;
    }
    if(mitItem.delMitItemDetailRanges){
      this.delMitItemDetailRangeList =  mitItem.delMitItemDetailRanges;
    }
    if(mitItem.delMitItemAttributes){
      this.delMitItemAttributeList =  mitItem.delMitItemAttributes;
    }
  }

  get attributeItemFormGroup() {
    return this.form.get('quotation_mit_item_attribute_info') as FormArray;
  }

  get detailItemFormGroup() {
    return this.form.get('quotation_mit_item_detail_info') as FormArray;
  }

  pointItemFormGroup(form) {
     return form.controls.quotation_mit_item_detail_points.controls;
  }

  async setServiceLocation(lab, onsite){
    this.form.get('service_lab').setValue(lab);
    this.form.get('service_onsite').setValue(onsite);
    if(lab && onsite){
      this.form.get('service_location').setValue('Lab, Onsite');
    }else if(lab){
      this.form.get('service_location').setValue('Lab');
    }else if(onsite){
      this.form.get('service_location').setValue('Onsite');
    }else{
      this.form.get('service_location').setValue("");
    }
  }

  async setService(){}

  async getEquipments(mit_scope_id){
    await this.MITEquipmentService.load(null, {mit_scope_id: mit_scope_id}).then((res) => {
      if(res.resultData){
        this.oldEquipmentList = res.resultData;
        this.oldEquipmentList = this.oldEquipmentList.map(function(elem) {
          return {
            data: elem,
            id: elem.mit_equipment_id,
            text: elem.equipment_name
          }
        });
        this.equipmentList =  this.oldEquipmentList;
      }
    });
  }

  async selectScope(event){
    this.getNote();
    if(event){
      const equipment = 
      this.oldEquipmentList.filter(item => (
        item.data?.mit_scope_id === event)
      );
      this.equipmentList = equipment;
      await this.getEquipments(event);
      this.detailList.clear();
      if(this.jobOrder.quotation_mit_item_info.mit_scope_id == event){
      }else{
        this.form.get('mit_equipment_id').setValue(null);
        if(this.jobOrder.quotation_mit_item_info.quotation_mit_item_detail_info && this.delMitItemDetailList.length == 0){
          this.jobOrder.quotation_mit_item_info.quotation_mit_item_detail_info.map(item=>{
            if(item.quotation_mit_item_detail_id){
              this.delMitItemDetailList.push(item);
            }
          })
        }
        this.clearMethod();
        this.clearServiceLocation();
        this.form.get('original_price').setValue(0);
      }

      let mit_scope_info = this.scopeList.find(item=> item.id == event);
      if(mit_scope_info) this.form.get('mit_scope_info').setValue(mit_scope_info.data);
      
      let scope = this.scopeList.find(scope => scope.id == event);
      if(scope)this.form.get('scope_name').setValue(scope.text);
    }else{
      this.form.get('scope_name').setValue("");
    }
  }

  async selectEquipment(event){
    this.oldRange = [];
    if(event){
      if(this.jobOrder.quotation_mit_item_info.mit_equipment_id == event){
        this.detailList.clear();
        this.oldItem = true;
        const equipment = this.getEquipmentList(event);
        this.getNote(equipment);

        if(this.jobOrder.quotation_mit_item_info.quotation_mit_item_detail_info){
          /////
          let detailList = this.getDetailList(equipment);
     
          detailList = this.sortDetailList(detailList);
          /////
          detailList.map(async (v: any, index: number)=>{
            let detail = this.jobOrder.quotation_mit_item_info.quotation_mit_item_detail_info.find(item=> item.product_calibration_fee_id == v.product_calibration_fee_id);

            if(detail){
              if(equipment){
                this.detailList.push(this.formMitService.createDetailItemCurrent(detail, equipment.data));

                this.form.get('equipment_name').setValue(equipment.text);
              }
    
              this.oldRange.push({min: detail.min_range, max: detail.max_range});

              if(v.detail_type == 'range'){
                let selectRange = detail.quotation_mit_item_detail_range;
                this.detailList.controls[index].get('cus_unit_id').setValue(selectRange.cus_unit_id);
                this.detailList.controls[index].get('cus_unit_name').setValue(selectRange.cus_unit_name);

                this.detailList.controls[index].get('number_of_point').setValue(detail.min_point);
                if(selectRange.cus_unit_id != selectRange.mit_unit_id){
                  const response = await this.getConvertRange(selectRange.cus_unit_id, detail);
                  this.detailList.controls[index].get('min_range').setValue(isNaN(response?.min_range)? 0: response?.min_range);
                  this.detailList.controls[index].get('max_range').setValue(isNaN(response?.max_range)? 0: response?.max_range);
                }
              }else{
                const control = 
                this.detailList.controls[index].get('quotation_mit_item_detail_points') as FormArray;
                let selectPoint = null;
                if(detail.quotation_mit_item_detail_points.length > 0){
                  detail.quotation_mit_item_detail_points.map(p=>{
                    if(selectPoint == null) selectPoint = p;
                    control.push(this.formMitService.createPointItem(p));
                  })
                  this.detailList.controls[index].get('number_of_point').setValue(detail.quotation_mit_item_detail_points.filter(item=>item.is_non_calculate == 'false').length);

                  this.detailList.controls[index].get('cus_unit_id').setValue(selectPoint.cus_unit_id);
                  this.detailList.controls[index].get('cus_unit_name').setValue(selectPoint.cus_unit_name);
                  if(selectPoint.cus_unit_id != selectPoint.mit_unit_id){
                    const response = await this.getConvertRange(selectPoint.cus_unit_id, detail);
                    this.detailList.controls[index].get('min_range').setValue(response?.min_range);
                    this.detailList.controls[index].get('max_range').setValue(response?.max_range);
                  }
                }
              }
            }else{
              this.detailList.push(this.formMitService.createDetailItem(v, equipment.data));

              this.oldRange.push({min: v.min_range, max: v.max_range});
            }
          });
          this.detailList.value.map((v, index: number)=>{
            this.selectCP(v.mit_cp_id, index);
          });
          ////
        }

      }else{

        this.detailList.clear();
        this.oldItem = false;
        const equipment = this.getEquipmentList(event);;
        this.getNote(equipment);

        if(equipment){
          this.setOptionServiceLocation(equipment.data.service_location);

          let detailList = this.getDetailList(equipment);


          detailList = this.sortDetailList(detailList);

          detailList.map((v: any)=>{
            this.detailList.push(this.formMitService.createDetailItem(v, equipment.data));

            this.oldRange.push({min: v.min_range, max: v.max_range});
          });

          this.form.get('equipment_name').setValue(equipment.text);
        }
        this.detailList.value.map((v, index: number)=>{
          this.selectCP(v.mit_cp_id, index);
        });

        if(this.jobOrder.quotation_mit_item_info.quotation_mit_item_detail_info && this.delMitItemDetailList.length == 0){
          this.jobOrder.quotation_mit_item_info.quotation_mit_item_detail_info.map(v =>{
            if(v.quotation_mit_item_detail_id){
              this.delMitItemDetailList.push(v);
            }
          })
        }
        this.form.get('original_price').setValue(0);
      }
      this.oldMitItemDetails = this.detailList.value;
      if(this.jobOrder.quotation_mit_item_info.mit_equipment_id == event){
        this.setMethodList(this.oldMitItemDetails, 'init');
      }else{
        this.setMethodList(this.oldMitItemDetails);
      }

      if(this.form.value.service_lab && !this.form.value.service_onsite){
        this.detailList.controls.map(v=>{
          v.get('service_location').setValue('LAB');
        })
      }else if(!this.form.value.service_lab && this.form.value.service_onsite){
        this.detailList.controls.map(v=>{
          v.get('service_location').setValue('ONSITE');
        })
      }

      let mit_equipment_info = this.oldEquipmentList.find(item=> item.id == event);

      if(mit_equipment_info) this.form.get('mit_equipment_info').setValue(mit_equipment_info.data);

    }else{
      this.clearMethod();
      this.clearServiceLocation();
      this.form.get('equipment_name').setValue("");
    }

    this.isLoading = false;
    this.cdRef.detectChanges();
  }

  async selectServiceLocation(event){
    let value = this.form.getRawValue();
  
    if(event == 'lab'){
      await this.setServiceLocation(true, false);
    }else{
      await this.setServiceLocation(false, true);
    }
    await this.setService();
  }

  setMethodList(items, type?){
    if(items.length > 0){
      this.methodList = items.map(function(elem) {
        return {
          id: elem.mit_method_id,
          text: elem.method_name
        }
      });
      this.methodList = this.methodList.filter((method, index, self) =>
        index === self.findIndex((t) => (
          t.id === method.id
        ))
      );
      if(type != 'init'){
        this.form.get('mit_method_id').setValue(this.methodList[0].id);
        this.form.get('method_name').setValue(this.methodList[0].text);
      }
      // this.form.get('mit_method_id').enable();
    }else{
      this.methodList = [];
      this.form.get('mit_method_id').disable();
    }
  }

  clearMethod(){
    this.form.get('mit_method_id').disable();
    this.form.get('mit_method_id').setValue("");
  }

  clearServiceLocation(){
    this.form.get('service_lab').disable();
    this.form.get('service_lab').setValue(false);

    this.form.get('service_onsite').disable();
    this.form.get('service_onsite').setValue(false);
  }

  getNote(equipment = null){
    if(equipment) {
      this.form.get('note_1').setValue(equipment.data.note_1);
      this.form.get('note_2').setValue(equipment.data.note_2);
    }else{
      this.form.get('note_1').setValue("");
      this.form.get('note_2').setValue("");
    }
  }

  getEquipmentList(event){
    const res = this.oldEquipmentList.filter(v => (
      v.id === event)
    );
    return  res[0];
  }

  getDetailList(equipment){
    let value = this.form.getRawValue();
    if(value.service_location.toUpperCase().includes('LAB') && value.service_location.toUpperCase().includes('ONSITE')){
      return  equipment.data?.product_calibration_fee_info || [];
    }else if(value.service_location.toUpperCase().includes('LAB')){
      return  equipment.data?.product_calibration_fee_info.filter(item=> item.service_location.trim() == "LAB") || [];
    }else{
      return  equipment.data?.product_calibration_fee_info.filter(item=> item.service_location.trim() == "ONSITE") || [];
    }
  }

  sortDetailList(items){
    items.sort(function(a, b) {
      if (a.mit_method_info.method_name > b.mit_method_info.method_name) return 1;
      if (a.mit_method_info.method_name < b.mit_method_info.method_name) return -1;
      
      if (a.unit_info.unit_short_name_th > b.unit_info.unit_short_name_th) return 1;
      if (a.unit_info.unit_short_name_th < b.unit_info.unit_short_name_th) return -1;

      return parseFloat(a.min_range) - parseFloat(b.min_range);
    });
    return items;
  }

  setOptionServiceLocation(service_location = null){
    if (service_location.includes('ONSITE') && service_location.includes('LAB')){
      this.service = "";
    }else if(service_location.includes('ONSITE')){
      this.service = "ONSITE";
    }else{
      this.service = "LAB";
    }
  }

  selectCP(event: number, index: number){
    const selectCp = this.cps.find(v => v.mit_cp_id == event);
    this.detailList.controls.map((v, i)=>{
      if(i === index){
        v.get('cp_name').setValue(selectCp?.cp_name || "")
      }
    })
  }

  async selectUnit(event: string, index: number){
    let units = this.getUnits(event);
    let detail = this.detailList.controls[index].value;
    const unit = units.find(v=>
       v.unit_id == event
    );
    const equipment = this.getEquipmentList(detail.mit_equipment_id);
    let detailList = this.getDetailList(equipment);
    detailList = this.sortDetailList(detailList);
    
    if(detailList[index].unit_id != unit.unit_id){
      const response = await this.getConvertRange(unit.unit_id, detailList[index]);
      this.detailList.controls[index].get('min_range').setValue(isNaN(response?.min_range)? 0: response?.min_range);
      this.detailList.controls[index].get('max_range').setValue(isNaN(response?.max_range)? 0: response?.max_range);
    }else{
      const response = await this.getConvertRange(detailList[index].unit_id, detailList[index]);
      this.detailList.controls[index].get('min_range').setValue(isNaN(response?.min_range)? 0: response?.min_range);
      this.detailList.controls[index].get('max_range').setValue(isNaN(response?.max_range)? 0: response?.max_range);
    }
    this.detailList.controls[index].get('cus_unit_name').setValue(unit.unit_short_name_en);

    if(detail.detail_type == 'range'){
      let selectRange = detail.quotation_mit_item_detail_range;
      if(selectRange.mit_unit_id !== unit.unit_id){
        let convertUnitMin = await this.ConvertService.convert({
          unit_id_2: selectRange.cus_unit_id,
          unit_id_1: unit.unit_id,
          val:parseFloat(this.utils.convertStingToNumber(selectRange.cus_min))
        });
        let convertUnitMax = await this.ConvertService.convert({
          unit_id_2: selectRange.cus_unit_id,
          unit_id_1: unit.unit_id,
          val:parseFloat(this.utils.convertStingToNumber(selectRange.cus_max))
        });
        this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
          cus_unit_id: unit.unit_id || "",
          cus_unit_name: unit.unit_short_name_en || "",
          mit_min: isNaN(convertUnitMin.resultData.val)? 0: convertUnitMin.resultData.val,
          mit_max: isNaN(convertUnitMax.resultData.val)? 0: convertUnitMax.resultData.val
        });
      }else{
        this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
          cus_unit_id: unit.unit_id || "",
          cus_unit_name: unit.unit_short_name_en || "",
          mit_min: selectRange?.cus_min,
          mit_max: selectRange?.cus_max
        });
      }
    }else{
      const control = 
      this.detailList.controls[index].get('quotation_mit_item_detail_points') as FormArray;
      control.controls.map(async v=>{
        if(v.value.mit_unit_id !== unit.unit_id){
          let convertUnit = await this.ConvertService.convert({
            unit_id_1: unit.unit_id,
            unit_id_2: v.value.cus_unit_id,
            val: v.value.cus_point
          });
          if(convertUnit.success){
            v.get('mit_point').setValue(
              isNaN(convertUnit.resultData.val)? 0: convertUnit.resultData.val
            );
          }
        }else{
          v.get('mit_point').setValue(v.value.cus_point);
        }
        v.get('cus_unit_id').setValue(unit.unit_id);
        v.get('cus_unit_name').setValue(unit.unit_short_name_en);
      })
    }
  }

  async getConvertRange(unitId, detailList): Promise<any>{
    if(unitId && detailList.unit_id){
      let convertMinRange = await this.ConvertService.convert({
        unit_id_2: unitId,
        unit_id_1: detailList.unit_id,
        val: detailList.min_range
      });
      let convertMaxRange = await this.ConvertService.convert({
        unit_id_2: unitId,
        unit_id_1: detailList.unit_id,
        val: detailList.max_range
      });
      return { 
        min_range: isNaN(convertMinRange.resultData.val)? 0: convertMinRange.resultData.val,
        max_range: isNaN(convertMaxRange.resultData.val)? 0: convertMaxRange.resultData.val,
      }
    }
  }

  async addPoint(detail: any, i: number, event: any){
    const point = event.target.value.trim();
    const control = 
    this.detailList.controls[i].get('quotation_mit_item_detail_points') as FormArray;
    const isDuplicateNumber = control.value.find(item=> item.cus_point == point);
    if(isDuplicateNumber){
      return;
    }
    if(point !== '' && !isNaN(point)){
      let units = this.getUnits(detail.unit_id);
      const unit = units.find(item=>
         item.unit_id == this.detailList.controls[i].get('cus_unit_id').value
      );
      let convertPoint = point;
      if(this.detailList.controls[i].get('unit_id').value != unit.unit_id){
        let convertResponse = await this.ConvertService.convert({
          unit_id_1: this.detailList.controls[i].get('unit_id').value,
          unit_id_2: unit.unit_id,
          val: point
        });
        if(convertResponse.success){
          convertPoint = isNaN(convertResponse.resultData.val)? 0: convertResponse.resultData.val;
        }
      }
      const data = {
        cus_point: point,
        cus_unit_id: unit.unit_id || "",
        cus_unit_name: unit.unit_short_name_en || "",
        mit_point: convertPoint,
        mit_unit_id: this.detailList.controls[i].get('unit_id').value || "",
        mit_unit_name: this.detailList.controls[i].get('unit_name').value || "",
      }
      event.preventDefault();
      event.target.value = "";
      control.push(this.formMitService.createPointItem(data));
      this.calculateTotalAmountItemDetail();
    }
  }
  
  removePoint(i: number, j: number){
    const control = 
    this.detailList.controls[i].get('quotation_mit_item_detail_points') as FormArray;
    this.delMitItemDetailPointList.push(control.value[j]);
    control.removeAt(j);
    this.calculateTotalAmountItemDetail();
  }

  calculateTotalAmountItemDetail(){
    const getDetailList = this.detailList.value.filter(item =>(
      (item.min_point != 0 && item.min_point != null) && 
      (item.quotation_mit_item_detail_points.length > 0 ||
        (item.quotation_mit_item_detail_range.cus_unit_id != null &&
          item.quotation_mit_item_detail_range.cus_unit_id != '')
      )
    ));
    let detail_high_price_start = null;
    if(getDetailList.length > 0){
      detail_high_price_start = getDetailList.reduce((a, b) => a.price_start > b.price_start ? a : b);
    }
    this.detailList.controls.map((v, index)=>{
      const control = v.value;
      const number_of_detail_points = control.quotation_mit_item_detail_points.length;
      if(control.detail_type == 'point'){
        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
          if(control.min_point == number_of_detail_points){
            this.detailList.controls[index].get('total_amount').setValue(control.price_start);
          }else if(control.min_point > number_of_detail_points){
            this.detailList.controls[index].get('total_amount').setValue(number_of_detail_points * control.price_point);
          }else if (control.min_point < number_of_detail_points){
            const dif = number_of_detail_points - control.min_point;
            this.detailList.controls[index].get('total_amount').setValue((dif * control.price_point) + control.price_start);
          }
        }else{
          this.detailList.controls[index].get('total_amount').setValue(number_of_detail_points * control.price_point);
        }
      }else if(control.detail_type == 'range'){
        if(control.min_point && control.min_point != 0 && detail_high_price_start == control){
          this.detailList.controls[index].get('total_amount').setValue(control.price_start);
        }else{
          this.detailList.controls[index].get('total_amount').setValue(control.min_point * control.price_point);
        }
      }
    })  
    this.calculateTotalAmountItem();
  }
  
  calculateTotalAmountItem(){
    const form = this.form.value;
    let countOfItem = 0;
    let priceStart = 0;
    let isMinPoint = false;
    let mostTotalAmount = 0;
    let mostTotalAmountItem = null;

    if(form.quotation_mit_item_detail_info.length > 0){
      form.quotation_mit_item_detail_info.map((v: any)=>{
        if(v.total_amount != 0 && priceStart < v.price_start) {
          priceStart = v.price_start;
        }
        if(v.total_amount != 0 && v.min_point != 0) {
          isMinPoint = true;
        } 
        if(v.total_amount != 0 && mostTotalAmount < v.total_amount) {
          mostTotalAmount = v.total_amount;
          mostTotalAmountItem = v;
        } 
        countOfItem = countOfItem + v.total_amount;
      })
    }
    let count = countOfItem;

    if(form.quotation_mit_item_attribute_info.length > 0){
      this.attributeList.controls.map((v: any)=>{
        if(v.value.attribute_value){
          v.get('attribute_value').setValue(
            this.utils.transformDecimal(v.get('attribute_value').value, '1.0-0')
          );
        }else{
          v.get('attribute_value').setValue(null);
        }
      })
    }
    

    if(isMinPoint && this.attributeList.value.find(attr=> attr.attribute_value != null)){
      let sumAttr = 0;
      this.attributeList.value.map((v: any)=>{
        if(v.attribute_value) sumAttr = sumAttr + parseFloat(v.attribute_value);
      })
      sumAttr--;
      if(mostTotalAmountItem.detail_type == 'point'){
        count = count + ((mostTotalAmountItem.quotation_mit_item_detail_points.length * sumAttr) *  mostTotalAmountItem.price_point);
      }else{
        count = count + ((mostTotalAmountItem.min_point * sumAttr) *  mostTotalAmountItem.price_point);
      }
    }else{
      if(form.quotation_mit_item_attribute_info.length > 0){
        this.attributeList.value.map((v: any)=>{
          if(v.attribute_value) count = count * parseFloat(v.attribute_value);
        })
      }
      if(!isMinPoint){
        count = count + priceStart;
      }
    }
    this.is_change_point = true;
    this.form.get('original_price').setValue(count);
  }

  async convertSpecificRange(index: number){
    let selectRange = this.detailList.controls[index].value.quotation_mit_item_detail_range;
   if(selectRange.mit_unit_id != selectRange.cus_unit_id){
      let convertMinRange = await this.ConvertService.convert({
        unit_id_1: selectRange.cus_unit_id,
        unit_id_2: selectRange.mit_unit_id,
        val: this.utils.convertStingToNumber(selectRange.cus_min) || 0
      });
      let convertMaxRange = await this.ConvertService.convert({
        unit_id_1: selectRange.cus_unit_id,
        unit_id_2: selectRange.mit_unit_id,
        val: this.utils.convertStingToNumber(selectRange.cus_max) || 0
      });
      this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
        cus_min: this.utils.transformDecimal(selectRange.cus_min, '1.1-5'),
        cus_max: this.utils.transformDecimal(selectRange.cus_max, '1.1-5'),
        mit_min: isNaN(convertMinRange.resultData.val)? 0: convertMinRange.resultData.val,
        mit_max: isNaN(convertMaxRange.resultData.val)? 0: convertMaxRange.resultData.val,
      });
    }else{
      this.detailList.controls[index].get('quotation_mit_item_detail_range').patchValue({
        cus_min: this.utils.transformDecimal(selectRange.cus_min, '1.1-5'),
        cus_max: this.utils.transformDecimal(selectRange.cus_max, '1.1-5'),
        mit_min: selectRange.cus_min,
        mit_max: selectRange.cus_max,
      });
    }
  }

  async submit(){
    this.submitted = true;

    if (this.form.invalid) {
      return;
    };
    this.detailList.controls.map((v, index)=>{
      v.get('min_range').setValue(this.oldRange[index].min);
      v.get('max_range').setValue(this.oldRange[index].max);
    });

    if(this.is_change_point){
      this.form.get('price').setValue(this.form.get('original_price').value);
    
      this.form.get('original_price').setValue(this.oldPrice);

      var lock_invoice_at = this.oldPrice != this.form.getRawValue().price? 
      new Date(): 
      undefined;
    }

    let data = {
      ...this.form.getRawValue(),
      quotation_mit_item_detail_info: this.form.getRawValue().quotation_mit_item_detail_info.filter(item=> item.quotation_mit_item_detail_points.length > 0 || item.quotation_mit_item_detail_range.cus_min != "")
    };

    data = await this.generateMitItemLogService.createMitItemLog(data, this.jobOrder.quotation_mit_item_info.quotation_mit_item_log_info, 'job-order');
    data = await this.mapPoint(data);
    data = await this.mapAttribute(data);
    // data = await this.checkScopeEquipment(data, this.oldMitItem, this.form.getRawValue());

    this.done.emit(
      {
        mitItem: data,
        lock_invoice_at: lock_invoice_at,
        delMitItemDetailList: this.delMitItemDetailList,
        delMitItemDetailPointList: this.delMitItemDetailPointList,
        delMitItemDetailRangeList: this.delMitItemDetailRangeList,
        delMitItemAttributeList: this.delMitItemAttributeList,
      }
    );
    this.closeModalBtn.nativeElement.click();
  }

  // async checkScopeEquipment(data, beforeValue, AfterValue){

  //   if(beforeValue.mit_scope_id != AfterValue.mit_scope_id || beforeValue.mit_equipment_id != AfterValue.mit_equipment_id){
  //     data['newScopeEquipment'] = true;
  //   }
  //   return data;
  // }

  async mapPoint(data){
    await Promise.all(data.quotation_mit_item_detail_info.map(async (item)=>{
      if(item.detail_type == 'point'){
        item.quotation_mit_item_detail_points.map((point, index)=>{
          let i = index + 1;
          if(i > item.number_of_point){
            point.is_non_calculate = 'true';
          }
        })
      }
    }));
    return data;
  }

  async mapAttribute(data){
    await Promise.all(data.quotation_mit_item_attribute_info.map(async (item, index)=>{
      if(item.attribute_value != ""){
        let isChangeBynoncalibration = this.attributes.find(a=> 
          item.quotation_mit_item_attribute_id == a.quotation_mit_item_attribute_id && item.attribute_value != a.attribute_value && a.is_non_calculate == 'false'
        );
        let isNew = item.quotation_mit_item_attribute_id == null;
        if(isNew){
          item.is_non_calculate = 'true';
        }else if(isChangeBynoncalibration){
          item.is_non_calculate = 'true';
          item.is_non_calibration = 'false';
          item.quotation_mit_item_attribute_id = null;

          data.quotation_mit_item_attribute_info.push(
            {
              quotation_mit_item_attribute_id: isChangeBynoncalibration.quotation_mit_item_attribute_id,
              attribute_name: isChangeBynoncalibration.attribute_name,
              attribute_value: isChangeBynoncalibration.attribute_value,
              is_non_calculate: isChangeBynoncalibration.is_non_calculate,
              is_non_calibration: 'true'
            }
          )
        }
      }
    }));
    return data;
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.done.emit(null);
      }
    });
  }

  onKeyup(){
    this.subject.next();
  }

  onKeyupRange(i){
    this.subject_convert_range.next(i);
  }

  getUnits(unit_id: string): any{
    let result = [];
    this.units.map(v=>{
      const unit = v.units.filter(unit => unit.unit_id == unit_id);
      if (unit.length > 0){
        result = v.units
      }
    })
    return result;
  }

  async setDetailType(i: number, type: string){
    this.detailList.controls.map(async (v, index)=>{
      if(i == index && v.value.detail_type != type){
        const selectDetail = this.oldMitItemDetails[i];
        v.get('detail_type').setValue(type);
        v.get('detail_input_type').setValue(type);
        if(type == 'point'){
          this.detailList.controls[index].get('cus_unit_id').setValue(selectDetail.unit_id);
          this.detailList.controls[index].get('cus_unit_name').setValue(selectDetail.unit_name);
          this.detailList.controls[index].get('min_range').setValue(this.utils.convertStingToNumber(selectDetail.min_range));
          this.detailList.controls[index].get('max_range').setValue(this.utils.convertStingToNumber(selectDetail.max_range));

          this.delMitItemDetailRangeList.push(this.detailList.controls[index].get('quotation_mit_item_detail_range').value);
          v.get('quotation_mit_item_detail_range').reset();
        }else{
          let units = this.getUnits(selectDetail.unit_id);
          const unit = units.find(item=>
             item.unit_id == selectDetail.cus_unit_id
          );
          const control = 
          this.detailList.controls[index].get('quotation_mit_item_detail_points') as FormArray;
          this.delMitItemDetailPointList.push(...control.value);
          control.clear();
          this.detailList.controls[index].get('cus_unit_id').setValue(selectDetail.unit_id);
          this.detailList.controls[index].get('cus_unit_name').setValue(selectDetail.unit_name);
          this.detailList.controls[index].get('min_range').setValue(this.utils.convertStingToNumber(selectDetail.min_range));
          this.detailList.controls[index].get('max_range').setValue(this.utils.convertStingToNumber(selectDetail.max_range));
          v.get('quotation_mit_item_detail_range').patchValue({
            quotation_mit_item_detail_range_id: "",
            cus_min: this.utils.transformDecimal(this.utils.convertStingToNumber(selectDetail.min_range), '1.1-5'),
            cus_max: this.utils.transformDecimal(this.utils.convertStingToNumber(selectDetail.max_range), '1.1-5'),
            cus_unit_id: selectDetail.cus_unit_id || "",
            cus_unit_name: unit.unit_short_name_en || "",
            mit_min: this.utils.transformDecimal(this.utils.convertStingToNumber(selectDetail.min_range), '1.1-5'),
            mit_max: this.utils.transformDecimal(this.utils.convertStingToNumber(selectDetail.max_range), '1.1-5'),
            mit_unit_id: selectDetail.unit_id || "",
            mit_unit_name:selectDetail.unit_name || ""
          });
        }
      }
    })
    this.calculateTotalAmountItemDetail();
  }
}
