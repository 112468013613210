import { State, Action, StateContext, Selector } from "@ngxs/store";

import { GetUserInfo, UpdateUserInfo } from "./user.action";

import { Injectable } from "@angular/core";
import { UserService } from "src/app/services";
import { Router } from "@angular/router";

export class UserInfoStateModel {
  user: any;
}

@State<UserInfoStateModel>({
  name: "user",
  defaults: {
    user: null,
  },
})
@Injectable({ providedIn: "root" })
export class UserInfoState {
  constructor(private router: Router, private UserService: UserService) {}

  @Selector()
  static getUser(state: UserInfoStateModel) {
    return state.user;
  }

  @Action(GetUserInfo)
  getUserInfo({ getState, setState }: StateContext<UserInfoStateModel>) {
    return this.UserService.me().then((result) => {
      if(result.success && result.resultData != null){
        let userInfo = result.resultData;
    
        userInfo.is_admin = userInfo?.user_role_id === "ADMIN";
        userInfo.is_sales_manager =
          userInfo.emp_info?.emp_level_id === "MANAGER" &&
          userInfo.emp_info?.emp_department_id === "SALES_DEP";

        userInfo.is_sales = userInfo.emp_info?.emp_department_id === "SALES_DEP";
  
        userInfo.is_co_sales =
          userInfo.emp_info?.emp_level_id === "OPERATION" &&
          userInfo.emp_info?.emp_department_id === "SALES_DEP" &&
          userInfo.emp_info?.emp_position_id === "SALES_DEP-03";

        userInfo.is_sales_supervisor =
          userInfo.emp_info?.emp_level_id === "SUPERVISOR" &&
          userInfo.emp_info?.emp_department_id === "SALES_DEP" &&
          userInfo.emp_info?.emp_position_id === "SALES_DEP-02";

        userInfo.is_co_sales_supervisor =
          userInfo.emp_info?.emp_level_id === "SUPERVISOR" &&
          userInfo.emp_info?.emp_department_id === "SALES_DEP" &&
          userInfo.emp_info?.emp_position_id === "SALES_DEP-03";

        userInfo.admin_cert =
          userInfo.emp_info?.emp_department_id === "CALIB_DEP" &&
          userInfo.emp_info?.emp_position_id === "CALIB_DEP-08";

        userInfo.is_tran_dep = userInfo.emp_info?.emp_department_id === "TRAN_DEP";
        userInfo.is_acc_dep = userInfo.emp_info?.emp_department_id === "ACC_DEP";
        userInfo.is_calib_dep = userInfo.emp_info?.emp_department_id === "CALIB_DEP";
        userInfo.is_puchase_dep = userInfo.emp_info?.emp_department_id === "PUCHASE_DEP";

        const state = getState();
        setState({
          ...state,
          user: userInfo,
        });
      }else if(result.resultCode === '40302'){
        this.router.navigateByUrl("/login");
      }else{
        localStorage.removeItem("token");
        this.router.navigateByUrl("/login");
      }
    });
  }

  @Action(UpdateUserInfo)
  updateUserInfo(
    { getState, setState }: StateContext<UserInfoStateModel>,
    { payload }
  ) {
    const state = getState();
    setState({
      ...state,
      user: payload,
    });
  }
}
