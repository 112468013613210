import { FormBuilder, Validators } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { UnitTypeService } from "src/app/services/unit-type.service";
import { ENSignValidator } from "src/app/validators";

@Component({
  selector: "app-unit-type-create",
  templateUrl: "./unit-type-create.component.html",
  styleUrls: ["./unit-type-create.component.css"],
})
export class UnitTypeCreateComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private UnitTypeService: UnitTypeService
  ) {
    this.setForm();
  }
  setForm(): void {
    this.form = this.formBuilder.group({
      unit_type_name_th: [""],
      unit_type_name_en: ["", [Validators.required, ENSignValidator()]],
    });
  }
  ngOnInit(): void {}
  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let data = {
      ...this.form.value,
      unit_type_name_th: this.form.value.unit_type_name_en,
    };
    let response = await this.UnitTypeService.create(data);
    this.done.emit(response.success || response.error);
    this.submitted = false;
    this.setForm();
    this.closeModalBtn.nativeElement.click();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }
}
