import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class MITMethodService {
  async create({ method_name }) {
    let result = null;
    try {
      result = await Axios().post(`/mit-method`, {
        method_name,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({ mit_method_id, method_name }) {
    let result = null;
    try {
      result = await Axios().put(`/mit-method/${mit_method_id}`, {
        method_name,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ mit_method_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/mit-method/${mit_method_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/mit-method`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter,order_by:'method_name:asc' },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ mit_method_id }) {
    let result = null;
    try {
      result = await Axios().get(`/mit-method/${mit_method_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
