import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import moment from "moment";
import { Observable } from "rxjs";
import { EmployeeService, TransportMasterBookingService } from "src/app/services";
import { 
  AccBillingNoteInfoService, 
  AccBillingNoteItemService 
} from "src/app/services/billing-note";
import { UserInfoState } from "src/app/store/user/user.state";
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-bill-cheque-list',
  templateUrl: './bill-cheque-list.component.html',
  styleUrls: ['./bill-cheque-list.component.css']
})
export class BillChequeListComponent implements OnInit {
  form : FormGroup;
  isLoading:boolean = true;
  isMessenger: boolean = false;

  billingList = [];
  transportContactList = [];

  billing = null;
  type: string = null;

  status: string;
  user_id: string;
  filter = {};
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private EmployeeService: EmployeeService,
    private AccBillingNoteItemService: AccBillingNoteItemService,
    private AccBillingNoteInfoService: AccBillingNoteInfoService,
    private TransportMasterBookingService: TransportMasterBookingService
    ) { 
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.getUser();

    this.filter ={
      sent_by: this.isMessenger? this.userInfo.user_id: undefined,
    }

    this.form = this.fbd.group({
      appointment_date: [""],
    });

    this.form.get('appointment_date').setValue(moment(new Date()).format('YYYY-MM-DD'));

    await this.EmployeeService.load(null, { emp_department_id: 'TRAN_DEP' })
    .then(res=>{
      this.transportContactList = res.resultData || {}
      this.transportContactList = this.transportContactList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info.firstname_th} ${elem.user_info.lastname_th}`,
        }
      });
    })
    this.searchDate(this.form.value.appointment_date);
    this.form.controls.appointment_date.valueChanges.subscribe(
      (appointment_date) => {
        if(appointment_date){
          this.searchDate(appointment_date);
        }
      }
    );
  }

  getUser(){
    this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;
      this.userInfo = userInfo;
      if(userInfo?.emp_info?.emp_position_info?.emp_position_id.startsWith("TRAN_DEP-03")){
        this.isMessenger = true;
      }
    });
  }

  async getBill(filter?){
    this.billingList = [];
    await this.AccBillingNoteInfoService.load(null, { ... filter }).then(res=>{

      let resultData = res.resultData.map(item=>{
        return {
          ...item,
          tab: 'bill'
        }
      })
      this.billingList = resultData;
    })
    await this.AccBillingNoteItemService.load(null, { ... filter }).then(res=>{

      let resultData = res.resultData.map(item=>{
        return {
          ...item,
          customer_info: item.acc_billing_note_info.customer_info,
          tab: 'cheque'
        }
      })
      this.billingList = [
        ...this.billingList,
        ...resultData
      ];
    })

    this.billingList.sort(function(a, b){
      if(a.customer_info.company_name < b.customer_info.company_name) { return -1; }
      if(a.customer_info.company_name > b.customer_info.company_name) { return 1; }
      return 0;
    })
    this.isLoading = false;
  }

  search(filter: object) {
    this.filter = {
      ... this.filter,
      ...this.form.value,
      ...filter
    }
    this.getBill(this.filter);
  }
  searchDate(date){
    let condition = {
      "appointment_date": date
    };
    this.search(condition);
  }
  clearDate(){
    this.form.get('appointment_date').setValue('');
    let condition = {
      "appointment_date": undefined
    };
    this.search(condition);
  }

  async select(billing, type: string){
    if(await this.checkMasterBooking(billing)){
      this.billing = billing;
      this.type = type;
      
      setTimeout(() => {
        $('#billing-update-sent-info').modal('show');
      }, 100);
    }else{
      Swal.fire({
        icon: "warning",
        confirmButtonText: "ตกลง",
        title: 'แจ้งทราบ',
        text: 'รายการนี้ถูกกำหนดวันที่ส่งคืนแล้ว หากต้องการแก้ไขโปรดแก้ไขที่ Master Booking'
      })
    }
  }

  async checkMasterBooking(billing): Promise<boolean>{
    let result = false;
    if(billing.tab== "bill"){
      if(billing.acc_billing_note_info_id){
        await this.TransportMasterBookingService.load(null, {ref_doc_id: billing.acc_billing_note_info_id})
        .then(res=>{
  
          if(res.resultData.find(master=> master.doc_status_id != "CANCELED")){
            result = false;
          }else{
            result = true;
          }
        })
      }else{
        result = true;
      }
    }else{
      if(billing.acc_billing_note_item_id){
        await this.TransportMasterBookingService.load(null, {ref_doc_id: billing.acc_billing_note_item_id})
        .then(res=>{
  
          if(res.resultData.find(master=> master.doc_status_id != "CANCELED")){
            result = false;
          }else{
            result = true;
          }
        })
      }else{
        result = true;
      }
    }
    return result;
  }

  async updateSentInfo(event){
    if(event){
      if(this.billing.tab == 'bill'){
        let response = await this.AccBillingNoteInfoService.update(
          {
            ...this.billing,
            ...event,
            sent_at : event.sent_at? moment(event.sent_at).format("YYYY-MM-DD"): undefined,
            is_master_booking: true
          }
        )
        this.status = response.success || response.error;
        this.getBill();
      }else{
        let response = await this.AccBillingNoteItemService.update(
          {
            ...this.billing,
            ...event,
            sent_at : event.sent_at? moment(event.sent_at).format("YYYY-MM-DD"): undefined,
            is_master_booking: true
          }
        )
        this.status = response.success || response.error;
        this.getBill();
      }
    }
    this.billing = null;
    this.type = null;
  }
}
