import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationMitItemDetailRangeService {
  async create({
    quotation_mit_item_detail_id,
    cus_min,
    cus_max,
    cus_unit_id,
    mit_min,
    mit_max,
    mit_unit_id,
    is_non_calculate,
    is_non_calibration,
    order_by
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-item-detail-ranges`, {
        quotation_mit_item_detail_id,
        cus_min: parseFloat(this.convertStingToNumber(cus_min)),
        cus_max: parseFloat(this.convertStingToNumber(cus_max)),
        cus_unit_id,
        mit_min: parseFloat(this.convertStingToNumber(mit_min)),
        mit_max: parseFloat(this.convertStingToNumber(mit_max)),
        mit_unit_id,
        is_non_calculate,
        is_non_calibration,
        order_by
      });
      result = result?.data || {};
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_mit_item_detail_range_id,
        quotation_mit_item_detail_id,
        cus_min,
        cus_max,
        cus_unit_id,
        mit_min,
        mit_max,
        mit_unit_id,
        is_non_calculate,
        is_non_calibration,
        order_by
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-mit-item-detail-ranges/${quotation_mit_item_detail_range_id}`, {
        quotation_mit_item_detail_id,
        cus_min: parseFloat(this.convertStingToNumber(cus_min)),
        cus_max: parseFloat(this.convertStingToNumber(cus_max)),
        cus_unit_id,
        mit_min: parseFloat(this.convertStingToNumber(mit_min)),
        mit_max: parseFloat(this.convertStingToNumber(mit_max)),
        mit_unit_id,
        is_non_calculate,
        is_non_calibration,
        order_by
      });
      result = result?.data || {};
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_mit_item_detail_range_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-mit-item-detail-ranges/${quotation_mit_item_detail_range_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-detail-ranges`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async getById({ quotation_mit_item_detail_range_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-detail-ranges/${quotation_mit_item_detail_range_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
  convertStingToNumber(str){
    if(isNaN(str)) str = str.split(',').join('');
    return str;
  }
}
