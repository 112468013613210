
<div
modal
class="modal fade text-left"
id="upload-cert"
tabindex="-1"
(close)="close()"
role="dialog"
aria-labelledby="TransportreceiveFilterModal"
aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header bg-primary text-white">
      <h4 class="modal-title" id="TransportreceiveFilterModal">
        แนบ CERT
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form form-horizontal" [formGroup]="form">
        <div class="form-body" [hidden]="['ICL','ICS'].includes(template) || pageType == 'show'">
          <div class="mt-1">
              <form-group
              [control]="form.controls.cost_of_sales"
              [submitted]="submitted"
              >
                <label for="template">
                  บันทึกราคา <span class="danger">*</span>
                </label>
                <input 
                placeholder="0.00"
                class="form-control text-right" 
                (keyup)="onKeyup()"
                (click)="$event.target.select()"
                oninput="this.value = 
                this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                id="cost_of_sales" 
                formControlName="cost_of_sales"/>
              </form-group>

            </div>
        </div>
        <div class="form-body">
        
            <div class="mt-1">
              <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups" *ngIf="pageType != 'show'">
                <div class="btn-group mr-1" role="group" aria-label="First group">
                    <label for="file">แนบไฟล์</label>
                </div>
                <button 
                  class="btn btn-outline-primary mb-1" 
                  type="button"
                  (click)="uploadFile()">
                    <i class="feather icon-upload icon-left"></i>
                    Upload
                </button>
                <input type="file" style="display:none;" multiple id="importFileCert"
                (change)="importFile($event.target.files)">
              </div>
              <small>* จำกัดขนาดไฟล์ {{UtilsService.config.limitFile}} mb</small> 
              <div class="mt-1">
                <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                  <span class="pointer" (click)="downloadFile(file)">
                    {{file.job_order_cert_id? file.file_name: file.name}}
                  </span>
                  <span aria-hidden="true" class="pointer" 
                  *ngIf="pageType != 'show'"
                  data-target="#modalDelCertFile"
                  data-toggle="modal"
                  (click)="removeFile(file, i)">&times;</span>
                </span>
                <span *ngIf="files.length == 0">
                  ไม่มีไฟล์
                </span>
              </div>
            </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-warning"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
        #closeRejectBtn
      >
        <i class="fa fa-times icon-left"></i> ยกเลิก
      </button>

      <button type="button" class="btn btn-primary" (click)="save()" *ngIf="pageType != 'show'">
        <i class="fa fa-check icon-left"></i>ยืนยัน
      </button>
    </div>
  </div>
</div>
</div>

<app-confirm-delete [id]="'modalDelCertFile'" (save)="deleteFile($event)"></app-confirm-delete>