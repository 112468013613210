import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { GETService, UtilsService } from 'src/app/services';
import { 
  AccTaxInvoiceInfoService 
} from 'src/app/services/tax-invoice';
import { UserInfoState } from 'src/app/store/user/user.state';

@Component({
  selector: 'app-tax-invoice-list',
  templateUrl: './tax-invoice-list.component.html',
  styleUrls: ['./tax-invoice-list.component.css']
})
export class TaxInvoiceListComponent implements OnInit {
  isLoading = true;
  
  taxInvoices = [];
  taxInvoice = null;

  isSalesManager = false;
  isSalesCoordinator = false;

  number_all_status:number = 0;
  number_rejected_status:number = 0;
  number_draft_status:number = 0;

  number_waiting_for_payment:number = 0; 
  number_complete_status:number = 0;
  number_lessthan_status:number = 0;

  number_greaterthan_status:number = 0;
  number_canceled_status:number = 0;
  number_total_amount_status:number = 0;
  number_partial_payment_status:number = 0;
  number_waiting_for_verify_status:number = 0;
  number_waiting_for_approve_status:number = 0;

  status: string;
  reset: boolean = false;
  load: Function = this.AccTaxInvoiceInfoService.load;
  filter = {};
  params: any;
  initialPage: number = 1;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userSub: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private GETService: GETService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,

    public _UtilsService: UtilsService,
  ) { 
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
    this.route.queryParams
    .subscribe(params => {
      if(params){
        let page = parseInt(params.page);

        this.params = params;
        this.filter = {
          ...params,
          offset: page == 1 || isNaN(page)? 0: ((page-1)* 10),
          page: this._UtilsService.setUndefined()
        };

        this.initialPage = isNaN(page)? 1: page;
      }
    }
  );
  }
  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit(): void {
    this.getCountOfStatus(null);
  }

  getCountOfStatus(filter: object){
    this.userSub = this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;
      this.isSalesManager =
      userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP") &&
      userInfo?.emp_info?.emp_level_id === "MANAGER";
      this.isSalesCoordinator =
      userInfo?.emp_info?.emp_level_id.startsWith("OPERATION") &&
      userInfo?.emp_info?.emp_position_info?.emp_position_name_en.includes("Sale coordinator");

      this.AccTaxInvoiceInfoService.load({limit:0}, { ...filter, doc_status_id: 'TOTAL_AMOUNT_CREDITED'}).then(res=>{
        this.number_total_amount_status = res.rowCount;
        // this.number_all_status = this.number_all_status + res.rowCount;
      });
      this.AccTaxInvoiceInfoService.load({limit:0}, { ...filter, doc_status_id: 'PARTIAL_PAYMENT'}).then(res=>{
        this.number_partial_payment_status = res.rowCount;
        // this.number_all_status = this.number_all_status + res.rowCount;
      });
      this.AccTaxInvoiceInfoService.load({limit:0}, { ...filter, doc_status_id: 'WAITING_FOR_PAYMENT'}).then(res=>{
        this.number_waiting_for_payment = res.rowCount;
        // this.number_all_status = this.number_all_status + res.rowCount;
      });
      this.AccTaxInvoiceInfoService.load({limit:0}, { ...filter, doc_status_id: 'COMPLETED'}).then(res=>{
        this.number_complete_status = res.rowCount;
        // this.number_all_status = this.number_all_status + res.rowCount;
      });
      this.AccTaxInvoiceInfoService.load({limit:0}, { ...filter, doc_status_id: 'CANCELED'}).then(res=>{
        this.number_canceled_status = res.rowCount;
        // this.number_all_status = this.number_all_status + res.rowCount;
      });
      this.AccTaxInvoiceInfoService.load({limit:0}, { ...filter, due_date: 'less_than'}).then(res=>{
        this.number_lessthan_status = res.rowCount;
      });
      this.AccTaxInvoiceInfoService.load({limit:0}, { ...filter, due_date: 'greater_than'}).then(res=>{
        this.number_greaterthan_status = res.rowCount;
      });
    });
  }

  onChangePage(taxInvoices: Array<any>) {
    this.taxInvoices = taxInvoices;
    this.isLoading = false;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = { ...this.filter, ...filter };

    this.setQueryUrl();
  }
  setQueryUrl(){
    let filter = {
      ...this.filter,
      page: this.initialPage,
      offset: this._UtilsService.setUndefined(),
    }
    this.router.navigate([], {
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
    this.params = filter;
  }

  getFilterLimit(filterLimit){

    if(this.initialPage != filterLimit.initialPage && filterLimit.initialPage != 0){
  
      let params = {
        ...this.filter,
        page: filterLimit.initialPage,
        offset: this._UtilsService.setUndefined()
      }

      this.router.navigate([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.params = params;
    }
  }
  searchStatus(status:string = null){
    let condition = null;
    if(status == 'less_than' || status == 'greater_than'){
      condition = {
        due_date: status,
        doc_status_id: undefined,
        order_by: 'doc_duedate:asc'
      }
    }else{
      condition = {
        doc_status_id: status,
        due_date: undefined,
        order_by:status == 'WAITING_FOR_PAYMENT'? 'doc_duedate:asc': undefined
      }
    }
    this.search(condition);
  }
  select(taxInvoice: string) {
    this.taxInvoice = Object.assign({}, taxInvoice);
  }

  printTaxInvoiceExcel(taxInvoiceInfo){
    this.GETService.taxInvoiceExcel(taxInvoiceInfo.acc_tax_invoice_info_id).then(res=>{
      let url = window.URL.createObjectURL(res.data);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = taxInvoiceInfo.doc_no + '_' + taxInvoiceInfo.customer_info.company_name + '.xlsx';
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
}
