<div
  class="modal fade text-left"
  [id]="id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">ยืนยันการลบ</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <app-response-status
          *ngIf="status"
          [status]="status"
        ></app-response-status>
        <div class="modal-body-content">
          <h4>คุณต้องการลบข้อมูลนี้ใช่หรือไม่ ?</h4>
          <p>หากต้องการลบให้คลิกปุ่ม "ยืนยัน" หากไม่ต้องการ คลิกปุ่ม "ปิด"</p>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn grey btn-outline-secondary"
          data-dismiss="modal"
          (click)="onClose()"
        >
          ปิด
        </button>
        <button type="button" class="btn btn-outline-danger" (click)="onSave()">
          ยืนยัน
        </button>
      </div>
    </div>
  </div>
</div>
