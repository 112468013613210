<div class="card">
    <div class="card-header pb-0">
        <h4 class="card-title">รายการใบ PO</h4>
    </div>

    <div class="card-content collapse show">
        <div class="card-body col-12">
            <div class="table-responsive">
                <table class="table table-striped table-bordered" >
                  <thead>
                    <tr>
                        <th>เลขที่ใบเสนอราคา</th>
                        <th>วันที่ตอบรับ</th>
                        <th>เลขที่ PO</th>
                        <th>หมายเหตุ</th>
                        <th>ไฟล์แนบ</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let po of poList; let i = index">

                       <td>
                        {{po?.doc_no | dash}}
                       </td>

                        <td>
                            {{po.data?.accepted_date | thai_date: "short":"date" | dash }}
                        </td>

                        <td>
                            {{po.data?.accepted_po_no | dash }}
                        </td>

                        <td>
                            {{po.data?.note | dash }}
                        </td>

                        <td>
                            <div class="custom-bullet" *ngFor="let file of po.data?.quotation_accepted_file_info; let j = index;">
                                <span class="text-primary" style="cursor: pointer;" 
                                (click)="downloadFile({file_path: file.accepted_doc_path,file_name: file.accepted_doc_name})">
                                    {{file.accepted_doc_name}}
                                </span>
                            </div>
                        </td>
                    </tr>
                    </tbody>

                    <tbody *ngIf="poList.length == 0">
                        <tr>
                            <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
