<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="add-quotation-item"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreate1Modal">
          เลือกรายการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <h4 class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="description">
                    ใบเสนอราคา <span class="danger">*</span> : 
                  </h4>
                  <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                      <form-group
                      [control]="form.controls.quotation_info_id"
                      [submitted]="submitted">
                      <ng-select2
                          [data]="quotationList"
                          class="w-100"
                          placeholder="โปรดเลือกใบเสนอราคา"
                          formControlName="quotation_info_id"
                          (valueChanged)="selectQuotation($event)">
                      </ng-select2>
                      </form-group>
                  </div>

                </div>
            </div>

            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                  </div>
                  <div class="col-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="table-responsive" style="max-height: 600px;">
                        <table class="table table-striped table-bordered mb-0">
                            <thead> 
                                <tr>
                                    <th>
                                    </th>
                                    <th>ชื่อเครื่องมือ</th>
                                    <th>Method</th>
                                    <th>Maker</th>
                                    <th>Model</th>
                                    <th>Serial No.</th>
                                    <th>Tag No.</th>
                                </tr>
                            </thead>
        
                            <tbody>
                                <tr *ngFor="let item of quotationMitItems; let i = index;">
                                    <td>
                                        <input 
                                        *ngIf="item.transport_receive_item_info.length == 0"
                                        type="radio"
                                        name="radio_1"
                                        [id]="'radio_'+i"
                                        (change)="onCheckChange(i)"
                                        >
                                    </td>
                                    <td>
                                        {{item.description || item.equipment_name}}
                                    </td>
                                    <td>
                                      {{getMethodName(item?.quotation_mit_item_detail_info)}}
                                    </td>
                                    <td>
                                      {{item.marker || '-'}}
                                    </td>
                                    <td>
                                        {{item.model || '-'}}
                                    </td>
                                    <td>
                                        {{item.serial_no || '-'}}
                                    </td>
                                    <td>
                                        {{item.tag_no || '-'}}
                                    </td>
                                </tr>
                                <tr *ngIf="quotationMitItems.length === 0">
                                    <td colspan="8" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>
                        </table>
                        <form-group
                        [control]="form.controls.quotation_mit_item_id"
                        [submitted]="submitted">
                        </form-group>
                    </div>
                  </div>
                </div>
            </div>
            
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>ยืนยัน
        </button>
      </div>
    </div>
  </div>
</div>
