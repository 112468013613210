import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  async auth({ email, password }) {
    let result = null;
    try {
      result = await Axios().post("/auth-user", {
        email,
        password,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async resetPassword({ password, user_id }) {
    let result = null;
    try {
      result = await Axios().post(`/reset-password`, {
        password,
        user_id,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
