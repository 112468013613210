import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import paginate from "jw-paginate";
import _ from "lodash";
@Component({
  selector: "app-paginator",
  templateUrl: "./paginator.component.html",
  styleUrls: ["./paginator.component.css"],
})
export class PaginatorComponent implements OnInit {
  @Output() loading = new EventEmitter<any>(false);
  @Output() changePage = new EventEmitter<any>(true);
  @Output() getTotalItems = new EventEmitter<any>();
  @Output() filterLimit = new EventEmitter<any>();
  @Input() filter = {};
  @Input() initialPage = 1;
  @Input() pageSize = 10;
  @Input() maxPages = 10;
  @Input() fetch: Function;
  @Input() reset: boolean;
  pageOfItems: Array<any> = [];
  pager: any = {};
  totalItems: number = 0;

  paginator = null;

  async ngOnInit() {
    this.paginator = setTimeout(async () => {
      await this.load(0);

      this.pager = paginate(
        this.totalItems,
        this.initialPage,
        this.pageSize,
        this.maxPages
      );
    
      this.insertIndex(this.initialPage);
      this.changePage.emit(this.pageOfItems);
      this.getTotalItems.emit(this.totalItems);
      this.sendlimit();
    }, 0);
  }

  sendlimit(){
    this.filterLimit.emit(
      {
        offset: this.pager.startIndex,
        limit: this.pageSize,
        initialPage: this.pager.currentPage
      }
    );
  }

  async load(offset) {
    let result = await this.fetch(
      { offset: offset, limit: this.pageSize },
      this.filter
    );
    if (result.success) {
      this.pageOfItems = result.resultData || [];
      this.totalItems = result.rowCount;
    } else {
      this.pageOfItems = [];
      this.totalItems = 0;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.paginator) clearTimeout(this.paginator);

    if (
      changes.filter &&
      changes.filter.currentValue !== changes.filter.previousValue &&
      !changes.filter.firstChange
    ) {
      this.ngOnInit();
    }

    if (changes.reset && !changes.reset.firstChange) {
      this.ngOnInit();
    }
  }

  async setPage(page: number) {
    // get new pager object for specified page
    this.loading.emit(true);
    this.pager = paginate(this.totalItems, page, this.pageSize, this.maxPages);
    await this.load(this.pager.startIndex);
    this.insertIndex(page);
    this.changePage.emit(this.pageOfItems);
    this.getTotalItems.emit(this.totalItems);
    this.sendlimit();
    this.loading.emit(false);
  }

  insertIndex(page) {
    this.pageOfItems = this.pageOfItems.map((v, i) => {
      return { ...v, i: (page - 1) * this.pageSize + i + 1 };
    });
  }
}
