import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class SalesAssignmentService {
  async create({
    customer_id,
    user_id = undefined,
    revoked_by = undefined
  }) {
    let result = null;
    try {
      result = await Axios().post(`/sales-assignment`, {
        customer_id,
        user_id,
        revoked_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    sales_assignment_id,
    customer_id,
    user_id = undefined,
    revoked_by = undefined,
  }) {
    console.log(user_id);
    let result = null;
    try {
      result = await Axios().patch(`/sales-assignment/${sales_assignment_id}`, {
        customer_id,
        user_id,
        revoked_by,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ sales_assignment_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/sales-assignment/${sales_assignment_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/sales-assignment`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ sales_assignment_id }) {
    let result = null;
    try {
      result = await Axios().get(`/sales-assignment/${sales_assignment_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
