<loading *ngIf="isLoading"></loading>
<form
  *ngIf="!isLoading"
  autocomplete="off"
  class="form"
  role="form"
  action="/dashboard/user-accounts"
  method="post"
  [formGroup]="form"
>
  <app-response-status *ngIf="status" [status]="status"></app-response-status>
  <div class="form-body">
    <div class="row">
      <div class="col-3">
        <h4 class="my-2">
          ข้อมูลบริษัท
        </h4>
      </div>
      <div class="col-9 text-right" 
      *ngIf="(userInfo | async)?.user_role_id === 'ADMIN' ||
      (userInfo | async)?.emp_info?.emp_department_id === 'ACC_DEP'
      ">
        <a
          class="btn mr-1 mb-1 btn-warning text-white"
          (click)="enableForm()"
          *ngIf="form.disabled"
          ><i class="fa fa-pencil icon-left"></i> แก้ไข</a
        >

        <div *ngIf="form.enabled">
          <button
            (click)="cancel()"
            type="button"
            class="btn btn-warning btn-min-width mr-1"
          >
            <i class="fa fa-times"></i> ยกเลิก
          </button>
          <button type="button" class="btn btn-primary" (click)="submit()">
            <i class="fa fa-save"></i> บันทึก
          </button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
        <form-group
          [control]="form.controls.company_name"
          [submitted]="submitted"
        >
          <label for="company_name">
            ชื่อบริษัท <span class="danger" *ngIf="form.controls['company_name'].enabled">*</span>
          </label>
          <input
            type="text"
            id="company_name"
            class="form-control"
            placeholder="ชื่อบริษัท"
            formControlName="company_name"
          />
        </form-group>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
        <form-group [control]="form.controls.tax_id" [submitted]="submitted">
          <label for="tax_id">
            เลขประจำตัวผู้เสียภาษี
            <span class="danger" *ngIf="form.controls['tax_id'].enabled">*</span>
          </label>
          <input
            type="text"
            id="tax_id"
            class="form-control"
            placeholder="เลขประจำตัวผู้เสียภาษี"
            formControlName="tax_id"
            maxlength="13"
          />
        </form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
        <form-group [control]="form.controls.branch" [submitted]="submitted">
          <label for="branch">สาขา / สำนักงาน
            <span class="danger" *ngIf="form.controls['branch'].enabled">*</span>
          </label>
          <input
            type="text"
            id="branch"
            class="form-control"
            placeholder="สาขา / สำนักงาน"
            formControlName="branch"
          />
        </form-group>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
        <!-- <form-group
          [control]="form.controls.department"
          [submitted]="submitted"
        >
          <label for="department">แผนก
            <span class="danger"  *ngIf="form.controls['department'].enabled">*</span>
          </label>
          <input
            type="text"
            id="department"
            class="form-control"
            placeholder="แผนก"
            formControlName="department"
          />
        </form-group> -->
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12">
        <form-group
          [control]="form.controls.industry_id"
          [submitted]="submitted"
        >
          <label for="industry_id">กลุ่มอุตสาหกรรม</label>
          <ng-select2 [data]="industrys"
            [placeholder]="'กรุณาเลือกข้อมูล'"
            formControlName="industry_id"
            [width]="300">
          </ng-select2>
        </form-group>
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mb-1">

        <form-group
        [control]="form.controls.industry_id"
        [submitted]="submitted"
      >
        <label for="industry_id">
          แหล่งที่มาของลูกค้า <span class="danger" *ngIf="form.controls['customer_source'].enabled">*</span>
        </label>
        <ng-select2 [data]="sources"
          [placeholder]="'กรุณาเลือกข้อมูล'"
          formControlName="customer_source"
          [width]="300">
        </ng-select2>
      </form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12">
      </div>

      <div class="col-xl-4 col-lg-6 col-md-12 mb-1" *ngIf="form.controls['customer_source'].value.includes('.')">
        <label for="billing_day">แหล่งที่มาเพิ่มเติม <span class="danger" *ngIf="form.controls['customer_source_text'].enabled">*</span></label>
        <form-group
          [control]="form.controls.customer_source_text"
          [submitted]="submitted"
        >
          <input
            type="text"
            id="customer_source_text"
            class="form-control"
            placeholder="แหล่งที่มาเพิ่มเติม"
            formControlName="customer_source_text"
          />
        </form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12">
        <form-group
          [control]="form.controls.credit_term_day"
          [submitted]="submitted"
        >
          <label for="credit">เครดิต (วัน)</label>
          <select
            class="form-control"
            id="credit_term_day"
            formControlName="credit_term_day"
          >
            <option value="">กรุณาเลือกข้อมูล</option>
            <option value="0">0 วัน</option>
            <option value="7">7 วัน</option>
            <option value="10">10 วัน</option>
            <option value="15">15 วัน</option>
            <option value="20">20 วัน</option>
            <option value="30">30 วัน</option>
            <option value="45">45 วัน</option>
            <option value="60">60 วัน</option>
            <option value="90">90 วัน</option>
          </select>
        </form-group>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12">
        <label for="billing_day">Billing Day</label>
        <form-group
          [control]="form.controls.billing_day"
          [submitted]="submitted"
        >
          <input
            type="text"
            id="billing_day"
            class="form-control"
            placeholder=""
            formControlName="billing_day"
          />
        </form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12">
        <form-group
          [control]="form.controls.billing_location"
          [submitted]="submitted"
        >
          <label for="billing_location">สถานที่วางบิล</label>
          <input
          type="text"
          id="billing_location"
          class="form-control"
          placeholder="สถานที่วางบิล"
          formControlName="billing_location"
        />
        </form-group>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12">
        <label for="check_location">สถานที่รับเช็ค</label>
        <form-group
          [control]="form.controls.check_location"
          [submitted]="submitted"
        >
          <input
            type="text"
            id="check_location"
            class="form-control"
            placeholder="สถานที่รับเช็ค"
            formControlName="check_location"
          />
        </form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-xl-4 col-lg-6 col-md-12">
        <label for="discount_percentage_init">ส่วนลดตั้งต้น (%)</label>
        <form-group
          [control]="form.controls.discount_percentage_init"
          [submitted]="submitted"
        >
          <input
            type="text"
            id="discount_percentage_init"
            class="form-control"
            placeholder=""
            oninput="this.value = 
            this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
            formControlName="discount_percentage_init"
          />
        </form-group>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12">
        <form-group
        [control]="form.controls.selling_type"
        [submitted]="submitted"
      >
        <label>ลักษณะงานขาย </label>
        <div class="input-group">
          <div
            class="d-inline-block custom-control custom-radio mr-1"
          >
            <input
              type="radio"
              value="normal"
              formControlName="selling_type"
              class="custom-control-input"
              id="selling_type_1"
            />
            <label
              class="custom-control-label"
              for="selling_type_1"
              >ทั่วไป</label
            >
          </div>
          <div
            class="d-inline-block custom-control custom-radio mr-1"
          >
            <input
              type="radio"
              value="outright"
              formControlName="selling_type"
              class="custom-control-input"
              id="selling_type_2"
            />
            <label
              class="custom-control-label"
              for="selling_type_2"
              >ขายขาด</label
            >
          </div>
        </div>
        </form-group>
      </div>
    </div>

    <div *ngIf="canSeeAll">
      <h4 class="my-2">ที่อยู่บริษัท</h4>
      <div class="row">
        <div class="col-xl-8 col-lg-6 col-md-12 mb-1">
          <form-group [control]="form.controls.address" [submitted]="submitted">
            <label for="address">
              ที่อยู่ <span class="danger" *ngIf="form.controls['address'].enabled">*</span>
            </label>
            <input
              type="text"
              id="address"
              class="form-control"
              placeholder="ที่อยู่"
              formControlName="address"
            />
          </form-group>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-12">
          <form-group
            [control]="form.controls.province_id"
            [submitted]="submitted"
          >
            <label for="province_id">
              จังหวัด
            </label>
            <ng-select2 [data]="provinces"
              [placeholder]="'จังหวัด'"
              formControlName="province_id"
              [width]="300">
            </ng-select2>
          </form-group>
        </div>
  
        <div class="col-xl-4 col-lg-6 col-md-12">
          <form-group
            [control]="form.controls.amphure_id"
            [submitted]="submitted"
          >
            <label for="amphure_id">
              เขต/อำเภอ
            </label>
            <ng-select2 [data]="amphures"
              [placeholder]="'เขต/อำเภอ'"
              formControlName="amphure_id"
              [width]="300">
            </ng-select2>
          </form-group>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-12">
          <form-group
            [control]="form.controls.district_id"
            [submitted]="submitted"
          >
            <label for="district_id">
              แขวง/ตำบล
            </label>
            <ng-select2 [data]="districts"
              [placeholder]="'แขวง/ตำบล'"
              formControlName="district_id"
              [width]="300">
            </ng-select2>
          </form-group>
        </div>
  
        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
          <form-group
            [control]="form.controls.geography_id"
            [submitted]="submitted"
          >
            <label for="geography_id">
              รหัสไปรษณีย์
            </label>
            <input
              type="text"
              id="geography_id"
              class="form-control"
              placeholder="รหัสไปรษณีย์"
              formControlName="geography_id"
            />
          </form-group>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
          <form-group
            [control]="form.controls.tel_1"
            [submitted]="submitted"
          >
            <label for="tel_1">โทรศัพท์ 1 <span class="danger" *ngIf="form.controls['tel_1'].enabled">*</span></label>
            <input
              type="text"
              id="tel_1"
              class="form-control"
              placeholder="โทรศัพท์ 1"
              formControlName="tel_1"
            />
          </form-group>
        </div>
  
        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
          <form-group
            [control]="form.controls.tel_2"
            [submitted]="submitted"
          >
            <label for="tel_2">โทรศัพท์ 2</label>
            <input
              type="text"
              id="tel_2"
              class="form-control"
              placeholder="โทรศัพท์ 2"
              formControlName="tel_2"
            />
          </form-group>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
          <form-group [control]="form.controls.email_1" [submitted]="submitted">
            <label for="email_1">E-mail 1</label>
            <input
              type="text"
              id="email_1"
              class="form-control"
              placeholder="E-mail 1"
              formControlName="email_1"
            />
          </form-group>
        </div>
  
        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
          <form-group [control]="form.controls.email_2" [submitted]="submitted">
            <label for="email_2">E-mail 2</label>
            <input
              type="text"
              id="email_2"
              class="form-control"
              placeholder="E-mail 2"
              formControlName="email_2"
            />
          </form-group>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
          <form-group [control]="form.controls.fax_1" [submitted]="submitted">
            <label for="fax_1">Fax 1</label>
            <input
              type="text"
              id="fax_1"
              class="form-control"
              placeholder="Fax 1"
              formControlName="fax_1"
            />
          </form-group>
        </div>
  
        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
          <form-group [control]="form.controls.fax_2" [submitted]="submitted">
            <label for="fax_2">Fax 2</label>
            <input
              type="text"
              id="fax_2"
              class="form-control"
              placeholder="Fax 2"
              formControlName="fax_2"
            />
          </form-group>
        </div>
      </div>
    </div>

    <h4 class="my-2" style="font-weight: 500;">Special condition</h4>
    <div class="row">
      <div class="col-lg-6 col-md-12 mb-1">
        <form-group>
          <label for="term_of_service" class="sr-only">คำอธิบาย</label>
          <textarea
            id="term_of_service"
            class="form-control"
            rows="3"
            formControlName="term_of_service"
          >
          </textarea>
          <!-- <p>
            <small class="text-muted blue-grey lighten-2">
              เงื่อนไขพิเศษ จะแสดงในใบสั่งงาน
            </small>
          </p> -->
        </form-group>
      </div>
    </div>
  </div>
</form>