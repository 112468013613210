import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { MITScopeService } from "src/app/services";

@Component({
  selector: "app-scope-list",
  templateUrl: "./scope-list.component.html",
  styleUrls: ["./scope-list.component.css"],
})
export class ScopeListComponent implements OnInit {
  scopes = [];
  status: string;
  mit_scope_id: string;
  reset: boolean = false;
  filter = {};
  isLoading = true;

  load: Function = this.MITScopeService.load;
  constructor(
    private router: Router,
    private MITScopeService: MITScopeService
  ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  async ngOnInit() {
    this.scopes = [];
  }
  onChangePage(scopes: Array<any>) {
    this.scopes = scopes;
    this.isLoading = false;
  }
  select(mit_scope_id: string) {
    this.mit_scope_id = mit_scope_id;
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
      this.mit_scope_id = null;
    }, 50);
  }
  search(filter) {
    this.filter = filter;
  }
  async delete(closeModalEl) {
    let response = await this.MITScopeService.delete({
      mit_scope_id: this.mit_scope_id,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }
}
