import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Select } from "@ngxs/store";
import { Observable } from "rxjs";
import {
  EmailTemplateAttachmentService,
  UploadService,
} from "src/app/services";
import {
  EmailTemplateAttachmentData,
  EmailTemplateAttachmentFilter,
} from "src/app/services/email-template-attachment.service";
import { DownloadFileService } from "src/app/services/quotations";
import { UserInfoState } from "src/app/store/user/user.state";
declare var $: any;
@Component({
  selector: "app-email-template-attachment-list",
  templateUrl: "./email-template-attachment-list.component.html",
  styleUrls: ["./email-template-attachment-list.component.css"],
})
export class EmailTemplateAttachmentListComponent implements OnInit {
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  isLoading: boolean = true;
  status: string;
  attachments: EmailTemplateAttachmentData[] = [];
  reset: boolean = false;
  load: Function = this.EmailTemplateAttachmentService.load;
  filter: EmailTemplateAttachmentFilter = {};
  createEmailTemplateAttachment: boolean = false;
  emailTemplateAttachmentData: EmailTemplateAttachmentData | null = null;
  emailTemplateAttachmentId: string;
  constructor(
    private router: Router,
    public UploadService: UploadService,
    private EmailTemplateAttachmentService: EmailTemplateAttachmentService,
    private DownloadFileService: DownloadFileService
  ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);

    this.userInfo$.subscribe((userInfo) => {
      if (userInfo.user_role_id !== "ADMIN") {
        window.location.href = "/landing";
      }
    });
  }

  routeEvent(state): void {
    this.status = state?.status;
  }
  async ngOnInit() {}

  onChangePage(attachments: EmailTemplateAttachmentData[]) {
    this.attachments = attachments;
    this.isLoading = false;
  }

  create() {
    this.createEmailTemplateAttachment = true;
    setTimeout(() => {
      $("#modalEmailTemplateAttachmentCreate").modal("toggle");
    }, 100);
  }

  edit(emailTemplateAttachmentData: EmailTemplateAttachmentData) {
    this.emailTemplateAttachmentData = emailTemplateAttachmentData;
    setTimeout(() => {
      $("#modalEmailTemplateAttachmentEdit").modal("toggle");
    }, 100);
  }

  select(emailTemplateAttachmentId: string) {
    this.emailTemplateAttachmentId = emailTemplateAttachmentId;
  }

  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }

  async delete(closeModalEl) {
    let response = await this.EmailTemplateAttachmentService.delete({
      email_template_attachment_id: this.emailTemplateAttachmentId,
    });
    closeModalEl.click();
    this.doReset(response.success || response.error);
  }

  saveEmailTemplateAttachment(e) {
    if (e) {
      this.doReset(e.status.success || e.status.error);
      e.closeModal.nativeElement.click();
    }
    this.emailTemplateAttachmentData = null;
    this.createEmailTemplateAttachment = false;
  }

  search(filter: EmailTemplateAttachmentFilter) {
    this.filter = filter;
  }

  close() {
    setTimeout(() => {
      this.emailTemplateAttachmentData = null;
      this.createEmailTemplateAttachment = false;
    }, 100);
  }

  downloadFile(attachment: EmailTemplateAttachmentData) {
    this.DownloadFileService.downloadFileURL(attachment);
  }
}
