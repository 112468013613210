<div class="container app-content overflow-container" [hidden]="!canAccess || !pageLoad">
  <div class="btn-section text-right border-bottom">
    <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(invoiceInfo)">
        <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
    </button>
  </div>
  <div *ngFor="let page of pages; let i = index; let lastIndex = last">
    <div class="pdf"  id="invoice_{{i}}">
      <div class="container mt-2" *ngIf="i == 0">
          <h2 class="text-center text-header pt-1">ใบส่งสินค้า / ใบเเจ้งหนี้</h2>
          <h2 class="text-center text-header pt-1">ใบรับมอบงาน</h2>
      </div>    
      <div class="container" *ngIf="i == 0">
          <div class="row">
            <img class="col-1 text-right p-0" src="/assets/app-assets/images/pdf/logo1.png" width="90px"
                height="110px">
            <div class="col-7 pt-1">
                <h2 class="text-left">บริษัท มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี จำกัด</h2>
                <h3 class="text-left">MIRACLE INTERNATIONAL TECHNOLOGY CO., LTD.</h3>
                <p class="text-left">214 ถนนบางแวก แขวงบางไผ่ เขตบางแค กรุงเทพฯ 10160 <br>
                  โทร. 0-2865-4647-8 โทรสาร 0-2865-4649
                </p>
                <b class="text-left">เลขประจำตัวผู้เสียภาษี 0-1055-45112-91-9 สถานประกอบการ สำนักงานใหญ่</b>
            </div>
            <div class="col-1"></div>
            <div class="col-3">
              <div class="mt-3"></div>
              <h3 class="text-right">เลขที่ {{invoiceInfo?.doc_no}}</h3>
            </div>
          </div>
      </div>
      <div class="container mt-1" *ngIf="i == 0">
        <div class="row">
            <table class="mx-auto table table-bordered">
              <thead>
                <tr>
                  <th class="text-center" scope="col">วันที่<br>DATE</th>
                  <th class="text-center" scope="col">ใบสั่งซื้อเลขที่<br>PURCHASE ORDER NO.</th>
                  <th class="text-center" scope="col">ผู้สั่งซื้อ<br>PURCHASE ORDER</th>
                  <th class="text-center" scope="col">พนักงานขาย<br>SALEMAN</th>
                  <th class="text-center" scope="col">CREDIT<br>TERM</th>
                  <th class="text-center" scope="col">วันครบกำหนดชำระ<br>DUE DATE</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center">{{invoiceInfo?.doc_date | thai_date: "short":"date" | dash}}</td>
                  <td class="text-center">
                    {{getPoNo()}}
                  </td>
                  <td class="text-center">{{contact?.customer_contact_info.firstname}} {{contact?.customer_contact_info.lastname}}</td>
                  <td class="text-center">{{invoiceInfo?.sales_assignment_info?.user_info?.firstname_th}} {{invoiceInfo?.sales_assignment_info?.user_info?.lastname_th || '-'}}</td>
                  <td class="text-center">{{invoiceInfo?.customer_credit_term_day}}</td>
                  <td class="text-center">{{invoiceInfo?.doc_duedate | thai_date: "short":"date" | dash}}</td>
                </tr>
              </tbody>
            </table>
        </div>
      </div>
      <div class="container mt-1" *ngIf="i == 0">
        <div class="row">
          <div class="col-2">
            <p class="text-left">ชื่อลูกค้า CUSTOMER NAME</p>
            <p class="text-left">ที่อยู่ ADDRESS</p>
          </div>
          <div class="col-5">
            <p class="text-left">{{invoiceInfo?.customer_address_name}}</p>
            <p class="text-left">{{invoiceInfo?.customer_address}}</p>
          </div>
        </div>
      </div>
      <div class="container">
        <div class="row">
          <div class="col-6" *ngIf="i == 0">
            <p class="text-left">เลขประจำตัวผู้เสียภาษี  
              <span *ngIf="invoiceInfo?.customer_address_info?.tax_id != 0 && invoiceInfo?.customer_address_info?.tax_id != ''">{{invoiceInfo?.customer_address_info?.tax_id}}</span>
              <span *ngIf="invoiceInfo?.customer_address_info?.tax_id == 0 || invoiceInfo?.customer_address_info?.tax_id == ''">-</span>
            </p>
          </div>
          <div class="col-6" *ngIf="i == 0">
            <p class="text-left">สถานประกอบการ {{invoiceInfo?.customer_address_info?.branch | dash}}</p>
          </div>
          <table class="mx-auto table table-bordered">
            <thead>
              <tr>
                <th class="text-center" width="10%">ลำดับ<br>ITEM</th>
                <th class="text-center" width="40%">รายการสินค้า<br>DESCRIPTION</th>
                <th class="text-center" width="10%">จำนวน<br>QUANTITY</th>
                <th class="text-center" width="10%">ราคาต่อหน่อย<br>UNIT PRICE</th>
                <th colspan="2" class="text-center" width="10%">ส่วนลด<br>DISCOUNT</th>
                <th class="text-center" width="10%">จำนวนเงิน<br>AMOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of page; let j = index;">
                <td class="text-center">
                  <div *ngIf="item?.index">
                    {{item?.index}}
                  </div>
                  <div *ngIf="!item?.index">
                    <br>
                  </div>
                </td>
                <td class="text-left">
                  {{item?.product_title}}
                  
                  <div *ngIf="item.item_type=='product'">
                    {{item.product_description}}
                  </div>
                  <div *ngIf="item.item_type=='calibration_service'">
                    <small>
                      <div *ngIf="item.data.description">Description: {{item.data.description | dash}}</div>
                      
                      Serial no: {{item.data.serial_no | dash}} / Tag No. {{item.data.tag_no | dash}}
                    </small>
                  </div>
                </td>
                <td class="text-center">{{item?.quantity}}</td>
                <td class="text-right">{{item?.price | number : '1.2-2'}}</td>
                <td colspan="2" class="text-center">
                  <div *ngIf="item?.discount > 0">
                    {{item?.discount}}
                  </div>
                </td>
                <td class="text-right">
                  <div *ngIf="item?.item_type=='calibration_service'">
                    {{_calculate.sumMit(item) | number : '1.2-2'}}
                  </div>
                  <div *ngIf="item?.item_type=='product'">
                      {{_calculate.sumProduct(item) | number : '1.2-2'}}
                  </div>
                </td>
              </tr>
              <tr>
                <td class="text-center">
                </td>
                <td class="text-left">
                  อ้างอิงใบเสนอราคาเลขที่: {{getQuotationNo()}}
                </td>
                <td class="text-center"></td>
                <td class="text-right"></td>
                <td colspan="2" class="text-center">
                 
                </td>
                <td class="text-right">
                 
                </td>
              </tr>

              <tr *ngIf="lastIndex">
                <td style="padding-top: 43px;" rowspan="3" colspan="2" class="text-center">{{invoiceInfo?.THBText}}</td>
                <td class="text-center" colspan="4">ราคาสินค้า / SUB TOTAL</td>
                <td class="text-right">{{count - invoiceInfo?.discount | number : '1.2-2'}}</td>
              </tr>
              <tr *ngIf="lastIndex">
                <td class="text-center" colspan="4">จำนวนภาษีมูลค่าเพิ่ม {{invoiceInfo?.tax}}% / VAT</td>
                <td class="text-right">
                  <span *ngIf="_calculate.sumProductWithTax(count, invoiceInfo) > 0">
                    {{_calculate.sumProductWithTax(count, invoiceInfo) | number : '1.2-2'}}
                  </span>
                </td>
              </tr>
              <tr *ngIf="lastIndex">
                  <td class="text-center" colspan="4">จำนวนเงินรวมทั้งสิ้น / TOTAL AMOUNT</td>
                  <td class="text-right">{{invoiceInfo?.grand_total | number : '1.2-2'}}</td>
              </tr>
              <tr *ngIf="lastIndex">
                <td rowspan="2" colspan="2" class="text-center">
                  <p>ได้รับมอบงานตามรายการข้างต้นในสภาพและถูกต้อง<br>
                    และใบรับรองผลการสอบเทียบครบถ้วนเรียบร้อยแล้ว
                  </p>
                  <div class="row form-group mx-sm-3 mb-2">
                      <label for="inputPassword2" class="col-2 mt-5 d-flex justify-content-end" style="font-weight: normal;">ผู้รับสินค้า</label>
                          <div class="col-sm-4 d-flex justify-content-end">
                            <div class="line-input"></div>
                          </div>
                      <label for="inputPassword2" class="col-2 mt-5 d-flex justify-content-end" style="font-weight: normal;">วันที่</label>
                          <div class="col-sm-4 d-flex justify-content-end">
                            <div class="line-input"></div>
                          </div>
                  </div>  
                </td>
                <td class="text-center pt-4" colspan="2"></td>
                <td class="text-right pt-4" colspan="3"></td>
              </tr>
              <tr *ngIf="lastIndex">
                <td class="text-center" colspan="2">
                 <p class="text-center pb-0">ผู้ส่งของ<br>
                  DELIVERY BY</p>
                  <div class="row form-group">
                    <label for="inputPassword2" class="col-3 d-flex justify-content-end" style="font-weight: normal;">วันที่<br>
                      DATE</label>
                        <div class="col-sm-8 d-flex justify-content-end">
                          <div class="line-input"></div>
                        </div>
                  </div>  
                </td>
                <td class="text-center" colspan="3"> <p class="text-center pb-0">
                  ผู้อนุมัติ<br>
                  APPROVED BY
                </p>
                  <div class="row form-group">
                    <label for="inputPassword2" class="col-3 d-flex justify-content-end" style="font-weight: normal;">วันที่<br>
                      DATE</label>
                        <div class="col-sm-8 d-flex justify-content-end">
                          <div class="line-input"></div>
                        </div>
                  </div> 
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>    
    </div>

    <div class="btn-section text-right border-bottom"></div>
    <br />
  </div>
</div>

<div class="container app-content" *ngIf="canAccess == false && pageLoad">
  <div class="text-center">
      <h2>ไม่สามารถเข้าถึงได้</h2>
      <h5>โปรดติดต่อพนักงานขาย</h5>
  </div>
</div>