<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายงานเช็ครับ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/invoice']">เอกสารทางบัญชี</a>
                </li>
                <li class="breadcrumb-item active">รายงานเช็ครับ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mt-1">รายงานเช็ครับ</h4>
                <div class="heading-elements">
                  <search-box (search)="search($event)"></search-box>
                </div>
              </div>
  
              <div class="card-content collapse show">
                <div class="card-body">
                    <loading *ngIf="isLoading"></loading>
                    <div class="table-responsive" *ngIf="!isLoading">
                        <table class="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>วันที่รับเช็ค</th>
                                    <th>ข้อมูลเช็ค</th>
                                    <th class="text-right">จำนวนเงิน (บาท)</th>
                                    <th>กำหนดวันนำเข้า</th>
                                    <th>วันที่เงินเข้า</th>
                                    <th>นำเข้าธนาคาร</th>
                                    <th class="text-center">สถานะ</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                              <tr *ngFor="let payment of payments; let i = index">
                                <td class="text-center text-nowrap">{{payment.payment_date | thai_date: "short":"date" | dash}}</td>
                                <td>
                                  <div>
                                    {{payment.bank_name}}
                                  </div>
                                  <div class="text-muted">
                                    <small>สาขา: {{payment.bank_branch}}</small><br>
                                    <small>เลขที่: {{payment.evidence_ref_no}}</small><br>
                                    <small>วันที่: {{payment.evidence_date | thai_date: "short":"date" | dash}}</small><br>
                                  </div>
                                </td>
                                <td class="text-right">{{payment.value_before_wht | number : '1.2-2'}}</td>
                                <td>{{payment.check_deposit_date | thai_date: "short":"date" | dash}}</td>
                                <td>{{payment.check_money_arrived_date | thai_date: "short":"date" | dash}}</td>
                                <td>{{payment.check_deposit_bank_name | dash}}</td>
                                <td class="text-center">
                                  <div class="text-primary" *ngIf="payment.check_deposit_status == 'SUCCESS'">
                                    เสร็จสิ้น
                                  </div>
                                  <div class="text-warning" *ngIf="payment.check_deposit_date == null">
                                    ยังไม่กำหนดวันนำเข้า
                                  </div>
                                  <div *ngIf="payment.check_deposit_status == 'WAITING_DEPOSIT'">
                                    <div class="text-warning"> 
                                      รอนำเข้า
                                    </div>
                                    <small>
                                      {{payment.check_deposit_date | thai_date: "short":"date" | dash}}
                                    </small>
                                  </div>
                                  <div class="text-danger" *ngIf="payment.check_deposit_status == 'CHECK_RETURNED'">
                                    เช็คคืน
                                  </div>
                                </td>
                                <td>
                                  <button type="button" class="btn mr-1 mb-1 btn-warning btn-sm text-nowrap" 
                                  *ngIf="payment.check_deposit_status != 'SUCCESS'"
                                  data-toggle="modal"
                                  data-target="#cheque-payment-edit"
                                  (click)="select(payment)">
                                    <i class="fa fa-pencil icon-left"></i> แก้ไข
                                  </button>
                                </td>
                              </tr>
                                <tr *ngIf="payments.length == 0">
                                    <td colspan="13" class="text-center">
                                        ไม่มีข้อมูล
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
  
                    <app-paginator
                      *ngIf="userInfo$ | async"
                      [filter]="filter"
                      [reset]="reset"
                      [fetch]="load"
                      (changePage)="onChangePage($event)">
                    </app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>

<app-cheque-edit [payment]="payment" *ngIf="payment" (done)="submitCheque($event)">
</app-cheque-edit>