<loading *ngIf="pageLoad"></loading>
<div class="card mb-0" [hidden]="pageLoad">
    <div class="card-content collapse show">
        <div class="card-body p-0">

            <div class="d-flex justify-content-between mb-1">
                <div>
                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#billing-note-item-create"
                    *ngIf="pageType !='show'"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบแจ้งหนี้
                    </button>
                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#billing-note-item-tax-create"
                    *ngIf="pageType !='show'"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบกำกับภาษี
                    </button>

                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#credit-note-item-create"
                    (click)="setBillType('credit')"
                    *ngIf="pageType !='show'"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบลดหนี้
                    </button>

                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#credit-note-item-create"
                    *ngIf="pageType !='show'"
                    (click)="setBillType('debit')"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการจากใบเพิ่มหนี้
                    </button>
                </div>
            </div>

            <div class="table-responsive" style="max-height: 600px;">
                <table class="table table-striped table-bordered">
                    <thead> 
                        <tr>
                            <th class="text-center">ลำดับ</th>
                            <th>เลขที่เอกสาร</th>
                            <th>วันที่ออก</th>
                            <th>กำหนดชำระ</th>
                            <th class="text-right">มูลค่า (บาท)</th>
                            <th>หมายเหตุ</th>
                            <th>สถานที่รับเช็ค</th>
                            <th>วันนัดชำระ</th>
                            <th *ngIf="pageType !='show'">
                                action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let billingNote of billingNoteItems; let i = index">
                            <td class="text-center">
                                {{i + 1}}
                            </td>
                            <td>
                                <div *ngIf="billingNote.doc_type == 'TAX_INVOICE'">
                                    {{billingNote?.data?.doc_no_company}}
                                  </div>
                                  <div *ngIf="billingNote.doc_type != 'TAX_INVOICE'">
                                    {{billingNote?.data?.doc_no}}
                                  </div>
                            </td>
                            <td>
                                {{billingNote?.data?.doc_date | thai_date: "short":"date" | dash }}
                            </td>
                            <td>
                                {{billingNote?.data?.doc_validdate | thai_date: "short":"date" | dash }}
                            </td>
                            <td class="text-right">
                                {{billingNote?.data?.grand_total | number : '1.2-2'}}
                            </td>
                            <td >
                              <textarea 
                              name="note"
                              [id]="'note_'+i" 
                              rows="1" 
                              [(ngModel)]="billingNote.note"
                              class="form-control"
                              [readonly]="pageType=='show'"></textarea>
                            </td>
                            <td>
                                <input 
                                name="appointment_location"
                                [id]="'appointment_location_'+i" 
                                rows="1" 
                                [(ngModel)]="billingNote.appointment_location"
                                class="form-control"
                                [readonly]="pageType=='show'"/>
                            </td>
                            <td class="text-nowrap">
                                <div *ngIf="!billingNote.appointment_date">
                                    ยังไม่ได้กำหนด 
                                    <i 
                                    class="fa fa-pencil text-warning pointer" 
                                    aria-hidden="true" 
                                    (click)="select(billingNote)"
                                    data-toggle="modal"
                                    data-target="#billing-note-item-history">
                                    </i>
                                </div>
                                <div *ngIf="billingNote.appointment_date">
                                    {{billingNote?.appointment_date | thai_date: "short":"date" | dash }} 
                                    <i 
                                    class="fa fa-pencil text-warning pointer" 
                                    aria-hidden="true" 
                                    (click)="select(billingNote)"
                                    data-toggle="modal"
                                    data-target="#billing-note-item-history">
                                    </i>
                                </div>
                            </td>
                            <td *ngIf="pageType !='show'">
                                <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#modalConfirmDelMsgBillingNoteItem"
                                (click)="select(billingNote)">
                                ลบ
                            </button>
                            </td>
                        </tr>
                    </tbody>

                    <tbody *ngIf="billingNoteItems.length == 0">
                        <tr>
                            <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>

<app-billing-note-item-create *ngIf="customer_info" [customer_info]="customer_info" (done)="submitBillingNoteItem($event);">
</app-billing-note-item-create>

<app-billing-note-item-tax-create *ngIf="customer_info" [customer_info]="customer_info" (done)="submitBillingNoteItem($event);">
</app-billing-note-item-tax-create>

<app-billing-note-item-credit-create *ngIf="customer_info && billType" [billType]="billType" [customer_info]="customer_info" (done)="submitBillingNoteItem($event);">
</app-billing-note-item-credit-create>

<app-billing-note-item-history *ngIf="billingNote" [billing_note_item]="billingNote" (done)="submitHistory($event);">
</app-billing-note-item-history>

<app-confirm-delete
  [id]="'modalConfirmDelMsgBillingNoteItem'"
  (save)="delete($event)"
></app-confirm-delete>