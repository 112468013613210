<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">

      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">ใบเสนอราคา</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/quotation']">จัดการใบเสนอราคา</a>
                </li>
                <li class="breadcrumb-item active">ใบเสนอราคา</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right" *ngIf="pageLoad">
            <button
            (click)="back()"
            type="button"
            class="btn btn-light btn-min-width ml-1"
            >
            <i class="fa fa-arrow-left"></i>  กลับ
          </button>
          <button
            *ngIf="(quotationInfo?.doc_status_id === 'DRAFT' || quotationInfo?.doc_status_id === 'WAITTING_FOR_ACCEPT')
            && ((userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales_supervisor
            || ((userInfo$ | async)?.is_co_sales && quotationInfo?.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)
            ||  quotationInfo?.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id) && loadBtn"
            type="button"
            class="btn btn-danger btn-min-width ml-1"
            data-target="#quotation-confirm-voided"
            data-toggle="modal"
            >
            <i class="fa fa-times icon-left"></i> ยกเลิก
          </button>
          <button *ngIf="(quotationInfo?.doc_status_id == 'DRAFT' || quotationInfo?.doc_status_id == 'REVISE')
            && (quotationInfo?.sales_assignment_info)
            && ((userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales_supervisor || (userInfo$ | async)?.is_co_sales
            ||  quotationInfo.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
            [routerLink]="['/quotation/'+ quotation_info_id + '/edit']"
            type="button"
            class="btn btn-warning btn-min-width ml-1"
            >
            <i class="fa fa-pencil"></i>  แก้ไข
          </button>
          <button *ngIf="quotationInfo?.doc_status_id == 'WAITING_FOR_APPROVE' && (userInfo$ | async)?.is_sales_manager && loadBtn"
            (click)="saveAsWaitingForAccept(true)"
            type="button"
            class="btn btn-success btn-min-width ml-1"
            >
            <i class="fa fa-check-circle-o"></i>  อนุมัติ
          </button>
          <button *ngIf="quotationInfo?.doc_status_id == 'WAITING_FOR_APPROVE' && (userInfo$ | async)?.is_sales_manager && loadBtn"
            (click)="saveAsWaitingForAccept(false)"
            type="button"
            class="btn btn-success btn-min-width ml-1"
            >
            <i class="fa fa-check-circle-o"></i>  อนุมัติโดยไม่ส่งอีเมล
          </button>
          <button *ngIf="quotationInfo?.doc_status_id == 'WAITING_FOR_APPROVE' && (userInfo$ | async)?.is_sales_manager && loadBtn"
            (click)="saveAsRejected()"
            type="button"
            class="btn btn-danger btn-min-width ml-1"
            >
            <i class="fa fa-times-circle-o"></i>  ไม่อนุมัติ
          </button>

          <button *ngIf="quotationInfo?.doc_status_id == 'ACCEPTED' 
          && (quotationInfo?.sales_assignment_info)
          && ((userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales_supervisor || (userInfo$ | async)?.is_co_sales
          ||  quotationInfo.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id) && loadBtn"
            (click)="confirm('REVISE')"
            type="button"
            class="btn btn-warning btn-min-width ml-1"
            >
            <i class="fa fa-pencil"></i> Revise
          </button>

          <button *ngIf="quotationInfo?.doc_status_id == 'REVISE_WAITING_FOR_APPROVE' && loadBtn && (userInfo$ | async)?.is_sales_manager"
            type="button"
            class="btn btn-success btn-min-width ml-1"
            (click)="approve(true)"
            >
            <i class="fa fa-check-circle-o"></i>  อนุมัติ
          </button>

          <button *ngIf="quotationInfo?.doc_status_id == 'REVISE_WAITING_FOR_APPROVE' && loadBtn && (userInfo$ | async)?.is_sales_manager"
            type="button"
            class="btn btn-success btn-min-width ml-1"
            (click)="approve(false)"
            >
            <i class="fa fa-check-circle-o"></i>  อนุมัติโดยไม่ส่งอีเมล
          </button>
          <button *ngIf="quotationInfo?.doc_status_id == 'REVISE_WAITING_FOR_APPROVE' && loadBtn && (userInfo$ | async)?.is_sales_manager"
            (click)="Revise()"
            type="button"
            class="btn btn-danger btn-min-width ml-1"
            >
            <i class="fa fa-times-circle-o"></i> ไม่อนุมัติ
          </button>

          <button *ngIf="quotationInfo?.doc_status_id == 'ACCEPTED' && canDraft && loadBtn"
            type="button"
            class="btn btn-warning btn-min-width ml-1"
            (click)="confirm('REJECT')"
            >
            <i class="fa fa-pencil"></i> ถอยสถานะไปเป็นร่าง
          </button>
          </div>
        </div>
      </div>
      
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="!pageLoad"></loading>

        <div class="card" *ngIf="pageLoad">
          <div class="card-header">
              <h4 class="card-title d-inline mr-50">ใบเสนอราคา
              </h4>
              <span>
                พนักงานขาย: {{quotationInfo.sales_assignment_info?.user_info?.firstname_th}} {{quotationInfo.sales_assignment_info?.user_info?.lastname_th || '-'}}
              </span> 
              &nbsp;
              <span [ngClass]="{'badge-info': quotationInfo?.doc_status_id === 'DRAFT',
              'badge-warning':['WAITTING_FOR_ACCEPT', 'WAITING_FOR_APPROVE'].includes(quotationInfo?.doc_status_id),
              'badge-danger':quotationInfo?.doc_status_id === 'VOIDED',
              'badge-success': quotationInfo?.doc_status_id === 'ACCEPTED'
              }"
              class="badge">{{
                quotationInfo?.doc_status_info?.document_status_name_th
              }}</span>
              <div class="float-md-right">
                <button *ngIf="(quotationInfo.doc_status_id =='WAITTING_FOR_ACCEPT' ||
                quotationInfo.doc_status_id=='ACCEPTED')
                  && ((userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales_supervisor || (userInfo$ | async)?.is_co_sales
                  ||  quotationInfo.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1 "
                  target="_blank"
                  (click)="sendEmail(quotationInfo)"
                  >
                      <i class="fa fa-envelope icon-left"></i> ส่งอีเมล
                </button>
                <button *ngIf="quotationInfo.doc_status_id != 'VOIDED' 
                  && quotationInfo.doc_status_id != 'REVISE' 
                  && ((userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales_supervisor || (userInfo$ | async)?.is_co_sales
                  ||  quotationInfo.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1 "
                  target="_blank"
                  (click)="openPdf(quotationInfo)"
                  >
                    <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                </button>
              </div>
          </div>

          <div class="card-content collapse show">
              <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
                  <div class="card-body">
                      <div class="form-body">
                              <div class="row">
                                <div class="col-xl-3 col-lg-6 col-md-12 pb-1">
                                 
                                  <label for="quotation_sub_header_id">
                                      ประเภทใบเสนอราคา
                                  </label>
                                  <input
                                    id="quotation_sub_header_id"
                                    type="text"
                                    class="form-control"
                                    placeholder="ประเภทใบเสนอราคา"
                                    formControlName="quotation_sub_header_id"/>
                                </div>
                              </div>
                              <h4 class="badge btn-blue badge-customer-lvl" *ngIf="customer_level_id">
                                {{ customer_level_id | dash }}
                              </h4>
                              <div class="row">
                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                      <form-group [control]="form.controls.customer_id" [submitted]="submitted">
                                      <label for="customer_id">
                                          ชื่อลูกค้า
                                      </label>
                                      <input
                                        type="text"
                                        id="customer_id"
                                        class="form-control"
                                        placeholder="ชื่อลูกค้า"
                                        formControlName="customer_id"
                                        />
                                      </form-group>
                                  </div>

                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                      <form-group
                                      [control]="form.controls.doc_date"
                                      [submitted]="submitted">
                                      <label for="doc_date">
                                          วันที่ออก
                                      </label>
                                      <div class="input-group mt-0">
                                          <input
                                          type="text"
                                          id="doc_date"
                                          class="form-control"
                                          formControlName="doc_date"
                                          />
                                          <div
                                          class="input-group-append"
                                          for="doc_date"
                                          >
                                          <span class="input-group-text"
                                              ><i class="feather icon-calendar"></i
                                          ></span>
                                          </div>
                                      </div>
                                      </form-group>
                                  </div>

                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                    <form-group
                                    [control]="form.controls.doc_validdate"
                                    [submitted]="submitted">
                                    <label for="doc_validdate">
                                        ใช้ได้ถึง
                                    </label>
                                    <div class="input-group mt-0">
                                        <input
                                        type="text"
                                        id="doc_validdate"
                                        class="form-control"
                                        formControlName="doc_validdate"
                                        />
                                        <div
                                        class="input-group-append"
                                        for="doc_validdate"
                                        >
                                        <span class="input-group-text"
                                            ><i class="feather icon-calendar"></i
                                        ></span>
                                        </div>
                                    </div>
                                    </form-group>
                                </div>

                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                      <form-group
                                      [control]="form.controls.doc_no"
                                      [submitted]="submitted">
                                      <label for="doc_no">
                                          เลขที่เอกสาร
                                      </label>
                                      <input
                                          type="text"
                                          id="doc_no"
                                          class="form-control"
                                          placeholder="เลขที่เอกสาร"
                                          formControlName="doc_no"
                                      />
                                      </form-group>
                                  </div>
                              </div>

                              <div class="row">
                                  <div class="col-lg-3 col-md-12">
                                      <form-group
                                      [control]="form.controls.customer_address_id"
                                      [submitted]="submitted">
                                      <label for="customer_address_id">
                                        ออกเอกสารในนาม
                                      </label>
                                      <input
                                        type="text"
                                        id="customer_address_id"
                                        class="form-control"
                                        placeholder="ออกเอกสารในนาม"
                                        formControlName="customer_address_id"
                                        />
                                        <small>mit_iplus_code: {{form.value.mit_iplus_code | dash}}</small>
                                      </form-group>
                                  </div>
                                  <div class="col-lg-6 col-md-12">
                                    <form-group
                                          [control]="form.controls.customer_address"
                                          [submitted]="submitted"
                                        >
                                          <label for="customer_address">
                                            ที่อยู่ 
                                          </label>
                                          <input
                                          type="text"
                                          id="customer_address"
                                          class="form-control"
                                          placeholder="ที่อยู่"
                                          formControlName="customer_address"
                                          readonly
                                          />
                                      </form-group>
                                  </div>
                                  <div class="col-lg-3 col-md-12">
                                    <form-group
                                    [control]="form.controls.customer_contact_ids"
                                    [submitted]="submitted">
                                    <label for="customer_contact_ids">
                                        ผู้ติดต่อ / เรียน
                                    </label>
                                    <ng-select2
                                      id="customer_contact_ids"
                                      [data]="customerContactList"
                                      class="w-100"
                                      placeholder="ผู้ติดต่อ / เรียน"
                                      [options]="{
                                        templateSelection: _ngSelect2Customer?.templateSelection,
                                        multiple: true
                                      }"
                                      formControlName="customer_contact_ids">
                                    </ng-select2>
                                    </form-group>
 
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-3 col-md-12">
                                  <form-group
                                    [control]="form.controls.discount_percentage"
                                        [submitted]="submitted"
                                      >
                                        <label for="discount_percentage">
                                          ส่วนลดตั้งต้น (%)
                                        </label>
                                        <input
                                          type="text"
                                          id="discount_percentage"
                                          class="form-control"
                                          placeholder="ส่วนลดตั้งต้น"
                                          formControlName="discount_percentage"
                                          readonly
                                        />
                                    </form-group>
                                </div>
                            </div>
                      </div>

                      <div class="mt-1">
                          <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" (click)="tab = 'product-tab'">
                              <a
                                class="nav-link active"
                                id="product-tab"
                                data-toggle="tab"
                                href="#product"
                                aria-controls="product"
                                role="tab"
                                aria-selected="true"
                                >รายการสินค้า/บริการ</a
                              >
                            </li>
                            <li class="nav-item" (click)="tab = 'mit-tab' && mitReady = 'yes'">
                              <a
                                class="nav-link"
                                id="address-tab"
                                data-toggle="tab"
                                href="#address"
                                aria-controls="address"
                                role="tab"
                                aria-selected="false"
                                >ข้อมูลบริการสอบเทียบ ({{number_of_mit_items}})</a
                              >
                            </li>
                          </ul>
                          <div class="tab-content pt-1">
                            <div
                              class="tab-pane active in"
                              id="product"
                              aria-labelledby="product-tab"
                              role="tabpanel">
                              <div class="card" *ngIf="tab === 'product-tab'">
                                <div class="card-content">
                                  <div class="card-body">
                                    <div class="table-responsive">
                                      <table class="table table-striped table-bordered">
                                          <thead>
                                              <tr class="text-center">
                                                  <th>ลำดับ</th>
                                                  <th>รหัสสินค้า</th>
                                                  <th class="text-left">ชื่อสินค้า</th>
                                                  <th class="text-left">คำอธิบาย</th>
                                                  <th class="text-right">จำนวน</th>
                                                  <th>Unit</th>
                                                  <th class="text-right">ราคา/หน่วย</th>
                                                  <th class="text-right">ส่วนลด (%)</th>
                                                  <th class="text-right">รวมเงิน</th>
                                              </tr>
                                          </thead>
                                          <thead *ngFor="let item of productItemList; let i = index;">
                                              <tr *ngIf="item.item_type === 'product'">
                                                  <td class="text-center text-bold-600">{{item.index}}</td>
                                                  <td class="text-center">{{item.product_info?.product_code}}</td>
                                                  <td>
                                                    {{item.product_title}}
                                                    <br>
                                                    <small *ngIf="item?.related_job_order_info?.doc_no">
                                                      Job Order: {{item?.related_job_order_info?.doc_no}}
                                                    </small>
                                                  </td>
                                                  <td>{{item.product_description}}</td>
                                                  <td class="text-right">{{item.quantity}}</td>
                                                  <td class="text-center">
                                                    <div [innerHTML]="item.unit_short_name_en">

                                                    </div>
                                                  </td>
                                                  <td>
                                                    <div class="text-right">
                                                      {{item.price | number : '1.2-2'}}
                                                    </div>
                                                    <div class="text-danger text-right" 
                                                    *ngIf="_utils.convertStingToNumber(item.original_price) != _utils.convertStingToNumber(item.price)">
                                                      ราคาตั้งต้น: {{_utils.convertStingToNumber(item.original_price) | number : '1.2-2'}}
                                                    </div>
                                                    <div class="text-danger text-right"  *ngIf="item.lock_invoice_at"> 
                                                      ต้นทุนการให้บริการ {{item.cost_of_sales | number : '1.2-2'}} บาท
                                                    </div>
                                                  </td>
                                                  <td class="text-right">{{item.discount | number : '1.2-2'}}</td>
                                                  <td class="text-right">{{_calculate.sumProduct(item) | number : '1.2-2'}}</td>
                                              </tr>
                                              <tr *ngIf="item.item_type === 'calibration_service'">
                                                <td class="text-center text-bold-600">{{item.index}}</td>
                                                <td colspan="7" >{{item.product_title}}</td>
                                                <td colspan="1" class="text-right">
                                                  {{item.price | number : '1.2-2'}}
                                                </td>
                                              </tr>
                                              <tr *ngIf="item.item_type  === 'description'">
                                                <td class="text-center"></td>
                                                <td colspan="8">
                                                    {{item.product_description}}
                                                </td>
                                              </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngIf="productItemList.length === 0">
                                                <td colspan="9" class="text-center">ไม่มีข้อมูล</td>
                                            </tr>
                                          </tbody>
                                      </table>
                                  </div>

                                  <div class="py-2">
                                      <div class="row">
                                          <div class="col-md-8 col-sm-8 mt-75">

                                              <label for="file"><b>แนบไฟล์</b></label>
                                              <div class="mt-1 mb-1">
                                                <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                                                    <span style="cursor: pointer;" (click)="downloadFile(file)">
                                                        {{file.file_name}}
                                                    </span>
                                                </span> 
                                                <span *ngIf="files.length == 0" class="text-dark">
                                                    ไม่มีไฟล์
                                                </span> 
                                              </div>
                                            
                                          </div>

                                          <div class="col-md-4 col-sm-4 mt-50">
                                              <ul class="list-group cost-list">
                                                  <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                                      <span class="cost-title mr-2">รวมเป็นเงิน </span>
                                                      <span class="cost-value">{{count | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                                  </li>

                                                  <li class="list-group-item border-0 p-50">
                                                      <div class="row">
                                                          <div class="col-6">
                                                            <div class="d-inline"><span class="cost-title mr-1">ภาษีมูลค่าเพิ่ม</span></div>
                                                            <select class="" id="tax" formControlName="tax">
                                                              <option value="0">n/a</option>
                                                              <option value="7">7%</option>
                                                              <option value="10">10%</option>
                                                            </select>
                                                          </div>

                                                          <div class="col-6 text-right">
                                                              <span class="cost-value">{{_calculate.sumProductWithTax(count, form.value) | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                                          </div>
                                                      </div>

                                                  </li>
                                                  <li class="dropdown-divider"></li>
                                                  <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                                      <span class="cost-title mr-2">รวมทั้งสิ้น </span>
                                                      <span class="cost-value">{{form.get('grand_total').value | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                                  </li>
                                                  <li class="dropdown-divider"></li>
                                                  <li class="dropdown-divider mt-0"></li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="tab-pane"
                              id="address"
                              aria-labelledby="address-tab"
                              role="tabpanel">
                              <app-quotation-mit-item  
                              [customer_id]="quotationInfo.customer_id"
                              (done)="onGetMitItem($event);"
                               >  
                              </app-quotation-mit-item>
                            </div>
                          </div>
                        </div>
                  </div>
              </form>
          </div>
      </div>
      </div>

      <app-quotation-accept-list (done)="getAcceeptedList($event)" [quotationMitItems]="mitItems?.quotationMitItems" [quotationProductItems]="productItemList" (onSave)="reload($event)" [quotationInfo]="quotationInfo" *ngIf="(quotationInfo?.doc_status_id == 'ACCEPTED' || quotationInfo?.doc_status_id == 'WAITTING_FOR_ACCEPT') 
      && (quotationInfo.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id || (userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales || (userInfo$ | async)?.is_sales_supervisor || (userInfo$ | async)?.is_co_sales_supervisor) && pageLoad"></app-quotation-accept-list>

      <app-quotation-note-show [note_type]="'standard_in'" *ngIf="pageLoad"></app-quotation-note-show>

      <app-quotation-note-show [note_type]="'standard_out'" *ngIf="pageLoad"></app-quotation-note-show>

      <app-quotation-memo *ngIf="pageLoad"></app-quotation-memo>
    </div>
</div>

<app-quotation-confirm
[confirmMessage]="confirmMessage"
(save)="onSaveConfirm($event)" 
>
</app-quotation-confirm>

<app-quotation-confirm-voided 
  *ngIf="quotationInfo"
  (save)="onSaveConfirmVoided($event)" 
  [quotation]="quotationInfo">
</app-quotation-confirm-voided>


