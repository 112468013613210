import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Output,
  EventEmitter,
  Input,
  NgZone,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SalesTeamService } from "src/app/services";

@Component({
  selector: "app-sales-team-edit",
  templateUrl: "./sales-team-edit.component.html",
  styleUrls: ["./sales-team-edit.component.css"],
})
export class SalesTeamEditComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  @Input() employees: [];
  @Input() coSales: [];
  @Input("sales-team-id") sales_team_id: string;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private zone: NgZone,
    private FormBuilder: FormBuilder,
    private SalesTeamService: SalesTeamService
  ) {
    this.setForm();
  }
  setForm() {
    this.form = this.FormBuilder.group({
      sales_team_name: ["", Validators.required],
      team_leader_id: ["", Validators.required],
      team_cosale_id: ["", Validators.required],
    });
  }
  ngOnInit(): void {
    this.SalesTeamService.getById({
      sales_team_id: this.sales_team_id,
    }).then((result) => {
      if (result.success) {
        let salesTeam = result.resultData;
        for (let key in this.form.value) {
          this.form.controls[key].setValue(salesTeam[key] || "");
        }
      }
    });
  }
  async onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let data = {
      ...this.form.value,
      sales_team_id: this.sales_team_id,
    };
    let response = await this.SalesTeamService.update(data);
    this.done.emit(response.success || response.error);
    this.closeModalBtn.nativeElement.click();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      } else {
        this.ngOnInit();
      }
    });
  }
}
