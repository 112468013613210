import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccBillingNoteEditHistoryService {
  async create({
    acc_billing_note_info_id,
    document_event_id,
    event_text
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-billing-note-edit-history`, {
        acc_billing_note_info_id,
        document_event_id,
        event_text
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        acc_billing_note_edit_history_id,
        acc_billing_note_info_id,
        document_event_id,
        event_text
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/acc-billing-note-edit-history/${acc_billing_note_edit_history_id}`, {
        acc_billing_note_info_id,
        document_event_id,
        event_text
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_billing_note_edit_history_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-billing-note-edit-history/${acc_billing_note_edit_history_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-edit-history`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ acc_billing_note_edit_history_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-edit-history/${acc_billing_note_edit_history_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
