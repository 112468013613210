import { Component, ElementRef, OnInit, ViewChild, EventEmitter,Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-bill-update-sent-info',
  templateUrl: './bill-update-sent-info.component.html',
  styleUrls: ['./bill-update-sent-info.component.css']
})
export class BillUpdateSentInfoComponent implements OnInit {
  form: FormGroup;
  submitted: boolean = false;

  @Input('type') type: string;
  @Input('billing') billing: any;
  @Input('transportContactList') transportContactList: any;

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private _fbd: FormBuilder
  ) { }

  async ngOnInit() {
    this.form = this._fbd.group({
      sent_at:  [""],
      sent_by:  [""],
    });

    if(this.type == 'date'){
      this.form.controls['sent_at'].setValidators([Validators.required]);
      this.form.controls['sent_by'].setValidators(null);
    }else{
      this.form.controls['sent_by'].setValidators([Validators.required]);
      this.form.controls['sent_at'].setValidators(null);
    }
    this.form.get("sent_at").updateValueAndValidity();
    this.form.get("sent_by").updateValueAndValidity();
    this.form.patchValue(this.billing);
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submitted = false;
    let data = {
      ...this.form.value,
    }
    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }

  close(){
    this.done.emit(false);
    this.closeModalBtn.nativeElement.click();
  }
}
