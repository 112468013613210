<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-0">Secure Code</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/users']">ภาพรวมระบบ</a>
                </li>
                <li class="breadcrumb-item active">Secure Code</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        </div>
      </div>
      <div class="content-body">
        <app-response-status
          *ngIf="status"
          [status]="status"
        ></app-response-status>
        <form [formGroup]="form" autocomplete="off">
            <div class="card col-lg-7 col-md-12 col-sm-12">
                <div class="card-content collapse show">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-12 col-sm-12 text-right">
                                <h5>Secure Code : </h5>
                            </div>
                            <div class="col-xl-6 col-lg-9 col-md-12 col-sm-12">
                                <div class="btn-group">
                                    <input
                                        type="text"
                                        id="secure_code"
                                        class="form-control text-center"
                                        formControlName="secure_code"
                                        (click)="$event.target.select()"
                                        readonly
                                        style="font-size: 24px;letter-spacing: 1px;"
                                    />
                                    <a
                                      class="btn btn-primary justify-content-center align-items-center text-white"
                                      style="width: 130px;"
                                      (click)="copySecureCode()" 
                                    >
                                      คัดลอก
                                    </a>
                                </div>
                                <div>
                                    <small>
                                       เปลี่ยนล่าสุด {{form.value.secure_code_last_update | thai_date: "short":"date" | dash}}
                                    </small>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="card col-lg-7 col-md-12 col-sm-12">
                <div class="card-content collapse show">
                    <div class="card-body col-xl-6 col-lg-8 col-md-12 col-sm-12">
                        <h6>เปลี่ยน Secure Code</h6>
                        <div class="pt-1">
                            <div class="mt-1">
                                <form-group
                                  [submitted]="submitted"
                                  [control]="form.controls.new_secure_code"
                                >
                                  <label for="new_secure_code"
                                    >กรอก Secure Code ใหม่ <span class="danger">*</span></label
                                  >
                                  <input
                                    type="text"
                                    placeholder="กรอก Secure Code ใหม่"
                                    id="new_secure_code"
                                    formControlName="new_secure_code"
                                    class="form-control"
                                    (click)="$event.target.select()"
                                    oninput="this.value = 
                                    this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                  />
                                </form-group>
                              </div>
                  
                              <button
                                (click)="submit()"
                                type="button"
                                class="btn btn-primary btn-min-width"
                              >
                                <i class="fa fa-save"></i> บันทึก
                              </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
      </div>
    </div>
  </div>