import { Component, OnInit } from "@angular/core";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import {
  ProductService,
  ProductTypeService,
  UnitService,
  MITScopeService,
  MITEquipmentService,
  MITMethodService,
  ProductPriceUnitService,
  ProductCalibrationDetailService,
  ProductCalibrationFeeService,
  MITCPService,
} from "src/app/services";
import _ from "lodash";
const CALIBRATION = "3";
@Component({
  selector: "app-product-edit",
  templateUrl: "./product-edit.component.html",
  styleUrls: ["./product-edit.component.css"],
})
export class ProductEditComponent implements OnInit {
  CALIBRATION = "3";
  isReady = false;
  form: FormGroup;
  // productDetailForm: FormGroup;
  submitted = false;
  status: any;

  productTypes = [];
  units = [];
  // scopes = [];
  // equipments = [];
  // methods = [];
  // fees = [];
  // cps = [];
  product_id: string;
  product = null;
  services = [];
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private ProductService: ProductService,
    private ProductPriceUnitService: ProductPriceUnitService,
    private ProductCalibrationDetailService: ProductCalibrationDetailService,
    private ProductCalibrationFeeService: ProductCalibrationFeeService,

    private ProductTypeService: ProductTypeService,
    private UnitService: UnitService,
    private MITScopeService: MITScopeService,
    private MITEquipmentService: MITEquipmentService,
    private MITMethodService: MITMethodService,
    private MITCPService: MITCPService
  ) {
    this.setForm();
  }
  setForm() {
    this.form = this.formBuilder.group({
      // product info
      product_type_id: ["", Validators.required],
      product_code: [""],
      product_title: ["", Validators.required],
      product_description: [""],
      unit_id: ["", [Validators.required]],
      price: ["", [Validators.min(0)]],
    });

    // this.productDetailForm = this.formBuilder.group({
    //   // product detail
    //   service_lab: [false],
    //   service_onsite: [false],
    //   mit_scope_id: ["", [Validators.required]],
    //   mit_equipment_id: ["", [Validators.required]],
    //   mit_method_id: ["", [Validators.required]],
    //   mit_cp_id: ["", [Validators.required]],
    //   note_1: [""],
    //   note_2: [""],
    //   service: ["", [Validators.required]],
    // });
  }
  async ngOnInit() {
    let promises = [];
    promises.push(
      this.ProductTypeService.load().then((response) => {
        if (response.success) {
          this.productTypes = response.resultData || [];
        }
      })
    );

    promises.push(
      this.UnitService.load().then((response) => {
        if (response.success) {
          this.units = this.groupUnit(response.resultData) || [];
        }
      })
    );
    // this.MITScopeService.load().then((response) => {
    //   if (response.success) {
    //     this.scopes = response.resultData || [];
    //   }
    // });

    // this.MITMethodService.load().then((response) => {
    //   if (response.success) {
    //     this.methods = response.resultData || [];
    //   }
    // });

    // this.MITCPService.load().then((response) => {
    //   if (response.success) {
    //     this.cps = response.resultData || [];
    //   }
    // });

    // this.productDetailForm.controls.service_lab.valueChanges.subscribe(
    //   (isLab) => {
    //     if (isLab) {
    //       this.services.push("LAB");
    //     } else {
    //       this.services = this.services.filter((v) => v !== "LAB");
    //     }
    //     this.productDetailForm.controls.service.setValue(
    //       this.services.join(", ")
    //     );
    //   }
    // );

    // this.productDetailForm.controls.service_onsite.valueChanges.subscribe(
    //   (isOnsite) => {
    //     if (isOnsite) {
    //       this.services.push("ONSITE");
    //     } else {
    //       this.services = this.services.filter((v) => v !== "ONSITE");
    //     }
    //     this.productDetailForm.controls.service.setValue(
    //       this.services.join(", ")
    //     );
    //   }
    // );

    // this.form.controls.product_type_id.valueChanges.subscribe(
    //   (product_type_id) => {
    //     if (product_type_id === CALIBRATION) {
    //       this.form.get("unit_id").setValidators(null);
    //       this.form.get("price").setValidators(null);
    //     } else {
    //       this.form.get("unit_id").setValidators([Validators.required]);
    //       this.form.get("price").setValidators([Validators.required]);
    //     }
    //     this.form.get("unit_id").updateValueAndValidity();
    //     this.form.get("price").updateValueAndValidity();
    //   }
    // );

    this.product_id = this.route.snapshot.paramMap.get("product_id");
    promises.push(
      this.ProductService.getById({ product_id: this.product_id }).then(
        async (response) => {
          if (response.success) {
            let product = response.resultData;
            this.product = product;
            // console.log(response.resultData);
            this.form.controls.product_type_id.setValue(
              product.product_type_id
            );
            this.form.controls.product_code.setValue(product.product_code);
            this.form.controls.product_code.disable();
            this.form.controls.product_title.setValue(product.product_title);
            this.form.controls.product_description.setValue(
              product.product_description
            );
            if (
              product.product_type_id === "1" ||
              product.product_type_id === "2"
            ) {
              this.form.controls.unit_id.setValue(
                product.product_price_unit_info.unit_id
              );
              this.form.controls.price.setValue(
                product.product_price_unit_info.price.toFixed(2)
              );
            }

            // if (product.product_type_id === CALIBRATION) {
            //   this.fees = product.product_calibration_detail_info.product_calibration_fee_info.map(
            //     (v) => {
            //       return {
            //         ...v,
            //         unit_name: v.unit_info.unit_name_en,
            //       };
            //     }
            //   );

            //   let services = product.product_calibration_detail_info.service.split(
            //     ", "
            //   );
            //   this.services = services;

            //   this.productDetailForm.controls.service_lab.setValue(
            //     services.includes("LAB")
            //   );
            //   this.productDetailForm.controls.service_onsite.setValue(
            //     services.includes("ONSITE")
            //   );

            //   this.productDetailForm.controls.mit_scope_id.setValue(
            //     product.product_calibration_detail_info.mit_scope_id
            //   );

            //   await this.changeScope();

            //   this.productDetailForm.controls.mit_equipment_id.setValue(
            //     product.product_calibration_detail_info.mit_equipment_id
            //   );
            //   this.productDetailForm.controls.mit_method_id.setValue(
            //     product.product_calibration_detail_info.mit_method_id
            //   );
            //   this.productDetailForm.controls.mit_cp_id.setValue(
            //     product.product_calibration_detail_info.mit_cp_id
            //   );
            //   this.productDetailForm.controls.note_1.setValue(
            //     product.product_calibration_detail_info.note_1
            //   );
            //   this.productDetailForm.controls.note_2.setValue(
            //     product.product_calibration_detail_info.note_2
            //   );
            // }
          }
        }
      )
    );
    await Promise.all(promises);
    this.isReady = true;
  }

  async submit() {
    this.submitted = true;
    this.form.controls.product_code.enable();
    // console.log(this.form.value);
    // console.log(this.productDetailForm.value);
    if (this.form.invalid) {
      this.form.controls.product_code.disable();
      return;
    }

    // if (
    //   this.form.value.product_type_id === CALIBRATION &&
    //   this.productDetailForm.invalid
    // ) {
    //   console.log(this.productDetailForm);
    //   return;
    // }

    let data = {
      ...this.form.value,
      price: Number(this.form.value.price),
    };
    let product_id = this.product_id;
    let status = null;
    // create product info
    let productResponse = await this.ProductService.update({
      product_id,
      ..._.pick(data, [
        "product_type_id",
        "product_code",
        "product_title",
        "product_description",
      ]),
    });

    if (productResponse.success) {
      status = productResponse.success;

      // if (this.form.value.product_type_id !== CALIBRATION) {
      let productPriceUnitResponse = await this.ProductPriceUnitService.update({
        product_price_unit_id: this.product.product_price_unit_info
          .product_price_unit_id,
        product_id,
        ..._.pick(data, ["unit_id", "price"]),
      });
      if (productPriceUnitResponse.error) {
        this.status = productPriceUnitResponse.error;
        this.form.controls.product_code.disable();
        return;
      }
      // }
      // else if (this.form.value.product_type_id === CALIBRATION) {
      //   // create product detail
      //   let productCalibrationDetailResponse = await this.ProductCalibrationDetailService.update(
      //     {
      //       product_calibration_detail_id: this.product
      //         .product_calibration_detail_info.product_calibration_detail_id,
      //       product_id,
      //       ...this.productDetailForm.value,
      //       mit_cp_id: Number(this.productDetailForm.value.mit_cp_id),
      //     }
      //   );

      //   if (productCalibrationDetailResponse.success) {
      //     for (let fee of this.fees) {
      //       if (!fee.product_calibration_fee_id) {
      //         let feeResponse = await this.ProductCalibrationFeeService.create({
      //           product_calibration_detail_id:
      //             productCalibrationDetailResponse.resultData
      //               .product_calibration_detail_id,
      //           ...fee,
      //         });
      //         if (feeResponse.error) {
      //           this.status = feeResponse.error;
      //           return;
      //         }
      //       } else {
      //         let feeResponse = await this.ProductCalibrationFeeService.update({
      //           product_calibration_fee_id: fee.product_calibration_fee_id,
      //           product_calibration_detail_id:
      //             productCalibrationDetailResponse.resultData
      //               .product_calibration_detail_id,
      //           ...fee,
      //         });
      //         if (feeResponse.error) {
      //           this.status = feeResponse.error;
      //           return;
      //         }
      //       }
      //     }
      //   } else {
      //     this.status = productCalibrationDetailResponse.error;
      //     return;
      //   }
      // }
    } else {
      this.form.controls.product_code.disable();
      this.status = productResponse.error;
      return;
    }

    this.router.navigateByUrl("/product", {
      state: {
        status: status,
      },
    });
  }

  // async changeScope() {
  //   if (!this.productDetailForm.value.mit_scope_id) {
  //     this.equipments = [];
  //     this.productDetailForm.controls.mit_equipment_id.setValue("");
  //     return;
  //   }
  //   await this.MITEquipmentService.load(null, {
  //     mit_scope_id: this.productDetailForm.value.mit_scope_id,
  //   }).then((response) => {
  //     if (response.success) {
  //       this.equipments = response.resultData || [];
  //     }
  //   });
  // }

  // onAddFee(fee) {
  //   this.fees.push(fee);
  // }

  groupUnit(units) {
    let groups = {};
    for (let unit of units) {
      if (!groups[unit.unit_type_id]) {
        groups[unit.unit_type_id] = unit.unit_type_info;
      }
    }
    let groupUnits = {};

    for (let unit of units) {
      if (!groupUnits[unit.unit_type_id]) {
        groupUnits[unit.unit_type_id] = [];
      }
      groupUnits[unit.unit_type_id].push(unit);
    }

    let groupByUnits = [];
    for (let group in groups) {
      groupByUnits.push({
        unit_type_info: groups[group],
        units: groupUnits[group],
      });
    }

    return groupByUnits;
  }
}
