<loading *ngIf="isLoading"></loading>
<div *ngIf="!isLoading">
  <div
    class="d-flex"
    [class]="{
      'justify-content-end': !canEditCustomer,
      'justify-content-between': canEditCustomer
    }"
  >
    <button
      *ngIf="canEditCustomer"
      type="button"
      class="btn btn-outline-primary btn-min-width mb-1"
      trigger-sidebar
      data-target="#customer-activity-create"
    >
      <i class="fa fa-plus-circle icon-left"></i> บันทึกกิจกรรม
    </button>

    <fieldset class="form-group">
      <select
        class="form-control"
        id="activityType"
        [(ngModel)]="activityType"
        name="activityType"
        [ngModelOptions]="{ standalone: true }"
        (change)="load()"
      >
        <option value="">ประเภท : ทั้งหมด</option>
        <option
          [value]="activityType.customer_activity_type_id"
          *ngFor="let activityType of activityTypes"
          >{{ activityType.activity_type_name_th }}</option
        >
      </select>
    </fieldset>
  </div>

  <div class="row">
    <div class="col-12">
      <section id="timeline" class="timeline-center timeline-wrapper mt-3">
        <ul
          class="timeline"
          [class]="{ 'no-icon': !activity.customer_activity_type_info?.isShow }"
          *ngFor="let activity of activities"
        >
          <li class="timeline-line bg-primary"></li>
          <li class="timeline-item pt-2 pb-0" style="width: 30%;">
            <div class="timeline-card">
              <div class="card-content">
                <div class="card-body">
                  <h5>{{ activity.activity_datetime | thai_date }}</h5>
                </div>
              </div>
            </div>
          </li>
          <li class="timeline-item pb-0" style="width: 70%;">
            <div class="timeline-badge">
              <span class="border-primary bg-white">
                <!-- TODO :: change icon for eacch type -->

                <i
                  class="feather text-primary"
                  [class]="[
                    activity.customer_activity_type_info?.activity_icon_path
                  ]"
                  *ngIf="activity.customer_activity_type_info?.isShow"
                ></i>
              </span>
            </div>
            <div
              class="timeline-card card bg-blue-grey bg-lighten-5 w-50"
              trigger-sidebar
              data-target="#customer-activity-edit"
              (click)="select(activity)"
              [clickable]="activity.customer_activity_type_info?.isShow"
              *ngIf="activity.customer_activity_type_info?.isShow"
            >
              <div class="card-content">
                <div class="card-body">
                  <h5 *ngIf="activity.created_by != 'system'">
                    {{ activity.created_by_info.firstname_th }}
                    {{ activity.created_by_info.lastname_th }}
                  </h5>
                  <p class="blue-grey mb-0">
                    {{ activity?.title }}
                  </p>
                </div>
              </div>
            </div>
            <h5
              class="mt-1"
              *ngIf="!activity.customer_activity_type_info?.isShow"
            >
              {{ activity.title }}
            </h5>
          </li>
        </ul>
      </section>

      <div class="text-center" *ngIf="!activities.length">
        ไม่มีข้อมูลกิจกรรม
      </div>
    </div>
  </div>
</div>
<app-customer-activity-create
  [activity-types]="activityTypes"
  (done)="load()"
></app-customer-activity-create>
<app-customer-activity-edit
  [can-edit-customer]="canEditCustomer"
  [customer-activity]="customer_activity"
  [activity-types]="activityTypes"
  (done)="load()"
  (close-sidebar)="closeSidebar()"
></app-customer-activity-edit>
