import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobOrderStatusConfirmService {
  async create({
    job_order_info_id,
    item_type,
    item_id,
    item_status_id,
    reject_service_date,
    reject_reason,
    reject_solution
  }) {
    let result = null;
    try {
      result = await Axios().post(`/job-order-status-confirm`, {
        job_order_info_id,
        item_type,
        item_id,
        item_status_id,
        reject_service_date,
        reject_reason,
        reject_solution
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      job_order_status_confirm_id,
      job_order_info_id,
      item_type,
      item_id,
      item_status_id,
      reject_service_date,
      reject_reason,
      reject_solution
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/job-order-status-confirm/${job_order_status_confirm_id}`, {
        job_order_info_id,
        item_type,
        item_id,
        item_status_id,
        reject_service_date,
        reject_reason,
        reject_solution
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ job_order_status_confirm_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/job-order-status-confirm/${job_order_status_confirm_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-status-confirm`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ job_order_status_confirm_id }) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-status-confirm/${job_order_status_confirm_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
