import {
  Component,
  OnInit,
  NgZone,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MITScopeService } from "src/app/services";
import { ENSignValidator } from "src/app/validators";

@Component({
  selector: "app-scope-create",
  templateUrl: "./scope-create.component.html",
  styleUrls: ["./scope-create.component.css"],
})
export class ScopeCreateComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  @Output() done = new EventEmitter();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private MITScopeService: MITScopeService
  ) {
    this.setForm();
  }
  setForm(): void {
    this.form = this.formBuilder.group({
      scope_name: ["", [Validators.required, ENSignValidator()]],
      scope_name_short: ["", [Validators.required]],
    });
  }
  ngOnInit(): void {}
  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let response = await this.MITScopeService.create(this.form.value);

    this.done.emit(response.success || response.error);
    this.submitted = false;
    this.closeModalBtn.nativeElement.click();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }
}
