import Axios from "../axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class AccTaxInvoiceInfoService {
  async create({
    doc_no,
    revise_count,
    doc_date,
    doc_validdate,
    doc_duedate,
    return_date,
    doc_note,
    customer_id,
    customer_credit_term_day,
    customer_address_id,
    doc_status_id,
    tax,
    discount,
    grand_total,
    acc_payment_id,
    is_return_doc_can_create,
    acc_invoice_info_id,
    grand_total_before_vat,
    doc_no_company
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-tax-invoice-info`, {
        doc_no,
        revise_count,
        doc_date,
        doc_validdate: doc_validdate? doc_validdate: undefined,
        doc_duedate,
        return_date,
        doc_note,
        customer_id,
        customer_credit_term_day: parseInt(customer_credit_term_day),
        customer_address_id,
        doc_status_id,
        tax: parseFloat(tax),
        discount: parseFloat(discount),
        grand_total,
        acc_payment_id,
        is_return_doc_can_create: is_return_doc_can_create? is_return_doc_can_create.toString(): undefined,
        acc_invoice_info_id,
        grand_total_before_vat,
        doc_no_company
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    acc_tax_invoice_info_id,
    doc_no,
    revise_count,
    doc_date,
    doc_validdate,
    doc_duedate,
    return_date,
    doc_note,
    customer_id,
    customer_credit_term_day,
    customer_address_id,
    doc_status_id,
    tax,
    discount,
    acc_billing_note_info_id,
    grand_total,
    acc_payment_id,
    is_return_doc_can_create,
    acc_invoice_info_id,
    grand_total_before_vat,
    doc_no_company
  }) {
    let result = null;
    try {
      result = await Axios().put(`/acc-tax-invoice-info/${acc_tax_invoice_info_id}`, {
        doc_no,
        revise_count,
        doc_date,
        doc_validdate: doc_validdate? doc_validdate: undefined,
        doc_duedate,
        return_date,
        doc_note,
        customer_id,
        customer_credit_term_day: parseInt(customer_credit_term_day),
        customer_address_id,
        doc_status_id,
        tax: parseFloat(tax),
        discount: parseFloat(discount),
        acc_billing_note_info_id,
        grand_total,
        acc_payment_id,
        is_return_doc_can_create: is_return_doc_can_create? is_return_doc_can_create.toString(): undefined,
        acc_invoice_info_id,
        grand_total_before_vat,
        doc_no_company
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_tax_invoice_info_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-tax-invoice-info/${acc_tax_invoice_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-tax-invoice-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ acc_tax_invoice_info_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-tax-invoice-info/${acc_tax_invoice_info_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getCode(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-tax-invoice-code`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
