import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { UtilsService } from 'src/app/services';
import { QuotationProductItemService, QuotationService } from 'src/app/services/quotations';

@Component({
  selector: 'app-work-order-non-lab-item-create',
  templateUrl: './work-order-non-lab-item-create.component.html',
  styleUrls: ['./work-order-non-lab-item-create.component.css']
})
export class WorkOrderNonLabItemCreateComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  isShowCheckAll:boolean = false;
  template: string = "";

  quotationList = [];
  jobOrderItems: any = [];

  number_of_select = 0;

  @Output() done = new EventEmitter<any>();
  @Input('jobOrderItems') selectedJobOrderItems;
  @Input('customer_info') customer_info;
  @Input('itemStatus') itemStatus: any;

  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private QuotationService: QuotationService,
    private QuotationProductItemService: QuotationProductItemService,

    public UtilsService: UtilsService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
    });
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }

  setForm(){
    this.form = this.formBuilder.group({
      quotation_info_id: ["", Validators.required],
      job_order_item: this.formBuilder.array([])
    });

    this.jobOrderItems = this.form.get('job_order_item') as FormArray;
  }

  createJobOrderItem(data:any = {}): FormGroup {
    let quotation_info = this.quotationList.find(item=> item.id == data.quotation_info_id);
    
    return this.formBuilder.group({
      select:[false],
      quotation_info_id: [data.quotation_info_id || ""],
      quotation_item_id: [data.quotation_item_id || ""],
      item_status_id: ["CRF-WAITING_FOR_CONFIRM"],
      note: [""],
      quotation_item_info: [data],
      quotation_info: [quotation_info.data]
    });
  }

  get productFormGroup() {
    return this.form.get('job_order_item') as FormArray;
  }

  ngOnInit() {}

  async ngOnChanges(changes: SimpleChanges) {

    await this.QuotationService.load(null, {customer_id: this.customer_info.customer_id, doc_status_id: 'ACCEPTED'})
    .then(async res=>{

      await Promise.all(res.resultData.map(async (item)=>{
        if(this.template == 'PRD'){
          await this.QuotationProductItemService.load(null, {quotation_info_id: item.quotation_info_id, 'product_info.product_type_id_ne': '3', 'ne|$product_info.product_title$': 'Training,Transport'})
          .then(res=>{
            var resultData = res.resultData || [];

            if(resultData.find(mit=> !mit.job_order_product_item_id)){
              this.quotationList.push(item);
            }
    
          })
        }else{
          await this.QuotationProductItemService.load(null, {quotation_info_id: item.quotation_info_id, 'product_info.product_type_id_ne': '3', '$product_info.product_title$': 'Training'})
          .then(res=>{
            var resultData = res.resultData || [];

            if(resultData.find(mit=> !mit.job_order_product_item_id)){
              this.quotationList.push(item);
            }
    
          })
        }
      }));
      this.quotationList = this.quotationList.map(elem => (
        {
          id: elem.quotation_info_id,
          text:  `[${elem?.doc_no || ''}] (${elem?.customer_info.customer_code || ''}) ${elem?.customer_info.company_name || ''}`,
          data: elem
        } 
      ));
    })
  }

  async selectQuotation(event){
    this.number_of_select = 0;

    if(event){
      while (this.jobOrderItems.length !== 0) {
        this.jobOrderItems.removeAt(0);
      }
      if(this.template == 'PRD'){
        await this.QuotationProductItemService.load(null, {quotation_info_id: event, 'product_info.product_type_id_ne': '3', 'ne|$product_info.product_title$': 'Training,Transport'})
        .then(res=>{
          var resultData = res.resultData || [];
          
          let result = res.resultData.filter(item=>
            !item.job_order_product_item_id && 
            this.checkSelected(item)
          );
          
          this.isShowCheckAll = false;
          if(result.length > 0){
            this.isShowCheckAll = true;
          }

          resultData.map(item=>{
            this.jobOrderItems.push(this.createJobOrderItem(item));
          })
        })
      }else{
        await this.QuotationProductItemService.load(null, {quotation_info_id: event, '$product_info.product_title$': 'Training'})
        .then(res=>{
          var resultData = res.resultData || [];
      
          let result = res.resultData.filter(item=>
            !item.job_order_product_item_id && 
            this.checkSelected(item)
          );
          
          this.isShowCheckAll = false;
          if(result.length > 0){
            this.isShowCheckAll = true;
          }

          resultData.map(item=>{
            this.jobOrderItems.push(this.createJobOrderItem(item));
          })
        })
      }
    }
  }

  onCheckAll(e){
    this.jobOrderItems.controls.map(item=>{
      if(!item.value.quotation_item_info.job_order_product_item_id){
        item.get('select').setValue(e.target.checked);
      }
    })
    this.number_of_select = this.jobOrderItems.value.filter(item=>item.select === true).length;
  }

  onCheckChange(e, index: number){
    this.jobOrderItems.controls[index].patchValue({
      select: e.target.checked
    });
    this.number_of_select = this.jobOrderItems.value.filter(item=>item.select === true).length;
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let items = this.jobOrderItems.value.filter(item=> item.select == true);
    this.done.emit(items);
    this.closeModalBtn.nativeElement.click();
  }

  public checkSelected (mit){
    if(!this.selectedJobOrderItems.find(selected=> selected.quotation_item_id == mit.quotation_item_id)){
      return true;
    }else{
      return false;
    }
  }

  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.isShowCheckAll = false;
        this.number_of_select = 0;
        this.setForm();
      }
    });
  }

  public getStatus(item_status_id: string){
    let findStatus = this.itemStatus.find(item=> item.item_status_id == item_status_id);
    return findStatus.item_status_name_th || item_status_id;
  }
}
