import { Component, OnInit } from "@angular/core";

import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { ActivatedRoute, Router } from "@angular/router";
import moment from "moment";
import {
  EmployeeLevelService,
  EmployeePositionService,
  EmployeeDepartmentService,
  UserService,
} from "src/app/services";
@Component({
  selector: "app-user-show",
  templateUrl: "./user-show.component.html",
  styleUrls: ["./user-show.component.css"],
})
export class UserShowComponent implements OnInit {
  isReady = false;
  form: FormGroup;
  submitted = false;
  user_id: any;
  status: string;
  departments: [];
  levels: [];
  positions: [];
  user: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private UserService: UserService,
    private EmployeeDepartmentService: EmployeeDepartmentService,
    private EmployeePositionService: EmployeePositionService,
    private EmployeeLevelService: EmployeeLevelService
  ) {}

  async ngOnInit() {
    this.isReady = false;
    this.form = this.formBuilder.group({
      email: ["", Validators.required],
      password: ["", [Validators.minLength(6)]],
      firstname_th: ["", Validators.required],
      lastname_th: ["", Validators.required],
      firstname_en: ["", Validators.required],
      lastname_en: ["", Validators.required],

      date_of_birth: ["", Validators.required],
      mobile_no: ["", Validators.required],
      user_role_id: ["USER"],
      user_state_id: ["ACTIVE"],

      // employee
      emp_code: [""],
      emp_department_id: [""],
      emp_level_id: [""],
      emp_position_id: [""],
      iplus_signature_filename: [""],
    });
    let promises = [];
    promises.push(
      this.EmployeeDepartmentService.load().then((result) => {
        this.departments = result.resultData;
      })
    );
    promises.push(
      this.EmployeeLevelService.load().then((result) => {
        this.levels = result.resultData;
      })
    );

    await Promise.all(promises);

    this.user_id = this.route.snapshot.paramMap.get("user_id");
    let result = await this.UserService.getById({ user_id: this.user_id });
    if (result.success) {
      let user = result.resultData;
      this.user = user;

      for (let key in this.form.value) {
        if (key === "password") {
        } else if (key === "date_of_birth") {
          this.form.controls[key].setValue(
            moment(user[key]).format("DD-MM-YYYY")
          );
        } else if (
          key === "emp_department_id" ||
          key === "emp_position_id" ||
          key === "emp_level_id" ||
          key === "emp_code"
        ) {
          this.form.controls[key].setValue(user.emp_info[key]);
        } else {
          this.form.controls[key].setValue(user[key]);
        }
      }

      this.EmployeePositionService.load({
        emp_department_id: this.form.value.emp_department_id,
      }).then((result) => {
        this.positions = result.resultData;
        this.form.disable();
      });
    }

    this.isReady = true;
  }

}
