import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { JobOrderEditHistoryService } from 'src/app/services/job-orders/job-order-edit-history.service';
import { JobOrderMemoService } from 'src/app/services/job-orders/job-order-memo.service';

@Component({
  selector: 'app-work-order-memo',
  templateUrl: './work-order-memo.component.html',
  styleUrls: ['./work-order-memo.component.css']
})
export class WorkOrderMemoComponent implements OnInit {
  form: FormGroup;
  //boolean
  submitted = false;
  pageLoad = true;
  @Input() resetMemo: boolean;
  //array
  memoList: any = [];
  //string
  job_order_info_id: string;
  //any
  status: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private JobOrderEditHistoryService: JobOrderEditHistoryService,
    private JobOrderMemoService: JobOrderMemoService
  ) { }

  ngOnInit() {
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
    this.createForm();
    this.getMemoList();
  }

  getMemoList(){
    this.pageLoad = true;
    this.memoList = [];
    this.form.get('memo').setValue('');

    this.JobOrderEditHistoryService.load(null, { job_order_info_id : this.job_order_info_id  })
    .then(async (res)=>{
      let historyList  = res.resultData;
      this.JobOrderMemoService.load(null, { job_order_info_id : this.job_order_info_id  })
      .then(async (res)=>{
        let memoList  = res.resultData;
        this.memoList = [
          ...historyList,
          ...memoList
        ];
        this.memoList.sort(function(a,b){
          if(a.created_at < b.created_at) return 1;
          if(a.created_at > b.created_at) return -1;
          return 0;
        });
      })
      this.pageLoad = false;
    })
  }

  ngOnChanges(changes: SimpleChanges) {}

  createForm(){
    this.form = this.formBuilder.group({
      memo: ["", [Validators.required]],
    });
  }

  async save(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    this.submitted = false;
    let  JobOrderMemoResponse = await this.JobOrderMemoService.create({
      job_order_info_id: this.job_order_info_id,
      ...this.form.value
    });
    if (JobOrderMemoResponse.success) {
      this.getMemoList();
    }
  }
}
