export class GetCustomer {
  static readonly type = "[Customer] Get";
  constructor(public payload: any) {}
}

export class UpdateCustomer {
  static readonly type = "[Customer] Update";

  constructor(public payload: any) {}
}

export class SetCustomer {
  static readonly type = "[Customer] Set";

  constructor(public payload: any) {}
}
