import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import {
  AccPaymentService
} from 'src/app/services/invoice';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';


@Component({
  selector: 'app-received-voucher-item-tax-create',
  templateUrl: './received-voucher-item-tax-create.component.html',
  styleUrls: ['./received-voucher-item-tax-create.component.css']
})
export class ReceivedVoucherItemTaxCreateComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  isShowCheckAll:boolean = false;

  taxInvoiceList = [];
  receivedVoucherItems: any = [];
  number_of_select = 0;
  //any
  @Input('customer_info') customer_info: any;
  @Input('paymentList') paymentList: any;

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(   
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccPaymentService: AccPaymentService
    ) { 
    this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.closeModalBtn.nativeElement.click();
        }
    });
    this.setForm();
  }

  async ngOnInit() {
    await this.AccTaxInvoiceInfoService.load(null, { doc_status_id: 'WAITING_FOR_PAYMENT,PARTIAL_PAYMENT,COMPLETED', customer_id: this.customer_info.customer_id})
    .then(res=>{
      this.taxInvoiceList = res.resultData || [];

      this.taxInvoiceList= this.taxInvoiceList.map(elem => (
        {
          id: elem.acc_tax_invoice_info_id,
          text:  `[${elem?.doc_no_company || ''}] (${elem?.customer_info.customer_code || ''}) ${elem?.customer_info.company_name || ''}`,
          data: elem
        } 
      ));
    })
  }
  
  setForm(){
    this.form = this.formBuilder.group({
      acc_tax_invoice_info_id: ["", Validators.required],
      received_voucher_item: this.formBuilder.array([])
    });

    this.receivedVoucherItems = this.form.get('received_voucher_item') as FormArray;
  }

  createVoucherInvoiceItem(data:any = {}): FormGroup {
    let taxInvoice = this.taxInvoiceList.find(item=> item.data.acc_tax_invoice_info_id == this.form.value.acc_tax_invoice_info_id);
    return this.formBuilder.group({
      select:[false],
      acc_received_voucher_info_id: [""],
      acc_document_payment_id: [data.acc_payment_id],
      doc_type: ["TAX_INVOICE"],
      doc_no: [taxInvoice.data.doc_no || ""],
      data: [data]
    });
  }

  get receivedVoucherFormGroup() {
    return this.form.get('received_voucher_item') as FormArray;
  }

  async selectInvoice(event){
    this.number_of_select = 0;

    if(event){
      while (this.receivedVoucherItems.length !== 0) {
        this.receivedVoucherItems.removeAt(0);
      }
      await this.AccPaymentService.load(null, {acc_document_info_id: event})
      .then(res=>{
        res.resultData.map(item=>{
          this.receivedVoucherItems.push(this.createVoucherInvoiceItem(item));
        })
      });
      
      let result = this.receivedVoucherItems.value.filter(item=>!item.data.acc_received_voucher_info_id);
      this.isShowCheckAll = false;
      if(result.length > 0){
        this.isShowCheckAll = true;
      }
    }
  }

  onCheckAll(e){
    this.receivedVoucherItems.controls.map(item=>{
      item.get('select').setValue(e.target.checked);
    })
    this.number_of_select = this.receivedVoucherItems.value.filter(item=>item.select == true && !item.data.acc_received_voucher_info_id).length;
  }

  onCheckChange(e, index: number){
    this.receivedVoucherItems.controls[index].patchValue({
      select: e.target.checked
    });
    this.number_of_select = this.receivedVoucherItems.value.filter(item=>item.select == true && !item.data.acc_received_voucher_info_id).length;
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let items = this.receivedVoucherItems.value.filter(item=> item.select == true && !item.data.acc_received_voucher_info_id);
    this.done.emit(items);
    this.closeModalBtn.nativeElement.click();
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.number_of_select = 0;
        this.setForm();
      }
    });
  }

  getPaymentName(payment): string{
    let findPayment = this.paymentList.find(pay=> pay.payment_method_id == payment.payment_method_id)
    return findPayment.payment_method_name_th || "";
  }
}
