<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="edit-mit-item"
  tabindex="-1"
  role="dialog"
  aria-labelledby="editMitItemModal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="editMitItemModal">
          {{pageType == 'edit'? 'แก้ไขรายการ MIT': 'รายละเอียด' }}
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <form class="form form-horizontal px-2" autocomplete="off" role="form" [formGroup]="form">
        <div class="modal-body">
          <div class="form-body" *ngIf="form.get('transport_receive_item_id').value">
            <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="transport_receive_id">
                  ใบรับเครื่องมือ:
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group [control]="form.controls.transport_receive_id" [submitted]="submitted">
                    <ng-select2
                    id="transport_receive_id"
                    [data]="transportReceiveList"
                    class="w-100"
                    placeholder="ใบรับเครื่องมือ"
                    formControlName="transport_receive_id"
                    >
                    </ng-select2>
                  </form-group>
                </div>
            </div>
          </div>

          <div class="form-body" *ngIf="form.get('transport_receive_item_id').value">
            <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="transport_receive_item_id">
                  เครื่องมือ:
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group [control]="form.controls.transport_receive_item_id" [submitted]="submitted">
                    <ng-select2
                    id="transport_receive_item_id"
                    [data]="transportReceiveItemList"
                    class="w-100"
                    placeholder="เครื่องมือ"
                    formControlName="transport_receive_item_id"
                    >
                    </ng-select2>
                  </form-group>
                </div>
            </div>
          </div>

          <div class="form-body">
            <div class="form-group row mb-0">
              <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="mit_scope_id">
                Scope <span class="danger">*</span>:
              </label>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <form-group [control]="form.controls.mit_scope_id" [submitted]="submitted">
                  <ng-select2
                  id="mit_scope_id"
                  [data]="scopeList"
                  class="w-100"
                  placeholder="Scope"
                  formControlName="mit_scope_id"
                  (valueChanged)="selectScope($event)"
                  >
                  </ng-select2>
                </form-group>
              </div>
            </div>
          </div>

          <div class="form-body">
            <div class="form-group row mb-0">
              <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="mit_equipment_id">
                Equipment <span class="danger">*</span>:
              </label>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <form-group [control]="form.controls.mit_equipment_id" [submitted]="submitted">
                  <ng-select2
                  id="mit_equipment_id"
                  [data]="equipmentList"
                  class="w-100"
                  placeholder="Equipment"
                  formControlName="mit_equipment_id"
                  (valueChanged)="selectEquipment($event)"
                  >
                  </ng-select2>
                  <div style="padding-bottom: 5px;margin-top: 5px;" 
                  *ngIf="form.get('mit_equipment_id').value !== '' && form.get('mit_equipment_id').value !== null
                  && form.value.note_1">
                    Note 1: {{form.value.note_1}}
                  </div>
                  <div *ngIf="form.get('mit_equipment_id').value !== '' && form.get('mit_equipment_id').value !== null
                  && form.value.note_2">
                    Note 2: {{form.value.note_2}}
                  </div>
                </form-group>
              </div>
            </div>
          </div>

          <div class="form-body">
            <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="service_location">
                  Service Location <span class="danger">*</span>:
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group
                  [submitted]="submitted"
                  [control]="
                    form.controls.service_location"
                >
                  <div class="input-group">
                    <div class="form-check pr-2">
                      <input                               
                      type="checkbox"
                      class="form-check-input"
                      formControlName="service_lab"
                      id="service_lab"
                      (click)="selectServiceLocation('lab')">
                      <label
                      class="form-check-label"
                      for="service_lab"
                      >Lab</label
                    >
                    </div>
                    <div class="form-check">
                      <input                               
                      type="checkbox"
                      class="form-check-input"
                      formControlName="service_onsite"
                      id="service_onsite"
                      (click)="selectServiceLocation('onsite')">
                      <label
                      class="form-check-label"
                      for="service_onsite"
                      >Onsite</label
                    >
                    </div>
                  </div>
                </form-group>
                </div>
            </div>
          </div>

          <div class="form-body">
            <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="mit_method_id">
                  Method <span class="danger">*</span>:
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group [control]="form.controls.mit_method_id" [submitted]="submitted">
                    <ng-select2
                    id="mit_method_id"
                    [data]="methodList"
                    class="w-100"
                    placeholder="Method"
                    formControlName="mit_method_id"
                    (valueChanged)="selectMethod($event)"
                    >
                    </ng-select2>
                  </form-group>
                </div>
            </div>
          </div>

          <div class="form-body">
            <div class="form-group row">
              <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="text2">
                Calibration Point :
              </label>
              <div class="col-12 col-sm-12 col-md-10 col-lg-10">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" style="max-height: 600px;" *ngIf="!isLoading">
                  <table class="table table-bordered" formArrayName="quotation_mit_item_details">
                    <thead>
                      <tr>
                        <th rowspan="2" class="align-middle fixed-column fixed-first-row">#</th>
                        <th rowspan="2" class="align-middle text-left fixed-column fixed-first-row">
                          Method
                          <span class="pr-5">&nbsp;</span>
                        </th>
                        <th rowspan="2" class="align-middle text-left fixed-column fixed-first-row">
                          Service Location
                        </th>
                        <th colspan="2" class="text-center fixed-first-row">Range</th>
                        <th colspan="2" class="text-center fixed-first-row">ราคา (บาท)</th>
                        <th rowspan="2" class="align-middle fixed-first-row">
                          Min Point
                        </th>
                        <th rowspan="2" class="align-middle fixed-first-row">ISO</th>
                        <th rowspan="2" class="align-middle text-left fixed-first-row">
                          Calibration Point by customer
                          <span class="px-4">&nbsp;</span>
                        </th>
                        <th rowspan="2" class="align-middle text-left fixed-first-row">
                          Calibration Point
                        </th>
                        <th rowspan="2" class="align-middle text-left fixed-first-row">
                          MPE (±)
                          <span class="pr-4">&nbsp;</span>
                          <span class="pr-4">&nbsp;</span>
                        </th>
                        <th rowspan="2" class="align-middle fixed-first-row">
                          <span>Note </span>
                          <span class="pr-4">&nbsp;</span>
                          <span class="pr-4">&nbsp;</span>
                        </th>
                      </tr>
                      <tr>
                        <th class="text-center fixed-second-row">
                          <span class="pr-2">&nbsp;</span>
                          Min 
                          <span class="pr-2">&nbsp;</span>
                        </th>
                        <th class="text-center fixed-second-row">
                          <span class="pr-2">&nbsp;</span>
                          Max 
                          <span class="pr-2">&nbsp;</span>
                        </th>
                        <th class="text-right fixed-second-row">Start</th>
                        <th class="text-right fixed-second-row">Point</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of detailItemFormGroup.controls; let i = index;" [formGroupName]="i" [hidden]="item.value.mit_method_id !== filterMethod">
                        <td class="fixed-column">
                          <span class="text-bold-600">
                            {{i+ 1}}
                          </span>
                        </td>
                        <td class="text-left fixed-column">
                          {{item.value?.method_name || ''}}
                        </td>
                        <td class="text-left" [ngSwitch]="oldItem">
                          <div *ngSwitchCase="true">
                            <select class="form-control" formControlName="service_location" *ngIf="pageType == 'edit'">
                              <option value="LAB" [hidden]="item.value?.service_location=='ONSITE'">Lab</option>
                              <option value="ONSITE" [hidden]="item.value?.service_location=='LAB'">Onsite</option>
                            </select>

                            <select class="form-control" formControlName="service_location" *ngIf="pageType == 'show'" readonly>
                              <option value="LAB" [hidden]="item.value?.service_location=='ONSITE'">Lab</option>
                              <option value="ONSITE" [hidden]="item.value?.service_location=='LAB'">Onsite</option>
                            </select>
                          </div>
                          <div *ngSwitchCase="false">
                            <select class="form-control" formControlName="service_location" *ngIf="pageType == 'edit'">
                              <option value="LAB" [hidden]="service=='ONSITE'">Lab</option>
                              <option value="ONSITE" [hidden]="service=='LAB'">Onsite</option>
                            </select>

                            <select class="form-control" formControlName="service_location" *ngIf="pageType == 'show'" readonly>
                              <option value="LAB" [hidden]="service=='ONSITE'">Lab</option>
                              <option value="ONSITE" [hidden]="service=='LAB'">Onsite</option>
                            </select>
                          </div>
                        </td>
                        <!-- <td>
                          <select class="form-control" formControlName="mit_cp_id" (change)="selectCP(mit_cp_id.value, i);"
                          #mit_cp_id name="mit_cp_id">
                            <option
                              *ngFor="let cp of item.value.cpInfo"
                              [value]="cp.mit_cp_id"
                              >{{ cp.mit_cp_info.cp_name }}
                            </option>
                          </select>
                        </td> -->
                        <td class="text-center">
                          {{item.value?.min_range | number : '1.1-5' }}
                          <span class="custom-inline" [innerHTML]="item.value.cus_unit_name"></span>
                        </td>
                        <td class="text-center">
                          {{item.value?.max_range | number : '1.1-5' }}
                          <span class="custom-inline" [innerHTML]="item.value.cus_unit_name"></span>
                        </td>
                        <td class="text-right">
                          {{item.value?.price_start || 0 | number : '1.2-2'}}
                        </td>
                        <td class="text-right">
                          {{item.value?.price_point || 0 | number : '1.2-2'}}
                        </td>
                        <td class="text-center">
                          {{item.value?.min_point || 0}}
                        </td>
                        <td class="text-center">
                          {{item.value?.isISO || ''}}
                        </td>
                        <td>
                          <div class="row pb-1 pl-0" *ngIf="item.value.min_point != 0">
                            <div class="col-12 pl-0">
                              <nav class="nav nav-pills nav-justified">
                                <a class="nav-link border pr-1" 
                                [class.disabled] = "pageType === 'show'"
                                [ngClass]="{'active': item.value.detail_type === 'point'}"
                                (click)="setDetailType(i, 'point')"
                                >ระบุ point</a>
                                <a class="nav-link border" 
                                [class.disabled] = "pageType === 'show'"
                                [ngClass]="{'active': item.value.detail_type === 'range'}"
                                (click)="setDetailType(i, 'range')"
                                >เหมา range</a>
                              </nav>
                            </div>
                          </div>
                          <div class="row pt-1 border" *ngIf="item.value.detail_type === 'point'">
                            <div class="col-8">
                              <div id="tags" formArrayName="quotation_mit_item_detail_points">
                                <span class="tag d-inline" *ngFor="let point of pointItemFormGroup(item); let j = index;" [formGroupName]="j">
                                  {{point.value.cus_point || 0  | number : '1.1-5'}}
                                 <i class="fa fa-times icon-right" (click)="removePoint(i, j)"  *ngIf="pageType == 'edit'"></i>
                                </span>
                                <input type="text" placeholder="โปรดระบุ..." class="form-control text-right"
                                (keydown.enter)="addPoint(item.value, i, $event)" 
                                *ngIf="pageType == 'edit'"/>

                                <input type="text" placeholder="โปรดระบุ..." class="form-control text-right"
                                (keydown.enter)="addPoint(item.value, i, $event)" 
                                *ngIf="pageType == 'show'" readonly/>
                              </div>
                            </div>
                            <div class="col-4">
                              <select class="form-control" formControlName="cus_unit_id" (change)="selectUnit(cus_unit_id.value, i);"
                              #cus_unit_id name="mit_cp_id" 
                              *ngIf="pageType == 'edit'"
                              > 
                                <option [value]="unit.unit_id" *ngFor="let unit of getUnits(item.value.unit_id)" [innerHTML]="unit?.unit_short_name_en">
                                </option>
                              </select>

                              <select class="form-control" formControlName="cus_unit_id" (change)="selectUnit(cus_unit_id.value, i);"
                              #cus_unit_id name="mit_cp_id" 
                              *ngIf="pageType == 'show'"
                              readonly
                              > 
                                <option [value]="unit.unit_id" *ngFor="let unit of getUnits(item.value.unit_id)" [innerHTML]="unit?.unit_short_name_en">
                                </option>
                              </select>
                            </div>
                            <div class="col-12">
                              <div class="text-danger pb-0" *ngIf="item.errors?.invalidPoint">
                                <span *ngIf="item.errors?.invalidRangePoint">
                                  -
                                </span>
                                <span>
                                  โปรดระบุอย่างน้อย {{item.value?.min_point || 0}} point
                                </span>
                              </div>
                              <div class="text-danger pb-0" *ngIf="item.errors?.invalidRangePoint">
                                <span *ngIf="item.errors?.invalidPoint">
                                  -
                                </span>
                                <span>
                                  โปรดระบุ point ตาม range {{_utils.convertStingToNumber(item.value?.min_range) | number : '1.1-5' }} to {{_utils.convertStingToNumber(item.value?.max_range) | number : '1.1-5'  }}
                                  <span class="custom-inline" [innerHTML]="item.value.cus_unit_name"></span>
                                </span>
                              </div>
                              <div class="pt-0">
                                <small class="text-muted">range {{_utils.convertStingToNumber(item.value?.min_range) | number : '1.1-5' }} to {{_utils.convertStingToNumber(item.value?.max_range) | number : '1.1-5'  }}
                                  <span class="custom-inline" [innerHTML]="item.value.cus_unit_name"></span>
                                </small>
                              </div>
                            </div>
                          </div>
                          <div class="row py-1 border" *ngIf="item.value.detail_type === 'range'">
                            <div class="col-4 pr-0" formGroupName="quotation_mit_item_detail_range">
                              <input type="text" 
                              *ngIf="pageType == 'edit'"
                              formControlName="cus_min" 
                              class="form-control text-right" 
                              placeholder="โปรดระบุ..." 
                              (keyup)="onKeyupRange(i)">

                              <input type="text" 
                              *ngIf="pageType == 'show'"
                              readonly
                              formControlName="cus_min" 
                              class="form-control text-right" 
                              placeholder="โปรดระบุ..." 
                              (keyup)="onKeyupRange(i)">
                            </div>
                            <div class="col-4 pl-0" formGroupName="quotation_mit_item_detail_range">
                              <input type="text" 
                              *ngIf="pageType == 'edit'"
                              formControlName="cus_max" 
                              class="form-control text-right" 
                              placeholder="โปรดระบุ..." 
                              (keyup)="onKeyupRange(i)">

                              <input type="text" 
                              *ngIf="pageType == 'show'"
                              readonly
                              formControlName="cus_max" 
                              class="form-control text-right" 
                              placeholder="โปรดระบุ..." 
                              (keyup)="onKeyupRange(i)">
                            </div>
                            <div class="col-4">
                              <select class="form-control" formControlName="cus_unit_id" (change)="selectUnit(cus_unit_id.value, i);"
                              #cus_unit_id name="mit_cp_id" 
                              *ngIf="pageType == 'edit'"
                              >
                                <option [value]="unit.unit_id" *ngFor="let unit of getUnits(item.value.unit_id)" [innerHTML]="unit?.unit_short_name_en">
                                </option>
                              </select>
                              <select class="form-control" formControlName="cus_unit_id" (change)="selectUnit(cus_unit_id.value, i);"
                              #cus_unit_id name="mit_cp_id" 
                              *ngIf="pageType == 'show'"
                              readonly
                              >
                                <option [value]="unit.unit_id" *ngFor="let unit of getUnits(item.value.unit_id)" [innerHTML]="unit?.unit_short_name_en">
                                </option>
                              </select>
                            </div>
                            <div class="col-12">
                              <div class="text-danger pb-0" *ngIf="item.errors?.invalidRangePoint">
                                <span class="pb-0">
                                  โปรดระบุ point ตาม range {{_utils.convertStingToNumber(item.value?.min_range) | number : '1.1-5' }} to {{_utils.convertStingToNumber(item.value?.max_range) | number : '1.1-5'  }}
                                  <span class="custom-inline" [innerHTML]="item.value.cus_unit_name"></span>
                                </span>
                              </div>
                              <div class="pt-0">
                                <small class="text-muted">range {{_utils.convertStingToNumber(item.value?.min_range) | number : '1.1-5' }} to {{_utils.convertStingToNumber(item.value?.max_range) | number : '1.1-5'  }}
                                  <span class="custom-inline" [innerHTML]="item.value.cus_unit_name"></span>
                                </small>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td class="text-left">
                          <div *ngIf="item.value.detail_type === 'point'">
                            <div formArrayName="quotation_mit_item_detail_points">
                              <span style="padding-right: 2px;" 
                                *ngFor="let point of pointItemFormGroup(item); let j = index;" 
                                [formGroupName]="j">
                                <span 
                                class="badge badge-primary" style="margin-bottom: 5px;">{{point.value.mit_point || 0 | number : '1.1-5'}}
                                </span>
                              </span>
                            </div>
                          </div>
                          <div *ngIf="item.value.detail_type === 'range'">
                            <span style="padding-right: 2px;">
                              <span class="badge btn-blue" style="margin-bottom: 5px;">{{_utils.convertStingToNumber(item.value.quotation_mit_item_detail_range.mit_min) || 0 | number : '1.1-5'}}</span>
                            </span> -&nbsp;
                            <span style="padding-right: 2px;">
                              <span class="badge btn-blue">{{_utils.convertStingToNumber(item.value.quotation_mit_item_detail_range.mit_max) || 0 | number : '1.1-5'}}</span>
                            </span>
                          </div>
                        </td>
                        <td class="text-right">
                          <input 
                          *ngIf="pageType == 'edit'"
                          type="text" 
                          placeholder="MPE (±)"
                          class="form-control" 
                          formControlName="mpe" 
                          [id]="'mpe_'+i"
                          name="mpe">
                          <input 
                          *ngIf="pageType == 'show'"
                          readonly
                          type="text" 
                          placeholder="MPE (±)"
                          class="form-control" 
                          formControlName="mpe" 
                          [id]="'mpe_'+i"
                          name="mpe">
                        </td>
                        <td>
                          <textarea 
                            *ngIf="pageType == 'edit'"
                            class="form-control" rows="1" 
                            placeholder="Note"
                            formControlName="note" [id]="'note_'+i">
                          </textarea>
                          <textarea 
                          *ngIf="pageType == 'show'"
                          readonly
                          class="form-control" rows="1" 
                          placeholder="Note"
                          formControlName="note" [id]="'note_'+i">
                        </textarea>
                        </td>
                      </tr>
                    </tbody>
                    <tbody *ngIf="detailList.length == 0">
                      <tr>
                        <td colspan="14" class="text-center">
                          ไม่มีข้อมูล
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="text-danger" *ngIf="form.errors?.invalidRequirePoint && submitted">
                  <span class="pl-1">
                    โปรดระบุ point อย่างน้อย 1 รายการ
                  </span>
                </div>

                <div class="text-danger" *ngIf="form.errors?.invalidTypePoint && submitted">
                  <span class="pl-1">
                    โปรดระบุ Point ในรูปแบบ Point หรือ เหมา Range อย่างใดอย่างหนึ่งเท่านั้น
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-body">
            <div class="form-group row mb-0">
              <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="cp_list_init">
                CP List :
              </label>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <form-group [control]="form.controls.cp_list_init" [submitted]="submitted">
                    <input type="text" [value]="form.getRawValue().cp_list_init" placeholder="CP List" class="form-control" readonly>
                </form-group>
              </div>
            </div>
          </div>

          <div class="form-body">
            <div class="form-group row">
              <h4 class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" style="font-weight: 500;"
              (click)="is_show_attributes = !is_show_attributes">
              <span class="pointer">
                Attributes 	&nbsp; 
                <i class="fa" [ngClass]="{'fa-angle-right': !is_show_attributes, 'fa-angle-down': is_show_attributes }"></i>
              </span>
            </h4>
            </div>
          </div>
          <div class="form-body pt-0" formArrayName="quotation_mit_item_attributes" [hidden]="!is_show_attributes">
            <div class="form-group row" *ngFor="let item of attributeItemFormGroup.controls; let i = index;" [formGroupName]="i">
              <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="description">
                {{item.value.attribute_name}} : 
              </label>
              <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                <input 
                  type="text"
                  class="form-control text-right"
                  placeholder="{{item.value.attribute_name}}"
                  formControlName="attribute_value" 
                  [id]="'attribute_value_'+i" 
                  [name]="'attribute_value_'+i"
                  (keyup)="onKeyup()"
                  oninput="this.value = 
                  this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                />
              </div>
            </div>
          </div>
          
          <div class="form-body pt-0">
            <div class="form-group row">
              <h4 class="col-12 col-sm-12 col-md-2 col-lg-2 label-control"
              (click)="is_show_deatils = !is_show_deatils">
              <span class="pointer">
                รายละเอียดเครื่องมือ 	&nbsp; 
                <i class="fa" [ngClass]="{'fa-angle-right': !is_show_deatils, 'fa-angle-down': is_show_deatils }"></i>
              </span>
            </h4>
            </div>
          </div>
          <div [hidden]="!is_show_deatils">
            <div class="form-body">
              <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="description">
                  Description : 
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group
                  [control]="form.controls.description"
                  [submitted]="submitted">
                    <textarea 
                      class="form-control" rows="2" 
                      placeholder="Description"
                      formControlName="description" id="description" name="description">
                    </textarea>
                  </form-group>
                </div>
              </div>
            </div>
            <div class="form-body">
              <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="marker">
                  Maker : 
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group
                  [control]="form.controls.marker"
                  [submitted]="submitted">
                  <input
                      type="text"
                      placeholder="Maker"
                      id="marker"
                      class="form-control"
                      formControlName="marker"
                  />
                  </form-group>
                </div>
              </div>
            </div>
            <div class="form-body">
              <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="model">
                  Model : 
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group
                  [control]="form.controls.model"
                  [submitted]="submitted">
                  <input
                      type="text"
                      placeholder="Model"
                      id="model"
                      class="form-control"
                      formControlName="model"
                  />
                  </form-group>
                </div>
              </div>
            </div>
            <div class="form-body">
              <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="serial_no">
                  Serial No. : 
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group
                  [control]="form.controls.serial_no"
                  [submitted]="submitted">
                  <input
                      type="text"
                      placeholder="Serial No."
                      id="serial_no"
                      class="form-control"
                      formControlName="serial_no"
                  />
                  </form-group>
                </div>
              </div>
            </div>
            <div class="form-body">
              <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="tag_no">
                  Tag No. : 
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group
                  [control]="form.controls.tag_no"
                  [submitted]="submitted">
                  <input
                      type="text"
                      placeholder="Tag No."
                      id="tag_no"
                      class="form-control"
                      formControlName="tag_no"
                  />
                  </form-group>
                </div>
              </div>
            </div>
            <div class="form-body">
              <div class="form-group row mb-0">
                <label class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="due_month">
                  Due (Month) : 
                </label>
                <div class="col-12 col-sm-12 col-md-6 col-lg-4">
                  <form-group
                  [control]="form.controls.due_month"
                  [submitted]="submitted">
                  <input
                    type="text"
                    placeholder="Due (Month)"
                    id="due_month"
                    formControlName="due_month"
                    class="form-control text-right"
                    oninput="this.value = 
                    this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                  />
                  </form-group>
                </div>
              </div>
            </div>
          </div>

          <h4 class="my-1 text-right">ราคารวม {{form.get('original_price').value | number : '1.2-2'}} บาท</h4>
        </div>
      </form>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()" *ngIf="pageType == 'edit'">
          <i class="fa fa-plus icon-left"></i>เพิ่ม
        </button>
      </div>
    </div>
  </div>
</div>
