<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">รายการทีมขาย</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#">จัดการทีมขาย</a>
              </li>
              <li class="breadcrumb-item active">รายการทีมขาย</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            type="button"
            class="btn btn-outline-primary"
            data-toggle="modal"
            data-target="#create-sales-team"
          >
            <i class="feather icon-plus-circle icon-left"></i> เพิ่มทีมขาย
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">รายการทีมขาย</h4>
              <div class="heading-elements">
                <search-box (search)="search($event)"></search-box>
              </div>
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center">ลำดับ</th>
                        <th>ชื่อทีมขาย</th>
                        <th>Co-Sale</th>
                        <th>หัวหน้าทีมขาย</th>
                        <th class="text-center" width="20%">
                          จำนวนสมาชิก (คน)
                        </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let salesTeam of salesTeams; let i = index">
                        <th class="text-center">{{ salesTeam.i }}</th>
                        <td>{{ salesTeam.sales_team_name }}</td>
                        <td>{{ salesTeam.team_cosale_info?.firstname_th }} {{ salesTeam.team_cosale_info?.lastname_th }}</td>
                        <td>{{ salesTeam.fullname || '' }}</td>
                        <td class="text-center">
                          {{ salesTeam.sales_team_members_count }}
                        </td>
                        <td>
                          <button
                            class="btn mr-1 mb-1 bg-warning bg-darken-1 text-white btn-sm btn-edit"
                            data-target="#edit-sales-team"
                            data-toggle="modal"
                            (click)="select(salesTeam.sales_team_id)"
                          >
                            <i class="fa fa-pencil icon-left"></i> แก้ไข
                          </button>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 bg-info bg-darken-1 text-white btn-sm"
                            data-toggle="modal"
                            data-target="#edit-sales-team-member"
                            (click)="select(salesTeam.sales_team_id)"
                          >
                            <i class="fa fa-pencil icon-left"></i> แก้ไขสมาชิก
                          </button>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 bg-danger bg-darken-1 text-white btn-sm"
                            data-target="#modalConfirmDelMsg"
                            data-toggle="modal"
                            (click)="select(salesTeam.sales_team_id)"
                            [disabled]="salesTeam?.sales_team_members_count || salesTeam?.team_leader_info?.is_sales_assignment"
                          >
                            <i class="fa fa-trash icon-left"></i> ลบ
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="!salesTeams.length">
                        <td colspan="6" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
      <app-sales-team-create
        [employees]="employees"
        [coSales]="coSales"
        (done)="doReset($event)"
      ></app-sales-team-create>
      <app-sales-team-edit
        [employees]="employees"
        [coSales]="coSales"
        [sales-team-id]="sales_team_id"
        (done)="doReset($event)"
        *ngIf="sales_team_id"
      ></app-sales-team-edit>

      <app-sales-team-member-create
        [employees]="employees"
        [sales-team-id]="sales_team_id"
        *ngIf="sales_team_id"
        (done)="doReset($event)"
      ></app-sales-team-member-create>
    </div>
  </div>
</div>

<app-confirm-delete (save)="delete($event)"></app-confirm-delete>
