
<div
modal
class="modal fade text-left"
id="cert-verify"
tabindex="-1"
(close)="close()"
role="dialog"
aria-labelledby="CertVerifyModal"
aria-hidden="true">
<div class="modal-dialog" role="document">
  <div class="modal-content">
    <div class="modal-header bg-primary text-white">
      <h4 class="modal-title" id="CertVerifyModal">
        ตรวจสอบความถูกต้อง
      </h4>
      <button
        type="button"
        class="close"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form class="form form-horizontal" [formGroup]="form">
          <div class="form-body">
            <div class="mt-1">
                <form-group
                [control]="form.controls.cert_verify_note"
                [submitted]="submitted"
                >
                  <label for="template">
                    หมายเหตุ <span class="danger">*</span>
                  </label>
                    <textarea
                        class="form-control"
                        rows="3"
                        placeholder="หมายเหตุ"
                        formControlName="cert_verify_note">
                    </textarea>
                </form-group>
  
              </div>
          </div>
      </form>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-warning"
        data-dismiss="modal"
        aria-label="Close"
        (click)="close()"
        #closeConfirmBtn
      >
        <i class="fa fa-times icon-left"></i> ยกเลิก
      </button>

      <button type="button" class="btn btn-primary" (click)="save()">
        <i class="fa fa-check icon-left"></i>บันทึก
      </button>
    </div>
  </div>
</div>
</div>
