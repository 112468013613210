import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class JobOrderCalibrationItemService {
  async create({
    job_order_info_id,
    quotation_info_id,
    quotation_mit_item_id,
    item_status_id,
    unlock_invoice_at,
    lock_invoice_at,
    person_in_charge_id,
    cert_verify_note,
    cert_verify_by,
    cert_verify_at,
    note,
    std,
    order
  }) {
    let result = null;
    try {
      result = await Axios().post(`/job-order-calibration-item`, {
        job_order_info_id,
        quotation_info_id,
        quotation_mit_item_id: [quotation_mit_item_id],
        item_status_id,
        unlock_invoice_at,
        lock_invoice_at,
        person_in_charge_id,
        cert_verify_note,
        cert_verify_by,
        cert_verify_at,
        note: note? note.toString(): "",
        std: std? std.toString(): "",
        order
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      job_order_calibration_item_id,
      job_order_info_id,
      quotation_info_id,
      quotation_mit_item_id,
      item_status_id,
      unlock_invoice_at,
      lock_invoice_at,
      person_in_charge_id,
      cert_verify_note,
      cert_verify_by,
      cert_verify_at,
      deliverable_by,
      deliverable_at,
      note,
      std,
      order
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/job-order-calibration-item/${job_order_calibration_item_id}`, {
        job_order_info_id,
        quotation_info_id,
        quotation_mit_item_id,
        item_status_id,
        unlock_invoice_at,
        lock_invoice_at,
        person_in_charge_id,
        cert_verify_note,
        cert_verify_by,
        cert_verify_at,
        deliverable_by,
        deliverable_at,
        note: note? note.toString(): "",
        std: std? std.toString(): "",
        order
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ job_order_calibration_item_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/job-order-calibration-item/${job_order_calibration_item_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-calibration-item`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ job_order_calibration_item_id }) {
    let result = null;
    try {
      result = await Axios().get(`/job-order-calibration-item/${job_order_calibration_item_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
