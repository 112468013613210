import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccTaxInvoiceItemService {
  async create({
    acc_tax_invoice_info_id,
    quotation_info_id,
    quotation_item_id,
    item_type,
    product_id,
    product_title,
    product_description,
    original_price,
    price,
    quantity,
    unit_id,
    discount,
    order_by
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-tax-invoice-items`, {
        acc_tax_invoice_info_id,
        quotation_info_id,
        quotation_item_id,
        item_type,
        product_id,
        product_title,
        product_description,
        original_price,
        price,
        quantity,
        unit_id,
        discount,
        order_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        acc_tax_invoice_items_id,
        acc_tax_invoice_info_id,
        quotation_info_id,
        quotation_item_id,
        item_type,
        product_id,
        product_title,
        product_description,
        original_price,
        price,
        quantity,
        unit_id,
        discount,
        order_by
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/acc-tax-invoice-items/${acc_tax_invoice_items_id}`, {
        acc_tax_invoice_info_id,
        quotation_info_id,
        quotation_item_id,
        item_type,
        product_id,
        product_title,
        product_description,
        original_price,
        price,
        quantity,
        unit_id,
        discount,
        order_by
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_tax_invoice_items_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-tax-invoice-items/${acc_tax_invoice_items_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-tax-invoice-items`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ acc_tax_invoice_items_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-tax-invoice-items/${acc_tax_invoice_items_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
