import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { QuotationNoteService } from '../../../../../services/quotations';

@Component({
  selector: 'app-quotation-note-show',
  templateUrl: './quotation-note-show.component.html',
  styleUrls: ['./quotation-note-show.component.css']
})
export class QuotationNoteShowComponent implements OnInit {
  pageLoad =  false;

  noteList = [];
  quotation_info_id: string;
  @Input("note_type") note_type: string;

  constructor(
    private QuotationNoteService: QuotationNoteService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.pageLoad = false;
    
    this.quotation_info_id = this.route.snapshot.paramMap.get("quotation_info_id");
    if(this.note_type){
      this.QuotationNoteService.load(null, { 
        quotation_info_id: this.quotation_info_id,
        note_type: this.note_type,
        order_by: 'order:asc'
      }).then(async (res)=>{
        
        this.noteList = res.resultData || [];
        this.noteList.sort((a, b) => (parseInt(a.order) > parseInt(b.order)? 1 : -1));
        
        this.pageLoad = true;
      })
    }
  }

}
