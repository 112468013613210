import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class SalesTeamService {
  async create({ 
    sales_team_name, 
    team_leader_id, 
    team_cosale_id 
  }) {
    let result = null;
    try {
      result = await Axios().post(`/sales-team-info`, {
        sales_team_name,
        team_leader_id,
        team_cosale_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({     
    sales_team_name, 
    team_leader_id, 
    team_cosale_id , 
    sales_team_id 
  }) {
    let result = null;
    try {
      result = await Axios().put(`/sales-team-info/${sales_team_id}`, {
        sales_team_name,
        team_leader_id,
        team_cosale_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ sales_team_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/sales-team-info/${sales_team_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/sales-team-info`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ sales_team_id }) {
    let result = null;
    try {
      result = await Axios().get(`/sales-team-info/${sales_team_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
