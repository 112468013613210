import { Directive, ElementRef, Output, EventEmitter } from "@angular/core";
declare const $;
@Directive({
  selector: "[sidebar]",
})
export class SidebarDirective {
  @Output() close = new EventEmitter();

  constructor(el: ElementRef) {
    let id = el.nativeElement.getAttribute("id");
    let selector = `#${id} .custom-sidebar`;
    $(document).on("classChange", selector, () => {
      this.close.emit(!$(selector).hasClass("show"));
    });
  }
}
