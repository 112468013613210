import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { UtilsService } from 'src/app/services';
import {
   QuotationService,
   QuotationMitItemService,
   QuotationProductItemService,
   CalculateFunctionService,
   QuotationUtilsService,
   QuotationAcceptedService
} from 'src/app/services/quotations';

@Component({
  selector: 'app-invoice-item-create',
  templateUrl: './invoice-item-create.component.html',
  styleUrls: ['./invoice-item-create.component.css']
})
export class InvoiceItemCreateComponent implements OnInit {
  form: FormGroup;

  submitted:boolean = false;
  isShowCheckAll:boolean = false;
  chkQuotationAccepted:boolean = true;

  quotationList = [];
  invoiceItems: any = [];

  number_of_select = 0;
  //any
  @Input('customer_info') customer_info: any;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(   
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private QuotationService: QuotationService,
    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,
    private QuotationAcceptedService: QuotationAcceptedService,

    public UtilsService: UtilsService,
    public calculate: CalculateFunctionService,
    public utils : QuotationUtilsService,
    ) { 
    this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.closeModalBtn.nativeElement.click();
        }
    });
    this.setForm();
  }
  async ngOnInit() {

    await this.QuotationService.load(null, {customer_id: this.customer_info.customer_id, doc_status_id: 'ACCEPTED'})
    .then(res=>{
      this.quotationList = res.resultData || [];
      this.quotationList= this.quotationList.map(elem => (
        {
          id: elem.quotation_info_id,
          text:  `[${elem?.doc_no || ''}] (${elem?.customer_info.customer_code || ''}) ${elem?.customer_info.company_name || ''}`,
          data: elem
        } 
      ));
    })
  }
  
  setForm(){
    this.form = this.formBuilder.group({
      quotation_info_id: ["", Validators.required],
      invoice_item: this.formBuilder.array([])
    });

    this.invoiceItems = this.form.get('invoice_item') as FormArray;
  }

  createInvoiceProductItem(data:any = {}): FormGroup {
    return this.formBuilder.group({
      select:[false],
      quotation_info_id: [data.quotation_info_id || ""],
      quotation_item_id: [data.quotation_item_id || ""],
      acc_invoice_info_id: [""],
      item_type: ["product"],
      product_id: [data.product_id],
      product_title: [data.product_title],
      product_description: [data.product_description],
      original_price: [data.original_price],
      price: [data.price],
      quantity: [data.quantity],
      unit_id: [data.unit_id],
      discount: [data.discount],
      order_by: [data.order_by],
      data: [data]
    });
  }

  createInvoiceCaibrationServiceItem(data:any = {}): FormGroup {
    return this.formBuilder.group({
      select:[false],
      quotation_info_id: [data.quotation_info_id || ""],
      quotation_item_id: [data.quotation_mit_item_id],
      acc_invoice_info_id: [""],
      item_type: ["calibration_service"],
      product_id: [""],
      product_title: [data.scope_name + ": "+ data.equipment_name],
      product_description: [data.description],
      original_price: [0],
      price: [data.price],
      quantity: [1],
      unit_id: [""],
      discount: [data.discount],
      order_by: [data.order_by],
      data: [data]
    });
  }

  get invoiceFormGroup() {
    return this.form.get('invoice_item') as FormArray;
  }

  async selectQuotation(event){
    this.number_of_select = 0;
    this.chkQuotationAccepted = true;
    if(event){
      let findQuotation = this.quotationList.find(item=> item.id == event);

      let acceptedResponse = await this.QuotationAcceptedService.load(null, {quotation_info_id: findQuotation.data.quotation_info_id});

      let findQuotationAccepted = acceptedResponse.resultData.find(accept=> accept.quotation_accepted_file_info.length > 0);
      if(findQuotationAccepted){
        while (this.invoiceItems.length !== 0) {
          this.invoiceItems.removeAt(0);
        }
        let chekProduct = [];
        let chekCalibration = [];
        await this.QuotationProductItemService.load(null,  {quotation_info_id: event, ready_to_billing: 'true'})
        .then(res=>{
          let resultData = res.resultData.filter(item=> item.item_type == 'product');
          chekProduct = res.resultData.filter(item=> 
            !item.acc_invoice_info_id &&
            (item.job_order_product_item_info[0]?.item_status_id =='CRF-COMPLETED' || item.job_order_product_item_info[0]?.item_status_id =='PREPARE_FOR_SHIP') && 
            item.job_order_product_item_info.length !=0 && 
            (!item.job_order_product_item_info[0]?.lock_invoice_at || item.value?.data?.job_order_product_item_info[0]?.unlock_invoice_at || item.product_title.startsWith("Transport"))
            );


          resultData.map(item=>{
            this.invoiceItems.push(this.createInvoiceProductItem(item));
          })
        });
        await this.QuotationMitItemService.load(null, {quotation_info_id: event, ready_to_billing: 'true' })
        .then(res=>{

          chekCalibration = res.resultData.filter(item=> ( 
             !item.acc_invoice_info_id &&
           ( item.job_order_calibration_item_info[0]?.item_status_id =='CRF-COMPLETED' ||  item.job_order_calibration_item_info[0]?.item_status_id =='PREPARE_FOR_SHIP') && 
            item.job_order_calibration_item_info.length !=0 && 
            (!item.job_order_calibration_item_info[0]?.lock_invoice_at || item.value?.data?.job_order_calibration_item_info[0]?.unlock_invoice_at))
            );

          res.resultData.map(item=>{
            this.invoiceItems.push(this.createInvoiceCaibrationServiceItem(item));
          })
        });

        this.isShowCheckAll = false;
        if(chekProduct.length > 0 || chekCalibration.length > 0){
          this.isShowCheckAll = true;
        }
      }else{
        this.chkQuotationAccepted = false;
      }
    }
  }

  onCheckAll(e){
    this.invoiceItems.controls.map(item=>{
      item.get('select').setValue(e.target.checked);
    })
    let tmpItems = this.invoiceItems.value.filter(item=> item.select == true);
    let product = tmpItems.filter(item=> item.item_type == 'product');
    let calibaration = tmpItems.filter(item=> item.item_type == 'calibration_service');

    product = product.filter(item=> (
      !item.data?.acc_invoice_info_id &&
      (item?.data?.job_order_product_item_info[0]?.item_status_id =='CRF-COMPLETED' || item?.data?.job_order_product_item_info[0]?.item_status_id =='PREPARE_FOR_SHIP') && 
      item?.data?.job_order_product_item_info.length !=0 &&
      (!item?.data?.job_order_product_item_info[0]?.lock_invoice_at || item?.data?.job_order_product_item_info[0]?.unlock_invoice_at || item?.data?.product_title.startsWith("Transport"))
      )
      );
    calibaration = calibaration.filter(item=> 
      !item.data?.acc_invoice_info_id &&
      (item?.data?.job_order_calibration_item_info[0]?.item_status_id =='CRF-COMPLETED' || item?.data?.job_order_calibration_item_info[0]?.item_status_id =='PREPARE_FOR_SHIP') && 
      item?.data?.job_order_calibration_item_info.length !=0 && 
      (!item?.data?.job_order_calibration_item_info[0]?.lock_invoice_at || item?.data?.job_order_calibration_item_info[0]?.unlock_invoice_at));

    let items = [
      ...product,
      ...calibaration
    ]
    this.number_of_select =items.filter(item=>item.select === true).length;
  }

  onCheckChange(e, index: number){
    this.invoiceItems.controls[index].patchValue({
      select: e.target.checked
    });
    this.number_of_select = this.invoiceItems.value.filter(item=>item.select === true).length;
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let tmpItems = this.invoiceItems.value.filter(item=> item.select == true);
    let product = tmpItems.filter(item=> item.item_type == 'product');
    let calibaration = tmpItems.filter(item=> item.item_type == 'calibration_service');

    product = product.filter(item=> (
      !item.data?.acc_invoice_info_id &&
      (item?.data?.job_order_product_item_info[0]?.item_status_id =='CRF-COMPLETED' || item?.data?.job_order_product_item_info[0]?.item_status_id =='PREPARE_FOR_SHIP') && 
      item?.data?.job_order_product_item_info.length !=0 &&
      (!item?.data?.job_order_product_item_info[0]?.lock_invoice_at || item?.data?.job_order_product_item_info[0]?.unlock_invoice_at || item?.data?.product_title.startsWith("Transport"))
      ));
    calibaration = calibaration.filter(item=> 
      !item.data?.acc_invoice_info_id &&
      (item?.data?.job_order_calibration_item_info[0]?.item_status_id =='CRF-COMPLETED' || item?.data?.job_order_calibration_item_info[0]?.item_status_id =='PREPARE_FOR_SHIP') && 
      item?.data?.job_order_calibration_item_info.length !=0 && 
      (!item?.data?.job_order_calibration_item_info[0]?.lock_invoice_at || item?.data?.job_order_calibration_item_info[0]?.unlock_invoice_at));

    let items = [
      ...product,
      ...calibaration
    ]
    this.done.emit(items);
    this.closeModalBtn.nativeElement.click();
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.isShowCheckAll = false;
        this.number_of_select = 0;
        this.setForm();
      }
    });
  }
}
