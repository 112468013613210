import { Pipe, PipeTransform } from "@angular/core";
import moment from "moment";
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
 */
@Pipe({ name: "dash" })
export class DashhPipe implements PipeTransform {
  transform(value: string): string {
    return value ? value : "-";
  }
}
