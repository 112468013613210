import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EmployeeService } from 'src/app/services';
import { UserInfoState } from 'src/app/store/user/user.state';
import { JobOrderWorkerEngineerService } from '../../../../services/job-orders/index';

@Component({
  selector: 'app-worker-engineer',
  templateUrl: './worker-engineer.component.html',
  styleUrls: ['./worker-engineer.component.css']
})
export class WorkerEngineerComponent implements OnInit {
  form: FormGroup;
  workerList: FormArray;

  employeeList = [];
  deleteWorkerList = [];

  @Input() type: string = "user_id";
  @Input() submitted: boolean;
  @Input() jobOrderInfo: any;
  @Output() done = new EventEmitter<any>();
  @Input() canSaveWorkEngineer: boolean;

  pageLoad: boolean = false;

  job_order_info_id: string;
  pageType: string;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private EmployeeService: EmployeeService,
    private JobOrderWorkerEngineerService: JobOrderWorkerEngineerService,
    private route: ActivatedRoute
  ) {
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  async ngOnInit() {
    this.createForm();
    this.EmployeeService.load(null, { emp_department_id: 'CALIB_DEP' }).then(res=>{
      this.employeeList = res.resultData;
      this.employeeList = this.employeeList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info?.firstname_th} ${elem.user_info?.lastname_th}`
        }
      })
    });
    await this.getWorker();
    this.form.controls.workers.valueChanges.subscribe(
      (value) => {
        this.sentData();
      }
    );
    this.sentData();
    this.userInfo$.subscribe(async (userInfo) => {
      if (!userInfo) return;
      if(userInfo?.emp_info?.emp_department_id.startsWith("SALES_DEP")){
        this.pageType = 'show';
      }
    });
  }

  async getWorker(){
    await this.JobOrderWorkerEngineerService.load(null, 
      { 
        job_order_info_id: this.job_order_info_id
      }).then(async (res)=>{
        
        await Promise.all(res.resultData.map(async (v: any) =>{
          this.workerList.push(this.createWorkerItem(v));
        }));
        this.pageLoad = true;
      })
  }

  public createForm(){
    this.form = this.formBuilder.group({
      workers: this.formBuilder.array([]),
    });
    this.workerList = 
    this.form.get('workers') as FormArray;
  }

  get workerItemFormGroup() {
    return this.form.get('workers') as FormArray;
  }

  public createWorkerItem(data:any = {}): FormGroup {
    return this.formBuilder.group({
      job_order_worker_engineer_id: this.formBuilder.control(data.job_order_worker_engineer_id || null),
      job_order_info_id: this.formBuilder.control(this.job_order_info_id),
      user_id: this.formBuilder.control(data.user_id || null, this.type=='user_id'?[Validators.required]: null),
      name: this.formBuilder.control(data.name || null, this.type=='text'?[Validators.required]: null), 
      email: this.formBuilder.control(data.email || null),
    });
  }

  public addWorker(){
    this.workerList.push(this.createWorkerItem());
    this.sentData();
  }

  public removeWorker(index: number, item: any){
    this.workerList.removeAt(index);
    this.deleteWorkerList.push(item.value);
    this.sentData();
  }

  public sentData(){
    this.done.emit({
      form: this.form,
      deleteWorkerList: this.deleteWorkerList
    });
  }

  public getEmplyeeName(id: string): string{
    let user = this.employeeList.find(emp=> emp.id == id);
    if(user) return user.text || "";
  }
}
