<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1" *ngIf="isCopy">คัดลอกใบเสนอราคา</h3>
          <h3 class="content-header-title mb-1" *ngIf="!isCopy">แก้ไขใบเสนอราคา</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/quotation']">จัดการใบเสนอราคา</a>
                </li>
                <li class="breadcrumb-item active" *ngIf="isCopy">คัดลอกใบเสนอราคา</li>
                <li class="breadcrumb-item active" *ngIf="!isCopy">แก้ไขใบเสนอราคา</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right" *ngIf="itemLoad">
            <a href="#" [routerLink]="['/quotation']" class="btn btn-warning">
              <i class="fa fa-times icon-left"></i> ไม่บันทึก
            </a>

            <button class="btn btn-primary ml-1" (click)="saveAsDraft()" [disabled]="disableSaveBtn"  *ngIf="quotationInfo?.doc_status_id != 'REVISE'">
              <i class="fa fa-save icon-left"></i> บันทึกแบบร่าง
            </button>

            <button class="btn btn-primary ml-1" (click)="save()" *ngIf="quotationInfo?.doc_status_id != 'REVISE' && !(userInfo$ | async)?.is_co_sales" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึก และส่งอนุมัติ
            </button>

            <button class="btn btn-primary ml-1" (click)="saveAsRevise()" [disabled]="disableSaveBtn"  *ngIf="quotationInfo?.doc_status_id == 'REVISE'">
              <i class="fa fa-save icon-left"></i> บันทึกแบบร่าง
            </button>

            <button class="btn btn-primary ml-1" (click)="saveRevise()" *ngIf="quotationInfo?.doc_status_id == 'REVISE'" [disabled]="disableSaveBtn">
              <i class="fa fa-save icon-left"></i> บันทึก และส่งอนุมัติ
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="!pageLoad"></loading>
        
        <div class="card" *ngIf="pageLoad">
          <div class="card-header">
              <h4 class="card-title d-inline mr-50">ใบเสนอราคา
              </h4>
              <span>
                พนักงานขาย: {{quotationInfo.sales_assignment_info?.user_info?.firstname_th}} {{quotationInfo.sales_assignment_info?.user_info?.lastname_th || '-'}}
              </span> 
              &nbsp;
              <span [ngClass]="{'badge-info': quotationInfo?.doc_status_id === 'DRAFT',
              'badge-warning':['WAITTING_FOR_ACCEPT', 'WAITING_FOR_APPROVE'].includes(quotationInfo?.doc_status_id),
              'badge-danger':quotationInfo?.doc_status_id === 'VOIDED',
              'badge-success': quotationInfo?.doc_status_id === 'ACCEPTED'
              }"
              [hidden]="isCopy"
              class="badge">{{
                quotationInfo?.doc_status_info?.document_status_name_th
              }}</span>
              <div class="float-md-right">
                <button *ngIf="(quotationInfo.doc_status_id =='WAITTING_FOR_ACCEPT' ||
                quotationInfo.doc_status_id=='ACCEPTED')
                  && ((userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales || (userInfo$ | async)?.is_sales_supervisor || (userInfo$ | async)?.is_co_sales_supervisor
                  ||  quotationInfo.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1"
                  target="_blank"
                  (click)="sendEmail(quotationInfo)"
                  >
                      <i class="fa fa-envelope icon-left"></i> ส่งอีเมล
                </button>
                <button *ngIf="quotationInfo.doc_status_id != 'VOIDED' 
                  && quotationInfo.doc_status_id != 'REVISE' 
                  && ((userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales || (userInfo$ | async)?.is_sales_supervisor || (userInfo$ | async)?.is_co_sales_supervisor
                  ||  quotationInfo.sales_assignment_info?.user_info?.user_id == (userInfo$ | async)?.user_id)"
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1"
                  target="_blank"
                  (click)="openPdf(quotationInfo)"
                  >
                    <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                </button>
              </div>
          </div>
      
          <div class="card-content collapse show">
              <form autocomplete="off" class="form" role="form" action="" method="post" [formGroup]="form">
                  <div class="card-body">
                      <div class="form-body">
                              <div class="row">
                                <div class="col-xl-3 col-lg-6 col-md-12">
                                  <form-group
                                      [control]="form.controls.quotation_sub_header_id"
                                      [submitted]="submitted"
                                    >
                                      <label for="quotation_sub_header_id">
                                      ประเภทใบเสนอราคา <span class="danger" *ngIf="form.controls['quotation_sub_header_id'].enabled">*</span>
                                      </label>
                                      <ng-select2
                                        [options]="
                                        {
                                          language: UtilsService.select2OptionsLanguage()
                                        }"
                                        [data]="subHeaderList"
                                        class="w-100"
                                        id="quotation_sub_header_id"
                                        placeholder="ประเภทใบเสนอราคา"
                                        formControlName="quotation_sub_header_id"
                                      >
                                      </ng-select2>
                                  </form-group>
                                </div>
                              </div>
                              <h4 class="badge btn-blue badge-customer-lvl" *ngIf="customer_level_id">
                                {{ customer_level_id | dash }}
                              </h4>
                              <div class="row">
                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                    <form-group
                                        [control]="form.controls.customer_id"
                                        [submitted]="submitted"
                                      >
                                        <label for="customer_id">
                                          ชื่อลูกค้า 
                                          <span class="danger" *ngIf="form.controls['customer_id'].enabled">*</span>
                                        </label>
              
                                        <div class="d-flex flex-row align-items-end">
                                          <ng-select2
                                            [options]="select2Options"
                                            class="w-100"
                                            id="customer_id"
                                            placeholder="ชื่อลูกค้า"
                                            formControlName="customer_id"
                                            [data]="customerList"
                                            (valueChanged)="selectCustomer($event)"
                                          >
                                          </ng-select2>
                                        </div>
                                    </form-group>
                                  </div>
      
                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                      <form-group
                                      [control]="form.controls.doc_date"
                                      [submitted]="submitted">
                                      <label for="doc_date">
                                          วันที่ออก <span class="danger" *ngIf="form.controls['doc_date'].enabled">*</span>
                                      </label>
                                      <div class="input-group mt-0">
                                          <input
                                          date-picker
                                          *ngIf="form.getRawValue().doc_date"
                                          type="text"
                                          id="doc_date"
                                          [date]="form.getRawValue().doc_date"
                                          class="form-control"
                                          formControlName="doc_date"
                                          />
                                          <div
                                          class="input-group-append"
                                          for="doc_date"
                                          >
                                          <span class="input-group-text"
                                              ><i class="feather icon-calendar"></i
                                          ></span>
                                          </div>
                                      </div>
                                      </form-group>
                                  </div>

                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                    <form-group
                                    [control]="form.controls.doc_validdate"
                                    [submitted]="submitted">
                                    <label for="doc_validdate">
                                        ใช้ได้ถึง <span class="danger" *ngIf="form.controls['doc_validdate'].enabled">*</span>
                                    </label>
                                    <div class="input-group mt-0">
                                        <input
                                        date-picker
                                        *ngIf="form.getRawValue().doc_validdate"
                                        type="text"
                                        id="doc_validdate"
                                        [max-date]="undefined"
                                        [date]="form.getRawValue().doc_validdate"
                                        class="form-control"
                                        formControlName="doc_validdate"
                                        />
                                        <div
                                        class="input-group-append"
                                        for="doc_validdate"
                                        >
                                        <span class="input-group-text"
                                            ><i class="feather icon-calendar"></i
                                        ></span>
                                        </div>
                                    </div>
                                    <div  class="text-danger pl-2" *ngIf="submitted">
                                      <span *ngIf="!(form.get('doc_date').errors && form.get('doc_validdate').errors) && form.hasError('docValiddateLessThanDocDate')">
                                        โปรดระบุวันที่ใช้ได้ถึงมากกว่าวันที่ออก
                                      </span>
                                    </div>
                                    </form-group>
                                </div>
      
                                <div class="col-xl-3 col-lg-6 col-md-12" *ngIf="!isCopy">
                                      <form-group
                                      [control]="form.controls.doc_no"
                                      [submitted]="submitted">
                                      <label for="doc_no">
                                          เลขที่เอกสาร
                                      </label>
                                      <input
                                          type="text"
                                          id="doc_no"
                                          class="form-control"
                                          placeholder="เลขที่เอกสาร"
                                          formControlName="doc_no"
                                          readonly
                                      />
                                      </form-group>
                                </div>
                              </div>
      
                              <div class="row">
                                  <div class="col-lg-3 col-md-12">
                                    <form-group
                                        [control]="form.controls.customer_address_id"
                                        [submitted]="submitted"
                                      >
                                        <label for="customer_address_id">
                                          ออกเอกสารในนาม <span class="danger" *ngIf="form.controls['customer_address_id'].enabled">*</span>
                                        </label>
                                        <ng-select2
                                          [options]="{
                                            templateResult: NgSelect2Customer?.templateQuotationResult,
                                            language: UtilsService.select2OptionsLanguage()
                                          }"
                                          [data]="customerAddressList"
                                          class="w-100"
                                          id="customer_address_id"
                                          placeholder="ออกเอกสารในนาม"
                                          formControlName="customer_address_id"
                                          (valueChanged)="selectAddressName($event)"
                                        >
                                        </ng-select2>
                                    </form-group>
                                  </div>
                                  <div class="col-lg-6 col-md-12">
                                    <form-group
                                          [control]="form.controls.customer_address"
                                          [submitted]="submitted"
                                        >
                                          <label for="customer_address">
                                            ที่อยู่ 
                                          </label>
                                          <input
                                          type="text"
                                          id="customer_address"
                                          class="form-control"
                                          placeholder="ที่อยู่"
                                          formControlName="customer_address"
                                          readonly
                                          />
                                      </form-group>
                                  </div>
                                  <div class="col-lg-3 col-md-12">
                                    <form-group
                                    [control]="form.controls.customer_contact_ids"
                                        [submitted]="submitted"
                                      >
                                        <label for="customer_contact_ids">
                                          ผู้ติดต่อ / เรียน <span class="danger" *ngIf="form.controls['customer_contact_ids'].enabled">*</span>
                                        </label>
                                        <ng-select2
                                          [data]="customerContactList"
                                          [options]="{
                                            templateResult: NgSelect2Customer?.templateContactResult,
                                            multiple: true,
                                            language: UtilsService.select2OptionsLanguage()
                                          }"
                                          class="w-100"
                                          id="customer_contact_ids"
                                          placeholder="ผู้ติดต่อ / เรียน"
                                          formControlName="customer_contact_ids"
                                        >
                                        </ng-select2>
                                    </form-group>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-lg-3 col-md-12">
                                  <form-group
                                    [control]="form.controls.discount_percentage"
                                        [submitted]="submitted"
                                      >
                                        <label for="discount_percentage">
                                          ส่วนลดตั้งต้น (%)
                                        </label>
                                        <input
                                          type="text"
                                          id="discount_percentage"
                                          class="form-control"
                                          placeholder="ส่วนลดตั้งต้น"
                                          formControlName="discount_percentage"
                                          readonly
                                        />
                                    </form-group>
                                </div>
                            </div>
                      </div>
      
                      <div class="mt-1">
                          <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item">
                              <a
                                class="nav-link active"
                                id="product-tab"
                                data-toggle="tab"
                                href="#product"
                                aria-controls="product"
                                role="tab"
                                aria-selected="true"
                                >รายการสินค้า/บริการ</a
                              >
                            </li>
                            <li class="nav-item">
                              <a
                                class="nav-link"
                                id="address-tab"
                                data-toggle="tab"
                                href="#address"
                                aria-controls="address"
                                role="tab"
                                aria-selected="false"
                                >ข้อมูลบริการสอบเทียบ ({{mitItems?.quotationMitItems.length}})</a
                              >
                            </li>
                          </ul>
                          <div class="tab-content pt-1">
                            <div
                              class="tab-pane active in"
                              id="product"
                              aria-labelledby="product-tab"
                              role="tabpanel">
                              <div class="card">
                                <div class="card-content"> 
                                  <div class="card-body">
                                    <div class="table-responsive">
                                      <table class="table table-striped table-bordered mb-0" formArrayName="quotation_product_items" cdkDropList (cdkDropListDropped)="drop($event)">
                                          <thead>
                                              <tr class="text-center">
                                                <th class="px-1" style="width: 2%;"></th>
                                                <th class="px-1">ลำดับ</th>
                                                <th class="px-1">รหัสสินค้า</th>
                                                <th class="text-left">ชื่อสินค้า</th>
                                                <th class="text-left">คำอธิบาย</th>
                                                <th class="text-right px-1" style="width: 8%;">จำนวน <span class="danger">*</span></th>
                                                <th class="px-1">Unit</th>
                                                <th class="text-right">ราคา/หน่วย  <span class="danger">*</span></th>
                                                <th class="text-right">ส่วนลด (%)</th>
                                                <th class="text-right">รวมเงิน</th>
                                                <th class="px-1" style="width: 5%;"></th>
                                              </tr>
                                          </thead>
                                          <thead *ngFor="let item of productItemFormGroup.controls; let i = index;" [formGroupName]="i">
                                            <tr *ngIf="item.getRawValue().item_type === 'calibration_service'" cdkDrag>
                                              <td class="px-1 py-0 pt-2" cdkDragHandle>
                                                <a style="color: #ccc;"><i class="fa fa-ellipsis-v"></i></a>
                                              </td>
                                              <div *cdkDragPreview>
                                                <div class="card px-5 py-2">
                                                  <div>
                                                    <b>ชื่อสินค้า:</b> {{item.getRawValue().product_title}}
                                                  </div>
                                                  <div>
                                                    <b>ราคา:</b> {{utils.convertStingToNumber(item.getRawValue().price) | number : '1.2-2'}}
                                                  </div>
                                                </div>
                                              </div>
                                              <td class="pt-2 text-center text-bold-600">{{item.getRawValue().index}}</td>
                                              <td colspan="7" class="py-2">{{item.getRawValue().product_title}}</td>
                                              <td colspan="1" class="py-2 text-right">
                                                {{utils.convertStingToNumber(item.getRawValue().price) | number : '1.2-2'}}
                                              </td>
                                              <td></td>
                                            </tr>
                                            <tr *ngIf="item.getRawValue().item_type === 'product'" cdkDrag>
                                                <td class="px-1 py-0 pt-2" cdkDragHandle>
                                                  <a style="color: #ccc;"><i class="fa fa-ellipsis-v"></i></a>
                                                </td>
                                                <div *cdkDragPreview>
                                                  <div class="card px-5 py-2">
                                                    <div>
                                                      <b>รหัสสินค้า:</b> {{item.getRawValue().product_code}}
                                                    </div>
                                                    <div>
                                                      <b>ชื่อสินค้า:</b> {{item.getRawValue().product_title}}
                                                    </div>
                                                    <div>
                                                      <b>ราคา:</b> {{item.getRawValue().price}}
                                                    </div>
                                                  </div>
                                                </div>
                                                <td class="pt-2 text-center text-bold-600">{{item.getRawValue().index}}</td>
                                                <td class="pt-2 text-center">
                                                  {{item.getRawValue().product_code}}
                                                </td>
                                                <td class="px-1">
                                                  <input type="text" 
                                                  class="form-control" 
                                                  [id]="'product_title_'+i"
                                                  placeholder="ชื่อสินค้า"
                                                  formControlName="product_title">

                                                    <small *ngIf="item.getRawValue()?.related_job_order_info?.doc_no">
                                                      Job Order: {{item.getRawValue()?.related_job_order_info?.doc_no}}
                                                    </small>
                                                </td>
                                                <td class="px-1">
                                                  <textarea
                                                  id="'product_description'+i"
                                                  placeholder="คำอธิบาย"
                                                  class="form-control"
                                                  rows="1"
                                                  formControlName="product_description"
                                                  >
                                                  </textarea>
                                                </td>
                                                <td class="text-right px-1">
                                                  <input type="number" 
                                                  placeholder="0"
                                                  step="1"
                                                  min="0" 
                                                  class="form-control text-right" 
                                                  [id]="'quantity_'+i"
                                                  (change)="onKeyup()"
                                                  (keyup)="onKeyup()"
                                                  (click)="$event.target.select()"
                                                  oninput="validity.valid||(value='');"
                                                  formControlName="quantity">
                                                </td>
                                                <td class="pt-2 text-center">
                                                  <div [innerHTML]="item.getRawValue().unit_short_name_en">

                                                  </div>
                                                </td>
                                                <td class="px-1">
                                                  <div>
                                                    <input type="text" 
                                                    placeholder="0.00"
                                                    class="form-control text-right" 
                                                    [id]="'price_'+i"
                                                    (keyup)="onKeyup()"
                                                    (click)="$event.target.select()"
                                                    formControlName="price">
                                                  </div>
                                                  <div class="text-danger text-right" 
                                                  *ngIf="utils.convertStingToNumber(item.getRawValue().original_price) != utils.convertStingToNumber(item.getRawValue().price)">
                                                    ราคาตั้งต้น: {{item.getRawValue().original_price | number : '1.2-2'}}
                                                  </div>
                                                  <div class="text-danger text-right"  *ngIf="item.getRawValue().lock_invoice_at"> 
                                                    ต้นทุนการให้บริการ {{item.getRawValue().cost_of_sales | number : '1.2-2'}} บาท
                                                  </div>
                                                </td>
                                                <td class="text-right px-1">
                                                  <input type="text" 
                                                  placeholder="0.00"
                                                  class="form-control text-right" 
                                                  [id]="'discount_'+i"
                                                  (keyup)="onKeyup()"
                                                  (click)="$event.target.select()"
                                                  oninput="this.value = 
                                                  this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                                  formControlName="discount">
                                                </td>
                                                <td class="text-right pt-2">
                                                  {{calculate.sumProduct(item.getRawValue()) | number : '1.2-2'}}
                                                </td>
                                                <td class="pt-2 px-1">
                                                  <button
                                                      *ngIf="item.controls['discount'].enabled"
                                                      type="button"
                                                      class="btn btn-danger btn-sm"
                                                      data-target="#modalConfirmDelMsg"
                                                      data-toggle="modal"
                                                      (click)="select(item)">
                                                      ลบ
                                                  </button>
                                                </td>
                                            </tr>
                                            <tr *ngIf="item.getRawValue().item_type  === 'description'" cdkDrag>
                                              <td class="px-1 py-0 pt-2" cdkDragHandle>
                                                <a style="color: #ccc;"><i class="fa fa-ellipsis-v"></i></a>
                                              </td>
                                              <div *cdkDragPreview>
                                                <div class="card px-5 py-2">
                                                  <div>
                                                    <b>คำอธิบาย:</b> {{item.getRawValue().product_description?item.getRawValue().product_description: '-'}}
                                                  </div>
                                                </div>
                                              </div>
                                              <td class="pt-2 text-center"></td>
                                              <td colspan="7" class="px-1">
                                                <textarea
                                                [id]="'product_description_'+i"
                                                placeholder="คำอธิบาย"
                                                class="form-control"
                                                rows="2"
                                                formControlName="product_description"
                                                >
                                                </textarea>
                                              </td>
                                              <td colspan="1">

                                              </td>
                                              <td class="pt-2 px-1">
                                                <button
                                                    type="button"
                                                    class="btn btn-danger btn-sm"
                                                    data-target="#modalConfirmDelMsg"
                                                    data-toggle="modal"
                                                    (click)="select(item)">
                                                    ลบ
                                                </button>
                                              </td>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr *ngIf="productItemList.length === 0">
                                              <td colspan="11" class="text-center">ไม่มีข้อมูล</td>
                                            </tr>
                                          </tbody>
                                      </table>
                                  </div>
                                  <div class="pt-0 mt-0 text-danger pl-2" 
                                  *ngIf="productItemList.length === 0 
                                  && form.get('doc_status_id').value === 'WAITING_FOR_APPROVE'
                                  && submitted">
                                    โปรดระบุ
                                  </div>
                                  <div class="pt-2">
                                    <label type="button" class="btn btn-primary btn-min-width mr-1 mb-1"
                                    data-toggle="modal"
                                    data-target="#add-item"
                                    >
                                    <i class="fa fa-plus icon-left"></i> เพิ่มรายการ
                                    </label>
        
                                    <label type="button"
                                        *ngIf="quotationInfo?.doc_status_id != 'REVISE'"
                                        class="btn btn-primary btn-min-width mr-1 mb-1"
                                        (click)="addDescription()"
                                    >
                                        <i class="fa fa-plus icon-left"></i> เพิ่มคำอธิบาย
                                    </label>

                                  </div>
                                  <div class="py-2">
                                      <div class="row">
                                          <div class="col-md-8 col-sm-8 mt-75">
                  
                                              <div class="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
                                                <div class="btn-group mr-1 pt-1" role="group" aria-label="First group">
                                                    <label for="file"><b>แนบไฟล์</b></label>
                                                </div>
                                                <button 
                                                  *ngIf="quotationInfo?.doc_status_id != 'REVISE'"
                                                  class="btn btn-outline-primary" 
                                                  type="button"
                                                  (click)="uploadFile()">
                                                    <i class="feather icon-upload icon-left"></i>
                                                    Upload
                                                </button>
                                                <input type="file" style="display:none;" multiple id="importFile"
                                                (change)="importFile($event.target.files)">
                                              </div>
                                              <small>* จำกัดขนาดไฟล์ {{UtilsService.config.limitFile}} mb</small> 
                                              <div class="mt-1">
                                                <span *ngFor="let file of files; let i = index;" class="badge badge-secondary nowrap mr-1 mb-1">
                                                  <span style="cursor: pointer;" (click)="downloadFile(file)">
                                                    {{file.quotation_file_id? file.file_name: file.name}}
                                                  </span>
                                                  <span aria-hidden="true" style="cursor: pointer;" 
                                                  data-target="#modalConfirmDelFile"
                                                  data-toggle="modal"
                                                  (click)="removeFile(file, i)">&times;</span>
                                                </span>
                                              </div>
                                              <span class="pl-1 text-danger w-100" *ngIf="submitted && checkFileInvalid">
                                                ขนาดไฟล์เกินกว่าที่กำหนด
                                              </span>
                                          </div>
      
                                          <div class="col-md-4 col-sm-4 mt-50">
                                              <ul class="list-group cost-list">
                                                  <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                                      <span class="cost-title mr-2">รวมเป็นเงิน </span>
                                                      <span class="cost-value">{{count | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                                  </li>

                                                  <li class="list-group-item border-0 p-50">
                                                      <div class="row">
                                                          <div class="col-6">
                                                             <div class="d-inline"><span class="cost-title mr-1">ภาษีมูลค่าเพิ่ม</span></div>
                                                              <select class="" id="tax" formControlName="tax" (change)="onKeyup()">
                                                                <option value="0">n/a</option>
                                                                <option value="7">7%</option>
                                                                <option value="10">10%</option>
                                                              </select>
                                                          </div>
                              
                                                          <div class="col-6 text-right">
                                                              <span class="cost-value">{{calculate.sumProductWithTax(count, form.getRawValue()) | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                                          </div>
                                                      </div>
                                              
                                                  </li>
                                                  <li class="dropdown-divider"></li>
                                                  <li class="list-group-item each-cost border-0 p-50 d-flex justify-content-between">
                                                      <span class="cost-title mr-2">รวมทั้งสิ้น </span>
                                                      <span class="cost-value">{{form.getRawValue().grand_total | number : '1.2-2'}} <span class="ml-2">บาท</span></span>
                                                  </li>
                                                  <li class="dropdown-divider"></li>
                                                  <li class="dropdown-divider mt-0"></li>
                                              </ul>
                                          </div>
                                      </div>
                                  </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="tab-pane"
                              id="address"
                              aria-labelledby="address-tab"
                              role="tabpanel">
                              <app-quotation-prepare
                              (done)="onSubmitMitItem($event);" 
                              [submitted]="submitted"
                              [discount_percentage]="form.get('discount_percentage').value"
                              [docStatusId]="form.get('doc_status_id').value"
                              [customer_id]="form.get('customer_id').value"
                              ></app-quotation-prepare>
                            </div>
                          </div>
                        </div>
                  </div>
              </form>
          </div>
      </div>
      </div>

      <app-quotation-accept-list [quotationInfo]="quotationInfo" *ngIf="quotationInfo?.doc_status_id == 'REVISE' && pageLoad"></app-quotation-accept-list>

      <app-quotation-note-edit 
      [note_type]="'standard_in'"
      [submitted]="submitted"
      *ngIf="pageLoad" 
      (done)="addNoteIn($event)">
      </app-quotation-note-edit>

      <app-quotation-note-edit 
      [note_type]="'standard_out'"
      [submitted]="submitted"
      *ngIf="pageLoad" 
      (done)="addNoteOut($event)">
      </app-quotation-note-edit>

      <app-quotation-memo *ngIf="isCopy==false && pageLoad"></app-quotation-memo>
    </div>
</div>

<app-quotation-price-create 
*ngIf="pageLoad"
(done)="submitProduct($event)" 
[customer_id]="form.getRawValue().customer_id"
[productList]="productList">
</app-quotation-price-create>

<app-confirm-delete (save)="deleteProductItem($event)"></app-confirm-delete>

<app-confirm-delete [id]="'modalConfirmDelFile'" (save)="deleteFile($event)"></app-confirm-delete>