<loading *ngIf="pageLoad"></loading>
<div class="card mb-0" [hidden]="pageLoad">
    <div class="card-content collapse show">
        <div class="card-body p-0">

            <div class="table-responsive" style="max-height: 600px;">
                <table class="table table-striped table-bordered" cdkDropList (cdkDropListDropped)="drop($event)">
                    <thead> 
                        <tr>
                            <th class="px-1" style="width: 2%;" *ngIf="pageType !='show'"></th>
                            <th class="text-center">ลำดับ</th>
                            <th>สินค้าบริการ</th>
                            <th>คำอธิบาย</th>
                            <th class="text-right">ราคา</th>
                            <th class="text-right">ส่วนลด</th>
                            <th class="text-right">จำนวน</th>
                            <th class="text-right">รวม</th>
                            <th *ngIf="pageType !='show'">
                                action
                            </th>
                        </tr>
                    </thead>
                    <thead *ngFor="let invoice of invoiceItems; let i = index" >
                        <tr *ngIf="invoice.item_type=='product' || invoice.item_type=='calibration_service'" cdkDrag>
                            <td class="px-1 py-0 pt-1" cdkDragHandle [hidden]="pageType =='show'">
                                <a style="color: #ccc;"><i class="fa fa-ellipsis-v"></i></a>
                            </td>
                            <div *cdkDragPreview>
                                <div class="card px-5 py-2">
                                  <div>
                                    <b>สินค้าบริการ:</b> {{invoice?.product_title}}
                                  </div>
                                  <div>
                                    <b>คำอธิบาย:</b> {{invoice?.product_description}}
                                  </div>
                                  <div>
                                    <b>ราคา:</b> {{utils.convertStingToNumber(invoice.price) | number : '1.2-2'}}
                                  </div>
                                </div>
                            </div>
                            <td class="text-center">
                                {{invoice.index}}
                            </td>
                            <td>
                                {{invoice.product_title}}
                            </td>
                            <td>
                                <div *ngIf="invoice.item_type=='product'">
                                    {{invoice.product_description}}
                                  </div>
                                  <div *ngIf="invoice.item_type=='calibration_service'">
                                    <small>
                                      Description: {{invoice.data.description | dash}}<br/>
                                      Serial no: {{invoice.data.serial_no | dash}}<br/>
                                      Point: 
                                      <span *ngFor="let detail of invoice.data.quotation_mit_item_detail_info; let dLastIndex = last;">
                                        <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0">
                                            <span *ngFor="let point of detail.quotation_mit_item_detail_points_info; let pLastIndex = last;">
                                              {{point?.cus_point}} <span *ngIf="!pLastIndex">,</span>
                                            </span>
                                        </span>
                                        <span *ngIf="detail.quotation_mit_item_detail_ranges_info.length > 0">
                                          <span *ngFor="let range of detail.quotation_mit_item_detail_ranges_info; let rLastIndex = last;">
                                            {{range?.cus_min}} - {{range?.cus_max}}
                                          </span>
                                        </span>
                                        <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0">
                                            <span class="inline-block" [innerHTML]="detail?.unit_info?.unit_short_name_th"></span>
                                        </span>
                                        <span *ngIf="!dLastIndex && (detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0)">, </span>
                                      </span>
                                    </small>
                                </div>
                            </td>
                            <td class="text-right">
                                <div *ngIf="creditNoteInfo.credit_note_type == 'FULL_AMOUNT'">
                                    {{invoice.price | number : '1.2-2'}}
                                </div>
                                <div *ngIf="creditNoteInfo.credit_note_type == 'DISCOUNT' || creditNoteInfo.credit_note_type == 'PARTIALLY'">
                                    <input type="text" 
                                    placeholder="0.00"
                                    class="form-control text-right" 
                                    [id]="'price_'+i"
                                    (keyup)="onKeyup()"
                                    [disabled]="pageType =='show'"
                                    (click)="$event.target.select()"
                                    oninput="this.value = 
                                    this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    [(ngModel)]="invoice.price"
                                    [ngModelOptions]="{standalone: true}">
                                    <span *ngIf="utils.convertStingToNumber(invoice.price) > invoice?.previous_price" class="text-danger">โปรดระบุราคาน้อยกว่า {{invoice?.previous_price | number : '1.2-2'}}</span>
                                </div>
                            </td>
                            <td class="text-right">

                                <div *ngIf="creditNoteInfo.credit_note_type == 'FULL_AMOUNT' || creditNoteInfo.credit_note_type == 'DISCOUNT'">
                                    {{invoice.discount | number : '1.2-2'}}
                                </div>
                                <div *ngIf="creditNoteInfo.credit_note_type == 'PARTIALLY'">
                                    <input type="text" 
                                    placeholder="0.00"
                                    class="form-control text-right" 
                                    [id]="'discount_'+i"
                                    (keyup)="onKeyup()"
                                    (click)="$event.target.select()"
                                    oninput="this.value = 
                                    this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    [(ngModel)]="invoice.discount"
                                    [ngModelOptions]="{standalone: true}"
                                    >
                                </div>
                            </td>
                            <td class="text-right">
                                <div *ngIf="creditNoteInfo.credit_note_type == 'FULL_AMOUNT' || creditNoteInfo.credit_note_type == 'DISCOUNT'">
                                    {{invoice.quantity}}
                                </div>
                                <div *ngIf="creditNoteInfo.credit_note_type == 'PARTIALLY'">
                                    <input type="number"
                                    placeholder="0"
                                    step="1"
                                    min="0"
                                    class="form-control text-right"
                                    [disabled]="pageType =='show'"
                                    [id]="'quantity_'+i"
                                    (change)="onKeyup()"
                                    (keyup)="onKeyup()"
                                    (click)="$event.target.select()"
                                    [max]="invoice.previous_quantity"
                                    oninput="validity.valid||(value='');"
                                    [(ngModel)]="invoice.quantity"
                                    [ngModelOptions]="{standalone: true}">
                                </div>
                            </td>
                            <td class="text-right">
                                <div *ngIf="reset">
                                    <div *ngIf="invoice.item_type=='calibration_service'">
                                        {{calculate.sumMitCredit(invoice) | number : '1.2-2'}}
                                    </div>
                                    <div *ngIf="invoice.item_type=='product'">
                                        {{calculate.sumProduct(invoice) | number : '1.2-2'}}
                                    </div>
                                </div>
                            </td>
                            <td *ngIf="pageType !='show'">
                                <button
                                *ngIf="credit_note_type != 'FULL_AMOUNT'"
                                type="button"
                                class="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#modalConfirmDelMsgCreditNoteItem"
                                (click)="select(invoice)">
                                ลบ
                                </button>
                            </td>
                        </tr>
                        <tr *ngIf="invoice.item_type=='description'" cdkDrag>
                            <td class="px-1 py-0 pt-2" cdkDragHandle [hidden]="pageType =='show'">
                                <a style="color: #ccc;"><i class="fa fa-ellipsis-v"></i></a>
                            </td>
                            <div *cdkDragPreview>
                                <div class="card px-5 py-2">
                                  <div>
                                    <b>คำอธิบาย:</b> {{invoice.product_description?invoice.product_description: '-'}}
                                  </div>
                                </div>
                            </div>
                            <td class="pt-2 text-center"></td>
                            <td colspan="5" class="px-1">
                                <div *ngIf="pageType!='show'">
                                    <textarea
                                        [id]="'product_description_'+i"
                                        placeholder="คำอธิบาย"
                                        class="form-control"
                                        rows="2"
                                        [(ngModel)]="invoice.product_description"
                                        >
                                    </textarea>
                                </div>
                                <div *ngIf="pageType=='show'">
                                  {{invoice.product_description}}
                                </div>
                            </td>
                            <td colspan="1">

                            </td>
                            <td *ngIf="pageType !='show'">
                                <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#modalConfirmDelMsgCreditNoteItem"
                                (click)="select(invoice)">
                                ลบ
                                </button>
                            </td>
                        </tr>

                        <tr *ngIf="invoice.item_type=='product_free'" cdkDrag>
                            <td class="px-1 py-0 pt-1" cdkDragHandle [hidden]="pageType =='show'">
                                <a style="color: #ccc;"><i class="fa fa-ellipsis-v"></i></a>
                            </td>
                            <div *cdkDragPreview>
                                <div class="card px-5 py-2">
                                  <div>
                                    <b>สินค้าบริการ:</b> {{invoice?.product_title | dash}}
                                  </div>
                                  <div>
                                    <b>คำอธิบาย:</b> {{invoice?.product_description | dash}}
                                  </div>
                                  <div>
                                    <b>ราคา:</b> {{utils.convertStingToNumber(invoice.price) | number : '1.2-2'}}
                                  </div>
                                </div>
                            </div>
                            <td class="text-center">
                                {{invoice.index}}
                            </td>
                            <td>
                                <input type="text" 
                                placeholder="สินค้าบริการ"
                                class="form-control" 
                                [id]="'product_title_'+i"
                                [disabled]="pageType =='show'"
                                [(ngModel)]="invoice.product_title"
                                [ngModelOptions]="{standalone: true}"
                            >
                            </td>
                            <td>
                                <input type="text" 
                                placeholder="คำอธิบาย"
                                class="form-control" 
                                [id]="'product_description_'+i"
                                [disabled]="pageType =='show'"
                                [(ngModel)]="invoice.product_description"
                                [ngModelOptions]="{standalone: true}"
                            >
                            </td>
                            <td class="text-right">
                                <input type="text" 
                                    placeholder="0.00"
                                    class="form-control text-right" 
                                    [id]="'price_'+i"
                                    (keyup)="onKeyup()"
                                    [disabled]="pageType =='show'"
                                    (click)="$event.target.select()"
                                    oninput="this.value = 
                                    this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                                    [(ngModel)]="invoice.price"
                                    [ngModelOptions]="{standalone: true}"
                                >
                            </td>
                            <td class="text-right">
                                {{invoice.discount | number : '1.2-2'}}
                            </td>
                            <td class="text-right">
                                {{invoice.quantity}}
                            </td>
                            <td class="text-right">
                                {{calculate.sumProduct(invoice) | number : '1.2-2'}}
                            </td>
                            <td *ngIf="pageType !='show'">
                                <button
                                type="button"
                                class="btn btn-danger btn-sm"
                                data-toggle="modal"
                                data-target="#modalConfirmDelMsgCreditNoteItem"
                                (click)="select(invoice)">
                                ลบ
                                </button>
                            </td>
                        </tr>
                    </thead>

                    <tbody *ngIf="invoiceItems.length == 0">
                        <tr>
                            <td [attr.colspan]="10" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="d-flex justify-content-between mb-1">
                <div>
                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    data-toggle="modal"
                    data-target="#credit-note-item-create"
                    (click)="openCreateItem()"
                    *ngIf="pageType !='show' && creditNoteInfo.credit_note_type != 'FULL_AMOUNT' && creditNoteInfo.credit_note_type != 'DISCOUNT'"
                    >
                    <i class="fa fa-plus icon-left"></i> เลือกรายการ
                    </button>

                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    (click)="addItem()"
                    *ngIf="pageType !='show' && creditNoteInfo.credit_note_type == 'DISCOUNT'"
                    >
                    <i class="fa fa-plus icon-left"></i> เพิ่มคำอธิบาย/สาเหตุ
                    </button>

                    <button
                    type="button"
                    class="btn btn-primary mr-1"
                    (click)="addDescription()"
                    *ngIf="pageType !='show' && creditNoteInfo.credit_note_type != 'DISCOUNT'"
                    >
                    <i class="fa fa-plus icon-left"></i> เพิ่มคำอธิบาย/สาเหตุ
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-credit-note-item-create *ngIf="createItem && creditNoteInfo" [creditNoteInfo]="creditNoteInfo" (done)="submitCredit($event)">
</app-credit-note-item-create>

<app-confirm-delete
  [id]="'modalConfirmDelMsgCreditNoteItem'"
  (save)="delete($event)"
></app-confirm-delete>