<div class="container app-content overflow-container">
    <div class="btn-section text-right border-bottom">
      <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(info)">
        <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
      </button>
    </div>
    <div *ngFor="let page of pages; let i = index; let lastIndex = last">
      <div class="pdf" id="note_{{i}}">
        <div class="container mt-5"  *ngIf="i == 0">
          <div class="row">
            <div class="col-2"></div>
            <img class="col-1 text-right p-0" src="/assets/app-assets/images/pdf/logo1.png" width="90px"
                height="110px">
            <div class="col-6 text-center pt-3">
                <h2>บริษัท มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี จำกัด</h2>
                <p>214 ถนนบางแวก แขวงบางไผ่ เขตบางแค กรุงเทพฯ 10160</p>
            </div>
            <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon2.jpg" width="90px"
                height="110px">
            <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon3.png" width="90px"
                height="110px">
            <div class="col-1"></div>
          </div>
          <p class="text-center">โทร. 0-2865-4647-8 ต่อ 201, 202 แฟ็กซ์. 02-865-4649 E-mail : account@mit.in.th</p>
        </div>
        
        <h2 class="text-center text-header pt-1"  *ngIf="i == 0">{{type=='credit'?'ใบลดหนี้':'ใบเพิ่มหนี้'}} / {{info?.ref_doc_type =='TAX_INVOICE'? 'ใบกำกับภาษี':'ใบแจ้งหนี้'}}</h2>
        <div class="container mt-5">
          <div class="row" *ngIf="i == 0">
            <div class="col-6">
              <p>ชื่อลูกค้า &nbsp;&nbsp; {{info?.customer_info.company_name}}</p>
              <p style="text-indent: 65px;">{{info?.customer_address}}</p>
            </div>
            <div class="col-6 text-right">
              <p>เลขที่ {{info?.doc_no }}</p>
              <p>วันที่ {{info?.doc_date | thai_date: "short":"date" | dash}}</p>
            </div>
          </div>
          <div class="row" *ngIf="i == 0">
            <div class="col-6">
              <p>เลขประจำตัวผู้เสียภาษีอากร &nbsp;&nbsp; {{info?.customer_address_info?.tax_id}}</p>
            </div>
            <div class="col-6 text-right">
              <p>สถานประกอบการ &nbsp;&nbsp; สำนักงานใหญ่</p>
            </div>
          </div>
          <div class="row"  *ngIf="i == 0">
            <div class="col-12">
              <p>อ้างถึง{{info?.ref_doc_type =='TAX_INVOICE'? 'ใบกำกับภาษี':'ใบแจ้งหนี้'}}เลขที่ {{info?.ref_doc_type =='TAX_INVOICE'? info?.ref_acc_document_info?.doc_no_company:info?.ref_acc_document_info?.doc_no}}  ลงวันที่ {{info?.ref_acc_document_info?.doc_date | thai_date: "short":"date" | dash}}</p>
            </div>
          </div>  
          <table class="table table-bordered">
            <thead>
              <tr>
                <th class="text-center" scope="col">ลำดับที่</th>
                <th class="text-center" scope="col">ชื่อสินค้า</th>
                <th class="text-right" scope="col">จำนวน</th>
                <th class="text-right" scope="col">ราคา</th>
                <th class="text-right" scope="col">จำนวนเงิน</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of page; let j = index;">
                <td class="text-center">{{j+1}}</td>
                <td class="text-left">
                  {{item.product_title}}
                </td>
                <td class="text-right">{{item.quantity}}</td>
                <td class="text-right">{{item.price | number : '1.2-2'}}</td>
                <td class="text-right">
                  <div *ngIf="item.item_type=='calibration_service'">
                    {{calculate.sumMitCredit(item) | number : '1.2-2'}}
                  </div>
                  <div *ngIf="item.item_type=='product' || item.item_type=='product_free'">
                      {{calculate.sumProduct(item) | number : '1.2-2'}}
                  </div>
                </td>
              </tr>

              <tr *ngIf="lastIndex">
                <td class="text-center"></td>
                <td class="text-center"><br></td>
                <td class="text-center"></td>
                <td class="text-right"></td>
                <td class="text-right"></td>
              </tr>

              <tr *ngIf="lastIndex && type=='credit'">
                <td colspan="4" rowspan="6" style="border: 1px solid white !important;border-right: 1px solid black !important;">
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-4 text-right"><p >มูลค่าของสินค้า/บริการตามใบกำกับภาษีเดิม</p></div>
                  </div>
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-4 text-right"><p>มูลค่าของสินค้าหรือบริการที่ถูกต้อง</p></div>
                  </div> 
                  <div class="row">
                    <div class="col-7"></div>
                    <div class="col-3 text-right"><p>ผลต่าง</p></div>
                  </div>
                  <div class="row">
                    <div class="col-7"></div>
                    <div class="col-3 text-right"><p>VAT {{info?.tax}}%</p></div>
                  </div>  
                  <div class="row">
                    <div class="col-7">หมายเหตุ : {{info?.doc_note}}</div>
                    <div class="col-3 text-right"><p>สุทธิ</p></div>
                  </div>  
                </td>
              </tr>
              <tr *ngIf="lastIndex && type=='credit'">
                <td class="text-right">{{info.ref_grand_total_before_vat | number : '1.2-2'}}</td>
              </tr>
              <tr *ngIf="lastIndex && type=='credit'">
                <td class="text-right">{{info.new_amount | number : '1.2-2'}}</td>
              </tr>
              <tr *ngIf="lastIndex && type=='credit'">
                <td class="text-right">{{info.difference | number : '1.2-2'}}</td>
              </tr>
              <tr *ngIf="lastIndex && type=='credit'">
                <td class="text-right">{{calculate.sumProductWithTax(info.difference, info) | number : '1.2-2'}}</td>
              </tr>
              <tr *ngIf="lastIndex && type=='credit'">
                <td class="text-right">{{(info.grand_total) | number : '1.2-2'}}</td>
              </tr>

              <tr *ngIf="lastIndex && type=='debit'">
                <td colspan="4" rowspan="6" style="border: 1px solid white !important;border-right: 1px solid black !important;">
                  <div class="row">
                    <div class="col-6"></div>
                    <div class="col-4 text-right"><p >รวมเป็นเงิน</p></div>
                  </div>
                  <div class="row">
                    <div class="col-7"></div>
                    <div class="col-3 text-right"><p>VAT {{info?.tax}}%</p></div>
                  </div>  
                  <div class="row">
                    <div class="col-7">หมายเหตุ : {{info?.doc_note}}</div>
                    <div class="col-3 text-right"><p>สุทธิ</p></div>
                  </div>  
                </td>
              </tr>
              <tr *ngIf="lastIndex && type=='debit'">
                <td class="text-right">{{info.grand_total_before_vat | number : '1.2-2'}}</td>
              </tr>
              <tr *ngIf="lastIndex && type=='debit'">
                <td class="text-right">{{calculate.sumProductWithTax(info.difference, info) | number : '1.2-2'}}</td>
              </tr>
              <tr *ngIf="lastIndex && type=='debit'">
                <td class="text-right">{{(info.grand_total) | number : '1.2-2'}}</td>
              </tr>
            </tbody>
          </table>       
        </div>
        <div class="container mt-3" *ngIf="lastIndex">
          <div class="row">
            <div class="col-6">
              <p>ผู้รับเอกสาร/วันที่ ___________________________________________________________ ผู้มีอำนาจลงนาม</p>
            </div>
          </div>
          <div class="row">
            <div class="col-6"></div>
            <div class="col-6 text-center">
              <p>ในนาม บจก. มิราเคิลฯ</p>
              <p>___________________________________________________________</p>
              <p>(__________________________________________________________)</p>
              <p>หัวหน้าบัญชีการเงิน</p>
            </div>
          </div>
        </div>  
      </div>
      
      <br />
    </div>
  </div>
  