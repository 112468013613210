import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationMemoService {
  async create({
    quotation_info_id,
    memo
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-memo`, {
        quotation_info_id,
        memo
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      quotation_memo_id,
      quotation_info_id,
      memo
    } = data;
    let result = null;
    try {
      result = await Axios().patch(`/quotation-memo/${quotation_memo_id}`, {
        quotation_memo_id,
        quotation_info_id,
        memo
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_memo_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-memo/${quotation_memo_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-memo`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_memo_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-memo/${quotation_memo_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
