import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { UtilsService } from "src/app/services";
import { 
  AccDebitNoteInfoService 
} from "src/app/services/debit-note";

@Component({
  selector: 'app-debit-note-list',
  templateUrl: './debit-note-list.component.html',
  styleUrls: ['./debit-note-list.component.css']
})
export class DebitNoteListComponent implements OnInit {
  isLoading = true;
  debit_notes: Array<any>;
  status: string;
  debit_note: string;
  reset: boolean = false;
  load: Function = this.AccDebitNoteInfoService.load;
  filter = {};
  params: any;
  initialPage: number = 1;

  number_all_status:number = 0;
  number_draft_status:number = 0;
  number_rejected_status:number = 0;

  number_canceled_status:number = 0;
  number_approved_status:number = 0;
  number_waiting_for_approve_status:number = 0;

  constructor(
    private router: Router, 
    private route: ActivatedRoute,
    public AccDebitNoteInfoService: AccDebitNoteInfoService,
    public _UtilsService: UtilsService,) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);
    this.route.queryParams
      .subscribe(params => {
        if(params){
          let page = parseInt(params.page);

          this.params = params;
          this.filter = {
            ...params,
            offset: page == 1 || isNaN(page)? 0: ((page-1)* 10),
            page: this._UtilsService.setUndefined()
          };

          this.initialPage = isNaN(page)? 1: page;
        }
      }
    );
  }

  routeEvent(state): void {
    this.status = state?.status;
  }

  ngOnInit(): void {
    this.getCountOfStatus(null);
    this.debit_notes = [];
  }
  getCountOfStatus(filter: object){
    this.AccDebitNoteInfoService.load({limit:0}, { ...filter, doc_status_id: 'DRAFT'}).then(res=>{
      this.number_draft_status = res.rowCount;
    });
    this.AccDebitNoteInfoService.load({limit:0}, { ...filter, doc_status_id: 'REJECTED'}).then(res=>{
      this.number_rejected_status = res.rowCount;
    });
    this.AccDebitNoteInfoService.load({limit:0}, { ...filter, doc_status_id: 'CANCELED'}).then(res=>{
      this.number_canceled_status = res.rowCount;
    });
    this.AccDebitNoteInfoService.load({limit:0}, { ...filter, doc_status_id: 'APPROVED'}).then(res=>{
      this.number_approved_status = res.rowCount;
    });
    this.AccDebitNoteInfoService.load({limit:0}, { ...filter, doc_status_id: 'WAITING_FOR_APPROVE'}).then(res=>{
      this.number_waiting_for_approve_status = res.rowCount;
    });
  }
  onChangePage(debit_notes: Array<any>) {
    this.debit_notes = debit_notes;
    this.isLoading = false;
  }
  select(debit_note) {
    this.debit_note = debit_note;
  }
  searchStatus(status:string = null){
    let condition = {
      doc_status_id: status,
    };
    this.search(condition);
  }
  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }
  search(filter: object) {
    this.filter = filter;

    this.setQueryUrl();
  }

  setQueryUrl(){
    let filter = {
      ...this.filter,
      page: this.initialPage,
      offset: this._UtilsService.setUndefined(),
    }
    this.router.navigate([], {
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
    this.params = filter;
  }

  getFilterLimit(filterLimit){

    if(this.initialPage != filterLimit.initialPage && filterLimit.initialPage != 0){
  
      let params = {
        ...this.filter,
        page: filterLimit.initialPage,
        offset: this._UtilsService.setUndefined()
      }

      this.router.navigate([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.params = params;
    }
  }
}
