<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">รายการข้อมูลลูกค้า</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="" [routerLink]="['/customer']">จัดการข้อมูลลูกค้า</a>
              </li>
              <li class="breadcrumb-item active">รายการข้อมูลลูกค้า</li>
            </ol>
          </div>
        </div>
      </div>
      <div
        class="content-header-right col-md-6 col-12 mb-md-0 mb-2"
        *ngIf="isAllowCreate && !(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
          'ACC_DEP'
        )"
      >
        <div class="float-md-right">
          <button
            [routerLink]="['/customer/create']"
            class="btn btn-outline-primary"
          >
            <i class="feather icon-plus-circle icon-left"></i> เพิ่มลูกค้าใหม่
          </button>
        </div>
      </div>
    </div>
    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <div class="row grouped-multiple-statistics-card">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <div class="row text-right">
                <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                  <div
                    class="d-flex justify-content-between align-items-start mb-2 mb-xl-0 border-right-blue-grey border-right-lighten-5"
                  >
                    <span
                      class="card-icon primary d-flex justify-content-center"
                    >
                      <!-- <i class="feather p-1 icon-bar-chart customize-icon font-large-2 p-1"></i> -->
                      <img
                        src="/assets/app-assets/images/icons/icon-all.png"
                        height="60"
                      />
                    </span>
                    <div class="stats-amount mr-3">
                      <p class="sub-heading text-muted blue-grey lighten-1">
                        ทั้งหมด
                      </p>
                      <h3 class="heading-text text-bold-600">
                        {{ number_of_lead + number_of_customer + number_of_opportunity | number }}
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                  <div
                    class="d-flex justify-content-between align-items-start mb-2 mb-xl-0 border-right-blue-grey border-right-lighten-5"
                  >
                    <span
                      class="card-icon danger d-flex justify-content-center"
                    >
                      <!-- <i class="feather p-1 icon-pie-chart customize-icon font-large-2 p-1"></i> -->
                      <img
                        src="/assets/app-assets/images/icons/icon-lead.png"
                        height="60"
                      />
                    </span>
                    <div class="stats-amount mr-3">
                      <p class="sub-heading text-muted blue-grey lighten-1">
                        ผู้มุ่งหวัง
                      </p>
                      <h3 class="heading-text text-bold-600">
                        {{ number_of_lead | number }}
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                  <div
                    class="d-flex justify-content-between align-items-start mb-2 mb-xl-0 border-right-blue-grey border-right-lighten-5"
                  >
                    <span
                      class="card-icon success d-flex justify-content-center"
                    >
                      <!-- <i class="feather p-1 icon-bar-chart-2 customize-icon font-large-2 p-1"></i> -->
                      <img
                        src="/assets/app-assets/images/icons/icon-opportunity.png"
                        height="60"
                      />
                    </span>
                    <div class="stats-amount mr-3">
                      <p class="sub-heading text-muted blue-grey lighten-1">
                        โอกาสทางการขาย
                      </p>
                      <h3 class="heading-text text-bold-600">
                        {{ number_of_opportunity | number }}
                      </h3>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-xl-3 col-sm-6 col-12">
                  <div class="d-flex justify-content-between align-items-start">
                    <span
                      class="card-icon warning d-flex justify-content-center"
                    >
                      <!-- <i class="feather p-1 icon-shopping-cart customize-icon font-large-2 p-1"></i> -->
                      <img
                        src="/assets/app-assets/images/icons/icon-customer.png"
                        height="60"
                      />
                    </span>
                    <div class="stats-amount mr-3">
                      <p class="sub-heading text-muted blue-grey lighten-1">
                        ลูกค้า
                      </p>
                      <h3 class="heading-text text-bold-600">
                        {{ number_of_customer | number }}
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">ข้อมูลลูกค้าทั้งหมด</h4>
              <div class="heading-elements">
                <div class="btn-group">
                  <ng-select2
                    *ngIf="select2Options"
                    [options]="select2Options"
                    allowClear="true"
                    class="w-200"
                    placeholder="ค้นหา..."
                    (valueChanged)="searchAll($event)"
                  >
                  </ng-select2>
                  <a
                    class="btn btn-primary d-flex justify-content-center align-items-center text-white"
                    (click)="search(filter)"
                  >
                    <i class="fa fa-search icon-left"></i> ค้นหา
                  </a>
                </div>

              </div>
            </div>

            <div class="card-content">
              <div class="card-body">
                <form>
                  <div class="row">
                    <!-- <div class="col-12 col-sm-6 col-lg-4"
                      *ngIf="!(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                        'ACC_DEP'
                      )">
                      <label for="customer_state_id" class="sr-only"
                        >สถานะ</label
                      >
                      <fieldset class="form-group">
                        <select
                          class="form-control"
                          id="customer_state_id"
                          (change)="filterByStatus($event)"
                        >
                          <option value="">สถานะ : ทั้งหมด</option>
                          <option
                            *ngFor="let customer_state of customer_states"
                            [value]="customer_state.customer_state_id"
                          >
                            {{ customer_state.customer_state_name_en }}
                          </option>
                        </select>
                      </fieldset>
                    </div> -->
                    <div
                      class="col-12 col-sm-6 col-lg-4"
                      *ngIf="(userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_co_sales || (userInfo$ | async)?.is_sales_supervisor || (userInfo$ | async)?.is_co_sales_supervisor"
                    >
                      <label for="user_id" class="sr-only">พนักงานขาย</label>
                      <fieldset class="form-group">
                        <employee-select
                          *ngIf="(userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_sales_supervisor"
                          [placeholder]="'พนักงานขาย : ทั้งหมด'"
                          [emp]="userInfo$ | async"
                          (valueChanged)="filterByUserId($event)"
                          (instance)="select2Instance = $event"
                        ></employee-select>

                        <employee-select
                          *ngIf="(userInfo$ | async)?.is_co_sales || (userInfo$ | async)?.is_co_sales_supervisor"
                          [placeholder]="'พนักงานขาย : ทั้งหมด'"
                          (valueChanged)="filterByUserId($event)"
                          (instance)="select2Instance = $event"
                        ></employee-select>
                      </fieldset>
                    </div>

                    <div
                      class="col-12 col-sm-6 col-lg-4"
                    >
                    </div>

                    <div
                    class="col-12 col-sm-6 col-lg-4 text-right"
                    *ngIf="(userInfo$ | async)?.is_sales_manager || (userInfo$ | async)?.is_sales_supervisor"
                  >
                    <a class="primary pt-1" (click)="filterByStateChange()"> รายการรอ Manager พิจารณาการถือครอง ({{number_of_state_change}})</a>
                  </div>
                  </div>
                </form>

                <div class="table-responsive">
                  <loading *ngIf="isLoading"></loading>
                  <table
                    class="table table-striped table-bordered"
                    *ngIf="!isLoading"
                  >
                    <thead>
                      <tr>
                        <th *ngIf="!(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                          'ACC_DEP'
                        )">สถานะ</th>
                        <th style="width: 20%;">บริษัท</th>
                        <!-- <th>ชื่อผู้ติดต่อ</th> -->
                        <th>พนักงาน</th>
                        <th>กิจกรรมล่าสุด</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let customer of customers">
                        <th *ngIf="!(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                          'ACC_DEP'
                        )">
                          {{
                            customer.sales_assignment_info
                              ? customer.customer_state_id
                              : "FREE"
                          }}
                          <br>
                          <h4 class="badge btn-blue">
                            {{ customer.customer_level_id | dash }}
                          </h4>
                        </th>
                        <td>
                          <p>
                            [{{ customer.customer_code }}]
                            {{ customer.company_name }} 
                            {{ customer.branch?  '('+customer.branch+')':''}}
                          </p>
                          <small [ngSwitch]="customer.customer_address_info?.province_id">
                            <span *ngSwitchCase="'1'">
                              {{ customer.customer_address_info?.address | dash }}
                              {{(customer.customer_address_info?.district_name)?'แขวง': ''}}{{
                                customer.customer_address_info?.district_name
                              }}
                              {{(customer.customer_address_info?.amphure_name)?'เขต': ''}}{{
                                customer.customer_address_info?.amphure_name
                              }}
                              {{
                                customer.customer_address_info?.province_name
                              }}

                              {{
                                customer.customer_address_info?.zip_code
                              }}
                            </span>
                            <span *ngSwitchDefault>
                              {{ customer.customer_address_info?.address | dash }}
                              {{(customer.customer_address_info?.district_name)?'ตำบล': ''}}{{
                                customer.customer_address_info?.district_name
                              }}
                              {{(customer.customer_address_info?.amphure_name)?'อำเภอ': ''}}{{
                                customer.customer_address_info?.amphure_name
                              }}
                              {{
                                customer.customer_address_info?.province_name
                              }}
                              {{
                                customer.customer_address_info?.zip_code
                              }}
                            </span>
                          </small>
                        </td>
                        <!-- <td>
                          <div
                            *ngIf="
                              customer.customer_state_id === 'FREE' ||
                                customer?.sales_assignment_info?.user_id ===
                                  (userInfo$ | async)?.user_id || ((userInfo$ | async)?.is_co_sales && customer?.inCharge) 
                                  || (userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
                                    'ACC_DEP'
                                  )
                                  || (userInfo$ | async)?.is_sales_manager ;
                              else noData
                            "
                          >
                            <p>
                              {{ customer.master_contact?.firstname }}
                              {{ customer.master_contact?.lastname }}
                            </p>
                            <p>
                              โทร: {{ customer.master_contact?.phonenumber | dash}}
                            </p>
                          </div>
                          <ng-template #noData>
                            <p>-</p>
                          </ng-template>
                        </td> -->
                        <td>
                          <div *ngIf="customer.customer_state_id === 'FREE'">
                            FREE
                          </div>
                          <div *ngIf="customer.customer_state_id !== 'FREE'">
                            <p>
                              {{
                                customer.sales_assignment_info?.user_info
                                  ?.firstname_th
                              }}
                              {{
                                customer.sales_assignment_info?.user_info
                                  ?.lastname_th
                              }}
                            </p>
                            <p>
                              รหัสพนักงาน:
                              {{
                                customer.sales_assignment_info?.emp_info
                                  ?.emp_code
                              }}
                            </p>
                          </div>
                        </td>
                        <td>
                          <p>
                            {{
                              customer.lastest_activity?.created_at
                                | thai_date: "short"
                                | dash
                            }}
                          </p>
                          <p>
                            {{
                              customer.lastest_activity
                                ?.customer_activity_type_info
                                ?.activity_type_name_th
                            }}
                          </p>
                        </td>
                        <td>
                          <!-- <button
                            *ngIf="
                              customer.sales_assignment_info.user_id ===
                                userInfo.user_id &&
                              customer.customer_state_id !== 'FREE'
                            "
                            type="button"
                            class="btn mr-1 mb-1 btn-warning btn-sm"
                            [routerLink]="[
                              '/customer/' + customer.customer_id + '/edit'
                            ]"
                          >
                            <i class="fa fa-pencil icon-left"></i> แก้ไข
                          </button> -->

                          <a
                            type="button"
                            class="btn mr-1 mb-1 btn-primary btn-sm"
                            [routerLink]="[
                              '/customer/' + customer.customer_id + '/show'
                            ]"
                          >
                            <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                          </a>

                          <button
                            *ngIf="
                              customer.customer_state_id === 'FREE' &&
                              ((userInfo$ | async)?.is_co_sales)
                            "
                            type="button"
                            class="btn mr-1 mb-1 btn-info btn-sm"
                            (click)="select(customer)"
                            data-toggle="modal"
                            data-target="#revoke"
                          >
                            <i class="feather icon-refresh-cw icon-left"></i>
                            เข้าถือครอง
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="!customers.length">
                        <td colspan="6" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  *ngIf="userInfo$ | async"
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>

<app-customer-revoke
  [customer]="customer"
  [activity-types]="activityTypes"
  [user]="userInfo$ | async"
  *ngIf="customer"
  (done)="doReset($event)"
  (cancel)="customer = null"
></app-customer-revoke>
