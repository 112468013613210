import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationMitItemsHistoryService {
  async create({
    quotation_mit_item_id,
    previous_date,
    new_date
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-items-return-history`, {
        quotation_mit_item_id,
        previous_date,
        new_date
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_mit_items_return_history_id,
        quotation_mit_item_id,
        previous_date,
        new_date
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-mit-items-return-history/${quotation_mit_items_return_history_id}`, {
        quotation_mit_item_id,
        previous_date,
        new_date
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_mit_items_return_history_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-mit-items-return-history/${quotation_mit_items_return_history_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-items-return-history`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_mit_items_return_history_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-items-return-history/${quotation_mit_items_return_history_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
