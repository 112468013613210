import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CustomerAddressService, CustomerInfoService, UtilsService } from 'src/app/services';
import { AccDebitNoteItemService, AccDebitNoteInfoService } from 'src/app/services/debit-note';
import { AccInvoiceInfoService } from 'src/app/services/invoice';
import { CalculateFunctionService, QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';

@Component({
  selector: 'app-debit-note-edit',
  templateUrl: './debit-note-edit.component.html',
  styleUrls: ['./debit-note-edit.component.css']
})
export class DebitNoteEditComponent implements OnInit {
  form: FormGroup;
  acc_debit_note_info_id: string;
  
  pageLoad:boolean = false;
  submitted:boolean = false;
  disableSaveBtn: boolean = false;

  customerList: any = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];

  state: any;
  status: any;

  customer_info = null;
  debitNoteInfo = null;
  debitNoteItem = null;
  subject: Subject<string> = new Subject();

  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private route: ActivatedRoute,

    private AccInvoiceInfoService: AccInvoiceInfoService,
    private CustomerAddressService: CustomerAddressService,
    private AccDebitNoteInfoService: AccDebitNoteInfoService,
    private QuotationMitItemService: QuotationMitItemService,
    private AccDebitNoteItemService: AccDebitNoteItemService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private QuotationProductItemService: QuotationProductItemService,

    public UtilsService: UtilsService,
    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) {
    this.acc_debit_note_info_id = 
    this.route.snapshot.paramMap.get("acc_debit_note_info_id"); 
  }

  async ngOnInit() {
    this.createForm();

    await this.AccDebitNoteInfoService.getById({acc_debit_note_info_id: this.acc_debit_note_info_id}).then(async res=>{
      let resultData = res.resultData || {};
      this.debitNoteInfo = resultData;
      this.customer_info = res.resultData.customer_info;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: this.customer_info? `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''} ${this.customer_info?.branch?" (" + this.customer_info?.branch + ")": ""}`: '',
        ...resultData
      }

      this.form.patchValue(data);

      if(resultData.customer_id){

        await this.setCustomerAddress(resultData.customer_id);
        await this.selectAddressName(resultData.customer_address_id);
      }

      if(resultData.customer_address_id){
        await this.CustomerAddressService.getById({ customer_address_id: resultData.customer_address_id }).then((res)=>{
          if(res.success){
            var resultData = res.resultData;

            this.form.controls['customer_address_name'].setValue(resultData.address_name + (resultData.branch?' ('+ resultData.branch +')': '') + ` แผนก: ${resultData.department}`);
          }else{
            this.form.controls['customer_address_name'].setValue(null);
          } 
        })
      }

      if(resultData.ref_doc_type == 'INVOICE'){
        await this.AccInvoiceInfoService.getById({acc_invoice_info_id: resultData.ref_acc_document_info_id})
        .then((res) => {
          let resultData = res.resultData;
          this.form.get("ref_acc_document_doc_no").setValue(resultData.doc_no);
        });
      }else{
        await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: resultData.ref_acc_document_info_id})
        .then((res) => {
          let resultData = res.resultData;
          this.form.get("ref_acc_document_doc_no").setValue(resultData.doc_no_company);
        });
      }
    })

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );

    this.pageLoad = true;
  }

  createForm(){
    this.form = this._fbd.group({
      acc_debit_note_info_id: [this.acc_debit_note_info_id],
      ref_doc_type: [""],
      ref_acc_document_info_id:  [""],
      ref_acc_document_doc_no:  [""],
      doc_no: ["", [Validators.required]],
      revise_count: [""],
      doc_date: ["", [Validators.required]],
      customer_id: ["", [Validators.required]],
      customer_name: [""],
      customer_address_name: [""],
      customer_address: [""],
      customer_address_id: ["", [Validators.required]],
      doc_note: [""],
      doc_reason: [""],
      difference_tax: [0],
      discount: [0],
      grand_total_before_vat: [0.00],
      tax: ["7"],
      grand_total: [0.00],
      doc_status_id: ["", [Validators.required]],
    });
    this.form.controls['customer_address_id'].disable(); 
  }

  async setCustomerAddress(event){
   const customer = this.customer_info;
   let address = null;
   await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
   .then(async (res) => {
     let customer = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];
     if(customer.length > 0){
       if(customer.length > 0){
         address = {
           id: customer[0].customer_address_id,
           customer_id: customer[0].customer_id,
           text: customer[0]?.branch?`${customer[0].address_name} (${customer[0]?.branch})` + ` แผนก: ${customer[0].department}`: `${customer[0].address_name}` + ` แผนก: ${customer[0].department}`,
           address_name: this.utils.genAddress(customer[0]),
         }
       } 
     }
   });
   
   if(customer){
     await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
     .then(async (res) => {
     this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];
     this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem => (
       {
         id: elem.customer_address_id,
         customer_id: elem.customer_id,
         text: elem.branch? `${elem.address_name} (${elem.branch})` + ` แผนก: ${elem.department}`: `${elem.address_name}` + ` แผนก: ${elem.department}`,
         address_name: this.utils.genAddress(elem)
       } 
     ));
     });
     let customerAddress = this.tmpCustomerAddressList;
     if(address){
      this.customerAddressList = [
        ...[address],
        ...customerAddress
      ];
    }else{
      this.customerAddressList = [
        ...customerAddress
      ];
    }

     if(this.customerAddressList.length > 0){
       const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
       if(checkAddress.length == 0) {
         if(address){
           this.form.get('customer_address_id').setValue(address.id);
           this.selectAddressName(address.id);
         }else{
          this.form.controls['customer_address_id'].setValue(this.customerAddressList[0]?.id);
          this.selectAddressName(this.customerAddressList[0]?.id);
         }
       }
     }else{
       if(address){
        this.customerAddressList = [
          ...[address],
        ];
         this.form.get('customer_address_id').setValue(address.id);
         this.selectAddressName(address.id);
       }
     }
   }
  }

  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address.address_name){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.get('customer_address').setValue("");
    }
  }

  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
  }

  save(){
    this.form.get('doc_status_id').setValue("WAITING_FOR_APPROVE");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
  }

  async submit(){
    let data = this.form.getRawValue();
    this.disableSaveBtn = true;

    let response = await this.AccDebitNoteInfoService.update(data);
    if(response.success){
      await Promise.all(
        this.debitNoteItem?.items.map(async (value: any, index: number)=>{
          if(value.acc_debit_note_info_id){
            let  itemResponse = await this.AccDebitNoteItemService
            .update({
              ...value,
              price: parseFloat(this.utils.convertStingToNumber(value.price)),
              acc_debit_note_info_id: response.resultData.acc_debit_note_info_id,
              order_by: index
            });
            this.updateMitItem(value, response);
          }else{
            let  itemResponse = await this.AccDebitNoteItemService
            .create({
              ...value,
              price: parseFloat(this.utils.convertStingToNumber(value.price)),
              acc_debit_note_info_id: response.resultData.acc_debit_note_info_id,
              order_by: index
            });
            if(itemResponse.success){
              if(value.item_type == 'calibration_service'){
                let mitResponse = await this.QuotationMitItemService.update(
                  {
                    ...value.data,
                    acc_debit_note_info_id: itemResponse.resultData.acc_debit_note_info_id
                  }
                )
              }else{
                let productResponse = await this.QuotationProductItemService.update(
                  {
                    ...value.data,
                    acc_debit_note_info_id: itemResponse.resultData.acc_debit_note_info_id
                  }
                )
              }
            }
            this.updateMitItem(value, response);
          }
      }));

      await Promise.all(this.debitNoteItem.delelteItems.map(async (v)=>{
        if(v.acc_debit_note_items_id){
          let accDeditNoteResponse = await this.AccDebitNoteItemService.delete({
            acc_debit_note_items_id: v.acc_debit_note_items_id,
          });
          await Promise.all(
            this.debitNoteItem.items.map(async (value: any, index: number)=>{
    
              if(value.item_type == 'calibration_service'){
                let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
                await this.QuotationMitItemService.update({
                  ...quotationMitResponse.resultData[0],
          
                  acc_debit_note_info_id: "",
                  acc_debit_note_info_doc_no: "",
                  acc_debit_note_info_status: ""
                })
              }else if(value.item_type == 'product'){
                let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
                await this.QuotationProductItemService.update({
                  ...quotationProductResponse.resultData,
          
                  acc_debit_note_info_id: "",
                  acc_debit_note_info_doc_no: "",
                  acc_debit_note_info_status: ""
                })
              }
          }));
          if (!accDeditNoteResponse.success) {
            throw accDeditNoteResponse.error;
          }
        }
      }));
      await this.router.navigateByUrl("/debit-note", {
        state: {
          status: response.success,
        },
      })
    }else{
      this.status = response.error;
    }
    this.disableSaveBtn = false;
  }

  getItems(event){
    if(event){
      this.debitNoteItem = event;
    }
    this.sumCount();
  }

  onKeyup(){
    this.subject.next();
  }

  sumCount(){
    let new_amount = 0;
    if(this.debitNoteItem){
      this.debitNoteItem?.items.map(item=>{
        if(item.item_type == 'product'){
          new_amount = new_amount + this.calculate.sumProduct(item);
        }else{
          new_amount = new_amount + this.calculate.sumMitCredit(item);
        }
      });
    }
    this.form.get('grand_total_before_vat').setValue(new_amount);
    
    this.form.get('difference_tax').setValue(this.calculate.sumProductWithTax(this.form.value?.grand_total_before_vat, this.form.value));

    this.form.get('grand_total').setValue(this.form.value.grand_total_before_vat + this.form.value?.difference_tax);
  }

  async updateMitItem(value, accDebitNoteResponse){
    if(value.item_type == 'calibration_service'){
      let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
      await this.QuotationMitItemService.update({
        ...quotationMitResponse.resultData[0],

        acc_debit_note_info_id: accDebitNoteResponse.resultData.acc_debit_note_info_id,
        acc_debit_note_info_doc_no: accDebitNoteResponse.resultData.doc_no,
        acc_debit_note_info_status: accDebitNoteResponse.resultData.doc_status_id
      })
    }else if(value.item_type == 'product'){
      let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
      await this.QuotationProductItemService.update({
        ...quotationProductResponse.resultData,

        acc_debit_note_info_id: accDebitNoteResponse.resultData.acc_debit_note_info_id,
        acc_debit_note_info_doc_no: accDebitNoteResponse.resultData.doc_no,
        acc_debit_note_info_status: accDebitNoteResponse.resultData.doc_status_id
      })
    }
  }
}
