import { Component, OnInit, Input, SimpleChanges } from "@angular/core";

@Component({
  selector: "app-response-status",
  templateUrl: "./response-status.component.html",
  styleUrls: ["./response-status.component.css"],
})
export class ResponseStatusComponent implements OnInit {
  @Input() status: any;
  isClose: boolean = false;
  constructor() {}
  ngOnInit(): void {}
  responseClass() {
    return `alert-${this.status?.bootstrapClass}`;
  }
}
