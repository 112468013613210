<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">ดูใบรับเครื่องมือ</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/transport-receive']">การรับเครื่องมือ</a>
                </li>
                <li class="breadcrumb-item">
                    <a href="#" [routerLink]="['/transport-receive']">รายการรับเครื่องมือ</a>
                </li>
                <li class="breadcrumb-item active">ดูใบรับเครื่องมือ</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2" [hidden]="pageLoad">
          <div class="float-md-right">
            <button
            (click)="back()"
            type="button"
            class="btn btn-light btn-min-width ml-1"
            >
            <i class="fa fa-arrow-left"></i>  กลับ
            </button>
            <button
            *ngIf="(transportreceive?.doc_status_id === 'DRAFT' || transportreceive?.doc_status_id === 'WAITING_FOR_RECEIVE') && !(userInfo$ | async)?.emp_info?.emp_department_id.startsWith(
              'TRAN_DEP'
            ) "
            type="button"
            class="btn btn-danger btn-min-width ml-1"
            data-target="#transport-cancel"
            data-toggle="modal"
            >
            <i class="fa fa-times icon-left"></i> ยกเลิก
            </button>
            <button 
            *ngIf="(transportreceive?.doc_status_id === 'DRAFT' || transportreceive?.doc_status_id === 'WAITING_FOR_RECEIVE' || transportreceive?.doc_status_id === 'RECEIVED_SUCCESS')"
              [routerLink]="['/transport-receive/'+ transport_receive_info_id + '/edit']"
              type="button"
              class="btn btn-warning btn-min-width ml-1"
              >
              <i class="fa fa-pencil"></i>  แก้ไข
            </button>
            <button 
              *ngIf="transportreceive?.doc_status_id === 'RECEIVED_SUCCESS'"
              [routerLink]="['/transport-receive/'+ transport_receive_info_id + '/edit']"
              [queryParams]="{type: 'return'}"
              type="button"
              class="btn btn-info ml-1"
              >
              <i class="fa fa-pencil"></i>  บันทึกส่งเครื่องมือ
            </button>
            <button type="button" class="btn btn-info ml-1"
              *ngIf="transportreceive?.doc_status_id !== 'VOIDED' && transportreceive?.doc_status_id !== 'RECEIVED_SUCCESS' && transportreceive?.doc_status_id !== 'DRAFT' && transportreceive?.doc_status_id !== 'RECEIVE_WAITING_FOR_REVIEW' && transportreceive?.doc_status_id !== 'RECEIVE_WAITING_FOR_CONFIRM' && transportreceive?.doc_status_id !== 'RETURNED_SUCCESS'"
              [routerLink]="['/transport-receive/' + transportreceive?.transport_receive_info_id + '/edit']" [queryParams]="{type: 'receive'}">
                <i class="fa fa-check-circle-o"></i>  บันทึกรับเครื่องมือ
            </button>
            <button type="button" class="btn btn-info ml-1"
              *ngIf="transportreceive?.doc_status_id === 'RECEIVE_WAITING_FOR_REVIEW' && !(userInfo$ | async)?.emp_info?.emp_position_info?.emp_position_id.startsWith('TRAN_DEP-03') && !(userInfo$ | async)?.emp_info?.emp_department_id.startsWith('SALES_DEP')"
              [routerLink]="[
                '/transport-receive/' + transportreceive?.transport_receive_info_id + '/edit'
              ]" [queryParams]="{type: 'review'}">
              <i class="fa fa-check-circle-o"></i>  ตรวจสอบการรับเครื่องมือ
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <loading *ngIf="pageLoad"></loading>

        <div class="card" *ngIf="!pageLoad">
          <div class="card-header">
              <h4 class="card-title d-inline mt-1">ดูใบรับเครื่องมือ</h4>

              <div class="float-md-right">
                <button
                  type="button"
                  class="btn btn-blue btn-min-width ml-1 mb-1 "
                  (click)="openPdf(transportreceive)"
                  >
                    <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                </button>
              </div>
          </div>

          <div class="card-content collapse show">
              <form autocomplete="off" class="form" [formGroup]="form">
                  <div class="card-body">
                    <div class="form-body">
                      <div class="mb-1">
                        <span class="badge btn-blue badge-customer-lvl" *ngIf="customer_info">
                          {{ customer_info.customer_level_id | dash }}
                        </span>
                        <span *ngIf="transportreceive">
                          พนักงานขาย {{transportreceive?.sales_assignment_info[0]?.user_info?.firstname_th}} {{transportreceive?.sales_assignment_info[0]?.user_info?.lastname_th}}
                        </span>
                      </div>
                      <div class="row">
                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                      <form-group
                                          [control]="form.controls.customer_id"
                                          [submitted]="submitted"
                                        >
                                          <label for="customer_id">
                                            ชื่อลูกค้า
                                          </label>
                                          <input
                                            type="text"
                                            id="customer_name"
                                            class="form-control"
                                            placeholder="ชื่อลูกค้า"
                                            formControlName="customer_name"
                                            readonly
                                          />
                                      </form-group>
                                  </div>

                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                    <form-group
                                      [control]="form.controls.customer_address_id"
                                      [submitted]="submitted"
                                      >
                                        <label for="customer_address_id">
                                          ออกเอกสารในนาม
                                        </label>
                                        <input
                                        type="text"
                                        id="customer_address_id"
                                        class="form-control"
                                        placeholder="ออกเอกสารในนาม"
                                        formControlName="customer_address_id"
                                        readonly
                                      />
                                    </form-group>
                                  </div>

                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                    <form-group
                                    [control]="form.controls.doc_date"
                                    [submitted]="submitted">
                                    <label for="doc_date">
                                        วันที่ออก 
                                    </label>
                                    <div class="input-group mt-0">
                                        <input
                                        date-picker
                                        type="text"
                                        id="doc_date"
                                        placeholder="วันที่ออก"
                                        [max-date]="undefined"
                                        class="form-control"
                                        [date]="form.value.doc_date"
                                        formControlName="doc_date"
                                        />
                                        <div
                                        class="input-group-append"
                                        for="doc_date"
                                        >
                                        <span class="input-group-text"
                                            ><i class="feather icon-calendar"></i
                                        ></span>
                                        </div>
                                    </div>
                                    </form-group>
                                </div>

                                  <div class="col-xl-3 col-lg-6 col-md-12">
                                    <form-group
                                    [control]="form.controls.doc_no"
                                    [submitted]="submitted">
                                    <label for="doc_no">
                                        เลขที่เอกสาร
                                    </label>
                                    <input
                                        type="text"
                                        id="doc_no"
                                        class="form-control"
                                        placeholder="เลขที่เอกสาร"
                                        formControlName="doc_no"
                                        readonly
                                    />
                                    </form-group>
                                  </div>
                        </div>

                        <div class="row">
                                  <div class="col-lg-6 col-md-12">
                                    <form-group
                                      [control]="form.controls.customer_address"
                                      [submitted]="submitted"
                                        >
                                          <label for="customer_address">
                                            ที่อยู่ในการรับเครื่องมือ
                                          </label>
                                          <input
                                            type="text"
                                            id="customer_address"
                                            class="form-control"
                                            placeholder="ที่อยู่ในการรับเครื่องมือ"
                                            formControlName="customer_address"
                                            readonly
                                            />
                                      </form-group>
                                  </div>

                                  <div class="col-lg-3 col-md-12">

                                    <form-group
                                      [control]="form.controls.receiver_id"
                                      [submitted]="submitted"
                                      >
                                        <label for="receiver_id">
                                          ผู้รับเครื่องมือ
                                        </label>
                                        <ng-select2
                                          [data]="transportContactList"
                                          class="w-100"
                                          id="receiver_id"
                                          formControlName="receiver_id"
                                          placeholder="ผู้รับเครื่องมือ"
                                        >
                                        </ng-select2>
                                    </form-group>
 
                                  </div>

                                  <div class="col-lg-3 col-md-12">

                                    <form-group
                                      [control]="form.controls.transport_type_id"
                                      [submitted]="submitted"
                                      >
                                        <label for="transport_type_id">
                                          วิธีรับเครื่องมือ 
                                        </label>
                                        <input
                                            type="text"
                                            id="transport_type_id"
                                            class="form-control"
                                            placeholder="วิธีรับเครื่องมือ"
                                            formControlName="transport_type_id"
                                            readonly
                                            />
                                    </form-group>
 
                                </div>
                        </div>

                        <div class="row">
                                <div class="col-lg-3 col-md-12">
                                  <form-group
                                  [control]="form.controls.pickup_date"
                                  [submitted]="submitted">
                                  <label for="pickup_date">
                                    วันที่นัดรับเครื่องมือ 
                                  </label>
                                  <div class="input-group mt-0">
                                      <input
                                      date-picker
                                      type="text"
                                      id="pickup_date"
                                      placeholder="วันที่นัดรับเครื่องมือ"
                                      [max-date]="undefined"
                                      class="form-control"
                                      [date]="form.value.pickup_date"
                                      formControlName="pickup_date"
                                      />
                                      <div
                                      class="input-group-append"
                                      for="pickup_date"
                                      >
                                      <span class="input-group-text"
                                          ><i class="feather icon-calendar"></i
                                      ></span>
                                      </div>
                                  </div>
                                  </form-group>

                                </div>

                                <div class="col-lg-3 col-md-12">
                                  <form-group
                                  [control]="form.controls.return_date"
                                  [submitted]="submitted">
                                  <label for="return_date">
                                    กำหนดวันส่งเครื่องคืน 
                                  </label>
                                  <div class="input-group mt-0">
                                      <input
                                      date-picker
                                      type="text"
                                      id="return_date"
                                      placeholder="กำหนดวันส่งเครื่องคืน"
                                      [max-date]="undefined"
                                      class="form-control"
                                      [date]="form.value.return_date"
                                      formControlName="return_date"
                                      />
                                      <div
                                      class="input-group-append"
                                      for="pickup_date"
                                      >
                                      <span class="input-group-text"
                                          ><i class="feather icon-calendar"></i
                                      ></span>
                                      </div>
                                  </div>
                                  </form-group>

                                </div>

                                <div class="col-lg-6 col-md-12">

                                    <form-group
                                    [control]="form.controls.customer_contact_ids"
                                    [submitted]="submitted">
                                    <label for="customer_contact_ids">
                                      ผู้ติดต่อ
                                    </label>
                                    <ng-select2
                                      id="customer_contact_ids"
                                      [data]="customerContactList"
                                      class="w-100"
                                      placeholder="ผู้ติดต่อ"
                                      [options]="{
                                        templateSelection: _ngSelect2Customer?.templateSelection,
                                        multiple: true
                                      }"
                                      formControlName="customer_contact_ids">
                                    </ng-select2>
                                    </form-group>
 
                                </div>
                        </div>

                        <div class="row">
                          <div class="col-lg-6 col-md-12">
                            <form-group
                              [control]="form.controls.extra_message"
                              [submitted]="submitted"
                                >
                                  <label for="extra_message">
                                    ข้อความพิเศษ
                                  </label>
                                  <input
                                    type="text"
                                    id="extra_message"
                                    class="form-control"
                                    placeholder="ข้อความพิเศษ"
                                    formControlName="extra_message"
                                    />
                              </form-group>
                          </div>
                        </div>
                    </div>

                    <div class="mt-1">
                        <app-transport-receive-item *ngIf="customer_info" [customer_info]="customer_info" [transportreceive]="transportreceive">
                        </app-transport-receive-item>
                    </div>
                      
                    <div class="form-body">
                      <div class="row">
                        <div class="col-lg-6 col-md-12">

                          <form-group
                          [control]="form.controls.accessory"
                          [submitted]="submitted">
                          <label for="doc_date">
                            Special condition
                          </label>
                          <textarea
                            placeholder=" Special condition"
                            class="form-control"
                            id="note"
                            rows="3"
                            formControlName="note"
                            >
                          </textarea>
                          </form-group>
                        </div>

                      </div>
                      
                      <div class="row">
                        <div class="col-lg-6 col-md-12">

                          <form-group
                          [control]="form.controls.accessory"
                          [submitted]="submitted">
                          <label for="doc_date">
                            Accessory
                          </label>
                          <textarea
                            placeholder="Accessory"
                            class="form-control"
                            id="accessory"
                            rows="3"
                            formControlName="accessory"
                            >
                          </textarea>
                          </form-group>
                        </div>

                      </div>
                    </div>
                  </div>
              </form>
          </div>
      </div>
      </div>

      <div class="card" *ngIf="!pageLoad && transportreceive.customer_signature">
        <div class="card-content collapse show">
            <div class="card-body">
                
                <p>ลูกค้าลงชื่อส่งมอบเครื่องมือ</p>
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="card box-shadow-0 border-lighten-3 rounded bg-transparent mb-0">
                            <div class="card-content collapse show border" style="height: 150px;">
                                <img [src]="transportreceive.customer_signature" alt="">
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
      </div>

      <div class="card" *ngIf="!pageLoad && (transportreceive.doc_status_id =='RECEIVED_SUCCESS' || transportreceive.doc_status_id =='RETURNED_SUCCESS')">
        <div class="card-content collapse show">
            <div class="card-body">
                
                <p>ลูกค้าลงชื่อรับมอบเครื่องมือ</p>
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-12">
                        <div class="card box-shadow-0 border-lighten-3 rounded bg-transparent mb-0">
                            <div class="card-content collapse show border" style="height: 150px;">
                                <img [src]="transportreceive.returned_customer_signature" alt="">
                            </div>
                        </div>
                    </div>
                </div>
    
            </div>
        </div>
      </div>

      <app-transport-receive-memo *ngIf="!pageLoad" [resetMemo]="true">
      </app-transport-receive-memo>
    </div>
</div>

<app-transport-cancel 
(save)="cancel($event)">
</app-transport-cancel>
