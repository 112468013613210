import axios from "axios";
import { ResponseStatusService } from "./repsonseStatus.service";
import { environment } from "src/environments/environment";

export default () => {
  let headers = {};
  if (localStorage.getItem("token")) {
    headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  }

  let instance = axios.create({
    baseURL: environment.apiUrl,
    timeout: 180000,
    headers: headers,
  });

  instance.interceptors.request.use(
    function (config) {
      let params = config.params;

      for (let key in config.params) {
        if (params[key] === "") delete params[key];
      }

      params = config.data;
      for (let key in config.data) {
        if (params[key] === null) delete params[key];
      }
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  instance.interceptors.response.use(
    function (response) {
      response.data.success = new ResponseStatusService().getResponseStatus(
        response.data.resultCode
      );
      return response;
    },
    function (error) {
      if(window.location.href.includes('/customer/create')  || (window.location.href.includes('/customer') && window.location.href.includes('/show'))){
      
      }else{
        if (error?.response?.status === 403) {
          localStorage.removeItem("token");
          window.location.href="/login";
        }
      }
      error.response.data.error = new ResponseStatusService().getResponseStatus(
        error.response.data.resultCode
      );
      return Promise.reject(error);
    }
  );

  return instance;
};
