import { State, Action, StateContext, Selector } from "@ngxs/store";

import { GetCustomer, SetCustomer, UpdateCustomer } from "./customer.action";
import { CustomerInfoService } from "src/app/services";
import { Injectable } from "@angular/core";

export class CustomerStateModel {
  customers: any[];
  customer: any;
}

@State<CustomerStateModel>({
  name: "customers",
  defaults: {
    customers: [],
    customer: null,
  },
})
@Injectable({ providedIn: "root" })
export class CustomerState {
  constructor(private CustomerInfoService: CustomerInfoService) {}
  @Selector()
  static getCustomerList(state: CustomerStateModel) {
    return state.customers;
  }

  @Selector()
  static getCustomer(state: CustomerStateModel) {
    return state.customer;
  }

  @Action(GetCustomer)
  getCustomer(
    { getState, setState }: StateContext<CustomerStateModel>,
    { payload }
  ) {
    return this.CustomerInfoService.getById({
      customer_id: payload.customer_id,
    }).then((result) => {
      const state = getState();
      let customer = result.resultData;
      customer.is_free = customer.customer_state_id === "FREE";
      setState({
        ...state,
        customer: customer,
      });
    });
  }

  @Action(SetCustomer)
  setCustomer(
    { getState, setState }: StateContext<CustomerStateModel>,
    { payload }
  ) {
    const state = getState();
    setState({
      ...state,
      customer: payload,
    });
  }
}
