<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">Pre Booking</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/transport/pre-booking']">Transport System</a>
                </li>
                <li class="breadcrumb-item active">Pre Booking</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button class="btn btn-outline-primary"  (click)="createPreBooking()">
              <i class="feather icon-plus-circle icon-left"></i> สร้าง Booking
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status *ngIf="status" [status]="status"></app-response-status>
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
                <div class="card-header">
                    <div class="row">
                      <h4 class="card-title mt-1 col-12 col-sm-4 col-md-6 col-lg-8">Pre Booking</h4>
                      <span class="col-12 col-sm-8 col-md-6 col-lg-4 text-right">
                        <div class="btn-group">
                          <ng-select2
                            *ngIf="select2Options"
                            [options]="select2Options"
                            allowClear="true"
                            placeholder="ค้นหาชื่อบริษัท"
                            (valueChanged)="searchAll($event)"
                          >
                          </ng-select2>
                          <a
                            class="btn btn-primary d-flex justify-content-center align-items-center text-white"
                            (click)="search(filter)"
                          >
                            <i class="fa fa-search icon-left"></i> ค้นหา
                          </a>
                        </div>
                      </span>
                    </div>
                </div>

                <div class="card-content collapse show">
                    <div class="card-body">
                        <loading *ngIf="isLoading"></loading>
                        <div class="table-responsive" *ngIf="!isLoading">
                            <table class="table table-striped table-bordered mb-5">
                                <thead>
                                    <tr>
                                        <th>Action</th>
                                        <th class="text-center">สถานะ</th>
                                        <th class="text-center">ประเภทงาน</th>
                                        <th>ชื่อหน่วยงาน / บริษัท</th>
                                        <th>สถานที่</th>
                                        <th>
                                            วันที่รับ - ส่งงาน

                                            <br>
                                            <div class="dropdownmenu">
                    
                                              <button (click)="btnToggleDate()" class="btn btn-primary btn-sm">
                                                วันที่รับ - ส่งงาน <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                              </button>
                                                
                                              <div id="booking_date_filter" class="dropdownmenu-content rounded-right rounded-bottom">
                                                <form class="px-2 pt-1" [formGroup]="form">
                                                  <div class="row">
                                                    <div class="col-8">
                                                      <label for="booking_date">           วันที่รับ - ส่งงาน</label>
                                                    </div>
                                                    <div class="col-4 text-right">
                                                      <i class="fa fa-times pointer text-secondary" (click)="btnToggleDate()"></i>
                                                    </div>
                                                  </div>
                                                  <div class="form-group">
                                                    <div class="input-group ">
                                                      <input
                                                          date-range-picker
                                                          type="text"
                                                          [singleDatePicker]="true"
                                                          [timePicker]="false"
                                                          [date]="form.value?.booking_date"
                                                          [format]="'DD-MM-YYYY'"
                                                          placeholder="วันที่รับ - ส่งงาน"
                                                          id="booking_date"
                                                          #booking_date
                                                          class="form-control"
                                                          formControlName="booking_date"
                                                      />
                                                      <div class="input-group-append pointer" (click)="booking_date.click()">
                                                        <span class="input-group-text rounded-0 btn-primary text-white"
                                                        ><i class="feather icon-calendar"></i
                                                        ></span>
                                                      </div>
                                                      <span class="input-group-text custom-rouded-right rounded-0 border-left-0 btn-primary text-white" (click)="clearDate()"
                                                      ><i class="fa fa-times"></i>
                                                      </span>
                                                    </div>
                                                  </div>
                                                </form>
                                                
                                              </div>
                                            </div>
                                        </th>
                                        <th>เวลา</th>
                                        <th>ข้อความพิเศษ</th>
                                        <th>TR</th>
                                        <th>WR</th>
                                        <th>QT</th>
                                        <th>ประเภทรถ</th>
                                        <th>ชื่อ - เบอร์ผู้ติดต่อ</th>
                                        <th>ชื่อผู้จองงาน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let booking of bookings;">
                                      <td class="text-nowrap">
                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="
                                          ((userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                            'TRAN_DEP-01'
                                          ) || 
                                          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                            'TRAN_DEP-02'
                                          ))
                                          && 
                                          ( booking?.doc_status_id == 'WAITING_FOR_APPROVE')">
                                            <div> <span class="text-warning pointer" data-toggle="modal"
                                              data-target="#modalPreBookingEdit" (click)="select(booking)"><i class="fa fa-pencil icon-left"></i> แก้ไข</span>
                                            </div>    
                                          </ng-container>
                                          <ng-container *ngSwitchCase="
                                          ((userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                            'TRAN_DEP-01'
                                          ) || 
                                          (userInfo$ | async)?.emp_info?.emp_position_id.startsWith(
                                            'TRAN_DEP-02'
                                          ))
                                          && booking?.doc_status_id == 'WAITING_FOR_APPROVE'" class="text-danger">
                                            <div>
                                              <span class="text-primary pointer" data-toggle="modal"
                                              data-target="#modalPreBookingEdit" (click)="verifyBooking(booking)"><i class="fa fa-gavel icon-left" aria-hidden="true"></i> ตรวจสอบ</span>
                                            </div>
                                          </ng-container>
                                          <ng-container *ngSwitchDefault class="text-warning">
                                            -
                                          </ng-container>
                                        </ng-container>
                                      </td>
                                      <td class="text-center text-nowrap">

                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="booking?.doc_status_id == 'CONFIRMED'">
                                            <span class="text-success">สามารถให้บริการได้</span>
                                            </ng-container>
                                          <ng-container *ngSwitchCase="booking?.doc_status_id == 'REJECTED'" class="text-danger">
                                            <span class="text-danger">ไม่สามารถให้บริการ</span>
                                            </ng-container>
                                          <ng-container *ngSwitchDefault class="text-warning">
                                            <span class="text-warning">รอตรวจสอบ</span>
                                            </ng-container>
                                        </ng-container>
                                      </td>
                                      <td class="text-center pointer">
                                        <ng-container [ngSwitch]="true">
                                          <ng-container *ngSwitchCase="booking.booking_type === 'SEND'">ส่ง</ng-container>
                                          <ng-container *ngSwitchDefault>รับ</ng-container>
                                        </ng-container>
                                      </td>
                                      <td>
                                        {{booking?.customer_info?.company_name
                                        }}
                                      </td>
                                      <td>
                                        {{booking?.location
                                        }}
                                      </td>
                                      <td>
                                        {{booking?.booking_date  | thai_date: "short":"date" | dash
                                        }}
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.booking_time 
                                        }}
                                      </td>
                                      <td>
                                        {{booking?.extra_message 
                                        }}
                                      </td>
                                      <td>
                                        {{booking?.transport_receive_info_doc_no 
                                        }}
                                      </td>
                                      <td>
                                        {{booking?.job_order_info_doc_no 
                                        }}
                                      </td>
                                      <td>
                                        {{booking?.quotation_info_doc_no 
                                        }}
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.transport_type_info?.transport_type_name_th 
                                        }}
                                        
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.contact_name 
                                        }} -   {{booking?.contact_phonenumber 
                                        }}
                                      </td>
                                      <td class="text-nowrap">
                                        {{booking?.created_by_info?.firstname_th 
                                        }}
                                        
                                        {{booking?.created_by_info?.lastname_th 
                                        }}
                                        
                                      </td>
                                    </tr>
                                    <tr *ngIf="bookings.length === 0">
                                        <td colspan="14" class="text-center">ไม่มีข้อมูล</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
    
                        <app-paginator
                        *ngIf="initialPage"
                        [filter]="filter"
                        [reset]="reset"
                        [fetch]="load"
                        [initialPage]="initialPage"
                        (filterLimit)="getFilterLimit($event)"
                        (changePage)="onChangePage($event)">
                      </app-paginator>
                    </div>
                </div>
            </div>
          </div>
        </section>
      </div>
    </div>
</div>

<app-pre-booking-create (save)="saveBooking($event)" [transportTypes]="transportTypes" *ngIf="createBooking">
</app-pre-booking-create>

<app-pre-booking-edit (save)="saveBooking($event)" [verify]="verify" [transportTypes]="transportTypes" *ngIf="booking" [booking]="booking">
</app-pre-booking-edit>

