<!-- Create Sales Team Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="edit-sales-team"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">แก้ไขทีมขาย</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form" [formGroup]="form">
          <div class="row justify-content-md-center">
            <div class="col-md-12">
              <div class="form-body">
                <form-group
                  [control]="form.controls.sales_team_name"
                  [submitted]="submitted"
                >
                  <label for="sales_team_name">
                    ชื่อทีม
                  </label>
                  <input
                    type="text"
                    id="sales_team_name"
                    class="form-control"
                    placeholder="ชื่อทีม"
                    formControlName="sales_team_name"
                  />
                </form-group>

                <form-group
                  [control]="form.controls.team_leader_id"
                  [submitted]="submitted"
                >
                  <label for="team_leader_id">หัวหน้าทีมขาย</label>
                  <select
                    class="form-control"
                    id="team_leader_id"
                    formControlName="team_leader_id"
                  >
                    <option value="">โปรดเลือก...</option>
                    <option
                      *ngFor="let emp of employees"
                      [value]="emp.user_info.user_id"
                      >{{ emp.fullname }}</option
                    >
                  </select>
                </form-group>

                <form-group
                  [control]="form.controls.team_cosale_id"
                  [submitted]="submitted"
                >
                  <label for="team_cosale_id">Co-sale</label>
                  <select
                    class="form-control"
                    id="team_cosale_id"
                    formControlName="team_cosale_id"
                  >
                    <option value="">โปรดเลือก...</option>
                    <option
                      *ngFor="let emp of coSales"
                      [value]="emp.user_info.user_id"
                      >{{ emp.fullname }}</option
                    >
                  </select>
                </form-group>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="onSubmit()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
