import { Component, OnInit } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import { Select } from "@ngxs/store";
import { UserInfoState } from "src/app/store/user/user.state";
import { Observable } from "rxjs";
declare const $;

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.css"],
})
export class SidebarComponent implements OnInit {
  constructor(private router: Router) {}
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  path = null;
  openProductMenu = false;
  openTransportMenu = false;
  openBookingMenu = false;
  openAccMenu = false;
  ngOnInit(): void {
    this.path = window.location.pathname;
    // console.log(this.path);

    this.openProductMenu =
      this.path.startsWith("/product") ||
      this.path.startsWith("/scope") ||
      this.path.startsWith("/unit");

    this.openTransportMenu =
      this.path.startsWith("/transport-receive") ||
      this.path.startsWith("/waiting-for-delivery") ||
      this.path.startsWith("/bill-cheque") ||
      this.path.startsWith("/transport") && !this.path.includes("booking")

    this.openBookingMenu =
      this.path.includes("booking")

    this.openAccMenu =
    this.path.startsWith("/invoice") ||
    this.path.startsWith("/waiting-for-charge") ||
    this.path.startsWith("/tax-invoice") ||
    this.path.startsWith("/payment") ||
    this.path.startsWith("/cheque") ||
    this.path.startsWith("/billing-note") ||
    this.path.startsWith("/credit-note") ||
    this.path.startsWith("/debit-note") ||
    this.path.startsWith("/bill-cheque") ||
    this.path.startsWith("/received-voucher");

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.path = event.url;
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      $.app.menu.init();
      $.app.nav.init();
    });
  }

  changeRoute(url) {
    this.router.navigateByUrl("/dummy?url=" + encodeURIComponent(url), {
      skipLocationChange: true,
    });
  }

  transportMenu(){
    
  }
}
