import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { EmployeeService } from 'src/app/services';

@Component({
  selector: 'app-transport-receive-unlock',
  templateUrl: './transport-receive-unlock.component.html',
  styleUrls: ['./transport-receive-unlock.component.css']
})
export class TransportreceiveUnlockComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  managerList = []
  constructor(   
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private EmployeeService: EmployeeService,
    private router: Router
    ) { 
      this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.closeModalBtn.nativeElement.click();
        }
      });
      this.setForm();
  }
  async ngOnInit() {
    await this.EmployeeService.load(null, {emp_level_id:'MANAGER'}).then(res=>{
      this.managerList = res.resultData;
      this.managerList = this.managerList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info.firstname_th} ${elem.user_info.lastname_th}`
        }
      });
    });
  }

  setForm(){
    this.form = this.formBuilder.group({
      user_id: ["", [Validators.required]],
      secure_code: ["", [Validators.required]]
    });
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.done.emit(this.form.value);
    this.closeModalBtn.nativeElement.click();
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }
}
