import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-work-order-lab-verify',
  templateUrl: './work-order-lab-verify.component.html',
  styleUrls: ['./work-order-lab-verify.component.css']
})
export class WorkOrderLabVerifyComponent implements OnInit {
  public form: FormGroup
  public submitted:boolean = false;

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeConfirmBtn") closeConfirmBtn: ElementRef;
  constructor(
    private fbd: FormBuilder,
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm(){
    this.form = this.fbd.group({
      cert_verify_note: ["", [Validators.required]]
    });
  }

  save(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.done.emit(this.form.value);
    this.closeConfirmBtn.nativeElement.click();
  }
  close(){
    this.submitted = false;
    this.createForm();
  }
}
