import Axios from "./axios";
import { Injectable } from "@angular/core";

export enum EMAIL_TEMPLATE {
  QUOTATION = "quotation",
}
export interface EmailTemplateAttachmentBase {
  email_template_attachment_id: number;
  template: EMAIL_TEMPLATE;
  order: number;

  description: string;
  file_path: string;
  file_name: string;
  asset_path: string;
}
export interface EmailTemplateAttachmentData
  extends EmailTemplateAttachmentBase {
  created_at: string;
  updated_at: string;
  deleted_at: string;
}
export type EmailTemplateAttachmentEdit = EmailTemplateAttachmentBase;

export type EmailTemplateAttachmentCreate = Omit<
  EmailTemplateAttachmentBase,
  "email_template_attachment_id"
>;

export interface EmailTemplateAttachmentFilter {
  description?: string;
}

@Injectable({ providedIn: "root" })
export class EmailTemplateAttachmentService {
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/email-template-attachment`, {
        params: {
          offset: paging?.offset,
          limit: paging?.limit,
          ...filter,
          order_by: "order:asc",
        },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async create(data: EmailTemplateAttachmentCreate) {
    let result = null;
    try {
      result = await Axios().post(`/email-template-attachment`, data);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async delete({ email_template_attachment_id }) {
    let result = null;
    try {
      result = await Axios().delete(
        `/email-template-attachment/${email_template_attachment_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async update(
    updateEmailTemplateAttachment: EmailTemplateAttachmentEdit
  ): Promise<any> {
    let result = null;
    const id = updateEmailTemplateAttachment.email_template_attachment_id;
    delete updateEmailTemplateAttachment["email_template_attachment_id"];
    try {
      result = await Axios().put(
        `/email-template-attachment/${id}`,
        updateEmailTemplateAttachment
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async reorder() {}
}
