<div id="customer-activity-show" sidebar (close)="close($event)">
  <div
    trigger-sidebar
    data-target="#customer-activity-show"
    class="w-100 h-100 position-fixed"
    style="
      left: 0;
      top: 0;
      background-color: black;
      opacity: 0.3;
      display: none;
      z-index: 1;
    "
  ></div>
  <div class="custom-sidebar">
    <div class="card shadow-none p-0 m-0">
      <div class="card-header border-bottom py-1">
        <div
          class="task-header d-flex justify-content-between align-items-center"
        >
          <h5 class="new-task-title mb-0">กิจกรรม</h5>
          <span>
            <button
              type="button"
              class="close close-icon"
              trigger-sidebar
              data-target="#customer-activity-show"
              (close)="close($event)"
              #closeSidebar
            >
              <i class="feather icon-x align-middle"></i>
            </button>
          </span>
        </div>
      </div>
      <!-- form start -->
      <form id="compose-form" class="mt-1" [formGroup]="form">
        <div class="card-content">
          <div class="card-body py-0">
            <form-group [control]="form.controls.company_name" [submitted]="submitted">
              <label for="company_name"> ชื่อบริษัท </label>
              <input
                type="company_name"
                id="title"
                class="form-control"
                placeholder="ชื่อบริษัท"
                formControlName="company_name"
              />
            </form-group>

            <form-group
              [control]="form.controls.activity_datetime"
              [submitted]="submitted"
            >
              <label for="activity_datetime">
                วันที่ - เวลา
              </label>
              <div class="input-group mt-0">
                <input
                  date-time-picker
                  type="text"
                  id="activity_datetime"
                  class="form-control"
                  formControlName="activity_datetime"
                />
                <div class="input-group-append">
                  <span class="input-group-text"
                    ><i class="feather icon-calendar"></i
                  ></span>
                </div>
              </div>
            </form-group>

            <form-group
              [control]="form.controls.customer_activity_type_id"
              [submitted]="submitted"
            >
              <label for="customer_activity_type_id">
                ประเภท
              </label>
              <select
                class="form-control"
                id="customer_activity_type_id"
                formControlName="customer_activity_type_id"
              >
                <option value="">กรุณาเลือกข้อมูล</option>
                <option
                  [value]="activityType.customer_activity_type_id"
                  *ngFor="let activityType of activityTypes"
                >
                  {{ activityType.activity_type_name_th }}</option
                >
              </select>
            </form-group>

            <form-group [control]="form.controls.title" [submitted]="submitted">
              <label for="title"> หัวข้อ </label>
              <input
                type="text"
                id="title"
                class="form-control"
                placeholder="หัวข้อ"
                formControlName="title"
              />
            </form-group>
          </div>

          <div class="card-body pb-1">
            <form-group
              [control]="form.controls.description"
              [submitted]="submitted"
            >
              <label for="description">
                รายละเอียด
              </label>
              <div
                class="ql-container ql-snow quill-custom-height pl-1 pt-1 overflow-auto"
                [innerHTML]="form.controls.description.value"
                *ngIf="form.disabled"
                disabled
              ></div>
            </form-group>
          </div>

          <div class="card-body pb-1">
            <div class="mb-2">
              <div class="card-title d-inline mr-2">รายการไฟล์</div>
            </div>

            <!-- <label
              for="customer_activities_file_id"
              class="btn btn-outline-primary btn-min-width mr-1 mb-1 d-inline"
            >
              <i class="feather icon-upload icon-left"></i> Upload
            </label>
            <input
              type="file"
              class="form-control-file d-none"
              id="customer_activities_file_id"
              (change)="uploadFile($event)"
              multiple
            /> -->
            <app-file-list
              [files]="customer_activity?.customer_activities_file_info"
              [canRemove]="false"
            ></app-file-list>

            <div
              *ngIf="!customer_activity?.customer_activities_file_info.length"
            >
              <div class="text-dark">
                ไม่มีไฟล์
              </div>
            </div>
          </div>

          <div
            class="card-body pb-1"
            *ngIf="form.value.customer_activity_type_id === 'VISIT'"
          >
            <div class="card-title">ตำแหน่ง</div>
            <!-- Map -->
            <div class="google-map">
              <input
                type="text"
                class="map-autocomplete-box form-control mb-1"
                [class]="form.disabled ? 'd-none' : ''"
              />
              <div
                google-map
                [default-location]="{
                  lat: form.value.location_lat,
                  lng: form.value.location_lng
                }"
                id="activity-map-edit"
                (change)="setLocation($event)"
              ></div>
            </div>
          </div>

          <!-- <div class="card-body pb-2">
            <div class="mt-1 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-warning mr-1"
                trigger-sidebar
                data-target="#customer-activity-show"
              >
                <i class="fa fa-times"></i> ยกเลิก
              </button>
            </div>
          </div> -->
        </div>
      </form>
      <!-- form start end-->
    </div>
  </div>
</div>
