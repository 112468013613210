import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { 
  UtilsService, 
  TransportMasterBookingService, 
  EmployeeService, 
  CustomerInfoService,
  GETService
} from 'src/app/services';
import { UserInfoState } from 'src/app/store/user/user.state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-master-booking-list',
  templateUrl: './master-booking-list.component.html'
})
export class MasterBookingListComponent implements OnInit {
  form : FormGroup;
  bookings: Array<any> = [];
  status: string;
  reset: boolean = false;
  isLoading: boolean = true;
  isLoadFilter: boolean = true;
  verify: boolean = false;
  load: Function = this.TransportMasterBookingService.load;
  filter = {};
  params: any;
  booking: any = null;
  select2Options: any = null;
  settingLanguage = {
    errorLoading: function () {
      return "ไม่สามารถค้นข้อมูลได้";
    },
    loadingMore: function () {
      return "กำลังค้นข้อมูลเพิ่ม…";
    },
    noResults: function () {
      return "ไม่พบข้อมูล";
    },
    searching: function () {
      return "กำลังค้นข้อมูล…";
    }
  } 

  transportStatus = [
    {
      id: "all",
      text: "ทั้งหมด"
    },
    {
      id: "INPROGRESS",
      text: "ระหว่างดำเนินการ"
    },
    {
      id: "COMPLETED",
      text: "สำเร็จ"
    },
    {
      id: "CANCELED",
      text: "ยกเลิก"
    },
    {
      id: "RESCHEDULED",
      text: "ทำนัดหมายใหม่"
    }
  ];
  transportTypes = [];
  customers = [];
  transportContactList = [];

  initialPage: number = 1;
  @ViewChild("booking_date") booking_date: ElementRef;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  
  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private route: ActivatedRoute,
    private GETService: GETService,
    private CustomerInfoService: CustomerInfoService,
    private EmployeeService: EmployeeService,
    private TransportMasterBookingService: TransportMasterBookingService,

    public _UtilsService: UtilsService
  ) {
    this.routeEvent(this.router.getCurrentNavigation().extras.state);

    this.route.queryParams
    .subscribe(params => {
      if(params){

        let page = parseInt(params.page);
        
        this.params = params;
        this.params = {
          ...this.params,
        }
        this.filter = {
          ...params,
          offset: page == 1 || isNaN(page)? 0: ((page-1)* 10),
          page: this._UtilsService.setUndefined(),
        };

        this.initialPage = isNaN(page)? 1: page;
      }
    }
    );
  }

   routeEvent(state): void {
    this.status = state?.status;
  }
  

  async ngOnInit() {
    this.form = this.fbd.group({
      customer_id: [""],
      booking_date: [""],
      doc_status_id: ["all"],
      messenger_id: [""],
      location: [""]
    });

    await this.EmployeeService.load(null, { emp_department_id: 'TRAN_DEP' })
    .then(res=>{
      this.transportContactList = res.resultData || {}
      this.transportContactList = this.transportContactList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info.firstname_th} ${elem.user_info.lastname_th}`,
        }
      });
    })
    await this.GETService.transportType(null)
    .then(res=>{
      this.transportTypes = res.resultData || {}
      this.transportTypes = this.transportTypes.map(function(elem) {
        return {
          id: elem.transport_type_id,
          text: `${elem.transport_type_name_th}`,
        }
      });
    })
    this.settingDropdown();

    if(this.params.customer_id){
      await this.CustomerInfoService.getById({customer_id: this.params.customer_id}).then(async res=>{

        this.customers.push(
          {
            id: this.params.customer_id,
            text: `${res.resultData?.company_name || ''} ${res.resultData?.branch?" (" + res.resultData?.branch + ")": ""}`,
          }
        )
      })
    }

    let data = {
      customer_id: this.params.customer_id? this.params.customer_id: null,
      messenger_id: this.params.messenger_id? this.params.messenger_id: null,
      booking_date:  this.params?.booking_date? this.params?.booking_date: moment(new Date()).format('YYYY-MM-DD'),
      doc_status_id:  this.params?.doc_status_id? this.params?.doc_status_id: "all",
      location: this.params?.location? this.params?.location: null
    }

    this.filter = {
      ...this.filter,
      booking_date: this.params?.booking_date? this.params?.booking_date:moment(new Date()).format('YYYY-MM-DD')
    }

    this.form.patchValue(data);

    setTimeout(() => {
      this.isLoadFilter = false;
    }, 200);
  }

  onChangePage(bookings: Array<any>) {
    this.bookings = bookings;
    this.isLoading = false;
  }

  getFilterLimit(filterLimit){

    if(this.initialPage != filterLimit.initialPage && filterLimit.initialPage != 0){
  
      let params = {
        ...this.filter,
        page: filterLimit.initialPage,
        offset: this._UtilsService.setUndefined()
      }

      this.router.navigate([], {
        queryParams: params,
        queryParamsHandling: 'merge',
      });
      this.params = params;
    }
  }

  search() {

    let formValue = this.form.getRawValue();
    this.filter = {
      ...this.filter,
      customer_id: formValue?.customer_id? formValue?.customer_id: null,
      messenger_id: formValue?.messenger_id? formValue?.messenger_id: null,
      booking_date: formValue?.booking_date? formValue?.booking_date: null,
      doc_status_id: formValue?.doc_status_id == "all"? null: formValue?.doc_status_id,
      location: formValue?.location? formValue?.location: null
    };
    this.setQueryUrl();
  }

  setQueryUrl(){
    let filter = {
      ...this.filter,
      page: this.initialPage,
      offset: this._UtilsService.setUndefined(),
    }
    this.router.navigate([], {
      queryParams: filter,
      queryParamsHandling: 'merge',
    });
    this.params = filter;
  }

  clearDate(){
    this.form.get('booking_date').setValue('');
  }

  saveBooking(e){
    if(e){
      this.doReset(e.status.success || e.status.error);
      e.closeModal.nativeElement.click();
    }
    this.booking = null;
  }

  select(booking){
    this.verify = false;
    this.booking = booking;
  }

  verifyBooking(booking){
    this.verify = true;
    this.booking = booking;
  }

  doReset(status?) {
    this.reset = false;
    this.status = null;
    setTimeout(() => {
      this.status = status;
      this.reset = true;
    }, 50);
  }

  btnToggleDate(){
    document.getElementById("booking_date_filter").classList.toggle("show");
  }

  settingDropdown(){
    this.select2Options = {
      language: this.settingLanguage,
      ajax: {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
        delay: 500,
        url: environment.apiUrl + "/customer-info?limit=10&&order_by=customer_code:desc",
        dataType: "json",
        data: (params) => {
          let query = null
          query= {
            search_all: params.term,
            isSearchAllCustomer: true
          };
          return query;
        },
        processResults: function (data) {
          let items = data.resultData.map((elem) => {
          return {
                id: elem.customer_id,
                text: `${elem.company_name} ${elem.branch?" (" + elem.branch + ")": ""}`
              }
          });
          return {
            results: items,
          };
        },
      },
    };
  }

  getTemplate(doc_no){
    return doc_no? doc_no.split("-")[2]: "";
  }

  getMergeDocNo(booking){
    let text = "";
    if(booking.quotation_info_doc_no){
      text = text + booking.quotation_info_doc_no;
    }
    if(booking?.transport_receive_info_doc_no){
      if(text != "") text = text + ", ";
      text = text +booking.transport_receive_info_doc_no;
    }
    if(booking?.job_order_info_doc_no){
      if(text != "") text = text + ", ";
      text = text +booking.job_order_info_doc_no;
    }
    return text;
  }


  async exportExcel(){
    let name = `Master_Report_${moment().format("YYYYMMDDhhmm")}.xlsx`;

    let filter = {
      ...this.filter,
      offset: undefined,
      limit: undefined
    }

    this.GETService.masterBookingExcel(filter).then(res=>{
      let url = window.URL.createObjectURL(res.data);
      let a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url;
      a.download = name;
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    });
  }
}
