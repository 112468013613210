<!-- Create Product Range Price Modal -->
<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="create-product-range-price"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">
          เพิ่ม Range และ ราคา
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
          <div class="form-body">
            <div class="row">
              <label class="col-md-3 label-control" for="service_location"
              ><h6 class="text-bold-600 mb-1">
                Service <span class="danger">*</span>
              </h6></label
            >
              <div class="col-md-9">
                <form-group
                  [control]="form.controls.service_location"
                  [submitted]="submitted"
                >
                  <div class="input-group">
                    <div
                      class="d-inline-block custom-control custom-radio mr-1"
                    >
                      <input
                        type="radio"
                        value="LAB"
                        formControlName="service_location"
                        class="custom-control-input"
                        id="lab_create"
                      />
                      <label class="custom-control-label" for="lab_create"
                        >Lab</label
                      >
                    </div>
                    <div
                      class="d-inline-block custom-control custom-radio mr-1"
                    >
                      <input
                        type="radio"
                        value="ONSITE"
                        formControlName="service_location"
                        class="custom-control-input"
                        id="onsite_create"
                      />
                      <label class="custom-control-label" for="onsite_create"
                        >Onsite</label
                      >
                    </div>
                  </div>
                </form-group>
              </div>
            </div>
            
            <div class="row">
              <label class="col-md-3 label-control" for="mit_method_id">
                <h6 class="text-bold-600 mb-1">
                  Method <span class="danger">*</span>
                </h6>
              </label>
              <div class="col-md-8">
                <form-group
                  [submitted]="submitted"
                  [control]="form.controls.mit_method_id"
                >
                  <select
                    class="form-control"
                    id="mit_method_id"
                    formControlName="mit_method_id"
                  >
                    <option value="">โปรดเลือก...</option>
                    <option
                      *ngFor="let method of methods"
                      [value]="method.mit_method_id"
                    >
                      {{ method.method_name }}
                    </option>
                  </select>
                </form-group>
              </div>
            </div>

            <h6 class="text-bold-600 mb-1">
              Range <span class="danger">*</span>
            </h6>
            <div class="form-group row">
              <label class="col-md-3 label-control" for="start_fee"
                >Min-Max :</label
              >
              <div class="col-md-9">
                <div class="d-flex flex-row">
                  <form-group
                    
                    [displayError]="false"
                    [control]="form.controls.min_range"
                    [submitted]="submitted"
                    style="width: 80px;"
                  >
                    <input
                      (change)="validateNumber('min_range')"
                      type="text"
                      id="min_range"
                      class="form-control"
                      placeholder="0"
                      formControlName="min_range"
                    />
                  </form-group>
                  <div class="fit-content">
                    <span class="input-group-text bg-primary white">To</span>
                  </div>
                  <form-group
                    [displayError]="false"
                    [control]="form.controls.max_range"
                    [submitted]="submitted"
                    style="width: 80px;"
                  >
                    <input
                      (change)="validateNumber('max_range')"
                      type="text"
                      id="max_range"
                      class="form-control"
                      placeholder="0"
                      formControlName="max_range"
                    />
                  </form-group>
                  <form-group
                    [displayError]="false"
                    [control]="form.controls.unit_id"
                    [submitted]="submitted"
                  >
                    <select
                      class="form-control"
                      style="width: 100px;"
                      id="unit_id"
                      formControlName="unit_id"
                    >
                      <option value="">โปรดเลือก...</option>
                      <optgroup
                        [label]="unit.unit_type_info.unit_type_name_en"
                        *ngFor="let unit of units"
                      >
                        <option
                          [value]="unitInfo.unit_id"
                          *ngFor="let unitInfo of unit.units"
                          >{{ unitInfo.unit_name_en }} (<span
                            [innerHTML]="unitInfo.unit_short_name_en"
                          ></span
                          >)</option
                        >
                      </optgroup>
                    </select>
                  </form-group>
                </div>

                <show-errors
                  [control]="[
                    form.controls.min_range,
                    form.controls.max_range,
                    form.controls.unit_id
                  ]"
                  [submitted]="submitted"
                >
                </show-errors>
                <div *ngIf="submitted && form.errors?.lessThan">
                  <ul role="alert" class="pl-2 pt-0">
                    <li class="text-danger">
                      Min range ต้องน้อยกว่าหรือเท่ากับ Max range
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <h6 class="text-bold-600 mb-1">
              Calibration fee <span class="danger">*</span>
            </h6>
            <div class="form-group row">
              <label class="col-md-3 label-control" for="start_fee"
                >Start :</label
              >
              <div class="col-md-6">
                <form-group
                  [control]="form.controls.start_fee"
                  [submitted]="submitted"
                >
                  <input
                    currency
                    type="text"
                    id="start_fee"
                    class="form-control placeholder-right"
                    placeholder="0.00"
                    formControlName="start_fee"
                  />
                </form-group>
              </div>
              <div class="col-md-3">Baht</div>
            </div>

            <div class="form-group row">
              <label class="col-md-3 label-control" for="per_point_fee"
                >Per Point :</label
              >
              <div class="col-md-6">
                <form-group
                  [control]="form.controls.per_point_fee"
                  [submitted]="submitted"
                >
                  <input
                    currency
                    type="text"
                    id="per_point_fee"
                    class="form-control placeholder-right"
                    placeholder="0.00"
                    formControlName="per_point_fee"
                  />
                </form-group>
              </div>
              <div class="col-md-3">Baht</div>
            </div>

            <h6 class="text-bold-600 mb-1">
              Condition <span class="danger">*</span>
            </h6>
            <div class="form-group row">
              <label class="col-md-3 label-control" for="min_point"
                >Min Point :</label
              >
              <div class="col-md-6">
                <form-group
                  [control]="form.controls.min_point"
                  [submitted]="submitted"
                >
                  <input
                    type="number"
                    min="0"
                    step="1"
                    id="min_point"
                    class="form-control placeholder-right"
                    placeholder="0"
                    formControlName="min_point"
                  />
                </form-group>
              </div>
              <div class="col-md-3">Point</div>
            </div>

            <!-- <div class="form-group row" *ngIf="isReady">
              <label for="cp" class="col-md-3 label-control">CP :</label>
              <div class="col-md-8">
                <form-group
                  [control]="form.controls.mit_cp_ids"
                  [submitted]="submitted"

                >
                  <select
                    dropdown-select
                    data-style="dropdown-select"
                    data-live-search="true"
                    class="form-control"
                    formControlName="mit_cp_ids"
                    multiple
                  >
                    <option *ngFor="let cp of cps" [value]="cp.mit_cp_id"
                      >{{ cp.cp_name }}
                    </option>
                  </select>
                </form-group>
              </div>
            </div> -->

            <div class="form-group row">
              <label class="col-md-3 label-control" for="isISO">ISO :</label>
              <div class="col-md-9">
                <form-group
                  [control]="form.controls.isISO"
                  [submitted]="submitted"
                >
                  <div class="input-group">
                    <div
                      class="d-inline-block custom-control custom-radio mr-1"
                    >
                      <input
                        type="radio"
                        value="Y"
                        formControlName="isISO"
                        class="custom-control-input"
                        id="yes"
                      />
                      <label class="custom-control-label" for="yes">Yes</label>
                    </div>
                    <div
                      class="d-inline-block custom-control custom-radio mr-1"
                    >
                      <input
                        type="radio"
                        value="N"
                        formControlName="isISO"
                        class="custom-control-input"
                        id="no"
                      />
                      <label class="custom-control-label" for="no">No</label>
                    </div>
                  </div>
                </form-group>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
