import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import moment from 'moment';
import { Observable } from 'rxjs';
import { GETService } from 'src/app/services';
import { AccInvoiceItemService, AccPaymentService } from 'src/app/services/invoice';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import { UserInfoState } from 'src/app/store/user/user.state';

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.css']
})
export class InvoicePaymentComponent implements OnInit {
  pageLoad: boolean = true;
  status:string;
  acc_invoice_info_id: string;

  @Input('invoice_info') invoice_info: any;
  @Output() done = new EventEmitter<any>();
  paymentList = [];
  invoiceItems = [];
  payments = [];
  payment = null;
  loadPayment = true

  sum_price:number = 0;
  sum_grand_total:number = 0;
  sum_value_before_wht:number = 0;
  balance:number = 0;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private GETService: GETService,
    private AccPaymentService: AccPaymentService,
    private AccInvoiceItemService: AccInvoiceItemService,
    public calculate: CalculateFunctionService,
    public util : QuotationUtilsService,
  ) { 
    this.acc_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_invoice_info_id");
  }

  async ngOnInit() {
    await this.GETService.paymentMethod(null)
    .then(res=>{
      this.paymentList = res.resultData;
    })
    if(this.acc_invoice_info_id){
      await this.AccInvoiceItemService.load(null, {acc_invoice_info_id: this.acc_invoice_info_id})
      .then(async res=>{
        this.invoiceItems = res.resultData || [];
        this.invoiceItems.map(value=>{
          if(value.item_type=='calibration_service'){
            this.sum_price = this.sum_price + this.calculate.sumMit(value);
          }else{
            this.sum_price = this.sum_price + this.calculate.sumProduct(value);
          }
        })
      })
    }
    this.sum_price = this.sum_price -  this.util.convertStingToNumber(this.invoice_info.discount);
    await this.getPayment();
    this.pageLoad = false;
  }
  
  ngOnChanges(changes: SimpleChanges) {}

  
  async getPayment(){
    this.sum_grand_total = 0;
    this.sum_value_before_wht = 0;
    this.balance = 0;
    
    await this.AccPaymentService.load(null, {acc_document_info_id: this.acc_invoice_info_id, order_by: 'payment_date:asc,created_at:asc'})
    .then(res=>{
      this.payments = res.resultData;
      this.payments.map(item=>{
        this.sum_grand_total = this.sum_grand_total + item.grand_total;
        this.sum_value_before_wht = this.sum_value_before_wht + item.value_before_wht;

      })
    });
    if(this.sum_price > this.sum_value_before_wht){
      this.balance = this.sum_price - this.sum_value_before_wht;
    }
    this.done.emit(this.payments);
  }

  async savePayment(event){
    if(event){
      this.status = event.success || event.error;
      await this.getPayment();
    }

    this.loadPayment = null;
    setTimeout(() => {
      this.loadPayment = true;
    }, 100);
  }

  select(payment){
    this.payment = payment;
  }

  resetPayment(event){
    this.payment = null;
  }

  getPaymentName(payment): string{
    let findPayment = this.paymentList.find(pay=> pay.payment_method_id == payment.payment_method_id)
    return findPayment.payment_method_name_th || "";
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.payments, event.previousIndex, event.currentIndex);
  }

  async updateReceivedPayment(payment){
    let data = {
      ...payment,
      wht_received_date: moment(new Date()).format("YYYY-MM-DD")
    }
    let response = await this.AccPaymentService.update(data);
    this.status = response.success || response.error;
    await this.getPayment();
  }

  openReceived(payment){
    this.router.navigate([`received-voucher/${this.invoice_info.customer_id}/create`],{ queryParams: { acc_payment_id: payment.acc_payment_id }});
  }

  copyInvocie(payment){
    this.router.navigate([`tax-invoice/${this.acc_invoice_info_id}/edit`],{ queryParams: { acc_payment_id: payment.acc_payment_id }});
  }
}
