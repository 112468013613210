import { Injectable } from "@angular/core";
import moment from "moment";
import { 
  UploadService 
} from 'src/app/services';
import { 
  JobOrderInfoService,
  JobOrderContactService,
  JobOrderFileService,
  JobOrderProductItemService,
  JobOrderCalibrationItemService,
  JobOrderStatusConfirmService,
  JobOrderStatusWorkService,
  JobOrderTrainingEngineerService,
  JobOrderWorkerEngineerService,
  JobOrderCarInfoService,
  JobOrderFileWorkService
} from '.';
import { 
  QuotationMitItemAttributeService, 
  QuotationMitItemDetailPointService, 
  QuotationMitItemDetailRangeService, 
  QuotationMitItemDetailService, 
  QuotationMitItemLog, 
  QuotationMitItemService, 
  QuotationProductItemService, 
  QuotationUtilsService 
} from "../quotations";
import { JobOrderCertService } from "./job-order-cert.service";

@Injectable({
   providedIn: "root"
})
export class CoreEditService {
  constructor(
    private UploadService: UploadService,
    private JobOrderInfoService: JobOrderInfoService,
    private JobOrderContactService: JobOrderContactService,
    private JobOrderFileService: JobOrderFileService,
    private JoborderProductItemService: JobOrderProductItemService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService,
    private JobOrderStatusConfirmService: JobOrderStatusConfirmService,
    private JobOrderStatusWorkService: JobOrderStatusWorkService,
    private JobOrderTrainingEngineerService: JobOrderTrainingEngineerService,
    private JobOrderWorkerEngineerService: JobOrderWorkerEngineerService,
    private JobOrderCarInfoService: JobOrderCarInfoService,
    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,
    private JobOrderFileWorkService: JobOrderFileWorkService,
    private JobOrderCertService: JobOrderCertService,
    private QuotationMitItemDetailPointService: QuotationMitItemDetailPointService,
    private QuotationMitItemDetailRangeService: QuotationMitItemDetailRangeService,
    private QuotationMitItemAttributeService: QuotationMitItemAttributeService,
    private QuotationMitItemDetailService: QuotationMitItemDetailService,
    private QuotationMitItemLog: QuotationMitItemLog,
    private utils: QuotationUtilsService
  ) {}

  async editJobOrder(
    data: any,
    files?: any,
    deleteContactList?: any,
    jobOrderProductItem?: any,
    jobOrderMitItem?: any,
    trainings?: any,
    deleteTrainingList?: any,
    workers?: any,
    deleteWorkerList?: any,
    carInfos?: any,
    deleteCarInfoList?: any
    ){
    if(data){
      let job_order_info = data;
      try {
        if(files.length > 0){
          await this.saveFileBeforeDoc(files, job_order_info);
        }

        if(workers){
          await this.saveWorkerListBeforeDoc(workers, job_order_info);
        }

        let jobOrderInfoResponse = await this.JobOrderInfoService.update({
          ...job_order_info,
          service_time: job_order_info.service_time.length > 10? moment(job_order_info.service_time).second(0).format("HH:mm:ss"): job_order_info.service_time + ":00",
        });
        if (jobOrderInfoResponse.success) {
          if(data.customer_contact_ids){
            await this.saveJobOrderContactList(data.customer_contact_ids, jobOrderInfoResponse);
          } 
          if(deleteContactList){
            await Promise.all(deleteContactList.map(async (v)=>{
              let jobOrderContactResponse = await this.JobOrderContactService
              .delete({
                 ...v
              });
              if (!jobOrderContactResponse.success) {
                throw jobOrderContactResponse.error;
              }
            }));
          }
 
          if(jobOrderMitItem?.jobOrderItems.length > 0){
            await this.saveOldJobOrderMitList(jobOrderMitItem);
          }
          
          if(jobOrderMitItem?.jobOrderItems.length > 0){
            await this.saveJobOrderMitList(jobOrderMitItem?.jobOrderItems, jobOrderInfoResponse);
          }   

          if(jobOrderMitItem?.deletejobOrderItems.length > 0){
            await Promise.all(jobOrderMitItem.deletejobOrderItems.map(async (v)=>{
              if(v.job_order_calibration_item_id){
                let jobOrderCalibrationItemResponse = await this.JobOrderCalibrationItemService.delete({
                  ...v
                });

                if(v.quotation_mit_item_info){
                  let  QuotationMitItemResponse = await this.QuotationMitItemService
                  .update({
                    ...v.quotation_mit_item_info,
              
                    job_order_info_id: "",
                    job_order_info_status: "",
                    job_order_info_doc_no: "",
                    job_order_info_type: "",
                    job_order_calibration_item_status: "",
                    job_order_calibration_item_id: "",
                    lock_invoice_at: "",
                    unlock_invoice_at: "",
                  });
  
                  if (!QuotationMitItemResponse.success) {
                    throw QuotationMitItemResponse.error;
                  }
                }
               if (!jobOrderCalibrationItemResponse.success) {
                 throw jobOrderCalibrationItemResponse.error;
               }
              }
            }));
          }    

          if(jobOrderProductItem?.jobOrderItems.length > 0){
            await this.saveJobOrderProductList(jobOrderProductItem?.jobOrderItems, jobOrderInfoResponse);
          }   

          if(jobOrderProductItem?.deletejobOrderItems.length > 0){
            await Promise.all(jobOrderProductItem.deletejobOrderItems.map(async (v)=>{
              if(v.job_order_product_item_id){
                let jobOrderProductItemResponse = await this.JoborderProductItemService.delete({
                  ...v
               });
               if(v.quotation_item_info){
                let  QuotationItemResponse = await this.QuotationProductItemService
                .update({
                  ...v.quotation_item_info,
            
                  job_order_info_id: "",
                  job_order_info_status: "",
                  job_order_info_doc_no: "",
                  job_order_info_type: "",
                  job_order_product_item_status: "",
                  job_order_product_item_id: "",
                  lock_invoice_at: "",
                  unlock_invoice_at: "",
                });

                if (!QuotationItemResponse.success) {
                  throw QuotationItemResponse.error;
                }
              }
               if (!jobOrderProductItemResponse.success) {
                 throw jobOrderProductItemResponse.error;
               }
              }
            }));
          }    

          if(trainings){
            await this.saveTrainingList(trainings, jobOrderInfoResponse);
          }

          if(deleteTrainingList){
            await Promise.all(deleteTrainingList.map(async (v)=>{
              if(v.job_order_training_engineer_id){
                let trainingItemResponse = await this.JobOrderTrainingEngineerService.delete({
                  ...v
               });
               if (!trainingItemResponse.success) {
                 throw trainingItemResponse.error;
               }
              }
            }));
          }

          if(deleteWorkerList){
            await Promise.all(deleteWorkerList.map(async (v)=>{
              if(v.job_order_worker_engineer_id){
                let workerItemResponse = await this.JobOrderWorkerEngineerService.delete({
                  ...v
               });
               if (!workerItemResponse.success) {
                 throw workerItemResponse.error;
               }
              }
            }));
          }

          if(carInfos){
            await this.saveCarInfoList(carInfos, jobOrderInfoResponse);
          }

          if(deleteCarInfoList){
            await Promise.all(deleteCarInfoList.map(async (v)=>{
              if(v.job_order_car_info_id){
                let carInfoItemResponse = await this.JobOrderCarInfoService.delete({
                  ...v
               });
               if (!carInfoItemResponse.success) {
                 throw carInfoItemResponse.error;
               }
              }
            }));
          }

          return {
            status: true,
            message: jobOrderInfoResponse.success
          }
        } else {
          if (jobOrderInfoResponse.resultCode === "40900") {
            return {
              status: false,
              message: 'codeDuplicate',
              error: jobOrderInfoResponse.error
            }
          } else {
            throw jobOrderInfoResponse.error;
          }
        }
      } catch (error) {

        return {
          status: false,
          message: error
        }
      }
    }
  }
  
  async saveJobOrderContactList(contactList, jobOrderInfoResponse){
    await Promise.all(contactList.map(async (v)=>{
      if(v.job_order_contact_id){

      }else{
        let  jobOrderContactResponse = await this.JobOrderContactService
        .create(
          {
            job_order_info_id: jobOrderInfoResponse?.resultData.job_order_info_id,
            ...v
          });
        if (!jobOrderContactResponse.success) {
          throw jobOrderContactResponse.error;
        }
      }
    }));
  }

  async saveOldJobOrderMitList(jobOrderMitItem){

    await Promise.all(jobOrderMitItem?.jobOrderItems.map(async (v:any, index: number) =>{
      if(v?.quotation_info_id.length > 3){

        let data = {
          ...v.quotation_mit_item_info,
  
          price: v.quotation_mit_item_info?.old_price? v.quotation_mit_item_info?.old_price: v.quotation_mit_item_info?.price,
          price_addition_attributes: v.quotation_mit_item_info?.old_price_addition_attributes? v.quotation_mit_item_info?.old_price_addition_attributes: v.quotation_mit_item_info?.price_addition_attributes,
          price_addition_points: v.quotation_mit_item_info?.old_price_addition_points? v.quotation_mit_item_info?.old_price_addition_points: v.quotation_mit_item_info?.price_addition_points,

          cost_of_sales: v.quotation_mit_item_info.cost_of_sales? parseFloat(this.utils.convertStingToNumber(v.quotation_mit_item_info.cost_of_sales)): undefined,
          quotation_info_id: v.quotation_info_id
        }

        let quotationMitItemResponse = await this.QuotationMitItemService.update(data);
        if (quotationMitItemResponse.success) {
          await this.updateMitAll(v, data, quotationMitItemResponse);
        }else{
          throw quotationMitItemResponse.error;
        }
      }
    }));
  }

  async updateMitAll(v, data,quotationMitItemResponse){
    if(v.quotation_mit_item_details){
      await this.saveDetailArray(v.quotation_mit_item_details, quotationMitItemResponse);
    }
    if(data.quotation_mit_item_attribute_info){
      await this.saveAttribute(data.quotation_mit_item_attribute_info, quotationMitItemResponse);
    }
    if(data.mit_item_logs?.length > 0){
      this.saveLogArray(data.mit_item_logs, quotationMitItemResponse, '');
    }
    if(data.delMitItemDetails){
      await Promise.all(data.delMitItemDetails.map(async (d)=>{
        if(d.detail_type == 'point'){
          await Promise.all(d.quotation_mit_item_detail_points.map(async (p)=>{
            let quotationMitItemDetailPoint = await this.QuotationMitItemDetailPointService.delete({
              quotation_mit_item_detail_point_id: p.quotation_mit_item_detail_point_id,
            });
            if(!quotationMitItemDetailPoint.success){
              throw quotationMitItemDetailPoint.error;
            }
          }));
        }else{
          let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.delete({
            quotation_mit_item_detail_range_id: d.quotation_mit_item_detail_range.quotation_mit_item_detail_range_id,
          });
          if(!quotationMitItemDetailRange.success){
            throw quotationMitItemDetailRange.error;
          }
        }
        let quotationMitItemDetailResponse = await this.QuotationMitItemDetailService.delete({
          quotation_mit_item_detail_id: d.quotation_mit_item_detail_id,
        });
        if(!quotationMitItemDetailResponse.success){
          throw quotationMitItemDetailResponse.error;
        }
      }));
    }
    if(data.delMitItemDetailPoints){
      await Promise.all(data.delMitItemDetailPoints.map(async (p)=>{
        if(p.quotation_mit_item_detail_point_id){
          let quotationMitItemDetailPoint = await this.QuotationMitItemDetailPointService.delete({
            quotation_mit_item_detail_point_id: p.quotation_mit_item_detail_point_id,
          });
          if(!quotationMitItemDetailPoint.success){
            throw quotationMitItemDetailPoint.error;
          }
        }
      }));
    }
    if(data.delMitItemDetailRanges){
      await Promise.all(data.delMitItemDetailRanges.map(async (r)=>{
        if(r.quotation_mit_item_detail_range_id){
          let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.delete({
            quotation_mit_item_detail_range_id: r.quotation_mit_item_detail_range_id,
          });
          if(!quotationMitItemDetailRange.success){
            throw quotationMitItemDetailRange.error;
          }
        }
      }));
    }
    if(data.delMitItemAttributes){
      await Promise.all(data.delMitItemAttributes.map(async (a)=>{
        if(a.quotation_mit_item_attribute_id){
          let quotationMitItemAttribute = await this.QuotationMitItemAttributeService.delete({
            quotation_mit_item_attribute_id: a.quotation_mit_item_attribute_id,
          });
          if(!quotationMitItemAttribute.success){
            throw quotationMitItemAttribute.error;
          }
        }
      }));
    }
  }

  async saveJobOrderMitList(jobOrderItems, jobOrderInfoResponse){

    await Promise.all(jobOrderItems.map(async (value: any, index: number)=>{
      
      if(value.files){
        await Promise.all(
          value.files.map(async (file: any, index: number)=>{
            if(value.job_order_calibration_item_id){
              let uploadResponse = await this.UploadService.post(file, 'job_order_items');

              let transportFileResponse = await this.JobOrderFileWorkService.create(
                {
                  job_order_info_id: Array.isArray(jobOrderInfoResponse.resultData)? jobOrderInfoResponse?.resultData[0]?.job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id,
                  job_order_calibration_item_id: value.job_order_calibration_item_id,
                  file_name: uploadResponse?.resultData.originalname,
                  file_path: uploadResponse?.resultData.path
                });
              if (!transportFileResponse.success) {
                throw jobOrderInfoResponse.error;
              }
            }
        }));
      }
      //Save cert
      if(value.job_order_cert){
        await Promise.all(
          value.job_order_cert.map(async (file: any, index: number)=>{
            if(value.job_order_calibration_item_id && !file.job_order_cert_id){
              let uploadResponse = await this.UploadService.post(file, 'job_order_item_certs');

              let certFileResponse = await this.JobOrderCertService.create(
                {
                  job_order_info_id: Array.isArray(jobOrderInfoResponse.resultData)? jobOrderInfoResponse?.resultData[0]?.job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id,
                  item_id: value.job_order_calibration_item_id,
                  file_name: uploadResponse?.resultData.originalname,
                  cert_file_path: uploadResponse?.resultData.path
                });
              if (!certFileResponse.success) {
                throw jobOrderInfoResponse.error;
              }
            }
        }));
      }
      //Save calibration item
      if(value.job_order_calibration_item_id){
        let jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
        .update({
          ...value,
          job_order_info_id: jobOrderInfoResponse?.resultData.job_order_info_id,
          order: index + 1
        });

        this.saveStatusMitItem(value, jobOrderMitItemResponse, jobOrderInfoResponse);
        this.updateMitItem(value, jobOrderInfoResponse, jobOrderMitItemResponse);
      }else{
        if(value.quotation_info_id.length > 3){
          let  jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
          .create({
            ...value,
            job_order_info_id: jobOrderInfoResponse?.resultData.job_order_info_id,
            order: index + 1
          });

          this.updateMitItem(value, jobOrderInfoResponse, jobOrderMitItemResponse);

          if(!jobOrderMitItemResponse.success){
            throw jobOrderMitItemResponse.error;
          }
        }else{

          let  QuotationMitItemResponse = await this.QuotationMitItemService.create({
            ...value.quotation_mit_item_info,
            customer_id: jobOrderInfoResponse?.resultData.customer_id
          });

          if(QuotationMitItemResponse.success){
            await this.saveDetailArray(value.quotation_mit_item_details, QuotationMitItemResponse);

            let  jobOrderMitItemResponse = await this.JobOrderCalibrationItemService
            .create({
              ...value,
              quotation_mit_item_id: QuotationMitItemResponse?.resultData.quotation_mit_item_id,
              job_order_info_id: jobOrderInfoResponse?.resultData.job_order_info_id,
              order: index + 1
            });

            if(jobOrderMitItemResponse.success){
              value.quotation_mit_item_info.quotation_mit_item_id = QuotationMitItemResponse?.resultData.quotation_mit_item_id;

              this.saveStatusMitItem(value, jobOrderMitItemResponse, jobOrderInfoResponse);
              this.updateMitItem(value, jobOrderInfoResponse, jobOrderMitItemResponse);
            }else{
              throw jobOrderMitItemResponse.error;
            }
          }else{
            throw QuotationMitItemResponse.error;
          }
        }
      }
    }));
  }

  async saveJobOrderProductList(jobOrderItems, jobOrderInfoResponse){

    await Promise.all(
      jobOrderItems.map(async (value: any, index: number)=>{

        if(value.job_order_product_item_id){
          let jobOrderProductItemResponse = await this.JoborderProductItemService
          .update({
            ...value,
            job_order_info_id: jobOrderInfoResponse?.resultData.job_order_info_id,
          });
 
          if(jobOrderProductItemResponse.success){
            this.saveStatusProductItem(value, jobOrderProductItemResponse, jobOrderInfoResponse);
            this.updateProductItem(value, jobOrderInfoResponse, jobOrderProductItemResponse);
          }
        }else{
          let  jobOrderProductItemResponse = await this.JoborderProductItemService
          .create({
            ...value,
            job_order_info_id: jobOrderInfoResponse?.resultData.job_order_info_id,
          });

          if(jobOrderProductItemResponse.success){
            this.updateProductItem(value, jobOrderInfoResponse, jobOrderProductItemResponse);
          }else{
            throw jobOrderProductItemResponse.error;
          }
        }

        
    }));
  }

  async saveStatusMitItem(value, jobOrderMitItemResponse, jobOrderInfoResponse){

    if(Array.isArray(jobOrderMitItemResponse?.resultData)){
      jobOrderMitItemResponse.resultData = jobOrderMitItemResponse?.resultData[0];
    }

    if(jobOrderMitItemResponse.success && (jobOrderInfoResponse.resultData.doc_status_id =='COMPLETED'|| jobOrderInfoResponse.resultData.doc_status_id == 'CONFIRMED' || jobOrderInfoResponse.resultData.doc_status_id == 'REJECTED')){
 
      if (value.item_status_id == 'CRF-REJECTED' || value.item_status_id == 'CRF-CONFIRMED'){
        await this.JobOrderStatusConfirmService.load(null, {
          job_order_info_id:Array.isArray(jobOrderInfoResponse.resultData)? jobOrderInfoResponse?.resultData[0]?.job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id
        }).then(async res=>{
          let resultData = res?.resultData.filter(status => status.item_id == value.job_order_calibration_item_id);
          
          if(resultData.length > 0){

            let  jobOrderStatusConfirmResponse = await this.JobOrderStatusConfirmService
            .update({
              ...jobOrderMitItemResponse,
              ...value,
              job_order_status_confirm_id: resultData[0].job_order_status_confirm_id,
              item_id: jobOrderMitItemResponse?.resultData.job_order_calibration_item_id,
              item_type: "calibration"
            });
  
            if(!jobOrderStatusConfirmResponse.success){
              throw jobOrderStatusConfirmResponse.error;
            }
          }else{
            let  jobOrderStatusConfirmResponse = await this.JobOrderStatusConfirmService
            .create({
              ...jobOrderMitItemResponse,
              ...value,
              item_id: jobOrderMitItemResponse?.resultData.job_order_calibration_item_id,
              item_type: "calibration"
            });
  
            if(!jobOrderStatusConfirmResponse.success){
              throw jobOrderStatusConfirmResponse.error;
            }
          }
        })
      }else if(value.item_status_id == 'CRF-COMPLETED' || value.item_status_id == 'CRF-CANCELED'){

        await this.JobOrderStatusWorkService.load(null, {
          job_order_info_id:Array.isArray(jobOrderInfoResponse.resultData)? jobOrderInfoResponse?.resultData[0]?.job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id
        }).then(async res=>{
          let resultData = res?.resultData.filter(status => status.item_id == value.job_order_calibration_item_id);
          
          if(resultData.length > 0){

            let  jobOrderStatusWorkResponse = await this.JobOrderStatusWorkService
            .update({
              ...jobOrderMitItemResponse,
              ...value,
              job_order_status_work_id: resultData[0].job_order_status_work_id,
              item_id: jobOrderMitItemResponse.resultData.job_order_calibration_item_id,
              item_type: "calibration"
            });
  
            if(!jobOrderStatusWorkResponse.success){
              throw jobOrderStatusWorkResponse.error;
            }
          }else{
            let  jobOrderStatusWorkResponse = await this.JobOrderStatusWorkService
            .create({
              ...jobOrderMitItemResponse,
              ...value,
              item_id: jobOrderMitItemResponse.resultData.job_order_calibration_item_id,
              item_type: "calibration"
            });
  
            if(!jobOrderStatusWorkResponse.success){
              throw jobOrderStatusWorkResponse.error;
            }
          }
        })
      }
    }else if(jobOrderMitItemResponse.success && (jobOrderInfoResponse.resultData.doc_status_id == 'WORK_DONE' || jobOrderInfoResponse.resultData.doc_status_id == 'CANCELED')){

      await this.JobOrderStatusWorkService.load(null, {
        job_order_info_id:Array.isArray(jobOrderInfoResponse.resultData)? jobOrderInfoResponse?.resultData[0]?.job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id
      }).then(async res=>{
        let resultData = res.resultData.filter(status => status.item_id == value.job_order_calibration_item_id);
        
        if(resultData.length > 0){

          let  jobOrderStatusWorkResponse = await this.JobOrderStatusWorkService
          .update({
            ...jobOrderMitItemResponse,
            ...value,
            job_order_status_work_id: resultData[0].job_order_status_work_id,
            item_id: jobOrderMitItemResponse.resultData.job_order_calibration_item_id,
            item_type: "calibration"
          });

          if(!jobOrderStatusWorkResponse.success){
            throw jobOrderStatusWorkResponse.error;
          }
        }else{
          let  jobOrderStatusWorkResponse = await this.JobOrderStatusWorkService
          .create({
            ...jobOrderMitItemResponse,
            ...value,
            item_id: jobOrderMitItemResponse.resultData.job_order_calibration_item_id,
            item_type: "calibration"
          });

          if(!jobOrderStatusWorkResponse.success){
            throw jobOrderStatusWorkResponse.error;
          }
        }
      })
    }else if(!jobOrderMitItemResponse.success){
      throw jobOrderMitItemResponse.error;
    }
  }

  async saveStatusProductItem(value, jobOrderProductItemResponse, jobOrderInfoResponse){
    if(jobOrderProductItemResponse.success && (jobOrderInfoResponse.resultData.doc_status_id == 'CONFIRMED' || jobOrderInfoResponse.resultData.doc_status_id == 'REJECTED')){

      if (value.item_status_id == 'CRF-REJECTED' || value.item_status_id == 'CRF-CONFIRMED'){
        await this.JobOrderStatusConfirmService.load(null, {
          job_order_info_id:Array.isArray(jobOrderInfoResponse.resultData)? jobOrderInfoResponse?.resultData[0]?.job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id
        }).then(async res=>{
          let resultData = res.resultData.filter(status => status.item_id == value.job_order_calibration_item_id);

          if(resultData.length > 0){
            let  jobOrderStatusConfirmResponse = await this.JobOrderStatusConfirmService
            .update({
              ...jobOrderProductItemResponse,
              ...value,
              job_order_status_confirm_id: resultData[0].job_order_status_confirm_id,
              item_id: jobOrderProductItemResponse.resultData.job_order_product_item_id,
              item_type: "product",
              reject_service_date: moment(new Date()).format("YYYY-MM-DD"),
            });
  
            if(!jobOrderStatusConfirmResponse.success){
              throw jobOrderStatusConfirmResponse.error;
            }
          }else{
            let  jobOrderStatusConfirmResponse = await this.JobOrderStatusConfirmService
            .create({
              ...jobOrderProductItemResponse,
              ...value,
              item_id: jobOrderProductItemResponse.resultData.job_order_product_item_id,
              item_type: "product",
              reject_service_date: moment(new Date()).format("YYYY-MM-DD"),
            });
  
            if(!jobOrderStatusConfirmResponse.success){
              throw jobOrderStatusConfirmResponse.error;
            }
          }
        })
      }

    }else if(jobOrderProductItemResponse.success && (jobOrderInfoResponse.resultData.doc_status_id == 'COMPLETED' || jobOrderInfoResponse.resultData.doc_status_id == 'CANCELED')){
      let  jobOrderStatusWorkResponse = await this.JobOrderStatusWorkService
      .create({
        ...jobOrderProductItemResponse,
        ...value,
        item_id: jobOrderProductItemResponse.resultData.job_order_product_item_id,
        item_type: "product",
        service_date: new Date(),
      });

      if(!jobOrderStatusWorkResponse.success){
        throw jobOrderStatusWorkResponse.error;
      }
    }else if(!jobOrderProductItemResponse.success){
      throw jobOrderProductItemResponse.error;
    }
  }

  async saveCarInfoList(carInfos, jobOrderInfoResponse){
    await Promise.all(
      carInfos.map(async (value: any, index: number)=>{

        if(value.job_order_car_info_id){
          let carInfoItemResponse = await this.JobOrderCarInfoService
          .update({
            ...value,
            job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id,
          });

          if(!carInfoItemResponse.success){
            throw carInfoItemResponse.error;
          }
        }else{
          let  carInfoItemResponse = await this.JobOrderCarInfoService
          .create({
            ...value,
            job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id,
          });

          if(!carInfoItemResponse.success){
            throw carInfoItemResponse.error;
          }
        }
    }));
  }
  
  async saveTrainingList(trainings, jobOrderInfoResponse){
    await Promise.all(
      trainings.map(async (value: any, index: number)=>{

        if(value.job_order_training_engineer_id){
          let trainingItemResponse = await this.JobOrderTrainingEngineerService
          .update({
            ...value,
            job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id,
          });

          if(!trainingItemResponse.success){
            throw trainingItemResponse.error;
          }
        }else{
          let  trainingItemResponse = await this.JobOrderTrainingEngineerService
          .create({
            ...value,
            job_order_info_id: jobOrderInfoResponse.resultData.job_order_info_id,
          });

          if(!trainingItemResponse.success){
            throw trainingItemResponse.error;
          }
        }
    }));
  }

  async saveFileBeforeDoc(files, jobOrderInfoResponse){
    await Promise.all(files.map(async v=>{
      if(!v.job_order_file_id){
        let uploadResponse = await this.UploadService.post(v, 'job_orders');

        let transportFileResponse = await this.JobOrderFileService.create(
          {
            job_order_info_id: jobOrderInfoResponse.job_order_info_id,
            file_name: uploadResponse.resultData.originalname,
            file_path: uploadResponse.resultData.path
          });
        if (!transportFileResponse.success) {
          throw jobOrderInfoResponse.error;
        }
      }
    }));
  }

  async saveWorkerListBeforeDoc(workers, jobOrderInfoResponse){
    await Promise.all(
      workers.map(async (value: any, index: number)=>{

        if(value.job_order_worker_engineer_id){
          let worekerItemResponse = await this.JobOrderWorkerEngineerService
          .update({
            ...value,
            job_order_info_id: jobOrderInfoResponse.job_order_info_id,
            order: index
          });

          if(!worekerItemResponse.success){
            throw worekerItemResponse.error;
          }
        }else{
          let  worekerItemResponse = await this.JobOrderWorkerEngineerService
          .create({
            ...value,
            job_order_info_id: jobOrderInfoResponse.job_order_info_id,
            order: index
          });

          if(!worekerItemResponse.success){
            throw worekerItemResponse.error;
          }
        }
    }));
  }

  async saveDetailArray(detailList, quotationMitItemResponse){
    let details = detailList;

    details = await Promise.all(details.map(async (d: any, indexD: number) =>{
      if(d.quotation_mit_item_detail_id){

        return {
          ...d,
          total_amount: d.old_total_amount?d.old_total_amount: d.total_amount,
          quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
          order_by: d.order_by || indexD
        }
      }else{
        return {
          ...d,
          quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
          order_by: d.order_by || indexD
        }
      }
    }));

    if(details.filter(item=>item.quotation_mit_item_detail_id == null || item.quotation_mit_item_detail_id == "").length > 0){
      var createDetailArrayResponse = await this.QuotationMitItemDetailService.createArray(details.filter(item=>item.quotation_mit_item_detail_id == null || item.quotation_mit_item_detail_id == ""));
    }
    
    if(details.filter(item=>item.quotation_mit_item_detail_id != "" && item.quotation_mit_item_detail_id != null).length > 0){
      var updateDetailArrayResponse = await this.QuotationMitItemDetailService.updateArray(details.filter(item=>item.quotation_mit_item_detail_id != "" && item.quotation_mit_item_detail_id != null));
    }

    let mergeArray = [
      ...createDetailArrayResponse?.resultData || [],
      ...updateDetailArrayResponse?.resultData || []
    ]

    mergeArray.sort((a, b) => (a.order_by > b.order_by? 1 : -1));

    if (mergeArray.length > 0) {
      await Promise.all(detailList.map(async (d: any, indexD) =>{
        if(d.detail_type == 'point'){
          this.saveDetailPointArray(d, mergeArray[indexD]);
        }else{
          this.saveDetailRange(d, mergeArray[indexD]);
        }
      }));
    }
  }

  async saveDetailPointArray(detail, quotationMitItemDetailResponse){
    detail.quotation_mit_item_detail_points.sort((a, b) => {
      return parseFloat(a.cus_point) - parseFloat(b.cus_point);
    });
    let detailPoints = detail.quotation_mit_item_detail_points;

    detailPoints = await Promise.all(detailPoints.map(async (p: any, indexP: number)=>{
      return {
        ...p,
        quotation_mit_item_detail_id: quotationMitItemDetailResponse.quotation_mit_item_detail_id,
        order_by: indexP
      }
    }));

    if(detailPoints.filter(item=>item.quotation_mit_item_detail_point_id == null || item.quotation_mit_item_detail_point_id == "").length > 0){
      await this.QuotationMitItemDetailPointService.createArray(detailPoints.filter(item=>item.quotation_mit_item_detail_point_id == null || item.quotation_mit_item_detail_point_id == ""));
    }
    
    if(detailPoints.filter(item=>item.quotation_mit_item_detail_point_id != "" && item.quotation_mit_item_detail_point_id != null).length > 0){
      await this.QuotationMitItemDetailPointService.updateArray(detailPoints.filter(item=>item.quotation_mit_item_detail_point_id != "" && item.quotation_mit_item_detail_point_id != null));
    }

    await Promise.all(detail.quotation_mit_item_detail_points.map(async (p: any, indexP: number)=>{
      if(indexP == 0){
        if(detail?.mit_item_logs?.length > 0){
          await this.saveLogArray(detail.mit_item_logs, quotationMitItemDetailResponse, quotationMitItemDetailResponse.quotation_mit_item_detail_id);
        }
      }
    }));
  }

  async saveDetailRange(detail, quotationMitItemDetailResponse){
    if(detail.quotation_mit_item_detail_range.quotation_mit_item_detail_range_id){
      let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.update(
        { 
          ...detail.quotation_mit_item_detail_range,
          quotation_mit_item_detail_id: quotationMitItemDetailResponse.quotation_mit_item_detail_id,
          order_by: 0
        });
        if (quotationMitItemDetailRange.success) {
          if(detail?.mit_item_logs?.length > 0){
            this.saveLogArray(detail.mit_item_logs, quotationMitItemDetailResponse, quotationMitItemDetailResponse.quotation_mit_item_detail_id)
          }
        }else{
          throw quotationMitItemDetailRange.error;
        }
    }else{
      let quotationMitItemDetailRange = await this.QuotationMitItemDetailRangeService.create(
        { 
          ...detail.quotation_mit_item_detail_range,
          quotation_mit_item_detail_id: quotationMitItemDetailResponse.quotation_mit_item_detail_id,
          order_by: 0
        });
        if (quotationMitItemDetailRange.success) {
          if(detail?.mit_item_logs?.length > 0){
            this.saveLogArray(detail.mit_item_logs, quotationMitItemDetailResponse, quotationMitItemDetailResponse.quotation_mit_item_detail_id)
          }
        }else{
          throw quotationMitItemDetailRange.error;
        }
    }
  }

  async saveLogArray(logList?, quotationMitItemResponse?, quotation_mit_item_detail_id?){
    logList =  await Promise.all(logList.map(async (log) =>{
      return {
        ...log,
        quotation_mit_item_detail_id: quotation_mit_item_detail_id,

        quotation_mit_item_id: quotationMitItemResponse?.resultData?.quotation_mit_item_id? 
        quotationMitItemResponse?.resultData?.quotation_mit_item_id:
        quotationMitItemResponse?.quotation_mit_item_id,
      }
    }));
    let quotationMitItemLog = await this.QuotationMitItemLog.createArray(logList);
      if (!quotationMitItemLog.success) {
        throw quotationMitItemLog.error;
      }
  }

  async saveAttribute(attributeList, quotationMitItemResponse){
    await Promise.all(attributeList.map(async (a, indexA: number) =>{
      if(a.quotation_mit_item_attribute_id){
        let quotationMitItemAttributeResponse = await this.QuotationMitItemAttributeService.update({
          quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
          ...a,
          order_by: indexA
        });
        if (!quotationMitItemAttributeResponse.success) {
          throw quotationMitItemAttributeResponse.error;
        }
      }else{
        let quotationMitItemAttributeResponse = await this.QuotationMitItemAttributeService.create({
          quotation_mit_item_id: quotationMitItemResponse.resultData.quotation_mit_item_id,
          ...a,
          order_by: indexA
        });
        if (!quotationMitItemAttributeResponse.success) {
          throw quotationMitItemAttributeResponse.error;
        }
      }
    }));
  }

  async updateMitItem(value, jobOrderInfoResponse, jobOrderMitItemResponse){
    // Update mit item

    let jobOrderMitItem = Array.isArray(jobOrderMitItemResponse.resultData)? jobOrderMitItemResponse.resultData[0]: jobOrderMitItemResponse.resultData;

    if(value.quotation_mit_item_info && value.quotation_mit_item_info.quotation_mit_item_id){

      let data = {
        ...value.quotation_mit_item_info,
        
        price: value.quotation_mit_item_info?.old_price? value.quotation_mit_item_info?.old_price: value.quotation_mit_item_info?.price,
        price_addition_attributes: value.quotation_mit_item_info?.old_price_addition_attributes? value.quotation_mit_item_info?.old_price_addition_attributes: value.quotation_mit_item_info?.price_addition_attributes,
        price_addition_points: value.quotation_mit_item_info?.old_price_addition_points? value.quotation_mit_item_info?.old_price_addition_points: value.quotation_mit_item_info?.price_addition_points,

        cost_of_sales: value.quotation_mit_item_info.cost_of_sales? parseFloat(this.utils.convertStingToNumber(value.quotation_mit_item_info.cost_of_sales)): undefined,
        quotation_info_id: value.quotation_info_id,
        customer_id: jobOrderInfoResponse.resultData.customer_id,
        // Update
        job_order_info_doc_no: jobOrderInfoResponse.resultData.doc_no,
        job_order_calibration_item_status: jobOrderMitItem.item_status_id,
        job_order_info_id: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.job_order_info_id: "",
        job_order_info_status: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.doc_status_id: "",
        job_order_info_type: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.job_order_type: "",
        job_order_calibration_item_id: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobOrderMitItem.job_order_calibration_item_id: "",
        unlock_invoice_at: jobOrderMitItem.item_status_id!='CRF-CANCELED'? jobOrderMitItem.unlock_invoice_at: "",

        lock_invoice_at: jobOrderMitItem.lock_invoice_at? jobOrderMitItem.lock_invoice_at: "",
      }

      let QuotationMitItemResponse = await this.QuotationMitItemService.update(data);

      if(!QuotationMitItemResponse.success){
        throw QuotationMitItemResponse.error;
      }
    }
  }

  async updateProductItem(value, jobOrderInfoResponse, jobOrderProductItemResponse){
    let jobOrderProductItem = Array.isArray(jobOrderProductItemResponse.resultData)? jobOrderProductItemResponse.resultData[0]: jobOrderProductItemResponse.resultData;

    if(value.quotation_item_info){
      let  QuotationProductItemResponse = await this.QuotationProductItemService
      .update({
        ...value.quotation_item_info,
        cost_of_sales: parseFloat(this.utils.convertStingToNumber(value.quotation_item_info.cost_of_sales)),
        quotation_info_id: value.quotation_info_id,

        job_order_info_id: jobOrderProductItem.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.job_order_info_id: "",
        job_order_info_status: jobOrderProductItem.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.doc_status_id: "",
        job_order_info_doc_no: jobOrderProductItem.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.doc_no: "",
        job_order_info_type: jobOrderProductItem.item_status_id!='CRF-CANCELED'? jobOrderInfoResponse.resultData.job_order_type: "",
        job_order_product_item_status: jobOrderProductItem.item_status_id!='CRF-CANCELED'? jobOrderProductItem.item_status_id: "",
        job_order_product_item_id: jobOrderProductItem.item_status_id!='CRF-CANCELED'? jobOrderProductItem.job_order_product_item_id: "",
        lock_invoice_at: jobOrderProductItem.item_status_id!='CRF-CANCELED'? jobOrderProductItem.lock_invoice_at: "",
        unlock_invoice_at: jobOrderProductItem.item_status_id!='CRF-CANCELED'? jobOrderProductItem.unlock_invoice_at: "",
      });

      if(QuotationProductItemResponse.success){
      }else{
        throw QuotationProductItemResponse.error
      }
    }
  }
}

