<div class="container app-content overflow-container">

  <div class="btn-section text-right border-bottom">
    <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(transportInfo)">
        <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
    </button>
  </div>

  <div *ngFor="let page of pages; let i = index;">
    <div class="pdf" id="transport_{{i}}">
      <div class="row">
        <div class="col-1"></div>
        <img class="col-1 text-right p-0" src="/assets/app-assets/images/pdf/logo1.png" width="90px" height="110px">
        <div class="col-8 text-center pt-3">
            <h1>MIRACLE INTERNATIONAL TECHNOLOGY CO., LTD.</h1>
            <p>214 Bangwaek Rd., Bangpai, Bangkae, Bangkok 10160 Tel : 0-2865-4647-8 Fax : 0-2865-4649
            </p>
        </div>
        <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon2.jpg" width="90px" height="110px">
        <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon3.png" width="90px" height="110px">
      </div>
      <p class="text-center">Taxpayer Identification No.: 0-1055-45112-91-9 (Head Office)</p>
      <div class="row">
          <div class="col-6 pr-0 text-right">
            <p>Web-site : http://www.mit.in.th</p>
          </div>
          <div class="col-6">
            <p>E-mail : info@mit.in.th</p>
          </div>
      </div>
      <h1 class="text-center text-header pt-1">SEND-RECEIVE INSTRUMENT FORM / ใบรับ-ส่งเครื่องมือ</h1>
  
      <br>
      <div *ngIf="transportInfo">
        <table class="table table-bordered">
          <tr>
              <td class="text-left" width="60%">
                  <div class="row pl-0" style="padding-bottom: 5px;">
                      <div class="col-3 col-sm-3 col-md-3">
                          <div class="font-weight-bold"><p>Customer/ลูกค้า:</p></div>
                      </div>
                      <div class="col-9 col-sm-9 col-md-9 pl-0">
                          <div class="font-weight-normal"><p>{{transportInfo?.customer_address_info?.address_name}}
                              ({{transportInfo?.customer_info?.customer_code}})</p>
                          </div>
                      </div>
                  </div>
                  <div class="row pl-0" style="padding-bottom: 5px;">
                      <div class="col-3 col-sm-3 col-md-3">
                          <div class="font-weight-bold"><p>Address/ที่อยู่:</p></div>
                      </div>
                      <div class="col-9 col-sm-9 col-md-9 pl-0">
                          <div class="font-weight-normal "><p>{{transportInfo?.address}}</p>
                          </div>
                      </div>
                  </div>
                  <div class="row pl-0" style="padding-bottom: 5px;">
                    <div class="col-3 col-sm-3 col-md-3">
                        <div class="font-weight-bold"><p>Tel No./โทร:</p></div>
                    </div>
                    <div class="col-3 col-sm-3 col-md-3 pl-0">
                        <div class="font-weight-normal">
                          <p>{{transportInfo?.customer_address_info.tel_1}}</p>
                          <span *ngIf="transportInfo?.customer_address_info.tel_2">
                            <p>, {{transportInfo?.customer_address_info.tel_2}}</p>
                          </span>
                        </div>
                    </div>
                    <div class="col-2 col-sm-2 col-md-2">
                        <div class="font-weight-bold"><p>Fax:</p></div>
                    </div>
                    <div class="col-4 col-sm-4 col-md-4 pl-0">
                        <div class="font-weight-normal">
                            <p>{{transportInfo?.customer_address_info.fax_1}}</p>
                            <span *ngIf="transportInfo?.customer_address_info.fax_2">
                              <p>, {{transportInfo?.customer_address_info.fax_2}}</p>
                            </span>
                          </div>
                    </div>
                  </div>
                  <div class="row pl-0" style="padding-bottom: 5px;">
                      <div class="col-3 col-sm-3 col-md-3">
                          <div class="font-weight-bold"><p>Attn/เรียน:</p></div>
                      </div>
                      <div class="col-9 col-sm-9 col-md-9 pl-0">
                          <div class="font-weight-normal">
                              <span class="pl-0" *ngFor="let item of contactList; let i = index;">
                                  <p>{{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}}</p>
                                  <span class="pl-0" *ngIf="contactList?.length != i+1">,</span>
                              </span>
                          </div>
                      </div>
                  </div>
                  <div class="row pl-0" style="padding-bottom: 5px;">
                      <div class="col-3 col-sm-3 col-md-3">
                          <div class="font-weight-bold"><p>Tel No./โทร:</p></div>
                      </div>
                      <div class="col-3 col-sm-3 col-md-3 pl-0">
                          <div class="font-weight-normal">
                              <span class="pl-0" *ngFor="let item of contactList; let i = index;">
                                  <p>{{item?.customer_contact_info?.phonenumber}}</p>
                                  <span class="pl-0" *ngIf="contactList?.length != i+1"><p>,</p></span>
                              </span>
                          </div>
                      </div>
                      <div class="col-2 col-sm-2 col-md-2">
                          <div class="font-weight-bold"><p>E-mail:</p></div>
                      </div>
                      <div class="col-4 col-sm-4 col-md-4 pl-0">
                          <div class="font-weight-normal">
                              <span class="pl-0" *ngFor="let item of contactList; let i = index;">
                                  <p>{{item?.customer_contact_info?.email}}</p>
                                  <span class="pl-0" *ngIf="contactList?.length != i+1"><p>,</p></span>
                              </span>
                          </div>
                      </div>
  
                  </div>
              </td>
              <td class="text-left" width="40%">
                  <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                      <div class="col-4 col-sm-4 col-md-4">
                          <div class="font-weight-bold"><p>Doc No:</p></div>
                      </div>
                      <div class="col-8 col-sm-8 col-md-8">
                          <div class="font-weight-normal" style="font-size: 18px;">{{transportInfo?.doc_no}}</div>
                      </div>
                  </div>
                  <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                      <div class="col-4 col-sm-4 col-md-4">
                          <div class="font-weight-bold"><p>Date/วันที่:</p></div>
                      </div>
                      <div class="col-8 col-sm-8 col-md-8">
                          <div class="font-weight-normal">
                            <p>{{transportInfo?.doc_date | date: 'dd/MM/yyyy'}}</p></div>
                      </div>
                  </div>
              </td>
          </tr>
          <tr class="text-center">
            <td colspan="2">
              <p>ท่านสามารถเช็คสถานะงานสอบเทียบได้จากเว็บไซต์ของบริษัทฯ www.mit.in.th โดยค้นหาจากเลข Doc No.</p>
            </td>
          </tr>
  
          <tr *ngIf="transportInfo">
            <td class="text-left w-50">
              <div class="row pl-0">
                <div class="col-3 col-sm-3 col-md-3">
                  <p class="font-weight-bold">Sender/ผู้ส่ง : </p>
                </div>
                <div class="col-6 col-sm-6 col-md-6 pl-0">
                  <div class="font-weight-normal">
                    <span class="pl-0" *ngFor="let item of contactList; let i = index;">
                        <p>{{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}}</p>
                        <span class="pl-0" *ngIf="contactList?.length != i+1"><p>,</p></span>
                    </span>
                  </div>
                </div>
                <div class="col-3 col-sm-3 col-md-3 pl-0">
                  <img class="image-size" [src]="transportInfo?.customer_signature" *ngIf="transportInfo?.customer_signature">
                </div>
              </div>
              <div class="row pl-0">
                <div class="col-3 col-sm-3 col-md-3">
                  <p class="font-weight-bold">Date/วันที่ : </p>
                </div>
                <div class="col-9 col-sm-9 col-md-9 pl-0">
                  <p>{{transportInfo?.pickup_date | date: 'dd/MM/yyyy'}}</p>
                </div>
              </div>
            </td>
            <td class="text-left w-50">
              <div class="row pl-0">
                <div class="col-5 col-sm-5 col-md-5">
                  <p class="font-weight-bold">Receiver/ผู้รับ : </p>
                </div>
                <div class="col-7 col-sm-7 col-md-7 pl-0">
                  <div class="font-weight-normal">
                    <p>{{transportInfo?.receiver_info?.firstname_th}} {{transportInfo?.receiver_info?.lastname_th}}</p>
                  </div>
                </div>
              </div>
             
              <div class="row pl-0">
                <div class="col-5 col-sm-5 col-md-5">
                  <p class="font-weight-bold">Date/วันที่ : </p>
                </div>
                <div class="col-7 col-sm-7 col-md-7 pl-0">
                  <p>{{transportInfo?.pickup_date | date: 'dd/MM/yyyy'}}</p>
                </div>
              </div>
            
            </td>
          </tr>
  
          <tr *ngIf="transportInfo">
            <td class="text-left w-50">
              <div class="row pl-0">
                <div class="col-3 col-sm-3 col-md-3">
                  <p class="font-weight-bold">Receiver/ผู้รับ : </p>
                </div>
                <div class="col-9 col-sm-9 col-md-9 pl-0">
                  
                </div>
              </div>
              <div class="row pl-0">
                <div class="col-3 col-sm-3 col-md-3">
                  <p class="font-weight-bold">Date/วันที่ : </p>
                </div>
                <div class="col-9 col-sm-9 col-md-9 pl-0">
                  
                </div>
              </div>
            
            </td>
            <td class="text-left w-50">
              <div class="row pl-0">
                <div class="col-5 col-sm-5 col-md-5">
                  <p class="font-weight-bold">Sender/ผู้ส่ง : </p>
                </div>
                <div class="col-7 col-sm-7 col-md-7 pl-0">
                  
                </div>
              </div>
              <div class="row pl-0">
                <div class="col-4 col-sm-4 col-md-4">
                  <p class="font-weight-bold">Date/วันที่ : </p>
                </div>
                <div class="col-8 col-sm-8 col-md-8 pl-0">
                  
                </div>
              </div>
            </td>
          </tr>
  
          <tr>
            <td colspan="2" class="text-left">
              <div class="row pl-0">
                <div class="col-2 col-sm-2 col-md-2">
                  <p class="font-weight-bold">Special condition :</p>
                </div>
                <div class="col-10 col-sm-10 col-md-10 pl-0">
                  <div class="font-weight-normal">
                    <p>{{transportInfo?.note}}</p>
                  </div>
                </div>
              </div>
  
            </td>
          </tr>

          <tr>
            <td colspan="2" class="text-left">
              <div class="row pl-0">
                <div class="col-2 col-sm-2 col-md-2">
                  <p class="font-weight-bold">Accessory :</p>
                </div>
                <div class="col-10 col-sm-10 col-md-10 pl-0">
                  <div class="font-weight-normal">
                    <p>{{transportInfo?.accessory}}</p>
                  </div>
                </div>
              </div>
  
            </td>
          </tr>
        </table>
  
        <table class="w-100" border="1" cellpadding="10">
          <thead class="text-center">
            <th width="5%">#</th>
            <th width="20%"><p>Description</p></th>
            <th><p>Maker</p></th>
            <th><p>Model</p></th>
            <th><p>Serial No. / Tag No.</p></th>
            <th width="20%"><p>Cal. Point</p></th>
            <th><p>สถานะ</p></th>
            <th><p>หมายเหตุ</p></th>
          </thead>
  
          <tbody>
            <tr class="text-center" *ngFor="let receive of page; let j = index;">
              <td><p>{{ receive.index + 1 }}</p></td>
              <td class="text-left">
                <ng-container [ngSwitch]="receive.quotation_info_id">
                  <ng-container *ngSwitchCase="null">
                    <p>{{receive.noqt_description}}</p>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <p>{{receive.quotation_mit_item_info?.description || receive.quotation_mit_item_info?.equipment_name}}</p>
                  </ng-container>
                </ng-container>
              </td>
              <td>
                <ng-container [ngSwitch]="receive.quotation_info_id">
                  <ng-container *ngSwitchCase="null">
                    <p>{{receive.noqt_marker || 'w-100'}}</p>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <p>{{receive.quotation_mit_item_info?.marker || '-'}}</p>
                  </ng-container>
                </ng-container>
              </td>
              <td>
                <ng-container [ngSwitch]="receive.quotation_info_id">
                  <ng-container *ngSwitchCase="null">
                    <p>{{receive.noqt_model || '-'}}</p>
                  </ng-container>
                  <ng-container *ngSwitchDefault>
                    <p>{{receive.quotation_mit_item_info?.model || '-'}}</p>
                  </ng-container>
                </ng-container>
              </td>
              <td>
                <p>
                  <ng-container [ngSwitch]="receive.quotation_info_id">
                    <ng-container *ngSwitchCase="null">
                      {{receive.noqt_serial_no || '-'}}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{receive.quotation_mit_item_info?.serial_no || '-'}}
                    </ng-container>
                  </ng-container>/
                  <ng-container [ngSwitch]="receive.quotation_info_id">
                    <ng-container *ngSwitchCase="null">
                      {{receive.noqt_tag_no || '-'}}
                    </ng-container>
                    <ng-container *ngSwitchDefault>
                      {{receive.quotation_mit_item_info?.tag_no || '-'}}
                    </ng-container>
                  </ng-container>
                </p>
              </td>
              <td class="text-left">
                <span class="custom-inline" *ngFor="let detail of receive.quotation_mit_item_details; let detailIndex = index;">
                  <span *ngIf="detail.detail_type == 'point'">
                      <span class="pb-2 custom-inline" *ngFor="let point of detail.quotation_mit_item_detail_points; let pointIndex = index;">
                          <span class="danger" *ngIf="detail.isISO === 'N' && point.is_non_calculate =='false'">*</span>
                          <span *ngIf="point.is_non_calculate =='false'">
                            <p>{{point.cus_point || 0 | number : '1.1-5'}}</p>
                          </span>
                          <span class="p-0 m-0" *ngIf="((pointIndex + 1) != pointLength(detail.quotation_mit_item_detail_points)) && point.is_non_calculate =='false'">,</span>
                          <span [innerHTML]="point.cus_unit_name" *ngIf="pointIndex + 1 == detail.quotation_mit_item_detail_points.length"></span>
                      </span>
                  </span>
                  <span *ngIf="detail.detail_type == 'range'">
                      <span  class="pb-2 custom-inline">
                          <span class="danger" *ngIf="detail.isISO === 'N'">*</span>
                          <p>{{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_min) || 0 | number : '1.1-5'}} </p>
                      </span>
                      &nbsp;-
                      <span  class="pb-2 custom-inline">
                          <p>{{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.cus_max) || 0 | number : '1.1-5'}}</p>
                          <span [innerHTML]="detail?.quotation_mit_item_detail_range.cus_unit_name"></span>
                      </span>
                  </span>
                  <br>
                </span>
              </td>
              <td>
                <p>{{receive?.item_status_info?.item_status_name_th}}</p>
              </td>
              <td>
                <p>{{receive?.note}}</p>
              </td>
            </tr>
            <tr class="text-center" *ngIf="receiveItems.length == 0">
              <td [attr.colspan]="10" class="text-center"><p>ไม่มีข้อมูล</p></td>
            </tr>
          </tbody>
        </table>
  
        <div class="pt-1">
          <p><span class="p-0 text-bold">หมายเหตุ:</span> * นอกขอบข่ายการรับรอง  ISO/IEC 17025</p>
        </div>
  
      </div>
    </div>
    
    <div class="btn-section text-right border-bottom">
    </div>
  </div>
  
</div>
