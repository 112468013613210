import {
  Directive,
  ElementRef,
  Output,
  EventEmitter,
  Input,
} from "@angular/core";
declare const Quill;
@Directive({
  selector: "[quill]",
})
export class QuillDirective {
  @Output() change = new EventEmitter();
  @Output("text-output") textOutput = new EventEmitter();
  @Output() instance = new EventEmitter();
  @Input() toolbar = [];
  @Input() html: boolean = false;
  @Input("readonly") readOnly: boolean = false;
  @Input() text: string = "";
  el = null;
  quill = null;

  constructor(el: ElementRef) {
    this.el = el;
    this.init();
  }

  init() {
    setTimeout(() => {
      let modules = {
        toolbar: this.toolbar,
      };
      let config = {
        readOnly: this.readOnly,
        theme: "snow",
      };

      if (this.toolbar && this.toolbar.length) {
        config["modules"] = modules;
      }

      let quill = new Quill(this.el.nativeElement, config);
      quill.on("text-change", () => {
        if (this.html) {
          this.change.emit(quill.root.innerHTML);
          this.textOutput.emit(quill.getText().replace(/\n$/, ""));
        } else {
          this.change.emit(quill.getText().replace(/\n$/, ""));
        }
      });

      const delta = quill.clipboard.convert(this.text);
      quill.setContents(delta, "silent");
      this.quill = quill;
      this.instance.emit(quill);
    });
  }

  // ngOnChanges(changes) {
  //   // if (changes.toolbar) {
  //   //   this.toolbar = changes.toolbar.currentValue;
  //   // }
  //   // this.el.nativeElement.parentElement.querySelector(".ql-toolbar")?.remove();
  // }
}
