<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="invoice-payment-show"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="ItemCreate1Modal">
          บันทึกการชำระเงิน
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="closeModal()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div *ngIf="tab =='เงินสด'">
                <div class="col-12">
                    <form-group
                    [control]="form.controls.payment_date"
                    [submitted]="submitted">
                    <label for="payment_date">
                        วันที่รับชำระเงิน 
                    </label>
                    <div class="input-group mt-0">
                        <input
                        date-picker
                        type="text"
                        id="payment_date"
                        *ngIf="form.value.payment_date"
                        [max-date]="undefined"
                        [date]="form.value.payment_date"
                        class="form-control"
                        formControlName="payment_date"
                        />
                        <div
                        class="input-group-append"
                        for="payment_date"
                        >
                        <span class="input-group-text"
                            ><i class="feather icon-calendar"></i
                        ></span>
                        </div>
                    </div>
                    </form-group>
                </div>

                <div class="col-12">
                    <form-group
                    [control]="form.controls.value_before_wht"
                    [submitted]="submitted">
                    <label for="value_before_wht">
                        จำนวนเงินก่อนภาษีมูลค่าเพิ่ม (บาท) 
                    </label>
                    <input
                        placeholder="0.00"
                        class="form-control text-right" 
                        (click)="$event.target.select()"
                        oninput="this.value = 
                        this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        type="text"
                        id="value_before_wht"
                        formControlName="value_before_wht"
                    />
                    </form-group>
                </div>

                <div class="col-12">
                    <form-group
                    [control]="form.controls.wht_percent"
                    [submitted]="submitted">
                    <label for="wht_percent">
                       หัก ณ ที่จ่าย
                    </label>
                    <select class="form-control" dir="rtl" id="wht_percent" formControlName="wht_percent">
                      <option value="0">ไม่หัก ณ ที่จ่าย</option>
                      <option value="1">1%</option>
                      <option value="1.5">1.5%</option>
                      <option value="2">2%</option>
                      <option value="3">3%</option>
                      <option value="5">5%</option>
                      </select>
                    </form-group>
                </div>

                <div class="col-12">
                    <form-group
                    [control]="form.controls.deduction"
                    [submitted]="submitted">
                    <label for="deduction">
                       ยอดเงินหัก ณ ที่จ่าย
                    </label>
                    <input
                        placeholder="0.00"
                        class="form-control text-right" 
                        type="text"
                        id="value_before_wht"
                        formControlName="deduction"
                        readonly
                    />
                    </form-group>
                </div>

                <div class="col-12">
                    <form-group
                    [control]="form.controls.tax"
                    [submitted]="submitted">
                    <label for="tax">
                       ภาษีมูลค่าเพิ่ม (%)
                    </label>
                    <input
                        placeholder="0.00"
                        class="form-control text-right" 
                        type="text"
                        id="tax"
                        formControlName="tax"
                        readonly
                    />
                    </form-group>
                </div>

                <div class="col-12">
                    <form-group
                    [control]="form.controls.grand_total"
                    [submitted]="submitted">
                    <label for="grand_total">
                       ยอดสุทธิ
                    </label>
                    <input
                        placeholder="0.00"
                        class="form-control text-right" 
                        type="text"
                        id="grand_total"
                        formControlName="grand_total"
                        readonly
                    />
                    </form-group>
                </div>
                
                <div class="col-12">
                    <form-group
                    [control]="form.controls.note"
                    [submitted]="submitted">
                    <label for="note">
                       หมายเหตุ
                    </label>
                    <textarea
                        placeholder="หมายเหตุ"
                        class="form-control"
                        rows="3"
                        id="note"
                        formControlName="note"
                        >
                    </textarea>
                    </form-group>
                </div>
            </div>

            <div *ngIf="tab=='เช็ค'">
                <div class="col-12">
                    <form-group
                    [control]="form.controls.payment_date"
                    [submitted]="submitted">
                    <label for="payment_date">
                        วันที่รับชำระเงิน 
                    </label>
                    <div class="input-group mt-0">
                        <input
                        date-picker
                        type="text"
                        id="payment_date"
                        [max-date]="undefined"
                        *ngIf="form.value.payment_date"
                        [date]="form.value.payment_date"
                        class="form-control"
                        formControlName="payment_date"
                        />
                        <div
                        class="input-group-append"
                        for="payment_date"
                        >
                        <span class="input-group-text"
                            ><i class="feather icon-calendar"></i
                        ></span>
                        </div>
                    </div>
                    </form-group>
                  </div>

                  <div class="col-12">
                    <form-group
                    [control]="form.controls.bank_name"
                    [submitted]="submitted">
                    <label for="bank_name">
                      ธนาคาร 
                    </label>
                    <select class="form-control" id="bank_name" formControlName="bank_name">
                        <option value="">กรุณาเลือกข้อมูล</option>
                        <option value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</option>
                        <option value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</option>
                        <option value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</option>
                        <option value="ธนาคารซิตี้แบงก์">ธนาคารซิตี้แบงก์</option>
                        <option value="ธนาคารทหารไทย">ธนาคารทหารไทย</option>
                        <option value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</option>
                        <option value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</option>
                        <option value="ธนาคารเกียรตินาคินภัทร">ธนาคารเกียรตินาคินภัทร</option>
                        <option value="ธนาคารซีไอเอ็มบีไทย">ธนาคารซีไอเอ็มบีไทย</option>
                        <option value="ธนาคารทิสโก้">ธนาคารทิสโก้</option>
                        <option value="ธนาคารธนชาต">ธนาคารธนชาต</option>
                        <option value="ธนาคารยูโอบี">ธนาคารยูโอบี</option>
                        <option value="ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร">ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร</option>
                        <option value="ธนาคารสแตนดาร์ดชาร์เตอร์ด">ธนาคารสแตนดาร์ดชาร์เตอร์ด</option>
                        <option value="ธนาคารอเมริกา">ธนาคารอเมริกา</option>
                        <option value="ธนาคารออมสิน">ธนาคารออมสิน</option>
                        <option value="ธนาคารอาคารสงเคราะห์">ธนาคารอาคารสงเคราะห์</option>
                        <option value="ธนาคารฮ่องกงแบงค์/HSBC">ธนาคารฮ่องกงแบงค์/HSBC</option>
                        <option value="ธ.แลนด์แอนด์เฮ้าส์C">ธ.แลนด์แอนด์เฮ้าส์</option>
                        <option value="ธ.ดอยซ์แบงค์">ธ.ดอยซ์แบงค์</option>
                        <option value="ธ.มิซูโฮ">ธ.มิซูโฮ</option>

                      </select>
                    </form-group>
                  </div>

                  <div class="col-12">
                    <form-group
                    [control]="form.controls.bank_branch"
                    [submitted]="submitted">
                    <label for="bank_branch">
                        สาขา 
                    </label>
                    <input
                        placeholder="สาขา"
                        class="form-control" 
                        type="text"
                        id="bank_branch"
                        formControlName="bank_branch"
                    />
                    </form-group>
                  </div>

                  <div class="col-12">
                    <form-group
                    [control]="form.controls.evidence_ref_no"
                    [submitted]="submitted">
                    <label for="evidence_ref_no">
                        เลขที่ 
                    </label>
                    <input
                        placeholder="เลขที่"
                        class="form-control" 
                        type="text"
                        id="evidence_ref_no"
                        formControlName="evidence_ref_no"
                    />
                    </form-group>
                  </div>

                  <div class="col-12">
                    <form-group
                    [control]="form.controls.evidence_date"
                    [submitted]="submitted">
                    <label for="evidence_date">
                        วันที่หน้าเข็ค 
                    </label>
                    <div class="input-group mt-0">
                        <input
                        date-picker
                        type="text"
                        id="evidence_date"
                        [max-date]="undefined"
                        *ngIf="form.value.evidence_date"
                        [date]="form.value.evidence_date"
                        class="form-control"
                        formControlName="evidence_date"
                        />
                        <div
                        class="input-group-append"
                        for="evidence_date"
                        >
                        <span class="input-group-text"
                            ><i class="feather icon-calendar"></i
                        ></span>
                        </div>
                    </div>
                    </form-group>
                  </div>

                  <div class="col-12">
                      <form-group
                      [control]="form.controls.value_before_wht"
                      [submitted]="submitted">
                      <label for="value_before_wht">
                        จำนวนเงินก่อนภาษีมูลค่าเพิ่ม (บาท) 
                      </label>
                      <input
                          placeholder="0.00"
                          class="form-control text-right" 
                          (click)="$event.target.select()"
                          oninput="this.value = 
                          this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                          type="text"
                          id="value_before_wht"
                          formControlName="value_before_wht"
                      />
                      </form-group>
                  </div>

                  <div class="col-12">
                      <form-group
                      [control]="form.controls.wht_percent"
                      [submitted]="submitted">
                      <label for="wht_percent">
                        หัก ณ ที่จ่าย
                      </label>
                      <select class="form-control" dir="rtl" id="wht_percent" formControlName="wht_percent">
                        <option value="0">ไม่หัก ณ ที่จ่าย</option>
                        <option value="1">1%</option>
                        <option value="1.5">1.5%</option>
                        <option value="2">2%</option>
                        <option value="3">3%</option>
                        <option value="5">5%</option>
                        </select>
                      </form-group>
                  </div>

                  <div class="col-12">
                      <form-group
                      [control]="form.controls.deduction"
                      [submitted]="submitted">
                      <label for="deduction">
                        ยอดเงินหัก ณ ที่จ่าย
                      </label>
                      <input
                          placeholder="0.00"
                          class="form-control text-right" 
                          type="text"
                          id="value_before_wht"
                          formControlName="deduction"
                          readonly
                      />
                      </form-group>
                  </div>

                  <div class="col-12">
                    <form-group
                    [control]="form.controls.tax"
                    [submitted]="submitted">
                    <label for="tax">
                      ภาษีมูลค่าเพิ่ม (%)
                    </label>
                    <input
                        placeholder="0.00"
                        class="form-control text-right" 
                        type="text"
                        id="tax"
                        formControlName="tax"
                        readonly
                    />
                    </form-group>
                  </div>

                  <div class="col-12">
                      <form-group
                      [control]="form.controls.fee"
                      [submitted]="submitted">
                      <label for="fee">
                        ค่าธรรมเนียม
                      </label>
                      <input
                        placeholder="0.00"
                        class="form-control text-right" 
                        (click)="$event.target.select()"
                        oninput="this.value = 
                        this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        formControlName="fee"
                        type="text"
                      />
                      </form-group>
                  </div>

                  <div class="col-12">
                      <form-group
                      [control]="form.controls.grand_total"
                      [submitted]="submitted">
                      <label for="grand_total">
                        ยอดสุทธิ
                      </label>
                      <input
                          placeholder="0.00"
                          class="form-control text-right" 
                          type="text"
                          id="grand_total"
                          formControlName="grand_total"
                          readonly
                      />
                      </form-group>
                  </div>
            </div>

            <div  *ngIf="tab=='โอนเงิน'">
                <div class="col-12">
                    <form-group
                    [control]="form.controls.payment_date"
                    [submitted]="submitted">
                    <label for="payment_date">
                        วันที่รับชำระเงิน 
                    </label>
                    <div class="input-group mt-0">
                        <input
                        date-picker
                        type="text"
                        id="payment_date"
                        [max-date]="undefined"
                        *ngIf="form.value.payment_date"
                        [date]="form.value.payment_date"
                        class="form-control"
                        formControlName="payment_date"
                        />
                        <div
                        class="input-group-append"
                        for="payment_date"
                        >
                        <span class="input-group-text"
                            ><i class="feather icon-calendar"></i
                        ></span>
                        </div>
                    </div>
                    </form-group>
                  </div>
  
                  <div class="col-12">
                    <form-group
                    [control]="form.controls.bank_name"
                    [submitted]="submitted">
                    <label for="bank_name">
                      ธนาคาร 
                    </label>
                    <select class="form-control" id="bank_name" formControlName="bank_name">
                        <option value="">กรุณาเลือกข้อมูล</option>
                        <option value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</option>
                        <option value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</option>
                        <option value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</option>
                        <option value="ธนาคารซิตี้แบงก์">ธนาคารซิตี้แบงก์</option>
                        <option value="ธนาคารทหารไทย">ธนาคารทหารไทย</option>
                        <option value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</option>
                        <option value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</option>
                        <option value="ธนาคารเกียรตินาคินภัทร">ธนาคารเกียรตินาคินภัทร</option>
                        <option value="ธนาคารซีไอเอ็มบีไทย">ธนาคารซีไอเอ็มบีไทย</option>
                        <option value="ธนาคารทิสโก้">ธนาคารทิสโก้</option>
                        <option value="ธนาคารธนชาต">ธนาคารธนชาต</option>
                        <option value="ธนาคารยูโอบี">ธนาคารยูโอบี</option>
                        <option value="ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร">ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร</option>
                        <option value="ธนาคารสแตนดาร์ดชาร์เตอร์ด">ธนาคารสแตนดาร์ดชาร์เตอร์ด</option>
                        <option value="ธนาคารอเมริกา">ธนาคารอเมริกา</option>
                        <option value="ธนาคารออมสิน">ธนาคารออมสิน</option>
                        <option value="ธนาคารอาคารสงเคราะห์">ธนาคารอาคารสงเคราะห์</option>
                        <option value="ธนาคารฮ่องกงแบงค์/HSBC">ธนาคารฮ่องกงแบงค์/HSBC</option>
                        <option value="ธ.แลนด์แอนด์เฮ้าส์C">ธ.แลนด์แอนด์เฮ้าส์</option>
                        <option value="ธ.ดอยซ์แบงค์">ธ.ดอยซ์แบงค์</option>
                        <option value="ธ.มิซูโฮ">ธ.มิซูโฮ</option>
                        
                      </select>
                    </form-group>
                  </div>
  
                  <div class="col-12">
                    <form-group
                    [control]="form.controls.evidence_ref_no"
                    [submitted]="submitted">
                    <label for="evidence_ref_no">
                        เลขที่ 
                    </label>
                    <input
                        placeholder="เลขที่"
                        class="form-control" 
                        type="text"
                        id="evidence_ref_no"
                        formControlName="evidence_ref_no"
                    />
                    </form-group>
                  </div>
  
                  <div class="col-12">
                      <form-group
                      [control]="form.controls.value_before_wht"
                      [submitted]="submitted">
                      <label for="value_before_wht">
                        จำนวนเงินก่อนภาษีมูลค่าเพิ่ม (บาท) 
                      </label>
                      <input
                          placeholder="0.00"
                          class="form-control text-right" 
                          (click)="$event.target.select()"
                          oninput="this.value = 
                          this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                          type="text"
                          id="value_before_wht"
                          formControlName="value_before_wht"
                      />
                      </form-group>
                  </div>
  
                  <div class="col-12">
                      <form-group
                      [control]="form.controls.wht_percent"
                      [submitted]="submitted">
                      <label for="wht_percent">
                        หัก ณ ที่จ่าย
                      </label>
                      <select class="form-control" dir="rtl" id="wht_percent" formControlName="wht_percent">
                        <option value="0">ไม่หัก ณ ที่จ่าย</option>
                        <option value="1">1%</option>
                        <option value="1.5">1.5%</option>
                        <option value="2">2%</option>
                        <option value="3">3%</option>
                        <option value="5">5%</option>
                        </select>
                      </form-group>
                  </div>
  
                  <div class="col-12">
                      <form-group
                      [control]="form.controls.deduction"
                      [submitted]="submitted">
                      <label for="deduction">
                        ยอดเงินหัก ณ ที่จ่าย
                      </label>
                      <input
                          placeholder="0.00"
                          class="form-control text-right" 
                          type="text"
                          id="value_before_wht"
                          formControlName="deduction"
                          readonly
                      />
                      </form-group>
                  </div>
  
                  <div class="col-12">
                    <form-group
                    [control]="form.controls.tax"
                    [submitted]="submitted">
                    <label for="tax">
                      ภาษีมูลค่าเพิ่ม (%)
                    </label>
                    <input
                        placeholder="0.00"
                        class="form-control text-right" 
                        type="text"
                        id="tax"
                        formControlName="tax"
                        readonly
                    />
                    </form-group>
                  </div>
  
                  <div class="col-12">
                      <form-group
                      [control]="form.controls.fee"
                      [submitted]="submitted">
                      <label for="fee">
                        ค่าธรรมเนียม
                      </label>
                      <input
                        placeholder="0.00"
                        class="form-control text-right" 
                        (click)="$event.target.select()"

                        oninput="this.value = 
                        this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                        formControlName="fee"
                        type="text"
                      />
                      </form-group>
                  </div>
  
                  <div class="col-12">
                      <form-group
                      [control]="form.controls.grand_total"
                      [submitted]="submitted">
                      <label for="grand_total">
                        ยอดสุทธิ
                      </label>
                      <input
                          placeholder="0.00"
                          class="form-control text-right" 
                          type="text"
                          id="grand_total"
                          formControlName="grand_total"
                          readonly
                      />
                      </form-group>
                  </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          #closeModalBtn
          (click)="closeModal()"
        >
          <i class="fa fa-times icon-left"></i> ปิด
        </button>
      </div>
    </div>
  </div>
</div>
