import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { CustomerAddressService, CustomerContactService, CustomerInfoService, UtilsService } from 'src/app/services';
import { CalculateFunctionService, QuotationUtilsService, DownloadFileService, NgSelect2Customer, QuotationContactService, } from 'src/app/services/quotations';
import { AccInvoiceContactService, AccInvoiceInfoService } from 'src/app/services/invoice';
import { CoreService } from 'src/app/services/invoice/core.service';
import { debounceTime } from 'rxjs/operators';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { AbsNumberValidator } from 'src/app/validators';
import moment from 'moment';

declare var $: any;

@Component({
  selector: 'app-invoice-create',
  templateUrl: './invoice-create.component.html',
  styleUrls: ['./invoice-create.component.css']
})
export class InvoiceCreateComponent implements OnInit {
  form: FormGroup;

  resetdate:boolean = true;
  pageLoad:boolean = false;
  submitted:boolean = false;
  disableSaveBtn: boolean = false;
  isSalesManager:boolean = false;
  isShowCheckbox:boolean = false;
  isSalesCoordinator:boolean = false;

  invoice_id: string;
  customer_id: string;

  customerList: any = [];
  customerAddressList: any = [];
  customerContactList: any = [];
  tmpCustomerAddressList: any = [];
  tmpCustomerContactList: any = [];
  productList: any = [];
  quotationItems = [];
  //number
  count: number = 0;
  //any
  status: any;
  state: any;
  select2Options = null;
  subject: Subject<string> = new Subject();
  credit: Subject<string> = new Subject();
  customer_info = null;
  invoiceItem = null;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private route: ActivatedRoute,
    private cdRef: ChangeDetectorRef,
    private CoreService: CoreService,
    private CustomerInfoService: CustomerInfoService,
    private DownloadFileService: DownloadFileService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccInvoiceContactService: AccInvoiceContactService,
    private QuotationContactService: QuotationContactService,

    public UtilsService: UtilsService,
    public util : QuotationUtilsService,
    public calculate: CalculateFunctionService,
    public NgSelect2Customer: NgSelect2Customer
  ) {
    this.customer_id = 
    this.route.snapshot.paramMap.get("customer_id");   
    this.route.queryParams.subscribe(params => {
      this.invoice_id = params['invoice_id'];
    });
    this.state = this.router.getCurrentNavigation().extras.state;
  }

  async ngOnInit() {
    this.createForm();

    this.userInfo$.subscribe(user=>{
      if(!user) return;
      this.userInfo = user;
    })

    if(this.invoice_id){
      await this.AccInvoiceInfoService.getById({acc_invoice_info_id: this.invoice_id})
      .then(async res=>{
  
        let resultData = res.resultData || {};
  
        this.customer_info = res.resultData.customer_info;
        let data = {
          ...resultData,
          customer_name: `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''}`,
        }
        this.form.patchValue(data);
  
        if(resultData.customer_id){
          await this.setCustomerContact(resultData.customer_id);
          await this.setCustomerAddress(resultData.customer_id);
          await this.selectAddressName(resultData.customer_address_id);
        }
  
        await this.AccInvoiceContactService.load(null, { acc_invoice_info_id: this.invoice_id }).then((res) => {
          let ids = res.resultData.map(v=>{
            return v.customer_contact_id;
          })
          this.form.controls['customer_contact_ids'].setValue(ids);
        });
      })
    }else{
      await this.CustomerInfoService.getById({customer_id: this.customer_id})
      .then(async res=>{
  
        let resultData = res.resultData || {};
        this.customer_info = res.resultData;
        let data = {
          customer_id: resultData.customer_id,
          customer_name: `[${resultData?.customer_code || ''}] ${resultData?.company_name || ''} ${resultData?.branch?" (" + resultData?.branch + ")": ""}`,
          customer_credit_term_day: resultData.credit_term_day || 0,
        }
        if(resultData.customer_id){
          await this.setCustomerContact(resultData.customer_id);
          await this.setCustomerAddress(resultData.customer_id);
        }
        this.form.patchValue(data);
        this.form.controls['doc_validdate'].setValue(moment(new Date()).add(resultData?.credit_term_day || 0, 'days').format('YYYY-MM-DD'));
        this.form.controls['doc_duedate'].setValue(moment(new Date()).add(resultData?.credit_term_day || 0, 'days').format('YYYY-MM-DD'));
      })
    }

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );

    this.credit.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.changeDate()  
    );


    this.pageLoad = true;
  }

  createForm(){
    this.form = this._fbd.group({
      customer_id: ["", [Validators.required]],
      customer_name: [""],

      doc_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      doc_validdate:[""],
      doc_duedate: [moment(new Date()).format('YYYY-MM-DD')],
      return_date:[moment(new Date()).format('YYYY-MM-DD')],
      doc_status_id: ["", [Validators.required]],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      doc_note: [""],
      discount: [this.util.transformDecimal(0)],
      customer_credit_term_day: ["", [Validators.required, AbsNumberValidator()]],
      tax: ["7"],
      grand_total_before_vat: [0.00],
      grand_total: [0.00],
      revise_count: [""],
      customer_contact_ids: [[], [Validators.required]],
      is_return_doc_can_create: [""],
    });
    this.form.controls['is_return_doc_can_create'].disable(); 
  }

  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];

      if(customer.length > 0){
        if(customer.length > 0){
          address = {
            id: customer[0].customer_address_id,
            customer_id: customer[0].customer_id,
            text: customer[0]?.branch?`${customer[0].address_name} (${customer[0]?.branch})` + ` แผนก: ${customer[0].department}`: `${customer[0].address_name}` + ` แผนก: ${customer[0].department}`,
            address_name: this.util.genAddress(customer[0]),
          }
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
        this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];

       this.tmpCustomerAddressList= this.tmpCustomerAddressList.map(elem => (
        {
          id: elem.customer_address_id,
          customer_id: elem.customer_id,
          text: elem.branch? `${elem.address_name} (${elem.branch})` + ` แผนก: ${elem.department}`: `${elem.address_name}` + ` แผนก: ${elem.department}`,
          address_name: this.util.genAddress(elem)
        } 
      ));
      });
      let customerAddress = this.tmpCustomerAddressList;
      if(address){
        this.customerAddressList = [
          ...[address],
          ...customerAddress
        ];
      }else{
        this.customerAddressList = [
          ...customerAddress
        ];
      }

      if(this.customerAddressList.length > 0){
        const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        if(checkAddress.length == 0) {
          if(address){
            this.form.get('customer_address_id').setValue(address.id);
            this.selectAddressName(address.id);
          }else{
            this.form.controls['customer_address_id'].setValue(this.customerAddressList[0]?.id);
            this.selectAddressName(this.customerAddressList[0]?.id);
          }
        }
      }else{
        if(address){
          this.customerAddressList = [
            ...[address],
          ];
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }


  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.get('customer_address').setValue("");
    }
    if(address && this.customerContactList.length > 0){
      let contacts = this.customerContactList.filter(item=> item.data.related_customer_address_id == address.id);
      const ids = contacts.map(v=>{
        return v.id;
      })
      this.form.get('customer_contact_ids').setValue(ids);
    }
  }

  onKeyup(){
    this.subject.next();
  }

  onCreditKeyup(){
    this.credit.next();
  }

  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
  }
  save(){
    if(this.userInfo.emp_info?.emp_department_id.startsWith(
      'ACC_DEP'
    )){
      this.form.get('doc_status_id').setValue("WAITING_FOR_APPROVE");
    }else{
      this.form.get('doc_status_id').setValue("WAITING_VERIFY");
    }
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
  }

  async submit(){
    let data = this.form.getRawValue();
    this.disableSaveBtn = true;

    if(data.customer_contact_ids.length > 0){
      data.customer_contact_ids = data.customer_contact_ids.map(v=>{
        return {customer_contact_id: v};
      })
    }
    let invoiceResponse = await this.CoreService.createInvoice(data, this.invoiceItem);

    if(invoiceResponse.status){
      await this.router.navigateByUrl("/invoice", {
        state: {
          status: invoiceResponse.message,
        },
      })
    }else{
      if(invoiceResponse.message == 'codeDuplicate'){
        this.status = invoiceResponse.error;
      }else{
        this.status = invoiceResponse.message;
      }
    }
    this.disableSaveBtn = false;
  }

  sumCount(){
    this.count = 0;
    this.invoiceItem.invoiceItems.map(product=>{
      this.count = this.count + this.calculate.sumProduct(product);
    })
    this.form.get('grand_total_before_vat').setValue(this.count);
    this.form.get('discount').setValue(this.util.transformDecimal(this.form.get('discount').value));
    this.form.get('grand_total').setValue(this.calculate.sumProductTotal(this.count, this.form.value));
    this.cdRef.detectChanges();
  }

  changeDate(){
    if(this.form.value.customer_credit_term_day !== '' && !isNaN(this.form.value.customer_credit_term_day)){
      this.form.controls['doc_validdate'].setValue(moment(new Date()).add(parseInt(this.form.value.customer_credit_term_day) || 0, 'days').format('YYYY-MM-DD'));
      this.form.controls['doc_duedate'].setValue(moment(new Date()).add(parseInt(this.form.value.customer_credit_term_day) || 0, 'days').format('YYYY-MM-DD'));

      this.resetdate = false;
      setTimeout(() => {
        this.resetdate = true;
      }, 100);
    }
  }

  async getInvoiceItems(event){
    this.invoiceItem = event;

    let findCalibration = this.invoiceItem.invoiceItems.find(item => item.item_type == 'calibration_service');
    this.quotationItems = this.invoiceItem.invoiceItems.filter((invoice, index, self) =>
    index === self.findIndex((t) => (
      t.quotation_info_id === invoice.quotation_info_id 
    )));
    const ids = [];
    await Promise.all(this.quotationItems.map(async item=>{
      let contactResponse = await this.QuotationContactService.load(null, { quotation_info_id: item.quotation_info_id });
      if(contactResponse.success){

         contactResponse.resultData.map(v=>{
           if(!ids.find(item=> item == v.customer_contact_info.customer_contact_id)){
            ids.push(v.customer_contact_info.customer_contact_id);
           }
        })
      }
    }))
    this.form.controls['customer_contact_ids'].setValue(ids);

    let invoices = this.invoiceItem.invoiceItems;
    invoices.sort(function(a,b){
      if(a.data.return_date > b.data.return_date) return 1;
      if(a.data.return_date < b.data.return_date) return -1;
      return 0;
    });
    if(invoices.filter(item=>item.item_type != 'product').length > 0){
      this.form.controls['return_date'].setValue(invoices[0]?.data.return_date); 
      this.resetdate = false;
      setTimeout(() => {
        this.resetdate = true;
      }, 100);
    }
    if(findCalibration){
      this.form.controls['is_return_doc_can_create'].setValue('true'); 
      this.form.controls['is_return_doc_can_create'].enable(); 
    }else{
      this.form.controls['is_return_doc_can_create'].setValue(''); 
      this.form.controls['is_return_doc_can_create'].disable(); 
    }
    this.form.controls['is_return_doc_can_create'].updateValueAndValidity(); 
    this.sumCount();
  }

  downloadFile(file){
    this.DownloadFileService.downloadFileURL(file);
  }

  clearDate(){
    this.form.controls['doc_validdate'].setValue(""); 
  }
}
