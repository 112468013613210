<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="cheque-payment-edit"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="ItemCreate1Modal">แก้ไขข้อมูล</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
          <div class="col-12">
            <form-group
              [control]="form.controls.payment_date"
              [submitted]="submitted"
            >
              <label for="payment_date">
                วันที่รับชำระเงิน <span class="danger">*</span>
              </label>
              <div class="input-group mt-0">
                <input
                  date-picker
                  type="text"
                  id="payment_date"
                  [max-date]="undefined"
                  [date]="form.value.payment_date"
                  class="form-control"
                  formControlName="payment_date"
                />
                <div class="input-group-append" for="payment_date">
                  <span class="input-group-text"
                    ><i class="feather icon-calendar"></i
                  ></span>
                </div>
              </div>
            </form-group>
          </div>

          <div class="col-12">
            <form-group
              [control]="form.controls.bank_name"
              [submitted]="submitted"
            >
              <label for="bank_name">
                ธนาคาร <span class="danger">*</span>
              </label>
              <select
                class="form-control"
                id="bank_name"
                formControlName="bank_name"
              >
                <option value="">กรุณาเลือกข้อมูล</option>
                <option value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</option>
                <option value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</option>
                <option value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</option>
                <option value="ธนาคารทหารไทย">ธนาคารทหารไทย</option>
                <option value="ธนาคารไทยพาณิชย์">ธนาคารไทยพาณิชย์</option>
                <option value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</option>
                <option value="ธนาคารเกียรตินาคินภัทร">
                  ธนาคารเกียรตินาคินภัทร
                </option>
                <option value="ธนาคารซีไอเอ็มบีไทย">ธนาคารซีไอเอ็มบีไทย</option>
                <option value="ธนาคารทิสโก้">ธนาคารทิสโก้</option>
                <option value="ธนาคารธนชาต">ธนาคารธนชาต</option>
                <option value="ธนาคารยูโอบี">ธนาคารยูโอบี</option>
                <option value="ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร">
                  ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร
                </option>
                <option value="ธนาคารออมสิน">ธนาคารออมสิน</option>
                <option value="ธนาคารอาคารสงเคราะห์">
                  ธนาคารอาคารสงเคราะห์
                </option>
              </select>
            </form-group>
          </div>

          <div class="col-12">
            <form-group
              [control]="form.controls.bank_branch"
              [submitted]="submitted"
            >
              <label for="bank_branch">
                สาขา <span class="danger">*</span>
              </label>
              <input
                placeholder="สาขา"
                class="form-control"
                type="text"
                id="bank_branch"
                formControlName="bank_branch"
              />
            </form-group>
          </div>

          <div class="col-12">
            <form-group
              [control]="form.controls.evidence_ref_no"
              [submitted]="submitted"
            >
              <label for="evidence_ref_no">
                เลขที่ <span class="danger">*</span>
              </label>
              <input
                placeholder="เลขที่"
                class="form-control"
                type="text"
                id="evidence_ref_no"
                formControlName="evidence_ref_no"
              />
            </form-group>
          </div>

          <div class="col-12">
            <form-group
              [control]="form.controls.evidence_date"
              [submitted]="submitted"
            >
              <label for="evidence_date">
                วันที่หน้าเช็ค <span class="danger">*</span>
              </label>
              <div class="input-group mt-0">
                <input
                  date-picker
                  type="text"
                  id="evidence_date"
                  [max-date]="undefined"
                  [date]="form.value.evidence_date"
                  class="form-control"
                  formControlName="evidence_date"
                />
                <div class="input-group-append" for="evidence_date">
                  <span class="input-group-text"
                    ><i class="feather icon-calendar"></i
                  ></span>
                </div>
              </div>
            </form-group>
          </div>

          <div class="col-12">
            <form-group
              [control]="form.controls.value_before_wht"
              [submitted]="submitted"
            >
              <label for="value_before_wht">
                จำนวนเงินก่อนภาษีมูลค่าเพิ่ม (บาท) <span class="danger">*</span>
              </label>
              <input
                placeholder="0.00"
                class="form-control text-right"
                (click)="$event.target.select()"
                (keyup)="onKeyup()"
                oninput="this.value = 
                      this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                type="text"
                id="value_before_wht"
                formControlName="value_before_wht"
              />
            </form-group>
          </div>

          <div class="col-12">
            <form-group
              [control]="form.controls.wht_percent"
              [submitted]="submitted"
            >
              <label for="wht_percent"> หัก ณ ที่จ่าย </label>
              <select
                class="form-control text-right"
                dir="rtl"
                id="wht_percent"
                formControlName="wht_percent"
                (change)="onKeyup()"
              >
              <option value="0">ไม่หัก ณ ที่จ่าย</option>
              <option value="1">1%</option>
              <option value="1.5">1.5%</option>
              <option value="2">2%</option>
              <option value="3">3%</option>
              <option value="5">5%</option>
              </select>
            </form-group>
          </div>

          <div class="col-12">
            <form-group
              [control]="form.controls.deduction"
              [submitted]="submitted"
            >
              <label for="deduction"> ยอดเงินหัก ณ ที่จ่าย </label>
              <input
                placeholder="0.00"
                class="form-control text-right"
                type="text"
                id="deduction"
                formControlName="deduction"
                readonly
              />
            </form-group>
          </div>

          <div class="col-12">
            <form-group [control]="form.controls.tax" [submitted]="submitted">
              <label for="tax"> ภาษีมูลค่าเพิ่ม (%) </label>
              <input
                placeholder="0.00"
                class="form-control text-right"
                type="text"
                id="tax"
                formControlName="tax"
                readonly
              />
            </form-group>
          </div>

          <div class="col-12">
            <form-group [control]="form.controls.fee" [submitted]="submitted">
              <label for="fee"> ค่าธรรมเนียม </label>
              <input
                placeholder="0.00"
                class="form-control text-right"
                (click)="$event.target.select()"
                (keyup)="onKeyup()"
                oninput="this.value = 
                    this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"
                formControlName="fee"
                type="text"
              />
            </form-group>
          </div>

          <div class="col-12">
            <form-group
              [control]="form.controls.grand_total"
              [submitted]="submitted"
            >
              <label for="grand_total"> ยอดสุทธิ </label>
              <input
                placeholder="0.00"
                class="form-control text-right"
                type="text"
                id="grand_total"
                formControlName="grand_total"
                readonly
              />
            </form-group>

            <hr />
          </div>

          <div class="col-12">
            <form-group
            [control]="form.controls.check_deposit_bank_name"
            [submitted]="submitted"
          >
            <label for="check_deposit_bank_name">
              นำเข้าธนาคาร <span class="danger">*</span>
            </label>
            <select
            class="form-control"
            id="check_deposit_bank_name"
            formControlName="check_deposit_bank_name"
          >
            <option value="">กรุณาเลือกข้อมูล</option>
            <option value="ธนาคารกรุงเทพ">ธนาคารกรุงเทพ</option>
            <option value="ธนาคารกรุงไทย">ธนาคารกรุงไทย</option>
            <option value="ธนาคารกสิกรไทย">ธนาคารกสิกรไทย</option>
            <option value="ธนาคารไทยพาณิชย์ (ออมทรัพย์)">ธนาคารไทยพาณิชย์ (ออมทรัพย์)</option>
            <option value="ธนาคารไทยพาณิชย์ (กระแสรายวัน)">ธนาคารไทยพาณิชย์ (กระแสรายวัน)</option>
            <option value="ธนาคารกรุงศรีอยุธยา">ธนาคารกรุงศรีอยุธยา</option>
          </select>
          </form-group>
          </div>

          <div class="col-12">
            <form-group
            [control]="form.controls.check_deposit_date"
            [submitted]="submitted"
          >
            <label for="check_deposit_date">
              กำหนดวันนำเข้า <span class="danger">*</span>
            </label>
            <div class="input-group mt-0">
              <input
                date-picker
                type="text"
                id="check_deposit_date"
                [max-date]="undefined"
                [date]="form.value.check_deposit_date"
                class="form-control"
                formControlName="check_deposit_date"
              />
              <div class="input-group-append" for="check_deposit_date">
                <span class="input-group-text"
                  ><i class="feather icon-calendar"></i
                ></span>
              </div>
            </div>
          </form-group>
          </div>

          <div class="col-12">
            <form-group
            [control]="form.controls.check_money_arrived_date"
            [submitted]="submitted"
          >
            <label for="check_money_arrived_date">
              วันที่เงินเข้า
            </label>
            <div class="input-group mt-0">
              <input
                date-picker
                type="text"
                id="check_money_arrived_date"
                [max-date]="undefined"
                [date]="form.value.check_money_arrived_date"
                class="form-control"
                formControlName="check_money_arrived_date"
              />
              <div class="input-group-append" for="check_money_arrived_date">
                <span class="input-group-text"
                  ><i class="feather icon-calendar"></i
                ></span>
              </div>
            </div>
          </form-group>
          </div>

          <div class="col-12">
            <form-group
              [control]="form.controls.check_deposit_status"
              [submitted]="submitted"
            >
              <label for="check_deposit_status">
                สถานะเช็ค <span class="danger">*</span>
              </label>
              <select
                class="form-control"
                id="check_deposit_status"
                formControlName="check_deposit_status"
              >
                <option value="">กรุณาเลือกข้อมูล</option>
                <option value="WAITING_DEPOSIT">รอนำเข้า</option>
                <option value="CHECK_RETURNED">เช็คคืน</option>
                <option value="SUCCESS">เสร็จสิ้น</option>
              </select>
            </form-group>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="save()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
