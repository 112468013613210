import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { 
  CalculateFunctionService, 
  QuotationMitItemService, 
  QuotationService, 
  QuotationUtilsService 
} from 'src/app/services/quotations';
import {
  MITCPService,
  MITScopeService,
  UnitService, 
} from 'src/app/services';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-quotation-mit-item',
  templateUrl: './quotation-mit-item.component.html',
  styleUrls: ['./quotation-mit-item.component.css']
})
export class QuotationMitItemComponent implements OnInit {
  form : FormGroup;
  reset: boolean = false;
  pageLoad: boolean = false;

  @Input() customer_id = "";

  quotationMitItems: Array<any>;

  filter = {};
  load: Function = this.QuotationMitItemService.load;

  cps = [];
  units = [];
  scopeList = [];
  quotation_info_id: string = "";

  mitItem = null;
  mitItemIndex = null;
  dropDownData = null;
  totalPrice: number = 0;

  @Output() done = new EventEmitter<any>();

  constructor(
    private fbd: FormBuilder,
    private route: ActivatedRoute,
    private UnitService: UnitService,
    private MITCPService: MITCPService,
    private MITScopeService: MITScopeService,
    private QuotationService: QuotationService,
    private QuotationMitItemService: QuotationMitItemService,
    private CalculateFunctionService: CalculateFunctionService,

    public utils : QuotationUtilsService
  ) { 
    this.quotation_info_id = this.route.snapshot.paramMap.get("quotation_info_id");
  }

  async ngOnInit() {
    this.form = this.fbd.group({
      tag_no: [""],
      serial_no: [""]

    });

    this.filter = {
      quotation_info_id: this.quotation_info_id, 
      is_parent_quotation_mit_item_id_not_null: false, 
      'order_by': 'order_by:asc'
    };

    let promises = [];
    promises.push(
      this.UnitService.load().then((response) => {
        if (response.success) {
          this.units = response.resultData || [];
        }
      })
    )
    promises.push(
      this.QuotationService.getSumMit(this.quotation_info_id).then(res=>{
        this.totalPrice = res.resultData.quotation_mit_item_sum || 0;
      })
    )

    promises.push(
      this.MITCPService.load().then((response) => {
        if (response.success) {
          this.cps = response.resultData || [];
        }
      })
    )

    promises.push(
      this.MITScopeService.load(null).then((res) => {
        this.scopeList = res.resultData;
      })
    )
    let units = [];
    promises.push(
      this.UnitService.load().then((response) => {
        if (response.success) {
          this.units = response.resultData || [];
          units = this.utils.groupUnit(response.resultData) || [];
        }
      })
    )
    await Promise.all(promises);
    
    this.dropDownData = {
      scopeList: this.scopeList,
      cps: this.cps,
      units: units
    };

    await Promise.all(promises);
  }

  search(){
    this.filter = {
      ...this.filter,
      tag_no: this.form.value.tag_no? this.form.value.tag_no: undefined,
      serial_no: this.form.value.serial_no? this.form.value.serial_no: undefined,
    }
  }

  async onChangePage(quotationMitItems: Array<any>) {
    this.pageLoad = false;
    this.quotationMitItems = await Promise.all(quotationMitItems.map(async value=>{
      value['grand_total'];
      value['quotation_mit_item_details'] = [];
      value['quotation_mit_item_attributes'] = [];

      value.quotation_mit_item_attributes = value?.quotation_mit_item_attribute_info || [];

      value.quotation_mit_item_details = value?.quotation_mit_item_detail_info.filter(item=> item.is_non_calculate == "false") || [];

      value.quotation_mit_item_details = await Promise.all(value.quotation_mit_item_details.map(async (data: any)=>{
        let resultData = await this.mapMitItemData(data);

        return {
          ...resultData
        }
      }));

      value.grand_total = this.CalculateFunctionService.sumMit(value);

      value.quotation_mit_item_details.sort((a, b) => (a.order_by > b.order_by? 1 : -1));
      return value;
    }));
    this.done.emit(true);
  }

  async mapMitItemData(mit: any): Promise<any>{
    let points = [];
    let range = [];
    if(mit.total_amount > 0){
      if(mit.detail_input_type == 'point'){
        points = mit.quotation_mit_item_detail_points_info || [];

        points
        .sort((a, b) => (a.order_by > b.order_by? 1 : -1));
      }else{
        range = mit.quotation_mit_item_detail_ranges_info || [];
      }
    }

    points.map(point=>{

      if(point.cus_unit_id) point['cus_unit_info'] = this.units.find(unit=> unit.unit_id == point.cus_unit_id);
      if(point.mit_unit_id) point['mit_unit_info'] = this.units.find(unit=> unit.unit_id == point.mit_unit_id);

      if(point.cus_unit_info) point['cus_unit_name'] = point.cus_unit_info.unit_short_name_en 
      || point.cus_unit_info.unit_short_name_th;
      if(point.mit_unit_info) point['mit_unit_name'] = point.mit_unit_info.unit_short_name_en 
      || point.mit_unit_info.unit_short_name_th;
    });

    range.map(range=>{

      if(range.cus_unit_id) range['cus_unit_info'] = this.units.find(unit=> unit.unit_id == range.cus_unit_id);
      if(range.mit_unit_id) range['mit_unit_info'] = this.units.find(unit=> unit.unit_id == range.mit_unit_id);
    });

    return {
      ...mit,
      detail_type: mit.detail_input_type,
      quotation_mit_item_detail_range: {
        cus_min: range[0]?.cus_min || 0,
        cus_max: range[0]?.cus_max || 0, 
        mit_min: range[0]?.mit_min || 0,
        mit_max: range[0]?.mit_max || 0,
        cus_unit_id: range[0]?.cus_unit_id || "",
        mit_unit_id: range[0]?.mit_unit_id || "",
        cus_unit_name: range[0]?.cus_unit_info?.unit_short_name_en || "",
        mit_unit_name: range[0]?.mit_unit_info?.unit_short_name_en || "", 
        is_non_calculate: range[0]?.is_non_calculate,
        quotation_mit_item_detail_range_id: range[0]?.quotation_mit_item_detail_range_id || "", 
      },
      cus_unit_id: mit.unit_id,
      unit_name: mit.unit_short_name_en,
      quotation_mit_item_detail_points: points,
      min_range: mit.product_calibration_fee_info?.min_range,
      max_range:  mit.product_calibration_fee_info?.max_range,
    }
  }

  select(mitItem, mitItemIndex){
    this.mitItem = mitItem;
    this.mitItemIndex = mitItemIndex;
  }

  resetMitItem(event){
    this.mitItem = null;
    this.mitItemIndex = null;
  }
}
