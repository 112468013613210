import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  Input,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UnitTypeService } from "src/app/services/unit-type.service";
import { ENSignValidator } from "src/app/validators";

@Component({
  selector: "app-unit-type-edit",
  templateUrl: "./unit-type-edit.component.html",
  styleUrls: ["./unit-type-edit.component.css"],
})
export class UnitTypeEditComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  @Input("unit-type-id") unit_type_id: string;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private UnitTypeService: UnitTypeService
  ) {
    this.setForm();
  }
  setForm() {
    this.form = this.formBuilder.group({
      unit_type_name_th: [""],
      unit_type_name_en: ["", [Validators.required, ENSignValidator()]],
    });
  }
  async ngOnInit() {
    let response = await this.UnitTypeService.getById({
      unit_type_id: this.unit_type_id,
    });
    if (response.success) {
      let data = response.resultData;
      for (let key in this.form.value) {
        this.form.controls[key].setValue(data[key]);
      }
    }
  }
  // ngOnChanges(changes) {
  //   this.submitted = false;
  //   if (changes.unit_type_id && !changes.unit_type_id.firstChange) {
  //     this.ngOnInit();
  //   }
  // }
  async submit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    let data = {
      ...this.form.value,
      unit_type_name_th: this.form.value.unit_type_name_en,
      unit_type_id: this.unit_type_id,
    };
    let response = await this.UnitTypeService.update(data);
    this.done.emit(response.success || response.error);
    this.submitted = false;
    this.closeModalBtn.nativeElement.click();
  }
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      } else {
        this.ngOnInit();
      }
    });
  }
}
