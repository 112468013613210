<div class="app-content content">
    <div class="content-overlay"></div>
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-6 col-12 mb-2">
          <h3 class="content-header-title mb-1">รายการใบลดหนี้</h3>
          <div class="row breadcrumbs-top">
            <div class="breadcrumb-wrapper col-12">
              <ol class="breadcrumb">
                <li class="breadcrumb-item">
                  <a href="#" [routerLink]="['/credit-note']">เอกสารทางบัญชี</a>
                </li>
                <li class="breadcrumb-item active">ใบลดหนี้</li>
              </ol>
            </div>
          </div>
        </div>
        <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
          <div class="float-md-right">
            <button class="btn btn-outline-primary"                     
              data-target="#credit-note-filter"
              data-toggle="modal">
              <i class="feather icon-plus-circle icon-left"></i> สร้างใบลดหนี้
            </button>
          </div>
        </div>
      </div>
      <div class="content-body">
        <app-response-status
          *ngIf="status"
          [status]="status"
        ></app-response-status>
  
        <section class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h4 class="card-title mt-1">รายการใบลดหนี้</h4>
                <div class="heading-elements">
                  <search-box (search)="search($event)" [search_all]="params?.search_all"></search-box>
                </div>
              </div>
  
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row justify-content-between">
                    <div class="col-12 align-self-end">
                      <ul class="nav nav-pills nav-pill-bordered mb-1 font-small-2">
                        <li class="nav-item border-primary mr-50">
                            <a class="nav-link py-0" [class.active] = "!params?.doc_status_id" id="pill1" data-toggle="pill" href="#pill1" aria-expanded="true" (click)="searchStatus()">ทั้งหมด</a>
                        </li>
                        <li class="nav-item border-primary mr-50">
                            <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='DRAFT'" id="pill2" data-toggle="pill" href="#pill2" aria-expanded="false" (click)="searchStatus('DRAFT')">ร่าง ({{number_draft_status}})</a>
                        </li>
                        <li class="nav-item border-primary mr-50">
                            <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='WAITING_FOR_APPROVE'" id="pill3" data-toggle="pill" href="#pill3" aria-expanded="false" (click)="searchStatus('WAITING_FOR_APPROVE')">รออนุมัติ ({{number_waiting_for_approve_status}})</a>
                        </li>
                        <li class="nav-item border-primary mr-50">
                          <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='REJECTED'" id="pill4" data-toggle="pill" href="#pill4" aria-expanded="false" (click)="searchStatus('REJECTED')">ไม่อนุมัติ ({{number_rejected_status}})</a>
                        </li>
                        <li class="nav-item border-primary mr-50">
                          <a class="nav-link py-0" [class.active] = "params?.doc_status_id=='APPROVED'" id="pill5" data-toggle="pill" href="#pill5" aria-expanded="false" (click)="searchStatus('APPROVED')">อนุมัติ ({{number_approved_status}})</a>
                        </li>
                        <li class="nav-item border-light">
                          <a class="nav-link text-light py-0" [class.active] = "params?.doc_status_id=='CANCELED'" id="pill22" data-toggle="pill" href="#pill22" aria-expanded="false" (click)="searchStatus('CANCELED')">ยกเลิก ({{number_canceled_status}})</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <loading *ngIf="isLoading"></loading>
                  <div class="table-responsive" *ngIf="!isLoading">
                    <table class="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>เลขที่เอกสาร</th>
                          <th>ชื่อลูกค้า</th>
                          <th>วันที่ออก</th>
                          <th>อ้างถึง</th>
                          <th class="text-center">สถานะ</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let credit_note of credit_notes">
                          <th>
                            {{credit_note.doc_no}}
                          </th>
                          <td>
                            [{{credit_note?.customer_info?.customer_code}}] {{credit_note?.customer_info?.company_name}}
                          </td>
                          <td>
                            {{credit_note.doc_date | thai_date: "short":"date" | dash}}
                          </td>
                          <td>
                            {{credit_note?.ref_doc_type =='TAX_INVOICE'? credit_note?.ref_acc_document_info?.doc_no_company:credit_note?.ref_acc_document_info?.doc_no}}
                          </td>
                          <td class="text-center">
                            {{credit_note.doc_status_info?.document_status_name_th || credit_note.doc_status_id}}
                          </td>
                          <td>
                            <a type="button" class="btn mr-1 mb-1 btn-primary btn-sm text-nowrap"
                            [routerLink]="[
                              '/credit-note/' + credit_note.acc_credit_note_info_id + '/show'
                            ]">
                              <i class="fa fa-eye icon-left"></i> ดูข้อมูล
                            </a>
                            <a 
                              type="button" class="btn mr-1 mb-1 btn-blue btn-sm"
                              target="_blank"
                              [routerLink]="[
                                '/acc-document/credit-debit/' + credit_note.acc_credit_note_info_id +'/credit'
                              ]">
                              <i class="fa fa-print icon-left"></i> พิมพ์เอกสาร
                            </a>
                          </td>
                        </tr>
                        <tr *ngIf="!credit_notes.length">
                          <td colspan="6" class="text-center">ไม่มีข้อมูล</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
  
                  <app-paginator
                    [filter]="filter"
                    [reset]="reset"
                    [fetch]="load"
                    [initialPage]="initialPage"
                    (filterLimit)="getFilterLimit($event)"
                    (changePage)="onChangePage($event)"
                  ></app-paginator>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>

  <app-credit-note-create-filter>
  </app-credit-note-create-filter>