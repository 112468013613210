import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerAddressService, CustomerInfoService } from 'src/app/services';
import { AccCreditNoteInfoService } from 'src/app/services/credit-note';
import { AccInvoiceInfoService } from 'src/app/services/invoice';
import { CalculateFunctionService, QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';
import moment from 'moment';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Observable } from 'rxjs';
import { Location } from "@angular/common";

@Component({
  selector: 'app-credit-note-show',
  templateUrl: './credit-note-show.component.html',
  styleUrls: ['./credit-note-show.component.css']
})
export class CreditNoteShowComponent implements OnInit {
  form: FormGroup;
  acc_credit_note_info_id: string;
  
  pageLoad:boolean = false;
  submitted:boolean = false;

  customerList: any = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];

  state: any;
  status: any;

  customer_info = null;
  creditNoteInfo = null;
  creditNoteItem = null;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;
  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private CustomerAddressService: CustomerAddressService,
    private AccCreditNoteInfoService: AccCreditNoteInfoService,

    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,

    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) {
    this.acc_credit_note_info_id = 
    this.route.snapshot.paramMap.get("acc_credit_note_info_id"); 
  }

  async ngOnInit() {
    this.createForm();

    this.userInfo$.subscribe(user=>{
      if(!user) return;
      this.userInfo = user;
    })

    await this.AccCreditNoteInfoService.getById({acc_credit_note_info_id: this.acc_credit_note_info_id}).then(async res=>{
      let resultData = res.resultData || {};
      this.creditNoteInfo = resultData;
      this.customer_info = res.resultData.customer_info;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: this.customer_info? `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''} ${this.customer_info?.branch?" (" + this.customer_info?.branch + ")": ""}`: '',
        ...resultData
      }

      this.form.patchValue(data);

      if(resultData.customer_address_id){
        await this.CustomerAddressService.getById({ customer_address_id: resultData.customer_address_id }).then((res)=>{
          if(res.success){
            var resultData = res.resultData;
            var address = this.utils.genAddress(resultData);

            this.form.controls['customer_address_name'].setValue(resultData.address_name + (resultData.branch?' ('+ resultData.branch +')': '') + ` แผนก: ${resultData.department}`);
            this.form.controls['customer_address'].setValue(address); 
          }else{
            this.form.controls['customer_address_name'].setValue(null);
            this.form.controls['customer_address'].setValue(null); 
          } 
        })
      }

      if(resultData.ref_doc_type == 'INVOICE'){
        await this.AccInvoiceInfoService.getById({acc_invoice_info_id: resultData.ref_acc_document_info_id})
        .then((res) => {
          let resultData = res.resultData;
          this.form.get("ref_acc_document_doc_no").setValue(resultData.doc_no);
        });
      }else{
        await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: resultData.ref_acc_document_info_id})
        .then((res) => {
          let resultData = res.resultData;
          this.form.get("ref_acc_document_doc_no").setValue(resultData.doc_no_company);
        });
      }
    })

    this.pageLoad = true;
  }

  createForm(){
    this.form = this._fbd.group({
      acc_credit_note_info_id: [this.acc_credit_note_info_id],
      ref_acc_document_info_id: [""],
      ref_acc_document_doc_no: [""],
      credit_note_type: [""],
      doc_no: [""],
      revise_count: [""],
      doc_date: [""],
      customer_id: [""],
      customer_name: [""],
      customer_address: [""],
      customer_address_id: [""],
      customer_address_name: [""],
      doc_note: [""],
      doc_reason: [""],
      ref_grand_total_before_vat: [0.00],
      new_amount: [0.00],
      difference: [0.00],
      tax: ["7"],
      grand_total: [0.00],
      doc_status_id: [""],
    });
    this.form.disable(); 
  }

  async cancel(){
    let response = await this.AccCreditNoteInfoService.update({
      ...this.creditNoteInfo,
      doc_status_id: 'CANCELED'
    });
    if (response.success) {
      await Promise.all(
        this.creditNoteItem.items.map(async (value: any, index: number)=>{

          if(value.item_type == 'calibration_service'){
            let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
            await this.QuotationMitItemService.update({
              ...quotationMitResponse.resultData[0],
      
              acc_credit_note_info_id: "",
              acc_credit_note_info_doc_no: "",
              acc_credit_note_info_status: ""
            })
          }else if(value.item_type == 'product'){
            let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
            await this.QuotationProductItemService.update({
              ...quotationProductResponse.resultData,
      
              acc_credit_note_info_id: "",
              acc_credit_note_info_doc_no: "",
              acc_credit_note_info_status: ""
            })
          }
      }));

      this.router.navigateByUrl("/credit-note", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async approve(){
    // TOTAL_AMOUNT_CREDITED
    let accResponse = await this.AccCreditNoteInfoService.update({
      ...this.creditNoteInfo,
      doc_status_id: 'APPROVED'
    });

    if (accResponse.success) {
      if(this.creditNoteInfo.credit_note_type == 'FULL_AMOUNT'){
        if(this.creditNoteInfo.ref_doc_type == 'INVOICE'){
          let accInvoiceResponse = await this.AccInvoiceInfoService.getById({acc_invoice_info_id: this.creditNoteInfo.ref_acc_document_info_id});
          if(accInvoiceResponse.success){
            let response = await this.AccInvoiceInfoService.update({
              ...accInvoiceResponse.resultData,
              doc_status_id: 'TOTAL_AMOUNT_CREDITED',
              doc_duedate : moment(accInvoiceResponse.resultData.doc_duedate).format('YYYY-MM-DD')
            });
  
            await this.AccTaxInvoiceInfoService.load(null, {acc_invoice_info_id: accInvoiceResponse.resultData.acc_invoice_info_id}).then(async res=>{
              if(res.resultData.length > 0){
                res.resultData.map(async item=>{
                  let taxResponse = await this.AccTaxInvoiceInfoService.update({
                    ...item,
                    doc_status_id: 'TOTAL_AMOUNT_CREDITED',
                    doc_duedate : moment(item.doc_duedate).format('YYYY-MM-DD')
                  });
                })
              }
            })

            await Promise.all(
              this.creditNoteItem.items.map(async (value: any, index: number)=>{
                if(value.item_type == 'calibration_service'){
                  let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
                  await this.QuotationMitItemService.update({
                    ...quotationMitResponse.resultData[0],
            
                    acc_invoice_info_status: "TOTAL_AMOUNT_CREDITED",
                    acc_tax_invoice_info_status: quotationMitResponse.resultData[0].acc_tax_invoice_info_id? "TOTAL_AMOUNT_CREDITED":"",
                    acc_credit_note_info_status: "APPROVED"
                  })
                }else if(value.item_type == 'product'){
                  let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
                  await this.QuotationProductItemService.update({
                    ...quotationProductResponse.resultData,
            
                    acc_invoice_info_status: "TOTAL_AMOUNT_CREDITED",
                    acc_tax_invoice_info_status: quotationProductResponse.resultData.acc_tax_invoice_info_id? "TOTAL_AMOUNT_CREDITED":"",
                    acc_credit_note_info_status: "APPROVED"
                  })
                }
            }));
          }
        }else{
          let accTaxInvoiceResponse = await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: this.creditNoteInfo.ref_acc_document_info_id});
          if(accTaxInvoiceResponse.success){
            let taxResponse = await this.AccTaxInvoiceInfoService.update({
              ...accTaxInvoiceResponse.resultData,
              doc_status_id: 'TOTAL_AMOUNT_CREDITED',
              doc_duedate : moment(accTaxInvoiceResponse.resultData.doc_duedate).format('YYYY-MM-DD')
            });
  
            await this.AccInvoiceInfoService.getById({acc_invoice_info_id: accTaxInvoiceResponse.resultData.acc_invoice_info_id}).then(async res=>{
              let response = await this.AccInvoiceInfoService.update({
                ...res.resultData,
                doc_status_id: 'TOTAL_AMOUNT_CREDITED',
                doc_duedate : moment(res.resultData.doc_duedate).format('YYYY-MM-DD')
              });
            })

            await Promise.all(
              this.creditNoteItem.items.map(async (value: any, index: number)=>{
                if(value.item_type == 'calibration_service'){
                  let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
                  await this.QuotationMitItemService.update({
                    ...quotationMitResponse.resultData[0],
            
                    acc_invoice_info_status: quotationMitResponse.resultData[0].acc_tax_invoice_info_id? "TOTAL_AMOUNT_CREDITED":"",
                    acc_tax_invoice_info_status: "TOTAL_AMOUNT_CREDITED",
                    acc_credit_note_info_status:  "APPROVED"
                  })
                }else if(value.item_type == 'product'){
                  let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
                  await this.QuotationProductItemService.update({
                    ...quotationProductResponse.resultData,
            
                    acc_invoice_info_status: quotationProductResponse.resultData.acc_tax_invoice_info_id? "TOTAL_AMOUNT_CREDITED":"",
                    acc_tax_invoice_info_status: "TOTAL_AMOUNT_CREDITED",
                    acc_credit_note_info_status: "APPROVED"
                  })
                }
            }));
          }
        }
      }

      this.router.navigateByUrl("/credit-note", {
        state: {
          status: accResponse.success,
        },
      });
    } else {
      this.status = accResponse.error;
    }
  }

  
  async reject(){
    let response = await this.AccCreditNoteInfoService.update({
      ...this.creditNoteInfo,
      doc_status_id: 'REJECTED'
    });
    if (response.success) {
      await Promise.all(
        this.creditNoteItem.items.map(async (value: any, index: number)=>{
          this.updateMitItem(value, response);
        }));
      this.router.navigateByUrl("/credit-note", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async updateMitItem(value, accCreditNoteResponse){
    if(value.item_type == 'calibration_service'){
      let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
      await this.QuotationMitItemService.update({
        ...quotationMitResponse.resultData[0],

        acc_credit_note_info_id: accCreditNoteResponse.resultData.acc_credit_note_info_id,
        acc_credit_note_info_doc_no: accCreditNoteResponse.resultData.doc_no,
        acc_credit_note_info_status: accCreditNoteResponse.resultData.doc_status_id
      })
    }else if(value.item_type == 'product'){
      let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
      await this.QuotationProductItemService.update({
        ...quotationProductResponse.resultData,

        acc_credit_note_info_id: accCreditNoteResponse.resultData.acc_credit_note_info_id,
        acc_credit_note_info_doc_no: accCreditNoteResponse.resultData.doc_no,
        acc_credit_note_info_status: accCreditNoteResponse.resultData.doc_status_id
      })
    }
  }

  getItems(event){
    if(event){
      this.creditNoteItem = event;
    }
  }

  back(): void {
    this.location.back();
  }
}
