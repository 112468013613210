import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit, Output, EventEmitter, Input, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AccInvoiceItemService } from 'src/app/services/invoice';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';

@Component({
  selector: 'app-invoice-item',
  templateUrl: './invoice-item.component.html',
  styleUrls: ['./invoice-item.component.css']
})
export class InvoiceItemComponent implements OnInit {
  pageLoad = false;
  pageType: string = 'create';
  invoice_id: string;
  acc_invoice_info_id: string;

  invoiceItems = [];
  delelteInvoiceItems: any = [];

  invoice = null;
  @Input('state') state: any;
  @Input('customer_info') customer_info: any;
  @Output() done = new EventEmitter<any>();
  subject: Subject<string> = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private AccInvoiceItemService: AccInvoiceItemService,

    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) { 
    this.acc_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_invoice_info_id");

    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
    this.route.queryParams.subscribe(params => {
      this.invoice_id = params['invoice_id'];
    });
  }

  async ngOnInit() {
    this.pageLoad = false;

    if(this.acc_invoice_info_id){
      await this.AccInvoiceItemService.load(null, {acc_invoice_info_id: this.acc_invoice_info_id})
      .then(async res=>{
        this.invoiceItems = res.resultData || [];
        this.invoiceItems = this.invoiceItems.map(value=>{
          return {
            ...value,
            data: {
              ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
              quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
            }
          }
        })
        this.invoiceItems.sort(function(a, b) {
          return a.order_by - b.order_by;
        });
        await this.sumCount();
      })
    }else{
      if(this.pageType == 'create' && (this.state || this.invoice_id)){
        if(this.state?.billing){
          await this.submitInvoiceItem(this.state.billing);
        }else{
          await this.AccInvoiceItemService.load(null, {acc_invoice_info_id: this.invoice_id})
          .then(async res=>{
            this.invoiceItems = res.resultData || [];
            this.invoiceItems = this.invoiceItems.map(value=>{
              return {
                ...value,
                data: {
                  ...value?.quotation_mit_item_detail_info[0]?.quotation_mit_item_info,
                  quotation_mit_item_detail_info: value?.quotation_mit_item_detail_info || []
                }
              }
            })
            this.invoiceItems.sort(function(a, b) {
              return a.order_by - b.order_by;
            });
            await this.sumCount();
          })
        }
      }
    }

    this.subject.pipe(
      debounceTime(1000)
    ).subscribe(x => 
      this.sumCount()  
    );
  }

  async submitInvoiceItem(event){
    if(event.length > 0){
      event.map(item=>{
        if(item.item_type=='product'){
          this.invoiceItems.push(item);
        }else{
          let checkDuplicate = this.invoiceItems.find(value=> value.quotation_item_id == item.quotation_mit_item_id);
          if(!checkDuplicate){
            this.invoiceItems.push(item);
          }
        }
      })
    }
    await this.sumCount();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.invoiceItems, event.previousIndex, event.currentIndex);
  }

  select(invoice){
    this.invoice = invoice;
  }

  onKeyup(){
    this.subject.next();
  }

  async sumCount(){
    this.invoiceItems.map(item=>{
      item.price = this.utils.transformDecimal(this.utils.convertStingToNumber(item.price));
    })
    this.sentData();
  }

  async delete(closeModalEl){
    const index = this.invoiceItems.indexOf(this.invoice);
    this.delelteInvoiceItems.push(this.invoice);
    if (index > -1) {
      this.invoiceItems.splice(index, 1);
    }
    await this.sumCount();
    closeModalEl.click();
  }

  async sentData(){
    this.done.emit({
      invoiceItems: this.invoiceItems,
      delelteInvoiceItems: this.delelteInvoiceItems
    });
  }
}
