import { Injectable } from "@angular/core";
import { 
    AccRecivedVoucherInfoService,
    AccRecivedVoucherItemService
} from '.';
import { AccPaymentService } from "../invoice";

@Injectable({
   providedIn: "root"
})
export class CoreService {
  constructor(
    private AccRecivedVoucherInfoService: AccRecivedVoucherInfoService,
    private AccRecivedVoucherItemService: AccRecivedVoucherItemService,
    private AccPaymentService: AccPaymentService
  ) {}

  async createReceivedVoucher(
    data: any,
    receiveVoucherItem?: any
    ){
    if(data){
        let received_info = data;
        try {
          let ReceivedVoucherResponse = await this.AccRecivedVoucherInfoService.create({
            ...received_info,
          });
          if (ReceivedVoucherResponse.success) {

            if(receiveVoucherItem?.receivedVoucherItems){
              await this.saveVoucherList(receiveVoucherItem?.receivedVoucherItems, ReceivedVoucherResponse);
            }   

            return {
              status: true,
              message: ReceivedVoucherResponse.success
            }
          } else {
            if (ReceivedVoucherResponse.resultCode === "40900") {
              return {
                status: false,
                message: 'codeDuplicate',
                error: ReceivedVoucherResponse.error
              }
            } else {
              throw ReceivedVoucherResponse.error;
            }
          }
        } catch (error) {
          return {
            status: false,
            message: error
          }
        }
      }
  }

  async editReceivedVoucher(
    data: any, 
    receiveVoucherItem?: any
    ){
    if(data){

      let received_info = data;
      try {
        let ReceivedVoucherResponse = await this.AccRecivedVoucherInfoService.update({
          ...received_info,
        });
        if (ReceivedVoucherResponse.success) {
          if(receiveVoucherItem?.receivedVoucherItems){
            await this.saveVoucherList(receiveVoucherItem?.receivedVoucherItems, ReceivedVoucherResponse);
          }   

          if(receiveVoucherItem?.delelteReceivedVoucherItems){
            await Promise.all(receiveVoucherItem.delelteReceivedVoucherItems.map(async (value)=>{

              if(value.acc_received_voucher_item_id){
                let receiveVoucherItemResponse = await this.AccRecivedVoucherItemService.delete({
                  ...value
               });
               if (receiveVoucherItemResponse.success) {
                let  paymentResponse = await this.AccPaymentService
                .update({
                  ...value.data,
                  acc_received_voucher_info_id: "",
                });
                if(!paymentResponse.success){
                  throw paymentResponse.error
                }
               }else{
                throw receiveVoucherItemResponse.error;
               }
              }
            }));
          }     
          return {
            status: true,
            message: ReceivedVoucherResponse.success
          }
        } else {
          if (ReceivedVoucherResponse.resultCode === "40900") {
            return {
              status: false,
              message: 'codeDuplicate',
              error: ReceivedVoucherResponse.error
            }
          } else {
            throw ReceivedVoucherResponse.error;
          }
        }
      } catch (error) {
        return {
          status: false,
          message: error
        }
      }
    }
  }

  async saveVoucherList(receivedVoucherList, ReceivedVoucherResponse){
    await Promise.all(
      receivedVoucherList.map(async (value: any, index: number)=>{

        if(value.acc_received_voucher_item_id){
          let receiveVoucherItemResponse = await this.AccRecivedVoucherItemService
          .update({
            ...value,
            acc_received_voucher_info_id: ReceivedVoucherResponse.resultData.acc_received_voucher_info_id,
          });
          if(!receiveVoucherItemResponse.success){
            throw receiveVoucherItemResponse.error
          }
        }else{

          let  receiveVoucherItemResponse = await this.AccRecivedVoucherItemService
          .create({
            ...value,
            acc_received_voucher_info_id: ReceivedVoucherResponse.resultData.acc_received_voucher_info_id,
          });
          if(receiveVoucherItemResponse.success){

           if(value.data.acc_payment_id){
            let  paymentResponse = await this.AccPaymentService
            .update({
              ...value.data,
              acc_received_voucher_info_id: ReceivedVoucherResponse.resultData.acc_received_voucher_info_id,
              created_acc_received_voucher_info_id: value?.created_acc_received_voucher_info_id? ReceivedVoucherResponse.resultData.acc_received_voucher_info_id: undefined,
            });
            if(!paymentResponse.success){
              throw paymentResponse.error
            }
           }
          }else{
            throw receiveVoucherItemResponse.error
          }
        }
    }));
  }
}

