import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ElementRef,
  ViewChild,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { NavigationStart, Router } from "@angular/router";

@Component({
  selector: 'app-transport-cancel',
  templateUrl: './transport-cancel.component.html',
  styleUrls: ['./transport-cancel.component.css']
})
export class TransportCancelComponent implements OnInit {
  @Output() save: EventEmitter<any> = new EventEmitter();
  @Output() close: EventEmitter<any> = new EventEmitter();
  @ViewChild("closeModal") private closeModal: ElementRef;

  constructor(private router: Router) {
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModal.nativeElement.click();
      }
    });
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
  }

  async onSave() {
    this.save.emit(this.closeModal.nativeElement);
  }

  onClose() {
    this.close.emit(null);
  }
}
