import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class MITCPService {
  async create({ cp_name, mit_scope_id }) {
    let result = null;
    try {
      result = await Axios().post(`/mit-cp`, {
        cp_name,
        mit_scope_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({ mit_cp_id, cp_name, mit_scope_id }) {
    let result = null;
    try {
      result = await Axios().put(`/mit-cp/${mit_cp_id}`, {
        cp_name,
        mit_scope_id
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ mit_cp_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/mit-cp/${mit_cp_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/mit-cp`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ mit_cp_id }) {
    let result = null;
    try {
      result = await Axios().get(`/mit-cp/${mit_cp_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
