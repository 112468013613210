import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccDebitNoteEditHistoryService {
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-debit-note-edit-history`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
