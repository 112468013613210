import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { NgSelect2Customer } from 'src/app/services/quotations';

import { 
  NgSelect2Quotation 
} from 'src/app/services/transport-receives/ng-select2-quotation.service';
import { UserInfoState } from 'src/app/store/user/user.state';

@Component({
  selector: 'app-transport-receive-create-filter',
  templateUrl: './transport-receive-create-filter.component.html',
  styleUrls: ['./transport-receive-create-filter.component.css']
})
export class TransportreceiveCreateFilterComponent implements OnInit {
  public form: FormGroup;
  public submitted:boolean = false;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  public select2OptionQuotation = null;
  public select2OptionCustomer= null;

  public type: string;
  public tab: string = "customer";

  public reset = false;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  public receiveTypes = [
    {id: "WITH_QT", text: "เครื่องมือที่มีใบเสนอราคา"},
    {id: "WITTHOUT_QT", text: "เครื่องมือที่ไม่มีใบเสนอราคา"},
  ];

  constructor(   
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private NgSelect2Quotation: NgSelect2Quotation,
    private NgSelect2Customer: NgSelect2Customer,
    private router: Router
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.setForm();
  }

  async ngOnInit() {}

  setForm(){
    this.form = this.formBuilder.group({
      quotation_info_id: ["", [Validators.required]],
      customer_id: ["", [Validators.required]],
      receive_type: ["WITH_QT", [Validators.required]]
    });
    
    this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;


      if(userInfo?.is_sales_supervisor || userInfo?.is_co_sales_supervisor || userInfo?.is_co_sales || userInfo?.is_sales_manager || userInfo?.emp_info?.emp_department_id.startsWith("TRAN_DEP")){
        this.select2OptionCustomer = this.NgSelect2Customer.select2Options("?limit=10&&exclude_customer_state_id=Free&&is_exclude_address=true");
      }else{
        this.select2OptionCustomer = this.NgSelect2Customer.select2Options("?limit=10&&exclude_customer_state_id='Free'&&is_exclude_address=true&&sales_assignment_info.user_id=" + userInfo.user_id);
      }
      this.select2OptionQuotation = this.NgSelect2Quotation.select2Options("?limit=10&&doc_status_id=ACCEPTED");
    });
    this.reset = false;
    setTimeout(() => {
      this.reset = true;
    }, 50);
  }

  select(event, type: string){
    this.type = type;
    if(type == "quotation"){
      this.form.get("customer_id").setValidators(null)
      this.form.get("quotation_info_id").setValue(event);
      this.form.get("quotation_info_id").setValidators([Validators.required]);
    }else{
      this.form.get("quotation_info_id").setValidators(null);
      this.form.get("customer_id").setValue(event);
      this.form.get("customer_id").setValidators([Validators.required]);
    }
    this.form.get("customer_id").updateValueAndValidity();
    this.form.get("quotation_info_id").updateValueAndValidity();
  }

  changeTab(tab: string){
    this.tab = tab;
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.closeModalBtn.nativeElement.click();
    if(this.tab == 'quotation'){
      this.router.navigateByUrl("/transport-receive/"+ this.form.value.quotation_info_id +"/create?type=" + this.tab + "&&receive_type=" + this.form.value.receive_type);
    }else{
      this.router.navigateByUrl("/transport-receive/"+ this.form.value.customer_id +"/create?type=" + this.tab + "&&receive_type=" + this.form.value.receive_type);
    }
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
      }
    });
  }
}
