<div class="app-content content">
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">แก้ไขข้อมูลลูกค้า</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/customer']">จัดการข้อมูลลูกค้า</a>
              </li>
              <li class="breadcrumb-item active">แก้ไขข้อมูลลูกค้า</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            [routerLink]="['/customer']"
            type="button"
            class="btn btn-warning btn-min-width mr-1"
          >
            <i class="fa fa-times"></i> ยกเลิก
          </button>
          <button
            (click)="submit()"
            type="button"
            class="btn btn-primary btn-min-width"
          >
            <i class="fa fa-save"></i> บันทึก
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>
      <section class="basic-elements">
        <div class="row">
          <div class="col-md-12">
            <div class="card">
              <div class="card-content collapse show">
                <div class="card-body">
                  <form
                    autocomplete="off"
                    class="form"
                    role="form"
                    action="/dashboard/user-accounts"
                    method="post"
                    [formGroup]="form"
                  >
                    <div class="form-body">
                      <h4 class="mb-2">ข้อมูลบริษัท</h4>
                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.company_name"
                            [submitted]="submitted"
                          >
                            <label for="company_name">
                              ชื่อบริษัท <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="company_name"
                              class="form-control"
                              placeholder="ชื่อบริษัท"
                              formControlName="company_name"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.tax_id"
                            [submitted]="submitted"
                          >
                            <label for="tax_id">เลขประจำตัวผู้เสียภาษี</label>
                            <input
                              type="text"
                              id="tax_id"
                              class="form-control"
                              placeholder="เลขประจำตัวผู้เสียภาษี"
                              formControlName="tax_id"
                              maxlength="13"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.branch"
                            [submitted]="submitted"
                          >
                            <label for="branch">สาขา / สำนักงาน <span class="danger">*</span></label>
                            <input
                              type="text"
                              id="branch"
                              class="form-control"
                              placeholder="สาขา / สำนักงาน"
                              formControlName="branch"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.department"
                            [submitted]="submitted"
                          >
                            <label for="department">แผนก <span class="danger">*</span></label>
                            <input
                              type="text"
                              id="department"
                              class="form-control"
                              placeholder="แผนก"
                              formControlName="department"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.industry_id"
                            [submitted]="submitted"
                          >
                            <label for="industry_id">
                              กลุ่มอุตสาหกรรม <span class="danger">*</span>
                            </label>
                            <select
                              class="form-control"
                              id="industry_id"
                              formControlName="industry_id"
                            >
                              <option value="">กรุณาเลือกข้อมูล</option>
                              <option
                                *ngFor="let item of industryGroups"
                                [value]="item.industry_group_id"
                              >
                                {{ item.industry_group_name }}</option
                              >
                            </select>
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.customer_source"
                            [submitted]="submitted"
                          >
                            <label for="customer_source"
                              >แหล่งที่มาของลูกค้า <span class="danger">*</span></label
                            >
                            <input
                              type="text"
                              id="customer_source"
                              class="form-control"
                              placeholder="แหล่งที่มาของลูกค้า"
                              formControlName="customer_source"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-8 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.address"
                            [submitted]="submitted"
                          >
                            <label for="address">
                              ที่อยู่ <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="address"
                              class="form-control"
                              placeholder="ที่อยู่"
                              formControlName="address"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.province_id"
                            [submitted]="submitted"
                          >
                            <label for="province_id">
                              จังหวัด
                            </label>
                            <select
                              class="form-control"
                              id="province_id"
                              formControlName="province_id"
                            >
                              <option value="">จังหวัด</option>
                              <option
                                *ngFor="let item of provinces"
                                [value]="item.province_id"
                              >
                                {{ item.province_name_th }}</option
                              >
                            </select>
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.amphure_id"
                            [submitted]="submitted"
                          >
                            <label for="amphure_id">
                              เขต/อำเภอ
                            </label>
                            <select
                              class="form-control"
                              id="amphure_id"
                              formControlName="amphure_id"
                            >
                              <option value="">เขต/อำเภอ</option>
                              <option
                                *ngFor="let item of amphures"
                                [value]="item.amphure_id"
                                >{{ item.amphure_name_th }}
                              </option>
                            </select>
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12">
                          <form-group
                            [control]="form.controls.district_id"
                            [submitted]="submitted"
                          >
                            <label for="district_id">
                              แขวง/ตำบล
                            </label>
                            <select
                              class="form-control"
                              id="district_id"
                              formControlName="district_id"
                            >
                              <option value="">แขวง/ตำบล</option>
                              <option
                                *ngFor="let item of districts"
                                [value]="item.district_id"
                              >
                                {{ item.district_name_th }}</option
                              >
                            </select>
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.geography_id"
                            [submitted]="submitted"
                          >
                            <label for="geography_id">
                              รหัสไปรษณีย์
                            </label>
                            <input
                              type="text"
                              id="geography_id"
                              class="form-control"
                              placeholder="รหัสไปรษณีย์"
                              formControlName="geography_id"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.phonenumber_1"
                            [submitted]="submitted"
                          >
                            <label for="phonenumber_1">โทรศัพท์ 1 <span class="danger" *ngIf="form.enabled">*</span></label>
                            <input
                              type="text"
                              id="phonenumber_1"
                              class="form-control"
                              placeholder="โทรศัพท์ 1"
                              formControlName="phonenumber_1"
                            />
                          </form-group>
                        </div>
                  
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.phonenumber_2"
                            [submitted]="submitted"
                          >
                            <label for="phonenumber_2">โทรศัพท์ 2</label>
                            <input
                              type="text"
                              id="phonenumber_2"
                              class="form-control"
                              placeholder="โทรศัพท์ 2"
                              formControlName="phonenumber_2"
                            />
                          </form-group>
                        </div>
                      </div>
                  
                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group [control]="form.controls.email_1" [submitted]="submitted">
                            <label for="email_1">E-mail 1</label>
                            <input
                              type="text"
                              id="email_1"
                              class="form-control"
                              placeholder="E-mail 1"
                              formControlName="email_1"
                            />
                          </form-group>
                        </div>
                  
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group [control]="form.controls.email_2" [submitted]="submitted">
                            <label for="email_2">E-mail 2</label>
                            <input
                              type="text"
                              id="email_2"
                              class="form-control"
                              placeholder="E-mail 2"
                              formControlName="email_2"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.fax_1"
                            [submitted]="submitted"
                          >
                            <label for="fax_1">
                              Fax
                            </label>
                            <input
                              type="text"
                              id="fax_1"
                              class="form-control"
                              placeholder="Fax"
                              formControlName="fax_1"
                            />
                          </form-group>
                        </div>

                      </div>

                      <h4 class="mb-2">ข้อมูลผู้ติดต่อ</h4>
                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.firstname"
                            [submitted]="submitted"
                          >
                            <label for="firstname">
                              ชื่อ <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="firstname"
                              class="form-control"
                              placeholder="ชื่อ"
                              formControlName="firstname"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.lastname"
                            [submitted]="submitted"
                          >
                            <label for="lastname">นามสกุล</label>
                            <input
                              type="text"
                              id="lastname"
                              class="form-control"
                              placeholder="นามสกุล"
                              formControlName="lastname"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.nickname"
                            [submitted]="submitted"
                          >
                            <label for="nickname">ชื่อเล่น</label>
                            <input
                              type="text"
                              id="nickname"
                              class="form-control"
                              placeholder="ชื่อเล่น"
                              formControlName="nickname"
                            />
                          </form-group>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.phonenumber"
                            [submitted]="submitted"
                          >
                            <label for="phonenumber">
                              โทรศัพท์ <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="phonenumber"
                              class="form-control"
                              placeholder="โทรศัพท์"
                              formControlName="phonenumber"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.email"
                            [submitted]="submitted"
                          >
                            <label for="email">
                              E-mail <span class="danger">*</span>
                            </label>
                            <input
                              type="text"
                              id="email"
                              class="form-control"
                              placeholder="E-mail"
                              formControlName="email"
                            />
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.line_id"
                            [submitted]="submitted"
                          >
                            <label for="line_id">Line Id</label>
                            <input
                              type="text"
                              id="line_id"
                              class="form-control"
                              placeholder="Line Id"
                              formControlName="line_id"
                            />
                          </form-group>
                        </div>
                      </div>

                      <h4 class="mb-2">พนักงานขาย</h4>
                      <div class="row">
                        <div
                          class="col-xl-4 col-lg-6 col-md-12 mb-1"
                          *ngIf="
                            (userInfo$ | async)?.emp_info?.order_by > 1 ||
                            (userInfo$ | async)?.user_role_id === 'ADMIN'
                          "
                        >
                          <form-group
                            [control]="form.controls.emp_code"
                            [submitted]="submitted"
                          >
                            <label for="emp_code">
                              ผู้รับผิดชอบ <span class="danger">*</span>
                            </label>
                            <!-- <input
                              type="text"
                              id="emp_code"
                              class="form-control"
                              placeholder="รหัสผู้รับผิดชอบ"
                              formControlName="emp_code"
                              (blur)="queryEmployee()"
                            /> -->
                            <employee-select
                              [selected]="form.value.sales_assignment_id"
                              [placeholder]="'รหัสผู้รับผิดชอบ'"
                              (valueChanged)="queryEmployee($event)"
                            ></employee-select>
                          </form-group>
                        </div>

                        <div class="col-xl-4 col-lg-6 col-md-12 mb-1">
                          <form-group
                            [control]="form.controls.sale_name"
                            [submitted]="submitted"
                          >
                            <label for="sale_name">ชื่อผู้รับผิดชอบ</label>
                            <input
                              type="text"
                              id="sale_name"
                              class="form-control"
                              placeholder="ชื่อผู้รับผิดชอบ"
                              formControlName="sale_name"
                              readonly
                            />
                          </form-group>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</div>
