import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PDF } from '../../../quotation/quotation-pdf/pdf.service';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

import jsPDF from 'jspdf';  
import domtoimage from 'dom-to-image-more'

@Component({
  selector: 'app-confirmation-letter',
  templateUrl: './confirmation-letter.component.html',
  styleUrls: ['./confirmation-letter.component.css']
})
export class ConfirmationLetterComponent implements OnInit {
  //boolean
  pageLoad: boolean = true;
  canAccess: boolean = false;
  isEmployeeShow: boolean = false;
  //array
  cars = [];
  carList = [];
  carTypes = [];
  engineerList = [];
  trainingList = [];
  customerContactList = [];
  jobOrderContactList = [];
  //string
  job_order_info_id: string = "";
  token: string = "";
  signature_file: string = "";
  uploadPath: string = "";

  jobOrderInfo = null;
  constructor(
    private getDataPdf: PDF,
    private titleService: Title,
    private route: ActivatedRoute,
  ) { 
    this.job_order_info_id = this.route.snapshot.paramMap.get("job_order_info_id");
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
    this.uploadPath = environment.uploadPath;
  }

  async ngOnInit() {
    this.pageLoad = false;
    try {
      await this.getDataPdf
      .get(this.token, "/job-order-info/" + this.job_order_info_id).then(async (res) => {

        if(res.resultData && (res.resultData.job_order_type == 'ICS' || res.resultData.job_order_type == 'TRN')){
          this.canAccess = true;
          this.jobOrderInfo ={
            ...res.resultData,
            service_time: res.resultData?.service_time? res.resultData?.service_time?.split(":")[0]+ ':'+ res.resultData?.service_time?.split(":")[1]: "00:00"
          };
          if(this.jobOrderInfo.confirmed_by_info?.signature_file){
            this.signature_file = this.uploadPath + this.jobOrderInfo.confirmed_by_info?.signature_file;
          }
          this.titleService.setTitle(this.jobOrderInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');

          if(this.jobOrderInfo.job_order_info_id){
            if(res.resultData.job_order_type == 'ICS'){
              await this.getDataPdf
              .get(this.token, "/job-order-worker-engineer?order_by=order:asc&&job_order_info_id=" + this.jobOrderInfo.job_order_info_id).then((res) => {
                this.engineerList = res.resultData || [];
              });
            }else{
              await this.getDataPdf
              .get(this.token, "/job-order-training-engineer?job_order_info_id=" + this.jobOrderInfo.job_order_info_id).then((res) => {
                this.trainingList = res.resultData || [];
              });
            }

            await this.getDataPdf
            .get(this.token, "/job-order-car-info?job_order_info_id=" + this.jobOrderInfo.job_order_info_id).then((res) => {
              this.carList = res.resultData || [];
            });

            await this.getDataPdf
            .get(this.token, "/car-type").then((res) => {
              this.carTypes = res.resultData || [];
            });

            await this.getDataPdf
            .get(this.token, "/car-info").then((res) => {
              this.cars = res.resultData || [];
            });
          }

          if(this.jobOrderInfo.customer_info.customer_id){
            await this.getDataPdf
            .get(this.token, "/customer-contact?order_by=isMaster:desc&&customer_id=" + this.jobOrderInfo.customer_info.customer_id).then((res) => {
              this.customerContactList = res.resultData;
            });

            await this.getDataPdf
            .get(this.token, "/job-order-contact?job_order_info_id=" + this.jobOrderInfo.job_order_info_id).then((res) => {
                let result = res.resultData;
                this.customerContactList.map((v) => {
                  const res = result.find((item) => item.customer_contact_id == v.customer_contact_id);
                  if (res) this.jobOrderContactList.push(res);
                });
            });
          }
        }else{
          this.canAccess = false;
        }
      });
    } catch (error) {
      this.canAccess = false;
    }
    this.pageLoad = true;
  }

  public async print(jobOrderInfo){
    const pdfs = document.querySelectorAll('.pdf');
    const id_card_sections = document.querySelectorAll('.id-card');

    let doc = new jsPDF('p', 'mm', 'a4', true);
    
    let pdfList = [];
    await Promise.all(Array.prototype.slice.call(pdfs,0).map(async (value, index) =>{
      let pdf = document.getElementById('pdf');
      await domtoimage.toJpeg(pdf, {
        background: 'white', 
        allowTaint: true,
        width: pdf.clientWidth * 2,
        height: pdf.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        pdfList.push({index: index, canvas: canvas, pdf: pdf});
      })
    }));
    await Promise.all(Array.prototype.slice.call(id_card_sections,0).map(async (v, index) =>{
      let id_card = document.getElementById('id_card_' + (index));

      await domtoimage.toJpeg(id_card, {
        background: 'white', 
        allowTaint: true,
        width: id_card.clientWidth * 2,
        height: id_card.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        pdfList.push({index: index, canvas: canvas, pdf: id_card});
      })
    }));

    pdfList.sort(function(a, b){return a.index - b.index});

    await Promise.all(pdfList.map(async (value, index) =>{

      var imgWidth = 208;
      var imgHeight = value.pdf.clientHeight * imgWidth / value.pdf.clientWidth;
      if((index) != 0) doc.addPage('a4');
      doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);

      if(index == pdfList.length - 1){
        doc.save(jobOrderInfo.doc_no);
      }
    }));
  }

  getCarType(id:string){
    return this.carTypes.find(car=>car.car_type_id == id).cart_type_name_th;
  }
}
