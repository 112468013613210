import { Directive, ElementRef, Output, EventEmitter } from "@angular/core";
declare const PerfectScrollbar;
@Directive({
  selector: "[perfect-scrollbar]",
})
export class PerfectScrollbarDirective {
  @Output() close = new EventEmitter();
  constructor(el: ElementRef) {
    new PerfectScrollbar(el.nativeElement, {
      theme: "dark",
      wheelPropagation: false,
    });
  }
}
