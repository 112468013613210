<div class="container app-content overflow-container">

  <div class="btn-section text-right border-bottom">
    <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(jobOrderInfo)">
        <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
    </button>
  </div>
  
  <div *ngFor="let page of pages; let i = index; let lastIndex = last">
    <div class="job-order-pdf" id="job_order_{{i}}">
      <div class="row">
        <div class="col-2"></div>
        <img class="col-1 text-right p-0" src="/assets/app-assets/images/pdf/logo1.png" width="90px"
            height="110px">
        <div class="col-6 text-center pt-3">
            <h2>MIRACLE INTERNATIONAL TECHNOLOGY CO., LTD.</h2>
            <p>214 Bangwaek Rd., Bangpai, Bangkae, Bangkok 10160 Tel : 0-2865-4647-8 Fax : 0-2865-4649
            </p>
        </div>
        <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon2.jpg" width="90px"
            height="110px">
        <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon3.png" width="90px"
            height="110px">
        <div class="col-1"></div>
      </div>
      <p class="text-center">Taxpayer Identification No.: 0-1055-45112-91-9 (Head Office)</p>
      <div class="row">
          <div class="col-6 pr-0 text-right">
              Web-site : http://www.mit.in.th
          </div>
          <div class="col-6">
            E-mail : info@mit.in.th
          </div>
      </div>
      <h2 class="text-center text-header pt-1">Calibration request form and review of work / ใบคำขอบริการสอบเทียบ และ ใบทบทวนงาน (CRF)</h2>
      <div class="container mt-5" *ngIf="jobOrderInfo && i == 0">
        
        <div class="row">
          <div class="col-12 text-right pr-5">
            CRF. No. {{jobOrderInfo?.doc_no}}
          </div>
          <div class="col-6 pr-0">
              <u>สำหรับติดต่อลูกค้า</u>
              <div class="pl-1">
                <p>{{jobOrderInfo?.customer_address_info.address_name}} <br>
                  {{jobOrderInfo?.customer_address}}<br>
                  ติดต่อ 
                  <span class="pl-0" *ngFor="let item of contactList; let i = index;">
                    {{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}}
                    <span class="pl-0" *ngIf="contactList?.length != i+1">,</span>
                  </span> <br>
                  Tel : 
                  <span class="pl-0" *ngFor="let item of contactList; let i = index;">
                    {{item?.customer_contact_info?.phonenumber}}
                    <span class="pl-0" *ngIf="contactList?.length != i+1">,</span>
                  </span>
                </p>
                <br>
              </div>
              <u>สำหรับออกใบรับรองผล (ภาษาอังกฤษเท่านั้น)</u>
              <div class="pl-1">
                <p>{{jobOrderInfo?.send_report_address_name}} <br>
                  {{jobOrderInfo?.send_report_address}}
                </p>
              </div>
          </div>
          <div class="col-6">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled checked *ngIf="jobOrderInfo?.job_order_type =='ICL'">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled *ngIf="jobOrderInfo?.job_order_type !='ICL'">
              <label class="form-check-label" for="flexCheckDefault">
                In-house Calibration<br>
                Finished Date: <span *ngIf="jobOrderInfo?.job_order_type =='ICL'">{{jobOrderInfo?.return_date | date: 'dd/MM/yyyy'}}</span>
              </label>
            </div>
            <div class="form-check mt-2">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled checked *ngIf="jobOrderInfo?.job_order_type =='ICS'">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled *ngIf="jobOrderInfo?.job_order_type !='ICS'">
              <label class="form-check-label" for="flexCheckChecked">
                Onsite Calibration<br>
                Service Date: <span *ngIf="jobOrderInfo?.job_order_type =='ICS'">{{jobOrderInfo?.return_date | date: 'dd/MM/yyyy'}}</span>
              </label>
            </div>
            <div class="form-check mt-2">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" disabled>
              <label class="form-check-label" for="flexCheckDefault">
                Attachment (มีเอกสารเเนบ)
              </label>
            </div>
            <div class="form-check mt-2">

              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" disabled checked *ngIf="jobOrderInfo?.accessory">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" disabled *ngIf="!jobOrderInfo?.accessory">
              <label class="form-check-label" for="flexCheckChecked">
                Accessories: {{jobOrderInfo?.accessory}}
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="container" *ngIf="i == 0">
        <div class="row">
          <div class="col-6">
            <div class="row form-group mx-sm-3 mt-5 mb-2">
              <div class="col-sm-10 d-flex justify-content-end">
                <div class="line-input"></div>
              </div>
              <label for="inputPassword2" class="mt-2" style="font-weight: normal;">ผู้ขอใช้บริการ</label>
            </div>
            <div class="row form-group mx-sm-3 mb-2">
              <div class="col-sm-10 d-flex justify-content-end">
                <div class="line-input"></div>
              </div>
              <label for="inputPassword2" class="mt-2" style="font-weight: normal;">วันที่</label>
            </div>
          </div>
          <div class="col-6">
            <div class="row form-group mx-sm-3 mt-5 mb-2">
              <div class="col-sm-10 d-flex justify-content-end">
                  <div class="line-input"></div>
              </div>
              <label for="inputPassword2" class="mt-2" style="font-weight: normal;">จนท. ทวนสอบ</label>
            </div>
            <div class="row form-group mx-sm-3 mb-2">
              <div class="col-sm-10 d-flex justify-content-end">
                <div class="line-input"></div>
              </div>
              <label for="inputPassword2" class="mt-2" style="font-weight: normal;">วันที่</label>
            </div>
          </div>
        </div>
      </div>
      <div class="container mt-4" *ngIf="jobOrderInfo">
        <div class="row" *ngIf="i == 0">
          <div class="col-12 font-weight-bold">
            <p>เงื่อนไขพิเศษ: {{jobOrderInfo?.special_condition}}</p> 
          </div> 
        </div>

        <div class="row">
          <div class="col-12">
            <p>รายการเครื่องมือสอบเทียบ</p>
          </div> 
        </div>
        <div>
          <div class="row">
        <div class="col-12">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center" scope="col">No.</th>
              <th scope="col">Cert. No.</th>
              <th scope="col">Description</th>
              <th scope="col">Maker</th>
              <th scope="col">Model</th>
              <th scope="col">Serial no.</th>
              <th scope="col">Tag no.</th>
              <th width="20%">Calibration point</th>
              <th scope="col">MPE</th>
              <th scope="col">Method</th>
              <th class="text-center" scope="col">ISO</th>
              <th class="text-center" scope="col">Pass-Failed</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of page; let j = index;">
              <td class="text-center">{{item.index +1}}</td>
              <td >{{item.cert_no}}</td>
              <td>{{item.quotation_mit_item_info.description || '-'}}</td>
              <td>{{item.quotation_mit_item_info.marker || '-'}}</td>
              <td>{{item.quotation_mit_item_info.model || '-'}}</td>
              <td>{{item.quotation_mit_item_info.serial_no || '-'}}</td>
              <td>{{item.quotation_mit_item_info.tag_no || '-'}}</td>
              <td class="text-left">
                <span class="custom-inline p-0" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                  <span *ngIf="detail.detail_type == 'point'">
                      <span class="pb-2 custom-inline" *ngFor="let point of detail.quotation_mit_item_detail_points; let pointIndex = index;">
                          <span class="danger" *ngIf="detail.isISO === 'N'">*</span>
                          {{point.mit_point || 0 | number : '1.1-5'}}
                          <span class="p-0 m-0" *ngIf="pointIndex + 1 != detail.quotation_mit_item_detail_points.length">,</span>
                          <span [innerHTML]="point.mit_unit_name" *ngIf="pointIndex + 1 == detail.quotation_mit_item_detail_points.length"></span>
                      </span>
                  </span>
                  <span *ngIf="detail.detail_type == 'range'">
                      <span  class="pb-2 custom-inline">
                          <span class="danger" *ngIf="detail.isISO === 'N'">*</span>
                          {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.mit_min) || 0 | number : '1.1-5'}} 
                      </span>
                      &nbsp;-
                      <span  class="pb-2 custom-inline">
                          {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.mit_max) || 0 | number : '1.1-5'}}
                          <span [innerHTML]="detail?.quotation_mit_item_detail_range.mit_unit_name"></span>
                      </span>
                  </span>
                  <br>
                </span>
              </td>
              <td>
                <span class="custom-inline p-0" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                  {{detail.mpe}}
                  <span class="pl-0" *ngIf="detail.mpe && item.quotation_mit_item_details.length != detailIndex + 1">,</span>
                </span>
              </td>
              <td>
                <span class="custom-inline p-0" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                  {{detail.method_name}}
                  <span class="pl-0" *ngIf="item.quotation_mit_item_details.length != detailIndex + 1">,</span>
                </span>
              </td>
              <td class="text-center">
                <span class="custom-inline" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                  {{detail.isISO}}
                  <span class="pl-0" *ngIf="item.quotation_mit_item_details.length != detailIndex + 1">,</span>
                </span>
              </td>
              <td  class="text-center">
                {{item?.job_order_status_work_info[0]?.status || '-'}}
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pt-0" *ngIf="lastIndex">
          <span class="p-0 text-bold">หมายเหตุ: </span> * นอกขอบข่ายการรับรอง  ISO/IEC 17025
        </div>
      </div>
      </div>
      </div>
      </div>   
      <div class="container mt-1" *ngIf="lastIndex">
        <p>Code description</p>
        <p>white = success</p>
        <p>
          <span class="bg-danger pt-0">
            red X = unsuccess
          </span>
        </p>
        <p> 
          <span class="bg-success pt-0">
            green = addition item
          </span>
        </p>
        <p>
          <span class="pt-0" style="background-color: yellow;">
            yellow = chainging detail
          </span>
        </p>
        <p>
          <span class="bg-warning pt-0">
            orange = out of MPE
          </span>
        </p>
      </div>

      <div class="container px-2" *ngIf="!isShowUnit">
          <div class="row">
          <div class="col-12 text-right">
            <!-- <p>M-CTM-01, Revise 14, Effective Date 14/2/17</p> -->
          </div>
          <table class="mx-auto table table-bordered">
            <thead>
              <tr>
                <th colspan="3" class="text-center" scope="col">List of Calibration Instrument</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="text-center" style="width: 33.33%">
                  <div class="row" *ngFor="let scope of scopeList[0]; let i = index;">
                    <div class="col-6">
                      <p class="text-left">{{scope.scope_name}}</p>
                    </div>
                    <div class="col-1">
                      <p class="text-center">:</p>
                    </div>
                    <div class="col-5">
                      <p class="text-right">{{scope.count || 0}} Unit(s)</p>
                    </div>
                  </div>
                </td>
                <td class="text-center" style="width: 33.33%">
                  <div class="row" *ngFor="let scope of scopeList[1]; let i = index;">
                    <div class="col-6">
                      <p class="text-left">{{scope.scope_name}}</p>
                    </div>
                    <div class="col-1">
                      <p class="text-center">:</p>
                    </div>
                    <div class="col-5">
                      <p class="text-right">{{scope.count || 0}} Unit(s)</p>
                    </div>
                  </div>
                </td>
                <td class="text-center" style="width: 33.33%">
                  <div class="row" *ngFor="let scope of scopeList[2]; let i = index;">
                    <div class="col-6">
                      <p class="text-left">{{scope.scope_name}}</p>
                    </div>
                    <div class="col-1">
                      <p class="text-center">:</p>
                    </div>
                    <div class="col-5">
                      <p class="text-right">{{scope.count || 0}} Unit(s)</p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="3" class="text-center">TOTAL {{jobOrderItems.length}} Unit(s)</td>  
              </tr>
            </tbody>
          </table>
        </div>  
      </div>  
    </div>
    
    <div class="btn-section text-right border-bottom">
    </div>
    <br>
  </div>


  <div *ngIf="isShowUnit">
    <div class="pdf" id="unit_section">
      <div class="container">
          <div class="row">
            <div class="col-12 text-right">
              <!-- <p>M-CTM-01, Revise 14, Effective Date 14/2/17</p> -->
            </div>
            <table class="mx-auto table table-bordered">
              <thead>
                <tr>
                  <th colspan="3" class="text-center" scope="col">List of Calibration Instrument</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="text-center" style="width: 33.33%">
                    <div class="row" *ngFor="let scope of scopeList[0]; let i = index;">
                      <div class="col-6">
                        <p class="text-left">{{scope.scope_name}}</p>
                      </div>
                      <div class="col-1">
                        <p class="text-center">:</p>
                      </div>
                      <div class="col-5">
                        <p class="text-right">{{scope.count || 0}} Unit(s)</p>
                      </div>
                    </div>
                  </td>
                  <td class="text-center" style="width: 33.33%">
                    <div class="row" *ngFor="let scope of scopeList[1]; let i = index;">
                      <div class="col-6">
                        <p class="text-left">{{scope.scope_name}}</p>
                      </div>
                      <div class="col-1">
                        <p class="text-center">:</p>
                      </div>
                      <div class="col-5">
                        <p class="text-right">{{scope.count || 0}} Unit(s)</p>
                      </div>
                    </div>
                  </td>
                  <td class="text-center" style="width: 33.33%">
                    <div class="row" *ngFor="let scope of scopeList[2]; let i = index;">
                      <div class="col-6">
                        <p class="text-left">{{scope.scope_name}}</p>
                      </div>
                      <div class="col-1">
                        <p class="text-center">:</p>
                      </div>
                      <div class="col-5">
                        <p class="text-right">{{scope.count || 0}} Unit(s)</p>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td colspan="3" class="text-center">TOTAL {{jobOrderItems.length}} Unit(s)</td>  
                </tr>
              </tbody>
            </table>
          </div>  
      </div>
      
    </div>

    <div class="btn-section text-right border-bottom">
    </div>
    <br>
  </div>

  <div *ngFor="let page of pageFinal; let i = index; let lastIndex = last">
    <div class="job-order-pdf-final" id="job_order_final_{{i}}">
     
      <div class="container mt-4" *ngIf="jobOrderInfo">
        <div class="col-12">
          <p class="text-center">Description of Work / ใบสรุปงาน</p>
        </div>  
        <table class="table table-bordered">
          <thead>
            <tr>
              <th class="text-center" scope="col">No.</th>
              <th scope="col">Cert. No.</th>
              <th scope="col">Description</th>
              <th scope="col">Maker</th>
              <th scope="col">Model</th>
              <th scope="col">Serial no.</th>
              <th scope="col">Tag no.</th>
              <th width="20%">Calibration point</th>
              <th scope="col">MPE</th>
              <th scope="col">Method</th>
              <th class="text-center" scope="col">ISO</th>
              <th class="text-center" scope="col">Pass-Failed</th>
            </tr>
          </thead>
          <tbody  *ngFor="let item of page; let j = index;">
            <tr>
              <td class="text-center">{{item.index +1}}</td>
              <td >{{item.cert_no}}</td>
              <td [ngClass]="{'highligh': highlight(item.logs, 'description')}">{{item.quotation_mit_item_info.description || '-'}}</td>
              <td [ngClass]="{'highligh': highlight(item.logs, 'marker')}">{{item.quotation_mit_item_info.marker || '-'}}</td>
              <td [ngClass]="{'highligh': highlight(item.logs, 'model')}">{{item.quotation_mit_item_info.model || '-'}}</td>
              <td [ngClass]="{'highligh': highlight(item.logs, 'serial_no')}">{{item.quotation_mit_item_info.serial_no || '-'}}</td>
              <td [ngClass]="{'highligh': highlight(item.logs, 'tag_no')}">{{item.quotation_mit_item_info.tag_no || '-'}}</td>
              <td [ngClass]="{'highligh': highlight(item.logs, 'point')}">
                <span class="custom-inline p-0" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                  <span *ngIf="detail.detail_type == 'point'">
                      <span class="pb-2 custom-inline" *ngFor="let point of detail.quotation_mit_item_detail_points; let pointIndex = index;">
                          <span class="danger" *ngIf="detail.isISO === 'N'">*</span>
                          {{point.mit_point || 0 | number : '1.1-5'}}
                          <span class="p-0 m-0" *ngIf="pointIndex + 1 != detail.quotation_mit_item_detail_points.length">,</span>
                          <span [innerHTML]="point.mit_unit_name" *ngIf="pointIndex + 1 == detail.quotation_mit_item_detail_points.length"></span>
                      </span>
                  </span>
                  <span *ngIf="detail.detail_type == 'range'">
                      <span  class="pb-2 custom-inline">
                          <span class="danger" *ngIf="detail.isISO === 'N'">*</span>
                          {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.mit_min) || 0 | number : '1.1-5'}} 
                      </span>
                      &nbsp;-
                      <span  class="pb-2 custom-inline">
                          {{utils.convertStingToNumber(detail?.quotation_mit_item_detail_range.mit_max) || 0 | number : '1.1-5'}}
                          <span [innerHTML]="detail?.quotation_mit_item_detail_range.mit_unit_name"></span>
                      </span>
                  </span>
                  <br>
                </span>
              </td>
              <td [ngClass]="{'highligh': highlight(item.logs, 'mpe')}">
                <span class="custom-inline p-0" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                  {{detail.mpe}}
                  <span class="pl-0" *ngIf="detail.mpe && item.quotation_mit_item_details.length != detailIndex + 1">,</span>
                </span>
              </td>
              <td>
                <span class="custom-inline p-0" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                  {{detail.method_name}}
                  <span class="pl-0" *ngIf="item.quotation_mit_item_details.length != detailIndex + 1">,</span>
                </span>
              </td>
              <td class="text-center">
                <span class="custom-inline" *ngFor="let detail of item.quotation_mit_item_details; let detailIndex = index;">
                  {{detail.isISO}}
                  <span class="pl-0" *ngIf="item.quotation_mit_item_details.length != detailIndex + 1">,</span>
                </span>
              </td>
              <td class="text-center">
                {{item?.job_order_status_work_info[0]?.status || '-'}}
              </td>
            </tr>
            <tr *ngIf="item.logs.length > 0">
              <td colspan="12" class="text-center">
                <div class="col-6">
                  <p class="text-left custom-bullet" *ngFor="let log of item.logs;" >{{log.description}}</p>
                </div>
              </td>
            </tr>
            <tr *ngIf="item.logs.length == 0">
              <td colspan="12" class="text-center">
                <div class="col-6">
                  <p class="text-left">-</p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="pt-0" *ngIf="lastIndex">
          <span class="p-0 text-bold">หมายเหตุ: </span> * นอกขอบข่ายการรับรอง  ISO/IEC 17025
        </div>
      </div>
  
      <div class="col-6 pt-1" *ngIf="lastIndex">
        <b>**note</b>
        <br><br>
        <b>Engineer</b>
        <br>
        <div class="row">
          <div class="col-6">
            <p *ngFor="let user of workerList;let k = index;">{{k+1}}. {{user.user_info?.firstname_th}} {{ user.user_info?.lastname_th}}</p>
          </div>
          <div class="col-6">
            <p *ngFor="let user of workerList;let k = index;">Tel. {{user.user_info?.mobile_no}}</p>
          </div>
        </div>
      </div>
      <!-- <div class="col-12">
        <br> 
        <p>Report Date - Time -</p>
        <p>Allowance = 0 minutes</p>
        <p>Allowance 2 = 0 minutes</p>
        <p>Depart time ปกติ HC (THAILAND) CO., LTD. 16/3 Moo2 Tambon Phrong Madua, Maeang Nakhon Pathom District, Nakhon Pathom 73000</p>
        <p>Arrival time 0.375</p>
      </div> -->
    </div>
    
    
    <div class="btn-section text-right border-bottom">
    </div>
    <br>
  </div>
    
  </div>
  