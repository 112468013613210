<div
  class="modal fade text-left"
  id="transport-cancel"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel1">ยืนยันการยกเลิกใบรับเครื่องมือ</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-body-content">
          <h4 class="d-inline">คุณต้องการยกเลิกข้อมูลนี้ใช่หรือไม่ ?</h4>
          <p>หากต้องการยกเลิกให้คลิกปุ่ม "ยืนยัน" หากไม่ต้องการ คลิกปุ่ม "ปิด"</p>

        </div>
      </div>
      <div class="modal-footer">
        <button
            type="button"
            class="btn grey btn-outline-secondary"
            data-dismiss="modal"
            (click)="onClose()"
        >
            ปิด
        </button>
        <button type="button" class="btn btn-outline-danger" (click)="onSave()">
            ยืนยัน
        </button>
      </div>
    </div>
  </div>
</div>
