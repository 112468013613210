import { 
  Component, 
  OnInit, 
  Output, 
  ViewChild, 
  EventEmitter,
  Input,
  SimpleChanges
} from '@angular/core';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
  selector: 'app-transport-receive-signature',
  templateUrl: './transport-receive-signature.component.html',
  styleUrls: ['./transport-receive-signature.component.css']
})
export class TransportreceiveSignatureComponent implements OnInit {
  //boolean
  check: boolean = false;
  isComplete: boolean = true;
  isEmpty: boolean = true;
  //string
  message: string = "";

  @ViewChild(SignaturePad) signaturePad: SignaturePad;
  signaturePadOptions: Object = { 
    'minWidth': 5,
    'canvasWidth': 750,
    'canvasHeight': 150
  };
  @Input() receiveItem: any;
  @Input() submitted: any;
  @Output() done = new EventEmitter<any>();
  @Output() checkSignature = new EventEmitter<any>();

  storeReceiveItems = [];
  constructor() {}

  ngOnInit(){}

  ngOnChanges(changes: SimpleChanges){
    this.isComplete = true;
    if(changes.receiveItem?.currentValue){
      this.receiveItem = changes.receiveItem.currentValue.receiveItems;
    }
    let result = this.receiveItem.filter(item => item.item_status_id == "WAITING_FOR_RECEIVE");
    if(result.length > 0){
      this.isComplete = false;
    }

    if(this.checkValidate()){
      this.check = false;
      this.isCheckbox();
      this.checkSignature.emit({ status: true, message: this.message });
    }else{
      this.validate();
    }
  }

  validate(){
    if(this.checkValidate()){
      this.message = "โปรดรับเครื่องมือทั้งหมดให้เสร็จสิ้น ก่อนให้ลูกค้าลงชื่อรับทราบ";
      this.checkSignature.emit({ status: false, message: this.message });
    }else{
      this.message = "";
      this.checkSignature.emit({ status: true, message: this.message });
    }
  }

  checkValidate(): boolean{
    return (this.isComplete === false || this.isEmpty === true) && this.check == true;
  }

  changeCheckbox(){
    this.isCheckbox();
    this.validate();
  }

  ngAfterViewInit() {
    this.reset();
    this.isCheckbox();
  }

  isCheckbox(){
    if(this.check === true){
      this.signaturePad.on();
    }else{
      this.signaturePad.off();
      this.signaturePad.clear();
      this.done.emit(null);
    }
  }

  drawComplete() {
    this.isEmpty = false;
    this.validate();
    this.done.emit(this.signaturePad.toDataURL());
  }

  drawStart() {}

  reset(){
    this.signaturePad.set('minWidth', 2);
    this.signaturePad.clear();
    this.isEmpty = true;
    this.validate();
    this.done.emit(null);
  }
}
