import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AccCreditNoteEditHistoryService } from 'src/app/services/credit-note';

@Component({
  selector: 'app-credit-note-item-history',
  templateUrl: './credit-note-item-history.component.html',
  styleUrls: ['./credit-note-item-history.component.css']
})
export class CreditNoteItemHistoryComponent implements OnInit {
 submitted:boolean = false;
 pageLoad:boolean = true;

 memoList: any = [];

 acc_credit_note_info_id: string;
 //any
 status: any;
 constructor(
   private route: ActivatedRoute,
   private AccCreditNoteEditHistoryService: AccCreditNoteEditHistoryService
 ) { }

 ngOnInit(): void {
   this.acc_credit_note_info_id = 
   this.route.snapshot.paramMap.get("acc_credit_note_info_id");
   this.getMemoList();
 }

 getMemoList(){
   this.pageLoad = true;
   this.memoList = [];

   this.AccCreditNoteEditHistoryService.load(null, { acc_credit_note_info_id : this.acc_credit_note_info_id  })
   .then(async (res)=>{
     this.memoList = res.resultData;
     this.memoList.sort(function(a,b){
       if(a.created_at < b.created_at) return 1;
       if(a.created_at > b.created_at) return -1;
       return 0;
     });
     this.pageLoad = false;
   })
 }
}
