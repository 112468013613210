import { Component, NgZone, OnInit, Output, ViewChild, EventEmitter, ElementRef, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { QuotationUtilsService } from 'src/app/services/quotations';
import { AccPaymentService } from 'src/app/services/invoice';


@Component({
  selector: 'app-tax-invoice-payment-show',
  templateUrl: './tax-invoice-payment-show.component.html',
  styleUrls: ['./tax-invoice-payment-show.component.css']
})
export class TaxInvoicePaymentShowComponent implements OnInit {
  form: FormGroup;
  //boolean
  submitted:boolean = false;

  acc_tax_invoice_info_id: string;
  tab: string = "เงินสด";

  @Input('paymentList') paymentList;
  @Input('payment') payment;

  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private zone: NgZone,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private AccPaymentService: AccPaymentService,
    public util : QuotationUtilsService,
  ) { 
    this.router.events
    .subscribe((event: NavigationStart) => {
      if (event.navigationTrigger === 'popstate') {
        this.closeModalBtn.nativeElement.click();
      }
    });
    this.acc_tax_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_tax_invoice_info_id");
    this.setForm();
  }

  async ngOnInit() {}

  async ngOnChanges(changes: SimpleChanges) {
    if(changes.payment.currentValue.acc_payment_id){
      await this.AccPaymentService.getById({acc_payment_id: changes.payment.currentValue.acc_payment_id})
      .then(res=>{
        let data = res.resultData;
        let method = this.paymentList.find(pay=> pay.payment_method_id == data.payment_method_id);
        this.tab = method.payment_method_name_th;

        this.form.patchValue(data);
        this.sumCount();
      })
    }
  }

  setForm(){
    this.form = this.formBuilder.group({
      acc_invoice_info_id: [this.acc_tax_invoice_info_id],
      payment_method_id: [""],
      payment_date: [""],
      bank_name: [""],
      bank_branch: [""],
      evidence_date: [""],
      evidence_ref_no: [""],
      value_before_vat: [""],
      wht_percent: ["0"],
      value_before_wht: [""],
      fee: [0],
      grand_total: [""],
      note: [""],
      tax: [""],
      order: [1],
      deduction: [""],
    });

    this.form.disable();
  }

  sumCount(){
    let data = {
      ...this.form.getRawValue()
    }
    this.form.get('value_before_wht').setValue(this.util.transformDecimal(data.value_before_wht));
  
    if(data.fee == "") data.fee = 0;
    this.form.get('fee').setValue(this.util.transformDecimal(data.fee));

    if(data.wht_percent){
      let sum = (this.util.convertStingToNumber(data.value_before_wht) * this.util.convertStingToNumber(data.wht_percent)) /100;
      this.form.get('deduction').setValue(this.util.transformDecimal(sum));
  
      if(data.value_before_wht){
        let value_before_vat = this.util.convertStingToNumber(data.value_before_wht) - sum;
        this.form.get('value_before_vat').setValue(value_before_vat);
  
        let grand_total = (this.util.convertStingToNumber(value_before_vat)+(this.util.convertStingToNumber(data.value_before_wht) * this.util.convertStingToNumber(data.tax)) /100)-this.util.convertStingToNumber(this.form.value.fee);
        this.form.get('grand_total').setValue(this.util.transformDecimal(grand_total));
      }
    }
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.setForm();
        this.done.emit(true);
      }
    });
  }
  
  closeModal(){
    this.done.emit(true);
  }
}
