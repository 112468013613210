import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { 
  TransportEditHistoryService,
  TransportreceiveContactService,
  TransportreceiveInfoService
} from '../../../../services/transport-receives';
import moment from 'moment';
import { 
  CustomerAddressService,
  CustomerContactService,
  EmployeeService,
  GETService, 
  UtilsService
} from 'src/app/services';
import { NgSelect2Customer, QuotationProductItemService, QuotationUtilsService } from 'src/app/services/quotations';
import { CoreService } from '../../../../services/transport-receives/core.service';
import { Observable } from 'rxjs';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
import Swal from 'sweetalert2';
declare var $: any;

@Component({
  selector: 'app-transport-receive-edit',
  templateUrl: './transport-receive-edit.component.html',
  styleUrls: ['./transport-receive-edit.component.css']
})
export class TransportreceiveEditComponent implements OnInit {
  pageLoad: boolean = true;
  isReturn: boolean = false;
  isReview: boolean = false;
  resetdateAfterLoad: boolean = true;
  resetdate: boolean = true;
  submitted: boolean = false;
  
  isReceive: boolean = false;
  resetMemo: boolean = false;
  showRequire: boolean = true;
  disableSaveBtn: boolean = false;
  canAccessDate: boolean = true;
  checkIsSignature: boolean = true;

  transport_receive_info_id: string;
  customer_signature: string;
  return_signature: string;
  signatureMessage: string = "";

  contacts = [];
  transportTypes = [];
  transportContactList = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];
  customerContactList: any  = [];
  tmpCustomerContactList: any  = [];

  form: FormGroup;

  status: any;
  receiveItem = null;
  transportreceive = null;
  customer_info = null;
  edit_history = null;
  maxReturnDate = undefined;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;
  constructor(
    private router: Router,
    private fbd: FormBuilder,
    private route: ActivatedRoute,
    private GETService: GETService,
    private cdr: ChangeDetectorRef,
    private CoreService: CoreService,
    private EmployeeService: EmployeeService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private TransportreceiveInfoService: TransportreceiveInfoService,
    private TransportEditHistoryService: TransportEditHistoryService,
    private QuotationProductItemService: QuotationProductItemService,
    private TransportreceiveContactService: TransportreceiveContactService,

    public UtilsService: UtilsService,
    public utils : QuotationUtilsService,
    public NgSelect2Customer: NgSelect2Customer
  ) { 
    this.route.queryParams.subscribe(params => {
      this.isReceive = params['type'] == 'receive'? true: false;
      this.isReview = params['type'] == 'review'? true: false;
      this.isReturn = params['type'] == 'return'? true: false;
    });
    this.transport_receive_info_id = 
    this.route.snapshot.paramMap.get("transport_receive_info_id");
  }

  async ngOnInit() {
    this.createForm();
    await this.getData();
    await this.setData();
    
    this.pageLoad = false;
  }

  async getData(){
    this.userInfo$.subscribe((userInfo) => {
      if (!userInfo) return;
      this.userInfo = userInfo;
    });
    await this.GETService.transportType(null)
    .then(res=>{
      this.transportTypes = res.resultData || {}
      this.transportTypes = this.transportTypes.map(function(elem) {
        return {
          id: elem.transport_type_id,
          text: `${elem.transport_type_name_th}`,
        }
      });
    })

    await this.EmployeeService.load(null, { emp_department_id: 'TRAN_DEP' })
    .then(res=>{
      this.transportContactList = res.resultData || {}
      this.transportContactList = this.transportContactList.map(function(elem) {
        return {
          id: elem.user_id,
          text: `${elem.user_info.firstname_th} ${elem.user_info.lastname_th}`,
        }
      });
    })
  }

  async setData(){
    if(this.isReceive || this.isReview || this.isReturn || this.userInfo?.emp_info?.emp_department_id.startsWith("TRAN_DEP")){
      this.disableForm();
    }
    await this.TransportreceiveInfoService.getById({transport_receive_info_id: this.transport_receive_info_id})
    .then(async res=>{
      let resultData = res.resultData || {};
      if(resultData.doc_status_id == 'RECEIVED_SUCCESS'){
        this.disableForm();
      }
      this.transportreceive = resultData;
      
      this.customer_info = res.resultData.customer_info;

      let data = {
        ...resultData,
        customer_name: `[${resultData?.customer_info?.customer_code || ''}] ${resultData?.customer_info?.company_name || ''} ${resultData?.customer_info.branch?" (" + resultData?.customer_info.branch + ")": ""}`,
      }
      this.form.patchValue(data);
      this.form.controls['doc_date'].setValue(moment(resultData.doc_date).format('YYYY-MM-DD'));
      if(resultData.pickup_date){
        this.form.controls['pickup_date'].setValue(moment(resultData.pickup_date).format('YYYY-MM-DD'));
      }
      if(resultData.return_date){
        this.form.controls['return_date'].setValue(moment(resultData.return_date).format('YYYY-MM-DD'));
      }
      if(resultData.pickup_date){
        this.maxReturnDate = moment(new Date(resultData.pickup_date)).add(17,'d').format('YYYY-MM-DD');

        this.resetdateAfterLoad = false;
        setTimeout(() => {
          this.resetdateAfterLoad = true;
        }, 100);
      }

      if(resultData.customer_id){
        await this.setCustomerContact(resultData.customer_id);
        await this.setCustomerAddress(resultData.customer_id);
      }

      await this.CustomerContactService.load(null, {
        order_by: "isMaster:desc",
        customer_id: resultData.customer_info.customer_id
      }).then((res) => {
        this.customerContactList = res.resultData || [];
        this.customerContactList = this.customerContactList.map(function(elem) {
          return {
            id: elem.customer_contact_id,
            customer_id: elem.customer_id,
            text: `${elem.firstname} ${elem.lastname}`,
            data: elem
          }
        });
      });

      await this.TransportreceiveContactService.load(null, { transport_receive_info_id: this.transport_receive_info_id }).then((res) => {
        this.contacts = res.resultData || [];
        let ids = res.resultData.map(v=>{
          return v.customer_contact_id;
        })
        this.form.controls['customer_contact_ids'].setValue(ids);
      });
      
      if(this.isReceive || this.isReview || this.isReturn || this.userInfo?.emp_info?.emp_department_id.startsWith("TRAN_DEP") || this.transportreceive?.doc_status_id == 'RECEIVED_SUCCESS'){
        this.showRequire = false;
      }
    })

    setTimeout(() => {
      this.setDateTimePicker();
    }, 1000);
  }

  disableForm(){
    this.form.controls['customer_address_id'].disable(); 
    this.form.controls['doc_date'].disable();  
    this.form.controls['customer_contact_ids'].disable();
    if(this.userInfo?.emp_info?.emp_position_info?.emp_position_id.startsWith("TRAN_DEP-03")){
      this.form.controls['receiver_id'].disable(); 
    }
    if(this.userInfo?.emp_info?.emp_department_id.startsWith("TRAN_DEP")){
      this.form.controls['pickup_date'].enable();
      this.form.controls['return_date'].enable(); 
    }else{
      this.form.controls['pickup_date'].disable();
      this.form.controls['return_date'].disable();
    }
  }

  setDateTimePicker(){
    let self = this;
    $('#pickup_date').on('dp.change', function(e){ 
      self.resetdate = false;
      setTimeout(() => {
        self.resetdate = true;
      }, 50);

      $('#return_date').data("DateTimePicker").date(moment(new Date(e.date)).add(17, 'd'));
      self.maxReturnDate = moment(new Date(e.date)).add(17,'d');
    });
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }

  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData.filter(item=> item.customer_address_type_id == 4) || [];

      if(customer.length > 0){
        if(customer.length > 0){
          address = this.utils.genCustomerAddressList(customer[0]);
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
        this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 4) || [];

        this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem=>{
          return this.utils.genCustomerAddressList(elem);
        })
      });
      let customerAddress = this.tmpCustomerAddressList;
      if(address){
        this.customerAddressList = [
          ...[address],
          ...customerAddress
        ];
      }else{
        this.customerAddressList = [
          ...customerAddress
        ];
      }

      if(this.customerAddressList.length > 0){
        const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        if(checkAddress.length == 0) {
          if(address){
            this.form.get('customer_address_id').setValue(address.id);
            this.selectAddressName(address.id);
          }else{
            this.form.controls['customer_address_id'].setValue(this.customerAddressList[0]?.id);
            this.selectAddressName(this.customerAddressList[0]?.id);
          }
        }
      }else{
        if(address){
          this.customerAddressList = [
            ...[address]
          ];
        }
        if(address){
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.controls['customer_address'].setValue("");
      this.form.controls['customer_address_id'].setValue("");
    }
  }

  createForm(){
    this.form = this.fbd.group({
      transport_receive_info_id: [""],
      doc_no: ["", [Validators.required]],
      doc_date: ["", [Validators.required]],
      customer_id: ["", [Validators.required]],
      customer_name: [""],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      transport_type_id: ["", [Validators.required]],
      pickup_date: [null],
      return_date: [null],
      doc_status_id: ["", [Validators.required]],
      receiver_id: [""],
      note: [""],
      accessory: [""],
      receive_type: [""],
      extra_message: [""],

      customer_contact_ids: [[], [Validators.required]]
    });
  }

  saveAsDraft(){
    if(this.customer_signature){
      this.form.get('doc_status_id').setValue("RECEIVE_WAITING_FOR_REVIEW");
    }else{
      this.form.get('doc_status_id').setValue("DRAFT");
    }
    this.submitted = true;
    if (this.form.invalid || !this.checkIsSignature) {
      return;
    };
    this.submit();
  }

  save(sendEmail){ 
    if(this.customer_signature){
      this.form.get('doc_status_id').setValue("RECEIVE_WAITING_FOR_REVIEW");
      this.submitted = true;
    }else{
      this.form.get('doc_status_id').setValue("WAITING_FOR_RECEIVE");
      this.submitted = false;
      setTimeout(() => {
        this.submitted = true;
      }, 50);
    }

    if (this.form.invalid || !this.checkIsSignature) {
      return;
    };
    this.submit(sendEmail);
  }

  saveEquipment(){
    this.submitted = true;
    if (this.form.invalid || !this.checkIsSignature) {
      return;
    };
    this.submitted = false;
    this.submit();
  }

  saveAsReturn(){
    this.submitted = true;
    
    let receiveItem = this.receiveItem.receiveItems.find(receive => receive.item_status_id == 'RECEIVED');

    let isnoQT = this.receiveItem.receiveItems.find(receive => !receive.quotation_mit_item_id);

    if(!receiveItem) this.form.get('doc_status_id').setValue("RETURNED_SUCCESS");

    if (this.form.invalid || (!receiveItem && this.return_signature == null)) {

      return;
    };
    if(isnoQT){
      Swal.fire({
        icon: "warning",
        confirmButtonText: "ตกลง",
        title: 'แจ้งทราบ',
        text: 'มีรายการ Item ที่ไม่มี QT'
      })
      return;
    }
    this.submitted = false;
    this.submit();
  }

  saveAsReview(){
    if(this.form.value.receive_type == 'WITTHOUT_QT'){
      if(!this.receiveItem.receiveItems.find(item=> item.item_status_id == 'WAITING_FOR_REVIEW')){
        this.form.get('doc_status_id').setValue("RECEIVE_WAITING_FOR_CONFIRM");
      }
    }else{
      if(!this.receiveItem.receiveItems.find(item=> item.item_status_id == 'WAITING_FOR_REVIEW')){
        this.form.get('doc_status_id').setValue("RECEIVED_SUCCESS");
      }
    }
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submitted = false;
    this.submit();
  }

  async submit(sendEmail = false){
    let data = this.form.getRawValue();
    this.disableSaveBtn = true;

    data['email'] = sendEmail;
    if(this.customer_signature){
      data = {
        ...data,
        customer_signature: this.customer_signature,
        customer_signature_date: new Date()
      }
    }
    if(this.return_signature){
      data = {
        ...data,
        returned_customer_signature: this.return_signature,
        returned_customer_signature_date: new Date()
      }
    }
    if(data.customer_contact_ids.length > 0){
      data.customer_contact_ids = data.customer_contact_ids.map(v=>{
        let select = this.contacts.find(item=> item.customer_contact_id == v);
        if(select){
          return {...select};
        }else{
          return {customer_contact_id: v};
        }
      })

      data.contacts = data.customer_contact_ids.map(v=>{
        return v.customer_contact_id;
      })
    }

    const deleteContactList =  this.contacts.map(v=>{
      let select = data.customer_contact_ids.find(item=> item.customer_contact_id == v.customer_contact_id);
      if(!select){
        return {...v};
      }
    }).filter(r => r != undefined);

    let transportResponse = await this.CoreService.editTransport(data, deleteContactList, this.receiveItem);

    if(transportResponse.status){
      await this.router.navigateByUrl("/transport-receive", {
        state: {
          status: transportResponse.message,
        },
      })
    }else{
      if(transportResponse.message == 'codeDuplicate'){
        this.status = transportResponse.error;
      }else{
        this.status = transportResponse.message;
      }
    }
    this.disableSaveBtn = false;
  }

  async getReceiveItems(event){
    this.receiveItem = event;
    // let result = null;
    // let receiveItems = this.receiveItem.receiveItems;
    // receiveItems = receiveItems.filter((receive, index, self) =>
    // index === self.findIndex((t) => (
    //   t.quotation_info_id === receive.quotation_info_id
    // )))

    // await Promise.all(receiveItems.map(async value=>{
    //   if(value.quotation_info_id){
    //     await this.QuotationProductItemService
    //     .load(null,  {quotation_info_id: value.quotation_info_id, '$product_info.product_title$': 'transport' })
    //     .then(res=>{
    //       if(res.resultData.length > 0) result = true;
    //     })
    //   }
    // }));

    // if(result){
    //   this.canAccessDate = false;
    // }else{
    //   this.canAccessDate = true;

    //   if(        
    //     this.receiveItem.delelteReceiveItems.length == 0 
    //     && 
    //     this.form.value.receive_type == 'WITH_QT'
    //     &&
    //     this.form.value.transport_type_id == ''){
    //     Swal.fire({
    //       icon: "warning",
    //       confirmButtonText: "ตกลง",
    //       title: 'แจ้งทราบ',
    //       text: 'รายการที่เลือกจากใบเสนอราคา ที่ไม่มีค่าขนส่ง'
    //     })
    //   }
    // }
  }

  getSignature(event){
    this.customer_signature = event;
  }

  checkSignature(event){
    this.checkIsSignature = event.status;
    this.signatureMessage = event.message || "";
    this.cdr.detectChanges();
  }

  getReturnSignature(event){
    this.return_signature = event;
  }

  openPdf(transportreceive) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/transport-document/' + transportreceive.transport_receive_info_id])
    );
  
    window.open(url, '_blank');
  }

  async unlock(event){
    await this.TransportreceiveInfoService.validateSecureCode(event)
    .then(async res=>{
      if(res.success){
        this.canAccessDate = false;
        await this.TransportEditHistoryService.update({...res.resultData, transport_receive_info_id: this.transport_receive_info_id})
        .then(res=>{
          if(res.success){
            this.edit_history = res.resultData || {};

            this.resetMemo = true;
            setTimeout(() => {
              this.resetMemo = false;
            }, 50);
          };
        })
      }else{
        alert("secure code not match.")
      }
    })
  }
}
