import { ValidatorFn, AbstractControl } from "@angular/forms";

export function EmailValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) return null;
    const email = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(
      control.value
    );
    return email ? null : { email: { value: control.value } };
  };
}
