import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-reject-calibration-item',
  templateUrl: './reject-calibration-item.component.html',
  styleUrls: ['./reject-calibration-item.component.css']
})
export class RejectCalibrationItemComponent implements OnInit {
  public form: FormGroup
  //boolean
  public submitted:boolean = false;
  public resetdate = null;

  @Input('isRejectAll') public isRejectAll: boolean;
  @Input('jobOrderInfo') public jobOrderInfo: any;
  @ViewChild("closeRejectBtn") public closeRejectBtn: ElementRef;
  @Output() public done = new EventEmitter<any>();
  constructor(
    private fbd: FormBuilder,
  ) { }

  ngOnInit() {}

  async ngOnChanges(changes: SimpleChanges) {
    this.createForm();
    this.resetdate = true;
  }

  createForm(){
    this.form = this.fbd.group({
      reject_reason: ["", [Validators.required]],
      reject_service_date: [this.jobOrderInfo?.pickup_date || "", [Validators.required]],
      reject_solution: ["", [Validators.required]]
    });
  }

  save(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.done.emit({data: this.form.value, isAll: this.isRejectAll});
    this.closeRejectBtn.nativeElement.click();
  }

  close(){
    this.submitted = false;
    this.createForm();
    
    this.resetdate = null;
    setTimeout(() => {
      this.resetdate = true;
    }, 100);
  }
}
