import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class UnitLabelService {
  async create({ unit_id, unit_label }) {
    let result = null;
    try {
      result = await Axios().post(`/unit-label`, {
        unit_id,
        unit_label,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({ unit_label_id, unit_id, unit_label }) {
    let result = null;
    try {
      result = await Axios().put(`/unit-label/${unit_label_id}`, {
        unit_id,
        unit_label,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ unit_label_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/unit-label/${unit_label_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/unit-label`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ unit_label_id }) {
    let result = null;
    try {
      result = await Axios().get(`/unit-label/${unit_label_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
