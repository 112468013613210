import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { 
  CustomerAddressService, 
  CustomerContactService, 
  CustomerInfoService, 
  UtilsService
} from 'src/app/services';
import { 
  DownloadFileService,
  NgSelect2Customer,
  QuotationUtilsService 
} from 'src/app/services/quotations';
import { 
  JobOrderInfoService 
} from 'src/app/services/job-orders';
import { AbsNumberValidator } from 'src/app/validators';
import { CoreCreateService } from 'src/app/services/job-orders/create.service';
import { Observable } from 'rxjs';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Select } from '@ngxs/store';
declare var $: any;

@Component({
  selector: 'app-work-order-non-lab-create',
  templateUrl: './work-order-non-lab-create.component.html',
  styleUrls: ['./work-order-non-lab-create.component.css']
})
export class WorkOrderNonLabCreateComponent implements OnInit {
  public form: FormGroup;
  public submitted: boolean = false;
  public pageLoad: boolean = true;
  public resetdate: boolean = true;
  public disableSaveBtn: boolean = false;
  public isTranDep: boolean = false;

  public files: any = [];
  public customerAddressList: any = [];
  public tmpCustomerAddressList: any = [];
  public customerContactList: any = [];
  public tmpCustomerContactList: any = [];
  public chargeReportAddressList = [];
  public addressList = [];

  public customer_id: string = "";
  public template: string = "";
  //any
  public status: any;
  public customer_info = null;
  public fileIndex = null;
  public jobOrderProductItem = null;
  public maxReturnDate = undefined;

  @Select(UserInfoState.getUser) public userInfo$: Observable<any>;
  constructor(
    private fbd: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private CustomerInfoService: CustomerInfoService,
    private CustomerAddressService: CustomerAddressService,
    private CustomerContactService: CustomerContactService,
    private DownloadFileService: DownloadFileService,
    private CoreService: CoreCreateService,

    public UtilsService: UtilsService,
    public utils : QuotationUtilsService,
    public NgSelect2Customer: NgSelect2Customer
  ) { 
    this.route.queryParams.subscribe(params => {
      this.template = params['template'];
    });
    this.customer_id = 
    this.route.snapshot.paramMap.get("customer_id");
  }

  async ngOnInit() {
    this.createForm();
    await this.getData();
    await this.setData();
    this.userInfo$.subscribe(async (userInfo) => {
      if(userInfo){
        if(userInfo.emp_info.emp_department_id.startsWith("TRAN_DEP")){
         this.isTranDep = true;
        }
      }
    });
    this.pageLoad = false;
  }

  async getData(){}

  async setData(){
    await this.CustomerInfoService.getById({customer_id: this.customer_id})
    .then(async res=>{

      let resultData = res.resultData || {};

      this.customer_info = res.resultData;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: `[${resultData?.customer_code || ''}] ${resultData?.company_name || ''} ${resultData?.branch?" (" + resultData?.branch + ")": ""}`,
        credit_term_day: resultData.credit_term_day || 0,
        job_order_type: this.template,
        special_condition: res.resultData.term_of_service
      }

      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: resultData.customer_id})
      .then(async (res) => {
        let resultData = res.resultData || [];

        this.chargeReportAddressList = resultData.filter(item=> item.customer_address_type_id === 3).map(elem => (
          {
            id: elem.customer_address_id,
            text: elem.address_name +' '+ this.utils.genAddress(elem),
            data: elem
          } 
        ));

        this.addressList =  resultData.map(elem => (
          {
            id: elem.customer_address_id,
            text: elem.address_name +' '+ this.utils.genAddress(elem),
            data: elem
          } 
        ));

        let sendReportAddress = resultData.find(item=> item.customer_address_type_id === 6);
        if(sendReportAddress){
          this.form.get('location').setValue(sendReportAddress.customer_address_id);
        }
      });

      if(resultData.customer_id){

        await this.setCustomerAddress(resultData.customer_id);
        await this.setCustomerContact(resultData.customer_id);
      }
      this.form.patchValue(data);

    })

    setTimeout(() => {
      this.setDateTimePicker();
    }, 1000);
  }
    
  setDateTimePicker(){
    let self = this;
    $('#pickup_date').on('dp.change', function(e){ 
      self.resetdate = false;
      setTimeout(() => {
        self.resetdate = true;
      }, 50);

      $('#return_date').data("DateTimePicker").date(moment(new Date(e.date)).add(10, 'd'));
      
      self.form.get('return_date').setValue(moment(new Date(e.date)).add(10, 'd'));
      self.maxReturnDate = moment(new Date(e.date)).add(10,'d');
    });
  }

  createForm(){
    this.form = this.fbd.group({
      customer_id: ["", [Validators.required]],
      customer_name: [""],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      pickup_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      return_date: [moment(new Date()).add(10,'d').format('YYYY-MM-DD'), [Validators.required]],
      real_return_date: [""],
      doc_status_id: ["", [Validators.required]],
      charge_address_id: ["", [Validators.required]],
      service_time: ["00:00"],
      
      doc_date:  [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      job_order_type: [""],
      special_condition: [""],
      note: [""],
      accessory: [""],
      location: ["", this.template == 'TRN'? [Validators.required]: null],
      no_of_day: ["", this.template == 'TRN'? [Validators.required, AbsNumberValidator()]: null],
      no_of_people: ["", this.template == 'TRN'? [Validators.required, AbsNumberValidator()]: null],

      customer_contact_ids: [[], [Validators.required]],
      credit_term_day: [""]
    });
    this.maxReturnDate = moment(new Date()).add(10,'d').format('YYYY-MM-DD');
  }

  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData || []
      if(customer.length > 0){
        if(customer.length > 0){
          address = this.utils.genCustomerAddressList(customer[0]);
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
       this.tmpCustomerAddressList = res.resultData || [];
       this.tmpCustomerAddressList = this.tmpCustomerAddressList.map(elem=>{
        return this.utils.genCustomerAddressList(elem);
      })
      });
      let customerAddress = this.tmpCustomerAddressList;
      if(address){
        this.customerAddressList = [
          ...[address],
          ...customerAddress
        ];
      }else{
        this.customerAddressList = [
          ...customerAddress
        ];
      }

      if(this.customerAddressList.length > 0){
        // const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        // if(checkAddress.length == 0) {
        //   if(address){
        //     this.form.get('customer_address_id').setValue(address.id);
        //     this.selectAddressName(address.id);
        //   }else{
        //     this.form.controls['customer_address_id'].setValue("");
        //   }
        // }
      }else{
        if(address){
          this.customerAddressList = [
            ...[address],
          ];
        }
        if(address){
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async setCustomerContact(event){
    await this.CustomerContactService.load(null, {
      order_by: "isMaster:desc",
      customer_id: event
    }).then((res) => {
      this.tmpCustomerContactList = res.resultData || [];
      this.tmpCustomerContactList = this.tmpCustomerContactList.map(function(elem) {
        return {
          id: elem.customer_contact_id,
          customer_id: elem.customer_id,
          text: `${elem.firstname} ${elem.lastname}`,
          data: elem
        }
      });
    });
    let customerContact = this.tmpCustomerContactList.filter(
      item => (item.customer_id === event)
    );
    this.customerContactList = [
      ...customerContact
    ];
  }

  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address.address_name){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.get('customer_address').setValue("");
    }
  }

  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;

    if (this.form.invalid || this.checkFileInvalid || this.jobOrderProductItem?.jobOrderItems?.length == 0 || this.jobOrderProductItem == null) {
      return;
    };
    this.submit();
  }

  save(){ 
    this.form.get('doc_status_id').setValue("WAITING_FOR_CONFIRM");
    this.submitted = true;
    if (this.form.invalid || this.checkFileInvalid || this.jobOrderProductItem?.jobOrderItems?.length == 0 || this.jobOrderProductItem == null) {
      return;
    };
    this.submit();
  }

  async submit(){
    let data = this.form.getRawValue();
    this.disableSaveBtn = true;

    if(data.customer_contact_ids.length > 0){
      data.customer_contact_ids = data.customer_contact_ids.map(v=>{
        return {customer_contact_id: v};
      })
    }
    if(this.customer_info.sales_assignment_info){
      data['sales_user_id'] = this.customer_info.sales_assignment_info.user_id;
    }
    if(data.real_return_date == ""){
      data.real_return_date = undefined;
    }

    let jobOrderResponse = await this.CoreService.createJobOrder(data, this.files, this.jobOrderProductItem);

    if(jobOrderResponse.status){
      await this.router.navigateByUrl("/work-order", {
        state: {
          status: jobOrderResponse.message,
        },
      })
    }else{
      if(jobOrderResponse.message == 'codeDuplicate'){
        this.status = jobOrderResponse.error;
      }else{
        this.status = jobOrderResponse.message;
      }
    }
    this.disableSaveBtn = false;
  }

  public get checkFileInvalid(): boolean {
    if(this.files.find(file=> this.UtilsService.checkFileSize(file) == false)){
      return true;
    }else{
      return false;
    }
  }

  async getJobOrderItems(event){
    this.jobOrderProductItem = event;
  }

  uploadFile(){
    document.getElementById("importFile").click();
  }
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  downloadFile(file){
    this.DownloadFileService.downloadFile(file);
  }
  removeFile(index){
    this.fileIndex = index; 
  }
  async deleteFile(closeModalEl){
    let index = this.fileIndex;
    this.files.splice(index, 1);
    closeModalEl.click();
  }
}
