import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { Observable } from 'rxjs';
import { CustomerAddressService, CustomerContactService, CustomerInfoService, UtilsService } from 'src/app/services';
import { CalculateFunctionService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccRecivedVoucherInfoService } from 'src/app/services/received-voucher';
import { CoreService } from 'src/app/services/received-voucher/core.service';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
declare var $: any;

@Component({
  selector: 'app-received-voucher-create',
  templateUrl: './received-voucher-create.component.html',
  styleUrls: ['./received-voucher-create.component.css']
})
export class ReceivedVoucherCreateComponent implements OnInit {
  form: FormGroup;
  submitted = false;
  pageLoad = false;
  disableSaveBtn = false;

  customer_id: string;
  acc_payment_id: string;

  customerList: any = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];
  count: number = 0;
  //any
  status: any;
  select2Options = null;
  options = { multiple: true };
  customer_info = null;
  receivedVoucherItem = null;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;

  constructor(
    private _fbd: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private CustomerInfoService: CustomerInfoService,
    private CustomerAddressService: CustomerAddressService,
    private CoreService: CoreService,

    public UtilsService: UtilsService,
    public util : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) {
    this.customer_id = 
    this.route.snapshot.paramMap.get("customer_id");
    this.route.queryParams.subscribe(params => {
      this.acc_payment_id = params['acc_payment_id'];
    });
  }

  async ngOnInit() {
    this.createForm();

    this.userInfo$.subscribe(user=>{
      if(!user) return;
      this.userInfo = user;
    })

    await this.CustomerInfoService.getById({customer_id: this.customer_id})
    .then(async res=>{

      let resultData = res.resultData || {};
      this.customer_info = res.resultData;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: `[${resultData?.customer_code || ''}] ${resultData?.company_name || ''} ${resultData?.branch?" (" + resultData?.branch + ")": ""}`,
        customer_credit_term_day: resultData.credit_term_day || 0,
      }
      if(resultData.customer_id){
        await this.setCustomerAddress(resultData.customer_id);
      }
      this.form.patchValue(data);

    })

    this.pageLoad = true;
  }

  createForm(){
    this.form = this._fbd.group({
      customer_id: ["", [Validators.required]],
      customer_name: [""],
      // doc_no: ["", [Validators.required]],
      doc_date: [moment(new Date()).format('YYYY-MM-DD'), [Validators.required]],
      doc_status_id: ["", [Validators.required]],
      customer_address_id: ["", [Validators.required]],
      customer_address: [""],
      doc_note: [""],
      grand_total: [0.00],
    });
  }

  async setCustomerAddress(event){
    const customer = this.customer_info;
    let address = null;
    await this.CustomerAddressService.load(null, {is_registed_address: "true", customer_id: event})
    .then(async (res) => {
      let customer = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];

      if(customer.length > 0){
        if(customer.length > 0){
          address = {
            id: customer[0].customer_address_id,
            customer_id: customer[0].customer_id,
            text: customer[0]?.branch?`${customer[0].address_name} (${customer[0]?.branch})` + ` แผนก: ${customer[0].department}`: `${customer[0].address_name}` + ` แผนก: ${customer[0].department}`,
            address_name: this.util.genAddress(customer[0]),
          }
        } 
      }
    });
    
    if(customer){
      await this.CustomerAddressService.load(null, {is_registed_address: "false",customer_id: event})
      .then(async (res) => {
      this.tmpCustomerAddressList = res.resultData.filter(item=> item.customer_address_type_id == 3) || [];

      this.tmpCustomerAddressList= this.tmpCustomerAddressList.map(elem => (
        {
          id: elem.customer_address_id,
          customer_id: elem.customer_id,
          text: elem.branch? `${elem.address_name} (${elem.branch})` + ` แผนก: ${elem.department}`: `${elem.address_name}` + ` แผนก: ${elem.department}`,
          address_name: this.util.genAddress(elem)
        } 
      ));
      });
      let customerAddress = this.tmpCustomerAddressList;
      if(address){
        this.customerAddressList = [
          ...[address],
          ...customerAddress
        ];
      }else{
        this.customerAddressList = [
          ...customerAddress
        ];
      }

      if(this.customerAddressList.length > 0){
        const checkAddress = this.customerAddressList.filter(item =>item?.id === this.form.get('customer_address_id').value);
        if(checkAddress.length == 0) {
          if(address){
            this.form.get('customer_address_id').setValue(address.id);
            this.selectAddressName(address.id);
          }else{
            this.form.controls['customer_address_id'].setValue(this.customerAddressList[0]?.id);
            this.selectAddressName(this.customerAddressList[0]?.id);
          }
        }
      }else{
        if(address){
          this.customerAddressList = [
            ...[address],
          ];
          this.form.get('customer_address_id').setValue(address.id);
          this.selectAddressName(address.id);
        }
      }
    }
  }

  async selectAddressName(event){
    let address = this.customerAddressList.find(v=> (v.id === event));
    if(address){
      this.form.get('customer_address').setValue(address.address_name);
    }else{
      this.form.get('customer_address').setValue("");
    }
  }

  saveAsDraft(){
    this.form.get('doc_status_id').setValue("DRAFT");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
  }
  save(){
    this.form.get('doc_status_id').setValue("WAITING_FOR_APPROVE");
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submit();
  }

  async submit(){
    let data = this.form.getRawValue();
    this.disableSaveBtn = true;

    if(this.acc_payment_id){
      data['acc_payment_id'] = this.acc_payment_id;
    }
    let response = await this.CoreService.createReceivedVoucher(data, this.receivedVoucherItem);

    if(response.status){
      await this.router.navigateByUrl("/received-voucher", {
        state: {
          status: response.message,
        },
      })
    }else{
      if(response.message == 'codeDuplicate'){
        this.status = response.error;
      }else{
        this.status = response.message;
      }
    }
    this.disableSaveBtn = false;
  }

  getReceivedVoucherItems(event){
    this.receivedVoucherItem = event;
    let total = 0;
    this.receivedVoucherItem.receivedVoucherItems.map(item=>{
      if(item.data.grand_total){
        total = total + item.data.grand_total;
      }
    })
    this.form.get('grand_total').setValue(total);
  }
}
