import { Component, ElementRef, OnInit, ViewChild, EventEmitter,Output, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import moment from 'moment';
import { AccBillingNoteItemHistoryService } from 'src/app/services/billing-note';

@Component({
  selector: 'app-billing-note-item-history',
  templateUrl: './billing-note-item-history.component.html',
  styleUrls: ['./billing-note-item-history.component.css']
})
export class BillingNoteItemHistoryComponent implements OnInit {
  form: FormGroup;
  pageType: string = 'create';
  history = [];

  submitted: boolean = false;
  //any
  @Input('billing_note_item') billing_note_item: any;
  @Output() done = new EventEmitter<any>();
  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;

  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private AccBillingNoteItemHistoryService: AccBillingNoteItemHistoryService 
  ) { 
    if (this.router.url.includes('show')) {
      this.pageType = 'show';
    } else if (this.router.url.includes('edit')) {
      this.pageType = 'edit';
    }
  }

  ngOnInit(): void {
    this.form = this._fbd.group({
      appointment_date:  ["", Validators.required],
      appointment_location:  [""],
      appointment_time_from:  ["00:00"],
      appointment_time_to:  ["00:00"],
    });
    if(this.billing_note_item.appointment_date){
      let data = {
        appointment_date:this.billing_note_item.appointment_date,
        appointment_location:this.billing_note_item.appointment_location,
        appointment_time_from:this.billing_note_item.appointment_time_from || "00:00",
        appointment_time_to:this.billing_note_item.appointment_time_to || "00:00",
      }
      this.form.patchValue(data);
    }

    if(this.pageType == 'show'){
      if(this.billing_note_item.acc_billing_note_item_id){
        this.AccBillingNoteItemHistoryService
        .load(null, {acc_billing_note_item_id: this.billing_note_item.acc_billing_note_item_id})
        .then(res=>{
          this.history = res.resultData || [];
          this.history.sort(function(a,b){
            if(a.created_at < b.created_at) return -1;
            if(a.created_at > b.created_at) return 1;
            return 0;
          });
        })
      }
    }else if(this.pageType == 'edit'){
      if(this.billing_note_item.acc_billing_note_item_id){
        this.AccBillingNoteItemHistoryService
        .load(null, {acc_billing_note_item_id: this.billing_note_item.acc_billing_note_item_id})
        .then(res=>{
          this.history = res.resultData || [];
          this.history.sort(function(a,b){
            if(a.created_at < b.created_at) return -1;
            if(a.created_at > b.created_at) return 1;
            return 0;
          });
        })
      }
    }
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid) {
      return;
    };
    this.submitted = false;

    if(this.pageType =='create'){
      this.billing_note_item.appointment_date = moment(this.form.value.appointment_date).set({hour:0,minute:0,second:0,millisecond:0}).format("YYYY-MM-DD");
      this.billing_note_item.appointment_location = this.form.value.appointment_location;
      this.billing_note_item.appointment_time_from = this.form.value.appointment_time_from.length > 10? moment(this.form.value.appointment_time_from).second(0).format("HH:mm:ss"): this.form.value.appointment_time_from.length ==8?this.form.value.appointment_time_from:this.form.value.appointment_time_from + ":00";
      this.billing_note_item.appointment_time_to = this.form.value.appointment_time_to.length > 10? moment(this.form.value.appointment_time_to).second(0).format("HH:mm:ss"): this.form.value.appointment_time_to.length ==8?this.form.value.appointment_time_to:this.form.value.appointment_time_to + ":00";
      this.billing_note_item['history'] ={
        previous_date: undefined,
        new_date: this.form.value.appointment_date
      }
      this.done.emit({ 
        billing_note_item : this.billing_note_item,
        closeModalBtn: this.closeModalBtn
      });
    }else{
      this.billing_note_item.appointment_date = moment(this.form.value.appointment_date).set({hour:0,minute:0,second:0,millisecond:0}).format("YYYY-MM-DD");
      this.billing_note_item.appointment_location = this.form.value.appointment_location;
      this.billing_note_item.appointment_time_from = this.form.value.appointment_time_from.length > 10? moment(this.form.value.appointment_time_from).second(0).format("HH:mm:ss"): this.form.value.appointment_time_from.length ==8?this.form.value.appointment_time_from:this.form.value.appointment_time_from + ":00";
      this.billing_note_item.appointment_time_to = this.form.value.appointment_time_to.length > 10? moment(this.form.value.appointment_time_to).second(0).format("HH:mm:ss"): this.form.value.appointment_time_to.length ==8?this.form.value.appointment_time_to:this.form.value.appointment_time_to + ":00";
      this.billing_note_item['history'] ={
        previous_date: this.history.length > 0?this.history.slice(-1)[0].new_date: undefined,
        new_date: this.form.value.appointment_date
      }

      this.done.emit({ 
        billing_note_item : this.billing_note_item,
        closeModalBtn: this.closeModalBtn
      });
    }
  }

  close(){
    this.done.emit({ 
      billing_note_item : false,
      closeModalBtn: this.closeModalBtn
    });
  }
}
