import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomerAddressService } from 'src/app/services';
import { AccDebitNoteInfoService } from 'src/app/services/debit-note';
import { AccInvoiceInfoService } from 'src/app/services/invoice';
import { CalculateFunctionService, QuotationMitItemService, QuotationProductItemService, QuotationUtilsService } from 'src/app/services/quotations';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';
import { Select } from '@ngxs/store';
import { UserInfoState } from 'src/app/store/user/user.state';
import { Observable } from 'rxjs';
import { Location } from "@angular/common";

@Component({
  selector: 'app-debit-note-show',
  templateUrl: './debit-note-show.component.html',
  styleUrls: ['./debit-note-show.component.css']
})
export class DebitNoteShowComponent implements OnInit {
  form: FormGroup;
  acc_debit_note_info_id: string;
  
  pageLoad:boolean = false;
  submitted:boolean = false;

  customerList: any = [];
  customerAddressList: any = [];
  tmpCustomerAddressList: any = [];

  state: any;
  status: any;

  customer_info = null;
  debitNoteInfo = null;
  debitNoteItem = null;

  @Select(UserInfoState.getUser) userInfo$: Observable<any>;
  userInfo = null;
  constructor(
    private router: Router,
    private _fbd: FormBuilder,
    private route: ActivatedRoute,
    private location: Location,
    private CustomerAddressService: CustomerAddressService,
    private AccDebitNoteInfoService: AccDebitNoteInfoService,

    private AccInvoiceInfoService: AccInvoiceInfoService,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,

    private QuotationMitItemService: QuotationMitItemService,
    private QuotationProductItemService: QuotationProductItemService,

    public utils : QuotationUtilsService,
    public calculate: CalculateFunctionService,
  ) {
    this.acc_debit_note_info_id = 
    this.route.snapshot.paramMap.get("acc_debit_note_info_id"); 
  }

  async ngOnInit() {
    this.createForm();

    this.userInfo$.subscribe(user=>{
      if(!user) return;
      this.userInfo = user;
    })

    await this.AccDebitNoteInfoService.getById({acc_debit_note_info_id: this.acc_debit_note_info_id}).then(async res=>{
      let resultData = res.resultData || {};
      this.debitNoteInfo = resultData;
      this.customer_info = res.resultData.customer_info;
      let data = {
        customer_id: resultData.customer_id,
        customer_name: this.customer_info? `[${this.customer_info?.customer_code || ''}] ${this.customer_info?.company_name || ''} ${this.customer_info?.branch?" (" + this.customer_info?.branch + ")": ""}`: '',
        ...resultData
      }

      this.form.patchValue(data);

      if(resultData.customer_address_id){
        await this.CustomerAddressService.getById({ customer_address_id: resultData.customer_address_id }).then((res)=>{
          if(res.success){
            var resultData = res.resultData;
            var address = this.utils.genAddress(resultData);

            this.form.controls['customer_address_name'].setValue(resultData.address_name + (resultData.branch?' ('+ resultData.branch +')': '') + ` แผนก: ${resultData.department}`);
            this.form.controls['customer_address'].setValue(address); 
          }else{
            this.form.controls['customer_address_name'].setValue(null);
            this.form.controls['customer_address'].setValue(null); 
          } 
        })
      }

      if(resultData.ref_doc_type == 'INVOICE'){
        await this.AccInvoiceInfoService.getById({acc_invoice_info_id: resultData.ref_acc_document_info_id})
        .then((res) => {
          let resultData = res.resultData;
          this.form.get("ref_acc_document_doc_no").setValue(resultData.doc_no);
        });
      }else{
        await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: resultData.ref_acc_document_info_id})
        .then((res) => {
          let resultData = res.resultData;
          this.form.get("ref_acc_document_doc_no").setValue(resultData.doc_no_company);
        });
      }
    })

    this.pageLoad = true;
  }

  createForm(){
    this.form = this._fbd.group({
      acc_debit_note_info_id: [this.acc_debit_note_info_id],
      ref_acc_document_info_id: [""],
      ref_acc_document_doc_no: [""],
      doc_no: [""],
      revise_count: [""],
      doc_date: [""],
      customer_id: [""],
      customer_name: [""],
      customer_address: [""],
      customer_address_id: [""],
      customer_address_name: [""],
      doc_note: [""],
      doc_reason: [""],
      discount: [0],
      difference_tax: [0.00],
      grand_total_before_vat: [0.00],
      tax: ["7"],
      grand_total: [0.00],
      doc_status_id: [""],
    });
    this.form.disable(); 
  }

  async cancel(){
    let response = await this.AccDebitNoteInfoService.update({
      ...this.debitNoteInfo,
      doc_status_id: 'CANCELED'
    });
    if (response.success) {
      await Promise.all(
        this.debitNoteItem.items.map(async (value: any, index: number)=>{

          if(value.item_type == 'calibration_service'){
            let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
            await this.QuotationMitItemService.update({
              ...quotationMitResponse.resultData[0],
      
              acc_debit_note_info_id: "",
              acc_debit_note_info_doc_no: "",
              acc_debit_note_info_status: ""
            })
          }else if(value.item_type == 'product'){
            let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
            await this.QuotationProductItemService.update({
              ...quotationProductResponse.resultData,
      
              acc_debit_note_info_id: "",
              acc_debit_note_info_doc_no: "",
              acc_debit_note_info_status: ""
            })
          }
      }));
      this.router.navigateByUrl("/debit-note", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  async approve(){

    let accResponse = await this.AccDebitNoteInfoService.update({
      ...this.debitNoteInfo,
      doc_status_id: 'APPROVED'
    });
    if (accResponse.success) {
      await Promise.all(
        this.debitNoteItem.items.map(async (value: any, index: number)=>{
          this.updateMitItem(value, accResponse);
        }));
      this.router.navigateByUrl("/debit-note", {
        state: {
          status: accResponse.success,
        },
      });
    } else {
      this.status = accResponse.error;
    }
  }

  
  async reject(){
    let response = await this.AccDebitNoteInfoService.update({
      ...this.debitNoteInfo,
      doc_status_id: 'REJECTED'
    });
    if (response.success) {
      await Promise.all(
        this.debitNoteItem.items.map(async (value: any, index: number)=>{
          this.updateMitItem(value, response);
        }));
      this.router.navigateByUrl("/debit-note", {
        state: {
          status: response.success,
        },
      });
    } else {
      this.status = response.error;
    }
  }

  getItems(event){
    if(event){
      this.debitNoteItem = event;
    }
    this.sumCount();
  }

  sumCount(){
    let new_amount = 0;
    if(this.debitNoteItem){
      this.debitNoteItem?.items.map(item=>{
        if(item.item_type == 'product'){
          new_amount = new_amount + this.calculate.sumProduct(item);
        }else{
          new_amount = new_amount + this.calculate.sumMitCredit(item);
        }
      });
    }
    this.form.get('grand_total_before_vat').setValue(new_amount);
    
    this.form.get('difference_tax').setValue(this.calculate.sumProductWithTax(this.form.value?.grand_total_before_vat, this.form.value));

    this.form.get('grand_total').setValue(this.form.value.grand_total_before_vat + this.form.value?.difference_tax);
  }

  async updateMitItem(value, accDebitNoteResponse){
    if(value.item_type == 'calibration_service'){
      let quotationMitResponse = await this.QuotationMitItemService.getById({quotation_mit_item_id: value.quotation_item_id});
      await this.QuotationMitItemService.update({
        ...quotationMitResponse.resultData[0],

        acc_debit_note_info_id: accDebitNoteResponse.resultData.acc_debit_note_info_id,
        acc_debit_note_info_doc_no: accDebitNoteResponse.resultData.doc_no,
        acc_debit_note_info_status: accDebitNoteResponse.resultData.doc_status_id
      })
    }else if(value.item_type == 'product'){
      let quotationProductResponse = await this.QuotationProductItemService.getById({quotation_item_id: value.quotation_item_id});
      await this.QuotationProductItemService.update({
        ...quotationProductResponse.resultData,

        acc_debit_note_info_id: accDebitNoteResponse.resultData.acc_debit_note_info_id,
        acc_debit_note_info_doc_no: accDebitNoteResponse.resultData.doc_no,
        acc_debit_note_info_status: accDebitNoteResponse.resultData.doc_status_id
      })
    }
  }

  back(): void {
    this.location.back();
  }
}
