import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AccCreditNoteInfoService } from 'src/app/services/credit-note';
import { AccDebitNoteInfoService } from 'src/app/services/debit-note';
import { AccTaxInvoiceInfoService } from 'src/app/services/tax-invoice';

@Component({
  selector: 'app-tax-invoice-credit-item',
  templateUrl: './tax-invoice-credit-item.component.html',
  styleUrls: ['./tax-invoice-credit-item.component.css']
})
export class TaxInvoiceCreditItemComponent implements OnInit {
  pageLoad: boolean = false;
  pageType: string = 'create';
  acc_tax_invoice_info_id: string;

  creditNotes = [];

  @Output() done = new EventEmitter<any>();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private AccTaxInvoiceInfoService: AccTaxInvoiceInfoService,
    private AccCreditNoteInfoService: AccCreditNoteInfoService,
    private AccDebitNoteInfoService: AccDebitNoteInfoService
  ) {
    this.acc_tax_invoice_info_id = 
    this.route.snapshot.paramMap.get("acc_tax_invoice_info_id");
   }

  async ngOnInit() {
    this.pageLoad = false;

    if(this.acc_tax_invoice_info_id){
      await this.AccTaxInvoiceInfoService.getById({acc_tax_invoice_info_id: this.acc_tax_invoice_info_id}).then(async res=>{
        if(res.resultData.acc_invoice_info_id){
          await this.AccCreditNoteInfoService.load(null, {ref_acc_document_info_id: res.resultData.acc_invoice_info_id, doc_status_id: 'APPROVED'})
          .then(async res=>{

            let resultData = res.resultData.filter(item=> item.ref_doc_type != 'INVOICE');
            this.creditNotes = [
              ...this.creditNotes,
              ...resultData
            ];
          })

          await this.AccDebitNoteInfoService.load(null, {ref_acc_document_info_id: res.resultData.acc_invoice_info_id, doc_status_id: 'APPROVED'})
          .then(async res=>{
            this.creditNotes = [
              ...this.creditNotes,
              ...res.resultData
            ];
          })
        }

        await this.AccCreditNoteInfoService.load(null, {ref_acc_document_info_id: this.acc_tax_invoice_info_id, doc_status_id: 'APPROVED'})
        .then(async res=>{
          this.creditNotes = [
            ...this.creditNotes,
            ...res.resultData
          ];
        })

        await this.AccDebitNoteInfoService.load(null, {ref_acc_document_info_id: this.acc_tax_invoice_info_id, doc_status_id: 'APPROVED'})
        .then(async res=>{
          this.creditNotes = [
            ...this.creditNotes,
            ...res.resultData
          ];
        })
      })

      this.creditNotes.sort(function(a,b){
        if(a.doc_date > b.doc_date) return 1;
        if(a.doc_date < b.doc_date) return -1;
        return 0;
      });

    }
    this.pageLoad = true;
    this.passData();
  }

  passData(){
    let sumTotalCredit = 0;
    let sumTotalDebit = 0;
    this.creditNotes.map(item=>{
      if(item.acc_credit_note_info_id){
        sumTotalCredit = sumTotalCredit + item.difference;
      }else{
        sumTotalDebit = sumTotalDebit + + item.grand_total_before_vat;
      }
    })
    this.done.emit({
      credit: sumTotalCredit,
      debit: sumTotalDebit
    });
  }
}
