import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationNoteService {
  async create({
    quotation_info_id,
    quotation_note,
    note_type,
    order,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-note`, {
        quotation_info_id,
        quotation_note,
        note_type,
        order: order.toString()
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async createArray(array) {
    array = array.map(item=>{
      return {
        quotation_info_id: item.quotation_info_id,
        quotation_note: item.quotation_note,
        note_type: item.note_type,
        order: item.order,
      }
    })
    let result = null;
    try {
      result = await Axios().post(`/quotation-note`, array);
      result = result?.data || {};
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_note_id,
        quotation_info_id,
        quotation_note,
        note_type,
        order,
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-note/${quotation_note_id}`, {
        quotation_info_id,
        quotation_note,
        note_type,
        order: order.toString()
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async updateArray(array) {
    array = array.map(item=>{
      return {
        quotation_note_id: item.quotation_note_id,
        quotation_info_id: item.quotation_info_id,
        quotation_note: item.quotation_note,
        note_type: item.note_type,
        order: item.order,
      }
    })
    let result = null;
    try {
      result = await Axios().put(`/quotation-note`, array);
      result = result?.data || {};
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_note_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-note/${quotation_note_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-note`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_note_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-note/${quotation_note_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
