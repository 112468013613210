import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationProductItemService {
  async create({
    quotation_info_id,
    product_id,
    product_title,
    product_description,
    unit_short_name_en,
    original_price,
    price,
    item_type,
    quantity,
    unit_id,
    discount,
    ready_to_billing,
    acc_debit_note_info_id,
    revised_at,
    order_by,
    related_job_order_info_id,

    job_order_info_id,
    job_order_info_status,
    job_order_info_doc_no,
    job_order_info_type,
    job_order_product_item_status,
    job_order_product_item_id,
    
    lock_invoice_at,
    unlock_invoice_at,

    acc_invoice_info_id,
    acc_invoice_info_doc_no,
    acc_invoice_info_status,
    acc_tax_invoice_info_id,
    acc_tax_invoice_info_doc_no,
    acc_tax_invoice_info_status,

    acc_debit_note_info_doc_no,
    acc_debit_note_info_status,
    acc_credit_note_info_id,
    acc_credit_note_info_doc_no,
    acc_credit_note_info_status,

    quotation_info_doc_no,
    quotation_info_status
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-product-items`, {
        quotation_info_id,
        product_id: product_id || "",
        product_title,
        product_description,
        unit_short_name_en,
        original_price: original_price|| 0,
        price,
        item_type,
        quantity,
        unit_id: unit_id || "",
        discount,
        ready_to_billing,
        acc_debit_note_info_id,
        revised_at,
        order_by,
        related_job_order_info_id,

        job_order_info_id,
        job_order_info_status,
        job_order_info_doc_no,
        job_order_info_type,
        job_order_product_item_status,
        job_order_product_item_id,

        lock_invoice_at,
        unlock_invoice_at,

        acc_invoice_info_id,
        acc_invoice_info_doc_no,
        acc_invoice_info_status,
        acc_tax_invoice_info_id,
        acc_tax_invoice_info_doc_no,
        acc_tax_invoice_info_status,
    
        acc_debit_note_info_doc_no,
        acc_debit_note_info_status,
        acc_credit_note_info_id,
        acc_credit_note_info_doc_no,
        acc_credit_note_info_status,

        quotation_info_doc_no,
        quotation_info_status
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
      quotation_item_id,
      quotation_info_id,
      product_id,
      product_title,
      product_description,
      unit_short_name_en,
      original_price,
      price,
      item_type,
      quantity,
      unit_id,
      discount,
      ready_to_billing,
      order_by,
      cost_of_sales,
      return_date,
      acc_invoice_info_id,
      acc_debit_note_info_id,
      revised_at,
      related_job_order_info_id,

      job_order_info_id,
      job_order_info_status,
      job_order_info_doc_no,
      job_order_info_type,
      job_order_product_item_status,
      job_order_product_item_id,

      lock_invoice_at,
      unlock_invoice_at,

      acc_invoice_info_doc_no,
      acc_invoice_info_status,
      acc_tax_invoice_info_id,
      acc_tax_invoice_info_doc_no,
      acc_tax_invoice_info_status,
  
      acc_debit_note_info_doc_no,
      acc_debit_note_info_status,
      acc_credit_note_info_id,
      acc_credit_note_info_doc_no,
      acc_credit_note_info_status,

      quotation_info_doc_no,
      quotation_info_status
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-product-items/${quotation_item_id}`, {
        quotation_info_id,
        product_id: product_id || "",
        product_title: product_title || "",
        product_description,
        unit_short_name_en,
        original_price: original_price|| 0,
        price,
        item_type,
        quantity,
        unit_id: unit_id || "",
        discount,
        ready_to_billing,
        order_by,
        cost_of_sales: cost_of_sales? parseFloat(cost_of_sales): undefined,
        return_date,
        acc_invoice_info_id,
        acc_debit_note_info_id,
        revised_at,
        related_job_order_info_id,

        job_order_info_id,
        job_order_info_status,
        job_order_info_doc_no,
        job_order_info_type,
        job_order_product_item_status,
        job_order_product_item_id,

        lock_invoice_at: lock_invoice_at || "null",
        unlock_invoice_at: unlock_invoice_at || "null",

        acc_invoice_info_doc_no,
        acc_invoice_info_status,
        acc_tax_invoice_info_id,
        acc_tax_invoice_info_doc_no,
        acc_tax_invoice_info_status,
    
        acc_debit_note_info_doc_no,
        acc_debit_note_info_status,
        acc_credit_note_info_id,
        acc_credit_note_info_doc_no,
        acc_credit_note_info_status,

        quotation_info_doc_no,
        quotation_info_status
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ quotation_item_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-product-items/${quotation_item_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-product-items`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ quotation_item_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-product-items/${quotation_item_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
