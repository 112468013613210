import { FormBuilder } from "@angular/forms";
import { Component, OnInit } from "@angular/core";
import { UnitConversionService } from "src/app/services";
import { ActivatedRoute } from "@angular/router";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
  selector: "app-unit-conversion",
  templateUrl: "./unit-conversion.component.html",
  styleUrls: ["./unit-conversion.component.css"],
})
export class UnitConversionComponent implements OnInit {
  isLoading = true;
  status: any;
  units = [];
  unit_type_id: string;
  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private UnitConversionService: UnitConversionService
  ) {}

  async ngOnInit() {
    this.unit_type_id = this.route.snapshot.paramMap.get("unit_type_id");
    // get units by filter
    let response = await this.UnitConversionService.load(null, {
      unit_type_id: this.unit_type_id,
      order_by: "order_number:asc",
    });
    if (response.success) {
      this.units = response.resultData || [];
      this.units = this.units.map((v) => {
        return { ...v, base_value: v.base_value ? v.base_value : 1 };
      });
      this.isLoading = false;
    }
  }
  submit() {
    let promises = [];
    for (let [i, unit] of this.units.entries()) {
      unit = {
        ...unit,
        order_number: i,
        base_value: String(unit.base_value ? unit.base_value : ""),
      };
      promises.push(this.UnitConversionService.update(unit));
    }
    let status = null;
    Promise.all(promises).then((responses) => {
      for (let res of responses) {
        if (res.error) {
          this.status = res.error;
          break;
        } else {
          status = res.success;
        }
      }
      this.ngOnInit();
      this.status = status;
    });
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.units, event.previousIndex, event.currentIndex);
  }
}
