<div class="container app-content overflow-container" *ngIf="pageLoad">

    <div class="btn-section text-right border-bottom">
      <button type="button" class="btn btn-blue px-2 mr-2" (click)="print(jobOrderInfo)">
          <i class="fa fa-download icon-left"></i> ดาวน์โหลดเอกสาร
      </button>
    </div>
  
    <div *ngFor="let page of pages; let i = index; let lastIndex = last">
      <div class="pdf" id="job_order_{{i}}">
        <div class="row">
            <div class="col-2"></div>
            <img class="col-1 text-right p-0" src="/assets/app-assets/images/pdf/logo1.png" width="90px"
                height="110px">
            <div class="col-6 text-center pt-3">
                <h2>MIRACLE INTERNATIONAL TECHNOLOGY CO., LTD.</h2>
                <p>214 Bangwaek Rd., Bangpai, Bangkae, Bangkok 10160 Tel : 0-2865-4647-8 Fax : 0-2865-4649
                </p>
            </div>
            <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon2.jpg" width="90px"
                height="110px">
            <img class="col-1 text-left p-0" src="/assets/app-assets/images/pdf/icon3.png" width="90px"
                height="110px">
            <div class="col-1"></div>
        </div>
        <p class="text-center">Taxpayer Identification No.: 0-1055-45112-91-9 (Head Office)</p>
        <div class="row">
            <div class="col-6 pr-0 text-right">
                Web-site : http://www.mit.in.th
            </div>
            <div class="col-6">
                E-mail : info@mit.in.th
            </div>
        </div>
        <h2 class="text-center text-header pt-1">ใบส่งสินค้า</h2>

        <table class="table table-bordered">
            <tr>
                <td class="text-left" width="60%">
                    <div class="row pl-0" style="padding-bottom: 5px;">
                        <div class="col-3 col-sm-3 col-md-3">
                            <div class="font-weight-bold">Customer / ลูกค้า:</div>
                        </div>
                        <div class="col-9 col-sm-9 col-md-9 pl-0">
                            <div class="font-weight-normal">{{jobOrderInfo?.customer_info?.company_name}}
                                ({{jobOrderInfo?.customer_info?.customer_code}})
                            </div>
                        </div>
                    </div>
                    <div class="row pl-0" style="padding-bottom: 5px;">
                        <div class="col-3 col-sm-3 col-md-3">
                            <div class="font-weight-bold">Address / ที่อยู่:</div>
                        </div>
                        <div class="col-9 col-sm-9 col-md-9 pl-0">
                            <div class="font-weight-normal ">{{jobOrderInfo?.customer_address}}
                            </div>
                        </div>
                    </div>
                    <div class="row pl-0" style="padding-bottom: 5px;">
                        <div class="col-3 col-sm-3 col-md-3">
                            <div class="font-weight-bold">Tel No. / โทร:</div>
                        </div>
                        <div class="col-3 col-sm-3 col-md-3 pl-0">
                            <div class="font-weight-normal">
                              {{jobOrderInfo?.customer_address_info.tel_1}}
                              <span *ngIf="jobOrderInfo?.customer_address_info.tel_2">
                                , {{jobOrderInfo?.customer_address_info.tel_2}}
                              </span>
                            </div>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2">
                            <div class="font-weight-bold">Fax:</div>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 pl-0">
                            <div class="font-weight-normal">
                                {{jobOrderInfo?.customer_address_info.fax_1}}
                                <span *ngIf="jobOrderInfo?.customer_address_info.fax_2">
                                  , {{jobOrderInfo?.customer_address_info.fax_2}}
                                </span>
                              </div>
                        </div>
                    </div>
                    <div class="row pl-0" style="padding-bottom: 5px;">
                        <div class="col-3 col-sm-3 col-md-3">
                            <div class="font-weight-bold">Attn / เรียน:</div>
                        </div>
                        <div class="col-9 col-sm-9 col-md-9 pl-0">
                            <div class="font-weight-normal">
                                <span class="pl-0" *ngFor="let item of joborderContactList; let i = index;">
                                    {{item?.customer_contact_info?.firstname}} {{item?.customer_contact_info?.lastname}}
                                    <span class="pl-0" *ngIf="joborderContactList?.length != i+1">,</span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="row pl-0" style="padding-bottom: 5px;">
                        <div class="col-3 col-sm-3 col-md-3">
                            <div class="font-weight-bold">Tel No. / โทร:</div>
                        </div>
                        <div class="col-3 col-sm-3 col-md-3 pl-0">
                            <div class="font-weight-normal">
                                <span class="pl-0" *ngFor="let item of joborderContactList; let i = index;">
                                    {{item?.customer_contact_info?.phonenumber}}
                                    <span class="pl-0" *ngIf="joborderContactList?.length != i+1">,</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-2 col-sm-2 col-md-2">
                            <div class="font-weight-bold">E-mail:</div>
                        </div>
                        <div class="col-4 col-sm-4 col-md-4 pl-0">
                            <div class="font-weight-normal">
                                <span class="pl-0" *ngFor="let item of joborderContactList; let i = index;">
                                    {{item?.customer_contact_info?.email}}
                                    <span class="pl-0" *ngIf="joborderContactList?.length != i+1">,</span>
                                </span>
                            </div>
                        </div>

                    </div>
                </td>
                <td class="text-left" width="40%">
                    <div style="padding-left: 0px;padding-bottom: 5px;" class="row">
                        <div class="col-5 col-sm-5 col-md-5">
                            <div class="font-weight-bold">Date / วันที่:</div>
                        </div>
                        <div class="col-7 col-sm-7 col-md-7">
                            <div class="font-weight-normal">
                                {{jobOrderInfo?.doc_date | date: 'dd/MM/yyyy'}}</div>
                        </div>
                    </div>
                </td>
            </tr>
        </table>

        <table class="table table-bordered">
            <tr>
                <th class="text-center form-group text-bold" width="10%">Item <br> ลําดับ</th>
                <th class="text-center form-group text-bold" width="70%">
                    Description <br> รายการ
                </th>
                <th class="text-center form-group text-bold">Quantity <br> จํานวน</th>
            </tr>
            <tr *ngFor="let item of page; let i = index;">
                <td class="text-center">{{item.index}}</td>
                <td class="text-left">
                    {{item.quotation_item_info?.product_title}}
                </td>
                <td class="text-center">
                    {{item.quotation_item_info?.quantity}}
                </td>
            </tr>
            <tr>
                <td class="text-center" style="height: 38px;"></td>
                <td class="text-left"></td>
                <td class="text-center"></td>
            </tr>
        </table>

        <table class="table table-bordered" *ngIf="lastIndex">
            <tr>

                <td width="50%" colspan="2">
                    <div class="row pl-0">
                        <div class="col-12 col-sm-12 col-md-12 mb-4 text-center">
                            <div class="font-weight-normal">สำหรับเจ้าหน้าที่</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 mb-1 text-center text-dark">
                            <hr />
                            <div class="font-weight-normal">(ตัวบรรจง)</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 mt-2 mb-2">
                            Date / วันที่: <span>.........</span><span>/</span><span>...............</span><span>/</span><span>........................</span>
                        </div>
                    </div>
                   
                </td>

                <td width="50%" colspan="2">
                    <div class="row pl-0">
                        <div class="col-12 col-sm-12 col-md-12 mb-4 text-center">
                            <div class="font-weight-normal">สำหรับลูกค้า</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 mb-1 text-center text-dark">
                            <hr />
                            <div class="font-weight-normal">(ตัวบรรจง)</div>
                        </div>
                        <div class="col-12 col-sm-12 col-md-12 mt-2 mb-2">
                            Date / วันที่: <span>.........</span><span>/</span><span>...............</span><span>/</span><span>........................</span>
                        </div>
                    </div>
                   
                </td>
            </tr>
          
        </table>
      </div>
      
      <div class="btn-section text-right border-bottom">
      </div>
      <br>
    </div>
    
</div>