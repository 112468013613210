<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="invoice-item-create"
  tabindex="-1"
  role="dialog"
  aria-labelledby="ItemCreate1Modal"
  aria-hidden="true"
>
  <div class="modal-dialog modal-xl" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="ItemCreate1Modal">
          เลือกรายการ
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form class="form form-horizontal" [formGroup]="form">
            <div class="form-body">
                <div class="form-group row mb-0">
                  <h4 class="col-12 col-sm-12 col-md-2 col-lg-2 label-control" for="description">
                    ใบเสนอราคา <span class="danger">*</span> :
                  </h4>
                  <div class="col-12 col-sm-12 col-md-3 col-lg-3">
                      <form-group
                      [control]="form.controls.quotation_info_id"
                      [submitted]="submitted">
                      <ng-select2
                          [data]="quotationList"
                          [options]="{
                            language: UtilsService.select2OptionsLanguage()
                          }"
                          class="w-100"
                          placeholder="โปรดเลือกใบเสนอราคา"
                          formControlName="quotation_info_id"
                          (valueChanged)="selectQuotation($event)">
                      </ng-select2>
                      </form-group>
                  </div>

                  <div class="col-12 col-sm-12 col-md-7 col-lg-7 text-right">
                    <h4>{{number_of_select}} รายการ</h4>
                  </div>
                </div>
            </div>

            <div class="form-body">
                <div class="form-group row mb-0">
                  <div class="col-12 col-sm-12 col-md-2 col-lg-2">
                  </div>
                  <div class="col-12 col-sm-12 col-md-10 col-lg-10">
                    <div class="table-responsive" style="max-height: 600px;" *ngIf="chkQuotationAccepted">
                        <table class="table table-striped table-bordered" formArrayName="invoice_item">
                            <thead>
                                <tr>
                                    <th>
                                      <span>
                                        <input
                                        *ngIf="isShowCheckAll"
                                        type="checkbox"
                                        id="select_all"
                                        (change)="onCheckAll($event)"
                                        >
                                      </span>
                                    </th>
                                    <th>สินค้าบริการ</th>
                                    <th>คำอธิบาย</th>
                                    <th class="text-right">ราคา</th>
                                    <th class="text-right">ส่วนลด</th>
                                    <th class="text-right">จำนวน</th>
                                    <th class="text-right">รวม</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let item of invoiceFormGroup.controls; let i = index;" [formGroupName]="i">
                                    <td>
                                      <div *ngIf="item.value.item_type=='calibration_service'">
                                        <div *ngIf="item.value?.data?.job_order_calibration_item_info && (item.value?.data?.job_order_calibration_item_info[0]?.item_status_id =='CRF-COMPLETED' || item.value?.data?.job_order_calibration_item_info[0]?.item_status_id =='PREPARE_FOR_SHIP') && !item.value?.data?.acc_invoice_info_id">
                                          <input
                                          type="checkbox"
                                          *ngIf="!item.value?.data?.job_order_calibration_item_info[0]?.lock_invoice_at || item.value?.data?.job_order_calibration_item_info[0]?.unlock_invoice_at"
                                          [id]="'select_'+i"
                                          formControlName="select"
                                          (change)="onCheckChange($event, i)"
                                          >
                                          <span *ngIf="item.value?.data?.job_order_calibration_item_info[0]?.lock_invoice_at && !item.value?.data?.job_order_calibration_item_info[0]?.unlock_invoice_at">
                                            <i class="fa fa-lock fa-lg" aria-hidden="true"></i>
                                          </span>
                                        </div>
                                      </div>
                                      <div *ngIf="item.value.item_type=='product'">
                                        <div *ngIf="item.value?.data?.job_order_product_item_info && (item.value?.data?.job_order_product_item_info[0]?.item_status_id =='CRF-COMPLETED' || item.value?.data?.job_order_product_item_info[0]?.item_status_id =='PREPARE_FOR_SHIP' || item?.value?.data?.product_title.startsWith('Transport')) && !item.value?.data?.acc_invoice_info_id">
                                          <input
                                          type="checkbox"
                                          *ngIf="!item.value?.data?.job_order_product_item_info[0]?.lock_invoice_at || item.value?.data?.job_order_product_item_info[0]?.unlock_invoice_at || item?.value?.data?.product_title.startsWith('Transport')"
                                          [id]="'select_'+i"
                                          formControlName="select"
                                          (change)="onCheckChange($event, i)"
                                          >
                                          <span *ngIf="item.value?.data?.job_order_product_item_info[0]?.lock_invoice_at && !item.value?.data?.job_order_product_item_info[0]?.unlock_invoice_at && !item?.value?.data?.product_title.startsWith('Transport')">
                                            <i class="fa fa-lock fa-lg" aria-hidden="true"></i>
                                          </span>
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                        {{item.value.product_title}}
                                    </td>
                                    <td>
                                      <div *ngIf="item.value.item_type=='product'">
                                        {{item.value.product_description}}
                                      </div>
                                      <div *ngIf="item.value.item_type=='calibration_service'">
                                        <small>
                                          Description: {{item.value.data.description | dash}}<br/>
                                          Serial no: {{item.value.data.serial_no | dash}}<br/>
                                          Point:
                                          <span *ngFor="let detail of item.value.data.quotation_mit_item_detail_info; let dLastIndex = last;">
                                            <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0">
                                                <span *ngFor="let point of detail.quotation_mit_item_detail_points_info; let pLastIndex = last;">
                                                  {{point?.cus_point}} <span *ngIf="!pLastIndex">,</span>
                                                </span>
                                            </span>
                                            <span *ngIf="detail.quotation_mit_item_detail_ranges_info.length > 0">
                                              <span *ngFor="let range of detail.quotation_mit_item_detail_ranges_info; let rLastIndex = last;">
                                                {{range?.cus_min}} - {{range?.cus_max}}
                                              </span>
                                            </span>
                                            <span *ngIf="detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0" [innerHTML]="detail?.unit_info?.unit_short_name_th"></span>
                                            <span *ngIf="!dLastIndex && (detail.quotation_mit_item_detail_points_info.length > 0 || detail.quotation_mit_item_detail_ranges_info.length > 0)">, </span>
                                          </span>
                                        </small>
                                      </div>
                                    </td>
                                    <td class="text-right">
                                        {{utils.convertStingToNumber(item.value.price) | number : '1.2-2'}}
                                    </td>
                                    <td class="text-right">
                                        {{item.value.discount | number : '1.2-2'}}
                                    </td>
                                    <td class="text-right">
                                        {{item.value.quantity}}
                                    </td>
                                    <td class="text-right">
                                        <div *ngIf="item.value.item_type=='calibration_service'">
                                            {{calculate.sumMit(item.value) | number : '1.2-2'}}
                                        </div>
                                        <div *ngIf="item.value.item_type=='product'">
                                            {{calculate.sumProduct(item.value) | number : '1.2-2'}}
                                        </div>
                                    </td>
                                </tr>
                                <tr *ngIf="invoiceItems.length === 0">
                                    <td colspan="7" class="text-center">ไม่มีข้อมูล</td>
                                </tr>
                            </tbody>

                        </table>
                    </div>

                    <div class="alert alert-danger" role="alert" *ngIf="!chkQuotationAccepted">
                      ใบเสนอราคานี้ยังไม่แนบหลักฐานยืนยันการสั่งซื้อ
                    </div>
                  </div>
                </div>
            </div>
        </form>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-plus icon-left"></i>เพิ่มรายการ
        </button>
      </div>
    </div>
  </div>
</div>
