import Axios from "../../services/axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class QuotationMitItemDetailPointService {
  async create({
    quotation_mit_item_detail_id,
    cus_point,
    cus_unit_id,
    mit_point,
    mit_unit_id,
    is_non_calculate,
    is_non_calibration,
    order_by
  }) {
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-item-detail-points`, {
        quotation_mit_item_detail_id,
        cus_point: parseFloat(cus_point),
        cus_unit_id,
        mit_point: parseFloat(mit_point),
        mit_unit_id,
        is_non_calculate,
        is_non_calibration,
        order_by
      });
      result = result?.data || {};
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }

  async createArray(array) {
    array = array.map(item=>{
      return {
        quotation_mit_item_detail_id:item.quotation_mit_item_detail_id,
        cus_point: parseFloat(item.cus_point),
        cus_unit_id:item.cus_unit_id,
        mit_point: parseFloat(item.mit_point),
        mit_unit_id:item.mit_unit_id,
        is_non_calculate:item.is_non_calculate,
        is_non_calibration:item.is_non_calibration,
        order_by:item.order_by
      }
    })
    let result = null;
    try {
      result = await Axios().post(`/quotation-mit-item-detail-points`, array);
      result = result?.data || {};
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async update(data) {
    let {
        quotation_mit_item_detail_point_id,
        quotation_mit_item_detail_id,
        cus_point,
        cus_unit_id,
        mit_point,
        mit_unit_id,
        is_non_calculate,
        is_non_calibration,
        order_by
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/quotation-mit-item-detail-points/${quotation_mit_item_detail_point_id}`, {
        quotation_mit_item_detail_id,
        cus_point: parseFloat(cus_point),
        cus_unit_id,
        mit_point: parseFloat(mit_point),
        mit_unit_id,
        is_non_calculate,
        is_non_calibration,
        order_by
      });
      result = result?.data || {};
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async updateArray(array) {
    array = array.map(item=>{
      return {
        quotation_mit_item_detail_point_id:item.quotation_mit_item_detail_point_id,
        quotation_mit_item_detail_id:item.quotation_mit_item_detail_id,
        cus_point: parseFloat(item.cus_point),
        cus_unit_id:item.cus_unit_id,
        mit_point: parseFloat(item.mit_point),
        mit_unit_id:item.mit_unit_id,
        is_non_calculate:item.is_non_calculate,
        is_non_calibration:item.is_non_calibration,
        order_by:item.order_by
      }
    })
    let result = null;
    try {
      result = await Axios().put(`/quotation-mit-item-detail-points`, array);
      result = result?.data || {};
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async delete({ quotation_mit_item_detail_point_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/quotation-mit-item-detail-points/${quotation_mit_item_detail_point_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-detail-points`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error?.response?.data;
    }
    return result;
  }
  async getById({ quotation_mit_item_detail_point_id }) {
    let result = null;
    try {
      result = await Axios().get(`/quotation-mit-item-detail-points/${quotation_mit_item_detail_point_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
