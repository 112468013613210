import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Select, Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { GetUserInfo } from "src/app/store/user/user.action";
import { UserInfoState } from "src/app/store/user/user.state";

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.css']
})
export class LandingComponent implements OnInit {
  pageLoad: boolean = false;
  @Select(UserInfoState.getUser) userInfo$: Observable<any>;

  constructor(
    private router: Router,
    private store: Store,
  ) {}

  me() {
    this.store.dispatch(new GetUserInfo());
  }

  async ngOnInit() {
    this.me();
    
    this.userInfo$.subscribe(user=>{
      if(user){
        let goToPage = "";
        if(user.emp_info?.emp_department_id.startsWith(
          'SALES_DEP'
        )) {
          goToPage = "/quotation";
        } else if(user.emp_info?.emp_department_id.startsWith(
          'TRAN_DEP'
        )) {
          goToPage = "/transport-receive";
        } else if(user.emp_info?.emp_department_id.startsWith(
          'CALIB_DEP'
        ) || user.emp_info?.emp_department_id.startsWith(
          'PUCHASE_DEP'
        )) {
          goToPage = "/work-order";
        } else if(user.emp_info?.emp_department_id.startsWith(
          'ACC_DEP'
        )){
          goToPage = "/invoice";
        } 
        window.location.href = goToPage;
      }
    })
  }

}
