import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { 
  MITCPService, 
} from 'src/app/services';
import { 
  QuotationMitItemDetailPointService,
  QuotationMitItemDetailRangeService,
  QuotationUtilsService 
} from 'src/app/services/quotations';
import { 
  JobOrderCalibrationItemService,
  JobOrderInfoService, 
  JobOrderProductItemService
} from 'src/app/services/job-orders';
import domtoimage from 'dom-to-image-more'
import jsPDF from 'jspdf';  
import { Title } from '@angular/platform-browser';
import { PDF } from '../../../quotation/quotation-pdf/pdf.service';
import moment from 'moment';

@Component({
  selector: 'app-prd',
  templateUrl: './prd.component.html',
  styleUrls: ['./prd.component.css']
})
export class PrdComponent implements OnInit {
  public jobOrderItems = [];
  public pages = [];

  public job_order_info_id: string = "";
  public token: string = "";
  public textBottomPage: string = "FM-ADM-03, Revise 4, Effective Date 01/11/21";
  //any
  public customer_info:any = null;
  public jobOrderInfo:any = null;

  public canAccess: boolean = false;
  public pageLoad: boolean = false;

  constructor(
    private getDataPdf: PDF,
    private titleService: Title,
    private route: ActivatedRoute,
    private MITCPService: MITCPService,
    private JobOrderInfoService: JobOrderInfoService,
    private JobOrderProductItemService: JobOrderProductItemService,
    private JobOrderCalibrationItemService: JobOrderCalibrationItemService,
    private QuotationMitItemDetailPointService: QuotationMitItemDetailPointService,
    private QuotationMitItemDetailRangeService: QuotationMitItemDetailRangeService,

    public utils : QuotationUtilsService
  ) {
    this.job_order_info_id = 
    this.route.snapshot.paramMap.get("job_order_info_id");
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
    });
   }

   async ngOnInit() {
    if(!this.token){
      await this.JobOrderInfoService.getById({job_order_info_id: this.job_order_info_id})
      .then(async res=>{
        this.canAccess = true;
        this.pageLoad = true;
        this.jobOrderInfo = res.resultData || {};
        this.jobOrderInfo.doc_date = moment(this.jobOrderInfo.doc_date).format("DD-MM-YYYY");
        if(this.jobOrderInfo.customer_address_info){
          this.jobOrderInfo['customer_address'] = this.utils.genAddress(this.jobOrderInfo.customer_address_info);
        }

        if(this.jobOrderInfo.job_order_type =='ECL' || this.jobOrderInfo.job_order_type =='ECS'){
          await this.JobOrderCalibrationItemService.load(null, {job_order_info_id: this.job_order_info_id})
          .then(async res=>{
            this.jobOrderItems = res.resultData || [];
          });
        }else{
          await this.JobOrderProductItemService.load(null, {job_order_info_id: this.job_order_info_id})
          .then(async res=>{
            this.jobOrderItems = res.resultData || [];
          });
        }

        this.setData();
      })
     }else{
      try {
        await this.getDataPdf
        .get(this.token, "/job-order-info/" + this.job_order_info_id)
        .then(async (res) => {
          this.canAccess = true;
          this.pageLoad = true;
          this.jobOrderInfo = res.resultData || {};
          this.jobOrderInfo.doc_date = moment(this.jobOrderInfo.doc_date).format("DD-MM-YYYY");
          if(this.jobOrderInfo.customer_address_info){
            this.jobOrderInfo['customer_address'] = this.utils.genAddress(this.jobOrderInfo.customer_address_info);
          }
  
          if(this.jobOrderInfo.job_order_type =='ECL' || this.jobOrderInfo.job_order_type =='ECS'){
            await this.getDataPdf
            .get(this.token, "/job-order-calibration-item?job_order_info_id=" + this.job_order_info_id)
            .then((res) => {
              this.jobOrderItems = res.resultData || [];
            });
          }else{
            await this.getDataPdf
            .get(this.token, "/job-order-product-item?job_order_info_id=" + this.job_order_info_id)
            .then((res) => {
              this.jobOrderItems = res.resultData || [];
            });
          }

          this.setData();
        });
      } catch (error) {
        this.canAccess = false;
        this.pageLoad = true;
      }
     }
  }

  setData(){
    this.titleService.setTitle(this.jobOrderInfo.doc_no + ' - มิราเคิล อินเตอร์เนชั่นแนล เทคโนโลยี');
      
    for (var i=0; i<this.jobOrderItems.length; i++) {
      this.jobOrderItems[i].index = i;
    }
    for (var i=0; i<this.jobOrderItems.length; i+=20) {
      this.pages.push(this.jobOrderItems.slice(i, i+20));
    }  
  }

  async mapMitItemData(mit: any): Promise<any>{
    let mit_cp_info = await this.MITCPService.getById({mit_cp_id:mit.mit_cp_id})
    .then(res=>{
      return {
        ...res.resultData
      }
    });
    const points = await this.QuotationMitItemDetailPointService
    .load(null, { quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id, 'order_by': 'order_by:asc'})
    .then((res) => {
      return res.resultData || [];
    });
    const range = await this.QuotationMitItemDetailRangeService
    .load(null, { quotation_mit_item_detail_id: mit.quotation_mit_item_detail_id})
    .then((res) => {
      return res.resultData || [];
    });
    await points.map(point=>{
      if(point.cus_unit_info) point['cus_unit_name'] = point.cus_unit_info.unit_short_name_en 
      || point.cus_unit_info.unit_short_name_th;
      if(point.mit_unit_info) point['mit_unit_name'] = point.mit_unit_info.unit_short_name_en 
      || point.mit_unit_info.unit_short_name_th;
    })
    
    return {
      ...mit,
      detail_type: range.length > 0? 'range': 'point',
      quotation_mit_item_detail_range: {
        quotation_mit_item_detail_range_id: range[0]?.quotation_mit_item_detail_range_id || '', 
        cus_min: range[0]?.cus_min || 0,
        cus_max: range[0]?.cus_max || 0, 
        cus_unit_id: range[0]?.cus_unit_id || '',
        cus_unit_name: range[0]?.cus_unit_info.unit_short_name_en || '',
        mit_min: range[0]?.mit_min || 0,
        mit_max: range[0]?.mit_max || 0,
        mit_unit_id: range[0]?.mit_unit_id || '',
        mit_unit_name: range[0]?.mit_unit_info.unit_short_name_en || '', 
      },
      cus_unit_id: mit.unit_id,
      unit_name: mit.unit_info.unit_short_name_en || mit.unit_info.unit_short_name_th,

      isISO: mit.product_calibration_fee_info.isISO,
      min_range: mit.product_calibration_fee_info.min_range,
      max_range:  mit.product_calibration_fee_info.max_range,

      mit_cp_name: mit_cp_info?.cp_name || null,
      mit_method_name: mit.mit_method_info?.method_name || null,
      quotation_mit_item_detail_points: points
    }
  }

  async print(jobOrderInfo){
    const job_order_sections = document.querySelectorAll('.pdf');
    let doc = new jsPDF('p', 'mm', 'a4', true);
    doc.setFontSize(8);
    let page = 1;
    let perPage = Array.prototype.slice.call(job_order_sections,0).length;
    
    let jobOrderList = [];
    await Promise.all(Array.prototype.slice.call(job_order_sections,0).map(async (value, index) =>{
      let job_order_section = document.getElementById('job_order_' + (index));
      await domtoimage.toJpeg(job_order_section, {
        background: 'white', 
        allowTaint: true,
        width: job_order_section.clientWidth * 2,
        height: job_order_section.clientHeight * 2,
        style: {
         transform: 'scale('+2+')',
         transformOrigin: 'top left'
        },
        quality: 0.95 
      })
      .then(async function (canvas) {
        jobOrderList.push({index: index, canvas: canvas, job_order_section: job_order_section});
      })
    }));
    jobOrderList.sort(function(a, b){return a.index - b.index});
    await Promise.all(jobOrderList.map(async (value, index) =>{

      var imgWidth = 208;
      var imgHeight = value.job_order_section.clientHeight * imgWidth / value.job_order_section.clientWidth;
      if((index) != 0) doc.addPage('a4');
      doc.addImage(value.canvas, 'JPEG', 0, 0, imgWidth, imgHeight, null);
      doc.text(this.textBottomPage, 130, 285);
      doc.text(page + ' /  ' + perPage, 200, 285);
      page = page+1;
    }));

    setTimeout(() => {
      doc.save(jobOrderInfo.doc_no);
    }, 1000);
  }

  public get docNo(): string{
    let docNoList = [];

    this.jobOrderItems.map(item=>{
      docNoList.push(item.quotation_info.doc_no);
    })
    docNoList = docNoList.filter((doc, index, self) =>
    index === self.findIndex((t) => (
      t === doc
    )))
    return docNoList.join();
  }
}
