import Axios from "../axios";
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root",
})
export class AccBillingNoteItemHistoryService {
  async create({
    acc_billing_note_item_id,
    previous_date,
    new_date
  }) {
    let result = null;
    try {
      result = await Axios().post(`/acc-billing-note-item-history`, {
        acc_billing_note_item_id,
        previous_date,
        new_date
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update(data) {
    let {
        acc_billing_note_item_history_id,
        acc_billing_note_item_id,
        previous_date,
        new_date
    } = data;
    let result = null;
    try {
      result = await Axios().put(`/acc-billing-note-item-history/${acc_billing_note_item_history_id}`, {
        acc_billing_note_item_id,
        previous_date,
        new_date
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ acc_billing_note_item_history_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/acc-billing-note-item-history/${acc_billing_note_item_history_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-item-history`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async getById({ acc_billing_note_item_history_id }) {
    let result = null;
    try {
      result = await Axios().get(`/acc-billing-note-item-history/${acc_billing_note_item_history_id}`);
      result = result.data;
    } catch (error) {}
    return result;
  }
}
