import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { environment } from "src/environments/environment";
@Component({
  selector: "app-file-list",
  templateUrl: "./file-list.component.html",
  styleUrls: ["./file-list.component.css"],
})
export class FileListComponent implements OnInit {
  @Input() files = [];
  @Output() filesChange = new EventEmitter();
  @Input() canRemove = true;
  @Input("confirm-modal-target") confirmModalId;
  environment = environment;
  @Output() remove = new EventEmitter();
  ngOnInit(): void {}

  removeFile(file) {
    this.remove.emit(file);
  }

  ngOnChanges(changes) {
    if (changes.files) {
      this.files = changes.files.currentValue;
    }
  }
}
