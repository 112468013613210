import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class CustomerActivityService {
  async create({
    customer_id,
    customer_activity_type_id,
    activity_datetime: activity_datetime = null,
    title,
    description: description = null,
    location_lat: location_lat = null,
    location_lng: location_lng = null,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/customer-activities`, {
        customer_id,
        customer_activity_type_id,
        activity_datetime,
        title,
        description,
        location_lat,
        location_lng,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    customer_activities_id,
    customer_id,
    customer_activity_type_id,
    activity_datetime,
    title,
    description,
    location_lat,
    location_lng,
  }) {
    let result = null;
    try {
      result = await Axios().put(
        `/customer-activities/${customer_activities_id}`,
        {
          customer_id,
          customer_activity_type_id,
          activity_datetime,
          title,
          description,
          location_lat,
          location_lng,
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ customer_activities_id }) {
    let result = null;
    try {
      result = await Axios().delete(
        `/customer-activities/${customer_activities_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/customer-activities`, {
        params: { offset: paging?.offset, limit: paging?.limit, ...filter },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ customer_activities_id }) {
    let result = null;
    try {
      result = await Axios().get(
        `/customer-activities/${customer_activities_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
