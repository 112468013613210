import { Directive, ElementRef, Input } from "@angular/core";
declare var $: any;

@Directive({
  selector: "[currency]",
})
export class CurrencyInputDirective {
  @Input() decimal = 2;
  constructor(el: ElementRef) {
    let that = this;
    $(el.nativeElement)
      .blur(function () {
        $(this).attr("type", "text");
        if ($(this).val()) {
          $(this).val(that.numberWithCommas($(this).val()));
        } else {
          $(this).val("");
        }
      })
      .focus(function () {
        if ($(this).val()) {
          $(this).val(that.removeCommas($(this).val()));
          $(this).attr("type", "number");
        } else {
          $(this).val("");
        }
      });

    setTimeout(() => {
      if ($(el.nativeElement).val() !== "") {
        $(el.nativeElement).val(
          that.numberWithCommas($(el.nativeElement).val())
        );
      }
    });
  }

  numberWithCommas(x) {
    return Number(x)
      .toFixed(this.decimal)
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  removeCommas(x) {
    return x.replace(/,/g, "");
  }
}
