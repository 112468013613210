<div
  class="modal fade text-left"
  [id]="id"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel1"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="myModalLabel1">ปรับสถานะ</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="onClose()"
          #closeModal
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="pageLoad">

        <form class="form" [formGroup]="form">
            <div class="row justify-content-md-center">
              <div class="col-md-12">
                <div class="form-body">
                    <label for="">โปรดเลือกสถานะงาน</label>
                    <div class="form-check pb-1">
                        <input class="form-check-input" type="radio" name="status" id="status1"   value="COMPLETED" formControlName="status">
                        <label class="form-check-label" for="status1">
                          สำเร็จ
                        </label>

                        <div class="row pt-1" *ngIf="form.value.status == 'COMPLETED'">

                          <div class="col-12">
                              <form-group [control]="form.controls.acc_invoice_info_doc_no" [submitted]="submitted">
                                  <label for="acc_invoice_info_doc_no">
                                      เลขที่ INV
                                  </label>
                                  <input type="text" id="acc_invoice_info_doc_no" class="form-control" placeholder="เลขที่ INV" formControlName="acc_invoice_info_doc_no" />
                              </form-group>
          
                          </div>
                      </div>
                    </div>
                    <div class="form-check pb-1">
                        <input class="form-check-input" type="radio" name="status" id="status2"   value="RESCHEDULED" formControlName="status">
                        <label class="form-check-label" for="status2">
                            ไม่สำเร็จและสร้าง Booking ใหม่
                        </label>

                        <div class="row pt-1" *ngIf="form.value.status == 'RESCHEDULED'">
                            <div class="col-7">
                                <form-group [control]="form.controls.booking_date" [submitted]="submitted">
                                    <label for="booking_date">
                                        วันที่รับส่งงาน <span class="danger" >*</span>
                                    </label>
                                    <div class="input-group mt-0">
                                        <input date-picker type="text" id="booking_date" 
                                        [date]="form.value.booking_date"
                                        [max-date]="undefined"
                                        *ngIf="form.value.booking_date"
                                         class="form-control"
                                            formControlName="booking_date" />
                                        <div class="input-group-append" for="doc_date">
                                            <span class="input-group-text"><i class="feather icon-calendar"></i></span>
                                        </div>
                                    </div>
                                </form-group>
                            </div>

                            <div class="col-5">
                                <form-group [control]="form.controls.booking_time" [submitted]="submitted">
                                    <label for="booking_time">
                                        เวลา <span class="danger" >*</span>
                                    </label>
                                    <input type="text" id="booking_time" class="form-control" placeholder="เวลา" formControlName="booking_time" />
                                </form-group>
            
                            </div>
                        </div>
                    </div>
                    <div class="form-check pb-1">
                        <input class="form-check-input" type="radio" name="status" id="status3"   value="CANCELED" formControlName="status">
                        <label class="form-check-label" for="status3">
                            ยกเลิก
                        </label>
                    </div>
                
                </div>
              </div>
            </div>
          </form>
      </div>

      <div class="modal-footer">

      <button type="button" class="btn btn-primary" (click)="onSave()">
        <i class="fa fa-save icon-left"></i>บันทึก
      </button>
      </div>
    </div>
  </div>
</div>
