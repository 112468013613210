import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TransportEditHistoryService, TransportreceiveMemoService } from 'src/app/services/transport-receives';

@Component({
  selector: 'app-transport-receive-memo',
  templateUrl: './transport-receive-memo.component.html',
  styleUrls: ['./transport-receive-memo.component.css']
})
export class TransportreceiveMemoComponent implements OnInit {
  form: FormGroup;
  //boolean
  submitted = false;
  pageLoad = true;
  @Input() resetMemo: boolean;
  //array
  memoList: any = [];
  //string
  transport_receive_info_id: string;
  //any
  status: any;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private TransportreceiveMemoService: TransportreceiveMemoService,
    private TransportEditHistoryService: TransportEditHistoryService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {
    this.transport_receive_info_id = 
    this.route.snapshot.paramMap.get("transport_receive_info_id");
    this.createForm();
    this.getMemoList();
  }
  createForm(){
    this.form = this.formBuilder.group({
      memo: ["", [Validators.required]],
    });
  }
  getMemoList(){
    this.pageLoad = true;
    this.memoList = [];
    this.form.get('memo').setValue('');

    this.TransportEditHistoryService.load(null, { transport_receive_info_id : this.transport_receive_info_id  })
    .then(async (res)=>{
      let historyList  = res.resultData;
      this.TransportreceiveMemoService.load(null, { transport_receive_id : this.transport_receive_info_id  })
      .then(async (res)=>{
        let memoList  = res.resultData;
        this.memoList = [
          ...historyList,
          ...memoList
        ];
        this.memoList.sort(function(a,b){
          if(a.created_at < b.created_at) return 1;
          if(a.created_at > b.created_at) return -1;
          return 0;
        });
      })
      this.pageLoad = false;
    })
  }
  async save(){
    this.submitted = true;
    if(this.form.invalid){
      return;
    }
    this.submitted = false;
    let  quotationMemoResponse = await this.TransportreceiveMemoService.create({
      transport_receive_id: this.transport_receive_info_id,
      ...this.form.value
    });
    if (quotationMemoResponse.success) {
      this.getMemoList();
    }
  }
}
