<div
  modal
  (close)="close($event)"
  class="modal fade text-left"
  id="work-order-return"
  tabindex="-1"
  role="dialog"
  aria-labelledby="TransportreceiveFilterModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header bg-primary text-white">
        <h4 class="modal-title" id="TransportreceiveFilterModal">
          บันทึกส่งงาน
        </h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="row">

            <div class="col-md-12 col-sm-12">
                <div class="card box-shadow-0 border-lighten-3 rounded bg-transparent mb-0">
                    <div class="card-content collapse show border">
                        <signature-pad class="mr-0 pr-0" [options]="signaturePadOptions" (onBeginEvent)="drawStart()" (onEndEvent)="drawComplete()"></signature-pad>
                    </div>
                </div>
                <div class="pl-1 text-danger" *ngIf="submitted === true && signaturePad.isEmpty()">
                    โปรดระบุ
                </div>
            </div>

            <div class="col-md-12 col-sm-12 text-right">
              <label type="button" class="btn btn-light btn-min-width mt-1" (click)="reset()">
                ลงชื่อใหม่
              </label>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-warning"
          data-dismiss="modal"
          #closeModalBtn
        >
          <i class="fa fa-times icon-left"></i> ยกเลิก
        </button>

        <button type="button" class="btn btn-primary" (click)="submit()">
          <i class="fa fa-save icon-left"></i>บันทึก
        </button>
      </div>
    </div>
  </div>
</div>
