<div class="app-content content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <div class="content-header row">
      <div class="content-header-left col-md-6 col-12 mb-2">
        <h3 class="content-header-title mb-1">รายการประเภทหน่วยนับ</h3>
        <div class="row breadcrumbs-top">
          <div class="breadcrumb-wrapper col-12">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/dashboard']">ตั้งค่าระบบ</a>
              </li>
              <li class="breadcrumb-item">
                <a href="#" [routerLink]="['/unit/unit-type']">ตั้งค่าประเภทหน่วยนับ</a>
              </li>
              <li class="breadcrumb-item active">รายการประเภทหน่วยนับ</li>
            </ol>
          </div>
        </div>
      </div>
      <div class="content-header-right col-md-6 col-12 mb-md-0 mb-2">
        <div class="float-md-right">
          <button
            [routerLink]="['/unit']"
            type="button"
            class="btn btn-light btn-min-width mr-1"
          >
            <i class="fa fa-arrow-left icon-left"></i> กลับ
          </button>

          <button
            type="button"
            class="btn btn-outline-primary"
            data-toggle="modal"
            data-target="#create-unit-type"
          >
            <i class="fa fa-plus-circle icon-left"></i> เพิ่มประเภท
          </button>
        </div>
      </div>
    </div>

    <div class="content-body">
      <app-response-status
        *ngIf="status"
        [status]="status"
      ></app-response-status>

      <section class="row">
        <div class="col-sm-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title mt-1">รายการหน่วยนับ</h4>
              <div class="heading-elements">
                <search-box (search)="search($event)"></search-box>
              </div>
            </div>

            <div class="card-content collapse show">
              <div class="card-body">
                <loading *ngIf="isLoading"></loading>
                <div class="table-responsive" *ngIf="!isLoading">
                  <table class="table table-striped table-bordered">
                    <thead>
                      <tr>
                        <th class="text-center">ลำดับ</th>
                        <th>ประเภท</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let unitType of unitTypes; let i = index">
                        <th class="text-center">{{ unitType.i }}</th>
                        <td>{{ unitType.unit_type_name_en }}</td>
                        <td>
                          <button
                            class="btn mr-1 mb-1 btn-warning btn-sm btn-edit"
                            data-target="#edit-unit-type"
                            data-toggle="modal"
                            (click)="select(unitType.unit_type_id)"
                          >
                            <i class="fa fa-pencil icon-left"></i> แก้ไข
                          </button>
                          <button
                            type="button"
                            class="btn mr-1 mb-1 btn-danger btn-sm"
                            data-target="#modalConfirmDelMsg"
                            data-toggle="modal"
                            (click)="select(unitType.unit_type_id)"
                            [disabled]="unitType.unit_conversion_count"
                          >
                            <i class="fa fa-trash icon-left"></i> ลบ
                          </button>
                          <span style="position: absolute;">|</span>
                          <button
                            type="button"
                            class="btn ml-2 mb-1 btn-primary btn-sm"
                            [routerLink]="[
                              '/unit/unit-type/' +
                                unitType.unit_type_id +
                                '/unit-conversion'
                            ]"
                          >
                            <i class="fa fa-cog icon-left"></i> จัดการแปลงหน่วย
                          </button>
                        </td>
                      </tr>
                      <tr *ngIf="!unitTypes.length">
                        <td colspan="4" class="text-center">ไม่มีข้อมูล</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <app-paginator
                  [filter]="filter"
                  [reset]="reset"
                  [fetch]="load"
                  (changePage)="onChangePage($event)"
                ></app-paginator>
              </div>
            </div>
          </div>
        </div>
      </section>
      <app-unit-type-create (done)="doReset($event)"></app-unit-type-create>
      <app-unit-type-edit
        *ngIf="unit_type_id"
        [unit-type-id]="unit_type_id"
        (done)="doReset($event)"
      ></app-unit-type-edit>
    </div>
  </div>
</div>

<app-confirm-delete (save)="delete($event)"></app-confirm-delete>
