import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class ProductCalibrationDetailService {
  async create({
    product_id,
    service,
    mit_scope_id,
    mit_equipment_id,
    mit_method_id,
    mit_cp_id,
    note_1,
    note_2,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/product-calibration-detail`, {
        product_id,
        service,
        mit_scope_id,
        mit_equipment_id,
        mit_method_id,
        mit_cp_id,
        note_1,
        note_2,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    product_calibration_detail_id,
    product_id,
    service,
    mit_scope_id,
    mit_equipment_id,
    mit_method_id,
    mit_cp_id,
    note_1,
    note_2,
  }) {
    let result = null;
    try {
      result = await Axios().put(
        `/product-calibration-detail/${product_calibration_detail_id}`,
        {
          product_id,
          service,
          mit_scope_id,
          mit_equipment_id,
          mit_method_id,
          mit_cp_id,
          note_1,
          note_2,
        }
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ product_calibration_detail_id }) {
    let result = null;
    try {
      result = await Axios().delete(
        `/product-calibration-detail/${product_calibration_detail_id}`
      );
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  // async load(paging?, filter?) {
  //   let result = null;
  //   try {
  //     result = await Axios().get(`/product-calibration-detail`, {
  //       params: { offset: paging?.offset, limit: paging?.limit, ...filter },
  //     });
  //     result = result.data;
  //   } catch (error) {
  //     result = error.response.data;
  //   }
  //   return result;
  // }

  // async getById({ product_calibration_detail_id }) {
  //   let result = null;
  //   try {
  //     result = await Axios().get(`/product-calibration-detail/${product_calibration_detail_id}`);
  //     result = result.data;
  //   } catch (error) {
  //     result = error.response.data;
  //   }
  //   return result;
  // }
}
