import Axios from "./axios";
import { Injectable } from "@angular/core";
@Injectable({ providedIn: "root" })
export class UnitService {
  async create({
    unit_type_id,
    unit_name_en,
    unit_short_name_en,
    unit_description,
  }) {
    let result = null;
    try {
      result = await Axios().post(`/unit`, {
        unit_type_id,

        unit_name_en,
        unit_short_name_en,
        unit_description,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async update({
    unit_id,
    unit_type_id,
    unit_name_th,
    unit_short_name_th,
    unit_name_en,
    unit_short_name_en,
    unit_description,
  }) {
    let result = null;
    try {
      result = await Axios().put(`/unit/${unit_id}`, {
        unit_type_id,
        unit_name_en,
        unit_short_name_en,
        unit_name_th,
        unit_short_name_th,
        unit_description,
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async delete({ unit_id }) {
    let result = null;
    try {
      result = await Axios().delete(`/unit/${unit_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
  async load(paging?, filter?) {
    let result = null;
    try {
      result = await Axios().get(`/unit`, {
        params: {
          offset: paging?.offset,
          limit: paging?.limit,
          ...filter,
          order_by: "unit_name_en:asc",
        },
      });
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }

  async getById({ unit_id }) {
    let result = null;
    try {
      result = await Axios().get(`/unit/${unit_id}`);
      result = result.data;
    } catch (error) {
      result = error.response.data;
    }
    return result;
  }
}
