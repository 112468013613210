import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, NgZone, Input, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { UtilsService } from 'src/app/services';
import { DownloadFileService } from 'src/app/services/quotations';
import { TransportReceiveItemFileService } from 'src/app/services/transport-receives';

@Component({
  selector: 'app-receive-item-edit',
  templateUrl: './receive-item-edit.component.html'
})
export class ReceiveItemEditComponent implements OnInit {
  form: FormGroup;
  submitted:boolean = false;
  isReview: boolean = false;

  @Output() done = new EventEmitter<any>();
  @Input() transportreceive: any;
  @Input() itemStatus: any;
  @Input() receive: any;

  @ViewChild("closeModalBtn") closeModalBtn: ElementRef;
  fileItem = null;
  //array
  statusList = [];
  files: any = [];
  tab: string ='status';

  constructor(   
    private zone: NgZone,
    private router: Router,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private UtilsService: UtilsService,
    private DownloadFileService: DownloadFileService,
    private TransportReceiveItemFileService: TransportReceiveItemFileService,
    ) { 
      this.router.events
      .subscribe((event: NavigationStart) => {
        if (event.navigationTrigger === 'popstate') {
          this.closeModalBtn.nativeElement.click();
        }
      });
      this.route.queryParams.subscribe(params => {
        this.isReview = params['type'] == 'review'? true: false;
      });
  }
  ngOnInit() {}
  
  ngOnChanges(changes: SimpleChanges) {
    this.setForm();
    this.statusList = changes.itemStatus?.currentValue;
    this.files = changes.receive?.currentValue.files || [];

    if(changes.transportreceive?.currentValue?.doc_status_id == 'RECEIVED_SUCCESS'){
      this.form.controls.item_status_id.disable();
    }

    if(changes.receive?.currentValue.quotation_mit_item_info){
      let data = {
        ...changes.receive?.currentValue,
        note: changes.receive?.currentValue.note || '-',
        noqt_serial_no: changes.receive?.currentValue.quotation_mit_item_info.serial_no || changes.receive?.currentValue.noqt_serial_no || '-',
        noqt_marker: changes.receive?.currentValue.quotation_mit_item_info.marker || changes.receive?.currentValue.noqt_marker || '-',
        noqt_model: changes.receive?.currentValue.quotation_mit_item_info.model || changes.receive?.currentValue.noqt_model || '-',
        noqt_tag_no: changes.receive?.currentValue.quotation_mit_item_info.tag_no || changes.receive?.currentValue.noqt_tag_no || '-',
      }
      this.form.patchValue(data);
    }else{
      let data = {
        ...changes.receive?.currentValue,
        note: changes.receive?.currentValue.note || '-'
      }
      this.form.patchValue(data);
    }
  }

  setForm(){
    this.form = this.formBuilder.group({
      item_status_id: ["", [Validators.required]],
      noqt_description: [""],
      noqt_marker: ["", [Validators.required]],
      noqt_model: ["", [Validators.required]],
      noqt_serial_no: ["", [Validators.required]],
      noqt_tag_no: ["", [Validators.required]],
      note: ["", [Validators.required]]
    });
  }
  
  checkEquipment(): boolean{
    let result = false;
    Object.keys(this.form.value).map(key=>{
      if(key != 'note' && key != 'noqt_description'){
        if(this.form.value[key] == '' || this.form.value[key] == null) result = true;
      }
    })
    return result;
  }

  checkMain(): boolean{
    let result = false;
    Object.keys(this.form.value).map(key=>{
      if(key == 'item_status_id ' || key == 'note'){
        if(this.form.value[key] == '' || this.form.value[key] == null) result = true;
      }
    })
    return result;
  }

  uploadFile(){
    document.getElementById("importFile").click();
  }
  importFile(files: FileList){
    Array.from(files).map(f=>{
      this.files.push(f);
    });
  }
  downloadFile(file){
    if(file.transport_receive_item_id){
      this.DownloadFileService.downloadFileURL(file);
    }else{
      this.DownloadFileService.downloadFile(file);
    }
  }


  removeFile(file){
    this.fileItem = file;
  }

  async deleteFile(closeModalEl){
    let fileItem = this.fileItem;
    if(fileItem.transport_receive_item_file_id){
      await this.TransportReceiveItemFileService.delete(fileItem);

      this.files.map((item, index)=>{
        if(fileItem == item){
          this.files.splice(index, 1);
        }
      })
    }else{
      this.files.map((item, index)=>{
        if(fileItem == item){
          this.files.splice(index, 1);
        }
      })
    }
    closeModalEl.click();
  }

  submit(){
    this.submitted = true;
    if (this.form.invalid || this.checkFileInvalid) {
      return;
    }
    this.submitted = false;
    let data = {
      ...this.form.getRawValue(),
      files: this.files
    }
    this.done.emit(data);
    this.closeModalBtn.nativeElement.click();
  }

  public get checkFileInvalid(): boolean {
    if(this.files.find(file=> this.UtilsService.checkFileSize(file) == false)){
      return true;
    }else{
      return false;
    }
  }
  
  close(isClose: boolean) {
    this.zone.run(() => {
      if (isClose) {
        this.submitted = false;
        this.done.emit(null);
      }
    });
  }
}
